import { Ng, Component, On, Watch, State, Mutation } from '@angular';

import { remove } from 'lodash';

const SiteDomain: { [key: string]: string } = {
  US: 'https://www.gifrgears.com',
  UK: 'https://www.gifrgears.uk',
  AU: 'https://au.gifrgears.com'
};

let currentRegion: string;

if (process.env.NODE_ENV === 'development') {
  SiteDomain.DEV = window.location.origin;
  currentRegion = 'DEV';
} else {
  currentRegion = Object.entries(SiteDomain).find(([, val]) =>
    window.location.href.includes(val)
  )?.[0];
}

@Component({
  name: 'Navbar',
  template: require('./navbar.html')
})
class Navbar extends Ng {
  // readonly logoImage = 'assets/images/logo/mhs-logo-all-white-test.png';
  readonly logoImage = 'assets/images/logo/mhs-logo-white-grayscale.svg';

  isCollapsed = true;
  userMenu = false;
  notificationMenu = false;
  mobileMenuActive = false;
  showCookiesNotice = false;
  showSiteOptions = false;
  showThemeOptions = false;
  userMenuItems: any[] = [];
  siteWideNotifications: any[] = [];
  siteRegion: string = currentRegion;
  newGuideDismissed = false;

  regionOptions = Object.entries(SiteDomain).map(([key, val]) => ({
    label: val.replace(/^https?:\/\/(?:www\.)?/, ''),
    value: key
  }));

  @State me;
  @Mutation('SET_THEME') setTheme;

  get theme() {
    return this.$store.state.theme;
  }

  set theme(theme) {
    this.setTheme({ theme });
  }

  @On('loggedOut')
  onLogout() {
    this.isCollapsed = true;
    this.userMenu = false;
    this.mobileMenuActive = false;
  }

  @Watch('siteRegion')
  onSiteRegionChanged(region) {
    window.location.href = SiteDomain[region];
  }

  get loggedIn() {
    return this.$auth.loggedIn && this.me.id !== null;
  }

  get onDashboard() {
    return this.$state.includes('dashboard');
  }

  get dashboardMenu() {
    return this.$rootScope.dashboardMenu;
  }

  $onInit() {
    // set newGuideDismissed to true on non .com sites since .com is the only
    // one that has existing users
    this.newGuideDismissed = localStorage.getItem('dismissNewGuide112020')
      ? true
      : false;

    //
    this.showCookiesNotice =
      this.$cookies.getObject('gifrCookiesNotified') !== true;

    this.userMenuItems = [
      {
        label: 'User Settings',
        icon: 'cog',
        action: () => this.userSettingsModal()
      },
      {
        label: 'Change Password',
        icon: 'lock',
        action: () => this.changePasswordModal()
      },
      {
        label: 'Change Role',
        icon: 'users',
        action: () => this.chooseRoleModal(),
        hide: () => !this.me.multiRole
      },
      {
        label: 'Dashboard',
        icon: 'tachometer',
        action: () => this.$state.go('dashboard')
      },
      {
        label: 'Date Calculator',
        icon: 'calendar',
        action: () => this.dateCalculatorModal()
      },
      {
        label: 'Logout',
        icon: 'sign-out',
        action: () => this.logout()
      }
    ];

    // grab sitewide notifications
    /**
     * { message: string }
     */
    this.siteWideNotifications = [];
  }

  loginModal() {
    this.$modals.login.login();
  }

  userSettingsModal() {
    this.$modals.settings.settings();
  }

  changePasswordModal() {
    this.$modals.settings.changePassword();
  }

  chooseRoleModal() {
    this.$modals.settings.chooseRole(this.me.roles, true);
  }

  dateCalculatorModal() {
    this.$modals.evaluation.dateCalculator();
  }

  logout() {
    this.$auth.logout();

    this.utils.notify('success', 'Logout Successful');
  }

  dismissCookiesNotice() {
    this.showCookiesNotice = false;
    this.$cookies.putObject('gifrCookiesNotified', true);
  }

  dismissNotification(notification) {
    remove(this.siteWideNotifications, notification);
  }

  dismissNewGuide() {
    localStorage.setItem('dismissNewGuide112020', 'true');
    this.newGuideDismissed = true;
  }
}

export default Navbar.$module;
