import angular from 'angular';

export default angular
  .module('app.ngApply', [])
  .directive('cfiGhostField', function() {
    return {
      template: require('./cfi-ghost-field.html'),
      restrict: 'E',
      replace: true,
      scope: {
        expanded: '=',
        value: '<'
      },
      link(scope, element, attrs) {
        var _expanded = !!scope.expanded;

        Object.defineProperty(scope, '$expanded', {
          get: () => _expanded
        });

        scope.setState = expand => {
          _expanded = !!expand;
        };

        scope.apply = () => {
          scope.$parent.$eval(attrs.ngApply);
        };

        // scope.$watch('expanded', val => {
        //   _expanded = !!val;
        // });
      }
    };
  }).name;
