'use strict';

import angular from 'angular';

export default angular
  .module('app.ngClickoff', [])
  .directive('ngClickoff', ($timeout, $rootScope) => {
    'ngInject';

    var listeners = [];

    document.addEventListener('click', e => {
      listeners.forEach(item => item(e.target));
    });

    return {
      restrict: 'A',
      scope: {
        localFn: '&ngClickoff'
      },
      link($scope, $element) {
        var wasClicked = false;

        $timeout(() => {
          $element.on('click', () => {
            wasClicked = true;
          });

          var action = el => {
            if (!wasClicked) {
              $scope.$apply($scope.localFn);
            }

            wasClicked = false;
          };

          listeners.push(action);

          $scope.$on('$destroy', () => {
            listeners.splice(listeners.indexOf(action), 1);
          });
        });
      }
    };
  }).name;
