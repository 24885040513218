'use strict';
const angular = require('angular');
import * as _ from 'lodash';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class GeneralEditModalController extends Modal {
  $setup() {
    'ngInject';
    this.originalItems = this.$props;
    this.items = [];
    _.forEach(this.originalItems.keys, key => {
      if (this.originalItems.object[key])
        this.items.push({
          key,
          value: this.originalItems.object[key],
          type: typeof this.originalItems.object[key]
        });
    });
  }

  submit() {
    _.forEach(this.items, item => {
      if (this.originalItems.object.hasOwnProperty(item.key))
        this.originalItems.object[item.key] = item.value;
    });
    this.$close();
  }
}

export default angular
  .module('app.generalEditModal', [])
  .directive('generalEditModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./general-edit.html'),
    controller: GeneralEditModalController,
    controllerAs: 'vm'
  })).name;
