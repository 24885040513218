'use strict';

import angular from 'angular';

import { State, Action, Mutation } from 'angular-store';
import { Controller, Inject, Watch } from '@/decorators/ngCtrl';

import Setup from './setup/setup.component';
import Evaluation from './choose-evaluation/choose-evaluation.component';
import CasePlan from './case-plan/case-plan.component';

class CmpSections {
  sections = [];

  get first() {
    return this.sections[0];
  }

  get last() {
    return this.sections[this.sections.length - 1];
  }

  section(title) {
    let sections = this.sections;

    this.sections.push({
      title,
      num: sections.length + 1,
      get prevSection() {
        return this.num > 1 ? sections[this.num - 2] : null;
      },
      get nextSection() {
        return this.num < sections.length ? sections[this.num] : null;
      }
    });

    return this;
  }
}

@Controller
class CaseManagementController {
  sections;
  activeSection;
  selectedClient;
  selectedEvaluation;
  selectedCmSection;
  interviewTimeHour;
  interviewTimeMinutes;
  isValid = false;

  @State clients;

  @Watch('clients.items', true)
  async loadClients(val) {
    return val;
  }

  $onInit() {
    let cmpSections = new CmpSections()
      .section('Setup')
      .section('Evaluation')
      .section('Case Plan');

    this.sections = cmpSections.sections;
    this.activeSection = cmpSections.first;
  }

  prevSection() {
    this.activeSection = this.activeSection.prevSection;
  }

  nextSection() {
    this.activeSection = this.activeSection.nextSection;
  }

  createCasePlan() {
    // Do create case plan stuff with data...
    this.$state.go('dashboardCaseManagement');
  }
}

export default angular
  .module('app.section.caseManagement', [Setup, Evaluation, CasePlan])
  .component('caseManagement', {
    template: require('./case-management.html'),
    controller: CaseManagementController,
    controllerAs: 'vm'
  }).name;
