'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class TermsAndConditionsModalController extends Modal {
  isOpera: any;
  isFirefox: any;
  isSafari: any;
  isIE: any;
  isEdge: any;
  isChrome: any;
  isBlink: any;

  @Inject $scope;

  agreed() {
    var agreed = true;

    this.$close(agreed);
  }
}

export default angular
  .module('app.termsAndConditionsModal', [])
  .directive('termsAndConditionsModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./terms-and-conditions.html'),
    controller: TermsAndConditionsModalController,
    controllerAs: 'vm'
  })).name;
