import { Component, Ref, State, Getter } from '@angular';
import { forEach, find, orderBy, filter } from 'lodash';

import { Institution } from '@interfaces/institution';
import { Policy } from '@interfaces/policy';
import { Modal } from '@mixins/modal.component';
import { RootGetters } from '@store/getters';
import { MeState } from '@store/modules/me';

interface InstitutionOption {
  id: Institution['id'];
  name: Institution['name'];
  policies: Policy[];
}

interface ManagedPolicyOption {
  id: string;
  name: string;
  description: string;
  statement: Array<any>;
}

// type SubmitOptions = { email: string } & ({ managedPolicyId: string} | { institutionPolicyId: string } | { inlinePolicy: Policy });

interface SubmitOptions {
  email: string;
  managedPolicyId?: string;
  institutionPolicyId?: string;
  inlinePolicy?: Policy;
}

@Component({
  name: 'inviteModal',
  template: require('./invite.html')
})
export class InviteModalController extends Modal {
  processing = false;
  institutions: InstitutionOption[] = [];
  managedPolicies: ManagedPolicyOption[] = [];
  managedPolicy: Policy | null = null;
  institutionPolicies: Policy[] = [];
  institutionPolicy: Policy | null = null;
  customPolicy: Policy | null = null;
  email: string = '';
  policyId: any = -1;
  selectedPolicy: Policy | null = null;
  institution: Institution | null = null;
  policyChoice: number = 2;
  submitted: boolean = false;
  step: number = 1;
  prevStep: number | null = null;

  @State readonly me!: MeState;
  @Getter readonly activeInstId!: RootGetters['activeInstId'];
  @Getter readonly isAdmin!: RootGetters['isAdmin'];

  onStepChange = async (newVal: number, oldVal: number) => {
    this.prevStep = oldVal;

    if (newVal === 2 && !this.activeInstId) {
      this.institutionPolicies =
        find(this.institutions, { id: this.institution!.id })?.policies ?? [];
    } else if (newVal == 3) {
      this.onPolicySelected();
    }
  };

  onPolicySelected = async () => {
    let policy: Policy | null = null;

    switch (this.policyChoice) {
      case 1:
        policy = this.managedPolicy;
        break;
      case 2:
        policy = this.institutionPolicy;
        break;
      case 3:
        policy = this.customPolicy;
        break;
    }

    this.selectedPolicy = policy;
  };

  onInstitution = () => {
    this.policyId = -1;
  };

  get hasInstitution() {
    return this.activeInstId !== null;
  }

  get nextValid() {
    let { email } = this.$forms.invite;

    if (this.step === 1) {
      return (
        email?.$valid &&
        ((this.isAdmin && !this.institution) || this.institution)
      );
    }

    if (this.step === 2) {
      return !!this.selectedPolicy;
    }

    if (this.step === 3) {
      return true;
    }

    return false;
  }

  get managedPoliciesOffer() {
    return this.institution
      ? filter(
          this.managedPolicies,
          (p) => p.id !== 'gifradmin' && p.id !== 'gifradminevaltracker'
        )
      : this.managedPolicies;
  }

  $setup() {
    this.$watch('step', this.onStepChange);
    this.$watch('institution', this.onInstitution);
    this.$watch('managedPolicy', this.onPolicySelected);
    this.$watch('institutionPolicy', this.onPolicySelected);
    this.$watch('customPolicy', this.onPolicySelected);
    this.$watch('policyChoice', this.onPolicySelected);

    this.load();
  }

  /**
   * ...
   */
  private async createInlinePolicy() {
    const options = this.customPolicy ?? {
      name: 'New User Policy',
      institutionId: this.institution?.id
    };

    const policy = await this.$modals.util.policyEditor(options);

    if (!policy) return;

    this.customPolicy = policy;
  }

  /**
   * ...
   */
  private async submitInvite() {
    const options: SubmitOptions = {
      email: this.email
    };

    switch (this.policyChoice) {
      case 1:
        options.managedPolicyId = this.managedPolicy!.id;
        break;
      case 2:
        options.institutionPolicyId = this.institutionPolicy!.id;
        break;
      case 3:
        options.inlinePolicy = this.customPolicy!;
        break;
    }

    const promise =
      !this.institution && this.isAdmin
        ? this.$api2.gm.inviteUser(options)
        : this.$api2.im.inviteUser({
            institutionId: this.institution!.id,
            ...options
          });

    let data: any = null;
    let error: Error | null = null;

    this.processing = true;

    try {
      data = await promise;
    } catch (err) {
      error = err;
    }

    this.processing = false;

    if (error) {
      return this.utils.notify(
        'error',
        error.message ? error.message : 'Error - Creating Invite'
      );
    }

    this.utils.notify('success', 'Invite Sent');

    this.$close(data);
  }

  /**
   * ...
   */
  private async load() {
    this.loadingData = true;

    if (this.activeInstId) {
      await this.loadAsInstitutionUser();
    } else {
      await this.loadAsSuperAdmin();
    }

    this.loadingData = false;

    this.$scope.$apply();
  }

  /**
   * ...
   */
  private async loadAsInstitutionUser() {
    this.institution = this.me.institution;

    this.managedPolicies =
      (await this.$api2.im.listManagedPolicies({
        institutionId: this.institution!.id
      })) ?? [];
    this.institutionPolicies =
      (await this.$api2.im.listPolicies({
        institutionId: this.institution!.id
      })) ?? [];
  }

  /**
   * ...
   */
  private async loadAsSuperAdmin() {
    this.managedPolicies = (await this.$api2.gm.listManagedPolicies()) ?? [];

    const policies = (await this.$api2.gm.listAllInstitutionPolicies()) ?? [];

    //
    await this.$store.dispatch('institutions/getAll');

    const institutions: InstitutionOption[] = Object.values(
      this.$store.state.institutions?.items
    ).map(({ id, name }) => ({ id, name, policies: [] }));

    for (const policy of policies) {
      const institution = find(institutions, {
        id: policy.institution.id
      });

      if (institution) {
        institution.policies.push(policy);
      } else {
        institutions.push({
          id: policy.institution.id,
          name: policy.institution.name,
          policies: [policy]
        });
      }
    }

    this.institutions = orderBy(institutions, ['name']);
  }
}

export default InviteModalController.$module;

// export default angular
//   .module('app.inviteModal', [])
//   .directive('inviteModal', () => ({
//     restrict: 'E',
//     replace: true,
//     template: require('./invite.html'),
//     controller: InviteModalController,
//     controllerAs: 'vm'
//   })).name;
