'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class YlsProfileReportChoicesModalController extends Modal {
  $setup() {
    this.options = [
      {
        text: 'Introduction',
        selected: true
      },
      {
        text: 'Narrative Report',
        selected: true
      },
      {
        text: 'General Notes for Evaluation',
        selected: true
      },
      {
        text: 'Overall Assessment Based on YLS/CMI 2.0 Total Risk/Need Level',
        selected: true
      },
      {
        text: 'Comparison to Young Offenders',
        selected: true
      },
      {
        text: 'Assessment of Risks and Needs',
        selected: true
      },
      {
        text: 'Profile Summary',
        selected: true
      },
      {
        text: 'Details Regarding Assessment of Risks and Needs',
        selected: true
      },
      {
        text: 'Assessment of Other Needs and Special Considerations',
        selected: true
      },
      {
        text: 'Professional Override',
        selected: true
      },
      {
        text: 'Contact Level',
        selected: true
      },
      {
        text: 'Case Review',
        selected: true
      }
    ];
  }

  submit() {
    this.$close(this.options);
  }
}

export default angular
  .module('app.ylsProfileReportChoicesModal', [])
  .directive('ylsProfileReportChoicesModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./yls-profile-report-choices.html'),
    controller: YlsProfileReportChoicesModalController,
    controllerAs: 'vm'
  })).name;
