// Hare PCL-R Profile Report Generator
import { find, forEach, maxBy } from 'lodash';
import angular from 'angular';
import harepclrPercentiles from './percentiles';
import harepclrProfileReportCoverPage from './coverPage.html';
import harepclrProfileReportTables from './tables';
import harepclrImages from './imageDataUrls';
import 'jspdf-autotable';
import JsPDF from 'jspdf';
import fontDataUrls from '../fontDataUrls';

export default {
  async generate(
    mhsLogoDataUrl,
    harePCLRLogo,
    pageSetup,
    services,
    client,
    evaluation,
    tool,
    options,
    $me
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let pageContentEnd = pageSetup.pageHeight - margins.bottom;
    let $http = services.$http;
    let $filter = services.$filter;
    let $templateCache = services.$templateCache;
    let $store = services.$store;
    let $auth = services.$auth;
    let Util = services.$util;
    let notify = services.notify;
    let Upload = services.Upload;
    let $reincode = services.$reincode;

    let parseOption = function (text) {
      // parse options from $me selection to see if it should be included in report
      return find(options, {
        text: text
      }).selected;
    };

    let checkEndOfPage = function (y) {
      return y >= pageHeight - margins.bottom - 100;
    };

    const assessmentDate = evaluation.assignedDate;
    const interviewDate = evaluation.evaluation?.interview?.dateTime || 'N/A';
    // console.log('evaluation at beginning: ', angular.copy(evaluation));
    let evaluator = evaluation.evaluator;
    let offenderHistoryId = evaluation.offenderHistoryId;
    let clientNormativeType =
      evaluation.evaluation?.additionalInformation?.clientNormativeType;
    if (evaluation.evaluation) evaluation = evaluation.evaluation;
    if (evaluation.evaluationData) evaluation = evaluation.evaluationData;

    if (!evaluator)
      evaluator =
        evaluation.evaluatorId === $me.id
          ? $me
          : find($store.state.users.items, {
              id: evaluation.evaluatorId
            });

    // console.log('Generating Hare PCL-R Profile Report...');
    // console.log('client: ', client);
    // console.log('evaluation: ', evaluation);
    // console.log('tool: ', tool);

    let anyItemOmitted = false;
    forEach(evaluation.data, (item) => {
      if (item.text === 'Omit' || item.text === 'omit') anyItemOmitted = true;
    });

    let riskCategories;
    forEach(tool.customRiskCategories, (crc) => {
      if (client.sex === crc.criteria?.sex) riskCategories = crc.categories;
    });

    let offenderHistory;
    // if client doesn't have offender history length, pull offender history
    if (!evaluation.offenderHistory && !client?.offenderHistory.length) {
      let clientOffenderHistory = await $http.get(
        `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history`
      );
      if (clientOffenderHistory)
        client.offenderHistory = clientOffenderHistory.data;
    }
    if (!evaluation.offenderHistory && client?.offenderHistory.length) {
      if (offenderHistoryId) {
        let evaluationOffenderHistory = await $http.get(
          `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history/${offenderHistoryId}`
        );
        offenderHistory =
          evaluationOffenderHistory.status === 200
            ? evaluationOffenderHistory.data.historyData
            : [];
      } else {
        let latestOffenderHistory = maxBy(client.offenderHistory, 'updatedAt');
        let evaluationOffenderHistory = await $http.get(
          `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history/${latestOffenderHistory.id}`
        );
        offenderHistory =
          evaluationOffenderHistory.status === 200
            ? evaluationOffenderHistory.data.historyData
            : [];
      }
    } else {
      offenderHistory = evaluation.offenderHistory;
    }

    $templateCache.put(
      'harepclrProfileReportCoverPage.html',
      harepclrProfileReportCoverPage
    );
    let harepclrPRCoverPage = $templateCache.get(
      'harepclrProfileReportCoverPage.html'
    );
    let harepclrPRCoverPageTable = JSON.stringify(
      harepclrProfileReportTables.coverPage()
    );

    // create variable map of known variables in report
    let variableMap = {};
    let key;
    const clientAge = client.age;
    variableMap[`clientAge`] = clientAge;
    const clientLocation = client.location;
    variableMap[`clientLocation`] = clientLocation;
    let clientAssessmentLocation = '';
    if (evaluation.clientZoneName)
      clientAssessmentLocation += `${evaluation.clientZoneName}`;
    if (evaluation.clientRegionName)
      clientAssessmentLocation += ` > ${evaluation.clientRegionName}`;
    if (evaluation.clientSubGroupName)
      clientAssessmentLocation += ` > ${evaluation.clientSubGroupName}`;
    if (clientAssessmentLocation.length == 0)
      clientAssessmentLocation = 'LOCATION NOT FOUND';
    variableMap[`clientAssessmentLocation`] = clientAssessmentLocation;
    for (key in client) {
      if (key === 'type') {
        let typeList = '';
        if (client.type) {
          let types =
            typeof client.type === 'string'
              ? client.type.split(',')
              : client.type;
          if (types.length === 1) {
            typeList = types[0];
          } else {
            forEach(types, (type, index) => {
              if (index === types.length - 1) {
                typeList += `and ${type}`;
              } else {
                typeList += `${type}, `;
              }
            });
          }
        } else {
          typeList = 'No Client Types Provided';
        }
        variableMap[`client.${key}`] = typeList;
      } else if (key === 'fName' || key === 'lName' || key === 'localId') {
        variableMap[`client.${key}`] = client[key] ? client[key] : 'N/A';
      } else {
        variableMap[`client.${key}`] = client[key]
          ? Util.decamlize(client[key])
          : 'N/A';
      }
    }

    // handle gender exclusively
    if (!client.gender) variableMap['client.gender'] = '-';

    variableMap['date.today'] = $filter('dynamicDate')(new Date(), 'longDate');
    variableMap['clientNormativeType'] = clientNormativeType;

    for (key in evaluator) {
      if (key === 'fName' || key === 'lName') {
        variableMap[`user.${key}`] = evaluator[key];
      } else {
        variableMap[`user.${key}`] = Util.decamlize(evaluator[key]);
      }
    }
    variableMap['assessmentDate'] = $filter('dynamicDate')(
      new Date(assessmentDate),
      'longDate'
    );
    variableMap['interviewDate'] = $filter('dynamicDate')(
      new Date(interviewDate),
      'longDate'
    );

    //================ CREATE VARIABLE MAP =================
    let variableRegEx = Object.keys(variableMap).join('|');
    variableRegEx = variableRegEx.replace(/\[/g, '\\[');
    variableRegEx = variableRegEx.replace(/]/g, '\\]');
    variableRegEx = variableRegEx.replace(/\)/g, '\\)');
    variableRegEx = variableRegEx.replace(/\(/g, '\\(');
    variableRegEx = variableRegEx.replace(/#/g, '\\#');
    variableRegEx = variableRegEx.replace(/\+/g, '\\+');
    variableRegEx = new RegExp(variableRegEx, 'gi');

    harepclrPRCoverPage = harepclrPRCoverPage.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    harepclrPRCoverPageTable = harepclrPRCoverPageTable.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    harepclrPRCoverPageTable = JSON.parse(harepclrPRCoverPageTable);
    //================ END CREATE VARIABLE MAP =================
    //=================== PDF DOC SETUP ==================
    let specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    let pdf = new JsPDF('p', 'pt', 'letter');

    // set comfortaa font
    pdf.addFileToVFS('ComfortaaRegular.tff', fontDataUrls.comfortaaRegular());
    pdf.addFont('ComfortaaRegular.tff', 'Comfortaa', 'normal');
    pdf.addFileToVFS('ComfortaaBold.tff', fontDataUrls.comfortaaBold());
    pdf.addFont('ComfortaaBold.tff', 'Comfortaa', 'bold');
    pdf.setFont('Comfortaa');
    let page = 2;

    let header = function () {
      pdf.setFontSize(11);
      pdf.setFontStyle('normal');
      pdf.text(`Profile Report: ${client.name()}`, margins.left, 21);
      pdf.text(`Page ${page}`, pageWidth - margins.left - 50, 21);
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(margins.left, 25, pageWidth - margins.left, 25);
      page = page + 1;
    };

    let footer = function () {
      pdf.addImage(
        mhsLogoDataUrl,
        'JPEG',
        pageWidth - margins.left - 80,
        pageHeight - margins.bottom,
        80,
        35
      );
    };
    //=================== END PDF DOC SETUP ==================
    // ======================COVER PAGE===============================
    pdf.addImage(harePCLRLogo, 'JPEG', margins.left, margins.top, 356, 102);

    pdf.fromHTML(
      harepclrPRCoverPage,
      margins.left,
      margins.top + 100,
      {
        width: margins.width,
        elementHandlers: specialElementHandlers
      },
      function (dispose) {
        // add cover page table with client info
        pdf.autoTable({
          head: harepclrPRCoverPageTable.head,
          body: harepclrPRCoverPageTable.body,
          columnStyles: harepclrPRCoverPageTable.columnStyles,
          startY: 300,
          theme: 'striped',
          headStyles: {
            fillColor: [84, 9, 5],
            textColor: [255, 255, 255]
          }
        });

        // add MHS logo and copyright info
        pdf.setFontSize(9);
        pdf.addImage(mhsLogoDataUrl, 'JPEG', margins.left, 680, 125, 54);
        pdf.text(
          'PCL-R™: 2nd Ed. Copyright © 2003, 2019 Multi-Health Systems Inc. All rights reserved. \n' +
            'PCL-R 2nd Ed. Normative data © 2003 Multi-Health Systems Inc.\n' +
            'All rights reserved.\n' +
            'P.O. Box 950, North Tonawanda, NY 14120-0950\n' +
            '3770 Victoria Park Ave., Toronto, ON M2H 3M6',
          margins.left + 135,
          705
        );

        // ==========================END COVER PAGE==============================
        // ========================== PARSE EVALUATION FOR REPORT ====================
        pdf.addPage();
        header();
        footer();
        let y = margins.top + 10;

        // logic for creating new line or new page if needbe
        let newLine = function (y) {
          if (y + 12 > pageHeight - (margins.top + margins.bottom)) {
            pdf.addPage();
            header();
            footer();
            y = margins.top + 20;
          } else {
            y += 12;
          }
          return y;
        };

        let addText = function (
          text,
          fontSize = 10,
          fontStyle = 'normal',
          align = 'left',
          x
        ) {
          if (!text) {
            console.error('Text parameter must be provided');
            return;
          }
          text = $reincode.text(text);
          if (fontSize > 10 && fontSize <= 14) y += 15;
          if (fontSize > 14 && fontSize < 18) y += 20;

          pdf.setFontSize(fontSize);
          pdf.setFontStyle(fontStyle);

          if (!text?.length || typeof text !== 'string') return;
          let lines = pdf.splitTextToSize(text, margins.width);

          forEach(lines, (line) => {
            pdf.setFontSize(fontSize);
            pdf.setFontStyle(fontStyle);
            pdf.text(line, x ? x : margins.left, y, align);
            y = newLine(y);
          });

          y = newLine(y);
        };

        let sectionBuffer = function () {
          if (y + 25 >= pageContentEnd) {
            newPage();
          } else {
            y += 25;
          }
        };

        let checkSectionStart = function (yVal = 40) {
          if (y + yVal >= pageContentEnd) newPage();
        };

        let addImage = function (imageUrl, type = 'JPEG', x, y, w, h) {
          if (y + h >= pageContentEnd) newPage();
          pdf.addImage(imageUrl, type, x, y, w, h);
          if ((y += h + 10 >= pageContentEnd)) {
            newPage();
          } else {
            y += h + 10;
          }
        };

        let newPage = function (pageAlreadyAdded) {
          if (y === margins.top + 10) return;
          if (!pageAlreadyAdded) pdf.addPage();
          header();
          footer();
          y = margins.top + 10;
        };

        let getFRiskFactor = function (val) {
          if (val <= 40) return 'Low';
          if (val > 40 && val <= 59) return 'Moderate';
          if (val >= 60) return 'High';
        };

        let makeBarGraph = function (minValue, maxValue, ticks, labels, value) {
          // if minValue is not 0, everything needs to be shifted that many increments left
          let shiftVal = minValue;
          let steps = maxValue - minValue;
          let totalHeight = 30;
          let totalWidth = pageWidth / 2;
          let lineHeight = 10;
          let lineWidth = totalWidth * ((value - shiftVal) / steps);
          let increments = totalWidth / steps;
          if (y + totalHeight + 25 > pageContentEnd) newPage();
          // draw bounds and line
          pdf.setDrawColor(0, 0, 0);
          pdf.setLineWidth(1);
          pdf.rect(margins.left, y, totalWidth, totalHeight);
          pdf.setDrawColor(71, 71, 71);
          pdf.setFontSize(10);
          pdf.setLineWidth(1);
          // write zone titles
          forEach(labels, (label) => {
            pdf.text(
              label.label.toString(),
              margins.left + increments * (label.yLoc - shiftVal),
              y + totalHeight + 20,
              { align: label.align ? label.align : 'left' }
            );
          });
          // write ticks
          forEach(ticks, (tick) => {
            pdf.text(
              tick.toString(),
              margins.left + increments * (tick - shiftVal) - 5,
              y + totalHeight + 10
            );
            pdf.line(
              margins.left + increments * (tick - shiftVal),
              y,
              margins.left + increments * (tick - shiftVal),
              y + totalHeight
            );
          });
          // draw line
          pdf.setDrawColor(255, 0, 0);
          pdf.setLineWidth(lineHeight);
          pdf.line(
            margins.left,
            y + totalHeight / 2,
            margins.left + lineWidth,
            y + totalHeight / 2
          );
          // draw threshold
          pdf.setDrawColor(50, 50, 50);
          pdf.setLineWidth(2);
          let xLeft = margins.left + lineWidth - increments * 2;
          let yLeft = margins.left + lineWidth + increments * 2;
          pdf.line(xLeft, y + totalHeight / 2, yLeft, y + totalHeight / 2);
          pdf.line(
            xLeft,
            y + totalHeight / 2 - 10,
            xLeft,
            y + totalHeight / 2 + 10
          );
          pdf.line(
            yLeft,
            y + totalHeight / 2 - 10,
            yLeft,
            y + totalHeight / 2 + 10
          );
          pdf.setLineWidth(1);
          if (y + totalHeight + 30 >= pageContentEnd) {
            newPage();
          } else {
            y += totalHeight + 30;
          }
        };

        //region INTRODUCTION
        if (parseOption('Introduction')) {
          sectionBuffer();
          addText(`Introduction`, 14, 'bold');
          addText(
            'This Profile Report is designed to facilitate the scoring and interpretation of the Hare Psychopathy Checklist–Revised (PCL–R): 2nd Edition. Its use is predicated on the assumptions that the user: (1) is professionally and legally qualified to perform psychological assessments; (2) is aware of, and adheres to, the guidelines for Uses and Users described in chapter 2 of the 2nd Edition of the PCL–R Technical Manual (Hare, 2003); (3) is familiar with the research and clinical literature on psychopathy; (4) has authorized access to, and properly uses the scoring criteria for the PCL–R, described in the 1st (Hare, 1991) or the 2nd (Hare, 2003) Edition of the PCL–R Technical Manual.'
          );
          addText(
            'This Profile Report provides a set of T-scores and percentile ranks for a given individual based on the appropriate comparison groups as well as summary descriptions of the meaning of several levels of scores and ranks. However, it is the responsibility of the user to decide on how best to interpret and use the information—keeping in mind the literature on psychopathy, the population involved (e.g., offender, forensic psychiatric, sex of individual, etc.), the context in which the scores are used (e.g., treatment options, conditional release, risk assessment, research, etc.), their integration with other relevant information and variables, assessment result implications for the individual and for society, and the potential for misuse of the PCL–R. These and related issues are discussed in the 2nd Edition Technical Manual, as well as in a number of published articles (see www.hare.org).'
          );
          addText(
            'The format for the Profile Report follows the structural model for the PCL–R (see Figure 1) described in the 2nd Edition Technical Manual. The Profile Report format includes the Total Score, Factor 1 and Factor 2 scores, and scores for Facet 1 (Interpersonal), Facet 2 (Affective), Facet 3 (Lifestyle), and Facet 4 (Antisocial). In some cases, a user may prefer to work only with PCL–R Total Scores as a reliable measure of the construct of psychopathy. Others may wish to supplement the Total Scores with information about the clusters of items (Factors, Facets) that contribute to the Total Score. In some cases, two such clusters (Factor 1 and Factor 2) may suffice, whereas in others the user may wish to conduct a finer descriptive or configural analysis by inspecting the pattern of Facet scores.'
          );
          addText('Figure 1: Scale Structure of the PCL-R 2nd Edition');
          addImage(harepclrImages.figure1(), 'JPEG', margins.left, y, 400, 246);
          addText(
            'Some users may prefer to work with raw scores, others with T-scores or percentile ranks. Still others may wish to avoid using the label "psychopathy" in reporting their assessments. For this reason, Table 2.1 in the 2nd Edition Technical Manual lists a possible descriptive scheme in which the raw Total Scores are grouped into five levels. The research and clinical implications of a score that falls into a given level would of course depend on the context and purpose of the PCL–R assessment, the comparison group used, and the relevant empirical literature (described in the 2nd Edition Technical Manual). Other descriptive schemes are of course possible.'
          );
          addText(
            'For convenience, and in accord with the research literature, the Profile Report provides general descriptors for three levels of Total, Factor, and Facet Scores: Low, Moderate, and High. These descriptors may function as convenient “anchors” to aid in organizing and interpreting PCL–R scores. The user should keep in mind the standard error of measurement of the scores, as well as the fact that an individual at the upper- or lower-end of a category may differ little from one with a slightly different score in an adjacent category. The high and low levels reflect scores that are approximately 1 standard deviation (SD) above the mean (high) or below the mean (low) for a given comparison group. Although the use of + 1 SD to define the high and low levels provides reasonable and useful descriptive separation among scores, there is nothing absolute about them, and other cut points are possible. Also note that any score includes measurement error, as described in the 2nd Edition Technical Manual. To facilitate interpretation of a given score, standard error of measurement (SEM) bars are depicted along with the T-scores. T-scores have a mean of 50 and a SD of 10. A High level thus is defined by a T-score of 60 or above, and a low level by a T-score of 40 or below. A moderate level is defined by a T-score between 41 and 59.'
          );
          addText(
            'The PCL–R takes a prototypical approach to assessment of psychopathy and the items that reflect it. That is, diagnostic criteria and descriptive statements represent the upper ends (prototypes) of Items, Facets, Factors, and Total Scores. Moderate and Low levels indicate progressively greater deviations from the relevant prototype.'
          );
        }
        //endregion Introduction
        //region Total Score
        if (parseOption('Total Score')) {
          // sectionBuffer();
          newPage();
          addText(`Total Score`, 14, 'bold');
          addText(
            `The PCL–R Total Score is the sum of all 20 items, each of which is scored 0, 1, or 2. Note that two items not included in Factor or Facet scores (Item 11, Promiscuous Sexual Behavior; Item 17, Many Short-Term Marital Relationships) contribute to the Total Score, which can range from 0 to 40 (consult the PCL–R 2nd Edition Technical Manual for specific reliability data). The Total Score is dimensional and may be seen as a reflection of the individual’s “dosage” of psychopathic features, or of the extent to which he or she is judged to match the “prototypical psychopath.” The higher the score, the closer the match.`
          );
          addText(
            `The descriptive statements provided in this report are simply guides for the user. They do not replace the user’s clinical, forensic, or research expertise or the user's professional judgement. Because a PCL–R Total Score can arise from different combinations of Item, Factor, and Facet scores, the user may find it informative to inspect the patterns (“profile”) of relative scores on Factors 1 and 2 and Facets 1–4 (see below). The Factor and Facet scores are more reliable than individual item scores, and interpretations of the latter should be done cautiously, if at all.`
          );
          addText(
            `Total Raw Score = ${
              anyItemOmitted
                ? evaluation.tableProrates?.totalScore
                : evaluation.rawScore
            }`
          );
          addText(
            `T-Score = ${evaluation.dynamicScoringTables.totalScoreTScore}`
          );
          addText(
            `Percentile = ${harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.totalScore,
              'total',
              clientNormativeType
            )}`
          );
          makeBarGraph(
            0,
            40,
            [0, 10, 20, 30, 40],
            [
              {
                label: 'Very Low',
                yLoc: 5,
                align: 'center'
              },
              {
                label: 'Low',
                yLoc: 12,
                align: 'center'
              },
              {
                label: 'Moderate',
                yLoc: 20,
                align: 'center'
              },
              {
                label: 'High',
                yLoc: 28,
                align: 'center'
              },
              {
                label: 'Very High',
                yLoc: 35,
                align: 'center'
              }
            ],
            evaluation.rawScore
          );
          checkSectionStart(60);
          if (evaluation.riskCategory === 'Low') {
            addText(`Low`, 12, 'bold');
            addText(
              `A Low Total Score suggests that ${client.name()} has few of the features that define psychopathy. ${client.name()} may exhibit, therefore, features (normal or abnormal) that are inconsistent with the construct of psychopathy. Note, however, that there may be other problems and characteristics of this individual other than psychopathy that may be a concern to the user.`
            );
          } else if (evaluation.riskCategory === 'Moderate') {
            addText(`Moderate`, 12, 'bold');
            addText(
              `A Moderate Total Score suggests that ${client.name()} has some of the features of psychopathy, as defined by the PCL–R. Compared with scores that fall in the High level, the implications of a score in this level (particularly in its lower range) will depend on other information about the individual, including socioeconomic, educational, work-related, and psychological/psychiatric issues and matters, as well as on the pattern of scores obtained on the two Factors and the four Facets.`
            );
          } else if (evaluation.riskCategory === 'High') {
            addText(`High`, 12, 'bold');
            addText(
              `A Total Score that falls in the high range suggests that ${client.name()} has most of the features of psychopathy, as defined by the PCL–R. Persons of this sort are likely to be egocentric, callous, "cold-blooded," predatory, impulsive, irresponsible, dominant, deceptive, manipulative, and lacking in empathy, guilt, or genuine remorse for socially deviant and criminal acts. They do not share the attitudes, thoughts, and feelings that motivate and guide the behaviors of most people. Their main concerns are for themselves and for exerting power and control over others. They are quite capable of using intimidation and violence to attain their needs and wants, but their actions are “a matter of process,” without the emotional coloring that characterizes the aggressive and violent acts of others. These characteristics are systemic and not confined to specific aspects or domains of an individual’s life (e.g., work, recreation, family, friends, etc.).`
            );
          }
        }
        //endregion Total Score
        //region Factor Scores
        if (parseOption('Factor Scores')) {
          // sectionBuffer();
          newPage();
          addText(`Factor Scores`, 14, 'bold');
          addText(
            `Factor 1 and Factor 2 consist of 8 and 10 items, respectively. Factor 1 is identical to the original Factor 1 described in the 1991 Edition (Hare, 1991), and consists of the Facet 1 and Facet 2 items. Factor 2 consists of the items in Facet 3 and Facet 4 (the 9 original Factor 2 items, plus Item 20, Criminal Versatility).`
          );
          addText(
            `Factor scores are more reliable and have a greater range of values than Facet scores. There is a large amount of literature on the correlates of Factor 1 and Factor 2 scores, and some users may find it helpful to describe an individual in terms of these broad clusters of items. However, except in the very high or very low range of values, the meaning of a Factor score will depend on the relative contribution of its two constituent Facets. Although the two Facets that make up a given Factor are highly correlated, Factor scores in the mid-range can result from various combinations of the Facet scores. For example, a moderate Factor 1 score could be the result of a moderate score on the Interpersonal and the Affective Facets, a high Interpersonal and a low Affective score, or a low Interpersonal and a high Affective score. For this reason, descriptive statements are not provided for Factor scores. Interpretation of Factor scores should be made in conjunction with inspection of the Facet scores.`
          );
          checkSectionStart(60);
          addText(`Factor 1`, 12, 'bold');
          addText(
            `T-Score = ${evaluation.dynamicScoringTables?.factor1TScore}`
          );
          addText(
            `Raw Score = ${
              anyItemOmitted
                ? evaluation.tableProrates?.factor1Prorated
                : evaluation.groupings?.factor1?.score
            }`
          );
          addText(
            `Percentile = ${harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.factor1Prorated,
              'factor1',
              clientNormativeType
            )}`
          );
          makeBarGraph(
            20,
            80,
            [20, 40, 60, 80],
            [
              {
                label: 'Low',
                yLoc: 25
              },
              {
                label: 'Moderate',
                yLoc: 45
              },
              {
                label: 'High',
                yLoc: 70
              }
            ],
            evaluation.dynamicScoringTables?.factor1TScore
          );
          checkSectionStart(60);
          addText(`Factor 2`, 12, 'bold');
          addText(
            `T-Score = ${evaluation.dynamicScoringTables?.factor2TScore}`
          );
          addText(
            `Raw Score = ${
              anyItemOmitted
                ? evaluation.tableProrates?.factor2Prorated
                : evaluation.groupings?.factor2?.score
            }`
          );
          addText(
            `Percentile = ${harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.factor2Prorated,
              'factor2',
              clientNormativeType
            )}`
          );
          makeBarGraph(
            20,
            80,
            [20, 40, 60, 80],
            [
              {
                label: 'Low',
                yLoc: 25
              },
              {
                label: 'Moderate',
                yLoc: 45
              },
              {
                label: 'High',
                yLoc: 70
              }
            ],
            evaluation.dynamicScoringTables?.factor2TScore
          );
        }
        //endregion Factor Scores
        //region Facet Scores
        if (parseOption('Facet Scores')) {
          // sectionBuffer();
          newPage();
          addText(`Facet Scores`, 14, 'bold');
          addText(
            `Facet scores are based on fewer items and have a more restricted range than is the case with Total Scores. Facets 1 and 2 have four items each (scores range from 0–8), while Facets 3 and 4 each have five items (scores range from 0–10). The user should keep this in mind when interpreting individual Facet scores or patterns of Facet scores.`
          );
          //FACET 1
          checkSectionStart(60);
          addText(`Facet 1: Interpersonal`, 12, 'bold');
          addText(`T-Score = ${evaluation.dynamicScoringTables?.facet1TScore}`);
          addText(
            `Raw Score = ${
              anyItemOmitted
                ? evaluation.tableProrates?.facet1Prorated
                : evaluation.groupings?.facet1?.score
            }`
          );
          addText(
            `Percentile = ${harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.facet1Prorated,
              'facet1',
              clientNormativeType
            )}`
          );
          makeBarGraph(
            20,
            80,
            [20, 40, 60, 80],
            [
              {
                label: 'Low',
                yLoc: 25
              },
              {
                label: 'Moderate',
                yLoc: 45
              },
              {
                label: 'High',
                yLoc: 70
              }
            ],
            evaluation.dynamicScoringTables?.facet1TScore
          );
          let facet1RiskCategory = getFRiskFactor(
            parseInt(evaluation.dynamicScoringTables?.facet1TScore, 10)
          );
          checkSectionStart(60);
          if (facet1RiskCategory === 'Low') {
            addText(`Low`, 12, 'bold');
            addText(
              `A Facet score in the Low range suggests that ${client.name()} has few of the interpersonal features of psychopathy. ${client.name()} may exhibit interpersonal features (normal or abnormal) that are inconsistent with the construct of psychopathy. A Low score suggests that ${client.name()}’s interactions with others are not superficial, manipulative, or exploitative.`
            );
          } else if (facet1RiskCategory === 'Moderate') {
            addText(`Moderate`, 12, 'bold');
            addText(
              `A Facet score in the Moderate range suggests that ${client.name()} has some (lower-end) or many (upper-end) of the interpersonal features of psychopathy. Interactions with others may sometimes tend to be relatively superficial, manipulative, or exploitative, but not to the extent exhibited by those with high scores.`
            );
          } else if (facet1RiskCategory === 'High') {
            addText(`High`, 12, 'bold');
            addText(
              `A high Facet 1 score suggests that ${client.name()} has many or most of the interpersonal features of psychopathy, particularly if the score is at the upper-end of the range. Persons in this range tend to take a dominant, controlling, and aggressive stance during interactions with others. They tend to be grandiose, egocentric, manipulative, deceptive, conning, and perhaps charming in a superficial way. They view themselves as the center of the universe and have a well-established sense of entitlement. ${client.name()} may be a smooth-talking con artist or a controlling, belligerent, or condescending adversary given to playing “head games.” In any case, what ${client.name()} reports should not be taken at face value without considerable corroborating evidence. Indeed, it is likely that a person who scores high on this Facet is deceptive, manipulative, and exploitative, and has conned and deceived many other people, resulting in opinions and accounts of the individual that may vary greatly from one another (e.g., some glowing`
            );
          }
          //FACET 2
          checkSectionStart(60);
          addText(`Facet 2: Affective`, 12, 'bold');
          addText(`T-Score = ${evaluation.dynamicScoringTables?.facet2TScore}`);
          addText(
            `Raw Score = ${
              anyItemOmitted
                ? evaluation.tableProrates?.facet2Prorated
                : evaluation.groupings?.facet2?.score
            }`
          );
          addText(
            `Percentile = ${harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.facet2Prorated,
              'facet2',
              clientNormativeType
            )}`
          );
          makeBarGraph(
            20,
            80,
            [20, 40, 60, 80],
            [
              {
                label: 'Low',
                yLoc: 25
              },
              {
                label: 'Moderate',
                yLoc: 45
              },
              {
                label: 'High',
                yLoc: 70
              }
            ],
            evaluation.dynamicScoringTables?.facet2TScore
          );
          let facet2RiskCategory = getFRiskFactor(
            parseInt(evaluation.dynamicScoringTables?.facet2TScore, 10)
          );
          checkSectionStart(60);
          if (facet2RiskCategory === 'Low') {
            addText(`Low`, 12, 'bold');
            addText(
              `A Facet score in the Low range suggests that ${client.name()} has few of the affective features of psychopathy. ${client.name()} may exhibit emotional features (normal or abnormal) that are inconsistent with the construct of psychopathy. A Low score suggests that ${client.name()} appreciates and cares what others think or feel.`
            );
          } else if (facet2RiskCategory === 'Moderate') {
            addText(`Moderate`, 12, 'bold');
            addText(
              `A Facet score in the Moderate range suggests that ${client.name()} has some (lower-end) or many (upper-end) of the affective features of psychopathy. Emotions and the social and emotional connections with others may tend to be somewhat shallow, self-serving, and impaired, but not to the extent exhibited by those with High scores.`
            );
          } else if (facet2RiskCategory === 'High') {
            addText(`High`, 12, 'bold');
            addText(
              `A high Facet 2 score suggests that ${client.name()} has many or most of the affective features of psychopathy, particularly if the score is at the upper-end of the range. Persons in this range tend to have an emotional life that is shallow and relatively barren of normal deep-seated feelings, with little or no concern for the feelings and welfare of others, except in an abstract, intellectual sense. Their emotional reactions tend to be primitive and short-lived, and typically occur in response to specific conditions or events, such as frustration, anger, an argument, a challenge or defiance by others, an obstacle, and so forth. They have little capacity for, or convincing expressions of, empathy, guilt, or remorse. Their social and emotional connections with others are weak and self-serving. A high score suggests that the person is relatively free from the subjective distress, worry, and apprehensions experienced by others and neither appreciates nor cares what others think or feel.`
            );
          }
          //FACET 3
          checkSectionStart(60);
          addText(`Facet 3: Lifestyle`, 12, 'bold');
          addText(`T-Score = ${evaluation.dynamicScoringTables?.facet3TScore}`);
          addText(
            `Raw Score = ${
              anyItemOmitted
                ? evaluation.tableProrates?.facet3Prorated
                : evaluation.groupings?.facet3?.score
            }`
          );
          addText(
            `Percentile = ${harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.facet3Prorated,
              'facet3',
              clientNormativeType
            )}`
          );
          makeBarGraph(
            20,
            80,
            [20, 40, 60, 80],
            [
              {
                label: 'Low',
                yLoc: 25
              },
              {
                label: 'Moderate',
                yLoc: 45
              },
              {
                label: 'High',
                yLoc: 70
              }
            ],
            evaluation.dynamicScoringTables?.facet3TScore
          );
          let facet3RiskCategory = getFRiskFactor(
            parseInt(evaluation.dynamicScoringTables?.facet3TScore, 10)
          );
          checkSectionStart(60);
          if (facet3RiskCategory === 'Low') {
            addText(`Low`, 12, 'bold');
            addText(
              `A Facet score in the Low range suggests that ${client.name()} has few of the lifestyle features of psychopathy. ${client.name()} may exhibit lifestyle features (normal or abnormal) that are inconsistent with the construct of psychopathy. A Low score suggests that ${client.name()} has a relatively stable lifestyle and is sensitive to social norms and expectations.`
            );
          } else if (facet3RiskCategory === 'Moderate') {
            addText(`Moderate`, 12, 'bold');
            addText(
              `A Facet score in the Moderate range suggests that ${client.name()} has some (lower-end) or many (upper-end) of the lifestyle features of psychopathy. Some aspects of the lifestyle may be somewhat impulsive, sensation-seeking, or otherwise unstable, but not to the extent exhibited by those with High scores.`
            );
          } else if (facet3RiskCategory === 'High') {
            addText(`High`, 12, 'bold');
            addText(
              `A high Facet 3 score suggests that ${client.name()} has many or most of the lifestyle features of psychopathy, particularly if the score is at the upper-end of the range. Persons in this range tend to have an impulsive, nomadic, or parasitic lifestyle and to live for the moment. They are likely to be relatively unconcerned about what has happened or might happen, may get bored easily, are unlikely to remain long in relationships, places, or jobs, and continually are searching for new experiences and sensations. People, ideas, and causes are important only as long as they can provide some benefit, and then are casually and quickly abandoned when they no longer are needed. Social norms and expectations are irrelevant and readily violated.`
            );
          }
          //FACET 4
          checkSectionStart(60);
          addText(`Facet 4: Antisocial`, 12, 'bold');
          addText(`T-Score = ${evaluation.dynamicScoringTables?.facet4TScore}`);
          addText(
            `Raw Score = ${
              anyItemOmitted
                ? evaluation.tableProrates?.facet4Prorated
                : evaluation.groupings?.facet4?.score
            }`
          );
          addText(
            `Percentile = ${harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.facet4Prorated,
              'facet4',
              clientNormativeType
            )}`
          );
          makeBarGraph(
            20,
            80,
            [20, 40, 60, 80],
            [
              {
                label: 'Low',
                yLoc: 25
              },
              {
                label: 'Moderate',
                yLoc: 45
              },
              {
                label: 'High',
                yLoc: 70
              }
            ],
            evaluation.dynamicScoringTables?.facet4TScore
          );
          let facet4RiskCategory = getFRiskFactor(
            parseInt(evaluation.dynamicScoringTables?.facet4TScore, 10)
          );
          checkSectionStart(50);
          if (facet4RiskCategory === 'Low') {
            addText(`Low`, 12, 'bold');
            addText(
              `A Facet score in the Low range suggests that ${client.name()}  has few of the antisocial features of psychopathy. The person may exhibit behaviors (normal or abnormal) that are inconsistent with the construct of psychopathy. A Low score suggests that ${client.name()}  has a more prosocial lifestyle than do those in the other levels.`
            );
          } else if (facet4RiskCategory === 'Moderate') {
            addText(`Moderate`, 12, 'bold');
            addText(
              `A Facet score in the Moderate range suggests that ${client.name()}  has some (lower-end) or many (upper-end) of the antisocial features of psychopathy. The antisocial attitudes and behaviors may be problematic for others, but not to the extent exhibited by those with High scores.`
            );
          } else if (facet4RiskCategory === 'High') {
            addText(`High`, 12, 'bold');
            addText(
              `A high Facet 4 score suggests that ${client.name()}  has many or most of the antisocial features of psychopathy, particularly if the score is at the upper-end of the range. Persons in this range tend to have a varied and persistent antisocial lifestyle, with frequent and serious violations of social and legal expectations and standards from an early age. They may be easily offended, short-tempered, and aggressive, and prone to engage in a wide variety of antisocial and illegal activities.`
            );
          }
        }
        //endregion Facet Score
        //region Profile for Normative Type
        if (parseOption('Profile for Normative Type')) {
          newPage();
          addText(`Profile for ${clientNormativeType}`, 14, 'bold');
          pdf.setFontStyle('normal');
          pdf.setFontSize(10);
          let makeTScoreGraph = function (values) {
            pdf.setFontSize(10);
            let totalWidth = pageWidth / 2;
            let totalHeight = totalWidth - 50;
            if (y + totalWidth >= pageContentEnd) newPage();
            // make Y axis
            pdf.text(
              'T-Score',
              margins.left,
              y + totalHeight / 2,
              undefined,
              90
            );
            // make number breakdowns
            let ySteps = 60;
            let stepSize = totalHeight / ySteps;
            let minValue = 20;
            let maxValue = 80;
            pdf.setDrawColor(71, 71, 71);
            pdf.setLineWidth(1);
            for (let i = 0; i < ySteps; i++) {
              let yLoc = y + totalHeight - stepSize * i;
              if (i === 0) {
                pdf.text(`20`, margins.left + 10, yLoc + 3);
                pdf.line(
                  margins.left + 30,
                  yLoc,
                  margins.left + totalWidth,
                  yLoc
                );
              } else if (i % 10 === 0) {
                pdf.text(`${i + 20}`, margins.left + 10, yLoc + 3);
                pdf.line(
                  margins.left + 30,
                  yLoc,
                  margins.left + totalWidth,
                  yLoc
                );
              } else if (i % 5 === 0) {
                pdf.line(margins.left + 30, yLoc, margins.left + 40, yLoc);
              } else if (i === 59) {
                pdf.text(`80+`, margins.left + 10, yLoc + 3);
                pdf.line(
                  margins.left + 30,
                  yLoc,
                  margins.left + totalWidth,
                  yLoc
                );
              }
            }
            // make X axis
            let xSteps = totalWidth / values.length;
            let previousPlotPoint = {};
            let currentPlotPoint = {};
            let xMargin = 60;
            forEach(values, (val, i) => {
              // label
              let xLoc = xSteps * i + 1;
              pdf.text(
                `${val.label}`,
                xMargin + margins.left + xLoc - val.label.length,
                y + totalHeight + 10,
                undefined,
                -20
              );
              pdf.setDrawColor(173, 173, 173);
              pdf.line(
                xMargin + margins.left + xLoc,
                y + totalHeight,
                xMargin + margins.left + xLoc,
                y
              );
              // plot point
              currentPlotPoint.x = xMargin + margins.left + xLoc;
              currentPlotPoint.y =
                y + totalHeight - stepSize * (val.value - 20);
              pdf.setDrawColor(168, 50, 115);
              pdf.circle(currentPlotPoint.x, currentPlotPoint.y, 2);
              // line connection
              if (previousPlotPoint.x) {
                pdf.setDrawColor(155, 0, 0);
                pdf.line(
                  previousPlotPoint.x,
                  previousPlotPoint.y,
                  currentPlotPoint.x,
                  currentPlotPoint.y
                );
              }
              previousPlotPoint.x = xMargin + margins.left + xLoc;
              previousPlotPoint.y =
                y + totalHeight - stepSize * (val.value - 20);
            });
            if (y + totalHeight + 60 >= pageContentEnd) {
              newPage();
            } else {
              y += totalHeight + 60;
            }
          };
          // Factor 1, 2 and Total Score T-Score Graph
          makeTScoreGraph([
            {
              label: 'Factor 1',
              value: parseInt(
                evaluation.dynamicScoringTables?.factor1TScore,
                10
              )
            },
            {
              label: 'Factor 2',
              value: parseInt(
                evaluation.dynamicScoringTables?.factor2TScore,
                10
              )
            },
            {
              label: 'Total Score',
              value: parseInt(
                evaluation.dynamicScoringTables?.totalScoreTScore,
                10
              )
            }
          ]);
          // Facets T-Score Graph
          makeTScoreGraph([
            {
              label: 'Facet 1',
              value: parseInt(evaluation.dynamicScoringTables?.facet1TScore, 10)
            },
            {
              label: 'Facet 2',
              value: parseInt(evaluation.dynamicScoringTables?.facet2TScore, 10)
            },
            {
              label: 'Facet 3',
              value: parseInt(evaluation.dynamicScoringTables?.facet3TScore, 10)
            },
            {
              label: 'Facet 4',
              value: parseInt(evaluation.dynamicScoringTables?.facet4TScore, 10)
            }
          ]);
        }
        //endregion Profile for Normative Type
        //region Comparison Group
        if (parseOption('Comparison Group')) {
          sectionBuffer();
          addText(`Comparison Group`, 14, 'bold');
          let cgTable = {
            head: [['', 'Raw Score', 'T-Score', 'Percentile']],
            body: []
          };
          // Total Score Row
          cgTable.body.push([
            'Total Score',
            evaluation.rawScore,
            evaluation.dynamicScoringTables?.totalScoreTScore,
            harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.totalScore,
              'total',
              clientNormativeType
            )
          ]);
          cgTable.body.push([
            'Factor 1',
            evaluation.groupings?.factor1?.score,
            evaluation.dynamicScoringTables?.factor1TScore,
            harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.factor1Prorated,
              'factor1',
              clientNormativeType
            )
          ]);
          cgTable.body.push([
            'Factor 2',
            evaluation.groupings?.factor2?.score,
            evaluation.dynamicScoringTables?.factor2TScore,
            harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.factor2Prorated,
              'factor2',
              clientNormativeType
            )
          ]);
          cgTable.body.push([
            'Facet 1',
            evaluation.groupings?.facet1?.score,
            evaluation.dynamicScoringTables?.facet1TScore,
            harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.facet1Prorated,
              'facet1',
              clientNormativeType
            )
          ]);
          cgTable.body.push([
            'Facet 2',
            evaluation.groupings?.facet2?.score,
            evaluation.dynamicScoringTables?.facet2TScore,
            harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.facet2Prorated,
              'facet2',
              clientNormativeType
            )
          ]);
          cgTable.body.push([
            'Facet 3',
            evaluation.groupings?.facet3?.score,
            evaluation.dynamicScoringTables?.facet3TScore,
            harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.facet3Prorated,
              'facet3',
              clientNormativeType
            )
          ]);
          cgTable.body.push([
            'Facet 4',
            evaluation.groupings?.facet4?.score,
            evaluation.dynamicScoringTables?.facet4TScore,
            harepclrPercentiles.getPercentile(
              evaluation.tableProrates?.facet4Prorated,
              'facet4',
              clientNormativeType
            )
          ]);
          pdf.autoTable({
            head: cgTable.head,
            body: cgTable.body,
            startY: y,
            theme: 'grid',
            rowPageBreak: 'avoid',
            didDrawPage: (hookData) => {
              if (hookData.pageNumber > 1) newPage(true);
              y = hookData.cursor.y + 25;
            }
          });
        }
        //endregion Comparison Group
        //region Item Responses
        if (parseOption('Item Responses')) {
          // sectionBuffer();
          newPage();
          addText(`Item Responses`, 14, 'bold');
          let irTable = {
            head: [['Item', 'Response', 'Item', 'Response']],
            body: []
          };
          for (let i = 0; i < 10; i++) {
            irTable.body.push([]);
          }
          let scoreCount0 = 0;
          let scoreCount1 = 0;
          let scoreCount2 = 0;
          let scoreCountOmit = 0;
          let bodyRow = 0;
          forEach(tool.codingFormItems, (cfi, i) => {
            if (i === 10) bodyRow = 0;
            irTable.body[bodyRow].push(`# ${i + 1}`);
            let answer = evaluation.data[cfi.id];
            irTable.body[bodyRow].push(
              answer?.text === 'Omit' ? 'Omit' : answer?.score
            );
            if (typeof answer?.score === 'number') {
              switch (answer.score) {
                case 0:
                  scoreCount0++;
                  break;
                case 1:
                  scoreCount1++;
                  break;
                case 2:
                  scoreCount2++;
                  break;
              }
            } else if (answer?.text === 'Omit') {
              scoreCountOmit++;
            }
            bodyRow++;
          });
          pdf.autoTable({
            head: irTable.head,
            body: irTable.body,
            startY: y,
            theme: 'grid',
            rowPageBreak: 'avoid',
            didDrawPage: (hookData) => {
              if (hookData.pageNumber > 1) newPage(true);
              y = hookData.cursor.y + 25;
            }
          });
          addText('Response Key', 12, 'bold');
          addText('2 = Yes');
          addText('1 = Maybe/In some respects');
          addText('0 = No');
          addText('Omit');
          addText('Answer Percentage Breakdown', 12, 'bold');
          addText(`Response 0: ${(scoreCount0 / 20) * 100}%`);
          addText(`Response 1: ${(scoreCount1 / 20) * 100}%`);
          addText(`Response 2: ${(scoreCount2 / 20) * 100}%`);
          addText(`Response Omit: ${(scoreCountOmit / 20) * 100}%`);
        }
        //endregion Item Responses

        // ==============END PARSE EVALUATION FOR REPORT==================
        // ================END OF REPORT===================
        let today = $filter('dynamicDate')(new Date(), 'fullDate');
        pdf.text(`Date Printed: ${today}`, margins.left, pageHeight - 35);
        pdf.text(`End of Report`, margins.left, pageHeight - 20);
        // addText(`End of Report (Assessment # ${caseplan.evaluation.id})`, null, 'bold');
        // ==========================UPLOAD PDF=========================
        let filename = `${client.name()} - ${$filter('dynamicDate')(
          new Date(),
          'MM-dd-yyyy'
        )} - Hare_PCL-R_Profile_Report`;
        // ==========================SAVE PDF=========================
        pdf.save(`${filename}.pdf`);
        // ==========================UPLOAD PDF=========================
        let pdfFile = pdf.output('arraybuffer');
        // let pdfFile = pdf.output('binary');
        pdfFile = new File([pdfFile], `${filename}.pdf`, {
          type: 'application/pdf'
        });
        Upload.upload({
          url: `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/evaluations/${evaluation.evaluationId}/media`,
          file: pdfFile,
          data: {
            isReport: true
          }
        })
          .then((response) => {
            $store.commit('evaluations/setFocus', evaluation.evaluationId);
            $store.dispatch('reports/getForEvaluation', {
              id: evaluation.evaluationId,
              client: client
            });
            return response;
          })
          .catch((err) => {
            return err;
          });
      },
      margins
    );
  }
};
