// LS/CMI Case Plan Report Generator

import lscmiCasePlanReportCoverPage from './coverPage.html';
import lscmiCasePlanTables from './tables';
import { forEach, find } from 'lodash';
import 'jspdf-autotable';
import fontDataUrls from '../fontDataUrls';
import JsPDF from 'jspdf';

export default {
  async generate(
    mhsLogoDataUrl,
    lscmiLogoDataUrl,
    pageSetup,
    services,
    client,
    caseplan,
    callback,
    $me
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let $filter = services.$filter;
    let $templateCache = services.$templateCache;
    let Util = services.$util;
    let $reincode = services.$reincode;

    $templateCache.put(
      'lscmiReportCoverPage.html',
      lscmiCasePlanReportCoverPage
    );
    let lscmiCoverPage = $templateCache.get('lscmiReportCoverPage.html');
    let lscmiCoverPageTable = JSON.stringify(lscmiCasePlanTables.coverPage());

    // create variable map of known variables in report
    let variableMap = {};
    let key;
    const clientAge = client.age;
    variableMap[`clientAge`] = clientAge;
    const clientLocation = client.location;
    variableMap[`clientLocation`] = clientLocation;
    for (key in client) {
      if (key === 'fName' || key === 'lName' || key === 'localId') {
        variableMap[`client.${key}`] = client[key] ? client[key] : 'N/A';
      } else {
        variableMap[`client.${key}`] = client[key]
          ? Util.decamlize(client[key])
          : 'N/A';
      }
    }

    // handle gender exclusively
    if (!client.gender) variableMap['client.gender'] = '-';

    variableMap['date.today'] = $filter('dynamicDate')(new Date(), 'longDate');
    for (key in $me) {
      variableMap[`user.${key}`] = Util.decamlize($me[key]);
    }

    variableMap['reasonForAssessment'] = client.reasonForAssessment;

    // create variableMap regex for replacing
    let variableRegEx = Object.keys(variableMap).join('|');
    variableRegEx = variableRegEx.replace(/\[/g, '\\[');
    variableRegEx = variableRegEx.replace(/]/g, '\\]');
    variableRegEx = variableRegEx.replace(/\)/g, '\\)');
    variableRegEx = variableRegEx.replace(/\(/g, '\\(');
    variableRegEx = variableRegEx.replace(/#/g, '\\#');
    variableRegEx = variableRegEx.replace(/\+/g, '\\+');
    variableRegEx = new RegExp(variableRegEx, 'gi');

    lscmiCoverPage = lscmiCoverPage.replace(variableRegEx, function (matched) {
      return variableMap[matched];
    });
    lscmiCoverPageTable = lscmiCoverPageTable.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    lscmiCoverPageTable = JSON.parse(lscmiCoverPageTable);

    //=================== PDF DOC SETUP ==================
    let specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    let pdf = new JsPDF('p', 'pt', 'letter');

    // set comfortaa font
    pdf.addFileToVFS('ComfortaaRegular.tff', fontDataUrls.comfortaaRegular());
    pdf.addFont('ComfortaaRegular.tff', 'Comfortaa', 'normal');
    pdf.addFileToVFS('ComfortaaBold.tff', fontDataUrls.comfortaaBold());
    pdf.addFont('ComfortaaBold.tff', 'Comfortaa', 'bold');
    pdf.setFont('Comfortaa');

    let header = function (page) {
      pdf.setFontSize(11);
      pdf.setFontStyle('normal');
      pdf.text(
        `Case Management Report: ${client.name()}`,
        margins.left,
        margins.top - 15
      );
      pdf.text(`Page ${page}`, pageWidth - margins.left - 50, margins.top - 15);
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(
        margins.left,
        margins.top - 10,
        pageWidth - margins.left,
        margins.top - 10
      );
    };

    let footer = function () {
      pdf.addImage(
        mhsLogoDataUrl,
        'JPEG',
        pageWidth - margins.left - 80,
        pageHeight - margins.bottom,
        80,
        35
      );
    };
    //=================== END PDF DOC SETUP ==================

    // ======================COVER PAGE===============================
    pdf.addImage(lscmiLogoDataUrl, 'JPEG', margins.left, margins.top, 189, 102);

    pdf.fromHTML(
      lscmiCoverPage,
      margins.left,
      margins.top + 100,
      {
        width: margins.width,
        elementHandlers: specialElementHandlers
      },
      function (dispose) {
        // add cover page table with client info
        pdf.autoTable({
          head: lscmiCoverPageTable.head,
          body: lscmiCoverPageTable.body,
          startY: 300,
          theme: 'striped',
          headStyles: {
            fillColor: [84, 9, 5],
            textColor: [255, 255, 255]
          }
        });

        // add MHS logo and copyright info
        pdf.setFontSize(9);
        pdf.addImage(mhsLogoDataUrl, 'JPEG', margins.left, 680, 125, 54);
        pdf.text(
          'Copyright © 2004 Multi-Health Systems Inc. All rights reserved.\n' +
            'P.O. Box 950, North Tonawanda, NY 14120-0950\n' +
            '3770 Victoria Park Ave., Toronto, ON M2H 3M6',
          margins.left + 135,
          705
        );

        // ==========================END COVER PAGE==============================
        // ==========================PARSE CASE PLAN HERE==============================
        pdf.addPage();
        let page = 2;
        header(page);
        footer();
        let y = margins.top + 10;

        // logic for creating new line or new page if needbe
        let newLine = function (y) {
          if (y + 10 > pageHeight - (margins.top + margins.bottom)) {
            pdf.addPage();
            page += 1;
            header(page);
            footer();
            y = margins.top + 20;
          } else {
            y += 10;
          }
          return y;
        };

        let newPage = function (pageAlreadyAdded) {
          if (y === margins.top + 10) return;
          if (!pageAlreadyAdded) pdf.addPage();
          header(page);
          footer();
          y = margins.top + 10;
        };

        let addText = function (text, fontSize = 10, fontStyle = 'normal') {
          if (!text) {
            console.error('Text parameter must be provided');
            return;
          }
          text = $reincode.text(text);
          if (fontSize > 10 && fontSize <= 14) y += 10;
          if (fontSize > 14 && fontSize < 18) y += 15;

          pdf.setFontSize(fontSize);
          pdf.setFontStyle(fontStyle);

          let lines = pdf.splitTextToSize(text, margins.width);

          forEach(lines, (line) => {
            pdf.setFontSize(fontSize);
            pdf.setFontStyle(fontStyle);
            pdf.text(line, margins.left, y);
            y = newLine(y);
          });

          y = newLine(y);
        };

        // parse for tables
        let parseTable = function (table) {
          addText(table.title, 12, 'bold');
          forEach(table.items, (item) => {
            if (item.item) item = item.item;
            forEach(table.cols, (column) => {
              let value;
              let key = column.key;

              if (column.type === 'intervention') {
                let option;
                if (item[key]?.hasOwnProperty('category')) {
                  option = find(column.options, {
                    value: item[key].category
                  });
                  if (item[key]?.hasOwnProperty('program')) {
                    option = find(option?.programsOptions, {
                      value: item[key].program
                    });
                  }
                }
                value = option ? `${option.label}` : '';
              } else if (
                column.type === 'enum' &&
                column.options &&
                find(column.options, { value: item[key] })
              ) {
                value = find(column.options, { value: item[key] }).label;
              } else if (column.type === 'date') {
                value = $filter('dynamicDate')(item[key], 'longDate');
              } else {
                value = Util.decamlize(item[key]);
              }

              addText(`${column.title}: ${value ? value : 'N/A'}`);
            });
            y += 10; // buffer in between items
          });
        };

        let parseProgressTable = function (table) {
          let head = table.sections.length
            ? [['Date', 'Needs', 'Progress', 'Noteworthy Developments']]
            : [['Date', 'Progress', 'Noteworthy Developments']];
          let body = [];
          let parseItem = function (item) {
            let row = [];
            row.push($filter('dynamicDate')(item.date, 'longDate'));
            if (item.section && find(table.sections, { key: item.section })) {
              row.push(find(table.sections, { key: item.section }).title);
            }
            row.push(Util.decamlize(item.change));
            row.push(item.noteworthyDevelopments);
            body.push(row);
            return row;
          };
          forEach(table.items, (item) => {
            if (item.item) item = item.item;
            parseItem(item);
          });

          addText(table.title, 12, 'bold');
          pdf.autoTable({
            head,
            body,
            startY: y - 15,
            theme: 'grid',
            headStyles: {
              fillColor: [230, 230, 230],
              textColor: [20, 20, 20]
            },
            rowPageBreak: 'avoid',
            didDrawCell: (hookData) => {
              y = hookData.cursor.y + 25;
            },
            didDrawPage: (hookData) => {
              if (hookData.pageNumber > 1) newPage(true);
              y = hookData.cursor.y + 25;
            }
          });
        };

        forEach(caseplan.sections, (section) => {
          forEach(section.subSections, (subSection) => {
            addText(`${subSection.number} ${subSection.title}`, 14, 'bold');

            if (subSection.description) {
              addText(subSection.description);
            }

            forEach(subSection.components, (component) => {
              if (component.table) {
                parseTable(component.table);
              }

              if (component.progressTrackerTable) {
                parseProgressTable(component.progressTrackerTable);
              }
            });
          });
        });
        // =========================END PARSE CASE PLAN===================
        // ================END OF REPORT===================
        let today = $filter('dynamicDate')(new Date(), 'fullDate');
        addText(`Date Printed: ${today}`);
        // addText(`End of Report (Assessment # ${caseplan.evaluation.id})`, null, 'bold');
        // ==========================SAVE PDF=========================
        // Notification.clearAll();
        pdf.save('LSCMI_Case_Management_Report.pdf');
        callback(true);
      },
      margins
    );
  }
};
