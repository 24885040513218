'use strict';

function InstitutionTransfersStore($rootScope, $api, errorHandler) {
  'ngInject';

  return {
    state: {
      loading: false,
      items: [],
      table: {
        sortedCol: 0,
        searchText: ''
      }
    },
    getters: {
      find: (state) => (id) => state.items.find((item) => item.id === id)
    },
    actions: {
      async getAll({ state, commit }) {
        commit('setProps', { loading: true });

        let data = [];

        try {
          // TODO (Alex) comment back in when Eric has the route finished
          // let res = await $api.gearsManager.listInstitutionTransfers();
          // state.items = res.data;
        } catch (err) {
          commit('setProps', { loading: false });
          throw err;
        }

        commit('set', data);

        // Set any transfers that belong to the current user.
        let myData = state.items.filter((item) => item.status != 2);
        commit('me/addInstTrans', myData, true);

        commit('setProps', { loading: false });

        return state.items;
      },
      async getMine({ state, rootState, commit }) {
        commit('setProps', { loading: true });

        let data = [];

        try {
          let res = await $api.users.getTransferRequests(rootState.me.id);
          data = res.data.data.institutionTransfers;
        } catch (err) {
          commit('setProps', { loading: false });
          throw err;
        }

        commit('set', data);
        commit('setProps', { loading: false });

        return state.items;
      },
      async approve({ getters, commit }, id) {
        let transfer = getters.find(id);

        let error;

        try {
          let { data } = await $api.institutionTransfers.accept(transfer.id);
          error = data.error;
        } catch (err) {
          error = err;
        }

        if (error) {
          errorHandler(error);
          return;
        }

        commit('remove', transfer.id);
        $rootScope.$broadcast('transferApproved');
      },
      async deny({ getters, commit }, id) {
        let transfer = getters.find(id);

        let error;

        try {
          let { data } = await $api.institutionTransfers.deny(transfer.id);
          error = data.error;
        } catch (err) {
          error = err;
        }

        if (error) {
          errorHandler(error);
          return;
        }

        commit('remove', transfer.id);
        $rootScope.$broadcast('transferDenied');
      }
    },
    mutations: {
      set(state, payload) {
        state.items = [...payload];
      },
      add(state, payload) {
        state.items = _.uniqBy([...payload, ...state.items], 'id');
      },
      remove({ items }, id) {
        items.splice(
          items.findIndex((item) => item.id === id),
          1
        );
      },
      setProps(state, props = {}) {
        for (let i in props) {
          if (i in state) {
            state[i] = props[i];
          }
        }
      },
      CLEAR(state) {
        Object.assign(state, {
          loading: false,
          items: [],
          table: {
            sortedCol: 0,
            searchText: ''
          }
        });
      }
    }
  };
}

export default InstitutionTransfersStore;
