import angular from 'angular';

declare module 'angular' {
  namespace gears {
    type ILsService = LsService;
  }
}

/**
 * A service that interacting with the browser's `localStorage`.
 */
export class LsService {
  /**
   * Get a value from local storage, if it exists.
   *
   * @param key Key to retrieve value by.
   * @return The retrived value, if it exists.
   */
  get<T>(key: string) {
    const value = localStorage.getItem(key);

    return typeof value !== 'string' ? null : (JSON.parse(value) as T);
  }

  /**
   * Add a value to local storage.
   *
   * @param key Key to associate stored value to.
   * @param value Value to store.
   */
  set(key: string, value: unknown) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  /**
   * Remove a value from local store, if it exists.
   *
   * @param key Key to remove the value by.
   */
  remove(key: string) {
    localStorage.removeItem(key);
  }
}

export default angular.module('app.$ls', []).service('$ls', LsService).name;
