'use strict';

import angular from 'angular';
import * as _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class RevertEvaluationWarningController extends Modal {
  @Inject $scope;
  @Inject $store;

  get evaluation() {
    return this.$props;
  }

  get client() {
    return this.evaluation.client || {};
  }

  get evlData() {
    return this.evaluation.evaluation || {};
  }

  $setup() {
    this.evaluation = this.$props;
    if (!this.evaluation?.client) {
      let client = _.find(this.$store.state.clients.items, {
        id: this.evaluation?.clientId
      });
      if (client) this.evaluation.client = client;
    }
  }

  revert() {
    this.$close(this.evaluation);
  }
}

export default angular
  .module('app.revertEvaluationWarning', [])
  .directive('revertEvaluationWarning', () => ({
    restrict: 'E',
    replace: true,
    template: require('./revert-evaluation-warning.html'),
    controller: RevertEvaluationWarningController,
    controllerAs: 'vm'
  })).name;
