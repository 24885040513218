import angular, { animate, IController, IDirectiveLinkFn } from 'angular';
import { IListItemModel } from 'lister';

import { Controller, Inject } from '@decorators/ngCtrl';
import { HasAccessCheckOptions } from '@services/acl/acl.service';

import './lister-view-list-item.scss';

interface ListItemData<T = any> {
  id: string | number;
  name: string;
  parent?: ListItemData;
  itemId: string | number;
  data: T;
  model: IListItemModel<T>;
  isNew: boolean;
}

/**
 * ...
 */
@Controller
class ListerViewListItem implements IController {
  @Inject readonly $scope!: angular.IScope;
  @Inject readonly $element!: angular.IElement;
  @Inject readonly $timeout!: angular.ITimeoutService;
  @Inject readonly $auth!: angular.gears.IAuthService;
  @Inject readonly $reincode!: angular.gears.IReincodeService;
  @Inject readonly utils!: angular.gears.IUtilsService;

  item!: ListItemData;
  prevName: string | null = null;
  isEditing: boolean = false;
  expanded: boolean = false;
  buttonPress: string | null = null;
  itemTitle: string = '';

  private $valueId!: unknown;
  private $level!: number;
  private $childModel!: unknown;
  private $selection!: IController | null;

  get itemId() {
    return this.item.itemId;
  }

  get itemData() {
    return this.item.data;
  }

  get selected() {
    return this.$selection === this;
  }

  get canAdd() {
    const { canAdd } = this.item.model;

    if (!this.item.data) return false;

    if (canAdd === undefined) return true;

    return !this.$childModel && typeof canAdd === 'boolean'
      ? canAdd
      : typeof canAdd === 'function'
      ? canAdd(this.item.data)
      : this.authCheck(canAdd);
  }

  get canEdit() {
    const { canEdit } = this.item.model;

    if (!this.item.data) return false;

    if (canEdit === undefined) return true;

    return !this.isEditing && typeof canEdit === 'boolean'
      ? canEdit
      : typeof canEdit === 'function'
      ? canEdit(this.item.data)
      : this.authCheck(canEdit);
  }

  get canRemove() {
    const { canRemove } = this.item.model;

    if (!this.item.data) return false;

    if (canRemove === undefined) return true;

    return !this.isEditing && typeof canRemove === 'boolean'
      ? canRemove
      : typeof canRemove === 'function'
      ? canRemove(this.item.data)
      : this.authCheck(canRemove);
  }

  $onInit() {
    let names = [this.item.name];
    let parent = this.item.parent;

    while (parent) {
      names.splice(0, 0, parent.name);
      parent = parent.parent;
    }

    this.itemTitle = names.join(' --> ');

    if (this.item.isNew) {
      this.edit();
    } else {
      this.prevName = this.item.name;
    }
  }

  $onDestroy() {
    if (this.selected) {
      this.$scope.$emit('triggerSelect');
    }
  }

  edit() {
    this.select();

    this.isEditing = true;

    this.$timeout(() => this.$element.find('input').focus(), 10);
  }

  select() {
    if (this.isEditing) return;

    this.$scope.$emit('selectListItem', this);
  }

  modify(cancel = false) {
    if (this.item.isNew) {
      this.$scope.$emit('postListItem', this.item.id, cancel);
    } else if (this.item.name && this.item.name != this.prevName) {
      this.$scope.$emit('patchListItem', this.item.id, cancel);
    } else {
      this.item.name = this.prevName;
    }

    if (cancel && this.prevName) {
      this.item.name = this.prevName;
    }

    this.item.name = this.$reincode.text(this.item.name);

    this.isEditing = false;
  }

  accept() {
    if (!this.isEditing) {
      return;
    }

    this.modify();
  }

  cancel() {
    if (!this.isEditing) {
      return;
    }

    this.modify(true);
  }

  remove() {
    this.$scope.$emit('deleteListItem', this.item.id);
  }

  onKeyPress(e: KeyboardEvent) {
    if (!e.target) return;

    if (e.key === 'Enter') {
      this.accept();
      e.target.blur();
    } else if (e.key === 'Escape') {
      this.cancel();
      e.target.blur();
    }
  }

  onBlur() {
    if (this.buttonPress) {
      this.$element.find('input').focus();
    } else {
      this.cancel();
    }
  }

  buttonPressStart(buttonName: string) {
    this.buttonPress = buttonName;
  }

  buttonPressFinish(buttonName: string, methodName: string) {
    if (this.buttonPress == buttonName) {
      this[methodName]();
    }

    this.buttonPress = null;
  }

  buttonPressCancel() {
    this.buttonPress = null;
  }

  authCheck(actions?: HasAccessCheckOptions) {
    if (!actions) return true;

    return this.$auth.hasAccess(actions);
  }
}

/**
 * ...
 */
const linkFn: IDirectiveLinkFn = (scope, _element, _attrs, ctrls) => {
  const { vm } = scope;
  const [$$lister] = ctrls;

  Object.defineProperties(vm, {
    $valueId: {
      value: vm.item.data ? vm.item.data[vm.item.model.idBy] : null
    },
    $level: {
      value: vm.item.model.$level
    },
    $childModel: {
      value: $$lister.getChildModel(vm.item.model.$level)
    },
    $selection: {
      get: () => $$lister.$selectedItemCtrl,
      set: (val) => ($$lister.$selectedItemCtrl = val)
    }
  });

  scope.$on('triggerSelect', (e) => {
    if (e.targetScope === scope) {
      return;
    }

    e.stopPropagation();

    vm.select();
  });

  scope.$on('addChild', (e, { parentId, item }) => {
    if (vm.item.id === parentId) {
      vm.expanded = true;
    }
  });

  // Auto-select self if there is no selection
  // within the Util View
  if (!vm.$selection) {
    vm.select();
  }
};

/**
 * ...
 */
function animateFactory($animateCss: animate.IAnimateCssService) {
  'ngInject';

  const enter = (el: JQLite) => {
    let toHeight = el.outerHeight();

    return $animateCss(el, {
      event: 'enter',
      structural: true,
      easing: 'ease',
      from: { height: 0 },
      to: { height: toHeight },
      duration: 0.25,
      cleanupStyles: true
    });
  };

  const leave = (el: JQLite) => {
    let fromHeight = el.outerHeight();

    return $animateCss(el, {
      event: 'leave',
      structural: true,
      easing: 'ease',
      from: { height: fromHeight },
      to: { height: 0 },
      duration: 0.25,
      cleanupStyles: true
    });
  };

  return { enter, leave };
}

export default angular
  .module('lister.listerViewListItem', [])
  .directive('listerViewListItem', () => {
    return {
      restrict: 'E',
      require: ['^^listerView', '?^^listerViewList', '?^^listerViewSearchList'],
      replace: true,
      scope: { item: '=' },
      link: linkFn,
      bindToController: true,
      template: require('./lister-view-list-item.html'),
      controller: ListerViewListItem,
      controllerAs: 'vm'
    };
  })
  .animation('.lister-view-list', animateFactory).name;
