'use strict';

import angular from 'angular';

class TableComponentController {
  // selectedOption = {};
  // customValue;
  //
  valuesTable;

  constructor(ItemTable, TableViewDataTypes) {
    'ngInject';

    let tableConfig = this.component.tableConfig;

    this.valuesTable = new ItemTable({
      title: tableConfig.title,
      items: tableConfig.items
    });

    this.component.tableColumnConfigs.forEach(item => {
      this.valuesTable.column(item);
    });

    this.component.value = this.valuesTable.items;
  }

  log(item) {
    console.log(item);
  }
}

export default angular.module('app.component.tableComponent', [])
  .component('tableComponent', {
    template: require('./table.html'),
    bindings: {
      component: '='
    },
    controller: TableComponentController,
    controllerAs: 'vm'
  })
  .name;
