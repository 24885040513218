'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class AcesProfileReportChoicesModalController extends Modal {
  $setup() {
    this.options = [
      {
        text: 'Item Responses',
        selected: true
      }
    ];
  }

  submit() {
    this.$close(this.options);
  }
}

export default angular
  .module('app.acesProfileReportChoicesModal', [])
  .directive('acesProfileReportChoicesModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./aces-profile-report-choices.html'),
    controller: AcesProfileReportChoicesModalController,
    controllerAs: 'vm'
  })).name;
