import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

const REASSESSMENT_OPTIONS = [
  {
    key: 'NOT_ALLOWED',
    label: 'Not Allowed'
  },
  {
    key: 'AUTO_ANSWER',
    label: 'Auto-Populate Previous Response'
  },
  {
    key: 'SHOW_ANSWER',
    label: 'Show Response'
  }
];

@Controller
class ChooseReassessmentOptionTypeModalController extends Modal {
  optionType: string = REASSESSMENT_OPTIONS[0].key;

  get optionTypes() {
    return REASSESSMENT_OPTIONS;
  }
}

export default angular
  .module('app.chooseReassessmentOptionTypeModal', [])
  .directive('chooseReassessmentOptionTypeModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./choose-reassessment-option-type-modal.html'),
    controller: ChooseReassessmentOptionTypeModalController,
    controllerAs: 'vm'
  })).name;
