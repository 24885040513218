// YLS/CMI Comparative Report Generator
import { forEach, sortBy, find } from 'lodash';
import angular from 'angular';
import { differenceInYears } from 'date-fns';
import 'jspdf-autotable';

import ylsPercentiles from '../ylsProfileReport/percentiles';

export default {
  async createReport(
    mhsLogoDataUrl,
    ylsTitleDataUrl,
    client,
    evaluations,
    tool,
    services,
    pageSetup,
    options
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let $filter = services.$filter;
    let $sce = services.$sce;
    let $reincode = services.$reincode;
    // console.log('client: ', client);
    // console.log('tool: ', tool);
    // console.log('evaluations: ', evaluations);
    // sort evaluations by interview date
    // evaluations = sortBy(evaluations, e => e.evaluationData.interview.dateTime);
    let parseOption = function (text) {
      // parse options from $me selection to see if it should be included in report
      return find(options, {
        text: text
      }).selected;
    };
    let makeCell = function (
      content = '',
      style = [],
      rowSpan = 1,
      colSpan = 1
    ) {
      return {
        content,
        style,
        rowSpan,
        colSpan
      };
    };
    // calculate evaluation score total
    // calculate risk categories breakdown too
    let findRiskCategories = function (e) {
      let categories;
      forEach(tool.customRiskCategories, (crc) => {
        let criteriaMet = true;
        forEach(crc.criteria, (val, key) => {
          if (key === 'sex' && val != client.sex) criteriaMet = false;
          if (
            key === 'clientNormativeType' &&
            val != e.evaluationData?.additionalInformation?.clientNormativeType
          )
            criteriaMet = false;
        });
        if (criteriaMet) categories = crc.categories;
      });
      return categories;
    };
    forEach(evaluations, (e) => {
      if (!e.evaluationData.hasOwnProperty('score')) {
        let evaluationScoreTotal = 0;
        forEach(e.evaluationData.data, (item) => {
          if (typeof item.score === 'number')
            evaluationScoreTotal += item.score;
        });
        e.evaluationData.score = evaluationScoreTotal;
      }
      // find risk categories
      e.evaluationData.riskCategories = findRiskCategories(e);
      // set riskLevelLetter here
      switch (e.evaluationData.riskCategory) {
        case 'Low':
          e.evaluationData.riskLevelLetter = 'L';
          break;
        case 'Moderate':
          e.evaluationData.riskLevelLetter = 'M';
          break;
        case 'High':
          e.evaluationData.riskLevelLetter = 'H';
          break;
        case 'Very High':
          e.evaluationData.riskLevelLetter = 'VH';
          break;
      }
    });
    let part1Tool = find(tool.childTools, {
      id: 119
    });
    let getPercentileRanking = function (e) {
      let normativeArea;
      if (
        e.evaluationData.additionalInformation?.clientNormativeType?.includes(
          'Community'
        )
      ) {
        normativeArea = 'community';
      } else if (
        e.evaluationData.additionalInformation?.clientNormativeType?.includes(
          'Custodial'
        )
      ) {
        normativeArea = 'custodial';
      } else if (
        e.evaluationData.additionalInformation?.clientNormativeType?.includes(
          'Canadian'
        )
      ) {
        normativeArea = 'canadian';
      }
      return ylsPercentiles.totalScore(
        e.evaluationData.score,
        client.sex,
        normativeArea
      );
    };
    let report = [];
    // #region cover page
    report.push({
      type: 'image',
      content: {
        dataUrl: $sce.trustAsResourceUrl(ylsTitleDataUrl),
        x: margins.left,
        y: margins.top,
        width: 500,
        height: 102
      }
    });
    report.push({
      type: 'text',
      content: {
        text: 'Youth Level of Service / Case Management Inventory 2.0',
        style: ['title']
      }
    });
    report.push({
      type: 'text',
      content: {
        text: 'By R. D. Hoge, Ph.D. & D. A. Andrews, Ph.D.',
        style: ['italics']
      }
    });
    report.push({
      type: 'text',
      content: {
        text: 'Comparative Report',
        style: ['title']
      }
    });
    // #region cover page info table
    let coverPageTable = {
      type: 'table',
      content: {
        head: [],
        body: [],
        theme: 'grid',
        startY: 300,
        headStyles: {
          fillColor: [84, 9, 5],
          textColor: [255, 255, 255]
        }
      }
    };
    coverPageTable.content.head.push([
      makeCell('Name:'),
      makeCell(`${client.name()}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('ID Number:'),
      makeCell(`${client.localId}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('Sex:'),
      makeCell(`${client.sex}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('Race/Ethnicity:'),
      makeCell(`${client.ethnicity}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('Date of Comparative Report:'),
      makeCell(`${$filter('dynamicDate')(new Date(), 'longDate')}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('Number of Assessments Included:'),
      makeCell(`${evaluations.length}`)
    ]);
    report.push(coverPageTable);
    // #endregion cover page info table
    // #region cover page assessments info table
    let cpta = {
      type: 'table',
      content: {
        head: [],
        body: [],
        theme: 'grid',
        startY: 460
      }
    };
    let cptahead = [
      {
        content: ''
      }
    ];
    let cptabody = [
      [makeCell('Administration Date:')],
      [makeCell('Age:')],
      [makeCell('Professional Override:')],
      [makeCell("Assessor's Name: ")],
      [makeCell('Normative Option:')]
    ];
    forEach(evaluations, (e, i) => {
      cptahead.push(makeCell(`Assessment ${i + 1}`));
      forEach(cptabody, (arr, i2) => {
        switch (i2) {
          case 0:
            //administration date
            arr.push(makeCell($filter('dynamicDate')(e.updatedAt, 'longDate')));
            break;
          case 1:
            //age at time of interview
            arr.push(
              makeCell(
                differenceInYears(new Date(e.updatedAt), new Date(client.dob))
              )
            );
            break;
          case 2:
            //professional override?
            arr.push(
              makeCell(e.evaluationData.overrideRiskCategory ? 'Yes' : 'No')
            );
            break;
          case 3:
            //assessor's name
            arr.push(makeCell(`${e.evaluator?.fName} ${e.evaluator?.lName}`));
            break;
          case 4:
            //normative option
            arr.push(
              makeCell(
                e.evaluationData.additionalInformation?.clientNormativeType
              )
            );
            break;
        }
      });
    });
    cpta.content.head.push(cptahead);
    cpta.content.body = cptabody;
    report.push(cpta);
    // #endregion cover page assessments info table
    // #region MHS copyright
    report.push({
      type: 'image',
      content: {
        dataUrl: $sce.trustAsResourceUrl(mhsLogoDataUrl),
        x: margins.left,
        y: 680,
        width: 125,
        height: 54
      }
    });
    report.push({
      type: 'text',
      content: {
        text:
          'Copyright © 2011, 2012, 2013 Multi-Health Systems Inc. All rights reserved.\n' +
          'YLS/CMI 2.0 test items and normative data © 2011 Multi-Health Systems Inc.\n' +
          'All rights reserved.\n' +
          'P.O. Box 950, North Tonawanda, NY 14120-0950\n' +
          '3770 Victoria Park Ave., Toronto, ON M2H 3M6',
        x: margins.left + 135,
        y: 705
      }
    });
    // #endregion MHS copyright
    // #endregion cover page
    // #region introduction
    if (parseOption('Introduction')) {
      report.push({
        type: 'text',
        content: {
          text: 'Introduction',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: "The Youth Level of Service/Case Management Inventory 2.0 (YLS/CMI 2.0) is a risk and needs assessment tool. This report summarizes the results of the YLS/CMI 2.0 assessment over time, and provides information pertinent to the assessment of the individual. The results of this inventory can be helpful in formulating a case management plan for the juvenile. See the YLS/CMI 2.0 User's Manual published by MHS for more information about this instrument and its results."
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'This computerized report is an interpretive aid and should not be given to clients or used as the sole basis for diagnostic decisions and case management plans. This report is most effective when combined with other sources of relevant information.'
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'Scores represent the number of items overall or within an assessment area that apply to the youth being assessed.'
        }
      });
    }
    // #endregion introduction
    // #region overall assessment
    if (
      parseOption(
        'Overall Assessment Based on YLS/CMI 2.0 Total Risk/Need Level'
      )
    ) {
      report.push({
        type: 'text',
        content: {
          text: 'Overall Assessment Based on YLS/CMI 2.0 Total Risk/Need Level',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'The graph below displays the YLS/CMI 2.0 Total Score and indicates the classification level associated with that score across assessments.'
        }
      });
      report.push({
        reportOverview: true,
        type: 'text',
        content: {
          text: 'Total Scores',
          style: ['title']
        }
      });
      // #region overall assessment graph
      let oag = {
        type: 'graph',
        content: {
          data: {},
          type: 'horizontalBar',
          options: {
            tooltips: {
              enabled: true,
              callbacks: {
                label: function (tooltipItem, data) {
                  return data.datasets[0].labels[tooltipItem.index];
                }
              },
              yAlign: 'top',
              xAlign: 'center'
            },
            showAllTooltips: true,
            title: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: 'Total Score',
                    fontColor: 'black'
                  },
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 55,
                    stepSize: 10,
                    fontColor: 'black',
                    display: true
                  }
                }
              ],
              yAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: 'black'
                  },
                  scaleLabel: {
                    display: true,
                    fontColor: 'black'
                  }
                }
              ]
            }
          }
        }
      };
      let oagData = {
        labels: [],
        datasets: [
          {
            backgroundColor: '#cf2b1f',
            borderColor: '#8c1d15',
            labels: []
          }
        ],
        data: [[]]
      };
      let orag = {
        reportOverview: true,
        type: 'graph',
        content: {
          data: {},
          type: 'horizontalBar',
          options: {
            tooltips: {
              enabled: true,
              callbacks: {
                label: function (tooltipItem, data) {
                  return data.datasets[0].labels[tooltipItem.index];
                }
              },
              yAlign: 'top',
              xAlign: 'center'
            },
            showAllTooltips: false,
            title: {
              display: false
            },
            scales: {
              xAxes: [
                {
                  scaleLabel: {
                    display: true,
                    labelString: 'Total Score',
                    fontColor: 'black'
                  },
                  ticks: {
                    display: true,
                    beginAtZero: true,
                    min: 0,
                    max: 55,
                    stepSize: 10,
                    fontColor: 'black'
                  }
                }
              ],
              yAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: 'black'
                  },
                  scaleLabel: {
                    display: true,
                    fontColor: 'black'
                  }
                }
              ]
            }
          }
        }
      };
      let oragData = {
        labels: [],
        datasets: [
          {
            backgroundColor: '#cf2b1f',
            borderColor: '#8c1d15',
            labels: []
          }
        ],
        data: [[]]
      };
      forEach(evaluations, (e, i) => {
        oagData.labels.push(
          `Assessment ${i + 1} (${$filter('dynamicDate')(
            e.updatedAt,
            'longDate'
          )})`
        );
        oragData.labels.push(
          `Assessment ${i + 1} (${$filter('dynamicDate')(
            e.updatedAt,
            'longDate'
          )})`
        );
        let oagLabel = e.evaluationData.overrideRiskCategory
          ? `${e.evaluationData.score} (${e.evaluationData.riskCategory} | override: ${e.evaluationData.overrideRiskCategory})`
          : `${e.evaluationData.score} (${e.evaluationData.riskCategory})`;
        oagData.datasets[0].labels.push(oagLabel);
        let oragRiskLabel = e.evaluationData.overrideRiskCategory
          ? `${e.evaluationData.riskCategory} | override: ${e.evaluationData.overrideRiskCategory}`
          : `${e.evaluationData.riskCategory}`;
        oragData.datasets[0].labels.push([
          [`Total Score: ${e.evaluationData.score}`],
          [`Risk Level: ${oragRiskLabel}`],
          [`Percentile Ranking: ${getPercentileRanking(e)}`],
          [`Override: ${e.evaluationData.overrideRiskCategory ? 'Yes' : 'No'}`]
        ]);
        oagData.data[0].push(e.evaluationData.score);
        oragData.data[0].push(e.evaluationData.score);
      });
      oag.content.data = oagData;
      report.push(oag);
      orag.content.data = oragData;
      report.push(orag);

      report.push({
        type: 'pagebreak'
      });
      // #endregion overall assessment graph
      report.push({
        type: 'text',
        content: {
          text: 'The following table shows the cut-off scores used to determine the Total Risk/Need Level for each assessment'
        }
      });
      // #region cutoff table
      // tutorial and example for merging cells: https://github.com/simonbengtsson/jsPDF-AutoTable/blob/master/examples/examples.js
      // example: https://simonbengtsson.github.io/jsPDF-AutoTable/#spans
      let cutoffTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let ctbody = [
        [makeCell('Assessment', undefined, undefined, 2), makeCell()],
        [makeCell('Date', undefined, undefined, 2), makeCell()],
        [makeCell('Normative Option', undefined, undefined, 2), makeCell()],
        [makeCell('Risk Level', undefined, 4), makeCell(), makeCell('Low')],
        [makeCell(), makeCell('Moderate')],
        [makeCell(), makeCell('High')],
        [makeCell(), makeCell('Very High')]
      ];
      forEach(evaluations, (e, i) => {
        forEach(ctbody, (row, i2) => {
          var rc;
          switch (i2) {
            case 0:
              // assessment
              row.push(makeCell(`Assessment ${i + 1}`));
              break;
            case 1:
              // date
              row.push(
                makeCell(`${$filter('dynamicDate')(e.updatedAt, 'longDate')}`)
              );
              break;
            case 2:
              // normative option
              row.push(
                makeCell(
                  `${e.evaluationData.additionalInformation?.clientNormativeType}`
                )
              );
              break;
            case 3:
              // low risk level
              rc = find(e.evaluationData.riskCategories, ['name', 'Low']);
              row.push(makeCell(`${rc.low} - ${rc.high}`));
              break;
            case 4:
              // moderate risk level
              rc = find(e.evaluationData.riskCategories, ['name', 'Moderate']);
              row.push(makeCell(`${rc.low} - ${rc.high}`));
              break;
            case 5:
              // high risk level
              rc = find(e.evaluationData.riskCategories, ['name', 'High']);
              row.push(makeCell(`${rc.low} - ${rc.high}`));
              break;
            case 6:
              // very high risk level
              rc = find(e.evaluationData.riskCategories, ['name', 'Very High']);
              row.push(makeCell(`${rc.low} - ${rc.high}`));
              break;
          }
        });
      });
      cutoffTable.content.body = ctbody;
      report.push(cutoffTable);
      // #endregion cutoff table
      // #region basic analysis
      report.push({
        type: 'text',
        content: {
          text: 'Basic Analysis',
          style: ['title']
        }
      });
      let diffInRiskLevels = false;
      forEach(evaluations, (e) => {
        let diff = false;
        forEach(evaluations, (e2) => {
          if (e.evaluationData.riskCategory !== e2.evaluationData.riskCategory)
            diff = true;
        });
        if (diff === true) diffInRiskLevels = true;
      });
      report.push({
        type: 'text',
        content: {
          text: `Based on the YLS/CMI 2.0 Total scores, there were ${
            diffInRiskLevels ? 'changes' : 'no changes'
          } in the risk/need levels across assessments.`
        }
      });
      // #endregion basic analysis
      // #region assessment comparisons
      forEach(evaluations, (e, i) => {
        if (i > 0) {
          let prevEval = evaluations[i - 1];
          report.push({
            type: 'text',
            content: {
              text: `Assessment ${i} vs Assessment ${i + 1}`,
              style: ['title']
            }
          });
          if (prevEval?.evaluationData?.score === e.evaluationData.score) {
            report.push({
              type: 'text',
              content: {
                text: `The score for Assessment ${i} was the same as the score for Assessment ${
                  i + 1
                }.`
              }
            });
          } else {
            report.push({
              type: 'text',
              content: {
                text: `The score for Assessment ${i} was ${
                  prevEval?.evaluationData?.score > e.evaluationData.score
                    ? 'higher'
                    : 'lower'
                } than the score for Assessment ${i + 1}.`
              }
            });
          }
        }
      });
      // #endregion assessment comparisons
      // #region overall total risk/need level
      report.push({
        type: 'text',
        content: {
          text: `Overall Total Risk/Need Level`,
          style: ['title']
        }
      });
      forEach(evaluations, (e, i) => {
        if (e.evaluationData?.overrideRiskCategory) {
          report.push({
            type: 'text',
            content: {
              text: `Assessment ${i + 1}: ${e.evaluationData.riskCategory} | ${
                e.evaluationData.overrideRiskCategory
              } (override)`
            }
          });
        } else {
          report.push({
            type: 'text',
            content: {
              text: `Assessment ${i + 1}: ${e.evaluationData.riskCategory}`
            }
          });
        }
      });
      // #endregion overal total risk/need level
      // #region score percentile rankings
      report.push({
        type: 'text',
        content: {
          text: `Total Score Percentile Rankings`,
          style: ['title']
        }
      });
      forEach(evaluations, (e, i) => {
        report.push({
          type: 'text',
          content: {
            text: `Assessment ${i + 1}: ${getPercentileRanking(e)}`
          }
        });
      });
      // #endregion score percentile rankings
      // #region contact level
      report.push({
        type: 'text',
        content: {
          text: `Contact Level`,
          style: ['title']
        }
      });
      let findContactLevel = function (e) {
        let supervisionLevel = 'N/A';
        if (
          e.evaluationData.data['118>Q-rPJjPr9ls'] &&
          (e.evaluationData.data['118>Q-rPJjPr9ls'].text !== '-' ||
            e.evaluationData.data['118>Q-rPJjPr9ls'].fillIn)
        ) {
          supervisionLevel = e.evaluationData.data['118>Q-rPJjPr9ls'].fillIn
            ? $reincode.text(e.evaluationData.data['118>Q-rPJjPr9ls'].fillIn)
            : $reincode.text(e.evaluationData.data['118>Q-rPJjPr9ls'].text);
        }
        return supervisionLevel;
      };
      forEach(evaluations, (e, i) => {
        report.push({
          type: 'text',
          content: {
            text: `Assessment ${i + 1}: ${findContactLevel(e)}`
          }
        });
      });
      // #endregion contact level
      report.push({
        type: 'pagebreak'
      });
    }
    // #endregion overall assessment
    // #region subscale scores
    if (parseOption('YLS/CMI 2.0 Subscale Scores')) {
      report.push({
        type: 'text',
        content: {
          text: `YLS/CMI 2.0 Subscale Scores`,
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `The following graphs display changes across assessments for each area. The cut-offs for each area are displayed on the following page. L = Low; M = Moderate; H = High.`
        }
      });
      report.push({
        reportOverview: true,
        type: 'text',
        content: {
          text: `Subscale Scores`,
          style: ['title']
        }
      });
      let makeSubscaleScoreGraph = function (title, toolScoreAddress) {
        let graph = {
          type: 'graph',
          content: {
            data: {},
            type: 'bar',
            options: {
              tooltips: {
                enabled: true,
                callbacks: {
                  label: function (tooltipItem, data) {
                    return data.datasets[0].labels[tooltipItem.index];
                  }
                },
                yAlign: 'top',
                xAlign: 'center'
              },
              showAllTooltips: true,
              title: {
                display: true,
                text: title,
                fontColor: 'black'
              },
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      fontColor: 'black'
                    },
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      max: 7,
                      stepSize: 1,
                      display: true,
                      fontColor: 'black'
                    }
                  }
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Assessment',
                      fontColor: 'black'
                    },
                    ticks: {
                      display: true,
                      fontColor: 'black'
                    }
                  }
                ]
              }
            },
            width: 50
          }
        };
        let graphData = {
          labels: [],
          datasets: [
            {
              backgroundColor: '#cf2b1f',
              borderColor: '#8c1d15',
              labels: []
            }
          ],
          data: [[]]
        };
        let overviewGraph = {
          reportOverview: true,
          type: 'graph',
          content: {
            data: {},
            type: 'bar',
            options: {
              tooltips: {
                enabled: true,
                callbacks: {
                  label: function (tooltipItem, data) {
                    return data.datasets[0].labels[tooltipItem.index];
                  }
                },
                yAlign: 'top',
                xAlign: 'center'
              },
              showAllTooltips: false,
              title: {
                display: true,
                text: title,
                fontColor: 'black'
              },
              scales: {
                yAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      fontColor: 'black'
                    },
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      max: 7,
                      stepSize: 1,
                      fontColor: 'black'
                    }
                  }
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Assessment',
                      fontColor: 'black'
                    },
                    ticks: {
                      display: true,
                      fontColor: 'black'
                    }
                  }
                ]
              }
            },
            width: 50
          }
        };
        let overviewGraphData = {
          labels: [],
          datasets: [
            {
              backgroundColor: '#cf2b1f',
              borderColor: '#8c1d15',
              labels: []
            }
          ],
          data: [[]]
        };
        forEach(evaluations, (e, i) => {
          graphData.labels.push(`Assessment ${i + 1}`);
          overviewGraphData.labels.push(`Assessment ${i + 1}`);
          let subScaleRiskLevelLetter;
          switch (e.evaluationData.toolScores[toolScoreAddress].riskCategory) {
            case 'Low':
              subScaleRiskLevelLetter = 'L';
              break;
            case 'Moderate':
              subScaleRiskLevelLetter = 'M';
              break;
            case 'High':
              subScaleRiskLevelLetter = 'H';
              break;
            case 'Very High':
              subScaleRiskLevelLetter = 'VH';
              break;
          }
          let score = e.evaluationData.toolScores[toolScoreAddress].score;
          graphData.datasets[0].labels.push(
            `${score} (${subScaleRiskLevelLetter})`
          );
          overviewGraphData.datasets[0].labels.push([
            [`Score: ${score}`],
            [
              `Risk Level: ${e.evaluationData.toolScores[toolScoreAddress].riskCategory}`
            ]
          ]);
          graphData.data[0].push(score);
          overviewGraphData.data[0].push(score);
        });
        graph.content.data = graphData;
        report.push(graph);
        overviewGraph.content.data = overviewGraphData;
        report.push(overviewGraph);
      };
      // #region Part 1 subscales
      makeSubscaleScoreGraph(`1. Offenses/Dispositions`, `119>107>`);
      makeSubscaleScoreGraph(`2. Family/Parenting`, `119>108>`);
      makeSubscaleScoreGraph(`3. Education/Employment`, `119>109>`);
      makeSubscaleScoreGraph(`4. Peer Relations`, `119>110>`);
      makeSubscaleScoreGraph(`5. Substance Abuse`, `119>111>`);
      makeSubscaleScoreGraph(`6. Leisure/Recreation`, `119>112>`);
      makeSubscaleScoreGraph(`7. Personality/Behavior`, `119>113>`);
      makeSubscaleScoreGraph(`8. Attitudes/Orientation`, `119>114>`);
      // #endregion Part 1 subscales
      report.push({
        type: 'pagebreak'
      });
      // #region Part 1 cut off scores table
      report.push({
        type: 'text',
        content: {
          text: `The following table shows the cut-off scores used to determine the subscale Risk/Need Levels for each assessment.`
        }
      });
      let part1CutoffTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let p1ctbody = [
        [makeCell('Assessment', undefined, undefined, 2), makeCell()],
        [makeCell('Date', undefined, undefined, 2), makeCell()],
        [makeCell('Normative Option', undefined, undefined, 2), makeCell()],
        [
          makeCell('1. Offenses/Dispositions', undefined, 3),
          makeCell(),
          makeCell('Low')
        ],
        [makeCell(), makeCell('Moderate')],
        [makeCell(), makeCell('High')],
        [
          makeCell('2. Family/Parenting', undefined, 3),
          makeCell(),
          makeCell('Low')
        ],
        [makeCell(), makeCell('Moderate')],
        [makeCell(), makeCell('High')],
        [
          makeCell('3. Education/Employment', undefined, 3),
          makeCell(),
          makeCell('Low')
        ],
        [makeCell(), makeCell('Moderate')],
        [makeCell(), makeCell('High')],
        [
          makeCell('4. Peer Relations', undefined, 3),
          makeCell(),
          makeCell('Low')
        ],
        [makeCell(), makeCell('Moderate')],
        [makeCell(), makeCell('High')],
        [
          makeCell('5. Substance Abuse', undefined, 3),
          makeCell(),
          makeCell('Low')
        ],
        [makeCell(), makeCell('Moderate')],
        [makeCell(), makeCell('High')],
        [
          makeCell('6. Leisure/Recreation', undefined, 3),
          makeCell(),
          makeCell('Low')
        ],
        [makeCell(), makeCell('Moderate')],
        [makeCell(), makeCell('High')],
        [
          makeCell('7. Personality/Behavior', undefined, 3),
          makeCell(),
          makeCell('Low')
        ],
        [makeCell(), makeCell('Moderate')],
        [makeCell(), makeCell('High')],
        [
          makeCell('8. Attitudes/Orientation', undefined, 3),
          makeCell(),
          makeCell('Low')
        ],
        [makeCell(), makeCell('Moderate')],
        [makeCell(), makeCell('High')]
      ];
      forEach(evaluations, (e, i) => {
        let toolId;
        forEach(p1ctbody, (row, i2) => {
          let findRiskCategory = function (toolId, name, tool) {
            let t = find(tool.childTools, (ct) => {
              return parseInt(ct.id, 10) === parseInt(toolId, 10);
            });
            let riskCategory = find(t.riskCategories, {
              name: name
            });
            return {
              low: riskCategory.low,
              high: riskCategory.high
            };
          };
          if (i2 === 0) {
            // assessment
            row.push(makeCell(`Assessment ${i + 1}`));
          } else if (i2 === 1) {
            // date
            row.push(
              makeCell(`${$filter('dynamicDate')(e.updatedAt, 'longDate')}`)
            );
          } else if (i2 === 2) {
            // normative option
            row.push(
              makeCell(
                `${e.evaluationData.additionalInformation?.clientNormativeType}`
              )
            );
          } else {
            switch (row[0].content) {
              case '1. Offenses/Dispositions':
                toolId = 107;
                break;
              case '2. Family/Parenting':
                toolId = 108;
                break;
              case '3. Education/Employment':
                toolId = 109;
                break;
              case '4. Peer Relations':
                toolId = 110;
                break;
              case '5. Substance Abuse':
                toolId = 111;
                break;
              case '6. Leisure/Recreation':
                toolId = 112;
                break;
              case '7. Personality/Behavior':
                toolId = 113;
                break;
              case '8. Attitudes/Orientation':
                toolId = 114;
                break;
            }
            let lowHigh = findRiskCategory(
              toolId,
              row[1].content ? row[1].content : row[2].content,
              part1Tool
            );
            if (lowHigh.low === lowHigh.high) {
              row.push(makeCell(`${lowHigh.low}`));
            } else {
              row.push(makeCell(`${lowHigh.low} - ${lowHigh.high}`));
            }
          }
        });
      });
      part1CutoffTable.content.body = p1ctbody;
      report.push(part1CutoffTable);
      report.push({
        type: 'pagebreak'
      });
      // #endregion Part 1 cut off scores table
    }
    // #endregion subscale scores
    // #region profile comparison
    if (parseOption('Profile Comparison')) {
      report.push({
        type: 'text',
        content: {
          text: `Profile Comparison`,
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `The following table displays risk/need levels across assessments for each area.`
        }
      });
      // #region profile comparison table
      let pcTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let pcbody = [
        [makeCell()],
        [makeCell('Area of Assessment')],
        [makeCell('1. Offenses/Dispositions')],
        [makeCell('2. Family/Parenting')],
        [makeCell('3. Education/Employment')],
        [makeCell('4. Peer Relations')],
        [makeCell('5. Substance Abuse')],
        [makeCell('6. Leisure/Recreation')],
        [makeCell('7. Personality/Behavior')],
        [makeCell('8. Attitudes/Orientation')]
      ];
      forEach(evaluations, (e, i) => {
        forEach(pcbody, (row, i2) => {
          let getRiskLevelAndStrength = function (toolAddress, questionId) {
            return {
              level: e.evaluationData.toolScores[toolAddress].riskCategory,
              strength:
                questionId === 'N/A'
                  ? questionId
                  : e.evaluationData.data[`${toolAddress}${questionId}`]?.text
            };
          };
          if (i2 === 0) {
            // assessment
            row.push(
              makeCell(
                `Assessment ${i + 1} ${$filter('dynamicDate')(
                  e.updatedAt,
                  'longDate'
                )}`,
                undefined,
                undefined,
                2
              )
            );
          } else if (i2 === 1) {
            // level and strength column headers
            row.push(makeCell(`Level`));
            row.push(makeCell(`Strength`));
          } else {
            let toolAddress;
            let questionId;
            switch (row[0].content) {
              case '1. Offenses/Dispositions':
                toolAddress = `119>107>`;
                questionId = 'N/A';
                break;
              case '2. Family/Parenting':
                toolAddress = '119>108>';
                questionId = 'Q-H9vlzzx_4';
                break;
              case '3. Education/Employment':
                toolAddress = '119>109>';
                questionId = 'Q-FVHmrUKUB';
                break;
              case '4. Peer Relations':
                toolAddress = '119>110>';
                questionId = 'Q-95A6K9bHc';
                break;
              case '5. Substance Abuse':
                toolAddress = '119>111>';
                questionId = 'Q-ARYYBrmxc';
                break;
              case '6. Leisure/Recreation':
                toolAddress = '119>112>';
                questionId = 'Q-A-uIhgljj';
                break;
              case '7. Personality/Behavior':
                toolAddress = '119>113>';
                questionId = 'Q-2kJwuWOdL';
                break;
              case '8. Attitudes/Orientation':
                toolAddress = '119>114>';
                questionId = 'Q-fXCMdpKA-';
                break;
            }
            let res = getRiskLevelAndStrength(toolAddress, questionId);
            row.push(makeCell(`${res.level}`));
            if (typeof res.strength === 'boolean') {
              row.push(makeCell(`${row.strength ? 'X' : ''}`));
            } else {
              row.push(makeCell('N/A'));
            }
          }
        });
      });
      pcTable.content.body = pcbody;
      report.push(pcTable);
      report.push({
        type: 'text',
        content: {
          text: `Note: Risk/need levels can range from Low to High. There is no Very High classification at the subscale level. N/A = not applicable.`
        }
      });
      report.push({
        type: 'pagebreak'
      });
      // #endregion profile comparison table
    }
    // #endregion profile comparison
    // #region item response table
    if (parseOption('Item Response Table')) {
      report.push({
        type: 'text',
        content: {
          text: `Item Response Table`,
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `The following tables indicate the items that were endorsed for each assessment.`
        }
      });
      let evaluationListRow = [''];
      forEach(evaluations, (e, i) => {
        evaluationListRow.push(
          makeCell(
            `${i + 1} ${$filter('dynamicDate')(e.updatedAt, 'longDate')}`,
            ['center']
          )
        );
      });

      let makeIrTable = function (ct, i) {
        let irTable = {
          type: 'table',
          content: {
            head: [],
            body: [],
            colWidths: [550, 225, 225]
          }
        };
        let irbody = [
          [
            makeCell('ITEM', ['center', 'bold']),
            makeCell(
              'ASSESSMENT',
              ['center', 'bold'],
              undefined,
              evaluations.length
            )
          ],
          evaluationListRow
        ];
        irbody.push([]);
        irbody.push([
          makeCell(
            `${i + 1}. ${ct.flyoutName}`,
            ['bold'],
            undefined,
            evaluations.length + 1
          )
        ]);
        forEach(ct.codingFormItems, (cfi, cfiI) => {
          let row = [
            makeCell(`${String.fromCharCode(97 + cfiI)}. ${cfi.riskFactor}`)
          ];
          forEach(evaluations, (e) => {
            row.push(
              makeCell(
                e.evaluationData.data[`119>${ct.id}>${cfi.id}`]?.text === 'Yes'
                  ? 'X'
                  : '',
                ['center']
              )
            );
          });
          irbody.push(row);
        });
        irTable.content.body = irbody;
        report.push(irTable);
      };
      forEach(part1Tool.childTools, (ct, i) => {
        makeIrTable(ct, i);
      });
    }
    // #endregion item response table
    report.push({
      type: 'text',
      content: {
        text: `--------------------`
      }
    });
    report.push({
      type: 'text',
      content: {
        text: `Date Printed: ${$filter('dynamicDate')(new Date(), 'fullDate')}`
      }
    });
    let evaluationIdsList = '';
    forEach(evaluations, (e, i) => {
      evaluationIdsList += i === 0 ? e.id : `, ${e.id}`;
    });
    report.push({
      type: 'text',
      content: {
        text: `End of Report: (Assessment # ${evaluationIdsList})`
      },
      styles: ['bold']
    });
    return report;
  }
};
