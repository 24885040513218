'use strict';

import { State, Getter, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardClientRequestsView {
  tableProps: {}[] = [];
  tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  tablePropsIcr: {}[] = [];
  tableItemsIcr: {}[] = [];
  tableActionsIcr: {}[] = [];
  searchTextIcr: string = '';

  tablePropsOcr: {}[] = [];
  tableItemsOcr: {}[] = [];
  tableActionsOcr: {}[] = [];
  searchTextOcr: string = '';

  @Inject $modals;
  @Inject $auth;
  @Inject $store;
  @Inject $api;
  @Inject errorHandler;
  @Inject utils;

  @State clientRequests;
  @State institutions;
  @State me;
  @Getter isAdmin;
  @Action('clientRequests/setRelationalData') setRelationalData;

  $onInit() {
    const $vm = this;

    this.tableItems = this.clientRequests.items;

    this.setRelationalData();

    this.tableProps = [
      {
        label: 'Client Name',
        value: ({ client }) =>
          client.lName || client.fName || client.mName
            ? `${client.lName}, ${client.fName} ${client.mName}`
            : `${client.localId}`
      },
      {
        label: 'Target Institution',
        value: 'targetInst.name'
      },
      {
        label: 'Previous Institution',
        value: 'previousInst.name'
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          // GET ==> `/api/invites/${id}/resend`
          {
            label: 'Approve',
            icon: 'check',
            fn: (val) => $vm.approveClientRequest(val)
            // hide: () => !$vm.$auth.hasAccess()
          },
          //
          {
            label: 'Deny',
            icon: 'ban',
            fn: (val) => $vm.denyClientRequest(val)
            // hide: () => !$vm.$auth.hasAccess()
          },
          // DELETE ==> '/api/invites/' + item.inviteId
          {
            label: 'Delete Client Request',
            icon: 'remove',
            fn: (val) => $vm.removeClientRequest(val)
            // hide: () => !$vm.$auth.hasAccess()
          }
        ]
      }
    ];

    this.tablePropsIcr = [
      {
        label: 'Client Name',
        value: ({ client }) =>
          client.lName || client.fName || client.mName
            ? `${client.lName}, ${client.fName} ${client.mName}`
            : `${client.localId}`
      },
      {
        label: 'Target Institution',
        value: 'targetInst.name'
      },
      {
        label: 'Previous Institution',
        value: 'previousInst.name'
      }
    ];

    this.tableActionsIcr = [
      {
        //
      }
    ];

    this.tablePropsOcr = [
      {
        label: 'Client Name',
        value: ({ client }) =>
          client.lName || client.fName || client.mName
            ? `${client.lName}, ${client.fName} ${client.mName}`
            : `${client.localId}`
      },
      {
        label: 'Target Institution',
        value: 'targetInst.name'
      },
      {
        label: 'Previous Institution',
        value: 'previousInst.name'
      }
    ];

    this.tableActionsOcr = [
      {
        label: 'Approve',
        icon: 'checkmark',
        fn: (val) => $vm.approveClientRequest(val)
        // hide: () => !$vm.$auth.hasAccess()
      },
      //
      {
        label: 'Deny',
        icon: 'ban',
        fn: (val) => $vm.denyClientRequest(val)
        // hide: () => !$vm.$auth.hasAccess()
      }
    ];
  }

  async approveClientRequest(clientReq) {
    let res;

    try {
      res = await this.$api.clientRequests.accept(clientReq.key);
    } catch (err) {
      this.errorHandler(err);
    }

    let { feedback, error } = res.data;

    if (error) {
      this.errorHandler(res);

      return;
    }

    this.$store.dispatch('clients/list');

    this.$store.commit('clientRequests/delete', clientReq);

    // if (this.activeRole.roleId === 7 || this.activeRole.roleId === 8) {
    //   this.clientRequests.splice(index, 1);
    //   this.getAllClients();
    // } else {
    //   this.outgoingClientRequests.splice(index, 1);
    //   this.getClientsForInstitution();
    // }

    // this.notificationNumbers.setClientRequests(null, true, 1);

    this.utils.notify('success', {
      title: 'Client Request Approved',
      message: feedback
    });
  }

  async denyClientRequest(clientReq) {
    let res;

    try {
      res = await this.$api.clientRequests.deny(clientReq.id);
    } catch (err) {
      this.errorHandler(err);
    }

    let { feedback, error } = res.data;

    if (error) {
      this.errorHandler(res);

      return;
    }

    this.$store.commit('clientRequests/delete', clientReq);

    this.utils.notify('success', {
      title: 'Client Request Denied',
      message: feedback
    });

    this.$scope.$broadcast('refreashTable');
  }

  removeClientRequest(clientReq) {
    // dsc.delete(row, 'client request');
  }
}

export default DashboardClientRequestsView;
