'use strict';

import angular from 'angular';

export default angular
  .module('app.sideNavItem', [])
  .directive('sideNavItem', ($state, $store, $auth) => {
    'ngInject';

    return {
      restrict: 'E',
      require: '^^sideNav',
      replace: true,
      scope: {
        route: '<'
      },
      template: require('./side-nav-item.html'),
      link: (scope) => {
        let { name, data } = scope.route;

        var hidden = !$auth.hasAccess(data.authenticate);

        scope.$on('permissionProfileSet', () => {
          hidden = !$auth.hasAccess(data.authenticate);
        });

        Object.defineProperties(scope, {
          label: {
            enumerable: true,
            get: () => data.title
          },
          marker: {
            enumerable: true,
            get: () => data.sideNavMarker
          },
          active: {
            enumerable: true,
            get: () => $state.current.name == name
          },
          hidden: {
            enumerable: true,
            get: () => hidden
          },
          action: {
            enumerable: true,
            value: () => {
              $state.go(name, { loadOnClick: data.loadOnClick });
            }
          },
          badgeNum: {
            enumerable: true,
            get: () => (data.itemBadge ? data.itemBadge($store) : 0)
          }
        });
      }
    };
  }).name;
