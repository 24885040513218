'use strict';

import angular from 'angular';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class EditInviteModalController extends Modal {
  @Inject $scope;
  @Inject $api;
  @Inject $util;
  @Inject notify;

  async $setup() {
    let $props = this.$props;
    this.invite = $props.invite;
  }

  async updateInvite() {
    this.formSubmitted = true;
    try {
      await this.$api.gearsManager.updateInvite(
        { inviteId: this.invite.id },
        this.invite
      );
      this.notify.display('Invite Updated Successfully', 'success');
      this.$close(this.invite);
    } catch (err) {
      this.notify.display(err, 'error');
    } finally {
      this.formSubmitted = false;
    }
  }
}

export default angular
  .module('app.editInviteModal', [])
  .directive('editInviteModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./edit-invite.html'),
    controller: EditInviteModalController,
    controllerAs: 'vm'
  })).name;
