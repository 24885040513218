import angular, { ITimeoutService } from 'angular';

export interface DelayService {
  /**
   * Create a promise that will resolve after a specified number of milliseconds.
   *
   * @param ms The number of milliseconds the promise will resolve after.
   * @return A promise.
   */
  (ms: number): Promise<unknown>;
}

/**
 * ...
 */
export type IDelayService = DelayService;

declare module 'angular' {
  namespace gears {
    type IDelayService = DelayService;
    type IWaitService = DelayService;
  }
}

function delayService($timeout: ITimeoutService) {
  'ngInject';

  return ((ms = 0) =>
    new Promise((resolve) => $timeout(resolve, ms))) as IDelayService;
}

export default angular.module('app.$delay', []).service('$delay', delayService)
  .name;
