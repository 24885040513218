'use strict';
import angular from 'angular';
import * as _ from 'lodash';
import shortid from 'shortid';
import { Getter } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardCaseManagementView {
  @Getter isAdmin;

  @Inject $api;
  @Inject $state;
  @Inject $scope;
  @Inject $timeout;
  @Inject $modals;
  @Inject $rootScope;
  @Inject CasePlan;
  @Inject $util;
  @Inject notify;
  @Inject $store;
  @Inject toolUtils;
  @Inject evlUtils;

  async $onInit() {
    // Live DCM
    if (!this.toolUtils.caseplan) {
      console.error('No Case Plan Detected on Tool Utils');
      this.$state.go('dashboardTools');
    }
    this.case = this.toolUtils.caseplan
      ? this.CasePlan.CasePlan(
          this.toolUtils.caseplan,
          this.evlUtils?.evaluation?.tool
        )
      : this.CasePlan.SampleCasePlan();
    this.caseplanOptions = this.toolUtils.caseplanOptions;
    // this.case = this._CasePlan.CasePlan(savedCasePlan);

    this.user = this.$store.state.me;
    this.tool = this.evlUtils.evaluation.tool;

    this.sections = this.case.sections;
    this.activeSection = this.case.first;
    this.isValid = true;
  }

  prevSection() {
    this.activeSection = this.activeSection.prevSection;
  }

  nextSection() {
    this.activeSection = this.activeSection.nextSection;
  }

  goToSection(section) {
    this.activeSection = _.find(this.case.sections, {
      title: section.title,
      number: section.number
    });
  }

  async addSection() {
    let itemProps = [
      {
        type: 'string',
        placeholder: '0',
        value: 0,
        label: 'Number',
        key: 'number'
      },
      {
        type: 'string',
        value: '',
        label: 'Title',
        key: 'title'
      }
    ];

    let newSection = await this.$modals.test.generalForm(
      'ADD SECTION',
      itemProps
    );

    if (newSection) this.case.section(newSection);
  }

  async addSubSection(section) {
    let itemProps = [
      {
        type: 'string',
        placeholder: '0.0',
        value: 0,
        label: 'Number',
        key: 'number'
      },
      {
        type: 'string',
        value: '',
        label: 'Title',
        key: 'title'
      },
      {
        type: 'string',
        textarea: true,
        value: '',
        label: 'Description',
        key: 'description'
      }
    ];

    let newSubsection = await this.$modals.test.generalForm(
      'ADD SUBSECTION',
      itemProps
    );

    if (newSubsection)
      section.subSections.push(this.CasePlan.CasePlanSubSection(newSubsection));
  }

  async addProgressTable(subsection) {
    let itemProps = [
      {
        type: 'string',
        placeholder: '0.0.0',
        value: '',
        label: 'Number',
        key: 'number'
      },
      {
        type: 'string',
        value: '',
        label: 'Title',
        key: 'title'
      },
      {
        type: 'boolean',
        value: true,
        label: 'Progress Tracker',
        key: 'progressTracker'
      }
    ];

    let newProgressTable = await this.$modals.test.generalForm(
      'ADD TABLE',
      itemProps
    );
    console.log(newProgressTable);
    if (!newProgressTable) return;
    // generate key
    newProgressTable.key = shortid.generate();
    this.$scope.$apply();

    if (newProgressTable) {
      newProgressTable.collapsible = true;
      newProgressTable.collapsed = false;
      subsection.components.push(
        this.CasePlan.ProgressTableComponent(newProgressTable)
      );
    }
  }

  addSubSectionComponent(subsection) {
    subsection.components.push(this.CasePlan.CasePlanSubSectionComponent());
  }

  deleteSubSectionComponent(subsection, component) {
    _.remove(subsection.components, component);
  }

  delete(object, parentArray, reset) {
    _.remove(parentArray, object);
    if (reset) this.resetActiveSection();
  }

  async editSubSectionComponent(component, parent) {
    if (component.hasOwnProperty('progressTracker')) {
      // dealing with progress table
      let originalTitle = component.title;
      let originalKey = component.key;

      let itemProps = [
        {
          type: 'string',
          placeholder: '0.0.0',
          value: component.number,
          label: 'Number',
          key: 'number'
        },
        {
          type: 'string',
          value: component.key,
          label: 'Key',
          key: 'key'
        },
        {
          type: 'string',
          value: component.title,
          label: 'Title',
          key: 'title'
        },
        {
          type: 'boolean',
          value: component.progressTracker,
          label: 'Progress Tracker',
          key: 'progressTracker'
        }
      ];

      let updatedProgressTable = await this.$modals.test.generalForm(
        'EDIT TABLE',
        itemProps
      );

      _.forEach(updatedProgressTable, function (val, key) {
        component[key] = val;
      });

      // update name of children tables
      if (component.tables && component.tables.length) {
        let indexOfPT = _.indexOf(component.tables, (table) => {
          return table.title.includes('CONDITION RULES');
        });
        if (!component.progressTracker && indexOfPT > -1) {
          // remove progress tracker table
          component.tables.splice(indexOfPT, 1);
        } else if (component.progressTracker && indexOfPT < 0) {
          // no progress tracker table, but we need one
          let updatedComponent = await this.CasePlan.ProgressTableComponent(
            component
          );
          for (let i = 0; i < parent.components.length; i++) {
            if (parent.components[i].key === updatedComponent.key)
              parent.components[i] = updatedComponent;
          }
        }

        _.forEach(component.tables, (table) => {
          table.title = table.title.replace(originalTitle, component.title);
          table.key = table.key.replace(originalKey, component.key);
        });
      }

      this.$rootScope.$apply();
    }
  }

  async editSectionInformation(items) {
    if (!this.isAdmin) {
      this.notify.display('You must be a GEARS Admin to edit', 'warning');
      return;
    }
    console.log('edit section information items: ', items);
    this.$modals.util.generalEdit(items);
  }

  async submitCaseTemplate() {
    this.submitting = true;
    console.log('this.case: ', angular.copy(this.case));
    let submission = this.case.toJson();

    console.log('submission: ', submission);

    // assign institution id if submitting for an institution
    let institutionId = this.caseplanOptions.institution
      ? this.caseplanOptions.institution.id
      : null;
    if (this.user.institution?.id && !institutionId) {
      institutionId = this.user.activeRole.institutionId;
    }

    // extract toolId and toolCommitId
    console.log('this.caseplanOptions: ', this.caseplanOptions);
    console.log('this.tool: ', this.tool);
    let toolId = this.tool ? this.tool.id : null;
    if (!toolId && this.caseplanOptions.toolCommit)
      toolId = this.caseplanOptions.toolCommit.toolUsed;
    if (!toolId) {
      this.notify.display('Could not gather tool id', 'error');
      this.submitting = false;
      return;
    }

    let toolCommitId = this.caseplanOptions.toolCommit
      ? this.caseplanOptions.toolCommit.id
      : this.tool.commitId;
    if (!toolCommitId) {
      this.notify.display('Could not gather tool commit id', 'error');
      this.submitting = false;
      return;
    }

    let response = await this.CasePlan.SaveCasePlanTemplate(
      submission,
      toolId,
      toolCommitId,
      institutionId,
      this.caseplanOptions
    );
    console.log('save case plan template response: ', response);

    this.submitting = false;
    if (response && response.status === 200 && this.isAdmin) {
      // redirect back to tool management
      this.$state.go('dashboardTools');
      this.toolUtils.goToEditTool(null, toolId);
    } else if (response && response.status === 200) {
      // we're an institution admin and need to make this the latest live case plan template
      let updateStatusResponse =
        await this.$api.icpt.setCasePlanTemplateCommitStatus(
          {
            instId: institutionId,
            tmpltId: response.data?.casePlanTemplateId,
            commitId: response.data?.id
          },
          {
            status: 'Live'
          }
        );
      if (updateStatusResponse.status === 204) this.$state.go('dashboardTools');
    }
  }

  resetActiveSection() {
    this.activeSection = this.case.first;
  }

  resetCase() {
    this.case = this.CasePlan.SampleCasePlan();
    this.sections = this.case.sections;
    this.activeSection = this.case.first;
  }

  log(item) {
    console.log(item);
  }
}

export default DashboardCaseManagementView;
