import angular from 'angular';
import { forEach } from 'lodash';

/**
 * ...
 */
export type IReincodeService = ReincodeService;

declare module 'angular' {
  namespace gears {
    type IReincodeService = ReincodeService;
  }
}

/**
 * ...
 *
 * @param text ...
 * @param reverse ...
 * @return ...
 */
function reincodeText(text: string, reverse: boolean) {
  if (!reverse) {
    text = text.replace(/&#34;/g, '"');
    text = text.replace(/&lt;/g, '<');
    text = text.replace(/&gt;/g, '>');
    text = text.replace(/&#10;/g, '\n');
    text = text.replace(/&#8217;/g, "'");
    text = text.replace(/&#47;/g, '/');
    text = text.replace(/&#92;/g, '\\');
    text = text.replace(/&amp;/g, '&');
    // text = text.replace(/&#59;/g, ';'); // causes issues with encoding
    text = text.replace(/&#58;/g, ':');
    // text = text.replace(/&#36;/g, '&'); // causes issues with encoding
    text = text.replace(/&#37;/g, '%');
    text = text.replace(/&#40;/g, '(');
    text = text.replace(/&#41;/g, ')');
    text = text.replace(/&#36;/g, '$');
    text = text.replace(/&#8220;/g, '“');
    text = text.replace(/&#8221;/g, '”');
  }

  if (reverse) {
    text = text.replace(/"/g, '&#34;');
    text = text.replace(/</g, '&lt;');
    text = text.replace(/>/g, '&gt;');
    text = text.replace(/\n/g, '&#10;');
    text = text.replace(/'/g, '&#8217;');
    text = text.replace(/\\/g, '&#92;');
    text = text.replace(/\//g, '&#47;');
    // text = text.replace(/&/g, '&amp;'); // causes issues with encoding
    // text = text.replace(/;/g, '&#59;'); // causes issues with encoding
    text = text.replace(/:/g, '&#58;');
    text = text.replace(/\$/g, '&#36;');
    text = text.replace(/%/g, '&#37;');
    text = text.replace(/\(/g, '&#40;');
    text = text.replace(/\)/g, '&#41;');
    text = text.replace(/“/g, '&#8220;');
    text = text.replace(/”/g, '&#8221;');
  }

  return text;
}

/**
 * ...
 *
 * @param obj ...
 * @param reverse ...
 */
function parseObject(obj: Record<string, unknown>, reverse: boolean): void {
  forEach(obj, (value, key) => {
    if (value && typeof value === 'string') {
      obj[key] = reincodeText(value, reverse);
    }

    if (obj[key] && typeof obj[key] === 'object') {
      parseObject(obj[key], reverse);
    }
  });
}

/**
 * ...
 */
export class ReincodeService {
  /**
   * ...
   *
   * @param text ...
   * @param reverse ...
   * @return ...
   */
  text(text: string, reverse?: boolean) {
    if (!reverse) reverse = false;
    return typeof text !== 'string' ? text : reincodeText(text, reverse);
  }

  /**
   * ...
   *
   * @param o ...
   * @param reverse ...
   * @return ...
   */
  object(o: Record<string, unknown>, reverse: boolean) {
    forEach(o, (val, key) => {
      if (o[key] && typeof o[key] === 'string') {
        o[key] = reincodeText(val, reverse);
      }
    });

    return o;
  }

  /**
   * ...
   *
   * @param o ...
   * @param reverse ...
   * @return ...
   */
  fullObject<T extends Record<string, unknown>>(o: T, reverse = false) {
    parseObject(o, reverse);

    return o;
  }
}

export default angular
  .module('app.$reincode', [])
  .service('$reincode', ReincodeService).name;
