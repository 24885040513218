import angular from 'angular';

import { states } from './states';

/**
 * ...
 */
export function stateConfig($stateProvider: angular.ui.IStateProvider) {
  'ngInject';

  for (let { component, ...props } of states) {
    const state: angular.ui.IState = { ...props };

    if (component) {
      state.controller = component.controller;
      state.template = component.template;
    }

    $stateProvider.state(state);
  }
}

/**
 * ...
 */
export function stateTransitions(
  $rootScope: angular.IRootScopeService,
  $window: angular.IWindowService,
  $location: angular.ILocationService,
  $transitions: angular.ui.ITransitionService,
  $state: angular.ui.IStateService,
  $auth: angular.gears.IAuthService,
  $store: angular.gears.IStoreService
) {
  'ngInject';

  let transitioning = false;

  /**
   * Navigate to the "login" page if after logout if there is no ongoing
   * transition. If there is, the redirect will be handeled there.
   */
  const onLoggedOut = () => {
    if (!transitioning) $state.go('login');
  };

  /**
   * ...
   */
  const onBefore = async (transition: angular.ui.ITransition) => {
    transitioning = true;

    const to = transition.to();
    let { authenticate: reqAuth, requiresUnauth } = to.data || {};

    if (!reqAuth && !requiresUnauth) return;

    let nextRoute = to.name;
    let loggedIn = $auth.loggedIn;

    if (!loggedIn) {
      $store.commit('isAuthenticating', true);
    }

    loggedIn = await $auth.loggedInAsync;

    $store.commit('isAuthenticating', false);

    if (!loggedIn) {
      nextRoute = requiresUnauth ? nextRoute : 'login';
    } else if (
      requiresUnauth ||
      (Array.isArray(reqAuth) && !$auth.hasAccess(reqAuth))
    ) {
      nextRoute = 'dashboardHome';
    }

    if (nextRoute && nextRoute !== to.name) {
      console.log('REDIRECT!!!', `${to.name} --> ${nextRoute}`);
      return transition.router.stateService.target(nextRoute);
    }
  };

  /**
   * ...
   */
  const onSuccess = (transition: angular.ui.ITransition) => {
    transitioning = false;

    const to = transition.to();

    $store.commit('ON_MAIN', $state.includes('main'));
    $store.commit('ON_DASHBOARD', $state.includes('dashboard'));
    $store.commit('ON_TOOL_CREATOR', $location.path().includes('tool-creator'));

    $window.document.title = `${to.data?.title} | MHS GEARS`;
  };

  $rootScope.$on('loggedOut', onLoggedOut);

  $transitions.onBefore({}, onBefore);
  $transitions.onSuccess({}, onSuccess);
}
