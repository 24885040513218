import { Ng } from '@angular';
import { IFormController } from 'angular';

interface ModelScopeAttributes {
  $title: string;
  $props: GenericObject | null;
  $dismissable: boolean;
  $maximisable: boolean;
  $maximised: boolean;
  $forms: Record<string, IFormController> | null;
  $resolve: {
    title: string;
    dismissable: boolean;
    maximisable: boolean;
    props: IndexableObject | null;
  };
  $maximize(value?: unknown): unknown;
  $minimize(value?: unknown): unknown;
  $dismiss(value?: unknown): unknown;
  $close(value?: unknown): unknown;
}

/**
 * ...
 */
export const ModalProp: PropertyDecorator = (_: Modal, key: string) => {
  const get = function (this: Modal) {
    return this.$props[key];
  };

  return { get, enumerable: true };
};

/**
 * ...
 */
export class Modal extends Ng<ModelScopeAttributes> {
  loadingData = false;
  submitting = false;

  private dialogEl!: angular.IElement;

  get $modal() {
    return this.$scope.$resolve;
  }

  get $title() {
    return this.$scope.$title;
  }

  get $dismissable() {
    return this.$scope.$dismissable;
  }

  get $maximisable() {
    return this.$scope.$maximisable;
  }

  get $maximised() {
    return this.$scope.$maximised;
  }

  get $forms() {
    return this.$scope.$forms ?? {};
  }

  // get $props() {
  //   return this.$scope.$props;
  // }

  $onInit() {
    this.$$setup();
  }

  /**
   * ...
   */
  $maximize() {
    return this.$scope.$maximize();
  }

  /**
   * ...
   */
  $minimize() {
    return this.$scope.$minimize();
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  $dismiss(value?: unknown) {
    return this.$scope.$dismiss(value);
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  $close(value?: unknown) {
    return this.$scope.$close(value);
  }

  /**
   * ...
   */
  $setup?(): void | Promise<void>;

  /**
   * ...
   */
  private async $$setup() {
    const { $resolve } = this.$scope;

    if (!$resolve) return;

    this.$scope.$title = $resolve.title;
    this.$scope.$dismissable = $resolve.dismissable;
    this.$scope.$maximisable = $resolve.maximisable;
    this.$scope.$forms = this.$scope.$forms ?? {};
    this.$scope.$maximize = () => (this.$scope.$maximised = true);
    this.$scope.$minimize = () => (this.$scope.$maximised = false);

    this.$$props = (this.$scope.$props = $resolve.props) ?? {};

    this.dialogEl = this.$element.closest('.modal-dialog');

    this.$watch('$maximised', this.onViewStateChanged);

    let setupError: unknown = null;

    try {
      this.$setup?.();
    } catch (err) {
      setupError = err;
    }

    if (!setupError) return;

    console.error(
      '[mixin:Modal] An error occured while setting up the modal.',
      setupError
    );

    await this.$delay(1000);

    this.$notify.error({
      title: 'There was an issue loading the modal.',
      message: setupError.message
    });
  }

  /**
   * ...
   */
  private readonly onViewStateChanged = (value: boolean) => {
    if (value) {
      this.dialogEl.addClass('modal-maximised');
    } else {
      this.dialogEl.removeClass('modal-maximised');
    }
  };
}

export default Modal.$module;
