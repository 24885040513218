'use strict';

import angular from 'angular';
import { format, getUnixTime } from 'date-fns';

import { State } from 'angular-store';
import { Controller, Inject, On, Watch } from '@/decorators/ngCtrl';

import { forEachRight } from 'lodash';

type GraphType = {
  name: string,
  type: string
};

@Controller
class AnalyticsSandboxComponent {
  data: {} = {};
  filters: {} = {};
  dataType: string = 'evaluations';
  parsingData: boolean = false;

  graphTypes: GraphType[] = [
    {
      name: 'Line',
      type: 'chart-line'
    },
    {
      name: 'Bar/Histogram',
      type: 'chart-bar'
    },
    {
      name: 'Pie',
      type: 'chart-pie'
    },
    {
      name: 'Doughnut',
      type: 'chart-doughnut'
    }
  ];

  @Inject $scope;
  @Inject $element;
  @Inject $rootScope;
  @Inject $filter;
  @Inject $store;

  @State analytics;
  @State institutions;
  @State evaluations;
  @State tool;

  get loading() {
    return this.evaluations.loading;
  }

  bgR = 0;
  bgG = 230;
  bgB = 183;
  bgA = 0.2;

  lnR = 59;
  lnG = 255;
  lnB = 176;
  lnA = 1;

  lineChartColors = [];

  makeStyle() {
    this.lineChartColors = [
      {
        backgroundColor: `rgba(${this.bgR}, ${this.bgG}, ${this.bgB}, ${this.bgA})`,
        // pointBorderColor: '#80b6f4',
        pointBackgroundColor: 'rgba(59, 255, 176, 1)',
        borderColor: `rgba(${this.lnR}, ${this.lnG}, ${this.lnB}, ${this.lnA})`
      }
    ];
  }

  $onInit() {
    Chart.defaults.global.defaultFontSize = 14;
    Chart.defaults.global.defaultFontColor = '#fff';
    Chart.defaults.global.elements = {
      arc: {
        backgroundColor: '#fff',
        borderColor: 'rgba(255,255,255,0.5)',
        borderWidth: 2
      },
      line: {
        tension: 0.3,
        borderWidth: 3,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0,
        borderJoinStyle: 'miter',
        capBezierPoints: true,
        fill: true
      },
      point: {
        radius: 3,
        pointStyle: 'circle',
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderWidth: 1,
        borderColor: 'rgba(0,0,0,0)',
        hitRadius: 1,
        hoverRadius: 4,
        hoverBorderWidth: 1
      },
      rectangle: {
        backgroundColor: 'rgba(0,0,0,0.1)',
        borderWidth: 2,
        borderColor: 'rgba(0,0,0,0.1)',
        borderSkipped: 'bottom'
      }
    };

    this.makeStyle();

    this.$scope.$watchGroup(
      [
        () => this.bgR,
        () => this.bgG,
        () => this.bgB,
        () => this.bgA,
        () => this.lnR,
        () => this.lnG,
        () => this.lnB,
        () => this.lnA
      ],
      () => {
        this.makeStyle();
      }
    );

    this.data.options = {
      scales: {
        xAxes: [
          {
            gridLines: {
              borderDash: [2, 2],
              lineWidth: 1,
              color: 'rgba(255,255,255,0.2)'
            }
          }
        ],
        yAxes: [
          {
            gridLines: {
              borderDash: [2, 2],
              lineWidth: 1,
              color: 'rgba(255,255,255,0.2)'
            }
          }
        ]
      },
      responsive: true,
      maintainAspectRatio: false
    };

    this.data.data = [[]];

    var aYearAgo = new Date();
    aYearAgo.setFullYear(aYearAgo.getFullYear() - 1);

    this.filters.fromDate = this.$filter('dynamicDate')(
      aYearAgo,
      'MM/dd/yyyy',
      'UTC'
    );
    this.filters.toDate = this.$filter('dynamicDate')(
      new Date(),
      'MM/dd/yyyy',
      'UTC'
    );
    this.filters.graphType = 'chart-line';
    this.filters.institution = 'all';
    this.filters.tool = 'all';

    if (this.dataType === 'evaluations') {
      this.filterSandboxData();
    }

    this.$scope.$watchCollection('vm.analytics.evaluationsPerMonth', () => {
      this.filterSandboxData();
    });
  }

  setFilter(dataType) {
    this.dataType = dataType;

    this.filterSandboxData();
  }

  filterSandboxData() {
    const DATA = this.data;
    const FILTERS = this.filters;
    const DATA_TYPE = this.dataType;
    this.parsingData = true;

    let m = format(new Date(this.filters.fromDate), 'MM/dd/yyyy');
    let endMonth = getUnixTime(new Date(this.filters.toDate));

    DATA.labels = [];
    forEachRight(this.analytics.evaluationsPerMonth, (month) => {
      DATA.labels.push(`${month.monthIndex + 1}/${month.year}`);
      DATA.data[0].push(month.total);
      DATA.series = 'Evaluations';
    });
    this.parsingData = false;
  }

  // filterSandboxData() {
  //   const DATA = this.data;
  //   const FILTERS = this.filters;
  //   const DATA_TYPE = this.dataType;

  //   this.parsingData = true;

  //   DATA.data = [[]];

  //   // prep x-axis labels via time in months
  //   var toDate = new Date(FILTERS.toDate);
  //   var toDateMonth = toDate.getMonth();

  //   var fromDate = new Date(FILTERS.fromDate);
  //   var fromDateMonth = fromDate.getMonth();

  //   var yearDiff = toDate.getFullYear() - fromDate.getFullYear();
  //   var monthDiff = 0;

  //   if (fromDateMonth < toDateMonth) {
  //     monthDiff = toDateMonth - fromDateMonth;
  //   } else if (toDateMonth < fromDateMonth && yearDiff > 0) {
  //     yearDiff--;
  //     monthDiff = 11 - fromDateMonth + toDateMonth;
  //   }

  //   var monthRange = yearDiff * 12 + monthDiff;
  //   var monthYear = fromDateMonth + 1 + '/' + fromDate.getFullYear();

  //   DATA.labels = [];

  //   for (var i = 0; i <= monthRange; i++) {
  //     DATA.labels.push(monthYear);
  //     DATA.data[0].push(0);

  //     monthYear = monthYear.split('/');

  //     if (parseInt(monthYear[0], 10) + 1 > 12) {
  //       monthYear[1] = parseInt(monthYear[1], 10) + 1;
  //       monthYear[0] = 1;
  //     } else {
  //       monthYear[0] = parseInt(monthYear[0], 10) + 1;
  //     }

  //     monthYear = monthYear[0] + '/' + monthYear[1];
  //   }

  //   var uniqueUsers = [];

  //   DATA.series =
  //     DATA_TYPE == 'activeUsers' ? ['Users'] : ['Evaluations Started'];

  //   this.evaluations.items.forEach(evl => {
  //     if (evl.status !== 2) {
  //       return;
  //     }

  //     let completeData = new Date(evl.updatedAt);
  //     completeData =
  //       completeData.getMonth() + 1 + '/' + completeData.getFullYear();

  //     if (DATA_TYPE == 'activeUsers') {
  //       let userFound = false;

  //       uniqueUsers.forEach(user => {
  //         if (user.id === evl.evaluatorId) {
  //           if (user.date === completeData) {
  //             userFound = true;
  //           }
  //         }
  //       });

  //       if (userFound) {
  //         return;
  //       }
  //     }

  //     if (
  //       (FILTERS.institution != 'all' &&
  //         evl.institutionId !== FILTERS.institution) ||
  //       (FILTERS.tool != 'all' && evl.toolUsed !== FILTERS.tool)
  //     ) {
  //       return;
  //     }

  //     DATA.labels.every((label, i) => {
  //       if (label !== completeData) {
  //         return true;
  //       }

  //       DATA.data[0][i]++;

  //       if (DATA_TYPE == 'activeUsers') {
  //         uniqueUsers.push({
  //           userId: evl.evaluatorId,
  //           date: completeData
  //         });
  //       }

  //       return false;
  //     });
  //   });

  //   this.parsingData = false;
  // }
}

export default angular
  .module('app.analyticsSandbox', [])
  .directive('analyticsSandbox', () => {
    return {
      restrict: 'E',
      // replace: true,
      scope: {},
      template: require('./analytics-sandbox.html'),
      controller: AnalyticsSandboxComponent,
      controllerAs: 'vm'
    };
  }).name;
