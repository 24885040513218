'use strict';

import angular from 'angular';
import { find } from 'lodash';

import { State, Action, Getter } from 'angular-store';
import { Controller, Inject, Watch } from '@/decorators/ngCtrl';

@Controller
class DashboardViewEvaluationView {
  @Inject $stateParams;
  @Inject $location;
  @Inject $store;
  @Inject $state;
  @Inject $api;
  @Inject $modals;
  @Inject notify;
  @Inject $reincode;

  @State evaluations;
  @State clients;
  @State tools;
  @Getter('evaluations/getById') getEvaluationById;
  @Getter('tools/getById') getToolById;

  @Action('evaluations/get') getEvaluation;
  @Action('tools/list') listTools;
  @Action('tools/getToolData') getToolData;
  @Action('users/list') listUsers;

  get evalId() {
    return this.$stateParams.evalId;
  }

  get users() {
    return this.$store.state.users.items;
  }

  async $onInit() {
    this.loading = true;
    this.viewingEvaluation = true;
    this.evaluation = await this.getEvaluationById(this.evalId);
    if (!this.evaluation) {
      this.notify.display('Could not find evaluation', 'error');
      this.$state.go('dashboardHome');
      this.loading = false;
      return;
    }

    this.client = find(this.clients.items, { id: this.evaluation.clientId });
    if (!this.client) {
      this.notify.display('Could not find client for evaluation', 'error');
      this.$state.go('dashboardHome');
      this.loading = false;
      return;
    }

    try {
      this.evaluation = await this.getEvaluation({
        instId: this.client.institution?.id,
        sbGrpId: this.client.subGroup?.id,
        clntId: this.client.id,
        evalId: this.evaluation.id
      });
    } catch (err) {
      this.notify.display(err, 'error');
      this.$state.go('dashboardHome');
      this.loading = false;
      return;
    }

    if (!this.tools.items.length) await this.listTools();

    const toolCommitId = this.evaluation.toolCommitId;
    const toolId = this.evaluation.toolUsed;
    const evalData = this.evaluation.data
      ? this.evaluation.data
      : this.evaluation;
    if (!evalData) {
      this.notify.display(
        'Could not find evaluation data for evaluation',
        'error'
      );
      this.$state.go('dashboardHome');
      this.loading = false;
      return;
    }
    this.evaluation = this.$reincode.fullObject(evalData);

    this.tool = _.find(this.tools.items, { id: toolId });

    if (!this.tool.toolData) {
      try {
        this.tool.toolData = await this.getToolData({
          toolId,
          commitId: toolCommitId
        });
      } catch (err) {
        this.notify.display(err, 'error');
        this.$state.go('dashboardHome');
        this.loading = false;
        return;
      }
    }

    if (!this.users.length) await this.listUsers();

    this.evaluation = {
      evaluation: evalData.evaluationData,
      tool: this.tool.toolData
    };

    if (this.evaluation.evaluation.interview) {
      this.evaluation.evaluation.interview.dateTime = new Date(
        this.evaluation.evaluation.interview.dateTime
      );
    }

    this.loading = false;
    this.$scope.$apply();
  }

  openMediaTable() {
    this.$modals.evaluation.manageMedia({
      institutionId: this.client?.institutionId,
      subGroupId: this.client?.subGroup?.id,
      clientId: this.client?.id,
      evaluationId: this.evaluation?.evaluation?.evaluationId
    });
  }
}

export default DashboardViewEvaluationView;
