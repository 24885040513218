import Directive, { Watch } from '@/decorators/ngDirective';

const directiveOptions = {
  name: 'fa',
  restrict: 'E',
  replace: true,
  scope: true,
  props: ['icon'],
  template: require('./fa.html')
};

@Directive(directiveOptions)
export class Fa {
  faType: string = 'r';
  faIcon: string = '';
  faAlt: boolean = false;

  get faClasses() {
    let str = `fa${this.faType} fa-${this.faIcon}`;

    if (this.$attrs.size) {
      str += ` fa-${this.$attrs.size}`;
    }

    if ('spin' in this.$attrs) {
      str += ` fa-spin`;
    }

    return str;
  }

  @Watch('icon')
  onIconChanged(val) {
    this.faIcon = (val || '').replace(
      /[A-Z]/g,
      (char) => `-${char.toLowerCase()}`
    );

    if (this.faAlt && !this.faIcon.includes('-alt')) {
      this.faIcon += '-alt';
    }
  }

  $render() {
    const { type } = this.$attrs;

    this.faType =
      type != 'l' && type != 's' && type != 'r' && type != 'b' && type != 'd'
        ? 'r'
        : type;

    this.faAlt = 'alt' in this.$attrs;
  }
}

export default Fa;
