import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { State, Getter } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

import { filter } from 'lodash';

@Controller
class CreateActivityReportRequestModalController extends Modal {
  institution: Institution = null;
  institutionAccess: Boolean = false;
  selectedGearsAdmin: Boolean = false;
  selectedInstitution: Institution = null;
  startDate: Date = new Date();
  endDate: Date = new Date();

  instSelectionModel = null;

  get instAccountId() {
    return this.institution?.accountId || this.activeInstAccountId;
  }

  get instId() {
    return this.institution?.id || this.activeInstId;
  }

  @Inject $scope;
  @Inject $store;
  @Inject $http;
  @Inject utils;
  @Inject activityReportsMonitor;

  @State(({ institutions }) => institutions.items) institutions;
  @Getter activeInstAccountId;
  @Getter activeInstId;
  @Getter isAdmin;

  async $setup() {
    this.loadingData = true;
    this.institutionAccountId = null;
    this.startDate.setHours(0, 0, 0, 0);

    const loaders = [];

    if (this.isAdmin) {
      loaders.push(this.$store.dispatch('institutions/getAll'));
    } else {
      this.institutionAccountId = this.activeInstAccountId;
    }

    await Promise.all(loaders);

    if (!this.isAdmin) {
      this.institutionAccountId = this.activeInstAccountId;
    } else {
      this.instSelectionModel = {
        items: this.institutions,
        cols: [
          {
            title: 'Name',
            key: 'name'
          }
        ]
      };
    }

    this.loadingData = false;
  }

  async sendRequest() {
    this.processing = true;

    let params = {
      startDate: this.startDate.toString(),
      endDate: this.endDate.toString()
    };
    if (this.isAdmin) {
      // params.institutions = this.selectedInstitutions.map(({ id }) => id);
      params.accountId = this.selectedGearsAdmin
        ? 'gifrgears'
        : this.selectedInstitution.id;
      params.institutionAccess = this.institutionAccess;
    } else {
      params.institutionId = this.instId;
    }

    if (!params.accountId && !params.institutionId) {
      this.utils.notify('error', 'No Institution/Account Selected');
      this.processing = false;
      return;
    }

    let res;

    try {
      res = await this.$store.dispatch('activityReports/create', params);
    } catch (err) {
      this.processing = false;
      this.utils.notify('error', 'Error - Sending Request');
      return;
    }

    this.utils.notify('success', 'Request Sent');
    this.processing = false;

    this.$close(res);
  }
}

export default angular
  .module('gifrApp.createActivityReportRequestModal', [])
  .directive('createActivityReportRequestModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./create-activity-report-request.html'),
    controller: CreateActivityReportRequestModalController,
    controllerAs: 'vm'
  })).name;
