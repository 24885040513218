/**
 * ...
 */
export const TEST_FIELD_VALUES = {
  address1: { label: 'Address 1', allowNull: true, show: true, alias: null },
  address2: { label: 'Address 2', allowNull: true, show: true, alias: null },
  address3: { label: 'Address 3', allowNull: true, show: true, alias: null },
  alias: { label: 'Alias', allowNull: false, show: true, alias: 'ALIAS' },
  city: { label: 'City', allowNull: true, show: true, alias: null },
  clinicalInfo: {
    label: 'Clinical Info',
    allowNull: true,
    show: true,
    alias: null
  },
  country: { label: 'Country', allowNull: true, show: true, alias: null },
  dob: { label: 'Date of Birth', allowNull: false, show: true, alias: null },
  email: { label: 'Email', allowNull: true, show: true, alias: null },
  ethnicity: {
    label: 'Ethnicities',
    allowNull: true,
    show: false,
    options: [
      {
        value: 'Cool',
        label: 'Cool'
      },
      {
        value: 'Awesome',
        label: 'Awesome'
      }
    ]
  },
  fName: { label: 'First Name', allowNull: false, show: true, alias: 'FN' },
  lName: { label: 'Last Name', allowNull: false, show: true, alias: 'LN' },
  mName: { label: 'Middle Name', allowNull: true, show: true, alias: 'MN' },
  phone: { label: 'Phone Number', allowNull: true, show: true, alias: null },
  postalCode: {
    label: 'Postal Code',
    allowNull: true,
    show: true,
    alias: null
  },
  stateProvince: {
    label: 'State/Province',
    allowNull: true,
    show: true,
    alias: null
  },
  type: {
    label: 'Client Types',
    allowNull: true,
    show: false,
    options: [
      { value: 'Type 1', label: 'Type 1' },
      { value: 'Type 2', label: 'Type 2' }
    ]
  }
};

/**
 * ...
 */
export const CUSTOM_FIELD_TEST_VALUES_LIST = [
  {
    allowNull: true,
    isList: false,
    key: 'LABEL',
    label: 'lABEL',
    options: null,
    show: true,
    type: 'String'
  },
  {
    allowNull: false,
    isList: true,
    key: 'My Types',
    label: 'myTypes',
    options: [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' }
    ],
    show: true,
    type: 'String'
  },
  {
    allowNull: false,
    isList: false,
    key: 'My Ethnicity',
    label: 'myEthnicity',
    options: [
      { value: 'A', label: 'A' },
      { value: 'B', label: 'B' },
      { value: 'C', label: 'C' }
    ],
    show: true,
    type: 'String'
  }
];
