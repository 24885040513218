'use strict';

class AboutView {
  constructor() {
    'ngInject';

  }
}

export default AboutView;
