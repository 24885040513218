import autoImporter from '@/modules/auto-importer';

const components = autoImporter(
  require.context(
    '@/__components',
    true,
    /(((component|directive|controller)\.(js|ts))|scss)$/
  )
);

export default components;
