'use strict';
const angular = require('angular');

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class PolicyJsoneModalController extends Modal {
  policyJson: string = '';

  @Inject $scope;
  @Inject $api;

  $setup() {
    this.getPolicyRef();
  }

  async getPolicyRef() {
    this.loadingData = true;

    const { policy } = this.$props;

    let targetPolicy;

    if (policy.institution) {
      let id = policy.institution.id;
      let { data } = await this.$api.IM.listPolicies(id);

      targetPolicy = data.find(item => item.id == policy.id);

      this.$scope.$apply();
    } else {
      targetPolicy = policy;
    }

    this.policyJson = JSON.stringify(targetPolicy, null, 4);

    this.loadingData = false;
  }
}

export default angular
  .module('app.policyJsonModal', [])
  .directive('policyJsonModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./policy-json.html'),
    controller: PolicyJsoneModalController,
    controllerAs: 'vm'
  })).name;
