const policyConfig = [
  // Resources
  {
    name: 'Location Access',
    description:
      'These are options for limiting access to specific Locations within an Institution',
    icon: 'key',
    resources: [
      {
        key: 'subGroup',
        name: 'Locations (Zone > Region > Sub Group)',
        service: ['clientmanager', 'institutionmanager']
      }
      // {
      //   key: 'template',
      //   name: 'Case Plan Templates',
      //   service: 'icpt'
      // },
      // {
      //   key: 'tool',
      //   name: 'Tools',
      //   service: 'toolcreator'
      // }
    ]
  },
  // Tool Access
  {
    name: 'Tool Access',
    description:
      'These are options for limiting access to specific Tools within an Institution',
    icon: 'wrench',
    resources: [
      {
        key: 'tool',
        name: 'Tools',
        service: ['institutionmanager']
      }
    ]
  },
  // Clients
  {
    name: 'Clients',
    icon: 'user',
    description:
      'This service allows the management of clients. These actions include editing the client itself, managing the client’s media, editing the client’s contacts, and offender history.',
    actions: {
      list: [
        'institutionmanager:ListClients',
        'clientmanager:ListClientEvaluators',
        'clientmanager:ListClientContacts',
        'clientmanager:ListOffenderHistory',
        'institutionmanager:ListSubGroupClients',
        'clientmanager:ListClientMedia'
      ],
      read: [
        'clientmanager:GetClient',
        'clientmanager:GetOffenderHistory',
        'institutionmanager:GetClient',
        'clientmanager:GetClientMedia'
      ],
      write: [
        'institutionmanager:CreateClient',
        'clientmanager:CreateClientContact',
        'institutionmanager:CreateClientContact',
        'clientmanager:UpdateClient',
        'institutionmanager:UpdateClient',
        'clientmanager:UpdateClientContact',
        'institutionmanager:UpdateClientContact',
        'clientmanager:CreateOffenderHistory',
        'clientmanager:UpdateOffenderHistory',
        'clientmanager:DeleteOffenderHistory',
        'clientmanager:AddClientToSubGroup',
        'institutionmanager:AddClientToSubGroup',
        'clientmanager:RemoveClientFromSubGroup',
        'institutionmanager:RemoveClientFromSubGroup',
        'institutionmanager:ImportClients',
        'institutionmanager:ListImportClientRequests',
        'institutionmanager:GetImportClientRequest',
        'clientmanager:UploadClientMedia',
        'clientmanager:DeleteClientMedia'
      ]
    }
  },
  // GEARS Clients
  {
    name: 'All Clients',
    icon: 'user',
    gifrAdminOnly: true,
    description:
      'This service allows the management of all clients on the system. These actions include editing the client itself, editing the client’s contacts, and offender history.',
    actions: {
      list: [
        'gearsmanager:ListClientTransferRequests',
        'gearsmanager:ListClients',
        'gearsmanager:ListSubGroupClients'
      ],
      read: ['gearsmanager:GetClient'],
      write: ['gearsmanager:CreateClient', 'gearsmanager:CreateClientContact']
    }
  },
  // GEARS Institution
  {
    name: 'All Institutions',
    icon: 'building',
    gifrAdminOnly: true,
    description:
      'This service allows the management of all institutions on the system.',
    actions: {
      list: [
        'gearsmanager:ListInstitutions',
        'gearsmanager:ListAllInstitutionPolicies',
        'gearsmanager:ListInstitutionPolicies',
        'gearsmanager:ListInstitutionTools'
      ],
      read: ['gearsmanager:GetInstitutionPolicy'],
      write: [
        'gearsmanager:CreateInstitution',
        'gearsmanager:DeleteInstitution',
        'gearsmanager:UpdateUserCap',
        'gearsmanager:AddInstitutionTools',
        'gearsmanager:RemoveInstitutionTools'
      ]
    }
  },
  // GEARS Aggregate Reports
  {
    name: 'Aggregate Reports & Evaluation Stats',
    icon: 'clipboard',
    gifrAdminOnly: true,
    description:
      'This service allows the management of aggregate reports on the system.',
    actions: {
      list: ['gearsmanager:ListAggregateReportRequests'],
      read: ['gearsmanager:DownloadAggregateReportCSV'],
      write: [
        'gearsmanager:CreateAggregateReportRequest',
        'gearsmanager:CalculateEvaluationStats'
      ]
    }
  },
  // Evaluations
  {
    name: 'Evaluations',
    icon: 'user-md',
    description:
      'This service allows the management (view, create, edit, and delete) of evaluations associated with clients that the user has access to.',
    actions: {
      list: [
        'clientmanager:ListClientEvaluations',
        'institutionmanager:ListClientEvaluations',
        'toolcreator:ListToolCodingForms',
        'toolcreator:ListToolCommits',
        'toolcreator:ListToolPdfs',
        'toolcreator:ListDescriptionPdfs',
        'toolcreator:ListToolReportTemplates',
        'toolcreator:ListToolReportTemplateCommits',
        'toolcreator:ListToolReportTemplateMedia',
        'institutionmanager:ListEvaluationRequests',
        'clientmanager:ListEvaluationMedia'
      ],
      read: [
        'clientmanager:GetClientEvaluation',
        'clientmanager:GetEvaluationMedia',
        'toolcreator:GetTool',
        'toolcreator:GetToolPdf',
        'toolcreator:GetDescriptionPdf',
        'toolcreator:GetToolCommit',
        'toolcreator:GetCodingForm',
        'institutionmanager:ListInstitutionTools'
      ],
      write: [
        'clientmanager:CreateClientEvaluation',
        'clientmanager:SaveClientEvaluation',
        'clientmanager:SubmitClientEvaluation',
        'clientmanager:DeleteClientEvaluation',
        'clientmanager:RevertClientEvaluation',
        'clientmanager:UploadEvaluationMedia',
        'clientmanager:DeleteEvaluationMedia',
        'institutionmanager:CreateEvaluation',
        'institutionmanager:DeleteEvaluation'
      ]
    }
  },
  // All Evaluations
  {
    name: 'All Evaluations',
    icon: 'user-md',
    gifrAdminOnly: true,
    description:
      'This service allows the management (view, create, edit, and delete) of all evaluations on the system.',
    actions: {
      list: [
        'gearsmanager:ListEvaluationRequests',
        'gearsmanager:ListEvaluations'
      ],
      read: [],
      write: ['gearsmanager:DeleteEvaluation']
    }
  },
  // Reports
  {
    name: 'Reports',
    description:
      'This service allows the management (view, create, edit, and delete) of reports associated to evaluations for clients that the user has access to.',
    icon: 'file',
    actions: {
      list: [
        'clientmanager:ListEvaluationReports',
        'clientmanager:ListClientReports'
      ],
      read: [
        'clientmanager:GetReportDocument',
        'clientmanager:GetReportPdf',
        'toolcreator:GetToolReportTemplate',
        'toolcreator:GetToolReportTemplateCommit',
        'toolcreator:GetToolReportTemplateMedia'
      ],
      write: [
        'clientmanager:CreateEvaluationReport',
        'clientmanager:EmailReport'
      ]
    }
  },
  // Case Plans
  {
    name: 'Case Plans',
    icon: 'book',
    description:
      'This service allows the management (view, create, edit, and delete) of case plans associated to clients that the user has access to.',
    actions: {
      list: [
        'clientmanager:ListClientCasePlans',
        'icpt:ListCasePlanTemplates',
        'icpt:ListCasePlanTemplateCommits',
        'mcpt:ListManagedCasePlanTemplates',
        'mcpt:ListCasePlanTemplateCommits'
      ],
      read: [
        'clientmanager:GetClientCasePlan',
        'icpt:GetCasePlanTemplateCommit',
        'mcpt:GetCasePlanTemplateCommit'
      ],
      write: [
        'clientmanager:CreateClientCasePlan',
        'clientmanager:UpdateClientCasePlanStatus',
        'clientmanager:UpdateClientCasePlan'
      ]
    }
  },
  // Institution Users
  {
    name: 'Institution Users',
    icon: 'users',
    description:
      'This service allows the management (invite, policy management, and remove from institution) of users in the institution.',
    actions: {
      list: [],
      read: ['institutionmanager:GetInstitutionUser'],
      write: [
        'institutionmanager:ListInvites',
        'institutionmanager:InviteUser',
        'institutionmanager:ResendInvite',
        'institutionmanager:RemovePolicyFromUser',
        'institutionmanager:DeleteInvite',
        'institutionmanager:AddUser',
        'institutionmanager:RemoveUser',
        'institutionmanager:AssignPolicyToUser',
        'institutionmanager:ListPolicies',
        'institutionmanager:ListManagedPolicies'
      ]
    },
    resources: [
      {
        service: 'institutionmanager',
        value: '*'
      }
    ],
    uniqueScope: true
  },
  // GEARS Users
  {
    name: 'All Users',
    icon: 'users',
    gifrAdminOnly: true,
    description:
      'This service allows the management of all users on the GEARS platform.',
    actions: {
      list: [
        'gearsmanager:ListUsers',
        'gearsmanager:ListInvites',
        'gearsmanager:ListManagedPolicies'
      ],
      read: ['gearsmanager:GetUser'],
      write: [
        'gearsmanager:CreateUser',
        'gearsmanager:UpdateUser',
        'gearsmanager:CreateInvite',
        'gearsmanager:DeleteInvite',
        'gearsmanager:InviteUser',
        'gearsmanager:UpdateInvite'
      ]
    }
  },
  // Location
  {
    name: 'Locations',
    icon: 'globe',
    description:
      'This service allows the management of locations (create, edit, and delete of Regions, Zones, and Subgroups) within the institution.',
    actions: {
      list: [],
      read: [],
      write: [
        'institutionmanager:CreateZone',
        'institutionmanager:CreateRegion',
        'institutionmanager:CreateSubGroup',
        'institutionmanager:DeleteRegion',
        'institutionmanager:DeleteSubGroup',
        'institutionmanager:DeleteZone',
        'institutionmanager:UpdateRegion',
        'institutionmanager:UpdateSubGroup',
        'institutionmanager:UpdateZone'
      ]
    }
  },
  // GEARS Location
  {
    name: 'All Locations',
    icon: 'globe',
    gifrAdminOnly: true,
    description:
      'This service allows the management of locations (create, edit, and delete of Regions, Zones, and Subgroups) on the entire system.',
    actions: {
      list: [
        'gearsmanager:ListInstitutionZones',
        'gearsmanager:ListInstitutionRegions',
        'gearsmanager:ListInstitutionSubGroups',
        'gearsmanager:ListZones',
        'gearsmanager:ListRegions',
        'gearsmanager:ListSubGroups'
      ],
      read: [],
      write: []
    }
  },
  // Institution Case Plan Templates
  {
    name: 'Institution Case Plan Templates',
    icon: 'book',
    description:
      'This service allows the management of Case Plan Templates related to tools that the institution has access to.',
    actions: {
      list: [],
      read: [],
      write: [
        'icpt:CreateCasePlanTemplate',
        'icpt:CommitCasePlanTemplate',
        'icpt:TagCasePlanTemplateCommit',
        'icpt:SetCasePlanTemplateCommitStatus'
      ]
    }
  },
  // Managed Case Plan Templates
  {
    name: 'Managed Case Plan Templates',
    icon: 'book',
    gifrAdminOnly: true,
    description:
      'This service allows the management of “managed” Case Plan Templates for the GEARS platform.',
    actions: {
      list: [],
      read: [],
      write: [
        'mcpt:CreateCasePlanTemplate',
        'mcpt:CommitManagedCasePlanTemplate',
        'mcpt:TagCasePlanTemplateCommit',
        'mcpt:SetCasePlanTemplateCommitStatus'
      ]
    }
  },
  // Policies
  {
    name: 'Policies',
    icon: 'id-card',
    description:
      'This service allows the management (create, edit, and delete) of Permission Policies for the institution.',
    actions: {
      list: [],
      read: [],
      write: [
        'institutionmanager:CreatePolicy',
        'institutionmanager:UpdatePolicy'
      ]
    }
  },
  // Managed Policies
  {
    name: 'Managed Policies',
    gifrAdminOnly: true,
    icon: 'id-card',
    description:
      'This service allows the management of “managed” Policies for the GEARS platform.',
    actions: {
      list: [],
      read: ['gearsmanager:GetManagedPolicy'],
      write: [
        'gearsmanager:CreateManagedPolicy',
        'gearsmanager:UpdateManagedPolicy'
      ]
    }
  },
  // Offense Classifications
  {
    name: 'Offense Classifications',
    icon: 'balance-scale',
    description:
      ' This service allows the management (create, edit, and delete) of Offense Classifications for the institution.',
    actions: {
      list: [],
      read: [],
      write: [
        'institutionmanager:CreateOffenseClassification',
        'institutionmanager:UpdateOffenseClassification',
        'institutionmanager:DeleteOffenseClassification'
      ]
    }
  },
  // Managed Offense Classifications
  {
    name: 'Managed Offense Classifications',
    icon: 'balance-scale',
    gifrAdminOnly: true,
    description:
      'This service allows the management of “managed” Offense Classifications for the GEARS platform.',
    actions: {
      list: ['gearsmanager:ListManagedOffenseClassifications'],
      read: ['gearsmanager:GetManagedOffenseClassification'],
      write: [
        'gearsmanager:CreateManagedOffenseClassification',
        'gearsmanager:UpdateManagedOffenseClassification'
      ]
    }
  },
  // Tool Creator (MHS GEARS Admin only)
  {
    name: 'Tool Creator',
    icon: 'wrench',
    gifrAdminOnly: true,
    description: '',
    actions: {
      list: [
        'toolcreator:ListAllTools',
        'toolcreator:ListInstitutionCasePlanTemplates'
      ],
      read: [],
      write: [
        'toolcreator:AddChild',
        'toolcreator:RemoveChild',
        'toolcreator:AddCodingForm',
        'toolcreator:AddDescriptionPdf',
        'toolcreator:AddToolPdf',
        'toolcreator:ChangePrice',
        'toolcreator:CommitTool',
        'toolcreator:CommitToolReportTemplate',
        'toolcreator:CreateCodingForm',
        'toolcreator:CreateDescriptionPdf',
        'toolcreator:CreateTool',
        'toolcreator:CreateToolPdf',
        'toolcreator:CreateToolReportTemplate',
        'toolcreator:DeleteTool',
        'toolcreator:SetLiveCodingForm',
        'toolcreator:SetLiveDescriptionPdf',
        'toolcreator:SetLiveToolPdf',
        'toolcreator:SetToolCommitStatus',
        'toolcreator:TagToolCommit',
        'toolcreator:UploadToolReportTemplateMedia'
      ]
    }
  },
  // Evaluations Aggregate Reports
  {
    name: 'Aggregate Evaluation Reports',
    icon: 'file',
    description: '',
    actions: {
      list: ['institutionmanager:ListAggregateReportRequests'],
      read: ['institutionmanager:DownloadAggregateReportCSV'],
      write: ['institutionmanager:CreateAggregateReportRequest']
    }
  },
  // Users Aggregate Reports
  {
    name: 'Aggregate User Reports',
    icon: 'file',
    description: '',
    actions: {
      list: ['institutionmanager:ListAggUserReportRequests'],
      read: ['institutionmanager:DownloadAggUserReportRequest'],
      write: ['institutionmanager:CreateAggUserReportRequest']
    }
  },
  // Advanced System Settings
  {
    name: 'Advanced System Settings',
    icon: 'cogs',
    description:
      'This service allows the management of “institution-level” changes. For instance, updating institution preferences, viewing all evaluations under the institution, and deleting institution clients',
    actions: {
      list: [
        'institutionmanager:ListEvaluations',
        'institutionmanager:ListIncomingClientRequests',
        'institutionmanager:ListOutgoingClientRequests',
        'institutionmanager:ListDashboardStats'
      ],
      read: [],
      write: [
        'institutionmanager:CreateClientTransferRequest',
        'institutionmanager:DeclineClientTransferRequest',
        'institutionmanager:DeleteClientTransferRequest',
        'institutionmanager:AcceptClientTransferRequest',
        'institutionmanager:DeleteClient',
        'institutionmanager:CreateExpungeClientRequest',
        'institutionmanager:DeleteEvaluationRequest',
        'institutionmanager:UpdateInstitution'
      ]
    }
  }
];

export default policyConfig;
