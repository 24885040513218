'use strict';

import angular from 'angular';

class FormGroupComponent {
  errors: Array = [];

  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  get value() {
    return this.$scope.ngModel;
  }

  set value(value) {
    this.$scope.ngModel = value;
  }
}

function processAttr(label, scope, attr) {
  let shouldEval = attr.$attr[label] == `:${label}`;

  Object.defineProperty(scope, label, {
    get: () => (shouldEval ? scope.$parent.$eval(attr[label]) : attr[label])
  });
}

export default angular
  .module('app.formGroup', [])
  .directive('formGroup', () => ({
    // require: 'ngModel',
    template: require('./form-group.html'),
    scope: {
      ngModel: '='
    },
    link(scope, element, attr, controller) {
      processAttr('name', scope, attr);
      processAttr('type', scope, attr);
      processAttr('label', scope, attr);
      processAttr('validations', scope, attr);
      // processAttr('required', scope, attr);
      processAttr('options', scope, attr);
      processAttr('btnLabel', scope, attr);
      processAttr('action', scope, attr);

      scope.errors = [];

      const validate = val => {
        scope.errors = [];

        if (!Array.isArray(scope.validations)) {
          return;
        }

        scope.validations.forEach(item => {
          let hasError = scope.$parent.$eval(item.check, {
            $val: val
          });

          // console.log(item, hasError);

          if (!!hasError) {
            scope.errors.push(item.notice);
          }
        });
      };

      scope.$watch('ngModel', validate, true);
    },
    controller: FormGroupComponent,
    controllerAs: 'vm'
  })).name;
