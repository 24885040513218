'use strict';
const angular = require('angular');

import { Controller, Inject } from '@/decorators/ngCtrl';
import { Action, Getter } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

import type { SubGroup } from '@/types';

@Controller
class SubGroupClientTransferModal extends Modal {
  fromSubgroup: SubGroup;
  toSubgroup: SubGroup;
  subGroupOptions: SubGroup[] = [];

  @Inject $scope;
  @Inject $api;
  @Inject notify;

  @Action('clients/transferLocations') transferLocations;

  @Getter('clients/getById') getClientById;

  get client() {
    if (this.$props.clientId) return this.getClientById(this.$props.clientId);
  }

  get clients() {
    if (this.$props.clients) return this.$props.clients;
  }

  $setup() {
    // Shallow copy of SubGroups for options dropdown.
    this.subGroupOptions = [...this.$props.subGroups];

    // Remove curretn uclient subgroup and set it to "fromSubgroup".
    const currentSubGroupId = this.client
      ? this.client.subGroup.id
      : this.clients[0].subGroup.id;

    if (!currentSubGroupId) {
      this.notify.display('Could not detect current sub group', 'error');
      this.$close();
    }

    this.fromSubgroup = this.subGroupOptions.splice(
      this.subGroupOptions.findIndex(({ id }) => id == currentSubGroupId),
      1
    )[0];
  }

  async transfer() {
    let clientId = null;
    let clientIds = null;

    if (this.client) clientId = this.client.id;

    if (this.clients) clientIds = this.clients.map((c) => c.id);

    this.processing = true;

    try {
      await this.transferLocations({
        clientId,
        clientIds,
        subGroupId: this.toSubgroup.id
      });

      this.notify.display('Client(s) Successfully Transferred', 'success');
      this.processing = false;
      this.$close(this.toSubgroup);
    } catch (err) {
      this.processing = false;
      console.error(err);
      this.notify.display(err, 'error');
    }
  }
}

export default angular
  .module('app.subGroupClientTransferModal', [])
  .directive('subGroupClientTransferModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./subgroup-client-transfer.html'),
    controller: SubGroupClientTransferModal,
    controllerAs: 'vm'
  })).name;
