'use strict';

import { Controller, Inject, On } from '@/decorators/ngCtrl';
import { State, Getter } from 'angular-store';

@Controller
class DashboardEditPolicyView {
  policyId: number;
  policy: Policy = null;
  processing = false;

  @Inject $scope;
  @Inject $location;
  @Inject $store;
  @Inject $state;
  @Inject $api;
  @Inject utils;

  @State policies;
  @Getter isAdmin;

  @On('policyCreated') update;
  @On('policyEditorCanceled') canceled;

  $onInit() {
    this.getPolicyRef();
  }

  async getPolicyRef() {
    this.policyId = this.$location.$$search.id;
    let policy = this.policies.items.find((item) => item.id == this.policyId);

    // Change once Eric implements route for
    // getting single policy.
    if (!policy.institution?.id && policy.institutionId)
      policy.institution = { id: policy.institutionId };
    let { data } = await this.$api.IM.listPolicies(policy.institution.id);
    this.policy = data.find((item) => item.id == policy.id);

    this.$scope.$apply();
  }

  async update() {
    const params = {
      instId: this.policy.institutionId,
      institutionPolicyId: this.policyId
    };

    this.processing = true;

    try {
      this.utils.notify('success', {
        message:
          'Updating Policy. This may take some time depending on how many users are associated with the policy. Thank you for your patience.',
        delay: true
      });
      await this.$api.IM.updatePolicy(params, this.policy);
      this.utils.notify('clear');
    } catch (err) {
      this.processing = false;
      this.utils.notify('clear');
      console.error(err);
      this.utils.notify('error', err);

      return;
    }

    this.processing = false;

    this.utils.notify('success', 'Policy updated.');

    try {
      // let refreashCall = this.isAdmin ? this.$store.dispatch('')
      this.$store.dispatch('policies/list');
    } catch (err) {}

    this.back();
  }

  back() {
    this.$state.go('dashboardPolicies');
  }

  async canceled() {
    this.$store.dispatch('policies/list');
    this.$state.go('dashboardPolicies');
  }
}

export default DashboardEditPolicyView;
