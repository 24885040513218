import { storeInstance } from './instance';

/** ... */
type StoreAccsessor = 'state' | 'getters' | 'actions' | 'mutations';
/** ... */
type CustomGetter<T> = (arg: T) => unknown;

/**
 * ...
 */
function createGetter<T>(fn: () => T) {
  return { enumerable: true, get: fn };
}

/**
 * ...
 */
function getAccessor(type: StoreAccsessor) {
  const store = storeInstance.get();

  if (!store) return null;

  if (type === 'state') return store.state;

  if (type === 'getters') return store.getters;

  if (type === 'actions') return store.actions;

  if (type === 'mutations') return store.mutations;

  return null;
}

/**
 * ...
 */
function setDecorator(type: StoreAccsessor, ...args: unknown[]) {
  const mainArg = args[0];

  if (typeof mainArg === 'function') {
    return () => createGetter(() => mainArg(getAccessor(type)));
  }

  if (typeof args[0] === 'string') {
    return () => createGetter(() => getAccessor(type)[args[0]]);
  }

  return createGetter(() => getAccessor(type)[args[1]]);
}

/**
 * ...
 */
export function State(prop: string): any;
export function State<T = IndexableObject>(getter: (arg: T) => unknown): any;
export function State(target: any, prop: string): any;
export function State(...args: any[]): any {
  return setDecorator('state', ...args);
}

/**
 * ...
 */
export function Getter(prop: string): any;
export function Getter<T = IndexableObject>(getter: CustomGetter<T>): any;
export function Getter(target: any, prop: string): any;
export function Getter(...args: any[]): any {
  return setDecorator('getters', ...args);
}

/**
 * ...
 */
export function Action(prop: string): any;
export function Action<T = IndexableObject>(getter: CustomGetter<T>): any;
export function Action(target: any, prop: string): any;
export function Action(...args: any[]): any {
  return setDecorator('actions', ...args);
}

/**
 * ...
 */
export function Mutation(prop: string): any;
export function Mutation<T = IndexableObject>(getter: CustomGetter<T>): any;
export function Mutation(target: any, prop: string): any;
export function Mutation(...args: any[]): any {
  return setDecorator('mutations', ...args);
}
