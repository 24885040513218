'use strict';

import TableView from '../table-view.service';

export default class SelectionTable extends TableView {
  selection;
  allowMultiple: boolean = false;
  hasTitle: boolean = true;
  hasItemsPerPage: boolean = true;

  onSelection = () => {};

  constructor(config = {}) {
    super(config);

    this.title = config.title || null;
    this.allowMultiple = !!config.allowMultiple;

    this.hasTitle = !!config?.hasTitle;
    this.hasItemsPerPage = !!config?.hasItemsPerPage;

    if (typeof config.onSelection == 'function') {
      this.onSelection = config.onSelection;
    }
  }
}
