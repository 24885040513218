'use strict';

import angular from 'angular';

export default angular
  .module('app.routerLink', [])
  .directive('routerLink', () => ({
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: true,
    template: `
      <a ui-state="to" ui-state-active="activeClass" ng-transclude></a>
    `,
    link($scope, $element, $attr) {
      let evalTo = $attr.$attr.to == ':to';

      Object.defineProperty($scope, 'to', {
        get: () => (evalTo ? $scope.$parent.$eval($attr.to) : $attr.to)
      });

      let evalActiveClass = $attr.$attr.activeClass == ':activeClass';

      Object.defineProperty($scope, 'activeClass', {
        get: () =>
          evalActiveClass
            ? $scope.$parent.$eval($attr.activeClass)
            : $attr.activeClass
      });

      if (!$element.children().length) {
        $element.append(`<span>${$scope.to}</span>`);
      }
    }
  })).name;
