export default {
  naNorms(score, sex, type) {
    const normRanks = {
      0: {
        male: {
          institutional: '0.0%',
          community: '0.7%'
        },
        female: {
          institutional: '0.0%',
          community: '0.9%'
        }
      },
      1: {
        male: {
          institutional: '0.1%',
          community: '2.5%'
        },
        female: {
          institutional: '0.1%',
          community: '3.3%'
        }
      },
      2: {
        male: {
          institutional: '0.3%',
          community: '5.2%'
        },
        female: {
          institutional: '0.2%',
          community: '6.5%'
        }
      },
      3: {
        male: {
          institutional: '0.6%',
          community: '8.2%'
        },
        female: {
          institutional: '0.7%',
          community: '9.9%'
        }
      },
      4: {
        male: {
          institutional: '1.0%',
          community: '11.4%'
        },
        female: {
          institutional: '1.5%',
          community: '13.3%'
        }
      },
      5: {
        male: {
          institutional: '1.5%',
          community: '14.6%'
        },
        female: {
          institutional: '2.4%',
          community: '17.2%'
        }
      },
      6: {
        male: {
          institutional: '2.3%',
          community: '18.2%'
        },
        female: {
          institutional: '3.6%',
          community: '21.1%'
        }
      },
      7: {
        male: {
          institutional: '3.1%',
          community: '21.9%'
        },
        female: {
          institutional: '4.7%',
          community: '24.9%'
        }
      },
      8: {
        male: {
          institutional: '4.2%',
          community: '25.4%'
        },
        female: {
          institutional: '6.0%',
          community: '28.7%'
        }
      },
      9: {
        male: {
          institutional: '5.4%',
          community: '29.0%'
        },
        female: {
          institutional: '7.6%',
          community: '32.5%'
        }
      },
      10: {
        male: {
          institutional: '6.9%',
          community: '32.7%'
        },
        female: {
          institutional: '9.3%',
          community: '36.0%'
        }
      },
      11: {
        male: {
          institutional: '8.5%',
          community: '36.5%'
        },
        female: {
          institutional: '10.9%',
          community: '39.4%'
        }
      },
      12: {
        male: {
          institutional: '10.4%',
          community: '40.1%'
        },
        female: {
          institutional: '13.0%',
          community: '42.8%'
        }
      },
      13: {
        male: {
          institutional: '12.7%',
          community: '43.5%'
        },
        female: {
          institutional: '15.1%',
          community: '45.9%'
        }
      },
      14: {
        male: {
          institutional: '15.0%',
          community: '46.8%'
        },
        female: {
          institutional: '17.3%',
          community: '49.2%'
        }
      },
      15: {
        male: {
          institutional: '17.5%',
          community: '50.0%'
        },
        female: {
          institutional: '19.5%',
          community: '52.1%'
        }
      },
      16: {
        male: {
          institutional: '20.3%',
          community: '53.0%'
        },
        female: {
          institutional: '22.1%',
          community: '55.0%'
        }
      },
      17: {
        male: {
          institutional: '23.3%',
          community: '56.1%'
        },
        female: {
          institutional: '24.4%',
          community: '57.8%'
        }
      },
      18: {
        male: {
          institutional: '26.6%',
          community: '59.1%'
        },
        female: {
          institutional: '26.8%',
          community: '60.8%'
        }
      },
      19: {
        male: {
          institutional: '30.3%',
          community: '62.3%'
        },
        female: {
          institutional: '29.5%',
          community: '63.9%'
        }
      },
      20: {
        male: {
          institutional: '33.7%',
          community: '64.7%'
        },
        female: {
          institutional: '32.5%',
          community: '66.1%'
        }
      },
      21: {
        male: {
          institutional: '37.4%',
          community: '67.1%'
        },
        female: {
          institutional: '35.9%',
          community: '68.6%'
        }
      },
      22: {
        male: {
          institutional: '41.4%',
          community: '69.5%'
        },
        female: {
          institutional: '39.2%',
          community: '70.9%'
        }
      },
      23: {
        male: {
          institutional: '45.6%',
          community: '71.9%'
        },
        female: {
          institutional: '42.5%',
          community: '73.1%'
        }
      },
      24: {
        male: {
          institutional: '49.8%',
          community: '74.3%'
        },
        female: {
          institutional: '46.2%',
          community: '75.3%'
        }
      },
      25: {
        male: {
          institutional: '54.0%',
          community: '76.6%'
        },
        female: {
          institutional: '51.1%',
          community: '77.5%'
        }
      },
      26: {
        male: {
          institutional: '58.5%',
          community: '78.9%'
        },
        female: {
          institutional: '55.6%',
          community: '79.8%'
        }
      },
      27: {
        male: {
          institutional: '62.9%',
          community: '81.2%'
        },
        female: {
          institutional: '60.6%',
          community: '82.0%'
        }
      },
      28: {
        male: {
          institutional: '67.2%',
          community: '83.3%'
        },
        female: {
          institutional: '65.1%',
          community: '84.1%'
        }
      },
      29: {
        male: {
          institutional: '71.7%',
          community: '85.3%'
        },
        female: {
          institutional: '69.9%',
          community: '86.2%'
        }
      },
      30: {
        male: {
          institutional: '75.7%',
          community: '87.3%'
        },
        female: {
          institutional: '73.9%',
          community: '88.2%'
        }
      },
      31: {
        male: {
          institutional: '79.6%',
          community: '89.2%'
        },
        female: {
          institutional: '77.6%',
          community: '90.1%'
        }
      },
      32: {
        male: {
          institutional: '83.4%',
          community: '91.1%'
        },
        female: {
          institutional: '80.9%',
          community: '91.8%'
        }
      },
      33: {
        male: {
          institutional: '86.9%',
          community: '92.8%'
        },
        female: {
          institutional: '84.8%',
          community: '93.4%'
        }
      },
      34: {
        male: {
          institutional: '89.9%',
          community: '94.2%'
        },
        female: {
          institutional: '88.2%',
          community: '94.9%'
        }
      },
      35: {
        male: {
          institutional: '92.4%',
          community: '95.5%'
        },
        female: {
          institutional: '90.8%',
          community: '96.3%'
        }
      },
      36: {
        male: {
          institutional: '94.6%',
          community: '96.7%'
        },
        female: {
          institutional: '93.5%',
          community: '97.4%'
        }
      },
      37: {
        male: {
          institutional: '96.3%',
          community: '97.7%'
        },
        female: {
          institutional: '95.8%',
          community: '98.3%'
        }
      },
      38: {
        male: {
          institutional: '97.7%',
          community: '98.6%'
        },
        female: {
          institutional: '97.5%',
          community: '99.0%'
        }
      },
      39: {
        male: {
          institutional: '98.7%',
          community: '99.2%'
        },
        female: {
          institutional: '98.5%',
          community: '99.5%'
        }
      },
      40: {
        male: {
          institutional: '99.4%',
          community: '99.7%'
        },
        female: {
          institutional: '99.3%',
          community: '99.7%'
        }
      },
      41: {
        male: {
          institutional: '99.8%',
          community: '99.9%'
        },
        female: {
          institutional: '99.9%',
          community: '99.9%'
        }
      },
      42: {
        male: {
          institutional: '99.9%',
          community: '99.9%'
        },
        female: {
          institutional: '99.9%',
          community: '99.9%'
        }
      },
      43: {
        male: {
          institutional: '99.9%',
          community: '99.9%'
        },
        female: {
          institutional: '99.9%',
          community: '99.9%'
        }
      }
    };
    if (typeof score !== 'number' || score < 0) return;
    if (typeof sex !== 'string' && (sex !== 'male' || sex !== 'female')) return;
    if (
      typeof type !== 'string' &&
      (type !== 'institutional' || type !== 'community')
    )
      return;
    if (normRanks[score]) {
      if (normRanks[score][sex]) {
        if (normRanks[score][sex][type]) {
          return normRanks[score][sex][type];
        }
      }
    }
  },
  recidivismRate(score, type) {
    if (typeof score !== 'number' || score < 0) return;
    if (
      typeof type !== 'string' &&
      (type !== 'institutional' || type !== 'community')
    )
      return;
    if (type === 'institutional') {
      if (score <= 4) {
        return {
          percentage: '0%',
          supervisionOptions: 'Consider early release or community residential placement.'
        };
      } else if (score >= 5 && score <= 10) {
        return {
          percentage: '6%',
          supervisionOptions: 'Consider early release or community residential placement.'
        };
      } else if (score >= 11 && score <= 19) {
        return {
          percentage: '28%',
          supervisionOptions: 'Consider early release or community residential placement with short-term treatment.'
        };
      } else if (score >= 20 && score <= 29) {
        return {
          percentage: '45%',
          supervisionOptions: 'Consider minimum and/or early release with management/treatment of dynamic risk factors.'
        };
      } else if (score >= 30) {
        return {
          percentage: '61%',
          supervisionOptions: 'Consider medium when prison experience acceptable. Consider management/treatment of risk factors.'
        };
      }
    } else if (type === 'community') {
      if (score <= 4) {
        return {
          percentage: '1%',
          supervisionOptions: 'Consider administrative closure.'
        };
      } else if (score >= 5 && score <= 10) {
        return {
          percentage: '8%',
          supervisionOptions: 'Consider administrative closure.'
        };
      } else if (score >= 11 && score <= 19) {
        return {
          percentage: '23%',
          supervisionOptions: 'Consider low with management and/or treatment of dynamic risk factors.'
        };
      } else if (score >= 20 && score <= 29) {
        return {
          percentage: '44%',
          supervisionOptions: 'Consider medium with management and/or treatment of dynamic risk factors.'
        };
      } else if (score >= 30) {
        return {
          percentage: '99%',
          supervisionOptions: 'Maintain highest levels of contact and treatment of dynamic risk factors.'
        };
      }
    }
  }
};
