'use strict';

import angular from 'angular';

import { Controller, Inject, On } from '@/decorators/ngCtrl';

import { find } from 'lodash';

@Controller
class InputSelectStateProvince {
  stateProvinces: {}[] = [];

  @Inject $scope;

  @On('selectedCountryUpdated', true)
  updateStateProvinces(e, args) {
    if (this.$scope.$parent === e.targetScope) {
      this.stateProvinces = args;
      if (args && (!this.ngModel || !find(this.stateProvinces, { value: this.ngModel }))) {
        this.ngModel = this.stateProvinces[0].value;
      } else if (!args) {
        this.ngModel = '';
      }
    }
  }
}

export default angular
  .module('app.selectStateProvince', [])
  .directive('selectStateProvince', () => ({
    restrict: 'E',
    replace: true,
    template: require('./select-state-province.html'),
    scope: {
      ngModel: '='
    },
    bindToController: true,
    controller: InputSelectStateProvince,
    controllerAs: 'vm'
  })).name;
