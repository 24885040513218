import { Controller, Inject } from '@/decorators/ngCtrl';
import { Action, State } from 'angular-store';

import { forEach } from 'lodash';

@Controller
export default class DashboardToolSandboxView {
  @State(({ tools }) => tools.items) tools;
  @Action('tools/getToolData') getToolData;

  async $onInit() {
    this.tool = await this.getToolData({ toolId: 120, commitId: '4XWDfOs85' });
    this.parseToolToTable(this.tool);
  }

  parseToolToTable(tool) {
    this.parsedTool = [];
    let makeRow = function(cfi, section) {
      let row = {
        questionId: cfi.id,
        section,
        questionText: cfi.riskFactor,
        answerOptions: '',
        referenceText: cfi.customReferenceText
      };
      forEach(cfi.codesAndScore, (cas, i) => {
        if (cas.id == '-') return;
        row.answerOptions +=
          i == cfi.codesAndScore.length - 1 ? `${cas.text}` : `${cas.text} | `;
      });
      return row;
    };

    let parseTool = t => {
      forEach(t.codingFormItems, cfi =>
        this.parsedTool.push(makeRow(cfi, t.name))
      );
      forEach(t.childTools, ct => parseTool(ct));
    };

    parseTool(tool);
  }
}
