export default {
  coverPage() {
    return {
      head: [['Name:', 'client.fName client.lName']],
      body: [
        ['ID Number:', 'client.localId'],
        ['Age:', 'clientAge'],
        ['Sex:', 'client.sex'],
        ['Gender Identity:', 'client.gender'],
        ['Race/Ethnicity:', 'client.ethnicity'],
        ['Current Location:', 'clientLocation'],
        ['Report Date:', 'date.today'],
        ["Assessor's Name:", 'user.fName user.lName'],
        ['Reason for Assessment:', 'reasonForAssessment']
      ]
    };
  }
};
