import angular from 'angular';

import { find } from 'lodash';

/**
 * ...
 */
abstract class ReportMonitor {
  private inProgress: unknown[] = [];
  private monitoring = false;

  constructor(
    protected readonly $state: angular.ui.IStateService,
    protected readonly $store: angular.gears.IStoreService,
    protected readonly $delay: angular.gears.IDelayService,
    protected readonly notify: angular.gears.INotifyService
  ) {
    'ngInject';
  }

  /**
   * ...
   *
   * @return ...
   */
  start() {
    this.monitoring = true;

    this.runCheck();
  }

  /**
   * ...
   *
   * @return ...
   */
  end() {
    this.monitoring = false;
  }

  /**
   * ...
   *
   * @return ...
   */
  async check() {
    const reports = await this.getReports();

    if (reports === false) return;

    let completed = 0;
    const toAdd = [];

    for (const rep of reports) {
      let matchIndex = this.inProgress.indexOf(({ id }) => id === rep.id);

      for (const j in this.inProgress) {
        if (this.inProgress[j].id === rep.id) {
          matchIndex = j;
          break;
        }
      }

      let scanTag = null;

      if (rep.tags && rep.tags.length) {
        // new scan tag
        scanTag = find(rep.tags, { Key: 'scan-status' });

        // fallback to old scan tag
        if (!scanTag) scanTag = find(rep.tags, { Key: 'av-status' });

        if (scanTag?.Value !== 'CLEAN') rep.status = 'SCANNING';
      }

      if (matchIndex >= 0 && rep.status == 'COMPLETED') {
        // The report has been completed!.
        this.inProgress.splice(matchIndex, 1);
        completed++;
      } else if (matchIndex < 0 && rep.status == 'QUEUED') {
        // A new report has been added and is awaiting
        // completion. Add it to the list of monitored
        // requests.
        toAdd.push(rep);
      }
    }

    if (completed >= 1) {
      this.notify.success(`${completed} activity report(s) are ready to view.`);
    }

    this.inProgress = [...this.inProgress, ...toAdd];
  }

  /**
   * ...
   *
   * @return ...
   */
  protected async getReports() {
    return [] as any;
  }

  /**
   * ...
   */
  private async runCheck() {
    await this.check();

    await this.$delay(60000);

    if (this.monitoring) this.runCheck();
  }
}

/**
 * ...
 */
export class ActivityReportsMonitor extends ReportMonitor {
  protected async getReports() {
    if (this.$state.current.name !== 'dashboardActivityReports') {
      return false;
    }

    // Pull all avalible report requets.
    return await this.$store.dispatch('activityReports/list', true);
  }
}

/**
 * ...
 */
export class AggregateReportsMonitor extends ReportMonitor {
  protected async getReports() {
    if (this.$state.current.name !== 'dashboardAggregateReports') {
      return false;
    }

    // Pull all avalible report requets.
    return await this.$store.dispatch('aggregateReports/list', true);
  }
}

/**
 * ...
 */
export class AggregateUserReportsMonitor extends ReportMonitor {
  protected async getReports() {
    if (this.$state.current.name !== 'dashboardAggregateUserReports') {
      return false;
    }

    console.log('listing');
    // Pull all avalible report requets.
    return await this.$store.dispatch('aggregateUserReports/list');
  }
}
