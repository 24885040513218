'use strict';

import angular from 'angular';

class CmpEvaluationController {
  constructor($scope, $http, ItemTable, SelectionTable, TableViewDataTypes) {
    'ngInject';

    this._scope = $scope;
    this._http = $http;

    this.evaluationsTable = new SelectionTable({
        // title: 'Evaluations',
        title: `${this.client.fullName}'s Evaluations`,
        itemsPerPage: 10,
        loader: async () => {
            let route = `/api/clients/${this.client.id}/evaluations`;
            return (await $http.get(route)).data.data;
          },
          onSelection: selection => {
            this.cmpManager.selectedEvaluation = selection;
            this.cmpManager.isValid = selection != null;
          }
      })
      .column({
        title: 'ID',
        key: 'id',
        type: TableViewDataTypes.string
      })
      .column({
        title: 'Status',
        key: 'status',
        // type: 'number'
        type: TableViewDataTypes.number
      })
      .column({
        title: 'Added On',
        key: 'createdAt',
        type: TableViewDataTypes.date
      })
      .column({
        title: 'Evaluated By',
        key: 'createdBy',
        type: TableViewDataTypes.string
      });

    this.cmpManager.isValid = this.selectedEvaluation != null;
  }

  get client() {
    return this.cmpManager.selectedClient;
  }

  get selectedEvaluation() {
    return this.evaluationsTable.selection;
  }

  showEvaluation(row) {
    this.cmpManager.dsc.showEvaluations(row);
  }
}

export default angular.module('app.section.cmpEvaluation', [])
  .component('cmpEvaluation', {
    template: require('./choose-evaluation.html'),
    bindings: {
      cmpManager: '=cmpManager'
    },
    controller: CmpEvaluationController,
    controllerAs: 'vm'
  })
  .name;