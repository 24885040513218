import { Controller, Inject, State } from '@angular';

@Controller
class InvoiceView {
  invoiceId: string = '';
  invoice: Object;
  transaction: Object;

  @Inject $rootScope;
  @Inject $http;
  @Inject $stateParams;
  @Inject $auth;
  @Inject $filter;
  @Inject $location;
  @Inject $cookies;
  @Inject $api;
  @Inject Notification;

  @State me;

  async $onInit() {
    this.document = document;

    this.removeAccessingInvoice();

    this.$cookies.remove('accessingInvoice');

    // Check if there is an ID in the url
    if (this.$stateParams.id === '') {
      this.Notification.warning({
        message: 'Invoice ID not given',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });

      this.$state.go('main');

      return;
    }

    // Get invoice ID
    this.invoiceId = this.$stateParams.id;
    // Check invoice ID

    try {
      let res = await this.$api.invoices.get(this.invoiceId);

      this.invoice = res.data.data;

      console.log(this.me);
      console.log(this.invoice);

      var authorized = false;
      var gifrAdmin = false;

      this.me.roles.forEach(role => {
        if (this.invoice.institutionId === role.institutionId) {
          authorized = true;
        } else if (role.roleId === 7 || role.roleId === 8) {
          gifrAdmin = true;
        }
      });

      if (!authorized && !gifrAdmin) {
        this.Notification.error({
          message: 'Unauthorized to access this invoice',
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });

        this.$state.go('main');

        return;
      }

      this.initInvoice();
    } catch (err) {
      console.log(err);

      this.Notification.error({
        message: 'Error - Retrieving Invoice',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });

      this.$state.go('main');
    }
  }

  // Remove accessing invoice from rootScope
  removeAccessingInvoice() {
    this.$cookies.remove('accessingInvoice');
  }

  // Handle error
  handleError(error) {
    console.log(error);

    if (error.status === 500) {
      this.Notification.error({
        title: 'Internal Server Error',
        message: `Something went wrong on our end. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`,
        positionX: 'center',
        delay: true,
        templateUrl: 'assets/components/notification-template.html'
      });
    } else if (error.status === 404) {
      this.Notification.error({
        title: 'Not Found',
        message: `The entity or place you were looking for was not found. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`,
        positionX: 'center',
        delay: true,
        templateUrl: 'assets/components/notification-template.html'
      });
    } else if (error.status === 403) {
      this.Notification.error({
        title: 'Forbidden',
        message: `Entity is forbidden from being accessed. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`,
        positionX: 'center',
        delay: true,
        templateUrl: 'assets/components/notification-template.html'
      });
    } else if (error.status === 401) {
      this.Notification.error({
        title: 'Unauthorized',
        message: `You are not authorized to access this entity. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`,
        positionX: 'center',
        delay: true,
        templateUrl: 'assets/components/notification-template.html'
      });
    } else if (error.data.feedback) {
      if (error.data.feedback.message) {
        this.Notification.error({
          title: 'Error',
          message: error.data.feedback.message,
          positionX: 'center',
          delay: true,
          templateUrl: 'assets/components/notification-template.html'
        });
      } else {
        var message = JSON.parse(error.data.feedback);

        if (message.message) {
          this.Notification.error({
            title: 'Error',
            message: message.message,
            positionX: 'center',
            delay: true,
            templateUrl: 'assets/components/notification-template.html'
          });
        } else {
          this.Notification.error({
            title: 'Error',
            message: error.data.feedback,
            positionX: 'center',
            delay: true,
            templateUrl: 'assets/components/notification-template.html'
          });
        }
      }
    }
  }

  // Initialize invoice
  initInvoice() {
    console.log(this.invoice);

    this.transaction = {
      total: this.invoice.amount
    };

    this.invoice.details = JSON.parse(this.invoice.details);
    var DETAILS = this.invoice.details;

    var split;

    console.log(DETAILS);

    for (var key in DETAILS) {
      var item_details = DETAILS[key];

      if (item_details === '' || !item_details) {
        Reflect.deleteProperty(DETAILS, key);
        continue;
      }

      var readableKey = '';
      console.log(item_details);
      split = key.split(/(?=[A-Z])/);
      console.log(split);

      split.forEach((item_split, i) => {
        console.log(item_split);

        if (i === 0) {
          readableKey = this.$filter('capitalize')(item_split);
        } else {
          readableKey =
            readableKey + ' ' + this.$filter('capitalize')(item_split);
        }
      });

      DETAILS[readableKey] = item_details;
      Reflect.deleteProperty(DETAILS, key);
    }

    if (this.invoice.status === 1) {
      this.Notification.success({
        message: 'This Invoice Has Already Been Paid',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html',
        delay: false
      });

      return;
    }

    if (this.invoice.status === 3) {
      this.Notification.success({
        message: 'This Invoice Has Been Cancelled',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html',
        delay: false
      });

      return;
    }

    console.log(DETAILS);

    var clientToken = '';
    var colorTransition = 'color 160ms linear';
    var form = this.document.querySelector('#pay-invoice-form');
    var submit = this.document.querySelector('input[type="submit"]');
    var cancel = this.document.querySelector('input[type="button"]');
    var braintreeLoad = this.document.getElementById('braintree-loading');
    var braintreeProcess = this.document.getElementById('braintree-processing');

    console.log(form);
    console.log(submit);
    console.log(cancel);
    console.log(braintreeLoad);
    console.log(braintreeProcess);

    const vm = this;
    const notification = this.Notification;
    const location = this.$location;
    const $api = this.$api;

    var invoice = this.invoice;

    braintreeLoad.className = 'loading';
    braintreeProcess.className = 'loading hidden';

    // Get token nonce for payment
    this.$api.braintree.getToken(this.invoice.institutionId).then(response => {
      clientToken = response.data.data;

      braintree.client.create({ authorization: clientToken }, function(
        err,
        clientInstance
      ) {
        if (err) {
          return;
        }

        createHostedFields(clientInstance);
      });

      function createHostedFields(clientInstance) {
        braintree.hostedFields.create(
          {
            client: clientInstance,
            styles: {
              input: {
                color: '#232323',
                transition: colorTransition,
                '-webkit-transition': colorTransition
              },
              '.number': {
                'font-family': 'Helvetica, Arial, sans-serif;'
              },
              '.valid': {
                color: '#5cb85c'
              },
              '.invalid': {
                color: '#ee6c4d'
              },
              // Media queries
              // Note that these apply to the iframe, not the root window.
              '@media screen and (max-width: 700px)': {
                input: {
                  'font-size': '14px'
                }
              }
            },
            fields: {
              number: {
                selector: '#card-number',
                placeholder: '1111 1111 1111 1111'
              },
              cvv: {
                selector: '#cvv',
                placeholder: '111'
              },
              expirationDate: {
                selector: '#expiration-date',
                placeholder: 'MM/YY'
              },
              postalCode: {
                selector: '#postal-code',
                placeholder: '11111'
              }
            }
          },
          function(err, hostedFieldsInstance) {
            braintreeLoad.className = 'loading hidden';

            if (err) {
              console.log(err);
              return;
            }

            submit.removeAttribute('disabled');

            form.addEventListener(
              'submit',
              function(event) {
                braintreeProcess.className = 'loading';
                event.preventDefault();
                hostedFieldsInstance.tokenize(function(tokenizeErr, payload) {
                  if (tokenizeErr) {
                    braintreeProcess.className = 'loading hidden';
                    return;
                  }

                  $api.braintree
                    .checkout({
                      invoiceId: invoice.id,
                      institutionId: invoice.institutionId,
                      payment_method_nonce: payload.nonce
                    })
                    .then(nonceResponse => {
                      braintreeProcess.className = 'loading hidden';
                      if (!nonceResponse.data.error) {
                        hostedFieldsInstance.teardown(function(teardownErr) {
                          if (teardownErr) {
                            console.log('Could not tear down hosted fields');
                          } else {
                            console.log('Hosted fields torn down');
                          }
                        });

                        location.url('/dashboard');

                        notification.success({
                          message: 'Invoice Paid Successfully',
                          positionX: 'center',
                          templateUrl:
                            'assets/components/notification-template.html'
                        });
                      } else {
                        vm.handleError(nonceResponse);
                      }
                    })
                    .catch(err => {
                      braintreeProcess.className = 'loading hidden';
                      vm.handleError(err);
                    });
                });
              },
              false
            );

            cancel.addEventListener(
              'click',
              function(event) {
                event.preventDefault();
                braintreeProcess.className = 'loading hidden';
                braintreeLoad.className = 'loading hidden';
                vm.$location.url('/dashboard');
                hostedFieldsInstance.teardown(function(teardownErr) {
                  if (teardownErr) {
                    vm.handleError(teardownErr);
                  }
                });
              },
              false
            );
          }
        );
      }
    });
  }
}

export default InvoiceView;
