'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';

@Controller
class BadgeComponent {}

export default angular.module('app.badge', []).component('badge', {
  // template: require('./badge.html'),
  require: 'count',
  template: `
    <label>{{ vm.count <= 99 ? vm.count : '99+' }}</label>
  `,
  bindings: {
    count: '<'
  },
  controller: BadgeComponent,
  controllerAs: 'vm'
}).name;
