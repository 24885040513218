import angular from 'angular';
import { find } from 'lodash';

/**
 * ...
 */
function generalNotesLabelFilter($store: any) {
  'ngInject';

  return (toolId: number) => {
    const evalConfig = find($store.state.tools.evaluationConfigs, { toolId });

    let label = 'General Notes';

    if (!evalConfig) return label;

    const comments = evalConfig.comments;

    if (comments.generalNotesAlias) label = comments.generalNotesAlias;

    if (comments.generalNotes === 'REQUIRED') label += '*';

    return label;
  };
}

export default angular
  .module('app.generalNotesLabel', [])
  .filter('generalNotesLabel', generalNotesLabelFilter).name;
