/*
generates a comparative report for evaluations completed
using the YLS/CMI tool
*/

/*
TABLE EXAMPLE
let riskCategories = [];
tool.riskCategories = _.sortBy(tool.riskCategories, 'low');
_.forEach(tool.riskCategories, rc => {
  riskCategories.push([`${rc.low} - ${rc.high}`, `${rc.name}`]);
});
pdf.autoTable({
  head: [['Range', 'Risk Level']],
  body: riskCategories,
  startY: y - 10,
  theme: 'grid'
});
// set y to height of table plus padding
y += 25 + (riskCategories.length + 1) * 20; // 25 added for padding and to fit title
*/

export default {
  generateReport(client, tool, evaluations) {
    console.log('client: ', client);
    console.log('tool: ', tool);
    console.log('evaluations: ', evaluations);
    let report = [
      {
        title: "Here's a Title For the Section",
        body: [
          {
            type: 'text',
            content: {
              text:
                'Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Mauris facilisis tortor non enim tempus, et accumsan est commodo. Quisque posuere lacus massa, quis vulputate nisi mollis quis. Nam vitae libero vel lorem bibendum convallis. Ut fermentum, erat ac sodales fringilla, orci justo pharetra ex, eu pellentesque eros lacus in felis. Morbi efficitur pellentesque semper. Vivamus ullamcorper rhoncus tempus. Nullam at nibh sodales, hendrerit urna et, aliquam libero. In interdum sem eu elementum rhoncus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. In ipsum massa, mattis non mauris at, feugiat aliquet leo. Donec vehicula turpis sed tortor pulvinar, nec consectetur velit sodales. Sed nec arcu vulputate, elementum sem eget, elementum tortor. Pellentesque nec sapien nec justo suscipit accumsan in eget risus.',
              style: ['title', 'body', 'bold', 'italics', 'underlined']
            }
          },
          {
            type: 'graph',
            content: {
              data: {},
              type: 'bar'
            }
          },
          {
            type: 'table',
            content: {
              head: [],
              body: [],
              theme: 'grid'
            }
          },
          {
            type: 'image'
          }
        ]
      }
    ];
    return report;
  }
};
