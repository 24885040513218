// Comparative Report Generator Downloader
import { forEach, sortBy, find } from 'lodash';
import 'jspdf-autotable';

import fontDataUrls from '../fontDataUrls';
import JsPDF from 'jspdf';

export default async function download(
  mhsLogoDataUrl,
  client,
  evaluations,
  tool,
  report,
  services,
  pageSetup,
  toolName
) {
  let pageHeight = pageSetup.pageHeight;
  let pageWidth = pageSetup.pageWidth;
  let margins = pageSetup.margins;
  let innerPageHeight = pageHeight - margins.top - margins.bottom;
  let innerPageWidth = pageWidth - margins.left * 2;
  let $http = services.$http;
  let $filter = services.$filter;
  let $templateCache = services.$templateCache;
  let $store = services.$store;
  let Util = services.$util;
  let notify = services.notify;
  let Upload = services.Upload;
  let $sce = services.$sce;
  let $reincode = services.$reincode;

  // console.log('COMPARATIVE REPORT PDF FOR DOWNLOAD...');
  // console.log(client, evaluations, tool, report);

  //=================== PDF DOC SETUP ==================
  let pdf = new JsPDF('p', 'pt', 'letter');

  // set comfortaa font
  pdf.addFileToVFS('ComfortaaRegular.tff', fontDataUrls.comfortaaRegular());
  pdf.addFont('ComfortaaRegular.tff', 'Comfortaa', 'normal');
  pdf.addFileToVFS('ComfortaaBold.tff', fontDataUrls.comfortaaBold());
  pdf.addFont('ComfortaaBold.tff', 'Comfortaa', 'bold');
  pdf.setFont('Comfortaa');
  pdf.setFont('Comfortaa');
  let page = 2;
  let y = margins.top + 10;

  //#region HELPER FUNCTIONS

  let checkEndOfPage = function (y) {
    return y >= pageHeight - margins.bottom - 100;
  };

  let header = function () {
    pdf.setFontSize(11);
    pdf.setFontStyle('normal');
    pdf.text(`Comparative Report: ${client.name()}`, margins.left, 21);
    pdf.text(`Page ${page}`, pageWidth - margins.left - 50, 21);
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(0, 0, 0);
    pdf.line(margins.left, 25, pageWidth - margins.left, 25);
    page = page + 1;
  };

  let footer = function () {
    pdf.addImage(
      mhsLogoDataUrl,
      'JPEG',
      pageWidth - margins.left - 80,
      pageHeight - margins.bottom,
      80,
      35
    );
  };

  // logic for creating new line or new page if needbe
  let newLine = function (y) {
    if (y + 12 > pageHeight - margins.top) {
      pdf.addPage();
      header();
      footer();
      y = margins.top;
    } else {
      y += 12;
    }
    return y;
  };

  let addText = function (
    text,
    fontSize = 10,
    fontStyle = 'normal',
    align = 'left',
    x,
    texty = y
  ) {
    y = texty;
    if (y < margins.top + 12) y = margins.top + 12;

    if (!text) {
      console.error('Text parameter must be provided');
      return;
    }

    text = $reincode.text(text);
    if (fontSize > 10 && fontSize <= 14) y += 15;
    if (fontSize > 14 && fontSize < 18) y += 20;

    pdf.setFont('Comfortaa');
    pdf.setFontSize(fontSize);
    pdf.setFontStyle(fontStyle);

    if (!text?.length || typeof text !== 'string') return;
    let lines = pdf.splitTextToSize(text, margins.width);

    forEach(lines, (line) => {
      pdf.setFontSize(fontSize);
      pdf.setFontStyle(fontStyle);
      pdf.text(line, x ? x : margins.left, y, align);
      y = newLine(y);
    });

    y = newLine(y);
  };

  let addImage = function (
    dataUrl,
    imgx,
    imgy = y,
    w,
    h,
    canvas,
    element,
    ignoreYChange
  ) {
    // check if image is taller than one page
    if (h > innerPageHeight) {
      for (let i = 0; i <= element.clientHeight / h; i++) {
        let srcImg = canvas;
        let sX = 0;
        let sY = h * i;
        let sWidth = canvas.width;
        let sHeight = h;
        let dX = 0;
        let dY = 0;
        let dWidth = innerPageWidth;
        let dHeight = innerPageHeight;

        window.onePageCanvas = document.createElement('canvas');
        onePageCanvas.setAttribute('width', innerPageWidth);
        onePageCanvas.setAttribute('height', innerPageHeight);
        let ctx = onePageCanvas.getContext('2d');
        ctx.drawImage(srcImg, sX, sY, sWidth, sHeight, dX, dY, dWidth, dHeight);
        let canvasDataURL = onePageCanvas.toDataURL('image/png', 1.0);
        let width = onePageCanvas.width;
        let height = onePageCanvas.height;
        if (i > 0) {
          newPage();
        }
        pdf.addImage(
          canvasDataURL,
          'JPEG',
          margins.left,
          margins.top,
          width,
          height
        );
      }
      newPage();
    } else {
      if (y + h >= innerPageHeight) {
        newPage();
        imgy = y;
      }
      pdf.addImage(
        typeof dataUrl === 'string'
          ? dataUrl
          : $sce.getTrustedResourceUrl(dataUrl),
        'JPEG',
        imgx ? imgx : margins.left,
        imgy,
        w,
        h
      );
      if (!ignoreYChange) y += h + 30;
    }
  };

  let addTable = function (
    head,
    body,
    headStyles,
    startY = y,
    theme = 'grid',
    dataUrl,
    imgWidth,
    imgHeight,
    canvas,
    element
  ) {
    if (dataUrl) {
      addImage(dataUrl, margins.left, y, imgWidth, imgHeight, canvas, element);
      // pdf.addImage(dataUrl, 'JPEG', margins.left, y, imgWidth, imgHeight);
      // y += imgHeight + 30;
    } else {
      pdf.autoTable({
        head,
        body,
        startY,
        theme,
        headStyles,
        pageBreak: 'avoid',
        tableWidth: pageWidth - margins.left * 2
      });
      y += 25 + (body.length + head.length) * 20;
    }
  };

  let newPage = function (pageAlreadyAdded) {
    if (!pageAlreadyAdded) {
      pdf.addPage();
    }
    header();
    footer();
    y = margins.top + 12;
  };

  let cropImage = function (width, height) {
    if (width > innerPageWidth) {
      let ratioPerc = innerPageWidth / width;
      width = innerPageWidth;
      height = height * ratioPerc;
      return { width, height };
    }
  };
  //#endregion HELPER FUNCTIONS
  //=================== END PDF DOC SETUP ==================

  //===================== PARSE REPORT =====================
  let lastImageX;
  // let pageCreatedTracker = false;
  forEach(report, (sec) => {
    if (sec.reportOverview) return;
    if (sec.type === 'image') {
      addImage(
        sec.content.dataUrl,
        sec.content.x ? sec.content.x : undefined,
        sec.content.y ? sec.content.y : undefined,
        sec.content.width,
        sec.content.height
      );
    } else if (sec.type === 'text') {
      let fontSize = 10;
      let fontStyle = 'normal';
      let align = 'left';
      if (sec.content.style && sec.content.style.length) {
        forEach(sec.content.style, (sty) => {
          if (sty === 'title') {
            fontSize = 12;
            fontStyle = 'bold';
          } else if (sty === 'bold' || sty === 'italic') {
            fontStyle = sty;
          } else if (sty === 'italics') {
            fontStyle = 'italic';
          }
        });
      }
      addText(
        sec.content.text,
        fontSize,
        fontStyle,
        align,
        sec.content.x ? sec.content.x : undefined,
        sec.content.y ? sec.content.y : undefined
      );
    } else if (sec.type === 'graph') {
      // adjust width, height to fit page
      let innerPageWidth = pageWidth - margins.left * 2;
      if (sec.content.imgWidth > innerPageWidth && !sec.content.width) {
        let ratioPerc = innerPageWidth / sec.content.imgWidth;
        sec.content.imgWidth = innerPageWidth;
        sec.content.imgHeight = sec.content.imgHeight * ratioPerc;
      } else if (sec.content.width) {
        let ratioPerc =
          (innerPageWidth * (sec.content.width / 100)) / sec.content.imgWidth;
        sec.content.imgWidth = innerPageWidth * (sec.content.width / 100);
        sec.content.imgHeight = sec.content.imgHeight * ratioPerc;
      }
      let graphX;
      let ignoreYChange;
      if (sec.content.width && sec.content.width <= 50) {
        ignoreYChange = true;
        // then we want the images to flex
        if (!lastImageX) lastImageX = margins.left;
        if (lastImageX + sec.content.imgWidth > innerPageWidth + margins.left) {
          graphX = margins.left;
          y += sec.content.imgHeight + 20;
        } else {
          graphX = lastImageX;
        }
      }
      addImage(
        sec.content.dataUrl,
        graphX ? graphX : undefined,
        undefined,
        sec.content.imgWidth,
        sec.content.imgHeight,
        undefined,
        undefined,
        ignoreYChange
      );
      lastImageX = graphX + sec.content.imgWidth;
    } else if (sec.type === 'table') {
      if (sec.content.dataUrl && sec.content.imgWidth > innerPageWidth) {
        let cropRes = cropImage(sec.content.imgWidth, sec.content.imgHeight);
        sec.content.imgWidth = cropRes.width;
        sec.content.imgHeight = cropRes.height;
      }
      addTable(
        sec.content.head,
        sec.content.body,
        sec.content.headStyles,
        sec.content.startY,
        sec.content.theme,
        sec.content.dataUrl,
        sec.content.imgWidth,
        sec.content.imgHeight,
        sec.content.canvas,
        sec.content.element
      );
    } else if (sec.type === 'pagebreak') {
      newPage();
    }
  });
  //===================== END PARSE REPORT =====================

  // ==========================UPLOAD PDF=========================
  let filename = `${client.name()} - ${$filter('dynamicDate')(
    new Date(),
    'MM-dd-yyyy'
  )} - ${toolName}_Comparative_Report`;
  // ==========================SAVE PDF=========================
  pdf.save(`${filename}.pdf`);
  return pdf;
}
