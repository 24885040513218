import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class LogoutWarningModalController extends Modal {
  timeRemaining: number = 30;
  timeout: any;

  @Inject $scope;
  @Inject $timeout;
  @Inject $auth;
  @Inject $modals;

  $setup() {
    this.timeout = this.$timeout(() => this.onTimeout(), 1000);
  }

  onTimeout() {
    if (this.timeRemaining <= 0) {
      // give logout a 1 second delay for user to react at 1 second left on clock
      this.timeout = this.$timeout(this::this.inactiveLogout, 1000);
    } else {
      this.timeRemaining--;
      this.timeout = this.$timeout(this::this.onTimeout, 1000);
    }
  }

  stillHere() {
    this.$timeout.cancel(this.timeout);
    this.$close(false);
  }

  async inactiveLogout() {
    this.$timeout.cancel(this.timeout);

    await this.$auth.logout();

    this.$close(true);

    this.$modals.other.genericNotice({
      title: 'LOGOUT NOTICE',
      text: 'You were automatically logged out due to inactivity.'
    });
  }

  async logout() {
    this.$timeout.cancel(this.timeout);

    await this.$auth.logout();

    this.$close(true);
  }
}

export default angular
  .module('app.logoutWarningModal', [])
  .directive('logoutWarningModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./logout-warning.html'),
    controller: LogoutWarningModalController,
    controllerAs: 'vm'
  })).name;
