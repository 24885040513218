import { INgModelController, IController, IAugmentedJQuery } from 'angular';
import { Ng, Component, Prop, Ref } from '@angular';

@Component({
  name: 'InputText',
  template: require('./input-text.html')
})
class InputText extends Ng {
  @Prop() name: string;
  @Prop() label: string;
  @Prop() value: INgModelController;

  @Ref inputCtrl: IController | IAugmentedJQuery | null;

  focused: boolean = false;

  get occupied(): boolean {
    return this.focused || !!this.value;
  }

  $postLink(): void {
    this.inputCtrl.on('input', this.onInput.bind(this));
    // this.inputCtrl.on('blur', (this.focused = false));
  }

  onInput({ target }): void {
    this.focused = true;

    this.$emit('input', target.value);
  }
}

export default InputText.$module;
