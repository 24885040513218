import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { State, Getter } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

import { filter } from 'lodash';

@Controller
class CreateAggregateReportRequestModalController extends Modal {
  institution: Institution = null;
  selectedInstitutions: Institution[] = [];
  tool: Tool = null;
  startDate: Date = new Date();
  endDate: Date = new Date();
  tools: Array<Tool> = [];
  casePlans: boolean = false;
  offenderHistory: boolean = false;
  supplementalTools: {}[] = null;
  includeCasePlans: boolean = false;
  includeOffenderHistory: boolean = false;
  includeSupplementalTools: boolean = false;
  allInstitutionsSelected: boolean = false;

  instSelectionModel = null;

  get instId() {
    return this.institution?.id || this.institutionId;
  }

  get toolId() {
    return this.tool?.id || null;
  }

  @Inject $scope;
  @Inject $store;
  @Inject $http;
  @Inject utils;
  @Inject aggregateReportsMonitor;

  @State(({ institutions }) => institutions.items) institutions;
  @State(({ tools }) => tools.items) allTools;
  @Getter activeInstId;
  @Getter isAdmin;

  async $setup() {
    this.loadingData = true;
    this.institutionId = null;

    const loaders = [this.$store.dispatch('tools/list')];

    if (this.isAdmin) {
      loaders.push(this.$store.dispatch('institutions/getAll'));
    } else {
      this.institutionId = this.activeInstId;
    }

    await Promise.all(loaders);

    if (!this.isAdmin) {
      this.institutionId = this.activeInstId;
      // this.institutionTools = this.allTools;
      this.tools = this.allTools;
    } else {
      this.tools = filter(this.allTools, 'publishedCommitId');

      this.instSelectionModel = {
        items: this.institutions,
        cols: [
          {
            title: 'Name',
            key: 'name'
          }
        ]
      };
    }

    this.loadingData = false;

    this.$watch(
      () => this.tool?.id,
      () => {
        this.includeCasePlans = false;
        this.includeOffenderHistory = false;
        this.includeSupplementalTools = false;

        this.casePlans = !!this.tool?.aggReportConfig.casePlans.included;
        this.offenderHistory = !!this.tool?.aggReportConfig.offenderHistory
          .included;

        let toolArr = this.tool?.aggReportConfig.supplementalTools;

        if (!toolArr?.length) {
          this.supplementalTools = null;
        } else {
          this.supplementalTools = toolArr.map(o =>
            this.allTools.find(({ id }) => id === o)
          );
        }
      }
    );
  }

  async sendRequest() {
    this.processing = true;

    let params = {
      startDate: this.startDate.toString(),
      endDate: this.endDate.toString(),
      toolId: this.toolId,
      includeCasePlans: this.includeCasePlans,
      includeOffenderHistory: this.includeOffenderHistory,
      includeSupplementalTools: this.includeSupplementalTools
    };

    if (this.isAdmin) {
      params.institutions = this.selectedInstitutions.map(({ id }) => id);
    } else {
      params.institutionId = this.instId;
    }

    let res;

    try {
      res = await this.$store.dispatch('aggregateReports/create', params);
    } catch (err) {
      this.processing = false;
      this.utils.notify('error', 'Error - Sending Request');
      return;
    }

    this.utils.notify('success', 'Request Sent');
    this.processing = false;

    this.$close(res);
  }
}

export default angular
  .module('app.createAggregateReportRequestModal', [])
  .directive('createAggregateReportRequestModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./create-aggregate-report-request.html'),
    controller: CreateAggregateReportRequestModalController,
    controllerAs: 'vm'
  })).name;
