// YLS/CMI Profile Report Generator
import { find, filter, maxBy, forEach, sortBy } from 'lodash';
import angular from 'angular';
import ylsPercentiles from './percentiles';
import ylsProfileReportCoverPage from './coverPage.html';
import ylsProfileReportTables from './tables';
import 'jspdf-autotable';
import fontDataUrls from '../fontDataUrls';
import JsPDF from 'jspdf';

export default {
  async generate(
    mhsLogoDataUrl,
    ylsTitleDataUrl,
    pageSetup,
    services,
    client,
    evaluation,
    tool,
    options,
    $me
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let $http = services.$http;
    let $filter = services.$filter;
    let $templateCache = services.$templateCache;
    let $store = services.$store;
    let $auth = services.$auth;
    let Util = services.$util;
    let notify = services.notify;
    let Upload = services.Upload;
    let $reincode = services.$reincode;

    let parseOption = function (text) {
      // parse options from $me selection to see if it should be included in report
      return find(options, { text: text }).selected;
    };

    let checkEndOfPage = function (y) {
      return y >= pageHeight - margins.bottom - 100;
    };

    // calculate risk categories breakdown
    let findRiskCategories = function (e) {
      let categories;
      forEach(tool.customRiskCategories, (crc) => {
        let criteriaMet = true;
        forEach(crc.criteria, (val, key) => {
          if (key === 'sex' && val != client.sex) criteriaMet = false;
          if (
            key === 'clientNormativeType' &&
            val != e.evaluationData?.additionalInformation?.clientNormativeType
          )
            criteriaMet = false;
        });
        if (criteriaMet) categories = crc.categories;
      });
      return categories;
    };
    let toolRiskCategories = findRiskCategories(evaluation);

    // console.log('evaluation at beginning: ', angular.copy(evaluation));
    let evaluator = evaluation.evaluator;
    // const evaluationAssignedDate = evaluation.assignedDate;
    const assessmentDate = evaluation.assignedDate;
    const interviewDate = evaluation.evaluation?.interview?.dateTime || 'N/A';
    let offenderHistoryId = evaluation.offenderHistoryId;
    if (evaluation.evaluation) evaluation = evaluation.evaluation;
    if (evaluation.evaluationData) evaluation = evaluation.evaluationData;

    if (!evaluator)
      evaluator =
        evaluation.evaluatorId === $me.id
          ? $me
          : find($store.state.users.items, { id: evaluation.evaluatorId });

    // console.log('Generating YLS...');
    // console.log('client: ', client);
    // console.log('evaluation: ', evaluation);
    // console.log('tool: ', tool);

    let offenderHistory;
    // if client doesn't have offender history length, pull offender history
    if (!evaluation.offenderHistory && !client?.offenderHistory.length) {
      let clientOffenderHistory = await $http.get(
        `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history`
      );
      if (clientOffenderHistory) {
        client.offenderHistory = filter(clientOffenderHistory.data, (oh) => {
          return oh.toolId === tool.id;
        });
      }
    }
    if (!evaluation.offenderHistory && client?.offenderHistory.length) {
      if (offenderHistoryId) {
        let evaluationOffenderHistory = await $http.get(
          `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history/${offenderHistoryId}`
        );
        offenderHistory =
          evaluationOffenderHistory.status === 200
            ? evaluationOffenderHistory.data.historyData
            : [];
      } else {
        let toolOh = filter(client.offenderHistory, (oh) => {
          return oh.toolId === tool.id;
        });
        let latestOffenderHistory = maxBy(toolOh, 'updatedAt');
        let evaluationOffenderHistory = await $http.get(
          `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history/${latestOffenderHistory.id}`
        );
        offenderHistory =
          evaluationOffenderHistory.status === 200
            ? evaluationOffenderHistory.data.historyData
            : [];
      }
    } else {
      offenderHistory = evaluation.offenderHistory;
    }

    let evaluationRiskCategory = evaluation.riskCategory;
    let evaluationRiskCategoryOverride =
      evaluation.overrideRiskCategory && evaluation.overrideRiskCategory.length
        ? $reincode.text(evaluation.overrideRiskCategory)
        : null;
    let supervisionLevel = 'N/A';
    let evaluationScoreTotal = 'Score Not Calculated';
    if (evaluation.data) {
      // find supervision level given from the question at 118>Q-rPJjPr9ls
      if (
        evaluation.data['118>Q-rPJjPr9ls'] &&
        (evaluation.data['118>Q-rPJjPr9ls'].text !== '-' ||
          evaluation.data['118>Q-rPJjPr9ls'].fillIn)
      ) {
        supervisionLevel = evaluation.data['118>Q-rPJjPr9ls'].fillIn
          ? $reincode.text(evaluation.data['118>Q-rPJjPr9ls'].fillIn)
          : $reincode.text(evaluation.data['118>Q-rPJjPr9ls'].text);
      }

      // calculate evaluation score total
      evaluationScoreTotal = 0;
      forEach(evaluation.data, (item) => {
        if (typeof item.score === 'number') evaluationScoreTotal += item.score;
      });
    }

    let clientNormativeArea;
    let clientNormativeType;
    if (
      evaluation.additionalInformation &&
      evaluation.additionalInformation.clientNormativeType
    ) {
      clientNormativeType =
        evaluation.additionalInformation.clientNormativeType;
    } else if (client.clientNormativeType) {
      clientNormativeType = client.clientNormativeType;
    }
    if (clientNormativeType.includes('Community'))
      clientNormativeArea = 'community';
    if (clientNormativeType.includes('Custodial'))
      clientNormativeArea = 'custodial';
    if (clientNormativeType.includes('Canadian'))
      clientNormativeArea = 'canadian';
    let percentile = ylsPercentiles.totalScore(
      evaluationScoreTotal,
      client.sex,
      clientNormativeArea
    );

    $templateCache.put(
      'ylsProfileReportCoverPage.html',
      ylsProfileReportCoverPage
    );
    let ylsPRCoverPage = $templateCache.get('ylsProfileReportCoverPage.html');
    let ylsPRCoverPageTable = JSON.stringify(
      ylsProfileReportTables.coverPage()
    );

    // create variable map of known variables in report
    let variableMap = {};
    let key;
    const clientAge = client.age;
    variableMap[`clientAge`] = clientAge;
    const clientLocation = client.location;
    variableMap[`clientLocation`] = clientLocation;
    let clientAssessmentLocation = '';
    if (evaluation.clientZoneName)
      clientAssessmentLocation += `${evaluation.clientZoneName}`;
    if (evaluation.clientRegionName)
      clientAssessmentLocation += ` > ${evaluation.clientRegionName}`;
    if (evaluation.clientSubGroupName)
      clientAssessmentLocation += ` > ${evaluation.clientSubGroupName}`;
    if (clientAssessmentLocation.length == 0)
      clientAssessmentLocation = 'LOCATION NOT FOUND';
    variableMap[`clientAssessmentLocation`] = clientAssessmentLocation;
    for (key in client) {
      if (key === 'type') {
        let typeList = '';
        if (client.type) {
          let types =
            typeof client.type === 'string'
              ? client.type.split(',')
              : client.type;
          if (types.length === 1) {
            typeList = types[0];
          } else {
            forEach(types, (type, index) => {
              if (index === types.length - 1) {
                typeList += `and ${type}`;
              } else {
                typeList += `${type}, `;
              }
            });
          }
        } else {
          typeList = 'No Client Types Provided';
        }
        variableMap[`client.${key}`] = typeList;
      } else if (key === 'fName' || key === 'lName' || key === 'localId') {
        variableMap[`client.${key}`] = client[key] ? client[key] : 'N/A';
      } else {
        variableMap[`client.${key}`] = client[key]
          ? Util.decamlize(client[key])
          : 'N/A';
      }
    }

    // handle gender exclusively
    if (!client.gender) variableMap['client.gender'] = '-';

    variableMap['date.today'] = $filter('dynamicDate')(new Date(), 'longDate');
    variableMap['assessmentDate'] = $filter('dynamicDate')(
      new Date(assessmentDate),
      'longDate'
    );
    variableMap['interviewDate'] = $filter('dynamicDate')(
      new Date(interviewDate),
      'longDate'
    );
    variableMap['clientNormativeType'] = clientNormativeType;

    if (evaluator) {
      for (key in evaluator) {
        if (key === 'fName' || key === 'lName') {
          variableMap[`user.${key}`] = evaluator[key];
        } else {
          variableMap[`user.${key}`] = Util.decamlize(evaluator[key]);
        }
      }
    } else {
      variableMap[`user.fName`] = 'N/A';
      variableMap[`user.lfName`] = 'N/A';
    }

    //================ CREATE VARIABLE MAP =================
    let variableRegEx = Object.keys(variableMap).join('|');
    variableRegEx = variableRegEx.replace(/\[/g, '\\[');
    variableRegEx = variableRegEx.replace(/]/g, '\\]');
    variableRegEx = variableRegEx.replace(/\)/g, '\\)');
    variableRegEx = variableRegEx.replace(/\(/g, '\\(');
    variableRegEx = variableRegEx.replace(/#/g, '\\#');
    variableRegEx = variableRegEx.replace(/\+/g, '\\+');
    variableRegEx = new RegExp(variableRegEx, 'gi');

    ylsPRCoverPage = ylsPRCoverPage.replace(variableRegEx, function (matched) {
      return variableMap[matched];
    });
    ylsPRCoverPageTable = ylsPRCoverPageTable.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    ylsPRCoverPageTable = JSON.parse(ylsPRCoverPageTable);
    //================ END CREATE VARIABLE MAP =================
    //=================== PDF DOC SETUP ==================
    let specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    let pdf = new JsPDF('p', 'pt', 'letter');

    // set comfortaa font
    pdf.addFileToVFS('ComfortaaRegular.tff', fontDataUrls.comfortaaRegular());
    pdf.addFont('ComfortaaRegular.tff', 'Comfortaa', 'normal');
    pdf.addFileToVFS('ComfortaaBold.tff', fontDataUrls.comfortaaBold());
    pdf.addFont('ComfortaaBold.tff', 'Comfortaa', 'bold');
    pdf.setFont('Comfortaa');
    let page = 2;

    let header = function () {
      pdf.setFontSize(11);
      pdf.setFontStyle('normal');
      pdf.text(`Profile Report: ${client.name()}`, margins.left, 21);
      pdf.text(`Page ${page}`, pageWidth - margins.left - 50, 21);
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(margins.left, 25, pageWidth - margins.left, 25);
      page = page + 1;
    };

    let footer = function () {
      pdf.addImage(
        mhsLogoDataUrl,
        'JPEG',
        pageWidth - margins.left - 80,
        pageHeight - margins.bottom,
        80,
        35
      );
    };
    //=================== END PDF DOC SETUP ==================
    // ======================COVER PAGE===============================
    pdf.addImage(ylsTitleDataUrl, 'JPEG', margins.left, margins.top, 500, 102);

    pdf.fromHTML(
      ylsPRCoverPage,
      margins.left,
      margins.top + 100,
      {
        width: margins.width,
        elementHandlers: specialElementHandlers
      },
      function (dispose) {
        // add cover page table with client info
        pdf.autoTable({
          head: ylsPRCoverPageTable.head,
          body: ylsPRCoverPageTable.body,
          columnStyles: ylsPRCoverPageTable.columnStyles,
          startY: 300,
          theme: 'striped',
          headStyles: {
            fillColor: [84, 9, 5],
            textColor: [255, 255, 255]
          }
        });

        // add MHS logo and copyright info
        pdf.setFontSize(9);
        pdf.addImage(mhsLogoDataUrl, 'JPEG', margins.left, 680, 125, 54);
        pdf.text(
          'Copyright © 2011, 2012, 2013 Multi-Health Systems Inc. All rights reserved.\n' +
            'YLS/CMI 2.0 test items and normative data © 2011 Multi-Health Systems Inc.\n' +
            'All rights reserved.\n' +
            'P.O. Box 950, North Tonawanda, NY 14120-0950\n' +
            '3770 Victoria Park Ave., Toronto, ON M2H 3M6',
          margins.left + 135,
          705
        );

        // ==========================END COVER PAGE==============================
        // ========================== PARSE EVALUATION FOR REPORT ====================
        pdf.addPage();
        header();
        footer();
        let y = margins.top + 10;

        // logic for creating new line or new page if needbe
        let newLine = function (y) {
          if (y + 12 > pageHeight - (margins.top + margins.bottom)) {
            pdf.addPage();
            header();
            footer();
            y = margins.top + 20;
          } else {
            y += 12;
          }
          return y;
        };

        let addText = function (
          text,
          fontSize = 10,
          fontStyle = 'normal',
          align = 'left',
          x
        ) {
          if (!text) {
            console.error('Text parameter must be provided');
            return;
          }

          text = $reincode.text(text);
          if (fontSize > 10 && fontSize <= 14) y += 15;
          if (fontSize > 14 && fontSize < 18) y += 20;

          pdf.setFontSize(fontSize);
          pdf.setFontStyle(fontStyle);

          if (!text?.length || typeof text !== 'string') return;
          let lines = pdf.splitTextToSize(text, margins.width);

          forEach(lines, (line) => {
            pdf.setFontSize(fontSize);
            pdf.setFontStyle(fontStyle);
            pdf.text(line, x ? x : margins.left, y, align);
            y = newLine(y);
          });

          y = newLine(y);
        };

        let newPage = function (pageAlreadyAdded) {
          if (!pageAlreadyAdded) pdf.addPage();
          header();
          footer();
          y = margins.top + 10;
        };

        //region INTRODUCTION
        if (parseOption('Introduction')) {
          addText(`Introduction`, 14, 'bold');
          addText(
            "The Youth Level of Service/Case Management Inventory 2.0 (YLS/CMI 2.0) is a risk and needs assessment tool. This report summarizes the results of the YLS/CMI 2.0 assessment, and provides information pertinent to the assessment of the individual. The results of this inventory can be helpful in formulating a case management plan for the juvenile. See the YLS/CMI 2.0 User's Manual published by MHS for more information about this instrument and its results."
          );
          addText(
            'This computerized report is an interpretive aid and should not be given to clients or used as the sole basis for diagnostic decisions and case management plans. This report is most effective when combined with other sources of relevant information.'
          );
          addText(
            'Scores represent the number of items overall or within an assessment area that apply to the youth being assessed.'
          );
        }
        //endregion

        //region NARRATIVE REPORT
        let highRiskList = [];
        let moderateRiskList = [];
        let lowRiskList = [];
        let strengthAreas = [];

        // create risk lists
        let part1 = find(tool.childTools, { id: 119 });
        let toolScores = evaluation.evaluation
          ? evaluation.evaluation.toolScores
          : null;
        if (!toolScores && evaluation.toolScores)
          toolScores = evaluation.toolScores;
        if (!toolScores) {
          console.log('Tool Scores Not Found!');
          return;
        }

        // create risk needs details table body items and strength areas list for later in the report
        let riskNeedsBody = [];
        let strengthQuestionAddresses = [
          {
            toolAddress: '119>108>',
            questionId: 'Q-H9vlzzx_4'
          },
          {
            toolAddress: '119>109>',
            questionId: 'Q-FVHmrUKUB'
          },
          {
            toolAddress: '119>110>',
            questionId: 'Q-95A6K9bHc'
          },
          {
            toolAddress: '119>111>',
            questionId: 'Q-ARYYBrmxc'
          },
          {
            toolAddress: '119>112>',
            questionId: 'Q-A-uIhgljj'
          },
          {
            toolAddress: '119>113>',
            questionId: 'Q-2kJwuWOdL'
          },
          {
            toolAddress: '119>114>',
            questionId: 'Q-fXCMdpKA-'
          }
        ];
        forEach(part1.childTools, (ct) => {
          // process strength question
          let strengthQuestion = find(strengthQuestionAddresses, {
            toolAddress: ct.address
          });
          let strength = false;
          if (strengthQuestion) {
            let evaluationScore =
              evaluation.data[
                `${strengthQuestion.toolAddress}${strengthQuestion.questionId}`
              ];
            if (evaluationScore && evaluationScore.text === 'Yes') {
              strengthAreas.push({
                name: ct.flyoutName,
                comment: $reincode.text(evaluationScore.comment)
              });
              strength = true;
            }
          }

          // process risk needs table details item
          /**
           * area of assessment, items selected, strength, items not selected, comments, sources
           */
          let riskNeedItem = [ct.flyoutName];
          let itemsSelected = '';
          let itemsNotSelected = '';
          let comments = '';
          let sources = [];

          // see if we have toolSourcesOfInformation
          let toolSourceOfInformation =
            evaluation.toolSourcesOfInformation &&
            evaluation.toolSourcesOfInformation[ct.address]
              ? evaluation.toolSourcesOfInformation[ct.address]
              : null;

          forEach(ct.codingFormItems, (cfi) => {
            if (strengthQuestion && strengthQuestion.questionId === cfi.id)
              return;

            let answer = evaluation.data[cfi.longAddress];
            if (answer) {
              if (answer.text === 'Yes') {
                itemsSelected += itemsSelected.length
                  ? `\n- ${cfi.riskFactor}`
                  : `- ${cfi.riskFactor}`;
              } else {
                itemsNotSelected += itemsNotSelected.length
                  ? `\n- ${cfi.riskFactor}`
                  : `- ${cfi.riskFactor}`;
              }

              if (answer.source && !toolSourceOfInformation) {
                if (Array.isArray(answer.source)) {
                  forEach(answer.source, (sc) => {
                    sources.push(
                      sc === 'other' && answer.customSource
                        ? answer.customSource
                        : sc
                    );
                  });
                } else {
                  if (answer.source === 'other' && answer.customSource) {
                    sources.push(answer.customSource);
                  } else {
                    sources.push(answer.source);
                  }
                }
              }
            }
          });

          if (toolSourceOfInformation && !sources.length) {
            if (toolSourceOfInformation.indexOf('other') > -1) {
              forEach(toolSourceOfInformation, (source) => {
                if (
                  source === 'other' &&
                  evaluation.toolSourcesOfInformation[ct.address + 'custom']
                ) {
                  sources.push(
                    evaluation.toolSourcesOfInformation[ct.address + 'custom']
                  );
                } else {
                  sources.push(source);
                }
              });
            } else {
              sources = toolSourceOfInformation;
            }
          }

          let sourcesString = '';
          if (sources.length) {
            sources = [...new Set(sources)];
            forEach(sources, (source, index) => {
              sourcesString =
                index === 0 ? `- ${source}` : `${sourcesString}\n- ${source}`;
            });
          }

          if (evaluation.toolNotes && evaluation.toolNotes[ct.address])
            comments = evaluation.toolNotes[ct.address];

          riskNeedItem.push(itemsSelected);
          if (strengthQuestion) {
            riskNeedItem.push(strength ? 'Yes' : 'No');
          } else {
            riskNeedItem.push('N/A');
          }
          riskNeedItem.push(itemsNotSelected);
          riskNeedItem.push(comments ? $reincode.text(comments) : '');
          riskNeedItem.push(sourcesString);
          riskNeedsBody.push(riskNeedItem);
        });

        forEach(toolScores, (val, key) => {
          let childTool = find(part1.childTools, { address: key });
          if (childTool) {
            switch (val.riskCategory) {
              case 'High':
                highRiskList.push(childTool.flyoutName);
                break;
              case 'Moderate':
                moderateRiskList.push(childTool.flyoutName);
                break;
              case 'Low':
                lowRiskList.push(childTool.flyoutName);
                break;
            }
          }
        });

        let narrativeReport = function () {
          let genderIdentifier = client.sex === 'male' ? 'him' : 'her';
          let text = `${client.name()}'s YLS/CMI 2.0 Total Score is ${evaluationScoreTotal}, which categorizes ${genderIdentifier} as ${evaluationRiskCategory} risk relative to other ${clientNormativeType} ${
            client.sex
          }s.`;

          // parse high risk list
          if (highRiskList.length) {
            text += ` High risk/need factors include `;
            forEach(highRiskList, (risk, index) => {
              if (highRiskList.length === 1) {
                text += `${risk}.`;
              } else if (index === highRiskList.length - 1) {
                text += ` and ${risk}.`;
              } else {
                text += `${risk}, `;
              }
            });
          } else {
            text += ` There were no high risk/need factors.`;
          }

          // parse moderate risk list
          if (moderateRiskList.length) {
            text += ` Moderate risk/need factors include `;
            forEach(moderateRiskList, (risk, index) => {
              if (moderateRiskList.length === 1) {
                text += `${risk}.`;
              } else if (index === moderateRiskList.length - 1) {
                text += ` and ${risk}.`;
              } else {
                text += `${risk}, `;
              }
            });
          } else {
            text += ` There were no moderate risk/need factors.`;
          }

          // parse low risk list
          if (lowRiskList.length) {
            text += ` Low risk/need factors include `;
            forEach(lowRiskList, (risk, index) => {
              if (lowRiskList.length === 1) {
                text += `${risk}.`;
              } else if (index === lowRiskList.length - 1) {
                text += ` and ${risk}.`;
              } else {
                text += `${risk}, `;
              }
            });
          } else {
            text += ` There were no low risk/need factors.`;
          }
          // add supervision level
          text += ` Recommended supervision level is ${supervisionLevel}.`;
          return text;
        };

        if (parseOption('Narrative Report')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(`Narrative Report`, 14, 'bold');
          addText(narrativeReport());
        }
        //endregion

        //region GENERAL NOTES FOR EVALUATION
        if (parseOption('General Notes for Evaluation')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(`General Notes for Evaluation`, 14, 'bold');
          addText(
            evaluation.generalNotes && evaluation.generalNotes.length
              ? $reincode.text(evaluation.generalNotes)
              : `No Notes Recorded`
          );
        }
        //endregion

        //region OVERALL ASSESSMENT BASED....
        if (
          parseOption(
            'Overall Assessment Based on YLS/CMI 2.0 Total Risk/Need Level'
          )
        ) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(
            `Overall Assessment Based on YLS/CMI 2.0 Total Risk/Need Level`,
            14,
            'bold'
          );
          addText(
            `The graph below displays the YLS/CMI 2.0 Total Score and indicates the classification level associated with that score (using user-defined cut-off scores)`
          );
          addText(`Total Score`, 14, 'bold', 'center', pageWidth / 2);
          // draw total score bar graph
          let tSGHeight = 50;
          let tSGWidth = pageWidth / 2;
          let tSGLineHeight = 20;
          let tSGLineWidth = tSGWidth * (evaluationScoreTotal / 42);
          pdf.setDrawColor(0, 0, 0);
          pdf.rect(153, y, tSGWidth, tSGHeight);
          pdf.setDrawColor(255, 0, 0);
          pdf.setLineWidth(tSGLineHeight);
          pdf.line(153, y + 25, 153 + tSGLineWidth, y + 25);
          pdf.setDrawColor(0, 0, 0);
          pdf.setFontSize(10);
          pdf.text(
            `(${evaluationRiskCategory})`,
            153 + tSGLineWidth + 10,
            y + 27
          );
          y += tSGHeight + 25;

          // total score text
          addText(
            `The Total Risk/Need Level is ${evaluationRiskCategory}, with a score of ${evaluationScoreTotal}. The following table shows the cut-off scores used to determine Total Risk/Need Level.`
          );
          // add risk categories table here
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(
            `Standard Cut-Offs`,
            undefined,
            'bold',
            'center',
            pageWidth / 2
          );
          let riskCategories = [];
          toolRiskCategories = sortBy(toolRiskCategories, 'low');
          forEach(toolRiskCategories, (rc) => {
            riskCategories.push([`${rc.low} - ${rc.high}`, `${rc.name}`]);
          });
          pdf.autoTable({
            head: [['Range', 'Risk Level']],
            body: riskCategories,
            startY: y - 10,
            theme: 'grid'
          });
          // set y to height of table plus padding
          y += 25 + (riskCategories.length + 1) * 20; // 25 added for padding and to fit title
        }
        //endregion

        //region COMPARISON TO YOUNG OFFENDERS
        if (parseOption('Comparison to Young Offenders')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(`Comparison to Young Offenders`, 14, 'bold');
          let youngComparisonText = `The score is as high or higher than ${percentile} of a ${
            clientNormativeType.includes('US')
              ? 'US Normative Group*'
              : 'Canadian'
          } of adjudicated ${client.sex} young offenders serving `;
          if (clientNormativeType.includes('Community')) {
            youngComparisonText += `sentences in the community (e.g., parole, probation).`;
          } else if (clientNormativeType.includes('Custodial')) {
            youngComparisonText += `custody sentences.`;
          } else if (clientNormativeType.includes('Canadian')) {
            youngComparisonText += `a probation or custody disposition.`;
          }
          addText(youngComparisonText);
          addText(
            `Note: For details on the normative group, please see Page 33-38 of the YLS/CMI 2.0 User's Manual`
          );
        }
        //endregion

        //region ASSESSMENT OF RISKS AND NEEDS
        if (parseOption('Assessment of Risks and Needs')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(`Assessment of Risks and Needs`, 14, 'bold');
          addText(
            `The graph below displays the risk level for each area of assessment.`
          );

          //region areas of assessment graph here
          let aornMeas = {
            totalWidth: pageWidth * 0.7,
            totalHeight: 200,
            marginLeft: (pageWidth - pageWidth * 0.7) / 2,
            graphWidth: pageWidth * 0.7 * 0.7,
            graphHeight: 180,
            labelsX: (pageWidth - pageWidth * 0.7) / 2 + 130,
            lowX: (pageWidth - pageWidth * 0.7) / 2 + 130 + 50,
            modX: (pageWidth - pageWidth * 0.7) / 2 + 130 + 150,
            highX: (pageWidth - pageWidth * 0.7) / 2 + 130 + 250,
            lineSpacing: 22.5,
            lineHeight: 15
          };

          pdf.setDrawColor(0, 0, 0);
          pdf.rect(
            aornMeas.labelsX,
            y + 20,
            aornMeas.graphWidth,
            aornMeas.graphHeight
          );
          // create header titles
          pdf.setFontStyle('bold');
          pdf.text('Area of Assessment', aornMeas.labelsX, y + 10, 'right');
          pdf.text('Low', aornMeas.lowX, y + 10, 'center');
          pdf.text('Moderate', aornMeas.modX, y + 10, 'center');
          pdf.text('High', aornMeas.highX, y + 10, 'center');

          // create areas, lines, and labels
          y += 30;
          forEach(part1.childTools, (ct) => {
            pdf.setFontSize(9);
            pdf.text(ct.flyoutName, aornMeas.labelsX - 5, y + 2, 'right');
            pdf.setDrawColor(255, 0, 0);
            pdf.setLineWidth(aornMeas.lineHeight);
            let lineWidth;
            let toolScore = toolScores[ct.address];
            if (toolScore) {
              switch (toolScore.riskCategory) {
                case 'Low':
                  lineWidth = aornMeas.lowX;
                  break;
                case 'Moderate':
                  lineWidth = aornMeas.modX;
                  break;
                case 'High':
                  lineWidth = aornMeas.highX;
                  break;
              }
            }
            if (!lineWidth) return;
            pdf.line(aornMeas.labelsX, y, lineWidth, y);
            pdf.setFontSize(10);
            pdf.text(`(${toolScore.score})`, lineWidth + 10, y + 2);
            y += aornMeas.lineSpacing;
          });
          pdf.setDrawColor(0, 0, 0);
          y += 10;
          //endregion

          addText(
            `The following table shows the standard cut-off scores used to determine risk level for each area of assessment.`
          );
          pdf.autoTable({
            head: ylsProfileReportTables.subSectionCutOffScores().head,
            body: ylsProfileReportTables.subSectionCutOffScores().body,
            styles: ylsProfileReportTables.subSectionCutOffScores().styles,
            columnStyles:
              ylsProfileReportTables.subSectionCutOffScores().columnStyles,
            startY: y,
            theme: 'grid'
          });
          // set y to height of table plus padding
          y +=
            25 +
            (ylsProfileReportTables.subSectionCutOffScores().body.length + 1) *
              20; // 25 added for padding and to fit title
        }
        //endregion

        //region PROFILE SUMMARY
        if (parseOption('Profile Summary')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(`Profile Summary`, 14, 'bold');

          if (lowRiskList.length) {
            addText(
              `${Util.numberWord(lowRiskList.length, true)} ${
                lowRiskList.length === 1 ? 'area was' : 'areas were'
              } assessed as low risk:`
            );
            forEach(lowRiskList, (risk) => {
              addText(
                `- ${risk}`,
                undefined,
                undefined,
                undefined,
                margins.left + 10
              );
            });
          } else {
            addText(`No areas were assessed as low risk.`);
          }

          if (moderateRiskList.length) {
            addText(
              `${Util.numberWord(moderateRiskList.length, true)} ${
                moderateRiskList.length === 1 ? 'area was' : 'areas were'
              } assessed as moderate risk:`
            );
            forEach(moderateRiskList, (risk) => {
              addText(
                `- ${risk}`,
                undefined,
                undefined,
                undefined,
                margins.left + 10
              );
            });
          } else {
            addText(`No areas were assessed as moderate risk.`);
          }

          if (highRiskList.length) {
            addText(
              `${Util.numberWord(highRiskList.length, true)} ${
                highRiskList.length === 1 ? 'area was' : 'areas were'
              } assessed as high risk:`
            );
            forEach(highRiskList, (risk) => {
              addText(
                `- ${risk}`,
                undefined,
                undefined,
                undefined,
                margins.left + 10
              );
            });
          } else {
            addText(`No areas were assessed as high risk.`);
          }

          if (strengthAreas.length) {
            addText(
              `${Util.numberWord(strengthAreas.length, true)} ${
                strengthAreas.length === 1 ? 'area was' : 'areas were'
              } assessed as strength areas:`
            );
            let strengthNotesBody = [];
            forEach(strengthAreas, (risk) => {
              addText(
                `- ${risk.name}`,
                undefined,
                undefined,
                undefined,
                margins.left + 10
              );
              strengthNotesBody.push([
                risk.name,
                risk.comment ? risk.comment : 'No Comments Recorded'
              ]);
            });

            pdf.autoTable({
              head: [['Area of Assessment', 'Notes on Strength']],
              body: strengthNotesBody,
              startY: y,
              theme: 'grid',
              columnStyles: {
                0: {
                  minCellWidth: 125
                }
              },
              rowPageBreak: 'avoid',
              didDrawPage: (hookData) => {
                if (hookData.pageNumber > 1) newPage(true);
                y = hookData.cursor.y + 25;
              }
            });
          } else {
            addText(`No areas were assessed as strength areas.`);
          }
        }
        //endregion

        //region DETAILS REGARDING ASSESSMENT OF RISKS AND NEEDS
        if (parseOption('Details Regarding Assessment of Risks and Needs')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(
            `Details Regarding Assessment of Risks and Needs`,
            14,
            'bold'
          );
          addText(
            `The following table contains detailed information regarding each area of assessment, including the specific items that apply to ${client.name()}'s case, sources of information, and comments, and indicates which areas are considered to be ones of strength for ${client.name()}.`
          );
          pdf.autoTable({
            head: ylsProfileReportTables.riskNeedsDetails().head,
            body: riskNeedsBody,
            startY: y,
            columnStyles:
              ylsProfileReportTables.riskNeedsDetails().columnStyles,
            theme: 'grid',
            rowPageBreak: 'avoid',
            didDrawPage: (hookData) => {
              if (hookData.pageNumber > 1) newPage(true);
              y = hookData.cursor.y + 25;
            }
          });
        }
        //endregion

        //region ASSESSMENT OF OTHER NEEDS AND SPECIAL CONSIDERATIONS
        if (
          parseOption('Assessment of Other Needs and Special Considerations')
        ) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(
            `Assessment of Other Needs and Special Considerations`,
            16,
            'bold'
          );
          addText(
            `The following factors should be considered when developing a case management plan:`
          );
          let part2 = find(tool.childTools, { id: 121 });
          let familyParentsItems = [];
          let familyParentsComments =
            evaluation.toolNotes &&
            evaluation.toolNotes.hasOwnProperty(`121>115>`)
              ? evaluation.toolNotes[`121>115>`]
              : 'No information provided';
          if (familyParentsComments)
            familyParentsComments = $reincode.text(familyParentsComments);
          let youthItems = [];
          let youthComments =
            evaluation.toolNotes &&
            evaluation.toolNotes.hasOwnProperty(`121>116>`)
              ? evaluation.toolNotes[`121>116>`]
              : 'No information provided';
          if (youthComments) youthComments = $reincode.text(youthComments);
          forEach(part2.childTools, (ct) => {
            forEach(ct.codingFormItems, (cfi) => {
              let evalItem = evaluation.data[`${ct.address}${cfi.id}`];
              if (!evalItem) return;
              if (!cfi.fillIn) {
                if (evalItem.text === 'Yes') {
                  if (ct.id === 115) {
                    familyParentsItems.push(`${cfi.riskFactor}`);
                  } else if (ct.id === 116) {
                    youthItems.push(`${cfi.riskFactor}`);
                  }
                }
              } else if (evalItem.fillIn && evalItem.fillIn.length) {
                if (ct.id === 115) {
                  familyParentsItems.push(
                    `${cfi.riskFactor}: ${evalItem.fillIn}`
                  );
                } else if (ct.id === 116) {
                  youthItems.push(`${cfi.riskFactor}: ${evalItem.fillIn}`);
                }
              }
            });
          });
          //Family/Parents
          addText(`Family/Parents`, 14, 'bold');
          if (familyParentsItems.length) {
            forEach(familyParentsItems, (item) => {
              addText(item);
            });
          } else {
            addText(`No factors selected.`);
          }
          addText(`Comments: ${familyParentsComments}`);
          //Youth
          addText(`Youth`, 14, 'bold');
          if (youthItems.length) {
            forEach(youthItems, (item) => {
              addText(item);
            });
          } else {
            addText(`No factors selected.`);
          }
          addText(`Comments: ${youthComments}`);
        }
        //endregion

        //region PROFESSIONAL OVERRIDE
        if (parseOption('Professional Override')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(`Professional Override`, 16, 'bold');
          let part3 = find(tool.childTools, { id: 117 });
          let professionalOverrideComments = evaluation.data[`117>Q-grCTpspel`]
            .fillIn
            ? $reincode.text(evaluation.data[`117>Q-grCTpspel`].fillIn)
            : 'No information provided';
          let overrideAnswer = evaluation.data[`${part3.address}Q-gX9DrVrH1`];
          if (overrideAnswer) {
            if (
              overrideAnswer.text === 'No' &&
              evaluationRiskCategoryOverride
            ) {
              addText(
                `The rater's estimate of risk level does differ from that of the inventory. The rater's estimate is: ${evaluationRiskCategoryOverride}`
              );
            } else {
              addText(
                "The rater's estimate of risk level does not differ from that of the inventory."
              );
            }
          }
          addText(`Comments: ${professionalOverrideComments}`);
        }
        //endregion

        //region CONTACT LEVEL
        if (parseOption('Contact Level')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(`Contact Level`, 16, 'bold');
          let part4 = find(tool.childTools, { id: 118 });
          let contactLevelComments = 'No information provided';
          let contactCommentsAnswer =
            evaluation.data[`${part4.address}Q-NGaJBlcyg`];
          if (contactCommentsAnswer && contactCommentsAnswer.fillIn) {
            contactLevelComments = $reincode.text(contactCommentsAnswer.fillIn);
          }
          if (contactLevelComments)
            contactLevelComments = $reincode.text(contactLevelComments);

          addText(
            `Level of supervision selected for this youth: ${supervisionLevel}`
          );
          addText(`Comments: ${contactLevelComments}`);
        }
        //endregion

        //region CASE REVIEW
        if (parseOption('Case Review')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(`Case Review`, 16, 'bold');
          let caseReviewChanges;
          let nonCompliance;
          let actionWithNonCompliance;
          let nonComplianceComments;

          let changesSection;
          let nonComplianceSection;

          if (offenderHistory && offenderHistory.sections) {
            forEach(offenderHistory.sections, (section) => {
              if (section.key === 'generalInformation') {
                changesSection = find(section.sections, { key: 'changes' });
                nonComplianceSection = find(section.sections, {
                  key: 'nonCompliance'
                });
              }
            });
            if (changesSection && changesSection.fields) {
              changesSection = find(changesSection.fields, {
                key: 'changesSinceLastReview'
              });
              if (changesSection) {
                caseReviewChanges = changesSection.model
                  ? changesSection.model
                  : 'No changes listed.';
              }
            }
            if (nonComplianceSection && nonComplianceSection.fields) {
              forEach(nonComplianceSection.fields, (field) => {
                switch (field.key) {
                  case 'nonComplianceWithCourtOrders':
                    nonCompliance = field.model
                      ? $reincode.text(field.model)
                      : 'No non-compliance with court orders listed.';
                    break;
                  case 'actionDealingWithNonCompliance':
                    actionWithNonCompliance = field.model
                      ? $reincode.text(field.model)
                      : 'No actions taken to deal with non-compliance listed.';
                    break;
                  case 'nonComplianceComments':
                    nonComplianceComments = field.model
                      ? $reincode.text(field.model)
                      : 'No non-compliance comments listed.';
                    break;
                }
              });
            }
          }
          // Changes
          addText(`Changes`, 14, 'bold');
          addText(caseReviewChanges);
          // Non-Compliance
          addText(`Non-Compliance`, 14, 'bold');
          addText(`Non-Compliance with Court Orders:`, 12, 'bold');
          addText(nonCompliance);
          addText(`Actions Taken to Deal with Non-Compliance:`, 12, 'bold');
          addText(actionWithNonCompliance);
          addText(`Comments:`, 12, 'bold');
          addText(nonComplianceComments);
        }
        //endregion

        // ==============END PARSE EVALUATION FOR REPORT==================
        // ================END OF REPORT===================
        let today = $filter('dynamicDate')(new Date(), 'fullDate');
        y += 50;
        addText(`Date Printed: ${today}`);
        // addText(`End of Report (Assessment # ${caseplan.evaluation.id})`, null, 'bold');
        // ==========================UPLOAD PDF=========================
        let filename = `${client.name()} - ${$filter('dynamicDate')(
          new Date(),
          'MM-dd-yyyy'
        )} - YLSCMI_Profile_Report`;
        // ==========================SAVE PDF=========================
        pdf.save(`${filename}.pdf`);
        // ==========================UPLOAD PDF=========================
        let pdfFile = pdf.output('arraybuffer');
        // let pdfFile = pdf.output('binary');
        pdfFile = new File([pdfFile], `${filename}.pdf`, {
          type: 'application/pdf'
        });

        Upload.upload({
          url: `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/evaluations/${evaluation.evaluationId}/media`,
          file: pdfFile,
          data: { isReport: true }
        })
          .then((response) => {
            $store.commit('evaluations/setFocus', evaluation.evaluationId);
            $store.dispatch('reports/getForEvaluation', {
              id: evaluation.evaluationId,
              client: client
            });
            return response;
          })
          .catch((err) => {
            return err;
          });
      },
      margins
    );
  }
};
