import { Ng, Component, Prop } from '@angular';

import { COUNTRIES, RELATIONS } from '@constants';
import { FormOption } from '@interfaces';
import { ContactInfo } from '@interfaces/client-contact';
import { indexOf } from 'lodash';

@Component({
  name: 'clientContactForm',
  template: require('./client-contact-form.html')
})
export class ClientContactForm extends Ng {
  @Prop() readonly contact!: ContactInfo;
  @Prop() readonly index!: number;

  readonly countries = COUNTRIES;
  readonly relations = RELATIONS;

  stateProvinces: FormOption<string>[] = [];

  get contactName() {
    const names: string[] = [];

    if (this.contact.fName) names.push(this.contact.fName);
    if (this.contact.lName) names.push(this.contact.lName);

    return names.join(' ');
  }

  $onInit() {
    if (
      this.contact.relation &&
      this.relations.indexOf(this.contact.relation) < 0
    ) {
      this.contact.otherRelation = this.contact.relation;
      this.contact.relation = 'Other...';
    }
    this.$watch(() => this.contact.country, this.onCountryChanged);
  }

  /**
   * ...
   *
   * @return ...
   */
  remove() {
    this.$emit('contact-removed', this.index);
  }

  private onCountryChanged = (value: string | null) => {
    this.stateProvinces = this.$util.getCountryProvinceOptions(value);
  };
}

export default ClientContactForm.$module;
