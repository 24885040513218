'use strict';

import { State, Action, Getter } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardInstitutionsView {
  tableProps: {}[] = [];
  // tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  @Inject $rootScope;
  @Inject $state;
  @Inject $location;
  @Inject $modals;
  @Inject $auth;
  @Inject $store;
  @Inject $stateParams;
  @Inject dataModals;
  @Inject notify;

  @State me;
  @State institutions;
  @State showIdInActions;
  @Action('institutions/getAll') listInstitutions;

  @Getter isAdmin;

  get tableItems() {
    return this.institutions.items;
  }

  async $onInit() {
    var $vm = this;
    var $goTo = this.$state.go;
    var $checkAuth = this.$auth.hasAccess;

    if (this.$stateParams.loadOnClick) {
      try {
        await this.listInstitutions();
      } catch (err) {
        this.notify.display(err, 'error');
      }
    }

    // this.tableItems = this.institutions.items;

    this.tableProps = [
      {
        label: 'Name',
        value: 'name'
      },
      {
        label: 'Country',
        value: 'country'
      },
      // {
      //   label: 'Users & Invites/Max Users',
      //   value: row => {
      //     let denom;
      //
      //     if (row.maxUsers === 0) {
      //       denom = '∞';
      //     } else if (row.maxUsers > 0) {
      //       denom = row.maxUsers;
      //     } else if (row.maxUsers === null || row.maxUsers === undefined) {
      //       denom = 'N/A';
      //     }
      //
      //     return `${row.usersCount} / ${denom}`;
      //   }
      // },
      {
        label: 'Max Users',
        value: (row) => {
          if (row.maxUsers === 0) {
            return '∞';
          } else if (row.maxUsers > 0) {
            return row.maxUsers;
          } else {
            return 'N/A';
          }
        }
      },
      {
        label: 'Active Users',
        value: 'userCount'
      },
      {
        label: 'Clients',
        value: 'clientCount'
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          // GET ==> `/api/invites/${id}/resend`
          {
            label: 'Edit',
            icon: 'edit',
            fn: (val) => $vm.openEditInst(val),
            hide: () =>
              !$vm.$auth.hasAccess('institutionmanager:UpdateInstitution')
          },
          {
            label: 'Update Super Admin',
            icon: 'user',
            fn: (val) => $vm.openUpdateSuperAdmin(val),
            hide: () => !$vm.$auth.hasAccess('gearsmanager:UpdateSuperAdmin')
          }
          //
          // {
          //   label: 'Delete',
          //   icon: 'trashAlt',
          //   fn: val => $vm.instDelete(val),
          //   hide: () => !$vm.$auth.hasAccess('gearsmanager:DeleteInstitution')
          // }
        ]
      }
    ];
  }

  openCreateInst() {
    // this.$modals.create.institution(this.me.id, null)(() => {});

    this.$modals.create.institution();
  }

  openEditInst(val) {
    this.$modals.edit.institution(val);
  }

  openUpdateSuperAdmin(val) {
    this.$modals.edit.institutionSuperAdmin(val);
  }

  instDelete(inst) {
    this.dataModals.delete(inst, 'institution');
  }
}

export default DashboardInstitutionsView;
