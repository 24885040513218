'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class YlscmiComparativeReportChoicesModalController extends Modal {
  $setup() {
    this.evaluations = this.$props.evaluations;
    this.options = [
      {
        text: 'Introduction',
        selected: true
      },
      {
        text: 'Overall Assessment Based on YLS/CMI 2.0 Total Risk/Need Level',
        selected: true
      },
      {
        text: 'YLS/CMI 2.0 Subscale Scores',
        selected: true
      },
      {
        text: 'Profile Comparison',
        selected: true
      },
      {
        text: 'Item Response Table',
        selected: true
      }
    ];
  }

  evalChoiceClicked(e, arr) {
    if (arr.indexOf(e.id) < 0) {
      arr.push(e.id);
    } else {
      arr.splice(e.id, 1);
    }
  }

  submit() {
    this.$close(this.options);
  }
}

export default angular
  .module('app.ylscmiComparativeReportChoicesModal', [])
  .directive('ylscmiComparativeReportChoicesModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./ylscmi-comparative-report-choices.html'),
    controller: YlscmiComparativeReportChoicesModalController,
    controllerAs: 'vm'
  })).name;
