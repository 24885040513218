/**
 * GEARS services by which API route access is organized.
 */
export enum Service {
  GearsManager = 'gearsmanager',
  ClientManager = 'clientmanager',
  InstitutionManager = 'institutionmanager',
  ToolCreator = 'toolcreator',
  ManagedCasePlanTemplates = 'mcpt',
  InstitutionCasePlanTemplates = 'icpt'
}

/**
 * ...
 */
export type GearsManagerServiceKey = Service.GearsManager | 'GM';

/**
 * `GearsManager` service action.
 */
export type GearsManagerAction =
  | 'ListAllServices'
  | 'ListInstitutions'
  | 'ListManagedPolicies'
  | 'ListUsers'
  | 'ListClientTransferRequests'
  | 'ListClients'
  | 'ListEvaluations'
  | 'ListEvaluationStats'
  | 'ListEvaluationRequests'
  | 'ListInvites'
  | 'ListInstitutionTools'
  | 'ListZones'
  | 'ListRegions'
  | 'ListSubGroups'
  | 'ListSubGroupClients'
  | 'ListManagedOffenseClassifications'
  | 'ListInstitutionPolicies'
  | 'ListAllInstitutionPolicies'
  | 'GetManagedPolicy'
  | 'GetUser'
  | 'GetClient'
  | 'GetManagedOffenseClassification'
  | 'GetInstitutionPolicy'
  | 'DeleteEvaluation'
  | 'DeleteInvite'
  | 'CreateManagedOffenseClassification'
  | 'UpdateManagedOffenseClassification'
  | 'UpdateInstitution'
  | 'CreateInstitution'
  | 'UpdateUserCap'
  | 'DeleteInstitution'
  | 'CreateManagedPolicy'
  | 'UpdateManagedPolicy'
  | 'CreateUser'
  | 'InviteUser'
  | 'UpdateUser'
  | 'CreateClient'
  | 'CreateClientContact'
  | 'DeleteClientTransferRequest'
  | 'DeleteEvaluationRequest'
  | 'DeleteZone'
  | 'RemoveUserFromInstitution'
  | 'AddInstitutionTools'
  | 'RemoveInstitutionTools'
  | 'CalculateEvaluationStats';

/**
 * ...
 */
export type GearsManagerServiceAddress =
  `${GearsManagerServiceKey}:${GearsManagerAction}`;

/**
 * ...
 */
export type ClientManagerServiceKey = Service.ClientManager | 'CM';

/**
 * `ClientManager` service action.
 */
export type ClientManagerAction =
  | 'ListClientCasePlans'
  | 'ListClientEvaluations'
  | 'ListClientEvaluators'
  | 'ListClientContacts'
  | 'ListClientReports'
  | 'ListOffenderHistory'
  | 'ListEvaluationReports'
  | 'ListSubGroupClients'
  | 'GetClient'
  | 'GetClientCasePlan'
  | 'GetClientEvaluation'
  | 'GetOffenderHistory'
  | 'GetEvaluationMedia'
  | 'GetReportDocument'
  | 'GetReportPdf'
  | 'UpdateClient'
  | 'UpdateClientContact'
  | 'CreateEvaluationReport'
  | 'EmailReport'
  | 'UploadEvaluationMedia'
  | 'DeleteEvaluationMedia'
  | 'CreateOffenderHistory'
  | 'UpdateOffenderHistory'
  | 'DeleteOffenderHistory'
  | 'CreateClientEvaluation'
  | 'SaveClientEvaluation'
  | 'SubmitClientEvaluation'
  | 'RevertClientEvaluation'
  | 'DeleteClientEvaluation'
  | 'CreateClientCasePlan'
  | 'UpdateClientCasePlanStatus'
  | 'UpdateClientCasePlan';

/**
 * ...
 */
export type ClientManagerServiceAddress =
  `${ClientManagerServiceKey}:${ClientManagerAction}`;

/**
 * ...
 */
export type InstitutionManagerServiceKey = Service.InstitutionManager | 'IM';

/**
 * `InstitutionManager` service action.
 */
export type InstitutionManagerAction =
  | 'ListManagedPolicies'
  | 'ListServices'
  | 'ListInvites'
  | 'ListImportClientRequests'
  | 'ListClients'
  | 'ListClientEvaluations'
  | 'ListIncomingClientRequests'
  | 'ListOutgoingClientRequests'
  | 'ListEvaluations'
  | 'ListEvaluationRequests'
  | 'ListInstitutionUsers'
  | 'ListPolicies'
  | 'ListInstitutionTools'
  | 'ListOffenseClassifications'
  | 'ListZones'
  | 'ListRegions'
  | 'ListSubGroups'
  | 'ListSubGroupClients'
  | 'ListSubGroupClientsEvaluations'
  | 'ListAggregateReportRequests'
  | 'ListImportEvaluationRequests'
  | 'ListUserActivity'
  | 'ListLogRequests'
  | 'GetImportClientRequest'
  | 'GetInstitution'
  | 'GetOffenseClassification'
  | 'GetClient'
  | 'DownloadAggregateReportCSV'
  | 'DownloadLogRequest'
  | 'CreateZone'
  | 'CreateRegion'
  | 'CreateSubGroup'
  | 'UpdateZone'
  | 'UpdateRegion'
  | 'UpdateSubGroup'
  | 'AddClientToSubGroup'
  | 'RemoveClientFromSubGroup'
  | 'CreateEvaluation'
  | 'DeleteEvaluation'
  | 'DeleteOffenseClassification'
  | 'CreateOffenseClassification'
  | 'CreateClientTransferRequest'
  | 'DeleteClientTransferRequest'
  | 'AcceptClientTransferRequest'
  | 'DeclineClientTransferRequest'
  | 'InviteUser'
  | 'ResendInvite'
  | 'DeleteInvite'
  | 'ImportClients'
  | 'ImportEvaluations'
  | 'UpdateInstitution'
  | 'AddUser'
  | 'RemoveUser'
  | 'CreatePolicy'
  | 'UpdatePolicy'
  | 'AssignPolicyToUser'
  | 'RemovePolicyFromUser'
  | 'CreateClient'
  | 'UpdateClient'
  | 'DeleteClient'
  | 'CreateClientContact'
  | 'UpdateClientContact'
  | 'DeleteEvaluationRequest'
  | 'DeleteZone'
  | 'DeleteRegion'
  | 'DeleteSubGroup'
  | 'CreateExpungeClientRequest'
  | 'UpdateOffenseClassification'
  | 'CreateAggregateReportRequest'
  | 'CreateLogRequest';

/**
 * ...
 */
export type InstitutionManagerServiceAddress =
  `${InstitutionManagerServiceKey}:${InstitutionManagerAction}`;

/**
 * ...
 */
export type ToolCreatorServiceKey = Service.ToolCreator | 'TC';

/**
 * `ToolCreator` service action.
 */
export type ToolCreatorAction =
  | 'ListAllTools'
  | 'ListToolCommits'
  | 'ListToolPdfs'
  | 'ListDescriptionPdfs'
  | 'ListToolCodingForms'
  | 'ListToolReportTemplates'
  | 'ListToolReportTemplateCommits'
  | 'ListManagedCasePlanTemplates'
  | 'ListInstitutionCasePlanTemplates'
  | 'ListToolReportTemplateMedia'
  | 'GetTool'
  | 'GetToolCommit'
  | 'GetDescriptionPdf'
  | 'GetCodingForm'
  | 'GetToolPdf'
  | 'GetToolReportTemplateMedia'
  | 'GetToolReportTemplateCommit'
  | 'CreateToolReportTemplate'
  | 'CommitToolReportTemplate'
  | 'UploadToolReportTemplateMedia'
  | 'CommitTool'
  | 'AddToolPdf'
  | 'CreateToolPdf'
  | 'AddDescriptionPdf'
  | 'CreateDescriptionPdf'
  | 'AddCodingForm'
  | 'CreateCodingForm'
  | 'SetLiveCodingForm'
  | 'SetLiveDescriptionPdf'
  | 'SetLiveToolPdf'
  | 'TagToolCommit'
  | 'TagReportTemplateCommit'
  | 'SetToolCommitStatus'
  | 'CreateTool'
  | 'AddChildTool'
  | 'RemoveChildTool'
  | 'ChangeToolPrice'
  | 'DeleteTool';

/**
 * ...
 */
export type ToolCreatorServiceAddress =
  `${ToolCreatorServiceKey}:${ToolCreatorAction}`;

/**
 * ...
 */
export type ManagedCasePlanTemplatesServiceKey =
  | Service.ManagedCasePlanTemplates
  | 'MCPT';

/**
 * ...
 */
export type ManagedCasePlanTemplatesAction =
  | 'ListManagedCasePlanTemplates'
  | 'ListCasePlanTemplateCommits'
  | 'GetCasePlanTemplateCommit'
  | 'CreateCasePlanTemplate'
  | 'CommitManagedCasePlanTemplate'
  | 'TagCasePlanTemplateCommit'
  | 'SetCasePlanTemplateCommitStatus';

/**
 * ...
 */
export type ManagedCasePlanTemplatesServiceAddress =
  `${ManagedCasePlanTemplatesServiceKey}:${ManagedCasePlanTemplatesAction}`;

/**
 * ...
 */
export type InstitutionCasePlanTemplatesServiceKey =
  | Service.InstitutionCasePlanTemplates
  | 'ICPT';

/**
 * ...
 */
export type InstitutionCasePlanTemplatesAction =
  | 'ListCasePlanTemplates'
  | 'ListCasePlanTemplateCommits'
  | 'GetCasePlanTemplateCommit'
  | 'CreateCasePlanTemplate'
  | 'CommitCasePlanTemplate'
  | 'TagCasePlanTemplateCommit'
  | 'SetCasePlanTemplateCommitStatus';

/**
 * ...
 */
export type InstitutionCasePlanTemplatesServiceAddress =
  `${InstitutionCasePlanTemplatesServiceKey}:${InstitutionCasePlanTemplatesAction}`;

/**
 * ...
 */
export type ServiceKey =
  | GearsManagerServiceKey
  | ClientManagerServiceKey
  | InstitutionManagerServiceKey
  | ToolCreatorServiceKey
  | ManagedCasePlanTemplatesServiceKey
  | InstitutionManagerServiceKey;

/**
 * ...
 */
export type ServiceAddress =
  | GearsManagerServiceAddress
  | ClientManagerServiceAddress
  | InstitutionManagerServiceAddress
  | ToolCreatorServiceAddress
  | ManagedCasePlanTemplatesServiceAddress
  | InstitutionManagerServiceAddress;

/**
 * ...
 */
export interface Resource {
  grn: string;
  service: string;
  region: string;
  instId: string;
  resourceName: string;
  resourceValue: string;
}

// const SERVICE_ALIAS_MAP: Record<Permissions.Service, string[]> = {
//   gearsmanager: ['GM'],
//   clientmanager: ['CM'],
//   institutionmanager: ['IM'],
//   toolcreator: ['TC'],
//   mcpt: ['MCPT'],
//   icpt: ['ICPT']
// };
//
// export const _SERVICE_MAP = Object.fromEntries(
//   Object.entries(SERVICE_ALIAS_MAP).flatMap(([key, value]) => [
//     [key, key],
//     ...value.map((alias) => [alias, key])
//   ])
// );
//
// console.log({ _SERVICE_MAP });

/**
 * ...
 */
export const SERVICE_MAP = {
  // Gears Manager service keys.
  gearsmanager: Service.GearsManager,
  GM: Service.GearsManager,
  // Client Manager service keys.
  clientmanager: Service.ClientManager,
  CM: Service.ClientManager,
  // Institution Manager service keys.
  institutionmanager: Service.InstitutionManager,
  IM: Service.InstitutionManager,
  // Tool Creator service keys.
  toolcreator: Service.ToolCreator,
  TC: Service.ToolCreator,
  // Managed Case Plan Templates service keys.
  mcpt: Service.ManagedCasePlanTemplates,
  MCPT: Service.ManagedCasePlanTemplates,
  // Institution Case Plan Templates service keys.
  icpt: Service.InstitutionCasePlanTemplates,
  ICPT: Service.InstitutionCasePlanTemplates
} as const;

/**
 * ...
 */
// type ServiceKey = keyof typeof SERVICE_MAP;

/**
 * ...
 */
export interface ResourceMap {
  [resource: string]: '*' | Record<string, Resource>;
}

/**
 * ...
 */
export interface ActionMap {
  [action: string]: '*' | ResourceMap;
}

/**
 * ...
 */
export interface PermissionProfile {
  allowed: ActionMap;
  denied: '*' | string[];
}

/**
 * ...
 */
export interface PermissionDescriptor {
  action: ServiceAddress;
  resources?: ResourceDescriptor | ResourceDescriptor[];
}

/**
 * ...
 */
export interface ResourceDescriptor {
  name: string;
  values: string | string[];
}

/**
 * ...
 */
export type AccessCheck = ServiceAddress | PermissionDescriptor;

/**
 * ...
 */
export type AccessCheckGroup = (AccessCheck | AccessCheckGroup)[];

/**
 * ...
 */
export interface EffectListMap {
  allowed: string[];
  denied: string[];
}

/**
 * ...
 */
export interface EffectPropsMap {
  allowed: Record<string, string[]>;
  denied: Record<string, string[]>;
}

/**
 * ...
 */
export interface StatementMap {
  actions: EffectListMap;
  resources: EffectPropsMap;
}
