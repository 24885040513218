import { differenceInYears } from 'date-fns';

/**
 * The Client Service for managing the Client Class and Client Functions
 */
class _Client {
  constructor(data: any) {
    // console.log('client data: ', data);
    this.id = data.id;
    this.fName = data.fName;
    this.mName = data.mName;
    this.lName = data.lName;
    this.dob = data.dob;
    this.ethnicity = data.ethnicity;
    this.type = data.type;
    this.country = data.country;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.address3 = data.address3;
    this.city = data.city;
    this.stateProvince = data.stateProvince;
    this.postalCode = data.postalCode;
    this.localId = data.localId;
    this.sex = data.sex;
    this.phone = data.phone;
    this.email = data.email;
    this.clinicalInfo = data.clinicalInfo;
    this.institutionId = data.institutionId;
    this.institution = data.institution;
    this.externalId = data.externalId;
    this.zone = data.zone;
    this.region = data.region;
    if (data.subGroups && data.subGroups.length)
      data.subGroup = data.subGroups[0];
    this.subGroup = data.subGroup;
    this.location = this.getLocation();
    this.offenderHistory = data.offenderHistory || [];
    this.evaluations = data.evaluations || [];
    this.age = this.getAge();
  }

  getAge() {
    return differenceInYears(new Date(), new Date(this.dob));
  }

  getLocation() {
    let location = '';
    if (this.zone) location = location + `${this.zone.name} > `;
    if (this.region) location = location + `${this.region.name} > `;
    if (this.subGroup) location = location + `${this.subGroup.name}`;
    return location;
  }

  name() {
    if (this.mName) return `${this.fName} ${this.mName} ${this.lName}`;
    return `${this.fName} ${this.lName}`;
  }
}

export class ClientService {
  /**
   * ...
   *
   * @param config ...
   * @return ...
   */
  Client(config: any) {
    return new _Client(config);
  }
}
