'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class LsirProfileReportChoicesModalController extends Modal {
  $setup() {
    this.options = [
      {
        text: 'Introduction',
        selected: true
      },
      {
        text: 'Overall Assessment Based on LSI-R Total Score',
        selected: true
      },
      {
        text: 'Assessment Based on LSI-R Score',
        selected: true
      },
      {
        text: 'Comparison to Prison Inmates',
        selected: true
      },
      {
        text: 'Professional Discretion/Override',
        selected: true
      },
      {
        text: 'Assessment of Risk/Needs Based on LSI-R Subcomponents',
        selected: true
      },
      {
        text: 'Details Regarding Subcomponent Risks/Needs',
        selected: true
      },
      {
        text: 'Summary of LSI-R Item Responses',
        selected: true
      },
      {
        text: 'Additional Item Information',
        selected: true
      },
      {
        text: 'Summary of Omitted Responses',
        selected: true
      },
      {
        text: 'Section 2: Specific Risk/Need Factors',
        selected: true
      },
      {
        text: 'Section 3: Prison Experience - Institutional Factors',
        selected: true
      },
      {
        text: 'Section 4: Other Client Issues (Social, Health, and Mental Health)',
        selected: true
      },
      {
        text: 'Section 5: Special Responsivity Considerations',
        selected: true
      },
      {
        text: 'Section 6: Risk/Need Summary and Override',
        selected: true
      },
      {
        text: 'Section 7: Program/Placement Decision',
        selected: true
      }
    ];
  }

  submit() {
    this.$close(this.options);
  }
}

export default angular
  .module('app.lsirProfileReportChoicesModal', [])
  .directive('lsirProfileReportChoicesModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./lsir-profile-report-choices.html'),
    controller: LsirProfileReportChoicesModalController,
    controllerAs: 'vm'
  })).name;
