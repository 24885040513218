import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';

import Modal from '@/mixins/modal.mixin';

@Controller
class ManageMediaModalController extends Modal {
  baseRoute: string = null;

  async $setup() {
    const instId = this.$props.institutionId;
    const subGroupId = this.$props.subGroupId;
    const clientId = this.$props.clientId;
    const evalId = this.$props.evaluationId;

    this.baseRoute = `api/client-manager/${instId}/subgroups/${subGroupId}/clients/${clientId}/evaluations/${evalId}/media`;

    this.filters = {
      isReport: false
    };
  }
}

export default angular
  .module('app.manageMediaModal', [])
  .directive('manageMediaModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./manage-media-modal.html'),
    controller: ManageMediaModalController,
    controllerAs: 'vm'
  })).name;
