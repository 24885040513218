import { State, Mutation, Action, Getter } from 'angular-store';

import { Controller, Inject } from '@decorators/ngCtrl';

@Controller
class DashboardUsersView {
  tableProps: unknown[] = [];
  tableItems: unknown[] = [];
  tableActions: unknown[] = [];
  searchText: string = '';

  @Inject $rootScope;
  @Inject $state;
  @Inject $auth;
  @Inject $api;
  @Inject $modals;
  @Inject $stateParams;
  @Inject utils;
  @Inject errorHandler;
  @Inject dataModals;

  @State users;
  @State me;
  @State institutions;
  @State showIdInActions;

  @Mutation('users/setTableProps') setTableProps;
  @Action('users/list') listUsers;
  @Action('users/getForInstitution') getInstitutionUsers;
  @Action('institutions/getAll') listInstitutions;

  @Getter isAdmin;

  get searchText() {
    return this.users.tableProps.searchText;
  }

  // get tableItems() {
  //   // return this.users?.items?.length ? this.users.items : [];
  //   return [];
  // }

  set searchText(val) {
    this.setTableProps({ searchText: val });
  }

  async $onInit() {
    const $vm = this;

    if (this.$stateParams.loadOnClick && !this.isAdmin) {
      try {
        this.tableItems = await this.listUsers();
      } catch (err) {
        this.$notify.display(err, 'error');
      }
    }

    this.tableProps = [
      {
        label: 'Name',
        value: (row) => `${row.lName}, ${row.fName}`
      },
      {
        label: 'Email',
        value: 'email'
      },
      {
        // label: 'Institution/Developer Group'
        label: 'Institution',
        value: (row) => {
          var output = '';

          row.institutions.forEach((item) => {
            output += '<span>';
            output += item.name;

            output += '</span><br>';
          });

          return output;
        },
        hide: () => !$vm.$auth.hasAccess('gearsmanager:ListInstitutions')
      },
      // {
      //   label: 'Role',
      //   value: row =>
      //     row.roles.map(item => `<span>${item.role.name}</span><br>`).join('')
      // },
      {
        label: 'Active',
        value: (row) => (row.active ? 'Active' : 'Inactive'),
        hide: () => !$vm.$auth.hasAccess('gearsmanager:UpdateUser')
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          // {
          //   label: 'Edit',
          //   icon: 'edit',
          //   fn: val => $vm.dataModals.openEditUser(val),
          //   hide: () => !$vm.$auth.hasAccess('UpdateUser')
          // },
          {
            label: 'Reset Password',
            icon: 'lock',
            fn: (val) => $vm.resetUserPW(val),
            hide: () => !$vm.$auth.hasAccess(['gearsmanager:UpdateUser'])
          },
          // {
          //   label: 'Set Active',
          //   icon: 'check-circle',
          //   fn: val => $vm.changeUserActive(val.id, true),
          //   hide: () => !$vm.$auth.hasAccess('UpdateUser')
          // },
          // {
          //   label: 'Set Inactive',
          //   icon: 'times-circle',
          //   fn: val => $vm.changeUserActive(val.id, false),
          //   hide: () => !$vm.$auth.hasAccess('UpdateUser')
          // },
          {
            label: 'Remove User from Institution',
            icon: 'times',
            fn: (val) => {
              if ($vm.me.institution) {
                $vm.dataModals.delete(val, 'remove-user');
              } else {
                $vm.$modals.delete.removeUserFromInstitution(val.id);
              }
            },
            disabled: (val) => {
              let disabled = true;
              if (val.id != $vm.me.id) disabled = false;
              if ($vm.isAdmin && !val.institutions?.length) disabled = false;
              return disabled;
            },
            hide: () => !$vm.$auth.hasAccess(['institutionmanager:RemoveUser'])
          },
          {
            label: `Disable User`,
            icon: 'times',
            fn: (val) => $vm.changeUserActive(val, false),
            disabled: (val) => !val.active,
            hide: (val) => !$vm.$auth.hasAccess('gearsmanager:UpdateUser')
          },
          {
            label: `Enable User`,
            icon: 'check',
            fn: (val) => $vm.changeUserActive(val, true),
            disabled: (val) => val.active,
            hide: (val) => !$vm.$auth.hasAccess('gearsmanager:UpdateUser')
          }
        ]
      },
      {
        label: 'Profile',
        icon: 'user',
        actions: (val) => $vm.$state.go('dashboardUser', { id: val.id })
      }
    ];

    this.$scope.$watchCollection(
      () => this.users.items,
      (users) => {
        this.tableItems = [...users];
      }
    );
  }

  async changeUserActive(user, active) {
    try {
      let res = await this.$api.GM.updateUser(user.id, {
        userId: user.id,
        active
      });
      if (res.data.error) {
        throw res;
      }

      if (active) {
        this.utils.notify('success', 'User Now Active');
      } else {
        this.utils.notify('success', 'User Now Inactive');
      }

      user.active = active;
      // this.loadInfo();
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async resetUserPW(user) {
    try {
      let res = await this.$api.base.passwordReset({
        email: user.email
      });

      // if (res.data.error) {
      //   throw res;
      // }
      if (res.status !== 200) {
        throw res;
      }

      this.utils.notify('success', 'User Password Reset Initiated');
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async loadInstitutions() {
    if (!this.institutions?.items?.length) {
      this.loadingInstitutions = true;
      try {
        await this.listInstitutions();
      } catch (err) {
        this.notify.display(err, 'error');
      } finally {
        this.loadingInstitutions = false;
      }
    }
  }
}

export default DashboardUsersView;
