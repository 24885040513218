import angular from 'angular';
import { State, Getter, Action } from 'angular-store';

import { Controller, Inject } from '@decorators/ngCtrl';
import { Institution } from '@interfaces';
import { TableProperty } from '@components/display-table/display-table.component';
import { InstitutionState } from '@store/modules/institutions';
import { PoliciesState } from '@store/modules/policies';
import { Policy } from '@/types.flow';

@Controller
class DashboardPoliciesView {
  tableProps: TableProperty[] = [];
  tableActions: any[] = [];
  searchText = '';

  @Inject readonly $scope!: angular.IScope;
  @Inject readonly $state!: angular.ui.IStateService;
  @Inject readonly $rootScope!: angular.IRootScopeService;
  @Inject readonly $modals!: angular.gears.IModalsService;
  @Inject readonly $stateParams!: angular.ui.IStateParamsService;
  @Inject readonly notify!: angular.gears.INotifyService;

  @State policies!: PoliciesState;
  @State institutions!: InstitutionState;
  @Getter('institutions/find') getInstitution!: () => Promise<Institution>;
  @Action('policies/list') listPolicies!: () => Promise<Policy[]>;

  get tableItems() {
    return this.policies.items;
  }

  async $onInit() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const $vm = this;

    if (this.$stateParams.loadOnClick) {
      try {
        await this.listPolicies();
      } catch (err) {
        this.notify.display(err, 'error');
      }
    }

    this.tableProps = [
      {
        label: 'Name',
        value: 'name'
      },
      {
        label: 'Institution',
        value: 'institution.name'
      },
      {
        label: 'Created',
        value: 'createdAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Updated',
        value: 'updatedAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Description',
        value: 'description'
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          {
            label: 'Edit',
            icon: 'edit',
            // fn: $vm.$modals.edit.policy
            fn: (item) => $vm.$state.go('dashboardEditPolicy', { id: item.id })
            // hide: () => !$vm.$auth.hasAccess('UpdateUser')
          }
          // {
          //   label: 'Delete',
          //   icon: 'lock'
          //   // fn: val => $vm.resetUserPW(val),
          //   // hide: () => !$vm.$auth.hasAccess('UpdateUser')
          // }
        ]
      }
      // ,
      // {
      //   label: 'JSON',
      //   icon: 'file-code',
      //   actions: val => $vm.$modals.util.policyJson(val)
      // }
    ];
  }
}

export default DashboardPoliciesView;
