import angular from 'angular';

function compileTemplateDirectiveFactory(
  $compile: angular.ICompileService,
  $parse: angular.IParseService
) {
  'ngInject';

  const link: angular.IDirectiveLinkFn = (scope, element, attrs) => {
    const parsed = $parse(attrs.ngBindHtml);

    scope.$watch(
      () => (parsed(scope) || '').toString(),
      () => $compile(element, undefined, -9999)(scope)
    );
  };

  return { link };
}

export default angular
  .module('app.compileTemplate', [])
  .directive('compileTemplate', compileTemplateDirectiveFactory).name;
