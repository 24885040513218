'use strict';

import { State, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardInvitationsView {
  tableProps: {}[] = [];
  // tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  @Inject $rootScope;
  @Inject $state;
  @Inject $location;
  @Inject $auth;
  @Inject $api;
  @Inject notify;
  @Inject $modals;
  @Inject $stateParams;
  @Inject dataModals;

  @State invitations;
  @Action('invitations/list') listInvitations;

  get tableItems() {
    return this.invitations.items || [];
  }

  async $onInit() {
    const $vm = this;
    var { $auth } = this;

    if (this.$stateParams.loadOnClick) {
      try {
        await this.listInvitations();
      } catch (err) {
        this.notify.display(err, 'error');
      }
    }

    // this.tableItems = this.invitations.items;

    this.tableProps = [
      {
        label: 'Email',
        value: 'email'
      },
      {
        label: 'Institution',
        value: (row) => (row.account || {}).name,
        hide: () => !$auth.hasAccess('gearsmanager:ListInstitutions')
      },
      {
        label: 'Used',
        value: (row) => (row.used ? 'Yes' : 'No')
      },
      // {
      //   label: 'Institution',
      //   value: row => (row.institution || {}).name,
      //   hide: () =>
      //     !$prmsState.ListInstitutions || $prmsState['??_ListDeveloperGroups']
      // },
      // {
      //   label: 'Developer Group',
      //   value: row => (row.developerGroup || {}).name,
      //   hide: () =>
      //     !$prmsState['??_ListDeveloperGroups'] || $prmsState.ListInstitutions
      // },
      // {
      //   label: 'Institution/Developer Group',
      //   value: row =>
      //     `${(row.institution || {}).name || ''}${(row.developerGroup || {})
      //       .name || ''}`,
      //   hide: () =>
      //     !$prmsState.ListInstitutions || !$prmsState['??_ListDeveloperGroups']
      // },
      {
        label: 'Creation Date',
        value: 'createdAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          // GET ==> `/api/invites/${id}/resend`
          {
            label: 'Resend',
            icon: 'paperPlane',
            fn: (val) => $vm.resendInvitation(val),
            hide: () =>
              !$auth.hasAccess('gearsmanager:ListInvites') &&
              !$auth.hasAccess('institutionmanager:ResendInvite'),
            disabled: (val) => val.used
          },
          {
            label: 'Update',
            icon: 'edit',
            fn: (val) => $vm.editInvite(val),
            hide: () => !$auth.hasAccess('gearsmanager:UpdateInvite')
          },
          // DELETE ==> '/api/invites/' + item.inviteId
          {
            label: 'Delete',
            icon: 'trashAlt',
            fn: (val) => $vm.invitationDelete(val),
            hide: () =>
              !$auth.hasAccess('gearsmanager:ListInvites') &&
              !$auth.hasAccess('institutionmanager:DeleteInvite')
          }
        ]
      }
    ];
  }

  async editInvite(invite) {
    await this.$modals.edit.invite(angular.copy(invite));
  }

  async resendInvitation(invite) {
    console.log(invite);
    let instId = invite.account?.id;
    if (!instId) {
      this.notify.display('Can only resend Institution Invites', 'warning');
      return;
    }

    try {
      // let res = this.$api.invitations.resend(id);
      let res = await this.$api.IM.resendInvite({
        instId,
        inviteId: invite.id
      });

      if (res.status !== 200 && res.status !== 204) {
        this.notify.display(res, 'error');
        throw res.data;
      }

      this.notify.display('Invitation Resent', 'success');
    } catch (err) {
      this.notify.display(err, 'error');
    }
  }

  invitationDelete(invt) {
    this.dataModals.delete(invt, 'invitation');
  }
}

export default DashboardInvitationsView;
