'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ToolChangelogModalController extends Modal {
  changelog: any;

  @Inject $scope;

  $setup() {
    this.changelog = this.$modal.item;
  }
}

export default angular
  .module('app.toolChangelogModal', [])
  .directive('toolChangelogModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./tool-changelog.html'),
    controller: ToolChangelogModalController,
    controllerAs: 'vm'
  })).name;
