'use strict';

import angular from 'angular';
import _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class TestModalController extends Modal {
  $setup() {
    console.log('hello!', this);
  }
}

export default angular
  .module('app.testModal', [])
  .directive('testModal', () => ({
    restrict: 'E',
    replace: true,
    bindToController: true,
    scope: {
      props: '<resolve',
      close: '&close',
      dismiss: '&dismiss'
    },
    template: require('./test.html'),
    controller: TestModalController,
    controllerAs: 'vm'
  })).name;
