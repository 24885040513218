'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal, { Submit } from '@/mixins/modal.mixin';

@Controller
class DeleteModalController extends Modal {
  clientName: any;
  itemType: any;

  @Inject $scope;
  @Inject $http;
  @Inject $api;
  @Inject $filter;
  @Inject utils;

  get itemsToDelete() {
    return this.$props || [];
  }

  get topItem() {
    return this.itemsToDelete[0] || {};
  }

  $setup() {
    this.itemType = '';

    if (this.topItem.evaluationRequest) {
      if (this.itemsToDelete.length > 1) {
        this.itemType = 'evaluation requests and corresponding evaluations';
      } else {
        this.itemType = 'evaluation request and corresponding evaluation';
      }

      for (let item of this.itemsToDelete) {
        var client = item.client.name(true);
        var evaluator = item.evaluator.lName + ', ' + item.evaluator.fName;
        item.name =
          'for ' +
          client +
          ' performed by ' +
          evaluator +
          ' using ' +
          item.tool.name +
          ' due ' +
          item.dueDate;
      }
    } else if (this.topItem.invitation) {
      if (this.itemsToDelete.length > 1) {
        this.itemType = 'invitations';
      } else {
        this.itemType = 'invitation';
      }

      for (let item of this.itemsToDelete) {
        if (item.roleId === 1) {
          item.roleName = 'Evaluator';
        } else if (item.roleId === 2) {
          item.roleName = 'Institution Admin';
        } else if (item.roleId === 3) {
          item.roleName = 'Institution Super Admin';
        } else if (item.roleId === 4) {
          item.roleName = 'Developer';
        } else if (item.roleId === 5) {
          item.roleName = 'Developer Admin';
        } else if (item.roleId === 6) {
          item.roleName = 'Developer Super Admin';
        } else if (item.roleId === 7) {
          item.roleName = 'GIFR Admin';
        } else if (item.roleId === 8) {
          item.roleName = 'GIFR Super Admin';
        }
        item.name = item.email + ': ' + item.roleName;
      }
    } else if (this.topItem.user) {
      // if it's a user
      for (let item of this.itemsToDelete) {
        item.name = item.fullName;
      }
      if (this.itemsToDelete.length > 1) {
        this.itemType = 'users';
      } else {
        this.itemType = 'user';
      }
    } else if (this.topItem.client) {
      // if it's a client
      if (this.itemsToDelete.length > 1) {
        this.itemType = 'clients';
      } else {
        this.itemType = 'client';
      }
      for (let item of this.itemsToDelete) {
        item.name = item.lName + ', ' + item.fName + ' ' + item.mName;
      }
    } else if (this.topItem.report) {
      // if it's a report
      if (this.itemsToDelete.length > 1) {
        this.itemType = 'reports';
      } else {
        this.itemType = 'report';
      }
      for (let item of this.itemsToDelete) {
        var date = this.$filter('dynamicDate')(
          item.createdAt,
          'MM/dd/yyyy',
          'UTC'
        );
        item.name = 'Report for ' + item.toolName + ' | Created ' + date;
      }
    } else if (this.topItem.developerGroup && !this.topItem.tool) {
      // if it's a developer group
      if (this.itemsToDelete.length > 1) {
        this.itemType = 'developer groups';
      } else {
        this.itemType = 'developer group';
      }
    } else if (this.topItem.evaluation) {
      // if it's an evaluation
      if (this.itemsToDelete.length > 1) {
        this.itemType = 'evaluations';
      } else {
        this.itemType = 'evaluation';
      }
      this.clientName = this.topItem.clientName;
      for (let item of this.itemsToDelete) {
        item.name =
          item.toolName +
          ' | created at: ' +
          this.$filter('dynamicDate')(
            item.createdAt,
            'MM/dd/yyyy h:mm a',
            'UTC'
          );
      }
    } else if (this.topItem.tool) {
      // if it's a tool
      if (this.itemsToDelete.length > 1) {
        this.itemType = 'tools';
      } else {
        this.itemType = 'tool';
      }
    }
    // delete evaluation request

    // delete instituion

    // delete user

    //delete client

    // delete report

    //delete dev group

    //delete tool

    //delete invite

    //delete evaluation
  }

  deleteEvaluationRequest(item) {
    this.$http
      .delete('/api/evaluation-requests/' + item.id)
      .then((response) => {
        this.deleteCount += 1;
        if (
          this.deleteCount === this.itemsToDelete.length - 1 ||
          this.itemsToDelete.length === 1
        ) {
          this.deleting = false;
          this.$close(this.itemsToDelete);
        }
        if (response.status === 204) {
          this.utils.notify(
            'success',
            'Evaluation Request Deleted Successfully'
          );
        } else {
          this.utils.notify('error', 'Error - Evaluation Request Deletion');
        }
      });
  }

  deleteInstitution(item) {
    this.$http
      .delete('/api/institutions/' + item.institutionId)
      .then((response) => {
        this.deleteCount += 1;
        if (
          this.deleteCount === this.itemsToDelete.length - 1 ||
          this.itemsToDelete.length === 1
        ) {
          this.deleting = false;
          this.$close(this.itemsToDelete);
        }
        if (response.status === 204) {
          this.utils.notify('success', 'Institution Deleted Successfully');
        } else {
          this.utils.notify('error', 'Error - Institution Deletion');
        }
      });
  }

  deleteUser(item) {
    this.$http.delete('/api/users/' + item.id).then((response) => {
      this.deleteCount += 1;
      if (
        this.deleteCount === this.itemsToDelete.length - 1 ||
        this.itemsToDelete.length === 1
      ) {
        this.deleting = false;
        this.$close(this.itemsToDelete);
      }
      if (response.status === 204) {
        this.utils.notify('success', 'User Successfully Deleted');
      } else {
        this.utils.notify('error', 'Error - User Deletion');
      }
    });
  }

  deleteClient(item) {
    this.$http.delete('/api/clients/' + item.id).then((response) => {
      this.deleteCount += 1;
      if (
        this.deleteCount === this.itemsToDelete.length - 1 ||
        this.itemsToDelete.length === 1
      ) {
        this.deleting = false;
        this.$close(this.itemsToDelete);
      }
      if (response.status === 204) {
        this.utils.notify('success', 'Client Successfully Deleted');
      } else {
        this.utils.notify('error', 'Error - Client Deletion');
      }
    });
  }

  deleteReport(item) {
    this.$http
      .delete('/api/reports/' + item.id)
      .then((response) => {
        this.deleteCount += 1;
        if (
          this.deleteCount === this.itemsToDelete.length - 1 ||
          this.itemsToDelete.length === 1
        ) {
          this.deleting = false;
          this.$close(this.itemsToDelete);
        }
        if (response.status === 204) {
          this.utils.notify('success', 'Report Successfully Deleted');
        } else {
          this.utils.notify('error', 'Error - Report Deletion');
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          this.deleteCount += 1;
          if (
            this.deleteCount === this.itemsToDelete.length - 1 ||
            this.itemsToDelete.length === 1
          ) {
            this.deleting = false;
            this.$close(this.itemsToDelete);
          }
          this.utils.notify('error', 'Forbidden - Report Deletion');
        }
      });
  }

  deleteDevGroup(item) {
    this.$http.delete('/api/developer-groups/' + item.id).then((response) => {
      this.deleteCount += 1;
      if (
        this.deleteCount === this.itemsToDelete.length - 1 ||
        this.itemsToDelete.length === 1
      ) {
        this.deleting = false;
        this.$close(this.itemsToDelete);
      }
      if (response.status === 204) {
        this.utils.notify('success', 'Developer Group Successfully Deleted');
      } else {
        this.utils.notify('error', 'Error - Developer Group Deletion');
      }
    });
  }

  deleteTool(item) {
    this.$http.delete('/api/tools/' + item.id).then((response) => {
      this.deleteCount += 1;
      if (
        this.deleteCount === this.itemsToDelete.length - 1 ||
        this.itemsToDelete.length === 1
      ) {
        this.deleting = false;
        this.$close(this.itemsToDelete);
      }
      if (response.status === 204) {
        this.utils.notify('success', 'Tool Successfully Deleted');
      } else {
        this.utils.notify('error', 'Error - Tool Deletion');
      }
    });
  }

  deleteInvite(item) {
    // console.log(item);
    this.$http.delete('/api/invites/' + item.inviteId).then((response) => {
      // console.log(response);
      this.deleteCount += 1;
      if (
        this.deleteCount === this.itemsToDelete.length - 1 ||
        this.itemsToDelete.length === 1
      ) {
        this.deleting = false;
        this.$close(this.itemsToDelete);
      }
      if (!response.data.error) {
        this.utils.notify('success', 'Invitation Successfully Deleted');
      } else {
        this.utils.notify('error', 'Error - Invitation Deletion');
      }
    });
  }

  async deleteEvaluation(item) {
    console.log('DELETING EVALUATIONS');
    console.log(item);
    let res = await this.$api.clientManager.deleteClientEvaluation({
      instId: item.institution.id,
      sbGrpId: item.client.subGroup.id,
      clntId: item.client.id,
      evalId: item.id
    });
    console.log('res: ', res);
    if (res.status === 204) {
      this.utils.notify('success', 'Evaluation Successfully Deleted');
    } else {
      this.utils.notify('error', 'Error - Evaluation Deletion');
    }

    // this.$http
    //   .delete('/api/evaluations/' + item.id)
    //   .then(response => {
    //     this.deleteCount += 1;
    //     if (
    //       this.deleteCount === this.itemsToDelete.length - 1 ||
    //       this.itemsToDelete.length === 1
    //     ) {
    //       this.deleting = false;
    //       this.$close(this.itemsToDelete);
    //     }
    //     if (response.status === 204) {
    //       this.utils.notify('success', 'Evaluation Successfully Deleted');
    //     } else {
    //       this.utils.notify('error', 'Error - Evaluation Deletion');
    //     }
    //   })
    //   .catch(error => {
    //     // console.log(error);
    //   });
  }

  deleteItem() {
    // handle deleting the items
    this.deleting = true;
    this.deleteCount = 0;
    // console.log(this.topItem);
    // check the item type to be deleted

    var deleteMethod = '';

    if (
      this.itemType === 'evaluation requests and corresponding evaluations' ||
      this.itemType === 'evaluation request and corresponding evaluation'
    ) {
      // if it's an evaluation request
      deleteMethod = 'deleteEvaluationRequest';
    } else if (this.itemType === 'client' || this.itemType === 'clients') {
      // if it's a client
      deleteMethod = 'deleteClient';
    } else if (this.itemType === 'user' || this.itemType === 'users') {
      // if it's a user
      deleteMethod = 'deleteUser';
    } else if (
      this.itemType === 'invitation' ||
      this.itemType === 'invitations'
    ) {
      // if it's an invite
      deleteMethod = 'deleteInvite';
    } else if (
      this.itemType === 'evaluation' ||
      this.itemType === 'evaluations'
    ) {
      // if it's an evaluation
      deleteMethod = 'deleteEvaluation';
    } else if (this.itemType === 'reports' || this.itemType === 'report') {
      deleteMethod = 'deleteReport';
    } else if (this.topItem.tool) {
      // if it's a tool
      deleteMethod = 'deleteTool';
    } else if (this.topItem.institution) {
      // if it's an institution
      deleteMethod = 'deleteInstitution';
    }

    for (let item of this.itemsToDelete) {
      this[deleteMethod](item);
    }
  }
}

export default angular
  .module('app.delete-modal', [])
  .directive('deleteModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./delete.html'),
    controller: DeleteModalController,
    controllerAs: 'vm'
  })).name;
