import angular from 'angular';
import { v4 as uuidv4 } from 'uuid';

import { Controller, Inject, Watch, On } from '@decorators/ngCtrl';

import './lister-view.scss';

type ListModel = {};

function escapeRegExp(s) {
  return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
}

@Controller
export class ListerView {
  $models: {} = {};
  $items: {} = {};
  $newItem: ListItem;
  $itemCount: number = 0;
  $selectedItemCtrl: {};
  rootModel: {};
  searchText: string = '';
  searchList: mixed[] = [];
  disabledMessage: string;

  @Inject $scope;
  @Inject $attrs;
  @Inject $auth;
  @Inject $reincode;

  @Watch('searchText')
  onSearch(val) {
    if (!val) {
      return;
    }

    this.searchList = [];

    let words = val
      .split(/\s+/g)
      .map((s) => s.trim())
      .filter((s) => !!s);

    const regex = new RegExp(
      words
        .map((word, i) =>
          i + 1 === words.length && !val.endsWith(' ')
            ? `(?=.*\\b${escapeRegExp(word)})`
            : `(?=.*\\b${escapeRegExp(word)}\\b)`
        )
        .join('') + '.+',
      'gi'
    );

    this.searchList = Object.values(this.$items).filter((item) => {
      return (item.model.queryBy || []).some((key) =>
        regex.test((item.data[key] || '').toString())
      );
    });

    // console.log(this.searchList.map(item => item.name));
  }

  @On('selectListItem')
  onSelection(val) {
    this.$selectedItemCtrl = val;
  }

  @On('addListItem') createListItem;
  @On('postListItem') onPostListItem;
  @On('patchListItem') onPatchListItem;
  @On('deleteListItem') onDeleteListItem;

  $onInit() {
    if (!this.listModel || typeof this.listModel != 'object') {
      throw new Error('[lister-view] Invalid list model data.');
    }

    Object.entries(this.listModel)
      .sort((a, b) => {
        let [keyA, valA] = a;
        let [keyB, valB] = b;

        if (!valB.parentModel || valA.parentModel == keyB) {
          return 1;
        } else if (!valA.parentModel || valB.parentModel == keyA) {
          return -1;
        } else {
          return 0;
        }
      })
      .forEach(([key, val], index) => {
        let model = { $name: key, $level: index + 1, ...val };

        if (index == 0) {
          this.rootModel = model;
        } else if (!val.parentModel) {
          throw new Error('[lister-view] ...');
        }

        this.$models[key] = model;
      });

    this.$scope.$watch(
      () => this.$attrs.disabledMessage,
      (val) => {
        this.disabledMessage =
          this.$attrs.$attr.disabledMessage.charAt(0) == ':'
            ? this.$scope.$eval(val)
            : val;
      }
    );

    this.$scope.$watch(
      () => this.$selectedItemCtrl,
      (val) => (this.selection = val?.item)
    );
    this.$scope.$watch(
      () => this.listItems,
      () => this.generateListItems()
    );
    this.$scope.$watchCollection(
      () => this.$items,
      ($items) => (this.$itemCount = Object.keys($items).length)
    );
  }

  generateListItems() {
    this.$items = {};
    this.$lists = {};
    this.selection = null;
    this.searchText = '';
    this.searchList = [];

    if (!this.listItems) {
      return;
    }

    Object.entries(this.listItems).forEach(([key, val]) => {
      val.forEach((item) => this.addListItem(item, key));
    });

    Object.values(this.$items).forEach((item) => {
      const { model, data } = item;

      if (model.parentModel) {
        let parentModel = this.$models[model.parentModel];
        let parentId = data[parentModel.listIdBy];

        item.parent = this.$items[`${model.parentModel}/${parentId}`];
      }
    });

    this.$scope.$broadcast('updateChildList');
  }

  newListItem(config = {}) {
    const $vm = this;

    let id = config.id || uuidv4();

    const li: ListItem = {
      id,
      name: config.name || `New Item ${id}`,
      model: config.model || $vm.rootModel,
      parent: config.parent || null,
      data: config.data || null,
      isNew: config.isNew || false,
      get highlighted() {
        return li == $vm.selection || li.parent?.highlighted;
      }
    };

    return li;
  }

  createListItem(parent) {
    let model = parent
      ? this.getChildModel(parent.model.$level)
      : this.rootModel;

    let item: ListItem = this.newListItem({
      id: `${model.$name}/new`,
      name: `New ${model.type}`,
      model,
      parent,
      data: null,
      isNew: true
    });

    this.$newItem = item;
    // this.selection = item;

    this.$scope.$broadcast('addChild', {
      parentId: parent?.id,
      item: item
    });
  }

  addListItem(ref, modelKey) {
    const model = this.$models[modelKey];

    let valueId = ref ? ref[model.idBy] : -1;
    const id = `${modelKey}/${valueId}`;

    let item: ListItem = this.newListItem({
      id,
      name: ref ? ref[model.nameBy] : `New ${model.type}`,
      model,
      parent: null,
      data: ref || null,
      isNew: !ref
    });

    this.$items[id] = item;

    return item;
  }

  removeListItem(item) {
    delete this.$items[item.id];

    // if (this.selection == item) {
    //   this.selection =
    // }

    this.searchList = this.searchList.filter(({ id }) => item.id != id);

    this.$scope.$broadcast('removeChild', item);
  }

  getChildModel(level) {
    return Object.values(this.$models).find(
      (model) => model.$level == level + 1
    );
  }

  async onPostListItem(itemId, cancel) {
    const li = this.$newItem;

    if (itemId != li.id) {
      console.error('[lister] create item ID mismatch');
      return;
    }

    if (!li.name || cancel) {
      this.$scope.$broadcast('removeChild', li);
      this.$newItem = null;
      return;
    }

    let res;

    try {
      res = await li.model.add(li, li.parent?.data);

      li.isNew = false;
    } catch (err) {
      console.error('Failed to add new item to item list:', err);

      this.$scope.$broadcast('removeChild', li);
      return;
    }

    li.id = `${li.model.$name}/${res.id}`;
    li.data = res;

    this.$items[li.id] = li;

    console.log('ITEM POSTED!', li);
    this.$newItem = null;

    if (this.searchText) {
      this.onSearch(this.searchText);
    }

    this.$scope.$apply();
  }

  async onPatchListItem(itemId, cancel) {
    let li = this.$items[itemId];

    console.log('PATCH ITEM: ', li);

    if (!li || cancel) {
      return;
    }

    let res;

    try {
      res = await li.model.edit(li, li.parent?.data);
    } catch (err) {
      console.error('Failed to edit list item:', err);
      return;
    }

    // li.data = res;

    console.log('ITEM PATCHED!', li);

    this.$scope.$apply();
  }

  async onDeleteListItem(itemId) {
    let li = this.$items[itemId];

    console.log({ itemId, li });

    if (!li) {
      return;
    }

    let res;

    try {
      res = await li.model.remove(li.data.id);
    } catch (err) {
      console.error('Failed to delete list item:', err);
      return;
    }

    this.removeListItem(li);

    this.$scope.$apply();
  }

  authCheck(arr) {
    if (!arr) return true;
    return this.$auth.hasAccess(arr);
  }
}

export default angular
  .module('lister.listerView', [])
  .directive('listerView', () => {
    return {
      restrict: 'E',
      replace: true,
      transclude: {
        items: 'listerItems',
        selection: 'listerSelection'
      },
      scope: {
        listModel: '=',
        listItems: '=',
        selection: '=',
        loading: '=',
        disabled: '='
        // disabledMessage: '='
      },
      bindToController: true,
      template: require('./lister-view.html'),
      controller: ListerView,
      controllerAs: 'vm'
    };
  }).name;
