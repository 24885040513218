import angular from 'angular';

import { ClientConfig } from '@interfaces/client-config';

/**
 * ...
 */
function clientLabelFilter($store: any) {
  'ngInject';

  const getField = (key: string) =>
    ($store.state.clients.clientConfig?.fields as ClientConfig['fields'])[key];

  return (key: string, ignoreAsterix: boolean = false) => {
    const field = getField(key);

    // for institutions that might not have Client ID specified
    // before we added alias options for Client ID field
    if (!field && key === 'localId') return 'Client ID';

    if (!field) return 'INVALID FIELD';

    let label = field.alias ?? field.label;

    if (!field.allowNull && !ignoreAsterix) {
      label += '*';
    }

    return label;
  };
}

export default angular
  .module('app.clientLabel', [])
  .filter('clientLabel', clientLabelFilter).name;
