'use strict';

import autoImporter from 'auto-importer';

const modules = autoImporter(
  // require.context('@/__modules', true, /index\.js/),
  // filePath => filePath.match(/^(?!\.\/index\.js$).*/g)
  require.context(
    './',
    true,
    /(^\.\/(?!index\.js$)((\w|-|\.)+\.js$|.*\/index\.js$)|.*\.scss$)/
  )
);

export default modules;
