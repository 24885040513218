'use strict';

import angular from 'angular';

import { Controller, Inject, On } from '@/decorators/ngCtrl';

@Controller
class Checkbox {
  @Inject $scope;

  @On('clicked')
  toggleVale() {
    this.value = !this.value;
  }

  get value() {
    return this.ngModelCtrl.$viewValue;
  }

  set value(value) {
    this.ngModelCtrl.$setViewValue(value);
  }
}

export default angular.module('app.checkbox', []).component('checkbox', {
  template: require('./checkbox.html'),
  require: { ngModelCtrl: 'ngModel' },
  controller: Checkbox,
  controllerAs: 'vm'
}).name;
