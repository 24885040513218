'use strict';

import angular from 'angular';

function capitalizeFilter() {
  'ngInject';

  return function capitalize(input, all) {
    var reg = all ? /([^\W_]+[^\s-]*) */g : /([^\W_]+[^\s-]*)/;

    return !!input
      ? input.replace(
          reg,
          txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        )
      : '';
  };
}

export default angular
  .module('app.capitalize', [])
  .filter('capitalize', capitalizeFilter).name;
