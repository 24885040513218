'use strict';

import angular from 'angular';
import * as _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { State, Getter, Action } from 'angular-store';
import Modal, { Submit } from '@/mixins/modal.mixin';

@Controller
class BulkCreateClientsModalController extends Modal {
  clientsCSV: {};
  existingClients: {}[] = [];
  selectedInst: '';

  @Inject $scope;
  @Inject $auth;
  @Inject $api;
  @Inject Notification;
  @Inject notify;
  @Inject utils;
  @Inject Upload;

  @Getter isAdmin;
  @State(({ institutions }) => institutions.items) institutions;
  @Action('institutions/getAll') listInstitutions;

  get instId() {
    return !this.isAdmin ? this.$props?.instId : this.selectedInst;
  }

  async $setup() {
    if (this.instId && !this.isAdmin) {
      this.focusedRequest = null;
      this.loading = true;
      try {
        let reqRes = await this.$api.IM.listImportClientRequests({
          instId: this.instId
        });
        this.requests = reqRes.data;
      } catch (err) {
        this.notify.display(err, 'error');
      } finally {
        this.loading = false;
        this.$scope.$apply();
      }
    } else if (this.isAdmin && !this.institutions?.length) {
      await this.listInstitutions();
    }
  }

  async getInstitutionClientRequests() {
    this.loading = true;
    try {
      let reqRes = await this.$api.IM.listImportClientRequests({
        instId: this.selectedInst
      });
      this.requests = reqRes.data;
    } catch (err) {
      this.notify.display(err, 'error');
    } finally {
      this.loading = false;
      this.$scope.$apply();
    }
  }

  async getRequestDetails(req) {
    req.loading = true;
    let res = await this.$api.IM.getImportClientRequest({
      instId: this.instId,
      importClientRequestId: req.id
    });
    if (res.status !== 200 && res.status !== 204) {
      this.notify.display(res, 'error');
    } else {
      for (let i = 0; i < this.requests.length; i++) {
        if (this.requests[i].id === req.id) {
          this.requests[i] = res.data;
        }
      }
    }
    this.$scope.$apply();
    req.loading = false;
  }

  @Submit
  async bulkCreateClients1() {
    this.uploading = true;
    let { instId, clientsCSV } = this;
    if (clientsCSV.type !== 'text/csv') {
      clientsCSV = clientsCSV.slice(0, clientsCSV.size, 'text/csv');
    }
    if (this.isAdmin) instId = this.selectedInst;
    if (!instId) {
      this.uploading = false;
      console.error(
        '[bulk create clients modal] must supply institution id to upload csv'
      );
      return;
    }
    let res;

    try {
      res = await this.Upload.upload({
        url: `/api/institution-manager/${instId}/import-clients`,
        file: clientsCSV
      });

      if (res?.data?.errors?.length || res?.data?.code || !res) {
        if (!res?.data) {
          this.notify.display(
            `There was an issue uploading your csv. Please contact ${process.env.SUPPORT_EMAIL} for assistance.`,
            'error'
          );
        } else if (res?.data?.errors?.length) {
          _.forEach(res.data.errors, (e) => {
            this.notify.display(e, 'error', true, null, 'right');
          });
        } else {
          this.notify.display(res, 'error');
        }
      } else {
        res.data.createdAt = new Date().toISOString();
        this.notify.display(
          'Your request has been created. Please allow us 5 minutes to process your request and check back here for the results',
          'success',
          undefined,
          'Request Successfully Created'
        );
        if (!Array.isArray(this.requests) || !this.requests) this.requests = [];
        this.requests.push(res.data);
        this.requests = _.orderBy(this.requests, 'createdAt');
        this.clientsCSV = null;
      }
    } catch (err) {
      console.log('err: ', err);
      if (!err?.data) {
        this.notify.display(
          `There was an issue uploading your csv. Please contact ${process.env.SUPPORT_EMAIL} for assistance.`,
          'error'
        );
      } else if (err?.data?.errors?.length) {
        _.forEach(err.data.errors, (e) => {
          this.notify.display(e, 'error', true, null, 'right');
        });
      } else {
        this.notify.display(err, 'error');
      }
    } finally {
      this.uploading = false;
      this.$scope.$apply();
    }
  }
}

export default angular
  .module('app.bulkCreateClients', [])
  .directive('bulkCreateClients', () => ({
    restrict: 'E',
    replace: true,
    template: require('./bulk-create-clients.html'),
    controller: BulkCreateClientsModalController,
    controllerAs: 'vm'
  })).name;
