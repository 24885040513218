'use strict';

import Directive from '@/decorators/ngDirective';

@Directive({
  name: 'menuPointer',
  restrict: 'E',
  replace: true,
  scope: true,
  props: ['side', 'pos'],
  template: `
    <div class="menu-pointer" ng-class="pointerClass" ng-style="styles"><div></div></div>
  `
})
class MenuPointer {
  pointerClass: string;
  styles: {} = {};

  $render() {
    switch (this.side) {
      case 'top':
        this.pointerClass = 'menu-pointer-top';
        this.styles.left = this.pos;
        break;
      case 'right':
        this.pointerClass = 'menu-pointer-right';
        this.styles.top = this.pos;
        break;
      case 'bottom':
        this.pointerClass = 'menu-pointer-bottom';
        this.styles.left = this.pos;
        break;
      case 'left':
        this.pointerClass = 'menu-pointer-left';
        this.styles.top = this.pos;
        break;
      default:
    }
  }
}

export default MenuPointer;
