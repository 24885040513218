'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ToolAgreementModalController extends Modal {
  agreement: any;

  @Inject $scope;
  @Inject $sce;

  $setup() {
    this.agreement = this.$props.agreement;
    this.agreement = this.agreement.replace(/(?:\r\n|\r|\n)/g, '<br />');
    this.agreement = this.$sce.trustAsHtml(this.agreement);
  }

  dismiss() {
    this.$close(false);
  }

  agreed(form) {
    this.$close(true);
  }
}

export default angular
  .module('app.tool-agreement-modal', [])
  .directive('toolAgreementModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./tool-agreement.html'),
    controller: ToolAgreementModalController,
    controllerAs: 'vm'
  })).name;
