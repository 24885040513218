import { Client } from '@interfaces/client';
import { Reminder } from '@interfaces/reminder';
import { Tool } from '@interfaces/tool';

import { RouteController, Get, Post, Put, Delete, RequestMethod, RouteGroup } from '../route-group';

// region Method Parameter Types

interface RouteBaseOptions {}

export interface GetMeOptions extends RouteBaseOptions {
  // ...
}

export interface GetGdprRequestOptions extends RouteBaseOptions {
  gdprRequestId: string;
}

export interface GetGdprRequestsOptions extends RouteBaseOptions {
  // ...
}

export interface GetEvaluationRequestsOptions extends RouteBaseOptions {
  // ...
}

export interface ListRemindersOptions extends RouteBaseOptions {
  // ...
}

export interface LogoutOptions extends RouteBaseOptions {
  // ...
}

export interface CompleteReminderOptions extends RouteBaseOptions {
  reminderId: string;
  completed: boolean;
}

export interface CreateReminderOptions extends RouteBaseOptions {
  type: Reminder['type'];
  clientId: string;
  dueDate: Reminder['dueDate'];
  data: Reminder['data'];
  email: string;
  alerts: Reminder['alerts'];
}

export interface CreateUserOptions extends RouteBaseOptions {
  // ...
}

export interface DismissReminderOptions extends RouteBaseOptions {
  reminderId: Reminder['id'];
}

export interface UpdateMeOptions extends RouteBaseOptions {
  // ...
}

export interface UpdateReminderOptions extends RouteBaseOptions {
  reminderId: Reminder['id'];
  type: Reminder['type'];
  dueDate: Reminder['dueDate'];
  data: Reminder['data'];
  sendEmail: Reminder['sendEmail'];
  alerts: Reminder['alerts'];
}

export interface UpdateReminderAlertsOptions extends RouteBaseOptions {
  reminderId: Reminder['id'];
  alerts: Reminder['alerts'];
}

export interface UpdateReminderDueDateOptions extends RouteBaseOptions {
  reminderId: Reminder['id'];
  dueDate: Reminder['dueDate'];
}

export interface ResetPasswordOptions extends RouteBaseOptions {
  // ...
}

export interface GdprRequestOptions extends RouteBaseOptions {
  // ...
}

export interface ExpungeRequestOptions extends RouteBaseOptions {
  // ...
}

export interface ToggleEmailReminderOptions extends RouteBaseOptions {
  reminderId: Reminder['id'];
  sendEmail: Reminder['sendEmail'];
}

export interface TwofaOptions extends RouteBaseOptions {
  // ...
}

export interface ChangePasswordOptions extends RouteBaseOptions {
  // ...
}

export interface DeleteMeOptions extends RouteBaseOptions {
  // ...
}

export interface DeleteReminderOptions extends RouteBaseOptions {
  reminderId: Reminder['id'];
}

// endregion Method Parameter Types

// region Method Response Types

export interface CreateReminderResponse {
  clientId: Client['id'];
  client: { id: Client['id']; fName: Client['fName']; lName: Client['lName'] };
  toolId: Tool['id'];
  tool: { id: Tool['id']; name: Tool['name'] };
}

// endregion Method Response Types

@RouteController('users')
export class UserRouteGroup extends RouteGroup {
  /** ... */
  @Get('me')
  readonly getMe!: RequestMethod<any, GetMeOptions>;

  /** ... */
  @Get('gdpr-requests/:gdprRequestId')
  readonly getGdprRequest!: RequestMethod<any, GetGdprRequestOptions>;

  /** ... */
  @Get('gdpr-requests')
  readonly getGdprRequests!: RequestMethod<any, GetGdprRequestsOptions>;

  /** ... */
  @Get('evaluation-requests')
  readonly getEvaluationRequests!: RequestMethod<any, GetEvaluationRequestsOptions>;

  /** ... */
  @Get('reminders')
  readonly listReminders!: RequestMethod<any, ListRemindersOptions>;

  /** ... */
  @Get('logout')
  readonly logout!: RequestMethod<any, LogoutOptions>;

  /** ... */
  @Post('reminders/:reminderId/complete')
  readonly completeReminder!: RequestMethod<any, CompleteReminderOptions>;

  /** ... */
  @Post('reminders')
  readonly createReminder!: RequestMethod<CreateReminderResponse, CreateReminderOptions>;

  /** ... */
  @Post('')
  readonly createUser!: RequestMethod<any, CreateUserOptions>;

  /** ... */
  @Post('reminders/:reminderId/dismiss')
  readonly dismissReminder!: RequestMethod<any, DismissReminderOptions>;

  /** ... */
  @Post('me')
  readonly updateMe!: RequestMethod<any, UpdateMeOptions>;

  /** ... */
  @Post('reminders/:reminderId')
  readonly updateReminder!: RequestMethod<any, UpdateReminderOptions>;

  /** ... */
  @Post('reminders/:reminderId/alerts')
  readonly updateReminderAlerts!: RequestMethod<any, UpdateReminderAlertsOptions>;

  /** ... */
  @Post('reminders/:reminderId/due-date')
  readonly updateReminderDueDate!: RequestMethod<any, UpdateReminderDueDateOptions>;

  /** ... */
  @Post('reset-password')
  readonly resetPassword!: RequestMethod<any, ResetPasswordOptions>;

  /** ... */
  @Post('gdpr-requests')
  readonly gdprRequest!: RequestMethod<any, GdprRequestOptions>;

  /** ... */
  @Post('expunge-request')
  readonly expungeRequest!: RequestMethod<any, ExpungeRequestOptions>;

  /** ... */
  @Post('reminders/:reminderId/toggle-email')
  readonly toggleEmailReminder!: RequestMethod<any, ToggleEmailReminderOptions>;

  /** ... */
  @Post('2fa')
  readonly twofa!: RequestMethod<any, TwofaOptions>;

  /** ... */
  @Put('password')
  readonly changePassword!: RequestMethod<any, ChangePasswordOptions>;

  /** ... */
  @Delete('me')
  readonly deleteMe!: RequestMethod<any, DeleteMeOptions>;

  /** ... */
  @Delete('reminders/:reminderId')
  readonly deleteReminder!: RequestMethod<any, DeleteReminderOptions>;
}
