import { Controller } from '@/decorators/ngCtrl';
import { valueOf } from 'angular-cookies';
import { Action, State } from 'angular-store';
import { forEach, find, indexOf, sortBy, filter, findIndex } from 'lodash';
import { isValid } from 'shortid';

@Controller
export default class DashboardTestBedView {
  @State(({ tools }) => tools.items) tools;
  @Action('tools/getToolData') getToolData;
  @Action('tools/list') listTools;

  toolOutline = false;

  get publishedTools() {
    if (!Array.isArray(this.tools)) return [];
    return this.tools.filter((t) => t.publishedCommitId);
  }

  async $onInit() {
    await this.listTools();

    const processCSVForOC = (evt) => {
      const csv = evt.target.files[0];
      if (!csv) return;
      const r = new FileReader();
      let output = [];
      let headers = [];
      r.onload = (e) => {
        const contents = e.target.result;
        const lines = contents.split('\n');

        for (let i = 0; i < lines.length; i++) {
          let l = lines[i];
          let ls = l.split(',');
          if (i === 0) {
            headers = ls.map((hi) => hi.replace(/\s+/g, ' ').trim());
            continue;
          }
          if (!Array.isArray(ls)) continue;
          let ocItem = {};
          ls.forEach((lsi, ind) => {
            ocItem[headers[ind]] = lsi.replace(/\s+/g, ' ').trim();
          });
          output.push(ocItem);
        }

        // PROCESSING FOR PEI SPECIFICALLY
        const categories = {
          '01': 'Crimes Against People',
          '02': 'Crimes Against Property',
          '03': 'Impaired Driving',
          '04': 'Other CCC Offences',
          '05': 'Breach/Failure to Comply',
          '06': 'Federal Statutes',
          '07': 'Drug Offences',
          '08': 'Provincial Statutes',
          '09': 'Municipal By-laws'
        };
        const sections = {
          E911A: 'Emergency 911 Act',
          ACF: 'Aboriginal Communal Fishing Licences',
          AFR: 'Atlantic Fisheries Regulations',
          AHP: 'Animal Health/Protection Act',
          AWA: 'Animal Welfare Act',
          BL: 'By-laws',
          CCC: 'Criminal Code of Canada',
          CCA: 'Cannabis Control Act',
          CDSA: 'Controlled Drugs and Substances Act',
          CS: 'Canada Shipping Act',
          DA: 'Dog Act',
          EI: 'Employment Insurance Act',
          ELC: 'Early Learning and Childcare Act',
          EPA: 'Environmental Protection Act',
          EX: 'Excise Act',
          FA: 'Firearm Act',
          FCA: 'Federal Cannabis Act',
          FD: 'Food and Drug',
          FF: 'Federal Fisheries',
          FG: 'Fish and Game Protection Act',
          FGR: 'Fishery General Regulation',
          FMA: 'Forest Management Act',
          FPA: 'Fire Prevention Act',
          HT: 'Highway Traffic Act',
          IRA: 'Imm/Refugee Protection Act',
          IT: 'Income Tax Act',
          LC: 'Liquor Control Act',
          ME: 'Maintenance Enforcement',
          MPFR: 'Maritime Provinces Fishery Regulations',
          NDA: 'National Defence Act',
          NWPA: "Navigable Waters Protect'n Act",
          OH: 'Off-Highway Vehicle Act',
          OHS: 'Occupational Health and Safety Act',
          PBA: 'Probation Act',
          PHA: 'Public Health Act',
          QA: 'Quarantine Act',
          RA: 'Roads Act',
          SVR: 'Small Vessel Regulations',
          TA: 'Trails Act',
          TP: 'Trespass to Property Act',
          TTA: 'Tobacco Tax Act',
          VFV: 'Victims of Family Violence Act',
          WC: 'Wildlife Conservation Act',
          WL: 'Wildlife Act',
          WWP: 'Watercourse Wetland Protection',
          YCJA: 'Youth Criminal Justice Act'
        };
        // rename categories and Statutes
        forEach(output, (oc) => {
          oc['Category'] = categories[oc['Category']];
          oc['Statute'] = sections[oc['Statute']];
        });

        /**
         * Chapter = Category on PEI worksheet
         * Section = Statute on PEI list
         * Offense Classification:
         *    Article Number = Auto Generated
         *    Label = Full Charge Field on PEI worksheet
         *    Description = Charge Description on PEI worksheet
         *
         * General OC Structure (At bottom of script)
         */
        const oc = [];
        // Create Chapters
        let cindex = 0;
        forEach(categories, (val, key) => {
          oc.push({
            label: val,
            number: cindex + 1,
            sections: []
          });
          cindex++;
        });
        // Create Sections
        forEach(output, (o) => {
          // find the chapter
          let chapter = find(oc, { label: o['Category'] });
          if (!chapter) return;

          // see if the section exists
          let section = find(chapter.sections, { label: o['Statute'] });
          if (!section) {
            chapter.sections.push({
              label: o['Statute'],
              number: chapter.sections.length + 1,
              offenseClassifications: []
            });
            section = find(chapter.sections, { label: o['Statute'] });
          }

          // Create Offense Classification
          section.offenseClassifications.push({
            label: `${o['Full Charge']} - ${o['Charge Description']}`,
            description: o['Charge Description'],
            articleNumber: `${section.number}.${
              section.offenseClassifications.length + 1
            }`
          });
        });

        console.log(oc);
      };
      r.readAsText(csv);
    };
    document
      .getElementById('oc-csv')
      .addEventListener('change', processCSVForOC);
  }

  async createToolOutline() {
    this.toolOutline = true;
    if (!this.tool) return;
    this.t = [];
    let tool = await this.getToolData({
      toolId: this.tool.id,
      commitId: this.tool.publishedCommitId
    });

    const parseTool = (t) => {
      forEach(t.codingFormItems, (cfi) => {
        let answers = '';

        forEach(cfi.codesAndScore, (cas) => {
          if (cas.text === '-') return;
          answers += `${cas.text} | `;
        });

        if (answers.length) answers = answers.substring(0, answers.length - 3);

        this.t.push({
          qid: cfi.id,
          section: t.name,
          riskFactor: cfi.riskFactor,
          answers,
          referenceText: cfi.customReferenceText
        });
      });

      if (t.childTools?.length) forEach(t.childTools, (ct) => parseTool(ct));
    };

    parseTool(tool);
  }
}

/**
 * [
  {
    "label": "New Chapter",
    "number": 1,
    "sections": [
      {
        "label": "New Section",
        "number": 1,
        "offenseClassifications": [
          {
            "label": "feqfe",
            "description": "fweqfew",
            "articleNumber": "1.1"
          },
          {
            "label": "geqge",
            "description": "gwqegweqge",
            "articleNumber": "1.2"
          }
        ]
      }
    ]
  },
  {
    "label": "New Chapter",
    "number": 2,
    "sections": [
      { "label": "New Section", "number": 1, "offenseClassifications": [] }
    ]
  },
  {
    "label": "New Chapter2",
    "number": 3,
    "sections": [
      {
        "label": "New Section23",
        "number": 1,
        "offenseClassifications": [
          {
            "label": "feqfe",
            "description": "fweqfew",
            "articleNumber": "1.1"
          },
          {
            "label": "geqge",
            "description": "gwqegweqge",
            "articleNumber": "1.2"
          }
        ]
      },
      {
        "label": "New Section2",
        "number": 2,
        "offenseClassifications": [
          {
            "label": "feqfe",
            "description": "fweqfew",
            "articleNumber": "2.1"
          },
          {
            "label": "geqge",
            "description": "gwqegweqge",
            "articleNumber": "2.2"
          }
        ]
      }
    ]
  }
]

 */
