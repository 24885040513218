'use strict';

import _ from 'lodash';

function ReportsStore($api, notify) {
  'ngInject';

  return {
    state: {
      loading: false,
      error: false,
      items: [],
      tableProps: {
        sortedCol: 0,
        searchText: ''
      }
    },
    getters: {
      getById: (state) => (id) => {
        return state.items.find((item) => item.id == id);
      }
    },
    actions: {
      async list({ state, commit }) {
        state.loading = true;

        let data = [];

        try {
          let res = await $api.reports.list();
          data = res.data.data;

          commit('setError', false);
        } catch (err) {
          commit('setError', true);

          throw err;
        }

        commit('set', data);

        state.loading = false;

        return state.items;
      },
      async getForEvaluation({ state, commit }, payload) {
        state.loading = true;
        state.items = [];

        let data = [];

        try {
          if (!payload.client) {
            notify.display(
              'reports.getForEvaluation: client needed but not supplied',
              'error'
            );
            return;
          }
          // let res = await $api.evaluations.getReports(id);
          let res = await $api.clientManager.listEvaluationReports({
            instId: payload.client.institutionId,
            sbGrpId: payload.client.subGroup?.id,
            clntId: payload.client.id,
            evalId: payload.id
          });
          data = res.data;

          // grab media for the evaluation
          let evalMediaRes = await $api.clientManager.getClientEvaluation({
            instId: payload.client.institutionId,
            sbGrpId: payload.client.subGroup?.id,
            clntId: payload.client.id,
            evalId: payload.id
          });

          if (evalMediaRes.data?.media?.length) {
            evalMediaRes.data.media = _.filter(evalMediaRes.data.media, {
              isReport: true
            });
            _.forEach(evalMediaRes.data.media, (media) => {
              media.tool = evalMediaRes.data.tool;
            });
            data = data.concat(evalMediaRes.data?.media);
          }

          commit('setError', false);
        } catch (err) {
          commit('setError', true);

          throw err;
        }

        commit('set', data);

        state.loading = false;

        return state.items;
      },
      async delete({ state, commit }, payload) {
        // TODO (Alex) test this once Eric finishes the routes
        if (!payload.client) {
          notify.display('Reports.delete: Must supply a client', 'error');
          return;
        }
        let report = _.find(state.items, { id: payload.id });
        if (!report) {
          notify.display('Could not find report to delete', 'error');
          return;
        }
        let deleteRes;
        if (report.bucket && report.path) {
          // evaluation media
          deleteRes = await $api.clientManager.deleteEvaluationMedia({
            instId: payload.client.institutionId,
            sbGrpId: payload.client.subGroup?.id,
            clntId: payload.client.id,
            evalId: payload.evaluationId,
            mediaId: payload.id
          });
        } else {
          // evaluation report
          deleteRes = await $api.clientManager.deleteEvaluationReport({
            instId: payload.client.institutionId,
            sbGrpId: payload.client.subGroup?.id,
            clntId: payload.client.id,
            evalId: payload.evaluationId,
            reportId: payload.id
          });
        }
        if (deleteRes.status !== 204 && deleteRes.status !== 200) {
          notify.display(deleteRes, 'error');
          return;
        }
        _.remove(state.items, { id: payload.id });
      }
    },
    mutations: {
      set(state, payload) {
        state.items = [...payload];
      },
      add(state, payload) {
        state.items = _.uniqBy([...payload, ...state.items], 'id');
      },
      setError(state, hasError) {
        state.error = hasError;
      },
      update(state, data = []) {},
      CLEAR(state) {
        Object.assign(state, {
          loading: false,
          error: false,
          items: [],
          tableProps: {
            sortedCol: 0,
            searchText: ''
          }
        });
      }
    }
  };
}

export default ReportsStore;
