import { request } from './request';
import { RouteMap } from './route-map';

type MethodType = 'get' | 'post' | 'put' | 'delete';

/**
 * Interface for request method.
 *
 * @param options Request options.
 * @return Data, if any, received from the response.
 */

export interface RequestMethod<T, O extends any = any> {
  (options?: O): Promise<T>;
}

declare global {
  interface Function {
    routeMap?: RouteMap;
    $baseRoute?: string;
  }
}

/**
 * ...
 */
export class RouteGroup {
  protected routeMap: RouteMap;

  constructor(parent?: RouteGroup) {
    this.routeMap = parent
      ? parent.routeMap.extend(this.constructor.$baseRoute!)
      : RouteMap.create(this.constructor.$baseRoute!);
  }

  /**
   * ...
   *
   * @param options ...
   * @return ...
   */
  protected async request(
    method: MethodType,
    routeMap: RouteMap,
    data: Record<string, unknown>
  ) {
    const url = routeMap.getRoute(data, this.routeMap);

    if (method === 'get') return request.get(url);
    if (method === 'post') return request.post(url, data);
    if (method === 'put') return request.put(url, data);
    if (method === 'delete') return request.delete(url);

    return null;
  }
}

// Decorators

const decoratorFactory = (method: MethodType) => (
  route: string,
  params?: string[]
): any => (_target: unknown, _key: string, descriptor: PropertyDescriptor) => {
  const routeMap = RouteMap.create(route, params);

  const transformer =
    typeof descriptor.value === 'function'
      ? (descriptor.value as GenericFunction)
      : (data: unknown) => data;

  const value = async function (this: RouteGroup, data: any) {
    return this.request(method, routeMap, data).then(transformer);
  };

  return { value };
};

/**
 * Decorator for setting the `$baseRoute` of a `RouteGroup` class.
 *
 * @param baseRoute Base route all full routes of the group share.
 */
export function RouteController(baseRoute: string) {
  return ((controller: typeof RouteGroup) => {
    controller.$baseRoute = baseRoute;
  }) as ClassDecorator;
}

/**
 * Decorator for `GET` request methods.
 *
 * @param route The relative api route.
 * @param transformer Function that allows for reponse data to be transformed in
 * some way before being returned.
 */
export const Get = decoratorFactory('get');

/**
 * Decorator for `POST` request methods.
 *
 * @param route The relative api route.
 * @param transformer Function that allows for reponse data to be transformed in
 * some way before being returned.
 */
export const Post = decoratorFactory('post');

/**
 * Decorator for `PUT` request methods.
 *
 * @param route The relative api route.
 * @param transformer Function that allows for reponse data to be transformed in
 * some way before being returned.
 */
export const Put = decoratorFactory('put');

/**
 * Decorator for `DELETE` request methods.
 *
 * @param route The relative api route.
 * @param transformer Function that allows for reponse data to be transformed in
 * some way before being returned.
 */
export const Delete = decoratorFactory('delete');
