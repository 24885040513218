import angular from 'angular';
import { v4 as uuidv4 } from 'uuid';

type CreatorObject = Record<string, unknown>;

/**
 * ...
 */
export type ICreateObjectsService = CreateObjectsService;

/**
 * ...
 */
export interface RegularInstitutionObject extends CreatorObject {
  billingAddressSame: boolean;
  toolChoices: any[];
}

/**
 * ...
 */
export interface DebugInstitutionObject extends CreatorObject {
  name: string;
  maxUsers: number;
  institutionType: string;
  country: string;
  address1: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  superAdminManual: string;
  reassessment: string;
  mfa: string;
  tools: any[];
}

declare module 'angular' {
  namespace gears {
    type ICreateObjectsService = CreateObjectsService;
  }
}

abstract class ObjectCreator<R extends CreatorObject, D extends CreatorObject> {
  constructor(protected regular: Partial<R>, protected debug: Partial<D>) {}

  /**
   * Get the current object instance.
   *
   * @param debug If `true`, will get the debug version of the object.
   * @return The requested object instance.
   */
  get(debug?: boolean) {
    return debug ? this.debug : this.regular;
  }

  /**
   * Update the structure of the regular config object instance.
   *
   * @param regular The new object instance.
   */
  update(regular: any) {
    this.regular = regular;
  }

  /**
   * Empty the current "regular" object instance.
   *
   * @return
   */
  empty() {
    this.regular = {};
  }
}

class InstitutionObjectCreator extends ObjectCreator<
  RegularInstitutionObject,
  DebugInstitutionObject
> {}

export class CreateObjectsService {
  newInstitution = new InstitutionObjectCreator(
    {
      billingAddressSame: true,
      toolChoices: [{}]
    },
    {
      name: `Name ${uuidv4()}`,
      maxUsers: 0,
      institutionType: 'Community Corrections',
      country: 'US',
      address1: '123 Main St',
      city: 'Some City',
      stateProvince: 'VA',
      postalCode: '12345',
      superAdminManual: `giovanniestep+${uuidv4()}@gmail.com`,
      reassessment: 'NOT_ALLOWED',
      mfa: 'NOMFA',
      tools: [{ id: 2 }]
    }
  );
}

export default angular
  .module('app.$createObjects', [])
  .service('$createObjects', CreateObjectsService).name;
