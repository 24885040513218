import angular, {
  animate,
  IController,
  IDirectiveLinkFn,
  IDirectiveFactory
} from 'angular';

import { Controller } from '@decorators/ngCtrl';

import './lister-view-search-list.scss';

/**
 * ...
 */
@Controller
class ListerViewSearchList implements IController {
  $listGroups: unknown[] = [];
}

/**
 * ...
 */
const linkFn: IDirectiveLinkFn = (scope, _element, _attrs, ctrls) => {
  const { vm } = scope;
  const [$$lister] = ctrls;

  vm.$listGroups = Object.values($$lister.$models).map((model) => ({
    model: model.type,
    items: []
  }));

  scope.$watchCollection(
    () => vm.searchList,
    function (list) {
      vm.$listGroups.forEach((group) => {
        for (let i = 0; i < group.items.length; i++) {
          let j = list.findIndex(({ id }) => id == group.items[i].id);

          if (j == -1) {
            group.items.splice(i--, 1);
          }
        }
      });

      list.forEach((item) => {
        let group = vm.$listGroups.find(
          ({ model }) => model == item.model.type
        );

        if (!group.items.includes(item)) {
          group.items.push(item);
        }
      });
    }
  );
};

/**
 * ...
 */
const listerViewSearchListDirectiveFactory: IDirectiveFactory = () => ({
  restrict: 'E',
  require: ['^listerView'],
  replace: true,
  scope: { searchList: '=' },
  link: linkFn,
  bindToController: true,
  template: require('./lister-view-search-list.html'),
  controller: ListerViewSearchList,
  controllerAs: 'vm'
});

/**
 * ...
 */
function listerViewSearchListAnimateFactory(
  $animateCss: animate.IAnimateCssService
) {
  'ngInject';

  const enter: animate.IAnimateCallbackObject['enter'] = (el, done) => {
    if (!el.hasClass('new')) {
      done();
      return;
    }

    el = el.find('>.item-header');

    return $animateCss(el, {
      event: 'enter',
      structural: true,
      easing: 'cubic-bezier(0, 0.63, 0.07, 1.005)',
      from: { height: 0 },
      to: { height: 60, opacity: 1 },
      duration: 0.25
    });
  };

  const leave: animate.IAnimateCallbackObject['leave'] = (el, done) => {
    if (!el.hasClass('new')) {
      done();
      return;
    }

    el = el.find('>.item-header');

    return $animateCss(el, {
      event: 'leave',
      structural: true,
      easing: 'cubic-bezier(0, 0.63, 0.07, 1.005)',
      from: { height: 60, opacity: 1 },
      to: { height: 0, opacity: 0 },
      duration: 0.25
    });
  };

  return { enter, leave };
}

export default angular
  .module('lister.listerViewSearchList', [])
  .directive('listerViewSearchList', listerViewSearchListDirectiveFactory)
  .animation('.lister-list-item', listerViewSearchListAnimateFactory).name;
