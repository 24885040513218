'use strict';

import angular from 'angular';

export default angular
  .module('app.btnSuccess', [])
  .directive('btnSuccess', btnDirective => {
    'ngInject';

    return Object.assign({}, btnDirective[0], {
      template: `
        <button class="btn" type="button" theme="success" ng-class="btnClasses" ng-transclude></button>
      `
    });
  }).name;
