import angular from 'angular';

/**
 * ...
 */
export class Shape {
  x: number;
  y: number;
  w: number;
  h: number;
  fill: string;

  constructor(x: number, y: number, w: number, h: number, fill: string) {
    // This is a very simple and unsafe constructor. All we're doing is checking
    // if the values exist. "x || 0" just means "if there is a value for x, use
    // that. Otherwise use 0." But we aren't checking anything else! We could
    // put "Lalala" for the value of x.
    this.x = x || 0;
    this.y = y || 0;
    this.w = w || 1;
    this.h = h || 1;
    this.fill = fill || '#AAAAAA';
  }

  /**
   * Draws this shape to a given context.
   *
   * @param ctx ...
   */
  draw(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = this.fill;
    ctx.fillRect(this.x, this.y, this.w, this.h);
  }

  /**
   * Determine if a point is inside the shape's bounds.
   *
   * @param mx ...
   * @param my ...
   * @return ...
   */
  contains(mx: number, my: number) {
    // All we have to do is make sure the Mouse X,Y fall in the area between
    // the shape's X and (X + Width) and its Y and (Y + Height)
    return (
      this.x <= mx &&
      this.x + this.w >= mx &&
      this.y <= my &&
      this.y + this.h >= my
    );
  }
}

/**
 * ...
 */
export class CanvasState {
  canvas: HTMLCanvasElement;
  width: number;
  height: number;
  ctx: CanvasRenderingContext2D;
  stylePaddingLeft = 0;
  stylePaddingTop = 0;
  styleBorderLeft = 0;
  styleBorderTop = 0;
  /** when set to false, the canvas will redraw everything. */
  valid = false;
  /** The collection of things to be drawn. */
  shapes: Shape[] = [];
  /**
   * Keep track of when we are dragging the current selected object. In the
   * future we could turn this into an array for multiple selection
   */
  dragging = false;
  selection: Shape | null = null;
  /** See mousedown and mousemove events for explanation. */
  dragoffx = 0;
  dragoffy = 0;
  /**
   * Some pages have fixed-position bars (like the stumbleupon bar) at the top
   * or left of the page. They will mess up mouse coordinates and this fixes
   * that.
   */
  htmlTop = document.body.parentNode.offsetTop;
  htmlLeft = document.body.parentNode.offsetLeft;
  selectionColor = '#0ec7cc';
  selectionWidth = 1;
  interval = 30;

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.width = canvas.width;
    this.height = canvas.height;
    this.ctx = canvas.getContext('2d')!;

    // This complicates things a little but but fixes mouse co-ordinate problems
    // when there's a border or padding. See getMouse for more detail
    if (document.defaultView && document.defaultView.getComputedStyle) {
      const styles = document.defaultView.getComputedStyle(canvas, null);

      this.stylePaddingLeft = parseInt(styles.paddingLeft, 10) || 0;
      this.stylePaddingTop = parseInt(styles.paddingTop, 10) || 0;
      this.styleBorderLeft = parseInt(styles.borderLeftWidth, 10) || 0;
      this.styleBorderTop = parseInt(styles.borderTopWidth, 10) || 0;
    }

    // Fixes a problem where double clicking causes text to get selected on the canvas
    canvas.addEventListener(
      'selectstart',
      this.onSelectStart.bind(this),
      false
    );

    // Up, down, and move are for dragging
    canvas.addEventListener('mousedown', this.onMouseDown.bind(this), true);
    canvas.addEventListener('mousemove', this.onMouseMove.bind(this), true);
    canvas.addEventListener('mouseup', this.onMouseUp.bind(this), true);
    canvas.addEventListener('click', this.onClick.bind(this));

    setInterval(this.draw.bind(this), this.interval);
  }

  /**
   * ...
   *
   * @param shape ...
   */
  addShape(shape: Shape) {
    this.shapes.push(shape);

    this.valid = false;
  }

  /**
   * ...
   */
  clear() {
    this.ctx?.clearRect(0, 0, this.width, this.height);
  }

  /**
   * While draw is called as often as the INTERVAL variable demands, it only
   * ever does something if the canvas gets invalidated by our code.
   *
   * @return ...
   */
  draw() {
    if (this.valid) return;

    this.clear();

    // ** Add stuff you want drawn in the background all the time here **

    // Draw all shapes.

    for (const shape of this.shapes) {
      // We can skip the drawing of elements that have moved off the screen:
      if (
        shape.x > this.width ||
        shape.y > this.height ||
        shape.x + shape.w < 0 ||
        shape.y + shape.h < 0
      ) {
        continue;
      }

      shape.draw(this.ctx);
    }

    // Draw selection. Right now this is just a stroke along the edge of the
    // selected shape.

    if (this.selection !== null) {
      this.ctx.strokeStyle = this.selectionColor;
      this.ctx.lineWidth = this.selectionWidth;
      this.ctx.strokeRect(
        this.selection.x,
        this.selection.y,
        this.selection.w,
        this.selection.h
      );
    }

    // Add stuff you want drawn on top all the time here.
    // ...

    this.valid = true;
  }

  /**
   * Creates an object with x and y defined, set to the mouse position relative
   * to the state's canvas If you wanna be super-correct this can be tricky, we
   * have to worry about padding and borders.
   *
   * @param event ...
   * @return ...
   */
  getMouse(event: MouseEvent) {
    let element: HTMLElement | null = this.canvas;
    let offsetX = 0;
    let offsetY = 0;

    // Compute the total offset.
    if (element.offsetParent !== undefined) {
      do {
        offsetX += element.offsetLeft;
        offsetY += element.offsetTop;
      } while ((element = element.offsetParent as HTMLElement));
    }

    // Add padding and border style widths to offset. Also add the <html>
    // offsets in case there's a position:fixed bar
    offsetX += this.stylePaddingLeft + this.styleBorderLeft + this.htmlLeft;
    offsetY += this.stylePaddingTop + this.styleBorderTop + this.htmlTop;

    const x = event.pageX - offsetX;
    const y = event.pageY - offsetY;

    // We return a simple javascript object (a hash) with x and y defined.
    return { x, y };
  }

  /**
   * ...
   */
  private onSelectStart(e: MouseEvent) {
    e.preventDefault();

    return false;
  }

  /**
   * ...
   */
  private onMouseDown(e: MouseEvent) {
    const { x, y } = this.getMouse(e);

    const selection = [...this.shapes]
      .reverse()
      .find((shape) => shape.contains(x, y));

    if (selection) {
      this.selection = selection;
      this.valid = false;

      return;
    }

    // Havent returned means we have failed to select anything. If there was an
    // object selected, we deselect it.
    if (this.selection) {
      this.selection = null;
      // Need to clear the old selection border
      this.valid = false;
    }
  }

  /**
   * ...
   */
  private onMouseMove(e: MouseEvent) {
    if (!this.dragging) return;

    const { x, y } = this.getMouse(e);

    // We don't want to drag the object by its top-left corner, we want to drag it
    // from where we clicked. Thats why we saved the offset and use it here
    this.selection.x = x - this.dragoffx;
    this.selection.y = y - this.dragoffy;

    // Something's dragging so we must redraw
    this.valid = false;
  }

  /**
   * ...
   */
  private onMouseUp(e: MouseEvent) {
    this.dragging = false;
  }

  /**
   * ...
   */
  private onClick(e: MouseEvent) {
    this.onMouseDown(e);
  }
}

/**
 * ...
 */
export class ToolCanvasService {
  /**
   * ...
   *
   * @return
   */
  init() {
    let s = new CanvasState(document.getElementById('flowchart'));

    s.addShape(new Shape(10, 10, 200, 50, '#295485'));
    // s.addShape(new Shape(40, 40, 50, 50)); // The default is gray
    // s.addShape(new Shape(60, 140, 40, 60, 'lightskyblue'));
    // // Lets make some partially transparent
    // s.addShape(new Shape(80, 150, 60, 30, 'rgba(127, 255, 212, .5)'));
    // s.addShape(new Shape(125, 80, 30, 80, 'rgba(245, 222, 179, .7)'));
  }
}
