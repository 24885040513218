import angular from 'angular';

import { EvalUtilsService } from './eval-utils.v1.service';
// import { EvalUtilsService } from './eval-utils.v2.service'
import { EvlReqUtilsService } from './evl-req-utils.service';

declare module 'angular' {
  namespace gears {
    type IEvalUtilsService = ReturnType<typeof EvalUtilsService>;
    type IEvlReqUtilsService = EvlReqUtilsService;
  }
}

export default angular
  .module('app.evaluations', [])
  .service('evlUtils', EvalUtilsService)
  .service('evlReqUtils', EvlReqUtilsService).name;
