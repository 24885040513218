import angular from 'angular';

import './viewer-text.scss';

export default angular
  .module('fileViewer.textViewer', [])
  .directive('textViewer', $http => {
    'ngInject';

    return {
      require: '^^fileViewer',
      replace: true,
      template: require('./viewer-text.html'),
      scope: true,
      link(scope, element, attrs, viewerCtrl) {
        scope.text = '';
        scope.loading = true;

        $http({
          method: 'get',
          url: viewerCtrl.filePath,
          responseType: 'blob'
          // headers: {
          //   'Content-Type': 'text/plain'
          // }
          // }).then(({ data }) => {
        }).then(res => {
          console.log(res);
          // scope.text = res?.data || res;

          var reader = new FileReader();
          reader.onload = function() {
            // alert(reader.result);
            scope.text = reader.result;
          };

          reader.readAsText(res.data);

          scope.loading = false;
        });
      }
    };
  }).name;
