import Auth from '@aws-amplify/auth';

// Add authorization token to headers
async function request(config: unknown) {
  let token: unknown | null = null;

  try {
    token = (await Auth.currentSession())?.accessToken?.jwtToken;
  } catch {}

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
}

// Intercept 401s and redirect you to login
function responseError(res: unknown) {
  console.log('res: ', res);

  throw res;
  // throw new Error(res);
}

export const interceptor = () => ({ request, responseError });
