'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal, { Submit } from '@/mixins/modal.mixin';

@Controller
class AgreementModalController extends Modal {
  agreement: {} = {
    information: false,
    score: false
  };
  evaluation: {} = null;

  @Inject $scope;
  @Inject $http;
  @Inject Notification;

  $setup() {
    console.log('this.$props: ', this.$props);
    this.evaluation = this.$props.evaluation;
    this.agreement = {
      information: false,
      score: false
    };
    // TRAP-18
    let toolsThatDoNotRequireScoreAgreement = [148];
    this.scoreAgreementNotRequired = toolsThatDoNotRequireScoreAgreement.includes(this.evaluation.tool.id);
    if (this.scoreAgreementNotRequired) this.agreement.score = true;
    console.log('scoreAgreementNotRequired: ', this.scoreAgreementNotRequired);
  }

  agree() {
    this.disagree = false;

    for (var key in this.agreement) {
      if (this.agreement[key] === false) {
        this.disagree = true;
      }
    }

    if (this.disagree) {
      this.Notification.error({
        title: 'Must Agree',
        message:
          'You must agree to all of the criteria to submit your evaluation.',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });
    } else {
      this.$close(true);
    }
  }
}

export default angular
  .module('app.agreementModal', [])
  .directive('agreementModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./agreement.html'),
    controller: AgreementModalController,
    controllerAs: 'vm'
  })).name;
