import angular from 'angular';
import { isValid, addMonths, format } from 'date-fns';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';
import { Tool } from '@interfaces/tool';
import { ClientModel } from '@models/client.model';

interface TypeOption {
  label: string;
  value: Reminder.Type;
}

interface ClientOptions {
  label: string;
  value: string;
}

interface ToolOption {
  label: string;
  value: number;
}

@Controller
class CreateReminderModalController extends Modal {
  @Inject readonly $store!: angular.gears.IStoreService;

  type: string = '';
  dueDate: string | null = null;
  email: boolean = false;
  alerts: number[] = [];
  tool: number | null = null;
  client: string | null = null;
  minDate = new Date();
  nextAlert = 1;
  types: TypeOption[] = [
    { label: 'Assessment Reminder', value: 'ASSESSMENT_REMINDER' }
  ];
  tools: ToolOption[] = [];
  clients: ClientOptions[] = [];
  testval = 10;

  get daysUntil() {
    return parseInt((this.dueDate - this.minDate) / 86400000);
  }

  get submitable() {
    return (
      !this.$forms.createReminder.$invalid &&
      !this.$forms.createReminder.$pristine &&
      !this.processing
    );
  }

  async $setup() {
    this.loadingData = true;
    this.type = 'ASSESSMENT_REMINDER';

    const loaders = [
      this.$store.dispatch('tools/list'),
      this.$store.dispatch('clients/list')
    ];

    await Promise.all(loaders);

    this.loadingData = false;

    this.$scope.$watchCollection(
      () => this.$store.state.tools.items,
      this.onToolsUpdates.bind(this)
    );

    this.$scope.$watchCollection(
      () => this.$store.state.clients.items,
      this.onClientsUpdates.bind(this)
    );

    if (this.$props.client) {
      this.client = this.$props.client.id;
      this.clientSearch = `${this.$props.client.name(true)}`;
    }

    if (this.$props.toolId) {
      this.tool = this.$props.toolId;
    }
  }

  addAlert() {
    if (!this.nextAlert || this.nextAlert <= 0) {
      return;
    }

    this.alerts.push(this.nextAlert);
    this.nextAlert = 1;
  }

  removeAlert(i) {
    this.alerts.splice(i, 1);
  }

  setDueDate(months) {
    let dueDate = addMonths(new Date(), months);
    this.dueDate = format(dueDate, 'MM/dd/yyyy');
  }

  async sendRequest() {
    this.processing = true;
    if (isValid(new Date(this.dueDate))) this.dueDate = new Date(this.dueDate);

    let params = {
      type: this.type,
      clientId: this.client,
      dueDate: this.dueDate.toISOString(),
      data: {
        toolId: this.tool,
        clientId: this.client
      },
      email: this.email,
      alerts: this.alerts
    };

    let res;

    try {
      res = await this.$store.dispatch('reminders/create', params);
    } catch (err) {
      this.processing = false;
      this.utils.notify('error', 'Error - Sending Request');
      return;
    }

    this.utils.notify('success', 'Request Sent');
    this.processing = false;

    this.$close(res);
  }

  /**
   * ...
   */
  private onClientsUpdates(clients: ClientModel[]) {
    this.clients = (clients ?? []).map((o) => ({
      label: o.name(true),
      value: o.id
    }));
  }

  /**
   * ...
   */
  private onToolsUpdates(tools: Tool[]) {
    this.tools = (tools ?? [])
      .filter((item) => item.publishedCommitId)
      .map((o) => ({ label: o.name, value: o.id }));
  }
}

export default angular
  .module('app.createReminderModal', [])
  .directive('createReminderModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./create-reminder-modal.html'),
    controller: CreateReminderModalController,
    controllerAs: 'vm'
  })).name;
