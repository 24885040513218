import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';

import Modal from '@/mixins/modal.mixin';

@Controller
class GenericNoticeModalController extends Modal {}

export default angular
  .module('app.genericNoticeModal', [])
  .directive('genericNoticeModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./generic-notice-modal.html'),
    controller: GenericNoticeModalController,
    controllerAs: 'vm'
  })).name;
