import { v4 as uuidv4 } from 'uuid';
import { filter, sortBy, uniqBy } from 'lodash';

import { Controller, Inject, On } from '@/decorators/ngCtrl';
import { State, Action, Getter } from 'angular-store';
// import UtilPane from '@/mixins/util-pane.mixin';

interface Chapter {
  number: number;
  label: string;
  sections: Section[];
}

interface Section {
  number: number;
  label: string;
  offenseClassifications: Classification[];
}

interface Classification {
  articleNumber: string;
  label: string;
  description: string;
}

@Controller
class DashboardOffenseClassificationsView {
  selection: any;
  breadCrumbs: string = '';
  selectedInstId: number = null;
  //
  listModel: unknown = {};
  listItems: unknown = {};
  //
  chapters: unknown[] = [];
  sections: unknown[] = [];
  classifications: unknown[] = [];
  //
  tableProps: TableProperty[] = [];
  tableItems: unknown[] = [];
  tableActions: unknown[] = [];
  searchText: string = '';
  loadingTable: boolean = false;

  @Inject $scope;
  @Inject $store;
  @Inject $auth;
  @Inject $api;
  @Inject $modals;
  @Inject Notification;
  @Inject notify;
  @Inject $location;
  @Inject utils;

  @State(({ institutions }) => institutions.items) institutions;
  @Getter activeInstId;

  @Action('offenseClassifications/get') getOffenseClassification;
  @Action('offenseClassifications/update') updateOffenseClassification;
  @Action('offenseClassifications/list') listInstitutionOffenseClassifications;
  @Action('institutions/getAll') listInstitutions;

  buildJSON() {
    const getChapterSections = (chapterId) => {
      return _.filter(this.sections, { chapterId });
    };

    const getSectionArticles = (sectionId) => {
      return _.filter(this.classifications, (c) => c.section?.id === sectionId);
    };

    return this.chapters.map((chapter, i) => ({
      number: i + 1,
      label: chapter.label,
      sections: getChapterSections(chapter.id).map((section, j) => ({
        number: j + 1,
        label: section.label,
        offenseClassifications: getSectionArticles(section.id).map((oc) => ({
          articleNumber: `${i + 1}.${j + 1}`,
          label: oc.label,
          description: oc.description
        }))
      }))
    }));
  }

  async updateOC() {
    let data = this.buildJSON();

    try {
      await this.updateOffenseClassification({
        id: this.selectedInstId,
        data,
        ocId: this.ocId
      });
    } catch (err) {
      console.error('UPDATE FAILED', err);
    }
  }

  $onCreate() {
    const $vm = this;

    this.$scope.$watch('vm.selection', (val) => this.selectItem(val));

    this.selectedInstId =
      this.activeInstId ||
      this.$location.$$search.institutionId ||
      this.institutions[0]?.id ||
      null;

    this.listModel = {
      chapter: {
        type: 'Chapter',
        idBy: 'id',
        listIdBy: 'chapterId',
        nameBy: 'label',
        queryBy: ['label'],
        canAdd: ['institutionmanager:UpdateOffenseClassification'],
        add: async function (data) {
          let chapter: Chapter = {
            id: uuidv4(),
            label: data.name,
            sections: [],
            number: $vm.chapters.length + 1
          };

          $vm.chapters.splice(0, 0, chapter);

          await $vm.updateOC();

          return chapter;
        },
        canEdit: ['institutionmanager:UpdateOffenseClassification'],
        edit: async ({ name, data }) => {
          let chapter = $vm.chapters.find((chapter) => chapter.id == data.id);

          if (!chapter) {
            return;
          }

          chapter.label = name;

          await $vm.updateOC();

          return chapter;
        },
        canRemove: ['institutionmanager:UpdateOffenseClassification'],
        remove: async (chapterId) => {
          let i = $vm.chapters.findIndex((chapter) => chapter.id == chapterId);

          if (i == -1) {
            return;
          }

          $vm.chapters.splice(i, 1);

          await $vm.updateOC();
        }
      },
      section: {
        type: 'Section',
        idBy: 'id',
        listIdBy: 'sectionId',
        nameBy: 'label',
        queryBy: ['label'],
        parentModel: 'chapter',
        canAdd: ['institutionmanager:UpdateOffenseClassification'],
        add: async function (data, parent) {
          let section: Section = {
            id: uuidv4(),
            chapterId: parent.id,
            label: data.name,
            offenseClassifications: [],
            number: parent.sections ? parent.sections?.length + 1 : 1
          };

          $vm.chapters
            .find((chapter) => parent.id == chapter.id)
            .sections.splice(0, 0, section);

          $vm.sections.splice(0, 0, section);

          await $vm.updateOC();

          return section;
        },
        canEdit: ['institutionmanager:UpdateOffenseClassification'],
        edit: async ({ name, data }, parent) => {
          let section = $vm.chapters
            .find((chapter) => parent.id == chapter.id)
            .sections.find((section) => section.id == data.id);

          if (!section) {
            return;
          }

          section.label = name;

          await $vm.updateOC();

          return section;
        },
        canRemove: ['institutionmanager:UpdateOffenseClassification'],
        remove: async (sectionId) => {
          let section = $vm.sections.find((section) => section.id == sectionId);
          let parent = $vm.chapters.find(
            (chapter) => chapter.id == section.chapterId
          );
          let i = parent.sections.findIndex(
            (section) => section.id == sectionId
          );

          if (i == -1) {
            return;
          }

          parent.sections.splice(i, 1);
          $vm.sections.splice(
            $vm.sections.findIndex((item) => item.id == sectionId),
            1
          );

          await $vm.updateOC();
        }
      }
    };

    this.tableProps = [
      {
        label: 'Article Number',
        value: 'articleNumber'
      },
      {
        label: 'Label',
        value: 'label'
      },
      {
        label: 'Description',
        value: 'description'
      },
      {
        label: 'Chapter',
        value: 'chapter.label'
      },
      {
        label: 'Section',
        value: 'section.label'
      }
    ];

    this.tableActions = [];

    this.initListView();
  }

  async initListView() {
    const $vm = this;

    this.loading = true;

    this.chapters = [];
    this.sections = [];
    this.classifications = [];

    if (this.selectedInstId) {
      this.$location.search('institutionId', this.selectedInstId);

      // let { data } = await this.$api.IM.listOffenseClassifications({
      //   instId: this.selectedInstId
      // });

      let data = await this.listInstitutionOffenseClassifications(
        this.selectedInstId
      );

      // if institution doesn't have an offense classification yet. auto create one
      if (!data[0]?.classificationData) {
        let createOCRes = await this.$api.IM.createOffenseClassification(
          {
            instId: this.selectedInstId
          },
          {
            classificationData: basicOffenseClassification
          }
        );
        if (createOCRes.status !== 204 && createOCRes.status !== 200) {
          this.notify.display(createOCRes, 'error');
          return;
        }
        data = [
          {
            id: createOCRes?.data?.id,
            classificationData: basicOffenseClassification
          }
        ];
      }

      this.chapters = this.utils.clone(data[0].classificationData);
      this.ocId = data[0].id;

      this.chapters.forEach((chapter) => {
        chapter.id = uuidv4();

        chapter.sections.forEach((section) => {
          section.id = uuidv4();
          section.chapterId = chapter.id;

          this.sections.push(section);

          section.offenseClassifications.forEach((oc) => {
            this.classifications.push({
              ...oc,
              section,
              chapter
            });
          });
        });
      });
    } else {
      this.$location.search('institutionId', null);
    }

    this.listItems = {
      section: this.sections,
      chapter: this.chapters
    };

    this.setTableItems();

    // Pause for effect...
    await this.utils.wait(500);

    this.loading = false;

    this.$scope.$apply();
  }

  selectItem() {
    if (!this.selection) {
      this.breadCrumbs = '';
      this.setTableItems();

      return;
    }

    this.breadCrumbs = this.selection.name;

    let item = this.selection;
    while (item.parent) {
      item = item.parent;
      this.breadCrumbs = `${item.name} > ${this.breadCrumbs}`;
    }

    this.setTableItems();
  }

  setTableItems() {
    if (!this.selection) {
      this.tableItems = [];

      return;
    }

    const { model, data } = this.selection;

    // Does the selection have any data? If not, it might be new.
    if (!data?.id) {
      this.tableItems = [];
      return;
    }

    this.tableItems = this.classifications.filter(
      (item) => item[model.$name].id == data.id
    );
  }

  async editClassifications() {
    console.log(this.sections);
    console.log(this.selection);
    let section = this.sections.find(({ id }) => this.selection.data.id == id);
    let chapter = this.chapters.find(({ id }) => section.chapterId == id);

    let classifications = await this.$modals.edit.offenseClassification(
      this.selectedInstId,
      this.chapters,
      section,
      this.ocId
    );

    if (!classifications) {
      return;
    }

    section.offenseClassifications = classifications;

    let vals = (classifications || []).map((item) => ({
      ...item,
      section,
      chapter
    }));

    this.classifications = this.classifications
      .filter((item) => item.section.id != section.id)
      .concat(vals);

    this.setTableItems();
  }

  async loadInstitutions() {
    if (this.institutions?.items?.length) return;

    this.loadingInstitutions = true;

    try {
      await this.listInstitutions();
    } catch (err) {
      this.notify.display(err, 'error');
    } finally {
      this.loadingInstitutions = false;
    }
  }
}

export default DashboardOffenseClassificationsView;

// data = [
//   {
//     number: 1,
//     label: 'Introductory Provisions',
//     sections: [
//       {
//         number: '1',
//         label: 'GENERAL PROVISIONS',
//         offenseClassifications: [
//           {
//             articleNumber: '1.1',
//             label: 'Short Title',
//             description: 'description of classification'
//           },
//           {
//             articleNumber: '1.1',
//             label: 'Objective of Code',
//             description: 'description of classification'
//           },
//           {
//             articleNumber: '1.1',
//             label: 'Effect of Code',
//             description: 'description of classification'
//           }
//         ]
//       },
//       {
//         number: '2',
//         label: 'BURDEN OF PROOF',
//         offenseClassifications: [
//           {
//             articleNumber: '1.2',
//             label: 'Territorial Jurisdiction',
//             description: 'description of classification'
//           },
//           {
//             articleNumber: '1.2',
//             label: 'Construction of Code',
//             description: 'description of classification'
//           },
//           {
//             articleNumber: '1.2',
//             label: 'Computation of Age',
//             description: 'description of classification'
//           }
//         ]
//       },
//       {
//         number: '3',
//         label: 'MULTIPLE PROSECUTIONS',
//         offenseClassifications: [
//           {
//             articleNumber: '1.3',
//             label: 'Definition',
//             description: 'description of classification'
//           },
//           {
//             articleNumber: '1.3',
//             label: 'Preemption',
//             description: 'description of classification'
//           }
//         ]
//       }
//     ]
//   },
//   {
//     number: 2,
//     label: 'General Principles Of Criminal Responsibility',
//     sections: [
//       {
//         number: '2.1',
//         label: 'CULPABILITY GENERALLY',
//         offenseClassifications: [
//           {
//             articleNumber: '1.1.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       },
//       {
//         number: '2.2',
//         label: 'RIMINAL RESPONSIBILITY FOR CONDUCT OF ANOTHER',
//         offenseClassifications: [
//           {
//             articleNumber: '1.2.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       },
//       {
//         number: '2.3',
//         label: 'GENERAL DEFENSES TO CRIMINAL RESPONSIBILITY',
//         offenseClassifications: [
//           {
//             articleNumber: '1.2.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       },
//       {
//         number: '2.4',
//         label: 'JUSTIFICATION EXCLUDING CRIMINAL RESPONSIBILITY',
//         offenseClassifications: [
//           {
//             articleNumber: '1.2.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       }
//     ]
//   },
//   {
//     number: 3,
//     label: 'Punishments',
//     sections: [
//       {
//         number: '3.1',
//         label: 'PUNISHMENTS',
//         offenseClassifications: [
//           {
//             articleNumber: '1.1.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       }
//     ]
//   },
//   {
//     number: 4,
//     label: 'Inchoate Offenses',
//     sections: [
//       {
//         number: '4.1',
//         label: 'PREPARATORY OFFENSES',
//         offenseClassifications: [
//           {
//             articleNumber: '1.1.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       },
//       {
//         number: '4.2',
//         label:
//           'CRIMINAL INSTRUMENTS, INTERCEPTION OF WIRE OR ORAL COMMUNICATION',
//         offenseClassifications: [
//           {
//             articleNumber: '1.2.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       }
//     ]
//   },
//   {
//     number: 5,
//     label: 'Offenses Against The Person',
//     sections: [
//       {
//         number: '5.1',
//         label: 'CRIMINAL HOMICIDE',
//         offenseClassifications: [
//           {
//             articleNumber: '1.1.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       },
//       {
//         number: '5.2',
//         label: 'KIDDNAPPING, UNLAWFUL RESTRAINT, AND SMUGGLING OF PERSONS',
//         offenseClassifications: [
//           {
//             articleNumber: '1.2.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       },
//       {
//         number: '5.3',
//         label: 'SEXUAL OFFENSES',
//         offenseClassifications: [
//           {
//             articleNumber: '1.2.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       },
//       {
//         number: '5.4',
//         label: 'ASSAULTIVE OFFENSES',
//         offenseClassifications: [
//           {
//             articleNumber: '1.2.1',
//             label: 'title of classification',
//             description: 'description of classification'
//           }
//         ]
//       }
//     ]
//   }
// ];

const basicOffenseClassification = [
  {
    label: 'Chapter 1',
    number: 1,
    sections: [
      {
        label: 'Section 1',
        number: '1',
        offenseClassifications: [
          {
            label: 'Short Title',
            description: 'description of classification',
            articleNumber: '1.1'
          }
        ]
      }
    ]
  }
];
