'use strict';
const angular = require('angular');

export default angular
  .module('app.evaluation-child-tools', [])
  .directive('evaluationChildTools', function () {
    return {
      template: require('./evaluation-child-tools.html'),
      restrict: 'EA',
      link: function (scope, element, attrs) {
        scope.toggleSourceOfInformation = function (evl, toolAdd, source) {
          if (!evl.toolSourcesOfInformation) {
            evl.toolSourcesOfInformation = {};
            evl.toolSourcesOfInformation[toolAdd] = [source];
            return;
          }
          let tsi = evl.toolSourcesOfInformation;
          if (!tsi[toolAdd]) {
            // tool address doesn't exist for toolSourcesOfInformation
            tsi[toolAdd] = [source];
          } else if (tsi[toolAdd].indexOf(source) > -1) {
            // toggling source off
            tsi[toolAdd].splice(tsi[toolAdd].indexOf(source), 1);
            if (source === 'other') {
              evl.toolSourcesOfInformation[toolAdd + 'custom'] = null;
            }
          } else {
            // toggling source on
            tsi[toolAdd].push(source);
          }
        };
      }
    };
  }).name;
