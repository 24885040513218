import angular from 'angular';

/**
 * ...
 */
export type IPaginatorFactory = PaginatorFactory;

declare module 'angular' {
  namespace gears {
    type IPaginatorFactory = PaginatorFactory;
  }
}

/**
 * ...
 */
export class PaginatorFactory {
  private _items: unknown[] = [];
  private _pages: unknown[] = [];
  private _currentPage = 1;
  private _itemsPerPage = 25;
  private _pageCount = 1;
  private _pageItems: unknown[] = [];

  /**
   * ...
   */
  get pages() {
    return this._pages;
  }

  /**
   * ...
   */
  get currentPage() {
    return this._currentPage;
  }

  // set currentPage() {
  //   currentPage = Math.min(1, Math.max())
  // }

  /**
   * ...
   */
  get pageItems() {
    return this._pageItems;
  }

  /**
   * ...
   */
  get pageCount() {
    return this._pageCount;
  }

  /**
   * ...
   */
  get itemsPerPage() {
    return this._itemsPerPage;
  }

  /**
   * ...
   */
  get itemCount() {
    return this._items.length || 0;
  }

  set itemsPerPage(val) {
    if (!Number(val) && val > 0) {
      throw new Error('');
    }

    this._itemsPerPage = val;
  }

  /**
   * ...
   */
  set(array: unknown[]) {
    this._items = array;
    this._pageCount = Math.ceil(this._items.length);

    this.toPage(1);
  }

  /**
   * ...
   */
  createPages() {
    this._pages = [];
    this._currentPage = 1;

    // let pos = 0;
    //
    // while (pos <= paginator.itemCount) {
    //   pages.push(items.slice(pos, (pos += itemsPerPage)));
    // }
  }

  /**
   * ...
   *
   * @param pageNum ...
   */
  toPage(pageNum: number) {
    let i = (pageNum - 1) * this._itemsPerPage;
    this._pageItems = this._items.slice(i, i + this._itemsPerPage);
  }

  /**
   * ...
   */
  previousPage() {
    this.toPage(this._currentPage - 1);
  }

  /**
   * ...
   */
  nextPage() {
    this.toPage(this._currentPage + 1);
  }
}

export default angular
  .module('paginator', [])
  .service('paginator', PaginatorFactory).name;
