import angular from 'angular';

import { storeInstance } from './instance';
import { Store } from './store';
import { StoreOptions } from './types';

declare module 'angular' {
  namespace store {
    type IStoreProvider = $StoreProvider;
  }
}

/**
 * ...
 */
export type StoreConfig =
  | StoreOptions<unknown>
  | ((...args: unknown[]) => StoreOptions<unknown>);

export class $StoreProvider {
  static $inject: string[] = [];

  private options: StoreConfig | null = null;

  $get = (
    $rootScope: angular.IRootScopeService,
    $injector: angular.auto.IInjectorService
  ) => {
    'ngInject';

    function prepareConfig(config: StoreConfig = {}) {
      const mod =
        typeof config !== 'function'
          ? config
          : Array.isArray(config.$actions)
          ? $injector.instantiate<StoreOptions<unknown>>(config)
          : $injector.invoke<StoreOptions<unknown>>(config);

      mod.mutations =
        typeof mod.mutations === 'function'
          ? $injector.instantiate(mod.mutations)
          : mod.mutations;

      mod.actions =
        typeof mod.actions === 'function'
          ? $injector.instantiate(mod.actions)
          : mod.actions;

      if (mod.modules) {
        const modules = {};

        for (let key in mod.modules) {
          modules[key] = prepareConfig(mod.modules[key]);
        }

        mod.modules = modules;
      }

      return mod;
    }

    const store = new Store($rootScope, prepareConfig(this.options ?? {}));

    storeInstance.set(store);

    Object.getPrototypeOf($rootScope).$store = store;

    return store;
  };

  /**
   * ...
   *
   * @param options ...
   */
  store(options: StoreConfig) {
    this.options = options || {};
  }
}
