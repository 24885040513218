import { pascalCase } from 'change-case';

export interface AppViewComponent {
  template: string | null;
  controller: any | null;
}

export const views: Record<string, AppViewComponent> = {};

const requireView = require.context(
  './',
  true,
  /^(?!.*index\.(js|tsx?)$).*[a-zA-Z]\w+\.(html|js|tsx?|scss)$/
);

for (const fileName of requireView.keys()) {
  const viewName = pascalCase(
    (fileName.match(/\/([\w-]+?)\.(?:html|view\.(js|tsx?)|scss)$/, '') || [])[1]
  );

  views[viewName] = views[viewName] || { controller: null, template: null };
  const viewComponent = views[viewName];

  if (/\.html$/.test(fileName)) {
    const viewTemplate = requireView(fileName);
    viewComponent.template = requireView(fileName);
  } else if (/\.view\.(js|tsx?)$/.test(fileName)) {
    const viewController = requireView(fileName);
    viewComponent.controller = viewController.default || viewController;
  } else if (/\.scss$/.test(fileName)) {
    requireView(fileName);
  }
}
