import legacyRoutes from './route-map-legacy';

const gearsManager = {
  base: '/gears-manager',
  methods: {
    get: {
      getManagedOffenseClassification:
        '/managed-offense-classifications/:offenseClassificationId',
      getManagedPolicy: '/policies/:policyId',
      getUser: '/users/:userId',
      getInstitutionPolicy: '/institution-policies/:policyId',
      getClient: '/clients/:clientId',
      listAggregateReportRequests: '/aggreport-requests',
      listLogRequests: '/log-requests',
      listClientTransferRequests: '/client-transfer-requests',
      listClients: '/clients',
      listEvaluationRequests: '/evaluations-requests',
      listEvaluations: '/evaluations',
      listEvaluationStats: '/evaluation-stats',
      listInstitutionTools: '/institutions/:instId/tools',
      listInstitutionZones: '/institutions/:instId/zones',
      listInstitutionRegions: '/institutions/:instId/regions',
      listInstitutionSubgroups: '/institutions/:instId/subgroups',
      listInstitutionPolicies: '/:institutionId/policies',
      listAllInstitutionPolicies: '/institution-policies',
      listInstitutions: '/institutions',
      listInvites: '/invites',
      listManagedOffenseClassifications: '/managed-offense-classifications',
      listManagedPolicies: '/policies',
      listRegions: '/institutions/:instId/regions',
      listSubGroupClients: '/institutions/:instId/subgroups/:sbGrpId/clients',
      listSubGroups: '/institutions/:instId/subgroups',
      listUsers: '/users',
      listZones: '/institutions/:instId/zones',
      listAllServices: '/services',
      downloadAggregateReportCSV: '/aggreport-requests/:aggReportId/download',
      downloadLogRequest: '/log-requests/:activityReportId/download'
    },
    post: {
      createAggregateReportRequest: '/aggreport-requests',
      createLogRequest: '/log-requests',
      calculateEvaluationStats: '/evaluation-stats',
      createClient: '/clients',
      createClientContact: '/clients/:clntId/contacts',
      createInstitution: '/institutions',
      createManagedOffenseClassification: '/managed-offense-classifications',
      createManagedPolicy: '/policies',
      createUser: '/users',
      inviteUser: '/invites',
      updateManagedOffenseClassification:
        '/managed-offense-classifications/:offenseClassificationId',
      updateManagedPolicy: '/policies/:policyId',
      updateUser: '/users/:userId',
      updateUserCap: '/institutions/:instId/update-user-cap',
      addInstitutionTools: '/institutions/:institutionId/add-tools',
      updateInstitution: '/institutions/:institutionId',
      updateInvite: '/invites/:inviteId',
      updateUser2FA: '/users/:userId/2fa',
      assignPolicyToUser: '/assign-policy',
      removeInstitutionTools: '/institutions/:institutionId/remove-tools'
    },
    del: {
      deleteInstitution: '/institutions/:instId',
      deleteInvite: '/invites/:inviteId',
      deleteEvaluation: '/evaluations/:evalId',
      deleteClientTransferRequest: '/client-transfer-requests/:requestId',
      deleteEvaluationRequest: '/evaluations-requests/:requestId',
      removeUserFromInstitution: '/institutions/:institutionId/users/remove'
    }
  }
};

const institutionManager = {
  base: '/institution-manager/:instId',
  methods: {
    get: {
      getInstitution: '',
      getOffenseClassification:
        '/offense-classifications/:offenseClassificationId',
      getClient: '/clients/:clientId',
      listAggregateReportRequests: '/aggreport-requests',
      listLogRequests: '/log-requests',
      listClients: '/clients',
      listClientEvaluations: '/clients/:clientId/evaluations',
      listEvaluationRequests: '/evaluation-requests',
      listEvaluations: '/evaluations',
      listIncomingClientRequests: '/incoming-client-requests',
      listInstitutionTools: '/tools',
      listInstitutionUsers: '/users',
      listInvites: '/invites',
      listManagedPolicies: '/managed-policies',
      listOffenseClassifications: '/offense-classifications',
      listOutgoingClientRequests: '/outgoing-client-requests',
      listPolicies: '/policies',
      listRegions: '/regions',
      listSubGroupClients: '/subgroups/:sbGrpId/clients',
      listSubGroups: '/subgroups',
      listZones: '/zones',
      listServices: '/services',
      listImportClientRequests: '/import-clients',
      getInstitutionUser: '/users/:userId',
      getImportClientRequest: '/import-clients/:importClientRequestId',
      downloadAggregateReportCSV: '/aggreport-requests/:aggReportId/download',
      downloadLogRequest: '/log-requests/:activityReportId/download',
      listImportEvaluationRequests: '/import-evaluations',
      getUser: '/users/:userId',
      listAggUserReportRequests: '/agguser-requests',
      downloadAggregateUserReport: '/agguser-requests/:aggReportId/download'
    },
    post: {
      listSubGroupClientsV2: '/subgroup-clients',
      listSubGroupClientsEvaluations: '/subgroup-clients-evaluations',
      createAggregateReportRequest: '/aggreport-requests',
      createLogRequest: '/log-requests',
      createClientTransferRequest: '/client-transfer-requests',
      addUser: '/users/add',
      assignPolicyToUser: '/policy/assign-policy',
      createClient: '/clients',
      updateClient: '/clients/:clntId',
      createClientContact: '/clients/:clntId/contacts',
      updateClientContact: '/clients/:clntId/contacts/:contactId',
      createEvaluation: '/clients/:clientId/evaluations',
      createOffenseClassification: '/offense-classifications',
      createPolicy: '/policies',
      importClients: '/import-clients',
      inviteUser: '/invites',
      resendInvite: '/invites/:inviteId/resend',
      updateInstitution: '/:institutionId',
      updateOffenseClassification:
        '/offense-classifications/:offenseClassificationId',
      updatePolicy: '/policies/:institutionPolicyId',
      addClientToSubGroup: '/subgroups/add-client',
      createRegion: '/regions',
      createSubGroup: '/subgroups',
      createZone: '/zones',
      updateRegion: '/regions/:regionId',
      updateSubGroup: '/subgroups/:subGroupId',
      updateZone: '/zones/:zoneId',
      acceptClientTransferRequest: '/client-transfer-requests/accept',
      createExpungeClientRequest: '/expunge-requests',
      importEvaluations: '/import-evaluations',
      createAggUserReportRequest: '/agguser-requests'
    },
    del: {
      deleteClient: '/clients/:clntId',
      removePolicyFromUser: '/policy/remove-policy',
      removeUser: '/users/remove',
      removeClientFromSubGroup: '/subgroups/remove-client',
      deleteInvite: '/invites/:inviteId',
      deleteEvaluation: '/clients/:clntId/evaluations/:evalId',
      deleteEvaluationRequest: '/evaluation-requests/:requestId',
      deleteOffenseClassification:
        '/offense-classifications/:offenseClassificationId',
      deleteClientTransferRequest: '/client-transfer-requests/:requestId',
      declineClientTransferRequest: '/client-transfer-requests/decline',
      deleteZone: '/zones/:zoneId',
      deleteRegion: '/regions/:regionId',
      deleteSubGroup: '/subgroups/:subGroupId'
    }
  }
};

const clientManager = {
  base: '/client-manager/:instId/subgroups/:sbGrpId/clients/:clntId',
  methods: {
    get: {
      getClientCasePlan: '/case-plans/:casePlanId',
      getClientEvaluation: '/evaluations/:evalId',
      getClientMedia: '/media/:mediaId',
      getEvaluationMedia: '/evaluations/:evalId/media/:mediaId',
      getOffenderHistory: '/history/:historyId',
      getReportDocument: '/evaluations/:evalId/reports/:reportId/doc',
      getReportPdf: '/evaluations/:evalId/reports/:reportId/pdf',
      listClientCasePlans: '/case-plans',
      listClientContacts: '/contacts',
      listClientEvaluations: '/evaluations',
      listClientEvaluators: '/evaluators',
      listClientMedia: '/media',
      listClientReports: '/reports',
      listEvaluationReports: '/evaluations/:evalId/reports',
      listOffenderHistory: '/history',
      listSubGroupClients: '/:instId/subgroups/:sbGrpId/clients'
    },
    post: {
      createClientCasePlan: '/case-plans',
      createClientEvaluation: '/evaluations',
      createEvaluationReport: '/evaluations/:evalId/reports',
      createOffenderHistory: '/history',
      emailReport: '/evaluations/:evalId/reports/:reportId/email',
      revertClientEvaluation: '/evaluations/:evalId/revert',
      saveClientEvaluation: '/evaluations/:evalId',
      submitClientEvaluation: '/evaluations/:evalId/submit',
      updateClientCasePlan: '/case-plans/:casePlanId',
      updateClientCasePlanStatus: '/case-plans/:casePlanId/status',
      updateOffenderHistory: '/history/:historyId',
      uploadClientMedia: '/media',
      uploadEvaluationMedia: '/evaluations/:evalId/media',
      updateClient: '/',
      updateClientContact: '/contacts/:contactId'
    },
    del: {
      deleteClientEvaluation: '/evaluations/:evalId',
      deleteClientMedia: '/media/:mediaId',
      deleteEvaluationMedia: '/evaluations/:evalId/media/:mediaId',
      deleteOffenderHistory: '/history/:historyId'
    }
  }
};

const icpt = {
  base: '/icpt/:instId',
  methods: {
    get: {
      getCasePlanTemplateCommit:
        '/case-plan-templates/:tmpltId/commits/:commitId',
      listCasePlanTemplateCommits: '/case-plan-templates/:tmpltId/commits',
      listCasePlanTemplates: '/case-plan-templates'
    },
    post: {
      commitCasePlanTemplate: '/case-plan-templates/:tmpltId/commit',
      createCasePlanTemplate: '/case-plan-templates',
      setCasePlanTemplateCommitStatus:
        '/case-plan-template/:tmpltId/commits/:commitId/status',
      tagCasePlanTemplateCommit:
        '/case-plan-template/:tmpltId/commits/:commitId/tag'
    }
  }
};

const mcpt = {
  base: '/mcpt',
  methods: {
    get: {
      listManagedCasePlanTemplates: '/managed-case-plan-templates',
      listCasePlanTemplateCommits:
        '/managed-case-plan-templates/:templateId/commits',
      getCasePlanTemplateCommit:
        '/managed-case-plan-templates/:templateId/commits/:commitId'
    },
    post: {
      createCasePlanTemplate: '/managed-case-plan-templates',
      commitManagedCasePlanTemplate:
        '/managed-case-plan-templates/:templateId/commit',
      tagCasePlanTemplateCommit:
        '/managed-case-plan-template/:templateId/commits/:commitId/tag',
      setCasePlanTemplateCommitStatus:
        '/managed-case-plan-template/:templateId/commits/:commitId/status'
    }
  }
};

const toolCreator = {
  base: '/tool-creator/tools',
  methods: {
    get: {
      listAllTools: '/',
      getCodingForm: '/:toolId/coding-forms/:codingFormId',
      getDescriptionPdf: '/:toolId/description-pdfs/:descriptionPdfId',
      getTool: '/:toolId',
      getToolCommit: '/:toolId/commits/:commitId',
      getToolPdf: '/:toolId/tool-pdfs/:toolPdfId',
      getToolReportTemplate: '/:toolId/report-templates/:reportTemplateId',
      getToolReportTemplateCommit:
        '/:toolId/report-templates/:reportTemplateId/commits/:commitId?templateDataOnly',
      getToolReportTemplateMedia:
        '/:toolId/report-templates/:reportTemplateId/media/:mediaId',
      listLiveTools: '/live',
      listManagedCasePlanTemplates: '/:toolId/managed-case-plan-templates',
      listInstitutionCasePlanTemplates:
        '/:toolId/institution-case-plan-templates',
      listToolCodingForms: '/:toolId/coding-forms',
      listToolCommits: '/:toolId/commits',
      listToolPdfs: '/:toolId/tool-pdfs',
      listDescriptionPdfs: '/:toolId/description-pdfs',
      listToolReportTemplates: '/:toolId/report-templates',
      listToolReportTemplateCommits:
        '/:toolId/report-templates/:reportTemplateId/commits',
      listToolReportTemplateMedia:
        '/:toolId/report-templates/:reportTemplateId/media'
    },
    post: {
      addChild: '/:toolId/add-child',
      addCodingForm: '/:toolId/commits/:commitId/coding-forms',
      addDescriptionPdf: '/:toolId/commits/:commitId/description-pdfs',
      addToolPdf: '/:toolId/commits/:commitId/tool-pdfs',
      changePrice: '/:toolId/price',
      commitTool: '/:toolId/commit',
      commitToolReportTemplate:
        '/:toolId/report-templates/:reportTemplateId/commit',
      createCodingForm: '/:toolId/coding-forms',
      createDescriptionPdf: '/:toolId/description-pdf',
      createTool: '/',
      createToolPdf: '/:toolId/tool-pdfs',
      createToolReportTemplate: '/:toolId/report-templates',
      removeChild: '/:toolId/remove-child',
      setLiveCodingForm: '/:toolId/commits/:commitId/set-live-coding-form',
      setLiveDescriptionPdf:
        '/:toolId/commits/:commitId/set-live-description-pdf',
      setLiveToolPdf: '/:toolId/commits/:commitId/set-live-tool-pdf',
      setToolCommitStatus: '/:toolId/commits/:commitId/status',
      tagToolCommit: '/:toolId/commits/:commitId/tag',
      uploadToolReportTemplateMedia:
        '/:toolId/report-templates/:reportTemplateId/media'
    },
    del: {
      deleteTool: '/:toolId'
    }
  },
  user: {
    base: '/users',
    methods: {
      get: {
        getMe: '/me',
        getGdprRequest: '/gdpr-requests/:gdprRequestId',
        getGdprRequests: '/gdpr-requests',
        getEvaluationRequests: '/evaluation-requests',
        listReminders: '/reminders',
        logout: '/logout'
      },
      post: {
        completeReminder: '/reminders/:reminderId/complete',
        createReminder: '/reminders',
        createUser: '/',
        dismissReminder: '/reminders/:reminderId/dismiss',
        updateMe: '/me',
        updateReminder: '/reminders/:reminderId',
        updateReminderAlerts: '/reminders/:reminderId/alerts',
        updateReminderDueDate: '/reminders/:reminderId/due-date',
        resetPassword: '/reset-password',
        gdprRequest: '/gdpr-requests',
        expungeRequest: '/expunge-request',
        toggleEmailReminder: '/reminders/:reminderId/toggle-email',
        twofa: '/2fa'
        // snoozeReminder: '/reminders/:reminderId/snooze'
      },
      put: {
        changePassword: '/password'
      },
      del: {
        deleteMe: '/me',
        deleteReminder: '/reminders/:reminderId'
      }
    }
  }
};

const user = {
  base: '/users',
  methods: {
    get: {
      getMe: '/me',
      getGdprRequest: '/gdpr-requests/:gdprRequestId',
      getGdprRequests: '/gdpr-requests',
      getEvaluationRequests: '/evaluation-requests',
      listReminders: '/reminders',
      logout: '/logout'
    },
    post: {
      completeReminder: '/reminders/:reminderId/complete',
      createReminder: '/reminders',
      createUser: '/',
      dismissReminder: '/reminders/:reminderId/dismiss',
      updateMe: '/me',
      updateReminder: '/reminders/:reminderId',
      updateReminderAlerts: '/reminders/:reminderId/alerts',
      updateReminderDueDate: '/reminders/:reminderId/due-date',
      resetPassword: '/reset-password',
      gdprRequest: '/gdpr-requests',
      expungeRequest: '/expunge-request',
      toggleEmailReminder: '/reminders/:reminderId/toggle-email',
      twofa: '/2fa'
      // snoozeReminder: '/reminders/:reminderId/snooze'
    },
    put: {
      changePassword: '/password'
    },
    del: {
      deleteMe: '/me',
      deleteReminder: '/reminders/:reminderId'
    }
  }
};

const base = {
  base: '/',
  methods: {
    post: {
      passwordReset: 'password-resets'
    }
  }
};

export default {
  gearsManager,
  institutionManager,
  clientManager,
  icpt,
  mcpt,
  toolCreator,
  user,
  base,
  ...legacyRoutes
};
