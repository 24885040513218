import { gears } from 'angular';
import { filter, find } from 'lodash';
import { ActionTree, MutationTree, GetterTree } from 'angular-store';

import { Evaluation } from '@interfaces/evaluation';
import { EvaluationRequest } from '@interfaces/evaluation-request';
import { Institution } from '@interfaces/institution';
import { InstitutionTransfer } from '@interfaces/institution-transfer';
import { Policy } from '@interfaces/policy';
import { RootState } from '@store/state';

export interface MeState {
  id: number | false;
  fName: string;
  lName: string;
  dob: string;
  email: string;
  phoneCell: string;
  phoneWork: string;
  address1: string;
  address2: string | null;
  address3: string | null;
  city: string;
  stateProvince: string;
  postalCode: string;
  country: string;
  loggedIn: boolean;
  active: boolean;
  policies: Policy[];
  // Relational
  evaluationRequests: EvaluationRequest[];
  institutionTransfers: InstitutionTransfer[];
  // Session
  loading: boolean;
  institution: Institution | null;
}

/** ... */
export type SetMeMutationOptions = Partial<MeState>;
/** ... */
export type SetEvaluationRequestsMutationOptions = unknown;
/** ... */
export type AddEvaluationRequestsMutationOptions = unknown;
/** ... */
export type UpdateEvaluationRequestMutationOptions =
  | Evaluation
  | EvaluationRequest;
/** ... */
export type RemoveEvaluationRequestsMutationOptions = EvaluationRequest[];
/** ... */
export type AddInstitutionTransfersMutationOptions = unknown;
/** ... */
export type RemoveInstitutionTransfersMutationOptions = unknown;
/** ... */
export type SetUserPropertiesMutationOptions = Partial<MeState>;

export class Me implements MeState {
  // Core Props
  id: number | false = false;
  fName: string = '';
  lName: string = '';
  dob: string = '';
  email: string = '';
  phoneCell: string = '';
  phoneWork: string = '';
  address1: string = '';
  address2: string | null = null;
  address3: string | null = null;
  city: string = '';
  stateProvince: string = '';
  postalCode: string = '';
  country: string = '';
  loggedIn: boolean = false;
  active: boolean = true;
  policies: Policy[] = [];
  // Relational
  evaluationRequests: EvaluationRequest[] = [];
  institutionTransfers: InstitutionTransfer[] = [];
  // Session
  loading: boolean = false;
  institution: Institution | null = null;
}

function MeStore($api2: gears.IAPI2Service) {
  'ngInject';

  const state = new Me();

  const getters: GetterTree<MeState, RootState> = {
    fullName: (state: MeState) => `${state.fName} ${state.lName}`,
    isAdmin: (state: MeState) => !state.institution
  };

  const actions: ActionTree<MeState, RootState> = {
    async get({ state, commit }) {
      commit('SET_PROPS', { loading: true });

      // Get user data
      const data = await $api2.user.getMe();

      commit('SET', data);
      commit('SET_PROPS', { loading: false });

      return state;
    },
    async getMyEvaluationRequests({ state, commit }) {
      let data = await $api2.user.getEvaluationRequests();

      commit('SET_EVALUATION_REQUESTS', data);

      return state.evaluationRequests;
    }
  };

  const mutations: MutationTree<MeState> = {
    SET(state, data: SetMeMutationOptions) {
      data = data || new Me();

      for (let prop in data) {
        state[prop] = data[prop];
      }
    },
    SET_EVALUATION_REQUESTS(state, data: SetEvaluationRequestsMutationOptions) {
      state.evaluationRequests = filter(
        data,
        (req) => req.evaluation?.status !== 'COMPLETED'
      );
    },
    ADD_EVL_REQS(
      { evaluationRequests: items },
      payload: AddEvaluationRequestsMutationOptions
    ) {
      payload = Array.isArray(payload) ? payload : [payload];
      items.push(...payload);
    },
    UPDATE_EVL_REQ({ evaluationRequests: items }, payload: EvaluationRequest) {
      let item = find(items, { id: payload.id });

      // NOTE: most likley non-essential. Remove if deemed to be so.
      if (!item) item = find(items, { evaluationId: payload.evaluationId });

      if (!item) {
        throw new Error('Evaluation request not found.');
      }

      for (let [key, val] of Object.entries(payload)) {
        if (key === 'id') return;

        item[key] = val;
      }
    },
    REMOVE_EVL_REQS(
      { evaluationRequests: items },
      payload: RemoveEvaluationRequestsMutationOptions
    ) {
      payload = Array.isArray(payload) ? payload : [payload];

      payload.forEach((item) =>
        items.splice(
          payload.findIndex(
            (o) => o.id === item.id || o.evaluationId === item.evaluationId
          ),
          1
        )
      );
    },
    ADD_INST_TRANS(
      { institutionTransfers: items },
      payload: AddInstitutionTransfersMutationOptions
    ) {
      payload = Array.isArray(payload) ? payload : [payload];
      items.push(...payload);
    },
    REMOVE_INST_TRANS(
      { institutionTransfers: items },
      payload: RemoveInstitutionTransfersMutationOptions
    ) {
      payload = Array.isArray(payload) ? payload : [payload];
      payload.forEach((item) =>
        items.splice(
          payload.findIndex((o) => o.id === item.id),
          1
        )
      );
    },
    SET_PROPS(state, props: SetUserPropertiesMutationOptions = {}) {
      for (let i in props) {
        if (i in state) {
          state[i] = props[i];
        }
      }
    },
    CLEAR(state) {
      Object.assign(state, {
        id: false,
        fName: '',
        lName: '',
        dob: '',
        email: '',
        phoneCell: '',
        phoneWork: '',
        address1: '',
        address2: null,
        address3: null,
        city: '',
        stateProvince: '',
        postalCode: '',
        country: '',
        loggedIn: false,
        active: true,
        policies: [],
        // Relational
        evaluationRequests: [],
        institutionTransfers: [],
        // Session
        loading: false,
        institution: null
      });
    }
  };

  // TEMP: remove once all references to them have been removed.
  Object.defineProperties(mutations, {
    set: { enumerable: true, value: mutations.SET },
    setEvaluationRequests: {
      enumerable: true,
      value: mutations.SET_EVALUATION_REQUESTS
    },
    addEvlReqs: { enumerable: true, value: mutations.ADD_EVL_REQS },
    removeEvlReqs: { enumerable: true, value: mutations.REMOVE_EVL_REQS },
    updateEvlReq: { enumerable: true, value: mutations.UPDATE_EVL_REQ },
    addInstTrans: { enumerable: true, value: mutations.ADD_INST_TRANS },
    removeInstTrans: { enumerable: true, value: mutations.REMOVE_INST_TRANS },
    setProps: { enumerable: true, value: mutations.SET_PROPS }
  });

  return { state, getters, actions, mutations };
}

export default MeStore;
