import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';

@Controller
class FooterComponent {
  year: string = new Date().getFullYear();
}

export default angular.module('app.footer', []).component('footer', {
  template: require('./footer.html'),
  controller: FooterComponent,
  controllerAs: 'vm'
}).name;
