import angular, { auto, IWindowService } from 'angular';
import numberConverter from 'number-to-words';
import {
  forEach,
  indexOf,
  isFunction,
  maxBy,
  minBy,
  noop,
  find,
  uniqBy,
  remove
} from 'lodash';

import * as constants from '@constants';
import { AssessmentInstance } from '@interfaces/assessment-instance';
import { Client } from '@interfaces/client';
import { Evaluation } from '@interfaces/evaluation';
import { Tool } from '@interfaces';

class WorkingToolManager {
  readonly get = () => {
    return this.tool;
  };

  readonly set = (tool: Tool) => {
    this.tool = tool;
  };

  readonly empty = () => {
    this.tool = {};
  };

  private tool: Partial<Tool> | null = null;
}

class ParsedOffenseClassification {
  value: any = {};
  label: string;

  constructor(
    chapter: unknown,
    chapterTitle: unknown,
    section: unknown,
    sectionTitle: unknown,
    articleNumber: unknown,
    description: unknown,
    label?: string | null
  ) {
    this.value = {
      chapter,
      chapterTitle,
      section,
      sectionTitle,
      articleNumber,
      description,
      label
    };

    if (!label || !label.length) {
      label = `${chapterTitle} > ${sectionTitle} > ${articleNumber}`;
    }

    this.label = label;
  }
}

/**
 * The Util service is for thin, globally reusable, utility functions.
 */
export class UtilService {
  readonly emailPattern = constants.EMAIL_REGEX;
  readonly workingTool = new WorkingToolManager();

  /**
   * List of normative settings.
   */
  get normativeSettingsList() {
    return constants.NORMATIVE_SETTINGS_LIST;
  }

  /**
   * List of offense classifications.
   */
  get offenseClassifications() {
    return constants.OFFENSE_CLASSIFICATIONS;
  }

  /**
   * ...
   */
  get changeOptions() {
    return constants.CHANGE_OPTIONS;
  }

  /**
   * ...
   */
  get columnTypes() {
    return constants.COLUMN_TYPES;
  }

  /**
   * ...
   */
  get intensityOptions() {
    return constants.INTENSITY_OPTIONS;
  }

  /**
   * ...
   */
  get ylslsToolsSuite() {
    return [
      { id: 4, name: 'LSI-R: SV' },
      { id: 105, name: 'YLS/CMI:SRV' },
      { id: 120, name: 'YLS/CMI 2.0' }
    ];
  }

  get frontEndReportToolsList() {
    return [
      { id: 157, name: 'LSI-R' },
      {
        id: 4,
        name: 'LSI-R: SV'
      },
      {
        id: 105,
        name: 'YLS/CMI:SRV'
      },
      {
        id: 120,
        name: 'YLS/CMI 2.0'
      },
      {
        id: 124,
        name: 'LS/CMI'
      },
      {
        id: 156,
        name: 'Hare PCL-R'
      }
    ];
  }

  constructor(
    private readonly $injector: auto.IInjectorService,
    private readonly $window: IWindowService,
    private readonly $store: any
  ) {
    'ngInject';
  }

  /**
   * Return a callback or noop function.
   *
   * @param cb A 'potential' function.
   * @return ...
   */
  safeCb(cb: unknown) {
    return isFunction(cb) ? cb : noop;
  }

  /**
   * Parse a given url with the use of an anchor element.
   *
   * @param url The url to parse
   * @return The parsed url, anchor element
   */
  urlParse(url: string) {
    const a = document.createElement('a');
    a.href = url;

    // Special treatment for IE, see http://stackoverflow.com/a/13405933 for details
    if (a.host === '') {
      a.href = a.href;
    }

    return a;
  }

  /**
   * Test whether or not a given url is same origin.
   *
   * @param url Url to test.
   * @param origins Additional origins to test against.
   * @return True if url is same origin
   */
  isSameOrigin(url: string, origins: any[]) {
    const parsedUrl = this.urlParse(url);

    origins = (origins && [].concat(origins)) || [];
    origins = origins.map(this.urlParse);
    origins.push(this.$window.location);
    origins = origins.filter((o) => {
      const hostnameCheck = parsedUrl.hostname === o.hostname;
      const protocolCheck = parsedUrl.protocol === o.protocol;
      // 2nd part of the special treatment for IE fix (see above):
      // This part is when using well-known ports 80 or 443 with IE,
      // when $window.location.port==='' instead of the real port number.
      // Probably the same cause as this IE bug: https://goo.gl/J9hRta
      const portCheck =
        parsedUrl.port === o.port ||
        (o.port === '' &&
          (parsedUrl.port === '80' || parsedUrl.port === '443'));

      return hostnameCheck && protocolCheck && portCheck;
    });

    return origins.length >= 1;
  }

  /**
   * ...
   */
  numberWord(n: string, capitalize = false) {
    const value = parseInt(n);

    if (isNaN(value)) return;

    const words = numberConverter.toWords(value);

    return capitalize ? words.charAt(0).toUpperCase() + words.slice(1) : words;
  }

  /**
   * ...
   */
  validEmail(email: string) {
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  /**
   * Converts any string to camelcase.
   *
   * @param value ...
   * @return ...
   */
  camelize(value?: string) {
    if (!value) return '';

    if (!value.length) return value;

    return value.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return index == 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  /**
   * Take camelized string and format for readability.
   *
   * @param value ...
   * @return ...
   */
  decamlize(value?: string) {
    if (typeof value !== 'string' || value.includes(' ')) return value;

    return value
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.substr(1))
      .join(' ');
  }

  /**
   * Capitalize the first letter in a string.
   *
   * @param value The string to capitalize.
   * @return The string with the first letter capitalized.
   */
  capitalize(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }

  /**
   * Takes number and returns the appropriate roman numeral.
   *
   * @param num ...
   * @return ....
   */
  romanize(num: number) {
    let roman = '';

    for (const [key, value] of Object.entries(constants.ROMAN_NUMERAL_VALUES)) {
      while (num >= value) {
        roman += key;
        num -= value;
      }
    }

    return roman;
  }

  /**
   * Moves item in array.
   *
   * @param arr The array.
   * @param oldIndex The index the item to move is currently at.
   * @param newIndex The index to move the item to.
   * @return The modified array.
   */
  arrayMove(arr: unknown[], oldIndex: number, newIndex: number) {
    if (newIndex >= arr.length) {
      let k = newIndex - arr.length + 1;

      while (k--) {
        arr.push(undefined);
      }
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

    return arr;
  }

  /**
   * Check if the value exists.
   *
   * @param value The value to check.
   * @return `true` if the value does exists, otherwise `false`.
   */
  valueExists(value: unknown) {
    return value || value === false || value === 0;
  }

  getEthnicityList() {
    return this.$store.state.clients.clientConfig.ethnicityTypes;
  }

  getClientTypeList() {
    return this.$store.state.clients.clientConfig.clientTypes;
  }

  getNormativeSettingList() {
    return this.normativeSettingsList;
  }

  // getOffenseClassifications() {
  //   return this.offenseClassifications;
  // }

  /**
   * ...
   *
   * @param institutionId ...
   * @return ...
   */
  async getOffenseClassifications(institutionId: string) {
    // Get store.
    const store = this.$injector.get('$store');
    // Get offense classifications based on the institution ID.
    const ocs = await store.dispatch(
      'offenseClassifications/list',
      institutionId
    );
    //
    const classificationData =
      Array.isArray(ocs) && ocs[0]
        ? ocs[0].classificationData
        : ocs.classificationData;
    //
    const parsedOffenseClassifications: ParsedOffenseClassification[] = [];

    forEach(classificationData, (cd) => {
      forEach(cd.sections, (sec) => {
        forEach(sec.offenseClassifications, (oc) => {
          parsedOffenseClassifications.push(
            new ParsedOffenseClassification(
              cd.number,
              cd.label,
              sec.number,
              sec.label,
              oc.articleNumber,
              oc.description,
              oc.label
            )
          );
        });
      });
    });

    return parsedOffenseClassifications;
  }

  getChangeOptions() {
    return this.changeOptions;
  }

  getColumnTypes() {
    return this.columnTypes;
  }

  /**
   * Get intensity options.
   *
   * @return ...
   */
  getIntensityOptions() {
    return this.intensityOptions;
  }

  getYLSLSSuiteOfTools() {
    return this.ylslsToolsSuite;
  }

  getFrontEndReportTools() {
    return this.frontEndReportToolsList;
  }

  /**
   * Get condition types.
   *
   * @return ...
   */
  getConditionTypes() {
    //
    const riskLevelOptions = [];
    //
    const answerOptions = [];
    //
    const tool =
      this.$injector.get<angular.gears.IEvalUtilsService>('evlUtils').evaluation
        .tool;

    // parses an individual tool's riskCategories and customRiskCategories
    const parseTool = function (t, childTool) {
      let riskOptions = [];

      // parse risk categories
      forEach(t.riskCategories, (rc) => {
        riskOptions.push({
          value: rc.name,
          label: rc.name
        });
      });

      forEach(t.customRiskCategories, (rc) => {
        forEach(rc.categories, (ct) => {
          riskOptions.push({
            value: ct.name,
            label: ct.name
          });
        });
      });

      riskOptions = uniqBy(riskOptions, 'value');

      // parse answers
      forEach(t.codingFormItems, (cfi) => {
        const label = t.flyoutName
          ? `${t.flyoutName} :: ${cfi.riskFactor}`
          : `${cfi.riskFactor}`;
        const cfiOption = {
          value: cfi.id,
          label,
          options: []
        };

        forEach(cfi.codesAndScore, (cas) => {
          cfiOption.options.push({
            value: cas.text,
            label: cas.text
          });
          remove(cfiOption.options, {
            label: '-'
          });
        });

        answerOptions.push(cfiOption);
      });

      if (childTool) {
        const ctRiskOptions = {
          value: t.address,
          label: t.name,
          options: []
        };

        forEach(riskOptions, (ro) => {
          ctRiskOptions.options.push(ro);
        });

        if (ctRiskOptions.options.length) riskLevelOptions.push(ctRiskOptions);
      } else {
        forEach(riskOptions, (ro) => {
          riskLevelOptions.push(ro);
        });
      }

      if (t.childTools && t.childTools.length) {
        forEach(t.childTools, (ct) => {
          ct.address = `${t.address ? t.address : ''}${ct.id}>`;
          parseTool(ct, true);
        });
      }
    };

    // add the root level risk categories
    parseTool(tool);

    return [
      {
        value: 'riskLevel',
        label: 'Risk Level',
        options: [
          {
            type: 'enum',
            options: riskLevelOptions
          }
        ]
      },
      {
        value: 'specificAnswer',
        label: 'Specific Answer',
        options: [
          {
            type: 'enum',
            options: answerOptions
          }
        ]
      },
      {
        value: 'scoreRange',
        label: 'Score Range',
        options: [
          {
            type: 'number',
            label: 'Low'
          },
          {
            type: 'number',
            label: 'High'
          }
        ]
      },
      {
        value: 'score',
        label: 'Score',
        options: [
          {
            type: 'number'
          }
        ]
      },
      {
        value: 'sex',
        label: 'Sex',
        options: [
          {
            type: 'enum',
            options: [
              {
                value: 'male',
                label: 'Male'
              },
              {
                value: 'female',
                label: 'Female'
              }
            ]
          }
        ]
      },
      {
        value: 'clientType',
        label: 'Client Type',
        options: [
          {
            type: 'enum',
            options: [
              {
                value: 'Violent Offender',
                label: 'Violent Offender'
              },
              {
                value: 'Sex Offender',
                label: 'Sex Offender'
              },
              {
                value: 'Mentally Disordered Offender',
                label: 'Mentally Disordered Offender'
              },
              {
                value: 'Dangerous Offender',
                label: 'Dangerous Offender'
              },
              {
                value: 'High-Profile Offender',
                label: 'High-Profile Offender'
              },
              {
                value: 'Suicidal Offender',
                label: 'Suicidal Offender'
              },
              {
                value: 'Petty Offender',
                label: 'Petty Offender'
              },
              {
                value: 'Certified to Adult Court/Status',
                label: 'Certified to Adult Court/Status'
              },
              {
                value: 'Psychopathic Offender',
                label: 'Psychopathic Offender'
              },
              {
                value: 'Drug/Alcohol Offender',
                label: 'Drug/Alcohol Offender'
              },
              {
                value: 'Domestic Violence Offender',
                label: 'Domestic Violence Offender'
              },
              {
                value: 'other',
                label: 'Other (specify)'
              }
            ]
          }
        ]
      }
    ];
  }

  /**
   * Get set of state/province options based on a country code.
   *
   * @param code Country code.
   * @return List of state/province options.
   */
  getCountryProvinceOptions(code: 'US' | 'CA' | 'GB' | 'AU' | unknown) {
    switch (code) {
      case 'US':
        return constants.US_STATES;
      case 'CA':
        return constants.CANADA_PROVINCES;
      case 'GB':
        return constants.UK_PROVINCES;
      case 'AU':
        return constants.AU_STATES;
      default:
        return [];
    }
  }

  /**
   * ...
   *
   * @param conditions ...
   * @param client ...
   * @param evaluation ...
   * @return ...
   */
  checkCaseConditions(
    conditions: unknown[],
    client: Client,
    evaluation: Evaluation | AssessmentInstance | null
  ) {
    if (evaluation && 'evaluation' in evaluation) {
      evaluation = evaluation.evaluation ?? null;
    }

    if (!evaluation) {
      throw new Error(
        '[util.checkCaseConditions] the evaluation data passed in was invalid.'
      );
    }

    const evaluationScore = evaluation.score;
    const toolScores = evaluation.evaluationData
      ? evaluation.evaluationData.toolScores
      : null;
    const evaluationData = evaluation.evaluationData
      ? evaluation.evaluationData.data
      : null;
    const evaluationRiskCategory = evaluation.evaluationData
      ? evaluation.evaluationData.riskCategory
      : null;
    let passed = true;

    forEach(conditions, (condition) => {
      // check each condition here, if one fails, change passed to false
      switch (condition.type) {
        case 'sex':
          if (client.sex !== condition.value) passed = false;
          break;
        case 'clientType':
          if (indexOf(client.type, condition.value) < 0) passed = false;
          break;
        case 'score':
          if (evaluationScore !== condition.value) passed = false;
          break;
        case 'scoreRange':
          const high = maxBy(condition.value);
          const low = minBy(condition.value);
          if (evaluationScore < low || evaluationScore > high) passed = false;
          break;
        case 'riskLevel':
          if (typeof condition.value === 'object') {
            console.log('condition: ', condition);
            console.log('toolScores: ', toolScores);
            if (condition.value.toolAddress && toolScores) {
              // check specific child tool risk category
              /**
               * condition.value.toolAddress does not exist
               * So we're getting a false positive
               *
               * we only have condition.value = {toolName, value}
               */
              if (toolScores[condition.value.toolAddress]) {
                if (
                  toolScores[condition.value.toolAddress].riskCategory !==
                  condition.value.value
                )
                  passed = false;
              }
            }
          }
          break;
        case 'specificAnswer':
          if (typeof condition.value === 'object') {
            const evaluationDataItem = find(evaluationData, (val, key) => {
              return key.includes(condition.value.questionId);
            });
            if (!evaluationDataItem) passed = false;
            if (
              evaluationDataItem &&
              evaluationDataItem.text !== condition.value.answerText
            )
              passed = false;
          }
          break;
      }
    });

    return passed;
  }

  /**
   * ...
   *
   * @param value ...
   * @return ...
   */
  strip(value: unknown) {
    return JSON.parse(angular.toJson(value));
  }

  /**
   * Sorts an array of objects by a given key value alphabetically
   *
   * @param value ...
   * @return ...
   */
  sortArrayByValue(arr, val) {
    if (!Array.isArray(arr) || !arr?.length) return;
    if (typeof val !== 'string') return;

    return arr.sort((a, b) => {
      if (!a[val] || !b[val]) return 0;

      const nameA = typeof a[val] === 'string' ? a[val].toUpperCase() : a[val]; // ignore upper and lowercase
      const nameB = typeof b[val] === 'string' ? b[val].toUpperCase() : b[val]; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1; //nameA comes first
      }
      if (nameA > nameB) {
        return 1; // nameB comes first
      }
      return 0; // names must be equal
    });
  }
}
