'use strict';

import angular from 'angular';

export default angular
  .module('app.btnIcon', [])
  .directive('btnIcon', () => {
    'ngInject';

    return {
      restrict: 'E',
      replace: true,
      scope: true,
      template: `
        <button class="btn btn-icon-only" type="button"><fa :icon="icon"></fa></button>
      `,
      link(scope, element, { $attr, icon }) {
        scope.icon = $attr.icon.charAt(0) == ':' ? scope.$eval(icon) : icon;
      }
    };
  }).name;
