/**
 * ...
 */
export const NG_COMPONENT_DEPENDENCIES = [
  '$scope',
  '$element',
  '$attrs',
  '$anchorScroll',
  '$cacheFactory',
  '$compile',
  '$controller',
  '$document',
  '$exceptionHandler',
  '$filter',
  '$http',
  '$httpBackend',
  '$httpParamSerializer',
  '$httpParamSerializerJQLike',
  '$interpolate',
  '$interval',
  '$locale',
  '$location',
  '$log',
  '$parse',
  '$q',
  '$rootElement',
  '$rootScope',
  '$sce',
  '$sceDelegate',
  '$templateCache',
  '$templateRequest',
  '$timeout',
  '$window',
  // 3rd Party
  '$state',
  '$stateParams',
  '$cookies',
  'Upload',
  // GEARS
  'anchorSmoothScroll',
  'CasePlan',
  '$createObjects',
  'getItems',
  'getToolJson',
  'httpRequestInterceptor',
  'loadTool',
  'toolCanvas',
  'toolManager',
  '$store',
  '$acl',
  '$api',
  '$api2',
  '$auth',
  '$delay',
  '$generatePdf',
  '$ls',
  '$modals',
  '$toolRequirementsParser',
  '$util',
  'aggregateReportsMonitor',
  'Braintree',
  'codeTokenizer',
  'dataModals',
  'errorHandler',
  'evlReqUtils',
  'evlUtils',
  'getFormItems',
  'getToolJson',
  'idleMonitor',
  'idolTracker',
  'notify',
  'paginator',
  'policyTranslator',
  'Proration',
  'reportUtils',
  'toolUtils',
  'utils'
] as const;
