import angular from 'angular';
import { forEach, some, find, maxBy, uniqBy } from 'lodash';
import { ActionTree, MutationTree, GetterTree } from 'angular-store';

import { Tool, ToolData, TableState, EvaluationConfig } from '@interfaces';
import { RootState } from '@store/state';

/**
 * ...
 */
export interface ToolsState {
  loading: boolean;
  items: Tool[];
  focus: Tool | null;
  table: TableState;
  hasCasePlanTools: false;
  hasOffenderHistoryTools: false;
  evaluationConfigs: EvaluationConfig[];
}

/**
 * ...
 */
export interface ToolMutations {
  SET: (payload: Tool[]) => void;
  ADD: (payload: Tool[]) => void;
  ADD_TOOL_DATA: (toolData: ToolData) => void;
  SET_PROPS: (props: Partial<ToolsState>) => void;
  SET_FOCUS: (toolId?: string) => void;
  DELETE: (toolId: string) => void;
}

/**
 * ...
 */
export type ToolsStoreModule = ReturnType<typeof ToolsStore>;

/**
 * ...
 */
export interface GetToolDataActionOptions {
  toolId: string;
  commit: string;
}

declare module 'angular-store' {
  // export interface Store<S> {
  //   commit<T extends keyof ToolMutations>(
  //     type: `tools/${T}`,
  //     payload: Parameters<ToolMutations[T]>[0]
  //   ): void;
  // }

  export interface Commit {
    (type: 'SET', payload: Tool[]): void;
    (type: 'ADD', payload: Tool[]): void;
    (type: 'ADD_TOOL_DATA', toolData: ToolData): void;
    (type: 'SET_PROPS', props: Partial<ToolsState>): void;
    (type: 'SET_FOCUS', toolId: string): void;
    (type: 'DELETE', toolId: string): void;
  }

  export interface Dispatch {
    (type: 'list'): Promise<Tool[]>;
    (type: 'getAll'): Promise<Tool[]>;
    (type: 'getForInstitution', institutionId: string): Promise<Tool[]>;
    (type: 'getToolData', options: GetToolDataActionOptions): Promise<ToolData>;
    (type: 'delete', toolId: string): Promise<void>;
  }
}

/**
 * ...
 */
export default function ToolsStore(
  $http: angular.IHttpService,
  // $injector: angular.auto.IInjectorService,
  $api2: angular.gears.IAPI2Service,
  $acl: angular.gears.IAclService,
  $toolRequirementsParser: angular.gears.IToolRequirementsParserService
) {
  'ngInject';

  /**
   * ...
   *
   * @param institutionId ...
   * @param resources ...
   * @return ...
   */
  const queryTools = async (institutionId: string, resources: any) => {
    if (!resources || !Object.keys(resources ?? {}).length) {
      console.warn('User does not have access to any institution tools');

      return [];
    }

    let query = 'tool=';

    if (resources === '*') {
      query += '*';
    } else {
      query += Object.values(resources)
        .filter((resource) => resource.resourceValue)
        .map((resource) => resource.resourceValue)
        .join(',');

      if (query.includes(',')) {
        query = query.substring(0, query.length);
      }
    }

    const res = await $http.get(
      `/api/institution-manager/${institutionId}/tools?${query}`
    );

    if (!res || res.status !== 200) {
      throw res ?? new Error('Invalid response.');
    }

    return Array.isArray(res.data) ? res.data : [res.data];
  };

  const state: ToolsState = {
    loading: false,
    items: [],
    focus: null,
    table: { sortedCol: 0, searchText: '' },
    hasCasePlanTools: false,
    hasOffenderHistoryTools: false,
    evaluationConfigs: []
  };

  const getters: GetterTree<ToolsState, RootState> = {
    /**
     * ...
     */
    find: (state) => (ref: Tool) => {
      return state.items.find((item) => item === ref || item.id === ref.id);
    },
    /**
     * ...
     */
    getById: (state) => (id: Tool['id']) => {
      return state.items.find((item) => item.id == id);
    }
  };

  const actions: ActionTree<ToolsState, RootState> = {
    /**
     * ...
     */
    async list({ state, commit, rootState, rootGetters }) {
      commit('SET_PROPS', { loading: true });

      let data: any = null;

      let promise: Promise<unknown> | null = null;

      if ($acl('TC:ListAllTools', rootState.permissions.profile)) {
        // get all tools
        promise = $api2.tc.listAllTools();
      } else if (
        $acl('IM:ListInstitutionTools', rootState.permissions.profile)
      ) {
        // get tools for institution
        const resources = rootGetters['permissions/getResources'](
          'institutionmanager:ListInstitutionTools',
          'tool'
        );

        promise = queryTools(rootGetters.activeInstId, resources);
      }

      if (promise) {
        try {
          data = await promise;
        } catch (err) {
          commit('SET_PROPS', { loading: false });

          throw err;
        }
      }

      data = !data ? [] : !Array.isArray(data) ? [data] : data;

      // Add institution institution case plan templates tool appropriate tools
      if (rootState?.me?.institution && data?.length) {
        let icpts;
        try {
          icpts = await $api2.icpt.listCasePlanTemplates({
            institutionId: rootGetters.activeInstId
          });

          console.log({ icpts });
        } catch (err) {
          console.error(err);
        }

        if (icpts?.length) {
          for (const tool of data) {
            if (find(icpts, { toolId: tool.id })) {
              tool.institutionCasePlanTemplates = [
                find(icpts, {
                  toolId: tool.id
                })
              ];
            }
            // if (!tool?.institutionCasePlanTemplates?.length) continue;

            // tool.institutionCasePlanTemplates =
            //   tool.institutionCasePlanTemplates.filter(
            //     ({ institutionId }) =>
            //       institutionId === rootState.me.institution.id
            //   );
          }
        }
      }

      // Check if tools have offender history required and set variable
      if (some(data, (tool) => tool.requiresOffenderHistory)) {
        commit('SET_PROPS', { hasOffenderHistoryTools: true });
      }

      // Check if tools have case plan templates and set variable
      if (
        some(
          data,
          (tool) =>
            tool.managedCasePlanTemplates?.length ||
            tool.institutionCasePlanTemplates?.length
        )
      ) {
        commit('SET_PROPS', { hasCasePlanTools: true });
      }

      commit('SET', data);
      commit('SET_PROPS', { loading: false });

      return state.items;
    },
    /**
     * ...
     */
    async getAll({ state, commit }) {
      console.error(
        '[store.tools] getAll deprecated: please update to list method'
      );

      commit('SET_PROPS', { loading: true });

      let data: Tool[] = [];

      try {
        data = await $api2.tc.listAllTools();
      } catch (err) {
        commit('SET_PROPS', { loading: false });
        throw err;
      }

      commit('SET', data);
      commit('SET_PROPS', { loading: false });

      return state.items;
    },
    /**
     * ...
     */
    async getForInstitution(
      { state, rootState, commit },
      institutionId: string
    ) {
      institutionId = institutionId ?? rootState.me.institution?.id;

      if (!institutionId) return;

      commit('SET_PROPS', { loading: true });

      let data: Tool[] = [];

      try {
        data = await $api2.im.listInstitutionTools({ institutionId });
      } catch (err) {
        commit('SET_PROPS', { loading: false });
        throw err;
      }

      commit('SET', data);
      commit('SET_PROPS', { loading: false });

      return state.items;
    },
    /**
     * grabs the basic toolData from a given tool and assigns it to the
     * "toolData" attribute of the state.items entry.
     */
    async getToolData({ state, commit }, { toolId, commitId }) {
      // See if we've already retrieved toolData from backend
      const tool = find(state.items, { id: toolId });

      if (tool?.toolData) return tool.toolData;

      let { toolData, liveToolPdfId, liveDescriptionPdfId } =
        await $api2.tc.getToolCommit({ toolId, commitId });

      // Check for pdfs
      toolData.liveToolPdfId = liveToolPdfId;
      toolData.liveDescriptionPdfId = liveDescriptionPdfId;
      toolData.address = `${toolData.id}>`;

      parseTools(toolData, toolData.address, true);

      toolData = $toolRequirementsParser.parse(
        toolData,
        state.evaluationConfigs[0]
      );

      commit('ADD_TOOL_DATA', toolData);

      return find(state.items, { id: toolData.id })?.toolData;
    },
    /**
     * ...
     */
    async delete({ commit }, toolId) {
      await $api2.tc.deleteTool({ toolId });

      commit('DELETE', toolId);
    }
  };

  const mutations: MutationTree<ToolsState> = {
    SET(state, payload) {
      state.items = [...payload];
    },
    ADD(state, payload) {
      state.items = uniqBy([...payload, ...state.items], 'id');
    },
    ADD_TOOL_DATA(state, toolData: ToolData) {
      const tool = find(state.items, { id: toolData.id });

      if (!tool) {
        state.items.push({
          id: toolData.id,
          commitId: toolData.commitId,
          name: toolData.name,
          toolData: toolData
        });

        return;
      }

      tool.toolData = toolData;
    },
    SET_PROPS(state, props: Partial<ToolsState> = {}) {
      for (const i in props) {
        if (i in state) {
          state[i] = props[i];
        }
      }
    },
    SET_FOCUS(state, toolId: string) {
      state.focus = state.items.find(({ id }) => id === toolId) ?? null;
    },
    DELETE(state, toolId: string) {
      state.items = state.items.filter(({ id }) => id !== toolId);
    },
    CLEAR(state) {
      Object.assign(state, {
        loading: false,
        items: [],
        focus: null,
        table: { sortedCol: 0, searchText: '' },
        hasCasePlanTools: false,
        hasOffenderHistoryTools: false,
        evaluationConfigs: []
      });
    }
  };

  // TEMP: remove once all references to them have been removed.
  Object.defineProperties(mutations, {
    set: { enumerable: true, value: mutations.SET },
    add: { enumerable: true, value: mutations.ADD },
    setProps: { enumerable: true, value: mutations.SET_PROPS },
    setFocus: { enumerable: true, value: mutations.SET_FOCUS }
  });

  return { state, getters, actions, mutations };
}

/**
 * ...
 *
 * @param t ...
 * @param toolAddress ...
 * @param rootTool ...
 * @return ...
 */
function parseTools(t: ToolData, toolAddress: string, rootTool: boolean) {
  t.address = toolAddress;
  t.answeredCount = 0;
  t.unansweredCount = 0;
  t.omitted = 0;
  t.valid = false;

  // Parse coding form items
  forEach(t.codingFormItems, (cfi) => {
    if (cfi.pdfItemHelpBookmark && cfi.pdfItemHelpBookmark !== '') {
      if (cfi.pdfItemPage && cfi.pdfItemPage !== '') {
        if (parseInt(cfi.pdfItemPage, 10)) {
          cfi.pdfBookmarkPage = cfi.pdfItemPage;
        } else {
          console.error('PDF Item Page is Not a Number');
        }
      }
    } else if (cfi.pdfItemPage) {
      cfi.pdfBookmarkPage = cfi.pdfItemPage;
    }

    if (!rootTool) {
      cfi.longAddress = `${t.address ? t.address : ''}${cfi.id}`;
    }

    // Add blank options to answers array
    cfi.codesAndScore.unshift({ id: '-', score: '-', text: '-' });
  });

  // Handle tool risk categories.
  if (t.riskCategories) {
    const riskCatogory = maxBy(t.riskCategories, 'high');

    if (riskCatogory) {
      riskCatogory.originalHigh = riskCatogory.high;

      t.progressBar = {
        type: 'warning',
        max: t.riskCategories.length,
        value: 0
      };
    }
  }

  if (t.childTools?.length) {
    forEach(t.childTools, (ct, i) => {
      if (i === 0) ct.active = true;
      const ta = rootTool ? `${ct.id}>` : `${t.id}>${ct.id}>`;

      parseTools(ct, ta, false);
    });
  }
}
