import { Ng, Component, Prop } from '@angular';

import { IAugmentedJQuery } from 'angular';
import $ from 'jquery';

class SpinnerBlade {
  private readonly innerEl: IAugmentedJQuery;
  private readonly outterEl: IAugmentedJQuery;

  constructor(
    public readonly count: number,
    public readonly index: number,
    anchorEl: IAugmentedJQuery
  ) {
    //
    this.innerEl = $('<div class="spinner-blade-inner"></div>');

    //
    this.outterEl = $('<div class="spinner-blade-outter"></div>');
    this.outterEl.css('transform', `rotate(${360 * (index / count)}deg)`);
    this.outterEl.append(this.innerEl);

    //
    anchorEl.append(this.outterEl);
  }

  setColor(color: string | null) {
    this.innerEl.css('backgroundColor', color);
  }

  setOpacity(offset: number) {
    this.outterEl.css(
      'opacity',
      Math.repeat(this.index - offset, this.count) / this.count
    );
  }
}

@Component({
  name: 'spinner',
  template: require('./spinner.html')
})
export class Spinner extends Ng {
  // @Prop() readonly color!: string;

  private blades: SpinnerBlade[] = [];
  private count = 10;
  private offset = 0;

  get color() {
    const { $attr, color } = this.$attrs;

    return $attr.color === ':color' ? this.$scope.$eval(color) : color;
  }

  $onInit() {
    const anchorElem = this.$element.find('>div');

    for (let i = 0; i < this.count; i++) {
      const blade = new SpinnerBlade(this.count, i, anchorElem);
      blade.setOpacity(this.offset);

      this.blades.push(blade);
    }

    this.$watch(() => this.color, this.onColorChanged);

    const intervalId = setInterval(this.onInterval);
    anchorElem.on('remove', () => clearInterval(intervalId));
  }

  private onInterval = () => {
    const nextOffset = this.offset + 0.05;
    this.offset = nextOffset > this.count ? 0 : nextOffset;

    this.blades.forEach((blade) => blade.setOpacity(this.offset));
  };

  private onColorChanged = (color: string | null) => {
    this.blades.forEach((blade) => blade.setColor(color));
  };
}

export default Spinner.$module;
