import angular from 'angular';
import { filter, find, forEach, maxBy } from 'lodash';

import { CasePlan } from '@interfaces/case-plan';
import { Client } from '@interfaces/client';
import { Institution } from '@interfaces/institution';
import { OffenderHistory } from '@interfaces/offender-history';
import { Tool } from '@interfaces/tool';

import { createZoneData } from './zone-data';

declare module 'angular' {
  namespace gears {
    type IGetItemsService = GetItemsService;
  }
}

export class GetItemsService {
  constructor(
    private readonly $http: angular.IHttpService,
    private readonly $q: angular.IQService,
    private readonly $api: angular.gears.IApiService,
    private readonly $api2: angular.gears.IAPI2Service,
    private readonly $store: angular.gears.IStoreService,
    private readonly $reincode: angular.gears.IReincodeService,
    private readonly notify: angular.gears.INotifyService
  ) {
    'ngInject';

    // Wrap all methods in a function that automatically imploys use of the
    // `$q.defer` for reactive async promise resolution.
    Object.getOwnPropertyNames(Object.getPrototypeOf(this))
      .filter((key) => key !== 'contructor')
      .forEach((key: keyof GetItemsService) => {
        if (typeof this[key] !== 'function') return;

        const method = this[key].bind(this);

        const value = async (...args: unknown[]) => {
          console.warn(`[getItem:${key}] API calls should be moved to $api2.`);

          const deferred = this.$q.defer();

          method(...args)
            .then((res: unknown) => deferred.resolve(res))
            .catch((err: Error) => deferred.reject(err));

          return deferred.promise;
        };

        Object.defineProperty(this, key, { value });
      });
  }

  /**
   * Get all institutions.
   *
   * @return ...
   */
  async allInstitutions() {
    let institutions = await this.$api2.gm.listInstitutions();

    for (const institution of institutions) {
      institution.zones = createZoneData(institution.zones);
    }

    return institutions;
  }

  /**
   * Get multi tool Discount.
   *
   * @return ...
   */
  multiToolDiscount() {
    const deferred = this.$q.defer();

    this.$http
      .get('/api/multi-tool-discounts')
      .then((response) => {
        let multiToolDiscount = response.data.data;
        deferred.resolve(multiToolDiscount);
      })
      .catch((err) => {
        deferred.resolve(err);
      });

    return deferred.promise;
  }

  /**
   * Get specific developer group.
   *
   * @return ...
   */
  developerGroup(developerGroupId: string) {
    const deferred = this.$q.defer();

    this.$http
      .get(`/api/developer-groups/${developerGroupId}`)
      .then((response) => {
        let developerGroup = response.data.data;
        deferred.resolve(developerGroup);
      })
      .catch((err) => {
        deferred.resolve(err);
      });

    return deferred.promise;
  }

  /**
   * Get specific user.
   *
   * @return ...
   */
  async user(userId: string) {
    const deferred = this.$q.defer();

    let response = await this.$api.gearsManager.getUser({
      userId
    });

    response.status === 200
      ? deferred.resolve(response.data.data)
      : deferred.reject(response);

    return deferred.promise;
  }

  /**
   * Get client's case plan history.
   *
   * @return ...
   */
  async clientCasePlans(client: Client) {
    const deferred = this.$q.defer();

    let caseplans = await this.$api.clientManager.listClientCasePlans({
      instId: client.institutionId,
      sbGrpId: client.subGroup?.id,
      clntId: client.id
    });

    if (caseplans.status === 200)
      deferred.resolve(this.$reincode.fullObject(caseplans.data));
    deferred.reject();

    return deferred.promise;
  }

  /**
   * Get client's case plan.
   *
   * @return ...
   */
  async getCasePlan(client: Client, caseplan: CasePlan) {
    const deferred = this.$q.defer();

    let caseplanRes = await this.$api.clientManager.getClientCasePlan({
      instId: client.institutionId,
      sbGrpId: client.subGroup?.id,
      clntId: client.id,
      casePlanId: caseplan.id
    });

    if (caseplanRes.status === 200)
      deferred.resolve(this.$reincode.fullObject(caseplanRes.data));
    deferred.reject();

    return deferred.promise;
  }

  /**
   * Get specific evaluation.
   *
   * @return ...
   */
  async evaluation(evaluationId: string, client: Client) {
    const deferred = this.$q.defer();

    // let response = await this.$http.get(`/api/evaluations/${evaluationId}`);
    let response = await this.$api.clientManager.getClientEvaluation({
      instId: client.institutionId,
      sbGrpId: client?.subGroup?.id,
      clntId: client.id,
      evalId: evaluationId
    });

    if (response.status === 200) {
      if (
        response.data.evaluationData &&
        typeof response.data.evaluationData.clientId !== 'string'
      ) {
        response.data.evaluationData.clientId =
          response.data.evaluationData.clientId.toString();
      }
      deferred.resolve(this.$reincode.fullObject(response.data));
    } else {
      this.notify.display(response, 'error');
      deferred.reject();
    }

    return deferred.promise;
  }

  /**
   * Get offender history for client.
   *
   * @return ...
   */
  async clientOffenderHistory(client: Client) {
    const deferred = this.$q.defer();

    let response = await this.$api.clientManager.listOffenderHistory({
      instId: client.institutionId,
      sbGrpId: client.subGroup?.id,
      clntId: client.id
    });

    if (response.status === 200) deferred.resolve(response.data);
    deferred.reject([]);

    return deferred.promise;
  }

  /**
   * Get specific offender history data.
   *
   * @return ...
   */
  async offenderHistoryData(client: Client, offenderHistory: OffenderHistory) {
    const deferred = this.$q.defer();

    let response = await this.$api.clientManager.getOffenderHistory({
      instId: client.institutionId,
      sbGrpId: client.subGroup?.id,
      clntId: client.id,
      historyId: offenderHistory.id
    });

    if (response.status === 200) deferred.resolve(response.data.historyData);
    deferred.reject();

    return deferred.promise;
  }

  //region Case Plan Templates
  /**
   * Get master case plan template.
   *
   * @return ...
   */
  async toolManagedCasePlanTemplate(tool: Tool) {
    const deferred = this.$q.defer();

    // let response = await this.$http.get(
    //   `/api/tools/${tool.id}/managed-case-plan-templates`
    // );
    let response = await this.$api.toolCreator.listManagedCasePlanTemplates({
      toolId: tool.id
    });

    if (response.status === 200) {
      if (response.data && response.data.length) {
        // there should only be one managed case plan template for the tool
        deferred.resolve(response.data[0]);
      } else {
        deferred.resolve();
      }
    } else {
      deferred.reject();
    }

    return deferred.promise;
  }

  /**
   * Get master case plan template commits.
   *
   * @return ...
   */
  async managedCasePlanTemplateCommits(template: unknown) {
    const deferred = this.$q.defer();

    // let response = await this.$http.get(
    //   `/api/managed-case-plan-templates/${template.id}/commits`
    // );
    let response = await this.$api.mcpt.listCasePlanTemplateCommits({
      templateId: template.id
    });

    if (response.status === 200) {
      forEach(response.data, (commit) => {
        commit.templateId = template.id;
      });

      deferred.resolve(response.data);
    } else {
      deferred.reject();
    }

    return deferred.promise;
  }

  /**
   * Get data for managed case plan template.
   *
   * @return ...
   */
  async managedCasePlanTemplateCommit(commitId: string, templateId: string) {
    const deferred = this.$q.defer();

    let data: unknown = null;
    let error: unknown = null;

    const options = {
      templateId,
      commitId
    };

    try {
      data = await this.$api2.mcpt.getCasePlanTemplateCommit(options);
      if (!data.toolId && data.tool?.id) data.toolId = data.tool.id;
    } catch (err) {
      error = err;
    }

    if (error) {
      deferred.reject(error);
    } else {
      deferred.resolve(data);
    }

    return deferred.promise;
  }

  /**
   * Get data for case plan template.
   *
   * @return ...
   */
  async casePlanTemplateCommit(
    institutionId: string,
    templateId: string,
    commitId: string
  ) {
    const deferred = this.$q.defer();

    let data: unknown = null;
    let error: unknown = null;

    const options = {
      institutionId,
      templateId,
      commitId
    };

    try {
      data = await this.$api2.icpt.getCasePlanTemplateCommit(options);
      if (!data.toolId && data.tool?.id) data.toolId = data.tool.id;
    } catch (err) {
      error = err;
    }

    if (error) {
      deferred.reject(error);
    } else {
      deferred.resolve(data);
    }

    return deferred.promise;
  }

  /**
   * Get case plan templates for all institutions.
   *
   * @return ...
   */
  async toolCasePlanTemplates(tool: Tool) {
    const deferred = this.$q.defer();

    let response = await this.$http.get(
      `/api/tools/${tool.id}/case-plan-templates`
    );

    response.status === 200
      ? deferred.resolve(response.data.data)
      : deferred.reject(response);

    return deferred.promise;
  }

  /**
   * Get case plan template commits for an institution.
   *
   * @return ...
   */
  async institutionCasePlanTemplates(institution: Institution, tool: Tool) {
    const deferred = this.$q.defer();

    let response = await this.$api.icpt.listCasePlanTemplates({
      instId: institution.id
    });

    if (response.status === 200) {
      if (tool) {
        // if tool is specified, filter templates that don't have tool id
        response.data = filter(response.data, (template) => {
          return template.toolId === tool.id;
        });
      }
      deferred.resolve(response.data);
    } else {
      deferred.reject(response);
    }

    return deferred.promise;
  }

  /**
   * Get case plan template commits for a case plan template.
   *
   * @return ...
   */
  async casePlanTemplateCommits(institution: Institution, template: unknown) {
    const deferred = this.$q.defer();

    // let response = await this.$http.get(
    //   `/api/institutions/${institution.id}/case-plan-templates/${
    //     template.id
    //   }/commits`
    // );
    let response = await this.$api.icpt.listCasePlanTemplateCommits({
      instId: institution.id,
      tmpltId: template.id
    });

    if (response.status === 200) {
      forEach(response.data, (commit) => {
        commit.institutionId = institution.id;
        commit.templateId = template.id;
      });

      deferred.resolve(response.data);
    } else {
      deferred.reject(response);
    }

    return deferred.promise;
  }

  /**
   * Given a tool, return either an institution template if a live one exists, or the master case plan template.
   *
   * @return ...
   */
  async getCasePlanTemplate(tool: Tool) {
    const deferred = this.$q.defer();

    console.log('get case plan template tool: ', tool);

    if (!tool) deferred.reject();
    let cpt;
    let commits;
    let liveCommit;
    let templateData;

    // find an institution case plan template that has a published commit id (i.e. is Live)
    if (tool.institutionCasePlanTemplates?.length) {
      cpt = find(tool.institutionCasePlanTemplates, 'publishedCommitId');
      if (!cpt) cpt = maxBy(tool.institutionCasePlanTemplates, 'updatedAt');
      if (cpt) {
        commits = await this.casePlanTemplateCommits(
          { id: cpt.institutionId },
          cpt
        );
        if (commits && commits.length) {
          liveCommit = find(commits, { status: 'Live' });
          if (!liveCommit) liveCommit = maxBy(commits, 'version');
          if (!liveCommit) liveCommit = maxBy(commits, 'createdAt');
          if (liveCommit) {
            templateData = await this.casePlanTemplateCommit(
              cpt.institutionId,
              cpt.id,
              liveCommit.id
            );
            if (templateData && templateData.templateData)
              templateData = templateData.templateData;
            if (templateData) {
              templateData = {
                data: templateData,
                institutionCasePlanTemplateId: cpt.id,
                institutionCpTemplateCommitId: liveCommit.id,
                managedCasePlanTemplateId: null,
                managedCpTemplateCommitId: null
              };
              deferred.resolve(templateData);
            }
            return templateData;
          }
        }
      }
    }

    // if no templateData was found, find the Live or latest Master Template
    if (!templateData && tool.managedCasePlanTemplates?.length) {
      cpt = null;
      commits = null;
      liveCommit = null;

      cpt = find(tool.managedCasePlanTemplates, 'publishedCommitId');
      if (!cpt) cpt = maxBy(tool.managedCasePlanTemplates, 'updatedAt');
      if (!cpt) {
        this.notify.display(
          'Could not detect Master Case Plan Template to use',
          'error'
        );
        return;
      }
      if (cpt) {
        commits = await this.managedCasePlanTemplateCommits(cpt);
        if (commits && commits.length) {
          liveCommit = find(commits, { status: 'Live' });
          if (!liveCommit) liveCommit = maxBy(commits, 'version');
          if (!liveCommit) liveCommit = maxBy(commits, 'createdAt');
          if (!liveCommit) {
            this.notify.display(
              'Could not detect Latest/Live Commit for Master Case Plan Template',
              'error'
            );
            return;
          }
          if (liveCommit) {
            templateData = await this.managedCasePlanTemplateCommit(
              liveCommit.id,
              cpt.id
            );
            if (templateData && templateData.templateData)
              templateData = templateData.templateData;
            if (templateData) {
              templateData = {
                data: templateData,
                institutionCasePlanTemplateId: null,
                institutionCpTemplateCommitId: null,
                managedCasePlanTemplateId: cpt.id,
                managedCpTemplateCommitId: liveCommit.id
              };
              deferred.resolve(templateData);
            }
            return templateData;
          }
        }
      }
    } else {
      deferred.resolve();
    }

    return deferred.promise;
  }

  //endregion Case Plan Templates
  /**
   * Get related tool template.
   *
   * @return ...
   */
  async toolTemplate(tool: Tool) {
    let response = await this.$api2.tc.listToolReportTemplates({
      toolId: tool.id
    });

    return response[0];
  }

  /**
   * Get template commits.
   *
   * @return ...
   */
  async templateCommits(template: unknown) {
    return await this.$api2.tc.listToolReportTemplateCommits({
      toolId: template.toolId,
      reportTemplateId: template.id
    });
  }

  /**
   * Get template commit file.
   *
   * @return ...
   */
  async templateCommitFile(
    commit: unknown,
    templateId: string,
    toolId: string,
    templateDataOnly = true
  ) {
    return await this.$http.get(
      `/api/tool-creator/tools/${toolId}/report-templates/${templateId}/commits/${commit.id}?templateDataOnly=${templateDataOnly}`
    );
  }

  /**
   * Get template editor file.
   *
   * @return ...
   */
  async templateEditorFile(commit: unknown) {
    const deferred = this.$q.defer();

    let response = await this.$http.get(
      `/api/tool-creator/tools/${commit.toolId}/report-templates/${commit.templateId}/commits/${commit.id}`
    );

    response.status === 200
      ? deferred.resolve(response.data.templateData)
      : deferred.reject(response);

    return deferred.promise;
  }

  /**
   * Get coding forms.
   *
   * @return ...
   */
  async codingForms(tool: Tool) {
    const deferred = this.$q.defer();

    // let response = await this.$http.get(`/api/tools/${tool.id}/coding-forms`);
    let response = await this.$api.toolCreator.listToolCodingForms({
      toolId: tool.id
    });

    response.status === 200
      ? deferred.resolve(response.data)
      : deferred.reject(response);

    return deferred.promise;
  }

  /**
   * Get tool commits.
   *
   * @return ...
   */
  async toolCommits(tool: Tool) {
    const deferred = this.$q.defer();

    let response = await this.$api.toolCreator.listToolCommits({
      toolId: tool.id
    });

    response.status === 200
      ? deferred.resolve(response.data)
      : deferred.reject(response);

    return deferred.promise;
  }

  /**
   * Get tool commit file.
   *
   * @return ...
   */
  async toolCommitFile(commit: unknown) {
    const deferred = this.$q.defer();

    try {
      let response = await this.$api.toolCreator.getToolCommit({
        toolId: commit.toolId,
        commitId: commit.id
      });

      if (response?.status === 200) {
        response.data.toolData.commitId = commit.id;
        deferred.resolve(response.data.toolData);
      } else {
        this.notify.display(response, 'error');
        deferred.reject();
      }
    } catch (err) {
      this.notify.display(err, 'error');
      deferred.reject();
    }

    return deferred.promise;
  }

  /**
   * Get tool editor file.
   *
   * @return ...
   */
  async toolEditorFile(commit: unknown) {
    const deferred = this.$q.defer();

    // let response = await this.$http.get(
    //   `/api/tool-commits/${commit.id}/editor-data`
    // );
    let response = await this.$api.toolCreator.getToolCommit({
      toolId: commit.toolId,
      commitId: commit.id
    });

    response.status === 200
      ? deferred.resolve(response.data.editorData)
      : deferred.reject(response);

    return deferred.promise;
  }

  /**
   * Get all live tools.
   *
   * @return ...
   */
  async liveTools() {
    const deferred = this.$q.defer();

    // let response = await this.$http.get('/api/tools/live');
    let response = await this.$api.toolCreator.listLiveTools();
    response.status === 200
      ? deferred.resolve(response.data)
      : deferred.reject(response);

    return deferred.promise;
  }

  /**
   * Get specific tool.
   *
   * @return ...
   */
  async tool(toolId: string) {
    const deferred = this.$q.defer();

    let response = await this.$http.get(`/api/tools/${toolId}`);
    response.status === 200
      ? deferred.resolve(response.data.data)
      : deferred.reject(response);

    return deferred.promise;
  }

  /**
   * Get specific invite.
   *
   * @return ...
   */
  async invite(inviteId: string) {
    const deferred = this.$q.defer();

    let response = await this.$http.get(`/api/invites/${inviteId}`);
    response.status === 200
      ? deferred.resolve(response.data.data)
      : deferred.reject(response);

    return deferred.promise;
  }

  /**
   * Get tools for an institution.
   *
   * @return ...
   */
  async institutionTools(id: string) {
    const deferred = this.$q.defer();

    let response = await this.$api.institutionManager.listInstitutionTools({
      instId: id
    });

    response.status === 200
      ? deferred.resolve(response.data)
      : deferred.resolve();

    return deferred.promise;
  }

  /**
   * Get institution locations.
   *
   * @return ...
   */
  async institutionLocations(id: string) {
    const data =
      (await this.$http.get<any[]>(`/api/institutions/${id}/zones`))?.data ??
      [];

    return createZoneData(data);
  }

  /**
   * Get offense classifications.
   *
   * @return ...
   */
  async offenseClassifications(instId: string) {
    const deferred = this.$q.defer();

    // let response = await this.$http.get(`/api/invites/${inviteId}`);
    let classifications;
    if (!this.$store.state.offenseClassifications.length) {
      classifications = await this.$store.dispatch(
        'offenseClassifications/list',
        instId
      );
    } else {
      classifications = this.$store.state.offenseClassifications.items;
    }

    deferred.resolve(classifications);

    return deferred.promise;
  }
}

export default angular
  .module('app.get-items', [])
  .service('getItems', GetItemsService).name;
