'use strict';

import angular from 'angular';
import _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class SelectTermModalController extends Modal {
  terms: {};
  selectedTerm: {};

  @Inject $scope;
  @Inject utils;

  $setup() {
    if (this.$modal.type === 'dictionary') {
      let dictionary = this.$props;
      this.terms = [];

      for (let i = 0; i < dictionary.rows.length; i++) {
        if (i > 0) {
          this.terms.push({
            id: _.find(dictionary.rows[i].fields, { label: 'Term ID' }).text,
            term: _.find(dictionary.rows[i].fields, { label: 'Term' }).model,
            definition: _.find(dictionary.rows[i].fields, {
              label: 'Definition'
            }).model
          });
        }
      }
    } else {
      this.terms = this.$props;
    }

    this.selectedTerm = {};
  }

  termSelected() {
    this.selectedTerm = JSON.parse(this.selectedTerm);

    if (this.selectedTerm.id) {
      this.$close(this.selectedTerm);
    } else {
      this.utils.notify('warning', {});
    }
  }
}

export default angular
  .module('app.selectTermModal', [])
  .directive('selectTermModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./select-term.html'),
    controller: SelectTermModalController,
    controllerAs: 'vm'
  })).name;
