export default function Paginated(Controller) {
  Object.defineProperties(Controller.prototype, {
    pages: {
      enumerable: true,
      writable: true,
      value: []
    },
    pageButtons: {
      enumerable: true,
      writable: true,
      value: []
    },
    pageNumber: {
      enumerable: true,
      writable: true,
      value: 1
    },
    itemsPerPage: {
      enumerable: true,
      writable: true,
      value: 15
    },
    currentPage: {
      enumerable: true,
      get() {
        this.pageNumber = Math.clamp(this.pageNumber, 1, this.pages.length);

        return this.pages[this.pageNumber - 1];
      }
    },
    pageCount: {
      enumerable: true,
      get() {
        return this.pages.length;
      }
    },
    pageItemsCount: {
      enumerable: true,
      get() {
        return this.currentPage?.length || 0;
      }
    },
    createPages: {
      value(entries = []) {
        var items = [...entries],
          pages = [],
          pos = 0;

        const itemsPerPage =
          this.itemsPerPage === null
            ? items.length
            : typeof this.itemsPerPage == 'number'
            ? this.itemsPerPage
            : 15;

        while (pos <= items.length) {
          const start = pos;
          pos += itemsPerPage;

          pages.push(items.slice(start, pos));
        }

        this.pages = pages;

        // Button configs
        this.updateButtons();
      }
    },
    updateButtons: {
      value() {
        const MAX_CNT = 5;
        const arr = [
          {
            type: 'page',
            value: 1
          }
        ];

        if (this.pageCount > MAX_CNT && this.pageNumber >= MAX_CNT) {
          arr.push({ type: 'ellipsis', value: '...' });
        }

        var num = Math.floor(this.pageNumber / MAX_CNT);
        num *= MAX_CNT;

        let s = num === 0 ? 2 : num === 1 ? 1 : 0;
        let l = MAX_CNT;

        for (let i = s; i <= l; i++) {
          const pageNumber = num + i;

          if (pageNumber > this.pageCount - 1) {
            break;
          }

          arr.push({
            type: 'page',
            value: pageNumber
          });
        }

        if (
          this.pageCount > MAX_CNT &&
          this.pageNumber < MAX_CNT * Math.floor(this.pageCount / MAX_CNT)
        ) {
          arr.push({ type: 'ellipsis', value: '...' });
        }

        if (this.pageCount > 1) {
          arr.push({ type: 'page', value: this.pageCount });
        }

        this.pageButtons = arr;
      }
    },
    toPage: {
      value(pageNum) {
        this.pageNumber = pageNum;
        this.updateButtons();
      }
    },
    previousPage: {
      value() {
        this.toPage(this.pageNumber - 1);
      }
    },
    nextPage: {
      value() {
        this.toPage(this.pageNumber + 1);
      }
    }
  });

  return Controller;
}
