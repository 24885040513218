'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class DefinitionModalController extends Modal {
  term: any;

  $setup() {
    this.term = this.$props;
  }
}

export default angular
  .module('app.definitionModal', [])
  .directive('definitionModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./definition.html'),
    controller: DefinitionModalController,
    controllerAs: 'vm'
  })).name;
