import angular from 'angular';
import _ from 'lodash';

import { RootModules } from '@store/state';

import TextFieldComponent from './sub-section-components/text-field/text-field.component';
import SelectorComponent from './sub-section-components/selector/selector.component';
import TableComponent from './sub-section-components/table/table.component';
import ProgressTableComp from './sub-section-components/progress-table/progress-table.component';

class CasePlanController {
  readonly $scope: angular.IScope;
  readonly $rootScope: angular.IRootScopeService;
  readonly $state: angular.ui.IStateService;
  // readonly $store: angular.gears.IStoreService;
  readonly $modals: angular.gears.IModalsService;
  // readonly $auth: angular.gears.IAuthService;
  // readonly $util: angular.gears.IUtilsService;
  readonly notify: angular.gears.INotifyService;
  readonly toolUtils: angular.gears.IToolUtilsService;
  readonly _CasePlan: angular.gears.ICasePlanService;

  dsc: unknown;
  case: unknown;
  caseplanOptions: unknown;
  sections: unknown[] = [];
  activeSection: unknown = null;
  isValid = true;
  user: RootModules['me'];

  constructor(
    $scope: angular.IScope,
    $rootScope: angular.IRootScopeService,
    $state: angular.ui.IStateService,
    $store: angular.gears.IStoreService,
    $modals: angular.gears.IModalsService,
    // $auth: angular.gears.IAuthService,
    // $util: angular.gears.IUtilsService,
    notify: angular.gears.INotifyService,
    toolUtils: angular.gears.IToolUtilsService,
    CasePlan: angular.gears.ICasePlanService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.dsc = $scope.$parent.$parent.dsc;

    if (!this.dsc) this.dsc = $scope.vm.dsc;

    this.$rootScope = $rootScope;
    this.notify = notify;
    this.toolUtils = toolUtils;

    // this.case = SampleCasePlan;

    this._CasePlan = CasePlan;

    let savedCasePlan = this._CasePlan.SavedCasePlan();

    this.case = this.dsc.caseplan
      ? this._CasePlan.CasePlan(this.dsc.caseplan)
      : this._CasePlan.SampleCasePlan();

    this.caseplanOptions = this.dsc.caseplanOptions;

    var me = $store.state.me;
    this.user = me;
    this.tool = toolUtils.tool;
    this.$modals = $modals;
    this.sections = this.case.sections;
    this.activeSection = this.case.first;
  }
}

export default angular
  .module('app.section.casePlan', [
    TextFieldComponent,
    SelectorComponent,
    TableComponent,
    ProgressTableComp
  ])
  .component('casePlan', {
    template: require('./case-plan.html'),
    bindings: { dsc: '<', case: '=' },
    controller: CasePlanController,
    controllerAs: 'vm'
  }).name;
