import { Component, Getter, Action, State } from '@angular';
import { Modal, ModalProp } from '@mixins/modal.component';

import { User } from '@interfaces/user';
import { Institution } from '@interfaces/institution';

@Component({
  name: 'editInstitutionSuperAdminModal',
  template: require('./edit-institution-super-admin.html')
})
export class EditInstitutionSuperAdminModalController extends Modal {
  @ModalProp readonly institution!: Institution | null;
  // @Prop() readonly user!: User | null;

  submitting = false;
  loading: boolean = false;
  email: string | null = null;
  userId: string | null = null;
  selectedInstitution: string | null = null;

  @State(({ users }) => users.items) readonly users!: User[];
  @State(({ institutions }) => institutions.items)
  readonly institutions!: Institution[];
  @Action('institutions/updateSuperAdmin') updateInstitutionSuperAdmin!: any;
  @Action('users/list') listUsers!: any;
  @Action('institutions/getAll') listInstitutions!: any;
  @Action('users/getForInstitution') getInstitutionUsers!: any;
  @Getter isAdmin;

  get processing() {
    return this.submitting;
  }

  get canSubmit() {
    return this.userId || this.email?.length;
  }

  async $setup() {
    if (!this.isAdmin) {
      this.notify.display('Must be GEARS Admin to access', 'warning');
      this.$close();
    }

    if (!this.institution) return;

    this.loading = true;
    this.selectedInstitution = this.institution.id;

    await this.getInstitutionUsers(this.selectedInstitution);
    this.loading = false;
    // if (!this.users?.length) await this.listUsers();
    // this.loading = false;
    // this.$scope.$apply();
  }

  async submit() {
    let data: any = null;
    let error: Error | null = null;

    this.submitting = true;

    if (!this.userId && !this.email) return;
    if (!this.institution) return;
    if (this.institution?.superAdmin === this.userId) {
      this.notify.display('User is already the Super Admin', 'warning');
      return;
    }

    const options = {
      institutionId: this.institution.id,
      email: this.email,
      userId: this.userId
    };

    try {
      data = await this.updateInstitutionSuperAdmin(options);
    } catch (err) {
      error = err;
    }

    this.submitting = false;

    if (error) {
      return this.notify.error(
        `Failed to update the institution super admin: ${error.message}`
      );
    }

    this.notify.success(`Institution Super Admin Updated Successfully.`);

    this.$close(data);
  }

  async loadInstitutions() {
    if (!this.institutions?.items?.length) {
      this.loadingInstitutions = true;
      try {
        await this.listInstitutions();
      } catch (err) {
        this.notify.display(err, 'error');
      } finally {
        this.loadingInstitutions = false;
      }
    }
  }
}
export default EditInstitutionSuperAdminModalController.$module;
