'use strict';

import { State, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardToolsView {
  tableProps: {}[] = [];
  tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  @Inject $rootScope;
  @Inject $state;
  @Inject $auth;
  @Inject $stateParams;
  @Inject toolUtils;
  @Inject dataModals;

  @State tools;
  @Action('tools/list') listTools;

  async $onInit() {
    var { dataModals, toolUtils } = this;

    if (this.$stateParams.loadOnClick) {
      try {
        await this.listTools();
      } catch (err) {
        this.notify.display(err, 'error');
      }
    }

    this.tableItems = this.tools.items;

    this.tableProps = [
      {
        label: 'Name',
        value: 'name'
      },
      {
        label: 'Published Commit ID',
        value: (row) => row.publishedCommitId || 'N/A'
      },
      {
        label: 'Updated At',
        value: 'updatedAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          // {
          //   label: 'Case Management Template',
          //   icon: 'briefcase',
          //   fn: val => toolUtils.loadCasePlanManager(undefined, val),
          //   hide: () => !$checkAuth('toolcreator:CommitTool')
          //   // disabled: val => !val.managedCasePlanTemplates.length
          // },
          // {
          //   label: 'Description',
          //   icon: 'info-circle',
          //   fn: val => toolUtils.openToolDescription(val),
          //   hide: () =>
          //     !$checkAuth([
          //       'toolcreator:CommitTool',
          //       'toolcreator:GetDescriptionPdf'
          //     ])
          // },
          {
            label: 'Edit Tools',
            icon: 'edit',
            fn: (val) => toolUtils.goToEditTool(val, undefined),
            hide: () =>
              !this.$auth.hasAccess([
                'toolcreator:CommitTool',
                'icpt:SetCasePlanTemplateCommitStatus',
                'icpt:TagCasePlanTemplateCommit',
                'toolcreator:AddCodingForm',
                'toolcreator:SetLiveCodingForm',
                'mcpt:SetCasePlanTemplateCommitStatus',
                'mcpt:TagCasePlanTemplateCommit'
              ])
            // disabled: val => !val.managedCasePlanTemplates.length
          },
          {
            label: 'Manage Case Plan Template',
            icon: 'briefcase',
            fn: (val) => toolUtils.editInstitutionCasePlanTemplate(val),
            hide: () =>
              !this.$auth.hasAccess([
                'icpt:CommitCasePlanTemplate',
                'icpt:TagCasePlanTemplateCommit',
                'icpt:SetCasePlanTemplateCommitStatus'
              ]) ||
              this.$auth.hasAccess([
                'toolcreator:CommitTool',
                'icpt:SetCasePlanTemplateCommitStatus',
                'icpt:TagCasePlanTemplateCommit',
                'toolcreator:AddCodingForm',
                'toolcreator:SetLiveCodingForm',
                'mcpt:SetCasePlanTemplateCommitStatus',
                'mcpt:TagCasePlanTemplateCommit'
              ]),
            disabled: (val) => !val.institutionCasePlanTemplates?.length
          },
          // {
          //   label: 'Price',
          //   icon: 'dollar',
          //   fn: val => toolUtils.openToolPrice(val),
          //   hide: () =>
          //     !$checkAuth([
          //       'toolcreator:GetTool',
          //       'toolcreator:ChangeToolPrice'
          //     ])
          // },
          {
            label: 'Delete Tool',
            icon: 'times',
            fn: (val) => dataModals.delete(val, 'tool'),
            hide: () => !this.$auth.hasAccess('toolcreator:DeleteTool')
          }
        ]
      }
    ];
  }

  toToolEditor() {
    // this.$rootScope.dsc.goToToolEditor();
  }

  toToolCreator() {
    this.$state.go('toolCreatorV2');
  }
}

export default DashboardToolsView;
