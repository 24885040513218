import angular, { IQService } from 'angular';

import * as constants from '@constants';

/**
 * ...
 */
export type IGetFormItemsService = GetFormItemsService;

declare module 'angular' {
  namespace gears {
    type IGetFormItemsService = GetFormItemsService;
  }
}

export class GetFormItemsService {
  constructor(private readonly $q: IQService) {
    'ngInject';
  }

  comparativeOperations() {
    return this.promisify(constants.COMPARATIVE_OPERATIONS);
  }

  basicOperations() {
    return this.promisify(constants.BASIC_OPERATIONS);
  }

  institutionTypes() {
    return this.promisify(constants.INSTITUTION_TYPES);
  }

  billingCycles() {
    return this.promisify(constants.BILLING_CYCLES);
  }

  billingDays() {
    return this.promisify(constants.BILLING_DAYS);
  }

  billingMethods() {
    return this.promisify(constants.BILLING_METHODS);
  }

  relations() {
    return this.promisify(constants.RELATIONS);
  }

  usStates() {
    return this.promisify(constants.US_STATES);
  }

  canadaProvinces() {
    return this.promisify(constants.CANADA_PROVINCES);
  }

  ukProvinces() {
    return this.promisify(constants.UK_PROVINCES);
  }

  auStates() {
    return this.promisify(constants.AU_STATES);
  }

  countries() {
    return this.promisify(constants.COUNTRIES);
  }

  private promisify<T>(value: T) {
    const deferred = this.$q.defer<T>();

    deferred.resolve(value);

    return deferred.promise;
  }
}

export default angular
  .module('app.get-form-items', [])
  .service('getFormItems', GetFormItemsService).name;
