'use strict';

import angular from 'angular';

export default angular
  .module('app.ngVerticalDrag', [])
  .directive('ngVerticalDrag', $document => {
    'ngInject';

    return {
      restrict: 'A',
      scope: {
        dragOptions: '=ngVerticalDrag'
      },
      link: function(scope, element, attr) {
        var startY = 0,
          y = 0;
        element.on('mousedown', function(event) {
          // Prevent default dragging of selected content
          event.preventDefault();
          startY = event.pageY - y;
          $document.on('mousemove', mousemove);
          $document.on('mouseup', mouseup);
        });

        function mousemove(event) {
          y = event.pageY - startY;
          element.css({
            top: y + 'px'
          });
        }

        function mouseup() {
          $document.unbind('mousemove', mousemove);
          $document.unbind('mouseup', mouseup);
        }
      }
    };
  }).name;
