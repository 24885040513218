'use strict';

import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class InitialPasswordView {
  resetKey: string = '';
  passwordPattern: Regex = /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/;
  password: string = '';
  passwordConfirm: string = '';

  @Inject $rootScope;
  @Inject $state;
  @Inject $ls;
  @Inject $api;
  @Inject Notification;

  $onInit() {
    this.resetKey = this.$ls.get('resetKey');

    // TODO (Alex) make a service to store resetKey and set/pull/delete in there
    if (!this.resetKey) {
      this.Notification.error({
        message: `No password reset key found. If you are having issues logging into your account please contact ${process.env.SUPPORT_EMAIL}`,
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html',
        delay: true
      });
    } else {
      this.Notification.success({
        message: 'Set your account password',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html',
        delay: true
      });
    }
  }

  // submit passwords
  async register(form) {
    if (!form.$valid) {
      return;
    }

    if (this.password !== this.passwordConfirm) {
      this.Notification.warning({
        title: 'Passwords Must Match',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });

      return;
    }

    console.log(this.password);
    console.log(this.resetKey);

    let res = await this.$api.resetPassword({
      password: this.password,
      resetKey: this.resetKey
    });

    console.log(res);

    this.Notification.clearAll();

    if (res.data.error) {
      this.Notification.error({
        title: 'Error - Password Reset',
        message: res.data.feedback,
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });

      return;
    }

    this.$ls.remove('resetKey');

    this.Notification.success({
      message: 'Password Successfully Set',
      positionX: 'center',
      templateUrl: 'assets/components/notification-template.html'
    });

    this.$state.go('login');
  }

  // Check password criteria
  checkPassword(passwordField) {
    var pass = passwordField.$modelValue;

    if (pass.length < 8) {
      passwordField.$valid = false;

      return;
    }

    var hasUpperCase = /[A-Z]/.test(pass);
    var hasLowerCase = /[a-z]/.test(pass);
    var hasNumbers = /\d/.test(pass);
    var hasNonalphas = /\W/.test(pass);

    if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
      passwordField.$valid = false;
    } else {
      passwordField.$valid = true;
    }
  }

  // Confirm password matching
  confirmPassword(pw, pwConfirm) {
    passwordField.$valid =
      pw.$modelValue === pwConfirm.$modelValue ? true : false;
  }
}

export default InitialPasswordView;
