import { indexOf } from 'lodash';

import TableViewController from '../table-view.controller';

export default class SelectionTableController extends TableViewController {
  constructor($rootScope, $scope, $element, $modals) {
    'ngInject';

    super($rootScope, $scope, $element, $modals);

    if (this.table.allowMultiple) {
      this.table.selection = [];
    }
  }

  updateSelection(item) {
    // console.log('selection made', item);

    if (!this.table.allowMultiple) {
      this.table.selection = item;
      return this.table.onSelection(item);
    }

    let i = indexOf(this.table.selection, item);

    if (i < 0) {
      this.table.selection.push(item);
    } else {
      this.table.selection.splice(i, 1);
    }

    this.table.onSelection(item);
  }

  itemInSelections(item) {
    return indexOf(this.table.selections, item) >= 0;
  }
}
