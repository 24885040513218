import angular from 'angular';

import { Controller, Inject } from '@decorators/ngCtrl';

type Method = typeof METHODS[number];

interface UrlDisplayComponent {
  text: string;
  className: string;
}

const METHODS = ['GET', 'POST', 'PUT', 'DELETE', 'HEAD'] as const;

@Controller
export default class TestView {
  @Inject private readonly $scope!: angular.IScope;

  readonly methodOptions = METHODS;

  url = 'api/this/is/a/test';
  method: Method = 'GET';
  success = true;
  arrow = '-->';
  components: UrlDisplayComponent[] = [];

  $onInit() {
    this.updateElems();

    this.$scope.$watch(() => this.url, this.updateElems.bind(this));
  }

  private updateElems() {
    this.components = this.url.split('/').flatMap((o, i) => {
      const elems: any[] = [{ text: o, className: 'word' }];

      if (i !== 0) {
        elems.unshift({ text: '.', className: 'sep' });
      }

      return elems;
    });
  }
}
