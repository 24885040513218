import angular from 'angular';

import './viewer-other.scss';

export default angular
  .module('fileViewer.otherViewer', [])
  .directive('otherViewer', () => {
    return {
      require: '^^fileViewer',
      replace: true,
      template: require('./viewer-other.html'),
      scope: true,
      link(scope, element, attrs, viewerCtrl) {
        scope.viewer = viewerCtrl;
      }
    };
  }).name;
