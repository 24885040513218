'use strict';
const angular = require('angular');
import * as _ from 'lodash';

/*@ngInject*/
export function generateReportTemplateService(Block) {
  this.generateItemDescriptions = function(template, callback) {
    let connectors = [];
    let blocks = [];
    console.log(angular.copy(template));
    if (!template.itemDescriptions) {
      let itemDescriptions = {};
      if (template.template) {
        itemDescriptions = template.template.reportCriteria.itemDescriptions.title ? template.template.reportCriteria.itemDescriptions : null;
      } else if (template.type === 'item-descriptions') {
        itemDescriptions = template;
      }

      template.itemDescriptions = new Block.ItemDescriptions({
        x: 400,
        y: 50,
        title: itemDescriptions.title || null,
        hasToggle: itemDescriptions.hasToggle || null,
        toggleDefault: itemDescriptions.toggleDefault || null,
      });
      blocks.push(template.itemDescriptions);

      // grab item description's outputNodeId to create tool editor connections for answerDescriptions
      let itemDescriptionsOutputId = _.find(template.itemDescriptions.rows, function(row) {
        return _.find(row.fields, {label: 'Descriptions'});
      }).outputNodeId;

      let nidx = 400;
      let nidy = 50;
      let ady = 200;
      // for loop through and create item descriptions
      if (itemDescriptions.descriptions) {
        _.forEach(itemDescriptions.descriptions, function(description) {
          let ansDesc;
          nidx += 100;
          description.x = nidx;
          description.y = nidy;
          description.title = description.codingFormItemRiskFactor;
          let newItemDescription = new Block.ItemDescription(description);
          newItemDescription.answerDescriptions = [];

          // grab item description's outputNodeId for Answer Descriptions row
          let descriptionOutputId = _.find(newItemDescription.rows, function(row) {
            return _.find(row.fields, {label: 'Answer Descriptions'});
          }).outputNodeId;
          if (description.answerDescriptions.length) {
            _.forEach(description.answerDescriptions, function(answerDescription) {
              ady += 150;
              answerDescription.x = nidx;
              answerDescription.y = ady;
              // check for and set title as the question's text
              answerDescription.title = answerDescription.questionText ? answerDescription.questionText : description.codingFormItemRiskFactor;
              console.log(angular.copy(answerDescription));
              ansDesc = new Block.AnswerDescriptions(answerDescription);
              newItemDescription.answerDescriptions.push(ansDesc);

              // make item description to descriptions connection
              ansDesc.rows[0].inputIds.push(descriptionOutputId);
              _.find(newItemDescription.rows, function(row) {
                return _.find(row.fields, {label: 'Answer Descriptions'});
              }).outputIds.push(ansDesc.rows[0].inputNodeId);
              connectors.push({
                inputNode: ansDesc.rows[0].inputNodeId,
                outputNode: descriptionOutputId
              });
              blocks.push(ansDesc);
            });
          }

          // make item descriptions to item description connection
          newItemDescription.rows[0].inputIds.push(itemDescriptionsOutputId);
          _.find(template.itemDescriptions.rows, function(row) {
            return _.find(row.fields, {label: 'Descriptions'});
          }).outputIds.push(newItemDescription.rows[0].inputNodeId);
          connectors.push({
            inputNode: newItemDescription.rows[0].inputNodeId,
            outputNode: itemDescriptionsOutputId
          });

          blocks.push(newItemDescription);
          template.itemDescriptions.descriptions.push(newItemDescription);
          callback({
            itemDescriptions: template.itemDescriptions,
            blocks,
            connectors
          });
        });
      }
    } else {
      callback(null, true);
    }
  };

  this.newTemplate = function(template, callback) {
    let reportTemplate = {};
    let connectors = [];
    reportTemplate.reportTemplateInformation = new Block.ReportTemplateInformation({
      x: 50,
      y: 50,
      id: template.id,
      version: template.template.version,
      name: template.template.name,
      toolId: template.toolId,
      toolCommitId: template.toolCommitId
    });
    reportTemplate.overview = new Block.Overview({
      x: 200,
      y: 50
    });
    console.log(angular.copy(template));
    if (template.doNotGenerateItemDescriptions) {
      reportTemplate.connectors = connectors;
      callback(reportTemplate);
    } else {
      this.generateItemDescriptions(template, function(response, err) {
        if (!err) {
          reportTemplate.itemDescriptions = response.itemDescriptions;
          connectors = response.connectors;
        }
        reportTemplate.connectors = connectors;
        callback(reportTemplate);
      });
    }
  };
}

export default angular.module('app.generate-report-template', [])
  .service('generateReportTemplate', generateReportTemplateService)
  .name;
