import { gears } from 'angular';
import { uniqBy } from 'lodash';

declare global {
  interface Function {
    $actions?: string[];
    $modifier?: unknown;
  }
}

/**
 * ...
 */
interface DisplayTableState {
  sortedCol: number;
  searchText: string;
}

/**
 * ...
 */
interface DataState {
  loading: boolean;
  items: {}[];
  tableProps: DisplayTableState;
}

/**
 * ...
 *
 * @param target ...
 * @param prop ...
 * @param descr ...
 * @return ...
 */
export function ActionConfig(
  target: Object,
  prop: string,
  descr: PropertyDescriptor
) {
  if (!target.constructor.$actions) {
    target.constructor.$actions = [];
  }

  target.constructor.$actions.push(prop);

  return descr;
}

/**
 * ...
 *
 * @param StoreClass ...
 * @return ...
 */
export function AppDataStore(StoreClass: ObjectConstructor) {
  const $actions = StoreClass.$actions || [];
  const $modifier = StoreClass.$modifier;

  const AppData = class DataStore extends StoreClass {
    $$itemMap: GenericObject = {};

    state: DataState = {
      loading: false,
      items: [],
      tableProps: { sortedCol: 0, searchText: '' }
    };

    getters = {
      find: (state: DataState) => (id) =>
        state.items.find((item) => item.id === id)
    };

    actions: GenericObject = {};
    mutations: GenericObject = {
      set(state, payload) {
        payload = Array.isArray(payload) ? payload : [payload];

        state.items = [...payload];
      },
      add(state, payload) {
        payload = Array.isArray(payload) ? payload : [payload];

        state.items = uniqBy([...payload, ...state.items], 'id');
      },
      update({ items }, payload) {
        //
      },
      remove({ items }, payload) {
        //
      },
      clear({ items }, payload) {
        items.splice(0, items.length);
      },
      setTableProps({ tableProps }, { sortedCol, searchText }) {
        if (sortedCol !== undefined) {
          tableProps.sortedCol = !isNaN(sortedCol) ? sortedCol : 0;
        }

        if (searchText !== undefined) {
          tableProps.searchText = searchText ? searchText : '';
        }
      },
      //
      setProps(state, props = {}) {
        for (let i in props) {
          if (i in state) {
            state[i] = props[i];
          }
        }
      }
    };
    modules: GenericObject = {};

    constructor($api: gears.IApiService) {
      super();

      for (let action of $actions) {
        let config = this[action];

        if (typeof config == 'function') {
          config = { request: config };
        }

        const dispatch = async (context, payload) => {
          // Params overload
          if ('params' in config) {
            payload = config.params(context, payload);
          }

          // Validation check
          let isValid =
            'validate' in config ? config.validate(context, payload) : true;

          if (!isValid) {
            console.log('[ngStore:action] The request was not valid.');

            return;
          }

          // Build request function
          let request = 'request' in config ? config.request($api) : null;
          if (!request || typeof request != 'function') {
            throw new Error('');
          }

          context.commit('setProps', { loading: true });

          let data = [];

          try {
            let res = await request(payload);
            data = res.data ? res.data.data || res.data : [];
          } catch (err) {
            // console.log('HERE');
            context.commit('setProps', { loading: false });
            throw err;
          }

          if ($modifier) {
            data = $modifier(data);
          }

          context.commit('add', data);

          if ('callback' in config && typeof config.callback == 'function') {
            await config.callback(context, data);
          }

          context.commit('setProps', { loading: false });

          return data;
        };

        this.actions[action] = dispatch;
      }
    }

    /**
     * ...
     */
    $$updateItemMap() {
      this.$$itemMap = {};

      this.state.items.forEach((item) => {
        this.$$itemMap[item.id] = item;
      });

      console.log('map updated', this.$$itemMap);
    }
  };

  AppData.$inject = ['$api'];

  return AppData;
}

export default AppDataStore;
