'use strict';

import angular from 'angular';
import * as _ from 'lodash';

const ProfileSections = {
  recentActivity: 0,
  casePlans: 1,
  details: 2
};

// import { State, Mutation } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ClientProfileModalController extends Modal {
  client: {};
  activeSection;

  @Inject $scope;
  @Inject $filter;
  @Inject getFormItems;
  @Inject getItems;
  @Inject $http;
  @Inject Notification;
  @Inject ItemTable;
  @Inject TableViewDataTypes;
  @Inject Util;

  get modalConfig() {
    return this.$scope.$parent.modal;
  }

  get clientDOB() {
    // return new Date(this.client.dob).toLocaleDateString('en-US');
    return this.$filter('dynamicDate')(
      this.client.dob,
      'MM/dd/yyyy',
      'UTC'
    );
  }

  get clientAge() {
    // return Math.floor(new Date(this.client.dob).getTime() * 3.1689E-11);
    var ageDate = new Date(Date.now() - new Date(this.client.dob).getTime()); //
    return Math.floor(ageDate.getUTCFullYear() - 1970);
  }

  async $setup() {
    // this.client = angular.copy(this.modalConfig.item.client);
    this.client = this.$scope.$parent.modal
      ? angular.copy(this.$scope.$parent.modal.item.client)
      : angular.copy(this.dsc.selectedClient);

    this.offenseClassifications = await this.Util.getOffenseClassifications(this.client.institution.id);

    this.activeSection = ProfileSections.recentActivity;

    function activityData(date, tool, status, progress, notes, actions) {
      // this.date = new Date(date).toLocaleDateString('en-US');
      this.date = this.$filter('dynamicDate')(date, 'MM/dd/yyyy');
      this.tool = tool;
      this.status = status;
      this.progress = progress;
      this.notes = notes;
      this.actions = actions;
    }

    function generateCasePlan(item) {
      console.log('Generate Case Plan', item);
    }

    function generateReport(item) {
      console.log('Generate Report', item);
    }

    function viewEvaluation(item) {
      console.log('View Evaluation', item);
    }

    function continueEvaluation(item) {
      console.log('Continue Evaluation', item);
    }

    function revertEvaluation(item) {
      console.log('Revert Evaluation', item);
    }

    // push evaluations to activity table
    let activityTableItems = [];
    let evalStatuses = {
      0: 'Not Started',
      1: 'In Progress',
      2: 'Completed'
    };

    let evalActions = {
      NOT_STARTED: [
        {
          icon: 'fa-edit',
          actionFunction: function(item) {
            continueEvaluation(item);
          },
          tooltip: 'Continue Evaluation'
        }
      ],
      IN_PROGRESS: [
        {
          icon: 'fa-eye',
          actionFunction: function(item) {
            viewEvaluation(item);
          },
          tooltip: 'View Evaluation'
        },
        {
          icon: 'fa-edit',
          actionFunction: function(item) {
            continueEvaluation(item);
          },
          tooltip: 'Continue Evaluation'
        }
      ],
      COMPLETED: [
        {
          icon: 'fa-briefcase',
          actionFunction: function(item) {
            generateCasePlan(item);
          },
          tooltip: 'Generate Case Plan'
        },
        {
          icon: 'fa-clipboard',
          actionFunction: function(item) {
            generateReport(item);
          },
          tooltip: 'Generate Report'
        },
        {
          icon: 'fa-eye',
          actionFunction: function(item) {
            viewEvaluation(item);
          },
          tooltip: 'View Evaluation'
        },
        {
          icon: 'fa-recycle',
          actionFunction: function(item) {
            revertEvaluation(item);
          },
          tooltip: 'Revert Evaluation'
        }
      ]
    };

    _.forEach(this.client.evaluations, evaluation => {
      activityTableItems.push(
        new activityData(
          evaluation.updatedAt,
          evaluation.tool ? evaluation.tool.name : '',
          evaluation.status,
          0,
          '',
          evalActions[evaluation.status]
        )
      );
    });

    this.activityTable = new this.ItemTable({
      title: ' ',
      items: activityTableItems
    })
      .column({
        title: 'Date',
        key: 'date',
        sortable: true
      })
      .column({
        title: 'Tool',
        key: 'tool',
        sortable: true
      })
      .column({
        title: 'Status',
        key: 'status',
        template: 'EvalStatus',
        sortable: true
      })
      .column({
        title: 'Progress',
        key: 'progress',
        template: 'ClientProgress',
        sortable: true
      })
      .column({
        title: 'Notes',
        key: 'notes'
      })
      .column({
        title: 'Actions',
        template: 'ActionItem',
        key: 'actions'
      });

    this.ohTable = new this.ItemTable({
      title: 'Offender History',
      hasSearchBar: false,
      addAction: async function() {
        let tools = _.filter(this.metaInformation.tools, {
          requiresOffenderHistory: true
        });
        let toolSelect = await this.$modals.util.generalChoice(
          tools,
          undefined,
          'SELECT TOOL'
        )();
        if (!toolSelect) return;

        let toolCommits = await this.getItems.toolCommits(toolSelect);
        let toolCommitId = _.find(toolCommits, { status: 'Live' });
        if (!toolCommitId) toolCommitId = _.maxBy(toolCommits, 'createdAt');
        if (!toolCommitId) return;
        toolCommitId = toolCommitId.id;

        // let toolData = await this.$http.get(
        //   `/api/tool-commits/${toolCommitId}/data`
        // );
        let toolData = await this.getItems.toolCommitFile({
          toolId: toolSelect.id,
          id: toolCommitId
        });
        if (!toolData) return;

        // let offenseClassifications = await this.Util.getOffenseClassifications();
        // console.log('this.metaInformation.offenseClassifications: ', this.metaInformation.offenseClassifications);

        let newOffenderHistory = await this.$modals.evaluation.offenderHistory(
          this.metaInformation.client,
          toolData,
          this.metaInformation.offenseClassifications
        );
        if (!newOffenderHistory) return;

        this.item(newOffenderHistory);
        this.metaInformation.client.offenderHistory.push(newOffenderHistory);
      },
      editAction: async function(item) {
        let originalItem = angular.copy(item);
        originalItem.loading = true;
        if (item.item) item = item.item;
        item.offenderHistory = await this.getItems.offenderHistoryData(
          this.metaInformation.client,
          item
        );
        item.offenderHistory.id = item.id;
        let tool = {
          offenderHistory: item.offenderHistory,
          id: item.toolId,
          name: item.toolName
        };

        originalItem.loading = false;
        // let offenseClassifications = await this.Util.getOffenseClassifications();

        let updatedOffenderHistory = await this.$modals.evaluation.offenderHistory(
          this.metaInformation.client,
          tool,
          this.metaInformation.offenseClassifications
        );
        if (!updatedOffenderHistory) return;

        let tableItem = _.find(this.items, item => {
          return item.id === updatedOffenderHistory.id;
        });
        if (tableItem) {
          tableItem.item.offenderHistory =
            updatedOffenderHistory.offenderHistory;
          tableItem.item.updatedAt = updatedOffenderHistory.updatedAt;
        }
        if (
          _.indexOf(this.metaInformation.client.offenderHistory, {
            id: item.id
          }) > -1
        ) {
          this.metaInformation.client.offenderHistory[
            _.indexOf(this.metaInformation.client.offenderHistory, {
              id: item.id
            })
          ] = tableItem.item;
        }
      },
      metaInformation: {
        tools: this.dsc.tools,
        client: this.client,
        offenseClassifications: this.offenseClassifications
      }
    })
      .column({
        title: 'Tool',
        key: 'toolName',
        sortable: true
      })
      .column({
        title: 'Created',
        key: 'createdAt',
        type: 'dateTime',
        sortable: true
      })
      .column({
        title: 'Updated',
        key: 'updatedAt',
        type: 'dateTime',
        sortable: true
      });

    this.ocTable = new this.ItemTable({
      title: 'Offense Classification',
      items: [
        {
          offence: 'Armed robbery (1)',
          doc: '02/02/14',
          disposition: '2 years',
          institution: 'Mountain C.C.',
          comments: 'Placed in maximum security'
        }
      ]
    })
      .column({
        title: 'Offence',
        key: 'offence'
      })
      .column({
        title: 'Date of Conviction',
        key: 'doc'
      })
      .column({
        title: 'Disposition',
        key: 'disposition'
      })
      .column({
        title: 'Institution',
        key: 'institution'
      })
      .column({
        title: 'Comments',
        key: 'comments'
      });

    this.priorOffencesTable = new this.ItemTable({
      title: 'Offense Classification',
      items: [
        {
          offence: 'B&E (5)',
          doc: '11/10/02',
          disposition: '2 months (concurrent)',
          institution: 'Fielding Institution',
          comments: ''
        },
        {
          offence: 'Armed robbery (1)',
          doc: '02/02/14',
          disposition: '2 years',
          institution: 'Mountain C.C.',
          comments: 'Placed in maximum security'
        }
      ]
    })
      .column({
        title: 'Offence',
        key: 'offence'
      })
      .column({
        title: 'Date of Conviction',
        key: 'doc'
      })
      .column({
        title: 'Disposition',
        key: 'disposition'
      })
      .column({
        title: 'Institution',
        key: 'institution'
      })
      .column({
        title: 'Comments',
        key: 'comments'
      });
  }

  async editClient(client) {
    let updatedClient = await this.dsc.openEditClient(client);
    _.forEach(this.client, (value, key) => {
      if (this.client[key] !== updatedClient[key])
        this.client[key] = updatedClient[key];
    });
  }
}

export default angular
  .module('app.clientProfileModal', [])
  .directive('clientProfileModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./client-profile.html'),
    controller: ClientProfileModalController,
    controllerAs: 'vm'
  })).name;
