import { Controller, Inject } from '@angular';

@Controller
class ForgotPasswordView {
  resetKey: string = '';
  validKey: boolean = false;
  pw: string = '';
  pwConfirm: string = '';
  userId: string = '';

  @Inject $api;
  @Inject Notification;

  async submitPWResetKey(form) {
    if (!form.$valid) {
      this.Notification.warning({
        title: 'Reset Key Required',
        message: 'You must enter a valid reset key to continue.',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });

      return;
    }

    let res = await this.$api.passwordReset.submitKey(this.resetKey);

    console.log(res);
    // TODO (Alex) should get back userId
    // this.userId = response.data.data.id;
  }

  async submitNewPW(form) {
    if (!form.$valid || this.pwConfirm !== this.pw) {
      this.Notification.error({
        title: 'Passwords Must Match',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });

      return;
    }

    // TODO (Alex & Eric) what route do I call for submitting userId, newPW, and pwResetKey
    // let res = await this.$api.passwordReset.submitNewPassword({
    //   userId: this.userId,
    //   newPW: this.pw,
    //   psResetKey: this.resetKey,
    // });
    //
    // console.log(res);
  }

  checkPassword(passwordField) {
    var pass = passwordField.$modelValue;

    if (pass.length < 8) {
      passwordField.$valid = false;

      return;
    }

    var hasUpperCase = /[A-Z]/.test(pass);
    var hasLowerCase = /[a-z]/.test(pass);
    var hasNumbers = /\d/.test(pass);
    var hasNonalphas = /\W/.test(pass);

    if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
      passwordField.$valid = false;
    } else {
      passwordField.$valid = true;
    }
  }

  confirmPassword(passwordField) {
    var passConfirm = passwordField.$modelValue;

    passwordField.$valid = passConfirm === this.pw ? true : false;
  }
}

export default ForgotPasswordView;
