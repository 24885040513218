'use strict';

import * as _ from 'lodash';

import { State } from 'angular-store';
import { Controller } from '@/decorators/ngCtrl';

@Controller
class PricingView {
  constructor() {
    'ngInject';
  }

  getType(item) {
    let type = typeof item;

    if (type === 'object' && item) {
      if (item.length) {
        type = 'array';
      }
    }

    return type;
  }

  sortAlpha(data) {
    if (!data) {
      return;
    }

    let sortedKeys = Object.keys(data).sort();
    let alphaData = {};

    _.forEach(sortedKeys, function(key) {
      alphaData[key] = data[key];
    });

    return alphaData;
  }

  compareToolJsons(a, b) {
    var compareArrays = function(a1, b1) {
      if (a1.length !== b1.length) return false;
      if (a1.length === 0 && b1.length !== 0) return false;

      if (a1.length > 0) {
        for (let i = 0; i < a1.length; i++) {
          if (typeof a1[i] === 'object' && !Array.isArray(a1[i])) {
            // a1 items are objects
            for (let key in a1[i]) {
              if (a1[i][key] !== b1[i][key]) return false;
            }
          } else if (a1[i] !== b1[i]) {
            return false;
          }
        }
      }

      return true;
    };

    // compare wizard answers together
    let compareWA = function(wa1, wa2, callback) {
      // console.log('wa1', wa1);
      // console.log('wa2', wa2);

      let setMismatch = function(key) {
        if (!wa1.mismatch) {
          wa1.mismatch = [key];
        } else {
          wa1.mismatch.push(key);
        }

        if (!wa2.mismatch) {
          wa2.mismatch = [key];
        } else {
          wa2.mismatch.push(key);
        }
      };

      if (wa1.id !== wa2.id) setMismatch('id');
      if (wa1.text !== wa2.text) setMismatch('text');
      if (wa1.spawnModal !== wa2.spawnModal) setMismatch('spawnModal');
      if (wa1.modalText !== wa2.modalText) setMismatch('modalText');

      if (wa1.resolveVariables === null && wa2.resolveVariables !== false)
        setMismatch('resolveVariables');

      if (!wa1.hasOwnProperty('manipulateVariables')) {
        if (wa2.manipulateVariables.length) setMismatch('manipulateVariables');
      } else if (
        !compareArrays(wa1.manipulateVariables, wa2.manipulateVariables)
      ) {
        setMismatch('manipulateVariables');
      }

      if (typeof wa1.answerScore === 'string') {
        if (wa2.answerScore !== null) setMismatch('answerScore');
      } else if (typeof wa1.answerScore === 'number') {
        if (wa1.answerScore !== wa2.answerScore) setMismatch('answerScore');
      }

      if (wa1.nextWizardQuestionId.length === 0) {
        if (wa2.nextWizardQuestionId !== null)
          setMismatch('nextWizardQuestionId');
      } else if (wa1.nextWizardQuestionId !== wa2.nextWizardQuestionId) {
        setMismatch('nextWizardQuestionId');
      }

      if (typeof wa1.increment === 'string') {
        if (wa2.increment !== null) setMismatch('increment');
      } else if (typeof wa1.increment === 'number') {
        if (wa1.increment !== wa2.increment) setMismatch('increment');
      }

      callback({ wa1, wa2 });
    };

    // compare wizard questions together
    let compareWQ = function(wq1, wq2, callback) {
      // console.log('wq1', wq1);
      // console.log('wq2', wq2);
      let setMismatch = function(key) {
        if (!wq1.mismatch) {
          wq1.mismatch = [key];
        } else {
          wq1.mismatch.push(key);
        }

        if (!wq2.mismatch) {
          wq2.mismatch = [key];
        } else {
          wq2.mismatch.push(key);
        }
      };

      if (wq1.id !== wq2.id) setMismatch('id');
      if (
        wq1.pdfItemHelpBookmark.length === 0 &&
        wq2.pdfItemHelpBookmark !== null
      )
        setMismatch('pdfItemHelpBookmark');
      if (wq1.questionText !== wq2.questionText) setMismatch('questionText');
      if (wq1.questionType !== wq2.questionType) setMismatch('questionType');

      if (!wq1.hasOwnProperty('multipleAnswerConditions')) {
        if (wq2.multipleAnswerConditions.length)
          setMismatch('multipleAnswerConditions');
      } else if (
        !compareArrays(
          wq1.multipleAnswerConditions,
          wq2.multipleAnswerConditions
        )
      ) {
        setMismatch('multipleAnswerConditions');
      }

      callback({ wq1, wq2 });
    };

    //compare wizard calculations
    let compareCalcs = function(calc1, calc2, callback) {
      // console.log('calc1', calc1);
      // console.log('calc2', calc2);
      let setMismatch = function(key) {
        if (!calc1.mismatch) {
          calc1.mismatch = [key];
        } else {
          calc1.mismatch.push(key);
        }

        if (!calc2.mismatch) {
          calc2.mismatch = [key];
        } else {
          calc2.mismatch.push(key);
        }
      };

      if (!compareArrays(calc1.conditions, calc2.conditions))
        setMismatch('conditions');
      if (!compareArrays(calc1.resolutions, calc2.resolutions))
        setMismatch('resolutions');
      if (!compareArrays(calc1.variables, calc2.variables))
        setMismatch('variables');

      callback({ calculationA: calc1, calculationB: calc2 });
    };

    //compare questions
    let compareQ = function(q1, q2, callback) {
      // console.log('q1', q1);
      // console.log('q2', q2);
      let setMismatch = function(key) {
        if (!q1.mismatch) {
          q1.mismatch = [key];
        } else {
          q1.mismatch.push(key);
        }

        if (!q2.mismatch) {
          q2.mismatch = [key];
        } else {
          q2.mismatch.push(key);
        }
      };

      if (q1.id !== q2.id) setMismatch('id');
      if (q1.pdfItemHelpBookmark !== q2.pdfItemHelpBookmark)
        setMismatch('pdfItemHelpBookmark');
      if (parseInt(q1.pdfItemPage, 10) !== parseInt(q2.pdfItemPage, 10))
        setMismatch('pdfItemPage');
      if (q1.riskFactor !== q2.riskFactor) setMismatch('riskFactor');

      callback({ q1, q2 });
    };

    a = _.cloneDeep(a);
    b = _.cloneDeep(b);
    this.compare = [];
    let indentSpacing = 25;

    if (typeof a === 'object' && a.length) {
      // we're dealing with an array
      let i;
      let j;
      let k;

      for (i = 0; i < a.length; i++) {
        // coding form items
        let codesAndScoreA = a[i].codesAndScore;
        let codesAndScoreB = b[i].codesAndScore;
        let itemWizardA = a[i].itemWizard;
        let itemWizardB = b[i].itemWizard;

        Reflect.deleteProperty(a[i], 'codesAndScore');
        Reflect.deleteProperty(b[i], 'codesAndScore');
        Reflect.deleteProperty(a[i], 'itemWizard');
        Reflect.deleteProperty(b[i], 'itemWizard');

        compareQ(a[i], b[i], res => {
          this.compare.push({
            a: this.sortAlpha(res.q1),
            b: this.sortAlpha(res.q2)
          });
        });

        if (!compareArrays(codesAndScoreA, codesAndScoreB)) {
          codesAndScoreA.mismatch = ['codesAndScore'];
          codesAndScoreB.mismatch = ['codesAndScore'];
        }
        this.compare.push({
          indentSpacing,
          a: this.sortAlpha(codesAndScoreA),
          b: this.sortAlpha(codesAndScoreB)
        });

        // break out item wizard
        if (itemWizardA.calculation) {
          compareCalcs(
            itemWizardA.calculation,
            itemWizardB.calculation,
            res => {
              this.compare.push({
                indentSpacing: indentSpacing * 2,
                a: res.calculationA ? this.sortAlpha(res.calculationA) : null,
                b: res.calculationB ? this.sortAlpha(res.calculationB) : null
              });
              Reflect.deleteProperty(itemWizardA, 'calculation');
              Reflect.deleteProperty(itemWizardB, 'calculation');
            }
          );
        }

        if (itemWizardA.references) {
          if (!compareArrays(itemWizardA.references, itemWizardB.references)) {
            itemWizardA.references.mismatch = ['references'];
            itemWizardB.references.mismatch = ['references'];
          }
          this.compare.push({
            indentSpacing: indentSpacing * 2,
            a: itemWizardA.references
              ? this.sortAlpha(itemWizardA.references)
              : null,
            b: itemWizardB.references
              ? this.sortAlpha(itemWizardB.references)
              : null
          });
          Reflect.deleteProperty(itemWizardA, 'references');
          Reflect.deleteProperty(itemWizardB, 'references');
        }

        for (j = 0; j < itemWizardA.wizardQuestions.length; j++) {
          let waA = itemWizardA.wizardQuestions[j].wizardAnswers;
          let waB = itemWizardB.wizardQuestions[j].wizardAnswers;

          Reflect.deleteProperty(
            itemWizardA.wizardQuestions[j],
            'wizardAnswers'
          );

          Reflect.deleteProperty(
            itemWizardB.wizardQuestions[j],
            'wizardAnswers'
          );

          compareWQ(
            itemWizardA.wizardQuestions[j],
            itemWizardB.wizardQuestions[j],
            res => {
              this.compare.push({
                indentSpacing: indentSpacing * 2,
                a: this.sortAlpha(res.wq1),
                b: res.wq2 ? this.sortAlpha(res.wq2) : 'NO MATCH FOUND'
              });
            }
          );

          for (k = 0; k < waA.length; k++) {
            compareWA(waA[k], waB[k], res => {
              // console.log(res.wa1);
              // console.log(res.wa2);
              this.compare.push({
                indentSpacing: indentSpacing * 3,
                a: this.sortAlpha(res.wa1),
                b: res.wa2 ? this.sortAlpha(res.wa2) : 'NO MATCH FOUND'
              });
            });
          }
        }
      }
    }
    // console.log('this.compare', this.compare);
  }

  compareTemplateJsons(a, b) {
    var compareArrays = function(a1, b1) {
      console.log('a1', a1);
      console.log('b1', b1);

      if (a1.length !== b1.length) return false;
      if (a1.length === 0 && b1.length !== 0) return false;

      if (a1.length > 0) {
        for (let i = 0; i < a1.length; i++) {
          if (typeof a1[i] === 'object' && !Array.isArray(a1[i])) {
            // a1 items are objects
            for (let key in a1[i]) {
              if (a1[i][key] !== b1[i][key]) return false;
            }
          } else if (a1[i] !== b1[i]) {
            return false;
          }
        }
      }

      return true;
    };

    a = _.cloneDeep(a);
    b = _.cloneDeep(b);

    console.log('a', a);
    console.log('b', b);

    this.compare = [];
    let indentSpacing = 25;
    let i;
    let k;
    let l;
    let m;

    //region Item Descriptions

    let compareCFI = function(cfi1, cfi2, callback) {
      // console.log('cfi1', cfi1);
      // console.log('cfi2', cfi2);
      let setMismatch = function(key) {
        if (!cfi1.mismatch) {
          cfi1.mismatch = [key];
        } else {
          cfi1.mismatch.push(key);
        }

        if (!cfi2.mismatch) {
          cfi2.mismatch = [key];
        } else {
          cfi2.mismatch.push(key);
        }
      };

      if (cfi1.codingFormItemId !== cfi2.codingFormItemId)
        setMismatch('codingFormItemId');
      if (cfi1.codingFormItemRiskFactor !== cfi2.codingFormItemRiskFactor)
        setMismatch('codingFormItemRiskFactor');
      if (cfi1.codingFormItemDescription !== cfi2.codingFormItemDescription)
        setMismatch('codingFormItemDescription');
      if (cfi1.includeAllAnswers !== cfi2.includeAllAnswers)
        setMismatch('includeAllAnswers');
      if (cfi1.concatenate !== cfi2.concatenate) setMismatch('concatenate');

      callback({ cfi1, cfi2 });
    };

    let compareDescription = function(desc1, desc2, callback) {
      console.log('desc1', desc1);
      console.log('desc2', desc2);

      let setMismatch = function(key) {
        if (!desc1.mismatch) {
          desc1.mismatch = [key];
        } else {
          desc1.mismatch.push(key);
        }

        if (!desc2.mismatch) {
          desc2.mismatch = [key];
        } else {
          desc2.mismatch.push(key);
        }
      };

      if (desc1.id !== desc2.id) setMismatch('id');
      if (desc1.questionText !== desc2.questionText)
        setMismatch('questionText');
      // if (!compareArrays(desc1.answers, desc2.answers)) setMismatch('answers');

      callback({ desc1, desc2 });
    };

    let compareAnswers = function(ans1, ans2, callback) {
      // console.log('ans1', ans1);
      // console.log('ans2', ans2);
      let setMismatch = function(key) {
        if (!ans1.mismatch) {
          ans1.mismatch = [key];
        } else {
          ans1.mismatch.push(key);
        }

        if (!ans2.mismatch) {
          ans2.mismatch = [key];
        } else {
          ans2.mismatch.push(key);
        }
      };

      if (ans1.id !== ans2.id) setMismatch('id');
      if (ans1.questionText !== ans2.questionText) setMismatch('questionText');

      callback({ ans1, ans2 });
    };

    let idA = a.itemDescriptions;
    let idB = b.itemDescriptions;

    let aDesc = idA.descriptions;
    let bDesc = idB.descriptions;

    Reflect.deleteProperty(idA, 'descriptions');
    Reflect.deleteProperty(idB, 'descriptions');

    this.compare.push({
      a: this.sortAlpha(idA),
      b: this.sortAlpha(idB)
    });

    for (i = 0; i < aDesc.length; i++) {
      let aAnsDesc = aDesc[i].answerDescriptions;
      Reflect.deleteProperty(aDesc[i], 'answerDescriptions');
      let bAnsDesc = bDesc[i].answerDescriptions;
      Reflect.deleteProperty(bDesc[i], 'answerDescriptions');
      compareCFI(aDesc[i], bDesc[i], res => {
        this.compare.push({
          indentSpacing,
          a: this.sortAlpha(res.cfi1),
          b: this.sortAlpha(res.cfi2)
        });
      });

      for (k = 0; k < aAnsDesc.length; k++) {
        let answersA = aAnsDesc[k].answers;
        Reflect.deleteProperty(aAnsDesc[k], 'answers');
        let answersB = bAnsDesc[k].answers;
        Reflect.deleteProperty(bAnsDesc[k], 'answers');
        compareDescription(aAnsDesc[k], bAnsDesc[k], res => {
          this.compare.push({
            indentSpacing: indentSpacing * 2,
            a: this.sortAlpha(res.desc1),
            b: this.sortAlpha(res.desc2)
          });
        });

        for (l = 0; l < answersA; l++) {
          compareAnswers(answersA[l], answersB[l], res => {
            this.compare.push({
              indentSpacing: indentSpacing * 3,
              a: this.sortAlpha(res.ans1),
              b: this.sortAlpha(res.ans2)
            });
          });
        }
      }
    }

    //endregion Item Descriptions
  }
}

export default PricingView;
