import angular from 'angular';

import { Controller, Inject, Watch } from '@/decorators/ngCtrl';
import { State, Getter } from 'angular-store';
import Modal from '@/mixins/modal.mixin';
import { find } from 'lodash';
import { resourceLimits } from 'worker_threads';

@Controller
class CalculateEvaluationStatsModalController extends Modal {
  selectedInstitutions: Array<Institution> = [];
  selectedTools: Array<Tool> = [];
  startDate: Date = new Date();
  endDate: Date = new Date();
  institutionFilter: String = '';

  @Inject $scope;
  @Inject $store;
  @Inject $http;
  @Inject $filter;
  @Inject $rootScope;
  @Inject $auth;
  @Inject utils;
  @Inject $api;
  @Inject $cookies;

  @State(({ institutions }) => institutions.items) institutions;
  @State(({ tools }) => tools.items) tools;

  @Getter activeInstId;
  @Getter isAdmin;

  get numberOfToolsSelected() {
    return this.selectedTools.filter(({ selected }) => selected).length;
  }

  get numberOfInstitutionsSelected() {
    return this.selectedInstitutions.filter(({ selected }) => selected).length;
  }

  async $setup() {
    this.loadingData = true;

    await Promise.all([
      this.$store.dispatch('tools/list'),
      this.$store.dispatch('institutions/getAll')
    ]);

    this.selectedInstitutions = this.institutions.map((item) => ({
      label: item.name,
      value: item.id,
      selected: false
    }));

    this.selectedTools = this.tools
      .filter((item) => item.publishedCommitId)
      .map((item) => ({
        label: item.name,
        value: item.id,
        selected: false
      }));

    this.loadingData = false;

    this.$scope.$apply();
  }

  selectAll(list, institution) {
    let instFilter = this.institutionFilter;
    let checkItem = function (item) {
      let itemLower = item.toLowerCase();
      return itemLower.includes(instFilter.toLowerCase());
    };
    list.forEach((item) => {
      if (institution && instFilter?.length && checkItem(item.label)) {
        item.selected = true;
      } else if (institution && instFilter?.length && !checkItem(item.label)) {
        item.selected = false;
      } else {
        item.selected = true;
      }
    });
  }

  selectNone(list) {
    list.forEach((item) => (item.selected = false));
  }

  validateSelections() {
    let instFilter = this.institutionFilter;
    let checkItem = function (item) {
      let itemLower = item.toLowerCase();
      return itemLower.includes(instFilter.toLowerCase());
    };
    this.selectedInstitutions.forEach((item) => {
      if (this.institutionFilter?.length && !checkItem(item.label))
        item.selected = false;
    });
  }

  async sendRequest() {
    this.processing = true;

    let res;

    this.loadingData = true;

    try {
      this.utils.notify('success', 'Request Sent');
      let institutions = this.selectedInstitutions
        .filter(({ selected }) => selected)
        .map(({ value }) => value);
      let tools = this.selectedTools
        .filter(({ selected }) => selected)
        .map(({ value }) => value);

      if (institutions.length > 25) {
        this.utils.notify(
          'warning',
          `Requesting ${institutions.length} institutions at a time might take a while. Please be patient.`
        );
      }

      res = await this.$api.GM.calculateEvaluationStats({
        institutions,
        tools,
        startDate: this.startDate.toString(),
        endDate: this.endDate.toString()
      });

      this.loadingData = false;
    } catch (err) {
      this.processing = false;
      this.utils.notify('error', 'Error - Sending Request');
      this.loadingData = false;

      return;
    }

    this.utils.notify('success', 'Evaluation Stats Done Processing');
    this.processing = false;

    // parse each institution's stat months into one sum
    let insts = [];
    for (const inst of res.data) {
      let result = {
        id: inst.id,
        name: inst.name,
        stats: [],
        deletedEvaluations: 0
      };

      result.stats = this.selectedTools
        .filter(({ selected }) => selected)
        .map((tool) => {
          return {
            tool: tool.value,
            name: tool.label,
            completedEvaluations: 0,
            inProgressEvaluations: 0
          };
        });

      for (const month of inst.stats) {
        if (!month || !month.details) continue;
        if (month.details.deleted)
          result.deletedEvaluations += month.details.deleted;
        if (!month.details?.tools) continue;

        for (const resStat of result.stats) {
          const currentTool = month.details.tools[resStat.tool];

          if (!currentTool) continue;

          resStat.completedEvaluations += currentTool.completedEvaluations;
          resStat.inProgressEvaluations += currentTool.inProgressEvaluations;
          resStat.inProgressEvaluations += currentTool.notStartedEvaluations;
        }
      }

      insts.push(result);
    }

    const statsReport = {
      startDate: this.startDate,
      endDate: this.endDate,
      institutions: insts
    };

    // this.$cookies.putObject('evalStats', res.data);
    localStorage.setItem('evalStats', JSON.stringify(statsReport));
    this.$close(statsReport);
  }
}

export default angular
  .module('app.calculateEvaluationStatsModalController', [])
  .directive('calculateEvaluationStatsModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./calculate-evaluation-stats-modal.html'),
    controller: CalculateEvaluationStatsModalController,
    controllerAs: 'vm'
  })).name;
