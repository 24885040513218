import angular, { Controller, Inject, State } from '@angular';
import Modal from '@mixins/modal.mixin';

@Controller
class LoginModalController extends Modal {
  forgotPassForm: boolean = false;
  confirmPassForm: boolean = false;
  submitted: boolean = false;
  user: {} = {
    email: '',
    password: ''
  };
  resetPass: {} = {
    code: null,
    password: null,
    confirmPassword: null
  };
  errors: {} = {
    login: undefined,
    forgotPass: undefined,
    confirmPass: undefined,
    passwordMismatch: undefined
  };

  @Inject $http;
  @Inject $auth;
  @Inject $scope;
  @Inject $state;
  @Inject $api;
  @Inject $modals;
  @Inject notify;
  @Inject utils;

  @State me;

  async login1(form, event) {
    this.processing = true;
    if (!form.$valid) {
      return;
    }

    let email = angular.copy(this.user.email);
    email = email.toLowerCase();

    try {
      let user = await this.$auth.login(
        {
          email,
          password: this.user.password
        },
        event
      );

      // close login modal
      this.$close();

      // this.notify successful login
      this.notify.display(`Welcome to MHS GEARS, ${this.me.fName}`, 'success');

      this.processing = false;
      this.$state.go('dashboardHome');
    } catch (err) {
      // this.notify.display(err, 'error');
      this.processing = false;
    }
  }

  async forgotPass1(form) {
    this.processing = true;
    if (!form.$valid) {
      return;
    }

    // this.forgotPassForm = false;
    // this.confirmPassForm = true;
    // return;

    try {
      let email = angular.copy(this.user.email);
      email = email.toLowerCase();
      let res = await this.$api.base.passwordReset({ email });

      this.forgotPassForm = false;
      this.confirmPassForm = true;

      this.notify.display(
        'If you have entered a valid/registered email account you will receive an email shortly with instructions to reset your password.',
        'success',
        true,
        'Password Reset Request Submitted'
      );
      this.processing = false;
    } catch (err) {
      this.notify.display(err, 'error');
      this.processing = false;
    }
  }

  async submitConfirmPass(form) {
    this.processing = true;
    this.errors.passwordMismatch = null;

    if (!form.$valid) {
      return;
    }

    if (
      this.resetPass.newPassword.$modelValue !=
      this.resetPass.confirmPassword.$modelValue
    ) {
      this.errors.passwordMismatch = true;
      this.processing = false;
      return;
    }

    let email = angular.copy(this.user.email);
    email = email.toLowerCase();

    try {
      await this.$api.user.resetPassword({
        email,
        code: this.resetPass.code,
        password: this.resetPass.newPassword
      });
      this.forgotPassForm = false;
      this.confirmPassForm = false;

      this.notify.display('Password Reset Success', 'success');
    } catch (err) {
      this.notify.display(err, 'error');
    } finally {
      this.processing = false;
    }
  }

  toggleForm() {
    this.forgotPassForm = !this.forgotPassForm;
  }

  backToLogin() {
    this.forgotPassForm = false;
    this.confirmPassForm = false;
  }

  checkPasswordMatch() {
    if (this.resetPass.newPassword && this.resetPass.confirmPassword) {
      return (
        this.resetPass.newPassword.toString() ===
        this.resetPass.confirmPassword.toString()
      );
    }
    return false;
  }

  checkPassword(passwordField) {
    let pass = passwordField.$modelValue;
    if (!pass || (pass && pass.length < 8)) {
      passwordField.$valid = false;
    } else {
      let hasUpperCase = /[A-Z]/.test(pass);
      let hasLowerCase = /[a-z]/.test(pass);
      let hasNumbers = /\d/.test(pass);
      let hasNonalphas = /\W/.test(pass);
      passwordField.$valid =
        hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas >= 3;
    }
  }

  resetPassInvalid(confirmPassForm) {
    let invalid = false;

    if (!this.resetPass.code) invalid = true;
    if (confirmPassForm.email.$invalid) invalid = true;
    if (this.resetPass.newPassword && this.resetPass.confirmPassword) {
      if (
        this.resetPass.newPassword.toString() !==
        this.resetPass.confirmPassword.toString()
      )
        invalid = true;
    } else {
      invalid = true;
    }
    return invalid;
  }
}

export default angular
  .module('app.loginModal', [])
  .directive('loginModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./login.html'),
    controller: LoginModalController,
    controllerAs: 'vm'
  })).name;
