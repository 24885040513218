import { State, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardRemindersView {
  tableProps: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  @Inject $store;
  @Inject $auth;
  @Inject $filter;
  @Inject $modals;
  @Inject dataModals;

  @State reminders;
  @Action('reminders/check') checkReminder;

  get tableItems() {
    return this.reminders?.items || [];
  }

  async $onInit() {
    await this.$store.dispatch('reminders/list');

    this.tableProps = [
      {
        label: 'Type',
        value: ({ type }) =>
          type == 'ASSESSMENT_REMINDER' ? 'Start Assessment' : '--'
      },
      {
        label: 'Status',
        value: ({ status }) =>
          status == 'COMPLETED'
            ? 'Completed'
            : status == 'PAST_DUE'
            ? 'Past Due'
            : status == 'REMINDING'
            ? 'Reminding'
            : status == 'PENDING'
            ? 'Pending'
            : '--'
      },
      {
        label: 'Created',
        value: ({ createdAt }) =>
          this.$filter('dynamicDate')(createdAt, 'MM/dd/yyyy')
      },
      {
        label: 'Due Date',
        value: ({ dueDate, $daysLeft }) => {
          let date = this.$filter('dynamicDate')(dueDate, 'MM/dd/yyyy');
          let leftOrPast =
            parseInt($daysLeft) < 0 ? 'day(s) past due' : 'day(s) left';
          return `${date} - ${Math.abs(parseInt($daysLeft))} ${leftOrPast}`;
        }
      },
      {
        label: 'Details',
        value: ({ type, data }) => {
          if (type == 'ASSESSMENT_REMINDER') {
            return `Start assessment using tool <b>${data.tool.name}</b> for <b>${data.client.fName} ${data.client.lName} | ${data.client.localId}</b>.`;
          }
        }
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          {
            label: 'Edit',
            icon: 'pencil',
            fn: (val) => this.$modals.edit.reminder(val)
          },
          {
            label: 'Mark as Complete',
            icon: 'check-circle',
            fn: (val) =>
              this.checkReminder({ reminderId: val.id, completed: true }),
            disabled: ({ completedOn }) => !!completedOn
          },
          {
            label: 'Mark as Incomplete',
            icon: 'circle',
            fn: (val) =>
              this.checkReminder({ reminderId: val.id, completed: false }),
            disabled: ({ completedOn }) => !completedOn
          },
          {
            label: 'Delete',
            icon: 'times-circle',
            fn: (val) =>
              this.$store.dispatch('reminders/del', { reminderId: val.id })
          }
        ]
      }
    ];
  }

  markReminder(reminder, isComplete = true) {
    console.log({ reminder, isComplete });
  }

  async createReminder() {
    await this.$modals.create.reminder();
  }
}

export default DashboardRemindersView;
