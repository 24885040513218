import angular, { ITimeoutService } from 'angular';

import { IGetFormItemsService } from '@services/get-form-items';

import { objectUtils } from './object-utils';
import { datetimeUtils } from './datetime-utils';

/**
 * ...
 */
export type IUtilsService = UtilsService;

/**
 * ...
 */
export interface DisplayFullNameOptions {
  fName: string;
  lName: string;
  mName?: string;
}

/**
 * ...
 */
export interface SplitStringOptions {
  capFirstLetter: boolean;
  capAll: boolean;
  lowerAll: boolean;
}

declare module 'angular' {
  namespace gears {
    type IUtilsService = UtilsService;
  }
}

const STRIP_COMMENTS = /((\/\/.*$)|(\/\*[\s\S]*?\*\/))/gm;
const ARGUMENT_NAMES = /([^\s,]+)/g;

/**
 * Ensures the output is always an array. If the input is not an array, it will
 * return one with the value as its sole element.
 *
 * @param value The value to ensure.
 * @return An array.
 */
export function makeArray<T>(value: T | T[] | undefined) {
  return value === undefined ? [] : !Array.isArray(value) ? [value] : value;
}

/**
 * ...
 *
 * @param val  ...
 * @param testArgs ...
 * @return ...
 */
export function matches(val: unknown, ...testArgs: unknown[]) {
  return testArgs.some((item) => item === val);
}

/**
 * ...
 *
 * @param val ...
 * @return ...
 */
export function clone(val: unknown) {
  let clone = null;

  try {
    clone = JSON.parse(JSON.stringify(val));
  } catch (err) {
    console.error('[utils:clone] Failed to clone provided value.', err);
  }

  return clone;
}

/**
 * ...
 *
 * @param func ...
 * @return ...
 */
export function getParamNames(func: () => any) {
  const fnStr = func.toString().replace(STRIP_COMMENTS, '');

  const result = fnStr
    .slice(fnStr.indexOf('(') + 1, fnStr.indexOf(')'))
    .match(ARGUMENT_NAMES);

  return result || [];
}

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
export function displayFullName(options: DisplayFullNameOptions) {
  let fullName = `${options.lName}, ${options.fName}`;

  if (options.mName) {
    fullName += ` ${options.mName}`;
  }

  return fullName;
}

/**
 * ...
 *
 * @param string ...
 * @param splitChar ...
 * @param options ...
 * @return ...
 */
export function splitString(
  string: unknown,
  splitChar: string | RegExp,
  options: SplitStringOptions
) {
  if (typeof string !== 'string') return;

  return string
    .split(splitChar)
    .map((char) => {
      if (options.capFirstLetter) {
        char = char.charAt(0).toUpperCase() + char.slice(1).toLowerCase();
      } else if (options.capAll) {
        char = char.toUpperCase();
      } else if (options.lowerAll) {
        char = char.toLowerCase();
      }
      return char;
    })
    .join(' ');
}

/**
 * Converts any string to camelcase.
 *
 * @param value ...
 * @return ...
 */
export function camelize(value?: string) {
  if (!value) return '';

  if (!value.length) return value;

  return value.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
    if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
    return index == 0 ? match.toLowerCase() : match.toUpperCase();
  });
}

/**
 * Take camelized string and format for readability.
 *
 * @param value ...
 * @return ...
 */
export function decamlize(value?: string) {
  if (typeof value !== 'string' || value.includes(' ')) return value;

  return value
    .split(/(?=[A-Z])/)
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1))
    .join(' ');
}

/**
 * alphabetize array based on key given
 */
export function alphabetizeArray(array: Array<any>, key: string) {
  // sort alphabetically
  if (Array.isArray(array)) {
    array.sort((a, b) => {
      if (!a[key] || !b[key]) return 0;
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  }

  return array;
}

export class UtilsService {
  readonly object = objectUtils;
  readonly dateTime = datetimeUtils;
  readonly makeArray = makeArray;
  readonly matches = matches;
  readonly clone = clone;
  readonly getParamNames = getParamNames;
  readonly displayFullName = displayFullName;
  readonly splitString = splitString;
  readonly camelize = camelize;
  readonly decamlize = decamlize;
  readonly alphabetizeArray = alphabetizeArray;

  constructor(
    private readonly $timeout: ITimeoutService,
    private readonly getFormItems: IGetFormItemsService,
    private readonly Notification: unknown
  ) {
    'ngInject';
  }

  /**
   * ...
   *
   * @param ms Amount of time (in miliseconds) to wait.
   * @return A promise that will resolve after the specified duration has
   * ellapsed.
   */
  wait(t: number) {
    return this.$timeout(() => {}, t);
  }

  /**
   * ...
   *
   * @param country ...
   * @return ...
   */
  async getStateProvinces(country: string) {
    switch (country) {
      case 'US':
        return await this.getFormItems.usStates();
      case 'CA':
        return await this.getFormItems.canadaProvinces();
      case 'GB':
        return await this.getFormItems.ukProvinces();
      case 'AU':
        return await this.getFormItems.auStates();
    }

    return [];
  }

  /**
   * ...
   *
   * @param type ...
   * @param params ...
   */
  notify(type = 'success', params: string | Record<any, any> = {}) {
    if (type === 'clear') {
      this.Notification.clearAll();
      return;
    }

    const config = {
      message: 'No content.',
      positionX: 'center',
      templateUrl: 'assets/components/notification-template.html'
    };

    if (typeof params === 'string') {
      config.message = params;
    } else if (typeof params === 'object') {
      Object.keys(params).forEach((key) => {
        config[key] = params[key].toString();
      });
    }

    this.Notification[type](config);
  }

  /**
   *
   */
  copyToClipboard(t: string) {
    const el = document.createElement('textarea');
    el.value = t;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    this.notify('success', 'Link Copied to Clipboard!');
  }
}

export default angular.module('app.utils', []).service('utils', UtilsService)
  .name;
