'use strict';

import angular from 'angular';

import { Controller, Inject, Watch } from '@/decorators/ngCtrl';

@Controller
class PolicyEditorActionGroupComponent {
  @Inject $scope;

  @Watch('actions', true)
  onActionsUpdated(val) {
    this.$scope.$emit('permissionActionsUpdated', val);
  }

  selectAll() {
    for (let key in this.actions) {
      this.actions[key].selected = true;
    }
  }

  selectNone() {
    for (let key in this.actions) {
      this.actions[key].selected = false;
    }
  }
}

export default angular
  .module('app.policyEditorActionGroup', [])
  .directive('policyEditorActionGroup', () => {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        label: '<',
        actions: '='
      },
      bindToController: true,
      template: require('./policy-editor-action-group.html'),
      controller: PolicyEditorActionGroupComponent,
      controllerAs: 'vm'
    };
  }).name;
