import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

import { forEach } from 'lodash';

@Controller
class offenderHistoryTemplateCustomizer {
  @Inject $scope;
  @Inject Util;
  // @Inject $store;
  @Inject $modals;
  // @Inject evlUtils;

  $onInit() {
    this.toolData = this.$scope.toolData;
    this.offenderHistory = this.$scope.offenderHistory;
  }

  addSection(parentSections) {
    parentSections.push({
      conditions: [],
      fields: [],
      key: 'SECTION KEY',
      sections: [],
      title: 'SECTION TITLE',
      description: 'SECTION DESCRIPTION'
    });
  }

  removeSection(parentSections, section) {
    parentSections.splice(parentSections.indexOf(section), 1);
  }

  moveSection(parentSections, section, down) {
    // "down" means further into the array
    // "up" or "not down" means sooner in the array
    let currentIndex = parentSections.indexOf(section);
    let newIndex = down ? currentIndex++ : currentIndex--;
    this.Util.arrayMove(parentSections, currentIndex, newIndex);
  }
}

export default angular
  .module('app.offender-history-template-customizer', [])
  .directive('offenderHistoryTemplateCustomizer', function () {
    return {
      template: require('./offender-history-template-customizer.html'),
      restrict: 'EA',
      replace: true,
      scope: {
        offenderHistory: '=',
        toolData: '='
      },
      controller: offenderHistoryTemplateCustomizer,
      controllerAs: 'vm'
    };
  }).name;
