import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

import { find, forEach } from 'lodash';

@Controller
class offenderHistoryTemplateCustomizerSection {
  @Inject $scope;
  @Inject Util;
  // @Inject $store;
  // @Inject $modals;
  // @Inject evlUtils;

  $onInit() {
    this.section = this.$scope.section;
    this.parentSections = this.$scope.parentSections;
  }

  addField() {
    this.section.fields.push({
      defaultData: null,
      key: 'FIELD KEY',
      label: 'FIELD LABEL',
      placeholder: 'FIELD PLACEHOLDER',
      type: this.section.radio ? 'radio' : 'text-input',
      required: false,
      options: []
    });
  }

  addSection() {
    this.section.sections.push({
      conditions: [],
      fields: [],
      key: 'SECTION KEY',
      sections: [],
      title: 'SECTION TITLE',
      description: 'SECTION DESCRIPTION'
    });
  }

  removeSection(parentSections, section) {
    parentSections.splice(parentSections.indexOf(section), 1);
  }

  sectionHasUneditableFields(section) {
    let uneditable = false;
    let checkSection = (s) => {
      if (find(s.fields, (f) => f.uneditable)) uneditable = true;
      if (s.sections?.length) forEach(s.sections, (ss) => checkSection(ss));
    };

    checkSection(section);

    return uneditable;
  }

  camelize(val) {
    val = this.Util.camelize(val);
  }
}

export default angular
  .module('app.offender-history-template-customizer-section', [])
  .directive('offenderHistoryTemplateCustomizerSection', function () {
    return {
      template: require('./offender-history-template-customizer-section.html'),
      restrict: 'EA',
      replace: true,
      scope: {
        section: '=',
        parentSections: '='
      },
      controller: offenderHistoryTemplateCustomizerSection,
      controllerAs: 'vm'
    };
  }).name;
