'use strict';
import html2canvas from 'html2canvas';
import ylscmi from './ylscmi.js';
import lscmi from './lscmi.js';
import { forEach, find, sortBy } from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { Getter, State } from 'angular-store';

@Controller
class ComparativeReportsView {
  @Inject $stateParams;
  @Inject $store;
  @Inject $state;
  @Inject $generatePdf;
  @Inject $sce;
  @State isLoadingMessage;
  @State isLoading;

  @Getter('evaluations/getById') getEvaluation;

  async $onInit() {
    this.evaluations = this.$stateParams.evaluations;
    this.client = this.$store.state.clients.focus;
    this.options = this.$stateParams.options;
    this.tool = this.$stateParams.tool;
    this.report = this.$stateParams.report; // if this is true we were given a report just to display
    if (!this.report) {
      this.parsing = true;
      if (!this.client || !this.evaluations || !this.options) {
        this.$state.go('dashboardClients');
      }
      let clntId = this.client.id;
      let sbGrpId = this.client.subGroup.id;
      let instId = this.client.institutionId;
      if (!this.tool.hasOwnProperty('codingFormItems')) {
        // tool needs to get it's data
        this.tool = await this.$store.dispatch('tools/getToolData', {
          toolId: this.tool.id,
          commitId: this.tool.publishedCommitId
        });
      }
      for (let i = 0; i < this.evaluations.length; i++) {
        await this.$store.dispatch('evaluations/get', {
          instId,
          sbGrpId,
          clntId,
          evalId: this.evaluations[i].id
        });
        this.evaluations[i] = await this.getEvaluation(this.evaluations[i].id);
      }
      this.evaluations = sortBy(
        this.evaluations,
        (e) => e.evaluationData.interview.dateTime
      );
      this.parseEvalsForReport();
    }
  }

  async parseEvalsForReport() {
    if (this.tool.id === 124) {
      //LS/CMI
      this.report = await this.$generatePdf.generateLSCMIComparativeReport(
        this.client,
        this.tool,
        this.evaluations,
        this.options
      );
    } else if (this.tool.id === 120) {
      //YLS/CMI
      this.report = await this.$generatePdf.generateYLSComparativeReport(
        this.client,
        this.tool,
        this.evaluations,
        this.options
      );
    }
    setTimeout(() => {
      this.parsing = false;
      this.$scope.$apply();
    }, 3000);
  }

  async makeTableImage(id) {
    let el = await document.getElementById(id);
    let canvas = await html2canvas(el);
    let dataUrl = canvas.toDataURL();
    dataUrl = this.$sce.trustAsResourceUrl(dataUrl);
    return {
      dataUrl,
      clientWidth: el.clientWidth,
      clientHeight: el.clientHeight,
      element: el,
      canvas
    };
  }

  async downloadReport() {
    this.downloading = true;
    this.$store.commit('setLoadingMessage', 'Processing...');
    this.$store.commit('setIsLoading', true);
    for (let i = 0; i < this.report.length; i++) {
      if (this.report[i].type === 'graph') {
        let el = document.getElementById(`graph-${i}`);
        if (el) {
          let dataUrl = el.toDataURL();
          this.report[i].content.dataUrl = dataUrl;
          this.report[i].content.imgWidth = el.clientWidth;
          this.report[i].content.imgHeight = el.clientHeight;
        }
      } else if (this.report[i].type === 'table') {
        let res = await this.makeTableImage(`table-${i}`);
        this.report[i].content.dataUrl = res.dataUrl;
        this.report[i].content.imgWidth = res.clientWidth;
        this.report[i].content.imgHeight = res.clientHeight;
        this.report[i].content.element = res.element;
        this.report[i].content.canvas = res.canvas;
      }
    }
    let pdf;
    this.$store.commit('setLoadingMessage', 'Generating PDF...');
    pdf = await this.$generatePdf.downloadComparativeReport(
      this.client,
      this.evaluations,
      this.tool,
      this.report
    );
    this.$store.commit('setIsLoading', false);
    this.downloading = false;
    console.log('returned pdf: ', pdf);
  }
}

export default ComparativeReportsView;
