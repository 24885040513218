import { Component, State, Getter, Mutation, Action } from '@angular';
import { IFormController } from 'angular';
import { filter, find } from 'lodash';

import { Modal } from '@mixins/modal.component';
import { RootModules } from '@store/state';

import template from './create-evaluation-request.html';

@Component({ name: 'createEvaluationRequestModal', template })
class CreateEvaluationRequestModalController extends Modal {
  formSubmitted = false;
  institutionId: string | null = null;
  //
  clientId: string | null = null;
  evaluatorId: string | null = null;
  toolId: number | null = null;
  dueDate: string | null = null;

  @State readonly me!: RootModules['me'];
  @State(({ clients }) => clients.items)
  readonly allClients!: RootModules['clients']['items'];
  @State(({ tools }) => tools.items)
  readonly allTools!: RootModules['tools']['items'];
  @State(({ users }) => users.items)
  readonly allUsers!: RootModules['users']['items'];
  @State(({ institutions }) => institutions.items)
  readonly institutions!: RootModules['institutions']['items'];
  @Getter readonly isAdmin!: any;
  @Mutation('evaluations/add') addEvaluation!: any;
  @Action('tools/list') readonly getInstitutionTools!: any;
  @Action('users/list') readonly getInstitutionUsers!: any;
  @Action('clients/list') readonly listClients!: any;
  @Action('institutions/getAll') readonly listInstitutions!: any;
  @Action('evaluationRequests/create') readonly createEvaluationRequest!: any;
  @Action('evaluations/create') readonly createEvaluation!: any;
  @Action('evaluations/get') readonly getEvaluation!: any;

  get parent() {
    return this.$scope.$parent;
  }

  get toolCommitId() {
    return typeof this.toolId === 'number'
      ? find(this.tools, { id: this.toolId })?.publishedCommitId ?? null
      : null;
  }

  get clients() {
    if (this.institutionId && this.isAdmin) {
      return filter(this.allClients, { institutionId: this.institutionId });
    }

    return this.allClients;
  }

  get users() {
    if (this.institutionId && this.isAdmin) {
      return filter(this.allUsers, (user) => {
        return find(user.institutions, { id: this.institutionId });
      });
    }

    return this.allUsers;
  }

  get tools() {
    return filter(
      this.allTools,
      ({ publishedCommitId }) => !!publishedCommitId
    );
  }

  $setup() {
    this.institutionId = this.me.institution?.id ?? null;

    this.load();
  }

  async submit(form: IFormController) {
    if (!form.$valid) return;

    if (!this.dueDate) return;

    this.formSubmitted = true;

    const options: any = {
      institutionId: this.institutionId,
      clientId: this.clientId,
      evaluatorId: this.evaluatorId,
      toolId: this.toolId,
      toolCommitId: this.toolCommitId,
      request: { dueDate: this.dueDate }
    };

    let data: unknown = null;
    let error: Error | null = null;

    try {
      data = await this.createEvaluationRequest(options);
    } catch (err) {
      error = err;
    }

    this.formSubmitted = false;

    if (error) {
      return this.notify.display(error, 'error');
    }

    const client = find(this.clients, { id: this.clientId! });

    const evaluation = await this.getEvaluation({
      instId: this.institutionId,
      sbGrpId: client!.subGroup?.id,
      clntId: client!.id,
      evalId: data.evaluationId
    });

    this.addEvaluation(evaluation);

    this.utils.notify('success', 'Evaluation Request Created');

    this.$close(data);
  }

  private async load() {
    const promises = [
      this.getInstitutionTools(),
      this.getInstitutionUsers(),
      this.listClients()
    ];

    if (!this.institutions?.length && this.isAdmin) {
      promises.push(this.listInstitutions());
    }

    await Promise.all(promises);
  }
}

export default CreateEvaluationRequestModalController.$module;
