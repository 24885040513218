'use strict';

import angular from 'angular';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class SMSCodeInputModalController extends Modal {
  code: text;

  @Inject $scope;

  async $setup() {
    this.parent = this.$scope.$parent;
  }

  async submitCode() {
    this.$close(this.code);
  }
}



export default angular
  .module('gifrApp.smsCodeInputModal', [])
  .directive('smsCodeInputModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./sms-code-input-modal.html'),
    controller: SMSCodeInputModalController,
    controllerAs: 'vm'
  })).name;
