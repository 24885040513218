import { RouteController, Get, Post, RequestMethod, RouteGroup } from '../route-group';

// region Method Parameter Types

interface RouteBaseOptions {
  institutionId: string;
}

export interface GetCasePlanTemplateCommitOptions extends RouteBaseOptions {
  templateId: string;
  commitId: string;
}

export interface ListCasePlanTemplateCommitsOptions extends RouteBaseOptions {
  templateId: string;
}

export interface ListCasePlanTemplatesOptions extends RouteBaseOptions {
  // ...
}

export interface CommitCasePlanTemplateOptions extends RouteBaseOptions {
  templateId: string;
}

export interface CreateCasePlanTemplateOptions extends RouteBaseOptions {
  // ...
}

export interface SetCasePlanTemplateCommitStatusOptions extends RouteBaseOptions {
  templateId: string;
  commitId: string;
}

export interface TagCasePlanTemplateCommitOptions extends RouteBaseOptions {
  templateId: string;
  commitId: string;
}

// endregion Method Parameter Types

@RouteController('icpt/:institutionId')
export class InstitutionCasePlanTemplateRouteGroup extends RouteGroup {
  /** ... */
  @Get('case-plan-templates/:templateId/commits/:commitId')
  readonly getCasePlanTemplateCommit!: RequestMethod<any, GetCasePlanTemplateCommitOptions>;

  /** ... */
  @Get('case-plan-templates/:templateId/commits')
  readonly listCasePlanTemplateCommits!: RequestMethod<any, ListCasePlanTemplateCommitsOptions>;

  /** ... */
  @Get('case-plan-templates')
  readonly listCasePlanTemplates!: RequestMethod<any, ListCasePlanTemplatesOptions>;

  /** ... */
  @Post('case-plan-templates/:templateId/commit')
  readonly commitCasePlanTemplate!: RequestMethod<any, CommitCasePlanTemplateOptions>;

  /** ... */
  @Post('case-plan-templates')
  readonly createCasePlanTemplate!: RequestMethod<any, CreateCasePlanTemplateOptions>;

  /** ... */
  @Post('case-plan-template/:templateId/commits/:commitId/status')
  readonly setCasePlanTemplateCommitStatus!: RequestMethod<any, SetCasePlanTemplateCommitStatusOptions>;

  /** ... */
  @Post('case-plan-template/:templateId/commits/:commitId/tag')
  readonly tagCasePlanTemplateCommit!: RequestMethod<any, TagCasePlanTemplateCommitOptions>;
}
