import angular from 'angular';

import { Controller, Inject, On } from '@/decorators/ngCtrl';

@Controller
class InputCheckboxController {
  @Inject $scope;

  @On('clicked')
  toggleVale() {
    this.value = !this.value;
  }

  get value() {
    return this.ngModelCtrl.$viewValue;
  }

  set value(value) {
    this.ngModelCtrl.$setViewValue(value);
  }
}

export default angular
  .module('app.component.inputCheckbox', [])
  .component('inputCheckbox', {
    template: require('./input-checkbox.html'),
    require: { ngModelCtrl: 'ngModel' },
    bindings: {
      boxBorder: '=',
      color: '='
    },
    controller: InputCheckboxController,
    controllerAs: 'vm'
  }).name;
