//LSI-R:SV Profile Report Generator
import { forEach, find } from 'lodash';
import lsirsvProfileReportCoverPage from './coverPage.html';
import lsirsvTables from './tables';
import lsirsvImageData from './imageDataUrls';
import 'jspdf-autotable';
import fontDataUrls from '../fontDataUrls';
import JsPDF from 'jspdf';

const cumulativeNumbers = {
  0: {
    cfi: {
      male: 3.5,
      female: 11.4
    },
    cfp: 26.0
  },
  1: {
    cfi: {
      male: 9.3,
      female: 42.0
    },
    cfp: 50.8
  },
  2: {
    cfi: {
      male: 21.8,
      female: 69.6
    },
    cfp: 68.1
  },
  3: {
    cfi: {
      male: 39.2,
      female: 84.2
    },
    cfp: 86.6
  },
  4: {
    cfi: {
      male: 57.0,
      female: 94.1
    },
    cfp: 94.4
  },
  5: {
    cfi: {
      male: 78.1,
      female: 98.5
    },
    cfp: 97.9
  },
  6: {
    cfi: {
      male: 91.6,
      female: 99.4
    },
    cfp: 99.8
  },
  7: {
    cfi: {
      male: 98.9,
      female: 100.0
    },
    cfp: 100.0
  },
  8: {
    cfi: {
      male: 100.0,
      female: 100.0
    },
    cfp: 100.0
  }
};

const followUp = {
  Minimum: 'desirable',
  Medium: 'strongly recommended',
  Maximum: 'mandatory'
};

export default {
  async generate(
    mhsLogoDataUrl,
    pageSetup,
    services,
    client,
    evaluation,
    tool,
    $me
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let $filter = services.$filter;
    let $templateCache = services.$templateCache;
    let Util = services.$util;
    let Upload = services.Upload;
    let $store = services.$store;
    let $reincode = services.$reincode;
    let lsirsvLogoDataUrl = lsirsvImageData.lsirsvLogo();

    let evaluationCompletedDate = evaluation.updatedAt;
    const assessmentDate = evaluation.assignedDate;
    const interviewDate = evaluation.evaluation?.interview?.dateTime || 'N/A';
    let generalNotesForEvaluation = evaluation.evaluation.generalNotes;

    if (evaluation.evaluation) evaluation = evaluation.evaluation;
    if (evaluation.evaluationData) evaluation = evaluation.evaluationData;

    let evaluationScoreTotal = 0;
    forEach(evaluation.data, (item) => {
      if (typeof item.score === 'number') evaluationScoreTotal += item.score;
    });

    let classification = evaluation.riskCategory;
    let cfi = cumulativeNumbers[evaluationScoreTotal]?.cfi[client.sex];
    let cfp = cumulativeNumbers[evaluationScoreTotal]?.cfp;
    let lsirFollowUp = followUp[classification];

    $templateCache.put(
      'lsirsvProfileReportCoverPage.html',
      lsirsvProfileReportCoverPage
    );
    let lsirsvPRCoverPage = $templateCache.get(
      'lsirsvProfileReportCoverPage.html'
    );
    let lsirsvPRCoverPageTable = JSON.stringify(lsirsvTables.coverPage());

    //region ==============VARIABLES====================
    // create variable map of known variables in report
    let variableMap = {};
    let key;
    const clientAge = client.age;
    variableMap[`clientAge`] = clientAge;
    const clientLocation = client.location;
    variableMap[`clientLocation`] = clientLocation;
    let clientAssessmentLocation = '';
    if (evaluation.clientZoneName)
      clientAssessmentLocation += `${evaluation.clientZoneName}`;
    if (evaluation.clientRegionName)
      clientAssessmentLocation += ` > ${evaluation.clientRegionName}`;
    if (evaluation.clientSubGroupName)
      clientAssessmentLocation += ` > ${evaluation.clientSubGroupName}`;
    if (clientAssessmentLocation.length == 0)
      clientAssessmentLocation = 'LOCATION NOT FOUND';
    variableMap[`clientAssessmentLocation`] = clientAssessmentLocation;
    for (key in client) {
      if (key === 'type') {
        let typeList = '';
        if (client.type) {
          let types =
            typeof client.type === 'string'
              ? client.type.split(',')
              : client.type;
          if (types.length === 1) {
            typeList = types[0];
          } else {
            forEach(types, (type, index) => {
              if (index === types.length - 1) {
                typeList += `and ${type}`;
              } else {
                typeList += `${type}, `;
              }
            });
          }
        } else {
          typeList = 'No Client Types Provided';
        }
        variableMap[`client.${key}`] = typeList;
      } else if (key === 'fName' || key === 'lName' || key === 'localId') {
        variableMap[`client.${key}`] = client[key] ? client[key] : 'N/A';
      } else {
        variableMap[`client.${key}`] = client[key]
          ? Util.decamlize(client[key])
          : 'N/A';
      }
    }

    // handle gender exclusively
    if (!client.gender) variableMap['client.gender'] = '-';

    variableMap['date.today'] = $filter('dynamicDate')(new Date(), 'longDate');
    variableMap['evaluationCompletedDate'] = $filter('dynamicDate')(
      new Date(evaluationCompletedDate),
      'longDate'
    );
    variableMap['assessmentDate'] = $filter('dynamicDate')(
      new Date(assessmentDate),
      'longDate'
    );
    variableMap['interviewDate'] = $filter('dynamicDate')(
      new Date(interviewDate),
      'longDate'
    );
    for (key in $me) {
      variableMap[`user.${key}`] = Util.decamlize($me[key]);
    }

    //================ CREATE VARIABLE MAP =================
    let variableRegEx = Object.keys(variableMap).join('|');
    variableRegEx = variableRegEx.replace(/\[/g, '\\[');
    variableRegEx = variableRegEx.replace(/]/g, '\\]');
    variableRegEx = variableRegEx.replace(/\)/g, '\\)');
    variableRegEx = variableRegEx.replace(/\(/g, '\\(');
    variableRegEx = variableRegEx.replace(/#/g, '\\#');
    variableRegEx = variableRegEx.replace(/\+/g, '\\+');
    variableRegEx = new RegExp(variableRegEx, 'gi');

    lsirsvPRCoverPage = lsirsvPRCoverPage.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    lsirsvPRCoverPageTable = lsirsvPRCoverPageTable.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    lsirsvPRCoverPageTable = JSON.parse(lsirsvPRCoverPageTable);
    //endregion================ END CREATE VARIABLE MAP =================
    //region=================== PDF DOC SETUP ==================
    let specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    let pdf = new JsPDF('p', 'pt', 'letter');

    // set comfortaa font
    pdf.addFileToVFS('ComfortaaRegular.tff', fontDataUrls.comfortaaRegular());
    pdf.addFont('ComfortaaRegular.tff', 'Comfortaa', 'normal');
    pdf.addFileToVFS('ComfortaaBold.tff', fontDataUrls.comfortaaBold());
    pdf.addFont('ComfortaaBold.tff', 'Comfortaa', 'bold');
    pdf.setFont('Comfortaa');
    let page = 2;

    let header = function () {
      pdf.setFontSize(11);
      pdf.setFontStyle('normal');
      pdf.text(
        `LSI-R:SV Profile Report for ${client.name()}`,
        margins.left,
        21
      );
      pdf.text(`Page ${page}`, pageWidth - margins.left - 50, 21);
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(margins.left, 25, pageWidth - margins.left, 25);
      page = page + 1;
    };

    let footer = function () {
      pdf.addImage(
        mhsLogoDataUrl,
        'JPEG',
        pageWidth - margins.left - 80,
        pageHeight - margins.bottom,
        80,
        35
      );
    };
    //endregion=================== END PDF DOC SETUP ==================
    // ======================COVER PAGE===============================
    pdf.addImage(
      lsirsvLogoDataUrl,
      'JPEG',
      margins.left,
      margins.top,
      244,
      102
    );

    pdf.fromHTML(
      lsirsvPRCoverPage,
      margins.left,
      margins.top + 100,
      {
        width: margins.width,
        elementHandlers: specialElementHandlers
      },
      function (dispose) {
        // add cover page table with client info
        pdf.autoTable({
          head: lsirsvPRCoverPageTable.head,
          body: lsirsvPRCoverPageTable.body,
          columnStyles: lsirsvPRCoverPageTable.columnStyles,
          startY: 300,
          theme: 'striped',
          headStyles: {
            fillColor: [84, 9, 5],
            textColor: [255, 255, 255]
          }
        });

        pdf.setFontSize(10);

        // add MHS logo and copyright info
        pdf.setFontSize(9);
        pdf.addImage(mhsLogoDataUrl, 'JPEG', margins.left, 680, 125, 54);
        pdf.text(
          'Copyright © 2004 Multi-Health Systems Inc. All rights reserved.\n' +
            'P.O. Box 950, North Tonawanda, NY 14120-0950\n' +
            '3770 Victoria Park Ave., Toronto, ON M2H 3M6',
          margins.left + 135,
          705
        );

        // ==========================END COVER PAGE==============================
        // ========================== PARSE EVALUATION FOR REPORT ====================
        pdf.addPage();
        header();
        footer();
        let y = margins.top + 10;

        // logic for creating new line or new page if needbe
        let newLine = function (y) {
          if (y + 12 > pageHeight - (margins.top + margins.bottom)) {
            pdf.addPage();
            header();
            footer();
            y = margins.top + 20;
          } else {
            y += 12;
          }
          return y;
        };

        let addText = function (
          text,
          fontSize = 10,
          fontStyle = 'normal',
          align = 'left',
          x,
          customY
        ) {
          if (!text) {
            console.error('Text parameter must be provided');
            return;
          }
          text = $reincode.text(text);
          if (fontSize > 10 && fontSize <= 14 && !customY) y += 15;
          if (fontSize > 14 && fontSize < 18 && !customY) y += 20;
          if (customY) y += customY;

          pdf.setFontSize(fontSize);
          pdf.setFontStyle(fontStyle);
          let lines = pdf.splitTextToSize(text, margins.width);

          forEach(lines, (line) => {
            pdf.setFontSize(fontSize);
            pdf.setFontStyle(fontStyle);
            pdf.text(line, x ? x : margins.left, y, align);
            y = newLine(y);
          });

          y = newLine(y);
        };

        let newPage = function (pageAlreadyAdded) {
          if (!pageAlreadyAdded) pdf.addPage();
          header();
          footer();
          y = margins.top + 10;
        };

        //region INTRODUCTION
        addText(`Introduction`, 14, 'bold');
        addText(
          'The Level of Service Inventory-Revised: Screening Version is a risk and needs assessment tool. This ' +
            'report summarizes the results of the LSI-R:SV administration, and provides information pertinent to the ' +
            'assessment of the individual.'
        );
        //endregion

        //region GENERAL NOTES
        addText(`General Notes for Evaluation`, 14, 'bold');
        addText(
          generalNotesForEvaluation?.length
            ? $reincode.text(generalNotesForEvaluation)
            : `No Notes Recorded`
        );
        //endregion

        // ==============GRAPHICAL PROFILE==================
        addText(`Graphical Profile`, 14, 'bold');
        addText(
          'The graph below shows the LSI-R:SV Total Score and indicates the classification level associated with that score.'
        );
        // draw bar graph here
        let tSGHeight = 50;
        let tSGWidth = pageWidth / 2;
        let tSGLineHeight = 20;
        let tSGLineWidth = evaluationScoreTotal * (tSGWidth / 8);
        let tSGIncrements = tSGWidth / 8;
        // write zones titles
        pdf.text('Minimum', 153 + tSGIncrements, y - 5);
        pdf.text('Medium', 153 + tSGIncrements * 4 - 20, y - 5);
        pdf.text('Maximum', 153 + tSGIncrements * 6, y - 5);
        // draw zones markers
        pdf.setDrawColor(200, 200, 200);
        pdf.line(
          153 + tSGIncrements * 2 + tSGIncrements / 2,
          y,
          153 + tSGIncrements * 2 + tSGIncrements / 2,
          y + tSGHeight
        );
        pdf.line(
          153 + tSGIncrements * 5 + tSGIncrements / 2,
          y,
          153 + tSGIncrements * 5 + tSGIncrements / 2,
          y + tSGHeight
        );
        // draw graph
        pdf.setDrawColor(0, 0, 0);
        pdf.rect(153, y, tSGWidth, tSGHeight);
        pdf.setDrawColor(255, 0, 0);
        pdf.setLineWidth(tSGLineHeight);
        pdf.line(153, y + 25, 153 + tSGLineWidth, y + 25);
        pdf.setDrawColor(0, 0, 0);
        pdf.setFontSize(12);
        pdf.setFontStyle('bold');
        pdf.text(`(${evaluationScoreTotal})`, 153 + tSGLineWidth + 10, y + 27);
        pdf.setFontSize(10);
        pdf.setFontStyle('normal');
        // write score markers
        let tSGX = 153;
        for (let i = 0; i <= 8; i++) {
          pdf.text(i.toString(), tSGX, y + tSGHeight + 10);
          tSGX = tSGX + tSGIncrements;
        }
        y += tSGHeight + 25;
        addText(
          `Classification: ${classification}`,
          12,
          'bold',
          undefined,
          undefined,
          10
        );
        addText(
          `Cumulative Frequency Inmates: ${cfi}`,
          12,
          'bold',
          undefined,
          undefined,
          10
        );
        addText(
          `Cumulative Frequency Probationers: ${cfp}`,
          12,
          'bold',
          undefined,
          undefined,
          10
        );
        addText(`Additional Comment(s)`, 11, 'bold');
        addText(`LSI-R follow-up is ${lsirFollowUp}.`);
        addText(
          `* The Risk/Needs assessment derived from the LSI-R:SV is based on only a subset of the Risk/Needs given in the LSI-R.`
        );
        // ============== ITEM RESPONSES ======================
        addText(`Summary of LSI-R:SV Item Responses`, 14, 'bold');
        addText(
          `The rater entered the following response values for the items on the Level of Service Inventory-Revised: Screening Version.`
        );
        let ir = {
          head: [['Item', 'Response', 'Comment']],
          body: [],
          startY: y
        };
        forEach(tool.codingFormItems, (cfi, index) => {
          let comment = '';
          let answerText = find(cfi.codesAndScore, (cas) => {
            if (
              evaluation.data[cfi.id] &&
              evaluation.data[cfi.id].aid === cas.id
            ) {
              comment = evaluation.data[cfi.id].comment
                ? evaluation.data[cfi.id].comment
                : '';
              return true;
            }
          });
          if (answerText) answerText = answerText.text;
          if (answerText === '-') answerText = 'Unanswered';
          if (answerText === 'Omit') answerText = 'Omitted';
          ir.body.push([
            `${index + 1}. ${cfi.riskFactor}`,
            `${answerText}`,
            `${comment}`
          ]);
          // addText(`${index + 1}. ${cfi.riskFactor} - ${answerText}`);
        });
        y += 20 * ir.body.length + 1;
        pdf.autoTable({
          head: ir.head,
          body: ir.body,
          startY: ir.startY,
          theme: 'striped',
          rowPageBreak: 'avoid',
          didDrawPage: (hookData) => {
            if (hookData.pageNumber > 1) newPage(true);
            y = hookData.cursor.y + 25;
          }
        });
        // ================END OF REPORT===================
        let today = $filter('dynamicDate')(new Date(), 'fullDate');
        y += 20;
        addText(`Date Printed: ${today}`);
        addText(`End of Report`, undefined, 'bold');
        // ==========================UPLOAD PDF=========================
        let filename = `${client.name()} - ${$filter('dynamicDate')(
          new Date(),
          'MM-dd-yyyy'
        )} - LSI-R-SRV_Profile_Report`;
        // ==========================SAVE PDF=========================
        pdf.save(`${filename}.pdf`);
        // ==========================UPLOAD PDF=========================
        let pdfFile = pdf.output('arraybuffer');
        // let pdfFile = pdf.output('binary');
        pdfFile = new File([pdfFile], `${filename}.pdf`, {
          type: 'application/pdf'
        });

        Upload.upload({
          url: `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/evaluations/${evaluation.evaluationId}/media`,
          file: pdfFile,
          data: { isReport: true }
        })
          .then((response) => {
            $store.commit('evaluations/setFocus', evaluation.evaluationId);
            $store.dispatch('reports/getForEvaluation', {
              id: evaluation.evaluationId,
              client: client
            });
            return response;
          })
          .catch((err) => {
            return err;
          });
      },
      margins
    );
  }
};
