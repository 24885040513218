// ACE Questionnaire Profile Report Generator
import { find, forEach, maxBy } from 'lodash';
import angular from 'angular';
import acesProfileReportCoverPage from './coverPage.html';
import acesProfileReportTables from './tables';
import 'jspdf-autotable';
import JsPDF from 'jspdf';
import fontDataUrls from '../fontDataUrls';

export default {
  async generate(
    mhsLogoDataUrl,
    pageSetup,
    services,
    client,
    evaluation,
    tool,
    options,
    $me
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let pageContentEnd = pageSetup.pageHeight - margins.bottom;
    let $http = services.$http;
    let $filter = services.$filter;
    let $templateCache = services.$templateCache;
    let $store = services.$store;
    let $auth = services.$auth;
    let Util = services.$util;
    let notify = services.notify;
    let Upload = services.Upload;
    let $reincode = services.$reincode;

    let parseOption = function (text) {
      // parse options from $me selection to see if it should be included in report
      return find(options, {
        text
      })
        ? find(options, { text }).selected
        : null;
    };

    let checkEndOfPage = function (y) {
      return y >= pageHeight - margins.bottom - 100;
    };

    const assessmentDate = evaluation.assignedDate;
    const interviewDate = evaluation.evaluation?.interview?.dateTime || 'N/A';
    // console.log('evaluation at beginning: ', angular.copy(evaluation));
    let evaluator = evaluation.evaluator;
    if (evaluation.evaluation) evaluation = evaluation.evaluation;
    if (evaluation.evaluationData) evaluation = evaluation.evaluationData;

    if (!evaluator)
      evaluator =
        evaluation.evaluatorId === $me.id
          ? $me
          : find($store.state.users.items, {
              id: evaluation.evaluatorId
            });

    // console.log('Generating ACE Questionnaire Profile Report...');
    // console.log('client: ', client);
    // console.log('evaluation: ', evaluation);
    // console.log('tool: ', tool);
    // console.log('options: ', options);

    $templateCache.put(
      'acesProfileReportCoverPage.html',
      acesProfileReportCoverPage
    );
    let acesPRCoverPage = $templateCache.get('acesProfileReportCoverPage.html');
    let acesPRCoverPageTable = JSON.stringify(
      acesProfileReportTables.coverPage()
    );

    // create variable map of known variables in report
    let variableMap = {};
    let key;
    const clientAge = client.age;
    variableMap[`clientAge`] = clientAge;
    const clientLocation = client.location;
    variableMap[`clientLocation`] = clientLocation;
    let clientAssessmentLocation = '';
    if (evaluation.clientZoneName)
      clientAssessmentLocation += `${evaluation.clientZoneName}`;
    if (evaluation.clientRegionName)
      clientAssessmentLocation += ` > ${evaluation.clientRegionName}`;
    if (evaluation.clientSubGroupName)
      clientAssessmentLocation += ` > ${evaluation.clientSubGroupName}`;
    if (clientAssessmentLocation.length == 0)
      clientAssessmentLocation = 'LOCATION NOT FOUND';
    variableMap[`clientAssessmentLocation`] = clientAssessmentLocation;
    for (key in client) {
      if (key === 'type') {
        let typeList = '';
        if (client.type) {
          let types =
            typeof client.type === 'string'
              ? client.type.split(',')
              : client.type;
          if (types.length === 1) {
            typeList = types[0];
          } else {
            forEach(types, (type, index) => {
              if (index === types.length - 1) {
                typeList += `and ${type}`;
              } else {
                typeList += `${type}, `;
              }
            });
          }
        } else {
          typeList = 'No Client Types Provided';
        }
        variableMap[`client.${key}`] = typeList;
      } else if (key === 'fName' || key === 'lName' || key === 'localId') {
        variableMap[`client.${key}`] = client[key] ? client[key] : 'N/A';
      } else {
        variableMap[`client.${key}`] = client[key]
          ? Util.decamlize(client[key])
          : 'N/A';
      }
    }

    // handle gender exclusively
    if (!client.gender) variableMap['client.gender'] = '-';

    variableMap['date.today'] = $filter('dynamicDate')(new Date(), 'longDate');
    for (key in evaluator) {
      if (key === 'fName' || key === 'lName') {
        variableMap[`user.${key}`] = evaluator[key];
      } else {
        variableMap[`user.${key}`] = Util.decamlize(evaluator[key]);
      }
    }
    variableMap['assessmentDate'] = $filter('dynamicDate')(
      new Date(assessmentDate),
      'longDate'
    );
    variableMap['interviewDate'] = $filter('dynamicDate')(
      new Date(interviewDate),
      'longDate'
    );

    //================ CREATE VARIABLE MAP =================
    let variableRegEx = Object.keys(variableMap).join('|');
    variableRegEx = variableRegEx.replace(/\[/g, '\\[');
    variableRegEx = variableRegEx.replace(/]/g, '\\]');
    variableRegEx = variableRegEx.replace(/\)/g, '\\)');
    variableRegEx = variableRegEx.replace(/\(/g, '\\(');
    variableRegEx = variableRegEx.replace(/#/g, '\\#');
    variableRegEx = variableRegEx.replace(/\+/g, '\\+');
    variableRegEx = new RegExp(variableRegEx, 'gi');

    acesPRCoverPage = acesPRCoverPage.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    acesPRCoverPageTable = acesPRCoverPageTable.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    acesPRCoverPageTable = JSON.parse(acesPRCoverPageTable);
    //================ END CREATE VARIABLE MAP =================
    //=================== PDF DOC SETUP ==================
    let specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    let pdf = new JsPDF('p', 'pt', 'letter');

    // set comfortaa font
    pdf.addFileToVFS('ComfortaaRegular.tff', fontDataUrls.comfortaaRegular());
    pdf.addFont('ComfortaaRegular.tff', 'Comfortaa', 'normal');
    pdf.addFileToVFS('ComfortaaBold.tff', fontDataUrls.comfortaaBold());
    pdf.addFont('ComfortaaBold.tff', 'Comfortaa', 'bold');
    pdf.setFont('Comfortaa');
    let page = 2;

    let header = function () {
      pdf.setFontSize(11);
      pdf.setFontStyle('normal');
      pdf.text(`Profile Report: ${client.name()}`, margins.left, 21);
      pdf.text(`Page ${page}`, pageWidth - margins.left - 50, 21);
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(margins.left, 25, pageWidth - margins.left, 25);
      page = page + 1;
    };

    let footer = function () {
      pdf.addImage(
        mhsLogoDataUrl,
        'JPEG',
        pageWidth - margins.left - 80,
        pageHeight - margins.bottom,
        80,
        35
      );
    };
    //=================== END PDF DOC SETUP ==================
    // ======================COVER PAGE===============================

    pdf.fromHTML(
      acesPRCoverPage,
      margins.left,
      margins.top + 100,
      {
        width: margins.width,
        elementHandlers: specialElementHandlers
      },
      function (dispose) {
        // add cover page table with client info
        pdf.autoTable({
          head: acesPRCoverPageTable.head,
          body: acesPRCoverPageTable.body,
          columnStyles: acesPRCoverPageTable.columnStyles,
          startY: 300,
          theme: 'striped',
          headStyles: {
            fillColor: [84, 9, 5],
            textColor: [255, 255, 255]
          }
        });

        // add MHS logo and copyright info
        pdf.setFontSize(9);
        pdf.addImage(mhsLogoDataUrl, 'JPEG', margins.left, 680, 125, 54);
        pdf.text(
          'Source: Centers for Disease Control and Prevention (CDC); Materials developed\n' +
            'by CDC and available on agency website for no charge. Reference to specific\n' +
            'commercial products, manufacturers, companies, or trademarks does not\n' +
            'constitute its endorsement or recommendation by the U.S. Government,\n' +
            'Department of Health and Human Services,\n' +
            'or Centers for Disease Control and Prevention.',
          margins.left + 135,
          680
        );

        // ==========================END COVER PAGE==============================
        // ========================== PARSE EVALUATION FOR REPORT ====================
        pdf.addPage();
        header();
        footer();
        let y = margins.top + 10;

        // logic for creating new line or new page if needbe
        let newLine = function (y) {
          if (y + 12 > pageHeight - (margins.top + margins.bottom)) {
            pdf.addPage();
            header();
            footer();
            y = margins.top + 20;
          } else {
            y += 12;
          }
          return y;
        };

        let addText = function (
          text,
          fontSize = 10,
          fontStyle = 'normal',
          align = 'left',
          x
        ) {
          if (!text) {
            console.error('Text parameter must be provided');
            return;
          }
          text = $reincode.text(text);
          if (fontSize > 10 && fontSize <= 14) y += 15;
          if (fontSize > 14 && fontSize < 18) y += 20;

          pdf.setFontSize(fontSize);
          pdf.setFontStyle(fontStyle);

          if (!text?.length || typeof text !== 'string') return;
          let lines = pdf.splitTextToSize(text, margins.width);

          forEach(lines, (line) => {
            pdf.setFontSize(fontSize);
            pdf.setFontStyle(fontStyle);
            pdf.text(line, x ? x : margins.left, y, align);
            y = newLine(y);
          });

          y = newLine(y);
        };

        let sectionBuffer = function () {
          if (y + 25 >= pageContentEnd) {
            newPage();
          } else {
            y += 25;
          }
        };

        let checkSectionStart = function (yVal = 40) {
          if (y + yVal >= pageContentEnd) newPage();
        };

        let addImage = function (imageUrl, type = 'JPEG', x, y, w, h) {
          if (y + h >= pageContentEnd) newPage();
          pdf.addImage(imageUrl, type, x, y, w, h);
          if ((y += h + 10 >= pageContentEnd)) {
            newPage();
          } else {
            y += h + 10;
          }
        };

        let newPage = function (pageAlreadyAdded) {
          if (y === margins.top + 10) return;
          if (!pageAlreadyAdded) pdf.addPage();
          header();
          footer();
          y = margins.top + 10;
        };

        //region Item Responses
        if (parseOption('Item Responses')) {
          // sectionBuffer();
          newPage();
          addText(`Item Responses`, 14, 'bold');
          let irTable = {
            head: [['Item', 'Response']],
            body: []
          };

          forEach(tool.codingFormItems, (cfi, i) => {
            let answer = evaluation.data[cfi.id];
            irTable.body.push([
              cfi.riskFactor,
              answer.text ? answer.text : 'N/A'
            ]);
          });

          pdf.autoTable({
            head: irTable.head,
            body: irTable.body,
            startY: y,
            theme: 'grid',
            rowPageBreak: 'avoid',
            didDrawPage: (hookData) => {
              if (hookData.pageNumber > 1) newPage(true);
              y = hookData.cursor.y + 25;
            }
          });

          addText(`Total Score: ${evaluation.score}`);
        }
        //endregion Item Responses

        // ==============END PARSE EVALUATION FOR REPORT==================
        // ================END OF REPORT===================
        let today = $filter('dynamicDate')(new Date(), 'fullDate');
        pdf.text(`Date Printed: ${today}`, margins.left, pageHeight - 35);
        pdf.text(`End of Report`, margins.left, pageHeight - 20);
        // addText(`End of Report (Assessment # ${caseplan.evaluation.id})`, null, 'bold');
        // ==========================UPLOAD PDF=========================
        let filename = `${client.name()} - ${$filter('dynamicDate')(
          new Date(),
          'MM-dd-yyyy'
        )} - ACE_Profile_Report`;
        // ==========================SAVE PDF=========================
        pdf.save(`${filename}.pdf`);
        // ==========================UPLOAD PDF=========================
        let pdfFile = pdf.output('arraybuffer');
        // let pdfFile = pdf.output('binary');
        pdfFile = new File([pdfFile], `${filename}.pdf`, {
          type: 'application/pdf'
        });
        Upload.upload({
          url: `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/evaluations/${evaluation.evaluationId}/media`,
          file: pdfFile,
          data: {
            isReport: true
          }
        })
          .then((response) => {
            $store.commit('evaluations/setFocus', evaluation.evaluationId);
            $store.dispatch('reports/getForEvaluation', {
              id: evaluation.evaluationId,
              client: client
            });
            return response;
          })
          .catch((err) => {
            return err;
          });
      },
      margins
    );
  }
};
