'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { Action } from 'angular-store';

import Modal from '@/mixins/modal.mixin';

@Controller
class EditOffenseClassificationModalController extends Modal {
  $indicies: number[] = [-1, -1];
  classifications: {}[] = [];

  @Inject $scope;
  @Inject $store;
  @Inject $api;
  @Inject utils;

  @Action('offenseClassifications/update') updateOffenseClassification;

  get sectionNumber() {
    return this.$props.section.number;
  }

  get classificationCount() {
    return this.classifications.length;
  }

  // @Getter isAdmin;

  getSectionIndex() {
    for (let i in this.$props.oc) {
      let chapter = this.$props.oc[i];

      for (let j in chapter.sections) {
        let section = chapter.sections[j];

        if (section == this.$props.section) {
          this.$indicies = [i, j];

          return;
        }
      }
    }
  }

  $setup() {
    this.getSectionIndex();

    this.refreash();

    this.$scope.$watchCollection(
      () => this.classifications,
      (val) =>
        val.forEach((item, i) => {
          item.articleNumber = `${this.sectionNumber}.${i + 1}`;
        })
    );
  }

  clear() {
    this.classifications = [];
  }

  refreash() {
    const { section } = this.$props;

    this.classifications = this.utils
      .clone(section.offenseClassifications)
      .map((item, i) => ({
        articleNumber: `${this.sectionNumber}.${i + 1}`,
        label: item.label,
        description: item.description
      }));
  }

  add() {
    this.classifications.push({
      articleNumber: `${this.sectionNumber}.${this.classificationCount + 1}`,
      label: '',
      description: ''
    });
  }

  remove(ref) {
    this.classifications = this.classifications.filter((item) => item != ref);
  }

  moveUp(i) {
    let ref = this.classifications.splice(i, 1)[0];
    this.classifications.splice(i - 1, 0, ref);
  }

  moveDown(i) {
    let ref = this.classifications.splice(i, 1)[0];
    this.classifications.splice(i + 1, 0, ref);
  }

  async update() {
    const [i, j] = this.$indicies;

    let data = this.utils.clone(this.$props.oc);
    data[i].sections[j].offenseClassifications = this.classifications;

    data = data.map((chapter, i) => ({
      number: i + 1,
      label: chapter.label,
      sections: chapter.sections.map((section, j) => ({
        number: j + 1,
        label: section.label,
        offenseClassifications: section.offenseClassifications.map((oc) => ({
          articleNumber: `${i + 1}.${j + 1}`,
          label: oc.label,
          description: oc.description
        }))
      }))
    }));

    try {
      await this.updateOffenseClassification({
        id: this.$props.instId,
        data,
        ocId: this.$props.ocId
      });
    } catch (err) {
      console.error('UPDATE FAILED', err);
    }

    this.utils.notify('success', 'Classification updated.');

    this.$close(this.classifications);
  }
}

export default angular
  .module('app.editOffenseClassificationModal', [])
  .directive('editOffenseClassificationModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./edit-offense-classification.html'),
    controller: EditOffenseClassificationModalController,
    controllerAs: 'vm'
  })).name;
