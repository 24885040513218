import { Controller, Inject } from '@angular';

@Controller
class PasswordResetView {
  resetKey: string = '';
  validKey: boolean = false;
  pw: string = '';
  pwConfirm: string = '';
  userId: string = '';

  @Inject $state;
  @Inject $api;
  @Inject Notification;

  async submitPWResetKey(form) {
    if (!form.$valid) {
      this.Notification.warning({
        title: 'Reset Key Required',
        message: 'You must enter a valid reset key to continue.',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });

      return;
    }

    try {
      let res = await this.$api.passwordReset.submitKey(this.resetKey);

      console.log(res);
      if (res.status !== 200) {
        this.Notification.error({
          title: 'Reset Key Error',
          message: 'The Reset Key you entered was invalid or already used.',
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });

        return;
      }

      this.userId = res.data.id;
      this.validKey = true;

      this.Notification.success({
        title: 'Reset Key Valid',
        message:
          'The Reset Key you entered was valid. Enter your new password below and click submit.',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });
    } catch (err) {
      console.log(err);

      if (err.status === 404) {
        this.Notification.error({
          title: 'Reset Key Error',
          message: 'Reset Key Not Found',
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });
      } else if (err.data) {
        this.Notification.error({
          title: 'Reset Key Error',
          message: err.data.feedback,
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });
      } else {
        this.Notification.error({
          title: 'Reset Key Error',
          message:
            'Something went wrong on our end. Please contact support@littlearms.com for help with this issue.',
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });
      }
    }
  }

  async submitNewPW(form) {
    if (!form.$valid || this.pwConfirm !== this.pw) {
      this.Notification.error({
        title: 'Passwords Must Match',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });

      return;
    }

    try {
      let res = await this.$api.passwordReset.submitNewPassword({
        userId: this.userId,
        newPW: this.pw,
        psResetKey: this.resetKey
      });

      if (res.data.error) {
        this.Notification.error({
          title: 'Error - Password Reset',
          message: res.data.feedback,
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });

        return;
      }

      this.Notification.success({
        title: 'Password Successfully Changed',
        message: res.data.feedback,
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });

      this.$state.go('login');
    } catch (err) {
      console.log(err);

      if (err.data.feedback) {
        this.Notification.error({
          title: 'Error - Password Reset',
          message: err.data.feedback,
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });
      } else {
        this.Notification.error({
          title: 'Error - Password Reset',
          message: `Please contact ${process.env.SUPPORT_EMAIL} for help with your password reset.`,
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });
      }
    }
  }

  checkPassword(passwordField) {
    var pass = passwordField.$modelValue;

    if (pass.length < 8) {
      passwordField.$valid = false;

      return;
    }

    var hasUpperCase = /[A-Z]/.test(pass);
    var hasLowerCase = /[a-z]/.test(pass);
    var hasNumbers = /\d/.test(pass);
    var hasNonalphas = /\W/.test(pass);

    if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
      passwordField.$valid = false;
    } else {
      passwordField.$valid = true;
    }
  }

  confirmPassword(passwordField) {
    var passConfirm = passwordField.$modelValue;

    passwordField.$valid = passConfirm === this.pw ? true : false;
  }
}

export default PasswordResetView;
