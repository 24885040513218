import angular from 'angular';

import {
  ActivityReportsMonitor,
  AggregateReportsMonitor,
  AggregateUserReportsMonitor
} from './report-monitors.service';
// import { ReportUtilsService } from './report-utils.new.service';
import { ReportUtilsService } from './report-utils.old.service';

declare module 'angular' {
  namespace gears {
    type IActivityReportsMonitor = ActivityReportsMonitor;
    type IAggregateReportsMonitor = AggregateReportsMonitor;
    type IAggregateUserReportsMonitor = AggregateUserReportsMonitor;
    // type IReportUtilsService = ReportUtilsService;
    type IReportUtilsService = ReturnType<typeof ReportUtilsService>;
  }
}

export default angular
  .module('app.reports', [])
  .service('activityReportsMonitor', ActivityReportsMonitor)
  .service('aggregateReportsMonitor', AggregateReportsMonitor)
  .service('aggregateUserReportsMonitor', AggregateUserReportsMonitor)
  .service('reportUtils', ReportUtilsService).name;
