'use strict';
const angular = require('angular');

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class GeneralChoiceModalController extends Modal {
  $setup() {
    'ngInject';
    this.items = this.$props.items;
    this.type = this.$props.type;
    if (this.$props.orderBy !== 'none') {
      this.orderBy = this.$props.orderBy ? this.$props.orderBy : 'createdAt';
    }
    this.sortReverse = this.$props.reverse;
    this.choice = null;
  }

  submit() {
    this.$close(this.choice);
  }
}

/*@ngInject*/
// export function generalChoiceModalController($scope) {
//   var parent = $scope.$parent;
//   this.items = parent.modal.item.items;
//   this.type = parent.modal.item.type;
//   this.choice = null;
//
//   this.submit = function() {
//     parent.$close(this.choice);
//   };
// }

export default angular
  .module('app.generalChoiceModal', [])
  .directive('generalChoiceModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./general-choice.html'),
    controller: GeneralChoiceModalController,
    controllerAs: 'vm'
  })).name;
