import angular from 'angular';

import {} from 'angular-store';
import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class MarkMatchedRemindersModalController extends Modal {}

export default angular
  .module('app.markMatchedRemindersModal', [])
  .directive('markMatchedRemindersModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./mark-matched-reminders-modal.html'),
    controller: MarkMatchedRemindersModalController,
    controllerAs: 'vm'
  })).name;
