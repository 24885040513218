interface TextComponent {
  text: string;
  color: string | null;
}

/**
 * ...
 */
export class ColorText {
  textComps: TextComponent[] = [];

  /**
   * ...
   *
   * @param text ...
   * @param color ...
   * @return ...
   */
  add(text: string, color?: string) {
    this.textComps.push({ text, color: color ?? null });

    return this;
  }

  /**
   * ...
   */
  print() {
    let text = '';
    let styles: string[] = [];

    for (let comp of this.textComps) {
      text += `%c${comp.text}`;
      styles.push(`font-size: 14px; line-height: 1.5em; color: ${comp.color}`);
    }

    console.log(text, ...styles);
  }
}
