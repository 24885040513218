import angular from 'angular';

export default angular
  .module('app.formFieldSelect', [])
  .directive('formFieldSelect', () => {
    'ngInject';

    return {
      restrict: 'E',
      replace: true,
      template(element, attrs) {
        return `
          <div class="form-field-select row">
            <div class="form-group">
              <div class="col-sm-4 col-xs-12">
                <label>{{ ${attrs.label} }}{{ ${attrs.required} ? '*' : '' }}</label>
              </div>
              <div class="col-sm-8 col-xs-12">
                <select
                  class="form-control"
                  ng-model="${attrs.model}"
                  ng-required="${attrs.required}"
                  ng-options="${attrs.options}"
                >
                  <option value="">-- Choose an option --</option>
                </select>
              </div>
            </div>
          </div>
        `;
      }
    };
  }).name;
