import angular from 'angular';
import Auth from '@aws-amplify/auth';
import { find } from 'lodash';

import { HasAccessCheckOptions } from '@services/acl/acl.service';
import codingFormItemsController from '@root/client/components/coding-form-items/coding-form-items.controller';

/**
 * ...
 */
type CognitoUserSession<T = ReturnType<typeof Auth['currentSession']>> =
  T extends Promise<infer R> ? R : T;

/**
 * ...
 */
export interface LoginOptions {
  email: string;
  password: string;
}

/**
 * ...
 */
export class AuthService {
  #loggedIn = false;
  #authenticating = false;
  #activeInstId: string | null = null;
  #sessionChecked = false;
  #timeSinceLastSessionTag = -1;
  #mfaChoice: string = '';
  #mfaCellPhone: string = '';
  // #forceLogout = null;
  // #permissions = null;

  /**
   * ...
   */
  get loggedIn() {
    return this.#loggedIn;
  }

  /**
   * ...
   */
  get loggedInAsync() {
    return new Promise<boolean>((resolve) => {
      void this.getSession().then((session) => {
        resolve(!!session);
      });
    });
  }

  /**
   * ...
   */
  get user() {
    return this.$store.state.me;
  }

  /**
   * ...
   */
  get institutionId() {
    return this.#activeInstId;
  }

  /**
   * ...
   */
  get mfaChoice() {
    return this.#mfaChoice;
  }

  /**
   * ...
   */
  set mfaChoice(choice) {
    this.#mfaChoice = choice;
  }

  /**
   * ...
   */
  get mfaCellPhone() {
    return this.#mfaCellPhone;
  }

  /**
   * ...
   */
  set mfaCellPhone(number) {
    this.#mfaCellPhone = number;
  }

  constructor(
    private readonly $rootScope: angular.IRootScopeService,
    private readonly $http: angular.IHttpService,
    private readonly $uibModalStack: angular.ui.bootstrap.IModalStackService,
    private readonly Notification: angular.uiNotification.INotificationService,
    private readonly $store: angular.gears.IStoreService,
    private readonly $api: angular.gears.IApiService,
    private readonly $api2: angular.gears.IAPI2Service,
    private readonly $ls: angular.gears.ILsService,
    private readonly $acl: angular.gears.IAclService,
    private readonly $modals: angular.gears.IModalsService,
    private readonly notify: angular.gears.INotifyService,
    private readonly aggregateReportsMonitor: unknown,
    private readonly aggregateUserReportsMonitor: unknown,
    private readonly activityReportsMonitor: unknown
  ) {
    'ngInject';

    const lastLoadedCache = localStorage.getItem('lastLoaded');
    const dateTime = lastLoadedCache ? new Date(lastLoadedCache) : null;

    // Get time differnce between vurrent datetime and the last session tag.
    this.#timeSinceLastSessionTag = !dateTime
      ? -1
      : 0.001 * (Date.now() - dateTime.getTime());

    // console.log('timeSinceLastSessionTag', this.#timeSinceLastSessionTag);
  }

  /**
   * ...
   *
   * @return
   */
  async getSession() {
    let session: CognitoUserSession | null = null;

    if (this.#authenticating) {
      return new Promise((resolve) => {
        this.$rootScope.$once(
          'authComplete',
          (_: Event, session: CognitoUserSession) => resolve(session)
        );
      });
    }

    this.#authenticating = true;

    const completeFetch = (session: CognitoUserSession | null) => {
      // console.log('LOGGED IN:', this.#loggedIn);

      this.#sessionChecked = true;
      this.#authenticating = false;
      this.$rootScope.$broadcast('authComplete', session);

      return session;
    };

    try {
      session = await Auth.currentSession();
      this.#loggedIn = true;
    } catch {
      this.#loggedIn = false;
    }

    if (!this.#loggedIn) {
      return completeFetch(null);
    }

    if (!this.#sessionChecked) {
      this.#sessionChecked = true;

      if (
        this.#timeSinceLastSessionTag === -1 ||
        this.#timeSinceLastSessionTag > 10
      ) {
        await this.logout();

        this.Notification.warning(
          'You were automatically logged out of your session due to being idle.'
        );

        return completeFetch(null);
      }
    }

    if (this.user.id) {
      return completeFetch(session);
    }

    let currentAuthUser = await Auth.currentAuthenticatedUser();

    this.#mfaChoice = await Auth.getPreferredMFA(currentAuthUser);

    if (
      currentAuthUser.attributes['custom:mfa'] === 'EMAIL' &&
      this.#mfaChoice === 'NOMFA'
    ) {
      this.#mfaChoice = 'EMAIL';
    }

    this.#mfaCellPhone = currentAuthUser.attributes['phone_number'];

    let me = await this.$store.dispatch('me/get');

    this.$store.commit('permissions/SET', me.policies);

    // Fetch active institution.
    let activeInstId = this.$ls.get(`${me.id}:activeInstitutionId`);

    // check to make sure if we have activeInstId that it's in their policies array
    if (
      activeInstId &&
      activeInstId !== '*' &&
      !find(me.policies, { institutionId: activeInstId })
    ) {
      activeInstId = null;
      this.$ls.set(`${me.id}:activeInstitutionId`, null);
    }

    let activeInst: unknown = null;

    if (activeInstId === null || activeInstId === undefined) {
      activeInst = await this.$modals.settings.chooseActiveInstitution({
        dismissable: false
      });

      console.log(activeInst);

      if (!activeInst) return;

      if (activeInst === 'noInstitution') activeInst = null;
      if (activeInst)
        activeInst = await this.$store.dispatch(
          'institutions/get',
          activeInst?.id
        );

      this.$ls.set(
        `${me.id}:activeInstitutionId`,
        activeInst ? activeInst.id : '*'
      );

      activeInstId = activeInst?.id;
    } else if (activeInstId !== '*') {
      activeInst = await this.$store.dispatch('institutions/get', activeInstId);
    }

    this.$store.commit('me/SET_PROPS', { institution: activeInst });
    this.$store.commit('permissions/SET_ACTIVE', activeInstId);

    this.setInstitutionConfigs(activeInst);

    // setPermited();

    return completeFetch(session);
  }

  /**
   *
   */
  setInstitutionConfigs(inst: any) {
    // If we are not a GEARS admin and the institution has clientConfig
    // set it for user over the site
    if (inst?.clientConfig) {
      this.$store.commit(
        'clients/setProps',
        { clientConfig: inst.clientConfig },
        true
      );
    }

    // If we are not a GEARS admin and the institution has evaluationConfigs
    // set them for use over the site
    if (inst?.evaluationConfigs?.length) {
      this.$store.commit(
        'tools/setProps',
        { evaluationConfigs: inst.evaluationConfigs },
        true
      );
    }
  }

  /**
   * ...
   *
   * @return
   */
  async setActiveInstitution() {
    const choice = await this.$modals.settings.chooseActiveInstitution();

    if (!choice) return;

    this.$store.commit('setLoadingMessage', 'Loading...');
    this.$store.commit('setIsLoading', true);
    // Clearing store states for new institution
    this.$store.commit('activityReports/CLEAR');
    this.$store.commit('aggregateReports/CLEAR');
    this.$store.commit('aggregateUserReports/CLEAR');
    this.$store.commit('aggregateUserReports/CLEAR');
    this.$store.commit('analytics/CLEAR');
    this.$store.commit('clientRequests/CLEAR');
    this.$store.commit('clientTransfers/CLEAR');
    this.$store.commit('clients/CLEAR');
    this.$store.commit('evaluationRequests/CLEAR');
    this.$store.commit('evaluations/CLEAR');
    this.$store.commit('institutionTransfers/CLEAR');
    this.$store.commit('institutions/CLEAR');
    this.$store.commit('invitations/CLEAR');
    this.$store.commit('locations/CLEAR');
    this.$store.commit('logs/CLEAR');
    this.$store.commit('offenseClassifications/CLEAR');
    this.$store.commit('policies/CLEAR');
    this.$store.commit('reports/CLEAR');
    this.$store.commit('tools/CLEAR');
    this.$store.commit('users/CLEAR');

    const institution = choice === 'noInstitution' ? null : choice;

    this.#activeInstId = institution ? institution.id : '*';

    // if (typeof this.user.id === 'number') {
    this.$ls.set(`${this.user.id}:activeInstitutionId`, this.#activeInstId);
    // }

    if (this.#activeInstId !== '*') {
      const inst = await this.$store.dispatch(
        'institutions/get',
        this.#activeInstId
      );
      this.setInstitutionConfigs(inst);
    }

    this.$store.commit('me/SET_PROPS', { institution });
    this.$store.commit('permissions/SET_ACTIVE', this.#activeInstId);
    this.$store.commit('setIsLoading', false);
  }

  /**
   * ...
   *
   * @param options ...
   * @return
   */
  async login(options: LoginOptions, event: Event) {
    // possible amplify interferance for network error
    if (event) event.preventDefault();
    const { email, password } = options;

    let user = null;
    let error = null;

    try {
      user = await Auth.signIn(email, password);
    } catch (err) {
      error = err;

      if (error?.name === 'QuotaExceededError') {
        // Safari-specific local storage maxed out issue
        // workaround until the root issue is resolved by AWS Auth
        window.localStorage.clear();
        this.notify.display(
          'There was a temporary issue with logging in. Please try again [Quota]',
          'warning'
        );

        throw error;
      }
    }

    if (error?.code === 'PasswordResetRequiredException') {
      // password reset is required. Initiate password reset for the user
      try {
        let pwResetRes = await this.$http.post('/api/password-resets', {
          email
        });

        this.notify.display(
          'Password Reset Is Required. Please check your email for a Reset Code. Use this code on the Forgot Password > Already Have a Reset Code? window.',
          'error',
          true
        );
      } catch (err) {
        this.notify.display(
          'Password Reset Is Required. Please select the "forgot password" option on the login page and follow the instructions',
          'error'
        );
      }

      throw error;
    }

    if (error) {
      this.notify.display(error, 'error');

      throw error;
    }

    if (!user) {
      throw 'User not retrieved';
    }

    // mfa challenge?
    if (user.challengeName) {
      await this._handleChallenge(user);
    }

    let sessionDetails = await this.getSession();
    let me = this.$store.state.me;

    if (me.status === 'NEW_PASSWORD_REQUIRED') {
      await this.$modals.settings.changePassword();
    } else if (me.status === 'RESET_REQUIRED') {
      await this.handlePasswordReset(email);
    }

    this.$rootScope.$broadcast('loggedIn');

    // Hot fix for impropper sessioon loading in IE
    if (this.$store.state.isIE) {
      setTimeout(() => location.reload());
    }

    return me;
  }

  /**
   * ...
   *
   * @return
   */
  async logout() {
    this.$store.commit('SET_LOADING_MESSAGE', 'Logging out...');
    this.$store.commit('SET_IS_LOADING', true);

    try {
      await this.$api2.user.logout();
    } catch (err) {
      console.error(err);
    }

    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) await Auth.signOut();
    } catch (err) {
      console.error(err);
    }

    this.$store.commit('me/SET');
    this.$store.dispatch('clearStates');

    this.#loggedIn = false;

    // close any open modals
    this.Notification.clearAll();
    this.$uibModalStack.dismissAll();
    this.aggregateReportsMonitor.end();
    this.aggregateUserReportsMonitor.end();
    this.activityReportsMonitor.end();

    this.$rootScope.$broadcast('loggedOut');
    this.$store.commit('setIsLoading', false);
  }

  /**
   * ...
   *
   * @return
   */
  async createUser(data: unknown) {
    // await Auth.signUp();
    // let res = await $api.user.createUser(data);
  }

  /**
   * ...
   *
   * @return
   */
  async changePassword(oldPassword: string, newPassword: string) {
    // let res = await $api.user.updatePassword({oldPassword, newPassword,});
  }

  /**
   * Invoke the `$acl` service using the currently active permission profile.
   *
   * @param checks Access check statement(s) to evaluate against the current
   * permission profile.
   * @return `true` if the check passes, otherwise `false`.
   */
  hasAccess(checks: HasAccessCheckOptions) {
    const { profile } = this.$store.state.permissions;

    if (!profile) {
      throw new Error(
        'Must have a valid permission profile set to make an access check.'
      );
    }

    return this.$acl(checks, profile);
  }

  /**
   * Takes an action string and returns the resources associated with the
   * associated statement.
   *
   * @param action ...
   * @return
   */
  getActionResources(action: string) {
    // first make sure we are allowed to make the call
    if (!$actionPrms[action]) {
      console.error(
        '[auth:getActionResources: User is not permitted that action]'
      );

      return;
    }

    let statementAction = find($$permissions.master.actions, (val, key) => {
      return key === action;
    });

    if (!statementAction) {
      console.error(
        '[auth:getActionResources: Could not find statement action in permissions.master.actions list]'
      );
      return;
    }

    return statementAction.resources;
  }

  /**
   * ...
   *
   * @param choice ...
   * @return
   */
  async setMFA(choice: 'NOMFA' | 'SMS' | 'EMAIL') {
    let user = await Auth.currentAuthenticatedUser();

    if (choice === 'NOMFA') {
      try {
        await this.$api.user.twofa({ mfa: choice });
        await Auth.setPreferredMFA(user, choice);
        this.notify.display('MFA Preference Updated to None', 'success');
      } catch (err) {
        this.notify.display(err, 'error');
        throw err;
      }
    } else if (choice === 'SMS') {
      try {
        await this.$api.user.twofa({ mfa: 'SMS' });
        await Auth.setPreferredMFA(user, choice);
        this.notify.display('MFA Preference Updated to SMS', 'success');
      } catch (err) {
        this.notify.display(err, 'error');
        throw err;
      }
    } else if (choice === 'EMAIL') {
      try {
        await Auth.setPreferredMFA(user, 'NOMFA');
        await this.$api.user.twofa({ mfa: 'EMAIL' });
        this.notify.display('MFA Preference Updated to Email', 'success');
      } catch (err) {
        this.notify.display(err, 'error');
        throw err;
      }
    }

    return choice;
  }

  /**
   * ...
   *
   * @return
   */
  async verifyPhoneNumberForMFA() {
    try {
      await Auth.verifyCurrentUserAttribute('phone_number');
    } catch (err) {
      this.notify.display(err, 'error');
      throw err;
    }

    let code = await this.$modals.settings.smsCodeInputModal();
    let res;

    try {
      res = Auth.verifyCurrentUserAttributeSubmit('phone_number', code);
    } catch (err) {
      this.notify.display(err, 'error');
    }

    return res;
  }

  /**
   * ...
   *
   * @return
   */
  private async _handleChallenge(cognitoUser: unknown) {
    const { challengeName, challengeParam } = cognitoUser;

    if (
      challengeName !== 'SMS_MFA' &&
      (challengeName !== 'CUSTOM_CHALLENGE' || challengeParam.mfa !== 'EMAIL')
    ) {
      return;
    }

    this.mfaChoice = challengeName;

    let authCode = await this.$modals.settings.smsCodeInputModal();

    if (!authCode) {
      console.error('Must supply Auth Code');

      this.notify.display('Must supply verification code.', 'error');

      throw new Error('Auth Code not supplied');
    }

    const promise =
      challengeName === 'SMS_MFA'
        ? Auth.confirmSignIn(cognitoUser, authCode, 'SMS_MFA')
        : Auth.sendCustomChallengeAnswer(cognitoUser, authCode);

    let loggedUser = null;
    let error = null;

    try {
      loggedUser = await promise;
    } catch (err) {
      error = err;
    }

    if (error) {
      error =
        error.code === 'NotAuthorizedException'
          ? new Error('Incorrect verification code.')
          : error;

      this.notify.display(error, 'error');

      throw error;
    }

    return loggedUser;
  }

  /**
   * ...
   *
   * @return
   */
  private async handlePasswordReset(email: string) {
    let error: Error | null = null;

    try {
      await this.$http.post('/api/password-resets', { email });
    } catch (err) {
      error = err;
    }

    if (error) {
      this.notify.display(
        'Password Reset Is Required. Please select the "forgot password" option on the login page and follow the instructions',
        'error'
      );
    } else {
      this.notify.display(
        'You will receive an email shortly with instructions to reset your password.',
        'success',
        true,
        'Password Reset Required'
      );
    }

    throw 'Password Reset Required';
  }
}
