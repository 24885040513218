'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class UploadReportTemplateModalController extends Modal {
  submission: any;
  editorFile: any;
  commitMessage: any;

  @Inject $scope;
  @Inject $http;
  @Inject notify;

  $setup() {
    this.submission = this.$props.submission;
    this.editorFile = this.$props.editorFile;

    // manually setting information for legacy conversion
    // this.submission.id = 49;
    // this.editorFile.reportTemplateInformation.id = 49;
    // this.editorFile.reportTemplateInformation.toolCommitId = 'r13uIbmxm';
    // this.editorFile.reportTemplateInformation.toolId = 99;

    this.commitMessage = this.submission.id ? '' : 'Initial Commit';
  }

  finish() {
    this.processing = true;
    let templateData = angular.toJson(this.submission);
    let templateEdit = angular.toJson(this.editorFile);
    let toolId = this.editorFile.reportTemplateInformation.toolId;
    let toolCommitId = this.editorFile.reportTemplateInformation.toolCommitId;
    let name = this.editorFile.reportTemplateInformation.name;

    if (!this.commitMessage.length) {
      this.notify.error('Must supply a commit message');
      return;
    }

    if (!this.submission.id) {
      this.$http
        .post('/api/templates', {
          templateData,
          templateEdit,
          toolId,
          toolCommitId,
          name,
          message: this.commitMessage,
          files: null
        })
        .then(response => {
          this.processing = false;
          if (response.data.error) {
            this.notify.error(
              response.data.feedback ||
                'Error in your submission. Check the console for more details.'
            );
          } else {
            this.notify.success(
              response.data.feedback || 'Template Successfully Uploaded'
            );

            this.newCommitId = response.data.data.commitId;
            this.templateId = response.data.data.templateId || this.template.id;
            this.$close({
              commitId: this.newCommitId,
              templateId: this.templateId,
              toolId,
              success: true
            });
          }
        })
        .catch(err => {
          this.processing = false;

          this.notify.error(
            err.data.feedback ||
              'Error in your submission. Check the console for more details.'
          );
        });
    } else {
      this.$http
        .post(`/api/templates/${this.submission.id}/commit`, {
          templateData,
          templateEdit,
          toolId,
          toolCommitId,
          name,
          message: this.commitMessage,
          files: null
        })
        .then(response => {
          this.processing = false;
          if (response.data.error) {
            this.notify.error(
              response.data.feedback ||
                'Error in your submission. Check the console for more details.'
            );
          } else {
            this.notify.success(
              response.data.feedback || 'Template Successfully Uploaded'
            );

            this.newCommitId = response.data.data.commitId;
            this.templateId = response.data.data.templateId || this.template.id;
            this.$close({
              commitId: this.newCommitId,
              templateId: this.templateId,
              toolId,
              success: true
            });
          }
        })
        .catch(err => {
          this.processing = false;

          this.notify.error(
            err.data.feedback ||
              'Error in your submission. Check the console for more details.'
          );
        });
    }
  }

  close(msg) {
    this.$close(msg);
  }
}

export default angular
  .module('app.uploadReportTemplateModal', [])
  .directive('uploadReportTemplateModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./upload-report-template.html'),
    controller: UploadReportTemplateModalController,
    controllerAs: 'vm'
  })).name;
