'use strict';

import angular from 'angular';

function chooseRoleModalController($scope, $auth, $http, $window) {
  var parent = $scope.$parent;

  this.loading = true;
  this.roles = parent.modal.item;
  this.institutions = [];
  this.devGroups = [];
  this.roleChoice = null;

  console.log(this.roles);

  this.roles.forEach((role, i) => {
    if (role.institutionId !== null) {
      // store the position in roles so it may be reintroduced after
      // getInstitutionName function.
      role.position = i;

      this.institutions.push(role);
    } else if (role.developerGroupId !== null) {
      // store the position in roles so it may be reintroduced after
      // getDeveloperGroupName function.
      role.position = i;

      this.devGroups.push(role);
    } else {
      role.roleName = role.name;
    }
  });

  this.loading = false;

  this.setActiveRole1 = form => {
    if (!form.$valid) {
      return;
    }

    // $auth.setActiveRole(this.roleChoice);
    // $window.location.reload();
    parent.$close(this.roleChoice);
  };

  this.setRole = role => {
    this.roleChoice = role;
  };
}

export default angular
  .module('app.chooseRoleModal', [])
  .directive('chooseRoleModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./choose-role.html'),
    controller: ChooseRoleModalController,
    controllerAs: 'vm'
  })).name;
