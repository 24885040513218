'use strict';

import angular from 'angular';

function riskCategoryLabel() {
  'ngInject';

  return function evlAnswer({ low, high }) {
    if (low === '-' && high === '-') {
      return '-';
    }
    if (typeof parseInt(low, 10) === 'number' && typeof parseInt(high, 10) === 'number') {
      return parseInt(low, 10) === parseInt(high, 10)
      ? `${high}`
      : `${low} to ${high}`;
    }
  };
}

export default angular
  .module('app.riskCategoryLabel', [])
  .filter('riskCategoryLabel', riskCategoryLabel).name;
