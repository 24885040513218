import Directive from '@decorators/ngDirective';

@Directive({
  name: 'listItem',
  restrict: 'E',
  replace: true,
  scope: { type: '<', action: '&' },
  props: ['label', 'icon'],
  template: require('./list-item.html')
})
export class ListItem {}

export default ListItem;
