import angular from 'angular';
import { MutationTree, GetterTree } from 'angular-store';

import { PermissionProfile, StatementMap } from '@interfaces/permissions';
import { UserPolicy } from '@interfaces/user-policy';
import { RootState } from '@store/state';

interface PolicyMap {
  name: string | null;
  institutionId: string | null;
  statementMap: StatementMap;
}

export interface PermissionsState {
  source: UserPolicy[];
  maps: PolicyMap[];
  profile: PermissionProfile | null;
  active: boolean | null;
}

/** ... */
export type SetPermissionsMutationOptions = UserPolicy | UserPolicy[];
/** ... */
export type SetActiveProfileMutationOptions = string;

function PermissionsStore(
  $rootScope: angular.IRootScopeService,
  utils: angular.gears.IUtilsService,
  policyTranslator: angular.gears.IPolicyTranslatorService
) {
  'ngInject';

  const state: PermissionsState = {
    source: [],
    maps: [],
    profile: null,
    active: null
  };

  Object.defineProperty(window, 'masterPolicy', {
    get: () => state.active
  });

  const getters: GetterTree<PermissionsState, RootState> = {
    getResources: (state) => (actionKey: string, resourceType: string) => {
      const action = state.profile?.allowed[actionKey] ?? null;

      return action === null
        ? null
        : action === '*'
        ? '*'
        : action[resourceType];
    }
  };

  const mutations: MutationTree<PermissionsState> = {
    SET(state, options: SetPermissionsMutationOptions) {
      const policies = utils.makeArray(options);

      const maps = policies.map<PolicyMap>((policy) => ({
        name: policy.name,
        institutionId: policy.institutionId,
        statementMap: policyTranslator.mapStatement(policy.statement)
      }));

      state.source = policies;
      state.maps = maps;
    },
    SET_ACTIVE(state, options: SetActiveProfileMutationOptions) {
      // TODO:Dane -- change no ID value ('*') to undefined/null for clarity
      const id = options === '*' ? null : options;

      let map = state.maps.find((map) => map.institutionId == id);

      if (!map) {
        return console.error('Could not find policy maps with given id');
      }

      state.profile = policyTranslator.permissionProfile(map.statementMap);

      $rootScope.$broadcast('permissionProfileSet');
    },
    CLEAR(state) {
      Object.assign(state, {
        source: [],
        maps: [],
        profile: null,
        active: null
      });
    }
  };

  Object.defineProperties(mutations, {
    set: { enumerable: true, value: mutations.SET },
    setActive: { enumerable: true, value: mutations.SET_ACTIVE }
  });

  return { state, getters, mutations };
}

export default PermissionsStore;
