'use strict';

import angular from 'angular';

export default angular
  .module('app.mongoose-error', [])
  .directive('mongooseError', () => ({
    restrict: 'A',
    require: 'ngModel',
    link(scope, element, attrs, ngModel) {
      element.on('keydown', () => ngModel.$setValidity('mongoose', true));
    }
  })).name;
