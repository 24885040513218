'use strict';

import angular from 'angular';

import { State } from 'angular-store';
import { Controller, Inject, On, Watch } from '@/decorators/ngCtrl';

@Controller
class DisplayTableColComponent {
  $onInit() {}
}

export default angular
  .module('app.displayTableCol', [])
  .directive('displayTableCol', () => ({
    restrict: 'E',
    replace: true,
    require: '^^displayTable',
    template: require('./display-table-col.html'),
    scope: {
      // label: '<',
      value: '<',
      hide: '<'
    },
    link(scope, element, attr, ctrl) {
      let label = attr.label;

      console.log(scope, element, attr, ctrl);

      let propConfig = {
        label,
        value: scope.value
      };

      if (scope.hide !== undefined) {
        propConfig.hide = scope.hide;
      }

      console.log(ctrl.props);

      ctrl.props.push(propConfig);
    },
    controller: DisplayTableColComponent,
    controllerAs: 'vm'
  })).name;
