import { Gears } from '@gears/db-sdk';

import { camelize } from '@services/utils';

// region Client Field Configs

/** ... */
const CORE_FIELD_CONFIGS = [
  {
    key: 'localId',
    label: 'Client ID',
    required: true,
    show: true,
    alias: null
  },
  {
    key: 'fName',
    label: 'First Name',
    required: true,
    show: true,
    alias: null
  },
  {
    key: 'mName',
    label: 'Middle Name',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'lName',
    label: 'Last Name',
    required: true,
    show: true,
    alias: null
  },
  {
    key: 'alias',
    label: 'Alias',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'dob',
    label: 'Date of Birth',
    required: true,
    show: true,
    alias: null,
    format: 'MM/DD/YYYY'
  },
  {
    key: 'address1',
    label: 'Address 1',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'address2',
    label: 'Address 2',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'address3',
    label: 'Address 3',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'city',
    label: 'City',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'stateProvince',
    label: 'State/Province',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'postalCode',
    label: 'Postal Code',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'country',
    label: 'Country',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'phone',
    label: 'Phone Number',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'email',
    label: 'Email',
    required: false,
    show: true,
    alias: null
  },
  {
    key: 'clinicalInfo',
    label: 'Clinical Info',
    required: false,
    show: true,
    alias: null
  }
] as const;

/** ... */
const ETHNICITY_FIELD = {
  show: true,
  required: false,
  label: 'Ethnicities',
  type: 'String',
  key: 'ethnicity',
  options: []
};

/** ... */
const TYPE_FIELD = {
  show: true,
  required: false,
  label: 'Client Types',
  type: 'String',
  key: 'type',
  options: []
};

/** ... */
const CORE_FIELD_KEYS = CORE_FIELD_CONFIGS.map(({ key }) => key);

/** Key names of all static `Client` fields.` */
export const CLIENT_STATIC_FIELDS = [
  ...CORE_FIELD_CONFIGS,
  ETHNICITY_FIELD,
  TYPE_FIELD
];

// endregion Client Field Configs

/**
 * ...
 *
 * @param options ...
 * @return ...
 */
function sanatizeOptions(options: cfc.Option[]) {
  return options.map(({ label, value }) => ({ label, value })) as cfc.Option[];
}

/**
 * ...
 *
 * @return ...
 */
function make() {
  // ...
  const coreFields = JSON.parse(
    JSON.stringify(CORE_FIELD_CONFIGS)
  ) as cfc.CoreField[];

  // ...
  const typeField: cfc.ClientTypeField = { options: [] };

  // ...
  const ethnicityField: cfc.ClientEthnicityField = { options: [] };

  // ...
  const customFields: cfc.CustomField[] = [];

  // ...
  return { coreFields, typeField, ethnicityField, customFields } as cfc.Config;
}

/**
 * ...
 *
 * @return ...
 */
function makeCustomField() {
  const customField: cfc.CustomField = {
    type: 'String',
    label: 'LABEL',
    key: 'label',
    required: false, // just used for form purposes
    show: true,
    isList: false, // if true, allows multiple options to be selected
    giveOptions: false,
    options: []
  };

  return customField;
}

/**
 * ...
 *
 * @param items ...
 * @param fieldType ...
 * @return ...
 */
function generateFieldOptions(
  items: Gears.Account.ClientConfiguration.Field.Option,
  fieldType: Gears.Account.ClientConfiguration.Field.Type
) {
  const option = { ...items };

  option.label = option.label.toString();

  if (fieldType === 'Boolean' && typeof option.value !== 'boolean') {
    option.value = !!option.value;
  } else if (fieldType === 'String' && typeof option.value !== 'string') {
    option.value = option.value.toString();
  } else if (fieldType === 'Number' && typeof option.value !== 'number') {
    option.value = parseFloat(option.value);
  }

  return option;
}

/**
 * ...
 *
 * @param clientConfig ...
 * @return
 */
function generateFieldsConfig(clientConfig: Gears.Account.ClientConfiguration) {
  // ...
  const fieldsConfig = make();

  // Core, ethnicity, and type fields.

  for (const [key, val] of Object.entries(clientConfig?.fields ?? {})) {
    if (key === 'ethnicity') {
      fieldsConfig.ethnicityField.options = val.options ?? [];
    } else if (key === 'type') {
      fieldsConfig.typeField.options = val.options ?? [];
    } else {
      const fc = fieldsConfig.coreFields.find((o) => o.key === key);

      if (!fc) continue;

      fc.show = val.show;
      fc.required = !val.allowNull;
      fc.alias = val.alias;
    }
  }

  // Custom fields.

  for (const [key, value] of Object.entries(clientConfig?.customFields ?? {})) {
    const fc: cfc.CustomField = {
      key,
      type: value.type,
      label: value.label,
      required: !value.allowNull,
      show: value.show,
      isList: value.isList,
      giveOptions: Array.isArray(value.options)
    };

    if (value.options) {
      fc.options = value.options.map((option) =>
        generateFieldOptions(option, value.type)
      );
    }

    fieldsConfig.customFields.push(fc);
  }

  return fieldsConfig;
}

/**
 * ...
 *
 * @param fieldsConfig ...
 * @return ...
 */
function generateClientConfig(fieldsConfig: Partial<cfc.Config>) {
  const fields: Gears.Account.ClientConfiguration['fields'] =
    Object.fromEntries(
      [...CORE_FIELD_CONFIGS, ETHNICITY_FIELD, TYPE_FIELD].map((item) => {
        const field: Gears.Account.ClientConfiguration.Field = {
          key: item.key,
          type: 'String',
          label: item.label,
          allowNull: !item.required,
          show: item.show
        };

        return [item.key, field];
      })
    );

  if (fieldsConfig.coreFields) {
    for (const config of fieldsConfig.coreFields) {
      const field = fields[config.key];
      field.allowNull = !config.required;
      field.show = config.show;

      if (config.alias) {
        field.alias = config.alias;
      }
    }
  }

  if (fieldsConfig.ethnicityField) {
    fields.ethnicity.options = sanatizeOptions(
      fieldsConfig.ethnicityField.options
    );
  }

  if (fieldsConfig.typeField) {
    fields.type.options = sanatizeOptions(fieldsConfig.typeField.options);
  }

  const customFields: Gears.Account.ClientConfiguration['customFields'] = {};

  if (fieldsConfig.customFields) {
    for (const config of fieldsConfig.customFields) {
      const key = camelize(config.label);

      const field: Gears.Account.ClientConfiguration.Field = {
        key,
        label: config.label,
        type: config.type,
        allowNull: !config.required,
        show: config.show,
        isList: config.isList
      };

      // Only add field options if they were not collapsed.
      if (config.giveOptions && config.options?.length) {
        field.options = config.options;
      }

      customFields[key] = field;
    }
  }

  return { fields, customFields };
}

/**
 * ...
 */
export const cfc = {
  make,
  makeCustomField,
  generateFieldsConfig,
  generateClientConfig
};

export namespace cfc {
  /**
   * ...
   */
  export interface Config {
    coreFields: CoreField[];
    ethnicityField: ClientEthnicityField;
    typeField: ClientTypeField;
    customFields: CustomField[];
  }

  /** ... */
  export interface Field {
    key: string;
    label: string;
    required?: boolean;
    show: boolean;
    alias?: string | null;
  }

  export type CoreField = Field;

  /** ... */
  export interface CustomField extends Field {
    type: 'Boolean' | 'String' | 'Number';
    giveOptions?: boolean;
    format?: string;
    includeOther?: boolean;
    isList?: boolean;
    options?: Option[];
  }

  /** ... */
  export interface ClientTypeField {
    options: Option[];
  }

  /** ... */
  export interface ClientEthnicityField {
    options: Option[];
  }

  /** ... */
  export interface Option {
    label: string;
    value: boolean | string | number;
  }
}
