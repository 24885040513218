import angular from 'angular';
import { State, Mutation, Getter } from 'angular-store';

import { Controller, Inject } from '@decorators/ngCtrl';
import { InstitutionPolicy } from '@interfaces/institution-policy';
import { User } from '@interfaces/user';
import { RootModules } from '@store/state';

import { find, remove } from 'lodash';

interface PolicyData extends InstitutionPolicy {
  institutionName: string | null;
  institutionPolicyId: string | null;
}

interface UserData {
  fName: string | null;
  lName: string | null;
  email: string | null;
  phoneWork: string | null;
  phoneCell: string | null;
  country: string | null;
  address1: string | null;
  address2: string | null;
  address3: string | null;
  stateProvince: string | null;
  city: string | null;
  postalCode: string | null;
  senderId: string | null;
}

@Controller
class DashboardUserView {
  activeSection: number = 0;
  loading: boolean = false;
  phonePattern = /^([a-zA-Z,#/ \.\(\)\-\+\*]*[0-9]){7}[0-9a-zA-Z,#/ \.\(\)\-\+\*]*$/;
  countries: unknown = null;
  stateProvinces: unknown = null;
  userData: UserData = {
    fName: null,
    lName: null,
    email: null,
    phoneWork: null,
    phoneCell: null,
    country: null,
    address1: null,
    address2: null,
    address3: null,
    stateProvince: null,
    city: null,
    postalCode: null,
    senderId: null
  };
  userInstitutionId: string | number | null = -1;
  //Form Groups
  editName: boolean = false;
  editEmail: boolean = false;
  editPhone: boolean = false;
  editAddress: boolean = false;
  editPolicy: boolean = false;
  policyChoice: number = 1;
  //
  managedPolicies: InstitutionPolicy[] = [];
  managedPolicy: InstitutionPolicy | null = null;
  institutionPolicies: InstitutionPolicy[] = [];
  allInstitutionPolicies: Record<string, PolicyData[]> = {};
  institutionPolicy: InstitutionPolicy | null = null;
  customPolicy: InstitutionPolicy | null = null;
  userPolicy: InstitutionPolicy | null = null;
  updateInstitutionSuperAdmin: boolean = false;
  // Other
  $me!: RootModules['me'];
  user!: User;
  userPolicies: PolicyData[] = [];
  isAdmin = false;

  @Inject readonly $scope!: angular.IScope;
  @Inject readonly $rootScope!: angular.IRootScopeService;
  @Inject readonly $location!: angular.ILocationService;
  @Inject readonly $http!: angular.IHttpService;
  @Inject readonly $filter!: angular.IFilterService;
  @Inject readonly $state!: angular.ui.IStateService;
  @Inject readonly Notification!: angular.uiNotification.INotificationService;
  @Inject readonly $modals!: angular.gears.IModalsService;
  @Inject readonly $api!: angular.gears.IApiService;
  @Inject readonly $api2!: angular.gears.IAPI2Service;
  @Inject readonly $auth!: angular.gears.IAuthService;
  @Inject readonly $util!: angular.gears.IUtilService;
  @Inject readonly getFormItems!: angular.gears.IGetItemsService;
  @Inject readonly utils!: angular.gears.IUtilsService;
  @Inject readonly errorHandler!: angular.gears.IErrorHandlerService;

  @State readonly me!: RootModules['me'];
  @State readonly users!: RootModules['users'];
  @State(({ institutions }) => institutions.items)
  readonly institutions!: RootModules['institutions']['items'];
  @Getter readonly activeInstId!: string | null;
  @Mutation('me/setProps') readonly setMe!: unknown;

  get userPermissions() {
    return userPermissions;
  }

  get userId(): string | null {
    return this.$location.search().id ?? null;
  }

  get userIsMe() {
    return this.$me.id === this.user.id;
  }

  $onInit() {
    const { users, tools } = this;

    this.$me = this.me;
    this.isAdmin = !this.$me.institution;

    const user = find(this.users.items, { id: this.userId });

    // Redirect to users pages if specified user can't be found.
    if (!user) {
      return this.$state.go('dashboardUsers');
    }

    this.user = user as User;
    this.userInstitutionId = this.activeInstId;

    void this.loadData();

    this.$scope.$watch(
      () => this.activeSection,
      () => {
        [
          'userName',
          'userEmail',
          'userPhone',
          'userAddress',
          'userPermissions',
          'mfa'
        ].forEach((group) => {
          this.cancelEdit(group);
        });
      }
    );
  }

  async loadData() {
    this.loading = true;

    if (!this.user) {
      console.log('NO USER, TRY TO SET');
    }

    if (this.isAdmin) {
      let res = await this.$api2.gm.getUser({ userId: this.user.id });

      this.user = res;
    } else if (this.$auth.hasAccess(['IM:ListUserActivity'])) {
      let res = await this.$api2.im.listUserActivity({
        institutionId: this.activeInstId || this.userInstitutionId,
        userId: this.user.id
      });

      this.user = res;
    }

    // Set user data object
    this.userData.fName = this.user.fName;
    this.userData.lName = this.user.lName;
    this.userData.email = this.user.email;
    this.userData.phoneWork = this.user.phoneWork;
    this.userData.phoneCell = this.user.phoneCell;
    this.userData.country = this.user.country;
    this.userData.address1 = this.user.address1;
    this.userData.address2 = this.user.address2;
    this.userData.address3 = this.user.address3;
    this.userData.stateProvince = this.user.stateProvince;
    this.userData.city = this.user.city;
    this.userData.postalCode = this.user.postalCode;
    this.userData.senderId = this.user.senderId;

    // Set initial user policies.
    this.userPolicies = [...(this.user.policies ?? [])] as PolicyData[];

    // Get countries
    this.countries = await this.getFormItems.countries();

    // Get stateProvinces
    if (this.user.country) {
      let methodName;

      if (this.user.country === 'US') {
        methodName = 'usStates';
      } else if (this.user.country === 'CA') {
        methodName = 'canadaProvinces';
      } else if (this.user.country === 'GB') {
        methodName = 'ukProvinces';
      } else if (this.user.country === 'AU') {
        methodName = 'auStates';
      }

      this.stateProvinces = await this.getFormItems[methodName]();
    }

    this.loadingData = true;

    // Get managed Polices
    if (this.$auth.hasAccess(['GM:ListManagedPolicies'])) {
      let res = await this.$api.GM.listManagedPolicies();
      this.managedPolicies = res.data || [];
      remove(this.managedPolicies, { id: 'gifradmin' });
    } else if (this.$auth.hasAccess(['IM:ListManagedPolicies'])) {
      let res = await this.$api.IM.listManagedPolicies(this.activeInstId);
      this.managedPolicies = res.data || [];
    }

    // Get institution policies and initialize
    if (!this.isAdmin) {
      this.institutionPolicies = await this.getInstitutionPolcicies();
    } else {
      this.allInstitutionPolicies = await this.getAllInstitutionPolcicies();
    }

    let targetPolicy: InstitutionPolicy | null = null;

    console.log('this.userPolicies', this.userPolicies);

    if (!this.isAdmin) {
      // not admin
      for (let policy of this.userPolicies) {
        if (policy.institutionId == this.userInstitutionId) {
          targetPolicy = policy;
          break;
        }
      }

      if (!targetPolicy) {
        console.warn(
          `None of the user's policies are associated with the current institution ${this.userInstitutionId}`
        );

        this.policyChoice = 3;
      } else if (targetPolicy.managedPolicyId) {
        this.managedPolicy = this.managedPolicies.find(
          ({ id }) => id == targetPolicy.managedPolicyId
        );
        this.userPolicy = this.managedPolicy;
        this.userPolicy.institutionId = targetPolicy.institutionId;
        this.policyChoice = 1;
      } else if (targetPolicy.institutionPolicyId) {
        this.institutionPolicy = this.institutionPolicies.find(
          ({ id }) => id == targetPolicy.institutionPolicyId
        );
        this.userPolicy = this.institutionPolicy;

        this.policyChoice = 2;
      } else {
        this.customPolicy = targetPolicy;
        this.userPolicy = this.customPolicy;

        this.policyChoice = 3;
      }
    } else {
      // isAdmin
      this.userPolicy = this.userPolicies[0];
      this.policySelected();
    }

    this.loading = false;

    this.$scope.$apply();
  }

  /**
   * ...
   */
  policySelected() {
    let policyInstId;

    if (this.userPolicy.institutionId) {
      this.institutionPolicies = this.allInstitutionPolicies[
        this.userPolicy.institutionId
      ];

      policyInstId = this.userPolicy.institutionId;
    }

    if (this.userPolicy.managedPolicyId) {
      let managedPolicyId = this.userPolicy.managedPolicyId;
      this.managedPolicy = this.managedPolicies.find(
        ({ id }) => id == this.userPolicy.managedPolicyId
      );
      this.userPolicy = this.managedPolicy;
      this.userPolicy.managedPolicyId = managedPolicyId;
      if (policyInstId) this.userPolicy.institutionId = policyInstId;

      this.policyChoice = 1;
    } else if (this.userPolicy.institutionPolicyId) {
      this.institutionPolicy = this.institutionPolicies.find(
        ({ id }) => id == this.userPolicy.institutionPolicyId
      );
      this.userPolicy = this.institutionPolicy;

      this.policyChoice = 2;
    } else {
      this.customPolicy = this.userPolicy;
      this.userPolicy = this.customPolicy;

      this.policyChoice = 3;
    }
  }

  /**
   * ...
   *
   * @param groupName ...
   */
  async createInlinePolicy() {
    let policy = await this.$modals.util.policyEditor(
      this.customPolicy || {
        name: 'New User Policy',
        institutionId: this.activeInstId
      },
      true
    );

    if (!policy) {
      return;
    }

    console.log('inlinePolicy created!');

    this.customPolicy = policy;
  }

  /**
   * ...
   *
   * @param groupName ...
   */
  editFormGroup(groupName: string) {
    [
      'userName',
      'userEmail',
      'userPhone',
      'userAddress',
      'userPermissions',
      'mfa'
    ]
      .filter((group) => group != groupName)
      .forEach((group) => {
        this.cancelEdit(group);
      });

    switch (groupName) {
      case 'userName':
        this.editName = true;

        break;
      case 'userEmail':
        this.editEmail = true;

        break;
      case 'userPhone':
        this.editPhone = true;

        break;
      case 'userAddress':
        this.editAddress = true;

        break;
      case 'userPermissions':
        this.editPolicy = true;

        break;
      case 'mfa':
        this.editMFA = true;

        break;
      default:
        console.warn('Group does not exist');
    }
  }

  /**
   * ...
   *
   * @param groupName ...
   */
  cancelEdit(groupName: string) {
    switch (groupName) {
      case 'userName':
        this.editName = false;

        this.userData.fName = this.user.fName;
        this.userData.lName = this.user.lName;

        break;
      case 'userEmail':
        this.editEmail = false;

        this.userData.email = this.user.email;
        break;
      case 'userPhone':
        this.editPhone = false;

        this.userData.phoneWork = this.user.phoneWork;
        this.userData.phoneCell = this.user.phoneCell;

        break;
      case 'userAddress':
        this.editAddress = false;

        this.userData.country = this.user.country;
        this.userData.address1 = this.user.address1;
        this.userData.address2 = this.user.address2;
        this.userData.address3 = this.user.address3;
        this.userData.stateProvince = this.user.stateProvince;
        this.userData.city = this.user.city;
        this.userData.postalCode = this.user.postalCode;

        break;
      case 'userPermissions':
        this.editPolicy = false;

        break;
      default:
        console.warn('Group does not exist');
    }
  }

  /**
   * ...
   *
   * @param groupName ...
   */
  async updateUserInfo(groupName: string) {
    let payload = {
      userId: this.user.id
    };

    switch (groupName) {
      case 'userName':
        payload.fName = this.userData.fName;
        payload.lName = this.userData.lName;

        break;
      case 'userEmail':
        payload.email = this.userData.email;

        break;
      case 'userPhone':
        payload.phoneWork = this.userData.phoneWork;
        payload.phoneCell = this.userData.phoneCell;

        break;
      case 'userAddress':
        payload.country = this.userData.country;
        payload.address1 = this.userData.address1;
        payload.address2 = this.userData.address2;
        payload.address3 = this.userData.address3;
        payload.stateProvince = this.userData.stateProvince;
        payload.city = this.userData.city;
        payload.postalCode = this.userData.postalCode;

        break;

      case 'mfa':
        payload.mfa = this.userData.mfa;

        break;
      default:
        console.warn('Group does not exist');
    }

    try {
      var res =
        groupName === 'mfa'
          ? await this.$api.GM.updateUser2FA(this.user.id, payload)
          : await this.$api.GM.updateUser(this.user.id, payload);

      if (res.data?.error) {
        throw res.data?.error;
      }
    } catch (err) {
      console.log(err);
      this.errorHandler(err);

      return;
    }

    Object.keys(this.userData).forEach((key) => {
      this.user[key] = this.userData[key];
    });

    this.utils.notify('success', 'Update User Successful');

    if (this.user.id == this.me.id) {
      this.setMe(this.userData);
    }

    Object.keys(this.userData).forEach((key) => {
      this.user[key] = this.userData[key];
    });

    this.editName = false;
    this.editEmail = false;
    this.editPhone = false;
    this.editAddress = false;
    this.editPolicy = false;
  }

  /**
   * ...
   */
  async updateUserPolicy() {
    let policy: PolicyData | null = null;

    let payload: Record<string, unknown> = {
      userId: this.user.id,
      managedPolicyId: null,
      institutionPolicyId: null,
      inlinePolicy: null,
      existingInstitutionPolicyId: this.isAdmin
        ? this.userPolicy?.institutionPolicyId
        : this.userPolicy?.id
    };

    let policyInstitutionId;

    switch (this.policyChoice) {
      case 1: {
        policy = this.managedPolicy;
        payload.managedPolicyId = policy.id;

        if (this.userPolicy?.institutionId) {
          policyInstitutionId = this.userPolicy.institutionId;
          policy.institutionId = policyInstitutionId;
        }

        break;
      }
      case 2: {
        policy = this.institutionPolicy;
        policyInstitutionId = this.institutionPolicy.institutionId;
        payload.institutionPolicyId = policy.id;
        policy.institutionPolicyId = policy.id;

        break;
      }
      case 3: {
        policy = this.customPolicy;
        policyInstitutionId = this.customPolicy.institutionId
          ? this.customPolicy.institutionId
          : this.userPolicy.institutionId;
        payload.inlinePolicy = policy;
        policy.isInlinePolicy = true;

        break;
      }
    }

    try {
      if (!this.isAdmin) {
        await this.$api.IM.assignPolicyToUser(
          this.userInstitutionId ? this.userInstitutionId : policyInstitutionId,
          payload
        );
      } else if (this.isAdmin) {
        payload.institutionId = this.userInstitutionId
          ? this.userInstitutionId
          : policyInstitutionId;
        payload.updateInstitutionSuperAdmin = this.updateInstitutionSuperAdmin;
        await this.$api.GM.assignPolicyToUser(payload);
      }

      this.userPolicy = policy;

      // Update the user policies list with new policy

      if (policy) {
        this.userPolicies.splice(
          this.userPolicies.findIndex(
            ({ institutionId }) => institutionId === policy!.institutionId
          ),
          1,
          policy
        );
      }

      if (this.isAdmin) {
        for (let policy of this.userPolicies) {
          if (policy.institutionId) {
            policy.institutionName = find(this.institutions, {
              id: policy.institutionId
            }).name;
          } else {
            policy.institutionName = 'No Institution';
          }
        }
      }

      this.Notification.success('Updated user policy.');
    } catch (err) {
      console.error('FAILED TO UPDATE USER POLICY', err);
      // this.Notification.success('Failed to update user asigned policy.');
    }

    this.editPolicy = false;

    this.$scope.$apply();
  }

  /**
   * ...
   *
   * @return An array of policies beloning to the user's institution.
   */
  private async getInstitutionPolcicies() {
    if (typeof this.userInstitutionId !== 'string') {
      return [];
    }

    let data: unknown = null;

    const options = {
      institutionId: this.userInstitutionId
    };

    try {
      data = await this.$api2.im.listPolicies(options);
    } catch (err) {
      console.error(err);
    }

    return (Array.isArray(data) ? data : []) as InstitutionPolicy[];
  }

  /**
   * ...
   *
   * @return ...
   */
  private async getAllInstitutionPolcicies() {
    const institutionPoliciesMap: Record<string, PolicyData[]> = {};

    for (let i = 0; i < this.userPolicies.length; i++) {
      const policy = this.userPolicies[i];

      if (!policy.institutionId) {
        policy.institutionName = 'No Institution';

        continue;
      }

      const data = await this.$api2.im.listPolicies({
        institutionId: policy.institutionId
      });

      institutionPoliciesMap[policy.institutionId] = data ?? [];

      const match = !policy.institutionPolicyId
        ? null
        : find(data, { id: policy.institutionPolicyId });

      if (match) {
        this.userPolicies[i] = match;
        this.userPolicies[i].institutionPolicyId = policy.institutionPolicyId;
      }

      this.userPolicies[i].institutionName = find(this.institutions, {
        id: policy.institutionId
      }).name;
    }

    return institutionPoliciesMap;
  }
}

export default DashboardUserView;
