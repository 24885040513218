import angular from 'angular';

import { logger } from './logger';
import * as types from './types';

// type SubscriptionHandler<> = (mutation: P, state: S) => unknown

declare module 'angular' {
  namespace store {
    type IStoreService = Store<unknown>;
  }
}

class StoreModule<T> {
  src: types.StoreOptions<T>;
  state: IndexableObject;
  children: Record<string, StoreModule<unknown>> = {};
  parent: StoreModule<unknown> | null = null;

  constructor(src: types.StoreOptions<T>) {
    this.src = src;
    this.state = (src.state as IndexableObject) ?? {};
  }
}

/**
 * ...
 *
 * @param config ...
 * @param path ...
 * @return ...
 */
function createStoreMap(
  config: types.StoreOptions<unknown>,
  path: string[] = []
) {
  const sm = new StoreModule(config);

  if (!config.modules) return sm;

  for (const key of Object.keys(config.modules)) {
    const cm = createStoreMap(config.modules[key], path.concat(key));
    cm.parent = sm;

    sm.children[key] = cm;
  }

  return sm;
}

/**
 * ...
 *
 * @param obj ...
 * @param key ...
 * @param value ...
 * @return ...
 */
function addGetter(
  obj: unknown,
  key: string,
  value: GenericObject | GenericFunction
) {
  const descriptor: PropertyDescriptor = {
    enumerable: true,
    configurable: true,
    get: typeof value === 'function' ? value : () => value
  };

  Object.defineProperty(obj, key, descriptor);
}

/**
 * ...
 *
 * @param src ...
 * @param ref ...
 * @return ...
 */
function makeGetterRef<T extends IndexableObject>(
  src: T,
  proxy?: IndexableObject
) {
  proxy = proxy ?? {};

  for (const key in proxy) delete proxy[key];

  for (const key in src) addGetter(proxy, key, () => src[key]);

  return proxy as T;
}

/**
 * ...
 */
export class Store<S> {
  readonly getters: IndexableObject = {};

  strict = false;

  private readonly rootScope: angular.IRootScopeService;

  #state: S;
  #actions: Record<string, types.Action<S, S>> = {};
  #mutations: Record<string, types.Mutation<S>> = {};
  #modules: { root: StoreModule<unknown> };
  #subscribers: ((mutation: unknown, state: S) => void)[] = [];
  #actionSubscribers: types.ActionSubscribersObject<unknown, S>[] = [];

  get state() {
    return this.#state;
  }

  get actions() {
    return this.#actions;
  }

  get mutations() {
    return this.#mutations;
  }

  constructor(
    rootScope: angular.IRootScopeService,
    options: types.StoreOptions<S>
  ) {
    this.rootScope = rootScope;
    this.#modules = { root: createStoreMap(options) };

    this.strict = !!options.strict;

    this.#state = this.storeSetup(this.#modules.root);
  }

  /**
   * ...
   *
   * @param type ...
   * @param payload ...
   */
  commit(type: string, payload?: unknown) {
    const mutation = { type, payload };

    let entry = this.#mutations[type];

    if (!entry) {
      return console.error(`[ngStore] unknown mutation type: ${type}`);
    }

    entry(payload);

    this.#subscribers.forEach((sub) => sub(mutation, this.state));

    if (process.env.NODE_ENV === 'development') {
      // logger('commit', type, payload);
    }
  }

  /**
   * ...
   *
   * @param type ...
   * @param payload ...
   */
  async dispatch(type: string, payload?: unknown) {
    const action = { type, payload };

    const entry = this.#actions[type];

    if (!entry) {
      return console.error(`[ngStore] unknown action type: ${type}`);
    }

    try {
      this.#actionSubscribers
        .filter((sub) => sub.before)
        .forEach((sub) => sub.before(action, this.state));
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`[ngStore] error in before action subscribers: `);
        console.error(e);
      }
    }

    let res = await entry(payload);

    try {
      this.#actionSubscribers
        .filter((sub) => sub.after)
        .forEach((sub) => sub.after(action, this.state));
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn(`[ngStore] error in after action subscribers: `);
        console.error(e);
      }
    }

    if (process.env.NODE_ENV === 'development') {
      // logger('dispatch', type, payload);
    }

    return res;
  }

  /**
   * ...
   *
   * @param fn ...
   * @return ...
   */
  subscribe<P extends types.MutationPayload>(
    fn: (mutation: P, state: S) => unknown
  ) {
    //
    if (this.#subscribers.indexOf(fn) < 0) {
      this.#subscribers.push(fn);
    }

    return () => {
      const i = this.#subscribers.indexOf(fn);

      if (i > -1) {
        this.#subscribers.splice(i, 1);
      }
    };
  }

  /**
   * ...
   *
   * @param fn ...
   * @return ...
   */
  subscribeAction<P extends types.ActionPayload>(
    fn: types.SubscribeActionOptions<P, S>
  ) {
    //
    fn = typeof fn === 'function' ? { before: fn } : fn;

    if (this.#actionSubscribers.indexOf(fn) < 0) {
      this.#actionSubscribers.push(fn);
    }

    return () => {
      const i = this.#actionSubscribers.indexOf(fn);

      if (i > -1) {
        this.#actionSubscribers.splice(i, 1);
      }
    };
  }

  /**
   * ...
   *
   * @param mod ...
   * @param path ...
   * @return ...
   */
  private storeSetup(mod: StoreModule<unknown>, path: string[] = []) {
    let namespace = path.join('/');

    if (path.length) namespace += '/';

    const state = mod.state ?? {};
    const mutations = mod.src.mutations ?? {};
    const actions = mod.src.actions ?? {};
    const getters = mod.src.getters ?? {};

    if (mod.parent) {
      mod.parent.state[path[path.length - 1]] = state;
    }

    const stateProxy = makeGetterRef(state) as S;

    this.rootScope.$watchCollection(
      () => state,
      (newVal, oldVal) => {
        if (newVal !== oldVal) makeGetterRef(newVal, stateProxy);
      }
    );

    // Register Getters
    for (const [key, fn] of Object.entries(getters)) {
      addGetter(this.getters, `${namespace}${key}`, () => fn(state));
      addGetter(stateProxy, key, () => fn(state));
    }

    const context: IndexableObject = {};

    Object.defineProperties(context, {
      state: { get: () => state },
      rootState: { get: () => this.#modules.root.state },
      getters: { get: () => stateProxy },
      rootGetters: { get: () => this.getters },
      commit: {
        value: (type: string, payload: unknown, atRoot = false) => {
          this.commit((atRoot ? '' : namespace) + type, payload);
        }
      },
      dispatch: {
        value: (type: string, payload: unknown, atRoot = false) => {
          return this.dispatch((atRoot ? '' : namespace) + type, payload);
        }
      }
    });

    // Register Mutations
    for (const [key, fn] of Object.entries(mutations)) {
      this.#mutations[`${namespace}${key}`] = (payload) => {
        fn(state, payload);

        this.runDigest();
      };
    }

    // Register Actions
    for (const [key, fn] of Object.entries(actions)) {
      this.#actions[`${namespace}${key}`] = (payload) => {
        let output = fn(context, payload);

        this.runDigest();

        return output;
      };
    }

    // Setup any children modules
    for (const [key, child] of Object.entries(mod.children)) {
      // let childStateRef = _store::storeSetup(child, path.concat(key));
      // let childStateRef = storeSetup.bind(_store)(child, path.concat(key));
      const childStateRef = this.storeSetup(child, path.concat(key));

      addGetter(stateProxy, key, childStateRef);
    }

    return stateProxy;
  }

  /**
   * ...
   *
   * @return
   */
  private runDigest() {
    setTimeout(() => this.rootScope.$apply() as void);
  }
}

export default Store;
