'use strict';
import * as _ from 'lodash';

const angular = require('angular');

/*@ngInject*/
export function convertLegacyToolService(Block) {
  // loads the template file retrieved from s3 storage (if template editor file is not available)
  this.loadTemplateFile = function(file) {
    let template = {};
    console.log('Template File', file);
    template.connectors = [];
    /*
    TODO (Alex) create connectors in template.connectors
     */

    // build...
    let x = 100;
    let y = 100;
    // reportTemplateInformation
    template.reportTemplateInformation = new Block.ReportTemplateInformation({
      name: file.name,
      version: file.version,
      id: file.id,
      x,
      y
    });

    // overview
    x += 150;
    template.overview = new Block.Overview({
      title: file.reportCriteria.overview.title,
      hasToggle: file.reportCriteria.overview.hasToggle,
      toggleDefault: file.reportCriteria.overview.toggleDefault,
      x,
      y
    });
    let overviewOptionOutputId = template.overview.rows[2].outputNodeId;
    _.forEach(file.reportCriteria.overview.options, function(option) {
      y += 150;
      x += 150;
      let newOption = new Block.OverviewOption({
        text: option.text,
        isDefault: option.isDefault,
        hasToggle: option.hasToggle,
        content: option.content,
        x,
        y
      });
      newOption.rows[0].inputIds.push(overviewOptionOutputId);
      template.overview.rows[2].outputIds.push(newOption.rows[0].inputNodeId);
      template.connectors.push({
        inputNode: newOption.rows[0].inputNodeId,
        outputNode: overviewOptionOutputId
      });
      template.overview.options.push(
        newOption
      );
    });

    // itemDescriptions
    if (file.reportCriteria.itemDescriptions) {
      x = 800;
      y = 150;
      template.itemDescriptions = new Block.ItemDescriptions({
        title: file.reportCriteria.itemDescriptions.title,
        hasToggle: file.reportCriteria.itemDescriptions.hasToggle,
        toggleDefault: file.reportCriteria.itemDescriptions.toggleDefault,
        descriptions: file.reportCriteria.itemDescriptions.descriptions,
        x,
        y
      });
      let itemDescriptionOutputId = template.itemDescriptions.rows[2].outputNodeId;
      _.forEach(template.itemDescriptions.descriptions, function(description) {
        description.rows[0].inputIds.push(itemDescriptionOutputId);
        template.itemDescriptions.rows[2].outputIds.push(description.rows[0].inputNodeId);
        template.connectors.push({
          inputNode: description.rows[0].inputNodeId,
          outputNode: itemDescriptionOutputId
        });
        let descriptionOutputId = description.rows[3].outputNodeId;
        _.forEach(description.answerDescriptions, function(ansDesc) {
          ansDesc.rows[0].inputIds.push(descriptionOutputId);
          description.rows[3].outputIds.push(ansDesc.rows[0].inputNodeId);
          template.connectors.push({
            inputNode: ansDesc.rows[0].inputNodeId,
            outputNode: descriptionOutputId
          });
        });
      });
    }

    // additionalCriteria
    template.additionalCriteria = [];
    template.additionalCriteriaOptions = [];
    template.choices = [];
    x = 50;
    y = 250;
    let acacx;
    let acacy;
    let acox;
    let acoy;
    _.forEach(file.reportCriteria.additionalCriteria, function(ac) {
      console.log('ac', ac);
      x += 150;
      y += 150;
      let additionalCriteria = new Block.AdditionalCriteria({
        title: ac.title,
        hasToggle: ac.hasToggle,
        toggleDefault: ac.toggleDefault,
        sectionType: ac.sectionType,
        options: ac.options,
        x,
        y
      });

      let acOutputId = additionalCriteria.rows[3].outputNodeId;
      _.forEach(additionalCriteria.options, function(option) {
        // set up connection
        option.rows[0].inputIds.push(acOutputId);
        additionalCriteria.rows[3].outputIds.push(option.rows[0].inputNodeId);
        template.connectors.push({
          inputNode: option.rows[0].inputNodeId,
          outputNode: acOutputId
        });

        template.additionalCriteriaOptions.push(option);

        let acoOptionsOutputId = option.rows[3].outputNodeId;
        // create sub options
        if (option.options.length) {
          _.forEach(option.options, function(optionOption) {
            // set up connection
            option.rows[3].outputIds.push(optionOption.rows[0].inputNodeId);
            optionOption.rows[0].inputIds.push(acoOptionsOutputId);
            template.connectors.push({
              inputNode: optionOption.rows[0].inputNodeId,
              outputNode: acoOptionsOutputId
            });

            // check for sub options
            if (optionOption.options.length) {
              let optionOutputId = optionOption.rows[3].outputNodeId;
              _.forEach(optionOption.options, function(optionOptionOption) {
                // set up connection
                optionOption.rows[3].outputIds.push(optionOptionOption.rows[0].inputNodeId);
                optionOptionOption.rows[0].inputIds.push(optionOutputId);
                template.connectors.push({
                  inputNode: optionOptionOption.rows[0].inputNodeId,
                  outputNode: optionOutputId
                });

                template.additionalCriteriaOptions.push(optionOptionOption);
              });
            }

            template.additionalCriteriaOptions.push(optionOption);
          });
        }

        let acoChoicesOutputId = option.rows[4].outputNodeId;
        // create choices
        if (option.choices.length) {
          _.forEach(option.choices, function(choice) {
            // set up connection
            option.rows[4].outputIds.push(choice.rows[0].inputNodeId);
            choice.rows[0].inputIds.push(acoChoicesOutputId);
            template.connectors.push({
              inputNode: choice.rows[0].inputNodeId,
              outputNode: acoChoicesOutputId
            });

            // create choice options
            if (choice.options.length) {
              let choiceOutputId = choice.rows[2].outputNodeId;
              _.forEach(choice.options, function(choiceOption) {
                // set up connection
                choice.rows[2].outputIds.push(choiceOption.rows[0].inputNodeId);
                choiceOption.rows[0].inputIds.push(choiceOutputId);
                template.connectors.push({
                  inputNode: choiceOption.rows[0].inputNodeId,
                  outputNode: choiceOutputId
                });

                template.additionalCriteriaOptions.push(choiceOption);
              });
            }

            template.choices.push(choice);
          });
        }
      });

      template.additionalCriteria.push(additionalCriteria);
    });

    console.log('template', template);
    return template;
  };
  // loads the tool file retrieved from s3 storage (if tool editor file is not available)
  this.loadToolFile = function(toolId, file) {
    console.log(file);
    let tool = {};
    tool.rootToolInformation = new Block.RootToolInformation({
      x: 50,
      y: 50,
      id: toolId,
      name: file.name,
      version: file.version,
      pdfCommitId: null,
      pdfDescriptionId: null,
      agreement: file.agreement,
      changelog: file.changelog
    });
    let i;
    let j;
    let x;
    let y;
    let wqx;
    let wqy;
    let calcx;
    let calcy;
    if (file.riskCategories) {
      if (file.riskCategories.length) {
        tool.riskCategories = [];
        x = 600;
        y = 50;
        for (i = 0; i < file.riskCategories.length; i++) {
          x += 100;
          y += 100;
          file.riskCategories[i].x = x;
          file.riskCategories[i].y = y;
          tool.riskCategories.push(new Block.RiskCategory(file.riskCategories[i]));
        }
      } else {
        tool.riskCategories.push(new Block.RiskCategory({}));
      }
    }
    if (file.dictionary) {
      let dictionaryTerms = [];
      for (i = 0; i < file.dictionary.length; i++) {
        dictionaryTerms.push(new Block.DictionaryTerm(file.dictionary[i]));
      }
      tool.dictionary = new Block.Dictionary({rows: dictionaryTerms, fromToolFile: true, x: 300, y: 50});
    }
    if (file.codingFormItems) {
      if (file.codingFormItems.length) {
        x = 200;
        y = 50;
        tool.codingFormItems = [];
        for (i = 0; i < file.codingFormItems.length; i++) {
          let buildingItemWizard = {};
          let newItemWizard = {};
          let newBlock = {};
          x += 100;
          y += 100;
          if (file.codingFormItems[i].itemWizard) {
            calcx = 50;
            calcy = 300;
            if (file.codingFormItems[i].itemWizard.references) {
              buildingItemWizard.references = [];
              let refs = [];
              for (j = 0; j < file.codingFormItems[i].itemWizard.references.length; j++) {
                refs.push(new Block.WizardReference(file.codingFormItems[i].itemWizard.references[j]));
              }
              buildingItemWizard.references.push(new Block.WizardReferences({
                rows: refs,
                fromToolFile: true,
                x: calcx,
                y: calcy
              }));
            }
            if (file.codingFormItems[i].itemWizard.calculation) {
              let conditions = [];
              for (j = 0; j < file.codingFormItems[i].itemWizard.calculation.conditions.length; j++) {
                conditions.push(new Block.WizardCondition(file.codingFormItems[i].itemWizard.calculation.conditions[j]));
              }
              buildingItemWizard.conditions = [];
              buildingItemWizard.conditions.push(new Block.WizardConditions({
                rows: conditions,
                fromToolFile: true,
                x: calcx,
                y: calcy + 200
              }));
              let resolutions = [];
              for (j = 0; j < file.codingFormItems[i].itemWizard.calculation.resolutions.length; j++) {
                resolutions.push(new Block.WizardResolution(file.codingFormItems[i].itemWizard.calculation.resolutions[j]));
              }
              buildingItemWizard.resolutions = [];
              buildingItemWizard.resolutions.push(new Block.WizardResolutions({
                rows: resolutions,
                fromToolFile: true,
                x: calcx,
                y: calcy + 400
              }));
              let variables = [];
              _.forEach(file.codingFormItems[i].itemWizard.calculation.variables, function(value, key) {
                variables.push(new Block.WizardVariable({name: key, defaultValue: value}));
              });
              buildingItemWizard.variables = [];
              buildingItemWizard.variables.push(new Block.WizardVariables({
                rows: variables,
                fromToolFile: true,
                x: calcx,
                y: calcy + 600
              }));
            }
            let multipleAnswerConditions = [];
            if (file.codingFormItems[i].itemWizard.wizardQuestions) {
              let wizardQuestions = [];
              wqx = -200;
              wqy = 0;
              for (j = 0; j < file.codingFormItems[i].itemWizard.wizardQuestions.length; j++) {
                file.codingFormItems[i].itemWizard.wizardQuestions[j].fromToolFile = true;
                if (wqx < 3500) {
                  wqx += 1000;
                } else {
                  wqx = 800;
                  wqy += 500;
                }
                file.codingFormItems[i].itemWizard.wizardQuestions[j].x = wqx;
                file.codingFormItems[i].itemWizard.wizardQuestions[j].y = wqy;
                // check for MAC's and create them
                if (file.codingFormItems[i].itemWizard.wizardQuestions[j].multipleAnswerConditions.length) {
                  let macs = [];
                  _.forEach(file.codingFormItems[i].itemWizard.wizardQuestions[j].multipleAnswerConditions, function(mac) {
                    mac.fromFile = {
                      wqid: file.codingFormItems[i].itemWizard.wizardQuestions[j].id,
                      action: mac.action,
                      answerScore: mac.answerScore,
                      nextWizardQuestionId: mac.nextWizardQuestionId
                    };
                    macs.push(new Block.MultipleAnswerCondition(mac));
                  });
                  multipleAnswerConditions.push(new Block.MultipleAnswerConditions({
                    rows: macs,
                    fromToolFile: true,
                    x: wqx + 850,
                    y: wqy
                  }));
                }
                wizardQuestions.push(new Block.WizardQuestion(file.codingFormItems[i].itemWizard.wizardQuestions[j]));
              }
              buildingItemWizard.wizardQuestions = wizardQuestions;
            }
            if (multipleAnswerConditions.length) {
              buildingItemWizard.multipleAnswerConditions = multipleAnswerConditions;
            }
            buildingItemWizard.x = 700;
            buildingItemWizard.y = 25;
            newItemWizard = new Block.ItemWizard(buildingItemWizard);
            file.codingFormItems[i].fromToolFile = true;
            file.codingFormItems[i].x = x;
            file.codingFormItems[i].y = y;
            console.log(file.codingFormItems[i]);
            newBlock = new Block.CodingFormItem(file.codingFormItems[i]);
            newBlock.itemWizard = newItemWizard;
            tool.codingFormItems.push(newBlock);
          } else {
            file.codingFormItems[i].fromToolFile = true;
            file.codingFormItems[i].x = x;
            file.codingFormItems[i].y = y;
            tool.codingFormItems.push(new Block.CodingFormItem(file.codingFormItems[i]));
          }
        }
      }
    }
    tool.connectors = this.createConnectors(tool, file);
    return tool;
  };

  // create connectors given a tool editor json
  this.createConnectors = function(tool, toolFile) {
    console.log('tool', tool);
    console.log('toolFile', toolFile);
    let connectors = [];
    let i;
    let j;
    if (tool.codingFormItems) {
      let fileCode;
      for (i = 0; i < tool.codingFormItems.length; i++) {
        fileCode = _.find(toolFile.codingFormItems, {id: tool.codingFormItems[i].id}); // coding item from file for reference
        if (tool.codingFormItems[i].itemWizard) {
          _.forEach(tool.codingFormItems[i].itemWizard.rows, function(row) {
            if (_.find(row.fields, {label: 'First Wizard Question'}) && tool.codingFormItems[i].itemWizard.wizardQuestions.length) {
              // the first wizard question from the tool file is the first wizard question so we set the id here
              console.log(tool.codingFormItems[i]);
              _.forEach(tool.codingFormItems[i].itemWizard.wizardQuestions[0].rows, function(qRow) {
                if (_.find(qRow.fields, {label: 'Question Text'})) {
                  row.outputIds[0] = qRow.inputNodeId;
                  qRow.inputIds.push(row.outputNodeId);
                  connectors.push({
                    inputNode: qRow.inputNodeId,
                    outputNode: row.outputNodeId
                  });
                }
              });
            }
          });
          let fileWQ;
          let nextWQID;
          let answerScore;
          let fAQuery;
          //handle wizard questions
          _.forEach(tool.codingFormItems[i].itemWizard.wizardQuestions, function(wq) {
            fileWQ = _.find(fileCode.itemWizard.wizardQuestions, {id: wq.id});
            for (j = 0; j < wq.rows.length; j++) {
              if (_.find(wq.rows[j].fields, {label: 'Answer ID'})) {
                /* figure out if the answer leads to another wizard question or gives an answer by checking
                  answerScore and nextWizardQuestionId
                   */
                fAQuery = _.find(fileWQ.wizardAnswers, {id: _.find(wq.rows[j].fields, {label: 'Answer ID'}).text});
                if (fAQuery) { // found the file answer
                  if (fAQuery.nextWizardQuestionId.length) { // has a nextWizardQuestionID
                    nextWQID = fAQuery.nextWizardQuestionId;
                    _.forEach(_.find(tool.codingFormItems[i].itemWizard.wizardQuestions, {id: nextWQID}).rows, function(row) {
                      if (_.find(row.fields, {label: 'Question Text'})) {
                        row.inputIds.push(wq.rows[j].outputNodeId);
                        wq.rows[j].outputIds.push(row.inputNodeId);
                        connectors.push({
                          inputNode: row.inputNodeId,
                          outputNode: wq.rows[j].outputNodeId
                        });
                      }
                    });
                  } else if (typeof fAQuery.answerScore === 'number') { // gives and answer score
                    answerScore = fAQuery.answerScore;
                    _.forEach(tool.codingFormItems[i].codesAndScore[0].rows, function(answerRow) {
                      if (_.find(answerRow.fields, {label: 'Answer ID'})) {
                        if (_.find(answerRow.fields, {label: 'Score'}).model === answerScore) {
                          wq.rows[j].outputIds.push(answerRow.inputNodeId);
                          answerRow.inputIds.push(wq.rows[j].outputNodeId);
                          connectors.push({
                            inputNode: answerRow.inputNodeId,
                            outputNode: wq.rows[j].outputNodeId
                          });
                        }
                      }
                    });
                  }
                }
              }
            }
          });
          //handle multiple answer conditions
          _.forEach(tool.codingFormItems[i].itemWizard.multipleAnswerConditions, function(mac) {
            let wq;
            let nextwq;
            let score;
            _.forEach(mac.rows, function(macRow) {
              if (_.find(macRow.fields, {label: 'Wizard Question Input'})) { // we're on the wizard question input row
                wq = _.find(tool.codingFormItems[i].itemWizard.wizardQuestions, {id: mac.rows[1].fromFile.wqid});
                _.forEach(wq.rows, function(wqRow) {
                  if (_.find(wqRow.fields, {label: 'Multiple Answer Conditions'})) {
                    wqRow.outputIds.push(macRow.inputNodeId);
                    macRow.inputIds.push(wqRow.outputNodeId);
                    connectors.push({
                      inputNode: macRow.inputNodeId,
                      outputNode: wqRow.outputNodeId
                    });
                  }
                });
              } else if (macRow.fromFile) {
                //set up the wq connection that it belongs to
                if (macRow.fromFile.action === 'nextQuestion') {
                  nextwq = _.find(tool.codingFormItems[i].itemWizard.wizardQuestions, {id: macRow.fromFile.nextWizardQuestionId});
                  _.forEach(nextwq.rows, function(nextwqRow) {
                    if (_.find(nextwqRow.fields, {label: 'PDF Item Bookmark'})) {
                      macRow.outputIds.push(nextwqRow.inputNodeId);
                      nextwqRow.inputIds.push(macRow.outputNodeId);
                      connectors.push({
                        inputNode: nextwqRow.inputNodeId,
                        outputNode: macRow.outputNodeId
                      });
                    }
                  });
                } else if (macRow.fromFile.action === 'giveScore') {
                  score = macRow.fromFile.answerScore;
                  _.forEach(tool.codingFormItems[i].codesAndScore[0].rows, function(casRow) {
                    if (_.find(casRow.fields, {label: 'Answer ID'})) {
                      if (score === _.find(casRow.fields, {label: 'Score'}).model) {
                        casRow.inputIds.push(macRow.outputNodeId);
                        macRow.outputIds.push(casRow.inputNodeId);
                        connectors.push({
                          inputNode: casRow.inputNodeId,
                          outputNode: macRow.outputNodeId
                        });
                      }
                    }
                  });
                }
              }
            });
          });
          let fileCalc = fileCode.itemWizard.calculation;
          //handle wizard conditions
          if (tool.codingFormItems[i].itemWizard.conditions[0]) {
            let conditionId;
            let variableName;
            let score;
            _.forEach(tool.codingFormItems[i].itemWizard.conditions[0].rows, function(row) {
              if (_.find(row.fields, {label: 'Variable'}) && _.find(row.fields, {label: 'Condition ID'})) {
                conditionId = _.find(row.fields, {label: 'Condition ID'}).text;
                variableName = _.find(fileCalc.conditions, {conditionId}).left;
                score = _.find(fileCalc.conditions, {conditionId}).right;
                _.forEach(tool.codingFormItems[i].codesAndScore[0].rows, function(scoreRow) { // set score connector
                  if (_.find(scoreRow.fields, {label: 'Score'})) {
                    if (score === _.find(scoreRow.fields, {label: 'Score'}).model) {
                      scoreRow.inputIds.push(row.outputNodeId);
                      row.outputIds.push(scoreRow.inputNodeId);
                      connectors.push({
                        inputNode: scoreRow.inputNodeId,
                        outputNode: row.outputNodeId
                      });
                    }
                  }
                });
                _.forEach(tool.codingFormItems[i].itemWizard.variables[0].rows, function(varRow) { // set variable connector
                  if (_.find(varRow.fields, {label: 'Variable Name'})) {
                    if (variableName === _.find(varRow.fields, {label: 'Variable Name'}).model) {
                      varRow.outputIds.push(row.inputNodeId);
                      row.inputIds.push(varRow.outputNodeId);
                      connectors.push({
                        inputNode: row.inputNodeId,
                        outputNode: varRow.outputNodeId
                      });
                    }
                  }
                });
              }
            });
          }
          //handle wizard resolutions
          if (tool.codingFormItems[i].itemWizard.resolutions[0]) {
            let resId;
            let res;
            let cond1;
            let cond2;
            let score;
            _.forEach(tool.codingFormItems[i].itemWizard.resolutions[0].rows, function(row) {
              if (_.find(row.fields, {label: '2 Conditions'}) && _.find(row.fields, {label: 'Code and Score'})) {
                resId = row.id;
                res = _.find(fileCalc.resolutions, {resolutionId: resId});
                cond1 = res.condition1;
                cond2 = res.condition2;
                score = res.result;
                _.forEach(tool.codingFormItems[i].itemWizard.conditions[0].rows, function(condRow) { // set up condition connectors
                  if (_.find(condRow.fields, {label: 'Variable'}) && _.find(condRow.fields, {label: 'Condition ID'})) {
                    if (_.find(condRow.fields, {label: 'Condition ID'}).text === cond1 || _.find(condRow.fields, {label: 'Condition ID'}).text === cond2) {
                      condRow.outputIds.push(row.inputNodeId);
                      row.inputIds.push(condRow.outputNodeId);
                      connectors.push({
                        inputNode: row.inputNodeId,
                        outputNode: condRow.outputNodeId
                      });
                      if (cond1 === cond2) {
                        condRow.outputIds.push(row.inputNodeId);
                        row.inputIds.push(condRow.outputNodeId);
                        connectors.push({
                          inputNode: row.inputNodeId,
                          outputNode: condRow.outputNodeId
                        });
                      }
                    }
                  }
                });
                _.forEach(tool.codingFormItems[i].codesAndScore[0].rows, function(scoreRow) { // set score connector
                  if (_.find(scoreRow.fields, {label: 'Score'})) {
                    if (score === _.find(scoreRow.fields, {label: 'Score'}).model) {
                      scoreRow.inputIds.push(row.outputNodeId);
                      row.outputIds.push(scoreRow.inputNodeId);
                      connectors.push({
                        inputNode: scoreRow.inputNodeId,
                        outputNode: row.outputNodeId
                      });
                    }
                  }
                });
              }
            });
          }
        }
      }
    }
    console.log(connectors);
    return connectors;
  };
}

export default angular.module('app.convert-legacy-tool', [])
  .service('convertLegacyTool', convertLegacyToolService)
  .name;
