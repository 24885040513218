import angular from 'angular';
import { camelCase } from 'lodash';

import { Ng } from '../ng';

export type Constructor = {
  new (...args: any[]): any;
};

export interface ComponentOptions {
  template?: angular.IDirective['template'];
  name?: string;
  moduleName?: string;
  transclude?: angular.IDirective['transclude'];
  replace?: angular.IDirective['replace'];
}

/**
 * ...
 *
 * @param name Name of the component.
 * @param element The component instance's root DOM element.
 * @param controller The component instance's controller.
 */
function refAssignmentHandler(
  name: string,
  element: angular.IElement,
  controller: Ng
) {
  const refName = element.attr('ng-ref');

  if (!refName) return;

  const parent = controller.$parent?.vm ?? controller.$parent;

  if (!parent) {
    return console.warn(
      `NG component "${name}" tagged with ngRef label "${refName}" could not find a parent component to assign itself to.`
    );
  }

  // if (refName in parent === false) return;

  const descriptor = {
    enumerable: true,
    get: () => controller
  };

  Object.defineProperty(parent, refName, descriptor);
}

/**
 * ...
 *
 * @param name Name of the component.
 * @param scope The component instance's scope object.
 * @param controller The component instance's controller.
 */
function wrapAsyncMethods(name: string, scope: angular.IScope, controller: Ng) {
  const props = Object.getOwnPropertyNames(Object.getPrototypeOf(controller));

  for (const prop of props) {
    const value = controller[prop];

    if (typeof value !== 'function') continue;

    if (/^async/.test(value.toString()) === false) continue;

    const originalMethod = value.bind(controller);

    const wrappedMethod = async (...params: unknown[]) => {
      const interval = setInterval(() => scope.$apply(), 10);

      try {
        return await originalMethod(...params);
      } catch (err) {
        throw err;
      } finally {
        clearInterval(interval);
      }
    };

    controller[prop] = wrappedMethod.bind(controller);
  }
}

/**
 * angularJS Component class decorator.
 *
 * @param options Options for the component.
 * @return Component decorator function.
 */
export const Component = (options?: ComponentOptions) => (
  component: Constructor
) => {
  const template = options?.template ?? '';
  const name = camelCase(options?.name || component.name);
  const moduleName = options?.moduleName || `app.${name}`;

  if (typeof template === 'string' && /<[\s\S]*>/.test(template) === false) {
    console.warn(
      `The template for the included angular component "${name}" may not be valid HTML.`
    );
  }

  component.$module = moduleName;
  component.$inject = Ng.dependencies;

  const scope = component.$props
    ? Object.fromEntries(component.$props.map((prop) => [prop, '=']))
    : true;

  // Set "replace" to default value of "true" if not set to false explicitly.
  const replace = options?.replace === false ? false : true;

  //
  const link: angular.IDirectiveLinkFn = (
    scope,
    element,
    _attributes,
    controller: Ng
  ) => {
    element.addClass('ng-component-root');

    //
    refAssignmentHandler(name, element, controller);

    // Find and wrap any async controller methods to enduce async
    // responsiveness.
    // TODO: Find a better way of doing this.
    wrapAsyncMethods(name, scope, controller);

    //
    // wrapLifecycleHookMethods(name, controller);
  };

  const directiveOptions: angular.IDirective = {
    restrict: 'E',
    replace,
    scope,
    template,
    controller: component,
    controllerAs: 'vm',
    link
  };

  if (options?.transclude) {
    directiveOptions.transclude = options.transclude;
  }

  const directiveFactory = () => directiveOptions;

  angular.module(moduleName, []).directive(name, directiveFactory);
};
