import angular from 'angular';
import { maxBy } from 'lodash';

import { Client } from '@interfaces/client';
import { Institution } from '@interfaces/institution';
import { Tool } from '@interfaces/tool';

interface CasePlanOptions {
  tool: Tool;
  toolCommit: { id: string; toolId: string };
  editCasePlan: unknown;
  masterCasePlanTemplate: unknown;
  previousTemplate?: unknown;
  institution?: Institution | null;
}

export class ToolUtilsService {
  private loading = false;
  private testTool: unknown = null;
  private caseplan: unknown = null;
  private caseplanOptions: CasePlanOptions | null = null;

  private get tools() {
    return this.$store.state.tools.items;
  }

  private get clients() {
    return this.$store.state.clients.items;
  }

  private get isAdmin() {
    return this.$store.getters.isAdmin;
  }

  private get activeInstId() {
    return this.$store.getters.activeInstId;
  }

  constructor(
    private readonly $state: angular.ui.IStateService,
    private readonly $store: angular.gears.IStoreService,
    private readonly $modals: angular.gears.IModalsService,
    private readonly evlUtils: angular.gears.IEvalUtilsService,
    private readonly getItems: angular.gears.IGetItemsService,
    private readonly notify: angular.gears.INotifyService
  ) {
    'ngInject';
  }

  /**
   * ...
   */
  goToToolEditor() {
    this.$state.go('toolCreatorV2');
  }

  /**
   * ...
   *
   * @param tool ...
   * @param toolId ...
   */
  async goToEditTool(tool: Tool | null, toolId?: number) {
    if (!tool && toolId) {
      tool = this.tools.find((item) => item.id === toolId) ?? null;
    }

    const commit = await new Promise((resolve) =>
      this.$modals.tool.commitsViewer(tool, null, resolve)
    );

    if (commit.casePlan) {
      this.loadCasePlanManager(commit);
    } else if (commit.newReportTemplate) {
      this.$state.go('toolCreatorV2', { commit, newReportTemplate: true });
    } else if (commit.test) {
      this.testToolStart(commit);
    } else if (commit.reportTemplateId) {
      this.$state.go('toolCreatorV2', { reportTemplate: commit });
    } else {
      this.$state.go('toolCreatorV2', { commit });
    }
  }

  /**
   * ...
   *
   * @param tool ...
   */
  async editInstitutionCasePlanTemplate(tool: Tool) {
    this.loading = true;

    let instId = this.activeInstId
      ? this.activeInstId
      : this.$store.state?.me?.institution?.id;

    let template = await this.getItems.institutionCasePlanTemplates(
      { id: instId },
      tool
    );

    // we can assume that an institution only has once case plan template for the tool
    if (template && Array.isArray(template)) {
      template = template[0];
    } else {
      return this.notify.display(
        'Could not retrieve Institution Case Plan Templates',
        'error'
      );
    }

    // get list of commits associated with template
    let commits = await this.getItems.casePlanTemplateCommits(
      { id: instId },
      template
    );

    if (!commits || !commits.length) {
      return this.notify.display(
        'Could not find any commits associated with Institution the Case Plan Template',
        'error'
      );
    }

    let commit = maxBy(commits, 'createdAt');

    // find the latest commit and grab the data to load into the editor
    let templateData = await this.getItems.casePlanTemplateCommit(
      commit.institutionId,
      commit.templateId,
      commit.id
    );

    console.log('template data: ', templateData);

    if (templateData.templateData) this.caseplan = templateData.templateData;

    this.caseplanOptions = {
      tool: tool,
      toolCommit: { id: tool.publishedCommitId, toolId: tool.id },
      editCasePlan: templateData.templateData,
      masterCasePlanTemplate: null,
      previousTemplate: templateData,
      institution: this.$store.state.me.institution
    };

    this.evlUtils.evaluation = {};
    this.evlUtils.evaluation.tool = await this.getItems.toolCommitFile({
      id: tool.publishedCommitId,
      toolId: tool.id
    });

    this.evlUtils.evaluation.toolChosen = {
      id: this.evlUtils.evaluation.tool.id,
      name: this.evlUtils.evaluation.tool.name
    };

    let loadToolRes = await this.evlUtils.finishLoadingTool(
      false,
      'dashboardCaseManagement',
      true
    );

    this.loading = false;
  }

  /**
   * ...
   *
   * @param commit ...
   */
  testToolStart(commit: unknown) {
    this.testTool = commit;

    this.$modals.evaluation.selectClient(this.clients, (client) => {
      this.testClientSelected(client);
    });
  }

  /**
   * ...
   *
   * @param client ...
   */
  testClientSelected(client: Client) {
    this.evlUtils.evaluation = { toolChosen: this.testTool };
    this.evlUtils.client = client;
    // Variable to check on tool chosen to see if we need to create a new
    // evaluation.
    this.evlUtils.newEvaluation = true;

    this.$state.go('dashboardEvaluationsPerform');

    this.evlUtils.evaluationToolChosen(true);
  }

  /**
   * ...
   *
   * @param tool ...
   */
  openToolPrice(tool: Tool) {
    this.$modals.tool.setToolPrice(tool);
  }

  /**
   * ...
   *
   * @param tool ...
   */
  openToolDescription(tool: Tool) {
    this.$modals.tool.description(tool);
  }

  /**
   * ...
   *
   * @param caseplan ...
   * @param tool ...
   */
  async loadCasePlanManager(caseplan: unknown = {}, tool?: Tool) {
    // handle institution user cases
    // console.log('isAdmin: ', this.isAdmin);
    // console.log('getters: ', this.$store.getters);

    let institutionUser = !this.isAdmin;

    const institutions = this.isAdmin
      ? this.$store.state.institutions.items
      : this.$store.state.me.institution;

    // initialize variables
    this.loading = true;
    this.caseplan = null;
    this.caseplanOptions = {
      tool: caseplan.tool,
      toolCommit: caseplan.commit ? caseplan.commit : null,
      editCasePlan: caseplan.editCasePlan ? caseplan.editCasePlan : null,
      masterCasePlanTemplate: caseplan.masterCasePlanTemplate
        ? caseplan.masterCasePlanTemplate
        : null
    };

    // initialize evaluation object so we can use this.finishLoadingTool()
    this.evlUtils.evaluation = {};

    if (!caseplan && tool) caseplan.commit = { id: tool.publishedCommitId };

    if (!caseplan.commit && caseplan.publishedCommitId) {
      caseplan.commit = { id: caseplan.publishedCommitId };
    }

    if (tool) {
      let toolFile = await this.getItems.toolCommitFile({
        id: tool.publishedCommitId
      });

      this.evlUtils.evaluation.tool = toolFile;
      caseplan.tool = toolFile;
    }

    if (caseplan.commit && !this.evlUtils.evaluation.tool) {
      this.evlUtils.evaluation.tool = await this.getItems.toolCommitFile(
        caseplan.commit
      );
    }

    this.evlUtils.evaluation.toolChosen = {
      id: this.evlUtils.evaluation.tool.id,
      name: this.evlUtils.evaluation.tool.name
    };

    // if given an editCasePlan template, retrieve the data for it
    if (caseplan.editCasePlan) {
      let ecp = caseplan.editCasePlan;

      if (caseplan.editCasePlan.institutionId) {
        this.caseplan = await this.getItems.casePlanTemplateCommit(
          ecp.institutionId,
          ecp.templateId,
          ecp.commitId
        );
      } else {
        this.caseplan = await this.getItems.managedCasePlanTemplateCommit(
          ecp.commitId,
          ecp.templateId
        );
      }

      console.log('this.caseplan: ', this.caseplan);
    }

    // no caseplan given, initiate the pre check modal for selecting template to work on
    if (!this.caseplan) {
      let precheck;
      let masterTemplate = caseplan.masterCasePlanTemplate;
      let masterTemplateCommits;

      if (!masterTemplate && tool) {
        masterTemplate = tool.masterCasePlanTemplate;
        masterTemplateCommits = tool.masterCasePlanTemplateCommits;
      }

      if (masterTemplate) {
        if (!masterTemplateCommits) {
          masterTemplateCommits =
            await this.getItems.managedCasePlanTemplateCommits(masterTemplate);
        }

        precheck = await this.$modals.tool.casePlanPreCheck({
          masterTemplate,
          masterTemplateCommits,
          institutions,
          tool: caseplan.tool,
          prevCtrl: this,
          institutionUser
        });
      } else {
        precheck = await this.$modals.tool.casePlanPreCheck({
          masterTemplate,
          institutions,
          tool: caseplan.tool,
          prevCtrl: this,
          institutionUser
        });
      }

      console.log('precheck results: ', precheck);

      if (precheck && precheck.closed) {
        // means it was cancelled/closed on purpose
        this.loading = false;
        return;
      }

      if (!precheck) {
        this.notify.display(
          'Must select an existing template to work on',
          'error'
        );
        this.loading = false;
        return;
      }

      if (precheck.casePlanTemplateData) {
        this.caseplan = precheck.casePlanTemplateData;
      }

      if (precheck.casePlanTemplateMetaData) {
        this.caseplanOptions.previousTemplate =
          precheck.casePlanTemplateMetaData;
      }

      if (precheck.institution) {
        this.caseplanOptions.institution = precheck.institution;
        this.caseplanOptions.masterCasePlanTemplate =
          precheck.masterCasePlanTemplate;
        this.caseplanOptions.newInstitutionTemplate =
          precheck.newInstitutionTemplate;

        console.log('this.caseplanOptions: ', this.caseplanOptions);

        if (!this.caseplan) this.caseplan = precheck.masterCasePlanTemplate;
      }

      if (precheck.newMasterTemplate) {
        this.caseplanOptions.newMasterTemplate = true;

        if (!this.caseplan) this.caseplan = {};
      }
    }

    console.log(
      'load case plan manager | this.evaluation: ',
      this.evlUtils.evaluation
    );

    let loadToolRes = await this.evlUtils.finishLoadingTool(
      false,
      'dashboardCaseManagement',
      true
    );

    this.loading = false;
  }
}
