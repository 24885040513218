import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';

import Modal from '@/mixins/modal.mixin';

@Controller
class ActivityLogColumnsDefinitionsModalController extends Modal {}

export default angular
  .module('gifrApp.activityLogColumnsDefinitionsModal', [])
  .directive('activityLogColumnsDefinitionsModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./activity-log-columns-definitions-modal.html'),
    controller: ActivityLogColumnsDefinitionsModalController,
    controllerAs: 'vm'
  })).name;
