'use strict';

import angular from 'angular';

class CmpSetupController {
  clientsTable;

  constructor(SelectionTable, TableViewDataTypes) {
    'ngInject';

    this.clientsTable = new SelectionTable({
        title: 'Clients',
        itemsPerPage: 10,
        loader: async () => {
            let clients = [];

            let data = await this.cmpManager.loadClients();

            let caseIdNum = 1;
            data.forEach(item => {
              let client = Object.assign({}, item);
              client.name = `${item.lName}, ${item.fName} ${item.mName}`;
              client.fullName = `${item.fName} ${item.lName}`;
              client.caseId = `012-${caseIdNum++}`;
              client.planCount = caseIdNum;
              client.lastUpdated = new Date();
              client.lastUpdatedBy = 'John Smith';

              clients.push(client);
            });

            return clients;
          },
          onSelection: selection => {
            this.cmpManager.selectedClient = selection;
            this.cmpManager.isValid = selection != null;
          }
      })
      .column({
        title: 'Name',
        key: 'name',
        type: TableViewDataTypes.string
      })
      .column({
        title: 'ID',
        key: 'id',
        type: TableViewDataTypes.string
      })
      .column({
        title: '# Of Plans',
        key: 'planCount',
        type: TableViewDataTypes.number
      })
      .column({
        title: 'Last Updated',
        key: 'lastUpdated',
        type: TableViewDataTypes.date
      })
      .column({
        title: 'Last Updated By',
        key: 'lastUpdatedBy',
        type: TableViewDataTypes.string
      });

    this.cmpManager.isValid = this.selectedClient != null;
  }

  get selectedClient() {
    return this.clientsTable.selection;
  }

  get interviewInfo() {
    return this.cmpManager.interviewInfo
  }
}

export default angular.module('app.section.cmpSetup', [])
  .component('cmpSetup', {
    template: require('./setup.html'),
    bindings: {
      cmpManager: '=cmpManager'
    },
    controller: CmpSetupController,
    controllerAs: 'vm'
  })
  .name;