// LS/CMI Profile Report Generator
import { find, filter, maxBy, forEach, indexOf } from 'lodash';
import angular from 'angular';
import { format, differenceInYears, isValid } from 'date-fns';
import lscmiImages from './imageDataUrls';
import lscmiPercentiles from './percentiles';
import lscmiProfileReportCoverPage from './coverPage.html';
import lscmiProfileReportTables from './tables';
import 'jspdf-autotable';
import fontDataUrls from '../fontDataUrls';
import JsPDF from 'jspdf';

export default {
  async generate(
    mhsLogoDataUrl,
    pageSetup,
    services,
    client,
    evaluation,
    tool,
    options,
    $me
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let $http = services.$http;
    let $filter = services.$filter;
    let $templateCache = services.$templateCache;
    let $store = services.$store;
    let $auth = services.$auth;
    let Util = services.$util;
    let notify = services.notify;
    let Upload = services.Upload;
    let $reincode = services.$reincode;
    let lscmiTitleDataUrl = lscmiImages.lscmiLogo();

    let parseOption = function (text) {
      // parse options from $me selection to see if it should be included in report
      return find(options, { text: text }).selected;
    };

    let checkEndOfPage = function (y) {
      return y >= pageHeight - margins.bottom - 100;
    };

    let evaluationCompletedDate = evaluation.updatedAt;
    const assessmentDate = evaluation.assignedDate;
    const interviewDate = evaluation.evaluation?.interview?.dateTime || 'N/A';
    // console.log('evaluation before parsing: ', angular.copy(evaluation));
    let evaluator = evaluation.evaluator;
    let offenderHistoryId = evaluation.offenderHistoryId;
    if (evaluation.evaluation) evaluation = evaluation.evaluation;
    if (evaluation.evaluationData) evaluation = evaluation.evaluationData;

    if (!evaluator)
      evaluator =
        evaluation.evaluatorId === $me.id
          ? $me
          : find($store.state.users.items, { id: evaluation.evaluatorId });

    // console.log('Generating LSCMI...');
    // console.log('client: ', client);
    // console.log('evaluation: ', evaluation);
    // console.log('tool: ', tool);
    // console.log('evaluator: ', evaluator);

    let section1Tool = find(tool.childTools, { id: 125 });

    const assessmentAge = differenceInYears(
      new Date(evaluationCompletedDate),
      new Date(client.dob)
    );

    client.adult = assessmentAge >= 18;

    let offenderHistory;
    // if client doesn't have offender history length, pull offender history
    if (!evaluation.offenderHistory && !client?.offenderHistory.length) {
      let clientOffenderHistory = await $http.get(
        `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history`
      );
      if (clientOffenderHistory) {
        client.offenderHistory = filter(clientOffenderHistory.data, (oh) => {
          return oh.toolId === tool.id;
        });
      }
    }
    if (!evaluation.offenderHistory && client?.offenderHistory.length) {
      if (offenderHistoryId) {
        let evaluationOffenderHistory = await $http.get(
          `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history/${offenderHistoryId}`
        );
        offenderHistory =
          evaluationOffenderHistory.status === 200
            ? evaluationOffenderHistory.data.historyData
            : [];
      } else {
        let toolOh = filter(client.offenderHistory, (oh) => {
          return oh.toolId === tool.id;
        });
        let latestOffenderHistory = maxBy(toolOh, 'updatedAt');
        let evaluationOffenderHistory = await $http.get(
          `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history/${latestOffenderHistory.id}`
        );
        offenderHistory =
          evaluationOffenderHistory.status === 200
            ? evaluationOffenderHistory.data.historyData
            : [];
      }
    } else {
      offenderHistory = evaluation.offenderHistory;
    }

    // TODO (Alex) parse offender history for report fields
    // console.log('OFFENDER HISTORY: ', offenderHistory);
    if (!offenderHistory) {
      console.error('No offender history could be found for the evaluation');
      return;
    }
    // reason for assessment
    let assessmentContext = 'N/A'; // probation intake...
    let reasonForAssessment = 'N/A';
    // general criminal history oand offense information
    let criminalHistorySources =
      'No sources were listed for criminal record information.';
    let currentOffenses = 'No current offences were recorded.';
    let priorOffenses = 'No prior offences were recorded.';
    let courtDispositionRecommendations =
      'No court disposition or recommendations were recorded.';
    let circumstancesOfCurrentOffenses =
      'No information regarding the circumstances of the current offence were recorded.';
    let coAccused = 'No information regarding co-accused was recorded.';
    let offenderType = 'N/A';
    let institutionalOffender;
    let clientSetting;
    forEach(offenderHistory?.sections, (section) => {
      if (section.key === 'reasonForAssessmentReassessment') {
        forEach(section.sections, (section) => {
          if (section.key === 'setting' && section.model) {
            if (section.subOption)
              offenderType = Util.decamlize(section.subOption);
            assessmentContext = section.subOption
              ? Util.decamlize(section.subOption)
              : 'N/A';
            reasonForAssessment = section.subOption
              ? `${section.model.label}: ${section.subOption}`
              : `${section.model.label}`;
            if (section.model.label.includes('Institutional')) {
              institutionalOffender = true;
              clientSetting = 'institutional';
            } else if (section.model.label.includes('Community')) {
              institutionalOffender = false;
              clientSetting = 'community';
            }
          }
        });
      }
      if (section.key === 'generalCriminalHistoryAndOffenseInformation') {
        forEach(section.sections, (section) => {
          if (section.key === 'criminalHistoryInformationSources') {
            let sources = '';
            forEach(section.fields, (field) => {
              if (field.model) {
                sources = sources + `${field.model}\n`;
              }
            });
            if (sources.length) criminalHistorySources = sources;
          } else if (section.key === 'offenseHistory') {
            forEach(section.sections, (section) => {
              if (
                section.key === 'currentOffenses' &&
                section.table?.items?.length
              ) {
                currentOffenses = {
                  head: [
                    [
                      'Offence',
                      '# of Counts',
                      'Date',
                      'Disposition',
                      'Institution',
                      'Comments'
                    ]
                  ],
                  body: [],
                  theme: 'grid'
                };
                let item;
                forEach(section.table.items, (offense) => {
                  item = offense.item;
                  let offenseLabel;
                  if (item?.offense?.label) {
                    offenseLabel = item.offense.label;
                  } else if (typeof item?.offense === 'string') {
                    offenseLabel = item.offense;
                  }
                  currentOffenses.body.push([
                    offenseLabel ? offenseLabel : 'N/A',
                    item.numberOfCounts ? item.numberOfCounts : 'N/A',
                    item.dateOfConviction &&
                    isValid(new Date(item.dateOfConviction))
                      ? format(new Date(item.dateOfConviction), 'PP')
                      : 'N/A',
                    item.disposition ? item.disposition : 'N/A',
                    item.institution ? item.institution : 'N/A',
                    item.comments ? $reincode.text(item.comments) : 'N/A'
                  ]);
                });
              } else if (
                section.key === 'priorOffenses' &&
                section.table?.items?.length
              ) {
                priorOffenses = {
                  head: [
                    [
                      'Offence',
                      '# of Counts',
                      'Date',
                      'Disposition',
                      'Institution',
                      'Comments'
                    ]
                  ],
                  body: [],
                  theme: 'grid'
                };
                let item;
                forEach(section.table.items, (offense) => {
                  item = offense.item;
                  let offenseLabel;
                  if (item?.offense?.label) {
                    offenseLabel = item.offense.label;
                  } else if (typeof item?.offense === 'string') {
                    offenseLabel = item.offense;
                  }
                  priorOffenses.body.push([
                    offenseLabel ? offenseLabel : 'N/A',
                    item.numberOfCounts ? item.numberOfCounts : 'N/A',
                    item.dateOfConviction &&
                    isValid(new Date(item.dateOfConviction))
                      ? format(new Date(item.dateOfConviction), 'PP')
                      : 'N/A',
                    item.disposition ? item.disposition : 'N/A',
                    item.institution ? item.institution : 'N/A',
                    item.comments ? $reincode.text(item.comments) : 'N/A'
                  ]);
                });
              } else if (section.key === 'courtDispositionRecommendations') {
                forEach(section.fields, (field) => {
                  if (field.key === 'details' && field.model)
                    courtDispositionRecommendations = field.model;
                });
              }
            });
          } else if (section.key === 'currentOffenses') {
            forEach(section.sections, (section) => {
              if (section.key === 'circumstanceOfCurrentOffenses') {
                forEach(section.fields, (field) => {
                  if (field.key === 'details' && field.model)
                    circumstancesOfCurrentOffenses = field.model;
                });
              } else if (
                section.key === 'coAccused' &&
                section.model &&
                section.model.key === 'yes'
              ) {
                coAccused = {
                  head: [['Relationship to Offender', 'Role of Co-accused']],
                  body: [],
                  theme: 'grid'
                };
                forEach(section.model.fields, (ca) => {
                  if (ca.model && ca.key.includes('a')) {
                    let role = find(section.model.fields, (field) => {
                      return field.key === `${ca.key.substring(0, 1)}b`;
                    });
                    role = role?.model ? role.model : 'N/A';
                    coAccused.body.push([ca.model, role]);
                  }
                });
              }
            });
          }
        });
      }
    });

    // Parse Risk Categories
    let evaluationRiskCategory = evaluation.riskCategory;
    let evaluationRiskCategoryOverride =
      evaluation.overrideRiskCategory && evaluation.overrideRiskCategory.length
        ? evaluation.overrideRiskCategory
        : null;
    let supervisionLevel = 'N/A';
    let evaluationScoreTotal = 'Score Not Calculated';
    let clientBasedOverride;
    let administrativePolicyOverride;
    if (evaluation.data) {
      // find clientBasedOverride at 142>Q-zpyiq8rBE answers
      if (evaluation.data['142>Q-zpyiq8rBE']) {
        if (evaluation.data['142>Q-zpyiq8rBE'].aid === 'A-9elo-8LNcK') {
          clientBasedOverride = 'Yes';
          supervisionLevel = evaluation.data['142>Q-zpyiq8rBE'].fillIn
            ? $reincode.text(evaluation.data['142>Q-zpyiq8rBE'].fillIn)
            : 'Not Provided';
        } else if (
          evaluation.data['142>Q-zpyiq8rBE'].text === '-' ||
          evaluation.data['142>Q-zpyiq8rBE'].text === 'No'
        ) {
          clientBasedOverride = 'No';
        }
      }
      // find administrativePolicyOverride at 142>Q-pRYF4PvNn
      if (evaluation.data['142>Q-pRYF4PvNn']) {
        if (evaluation.data['142>Q-pRYF4PvNn'].aid === 'A-Y1Gjbn6x9F') {
          administrativePolicyOverride = 'Yes';
          supervisionLevel = evaluation.data['142>Q-pRYF4PvNn'].fillIn
            ? $reincode.text(evaluation.data['142>Q-pRYF4PvNn'].fillIn)
            : 'Not provided';
        } else if (
          evaluation.data['142>Q-pRYF4PvNn'].text === '-' ||
          evaluation.data['142>Q-pRYF4PvNn'].text === 'No'
        ) {
          administrativePolicyOverride = 'No';
        }
      }

      // calculate evaluation score total
      evaluationScoreTotal = 0;
      forEach(evaluation.data, (item) => {
        if (typeof item.score === 'number') evaluationScoreTotal += item.score;
      });
    }

    // percentile and norm ranks
    let naNormPercent = lscmiPercentiles.naNorms(
      evaluationScoreTotal,
      client.sex,
      clientSetting
    );
    let recidivismRate = lscmiPercentiles.recidivismRate(
      evaluationScoreTotal,
      clientSetting
    );
    let supervisionSuggestions;
    if (recidivismRate) {
      supervisionSuggestions = recidivismRate?.supervisionOptions
        ? recidivismRate.supervisionOptions
        : 'N/A';
      recidivismRate = recidivismRate?.percentage
        ? recidivismRate.percentage
        : 'N/A';
    } else {
      supervisionSuggestions = 'N/A';
      recidivismRate = 'N/A';
    }

    $templateCache.put(
      'lscmiProfileReportCoverPage.html',
      lscmiProfileReportCoverPage
    );
    let lscmiPRCoverPage = $templateCache.get(
      'lscmiProfileReportCoverPage.html'
    );
    let lscmiPRCoverPageTable = JSON.stringify(
      lscmiProfileReportTables.coverPage()
    );

    // create variable map of known variables in report
    let variableMap = {};
    let key;
    const clientAge = client.age;
    variableMap[`clientAge`] = clientAge;
    const clientLocation = client.location;
    variableMap[`clientLocation`] = clientLocation;
    let clientAssessmentLocation = '';
    if (evaluation.clientZoneName)
      clientAssessmentLocation += `${evaluation.clientZoneName}`;
    if (evaluation.clientRegionName)
      clientAssessmentLocation += ` > ${evaluation.clientRegionName}`;
    if (evaluation.clientSubGroupName)
      clientAssessmentLocation += ` > ${evaluation.clientSubGroupName}`;
    if (clientAssessmentLocation.length == 0)
      clientAssessmentLocation = 'LOCATION NOT FOUND';
    variableMap[`clientAssessmentLocation`] = clientAssessmentLocation;
    for (key in client) {
      if (key === 'type') {
        let typeList = '';
        if (client.type) {
          let types =
            typeof client.type === 'string'
              ? client.type.split(',')
              : client.type;
          if (types.length === 1) {
            typeList = types[0];
          } else {
            forEach(types, (type, index) => {
              if (index === types.length - 1) {
                typeList += `and ${type}`;
              } else {
                typeList += `${type}, `;
              }
            });
          }
        } else {
          typeList = 'No Client Types Provided';
        }
        variableMap[`client.${key}`] = typeList;
      } else if (key === 'fName' || key === 'lName' || key === 'localId') {
        variableMap[`client.${key}`] = client[key] ? client[key] : 'N/A';
      } else {
        variableMap[`client.${key}`] = client[key]
          ? Util.decamlize(client[key])
          : 'N/A';
      }
    }

    // handle gender exclusively
    if (!client.gender) variableMap['client.gender'] = '-';

    variableMap['date.today'] = $filter('dynamicDate')(new Date(), 'longDate');
    variableMap['assessmentAge'] = assessmentAge;
    variableMap['reasonForAssessment'] = reasonForAssessment;
    variableMap['assessmentDate'] = $filter('dynamicDate')(
      new Date(assessmentDate),
      'longDate'
    );
    variableMap['interviewDate'] = $filter('dynamicDate')(
      new Date(interviewDate),
      'longDate'
    );
    let evaluatorName = '';
    if (evaluator.fName) evaluatorName += evaluator.fName;
    if (evaluator.lName) evaluatorName += ' ' + evaluator.lName;
    if (!evaluatorName.length) evaluatorName = 'N/A';

    for (key in evaluator) {
      for (key in evaluator) {
        if (key === 'fName' || key === 'lName') {
          variableMap[`user.${key}`] = evaluator[key];
        } else {
          variableMap[`user.${key}`] = Util.decamlize(evaluator[key]);
        }
      }
    }

    //================ CREATE VARIABLE MAP =================
    let variableRegEx = Object.keys(variableMap).join('|');
    variableRegEx = variableRegEx.replace(/\[/g, '\\[');
    variableRegEx = variableRegEx.replace(/]/g, '\\]');
    variableRegEx = variableRegEx.replace(/\)/g, '\\)');
    variableRegEx = variableRegEx.replace(/\(/g, '\\(');
    variableRegEx = variableRegEx.replace(/#/g, '\\#');
    variableRegEx = variableRegEx.replace(/\+/g, '\\+');
    variableRegEx = new RegExp(variableRegEx, 'gi');

    lscmiPRCoverPage = lscmiPRCoverPage.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    lscmiPRCoverPageTable = lscmiPRCoverPageTable.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    lscmiPRCoverPageTable = JSON.parse(lscmiPRCoverPageTable);
    //================ END CREATE VARIABLE MAP =================
    //=================== PDF DOC SETUP ==================
    let specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    let pdf = new JsPDF('p', 'pt', 'letter');

    // set comfortaa font
    pdf.addFileToVFS('ComfortaaRegular.tff', fontDataUrls.comfortaaRegular());
    pdf.addFont('ComfortaaRegular.tff', 'Comfortaa', 'normal');
    pdf.addFileToVFS('ComfortaaBold.tff', fontDataUrls.comfortaaBold());
    pdf.addFont('ComfortaaBold.tff', 'Comfortaa', 'bold');
    pdf.setFont('Comfortaa');

    let page = 2;

    let header = function () {
      pdf.setFontSize(11);
      pdf.setFontStyle('normal');
      pdf.text(`Profile Report: ${client.name()}`, margins.left, 21);
      pdf.text(`Page ${page}`, pageWidth - margins.left - 50, 21);
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(margins.left, 25, pageWidth - margins.left, 25);
      page = page + 1;
    };

    let footer = function () {
      pdf.addImage(
        mhsLogoDataUrl,
        'JPEG',
        pageWidth - margins.left - 80,
        pageHeight - margins.bottom,
        80,
        35
      );
    };
    //=================== END PDF DOC SETUP ==================
    // ======================COVER PAGE===============================
    pdf.addImage(
      lscmiTitleDataUrl,
      'JPEG',
      margins.left,
      margins.top,
      189,
      102
    );

    pdf.fromHTML(
      lscmiPRCoverPage,
      margins.left,
      margins.top + 100,
      {
        width: margins.width,
        elementHandlers: specialElementHandlers
      },
      function (dispose) {
        // add cover page table with client info
        pdf.autoTable({
          head: lscmiPRCoverPageTable.head,
          body: lscmiPRCoverPageTable.body,
          columnStyles: lscmiPRCoverPageTable.columnStyles,
          startY: 300,
          theme: 'striped',
          headStyles: {
            fillColor: [84, 9, 5],
            textColor: [255, 255, 255]
          }
        });

        // add override details
        pdf.setFontSize(12);
        pdf.text(
          `Client-Based/Clinical Override: ${clientBasedOverride}`,
          margins.left,
          600
        );
        pdf.text(
          `Administrative/Policy Override: ${administrativePolicyOverride}`,
          margins.left,
          620
        );

        // add MHS logo and copyright info
        pdf.setFontSize(9);
        pdf.addImage(mhsLogoDataUrl, 'JPEG', margins.left, 680, 125, 54);
        pdf.text(
          'Copyright © 2004 Multi-Health Systems Inc. All rights reserved.\n' +
            'P.O. Box 950, North Tonawanda, NY 14120-0950\n' +
            '3770 Victoria Park Ave., Toronto, ON M2H 3M6',
          margins.left + 135,
          705
        );

        // ==========================END COVER PAGE==============================
        // ========================== PARSE EVALUATION FOR REPORT ====================
        pdf.addPage();
        header();
        footer();
        let y = margins.top + 10;

        // logic for creating new line or new page if needbe
        let newLine = function (y) {
          if (y + 12 > pageHeight - (margins.top + margins.bottom)) {
            pdf.addPage();
            header();
            footer();
            y = margins.top + 20;
          } else {
            y += 12;
          }
          return y;
        };

        let addText = function (
          text,
          fontSize = 10,
          fontStyle = 'normal',
          align = 'left',
          x
        ) {
          if (!text) {
            console.error('Text parameter must be provided');
            return;
          }
          text = $reincode.text(text);
          if (fontSize > 10 && fontSize <= 14) y += 15;
          if (fontSize > 14 && fontSize < 18) y += 20;
          if (fontSize > 10) checkHeadingFit(y);

          pdf.setFontSize(fontSize);
          pdf.setFontStyle(fontStyle);
          if (!text?.length || typeof text !== 'string') return;
          let lines = pdf.splitTextToSize(text, margins.width);

          forEach(lines, (line) => {
            pdf.setFontSize(fontSize);
            pdf.setFontStyle(fontStyle);
            pdf.text(line, x ? x : margins.left, y, align);
            y = newLine(y);
          });

          y = newLine(y);
        };

        let newPage = function (pageAlreadyAdded) {
          if (!pageAlreadyAdded) pdf.addPage();
          header();
          footer();
          y = margins.top + 14;
        };

        const checkHeadingFit = function (y) {
          const oneFifth = pageHeight / 5;
          if (y + oneFifth >= pageHeight) {
            newPage();
          }
        };

        //region INTRODUCTION
        if (parseOption('Introduction')) {
          addText(`Introduction`, 14, 'bold');
          addText(
            `The LS/CMI is a risk and need assessment tool. This report summarizes the results of the LS/CMI administration and provides information pertinent to the assessment of the individual. The results of this inventory can be used in formulating a case management plan for ${client.name()}. See the LS/CMI User's Manual, published by MHS, for more information about this instrument and its applications.`
          );
          addText(
            'Caution: This computerized report is an interpretive aid and should not be given to the client. The LS/CMI is not intended to be the only instrument used to assess the level of service required by an individual, nor is the LS/CMI to be used as a substitute for sound judgement that utilizes various sources of information.'
          );
        }
        //endregion INTRODUCTION

        //region OFFENDER HISTORY INFORMATION
        if (parseOption('Offender History Information')) {
          addText(`Offender History Information`, 14, 'bold');
          //intro
          addText(
            `${client.name()} is a ${assessmentAge}-year-old, ${
              client.sex
            } offender who was assessed by ${evaluatorName} on ${interviewDate}. The context of this assessment was ${assessmentContext}. The following information summarizes ${client.name()}'s offence history.`
          );
          //criminal history sources
          addText(`Criminal History Sources`, 14, 'bold');
          addText(criminalHistorySources);
          //current offences
          addText(`Current Offence(s)`, 14, 'bold');
          if (typeof currentOffenses === 'string') {
            addText(currentOffenses);
          } else {
            pdf.autoTable({
              head: currentOffenses.head,
              body: currentOffenses.body,
              startY: y,
              theme: currentOffenses.theme,
              rowPageBreak: 'avoid',
              didDrawPage: (hookData) => {
                if (hookData.pageNumber > 1) newPage(true);
                y = hookData.cursor.y + 25;
              }
            });
          }
          //prior offences
          addText(`Prior Offence(s)`, 14, 'bold');
          if (typeof priorOffenses === 'string') {
            addText(priorOffenses);
          } else {
            pdf.autoTable({
              head: priorOffenses.head,
              body: priorOffenses.body,
              startY: y,
              theme: priorOffenses.theme,
              rowPageBreak: 'avoid',
              didDrawPage: (hookData) => {
                if (hookData.pageNumber > 1) newPage(true);
                y = hookData.cursor.y + 25;
              }
            });
          }
          //circumstances of current offenses
          addText(`Circumstances of Current Offence(s)`, 14, 'bold');
          addText(circumstancesOfCurrentOffenses);
          //co-accused
          addText(`Co-accused`, 14, 'bold');
          if (typeof coAccused === 'string') {
            addText(coAccused);
          } else {
            pdf.autoTable({
              head: coAccused.head,
              body: coAccused.body,
              startY: y,
              theme: coAccused.theme,
              rowPageBreak: 'avoid',
              didDrawPage: (hookData) => {
                if (hookData.pageNumber > 1) newPage(true);
                y = hookData.cursor.y + 25;
              }
            });
          }
          //court disposition and recommendations/probation conditions
          addText(
            `Court Disposition and Recommendations/Probation Conditions`,
            14,
            'bold'
          );
          addText(courtDispositionRecommendations);
        }
        //endregion OFFENDER HISTORY INFORMATION

        //region GENERAL NOTES FOR EVALUATION
        if (parseOption('General Notes for Evaluation')) {
          if (checkEndOfPage(y)) {
            pdf.addPage();
            header(page);
            footer();
            y = margins.top + 10;
          }
          addText(`General Notes for Evaluation`, 14, 'bold');
          addText(
            evaluation.generalNotes && evaluation.generalNotes.length
              ? $reincode.text(evaluation.generalNotes)
              : `No Notes Recorded`
          );
        }
        //endregion

        //region LS/CMI GENERAL RISK/NEED FACTORS
        if (parseOption('General Risk/Need Factors')) {
          addText(`Section 1: General Risk/Need Factors`, 14, 'bold');
          addText(
            `The General Risk/Need score reflects the literature's "big eight" risk/need factors (i.e., Criminal History, Education/Employment, Family/Marital, Leisure/Recreation, Companions, Alcohol/Drug Problem, Procriminal Attitude/Orientation, and Antisocial Pattern). The General Risk/Need score can be used in determining ${client.name()}’s level of supervision.`
          );
          addText(`LS/CMI General Risk/Need Total Score`, 14, 'bold');
          addText(
            `The graph below displays ${client.name()}'s General Risk/Need Total score and indicates the classification level associated with that score.`
          );
          // draw total score bar graph
          let tSGHeight = 50;
          let tSGWidth = pageWidth / 2;
          let tSGLineHeight = 15;
          let tSGLineWidth = tSGWidth * (evaluationScoreTotal / 43);
          let tSGIncrements = tSGWidth / 43;
          // write zone titles
          pdf.text('Very', 153 + tSGIncrements, y + 9);
          pdf.text('Low', 153 + tSGIncrements, y + 20);
          pdf.text('Low', 153 + tSGIncrements * 6, y + 9);
          pdf.text('Medium', 153 + tSGIncrements * 12, y + 9);
          pdf.text('High', 153 + tSGIncrements * 23, y + 9);
          pdf.text('Very High', 153 + tSGIncrements * 32, y + 9);
          // draw zone markers
          pdf.setDrawColor(200, 200, 200);
          pdf.line(
            153 + tSGIncrements / 2,
            y,
            153 + tSGIncrements / 2,
            y + tSGHeight
          );
          pdf.line(
            153 + tSGIncrements * 4 + tSGIncrements / 2,
            y,
            153 + tSGIncrements * 4 + tSGIncrements / 2,
            y + tSGHeight
          );
          pdf.line(
            153 + tSGIncrements * 10 + tSGIncrements / 2,
            y,
            153 + tSGIncrements * 10 + tSGIncrements / 2,
            y + tSGHeight
          );
          pdf.line(
            153 + tSGIncrements * 19 + tSGIncrements / 2,
            y,
            153 + tSGIncrements * 19 + tSGIncrements / 2,
            y + tSGHeight
          );
          pdf.line(
            153 + tSGIncrements * 29 + tSGIncrements / 2,
            y,
            153 + tSGIncrements * 29 + tSGIncrements / 2,
            y + tSGHeight
          );
          // draw number ticks
          for (let i = 0; i < 44; i++) {
            if (i % 5 === 0) {
              pdf.text(
                i.toString(),
                153 + tSGIncrements * i - 4,
                y + tSGHeight + 9
              );
            } else if (i === 0 || i === 43) {
              pdf.text(
                i.toString(),
                153 + tSGIncrements * i - 4,
                y + tSGHeight + 9
              );
            }
          }
          // draw graph
          pdf.setDrawColor(0, 0, 0);
          pdf.rect(153, y, tSGWidth, tSGHeight);
          pdf.setDrawColor(255, 0, 0);
          pdf.setLineWidth(tSGLineHeight);
          pdf.line(153, y + 30, 153 + tSGLineWidth, y + 30);
          pdf.setDrawColor(0, 0, 0);
          pdf.setFontSize(10);
          pdf.text(
            `(${evaluationScoreTotal})`,
            153 + tSGLineWidth + 10,
            y + 31
          );
          pdf.setFontSize(12);
          pdf.setFontStyle('bold');
          pdf.text('Total Score', pageWidth / 2 - 20, y + tSGHeight + 30);
          y += tSGHeight + 40;
          //GENERAL RISK/NEED ASSESSMENT BASED ON GENERAL RISK/NEED TOTAL SCORE
          addText(
            `General Risk/Need Assessment Based on General Risk/Need Total Score`,
            14,
            'bold'
          );
          addText(
            `The General Risk/Need Total score of ${evaluationScoreTotal} places ${client.name()} in the ${evaluationRiskCategory} risk/need level. Based on past research with other ${clientSetting} offenders in the ${evaluationRiskCategory} risk/need level, ${client.name()} has approximately a ${recidivismRate} chance of recidivating (i.e., being re-incarcerated within one year). The following guidelines represent some supervision options: ${supervisionSuggestions}`
          );
        }
        //endregion LS/CMI GENERAL RISK/NEED FACTORS

        //region COMPARISON TO NORTH AMERICAN OFFENDERS
        if (parseOption('Comparison to North American Offenders')) {
          addText(
            `Comparison to North American ${
              client.adult ? 'Adult' : 'Young'
            } ${Util.capitalize(client.sex)} ${Util.capitalize(
              clientSetting
            )} Offenders`,
            14,
            'bold'
          );
          addText(
            `${client.name()}'s score is as high or higher than ${naNormPercent} of the normative group* of ${
              client.adult ? 'Adult' : 'Young'
            } ${Util.capitalize(client.sex)} ${Util.capitalize(
              clientSetting
            )} Offenders in North America.`
          );
          addText(
            `*Note: For details on the normative group, see chapter 4 of the LS/CMI User’s Manual.`
          );
        }
        //endregion COMPARISON TO NORTH AMERICAN OFFENDERS

        //region ASSESSMENT OF GENERAL RISK/NEED FACTORS
        if (parseOption('Assessment of General Risk/Need Factors')) {
          let boxHeight = 200;
          // check if the graph is going to be pusehd to the next page
          if (y + boxHeight + 90 >= pageHeight) newPage();
          addText(`Assessment of General Risk/Need Factors`, 14, 'bold');
          addText(
            `The graph below displays ${client.name()}'s risk level for each General Risk/Need subcomponent`
          );
          y += 10;
          // draw box bounds
          let boxWidth = pageWidth / 2;
          let boxIncrements = boxWidth / 5;
          let boxHeightIncrements = boxHeight / 8;
          let boxLeftMargin = 250;
          pdf.setLineWidth(1);
          pdf.setDrawColor(0, 0, 0);
          pdf.rect(boxLeftMargin, y, boxWidth, boxHeight);
          pdf.setDrawColor(200, 200, 200);
          pdf.setFontSize(9);
          for (let i = 0; i < 5; i++) {
            let rc = '';
            switch (i) {
              case 0:
                rc = 'Very Low';
                break;
              case 1:
                rc = 'Low';
                break;
              case 2:
                rc = 'Medium';
                break;
              case 3:
                rc = 'High';
                break;
              case 4:
                rc = 'Very High';
                break;
            }
            pdf.text(
              rc,
              boxLeftMargin + boxIncrements * i,
              y - 10,
              null,
              null,
              'center'
            );
            pdf.line(
              boxLeftMargin + boxIncrements * i,
              y,
              boxLeftMargin + boxIncrements * i,
              y + boxHeight
            );
          }
          // write risk categories
          let toolScore;
          let lineWidth;
          pdf.setDrawColor(255, 0, 0);
          pdf.setLineWidth(10);
          forEach(section1Tool.childTools, (ct, index) => {
            // add section name
            pdf.text(
              `${ct.tabName} ${ct.flyoutName}`,
              boxLeftMargin - 10,
              y + 15 + boxHeightIncrements * index,
              undefined,
              undefined,
              'right'
            );

            // find score and risk category
            toolScore = evaluation.toolScores[ct.address];
            if (!toolScore) {
              console.error(
                `Could not find toolScore associated with ${ct.name}`
              );
              return;
            }
            switch (toolScore.riskCategory) {
              case 'Very Low':
                lineWidth = 15;
                break;
              case 'Low':
                lineWidth = boxIncrements * 1;
                break;
              case 'Medium':
                lineWidth = boxIncrements * 2;
                break;
              case 'High':
                lineWidth = boxIncrements * 3;
                break;
              case 'Very High':
                lineWidth = boxIncrements * 4;
                break;
            }
            pdf.line(
              boxLeftMargin,
              y + 15 + boxHeightIncrements * index,
              boxLeftMargin + lineWidth,
              y + 15 + boxHeightIncrements * index
            );
            pdf.text(
              `(${toolScore.score})`,
              boxLeftMargin + lineWidth + 10,
              y + 17 + boxHeightIncrements * index
            );
          });
          pdf.text(
            'Risk Level (Score)',
            boxLeftMargin + boxWidth / 2 - 30,
            y + boxHeight + 20
          );
          y += boxHeight + 40;
        }
        //endregion ASSESSMENT OF GENERAL RISK/NEED FACTORS

        //region PROFILE SUMMARY
        if (parseOption('Profile Summary')) {
          // find PROFILE SUMMARY risk lists
          let veryLowRisk = [];
          let lowRisk = [];
          let mediumRisk = [];
          let highRisk = [];
          let veryHighRisk = [];
          let toolScore;
          forEach(section1Tool.childTools, (ct) => {
            toolScore = evaluation.toolScores[ct.address];
            if (toolScore && toolScore.riskCategory) {
              switch (toolScore.riskCategory) {
                case 'Very Low':
                  veryLowRisk.push(`${ct.tabName} ${ct.flyoutName}`);
                  break;
                case 'Low':
                  lowRisk.push(`${ct.tabName} ${ct.flyoutName}`);
                  break;
                case 'Medium':
                  mediumRisk.push(`${ct.tabName} ${ct.flyoutName}`);
                  break;
                case 'High':
                  highRisk.push(`${ct.tabName} ${ct.flyoutName}`);
                  break;
                case 'Very High':
                  veryHighRisk.push(`${ct.tabName} ${ct.flyoutName}`);
                  break;
              }
            }
          });
          addText(`Profile Summary`, 14, 'bold');
          let writeProfileSummarySection = function (arr, rc) {
            if (!arr.length) {
              addText(`No subcomponents were assessed as ${rc} risk.`);
            } else if (arr.length === 1) {
              addText(`One subcomponent was assessed as ${rc} risk:`);
              for (let i = 0; i < arr.length; i++) {
                addText(`- ${arr[i]}`);
              }
            } else {
              addText(
                `${arr.length} subcomponents were assessed as ${rc} risk:`
              );
              for (let i = 0; i < arr.length; i++) {
                addText(`- ${arr[i]}`);
              }
            }
            y += 15;
          };
          writeProfileSummarySection(veryLowRisk, 'Very Low');
          writeProfileSummarySection(lowRisk, 'Low');
          writeProfileSummarySection(mediumRisk, 'Medium');
          writeProfileSummarySection(highRisk, 'High');
          writeProfileSummarySection(veryHighRisk, 'Very High');
        }
        //endregion PROFILE SUMMARY

        //region GENERAL RISK/NEED SUBCOMPONENT AREAS OF STRENGTH
        let strengthQuestionAddresses = [
          {
            address: '125>126>Q-XUpZC0ukY',
            toolName: '1.1 Criminal History'
          },
          {
            address: '125>127>Q-JehMSRVss',
            toolName: '1.2 Education/Employment'
          },
          {
            address: '125>128>Q-a2os1ezd8',
            toolName: '1.3 Family/Marital'
          },
          {
            address: '125>129>Q-kprx8TErH',
            toolName: '1.4 Leisure/Recreation'
          },
          {
            address: '125>130>Q-8SM3vJIJU',
            toolName: '1.5 Companions'
          },
          {
            address: '125>131>Q-_pcrImw1u',
            toolName: '1.6 Alcohol/Drug Problem'
          },
          {
            address: '125>132>Q-WzJCQ5417',
            toolName: '1.7 Procriminal Attitude/Orientation'
          },
          {
            address: '125>133>Q-rguy6jkZX',
            toolName: '1.8 Antisocial Pattern'
          }
        ];
        if (parseOption('General Risk/Need Subcomponent Areas of Strength')) {
          addText(
            `General Risk/Need Subcomponent Areas of Strength`,
            14,
            'bold'
          );
          addText(
            `Each of the eight General Risk/Need subcomponents has been judged by the assessor as to whether or not it represents an area of strength for ${client.name()}. Research has shown that some conditions (i.e., strengths), by virtue of their presence, may serve as protective factors and actively reduce the chances of antisocial conduct. A subcomponent rated as a strength indicates that the circumstances of level of functioning for that subcomponent are so positive that they may reduce the influence of existing risk factors. ${client.name()}'s strengths should be built upon in service planning.`
          );
          addText(
            `The following subcomponents were identified as areas of strength for ${client.name()}:`
          );
          let areasOfStrength = [];
          for (let i = 0; i < strengthQuestionAddresses.length; i++) {
            let evaluationScore =
              evaluation.data[strengthQuestionAddresses[i].address];
            if (evaluationScore?.text === 'Yes') {
              areasOfStrength.push({
                toolName: strengthQuestionAddresses[i].toolName,
                comment: evaluationScore.comment
                  ? $reincode.text(evaluationScore.comment)
                  : 'No notes provided'
              });
            }
          }
          if (areasOfStrength.length) {
            forEach(areasOfStrength, (aos, index) => {
              index > 0
                ? addText(`\n\n${aos.toolName}\n${aos.comment}`)
                : addText(`${aos.toolName}\n${aos.comment}`);
            });
          } else {
            addText('No subcomponents are identified as strengths.');
          }
        }
        //endregion GENERAL RISK/NEED SUBCOMPONENT AREAS OF STRENGTH

        //region DETAILS REGARDING SUBCOMPONENT RISK/NEED
        if (parseOption('Details Regarding Subcomponent Risk/Need')) {
          addText(`Details Regarding Subcomponent Risk/Need`, 14, 'bold');
          addText(
            `This is a list of all endorsed items as well as any notes relating to the subcomponent. An item is considered to be endorsed if it received a “Yes” rating or a rating of 0 or 1 on the following rating scale:`
          );
          addText(
            `3 - A satisfactory situation with little opportunity or need for improvement.`
          );
          addText(
            `2 - A relatively satisfactory situation, with some room for improvement evident.`
          );
          addText(
            `1 - A relatively unsatisfactory situation with a need for improvement.`
          );
          addText(
            `0 - A very unsatisfactory situation with a very clear and strong need for improvement`
          );
          forEach(section1Tool.childTools, (ct, index) => {
            addText(`${ct.tabName} ${ct.flyoutName}`, 14, 'bold');
            let skipList = [
              'Q-K7w8EvVMh',
              'Q-TvhtTH6DS',
              'Q-UD_E0kX2N',
              'Q-0lRKewg1v',
              'Q-xoEViR0h8',
              'Q-zaom2GjVK',
              'Q-ELSyXhSKr',
              'Q-_4IGwPwWJ',
              'Q-D5Gc25W9m',
              'Q-0KfK_BG--',
              'Q-WtZnrcIET',
              'Q-20Nndpcxp',
              'Q-iCUvZQyMp',
              'Q-I1ajg4kp7',
              'Q-MS2d82aD1'
            ];
            forEach(ct.codingFormItems, (cfi) => {
              //skip strength questions and specific 1.8 questions
              if (
                find(strengthQuestionAddresses, (sqa) => {
                  return sqa.address === cfi.longAddress;
                }) ||
                indexOf(skipList, cfi.id) >= 0
              )
                return;
              let evaluationScore = evaluation.data[cfi.longAddress];
              let subAnswerString = '';
              if (cfi.subAnswers) {
                forEach(cfi.subAnswers, (sa) => {
                  subAnswerString = subAnswerString + sa.label + ': ';
                  if (evaluationScore[sa.label]) {
                    subAnswerString =
                      subAnswerString + evaluationScore[sa.label];
                  } else {
                    subAnswerString = subAnswerString + 'Not specified. ';
                  }
                });
              }
              addText(
                `${cfi.customItemNumber}. ${cfi.riskFactor}: ${evaluationScore.text}. ${subAnswerString}`
              );
            });
            if (evaluation.toolNotes && evaluation.toolNotes[ct.address]) {
              addText(`Notes: ${evaluation.toolNotes[ct.address]}`);
            } else {
              addText(`Notes: No notes were recorded for this subcomponent.`);
            }
          });
        }
        //endregion DETAILS REGARDING SUBCOMPONENT RISK/NEED

        //region SUMMARY OF GENERAL RISK/NEED ITEM RESPONSES
        if (parseOption(`Summary of General Risk/Need Item Responses`)) {
          addText(`Summary of General Risk/Need Item Responses`, 14, 'bold');
          addText(
            `The assessor entered the following response values for the LS/CMI General Risk/Need section.`
          );
          let itemResponsesTable = {
            head: [
              ['Item', 'Response', 'Item', 'Response', 'Item', 'Response']
            ],
            body: [],
            theme: 'grid'
          };
          for (let i = 0; i < 20; i++) {
            itemResponsesTable.body.push([]);
          }
          let bodyIndex = 0;
          forEach(section1Tool.childTools, (ct) => {
            forEach(ct.codingFormItems, (cfi) => {
              //skip strength questions
              if (
                find(strengthQuestionAddresses, (sqa) => {
                  return sqa.address === cfi.longAddress;
                })
              )
                return;
              let evaluationScore = evaluation.data[cfi.longAddress];
              itemResponsesTable.body[bodyIndex].push(cfi.customItemNumber);
              itemResponsesTable.body[bodyIndex].push(evaluationScore.text);
              bodyIndex === 19 ? (bodyIndex = 0) : bodyIndex++;
            });
          });
          pdf.autoTable({
            head: itemResponsesTable.head,
            body: itemResponsesTable.body,
            startY: y,
            theme: itemResponsesTable.theme,
            rowPageBreak: 'avoid',
            didDrawPage: (hookData) => {
              if (hookData.pageNumber > 1) newPage(true);
              y = hookData.cursor.y + 25;
            }
          });
        }
        //endregion SUMMARY OF GENERAL RISK/NEED ITEM RESPONSES

        //region ADDITIONAL ITEM INFORMATION
        if (parseOption('Additional Item Information')) {
          addText(`Additional Item Information`, 14, 'bold');
          let evaluationScore;
          forEach(section1Tool.childTools, (ct) => {
            forEach(ct.codingFormItems, (cfi) => {
              evaluationScore = evaluation.data[cfi.longAddress];
              if (cfi.subAnswers) {
                forEach(cfi.subAnswers, (sa) => {
                  if (evaluationScore[sa.label]) {
                    addText(
                      `${cfi.customItemNumber}. ${sa.label}: ${
                        evaluationScore[sa.label]
                      }`
                    );
                  } else {
                    addText(
                      `${cfi.customItemNumber}. ${sa.label}: Not Specified`
                    );
                  }
                });
              }
            });
          });
        }
        //endregion ADDITIONAL ITEM INFORMATION

        //region SUMMARY OF OMITTED RESPONSES
        if (parseOption('Summary of Omitted Responses')) {
          addText(`Summary of Omitted Responses`, 14, 'bold');
          let omittedList = filter(evaluation.data, (data) => {
            return data.text === 'Omit' || data.text === 'omit';
          });
          if (omittedList?.length) {
            forEach(omittedList, (omittedItem) => {
              forEach(section1Tool.childTools, (ct) => {
                forEach(ct.codingFormItems, (cfi) => {
                  if (cfi.longAddress === omittedItem.qid) {
                    addText(
                      `${cfi.customItemNumber}. ${cfi.riskFactor}: Notes: ${
                        omittedItem.comment
                          ? $reincode.text(omittedItem.comment)
                          : 'No notes provided'
                      }`
                    );
                  }
                });
              });
            });
          } else {
            addText(`No General/Risk Need Factor items were omitted.`);
          }
        }
        //endregion SUMMARY OF OMITTED RESPONSES

        //region SECTION 2: SPECIFIC RISK/NEED FACTORS
        let section2Tool = find(tool.childTools, { id: 136 });
        if (parseOption('Section 2: Specific Risk/Need Factors')) {
          addText(`Section 2: Specific Risk/Need Factors`, 14, 'bold');
          addText(
            `The Specific Risk/Need Factors section derives from a review of the research literature. It pinpoints items that may not apply to the general offender population. When "specific" items occur in a given case, they may take a prominent role in the assessment of this offender’s risk. Note that the items are not summed for a total Specific Risk/Need score. Rather, the endorsement of any item should be considered in planning ${client.name()}’s case management plan.`
          );
          addText(
            `Section 2.1: Personal Problems with Criminogenic Potential`,
            14,
            'bold'
          );
          addText(
            `This section focuses on specific attributes and personal characteristics that may be particularly indicative of specific criminogenic needs. Items that were given "Yes" responses for this section are shown below:`
          );
          let ppcp = section2Tool.childTools[0];
          forEach(ppcp.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text === 'Yes'
            ) {
              addText(`${index + 1}. ${cfi.riskFactor}`);
            }
          });
          addText(`Section 2.2: History of Perpetration`, 14, 'bold');
          addText(
            `This section includes a series of historical items that might constitute specific static risk factors. Note that the items refer to history and not simply to the current offence. Items that were given "Yes" responses for this section are shown below:`
          );
          addText(`Section 2.2a: Sexual Assault`, 14, 'bold');
          let hp = section2Tool.childTools[1];
          forEach(hp.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text === 'Yes'
            ) {
              addText(
                `${cfi.customItemNumber ? cfi.customItemNumber : index + 1}. ${
                  cfi.riskFactor
                }`
              );
            }
          });
          addText(
            `Section 2.2b: Nonsexual Physical Assault and Other Forms of Violence`,
            14,
            'bold'
          );
          let hp2 = section2Tool.childTools[2];
          forEach(hp2.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text === 'Yes'
            ) {
              addText(
                `${cfi.customItemNumber ? cfi.customItemNumber : index + 1}. ${
                  cfi.riskFactor
                }`
              );
            }
          });
          addText(
            `Section 2.2c: Other Forms of Antisocial Behavior`,
            14,
            'bold'
          );
          let hp3 = section2Tool.childTools[3];
          forEach(hp3.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text === 'Yes'
            ) {
              addText(
                `${cfi.customItemNumber ? cfi.customItemNumber : index + 1}. ${
                  cfi.riskFactor
                }`
              );
            }
          });
        }
        //endregion SECTION 2: SPECIFIC RISK/NEED FACTORS

        //region SECTION 3: PRISON EXPERIENCE - INSTITUTIONAL FACTORS
        if (
          parseOption(`Section 3: Prison Experience - Institutional Factors`)
        ) {
          addText(
            `Section 3: Prison Experience - Institutional Factors`,
            14,
            'bold'
          );
          let section3Tool = find(tool.childTools, { id: 139 });
          if (institutionalOffender) {
            addText(
              `This section samples items that institutional classification staff have identified as crucial considerations over-and-above the General Risk/Need level and the other sections of the LS/CMI. The endorsement of any item should be considered when developing ${client.name()}'s case management plan.`
            );
            let pastIncarcerationItems = [];
            let presentIncarcerationItems = [];
            let barriersToRelease = [];
            // past incarcerations = 137
            // present incarcerations = 152
            // barriersToRelease = 138
            forEach(section3Tool.childTools, (ct) => {
              forEach(ct.codingFormItems, (cfi) => {
                if (
                  evaluation.data[cfi.longAddress] &&
                  evaluation.data[cfi.longAddress].text.includes('Yes')
                ) {
                  let specify = evaluation.data[cfi.longAddress].fillIn;
                  // addText(
                  //   `${cfi.riskFactors}: ${
                  //     evaluation.data[cfi.longAddress].text
                  //   }. ${specify ? 'Specify: ' + specify : ''}`
                  // );
                  if (ct.id === 137) {
                    pastIncarcerationItems.push(
                      `${cfi.riskFactor}: ${
                        evaluation.data[cfi.longAddress].text
                      }. ${specify ? 'Specify: ' + specify : ''}`
                    );
                  } else if (ct.id === 152) {
                    presentIncarcerationItems.push(
                      `${cfi.riskFactor}: ${
                        evaluation.data[cfi.longAddress].text
                      }. ${specify ? 'Specify: ' + specify : ''}`
                    );
                  } else if (ct.id === 138) {
                    barriersToRelease.push(
                      `${cfi.riskFactor}: ${
                        evaluation.data[cfi.longAddress].text
                      }. ${specify ? 'Specify: ' + specify : ''}`
                    );
                  }
                }
              });
            });
            addText(`Past Incarceration`, 13, 'bold');
            addText(
              `Items that were given "Yes" responses for this section are shown below:`
            );
            if (pastIncarcerationItems.length) {
              forEach(pastIncarcerationItems, (item) => {
                addText(`- ${item}`);
              });
            } else {
              addText(
                `No items were given responses of "Yes" for this section.`
              );
            }

            addText(`Present Incarceration:`, 13, 'bold');
            addText(
              `Items that were given "Yes" responses for this section are shown below:`
            );
            if (presentIncarcerationItems.length) {
              forEach(presentIncarcerationItems, (item) => {
                addText(`- ${item}`);
              });
            } else {
              addText(
                `No items were given responses of "Yes" for this section.`
              );
            }

            addText(`Barriers to Release:`, 13, 'bold');
            addText(
              `Items that were given "Yes" responses for this section are shown below:`
            );
            if (barriersToRelease.length) {
              forEach(barriersToRelease, (item) => {
                addText(`- ${item}`);
              });
            } else {
              addText(
                `No items were given responses of "Yes" for this section.`
              );
            }
          } else {
            addText(
              `This section is not applicable since it is used for prison inmates only.`
            );
          }
        }
        //endregion SECTION 3: PRISON EXPERIENCE - INSTITUTIONAL FACTORS

        //region SECTION 4: OTHER CLIENT ISSUES
        if (
          parseOption(
            `Section 4: Other Client Issues (Social, Health, and Mental Health)`
          )
        ) {
          addText(
            `Section 4: Other Client Issues (Social, Health, and Mental Health)`,
            14,
            'bold'
          );
          addText(
            `This section includes supplementary information that may impact on ${client.name()}’s classification and case management decisions. Note that the items are not summed for a total score. Items that were given "Yes" responses for this section are shown below:`
          );
          let section4Tool = find(tool.childTools, {
            id: 140
          });
          forEach(section4Tool.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text.includes('Yes')
            ) {
              addText(`${index + 1}. ${cfi.riskFactor}`);
            }
            if (
              cfi.id === 'Q-F4xUdZi1Z' &&
              evaluation.data[cfi.longAddress].fillIn
            ) {
              addText(
                `${index + 1}. ${cfi.riskFactor}: ${
                  evaluation.data[cfi.longAddress].fillIn
                }`
              );
            }
          });
        }
        //endregion SECTION 4: OTHER CLIENT ISSUES

        //region SECTION 5: SPECIAL RESPONSIVITY CONSIDERATIONS
        if (parseOption(`Section 5: Special Responsivity Considerations`)) {
          addText(`Section 5: Special Responsivity Considerations`, 14, 'bold');
          addText(
            `This section samples some of the dominant responsivity considerations in clinical research and correctional opinion. Responsivity factors are considerations that may impact upon choice of the most appropriate style and mode of service. Note that the items are not summed for a total score. Items that were given "Yes" responses for this section are shown below:`
          );
          let section5Tool = find(tool.childTools, { id: 141 });
          let section5Items = [];
          forEach(section5Tool.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text.includes('Yes')
            ) {
              // addText(`${index + 1}. ${cfi.riskFactor}`);
              section5Items.push(`${index + 1}. ${cfi.riskFactor}`);
            }
            if (
              cfi.id === 'Q-XJYjgZ39c' &&
              evaluation.data[cfi.longAddress].fillIn
            ) {
              section5Items.push(
                `${index + 1}. ${cfi.riskFactor}: ${
                  evaluation.data[cfi.longAddress].fillIn
                }`
              );
            }
          });
          if (section5Items.length) {
            forEach(section5Items, (item) => {
              addText(`- ${item}`);
            });
          } else {
            addText(`No items were given responses of "Yes" for this section.`);
          }
        }
        //endregion SECTION 5: SPECIAL RESPONSIVITY CONSIDERATIONS

        //region SECTION 6: RISK/NEED SUMMARY AND OVERRIDE
        if (parseOption(`Section 6: Risk/Need Summary and Override`)) {
          addText(`Section 6: Risk/Need Summary and Override`, 14, 'bold');

          addText(`Score-Based General Risk/Need Level`, 13, 'bold');
          addText(`${evaluation.riskCategory}`);

          addText(`Client-Based/Clinical Override`, 13, 'bold');
          let clientBasedAnswer = evaluation.data[`142>Q-zpyiq8rBE`];
          if (clientBasedAnswer.aid === 'A-9elo-8LNcK') {
            addText(`The client-based/clinical override was used`);
            addText(
              `Reasons for override: ${
                clientBasedAnswer.fillIn
                  ? clientBasedAnswer.fillIn
                  : 'No reasons provided.'
              }`
            );
          } else {
            addText(`The client-based/clinical override was not used`);
          }

          addText(`Administrative/Policy Override`, 13, 'bold');
          let adminBasedAnswer = evaluation.data[`142>Q-pRYF4PvNn`];
          if (adminBasedAnswer.aid === 'A-Y1Gjbn6x9F') {
            addText(`The administrative/policy override was used`);
            addText(
              `Reason specified: ${
                adminBasedAnswer.fillIn
                  ? adminBasedAnswer.fillIn
                  : 'No reasons provided.'
              }`
            );
          } else {
            addText(`The administrative/policy override was not used`);
          }

          addText(`Final Risk/Need Level`, 13, 'bold');
          addText(
            `${
              evaluation.overrideRiskCategory
                ? evaluation.overrideRiskCategory
                : evaluation.riskCategory
            }`
          );
        }
        //endregion SECTION 6: RISK/NEED SUMMARY AND OVERRIDE

        //region SECTION 7: PROGRAM/PLACEMENT DECISION
        if (parseOption(`Section 7: Program/Placement Decision`)) {
          addText(`Section 7: Program/Placement Decision`, 14, 'bold');
          let section8Tool = find(tool.childTools, { id: 143 });
          if (institutionalOffender) {
            let instTool = find(section8Tool.childTools, { id: 144 });
            let evaluationScore;
            forEach(instTool.codingFormItems, (cfi) => {
              evaluationScore = evaluation.data[cfi.longAddress];
              if (evaluationScore.fillIn) {
                addText(
                  `${cfi.riskFactor}: ${
                    evaluationScore.fillIn ? evaluationScore.fillIn : 'N/A'
                  }`
                );
              } else {
                addText(
                  `${cfi.riskFactor}: ${
                    evaluationScore.text ? evaluationScore.text : 'N/A'
                  }`
                );
              }
            });
          } else {
            let commTool = find(section8Tool.childTools, { id: 145 });
            let evaluationScore;
            forEach(commTool.codingFormItems, (cfi) => {
              evaluationScore = evaluation.data[cfi.longAddress];
              if (evaluationScore.fillIn) {
                addText(
                  `${cfi.riskFactor}: ${
                    evaluationScore.fillIn ? evaluationScore.fillIn : 'N/A'
                  }`
                );
              } else {
                addText(
                  `${cfi.riskFactor}: ${
                    evaluationScore.text ? evaluationScore.text : 'N/A'
                  }`
                );
              }
            });
          }
          addText(
            `Presentence Report: ${
              evaluation.data['143>146>Q-pNUe_VJqO'].fillIn
                ? $reincode.text(evaluation.data['143>146>Q-pNUe_VJqO'].fillIn)
                : 'No comments provided.'
            }`
          );
          addText(
            `Summary of Findings: ${
              evaluation.data['143>147>Q-KkNP5MZpH'].fillIn
                ? $reincode.text(evaluation.data['143>147>Q-KkNP5MZpH'].fillIn)
                : 'No comments provided.'
            }`
          );
        }
        //endregion SECTION 7: PROGRAM/PLACEMENT DECISION

        // ==============END PARSE EVALUATION FOR REPORT==================
        // ================END OF REPORT===================
        let today = $filter('dynamicDate')(new Date(), 'fullDate');
        y += 50;
        addText(`Date Printed: ${today}`);
        addText(`End of Report (Assessement #${evaluation.evaluationId})`);
        // addText(`End of Report (Assessment # ${caseplan.evaluation.id})`, null, 'bold');
        // ==========================UPLOAD PDF=========================
        let filename = `${client.name()} - ${$filter('dynamicDate')(
          new Date(),
          'MM-dd-yyyy'
        )} - LS/CMI_Profile_Report`;
        // ==========================SAVE PDF=========================
        pdf.save(`${filename}.pdf`);
        // ==========================UPLOAD PDF=========================
        let pdfFile = pdf.output('arraybuffer');
        // let pdfFile = pdf.output('binary');
        pdfFile = new File([pdfFile], `${filename}.pdf`, {
          type: 'application/pdf'
        });

        Upload.upload({
          url: `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/evaluations/${evaluation.evaluationId}/media`,
          file: pdfFile,
          data: { isReport: true }
        })
          .then((response) => {
            $store.commit('evaluations/setFocus', evaluation.evaluationId);
            $store.dispatch('reports/getForEvaluation', {
              id: evaluation.evaluationId,
              client: client
            });
            return response;
          })
          .catch((err) => {
            return err;
          });
      },
      margins
    );
  }
};
