'use strict';

import angular from 'angular';
import * as _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { Action, State } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

@Controller
class CasePlanPreCheckController extends Modal {
  masterTemplate: any;
  masterTemplateCommits: any;
  institutions: any;
  tool: any;
  institutionUser: any;
  statusOptions: any;
  selectedStatus: {};
  // viewInstitutionTemplates: any;

  @Inject $store;
  @Inject $scope;
  @Inject $api;
  @Inject getItems;
  @Inject $modals;
  @Inject $http;
  @Inject notify;
  @Inject toolUtils;

  @State(({ institutions }) => institutions.items) institutions;
  @Action('institutions/getAll') listInstitutions;

  async $setup() {
    'ngInject';

    console.log('this.$props: ', this.$props);
    this.masterTemplate = angular.copy(this.$props.masterTemplate);
    this.$props.masterTemplateCommits = _.sortBy(
      this.$props.masterTemplateCommits,
      'createdAt'
    ).reverse();
    this.masterTemplateCommitsIndex = 11;
    this.masterTemplateCommits = angular
      .copy(this.$props.masterTemplateCommits)
      .slice(0, this.masterTemplateCommitsIndex);
    this.masterTemplateCommitsFullList = angular.copy(
      this.$props.masterTemplateCommits
    );
    // this.institutions = angular.copy(this.$props.institutions);
    if (!this.institutions.length) await this.listInstitutions();
    this.tool = angular.copy(this.$props.tool);
    this.institutionUser = this.$props.institutionUser;
    //  var dsc = this.$props.prevCtrl;

    // filter master template commits to the given tool commit id
    _.forEach(this.masterTemplateCommits, (commit) => {
      commit.toolCommitId = commit.toolCommits[0].id;
    });

    this.statusOptions = [
      {
        label: 'Saved',
        value: 'Saved'
      },
      {
        label: 'Testing',
        value: 'Testing'
      },
      {
        label: 'Live',
        value: 'Live'
      }
    ];
    this.selectedStatus = null;

    if (this.institutionUser)
      this.viewInstitutionTemplates(this.institutions[0]);
  }

  loadMoreMasterTemplateCommits() {
    let newItems = this.masterTemplateCommitsFullList.slice(
      this.masterTemplateCommitsIndex,
      this.masterTemplateCommitsIndex + 10
    );
    this.masterTemplateCommits = this.masterTemplateCommits.concat(newItems);
    this.masterTemplateCommitsIndex += 10;
  }

  createMasterTemplate() {
    this.$close({ newMasterTemplate: true });
  }

  async edit(commit, master) {
    let template;
    let institution;
    if (master) {
      template = await this.getItems.managedCasePlanTemplateCommit(
        commit.id,
        commit.templateId
      );
    } else {
      template = await this.getItems.casePlanTemplateCommit(
        commit.institutionId,
        commit.templateId,
        commit.id
      );
      institution = _.find(this.institutions, { id: commit.institutionId });
    }

    if (template && template.templateData) {
      this.$close({
        casePlanTemplateData: template.templateData,
        casePlanTemplateMetaData: template,
        institution
      });
    } else {
      this.notify.display('Could not retrieve template', 'error');
    }
  }

  async version(commit) {
    commit.processing = true;

    let version = await this.$modals.test.generalForm('TAG VERSION', [
      {
        type: 'string',
        placeholder: '0.0.1',
        value: '',
        label: 'Version Number',
        key: 'version'
      }
    ]);

    if (!version) return;
    if (!version.version) {
      this.notify.display('Must provide a version number', 'error');
      return;
    }
    version = version.version;

    // check validity of version number
    let semverPattern = /^\d+(\.\d+){0,2}$/;
    if (!semverPattern.test(version)) {
      this.notify.display(
        'Version must be in the form of {number}.{number}.{number}'
      );
      return;
    }

    // check if it's an institution commit or master template
    let res;
    if (commit.institutionId) {
      res = await this.$api.icpt.tagCasePlanTemplateCommit(
        {
          instId: commit.institutionId,
          tmpltId: commit.templateId,
          commitId: commit.id
        },
        {
          version
        }
      );
    } else {
      res = await this.$api.mcpt.tagCasePlanTemplateCommit(
        {
          templateId: commit.templateId,
          commitId: commit.id
        },
        {
          version
        }
      );
    }

    if (res.status !== 200 && res.status !== 204) {
      this.notify.display(res, 'error');
      commit.processing = false;
    } else {
      this.notify.display('Successfully tagged version', 'success');
      commit.processing = false;
      commit.version = version;
    }
  }

  async status(commit) {
    commit.processing = true;

    if (!this.selectedStatus) {
      this.notify.display('You must select a status to update');
      return;
    }

    // check if it's a master template or an institution template
    let res;
    if (commit.institutionId) {
      res = await this.$api.icpt.setCasePlanTemplateCommitStatus(
        {
          instId: commit.institutionId,
          tmpltId: commit.templateId,
          commitId: commit.id
        },
        {
          status: this.selectedStatus
        }
      );
    } else {
      res = await this.$api.mcpt.setCasePlanTemplateCommitStatus(
        {
          templateId: commit.templateId,
          commitId: commit.id
        },
        {
          status: this.selectedStatus
        }
      );
    }

    if (res.status !== 200 && res.status !== 204) {
      this.notify.display(res, 'error');
      commit.processing = false;
    } else {
      this.notify.display('Successfully updated status', 'success');
      commit.status = this.selectedStatus;
      this.selectedStatus = null;
      commit.processing = false;
      commit.showStatusOptions = false;
      commit.showOptions = false;
    }
  }

  async createInstitutionCasePlanTemplate(institution) {
    let masterCasePlanTemplate = _.find(this.masterTemplateCommits, {
      status: 'Live'
    });
    if (!masterCasePlanTemplate)
      masterCasePlanTemplate = _.maxBy(this.masterTemplateCommits, 'createdAt');
    masterCasePlanTemplate = await this.getItems.managedCasePlanTemplateCommit(
      masterCasePlanTemplate.id,
      masterCasePlanTemplate.templateId
    );
    if (masterCasePlanTemplate.templateData)
      masterCasePlanTemplate = masterCasePlanTemplate.templateData;
    this.$close({
      institution,
      masterCasePlanTemplate,
      newInstitutionTemplate: true
    });
  }

  async viewInstitutionTemplates(institution) {
    this.institution = institution;
    institution.loading = true;
    institution.casePlanTemplates =
      await this.getItems.institutionCasePlanTemplates(institution, this.tool);
    if (institution.casePlanTemplates?.length) {
      // check if template toolId and toolCommitId matches the tool we're viewing for
      for (let i = 0; i < institution.casePlanTemplates.length; i++) {
        institution.casePlanTemplates[i].commits =
          await this.getItems.casePlanTemplateCommits(
            institution,
            institution.casePlanTemplates[i]
          );
        // assign tool commit id's so we can sort and display specific options
        if (institution.casePlanTemplates[i].commits?.length) {
          _.forEach(institution.casePlanTemplates[i].commits, (commit) => {
            commit.toolCommitId = commit.toolCommits[0].id;
            commit.matchesToolCommit =
              commit.toolCommitId === this.tool?.commitId;
          });
        }
      }
    }
    this.$scope.$apply();
    institution.loading = false;
  }

  async copy(commit, array) {
    let templateData;

    if (commit.institutionId) {
      templateData = await this.getItems.casePlanTemplateCommit(
        commit.institutionId,
        commit.templateId,
        commit.id
      );
    } else {
      templateData = await this.getItems.managedCasePlanTemplateCommit(
        commit.id,
        commit.templateId
      );
    }

    if (templateData.hasOwnProperty('templateData'))
      templateData = templateData.templateData;

    let payload = {
      message: commit.institutionId
        ? `Copying case plan template from ${commit.toolCommitId} to ${this.tool.commitId}`
        : `Copying master case plan template from ${commit.toolCommitId} to ${this.tool.commitId}`,
      templateData,
      toolCommitId: this.tool.commitId,
      previousCommitId: commit.id
    };

    let res;
    if (commit.institutionId) {
      res = await this.$api.icpt.commitManagedCasePlanTemplate(
        {
          instId: commit.institutionId,
          templateId: commit.templateId
        },
        payload
      );
    } else {
      res = await this.$api.mcpt.commitManagedCasePlanTemplate(
        {
          templateId: commit.templateId
        },
        payload
      );
    }

    if (res.status !== 200 && res.status !== 204) {
      this.notify.display(res, 'error');
    } else {
      if (res.data && res.data.newCommit) {
        let newCommit = res.data.newCommit;
        newCommit.templateId = commit.templateId;
        newCommit.toolCommitId = this.tool.commitId;
        array.push(newCommit);
      }

      this.notify.display(
        'Successfully copied Case Plan Template to chosen Tool Commit',
        'success'
      );
    }
  }

  matchesToolCommit(commit) {
    return commit.toolCommitId === this.tool.commitId;
  }

  backToCommitsView() {
    this.$modals.tool.commitsViewer(this.tool, null, (commit) => {
      if (commit.casePlan) {
        this.$store.dispatch('casePlan/load', { cp: commit });
      } else if (commit.newReportTemplate) {
        this.$state.go('toolCreatorV2', { commit, newReportTemplate: true });
      } else if (commit.test) {
        this.toolUtils.testToolStart(commit);
      } else if (commit.templateId) {
        this.$state.go('toolCreatorV2', { reportTemplate: commit });
      } else {
        this.$state.go('toolCreatorV2', { commit });
      }
    });

    this.$close({ closed: true });
  }
}

export default angular
  .module('app.casePlanPreCheck', [])
  .directive('casePlanPreCheck', () => ({
    restrict: 'E',
    replace: true,
    template: require('./case-plan-pre-check.html'),
    controller: CasePlanPreCheckController,
    controllerAs: 'vm'
  })).name;
