// LSCMI Comparative Generator
import { forEach, sortBy, find } from 'lodash';
import 'jspdf-autotable';

import lscmiPercentiles from '../lscmiProfileReport/percentiles';

export default {
  async createReport(
    mhsLogoDataUrl,
    lscmiLogoDataUrl,
    client,
    evaluations,
    tool,
    services,
    pageSetup,
    options
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let $filter = services.$filter;
    let $reincode = services.$reincode;
    let $sce = services.$sce;
    // console.log('client: ', client);
    // console.log('tool: ', tool);
    // console.log('evaluations: ', evaluations);
    // console.log('options: ', options);
    // sort evaluations by interview date
    // evaluations = sortBy(evaluations, e => e.evaluationData.interview.dateTime);
    let parseOption = function (text) {
      // console.log('text: ', text);
      // parse options from $me selection to see if it should be included in report
      if (find(options, { text: text }).selected) {
        return find(options, { text: text }).includeEvals
          ? find(options, { text: text }).includeEvals
          : true;
      }
    };
    let omittedEvalsList = function (arr) {
      let str;
      forEach(evaluations, (e, i) => {
        if (arr.indexOf(e.id) < 0) {
          if (!str) {
            str = `T${i + 1}`;
          } else {
            str += `, T${i + 1}`;
          }
        }
      });
      return str;
    };
    let makeCell = function (
      content = '',
      style = [],
      rowSpan = 1,
      colSpan = 1
    ) {
      return {
        content,
        style,
        rowSpan,
        colSpan
      };
    };
    let strengthQuestionAddresses = [
      {
        address: '125>126>Q-XUpZC0ukY',
        toolName: '1.1 Criminal History'
      },
      {
        address: '125>127>Q-JehMSRVss',
        toolName: '1.2 Education/Employment'
      },
      {
        address: '125>128>Q-a2os1ezd8',
        toolName: '1.3 Family/Marital'
      },
      {
        address: '125>129>Q-kprx8TErH',
        toolName: '1.4 Leisure/Recreation'
      },
      {
        address: '125>130>Q-8SM3vJIJU',
        toolName: '1.5 Companions'
      },
      {
        address: '125>131>Q-_pcrImw1u',
        toolName: '1.6 Alcohol/Drug Problem'
      },
      {
        address: '125>132>Q-WzJCQ5417',
        toolName: '1.7 Procriminal Attitude/Orientation'
      },
      {
        address: '125>133>Q-rguy6jkZX',
        toolName: '1.8 Antisocial Pattern'
      }
    ];
    forEach(evaluations, (e) => {
      if (!e.evaluationData.hasOwnProperty('score')) {
        let evaluationScoreTotal = 0;
        forEach(e.evaluationData.data, (item) => {
          if (typeof item.score === 'number')
            evaluationScoreTotal += item.score;
        });
        e.evaluationData.score = evaluationScoreTotal;
      }
      e.filteredDate = $filter('dynamicDate')(e.updatedAt, 'longDate');
      // Client-Based/Clinical Override (Yes/No) and Administrative/Policy Override (Yes/No)
      e.clientBasedOverride =
        e.evaluationData.data['142>Q-zpyiq8rBE'].aid === 'A-9elo-8LNcK'
          ? 'Yes'
          : 'No';
      e.administrativePolicyOverride =
        e.evaluationData.data['142>Q-pRYF4PvNn'].aid === 'A-Y1Gjbn6x9F'
          ? 'Yes'
          : 'No';
      // find supervision options (General Risk/Need Assessment... table)
      let recidivismRate = lscmiPercentiles.recidivismRate(
        e.evaluationData.score,
        e.evaluationData?.additionalInformation?.setting === 'Institutional'
          ? 'institutional'
          : 'community'
      );
      e.supervisionOption = recidivismRate
        ? recidivismRate.supervisionOptions
        : 'N/A';
      // probability of recidivism (incarceration) (General Risk/Need Assessment... table)
      e.recidivismRate = recidivismRate ? recidivismRate.percentage : 'N/A';
      // areas of strength (General Risk/Need SUbcomponent Areas of Strength table) e.strengthAreas
      e.strengthAreas = [];
      forEach(strengthQuestionAddresses, (sqa) => {
        let evaluationScore = e.evaluationData.data[sqa.address];
        e.strengthAreas.push({
          name: sqa.toolName,
          strength: evaluationScore?.text === 'Yes' ? 'X' : ''
        });
      });
      /* Additional Item Information:
      1. Number of Youth Dispositions - 125>126>Q-Kjiiq7Een "Number of youth dispositions"
      1. Number of Adult convictions - 125>126>Q-Kjiiq7Een "Number of adult convictions"
      4. Number of present offences - 125>126>Q-k7iskf-DL "Number of present offences"
      7. Number of times punished for institutional misconduct - 125>126>Q-q8tnmwIu- "Number of times punished for institutional misconduct"
      31. Type of drug(s) used - 125>131>Q-8sUZk4mvl "Type of drug(s) used"
      35. Specify - 125>131>Q-Bp9bFS_12 "Specify"
      43b. Number - 125>133>Q-D5Gc25W9m "Number"
      */
      e.additionalItemInformation = {
        '1. Number of youth dispositions': e.evaluationData.data[
          '125>126>Q-Kjiiq7Een'
        ].hasOwnProperty('Number of youth dispositions')
          ? e.evaluationData.data['125>126>Q-Kjiiq7Een'][
              'Number of youth dispositions'
            ]
          : 'Not Specified',
        '1. Number of adult convictions': e.evaluationData.data[
          '125>126>Q-Kjiiq7Een'
        ].hasOwnProperty('Number of adult convictions')
          ? e.evaluationData.data['125>126>Q-Kjiiq7Een'][
              'Number of adult convictions'
            ]
          : 'Not Specified',
        '4. Number of present offences': e.evaluationData.data[
          '125>126>Q-k7iskf-DL'
        ].hasOwnProperty('Number of present offences')
          ? e.evaluationData.data['125>126>Q-k7iskf-DL'][
              'Number of present offences'
            ]
          : 'Not Specified',
        '7. Number of times punished for institutional misconduct':
          e.evaluationData.data['125>126>Q-q8tnmwIu-'].hasOwnProperty(
            'Number of times punished for institutional misconduct'
          )
            ? e.evaluationData.data['125>126>Q-q8tnmwIu-'][
                'Number of times punished for institutional misconduct'
              ]
            : 'Not Specified',
        '31. Type of drug(s) used': e.evaluationData.data[
          '125>131>Q-8sUZk4mvl'
        ].hasOwnProperty('Type of drug(s) used')
          ? e.evaluationData.data['125>131>Q-8sUZk4mvl']['Type of drug(s) used']
          : 'Not Specified',
        '35. Specify': e.evaluationData.data[
          '125>131>Q-Bp9bFS_12'
        ].hasOwnProperty('Specify')
          ? e.evaluationData.data['125>131>Q-Bp9bFS_12']['Specify']
          : 'Not Specified',
        '43b. Number': e.evaluationData.data[
          '125>133>Q-D5Gc25W9m'
        ].hasOwnProperty('Number')
          ? e.evaluationData.data['125>133>Q-D5Gc25W9m']['Number']
          : 'Not Specified'
      };

      // Total # of omitted items (Summary of Omitted Responses table) e.totalOmittedItems
      let totalOmittedItems = 0;
      forEach(e.evaluationData.data, (val) => {
        if (val.text === 'Omit' || val.text === 'omit') totalOmittedItems++;
      });
      e.totalOmittedItems = totalOmittedItems;
      // check if they were an inmate e.isInmate
      e.isInmate =
        e.evaluationData?.additionalInformation?.setting === 'Institutional';
      // section 8, find the following items and set to evaluation
      /* e.institutionPlacement - 143>144>Q-5CPdd31Nm
          e.institutionPlacementComments - 143>144>Q-29TnkFO8K
          e.programPlacement - 143>145>Q-vMsp4FCLI
          e.programPlacementComments - 143>145>Q-GBdVH0-qL
          e.presentenceReport - 143>146>Q-pNUe_VJqO
          e.summaryOfFindings - 143>147>Q-KkNP5MZpH
      */
      e.institutionPlacement = e.evaluationData.data['143>144>Q-5CPdd31Nm']
        .fillIn
        ? $reincode.text(e.evaluationData.data['143>144>Q-5CPdd31Nm'].fillIn)
        : 'None Specified';
      e.institutionPlacementComments = e.evaluationData.data[
        '143>144>Q-29TnkFO8K'
      ].fillIn
        ? $reincode.text(e.evaluationData.data['143>144>Q-29TnkFO8K'].fillIn)
        : 'None Specified';
      e.programPlacement = e.evaluationData.data['143>145>Q-vMsp4FCLI'].fillIn
        ? $reincode.text(e.evaluationData.data['143>145>Q-vMsp4FCLI'].fillIn)
        : 'None Specified';
      e.programPlacementComments = e.evaluationData.data['143>145>Q-GBdVH0-qL']
        .fillIn
        ? $reincode.text(e.evaluationData.data['143>145>Q-GBdVH0-qL'].fillIn)
        : 'None Specified';
      e.presentenceReport = e.evaluationData.data['143>146>Q-pNUe_VJqO'].fillIn
        ? $reincode.text(e.evaluationData.data['143>146>Q-pNUe_VJqO'].fillIn)
        : 'None Specified';
      e.summaryOfFindings = e.evaluationData.data['143>147>Q-KkNP5MZpH'].fillIn
        ? $reincode.text(e.evaluationData.data['143>147>Q-KkNP5MZpH'].fillIn)
        : 'None Specified';
    });
    let report = [];
    // #region cover page
    report.push({
      type: 'image',
      content: {
        dataUrl: $sce.trustAsResourceUrl(lscmiLogoDataUrl),
        x: margins.left,
        y: margins.top,
        width: 189,
        height: 102
      }
    });
    report.push({
      type: 'text',
      content: {
        text: 'Level of Service/Case Management Inventory',
        style: ['title']
      }
    });
    report.push({
      type: 'text',
      content: {
        text: 'By D. A. Andrews, Ph.D., J. L. Bonta, Ph.D., & J. S. Wormith, Ph.D.',
        style: ['italics']
      }
    });
    report.push({
      type: 'text',
      content: {
        text: 'Comparative Report',
        style: ['title']
      }
    });
    // cover page table
    let coverPageTable = {
      type: 'table',
      content: {
        head: [],
        body: [],
        theme: 'grid',
        startY: 300,
        headStyles: {
          fillColor: [84, 9, 5],
          textColor: [255, 255, 255]
        }
      }
    };
    coverPageTable.content.head.push([
      makeCell('Name:'),
      makeCell(`${client.name()}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('ID Number:'),
      makeCell(`${client.localId}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('Sex:'),
      makeCell(`${client.sex}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('Race/Ethnicity:'),
      makeCell(`${client.ethnicity}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('Date of Comparative Report:'),
      makeCell(`${$filter('dynamicDate')(new Date(), 'longDate')}`)
    ]);
    coverPageTable.content.body.push([
      makeCell('Number of Assessments Included:'),
      makeCell(`${evaluations.length}`)
    ]);
    report.push(coverPageTable);
    // cover page assessments info table
    let cpta = {
      type: 'table',
      content: {
        head: [],
        body: [],
        theme: 'grid',
        startY: 460
      }
    };
    cpta.content.head.push([
      makeCell('Assessment'),
      makeCell(`Date`),
      makeCell(`Client-Based/Clinical Override`),
      makeCell(`Administrative/Policy Override`)
    ]);
    let cptabody = [];
    forEach(evaluations, (e, i) => {
      let row = [];
      row.push(makeCell(`${i + 1}`));
      row.push(makeCell(`${e.filteredDate}`));
      row.push(makeCell(`${e.clientBasedOverride}`));
      row.push(makeCell(`${e.administrativePolicyOverride}`));
      cptabody.push(row);
    });
    cpta.content.body = cptabody;
    report.push(cpta);
    // MHS copyright
    report.push({
      type: 'image',
      content: {
        dataUrl: $sce.trustAsResourceUrl(mhsLogoDataUrl),
        x: margins.left,
        y: 680,
        width: 125,
        height: 54
      }
    });
    report.push({
      type: 'text',
      content: {
        text:
          'Copyright © 2004 Multi-Health Systems Inc. All rights reserved\n' +
          'P.O. Box 950, North Tonawanda, NY 14120-0950\n' +
          '3770 Victoria Park Ave., Toronto, ON M2H 3M6',
        x: margins.left + 135,
        y: 705
      }
    });
    report.push({
      type: 'pagebreak'
    });
    // #endregion cover page
    // #region introduction
    if (parseOption('Introduction')) {
      report.push({
        type: 'text',
        content: {
          text: 'Introduction',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'The LS/CMI is a risk and need assessment tool. This report summarizes the results of the LS/CMI over multiple administrations and is helpful in assessing changes in levels of risk/need over time.'
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'Caution: This computerized report is an interpretive aid and should not be given to the client. The LS/CMI is not intended to be the only instrument used to assess the level of service required by an individual, nor is the LS/CMI to be used as a substitute for sound judgement that utilizes various sources of information.'
        }
      });
    }
    // #endregion introduction
    // #region LS/CMI general risk/need total score
    if (parseOption('LS/CMI General Risk/Need Total Score')) {
      report.push({
        type: 'text',
        content: {
          text: 'LS/CMI General Risk/Need Total Score',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `The graph below displays ${client.name()}'s LS/CMI General Risk/Need Total score and indicates the classification level associated with that score across assessments.`
        }
      });
      report.push({
        reportOverview: true,
        type: 'text',
        content: {
          text: `Total Score`,
          style: ['title']
        }
      });
      // add line graph
      let totalScoreGraph = {
        type: 'graph',
        content: {
          data: {},
          type: 'bar',
          options: {
            tooltips: {
              enabled: true,
              callbacks: {
                label: function (tooltipItem, data) {
                  return data.datasets[0].labels[tooltipItem.index];
                }
              },
              yAlign: 'top',
              xAlign: 'center'
            },
            showAllTooltips: true,
            title: {
              display: false
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 45,
                    stepSize: 1,
                    callback: function (label) {
                      switch (label) {
                        case 0:
                          return 'Very Low';
                        case 5:
                          return 'Low';
                        case 11:
                          return 'Medium';
                        case 20:
                          return 'High';
                        case 30:
                          return 'Very High';
                      }
                    },
                    display: true,
                    fontColor: 'black'
                  },
                  scaleLabel: {
                    display: true,
                    fontColor: 'black'
                  }
                }
              ],
              xAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: 'black'
                  },
                  scaleLabel: {
                    display: true,
                    fontColor: 'black'
                  }
                }
              ]
            }
          }
        }
      };
      let totalScoreData = {
        labels: [],
        datasets: [
          {
            backgroundColor: '#cf2b1f',
            borderColor: '#8c1d15',
            labels: []
          }
        ],
        data: [[]]
      };
      let totalScoreOverviewGraph = {
        reportOverview: true,
        type: 'graph',
        content: {
          data: {},
          type: 'bar',
          options: {
            tooltips: {
              enabled: true,
              callbacks: {
                label: function (tooltipItem, data) {
                  return data.datasets[0].labels[tooltipItem.index];
                }
              },
              yAlign: 'top',
              xAlign: 'center'
            },
            // showAllTooltips: true,
            title: {
              display: false
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    min: 0,
                    max: 45,
                    stepSize: 1,
                    callback: function (label) {
                      switch (label) {
                        case 0:
                          return 'Very Low';
                        case 5:
                          return 'Low';
                        case 11:
                          return 'Medium';
                        case 20:
                          return 'High';
                        case 30:
                          return 'Very High';
                      }
                    },
                    fontColor: 'black',
                    display: true
                  },
                  scaleLabel: {
                    display: true,
                    fontColor: 'black'
                  }
                }
              ],
              xAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: 'black'
                  },
                  scaleLabel: {
                    display: true,
                    fontColor: 'black'
                  }
                }
              ]
            }
          }
        }
      };
      let totalScoreOverviewData = {
        labels: [],
        datasets: [
          {
            backgroundColor: '#cf2b1f',
            borderColor: '#8c1d15',
            labels: []
          }
        ],
        data: [[]]
      };
      forEach(evaluations, (e, i) => {
        totalScoreData.labels.push(`Assessment ${i + 1} | ${e.filteredDate}`);
        totalScoreData.datasets[0].labels.push(`${e.evaluationData.score}`);
        totalScoreData.data[0].push(e.evaluationData.score);
        // overview table data
        totalScoreOverviewData.labels.push(
          `Assessment ${i + 1} | ${e.filteredDate}`
        );
        totalScoreOverviewData.datasets[0].labels.push([
          [`Total Score: ${e.evaluationData.score}`],
          [`Risk Level: ${e.evaluationData.riskCategory}`],
          [`Probability of Recidivism: ${e.recidivismRate}`],
          [
            `Override: ${
              e.clientBasedOverride === 'Yes' ||
              e.administrativePolicyOverride === 'Yes'
                ? 'Yes'
                : 'No'
            }`
          ]
        ]);
        totalScoreOverviewData.data[0].push(e.evaluationData.score);
      });
      totalScoreGraph.content.data = totalScoreData;
      report.push(totalScoreGraph);
      totalScoreOverviewGraph.content.data = totalScoreOverviewData;
      report.push(totalScoreOverviewGraph);
      report.push({
        type: 'pagebreak'
      });
    }
    // #endregion LS/CMI general risk/need total score
    // #region General risk/need assessment based on total score
    if (parseOption('General Risk/Need Assessment Based on Total Score')) {
      report.push({
        type: 'text',
        content: {
          text: 'General Risk/Need Assessment Based on Total Score',
          style: ['title']
        }
      });
      // make table
      let gtsTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      gtsTable.content.head.push([
        makeCell('Administration'),
        makeCell(`Total Score`),
        makeCell(`Risk Level`),
        makeCell(`Supervision Options`),
        makeCell(`Probability of Recidivism (Incarceration)`)
      ]);
      let gtsbody = [];
      forEach(evaluations, (e, i) => {
        let row = [];
        row.push(makeCell(`T${i + 1} (${e.filteredDate})`));
        row.push(makeCell(`${e.evaluationData.score}`));
        row.push(makeCell(`${e.evaluationData.riskCategory}`));
        row.push(makeCell(`${e.supervisionOption}`));
        row.push(makeCell(`${e.recidivismRate}`));
        gtsbody.push(row);
      });
      gtsTable.content.body = gtsbody;
      report.push(gtsTable);
    }
    // #endregion General risk/need assessment based on total score
    // #region general risk/need subcomponent scores
    if (parseOption('General Risk/Need Subcomponent Scores')) {
      report.push({
        type: 'text',
        content: {
          text: 'General Risk/Need Subcomponent Scores',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'The following graphs display changes across assessments for each subcomponent.'
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'Graph Legend: 0 = Very Low | 1 = Low | 2 = Medium | 3 = High | 4 = Very High'
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'Note that for the Criminal History subcomponent, risk level cannot decrease.'
        }
      });
      report.push({
        reportOverview: true,
        type: 'text',
        content: {
          text: 'Subcomponent Scores',
          style: ['title']
        }
      });
      // make subcomponent bar graphs (use YLS Comp. Rep. generator for reference)
      let makeComponentScoreGraph = function (title, toolScoreAddress) {
        let graph = {
          type: 'graph',
          content: {
            data: {},
            type: 'bar',
            options: {
              showAllTooltips: false,
              tooltips: {
                yAlign: 'top',
                xAlign: 'center'
              },
              title: {
                display: true,
                text: title,
                fontColor: 'black'
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      max: 4,
                      display: true,
                      fontColor: 'black'
                    },
                    scaleLabel: {
                      display: true,
                      fontColor: 'black'
                    }
                  }
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Assessment',
                      fontColor: 'black'
                    },
                    ticks: {
                      display: true,
                      fontColor: 'black'
                    }
                  }
                ]
              }
            },
            width: 50
          }
        };
        let graphData = {
          labels: [],
          datasets: [
            {
              backgroundColor: '#cf2b1f',
              borderColor: '#8c1d15',
              labels: []
            }
          ],
          data: [[]]
        };
        let overviewGraph = {
          reportOverview: true,
          type: 'graph',
          content: {
            data: {},
            type: 'bar',
            options: {
              showAllTooltips: false,
              tooltips: {
                enabled: true,
                callbacks: {
                  label: function (tooltipItem, data) {
                    return data.datasets[0].labels[tooltipItem.index];
                  }
                },
                yAlign: 'top',
                xAlign: 'center'
              },
              title: {
                display: true,
                text: title,
                fontColor: 'black'
              },
              scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      max: 4,
                      fontColor: 'black',
                      display: true
                    },
                    scaleLabel: {
                      display: true,
                      fontColor: 'black'
                    }
                  }
                ],
                xAxes: [
                  {
                    scaleLabel: {
                      display: true,
                      labelString: 'Assessment',
                      fontColor: 'black'
                    },
                    ticks: {
                      display: true,
                      fontColor: 'black'
                    }
                  }
                ]
              }
            },
            width: 50
          }
        };
        let overviewGraphData = {
          labels: [],
          datasets: [
            {
              backgroundColor: '#cf2b1f',
              borderColor: '#8c1d15',
              labels: []
            }
          ],
          data: [[]]
        };
        forEach(evaluations, (e, i) => {
          graphData.labels.push(`${i + 1}`);
          overviewGraphData.labels.push(`Assessment ${i + 1}`);
          let subScaleRiskNumber;
          switch (e.evaluationData.toolScores[toolScoreAddress].riskCategory) {
            case 'Very Low':
              subScaleRiskNumber = 0.1;
              break;
            case 'Low':
              subScaleRiskNumber = 1;
              break;
            case 'Medium':
              subScaleRiskNumber = 2;
              break;
            case 'High':
              subScaleRiskNumber = 3;
              break;
            case 'Very High':
              subScaleRiskNumber = 4;
              break;
          }
          overviewGraphData.datasets[0].labels.push([
            [`Score: ${e.evaluationData.toolScores[toolScoreAddress].score}`],
            [
              `Risk Level: ${e.evaluationData.toolScores[toolScoreAddress].riskCategory}`
            ]
          ]);
          graphData.datasets[0].labels.push(`${subScaleRiskNumber}`);
          overviewGraphData.data[0].push(subScaleRiskNumber);
          graphData.data[0].push(subScaleRiskNumber);
        });
        overviewGraph.content.data = overviewGraphData;
        graph.content.data = graphData;
        report.push(overviewGraph);
        report.push(graph);
      };
      makeComponentScoreGraph(`1.1 Criminal History`, `125>126>`);
      makeComponentScoreGraph(`1.2 Education/Employment`, `125>127>`);
      makeComponentScoreGraph(`1.3 Family/Marital`, `125>128>`);
      makeComponentScoreGraph(`1.4 Leisure/Recreation`, `125>129>`);
      makeComponentScoreGraph(`1.5 Companions`, `125>130>`);
      makeComponentScoreGraph(`1.6 Alcohol/Drug Problem`, `125>131>`);
      makeComponentScoreGraph(
        `1.7 Procriminal Attitude/Orientation`,
        `125>132>`
      );
      makeComponentScoreGraph(`1.8 Antisocial Pattern`, `125>133>`);
      report.push({
        type: 'pagebreak'
      });
    }
    // #endregion general risk/need subcomponent scores
    // #region general risk/need subcomponent areas of strength
    if (parseOption('General Risk/Need Subcomponent Areas of Strength')) {
      report.push({
        type: 'text',
        content: {
          text: 'General Risk/Need Subcomponent Areas of Strength',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `The identification of strengths may be built upon in service planning. The following table indicates which General Risk/Need subcomponents were considered to be areas of strength over ${client.name()}'s ${
            evaluations.length
          } assessments. If any strength areas were indicated, they will be shown with an "X" mark.`
        }
      });
      // add strengths table
      let strTable = {
        type: 'table',
        content: {
          head: [],
          body: [],
          theme: 'grid'
        }
      };
      let headRow = [makeCell()];
      let strbody = [];
      forEach(strengthQuestionAddresses, (str) => {
        let row = [makeCell(`${str.toolName}`)];
        forEach(evaluations, (e) => {
          row.push(
            makeCell(
              `${
                find(e.strengthAreas, (sa) => {
                  return sa.name === str.toolName;
                }).strength
              }`
            )
          );
        });
        strbody.push(row);
      });
      forEach(evaluations, (e, i) => {
        headRow.push(makeCell(`T${i + 1} | ${e.filteredDate}`));
      });
      strTable.content.head.push(headRow);
      strTable.content.body = strbody;
      report.push(strTable);
      report.push({
        type: 'pagebreak'
      });
    }
    // #endregion general risk/need subcomponent areas of strength
    // #region Item Response table
    if (parseOption('Item Response Table')) {
      report.push({
        type: 'text',
        content: {
          text: 'Item Response Table',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `The following response values were entered over ${client.name()}'s ${
            evaluations.length
          } assessments. An item is scored (i.e., is counted toward the subcomponent and General Risk/Need Total score) if it received a "Yes" or rating of 0 or 1 on the rating scale: `
        }
      });
      report.push({
        type: 'text',
        content: {
          text:
            `3   A satisfactory situation with no need for improvement.\n` +
            `2   A relatively satisfactory situation with some room for improvement.\n` +
            `1   A relatively unsatisfactory situation with a need for improvement.\n` +
            `0   A very unsatisfactory situation with a very clear and strong need for improvement.`
        }
      });
      // make item response table
      let irTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let irHeadRow1 = [];
      let irHeadRow2 = [];
      for (let i = 0; i < 3; i++) {
        irHeadRow1.push(makeCell('Item', undefined, 2));
        irHeadRow1.push(
          makeCell(`Assessment`, undefined, undefined, evaluations.length)
        );
        // irHeadRow2.push(makeCell(''));
        forEach(evaluations, (e, i) => {
          irHeadRow2.push(makeCell(`T${i + 1}`));
        });
      }
      irTable.content.head = [irHeadRow1, irHeadRow2];
      let irBody = [];
      for (let i = 0; i < 20; i++) {
        irBody.push([]);
      }
      let p1tool;
      forEach(tool.childTools, (ct) => {
        if (ct.id === 125) p1tool = ct;
      });
      let bodyIndex = 0;
      forEach(p1tool.childTools, (ct) => {
        forEach(ct.codingFormItems, (cfi) => {
          // skip strength questions
          if (
            find(
              strengthQuestionAddresses,
              (sqa) => sqa.address === cfi.longAddress
            )
          )
            return;
          irBody[bodyIndex].push(makeCell(`${cfi.customItemNumber}`, ['bold']));
          forEach(evaluations, (e) => {
            let evalScore = e.evaluationData.data[cfi.longAddress];
            irBody[bodyIndex].push(
              evalScore ? makeCell(`${evalScore.text}`) : makeCell('N/A')
            );
          });
          bodyIndex === 19 ? (bodyIndex = 0) : bodyIndex++;
        });
      });
      irTable.content.body = irBody;
      report.push(irTable);
      report.push({
        type: 'pagebreak'
      });
    }
    // #endregion Item Response table
    // #region Additional Item Information
    if (parseOption('Additional Item Information')) {
      report.push({
        type: 'text',
        content: {
          text: 'Additional Item Information',
          style: ['title']
        }
      });
      // make addit. item table
      let aiTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let aiHeadRow1 = [
        makeCell(''),
        makeCell('Assessment', undefined, undefined, evaluations.length)
      ];
      let aiHeadRow2 = [makeCell('')];
      let aiBody = [];
      forEach(evaluations, (e, i) => {
        aiHeadRow2.push(makeCell(`T${i + 1} (${e.filteredDate})`));
        if (i === 0) {
          forEach(e.additionalItemInformation, (val, key) => {
            let row = [];
            row.push(makeCell(key));
            row.push(makeCell(val));
            aiBody.push(row);
          });
        } else {
          forEach(e.additionalItemInformation, (val, key) => {
            let row = find(aiBody, (r) => r[0].content === key);
            row.push(makeCell(val));
          });
        }
      });
      aiTable.content.head.push(aiHeadRow1);
      aiTable.content.head.push(aiHeadRow2);
      aiTable.content.body = aiBody;
      report.push(aiTable);
    }
    // #endregion Additional Item Information
    // #region Summary of Omitted Responses
    if (parseOption('Summary of Omitted Responses')) {
      report.push({
        type: 'text',
        content: {
          text: 'Summary of Omitted Responses',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'The following table summarizes the total number of omitted items per assessment.'
        }
      });
      let ortable = {
        type: 'table',
        content: {
          head: [],
          body: [],
          theme: 'grid'
        }
      };
      ortable.content.head.push([
        makeCell('Administration'),
        makeCell('Total # of Omitted Items')
      ]);
      let ortablebody = [];
      forEach(evaluations, (e, i) => {
        let row = [];
        row.push(makeCell(`T${i + 1} (${e.filteredDate})`));
        row.push(makeCell(`${e.totalOmittedItems}`));
        ortablebody.push(row);
      });
      ortable.content.body = ortablebody;
      report.push(ortable);
    }
    // #endregion Summary of Omitted Responses
    // #region Section 2: Specific Risk/Need factors
    if (parseOption('Section 2: Specific Risk/Need Factors')) {
      let part2Tool;
      forEach(tool.childTools, (ct) => {
        if (ct.id === 136) part2Tool = ct;
      });
      let includeEvals = parseOption('Section 2: Specific Risk/Need Factors');
      let listSection2OmittedAssessments = omittedEvalsList(includeEvals);
      report.push({
        type: 'text',
        content: {
          text: 'Section 2: Specific Risk/Need Factors',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'The Specific Risk/Need Factors section derives from a review of the research literature. It pinpoints items that may not apply to the general offender population. When "specific" items occur in a given case, they may take a prominent role in the assessment of this offender\'s risk. Note that the items are not summed for a total Specific Risk/Need score.'
        }
      });

      // user selection, list the assessments that were excluded (ex. T3, T4)
      if (listSection2OmittedAssessments) {
        report.push({
          type: 'text',
          content: {
            text: `Note: The information for this section was not selected for inclusion in ${client.name()}'s ${listSection2OmittedAssessments} assessment(s).`
          }
        });
      }
      // #region Personal Problems with Criminogenic Potential
      let part2_1Tool;
      let part2_2aTool;
      // let part2_2bTool;
      // let part2_2cTool;
      forEach(part2Tool.childTools, (ct) => {
        switch (ct.id) {
          case 134:
            part2_1Tool = ct;
            break;
          case 153:
            part2_2aTool = ct;
            break;
          // case 154:
          //   part2_2bTool = ct;
          //   break;
          // case 155:
          //   part2_2cTool = ct;
          //   break;
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'Personal Problems with Criminogenic Potential',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `The following table displays the results of ${client.name()}'s ${
            evaluations.length
          } assessments. Any items marked with an "X" were endorsed by the assessor (i.e., marked Yes).`
        }
      });
      // make table for this section
      let ppcpTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let ppcpHeadRow1 = [
        makeCell('Item', undefined, 2),
        makeCell('Assessment', undefined, undefined, evaluations.length)
      ];
      let ppcpHeadRow2 = [];
      forEach(evaluations, (e, i) => {
        ppcpHeadRow2.push(makeCell(`T${i + 1} (${e.filteredDate})`));
      });
      ppcpTable.content.head.push(ppcpHeadRow1);
      ppcpTable.content.head.push(ppcpHeadRow2);
      let ppcpBody = [];
      forEach(part2_1Tool.codingFormItems, (cfi, i) => {
        let row = [makeCell(`${i + 1}. ${cfi.riskFactor}`)];
        forEach(evaluations, (e) => {
          if (includeEvals.indexOf(e.id) >= 0) {
            let endorsed = e.evaluationData.data[cfi.longAddress]?.text;
            endorsed = endorsed === 'Yes' ? 'X' : '';
            row.push(makeCell(endorsed));
          } else {
            row.push(makeCell());
          }
        });
        ppcpBody.push(row);
      });
      ppcpTable.content.body = ppcpBody;
      report.push(ppcpTable);
      // #endregion Personal Problems with Criminogenic Potential
      // #region History of Perpetration
      report.push({
        type: 'text',
        content: {
          text: 'History of Perpetration',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `The following table displays the results of ${client.name()}'s ${
            evaluations.length
          } assessments. Any items marked with an "X" were endorsed by the assessor (i.e., marked Yes). Note: This section refers to ${client.name()}'s history, and hence, changes in these items are not expected; however, over time, additional items may be added/checked.`
        }
      });
      // make table for this section
      let hpTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let hpHeadRow1 = [
        makeCell('Item', undefined, 2),
        makeCell('Assessment', undefined, undefined, evaluations.length)
      ];
      let hpHeadRow2 = [];
      forEach(evaluations, (e, i) => {
        hpHeadRow2.push(makeCell(`T${i + 1} (${e.filteredDate})`));
      });
      hpTable.content.head.push(hpHeadRow1);
      hpTable.content.head.push(hpHeadRow2);
      let hpBody = [];
      forEach(part2_2aTool.codingFormItems, (cfi, i) => {
        let row = [makeCell(`${i + 1}. ${cfi.riskFactor}`)];
        forEach(evaluations, (e) => {
          if (includeEvals.indexOf(e.id) >= 0) {
            let endorsed = e.evaluationData.data[cfi.longAddress].text;
            endorsed = endorsed === 'Yes' ? 'X' : '';
            row.push(makeCell(endorsed));
          } else {
            row.push(makeCell());
          }
        });
        hpBody.push(row);
      });
      hpTable.content.body = hpBody;
      report.push(hpTable);
      report.push({
        type: 'pagebreak'
      });
      // #endregion History of Perpetration
    }
    // #endregion Section 2: Specific Risk/Need factors
    // #region Section 3: Prison Experience - Institutional Factors
    if (parseOption('Section 3: Prison Experience - Institutional Factors')) {
      let part3_1aTool;
      let part3_1bTool;
      let part3_2Tool;
      forEach(tool.childTools, (ct) => {
        if (ct.id === 139) {
          forEach(ct.childTools, (ct2) => {
            if (ct2.id === 137) part3_1aTool = ct2;
            if (ct2.id === 152) part3_1bTool = ct2;
            if (ct2.id === 138) part3_2Tool = ct2;
          });
        }
      });
      report.push({
        type: 'text',
        content: {
          text: 'Section 3: Prison Experience - Institutional Factors',
          style: ['title']
        }
      });
      forEach(evaluations, (e, i) => {
        report.push({
          type: 'text',
          content: {
            text: `Administration ${i + 1}`,
            style: ['bold']
          }
        });
        if (e.isInmate) {
          /* Past Incarceration: Last classification medium; Past state/provincial incarceration.
          Present Incarceration: Protective custody; Treatment recommended/ordered; Security management concerns.
          Barriers to release: Community supervision inappropriate due to specific risk/need factors identified in Section 2.
          */
          // find "Past Incarceration" item
          let pastIncarcerationString;
          forEach(part3_1aTool.codingFormItems, (cfi) => {
            if (e.evaluationData.data[cfi.longAddress].text.includes('Yes')) {
              if (!pastIncarcerationString) {
                pastIncarcerationString = cfi.riskFactor;
              } else {
                pastIncarcerationString =
                  pastIncarcerationString + '; ' + cfi.riskFactor;
              }
            }
          });
          report.push({
            type: 'text',
            content: {
              text: !pastIncarcerationString
                ? 'Past Incarceration: None Specified'
                : `Past Incarceration: ${pastIncarcerationString}`
            }
          });
          // find "Present Incarceration" item
          let presentIncarcerationString;
          forEach(part3_1bTool.codingFormItems, (cfi) => {
            if (e.evaluationData.data[cfi.longAddress].text.includes('Yes')) {
              if (!presentIncarcerationString) {
                presentIncarcerationString = cfi.riskFactor;
              } else {
                presentIncarcerationString =
                  presentIncarcerationString + '; ' + cfi.riskFactor;
              }
            }
          });
          report.push({
            type: 'text',
            content: {
              text: !presentIncarcerationString
                ? 'Present Incarceration: None Specified'
                : `Present Incarceration: ${presentIncarcerationString}`
            }
          });
          // find "Barriers to Release" item
          let barriersToReleaseString;
          forEach(part3_2Tool.codingFormItems, (cfi) => {
            if (e.evaluationData.data[cfi.longAddress].text.includes('Yes')) {
              if (!barriersToReleaseString) {
                barriersToReleaseString = cfi.riskFactor;
              } else {
                barriersToReleaseString =
                  barriersToReleaseString + '; ' + cfi.riskFactor;
              }
            }
          });
          report.push({
            type: 'text',
            content: {
              text: !barriersToReleaseString
                ? 'Barriers to Release: None Specified'
                : `Barriers to Release: ${barriersToReleaseString}`
            }
          });
        } else {
          report.push({
            type: 'text',
            content: {
              text: `This section is for inmates only and is not applicable for ${client.name()}'s T${
                i + 1
              } assessment. `
            }
          });
        }
      });
      report.push({
        type: 'pagebreak'
      });
    }
    // #endregion Section 3: Prison Experience - Institutional Factors
    // #region Section 4: Other Client Issues (Social, Health, and Mental Health)
    if (
      parseOption(
        'Section 4: Other Client Issues (Social, Health, and Mental Health)'
      )
    ) {
      let part4Tool;
      forEach(tool.childTools, (ct) => {
        if (ct.id === 140) part4Tool = ct;
      });
      let includeEvals = parseOption(
        'Section 4: Other Client Issues (Social, Health, and Mental Health)'
      );
      let listSection4OmittedAssessments = omittedEvalsList(includeEvals);
      report.push({
        type: 'text',
        content: {
          text: 'Section 4: Other Client Issues (Social, Health, and Mental Health)',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `This section includes supplementary information that may impact ${client.name()}'s classification and case management decisions. Note that these items are not summed for a total score. The following table displays the results of ${client.name()}'s ${
            evaluations.length
          } assessments. Any items marked with an "X" were endorsed by the assessor (i.e., marked Yes). The following items were endorsed by the assessor (i.e., marked Yes) and should be addressed for basic humanitarian and ethical reasons. Moreover, their successful resolution may facilitate the rehabilitative efforts devoted to the previously identified criminogenic needs found in the General and Specific Risk/Need Factors sections.`
        }
      });
      // based on user selection, list the assessments that were excluded (ex. T3, T4)
      if (listSection4OmittedAssessments) {
        report.push({
          type: 'text',
          content: {
            text: `Note: The information for this section was not selected for inclusion in ${client.name()}'s ${listSection4OmittedAssessments} assessment(s).`
          }
        });
      }
      let ocTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let ocHeadRow1 = [
        makeCell('Item', undefined, 2),
        makeCell('Assessment', undefined, undefined, evaluations.length)
      ];
      let ocHeadRow2 = [];
      forEach(evaluations, (e, i) => {
        ocHeadRow2.push(makeCell(`T${i + 1} (${e.filteredDate})`));
      });
      ocTable.content.head.push(ocHeadRow1);
      ocTable.content.head.push(ocHeadRow2);
      let ocBody = [];
      forEach(part4Tool.codingFormItems, (cfi, i) => {
        let row = [makeCell(`${i + 1}. ${cfi.riskFactor}`)];
        forEach(evaluations, (e) => {
          if (includeEvals.indexOf(e.id) >= 0) {
            let endorsed = e.evaluationData.data[cfi.longAddress].text;
            endorsed = endorsed === 'Yes' ? 'X' : '';
            row.push(makeCell(endorsed));
          } else {
            row.push(makeCell());
          }
        });
        ocBody.push(row);
      });
      ocTable.content.body = ocBody;
      report.push(ocTable);
      // make table
      report.push({
        type: 'pagebreak'
      });
    }
    // #endregion Section 4: Other Client Issues (Social, Health, and Mental Health)
    // #region Section 5: Special Responsivity Considerations
    if (parseOption('Section 5: Special Responsivity Considerations')) {
      let part5Tool;
      forEach(tool.childTools, (ct) => {
        if (ct.id === 141) part5Tool = ct;
      });
      let includeEvals = parseOption(
        'Section 5: Special Responsivity Considerations'
      );
      let listSection5OmittedAssessments = omittedEvalsList(includeEvals);
      report.push({
        type: 'text',
        content: {
          text: 'Section 5: Special Responsivity Considerations',
          style: ['title']
        }
      });
      report.push({
        type: 'text',
        content: {
          text: `This section samples some of the dominant responsivity considerations according to clinical research and correctional opinion. Responsivity factors are considerations that may impact the choice of the most appropriate style and mode of service. Note that the items are not summed for a total score. The following table displays the results of ${client.name()}'s ${
            evaluations.length
          } assessments. Any items marked with an "X" were endorsed by the assessor (i.e., marked Yes).`
        }
      });
      // based on user selection, list the assessments that were excluded (ex. T3, T4)
      if (listSection5OmittedAssessments) {
        report.push({
          type: 'text',
          content: {
            text: `Note: The information for this section was not selected for inclusion in ${client.name()}'s ${listSection5OmittedAssessments} assessment(s).`
          }
        });
      }
      // make table
      let srTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let srHeadRow1 = [
        makeCell('Item', undefined, 2),
        makeCell('Assessment', undefined, undefined, evaluations.length)
      ];
      let srHeadRow2 = [];
      forEach(evaluations, (e, i) => {
        srHeadRow2.push(makeCell(`T${i + 1} (${e.filteredDate})`));
      });
      srTable.content.head.push(srHeadRow1);
      srTable.content.head.push(srHeadRow2);
      let srBody = [];
      forEach(part5Tool.codingFormItems, (cfi, i) => {
        let row = [makeCell(`${i + 1}. ${cfi.riskFactor}`)];
        forEach(evaluations, (e) => {
          if (includeEvals.indexOf(e.id) >= 0) {
            let endorsed = e.evaluationData.data[cfi.longAddress].text;
            endorsed = endorsed === 'Yes' ? 'X' : '';
            row.push(makeCell(endorsed));
          } else {
            row.push(makeCell());
          }
        });
        srBody.push(row);
      });
      srTable.content.body = srBody;
      report.push(srTable);
    }
    // #endregion Section 5: Special Responsivity Considerations
    // #region Section 6: Risk/Need Summary Override
    if (parseOption('Section 6: Risk/Need Summary Override')) {
      let part6Tool;
      forEach(tool.childTools, (ct) => {
        if (ct.id === 142) part6Tool = ct;
      });
      let includeEvals = parseOption('Section 6: Risk/Need Summary Override');
      let listSection6OmittedAssessments = omittedEvalsList(includeEvals);
      report.push({
        type: 'text',
        content: {
          text: 'Section 6: Risk/Need Summary Override',
          style: ['title']
        }
      });
      // based on user selection, list the assessments that were excluded (ex. T3, T4)
      if (listSection6OmittedAssessments) {
        report.push({
          type: 'text',
          content: {
            text: `Note: The information for this section was not selected for inclusion in ${client.name()}'s ${listSection6OmittedAssessments} assessment(s).`
          }
        });
      }
      // make table
      let rnTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let rnHeadRow1 = [
        makeCell('Item', undefined, 2),
        makeCell('Assessment', undefined, undefined, evaluations.length)
      ];
      let rnHeadRow2 = [];
      forEach(evaluations, (e, i) => {
        rnHeadRow2.push(makeCell(`T${i + 1} (${e.filteredDate})`));
      });
      rnTable.content.head.push(rnHeadRow1);
      rnTable.content.head.push(rnHeadRow2);
      let rnBody = [];
      // make score-based section 1 row
      let scoreBasedRow = [makeCell(`Score-Based Section 1 Risk/Need Level`)];
      forEach(evaluations, (e) => {
        scoreBasedRow.push(makeCell(e.evaluationData.riskCategory));
      });
      rnBody.push(scoreBasedRow);
      forEach(part6Tool.codingFormItems, (cfi, i) => {
        let row = [makeCell(`${cfi.riskFactor}`)];
        forEach(evaluations, (e) => {
          if (includeEvals.indexOf(e.id) >= 0) {
            switch (cfi.id) {
              case 'Q-zpyiq8rBE':
                // client based override
                row.push(makeCell(e.clientBasedOverride === 'Yes' ? 'X' : ''));
                break;
              case 'Q-pRYF4PvNn':
                // clinic based override
                row.push(
                  makeCell(e.administrativePolicyOverride === 'Yes' ? 'X' : '')
                );
                break;
              case 'Q-aGoAT4MUz':
                // final risk need override
                row.push(
                  makeCell(
                    e.evaluationData?.overrideRiskCategory
                      ? e.evaluationData.overrideRiskCategory
                      : ''
                  )
                );
            }
          } else {
            row.push(makeCell());
          }
        });
        rnBody.push(row);
      });
      rnTable.content.body = rnBody;
      report.push(rnTable);
    }
    // #endregion Section 6: Risk/Need Summary Override
    // #region Section 7: Program/Placement Decision
    if (parseOption('Section 7: Program/Placement Decision')) {
      let includeEvals = parseOption('Section 7: Program/Placement Decision');
      let listSection7OmittedAssessments = omittedEvalsList(includeEvals);
      report.push({
        type: 'text',
        content: {
          text: 'Section 7: Program/Placement Decision',
          style: ['title']
        }
      });
      // based on user selection, list the assessments that were excluded (ex. T3, T4)
      if (listSection7OmittedAssessments) {
        report.push({
          type: 'text',
          content: {
            text: `Note: The information for this section was not selected for inclusion in ${client.name()}'s ${listSection7OmittedAssessments} assessment(s).`
          }
        });
      }
      // make table
      let ppTable = {
        type: 'table',
        content: {
          head: [],
          body: []
        }
      };
      let ppHeadRow1 = [
        makeCell('Item', undefined, 2),
        makeCell('Assessment', undefined, undefined, evaluations.length)
      ];
      let ppHeadRow2 = [];
      forEach(evaluations, (e, i) => {
        ppHeadRow2.push(makeCell(`T${i + 1} (${e.filteredDate})`));
      });
      ppTable.content.head.push(ppHeadRow1);
      ppTable.content.head.push(ppHeadRow2);
      let ppBody = [];
      let bodyItems = [
        'Institutional Offenders',
        'Recommendation/Decision',
        'Release Recommended',
        'Community Offenders',
        'Recommendation/Decision'
      ];
      forEach(bodyItems, (bi, i) => {
        let row = [];
        if (i === 0 || i === 3) {
          row.push(makeCell(bi, ['bold']));
          forEach(evaluations, () => {
            row.push(makeCell());
          });
        } else {
          row.push(makeCell(bi));
          forEach(evaluations, (e) => {
            if (includeEvals.indexOf(e.id) >= 0) {
              if (i === 1) {
                // recommendation/decision
                row.push(
                  makeCell(
                    e.evaluationData.data['143>144>Q-XUgprLd4M'].text === '-'
                      ? 'Not Specified'
                      : e.evaluationData.data['143>144>Q-XUgprLd4M'].text
                  )
                );
              } else if (i === 2) {
                // release recommendation
                row.push(
                  makeCell(
                    e.evaluationData.data['143>144>Q-i2JYPeh9F'].text === '-'
                      ? 'Not Specified'
                      : e.evaluationData.data['143>144>Q-i2JYPeh9F'].text
                  )
                );
              } else if (i === 4) {
                // recommendation/decision
                row.push(
                  makeCell(
                    e.evaluationData.data['143>145>Q-_n7JcO3uq'].text === '-'
                      ? 'Not Specified'
                      : e.evaluationData.data['143>145>Q-_n7JcO3uq'].text
                  )
                );
              }
            } else {
              row.push(makeCell());
            }
          });
        }
        ppBody.push(row);
      });
      ppTable.content.body = ppBody;
      report.push(ppTable);
      // make ending text
      forEach(evaluations, (e, i) => {
        if (includeEvals.indexOf(e.id) >= 0) {
          report.push({
            type: 'text',
            content: {
              text: `Administration ${i + 1}`,
              style: ['bold']
            }
          });
          report.push({
            type: 'text',
            content: {
              text: `Institutional Offenders`,
              style: ['bold']
            }
          });
          report.push({
            type: 'text',
            content: {
              text:
                `Institutional Placement: ${e.institutionalPlacement}\n` +
                `Comments: ${
                  e.institutionalPlacementComments
                    ? e.institutionalPlacementComments
                    : 'None Specified'
                }`
            }
          });
          // ------
          report.push({
            type: 'text',
            content: {
              text: `Community Offenders`,
              style: ['bold']
            }
          });
          report.push({
            type: 'text',
            content: {
              text:
                `Program Placement: ${e.programPlacement}\n` +
                `Comments: ${
                  e.programPlacementComments
                    ? e.programPlacementComments
                    : 'None Specified'
                }`
            }
          });
          // ------
          report.push({
            type: 'text',
            content: {
              text: `Presentence Report`,
              style: ['bold']
            }
          });
          report.push({
            type: 'text',
            content: {
              text: `${
                e.presentenceReport ? e.presentenceReport : 'None Specified'
              }`
            }
          });
          // ------
          report.push({
            type: 'text',
            content: {
              text: `Summary of Findings`,
              style: ['bold']
            }
          });
          report.push({
            type: 'text',
            content: {
              text: `${e.summaryOfFindings}`
            }
          });
        }
      });
    }
    // #endregion Section 7: Program/Placement Decision
    report.push({
      type: 'text',
      content: {
        text: `------------`
      }
    });
    report.push({
      type: 'text',
      content: {
        text: `Date Printed: ${$filter('dynamicDate')(new Date(), 'fullDate')}`
      }
    });
    report.push({
      type: 'text',
      content: {
        text: 'End of Report',
        style: ['bold']
      }
    });
    return report;
  }
};
