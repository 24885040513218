'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class UploadToolController extends Modal {
  submission: any;
  tool: any;
  onlyPDF: any;
  newCommitId: any;
  step: number;
  // getExistingCommitPdfs: any;
  toolPdfs: {};
  selectedToolPdf: {};
  toolDescriptionPdfs: {};
  selectedDescriptionPdf: {};
  referencePDF: {};
  descriptionPDF: {};
  processing: boolean;
  commitMessage: any;

  @Inject $scope;
  @Inject Upload;
  @Inject $http;
  @Inject $api;
  @Inject utils;
  @Inject $modals;
  @Inject notify;

  async $setup() {
    this.submission = this.$props.submission;
    this.tool = this.$props.tool;
    this.onlyPDF = this.$props.onlyPDF;

    if (this.onlyPDF) {
      this.newCommitId = this.tool.id; // passing commit id instead of tool
      this.step = 1;
    } else {
      this.step = 0;
    }

    // grab the list of existing tool pdfs for this commit

    this.getExistingCommitPdfs();

    // pdf management variable setup
    this.toolPdfs = [];
    this.selectedToolPdf = null;

    this.toolDescriptionPdfs = [];
    this.selectedDescriptionPdf = null;

    let toolId = this.tool.toolId ? this.tool.toolId : this.submission.id;
    if (toolId) {
      let toolPdfsRes;
      try {
        toolPdfsRes = await this.$api.toolCreator.listToolPdfs({
          toolId
        });
        this.toolPdfs = toolPdfsRes.data;
      } catch (err) {
        this.notify.display(err, 'error');
      }

      let descPdfsRes;
      try {
        descPdfsRes = await this.$api.toolCreator.listDescriptionPdfs({
          toolId
        });
        this.toolDescriptionPdfs = descPdfsRes.data;
      } catch (err) {
        this.notify.display(err, 'error');
      }
    }

    this.referencePDF = null;
    this.descriptionPDF = null;
    this.processing = false;
    this.commitMessage = '';
  }

  async getExistingCommitPdfs() {
    if (this.tool?.id) {
      let toolCommRes;
      try {
        toolCommRes = await this.$api.toolCreator.getToolCommit({
          toolId: this.tool.toolId,
          commitId: this.tool.id
        });
        this.tool.descriptionPdfs = toolCommRes.data.descriptionPdfs;
        this.tool.toolPdfs = toolCommRes.data.toolPdfs;
        if (!this.tool.toolId && this.tool?.rootToolInformation) {
          this.tool.toolId = this.tool.rootToolInformation.id;
        }
      } catch (err) {
        this.notify.display(err, 'error');
      }
    }
  }

  async updateWithExistingPdf(toolPdf) {
    // toolPdf: boolean
    let url = '';
    let data = null;
    let commitId = this.newCommitId ? this.newCommitId : this.tool.id;
    let toolId =
      !this.tool?.toolId && this.tool?.rootToolInformation?.id
        ? this.tool.rootToolInformation.id
        : this.tool.id;
    let res;
    if (toolPdf) {
      try {
        res = await this.$api.toolCreator.setLiveToolPdf(
          {
            toolId,
            commitId
          },
          {
            toolPdfId: this.selectedToolPdf.id
          }
        );
      } catch (err) {
        this.notify.display(err, 'error');
        return;
      }
    } else {
      try {
        res = await this.$api.toolCreator.setLiveDescriptionPdf(
          {
            toolId,
            commitId
          },
          {
            toolPdfId: this.selectedDescriptionPdf.id
          }
        );
      } catch (err) {
        this.notify.display(err, 'error');
        return;
      }
    }

    this.getExistingCommitPdfs();
    this.selectedDescriptionPdf = null;
    this.selectedToolPdf = null;
    this.notify.display(
      'Successfully set your PDF to live for this commit',
      'success'
    );
    this.pass();
  }

  async previewPDF(pdf, toolPdf, elem) {
    elem.loading = true;
    let url = toolPdf
      ? `/api/tool-creator/tools/${pdf.toolId}/tool-pdfs/${pdf.id}`
      : `/api/tool-creator/tools/${pdf.toolId}/description-pdfs/${pdf.id}`;
    this.$http
      .get(url, {
        responseType: 'arraybuffer'
      })
      .then((pdfData) => {
        elem.loading = false;
        if (pdfData.data.error) {
          this.utils.notify(
            'error',
            pdfData.data.feedback || 'Error retrieving your PDF'
          );
        } else {
          this.$modals.settings.openPDF(1, 'PREVIEW PDF', pdfData.data);
        }
      })
      .catch((err) => {
        elem.loading = false;
        this.utils.notify(
          'error',
          err.data.feedback || 'Error retrieving your PDF'
        );
      });
  }

  pass() {
    if (this.step === 2) {
      this.$close({ commitId: this.newCommitId, toolId: this.toolId });
    } else {
      this.step++;
    }
  }

  async upload() {
    let file;
    let url;
    this.processing = true;

    if (this.step === 1) {
      // reference PDF
      file = this.referencePDF;
      // url = `/api/tool-commits/${this.newCommitId}/pdf`;
      url = `/api/tool-creator/tools/${
        this.tool.toolId ? this.tool.toolId : this.tool.id
      }/tool-pdfs`;
    } else if (this.step === 2) {
      // description PDF
      file = this.descriptionPDF;
      // url = `/api/tool-commits/${this.newCommitId}/desc`;
      url = `/api/tool-creator/tools/${
        this.tool.toolId ? this.tool.toolId : this.tool.id
      }/description-pdfs`;
    }

    let response;

    try {
      response = await this.Upload.upload({
        url,
        file
      });

      let toolPdfId = response.data.id;
      this.processing = false;
      if (response.status !== 200 && response.status !== 204) {
        this.notify.display(response, 'error');
      } else if (this.step === 1) {
        this.notify.display('PDF Successfully Uploaded', 'success');
        // add pdf id to tool commit's usable list
        let postPdfRes;
        let setPdfToLiveRes;
        try {
          postPdfRes = await this.$api.toolCreator.addToolPdf(
            {
              toolId: this.tool.id,
              commitId: this.newCommitId
            },
            {
              toolPdfId
            }
          );
          // set tool pdf to live
          try {
            setPdfToLiveRes = await this.$api.toolCreator.setLiveToolPdf(
              {
                toolId: this.tool.id,
                commitId: this.newCommitId
              },
              {
                toolPdfId
              }
            );
            this.notify.display(
              'Successfully set new Tool PDF to live for this commit.',
              'success'
            );
            this.getExistingCommitPdfs();
            this.pass();
          } catch (err) {
            this.notify.display(err, 'error');
            this.processing = false;
          }
        } catch (err) {
          this.notify.display(err, 'error');
          this.processing = false;
        }
      } else if (this.step === 2) {
        this.notify.display('PDF Successfully Uploaded', 'success');
        // add description pdf id to tool commit's usable list
        let postDescPdfRes;
        try {
          postDescPdfRes = await this.$api.toolCreator.addDescriptionPdf(
            {
              toolId: this.tool.id,
              commitId: this.newCommitId
            },
            {
              descriptionPdfId: toolPdfId
            }
          );

          let setDescPdfToLiveRes;
          try {
            setDescPdfToLiveRes = await this.$api.toolCreator.setLiveDescriptionPdf(
              {
                toolId: this.tool.id,
                commitId: this.newCommitId
              },
              {
                descriptionPdfId: toolPdfId
              }
            );
            this.getExistingCommitPdfs();
            this.notify.display(
              'Successfully set new Tool PDF to Description for this commit.',
              'success'
            );
            this.pass();
          } catch (err) {
            this.getExistingCommitPdfs();
            this.notify.display(err, 'error');
          }
        } catch (err) {
          this.notify.display(err, 'error');
        }
        this.pass();
      }
    } catch (err) {
      this.notify.display(err, 'error');
      this.processing = false;
    }
  }

  async finish() {
    // upload tool data
    this.processing = true;
    // let toolData = angular.toJson(this.submission);
    let toolData = angular.copy(this.submission);
    // let toolEdit = angular.toJson(this.tool);
    let toolEdit = angular.copy(this.tool);
    let toolPostRes;
    if (!this.submission.id) {
      try {
        toolPostRes = await this.$api.toolCreator.createTool({
          toolData,
          editorData: toolEdit,
          name: this.submission.name,
          description: this.submission.description
        });
        this.notify.display('Tool Successfully Uploaded', 'success');
        this.newCommitId = toolPostRes.data.commitId;
        this.toolId = toolPostRes.data.toolId || this.tool.id;
        this.processing = false;
        this.pass();
      } catch (err) {
        this.processing = false;
        this.notify.display(err, 'error');
      }
    } else {
      try {
        toolPostRes = await this.$api.toolCreator.commitTool(
          {
            toolId: this.submission.id
          },
          {
            toolData,
            editorData: toolEdit,
            parentCommitId: this.tool.toolCommitId,
            message: this.commitMessage
          }
        );
        this.processing = false;
        this.notify.display('Tool Successfully Uploaded', 'success');
        this.newCommitId = toolPostRes.data.commitId;
        this.toolId = toolPostRes.data.toolId || this.tool.id;
        this.pass();
      } catch (err) {
        console.log(err);
        this.processing = false;
        this.notify.display(err, 'error');
      }
    }
  }

  close(msg) {
    this.$close(msg);
  }
}

export default angular
  .module('app.uploadTool', [])
  .directive('uploadTool', () => ({
    restrict: 'E',
    replace: true,
    template: require('./upload-tool.html'),
    controller: UploadToolController,
    controllerAs: 'vm'
  })).name;
