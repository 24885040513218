'use strict';

import { State, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardSuperAdminTransfersView {
  tableProps: {}[] = [];
  tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  @Inject $rootScope;
  @Inject $state;
  @Inject $location;
  @Inject $auth;
  @Inject $modals;
  @Inject $api;
  @Inject utils;

  @State institutionTransfers;
  @Action('institutionTransfers/approve') approveTransfer;
  @Action('institutionTransfers/deny') denyTransfer;

  async $onInit() {
    const $vm = this;

    this.tableItems = this.institutionTransfers.items.filter(
      item => item.status == 0
    );

    this.tableProps = [
      {
        label: 'User',
        value: row => `${row.superUser.lName}, ${row.superUser.fName}`
      },
      {
        label: 'Institution',
        value: 'institution.name'
      }
    ];

    this.tabelActions = [
      {
        label: 'Approve',
        icon: 'check',
        actions: ({ id }) => $vm.approveTransfer(id)
      },
      {
        label: 'Deny',
        icon: 'ban',
        actions: ({ id }) => $vm.denyTransfer(id)
      }
    ];
  }
}

export default DashboardSuperAdminTransfersView;
