import { ColorText } from './color-text';
import { SYNTAX_COLORS, SyntaxScope } from './syntax-colors';

function getSyntaxColor(
  types: string[] | null,
  scope = SYNTAX_COLORS
): string | null {
  if (!types) return null;

  let color: string | null = null;

  let memebers = scope.children || scope;

  let type: string = '';
  let match: boolean = false;

  do {
    type = types.splice(0, 1)[0];
    match = type in memebers;
  } while (!match && types.length);

  if (!match) {
    return null;
  }

  let matchedScope: SyntaxScope = memebers[type];

  if (types.length) {
    color = getSyntaxColor(types, matchedScope) || matchedScope.color;
  } else {
    color = matchedScope.color;
  }

  return color;
}

export class CodeTokenizerService {
  highlight(input: unknown) {
    let { line, tags } = grammar.tokenizeLine(input);
    let tokens = registry.decodeTokens(line, tags);

    let colorText = new ColorText();
    let j = 0;

    for (let { scopes, value } of tokens) {
      let color;

      for (let i = 0; i < scopes.length; i++) {
        let type = scopes[i].replace('.js', '').split('.');

        let res = getSyntaxColor([...type]);

        if (res) {
          color = res;
        }
      }

      if (color) {
        colorText.add(value, `${color}`);
      } else {
        colorText.add(value);
      }
    }

    return colorText;
  }
}
