'use strict';

import _, { forEach } from 'lodash';

function OffenseClassificationsStore(
  $api,
  $http,
  notify,
  $injector,
  $reincode
) {
  'ngInject';

  return {
    state: {
      loading: false,
      items: [],
      table: {
        sortedCol: 0,
        searchText: ''
      }
    },
    getters: {
      find: (state) => (ref) => {
        return state.items.find((item) => item == ref || item.id == ref.id);
      },
      getById: (state) => (id) => {
        return state.items.find((item) => item.id == id);
      }
    },
    actions: {
      async setItems({ rootState, commit }, { items, replace = false }) {
        commit(replace ? 'set' : 'add', items);
      },
      async list({ state, commit, rootGetters, dispatch }, instId, replace) {
        const $auth = $injector.get('$auth');

        // grabs list of all offense classifications for either gears or institution user
        commit('setProps', { loading: true });

        let data = [];
        // console.log('list offense classifications: ', instId);
        try {
          let res;
          if (
            $auth.hasAccess('institutionmanager:ListOffenseClassifications')
          ) {
            res = await $api.institutionManager.listOffenseClassifications({
              instId: instId ? instId : rootGetters.activeInstId
            });
          } else {
            console.warn(
              '[gears-auth] This user does not have access to fetch offense classifications'
            );
          }
          // console.log('retrieved offense classifications: ', res);
          data = res.data;
        } catch (err) {
          commit('setProps', { loading: false });
          throw err;
        }

        const parseOC = function (d) {
          if (d.classificationData?.length) {
            forEach(d.classificationData, (oc) => {
              if (oc.sections?.length)
                forEach(oc.sections, (sec) => {
                  if (sec.offenseClassifications?.length)
                    forEach(
                      sec.offenseClassifications,
                      (oc) => (oc = $reincode.fullObject(oc))
                    );
                  sec = $reincode.fullObject(sec);
                });
              oc = $reincode.fullObject(oc);
            });
          }
        };

        if (Array.isArray(data)) {
          forEach(data, (d) => parseOC(d));
        } else {
          data = [parseOC(data)];
        }

        await dispatch('setItems', { items: data, replace });

        commit('setProps', { loading: false });

        return state.items;
      },
      async get({ state, commit, dispatch }, id) {
        const $auth = $injector.get('$auth');

        // grabs a single offense classification
        let res;

        if ($auth.hasAccess('gearsmanager:GetOffenseClassification')) {
          res = await $api.GM.getOffenseClassification();
        } else if (
          $auth.hasAccess('institutionmanager:GetOffenseClassification')
        ) {
          res = await $api.IM.getOffenseClassification({
            instId: $auth.institutionId,
            offenseClassificationId: id
          });
        } else {
          console.warn(
            '[gears-auth] This user does not have access to fetch offense classifications'
          );
        }

        res.data = $reincode.fullObject(res.data);

        await dispatch('set', res.data);

        return state.items;
      },
      async update({ state, commit }, { id, data, ocId }) {
        let res = await $api.IM.updateOffenseClassification(
          {
            instId: id,
            offenseClassificationId: ocId
          },
          {
            classificationData: data
          }
        );
      },
      async delete({ state, commit }, { id, instId }) {
        commit('setProps', { loading: true });

        let res = await $api.IM.deleteOffenseClassification({
          offenseClassificationId: id,
          instId
        });

        if (!res.data.error) {
          throw res;
        }

        _.remove(state.items, { id });
        commit('setProps', { loading: false });
      }
    },
    mutations: {
      set(state, payload) {
        state.items = [...payload];
      },
      add(state, payload) {
        state.items = _.uniqBy([...payload, ...state.items], 'id');
      },
      update({ items }, payload = []) {
        if (!Array.isArray(payload)) {
          payload = [payload];
        }

        payload.forEach((item) => {
          if (typeof item != 'object' || !item.id) {
            console.warn(
              `[data-store:offense-classifications:update] A provided value for offense classification data was not valid.`,
              item
            );
            return;
          }

          let offenseClassification = items.find(
            (offenseClassification) => offenseClassification.id == item.id
          );

          if (!offenseClassification) {
            console.warn(
              `[data-store:offense-classifications:update] The offense classification with ID "${item.id}" could not be found.`
            );
            return;
          }

          Object.keys(item).forEach((key) => {
            offenseClassification[key] = item[key];
          });
        });
      },
      updateItem({ items }, { ref = -1, props = {} }) {
        let item = items.find((item) => item == ref || item.id == ref.id);

        if (!item) {
          return;
        }

        Object.keys(props).forEach((key) => {
          item[key] = props[key];
        });
      },
      // remove({ items }, payload) {
      //   state.items = items.filter(item => !payload.includes(item.id));
      //   items.splice(items.findIndex(item => item.id === payload), 1);
      // },
      remove({ items }, id) {
        items.splice(
          items.findIndex((item) => item.id === id),
          1
        );
      },
      //
      setProps(state, props = {}) {
        for (let i in props) {
          if (i in state) {
            state[i] = props[i];
          }
        }
      },
      //
      setFocus(state, id) {
        state.focus = state.items.find((item) => item.id === id) || null;
      },
      CLEAR(state) {
        Object.assign(state, {
          loading: false,
          items: [],
          table: {
            sortedCol: 0,
            searchText: ''
          }
        });
      }
    }
  };
}

export default OffenseClassificationsStore;
