import angular from 'angular';
import { filter, remove, uniqBy } from 'lodash';
import { ActionTree, MutationTree } from 'angular-store';

import { TableState } from '@interfaces';
// import { Evaluation } from '@interfaces/evaluation';
import { RootState } from '@store/state';

/**
 * ...
 */
export interface EvaluationsResquestsState {
  loading: boolean;
  items: unknown[];
  table: TableState;
}

export default function EvaluationsResquestsStore(
  $api: angular.gears.IApiService,
  $api2: angular.gears.IAPI2Service,
  notify: angular.gears.INotifyService,
  $injector: angular.auto.IInjectorService
) {
  'ngInject';

  const state: EvaluationsResquestsState = {
    loading: false,
    items: [],
    table: { sortedCol: 0, searchText: '' }
  };

  const actions: ActionTree<EvaluationsResquestsState, RootState> = {
    async list({ state, commit, rootGetters }) {
      const $auth = $injector.get<angular.gears.IAuthService>('$auth');

      // If Institution User, prep payloads
      const options = {
        institutionId: rootGetters?.activeInstId
          ? rootGetters.activeInstId
          : null,
        subgroupIds: []
      };

      if (
        !$auth.hasAccess('GM:ListEvaluationRequests') &&
        $auth.hasAccess('IM:ListEvaluationRequests') &&
        rootGetters.activeInstId
      ) {
        options.institutionId = rootGetters.activeInstId;

        const resources = rootGetters['permissions/getResources'](
          'institutionmanager:ListEvaluationRequests',
          'subGroup'
        );

        options.subgroupIds =
          resources === '*'
            ? '*'
            : Object.values(resources)
                .map(({ resourceValue }) => resourceValue)
                .filter((val) => typeof val === 'string');

        // options.subgroupIds.push('D8TnKUkEzuyvxx456-6fN');
        // options.subgroupIds = ['D8TnKUkEzuyvxx456-6fN'];
      }

      const promise = $auth.hasAccess('GM:ListEvaluationRequests')
        ? $api2.gm.listEvaluationRequests()
        : $auth.hasAccess('IM:ListEvaluationRequests')
        ? $api2.im.listEvaluationRequests(options)
        : $api2.user.getEvaluationRequests();

      let data: unknown[] = [];
      let error: Error | null = null;

      // console.log('promise: ', promise);

      commit('SET_PROPS', { loading: true });

      try {
        data = await promise;
      } catch (err) {
        error = err;
      }

      commit('SET_PROPS', { loading: false });

      if (error) {
        throw error;
      }

      commit('SET', data);

      return state.items;
    },
    async getAll({ state, commit }) {
      commit('SET_PROPS', { loading: true });

      let data = [];

      try {
        const res = await $api.gearsManager.listEvaluationRequests();
        data = res.data;
      } catch (err) {
        commit('SET_PROPS', { loading: false });
        throw err;
      }

      commit('ADD', data);
      commit('SET_PROPS', { loading: false });

      return state.items;
    },
    async getMine({ state, rootState, commit }) {
      commit('SET_PROPS', { loading: true });

      let data = [];

      try {
        const res = await $api.user.getEvaluationRequests();
        data = res.data;
      } catch (err) {
        commit('SET_PROPS', { loading: false });
        throw err;
      }

      commit('ADD', data);
      commit('SET_PROPS', { loading: false });

      return state.items;
    },
    async getForUser({ state, rootState, commit, rootGetters }, userId) {
      userId = typeof userId == 'number' ? userId : rootState.me.id;

      commit('SET_PROPS', { loading: true });

      let data = [];

      try {
        const res = await $api.users.getEvaluationRequests(userId);
        data = res.data;
      } catch (err) {
        commit('SET_PROPS', { loading: false });
        throw err;
      }

      commit('ADD', data);

      // Set any ERs that belong to the current user.
      const myData = state.items.filter(
        (item) =>
          item.evaluation &&
          item.evaluation.status != 2 &&
          rootGetters.activeInstId === item.institutionId
      );

      commit('me/addEvlReqs', myData, true);
      commit('SET_PROPS', { loading: false });

      return state.items;
    },
    async getForInstitution({ rootState, state, commit }, instId) {
      if (isNaN(instId)) {
        if (!rootState.me.institution) {
          return;
        }

        instId = rootState.me.institution.id;
      }

      commit('SET_PROPS', { loading: true });

      let data = [];

      try {
        const res = await $api.IM.listEvaluationRequests(instId);
        data = res.data;
      } catch (err) {
        commit('SET_PROPS', { loading: false });
        throw err;
      }

      data = data.filter(
        (item) => !!item.evaluation && item.evaluation.status !== 2
      );

      commit('SET', data);

      return state.items;
    },
    async create({ rootState, commit }, options: unknown) {
      options.overrideOffenderHistoryRequirement = true;

      const data = await $api2.im.createEvaluation(options);

      commit('ADD', [data]);

      return data;
    },
    async delete({ state }, options: { id: string; institutionId: string }) {
      try {
        await $api2.im.deleteEvaluationRequest({
          requestId: options.id,
          institutionId: options.institutionId
        });
      } catch (err) {
        return notify.display(err, 'error');
      }

      remove(state.items, { id: options.id });
    }
  };

  const mutations: MutationTree<EvaluationsResquestsState> = {
    SET(state, payload) {
      state.items = [...payload];
    },
    ADD(state, payload) {
      state.items = uniqBy([...payload, ...state.items], 'id');
    },
    REMOVE(state, id) {
      state.items = filter(state.items, { id });
    },
    SET_PROPS(state, props = {}) {
      for (const i in props) {
        if (i in state) {
          state[i] = props[i];
        }
      }
    },
    UPDATE_ITEMS({ items }, payload) {
      const ref = items.find((item) => {
        payload.evaluationId
          ? item.evaluationId == payload.evaluationId
          : item.id == payload.id;
      });

      if (!ref) return;

      for (const [key, val] of Object.entries(payload)) {
        ref[key] = val;
      }
    },
    CLEAR(state) {
      Object.assign(state, {
        loading: false,
        items: [],
        table: { sortedCol: 0, searchText: '' }
      });
    }
  };

  // TEMP: remove once all references to them have been removed.
  Object.defineProperties(mutations, {
    set: { enumerable: true, value: mutations.SET },
    add: { enumerable: true, value: mutations.ADD },
    remove: { enumerable: true, value: mutations.REMOVE },
    setProps: { enumerable: true, value: mutations.SET_PROPS },
    updateItem: { enumerable: true, value: mutations.UPDATE_ITEMS }
  });

  return { state, actions, mutations };
}
