import { Client } from '@interfaces/client';

class ZoneData {
  id: string;
  name: string;
  regions: RegionData[];
  expanded: boolean;

  constructor(config: any = {}) {
    this.id = config.id;
    this.name = config.name;
    this.regions = (config.regions ?? []).map((o: any) => new RegionData(o));
    this.expanded = config.expanded || false;
  }

  get clients() {
    return this.regions.flatMap((region) => region.clients);
  }
}

class RegionData {
  id: string;
  name: string;
  subGroups: SubGroupData[];
  expanded: boolean;

  constructor(config: any = {}) {
    this.id = config.id;
    this.name = config.name;
    this.subGroups = (config.subGroups ?? []).map(
      (o: any) => new SubGroupData(o)
    );
    this.expanded = config.expanded || false;
  }

  get clients() {
    return this.subGroups.flatMap(({ clients }) => clients);
  }
}

class SubGroupData {
  id: string;
  name: string;
  clients: Client[];
  expanded: boolean;

  constructor(config: any = {}) {
    this.id = config.id;
    this.name = config.name;
    this.clients = config.clients || [];
    this.expanded = config.expanded || false;
  }
}

/**
 * Created zone data with nested regions, subgroups and clients.
 *
 * @param data Raw zone data.
 * @return Zone data.
 */
export function createZoneData(data: any[]) {
  return data.map((o) => new ZoneData(o));
}
