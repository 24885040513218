import angular from 'angular';
import { State, Mutation } from 'angular-store';

import { Controller, Inject } from '@decorators/ngCtrl';
import { ClientsState } from '@store/modules/clients';
import { MeState } from '@store/modules/me';
import { ToolsState } from '@store/modules/tools';
import { RootMutations } from '@store/mutations';
import { RootState } from '@store/state';

@Controller
class DashboardView {
  activeRole: number;
  loadMax = 0;
  loadCount = 0;
  institutionId: number | string;

  @Inject private readonly $scope!: angular.IScope;
  @Inject private readonly $http!: angular.IHttpService;
  @Inject private readonly $location!: angular.ILocationService;
  @Inject private readonly $timeout!: angular.ITimeoutService;
  @Inject private readonly $state!: angular.ui.IStateService;
  @Inject private readonly $stateParams!: angular.ui.IStateParamsService;
  @Inject private readonly $store!: angular.gears.IStoreService;
  @Inject private readonly $auth!: angular.gears.IAuthService;
  @Inject private readonly $modals!: angular.gears.IModalsService;
  @Inject private readonly utils!: angular.gears.IUtilsService;
  @Inject private readonly errorHandler!: angular.gears.IErrorHandlerService;
  @Inject private readonly reportUtils!: angular.gears.IReportUtilsService;
  @Inject private readonly idleMonitor!: angular.gears.IIdleMonitorService;
  @Inject private readonly $api!: angular.gears.IApiService;
  @Inject private readonly $api2!: angular.gears.IAPI2Service;
  @Inject private readonly evlUtils!: angular.gears.IEvalUtilsService;
  @Inject
  private readonly aggregateReportsMonitor!: angular.gears.IAggregateReportsMonitor;
  @Inject
  private readonly aggregateUserReportsMonitor!: angular.gears.IAggregateUserReportsMonitor;
  @Inject
  private readonly activityReportsMonitor!: angular.gears.IActivityReportsMonitor;
  @Inject readonly loadTool!: angular.gears.ILoadToolService;

  @State readonly me!: MeState;
  @State readonly users!: any;
  @State readonly clients!: ClientsState;
  @State readonly institutions!: any;
  @State readonly tools!: ToolsState;
  @State readonly loadingData!: RootState['loadingData'];
  @State readonly processing!: any;
  @State readonly emailingReport!: any;
  @State readonly sideNavCollapsed!: RootState['sideNavCollapsed'];
  @State readonly isLoadingMessage!: RootState['isLoadingMessage'];
  @State readonly isLoading!: RootState['isLoading'];
  @State readonly prohibitInput!: RootState['prohibitInput'];
  @Mutation readonly isLoadingData!: RootMutations['IS_LOADING_DATA'];
  @Mutation readonly loadingProgress!: RootMutations['LOADING_PROGRESS'];
  @Mutation readonly setLoadingMessage!: RootMutations['SET_LOADING_MESSAGE'];
  @Mutation readonly setIsLoading!: RootMutations['SET_IS_LOADING'];

  private readonly dataLoaders = [
    {
      fn: () => this.$store.dispatch('analytics/getStatsForDashboard'),
      access: 'gearsmanager:ListDashboardStats',
      instMemeber: false
    },
    {
      fn: () => this.$store.dispatch('analytics/getStatsForDashboard'),
      access: 'institutionmanager:ListDashboardStats',
      instMemeber: true
    },
    {
      fn: () =>
        this.$store.dispatch('clientRequests/getOutgoingForInstitution'),
      access: 'institutionmanager:ListOutgoingClientRequests',
      instMemeber: true
    },
    {
      fn: () =>
        this.$store.dispatch('clientRequests/getIncomingForInstitution'),
      access: 'institutionmanager:ListIncomingClientRequests',
      instMemeber: true
    },
    {
      fn: () => this.$store.dispatch('casePlanTemplates/listForInstitution'),
      access: 'icpt:ListCasePlanTemplates',
      instMemeber: true
    },
    {
      fn: () =>
        this.$store.dispatch('casePlanTemplateCommits/listForInstitution'),
      access: 'icpt:ListCasePlanTemplateCommits',
      instMemeber: true
    },
    {
      fn: () => this.$store.dispatch('evaluationRequests/getMine'),
      instMemeber: true
    },
    // {
    //   fn: () => this.$store.dispatch('evaluationRequests/getForInstitution'),
    //   access: 'institutionmanager:ListEvaluationRequests',
    //   instMemeber: true
    // },
    {
      fn: () => this.$store.dispatch('reminders/list'),
      // access: 'institutionmanager:ListPolicies',
      instMemeber: true
    },
    {
      fn: () => this.$store.dispatch('reminders/list'),
      // access: 'institutionmanager:ListPolicies',
      instMemeber: false
    },
    {
      fn: () => this.$store.dispatch('tools/list'),
      instMemeber: true
    }
  ];

  $onInit() {
    //
    this.$scope.$on('permissionProfileSet', () => this.reload());
    this.$scope.$on('reloadDashboard', () => this.reload());

    this.isLoadingData(true);
    void this.loadData();
    // this.setIsLoading(true);
    // this.setLoadingMessage('Custom Message Here');
    this.idleMonitor.start();
    this.aggregateReportsMonitor.start();
    this.aggregateUserReportsMonitor.start();
    this.activityReportsMonitor.start();
  }

  reload() {
    // TODO:Dane find a better way of clearing store data on
    // dashboard reload...
    for (const prop in this.$store.state) {
      const state = this.$store.state[prop];

      if (!!state && typeof state == 'object' && 'items' in state) {
        this.$store.commit(`${prop}/set`, []);
      }
    }

    this.isLoadingData(true);
    void this.loadData();
  }

  async loadData() {
    const hasPermission = ({ access }) =>
      access ? this.$auth.hasAccess(access) : true;
    const adminOnly = ({ instMemeber }) =>
      !!instMemeber && !!this.me.institution;
    const nonAdminOnly = ({ instMemeber }) =>
      !instMemeber && !this.me.institution;

    const filterLoaders = (params) =>
      hasPermission(params) && (adminOnly(params) || nonAdminOnly(params));

    const loaders = this.dataLoaders.filter(filterLoaders).map(({ fn }) => fn);

    this.loadMax = loaders.length;
    this.loadCount = 0;

    this.loadingProgress(0);

    // Pause for effect...
    await this.utils.wait(1000);

    // Loop through and apply all loaders
    for (const loader of loaders) {
      try {
        await loader();
      } catch (err) {
        this.errorHandler(err);
      }

      this.loadingProgress(++this.loadCount / this.loadMax);
    }

    if (this.$stateParams.referrer) {
      const ref = this.$stateParams.referrer;

      if (ref.commitId && ref.toolId) {
        // we're coming from updating a tool
        this.$state.go('dashboardTools');

        const toolId = parseInt(ref.toolId, 10);
        const tool = this.tools.items.find((tool) => tool.id === toolId);

        this.goToEditTool(tool);
      }
    }

    // this.reportUtils.reloadReports();

    this.$timeout(this.afterDataLoad.bind(this), 500);
  }

  goToEditTool(tool: unknown, toolId?: unknown) {
    if (!tool && toolId) {
      tool = this.tools.items.find((tool) => tool.id == toolId);
    }

    this.$modals.tool.commitsViewer(tool, null);
  }

  private afterDataLoad() {
    this.isLoadingData(false);

    // Atempt to reference possible in-progress evaluation ID
    // query string in the URL
    const evalInProgressId = this.$location.search().evaluationId;

    // If an ID was found...
    if (!evalInProgressId) return;

    // Grab the coresponding evaluation data object
    const currentEval = this.evaluations.items.find(
      (item) => item.id == evalInProgressId
    );

    // If a matching evaluation was found, auto-continue the
    // evaluation. Otherwise, clear the query string
    if (currentEval) {
      this.testingEvaluation = true;
      this.evlUtils.continueEvaluation(currentEval);
    } else {
      this.$location.search('');
    }
  }

  // TODO (Alex) DEBUG REMOVE BEFORE LIVE
  _testCreateInstitutionModal() {
    this.$modals.create.institution();
  }

  // TODO (Dane) DEBUG REMOVE BEFORE LIVE
  _testCreateClientModal() {
    this.$modals.create.client();
  }

  // TODO (Dane) DEBUG REMOVE BEFORE LIVE
  _testEditClientModal() {
    const testClient = {
      account: { id: '418759646721', name: 'The Best Prison Institution Ever' },
      address1: '3320 23rd Rd N',
      address2: null,
      address3: null,
      alias: 'rgrg',
      city: null,
      clinicalInfo: 'tgtgtgt',
      country: 'US',
      createdAt: '2021-04-20T19:03:23.715Z',
      customFields: null,
      dataModel: 'Client',
      dob: '04/16/21',
      email: 'dane.fitzmaurice@icloud.com',
      ethnicity: 'White',
      externalId: 'US:418759646721:rfrtty',
      fName: 'Dane',
      grn: 'grn:gifr:institutionmanager:usa:418759646721:client:790fd4f6-1e81-4673-943f-ec3806c341c8',
      id: '790fd4f6-1e81-4673-943f-ec3806c341c8',
      lName: 'Fitzmaurice',
      localId: 'rfrtty',
      mName: null,
      phone: '7032326736',
      pk: 'client-790fd4f6-1e81-4673-943f-ec3806c341c8',
      postalCode: null,
      region: { id: '63', name: 'default', description: 'the default region' },
      sex: 'male',
      sk: '#profile',
      stateProvince: null,
      subGroup: {
        id: '1fe59w1fae981a89r1e',
        name: 'default',
        description: 'the default subgroup'
      },
      type: ['High-Profile Offender'],
      updatedAt: '2021-04-20T19:03:23.715Z',
      zone: { id: '19', name: 'default', description: 'the default zone' }
    };

    this.$modals.edit.client(testClient);
  }
}

export default DashboardView;
