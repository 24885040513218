import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

import './viewer.scss';

import ImageViewer from '../viewer-image/viewer-image.component';
import AudioViewer from '../viewer-audio/viewer-audio.component';
import VideoViewer from '../viewer-video/viewer-video.component';
import PdfViewer from '../viewer-pdf/viewer-pdf.component';
import CsvViewer from '../viewer-csv/viewer-csv.component';
import TextViewer from '../viewer-text/viewer-text.component';
import OtherViewer from '../viewer-other/viewer-other.component';

// const EXT_REGEX = /(?<=\.)[0-9a-z]+$/i;
const EXT_REGEX = /[0-9a-z]+$/i;

function getViewerType(ext, size) {
  if (/jpg|jpeg|gif|bmp|png|JPG|JPEG|PNG/.test(ext)) {
    return 'image';
  } else if (/mp3/.test(ext)) {
    return 'audio';
  } else if (/webm|mp4|mov|ogg/.test(ext)) {
    return 'video';
  } else if (/txt/.test(ext)) {
    return 'text';
  } else if (/pdf|avi|xlsx|docx|wexbim/.test(ext)) {
    return ext;
  } else if (/csv/.test(ext)) {
    return size > 500000 ? 'too-large' : ext;
  } else {
    return 'unsupported';
  }
}

@Controller
class Viewer {
  fileSrc: any = null;
  filePath: string = null;
  fileExt: string = null;
  driver: string = null;
  width: number = null;
  height: number = null;
  loading: boolean = false;

  @Inject $scope;
  @Inject $store;
  @Inject $element;
  @Inject $http;

  $onInit() {
    this.width = this.$element.width() || 0;
    this.height = this.$element.height() || 0;

    this.$scope.$watch('file', this::this.prepareFile);
  }

  async prepareFile(input) {
    this.loading = true;

    let file;

    if (typeof input == 'string') {
      let res = await this.$http({
        method: 'get',
        url: input
      });

      file = res.data;
    } else {
      file = input;
    }

    const [ext] = file.name.match(EXT_REGEX);
    this.driver = getViewerType(ext, file.size);

    // this.fileSrc = input;

    let mimeType;

    if (this.driver == 'image') {
      mimeType = `image/${ext}`;
    } else if (this.driver == 'audio') {
      mimeType = `audio/${ext}`;
    } else if (this.driver == 'video') {
      mimeType = `video/${ext}`;
    } else if (this.driver == 'text') {
      mimeType = 'text/plain';
    } else if (this.driver == 'pdf') {
      mimeType = 'application/pdf';
    } else if (this.driver == 'csv') {
      mimeType = 'text/csv';
    } else if (this.driver == 'doc') {
      mimeType = 'application/msword';
    } else if (this.driver == 'docx') {
      mimeType =
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    } else if (this.driver == 'xlsx') {
      mimeType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    } else if (this.driver == 'too-large') {
      this.message = 'File Type Too Large to Display in Browser';
    }

    this.fileSrc = new Blob([file], { type: mimeType });

    this.filePath = URL.createObjectURL(this.fileSrc);

    this.loading = false;
  }
}

export default angular
  .module('fileViewer.viewer', [
    ImageViewer,
    AudioViewer,
    VideoViewer,
    PdfViewer,
    CsvViewer,
    TextViewer,
    OtherViewer
  ])
  .directive('fileViewer', () => {
    return {
      restrict: 'E',
      replace: true,
      template: require('./viewer.html'),
      scope: {
        type: '<',
        file: '<'
      },
      controller: Viewer,
      controllerAs: 'vm'
    };
  }).name;
