import angular from 'angular';

class InputToggleController {}

export default angular
  .module('app.inputToggle', [])
  .component('inputToggle', {
    template: require('./input-toggle.html'),
    bindings: {
      model: '=ngModel'
    },
    controller: InputToggleController,
    controllerAs: 'vm'
  }).name;
