import angular from 'angular';

export default angular
  .module('app.formFieldText', [])
  .directive('formFieldText', () => ({
    restrict: 'E',
    replace: true,
    template: require('./form-field-text.html'),
    scope: {
      model: '=',
      label: '<',
      required: '<'
    }
  })).name;
