import angular from 'angular';
import { sortBy } from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ImportRequestDetailsModalController extends Modal {
  tableProps: {}[] = [];
  details: {}[] = [];
  formatErrors: {}[] = [];
  validationErrors: {}[] = [];

  @Inject $scope;

  get request() {
    return this.$props?.request;
  }

  async $setup() {
    this.tableProps = [
      {
        label: 'Page',
        value: 'page'
      },
      {
        label: 'Row',
        value: 'row'
      },
      {
        label: 'Column',
        value: 'column'
      },
      {
        label: 'Message',
        value: 'message'
      }
    ];

    for (const detail of this.request?.details || []) {
      const error = {};
      error.status = detail.status;
      error.errors = detail.errors;

      if (detail.evaluationId) {
        error.id = detail.evaluationId;
        error.expanded = false;
        this.validationErrors.push(error);
      } else {
        this.formatErrors.push(error);
      }
    }

    this.validationErrors = sortBy(this.validationErrors, ['status']);

    this.formatErrors = this.formatErrors.flatMap(error => error.errors);
  }
}

export default angular
  .module('gifrApp.importRequestDetailsModal', [])
  .directive('importRequestDetailsModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./import-request-details.html'),
    controller: ImportRequestDetailsModalController,
    controllerAs: 'vm'
  })).name;
