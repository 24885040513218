import { Ng, Component, Prop, Watch } from '@angular';
import Prism from 'prismjs';

Prism.languages.json = {
  comment: /\/\/.*|\/\*[\s\S]*?(?:\*\/|$)/,
  property: { pattern: /"(?:\\.|[^\\"\r\n])*"(?=\s*:)/, greedy: !0 },
  string: { pattern: /"(?:\\.|[^\\"\r\n])*"(?!\s*:)/, greedy: !0 },
  number: /-?\d+\.?\d*(e[+-]?\d+)?/i,
  punctuation: /[{}[\],]/,
  operator: /:/,
  boolean: /\b(?:true|false)\b/,
  null: /\bnull\b/
};

@Component({
  name: 'CodeBlock',
  template: require('./code-block.html')
})
export class CodeBlock extends Ng {
  @Prop() source: string;
  @Prop() lang: string;

  sourceHtml: any;

  $onInit() {
    this.$watch('source', () => this.tokenizeSource());
  }

  tokenizeSource() {
    this.sourceHtml = Prism.highlight(
      this.source,
      Prism.languages[this.lang],
      this.lang
    );
  }
}

export default CodeBlock.$module;
