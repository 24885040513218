'use strict';

import AppDataStore, { ActionConfig } from '@/mixins/app-data-store.mixin';

@AppDataStore
class CasePlanTemplatesStore {
  @ActionConfig
  list({ toolCreator }) {
    return instId => toolCreator.listCasePlanTemplates(instId);
  }

  @ActionConfig
  listForInstitution = {
    params: ({ rootState }, instId) => {
      return isNaN(instId) ? (rootState.me.institution || {}).id : instId;
    },
    validate: (context, instId) => {
      return !isNaN(instId);
    },
    request: ({ icpt }) => instId => icpt.listCasePlanTemplates(instId)
  };
}

export default CasePlanTemplatesStore;
