import angular from 'angular';
import { format } from 'date-fns';
import moment from 'moment';

const DATEPICKER_PROPS = [
  'closeText',
  'prevText',
  'nextText',
  'currentText',
  'monthNames',
  'monthNamesShort',
  'dayNames',
  'dayNamesShort',
  'dayNamesMin',
  'weekHeader',
  // 'dateFormat',
  'firstDay',
  'isRTL',
  'showMonthAfterYear',
  'yearSuffix',
  'showOn',
  'showAnim',
  'showOptions',
  // 'defaultDate',
  'appendText',
  'buttonText',
  'buttonImage',
  'buttonImageOnly',
  'hideIfNoPrevNext',
  'navigationAsDateFormat',
  'gotoCurrent',
  'changeMonth',
  'changeYear',
  'yearRange',
  'showOtherMonths',
  'selectOtherMonths',
  'showWeek',
  'calculateWeek',
  'shortYearCutoff',
  'minDate',
  'maxDate',
  'duration',
  'beforeShowDay',
  'beforeShow',
  'onSelect',
  'onChangeMonthYear',
  'onClose',
  'numberOfMonths',
  'showCurrentAtPos',
  'stepMonths',
  'stepBigMonths',
  // 'altField',
  // 'altFormat',
  'constrainInput',
  'showButtonPanel',
  'autoSize',
  'disabled'
];

function link(scope, element, attrs) {
  let dateFormat = scope.dateFormat;

  element.datepicker();

  const ngModel = element.controller('ngModel');

  if (ngModel) {
    // ngModel.$parsers.push(value =>
    //   value instanceof Date ? value : new Date(value)
    // );

    ngModel.$parsers.push((value) => {
      const dateTime =
        value instanceof Date
          ? value
          : moment(
              value,
              dateFormat.replace(/m/g, 'M').replace(/d/g, 'D')
            ).toDate();

      return dateTime;
    });

    ngModel.$formatters.push((value) => {
      setTimeout(() =>
        element.datepicker(
          'setDate',
          ngModel.$modelValue ? new Date(ngModel.$modelValue) : null
        )
      );
      return value;
    });
  }

  scope.$watch('dateFormat', (val) => {
    dateFormat = val
      ? val.replace(window.gearsRegionPatter, window.gearsRegionFormat)
      : window.gearsRegionFormat;

    dateFormat = dateFormat.replace(/(yyyy|y)/, 'yy').replace(/M/g, 'm');
    return element.datepicker('option', 'dateFormat', dateFormat);
  });

  scope.options = {};

  // reset yearRange if not specified
  if (!attrs.$attr['yearRange'])
    element.datepicker('option', 'yearRange', '-10:+10');

  // set placeholder options
  element.attr('placeholder', window.gearsRegionFormat.toUpperCase());

  for (const prop of DATEPICKER_PROPS) {
    const key = attrs.$attr[prop];

    if (key === undefined) continue;

    const checkVal =
      key.charAt(0) == ':'
        ? () => scope.$parent.$eval(attrs[prop])
        : () => attrs[prop];

    // scope.$watch(checkVal, val => element.datepicker('option', prop, val));
    scope.$watch(checkVal, (val) => {
      scope.options = { ...scope.options, [prop]: val };
      element.datepicker('option', prop, val);
    });
  }
}

export default angular
  .module('app.component.inputDatepicker', [])
  .directive('inputDatepicker', () => ({
    restrict: 'E',
    replace: true,
    scope: { ngModel: '=', dateFormat: '=', yearRange: '@' },
    template: `<input type="text" />`,
    link
  })).name;
