'use strict';

import { State, Action, Mutation, Getter } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardClientsView {
  tableProps: {}[] = [];
  // tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';
  loading: boolean = false;
  loadingInstitutions: boolean = false;

  @Inject $rootScope;
  @Inject $state;
  @Inject $location;
  @Inject $auth;
  @Inject $store;
  @Inject $api;
  @Inject $modals;
  @Inject $stateParams;
  @Inject errorHandler;
  @Inject utils;
  @Inject notify;
  @Inject $filter;

  @State me;
  @State clients;
  @State institutions;
  @State showIdInActions;

  @Action('clients/remove') removeClient;
  @Action('clients/list') listClients;
  @Mutation('clients/update') updateClients;
  @Action('clients/getForInstitution') getInstitutionClients;
  @Action('clients/list') getAllClients;
  @Action('institutions/getAll') listInstitutions;

  @Getter isAdmin;

  get tableItems() {
    return this.clients.items || [];
  }

  async $onInit() {
    const $vm = this;

    if (this.$stateParams.loadOnClick && !this.isAdmin) {
      try {
        await this.listClients();
      } catch (err) {
        this.notify.display(err, 'error');
      }
    }

    // this.tableItems = this.clients.items;

    this.tableProps = [
      {
        label: 'Name',
        value: (row) => `${row.name(true)}`
      },
      {
        label: this.$filter('clientLabel')('localId', true),
        value: 'localId'
      },
      {
        label: 'Institution',
        value: 'institution.name'
      },
      {
        label: 'Location',
        value: 'subGroup.name'
      },
      {
        label: this.$filter('clientLabel')('dob', true),
        value: 'dob',
        filter: {
          type: 'date'
        }
      },
      {
        label: 'Country',
        value: 'country'
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          // GET ==> `/api/invites/${id}/resend`
          {
            label: 'Edit',
            icon: 'edit',
            fn: (val) => $vm.openEditClient(val),
            hide: () =>
              !$vm.$auth.hasAccess(['institutionmanager:UpdateClient'])
          },
          //
          {
            label: 'Evaluations',
            icon: 'copy',
            fn: (val) =>
              $vm.$state.go('dashboardEvaluations', { clientId: val.id }),
            hide: () =>
              !$vm.$auth.hasAccess('clientmanager:ListClientEvaluations')
          },
          // DELETE/EXPUNGE
          {
            label: 'Create Expunge Request',
            icon: 'times',
            fn: (val) => $vm.createExpungeRequest(val),
            hide: () =>
              !$vm.$auth.hasAccess(
                'institutionmanager:CreateExpungeClientRequest'
              ),
            specClass: 'danger'
          }
        ]
      },
      {
        label: 'Profile',
        icon: 'user',
        // actions: val => $goTo('dashboardClient', { id: val.id.toString() })
        actions: (val) => {
          $vm.$state.go('dashboardClient', { id: val.id.toString() });
        }
      }
    ];
  }

  clientCreate() {
    this.$modals.create.client();
  }

  async openEditClient(client) {
    this.$store.commit(
      'clients/SET_FOCUS',
      client.id ? client.id.toString() : null
    );
    let newData = await this.$modals.edit.client(client);

    if (newData) {
      this.updateClients(newData);
    }
  }

  clientBulkCreate() {
    this.$modals.create.bulkClients(this.me.institution?.id);
  }

  clientCreateRequest() {
    // if (me.activeRole.roleId === 7 || me.activeRole.roleId === 8) {
    //   $modals.create.clientRequest(institutions)(() => {});
    // } else {
    //   $modals.create.clientRequest(null)(() => {});
    // }
    this.$modals.create.clientRequest(this.institutions);
  }

  async createExpungeRequest(client) {
    this.$store.commit('setLoadingMessage', 'Creating Request...');
    this.$store.commit('setIsLoading', true);
    let res;
    try {
      let res = await this.$api.IM.createExpungeClientRequest(
        {
          instId: client.institutionId
        },
        {
          clientId: client.id
        }
      );
      this.notify.display(
        'You will receive an email shortly with an expunge key to finish the client deletion process',
        'success',
        true,
        'Expunge Request Created'
      );
      this.$modals.settings.expungeClient(res.data.id, client);
    } catch (err) {
      if (err.response?.data?.code === 'ONGOING_EXPUNGE_CLIENT_REQUEST') {
        this.notify.display(
          'You already have an ongoing request, please provide the Expunge ID and Validation Key created on your initial request',
          'warning'
        );
        this.$modals.settings.expungeClient('', client, true);
      } else {
        this.notify.display(err, 'error');
      }
    } finally {
      this.$store.commit('setIsLoading', false);
    }
  }

  async removeClient({ id }) {
    try {
      await this.removeClient(id);

      this.utils.notify(
        'success',
        'Client Successfully Removed from Institution.'
      );
      // this.getClientsForInstitution();
    } catch (err) {
      this.errorHandler(err);
    }
  }

  async loadInstitutions() {
    if (!this.institutions?.items?.length) {
      this.loadingInstitutions = true;
      try {
        await this.listInstitutions();
      } catch (err) {
        this.notify.display(err, 'error');
      } finally {
        this.loadingInstitutions = false;
      }
    }
  }
}

export default DashboardClientsView;
