import angular, { Controller, Inject } from '@angular';

@Controller
class NavItemSelect {
  @Inject $scope;

  get options() {
    return this.$scope.options;
  }

  get ngModel() {
    return this.$scope.ngModel;
  }

  set ngModel(val) {
    this.$scope.ngModel = val;
  }
}

export default angular
  .module('app.nav-item-select', [])
  .directive('navItemSelect', () => ({
    restrict: 'E',
    replace: true,
    scope: {
      options: '<',
      ngModel: '='
    },
    template: require('./nav-item-select.html'),
    controller: NavItemSelect,
    controllerAs: 'vm'
  })).name;
