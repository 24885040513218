import { Component } from '@angular';
import { intervalToDuration } from 'date-fns';

import { Modal } from '@mixins/modal.component';

@Component({
  name: 'dateCalculatorModal',
  template: require('./date-calculator.html')
})
export class DateCalculatorModalController extends Modal {
  startDate: Date = new Date();
  endDate: Date = new Date();
  duration = intervalToDuration({ start: new Date(), end: new Date() });

  onDateChanged = () => {
    const start = new Date(this.startDate);
    const end = new Date(this.endDate);

    try {
      this.duration = intervalToDuration({ start, end });
    } catch (err) {
      console.error(err);
    }
  };

  $onInit() {
    this.$watch(() => this.startDate, this.onDateChanged);
    this.$watch(() => this.endDate, this.onDateChanged);
  }
}

export default DateCalculatorModalController.$module;
