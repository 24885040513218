import { forEach } from 'angular';
import { find } from 'lodash';

import { ToolData } from '@interfaces';

/**
 * ...
 */
export class ToolService {
  constructor() {
    'ngInject';
  }

  /**
   * ...
   *
   * @param tool ...
   * @param evalData ...
   * @param valid ...
   */
  setToolOmitsValidity(
    tool: ToolData,
    evalData: unknown,
    valid: Boolean = false
  ) {
    if (!evalData)
      throw console.error(
        '[tool-service:set-tool-omits-validity]: no evalData provided'
      );
    const checkTool = function (t: unknown) {
      // check codingFormItems against Evaluation
      if (t.codingFormItems?.length) {
        forEach(t.codingFormItems, (cfi) => {
          // cfi.longAddress on evalData[cfi.longAddress]
          const item = evalData[cfi.longAddress];
          if (item?.text === 'Omit' || item?.text === 'omit') cfi.valid = valid;
        });
      }

      if (t.childTools?.length) forEach(t.childTools, (ct) => checkTool(ct));
    };

    checkTool(tool);
  }

  /**
   * ...
   *
   * @param tool ...
   * @param evalData ...
   * @param groupId ...
   * @param valid ...
   */
  setToolGroupingsOmitsValidity(
    tool: ToolData,
    evalData: unknown,
    groupId: string,
    valid: Boolean = false
  ) {
    forEach(evalData, (item) => {
      let qid = item.qid ? item.qid : null;
      qid = qid ? qid.split('>') : null;
      qid = qid ? qid[qid.length - 1] : null;

      const toolQuestionData = find(tool.codingFormItems, { id: qid });

      if (
        (item.text === 'Omit' || item.text === 'omit') &&
        toolQuestionData?.groupings?.includes(groupId)
      ) {
        toolQuestionData.valid = valid;
      }
    });
  }
}
