import angular from 'angular';

import { CodeTokenizerService } from './code-tokenizer.service';

declare module 'angular' {
  namespace gears {
    type ICodeTokenizerService = CodeTokenizerService;
  }
}

export default angular
  .module('app.code-tokenizer', [])
  .service('codeTokenizer', CodeTokenizerService).name;
