import angular from 'angular';
import { uniq } from 'lodash';

/**
 * ...
 */
type WatchExpression<T> = string | ((scope: angular.IScope) => T);

/**
 * ...
 */
type ListenerCallback<T> = (
  newValue: T,
  oldValue: T,
  scope: angular.IScope
) => unknown;

export interface IController {
  $scope: angular.IScope;
  $emit(event: string, ...args: unknown[]): void;
  $on(
    event: string,
    listener: (event: angular.IAngularEvent, ...args: any[]) => any
  ): void;
  $watch<T>(
    watchExpression: WatchExpression<T>,
    listener?: ListenerCallback<T>,
    objectEquality?: boolean
  ): void;
  $watchCollection<T>(
    watchExpression: WatchExpression<T>,
    listener: ListenerCallback<T>
  ): void;
}

interface NgListenerOptions {
  eventName: string;
  propName: string;
  includeEvent: boolean;
}

interface NgWatcherOptions {
  propRef: any;
  cbName: string;
  objectEquality: boolean;
}

interface NgCollectionWatcherOptions {
  objKey: string;
  listenerKey: string;
}

interface NgGroupWatcherOptions {
  watchExpressions: any;
  listener: string;
}

interface ControllerRegistryEntry {
  ctrl: any;
  inject: string[];
  listeners: NgListenerOptions[];
  watchers: NgWatcherOptions[];
  collectionWatchers: NgCollectionWatcherOptions[];
  groupWatchers: NgGroupWatcherOptions[];
}

class ControllerRegistry {
  entries: ControllerRegistryEntry[] = [];

  find(ctrl) {
    return this.entries.find((entry) => entry.ctrl === ctrl);
  }

  add(ctrl): ControllerRegistryEntry {
    const entry = {
      ctrl,
      inject: [],
      listeners: [],
      watchers: [],
      collectionWatchers: [],
      groupWatchers: []
    };

    this.entries.push(entry);

    return entry;
  }

  ensure(ctrl) {
    return this.find(ctrl) || this.add(ctrl);
  }
}

const registry = new ControllerRegistry();

function fetchNestedProp(obj, propStr) {
  let prop = obj,
    propPath = propStr.split('.');

  for (var propName of propPath) {
    if (!prop || typeof prop != 'object' || !(propName in prop)) {
      prop = undefined;
      break;
    }

    prop = prop[propName];
  }

  return prop === undefined ? undefined : JSON.parse(JSON.stringify(prop));
}

function addWatcher(ctrl, watchExpression, listenerRef, objectEquality) {
  const { $scope } = ctrl;

  let cb = ctrl[listenerRef];

  if (typeof cb != 'function') {
    throw new Error(
      `[ngCtrl] Registered watcher "${listenerRef}" was not a function.`
    );
  }

  // Property value getter.
  const watcherVal = function watcherVal() {
    return typeof watchExpression == 'function'
      ? watchExpression({ ctrl, scope: $scope })
      : fetchNestedProp(ctrl, watchExpression);
  };

  // Local reference for old value.
  var oldVal = watcherVal();

  let cbRef = $scope.$watch(
    watcherVal,
    function (newVal) {
      // Only trigger watcher callback if
      // values are not equal.
      if (newVal === oldVal) {
        return;
      }

      cb.apply(ctrl, [newVal, oldVal]);

      oldVal = newVal;
    },
    objectEquality
  );

  $scope.$on('$destroy', () => cbRef());
}

function registerCtrlGroupWatcher(ctrl, expression, listener) {
  const listenerFn = ctrl[listener];

  if (typeof listenerFn != 'function') {
    throw new Error(
      `[ngCtrl] Registered watcher "${listener}" was not a function.`
    );
  }

  expression = expression.map((expr) => {
    let localExpr;

    if (typeof expr == 'function') {
      localExpr = () => expr({ ctrl, scope: ctrl.$scope });
    } else {
      localExpr = () => fetchNestedProp(ctrl, expr);
    }

    return localExpr;
  });

  let deRegFn = ctrl.$scope.$watchGroup(expression, (newVal, oldVal) =>
    listenerFn.apply(ctrl, [newVal, oldVal])
  );

  ctrl.$scope.$on('$destroy', () => deRegFn());
}

export function Controller(Ctrl: any) {
  const entry = registry.ensure(Ctrl.prototype);

  if (Ctrl.__proto__.$inject) {
    entry.inject.push(...Ctrl.__proto__.$inject);
  }

  entry.inject = uniq(['$scope', ...entry.inject]);

  const ngCtrl = class NgController extends Ctrl implements IController {
    $scope: angular.IScope;

    constructor(...args: any) {
      super();

      for (const i in entry.inject) {
        this[entry.inject[i]] = args[i];
      }

      const { $scope } = this;

      entry.listeners.forEach((item) => {
        let cb = this[item.propName];

        if (typeof cb != 'function') {
          console.error(
            `[ngCtrl] Registered listener for event "${item.eventName}" was not a function.`
          );

          return;
        }

        let cbRef = $scope.$on(item.eventName, (e, ...args) => {
          if (item.includeEvent) {
            cb.apply(this, [e, ...args]);
          } else {
            cb.apply(this, [...args]);
          }

          setTimeout(() => $scope.$apply());
        });

        $scope.$on('$destroy', () => cbRef());
      });

      // Initialize any Watchers
      for (let { propRef, cbName, objectEquality } of entry.watchers) {
        addWatcher(this, propRef, cbName, objectEquality || false);
      }

      for (let { objKey, listenerKey } of entry.collectionWatchers) {
        this.$watchCollection(this[objKey], (newVal, oldVal) =>
          this[listenerKey](newVal, oldVal)
        );
      }

      entry.groupWatchers.forEach(({ watchExpressions, listener }) => {
        registerCtrlGroupWatcher(this, watchExpressions, listener);
      });

      if (this.$onCreate && typeof this.$onCreate == 'function') {
        this.$onCreate();
      }
    }

    $emit(event: string, ...args) {
      this.$scope.$emit(event, ...args);
    }

    $on(
      event: string,
      listener: (event: angular.IAngularEvent, ...args: any[]) => any
    ) {
      this.$scope.$on(event, (...args) => listener(...args));
    }

    $watch(expression, listener, objectEquality = false) {
      let exprFn;

      if (typeof expression == 'string') {
        exprFn = `vm.${expression}`;
      } else if (typeof expression == 'function') {
        exprFn = () => expression();
      }

      this.$scope.$watch(
        exprFn,
        (newVal, oldVal) => listener(newVal, oldVal),
        objectEquality
      );
    }

    $watchCollection(obj, listener) {
      return this.$scope.$watchCollection(obj, listener);
    }
  };

  ngCtrl.$inject = entry.inject;

  return ngCtrl as any;
}

export function Inject(target: any, prop: string) {
  const entry = registry.ensure(target);

  entry.inject.push(prop);

  return { writable: true, value: undefined } as any;
}

export function On(eventName, includeEvent = false) {
  return function (target: any, prop: string) {
    const entry = registry.ensure(target);

    const listener = {
      eventName,
      propName: prop,
      includeEvent
    };

    entry.listeners.push(listener);

    return { writable: true, value: target[prop] } as any;
  };
}

export function Watch(propRef, objectEquality = false) {
  return function (target: any, prop: string) {
    const entry = registry.ensure(target);

    const watcher = {
      propRef,
      cbName: prop,
      objectEquality: !!objectEquality
    };

    entry.watchers.push(watcher);

    return { writable: true, value: target[prop] } as any;
  };
}

export function WatchCollection(objKey) {
  return function (target: any, prop: string) {
    const entry = registry.ensure(target);

    const watcher = {
      objKey,
      listenerKey: prop
    };

    entry.collectionWatchers.push(watcher);

    return { writable: true, value: target[prop] };
  };
}

export function WatchGroup(...watchExpressions) {
  return function (target: any, prop: string) {
    const entry = registry.ensure(target);

    const watcher = {
      watchExpressions,
      listener: prop
    };

    entry.groupWatchers.push(watcher);

    return { writable: true, value: target[prop] };
  };
}
