import angular from 'angular';
import { find, uniqBy } from 'lodash';
import { ActionTree, MutationTree, GetterTree } from 'angular-store';

import { TableState } from '@interfaces';
import { User } from '@interfaces/user';
import { HasAccessCheckOptions } from '@services/acl/acl.service';
import { RootState } from '@store/state';

/**
 * ...
 */
export interface UsersState {
  [key: string]: unknown;
  loading: false;
  items: User[];
  tableProps: TableState;
}

/** ... */
export interface GetUserActionOptions {
  instId: string; // TODO: Change to "institutionId" for consistancey.
  userId: string;
}

/** ... */
export interface RemoveUserActionOptions {
  id: string; // TODO: Change to "userId" for clarity.
  institutionId: string;
  InstitutionUser?: { institutionId: string }; // TODO: Perhaps refactor out?
}

/** ... */
export interface DeleteUserActionOptions {
  id: string; // TODO: Change to "userId" for clarity.
}

/** ... */
export type SetUsersMutationOptions = User[];

/** ... */
export type AddUsersMutationOptions = User | User[];

/** ... */
export type UpdateUserMutationOptions = Partial<User> & { id: User['id'] };

/** ... */
export interface SetTablePropsMutationOptions {
  sortedCol?: number;
  searchText?: string | null;
}

export default function UsersStore(
  $injector: angular.auto.IInjectorService,
  $api: angular.gears.IApiService,
  $api2: angular.gears.IAPI2Service,
  notify: angular.gears.INotifyService
) {
  'ngInject';

  /** ... */
  const hasAccess = (actions: HasAccessCheckOptions) =>
    $injector.get<angular.gears.IAuthService>('$auth').hasAccess(actions);

  const state: UsersState = {
    loading: false,
    items: [],
    tableProps: { sortedCol: 0, searchText: '' }
  };

  const getters: GetterTree<UsersState, RootState> = {
    /**
     * Find a user by ID.
     *
     * @return The matching user, if found.
     */
    find: (state) => (id: User['id']) => find(state.items, { id })
  };

  const actions: ActionTree<UsersState, RootState> = {
    async list({ state, commit, rootGetters, dispatch }) {
      let promise: Promise<User[]> | null = null;
      let analyticsUrl = '';

      if (hasAccess('GM:ListUsers')) {
        analyticsUrl = 'analytics/computeForUsers';
        promise = $api2.gm.listUsers();
      } else if (hasAccess('IM:ListInstitutionUsers')) {
        analyticsUrl = 'analytics/computeForUsers';
        promise = $api2.im.listInstitutionUsers({
          institutionId: rootGetters.activeInstId as string
        });
      } else {
        throw new Error('');
      }

      let data: User[] = [];
      let error: unknown = null;

      commit('setProps', { loading: true });

      try {
        data = await promise;
      } catch (err) {
        error = err;
      }

      commit('setProps', { loading: false });

      if (error) {
        throw error;
      }

      commit('setProps', { items: data });

      dispatch(analyticsUrl, null, true);

      return state.items;
    },
    async getAll({ state, commit, dispatch }) {
      let data: User[] = [];
      let error: unknown = null;

      commit('setProps', { loading: true });

      try {
        data = await $api2.gm.listUsers();
      } catch (err) {
        error = err;
      }

      commit('setProps', { loading: false });

      if (error) {
        throw error;
      }

      commit('set', data);

      dispatch('analytics/computeForUsers', null, true);

      return state.items;
    },
    async getForInstitution(
      { state, commit, dispatch },
      institutionId: string
    ) {
      let data: User[] = [];
      let error: unknown = null;

      commit('setProps', { loading: true });

      try {
        if (hasAccess('GM:ListUsers')) {
          data = await $api2.gm.searchUsers({ institutionId });
        } else {
          data = await $api2.im.listInstitutionUsers({ institutionId });
        }
      } catch (err) {
        error = err;
      }

      commit('setProps', { loading: false });

      if (error) {
        throw error;
      }

      commit('set', data);

      dispatch('analytics/computeUsersForInstitutions', null, true);

      return state.items;
    },
    async get({ commit, rootGetters }, options: GetUserActionOptions) {
      if (!options.userId) {
        throw new Error('[users-store] No User Id Provided');
      }

      let data: User | null = null;
      let error: unknown = null;

      const promise = rootGetters.isAdmin
        ? $api2.gm.getUser({ userId: options.userId })
        : $api2.im.getInstitutionUser({
            institutionId: options.instId,
            userId: options.userId
          });

      commit('setProps', { loading: true });

      try {
        data = await promise;
      } catch (err) {
        error = err;
      }

      commit('setProps', { loading: false });

      if (error) {
        throw error;
      }

      commit('add', { ...data });

      return data;
    },
    async remove({ commit, rootGetters }, payload: RemoveUserActionOptions) {
      const institutionId =
        payload.institutionId ??
        payload.InstitutionUser?.institutionId ??
        (rootGetters.activeInstId as string | null);

      if (!institutionId) {
        return notify.display(
          `Could not detect user\'s institution id. Contact ${process.env.SUPPORT_EMAIL} for assistance.`,
          'error'
        );
      }

      const options = {
        institutionId,
        userId: payload.id
      };

      try {
        await $api.IM.removeUser(
          { instId: options.institutionId },
          { userId: options.userId }
        );
        // await $api2.im.removeUser(options);
      } catch (err) {
        return notify.display(err, 'error');
      }

      commit('remove', payload.id);
    },
    // NOTE: Is this valid/still used.
    async delete({ commit }, payload: DeleteUserActionOptions) {
      const res = await $api.GM.deleteUser({ userId: payload.id });

      if (res.status !== 204 && res.status !== 200) {
        return notify.display(res, 'error');
      }

      commit('remove', payload.id);
    }
  };

  const mutations: MutationTree<UsersState> = {
    SET(state, payload: SetUsersMutationOptions) {
      state.items = [...payload];
    },
    ADD(state, payload: AddUsersMutationOptions) {
      if (!Array.isArray(payload)) payload = [payload];

      state.items = uniqBy([...payload, ...state.items], 'id');
    },
    UPDATE_ITEM({ items }, payload: UpdateUserMutationOptions) {
      let ref = items.find((item) => item.id === payload.id);

      if (!ref) return;

      for (let [key, val] of Object.entries(payload)) {
        ref[key] = val;
      }
    },
    REMOVE({ items }, id: User['id']) {
      // remove(state.items, { id })

      items.splice(
        items.findIndex((item) => item.id === id),
        1
      );
    },
    SET_TABLE_PROPS({ tableProps }, options: SetTablePropsMutationOptions) {
      if (options.sortedCol !== undefined) {
        tableProps.sortedCol = !isNaN(options.sortedCol)
          ? options.sortedCol
          : 0;
      }

      if (options.searchText !== undefined) {
        tableProps.searchText = options.searchText ? options.searchText : '';
      }
    },
    SET_PROPS(state, props: Partial<UsersState> = {}) {
      for (const key in props) {
        if (key in state) state[key] = props[key];
      }
    },
    CLEAR(state) {
      Object.assign(state, {
        loading: false,
        items: [],
        tableProps: { sortedCol: 0, searchText: '' }
      });
    }
  };

  // TEMP: remove once all references to them have been removed.
  Object.defineProperties(mutations, {
    set: { enumerable: true, value: mutations.SET },
    add: { enumerable: true, value: mutations.ADD },
    updateItem: { enumerable: true, value: mutations.UPDATE_ITEM },
    remove: { enumerable: true, value: mutations.REMOVE },
    setTableProps: { enumerable: true, value: mutations.SET_TABLE_PROPS },
    setProps: { enumerable: true, value: mutations.SET_PROPS }
  });

  return { state, getters, actions, mutations };
}
