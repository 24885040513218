'use strict';

import angular from 'angular';

const ngElif = angular.module('app.ngElif', []);
var CONDITIONALS = 'elif.conditionals';

function getBlockElements(nodes) {
  if (!nodes || !nodes.length) {
    return angular.element();
  }

  var startNode = nodes[0];
  var endNode = nodes[nodes.length - 1];

  if (startNode === endNode) {
    return angular.element(startNode);
  }

  var element = startNode;
  var elements = [element];

  do {
    element = element.nextSibling;
    if (!element) {
      break;
    }
    elements.push(element);
  } while (element !== endNode);

  return angular.element(elements);
}

function makeDirective(name, method, getter) {
  ngElif.directive(name, ($animate, $document, $injector, elif) => {
    'ngInject';

    var getterFn = getter && $injector.invoke(getter);

    return {
      transclude: 'element',
      restrict: 'A',
      priority: 600,
      terminal: true,
      link(scope, element, attrs, ctrls, transclude) {
        var watchFn = getterFn && getterFn(scope, element, attrs);
        var childScope;
        var childElement;
        var previousElements;

        elif[method](scope, watchFn, function(value) {
          if (value) {
            if (!childScope) {
              childScope = scope.$new();

              transclude(childScope, function(clone) {
                clone[clone.length + 1] = $document[0].createComment(
                  ' end ' + name + ': ' + attrs[name] + ' '
                );

                childElement = clone;
                $animate.enter(clone, element.parent(), element);
              });
            }
          } else {
            if (childScope) {
              childScope.$destroy();
              childScope = null;
            }

            if (previousElements) {
              previousElements.remove();
              previousElements = null;
            }

            if (childElement) {
              previousElements = getBlockElements(childElement);
              $animate.leave(previousElements, function() {
                previousElements = null;
              });

              childElement = null;
            }
          }
        });
      }
    };
  });
}

function getter(name) {
  return function($parse) {
    'ngInject';

    return function(scope, element, attrs) {
      var testFn = $parse(attrs[name]);

      return function() {
        return !!testFn(scope);
      };
    };
  };
}

ngElif.factory('elif', () => {
  const getConditionals = scope => {
    if (angular.hasOwnProperty.call(scope, CONDITIONALS)) {
      var conditionals = scope[CONDITIONALS];

      return conditionals[conditionals.length - 1];
    }
  };

  return {
    create(scope, fn, callback) {
      var conditionals = [
        {
          fn: fn,
          callback: callback || angular.identity
        }
      ];

      var conditionalVals = [];

      scope.$watch(
        () => {
          var i;
          var len = conditionals.length;
          conditionalVals.length = len;

          for (i = 0; i < len; i++) {
            if ((conditionalVals[i] = !!conditionals[i].fn())) {
              i++;
              break;
            }
          }

          for (; i < len; i++) {
            conditionalVals[i] = false;
          }

          return conditionalVals;
        },
        conditionalVals => {
          // Update each block; also find first matching if/else-if.
          var index = -1;
          for (var i = 0, len = conditionals.length; i < len; i++) {
            if (conditionalVals[i]) {
              conditionals[i].callback(true);
              index = i;
            } else {
              conditionals[i].callback(false);
            }
          }

          conditionals.fallthrough && conditionals.fallthrough(index === -1);
        },
        true
      );

      if (!angular.hasOwnProperty.call(scope, CONDITIONALS)) {
        scope[CONDITIONALS] = [];
      }

      scope[CONDITIONALS].push(conditionals);
    },
    extend(scope, fn, callback) {
      var conditionals = getConditionals(scope);

      if (!conditionals) {
        throw new Error('elif.extend: no if found at this level');
      }

      if (conditionals.fallthrough) {
        throw new Error('elif.extend: else-if after else');
      }

      conditionals.push({
        fn: fn,
        callback: callback
      });
    },
    fallthrough(scope, fn, callback) {
      var conditionals = getConditionals(scope);

      if (!conditionals) {
        throw new Error('elif.fallthrough: no if found at this level');
      }

      if (conditionals.fallthrough) {
        throw new Error('elif.fallthrough: else already found at this level');
      }

      conditionals.fallthrough = callback;
    }
  };
});

ngElif.directive('ngIf', ($injector, elif) => {
  'ngInject';

  var getterFn = $injector.invoke(getter('ngIf'));

  return {
    priority: 600,
    link(scope, element, attrs) {
      var watchFn = getterFn(scope, element, attrs);

      elif.create(scope, watchFn);
    }
  };
});

makeDirective('ngElseIf', 'extend', getter('ngElseIf'));
// makeDirective('ngElif', 'extend', getter('ngElif'));
makeDirective('ngElse', 'fallthrough');

export default ngElif.name;
