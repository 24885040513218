'use strict';

import angular from 'angular';

export default angular
  .module('app.stopEvent', [])
  .directive('stopEvent', () => {
    return {
      restrict: 'A',
      link(scope, element, attrs) {
        let events = scope.$eval(attrs.stopEvent) || [];

        if (!Array.isArray(events)) {
          events = [(events || '').toString()];
        }

        element.on(events.join(' '), e => e.stopPropagation());
      }
    };
  }).name;
