'use strict';

import angular from 'angular';

import { State, Action } from 'angular-store';
import { Controller, Inject, Watch } from '@/decorators/ngCtrl';

@Controller
class DashboardLogsView {
  logData: {}[] = [];

  @Inject utils;

  @State logs;
  @Action('logs/list') fetchLogs;

  @Watch('logs', true)
  onLogsChanged() {
    // this.logData = this.logs.items.map(item => {
    //   let data = { ...item };
    //   data.changedFrom = this.utils.clone(data.changedFrom);
    //   data.changedTo = this.utils.clone(data.changedTo);
    //
    //   return data;
    // });

    this.logData = [];

    for (let i = 0; i < 50; i++) {
      let ref = this.logs.items[i];

      let copy = { ...ref };
      copy.changedFrom = !!copy.changedFrom
        ? JSON.stringify(JSON.parse(copy.changedFrom), null, 4)
        : '';
      copy.changedTo = !!copy.changedTo
        ? JSON.stringify(JSON.parse(copy.changedTo), null, 4)
        : '';
      // console.log(copy.changedTo);

      this.logData.push(copy);
    }
  }

  $onInit() {
    // this.fetchLogs();
  }
}

export default DashboardLogsView;
