import angular from 'angular';
import { State } from 'angular-store';

import { Controller, Inject } from '@decorators/ngCtrl';
import { RootModules } from '@store/state';

@Controller
export class DashboardEvaluationRequestsView {
  tableProps: unknown[] = [];
  tableActions: unknown[] = [];
  searchText = '';

  @Inject readonly $rootScope!: angular.IRootScopeService;
  @Inject readonly $location!: angular.ILocationService;
  @Inject readonly $state!: angular.ui.IStateService;
  @Inject readonly $store!: angular.gears.IStoreService;
  @Inject readonly $modals!: angular.gears.IModalsService;
  @Inject readonly $auth!: angular.gears.IAuthService;
  @Inject readonly utils!: angular.gears.IUtilsService;
  @Inject readonly dataModals!: angular.gears.IDataModalsService;

  @State readonly me!: RootModules['me'];
  @State readonly evaluationRequests!: RootModules['evaluationRequests'];
  @State readonly clients!: RootModules['clients'];

  get tableItems() {
    return this.evaluationRequests?.items || [];
  }

  async $onInit() {
    if (!this.me.institutionId) {
      this.$store.dispatch('evaluationRequests/list');
    }

    // this.tableItems = this.evaluationRequests?.items || [];

    const { $auth, dataModals } = this;

    this.tableProps = [
      {
        label: 'Due Date',
        value: 'dueDate',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy'
        }
      },
      {
        label: 'Client',
        value: ({ client }) => this.utils.displayFullName(client)
      },
      {
        label: 'Evaluator',
        value: ({ evaluator }) => this.utils.displayFullName(evaluator)
      },
      {
        label: 'Tool',
        value: 'tool.name'
      },
      {
        label: 'Status',
        value: ({ evaluation }) => {
          let output = '';

          if (!evaluation) return 'N/A';

          if (evaluation.status === 'IN_PROGRESS') {
            output = `<label class="status-label in-progress">In Progress</label>`;
          } else if (evaluation.status === 'NOT_STARTED') {
            output = `<label class="status-label not-started">Not Started</label>`;
          } else if (evaluation.status === 'COMPLETED') {
            output = `<label class="status-label completed">Completed</label>`;
          }

          return output;
        }
      }
    ];

    this.tableActions = [
      {
        label: 'Delete',
        icon: 'times',
        actions: (val) => dataModals.delete(val, 'evaluation-request'),
        hide: () => !$auth.hasAccess('IM:DeleteEvaluationRequest')
      }
    ];
  }

  async requestCreate() {
    await this.$modals.create.evaluationRequest(this.clients);
  }
}

export default DashboardEvaluationRequestsView;
