// YLS/CMI SRV Profile Report Generator
import { find, forEach } from 'lodash';
import angular from 'angular';
import ylsSRVProfileReportCoverPage from './coverPage.html';
import ylsSRVTables from './tables';
import ylsSRVImageData from './imageDataUrls';
import 'jspdf-autotable';
import { differenceInYears } from 'date-fns';
import fontDataUrls from '../fontDataUrls';
import JsPDF from 'jspdf';

export default {
  async generate(
    mhsLogoDataUrl,
    pageSetup,
    services,
    client,
    evaluation,
    tool,
    $me,
    callback
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let $filter = services.$filter;
    let $templateCache = services.$templateCache;
    let Util = services.$util;
    let Upload = services.Upload;
    let $store = services.$store;
    let $reincode = services.$reincode;
    let ylsSRVLogoDataUrl = ylsSRVImageData.ylsSRVLogo();

    const evaluationCompletedDate = evaluation.updatedAt;
    const assessmentDate = evaluation.assignedDate;
    const interviewDate = evaluation.evaluation?.interview?.dateTime || 'N/A';
    let evaluationId = evaluation.id;
    let generalNotesForEvaluation = evaluation.evaluation.generalNotes;

    let checkEndOfPage = function (y) {
      return y >= pageHeight - margins.bottom - 100;
    };

    let checkAgeRange = function () {
      // age range = 12 - 18 from when evlauation was completed (evaluation.updatedAt)
      // let clientAgeAtEvalCompletion = moment
      //   .duration(moment(evaluationCompletedDate).diff(moment(client.dob)))
      //   .asYears();
      const clientAgeAtEvalCompletion = differenceInYears(
        new Date(evaluationCompletedDate),
        new Date(client.dob)
      );
      return clientAgeAtEvalCompletion >= 12 && clientAgeAtEvalCompletion <= 18;
    };

    let evaluator = evaluation.evaluator;
    if (!evaluator)
      evaluator =
        evaluation.evaluatorId === $me.id
          ? $me
          : find($store.state.users.items, { id: evaluation.evaluatorId });
    if (evaluation.evaluation) evaluation = evaluation.evaluation;
    if (evaluation.evaluationData) evaluation = evaluation.evaluationData;

    let evaluationScoreTotal = 0;
    forEach(evaluation.data, (item) => {
      if (typeof item.score === 'number') evaluationScoreTotal += item.score;
    });

    let followUpClassification;
    if (evaluationScoreTotal >= 0 && evaluationScoreTotal <= 2) {
      followUpClassification = 'desirable';
    } else if (evaluationScoreTotal >= 3 && evaluationScoreTotal <= 5) {
      followUpClassification = 'strongly recommended';
    } else if (evaluationScoreTotal >= 6 && evaluationScoreTotal <= 8) {
      followUpClassification = 'mandatory';
    }

    $templateCache.put(
      'ylsSRVProfileReportCoverPage.html',
      ylsSRVProfileReportCoverPage
    );
    let ylsSRVPRCoverPage = $templateCache.get(
      'ylsSRVProfileReportCoverPage.html'
    );
    let ylsPRCoverPageTable = JSON.stringify(ylsSRVTables.coverPage());

    // create variable map of known variables in report
    let variableMap = {};
    let key;
    const clientAge = client.age;
    variableMap[`clientAge`] = clientAge;
    const clientLocation = client.location;
    variableMap[`clientLocation`] = clientLocation;
    let clientAssessmentLocation = '';
    if (evaluation.clientZoneName)
      clientAssessmentLocation += `${evaluation.clientZoneName}`;
    if (evaluation.clientRegionName)
      clientAssessmentLocation += ` > ${evaluation.clientRegionName}`;
    if (evaluation.clientSubGroupName)
      clientAssessmentLocation += ` > ${evaluation.clientSubGroupName}`;
    if (clientAssessmentLocation.length == 0)
      clientAssessmentLocation = 'LOCATION NOT FOUND';
    variableMap[`clientAssessmentLocation`] = clientAssessmentLocation;
    for (key in client) {
      if (key === 'type') {
        let typeList = '';
        if (client.type) {
          let types =
            typeof client.type === 'string'
              ? client.type.split(',')
              : client.type;
          if (types.length === 1) {
            typeList = types[0];
          } else {
            forEach(types, (type, index) => {
              if (index === types.length - 1) {
                typeList += `and ${type}`;
              } else {
                typeList += `${type}, `;
              }
            });
          }
        } else {
          typeList = 'No Client Types Provided';
        }
        variableMap[`client.${key}`] = typeList;
      } else if (key === 'fName' || key === 'lName' || key === 'localId') {
        variableMap[`client.${key}`] = client[key] ? client[key] : 'N/A';
      } else {
        variableMap[`client.${key}`] = client[key]
          ? Util.decamlize(client[key])
          : 'N/A';
      }
    }

    // handle gender exclusively
    if (!client.gender) variableMap['client.gender'] = '-';

    variableMap['date.today'] = $filter('dynamicDate')(new Date(), 'longDate');
    variableMap['evaluationCompletedDate'] = $filter('dynamicDate')(
      new Date(evaluationCompletedDate),
      'longDate'
    );
    variableMap['assessmentDate'] = $filter('dynamicDate')(
      new Date(assessmentDate),
      'longDate'
    );
    variableMap['interviewDate'] = $filter('dynamicDate')(
      new Date(interviewDate),
      'longDate'
    );

    if (evaluator) {
      for (key in evaluator) {
        if (key === 'fName' || key === 'lName') {
          variableMap[`user.${key}`] = evaluator[key];
        } else {
          variableMap[`user.${key}`] = Util.decamlize(evaluator[key]);
        }
      }
    } else {
      variableMap[`user.fName`] = 'N/A';
      variableMap[`user.lfName`] = 'N/A';
    }

    //================ CREATE VARIABLE MAP =================
    let variableRegEx = Object.keys(variableMap).join('|');
    variableRegEx = variableRegEx.replace(/\[/g, '\\[');
    variableRegEx = variableRegEx.replace(/]/g, '\\]');
    variableRegEx = variableRegEx.replace(/\)/g, '\\)');
    variableRegEx = variableRegEx.replace(/\(/g, '\\(');
    variableRegEx = variableRegEx.replace(/#/g, '\\#');
    variableRegEx = variableRegEx.replace(/\+/g, '\\+');
    variableRegEx = new RegExp(variableRegEx, 'gi');

    ylsSRVPRCoverPage = ylsSRVPRCoverPage.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    ylsPRCoverPageTable = ylsPRCoverPageTable.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    ylsPRCoverPageTable = JSON.parse(ylsPRCoverPageTable);
    //================ END CREATE VARIABLE MAP =================
    //=================== PDF DOC SETUP ==================
    let specialElementHandlers = {
      '#editor': function (element, renderer) {
        return true;
      }
    };
    let pdf = new JsPDF('p', 'pt', 'letter');

    // set comfortaa font
    pdf.addFileToVFS('ComfortaaRegular.tff', fontDataUrls.comfortaaRegular());
    pdf.addFont('ComfortaaRegular.tff', 'Comfortaa', 'normal');
    pdf.addFileToVFS('ComfortaaBold.tff', fontDataUrls.comfortaaBold());
    pdf.addFont('ComfortaaBold.tff', 'Comfortaa', 'bold');
    pdf.setFont('Comfortaa');
    let page = 2;

    let header = function () {
      pdf.setFontSize(11);
      pdf.setFontStyle('normal');
      pdf.text(
        `YLS/CMI:SRV Profile Report: ${client.name()}`,
        margins.left,
        21
      );
      pdf.text(`Page ${page}`, pageWidth - margins.left - 50, 21);
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(margins.left, 25, pageWidth - margins.left, 25);
      page = page + 1;
    };

    let footer = function () {
      pdf.addImage(
        mhsLogoDataUrl,
        'JPEG',
        pageWidth - margins.left - 80,
        pageHeight - margins.bottom,
        80,
        35
      );
    };
    //=================== END PDF DOC SETUP ==================
    // ======================COVER PAGE===============================
    pdf.addImage(
      ylsSRVLogoDataUrl,
      'JPEG',
      margins.left,
      margins.top,
      500,
      102
    );

    pdf.fromHTML(
      ylsSRVPRCoverPage,
      margins.left,
      margins.top + 100,
      {
        width: margins.width,
        elementHandlers: specialElementHandlers
      },
      function (dispose) {
        // add cover page table with client info
        pdf.autoTable({
          head: ylsPRCoverPageTable.head,
          body: ylsPRCoverPageTable.body,
          columnStyles: ylsPRCoverPageTable.columnStyles,
          startY: 300,
          theme: 'striped',
          headStyles: {
            fillColor: [84, 9, 5],
            textColor: [255, 255, 255]
          }
        });

        pdf.setFontSize(10);
        // add CAUTION if client's age is outside of range
        if (!checkAgeRange()) {
          pdf.text(
            `CAUTION: ${client.fName}'s age is outside the range for which this instrument is\n` +
              `recommended. Consequently, results presented in this report could be invalid.\n` +
              `Any interpretation based on this report should be made with extreme caution.`,
            margins.left,
            600
          );
        }

        // add MHS logo and copyright info
        pdf.setFontSize(9);
        pdf.addImage(mhsLogoDataUrl, 'JPEG', margins.left, 680, 125, 54);
        pdf.text(
          'Copyright © 2011, 2012, 2013 Multi-Health Systems Inc. All rights reserved.\n' +
            'YLS/CMI 2.0 test items and normative data © 2011 Multi-Health Systems Inc.\n' +
            'All rights reserved.\n' +
            'P.O. Box 950, North Tonawanda, NY 14120-0950\n' +
            '3770 Victoria Park Ave., Toronto, ON M2H 3M6',
          margins.left + 135,
          705
        );

        // ==========================END COVER PAGE==============================
        // ========================== PARSE EVALUATION FOR REPORT ====================
        pdf.addPage();
        header();
        footer();
        let y = margins.top + 10;

        // logic for creating new line or new page if needbe
        let newLine = function (y) {
          if (y + 12 > pageHeight - (margins.top + margins.bottom)) {
            pdf.addPage();
            header();
            footer();
            y = margins.top + 20;
          } else {
            y += 12;
          }
          return y;
        };

        let addText = function (
          text,
          fontSize = 10,
          fontStyle = 'normal',
          align = 'left',
          x
        ) {
          if (!text) {
            console.error('Text parameter must be provided');
            return;
          }
          text = $reincode.text(text);
          if (fontSize > 10 && fontSize <= 14) y += 15;
          if (fontSize > 14 && fontSize < 18) y += 20;

          pdf.setFontSize(fontSize);
          pdf.setFontStyle(fontStyle);
          let lines = pdf.splitTextToSize(text, margins.width);

          forEach(lines, (line) => {
            pdf.setFontSize(fontSize);
            pdf.setFontStyle(fontStyle);
            pdf.text(line, x ? x : margins.left, y, align);
            y = newLine(y);
          });

          y = newLine(y);
        };

        let newPage = function (pageAlreadyAdded) {
          if (!pageAlreadyAdded) pdf.addPage();
          header();
          footer();
          y = margins.top + 10;
        };

        //region INTRODUCTION
        addText(`Introduction`, 14, 'bold');
        addText(
          'The Youth Level of Service/Case Management Inventory Screening Research Version (YLS/CMI:SRV) ' +
            'is a risk and needs assessment tool. This report summarizes the results of the YLS/CMI:SRV ' +
            'assessment, and provides information pertinent to the assessment of the individual.'
        );
        //endregion

        //region GENERAL NOTES
        addText(`General Notes for Evaluation`, 14, 'bold');
        addText(
          generalNotesForEvaluation?.length
            ? $reincode.text(generalNotesForEvaluation)
            : `No Notes Recorded`
        );
        //endregion

        // ==============OVERALL ASSESSMENT RISK/NEED LEVEL==================
        addText(
          `Overall Assessment Based on YLS/CMI:SRV Total Risk/Need Level`,
          14,
          'bold'
        );
        addText(
          'The graph below displays the YLS/CMI:SRV Total Score. Higher scores suggest a complete YLS/CMI ' +
            '2.0 assessment should be conducted. Note that the interpretation guidelines presented below are ' +
            'preliminary recommendations until further research is conducted.'
        );
        // draw bar graph here
        let tSGHeight = 50;
        let tSGWidth = pageWidth / 2;
        let tSGLineHeight = 20;
        let tSGLineWidth = evaluationScoreTotal * (tSGWidth / 8);
        pdf.setDrawColor(0, 0, 0);
        pdf.rect(153, y, tSGWidth, tSGHeight);
        pdf.setDrawColor(255, 0, 0);
        pdf.setLineWidth(tSGLineHeight);
        pdf.line(153, y + 25, 153 + tSGLineWidth, y + 25);
        pdf.setDrawColor(0, 0, 0);
        pdf.setFontSize(10);
        pdf.text(`(${evaluationScoreTotal})`, 153 + tSGLineWidth + 10, y + 27);
        y += tSGHeight + 25;
        addText(`Total Score`, 14, 'bold', 'center', pageWidth / 2);
        addText(
          `The YLS/CMI:SRV Total Score is ${evaluationScoreTotal}, which suggests a YLS/CMI 2.0 follow-up is ${followUpClassification}.`
        );
        // ============== ITEM RESPONSES ======================
        addText(`Item Responses`, 14, 'bold');
        let ir = {
          head: [['Item', 'Response', 'Comment']],
          body: [],
          startY: y
        };
        forEach(tool.codingFormItems, (cfi, index) => {
          let comment = '';
          let answerText = find(cfi.codesAndScore, (cas) => {
            if (
              evaluation.data[cfi.id] &&
              evaluation.data[cfi.id].aid === cas.id
            ) {
              comment = evaluation.data[cfi.id].comment
                ? $reincode.text(evaluation.data[cfi.id].comment)
                : '';
              return true;
            }
          });
          if (answerText) answerText = answerText.text;
          if (answerText === '-') answerText = 'Unanswered';
          if (answerText === 'Omit') answerText = 'Omitted';
          ir.body.push([
            `${index + 1}. ${cfi.riskFactor}`,
            `${answerText}`,
            `${comment}`
          ]);
        });
        y += 20 * ir.body.length + 1;
        pdf.autoTable({
          head: ir.head,
          body: ir.body,
          startY: ir.startY,
          theme: 'striped',
          rowPageBreak: 'avoid',
          didDrawPage: (hookData) => {
            if (hookData.pageNumber > 1) newPage(true);
            y = hookData.cursor.y + 25;
          }
        });
        // ================END OF REPORT===================
        let today = $filter('dynamicDate')(new Date(), 'fullDate');
        y += 20;
        addText(`Date Printed: ${today}`);
        addText(
          `End of Report: Assessment # ${evaluationId}`,
          undefined,
          'bold'
        );
        // addText(`End of Report (Assessment # ${caseplan.evaluation.id})`, null, 'bold');
        // ==========================UPLOAD PDF=========================
        let filename = `${client.name()} - ${$filter('dynamicDate')(
          new Date(),
          'MM-dd-yyyy'
        )} - YLSCMI-SRV_Profile_Report`;
        // ==========================SAVE PDF=========================
        pdf.save(`${filename}.pdf`);
        // ==========================UPLOAD PDF=========================
        let pdfFile = pdf.output('arraybuffer');
        // let pdfFile = pdf.output('binary');
        pdfFile = new File([pdfFile], `${filename}.pdf`, {
          type: 'application/pdf'
        });

        Upload.upload({
          url: `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/evaluations/${evaluation.evaluationId}/media`,
          file: pdfFile,
          data: { isReport: true }
        })
          .then((response) => {
            $store.commit('evaluations/setFocus', evaluation.evaluationId);
            $store.dispatch('reports/getForEvaluation', {
              id: evaluation.evaluationId,
              client: client
            });
            return response;
          })
          .catch((err) => {
            callback(err, true);
            return err;
          });
      },
      margins
    );
  }
};
