'use strict';

import angular from 'angular';

export default angular
  .module('app.datepicker', [])
  .directive('datepicker', () => ({
    restrict: 'A',
    require: 'ngModel',
    link: function (scope, element, attrs, ngModel) {
      let allowFuture = attrs.class === 'form-control allow-future';

      element.datepicker({
        changeYear: true,
        changeMonth: true,
        yearRange: allowFuture ? '-100:+100' : '-100:+0',
        dateFormat: 'mm/dd/yy',
        showMonthAfterYear: true,
        onSelect: (date) => scope.$apply(() => ngModel.$setViewValue(date))
      });
    }
  })).name;
