'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class MessageModalController extends Modal {
  message: any;

  @Inject $scope;
  @Inject $sce;

  $setup() {
    // this.message = this.$sce.trustAsHtml(this.$props) || '';
    this.message = this.$props.message.toString();
  }
}

export default angular
  .module('app.messageModal', [])
  .directive('messageModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./message.html'),
    controller: MessageModalController,
    controllerAs: 'vm'
  })).name;
