// 'use strict';

import angular from 'angular';
import { forEach, map, some, find } from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class WizardModalController extends Modal {
  itemWizard: any;
  startingVariables: any;
  $modals: any;
  pdf: any;
  questionNumber: any;
  wizardPosition: number;
  wizardAnswers: {};
  questions: boolean;
  modal: boolean;
  dictionary: any;
  dictionaryMap: {};
  showDefinition: any;
  dictionaryRegEx: any;

  @Inject $scope;
  @Inject $sce;
  @Inject $sanitize;
  @Inject $anchorScroll;
  @Inject $location;
  @Inject getFormItems;
  @Inject getItems;
  @Inject $http;
  @Inject $auth;
  @Inject utils;
  @Inject $modals;
  @Inject Proration;

  $setup() {
    'ngInject';
    this.itemWizard = angular.copy(this.$props.itemWizard.itemWizard);

    if (this.itemWizard.calculation && this.itemWizard.calculation.variables)
      this.startingVariables = angular.copy(
        this.itemWizard.calculation.variables
      );

    this.pdf = this.$props.pdf;
    this.questionNumber = this.$props.itemWizard.id; // question from which we came
    this.wizardPosition = 0;
    this.wizardAnswers = []; // user's answers for questions
    this.questions = true;
    this.modal = false;
    // build dictionary map
    this.dictionary = this.$props.dictionary;
    var dictionaryMap = {};
    var key, htmlString;
    for (let i = 0; i < this.dictionary.length; i++) {
      key = '[' + this.dictionary[i].id + ':' + this.dictionary[i].term + ']';
      htmlString =
        '<a ng-click="vm.showDefinition(\'' +
        this.dictionary[i].id +
        '\')">' +
        this.dictionary[i].term +
        '</a>';
      dictionaryMap[key] = htmlString;
    }
    // create regex for dictionary
    this.dictionaryRegEx = Object.keys(dictionaryMap).join('|');
    this.dictionaryRegEx = this.dictionaryRegEx.replace(/\[/g, '\\[');
    this.dictionaryRegEx = this.dictionaryRegEx.replace(/]/g, '\\]');
    this.dictionaryRegEx = this.dictionaryRegEx.replace(/\(/g, '\\(');
    this.dictionaryRegEx = this.dictionaryRegEx.replace(/\)/g, '\\)');
    this.dictionaryRegEx = this.dictionaryRegEx.replace(/#/g, '\\#');
    this.dictionaryRegEx = this.dictionaryRegEx.replace(/\+/g, '\\+');
    // this.dictionaryRegEx = this.dictionaryRegEx.replace(/\|/g, '\\|');
    this.dictionaryRegEx = new RegExp(this.dictionaryRegEx, 'gi');
    this.mapReplace = function (matched) {
      return dictionaryMap[matched];
    };
    forEach(this.itemWizard.wizardQuestions, (wq, i) => {
      if (this.dictionary?.length) {
        wq.questionText = wq.questionText.replace(
          this.dictionaryRegEx,
          this.mapReplace
        );
      }
      wq.questionText = this.$sce.trustAsHtml(wq.questionText);
      if (wq.questionType === 2) {
        forEach(wq.wizardAnswers, (wa) => {
          if (wa.multiplicand) wq.selected = 0;
        });
      }
    });
    // for (let i = 0; i < this.itemWizard.wizardQuestions.length; i++) {
    //   // replace matching items with dictionary mapped values
    //   this.itemWizard.wizardQuestions[
    //     i
    //   ].questionText = this.itemWizard.wizardQuestions[i].questionText.replace(
    //     this.dictionaryRegEx,
    //     this.mapReplace
    //   );
    //   this.itemWizard.wizardQuestions[i].questionText = this.$sce.trustAsHtml(
    //     this.itemWizard.wizardQuestions[i].questionText
    //   );
    //   // prepare multiplicand number input fields
    //   if (this.itemWizard.wizardQuestions[i].questionType === 2) {
    //     for (
    //       let j = 0;
    //       j < this.itemWizard.wizardQuestions[i].wizardAnswers.length;
    //       j++
    //     ) {
    //       if (
    //         this.itemWizard.wizardQuestions[i].wizardAnswers[j].multiplicand
    //       ) {
    //         this.itemWizard.wizardQuestions[i].wizardAnswers[j].selected = 0;
    //       }
    //     }
    //   }
    // }

    this.showDefinition = function (termId) {
      console.log('show definition: ', termId);
      var term = null;
      for (let i = 0; i < this.dictionary.length; i++) {
        if (this.dictionary[i].id === termId) {
          term = this.dictionary[i];
        }
      }
      if (term === null) {
        this.utils.notify('warning', {});
      } else {
        this.$modals.evaluation.definition(term, this);
      }
    };
  }

  resolveVariables(answer) {
    var calculation = this.itemWizard.calculation;
    var conditions = calculation.conditions;
    var operations = calculation.operations;
    var resolutions = calculation.resolutions;
    var j, operationSymbol, mathString, result, ans;

    let proratedValue;
    // If the WizardItem calculation config
    // has operations, resolve the prorated
    // score
    if (operations && operations.length) {
      proratedValue = this.Proration({
        ops: calculation.operations,
        constants: map(calculation.variables, (item, key) => {
          return {
            label: key,
            value: item
          };
        }),
        input: calculation.opInput,
        output: calculation.opOutput
      });
    }

    for (let i = 0; i < conditions.length; i++) {
      // evaluate conditions
      let item = conditions[i];

      if (item.operation === 'greaterThan') {
        operationSymbol = '>';
      } else if (item.operation === 'lessThan') {
        operationSymbol = '<';
      } else if (item.operation === 'equal') {
        operationSymbol = '==';
      } else if (item.operation === 'lessThanEqual') {
        operationSymbol = '<=';
      } else if (item.operation === 'greaterThanEqual') {
        operationSymbol = '>=';
      }

      if (proratedValue === 0 || proratedValue) {
        // the final output of operations
        mathString = proratedValue + operationSymbol + item.right;
      } else {
        mathString =
          calculation.variables[item.left] + operationSymbol + item.right;
      }

      item.result = eval(mathString);
    }

    result = null;
    for (let i = 0; i < resolutions.length; i++) {
      // resolve resolutions
      let resolution = resolutions[i];

      for (j = 0; j < conditions.length; j++) {
        // retrieve condition results
        let condition = conditions[j];

        if (resolution.condition1 === condition.conditionId) {
          resolution.condition1Result = condition.result;
        }

        if (resolution.condition2 === condition.conditionId) {
          resolution.condition2Result = condition.result;
        }
      }

      if (resolution.operation === 'OR') {
        // get operation symbol
        operationSymbol = '||';
      } else if (resolution.operation === 'AND') {
        operationSymbol = '&&';
      }

      mathString =
        resolution.condition1Result +
        operationSymbol +
        resolution.condition2Result;
      resolution.resolutionResult = eval(mathString);

      if (eval(mathString)) {
        if (result === null) {
          result = resolution.result;
        } else if (resolution.result > result) {
          result = resolution.result;
        }
      }
    }

    if (result === null) {
      return;
    }

    ans = answer;
    ans.answerScore = result;

    this.giveAnswer(ans);
  }

  manipulateVariables(answer) {
    var mathString;
    if (this.itemWizard.calculation) {
      for (let i = 0; i < answer.manipulateVariables.length; i++) {
        mathString = '';
        mathString =
          this.itemWizard.calculation.variables[
            answer.manipulateVariables[i].variableLabel
          ] +
          answer.manipulateVariables[i].operation +
          answer.manipulateVariables[i].rightOperand;
        this.itemWizard.calculation.variables[
          answer.manipulateVariables[i].variableLabel
        ] = eval(mathString);
      }
    }
  }

  submitMultipleAnswers(question, answers) {
    this.currentQuestion = question;
    console.log('question: ', question);
    console.log('answers: ', answers);
    question.answered = true;
    var questionScore = 0;
    var operationSymbol = '';
    var mathString = '';
    var answerArray = [];
    var j;
    for (let i = 0; i < answers.length; i++) {
      // calculate answers given
      if (answers[i].multiplicand) {
        // multiplicand type mcma (VRAG-R Item 5 and 8)
        if (typeof answers[i].selected === 'number') {
          questionScore =
            questionScore +
            parseInt(answers[i].selected, 10) *
              parseInt(answers[i].increment, 10);

          answerArray.push(answers[i].text);
        }
      } else if (answers[i].selected) {
        if (
          answers[i].manipulateVariables &&
          answers[i].manipulateVariables.length
        ) {
          // if the wizard has a calculation
          // this.manipulateVariables(answers[i]);
          if (this.itemWizard.calculation) {
            for (j = 0; j < answers[i].manipulateVariables.length; j++) {
              var manipVarMathString = '';
              manipVarMathString =
                this.itemWizard.calculation.variables[
                  answers[i].manipulateVariables[j].variableLabel
                ] +
                answers[i].manipulateVariables[j].operation +
                answers[i].manipulateVariables[j].rightOperand;
              this.itemWizard.calculation.variables[
                answers[i].manipulateVariables[j].variableLabel
              ] = eval(manipVarMathString);
            }
          }
        }
        questionScore = questionScore + parseInt(answers[i].increment, 10);
        answerArray.push(answers[i].text);
      }
    }
    for (let i = 0; i < question.multipleAnswerConditions.length; i++) {
      if (question.multipleAnswerConditions[i].operation === 'greaterThan') {
        operationSymbol = '>';
      } else if (
        question.multipleAnswerConditions[i].operation === 'lessThan'
      ) {
        operationSymbol = '<';
      } else if (question.multipleAnswerConditions[i].operation === 'equal') {
        operationSymbol = '==';
      } else if (
        question.multipleAnswerConditions[i].operation === 'lessThanEqual'
      ) {
        operationSymbol = '<=';
      } else if (
        question.multipleAnswerConditions[i].operation === 'greaterThanEqual'
      ) {
        operationSymbol = '>=';
      }
      mathString =
        questionScore +
        operationSymbol +
        question.multipleAnswerConditions[i].right;
      question.multipleAnswerConditions[i].result = eval(mathString);
      if (eval(mathString)) {
        var answerObject = {}; // push wizard answer chosen to wizardAnswers array
        answerObject[question.id] = {
          answerText: answerArray,
          operator: operationSymbol,
          selectedAnswer: question.multipleAnswerConditions[i].right
        };
        this.wizardAnswers.push(answerObject);
        if (this.checkWizardAnswerDup()) {
          this.revertWizardAnswers(question.id);
        }
        if (question.multipleAnswerConditions[i].action === 'nextQuestion') {
          for (let j = 0; j < this.itemWizard.wizardQuestions.length; j++) {
            if (
              question.multipleAnswerConditions[i].nextWizardQuestionId ===
              this.itemWizard.wizardQuestions[j].id
            ) {
              if (this.itemWizard.wizardQuestions[j].answer) {
                Reflect.deleteProperty(
                  this.itemWizard.wizardQuestions[j],
                  'answer'
                );
              }
              this.wizardPosition = j;
            }
          }
        } else {
          var ans = {
            answerScore: question.multipleAnswerConditions[i].answerScore,
            questionAnswerToSelect: question.multipleAnswerConditions[i]
              .questionAnswerToSelect
              ? question.multipleAnswerConditions[i].questionAnswerToSelect
              : null,
            id: question.id
          };
          this.giveAnswer(ans);
        }
      }
    }
  }

  questionAnswered(answer, question) {
    question.answered = true; // keeping track of answer questions to keep displayed
    this.currentAnswer = answer;
    this.currentQuestion = question;
    var answerArray = [];
    answerArray.push(answer.text);
    var answerObject = {};
    answerObject[question.id] = {
      answerText: answerArray,
      operator: '',
      selectedAnswer: this.currentAnswer.id
    };
    this.wizardAnswers.push(answerObject);
    if (this.checkWizardAnswerDup()) this.revertWizardAnswers(question.id);
    if (answer.manipulateVariables && answer.manipulateVariables.length)
      this.manipulateVariables(answer);
    if (answer.resolveVariables) {
      this.resolveVariables(answer);
    } else if (
      answer.nextWizardQuestionId !== '' &&
      answer.nextWizardQuestionId !== null &&
      answer.nextWizardQuestionId !== undefined &&
      answer.nextWizardQuestionId !== 0
    ) {
      // go to next wizard question
      for (let i = 0; i < this.itemWizard.wizardQuestions.length; i++) {
        if (
          answer.nextWizardQuestionId === this.itemWizard.wizardQuestions[i].id
        ) {
          if (this.itemWizard.wizardQuestions[i].answer) {
            Reflect.deleteProperty(
              this.itemWizard.wizardQuestions[i],
              'answer'
            );
          }
          this.wizardPosition = i;
          this.$location.hash(this.itemWizard.wizardQuestions[i].id);
          this.$anchorScroll();
          break;
        }
      }
    } else if (answer.spawnModal) {
      // spawn modal text
      this.showModal(answer);
    } else {
      // give answer
      this.giveAnswer(answer);
    }
  }

  checkWizardAnswerDup() {
    var keys = [];
    for (let i = 0; i < this.wizardAnswers.length; i++) {
      keys.push(Object.keys(this.wizardAnswers[i])[0]);
    }
    // var keys = Object.keys(this.wizardAnswers);
    keys = keys.slice().sort();
    var results = [];
    for (let i = 0; i < this.wizardAnswers.length; i++) {
      if (keys[i + 1] == keys[i]) {
        results.push(keys[i]);
      }
    }
    if (results.length > 0) {
      // var x = 0;
      // var foundFirst = false;
      // for (let i = 0; i < this.wizardAnswers.length; i++) { // remove old duplicate answer
      //   if (Object.keys(this.wizardAnswers[i])[0] === results[0]) {
      //     if (!foundFirst) {
      //       x = i;
      //     } else if (i > x) {
      //       this.wizardAnswers.splice(x, 1);
      //     }
      //   }
      // }
      return true;
    } else {
      return false;
    }
  }

  revertWizardAnswers(questionId) {
    var reachedQuestion = false;
    var wQID = '';
    for (let i = 0; i < this.wizardAnswers.length; i++) {
      wQID = Object.keys(this.wizardAnswers[i])[0];
      if (wQID === questionId) {
        if (!reachedQuestion) {
          this.wizardAnswers.splice(i, 1);
          i--;
          reachedQuestion = true;
        }
        continue;
      }
      if (reachedQuestion) {
        for (let j = 0; j < this.itemWizard.wizardQuestions.length; j++) {
          if (wQID === this.itemWizard.wizardQuestions[j].id) {
            this.itemWizard.wizardQuestions[j].answered = false;
          }
        }
        this.wizardAnswers.splice(i, 1);
        i--;
      }
    }
    let waqids = [];
    forEach(this.wizardAnswers, (key, val) => {
      waqids.push(key);
    });
    forEach(this.itemWizard.wizardQuestions, (wq) => {
      if (!some(waqids, wq.id)) {
        wq.answered = false;
        if (wq.answer) Reflect.deleteProperty(wq, 'answer');
      }
    });
    // recalculate variables
    if (this.itemWizard.calculation && this.itemWizard.calculation.variables) {
      // reset variables
      forEach(this.wizardAnswers, (wa) => {
        let wqid = Object.keys(wa)[0];
        let wq = find(this.itemWizard.wizardQuestions, { id: wqid });
        let foundAnswer = find(wq.wizardAnswers, {
          id: wa[wqid].selectedAnswer
        });
        if (
          foundAnswer &&
          foundAnswer.manipulateVariables &&
          foundAnswer.manipulateVariables.length
        ) {
          if (questionId !== wqid) {
            // the changed/chosen answer already updates variables
            this.manipulateVariables(foundAnswer);
          }
        }
      });
    }
  }

  showModal(answer) {
    this.modal = true;
    this.questions = false;
    this.modalText = answer.modalText;
  }

  giveAnswer(answer) {
    var ans = {
      question: this.questionNumber, // codingFormItem id
      answer: answer
    };
    ans.answer.wizardQuestionId = this.currentQuestion.id;
    var returnAnswer = {};
    if (this.itemWizard.calculation) {
      var variables = {};
      for (var key in this.itemWizard.calculation.variables) {
        variables[key] = this.itemWizard.calculation.variables[key];
      }
      returnAnswer.variables = variables;
    }
    returnAnswer.answer = ans;
    returnAnswer.answers = this.wizardAnswers;
    this.$close(returnAnswer);
  }

  reference(page, title) {
    this.$modals.settings.openPDF(page, title, this.pdf);
  }

  mcmaAnswered(answer, otherAnswers, numberInput) {
    if (answer.isSolo) {
      forEach(otherAnswers, function (ans) {
        if (answer !== ans) ans.selected = false;
      });
    } else {
      forEach(otherAnswers, function (ans) {
        if (ans.isSolo) ans.selected = false;
      });
    }
    if (numberInput) {
      if (typeof answer.selected !== 'number') {
        answer.selected = 0;
      }
    }
  }

  numberSpinnerClicked(answer, up) {
    if (up) {
      if (!answer.selected) answer.selected = 0;
      answer.selected++;
    } else {
      if (answer.selected > 0) {
        answer.selected--;
      }
    }
  }
}

export default angular
  .module('app.wizardModal', [])
  .directive('wizardModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./wizard.html'),
    controller: WizardModalController,
    controllerAs: 'vm'
  })).name;
