import countryList from 'country-list';
import { orderBy } from 'lodash';

import { FormOption } from '@interfaces';

/**
 * Institution type enum.
 */
export enum InstitutionType {
  GeneralHealthcare = 'General Healthcare',
  ForensicHealthcare = 'Forensic Healthcare',
  ResidentialCorrections = 'Residential Corrections',
  CommunityCorrections = 'Community Corrections',
  LawEnforcement = 'Law Enforcement',
  Pretrial = 'Pretrial',
  PrivatePractice = 'Private Practice',
  Other = 'Other...'
}

/**
 * Reassessment type enum.
 */
export enum ReassessmentType {
  NotAllowed = 'NOT_ALLOWED',
  AutoAnswer = 'AUTO_ANSWER',
  ShowAnswer = 'SHOW_ANSWER'
}

/**
 * Multi-factor authentication (MFA) type enum.
 */
export enum MultiFactorAuthenticationType {
  None = 'NOMFA',
  Email = 'EMAIL'
}

/**
 * List of copmarative operations.
 */
export const COMPARATIVE_OPERATIONS = {
  '=': 'equal',
  '>': 'greaterThan',
  '<': 'lessThan',
  '>=': 'greaterThanEqual',
  '<=': 'lessThanEqual'
};

/**
 * List of basic operations.
 */
export const BASIC_OPERATIONS = ['+', '-', '*', '/'];

/**
 * List of institution types.
 */
export const INSTITUTION_TYPES = Object.values(InstitutionType);

/**
 * List if billing cycle options.
 */
export const BILLING_CYCLES: FormOption<number>[] = [
  { value: 1, name: 'Monthly' },
  { value: 3, name: 'Quarterly' },
  { value: 6, name: 'Bi-Annually' },
  { value: 12, name: 'Annually' }
];

/**
 * List if billing day options.
 */
export const BILLING_DAYS: FormOption<number>[] = [
  { value: 1, name: '1st' },
  { value: 2, name: '2nd' },
  { value: 3, name: '3rd' },
  { value: 4, name: '4th' },
  { value: 5, name: '5th' },
  { value: 6, name: '6th' },
  { value: 7, name: '7th' },
  { value: 8, name: '8th' },
  { value: 9, name: '9th' },
  { value: 10, name: '10th' },
  { value: 11, name: '11th' },
  { value: 12, name: '12th' },
  { value: 13, name: '13th' },
  { value: 14, name: '14th' },
  { value: 15, name: '15th' },
  { value: 16, name: '16th' },
  { value: 17, name: '17th' },
  { value: 18, name: '18th' },
  { value: 19, name: '19th' },
  { value: 20, name: '20th' },
  { value: 21, name: '21st' },
  { value: 22, name: '22nd' },
  { value: 23, name: '23rd' },
  { value: 24, name: '24th' },
  { value: 25, name: '25th' },
  { value: 26, name: '26th' },
  { value: 27, name: '27th' },
  { value: 28, name: '28th' }
];

/**
 * List if billing method options.
 */
export const BILLING_METHODS: FormOption<number>[] = [
  { value: 0, name: 'Per User' },
  { value: 1, name: 'Per Evaluation' }
];

/**
 * List of familial relations.
 */
export const RELATIONS = ['Mother', 'Father', 'Sister', 'Brother', 'Other...'];

/**
 * List of US states.
 */
export const US_STATES: FormOption<string>[] = [
  { value: 'AL', name: 'Alabama' },
  { value: 'AK', name: 'Alaska' },
  { value: 'AZ', name: 'Arizona' },
  { value: 'AR', name: 'Arkansas' },
  { value: 'CA', name: 'California' },
  { value: 'CO', name: 'Colorado' },
  { value: 'CT', name: 'Connecticut' },
  { value: 'DE', name: 'Delaware' },
  { value: 'DC', name: 'Dist. of Columbia' },
  { value: 'FL', name: 'Florida' },
  { value: 'GA', name: 'Georgia' },
  { value: 'HI', name: 'Hawaii' },
  { value: 'ID', name: 'Idaho' },
  { value: 'IL', name: 'Illinois' },
  { value: 'IN', name: 'Indiana' },
  { value: 'IA', name: 'Iowa' },
  { value: 'KS', name: 'Kansas' },
  { value: 'KY', name: 'Kentucky' },
  { value: 'LA', name: 'Louisiana' },
  { value: 'ME', name: 'Maine' },
  { value: 'MD', name: 'Maryland' },
  { value: 'MA', name: 'Massachusetts' },
  { value: 'MI', name: 'Michigan' },
  { value: 'MN', name: 'Minnesota' },
  { value: 'MS', name: 'Mississippi' },
  { value: 'MO', name: 'Missouri' },
  { value: 'MT', name: 'Montana' },
  { value: 'NE', name: 'Nebraska' },
  { value: 'NV', name: 'Nevada' },
  { value: 'NH', name: 'New Hampshire' },
  { value: 'NJ', name: 'New Jersey' },
  { value: 'NM', name: 'New Mexico' },
  { value: 'NY', name: 'New York' },
  { value: 'NC', name: 'North Carolina' },
  { value: 'ND', name: 'North Dakota' },
  { value: 'OH', name: 'Ohio' },
  { value: 'OK', name: 'Oklahoma' },
  { value: 'OR', name: 'Oregon' },
  { value: 'PA', name: 'Pennsylvania' },
  { value: 'PR', name: 'Puerto Rico' },
  { value: 'RI', name: 'Rhode Island' },
  { value: 'SC', name: 'South Carolina' },
  { value: 'SD', name: 'South Dakota' },
  { value: 'TN', name: 'Tennessee' },
  { value: 'TX', name: 'Texas' },
  { value: 'UT', name: 'Utah' },
  { value: 'VT', name: 'Vermont' },
  { value: 'VA', name: 'Virginia' },
  { value: 'WA', name: 'Washington' },
  { value: 'WV', name: 'West Virginia' },
  { value: 'WI', name: 'Wisconsin' },
  { value: 'WY', name: 'Wyoming' }
];

/**
 * List of Canadian provinces.
 */
export const CANADA_PROVINCES: FormOption<string>[] = [
  { value: 'AB', name: 'Alberta' },
  { value: 'BC', name: 'British Columbia' },
  { value: 'MB', name: 'Manitoba' },
  { value: 'NB', name: 'New Brunswick' },
  { value: 'NL', name: 'Newfoundland and Labrador' },
  { value: 'NS', name: 'Nova Scotia' },
  { value: 'NT', name: 'Northwest Territories' },
  { value: 'NU', name: 'Nunavut' },
  { value: 'ON', name: 'Ontario' },
  { value: 'PE', name: 'Prince Edward Island' },
  { value: 'QC', name: 'Quebec' },
  { value: 'SK', name: 'Saskatchewan' },
  { value: 'YT', name: 'Yukon' }
];

/**
 * List of UK provinces.
 */
export const UK_PROVINCES: FormOption<string>[] = [
  { value: 'I0', name: 'Aberconwy and Colwyn' },
  { value: 'I1', name: 'Aberdeen City' },
  { value: 'I2', name: 'Aberdeenshire' },
  { value: 'I3', name: 'Anglesey' },
  { value: 'I4', name: 'Angus' },
  { value: 'I5', name: 'Antrim' },
  { value: 'I6', name: 'Argyll and Bute' },
  { value: 'I7', name: 'Armagh' },
  { value: 'I8', name: 'Avon' },
  { value: 'I9', name: 'Ayrshire' },
  { value: 'IB', name: 'Bath and NE Somerset' },
  { value: 'IC', name: 'Bedfordshire' },
  { value: 'IE', name: 'Belfast' },
  { value: 'IF', name: 'Berkshire' },
  { value: 'IG', name: 'Berwickshire' },
  { value: 'IH', name: 'BFPO' },
  { value: 'II', name: 'Blaenau Gwent' },
  { value: 'IJ', name: 'Buckinghamshire' },
  { value: 'IK', name: 'Caernarfonshire' },
  { value: 'IM', name: 'Caerphilly' },
  { value: 'IO', name: 'Caithness' },
  { value: 'IP', name: 'Cambridgeshire' },
  { value: 'IQ', name: 'Cardiff' },
  { value: 'IR', name: 'Cardiganshire' },
  { value: 'IS', name: 'Carmarthenshire' },
  { value: 'IT', name: 'Ceredigion' },
  { value: 'IU', name: 'Channel Islands' },
  { value: 'IV', name: 'Chesire' },
  { value: 'IW', name: 'City of Bristol' },
  { value: 'IX', name: 'Clackmannanshire' },
  { value: 'IY', name: 'Clwyd' },
  { value: 'IZ', name: 'Conwy' },
  { value: 'J0', name: 'Cornwall/Scilly' },
  { value: 'J1', name: 'Cumbria' },
  { value: 'J2', name: 'Denbighshire' },
  { value: 'J3', name: 'Derbyshire' },
  { value: 'J4', name: 'Derry/Londonderry' },
  { value: 'J5', name: 'Devon' },
  { value: 'J6', name: 'Dorset' },
  { value: 'J7', name: 'Down' },
  { value: 'J8', name: 'Dumbfires and Galloway' },
  { value: 'J9', name: 'Dunbartonshire' },
  { value: 'JA', name: 'Dundee' },
  { value: 'JB', name: 'Durham' },
  { value: 'JC', name: 'Dyfed' },
  { value: 'JD', name: 'East Ayrshire' },
  { value: 'JE', name: 'East Dunbartonshire' },
  { value: 'JF', name: 'East Lothian' },
  { value: 'JG', name: 'Easy Renfrewshire' },
  { value: 'JH', name: 'Easy Riding Yorkshire' },
  { value: 'JI', name: 'Easy Sussex' },
  { value: 'JJ', name: 'Edinburgh' },
  { value: 'JK', name: 'England' },
  { value: 'JL', name: 'Essex' },
  { value: 'JM', name: 'Falkirk' },
  { value: 'JN', name: 'Fermanagh' },
  { value: 'JO', name: 'Fife' },
  { value: 'JP', name: 'Flintshire' },
  { value: 'JQ', name: 'Glasgow' },
  { value: 'JR', name: 'Goucestershire' },
  { value: 'JS', name: 'Greater London' },
  { value: 'JT', name: 'Greater Manchester' },
  { value: 'JU', name: 'Gwent' },
  { value: 'JV', name: 'Gwynedd' },
  { value: 'JW', name: 'Hampshire' },
  { value: 'JX', name: 'Hartlepool' },
  { value: 'HAW', name: 'Hereford and Worcester' },
  { value: 'JY', name: 'Hertfordshire' },
  { value: 'JZ', name: 'Highlands' },
  { value: 'K0', name: 'Inverclyde' },
  { value: 'K1', name: 'Inverness-Shire' },
  { value: 'K2', name: 'Isle of Man' },
  { value: 'K3', name: 'Isle of Wight' },
  { value: 'K4', name: 'Kent' },
  { value: 'K5', name: 'Kincardinshire' },
  { value: 'K6', name: 'Kingston Upon Hull' },
  { value: 'K7', name: 'Kinross-Shire' },
  { value: 'K8', name: 'Kirkless' },
  { value: 'K9', name: 'Lanarkshire' },
  { value: 'KA', name: 'Lancashire' },
  { value: 'KB', name: 'Leicestershire' },
  { value: 'KC', name: 'Lincolnshire' },
  { value: 'KD', name: 'Londonderry' },
  { value: 'KE', name: 'Merseyside' },
  { value: 'KF', name: 'Merthyr Tidfil' },
  { value: 'KG', name: 'Mid Glamorgan' },
  { value: 'KI', name: 'Mid Lothian' },
  { value: 'KH', name: 'Middlesex' },
  { value: 'KJ', name: 'Monmouthshire' },
  { value: 'KK', name: 'Moray' },
  { value: 'KL', name: 'Neath & Port Talbot' },
  { value: 'KM', name: 'Newport' },
  { value: 'KN', name: 'Norfolk' },
  { value: 'KP', name: 'North Ayrshire' },
  { value: 'KQ', name: 'North East Lincolnshire' },
  { value: 'KR', name: 'North Lanarkshire' },
  { value: 'KT', name: 'North Lincolnshire' },
  { value: 'KU', name: 'North Somerset' },
  { value: 'KV', name: 'North Yorkshire' },
  { value: 'KO', name: 'North Hamptonshire' },
  { value: 'KW', name: 'Northern Ireland' },
  { value: 'KX', name: 'Northumberland' },
  { value: 'KZ', name: 'Nottinghamshire' },
  { value: 'L0', name: 'Orkney & Shetland Isles' },
  { value: 'L1', name: 'Oxfordshire' },
  { value: 'L2', name: 'Pembrokeshire' },
  { value: 'L3', name: 'Perth & Kinross' },
  { value: 'L4', name: 'Powys' },
  { value: 'L5', name: 'Redcar and Cleveland' },
  { value: 'L6', name: 'Renfrewshire' },
  { value: 'L7', name: 'Rhonda Cynon Taff' },
  { value: 'L8', name: 'Rutland' },
  { value: 'L9', name: 'Scottish Borders' },
  { value: 'LB', name: 'Shetland' },
  { value: 'LC', name: 'Shropshire' },
  { value: 'LD', name: 'Somerset' },
  { value: 'LE', name: 'South Ayrshire' },
  { value: 'LF', name: 'South Glamorgan' },
  { value: 'LG', name: 'South Gloucesteshire' },
  { value: 'LH', name: 'South Lanarkshire' },
  { value: 'LI', name: 'South Yorkshire' },
  { value: 'LJ', name: 'Staffordshire' },
  { value: 'LK', name: 'Stirling' },
  { value: 'LL', name: 'Stockton On Tees' },
  { value: 'LM', name: 'Suffolk' },
  { value: 'LN', name: 'Surrey' },
  { value: 'LO', name: 'Swansea' },
  { value: 'LP', name: 'Torfaen' },
  { value: 'LQ', name: 'Tyne and Wear' },
  { value: 'LR', name: 'Tyrone' },
  { value: 'LS', name: 'Vale of Glamorgan' },
  { value: 'LT', name: 'Wales' },
  { value: 'LU', name: 'Warwickshire' },
  { value: 'LV', name: 'West Berkshire' },
  { value: 'LW', name: 'West Dunbartonshire' },
  { value: 'LX', name: 'West Glamorgan' },
  { value: 'LY', name: 'West Lothian' },
  { value: 'LZ', name: 'West Midlands' },
  { value: 'M0', name: 'West Sussex' },
  { value: 'M1', name: 'West Yorkshire' },
  { value: 'M2', name: 'Western Isles' },
  { value: 'M3', name: 'Wilshire' },
  { value: 'M4', name: 'Wirral' },
  { value: 'M5', name: 'Worcestershire' },
  { value: 'M6', name: 'Wrexham' },
  { value: 'M7', name: 'York' }
];

/**
 * List of AU states.
 */
export const AU_STATES: FormOption<string>[] = [
  { value: 'B0', name: 'Australian Capital Territory' },
  { value: 'B1', name: 'New South Wales' },
  { value: 'B2', name: 'Northern Territory' },
  { value: 'B3', name: 'Queensland' },
  { value: 'B4', name: 'South Australia' },
  { value: 'B5', name: 'Tasmania' },
  { value: 'B6', name: 'Victoria' },
  { value: 'B7', name: 'Western Australia' }
];

/**
 * List of countries.
 */
export const COUNTRIES: FormOption<string>[] = orderBy(
  countryList.getData().map(({ code, name }) => ({ value: code, name })),
  [({ value }) => value === 'US', 'name'],
  ['desc', 'asc']
);

// Utilities

/**
 * Regular expression for validating email addresses.
 */
export const EMAIL_REGEX =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

/**
 * ...
 */
export const ROMAN_NUMERAL_VALUES = {
  M: 1000,
  CM: 900,
  D: 500,
  CD: 400,
  C: 100,
  XC: 90,
  L: 50,
  XL: 40,
  X: 10,
  IX: 9,
  V: 5,
  IV: 4,
  I: 1
};

/**
 * List of normative settings.
 */
export const NORMATIVE_SETTINGS_LIST = [
  { label: 'US Community', value: 'US Community' },
  { label: 'US Custodial', value: 'US Custodial' },
  { label: 'Canadian', value: 'Canadian' }
];

/**
 * List of offense classifications.
 */
export const OFFENSE_CLASSIFICATIONS = [
  {
    value: {
      chapter: 1,
      chapterTitle: 'Introductory Provisions',
      section: 1,
      sectionTitle: 'General Provisions',
      articleNumber: 1.1,
      description: 'Short Title'
    },
    label: '1.1 Short Title'
  },
  {
    value: {
      chapter: 1,
      chapterTitle: 'Introductory Provisions',
      section: 1,
      sectionTitle: 'General Provisions',
      articleNumber: 1.2,
      description: 'Objectives of Code'
    },
    label: '1.2 Objectives of Code'
  },
  {
    value: {
      chapter: 2,
      chapterTitle: 'General Principles of Criminal Responsibility',
      section: 6,
      sectionTitle: 'Culpability Generally',
      articleNumber: 6.1,
      description: 'Proof Beyond A Reasonable Doubt'
    },
    label: '6.1 Proof Beyond A Reasonable Doubt'
  },
  {
    value: {
      chapter: 2,
      chapterTitle: 'General Principles of Criminal Responsibility',
      section: 7,
      sectionTitle: 'Criminal Responsibility for Conduct of Another',
      articleNumber: 7.1,
      description: 'Exception'
    },
    label: '7.1 Exception'
  }
];

/**
 * ...
 */
export const CHANGE_OPTIONS = [
  { label: 'Improvement (+)', value: 'improvement' },
  { label: 'No Change (0)', value: 'noChange' },
  { label: 'Deterioration (-)', value: 'deterioration' },
  { label: 'Completed', value: 'completed' },
  { label: 'Failed', value: 'failed' }
];

/**
 * ...
 */
export const COLUMN_TYPES = [
  {
    value: 'enum',
    label: 'Dropdown',
    formInfluence: { type: 'enum', values: [] }
  },
  { value: 'text', label: 'Text Input' },
  {
    value: 'intervention',
    label: 'Intervention',
    formInfluence: { type: 'intervention', values: [] }
  },
  { value: 'date', label: 'Date' },
  { value: 'dateTime', label: 'Date/Time' },
  { value: 'intensity', label: 'Intensity' }
];

/**
 * ...
 */
export const INTENSITY_OPTIONS = [
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Bi-weekly', value: 'bi-weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Bi-monthly', value: 'bi-monthly' },
  { label: 'Annually', value: 'annually' },
  { label: 'Not Applicable', value: 'N/A' }
];

/**
 * ...
 */
export const YLSLS_TOOLS_SUITE = [
  { id: 4, name: 'LSI-R: SV' },
  { id: 105, name: 'YLS/CMI:SRV' },
  { id: 120, name: 'YLS/CMI 2.0' }
];

/**
 * ...
 */
export const DEFAULT_CLIENT_TYPE_OPTIONS = [
  { label: 'Violent Offender', value: 'Violent Offender' },
  { label: 'Sex Offender', value: 'Sex Offender' },
  {
    label: 'Mentally Disordered Offender',
    value: 'Mentally Disordered Offender'
  },
  { label: 'Dangerous Offender', value: 'Dangerous Offender' },
  { label: 'High-Profile Offender', value: 'High-Profile Offender' },
  { label: 'Suicidal Offender', value: 'Suicidal Offender' },
  { label: 'Petty Offender', value: 'Petty Offender' },
  {
    label: 'Certified to Adult Court/Status',
    value: 'Certified to Adult Court/Status'
  },
  { label: 'Psychopathic Offender', value: 'Psychopathic Offender' },
  { label: 'Drug/Alcohol Offender', value: 'Drug/Alcohol Offender' },
  { label: 'Domestic Violence Offender', value: 'Domestic Violence Offender' }
];

/**
 * ...
 */
export const DEFAULT_ETHNICITY_OPTIONS = [
  { label: 'Asian', value: 'Asian' },
  { label: 'Black', value: 'Black' },
  { label: 'Hispanic/Latino', value: 'Hispanic/Latino' },
  { label: 'Indigenous', value: 'Indigenous' },
  { label: 'White', value: 'White' }
];

/**
 * ...
 */
export const CLIENT_CONFIG_OTHER_OPTION = {
  label: 'Other (specify)',
  value: 'other'
};
