/*
generates a comparative report for evaluations completed
using the LSCMI tool
*/
export default {
  generateReport(client, tool, evaluations) {
    console.log('client: ', client);
    console.log('tool: ', tool);
    console.log('evaluations: ', evaluations);
  }
};
