'use strict';

import angular from 'angular';
import _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ChooseReportTemplateModalController extends Modal {
  tool: any;
  reportTemplates: any;
  institutions: any;
  baseTemplateExists: boolean;
  loading: boolean;

  @Inject $scope;
  @Inject $http;
  @Inject $q;
  @Inject utils;
  @Inject notify;

  $setup() {
    this.tool = this.$modal.tool;
    this.reportTemplates = this.$props.reportTemplates;
    this.institutions = this.$props.institutions;
    this.baseTemplateExists = false;

    for (let i = 0; i < this.reportTemplates.length; i++) {
      if (this.reportTemplates[i].developerGroupId) {
        this.baseTemplateExists = true;
      }
    }
    // var deferred = this.$q.defer();

    this.loading = true;
    this.getInstitutionTools(0);
    // for (i = 0; i < this.institutions.length; i++) {
    //
    //
    //   this.getInstitutionTools(this.institutions[i]);
    // }
  }

  async getInstitutionTools(pos) {
    if (pos > this.institutions.length - 1) {
      return;
    }

    let inst = this.institutions[pos];

    let { data } =
      (await this.$http.get('/api/institutions/' + inst.id + '/tools')) || {};

    if (!data) {
      return;
    }

    var instTools = data.data.filter(item => item.id === this.tool.id);

    if (instTools.length) {
      if (this.reportTemplates.length) {
        let inst = this.institutions[pos];
        for (let item of this.reportTemplates) {
          if (item.institutionId === inst.id) {
            inst.giveOption = false;
            break;
          } else {
            inst.giveOption = true;
          }
        }
      } else {
        inst.giveOption = true;
      }
    } else {
      // institution doesn't have access to tool
      inst.giveOption = false;
    }

    if (pos !== this.institutions.length - 1) {
      this.getInstitutionTools(pos + 1);

      return;
    }

    if (
      !_.some(this.institutions, { giveOption: true }) &&
      !this.baseTemplateExists
    ) {
      this.selectedGroup = 'baseTemplate';
      this.notify.success('Base Template Chosen Automatically');
      this.groupSelected(null, true);
    }

    this.loading = false;
  }

  groupSelected(form, autoChosen) {
    if (form) {
      if (form.$valid) {
        this.$close(this.selectedGroup);
      }
    } else if (autoChosen) {
      this.$close(this.selectedGroup);
    }
  }
}

export default angular
  .module('app.chooseReportTemplateModal', [])
  .directive('chooseReportTemplateModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./choose-report-template.html'),
    controller: ChooseReportTemplateModalController,
    controllerAs: 'vm'
  })).name;
