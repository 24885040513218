'use strict';

import angular from 'angular';

import { Getter } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

import { cloneDeep } from 'lodash';

@Controller
class InstitutionOffenderHistoryCustomizationModalController extends Modal {
  @Inject $scope;
  @Inject $store;
  @Inject notify;
  @Inject $modals;
  @Getter isDev;

  loading = false;

  $setup() {
    this.toolData = this.$props.toolData;
    // this.toolCommitId = this.$props.toolCommitId;
    this.offenderHistory = this.$props.offenderHistory;

    // if (!this.toolId) {
    //   this.notify.display('Tool ID Not Provided', 'error');
    //   this.$dismiss();
    // }

    // if (!this.toolCommitId) {
    //   this.notify.display('Commit ID Not Provided', 'error');
    //   this.$dismiss();
    // }

    if (!this.offenderHistory) {
      this.notify.display('Offender History Not Provided', 'error');
      this.$dismiss();
    }
  }

  // addQuestionRequirements(questionId, requirement) {
  //   this.customization.questions[questionId] = requirement;
  // }

  // addCommentRequirements(toolId, requirement) {
  //   this.customization.comments[toolId] = requirement;
  // }

  logOffenderHistory() {
    console.log(this.offenderHistory);
  }

  preview() {
    const tool = {
      offenderHistory: cloneDeep(this.offenderHistory)
    };

    const client = {
      fName: 'TEST',
      lName: 'TEST',
      id: 'abc123',
      sex: 'male'
    };

    this.$modals.evaluation.offenderHistory(client, tool, []);
  }
}

export default angular
  .module('app.institutionOffenderHistoryCustomizationModal', [])
  .directive('institutionOffenderHistoryCustomizationModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./institution-offender-history-customization-modal.html'),
    controller: InstitutionOffenderHistoryCustomizationModalController,
    controllerAs: 'vm'
  })).name;
