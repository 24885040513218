'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class AskWorkingToolModalController extends Modal {
  objectType: any;
  toolName: any;
  tool: any;

  @Inject $scope;

  $setup() {
    this.objectType = '';

    if (this.$modal.reportTemplateInformation) {
      this.objectType = 'Report Template';
      this.toolName = this.$props.reportTemplateInformation.name;
    } else if (this.$props.rootToolInformation) {
      this.objectType = 'Tool';
      this.toolName = this.$props.rootToolInformation.name;
    }

    this.tool = JSON.stringify(this.$props, undefined, 2);
  }

  choice(choice) {
    this.$close(choice);
  }
}

export default angular
  .module('app.askWorkingToolModal', [])
  .directive('askWorkingToolModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./ask-working-tool.html'),
    controller: AskWorkingToolModalController,
    controllerAs: 'vm'
  })).name;
