'use strict';

import angular from 'angular';

export default angular
  .module('app.formWrapper', [])
  .directive('formWrapper', $compile => ({
    multiElement: true,
    restrict: 'E',
    transclude: true,
    scope: true,
    template: `
      <form id="{{formId}}" name="{{formId}}" class="form-wrapper" ng-submit="submit()">
        <div ng-transclude></div>
      </form>
    `,
    link(scope, element, attr) {
      scope.formId = attr.formId;

      scope.submit = () =>
        scope.$parent.$eval(attr.ngSubmit, {
          $form: scope[scope.formId]
        });

      let formElem = element.children();

      element.replaceWith(formElem);
    }
  })).name;
