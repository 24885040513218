import { Client } from '@interfaces/client';
import { Policy } from '@interfaces/policy';
import { Region } from '@interfaces/region';
import { Subgroup } from '@interfaces/subgroup';
import { User } from '@interfaces/user';
import { Zone } from '@interfaces/zone';
import * as constants from '@constants';

import { RouteController, Get, Post, Delete, RequestMethod, RouteGroup } from '../route-group';

// region Method Parameter Types

interface RouteBaseOptions {}

export interface GetManagedOffenseClassificationOptions extends RouteBaseOptions {
  offenseClassificationId: string;
}

export interface GetManagedPolicyOptions extends RouteBaseOptions {
  policyId: string;
}

export interface GetUserOptions extends RouteBaseOptions {
  userId: string;
}

export interface GetInstitutionPolicyOptions extends RouteBaseOptions {
  policyId: string;
}

export interface GetClientOptions extends RouteBaseOptions {
  clientId: string;
}

export type ListAggregateReportRequestsOptions = RouteBaseOptions;

export type ListLogRequestsOptions = RouteBaseOptions;

export type ListClientTransferRequestsOptions = RouteBaseOptions;

export type ListClientsOptions = RouteBaseOptions;

export type ListEvaluationRequestsOptions = RouteBaseOptions;

export type ListEvaluationsOptions = RouteBaseOptions;

export type ListEvaluationStatsOptions = RouteBaseOptions;

export interface ListInstitutionToolsOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface ListInstitutionZonesOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface ListInstitutionRegionsOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface ListInstitutionSubgroupsOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface ListInstitutionPoliciesOptions extends RouteBaseOptions {
  institutionId: string;
}

export type ListAllInstitutionPoliciesOptions = RouteBaseOptions;

export type ListInstitutionsOptions = RouteBaseOptions;

export type ListInvitesOptions = RouteBaseOptions;

export type ListManagedOffenseClassificationsOptions = RouteBaseOptions;

export type ListManagedPoliciesOptions = RouteBaseOptions;

export interface ListRegionsOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface ListSubGroupClientsOptions extends RouteBaseOptions {
  institutionId: string;
  subGroupId: string;
}

export interface ListSubGroupsOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface SearchUsersOptions extends RouteBaseOptions {
  institutionId: string;
}

export type ListUsersOptions = RouteBaseOptions;

export interface ListZonesOptions extends RouteBaseOptions {
  institutionId: string;
}

export type ListAllServicesOptions = RouteBaseOptions;

export interface DownloadAggregateReportCSVOptions extends RouteBaseOptions {
  aggReportId: string;
}

export interface DownloadLogRequestOptions extends RouteBaseOptions {
  activityReportId: string;
}

export type ListDashboardStatsRequestOptions = RouteBaseOptions;

export type CreateAggregateReportRequestOptions = RouteBaseOptions;

export type CreateLogRequestOptions = RouteBaseOptions;

export type CalculateEvaluationStatsOptions = RouteBaseOptions;

export type CreateClientOptions = RouteBaseOptions;

export interface CreateClientContactOptions extends RouteBaseOptions {
  clientId: string;
}

export interface CreateInstitutionOptions extends RouteBaseOptions {
  name: string;
  email: string;
  // institutionType: constants.InstitutionType;
  institutionType: string;
  // reassessment: constants.ReassessmentType;
  reassessment: string;
  // mfa: constants.MultiFactorAuthenticationType;
  mfa: string;
  maxUsers: number;
  country: string;
  address1: string;
  address2?: string;
  address3?: string;
  city: string;
  stateProvince: string;
  postalCode: string;
  clientConfig: unknown;
  evaluationConfigs: unknown;
  tools: { id?: number }[];
}

export type CreateManagedOffenseClassificationOptions = RouteBaseOptions;

export type CreateManagedPolicyOptions = RouteBaseOptions;

export type CreateUserOptions = RouteBaseOptions;

export interface InviteUserOptions extends RouteBaseOptions {
  email: string;
  managedPolicyId?: string;
  institutionPolicyId?: string;
  inlinePolicy?: Policy;
}

export interface UpdateManagedOffenseClassificationOptions extends RouteBaseOptions {
  offenseClassificationId: string;
}

export interface UpdateManagedPolicyOptions extends RouteBaseOptions {
  policyId: string;
}

export interface UpdateUserOptions extends RouteBaseOptions {
  userId: string;
}

export interface UpdateUserCapOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface UpdateInstitutionSuperAdminOptions extends RouteBaseOptions {
  institutionId: string;
  email?: string;
  userId?: string;
}

export interface AddInstitutionToolsOptions extends RouteBaseOptions {
  institutionId: string;
  tools: { id?: number }[];
}

export interface UpdateInstitutionOptions extends RouteBaseOptions {
  institutionId: string;
  // id: string;
  name?: string;
  email?: string;
  institutionType?: constants.InstitutionType;
  reassessment?: constants.ReassessmentType;
  mfa?: constants.MultiFactorAuthenticationType;
  maxUsers?: number;
  country?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  clientConfig?: unknown;
  evaluationConfigs?: unknown;
  tools?: { id?: number }[];
  addTools?: { id?: number }[];
  removeTools?: { id?: number }[];
}

export interface UpdateInviteOptions extends RouteBaseOptions {
  inviteId: string;
}

export interface UpdateUser2FAOptions extends RouteBaseOptions {
  userId: string;
}

export interface DeleteInstitutionOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface DeleteInviteOptions extends RouteBaseOptions {
  inviteId: string;
}

export interface DeleteEvaluationOptions extends RouteBaseOptions {
  evaluationId: string;
}

export interface DeleteClientTransferRequestOptions extends RouteBaseOptions {
  requestId: string;
}

export interface DeleteEvaluationRequestOptions extends RouteBaseOptions {
  requestId: string;
}

export interface DeleteZoneOptions extends RouteBaseOptions {
  institutionId: string;
  zoneId: string;
}

export interface RemoveUserFromInstitutionOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface RemoveInstitutionToolsOptions extends RouteBaseOptions {
  institutionId: string;
  tools: { id?: number }[];
}

// endregion Method Parameter Types

@RouteController('gears-manager')
export class GearsManagerRouteGroup extends RouteGroup {
  /** ... */
  @Get('managed-offense-classifications/:offenseClassificationId')
  readonly getManagedOffenseClassification!: RequestMethod<any, GetManagedOffenseClassificationOptions>;

  /** ... */
  @Get('policies/:policyId')
  readonly getManagedPolicy!: RequestMethod<any, GetManagedPolicyOptions>;

  /** ... */
  @Get('users/:userId')
  readonly getUser!: RequestMethod<User, GetUserOptions>;

  /** ... */
  @Get('institution-policies/:policyId')
  readonly getInstitutionPolicy!: RequestMethod<any, GetInstitutionPolicyOptions>;

  /** ... */
  @Get('clients/:clientId')
  readonly getClient!: RequestMethod<any, GetClientOptions>;

  /** ... */
  @Get('aggreport-requests')
  readonly listAggregateReportRequests!: RequestMethod<any, ListAggregateReportRequestsOptions>;

  /** ... */
  @Get('log-requests')
  readonly listLogRequests!: RequestMethod<any, ListLogRequestsOptions>;

  /** ... */
  @Get('client-transfer-requests')
  readonly listClientTransferRequests!: RequestMethod<any, ListClientTransferRequestsOptions>;

  /** ... */
  @Get('clients')
  readonly listClients!: RequestMethod<Client[], ListClientsOptions>;

  /** ... */
  @Get('evaluations-requests')
  readonly listEvaluationRequests!: RequestMethod<any, ListEvaluationRequestsOptions>;

  /** ... */
  @Get('evaluations')
  readonly listEvaluations!: RequestMethod<any, ListEvaluationsOptions>;

  /** ... */
  @Get('evaluation-stats')
  readonly listEvaluationStats!: RequestMethod<any, ListEvaluationStatsOptions>;

  /** ... */
  @Get('institutions/:institutionId/tools')
  readonly listInstitutionTools!: RequestMethod<any, ListInstitutionToolsOptions>;

  /** ... */
  @Get('institutions/:institutionId/zones')
  readonly listInstitutionZones!: RequestMethod<any, ListInstitutionZonesOptions>;

  /** ... */
  @Get('institutions/:institutionId/regions')
  readonly listInstitutionRegions!: RequestMethod<any, ListInstitutionRegionsOptions>;

  /** ... */
  @Get('institutions/:institutionId/subgroups')
  readonly listInstitutionSubgroups!: RequestMethod<any, ListInstitutionSubgroupsOptions>;

  /** ... */
  @Get(':institutionId/policies')
  readonly listInstitutionPolicies!: RequestMethod<any, ListInstitutionPoliciesOptions>;

  /** ... */
  @Get('institution-policies')
  readonly listAllInstitutionPolicies!: RequestMethod<any, ListAllInstitutionPoliciesOptions>;

  /** ... */
  @Get('institutions')
  readonly listInstitutions!: RequestMethod<any, ListInstitutionsOptions>;

  /** ... */
  @Get('invites')
  readonly listInvites!: RequestMethod<any, ListInvitesOptions>;

  /** ... */
  @Get('managed-offense-classifications')
  readonly listManagedOffenseClassifications!: RequestMethod<any, ListManagedOffenseClassificationsOptions>;

  /** ... */
  @Get('policies')
  readonly listManagedPolicies!: RequestMethod<any, ListManagedPoliciesOptions>;

  /** ... */
  @Get('institutions/:institutionId/regions')
  readonly listRegions!: RequestMethod<Region[], ListRegionsOptions>;

  /** ... */
  @Get('institutions/:institutionId/subgroups/:subGroupId/clients')
  readonly listSubGroupClients!: RequestMethod<any, ListSubGroupClientsOptions>;

  /** ... */
  @Get('institutions/:institutionId/subgroups')
  readonly listSubGroups!: RequestMethod<Subgroup[], ListSubGroupsOptions>;

  /** ... */
  @Get('users')
  readonly listUsers!: RequestMethod<User[], ListUsersOptions>;

  /** ... */
  @Get('institutions/:institutionId/zones')
  readonly listZones!: RequestMethod<Zone[], ListZonesOptions>;

  /** ... */
  @Get('services')
  readonly listAllServices!: RequestMethod<any, ListAllServicesOptions>;

  /** ... */
  @Get('aggreport-requests/:aggReportId/download')
  readonly downloadAggregateReportCSV!: RequestMethod<any, DownloadAggregateReportCSVOptions>;

  /** ... */
  @Get('log-requests/:activityReportId/download')
  readonly downloadLogRequest!: RequestMethod<any, DownloadLogRequestOptions>;

  /** ... */
  @Get('dashboard-stats')
  readonly listDashboardStats!: RequestMethod<any, ListDashboardStatsRequestOptions>;

  /** ... */
  @Post('users/search') readonly searchUsers!: RequestMethod<any, SearchUsersOptions>;

  /** ... */
  @Post('aggreport-requests')
  readonly createAggregateReportRequest!: RequestMethod<any, CreateAggregateReportRequestOptions>;

  /** ... */
  @Post('log-requests')
  readonly createLogRequest!: RequestMethod<any, CreateLogRequestOptions>;

  /** ... */
  @Post('evaluation-stats')
  readonly calculateEvaluationStats!: RequestMethod<any, CalculateEvaluationStatsOptions>;

  /** ... */
  @Post('clients')
  readonly createClient!: RequestMethod<any, CreateClientOptions>;

  /** ... */
  @Post('clients/:clientId/contacts')
  readonly createClientContact!: RequestMethod<any, CreateClientContactOptions>;

  /** ... */
  @Post('institutions')
  readonly createInstitution!: RequestMethod<any, CreateInstitutionOptions>;

  /** ... */
  @Post('managed-offense-classifications')
  readonly createManagedOffenseClassification!: RequestMethod<any, CreateManagedOffenseClassificationOptions>;

  /** ... */
  @Post('policies')
  readonly createManagedPolicy!: RequestMethod<any, CreateManagedPolicyOptions>;

  /** ... */
  @Post('users')
  readonly createUser!: RequestMethod<any, CreateUserOptions>;

  /** ... */
  @Post('invites')
  readonly inviteUser!: RequestMethod<any, InviteUserOptions>;

  /** ... */
  @Post('managed-offense-classifications/:offenseClassificationId')
  readonly updateManagedOffenseClassification!: RequestMethod<any, UpdateManagedOffenseClassificationOptions>;

  /** ... */
  @Post('policies/:policyId')
  readonly updateManagedPolicy!: RequestMethod<any, UpdateManagedPolicyOptions>;

  /** ... */
  @Post('users/:userId')
  readonly updateUser!: RequestMethod<any, UpdateUserOptions>;

  /** ... */
  @Post('institutions/:institutionId/update-user-cap')
  readonly updateUserCap!: RequestMethod<any, UpdateUserCapOptions>;

  /** ... */
  @Post('institutions/:institutionId/update-super-admin')
  readonly updateInstitutionSuperAdmin!: RequestMethod<any, UpdateInstitutionSuperAdminOptions>;

  /** ... */
  @Post('institutions/:institutionId/add-tools')
  readonly addInstitutionTools!: RequestMethod<any, AddInstitutionToolsOptions>;

  /** ... */
  @Post('institutions/:institutionId')
  readonly updateInstitution!: RequestMethod<any, UpdateInstitutionOptions>;

  /** ... */
  @Post('invites/:inviteId')
  readonly updateInvite!: RequestMethod<any, UpdateInviteOptions>;

  /** ... */
  @Post('users/:userId/2fa')
  readonly updateUser2FA!: RequestMethod<any, UpdateUser2FAOptions>;

  /** ... */
  @Post('institutions/:institutionId/remove-tools')
  readonly removeInstitutionTools!: RequestMethod<any, RemoveInstitutionToolsOptions>;

  /** ... */
  @Delete('institutions/:institutionId')
  readonly deleteInstitution!: RequestMethod<any, DeleteInstitutionOptions>;

  /** ... */
  @Delete('invites/:inviteId')
  readonly deleteInvite!: RequestMethod<any, DeleteInviteOptions>;

  /** ... */
  @Delete('evaluations/:evaluationId')
  readonly deleteEvaluation!: RequestMethod<any, DeleteEvaluationOptions>;

  /** ... */
  @Delete('client-transfer-requests/:requestId')
  readonly deleteClientTransferRequest!: RequestMethod<any, DeleteClientTransferRequestOptions>;

  /** ... */
  @Delete('evaluations-requests/:requestId')
  readonly deleteEvaluationRequest!: RequestMethod<any, DeleteEvaluationRequestOptions>;

  /** ... */
  @Delete('institutions/:institutionId/zones/:zoneId')
  readonly deleteZone!: RequestMethod<any, DeleteZoneOptions>;

  /** ... */
  @Delete('institutions/:institutionId/users/remove')
  readonly removeUserFromInstitution!: RequestMethod<any, RemoveUserFromInstitutionOptions>;
}
