import angular from 'angular';
import { format, isValid } from 'date-fns';
import { forEach } from 'lodash';
import download from 'downloadjs';
import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils';

import { State } from '@angular';
import { Controller, Inject } from '@decorators/ngCtrl';
import { GDPRDataRequest } from '@interfaces/gdpr-data-request';
import Modal, { Submit } from '@mixins/modal.mixin';
import { MeState } from '@store/modules/me';

type MfaChoice = 'NOMFA' | 'SMS_MFA' | 'EMAIL';

interface GDPRDataRequestData extends GDPRDataRequest {
  downloading?: boolean;
}

@Controller
class SettingsModalController extends Modal {
  userSettings = {
    fName: '',
    lName: '',
    email: '',
    dob: null,
    phoneWork: '',
    phoneCell: '',
    address1: '',
    address2: '',
    address3: '',
    city: '',
    stateProvince: '',
    postalCode: '',
    country: ''
  };

  phonePattern =
    /^([a-zA-Z,#/ \.\(\)\-\+\*]*[0-9]){7}[0-9a-zA-Z,#/ \.\(\)\-\+\*]*$/;
  passwordPattern =
    /^(?:(?=.*[a-z])(?:(?=.*[A-Z])(?=.*[\d\W])|(?=.*\W)(?=.*\d))|(?=.*\W)(?=.*[A-Z])(?=.*\d)).{8,}$/;
  errors = {};
  mfaCellPhone = '';

  itiErrorMap = [
    'Invalid number',
    'Invalid country code',
    'Too short',
    'Too long',
    'Invalid number'
  ];

  expungeKey: string | null = null;
  gdprRequests: GDPRDataRequestData[] | null = null;
  mfaChoice: string | null = null;
  mfaCellInputITI: intlTelInput.Plugin | null = null;
  mfaCellPhoneError: Error | null = null;
  generatedExpungeId: string | null = null;

  // @Inject readonly $scope!: angular.IScope;
  @Inject readonly $http!: angular.IHttpService;
  @Inject readonly $store!: angular.gears.IStoreService;
  @Inject readonly $rootScope!: angular.IRootScopeService;
  @Inject readonly $auth!: angular.gears.IAuthService;
  @Inject readonly $api!: angular.gears.IApiService;
  @Inject readonly $filter!: angular.IFilterService;
  @Inject readonly $location!: angular.ILocationService;
  @Inject readonly $window!: angular.IWindowService;
  @Inject readonly $util!: angular.gears.IUtilService;
  @Inject readonly getFormItems!: angular.gears.IGetFormItemsService;
  @Inject readonly notify!: angular.gears.INotifyService;

  @State readonly me!: MeState;

  async $setup() {
    for (const key in this.userSettings) {
      this.userSettings[key] = this.me[key];
    }

    this.expungeKey = null;

    const res = await this.$api.user.getGdprRequests();

    if (res.data && res.status === 200) {
      this.gdprRequests = res.data;
    }

    this.mfaChoice = this.$auth.mfaChoice;

    const mfaCellInputField = document.getElementById('mfaCellPhone');

    if (mfaCellInputField) {
      this.mfaCellInputITI = intlTelInput(mfaCellInputField, {
        autoPlaceholder: 'aggressive',
        preferredCountries: [],
        onlyCountries: ['us', 'ca', 'au', 'ie', 'gb'],
        utilsScript
      });
    }

    if (this.$auth.mfaCellPhone) {
      this.mfaCellInputITI?.setNumber(this.$auth.mfaCellPhone);
    }

    this.$scope.$apply();
  }

  async setMFAChoice(choice: MfaChoice) {
    // if sms, we need a verify button/process
    const originalMFA = angular.copy(this.mfaChoice);

    this.mfaChoice = choice;

    if (choice === 'SMS_MFA') return;

    try {
      await this.$auth.setMFA(choice);
    } catch (err) {
      this.mfaChoice = originalMFA;
    }
  }

  async submitFMAPhone() {
    this.mfaCellPhoneError = null;

    if (!this.mfaCellInputITI?.isValidNumber()) {
      this.mfaCellPhoneError =
        this.itiErrorMap[this.mfaCellInputITI.getValidationError()];

      return;
    }

    const phoneNumber = this.mfaCellInputITI.getNumber();

    // save new number to congito user
    try {
      await this.$api.user.updateMe({ phone_number: phoneNumber });
    } catch (err) {
      return this.notify.display(err, 'error');
    }

    // submit for verification code

    try {
      await this.$auth.verifyPhoneNumberForMFA();
    } catch (err) {
      return;
    }

    // success
    this.notify.display(
      'Successfully updated and verified phone number for MFA',
      'success'
    );

    await this.$auth.setMFA('SMS');
  }

  async createGDPRRequest() {
    const res = await this.$api.user.gdprRequest();

    if (res.status !== 200) {
      return this.notify.display(res, 'error');
    }

    this.gdprRequests?.push(res.data);

    this.notify.display(
      'You will receive an email when your data is ready to download. Revisit this page and click the download option next to the request in the table.',
      'success',
      true,
      'GDPR Request Successful'
    );
  }

  async downloadGDPRData(request: GDPRDataRequestData) {
    const filename = `${format(new Date(request.updatedAt), 'MM-dd-yyyy')}_${
      this.userSettings.fName
    }_${this.userSettings.lName}_GEARS_Data.zip`;

    let res: angular.IHttpResponse<string> | null = null;
    let error: Error | null = null;

    request.downloading = true;

    try {
      res = await this.$http.get<string>(
        `/api/users/gdpr-requests/${request.id}`,
        { responseType: 'blob' }
      );
    } catch (err) {
      error = err as Error;
    }

    request.downloading = false;

    if (error) {
      return this.notify.error(error);
    }

    download(res!.data, filename, 'application/zip');

    this.notify.display(
      'A zip file containing your data should download automatically',
      'success'
    );
  }

  async createExpungeRequest() {
    const res = await this.$api.user.expungeRequest();

    if (res.status !== 200) {
      return this.notify.display(res, 'error');
    }

    this.generatedExpungeId = res.data.id;

    this.notify.display(
      'You will receive an email shortly with a key that you will need to enter along with the ID generated to delete your account.',
      'success',
      true,
      'Expunge Requested Successful'
    );
  }

  async expungeUser() {
    const { accessToken } = await this.$auth.getSession();

    const url = new URL(window.location.origin);
    url.pathname = 'api/users/me';
    url.searchParams.append('expungeUserRequestId', this.generatedExpungeId);
    url.searchParams.append('validationKey', this.expungeKey);

    const x = new XMLHttpRequest();
    x.open('DELETE', url, true);
    x.setRequestHeader('Authorization', `Bearer ${accessToken.jwtToken}`);

    x.onloadend = () => {
      if (x.status === 204) {
        this.notify.display(
          'It may take some time to finish processing your request. You will receive an email when the process is complete. You will be logged out now.',
          'success',
          true,
          'Account Deletion Successfully Queued'
        );

        void this.$auth.logout();

        return;
      }

      this.notify.error(
        `There was an error processing your request. Please contact ${process.env.SUPPORT_EMAIL} for assistance.`
      );
    };

    x.send();
  }

  @Submit
  async changeSettings1() {
    const payload = {};

    for (const [key, newVal] of Object.entries(this.userSettings)) {
      const oldVal = this.me[key];

      if (key === 'dob') {
        if (!isValid(new Date(newVal))) {
          this.notify.display('Invalid Date Format', 'error');
          return;
        }
        const a = Date.parse(oldVal);
        const b = Date.parse(newVal);

        if (a !== b) {
          payload[key] = format(new Date(newVal), 'MM/dd/yyyy');
        }
      } else if (oldVal !== newVal) {
        payload[key] = newVal;
      }
    }

    let res;

    try {
      res = await this.$api.user.updateMe(payload);
    } catch (err) {
      this.notify.display(err, 'error');
      return;
    }

    if (res.status === 200 || res.status === 204) {
      forEach(payload, (val, key) => {
        this.me[key] = val;
      });
      this.notify.display('User information successfully updated', 'success');
    } else {
      this.notify.display(res, 'error');
    }
  }
}

// export function settingsModalController(
//   $scope,
//   $store,
//   $rootScope,
//   $auth,
//   $api,
//   $filter,
//   getFormItems,
//   parseDate,
//   Notification
// ) {
//   'ngInject';
//
//   var parent = $scope.$parent;
//
//   $scope.parseDate = function(date) {
//     date = parseDate.parseZeros(angular.copy(date));
//     this.userSettings.dob = date;
//   };
//
//   $scope.updateAddressFields = async function() {
//     let methodName;
//
//     switch (this.userSettings.country) {
//       case 'US':
//         methodName = 'usStates';
//         break;
//       case 'CA':
//         methodName = 'canadaProvinces';
//         break;
//       case 'GB':
//         methodName = 'ukProvinces';
//         break;
//       case 'AU':
//         methodName = 'auStates';
//         break;
//     }
//
//     if (!methodName) {
//       return;
//     }
//
//     $scope.stateProvinces = await getFormItems[methodName]();
//   };
//
//   $scope.changeSettings1 = async function() {
//     $scope.formSubmitted = true;
//
//     var data = {};
//
//     _.forEach(this.userSettings, (newVal, key) => {
//       let oldVal = this.me[key];
//
//       if (key === 'dob') {
//         var a = Date.parse(oldVal);
//         var b = Date.parse(newVal);
//
//         if (a !== b) {
//           data[key] = newVal;
//         }
//       } else if (oldVal !== newVal) {
//         data[key] = newVal;
//       }
//     });
//
//     try {
//       let res = await $api.users.update(this.me.id, data);
//
//       $scope.formSubmitted = false;
//
//       if (!res.data.error) {
//         Notification.success({
//           title: 'Updated Profile Information',
//           positionX: 'center',
//           templateUrl: 'assets/components/notification-template.html'
//         });
//
//         var rootUser = $rootScope.user;
//
//         for (var cle in data) {
//           // update user info in root scope
//           rootUser[cle] = data[cle];
//         }
//
//         parent.$close(res);
//       } else {
//         Notification.warning({
//           message: res.data.feedback,
//           title: 'Error - Update Profile Information',
//           positionX: 'center',
//           templateUrl: 'assets/components/notification-template.html'
//         });
//       }
//     } catch (err) {
//       $scope.formSubmitted = false;
//
//       Notification.warning({
//         message: err.data.feedback,
//         title: 'Error - Update Profile Information',
//         positionX: 'center',
//         templateUrl: 'assets/components/notification-template.html'
//       });
//     }
//   };
// }

export default angular
  .module('app.settingsModal', [])
  .directive('settingsModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./settings.html'),
    controller: SettingsModalController,
    controllerAs: 'vm'
  })).name;
