import { Client } from '@interfaces/client';
import { ClientContact } from '@interfaces/client-contact';
import { Institution } from '@interfaces/institution';
import { InstitutionPolicy } from '@interfaces/institution-policy';
import { Policy } from '@interfaces/policy';
import { Region } from '@interfaces/region';
import { Subgroup } from '@interfaces/subgroup';
import { User } from '@interfaces/user';
import { Zone } from '@interfaces/zone';
import { Tool } from '@root/client/interfaces';

import { RouteController, Get, Post, Delete, RequestMethod, RouteGroup } from '../route-group';

// region Method Parameter Types

interface RouteBaseOptions {
  institutionId: string;
}

export type GetInstitutionOptions = RouteBaseOptions;

export interface GetOffenseClassificationOptions extends RouteBaseOptions {
  offenseClassificationId: string;
}

export interface GetClientOptions extends RouteBaseOptions {
  clientId: string;
}

export type ListAggregateReportRequestsOptions = RouteBaseOptions;

export type ListLogRequestsOptions = RouteBaseOptions;

export type ListClientsOptions = RouteBaseOptions;

export interface ListClientEvaluationsOptions extends RouteBaseOptions {
  clientId: string;
}

export interface ListEvaluationRequestsOptions extends RouteBaseOptions {
  subgroupIds: string[];
}

export type ListEvaluationsOptions = RouteBaseOptions;

export type ListIncomingClientRequestsOptions = RouteBaseOptions;

export type ListInstitutionToolsOptions = RouteBaseOptions;

export type ListInstitutionUsersOptions = RouteBaseOptions;

export type ListInvitesOptions = RouteBaseOptions;

export type ListManagedPoliciesOptions = RouteBaseOptions;

export type ListOffenseClassificationsOptions = RouteBaseOptions;

export type ListOutgoingClientRequestsOptions = RouteBaseOptions;

export type ListPoliciesOptions = RouteBaseOptions;

export type ListRegionsOptions = RouteBaseOptions;

export interface ListSubGroupClientsOptions extends RouteBaseOptions {
  subGroupId: string;
}

export type ListSubGroupsOptions = RouteBaseOptions;

export type ListZonesOptions = RouteBaseOptions;

export type ListServicesOptions = RouteBaseOptions;

export type ListImportClientRequestsOptions = RouteBaseOptions;

export interface GetInstitutionUserOptions extends RouteBaseOptions {
  userId: string;
}

export interface GetImportClientRequestOptions extends RouteBaseOptions {
  importClientRequestId: string;
}

export interface DownloadAggregateReportCSVOptions extends RouteBaseOptions {
  aggReportId: string;
}

export interface DownloadLogRequestOptions extends RouteBaseOptions {
  activityReportId: string;
}

export type ListImportEvaluationRequestsOptions = RouteBaseOptions;
export type ListDashboardStatsRequestOptions = RouteBaseOptions;

export type ListCustomOffenderHistoryTemplatesOptions = RouteBaseOptions;
export interface GetCustomOffenderHistoryTemplateOptions extends RouteBaseOptions {
  templateId: string;
}

export interface ListSubGroupClientsV2Options extends RouteBaseOptions {
  subgroupIds: string[];
}

export type ListSubGroupClientsEvaluationsOptions = RouteBaseOptions;

export type CreateAggregateReportRequestOptions = RouteBaseOptions;

export type CreateLogRequestOptions = RouteBaseOptions;

export type CreateClientTransferRequestOptions = RouteBaseOptions;

export type AddUserOptions = RouteBaseOptions;

export type AssignPolicyToUserOptions = RouteBaseOptions;

export interface CreateClientOptions extends RouteBaseOptions {
  alias?: string;
  localId?: string;
  fName?: string;
  mName?: string;
  lName?: string;
  phone?: string;
  email?: string;
  dob: string;
  sex: 'male' | 'female';
  gender?: string;
  ethnicity?: string;
  type?: string[];
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  country: string;
  clinicalInfo?: string;
  subGroupId?: string;
  // Custom field values dictated by client config.
  [key: string]: unknown;
}

export interface UpdateClientOptions extends RouteBaseOptions {
  clientId: string;
  alias?: string;
  localId?: string;
  fName?: string;
  mName?: string;
  lName?: string;
  phone?: string;
  email?: string;
  dob?: string;
  sex?: 'male' | 'female';
  gender?: string;
  ethnicity?: string;
  type?: string[];
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  country?: string;
  clinicalInfo?: string;
  subGroupId?: string;
  // Custom field values dictated by client config.
  [key: string]: unknown;
}

export interface CreateClientContactOptions extends RouteBaseOptions {
  clientId: string;
  fName: string;
  lName: string;
  email?: string;
  phone?: string;
  relation?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  country: string;
}

export interface UpdateClientContactOptions extends RouteBaseOptions {
  contactId: string;
  clientId: string;
  fName?: string;
  lName?: string;
  email?: string;
  phone?: string;
  relation?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  city?: string;
  stateProvince?: string;
  postalCode?: string;
  country?: string;
}

export interface CreateEvaluationOptions extends RouteBaseOptions {
  clientId: string;
}

export type CreateOffenseClassificationOptions = RouteBaseOptions;

export type CreatePolicyOptions = RouteBaseOptions;

export type ImportClientsOptions = RouteBaseOptions;

export interface InviteUserOptions extends RouteBaseOptions {
  email: string;
  managedPolicyId?: string;
  institutionPolicyId?: string;
  inlinePolicy?: Policy;
}

export interface ResendInviteOptions extends RouteBaseOptions {
  inviteId: string;
}

export interface UpdateInstitutionOptions extends RouteBaseOptions {
  institutionId: string;
}

export interface UpdateOffenseClassificationOptions extends RouteBaseOptions {
  offenseClassificationId: string;
}

export interface UpdatePolicyOptions extends RouteBaseOptions {
  institutionPolicyId: string;
}

export interface AddClientToSubGroupOptions extends RouteBaseOptions {
  clientId?: string;
  clientIds?: string[];
  subGroupId: string;
}

export interface CreateRegionOptions extends RouteBaseOptions {
  zoneId: string;
  name: string;
}

export interface CreateSubGroupOptions extends RouteBaseOptions {
  regionId: string;
  name: string;
}

export interface CreateZoneOptions extends RouteBaseOptions {
  name: string;
}

export interface UpdateRegionOptions extends RouteBaseOptions {
  regionId: string;
  name: string;
}

export interface UpdateSubGroupOptions extends RouteBaseOptions {
  subGroupId: string;
  name: string;
}

export interface UpdateZoneOptions extends RouteBaseOptions {
  zoneId: string;
  name: string;
}

export interface CreateCustomOffenderHistoryTemplateOptions extends RouteBaseOptions {
  name: string;
  tool: any;
  templateData: any;
}

export interface UpdateCustomOffenderHistoryTemplateOptions extends RouteBaseOptions {
  templateId: string;
  name: string;
  templateData: any;
}

export type AcceptClientTransferRequestOptions = RouteBaseOptions;

export type CreateExpungeClientRequestOptions = RouteBaseOptions;

export type ImportEvaluationsOptions = RouteBaseOptions;

export interface DeleteClientOptions extends RouteBaseOptions {
  clientId: string;
}

export type RemovePolicyFromUserOptions = RouteBaseOptions;

export interface RemoveUserOptions extends RouteBaseOptions {
  userId: string;
}

export type RemoveClientFromSubGroupOptions = RouteBaseOptions;

export interface DeleteInviteOptions extends RouteBaseOptions {
  inviteId: string;
}

export interface DeleteEvaluationOptions extends RouteBaseOptions {
  clientId: string;
  evaluationId: string;
}

export interface DeleteEvaluationRequestOptions extends RouteBaseOptions {
  requestId: string;
}

export interface DeleteOffenseClassificationOptions extends RouteBaseOptions {
  offenseClassificationId: string;
}

export interface DeleteClientTransferRequestOptions extends RouteBaseOptions {
  requestId: string;
}

export type DeclineClientTransferRequestOptions = RouteBaseOptions;

export interface DeleteZoneOptions extends RouteBaseOptions {
  zoneId: string;
}

export interface DeleteRegionOptions extends RouteBaseOptions {
  regionId: string;
}

export interface DeleteSubGroupOptions extends RouteBaseOptions {
  subGroupId: string;
}

export interface DeleteCustomOffenderHistoryTemplateOptions extends RouteBaseOptions {
  templateId: string;
}

export interface DeleteClientContactOptions extends RouteBaseOptions {
  clientId: string;
  contactId: string;
}

// endregion Method Parameter Types

@RouteController('institution-manager/:institutionId')
export class InstitutionManagerRouteGroup extends RouteGroup {
  /** ... */
  @Get('')
  // readonly getInstitution!: RequestMethod<Institution, GetInstitutionOptions>;
  readonly getInstitution!: RequestMethod<unknown, GetInstitutionOptions>;

  /** ... */
  @Get('offense-classifications/:offenseClassificationId')
  readonly getOffenseClassification!: RequestMethod<any, GetOffenseClassificationOptions>;

  /** Get a client. */
  @Get('clients/:clientId')
  readonly getClient!: RequestMethod<Client, GetClientOptions>;

  /** ... */
  @Get('aggreport-requests')
  readonly listAggregateReportRequests!: RequestMethod<any, ListAggregateReportRequestsOptions>;

  /** ... */
  @Get('log-requests')
  readonly listLogRequests!: RequestMethod<any, ListLogRequestsOptions>;

  /** List clients. */
  @Get('clients')
  readonly listClients!: RequestMethod<Client[], ListClientsOptions>;

  /** ... */
  @Get('clients/:clientId/evaluations')
  readonly listClientEvaluations!: RequestMethod<any, ListClientEvaluationsOptions>;

  /** ... */
  @Get('evaluations')
  readonly listEvaluations!: RequestMethod<any, ListEvaluationsOptions>;

  /** ... */
  @Get('incoming-client-requests')
  readonly listIncomingClientRequests!: RequestMethod<any, ListIncomingClientRequestsOptions>;

  /** ... */
  @Get('tools')
  readonly listInstitutionTools!: RequestMethod<any, ListInstitutionToolsOptions>;

  /** ... */
  @Get('users')
  readonly listInstitutionUsers!: RequestMethod<User[], ListInstitutionUsersOptions>;

  /** ... */
  @Get('users/:userId')
  readonly getInstitutionUser!: RequestMethod<User, GetInstitutionUserOptions>;

  /** ... */
  @Get('invites')
  readonly listInvites!: RequestMethod<any, ListInvitesOptions>;

  /** ... */
  @Get('managed-policies')
  readonly listManagedPolicies!: RequestMethod<any, ListManagedPoliciesOptions>;

  /** ... */
  @Get('offense-classifications')
  readonly listOffenseClassifications!: RequestMethod<any, ListOffenseClassificationsOptions>;

  /** ... */
  @Get('outgoing-client-requests')
  readonly listOutgoingClientRequests!: RequestMethod<any, ListOutgoingClientRequestsOptions>;

  /** ... */
  @Get('policies')
  readonly listPolicies!: RequestMethod<InstitutionPolicy[], ListPoliciesOptions>;

  /** ... */
  @Get('regions')
  readonly listRegions!: RequestMethod<Region[], ListRegionsOptions>;

  /** ... */
  @Get('subgroups/:subGroupId/clients')
  readonly listSubGroupClients!: RequestMethod<Client[], ListSubGroupClientsOptions>;

  /** ... */
  @Get('subgroups')
  readonly listSubGroups!: RequestMethod<Subgroup[], ListSubGroupsOptions>;

  /** ... */
  @Get('zones')
  readonly listZones!: RequestMethod<Zone[], ListZonesOptions>;

  /** ... */
  @Get('services')
  readonly listServices!: RequestMethod<any, ListServicesOptions>;

  /** ... */
  @Get('import-clients')
  readonly listImportClientRequests!: RequestMethod<any, ListImportClientRequestsOptions>;

  /** ... */
  @Get('import-clients/:importClientRequestId')
  readonly getImportClientRequest!: RequestMethod<any, GetImportClientRequestOptions>;

  /** ... */
  @Get('aggreport-requests/:aggReportId/download')
  readonly downloadAggregateReportCSV!: RequestMethod<any, DownloadAggregateReportCSVOptions>;

  /** ... */
  @Get('log-requests/:activityReportId/download')
  readonly downloadLogRequest!: RequestMethod<any, DownloadLogRequestOptions>;

  /** ... */
  @Get('import-evaluations')
  readonly listImportEvaluationRequests!: RequestMethod<any, ListImportEvaluationRequestsOptions>;
  /** ... */

  @Get('dashboard-stats')
  readonly listDashboardStats!: RequestMethod<any, ListDashboardStatsRequestOptions>;

  @Get('custom-offender-history-templates')
  readonly listCustomOffenderHistoryTemplates!: RequestMethod<any, ListCustomOffenderHistoryTemplatesOptions>;

  @Get('custom-offender-history-templates/:templateId')
  readonly getCustomOffenderHistoryTemplate!: RequestMethod<any, GetCustomOffenderHistoryTemplateOptions>;

  /** ... */
  @Post('subgroup-clients')
  readonly listSubGroupClientsV2!: RequestMethod<Client[], ListSubGroupClientsV2Options>;

  /** ... */
  @Post('subgroup-clients-evaluations')
  readonly listSubGroupClientsEvaluations!: RequestMethod<any, ListSubGroupClientsEvaluationsOptions>;

  /** ... */
  @Post('evaluation-requests')
  readonly listEvaluationRequests!: RequestMethod<any, ListEvaluationRequestsOptions>;

  /** ... */
  @Post('aggreport-requests')
  readonly createAggregateReportRequest!: RequestMethod<any, CreateAggregateReportRequestOptions>;

  /** ... */
  @Post('log-requests')
  readonly createLogRequest!: RequestMethod<any, CreateLogRequestOptions>;

  /** ... */
  @Post('client-transfer-requests')
  readonly createClientTransferRequest!: RequestMethod<any, CreateClientTransferRequestOptions>;

  /** ... */
  @Post('users/add')
  readonly addUser!: RequestMethod<any, AddUserOptions>;

  /** ... */
  @Post('policy/assign-policy')
  readonly assignPolicyToUser!: RequestMethod<any, AssignPolicyToUserOptions>;

  /** ... */
  @Post('clients')
  readonly createClient!: RequestMethod<Client, CreateClientOptions>;

  /** ... */
  @Post('clients/:clientId')
  readonly updateClient!: RequestMethod<Client, UpdateClientOptions>;

  /** ... */
  @Post('clients/:clientId/contacts')
  readonly createClientContact!: RequestMethod<Client, CreateClientContactOptions>;

  /** ... */
  @Post('clients/:clientId/contacts/:contactId')
  readonly updateClientContact!: RequestMethod<Client, UpdateClientContactOptions>;

  /** ... */
  @Post('clients/:clientId/evaluations')
  readonly createEvaluation!: RequestMethod<any, CreateEvaluationOptions>;

  /** ... */
  @Post('offense-classifications')
  readonly createOffenseClassification!: RequestMethod<any, CreateOffenseClassificationOptions>;

  /** ... */
  @Post('policies')
  readonly createPolicy!: RequestMethod<any, CreatePolicyOptions>;

  /** ... */
  @Post('import-clients')
  readonly importClients!: RequestMethod<any, ImportClientsOptions>;

  /** ... */
  @Post('invites')
  readonly inviteUser!: RequestMethod<any, InviteUserOptions>;

  /** ... */
  @Post('invites/:inviteId/resend')
  readonly resendInvite!: RequestMethod<any, ResendInviteOptions>;

  /** ... */
  @Post(':institutionId')
  readonly updateInstitution!: RequestMethod<any, UpdateInstitutionOptions>;

  /** ... */
  @Post('offense-classifications/:offenseClassificationId')
  readonly updateOffenseClassification!: RequestMethod<any, UpdateOffenseClassificationOptions>;

  /** ... */
  @Post('policies/:institutionPolicyId')
  readonly updatePolicy!: RequestMethod<any, UpdatePolicyOptions>;

  /** ... */
  @Post('subgroups/add-client')
  readonly addClientToSubGroup!: RequestMethod<Client, AddClientToSubGroupOptions>;

  /** ... */
  @Post('regions')
  readonly createRegion!: RequestMethod<any, CreateRegionOptions>;

  /** ... */
  @Post('subgroups')
  readonly createSubGroup!: RequestMethod<any, CreateSubGroupOptions>;

  /** ... */
  @Post('zones')
  readonly createZone!: RequestMethod<any, CreateZoneOptions>;

  /** ... */
  @Post('regions/:regionId')
  readonly updateRegion!: RequestMethod<any, UpdateRegionOptions>;

  /** ... */
  @Post('subgroups/:subGroupId')
  readonly updateSubGroup!: RequestMethod<any, UpdateSubGroupOptions>;

  /** ... */
  @Post('zones/:zoneId')
  readonly updateZone!: RequestMethod<any, UpdateZoneOptions>;

  /** ... */
  @Post('client-transfer-requests/accept')
  readonly acceptClientTransferRequest!: RequestMethod<any, AcceptClientTransferRequestOptions>;

  /** ... */
  @Post('expunge-requests')
  readonly createExpungeClientRequest!: RequestMethod<any, CreateExpungeClientRequestOptions>;

  /** ... */
  @Post('import-evaluations')
  readonly importEvaluations!: RequestMethod<any, ImportEvaluationsOptions>;

  /** ... */
  @Post('custom-offender-history-templates')
  readonly createCustomOffenderHistoryTemplate!: RequestMethod<any, CreateCustomOffenderHistoryTemplateOptions>;

  /** ... */
  @Post('custom-offender-history-templates/:templateId')
  readonly updateCustomOffenderHistoryTemplate!: RequestMethod<any, UpdateCustomOffenderHistoryTemplateOptions>;

  /** ... */
  @Delete('clients/:clientId')
  readonly deleteClient!: RequestMethod<any, DeleteClientOptions>;

  /** ... */
  @Delete('policy/remove-policy')
  readonly removePolicyFromUser!: RequestMethod<any, RemovePolicyFromUserOptions>;

  /** ... */
  @Delete('users/remove')
  readonly removeUser!: RequestMethod<any, RemoveUserOptions>;

  /** ... */
  @Delete('subgroups/remove-client')
  readonly removeClientFromSubGroup!: RequestMethod<any, RemoveClientFromSubGroupOptions>;

  /** ... */
  @Delete('invites/:inviteId')
  readonly deleteInvite!: RequestMethod<void, DeleteInviteOptions>;

  /** ... */
  @Delete('clients/:clientId/evaluations/:evalId')
  readonly deleteEvaluation!: RequestMethod<void, DeleteEvaluationOptions>;

  /** ... */
  @Delete('evaluation-requests/:requestId')
  readonly deleteEvaluationRequest!: RequestMethod<void, DeleteEvaluationRequestOptions>;

  /** ... */
  @Delete('offense-classifications/:offenseClassificationId')
  readonly deleteOffenseClassification!: RequestMethod<void, DeleteOffenseClassificationOptions>;

  /** ... */
  @Delete('client-transfer-requests/:requestId')
  readonly deleteClientTransferRequest!: RequestMethod<void, DeleteClientTransferRequestOptions>;

  /** ... */
  @Delete('client-transfer-requests/decline')
  readonly declineClientTransferRequest!: RequestMethod<void, DeclineClientTransferRequestOptions>;

  /** ... */
  @Delete('zones/:zoneId')
  readonly deleteZone!: RequestMethod<void, DeleteZoneOptions>;

  /** ... */
  @Delete('regions/:regionId')
  readonly deleteRegion!: RequestMethod<void, DeleteRegionOptions>;

  /** ... */
  @Delete('subgroups/:subGroupId')
  readonly deleteSubGroup!: RequestMethod<void, DeleteSubGroupOptions>;

  /** ... */
  @Delete('clients/:clientId/contacts/:contactId')
  readonly deleteClientContact!: RequestMethod<void, DeleteClientContactOptions>;

  /** ... */
  @Delete('custom-offender-history-templates/:templateId')
  readonly deleteCustomOffenderHistoryTemplate!: RequestMethod<void, DeleteCustomOffenderHistoryTemplate>;
}
