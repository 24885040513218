import changeCase from 'change-case';
import { ModuleTree } from 'angular-store/types';

import { RootState } from '../state';

const context = require.context('.', false, /\.(js|ts)$/);

const files = context
  .keys()
  .filter((key) => key !== './index.js')
  .map((key) => ({
    path: key,
    name: changeCase.camel(key.replace(/(\.\/|\.(js|ts))/g, ''))
  }));

export const modules: ModuleTree<RootState> = {};

files.forEach(({ name, path }) => {
  modules[name] = context(path).default;
});
