import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class LogoutView {
  @Inject $auth;

  $onInit() {
    console.log('LOGOUT!!!');
    this.$auth.logout();
  }
}

export default LogoutView;
