import angular, { IHttpProvider } from 'angular';
import { Amplify } from '@aws-amplify/core';

import { amplifyConfig } from './amplify-config';
import { AuthService } from './auth.service';
import { interceptor } from './interceptor.factory';

/**
 * ...
 */
export type IAuthService = AuthService;

declare module 'angular' {
  namespace gears {
    type IAuthService = AuthService;
  }
}

function config($httpProvider: IHttpProvider) {
  'ngInject';

  $httpProvider.interceptors.push('authInterceptor');
}

function run($auth: AuthService) {
  'ngInject';

  $auth.loggedInAsync.then();
}

Amplify.configure(amplifyConfig);

window.onbeforeunload = () => {
  // sets lastLoaded with current time for detecting if the user
  // spent more than 10 seconds away from the page (i.e. did not refresh)
  localStorage.setItem('lastLoaded', new Date().toString());
};

export default angular
  .module('app.$auth', [])
  .factory('authInterceptor', interceptor)
  .service('$auth', AuthService)
  .config(config)
  .run(run).name;
