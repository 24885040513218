import angular from 'angular';
import { ActionContext, GetterTree } from 'angular-store';
import { HasAccessCheckOptions } from '@services/acl/acl.service';
import { RootState } from '@store/state';
import { find, filter, sortBy } from 'lodash';

export interface AnalyticsState {
  allOpenEvaluations: number;
  clientsIncrease: boolean;
  clientsPercentage: string;
  openEvaluations: number;
  userOpenEvaluations: number;
  userCompletedEvaluations: number;
  userEvaluationsComputed: boolean;
  averageEvaluationElapsedTime: number | string;
  evaluationsIncrease: boolean;
  evaluationsPercentage: string;
  averageUsersPerInstitutionNumber: number;
  usersIncrease: boolean;
  usersPercentage: string;
  usersActive: number;
  usersInactive: number;
  institutionsIncrease: boolean;
  institutionsPercentage: string;
  averageEvaluationsPerUser: number;
  averageEvaluationsPerInstitution: number;
  toolsUsedForEvals: { labels: string[]; data: number[][] };
  avgUsersPerInst: { labels: string[]; data: number[][] };
  // new lean stats
  evaluationsCompleted: number;
  evaluationsInProgress: number;
  evaluationsNotStarted: number;
  totalEvaluations: number;
  evaluationsPerMonth: unknown[];
  totalInstitutions: number;
  totalUsers: number;
  totalClients: number;
  // last updated at tracker for stats
  usersLastUpdated: string;
  clientsLastUpdated: string;
  evaluationsLastUpdated: string;
  institutionsLastUpdated: string;
  usersNextUpdate: string;
  clientsNextUpdate: string;
  evaluationsNextUpdate: string;
  institutionsNextUpdate: string;
}

export interface StatItem {
  id: string;
  createdAt: string;
  dataModel: string;
  monthIndex: number;
  monthName: string;
  pk: string;
  sk: string;
  total: number;
  type: string;
  updatedAt: string;
  year: number;
}

export interface AnalyticsMutations {
  SET_PROPS: (props: Partial<AnalyticsState>) => void;
  CLEAR: () => void;
}

export interface AnalyticsActions {
  generatetoolsUsedForEvalsTableData: () => Promise<void>;
  computeForUsers: () => Promise<void>;
  getStatsForEvaluations: () => Promise<void>;
  computeForEvaluations: (
    params?: ComputeEvaluationAnalyticsActionOptions
  ) => Promise<void>;
  computeForClients: () => Promise<void>;
  computeForInstitutions: () => Promise<void>;
  computeUsersForInstitutions: () => Promise<void>;
  computeAverages: () => Promise<void>;
}

export interface ComputeEvaluationAnalyticsActionOptions {
  tableData: boolean;
  elapsedTime: boolean;
  growth: boolean;
  client: unknown;
  user: unknown;
}

type AnalyticsMutationTree = {
  [P in keyof AnalyticsMutations]: (
    state: AnalyticsState,
    payload: Parameters<AnalyticsMutations[P]>[0]
  ) => ReturnType<AnalyticsMutations[P]>;
};

type AnalyticsActionTree = {
  [P in keyof AnalyticsActions]: (
    context: ActionContext<RootState, AnalyticsState>,
    payload: Parameters<AnalyticsActions[P]>[0]
  ) => ReturnType<AnalyticsActions[P]>;
};

// declare module 'angular-store' {
//   export interface Store<S> {
//     commit(type: 'analytics/SET_PROPS', props: Partial<AnalyticsState>): void;
//
//     dispatch(type: 'analytics/computeForUsers'): Promise<void>;
//     dispatch(type: 'analytics/getStatsForEvaluations'): Promise<void>;
//     dispatch(
//       type: 'analytics/computeForEvaluations',
//       params?: ComputeEvaluationAnalyticsActionOptions
//     ): Promise<void>;
//     dispatch(type: 'analytics/computeForClients'): Promise<void>;
//     dispatch(type: 'analytics/computeForInstitutions'): Promise<void>;
//     dispatch(type: 'analytics/computeUsersForInstitutions'): Promise<void>;
//     dispatch(type: 'analytics/computeAverages'): Promise<void>;
//   }
// }

function AnalyticsStore(
  $api: angular.gears.IApiService,
  $injector: angular.auto.IInjectorService,
  $api2: angular.gears.IAPI2Service,
  notify: angular.gears.INotifyService
) {
  'ngInject';

  /** ... */
  const hasAccess = (actions: HasAccessCheckOptions) =>
    $injector.get<angular.gears.IAuthService>('$auth').hasAccess(actions);

  const state: AnalyticsState = {
    // loading: false,
    allOpenEvaluations: 0,
    clientsIncrease: false,
    clientsPercentage: '0%',
    openEvaluations: 0,
    userOpenEvaluations: 0,
    userCompletedEvaluations: 0,
    userEvaluationsComputed: false,
    averageEvaluationElapsedTime: 0,
    evaluationsIncrease: false,
    evaluationsPercentage: '0%',
    averageUsersPerInstitutionNumber: 0,
    usersIncrease: false,
    usersPercentage: '0%',
    usersActive: 0,
    usersInactive: 0,
    institutionsIncrease: false,
    institutionsPercentage: '0%',
    averageEvaluationsPerUser: 0,
    averageEvaluationsPerInstitution: 0,
    toolsUsedForEvals: { labels: [], data: [] },
    avgUsersPerInst: { labels: [], data: [] },
    // new lean stats
    evaluationsCompleted: 0,
    evaluationsInProgress: 0,
    evaluationsNotStarted: 0,
    totalEvaluations: 0,
    evaluationsPerMonth: [],
    totalInstitutions: 0,
    totalUsers: 0,
    totalClients: 0,
    // last updated at tracker for stats
    usersLastUpdated: '',
    clientsLastUpdated: '',
    evaluationsLastUpdated: '',
    institutionsLastUpdated: '',
    usersNextUpdate: '',
    clientsNextUpdate: '',
    evaluationsNextUpdate: '',
    institutionsNextUpdate: ''
  };

  const getters: GetterTree<AnalyticsState, RootState> = {
    avgUsersPerInstGraphConfig(state) {
      return {
        labels: state.avgUsersPerInst.labels,
        data: state.avgUsersPerInst.data,
        series: ['Average Users Per Institution'],
        options: {
          scales: { xAxes: [{ display: false }] },
          toolTipEvents: [],
          showTooltips: true,
          tooltipCaretSize: 0,
          onAnimationComplete: function () {
            this.showTooltip(this.segments, true);
          }
        }
      };
    },
    toolsUsedForEvalsGraphConfig(state) {
      return {
        labels: state.toolsUsedForEvals.labels,
        data: state.toolsUsedForEvals.data,
        options: {
          toolTipEvents: [],
          showTooltips: true,
          tooltipCaretSize: 0,
          legend: { display: true },
          onAnimationComplete: function () {
            this.showTooltip(this.segments, true);
          }
        }
      };
    }
  };

  const actions: AnalyticsActionTree = {
    /**
     * ...
     */
    async generatetoolsUsedForEvalsTableData() {},
    /**
     * ...
     */
    async computeForUsers({ rootState, commit }) {
      let { items: users } = rootState.users;

      let labels = [];
      let data = [];

      if (!users.length) {
        commit('SET_PROPS', {
          avgUsersPerInst: { labels, data: [data] },
          averageUsersPerInstitutionNumber: 0,
          usersIncrease: 0,
          usersPercentage: 0
        });

        return;
      }

      let growthTracker = new GrowthTracker();

      rootState.users.items.forEach((user) => {
        // user.roles.forEach(role => {
        //   if (!role.institution) {
        //     return;
        //   }
        //
        //   let i = labels.indexOf(role.institution.name);
        //   if (i >= 0) {
        //     data[i]++;
        //   } else {
        //     labels.push(role.institution.name);
        //     data.push(1);
        //   }
        // });

        growthTracker.addDate(user.createdAt);
      });

      var total = data.length ? data.reduce((accum, cur) => accum + cur) : 0;
      let { didIncrease, percentage } = growthTracker.compute();

      commit('SET_PROPS', {
        avgUsersPerInst: { labels, data: [data] },
        averageUsersPerInstitutionNumber: (total / data.length).toFixed(2),
        usersIncrease: didIncrease,
        usersPercentage: percentage
      });
    },
    /**
     * ...
     */
    async getStatsForEvaluations({ commit }) {
      let $$results: Partial<AnalyticsState> = {};

      try {
        let res = await $api.gearsManager.listEvaluationStats();
        $$results = res.data;
      } catch (err) {
        throw err;
      }

      $$results.allOpenEvaluations =
        $$results.evaluationsInProgress + $$results.evaluationsNotStarted;

      commit('SET_PROPS', $$results);
    },
    /**
     *
     */
    async getStatsForDashboard(
      { commit, rootGetters, dispatch },
      institutionId
    ) {
      let data;
      let promise: Promise<any> = Promise.resolve([]);

      commit('CLEAR');

      if (!institutionId && rootGetters.activeInstId)
        institutionId = rootGetters.activeInstId;

      let imCalled = false;

      if (hasAccess('gearsmanager:ListDashboardStats') && !institutionId) {
        promise = $api2.gm.listDashboardStats();
      } else if (
        hasAccess('institutionmanager:ListDashboardStats') &&
        institutionId
      ) {
        imCalled = true;
        promise = $api2.im.listDashboardStats({ institutionId });
      } else {
        // Basic Institution User
        await dispatch('evaluations/list', null, true);
        return;
      }

      commit('SET_PROPS', { loading: true });

      try {
        data = await promise;
      } catch (err) {
        commit('SET_PROPS', { loading: false });

        throw err;
      }

      // console.log('get dashboard stats data: ', data);
      const $$results: Partial<AnalyticsState> = {};

      // set totals
      const totals = find(data, { type: 'TOTALS' });

      if (!totals)
        console.warn(
          '[analytics-store-list-dashboard-stats] could not find TOTALS in returned object'
        );
      $$results.totalEvaluations = totals ? totals.totalEvaluations : 'N/A';
      if (!imCalled)
        $$results.totalInstitutions = totals ? totals.totalInstitutions : 'N/A';
      $$results.totalUsers = totals ? totals.totalUsers : 'N/A';
      $$results.totalClients = totals ? totals.totalClients : 'N/A';

      // calculate all evaluation type totals
      let evaluationsInMonths = filter(data, { type: 'EVALUATION' });
      if (!evaluationsInMonths) {
        console.warn(
          '[analytics-store-list-dashboard-stats] could not find EVALUATION MONTHS in returned object'
        );
      } else {
        $$results.evaluationsCompleted = 0;
        $$results.evaluationsInProgress = 0;
        $$results.evaluationsNotStarted = 0;
        $$results.allOpenEvaluations = 0;
        $$results.openEvaluations = 0;

        evaluationsInMonths.forEach((x) => {
          // create month/year object
          x.monthYear = new Date(x.year, x.monthIndex, 1);

          // add to evaluations counter
          if (x.details) {
            $$results.evaluationsCompleted += x.details['COMPLETED'];
            $$results.evaluationsInProgress += x.details['IN_PROGRESS'];
            $$results.evaluationsNotStarted += x.details['NOT_STARTED'];
            $$results.allOpenEvaluations += x.details['IN_PROGRESS'];
            $$results.allOpenEvaluations += x.details['NOT_STARTED'];
            $$results.openEvaluations += x.details['IN_PROGRESS'];
            $$results.openEvaluations += x.details['NOT_STARTED'];
          }
        });
        // sort months
        evaluationsInMonths.sort((x, y) => y.monthYear - x.monthYear);

        $$results.evaluationsPerMonth = evaluationsInMonths
          ? evaluationsInMonths.slice(0, 11)
          : [];
      }

      // calculate increases/decreases
      // clients increase/decrease
      const clientsData = computeIncreaseDecrease('CLIENT', data);
      if (clientsData) {
        $$results.clientsIncrease = clientsData.increase;
        $$results.clientsPercentage = clientsData.percentage;
        $$results.clientsLastUpdated = clientsData.lastUpdated;
        $$results.clientsNextUpdate = clientsData.nextUpdate;
      }

      // evaluations increase/decrease
      const evalData = computeIncreaseDecrease('EVALUATION', data);
      if (evalData) {
        $$results.evaluationsIncrease = evalData.increase;
        $$results.evaluationsPercentage = evalData.percentage;
        $$results.evaluationsLastUpdated = evalData.lastUpdated;
        $$results.evaluationsNextUpdate = evalData.nextUpdate;
      }

      // users increase/decrease
      const usersData = computeIncreaseDecrease('USER', data);
      if (usersData) {
        $$results.usersIncrease = usersData.increase;
        $$results.usersPercentage = usersData.percentage;
        $$results.usersLastUpdated = usersData.lastUpdated;
        $$results.usersNextUpdate = usersData.nextUpdate;
        if (usersData.activeUsers && usersData.inactiveUsers) {
          $$results.usersActive = usersData.activeUsers;
          $$results.usersInactive = usersData.inactiveUsers;
        }
      }

      // institutions increase/decrease
      if (!imCalled) {
        const accountData = computeIncreaseDecrease('ACCOUNT', data);
        if (accountData) {
          $$results.institutionsIncrease = accountData.increase;
          $$results.institutionsPercentage = accountData.percentage;
          $$results.institutionsLastUpdated = accountData.lastUpdated;
          $$results.institutionsNextUpdate = accountData.nextUpdate;
        }
      }

      commit('SET_PROPS', $$results);
      commit('SET_PROPS', { loading: false });
    },
    /**
     * ...
     */
    async computeForEvaluations({ rootState, commit }, params) {
      params = params ?? {
        tableData: true,
        elapsedTime: true,
        growth: true,
        client: null,
        user: null
      };

      let tableData = params.tableData ?? false;
      let elapsedTime = params.elapsedTime ?? false;
      let growth = params.growth ?? false;
      let client = params.client ?? null;
      let user = params.user ?? null;

      user = rootState.me;

      const $$results: Partial<AnalyticsState> = {};

      let timeTracker = elapsedTime ? new TimeTracker() : null;
      let tableProps = tableData ? new TableProps() : null;
      let growthTracker = growth ? new GrowthTracker() : null;

      let openCnt = 0;
      let openCntClient = 0;
      let openCntUser = 0;
      let completedCntUser = 0;

      for (const item of rootState.evaluations.items) {
        if (!item) continue;

        // Calculate elapsed time.
        if (timeTracker && !!item.elapsedTime) {
          timeTracker.addTime(item.elapsedTime);
        }

        if (tableProps && !!item.tool) {
          tableProps.addData(item.tool);
        }

        if (growthTracker) {
          growthTracker.addDate(item.createdAt);
        }

        item.status === 'COMPLETED' ? completedCntUser++ : openCnt++;

        if (user && item.evaluatorId === user.id) {
          if (item.status != 'COMPLETED') {
            openCntUser++;
          } else {
            completedCntUser++;
          }
        }

        if (
          client &&
          (item.status === 'IN_PROGRESS' || item.status === 'NOT_STARTED')
        ) {
          openCntClient++;

          // if (notificationNumber < 9) {
          //   notificationNumber++;
          // }
        }
      }

      if (timeTracker) {
        $$results.averageEvaluationElapsedTime = timeTracker.compute();
      }

      if (tableProps) {
        $$results.toolsUsedForEvals = tableProps.results();
      }

      if (growthTracker) {
        let { didIncrease, percentage } = growthTracker.compute();

        $$results.evaluationsIncrease = didIncrease;
        $$results.evaluationsPercentage = percentage;
      }

      $$results.allOpenEvaluations = openCnt;
      $$results.totalEvaluations = rootState.evaluations?.items?.length;

      if (client) {
        if (openCntClient > 0) {
          // rootState.commit('client/updateItem', {
          //   ref: client,
          //   props: { openEvaluation: true }
          // });
        }

        // this.notificationNumbers.setEvaluations(notificationNumber);
        $$results.openEvaluations = openCntClient;
      }

      if (user) {
        $$results.userOpenEvaluations = openCntUser;
        $$results.userCompletedEvaluations = completedCntUser;
        $$results.userEvaluationsComputed = true;
      }

      commit('SET_PROPS', $$results);
    },
    /**
     * ...
     */
    async computeForClients({ rootState, commit }) {
      let growthTracker = new GrowthTracker();

      rootState.clients.items.forEach((client) =>
        growthTracker.addDate(client.createdAt)
      );

      let { didIncrease, percentage } = growthTracker.compute();

      commit('SET_PROPS', {
        clientsIncrease: didIncrease,
        clientsPercentage: percentage
      });
    },
    /**
     * ...
     */
    async computeForInstitutions({ rootState, commit }) {
      let growthTracker = new GrowthTracker();

      rootState.institutions.items.forEach((inst) =>
        growthTracker.addDate(inst.createdAt)
      );

      let { didIncrease, percentage } = growthTracker.compute();

      commit('SET_PROPS', {
        institutionsIncrease: didIncrease,
        institutionsPercentage: percentage
      });
    },
    /**
     * ...
     */
    async computeUsersForInstitutions({ rootState, commit }) {
      var $$results = {
        usersActive: 0,
        usersInactive: 0
      };
      // console.log(rootState.users);

      rootState.users.items.forEach((user) => {
        if (user.active) {
          $$results.usersActive++;
        } else {
          $$results.usersInactive++;
        }
      });

      commit('SET_PROPS', $$results);
    },
    /**
     * ...
     */
    async computeAverages({ rootState, commit }) {
      let { institutions, users, evaluations } = rootState;

      var evalInsts = institutions.items.map((item) => ({
        id: item.id,
        cnt: 0
      }));

      var evalUsers = users.items.map((item) => ({
        id: item.id,
        cnt: 0
      }));

      for (let evl of evaluations.items) {
        for (let item of evalInsts) {
          if (evl.institutionId === item.id) {
            item.cnt++;
            break;
          }
        }

        for (let item of evalUsers) {
          if (evl.evaluatorId === item.id) {
            item.cnt++;
            break;
          }
        }
      }

      let evalUsersAvg = sum(evalUsers, (item) => item.cnt) / evalUsers.length;
      let evalInstAvg = sum(evalInsts, (item) => item.cnt) / evalInsts.length;

      commit('SET_PROPS', {
        averageEvaluationsPerUser: evalUsersAvg.toFixed(2),
        averageEvaluationsPerInstitution: evalInstAvg.toFixed(2)
      });
    }
  };

  const mutations: AnalyticsMutationTree = {
    SET_PROPS(state, props: Partial<AnalyticsState>) {
      for (const i in props) {
        if (i in state) state[i] = props[i];
      }
    },
    CLEAR(state) {
      for (const i in state) {
        if (typeof state[i] === 'number') state[i] = 0;
        if (typeof state[i] === 'string') state[i] = 'N/A';
        if (typeof state[i] === 'boolean') state[i] = false;
        if (Array.isArray(state[i])) state[i] = [];
      }
    }
  };

  // TEMP: remove once all references to them have been removed.
  Object.defineProperties(mutations, {
    setProps: { enumerable: true, value: mutations.SET_PROPS }
  });

  return { state, getters, actions, mutations };
}

/**
 * ...
 * @param arr ...
 * @param fn ...
 * @return ...
 */
function sum(arr: number[], fn = (v: number) => v) {
  if (!arr.length) return 0;

  return arr.reduce((accum, cur, i) => {
    let a = 0;
    let b = 0;

    if (i == 1) {
      a = fn(accum);
    }

    a = i == 1 ? fn(accum) : accum;

    b = fn(cur);

    return a + b;
  });
}

class TableProps {
  private labels: string[] = [];
  private data: number[] = [];

  /**
   * ...
   *
   * @param item
   * @return
   */
  addData(item: { name: string }) {
    let i = this.labels.indexOf(item.name);

    if (i >= 0) {
      this.data[i]++;
    } else {
      this.labels.push(item.name);
      this.data.push(1);
    }
  }

  /**
   * ...
   *
   * @return ...
   */
  results() {
    return { labels: this.labels, data: [this.data] };
  }
}

class TimeTracker {
  elapsedTimeTotal = 0;
  elapsedTimeDivider = 0;

  /**
   * ...
   *
   * @return
   */
  addTime(time: number) {
    this.elapsedTimeDivider += 1;
    this.elapsedTimeTotal += time;
  }

  /**
   * ...
   *
   * @return
   */
  compute() {
    return this.elapsedTimeDivider === 0
      ? 'N/A'
      : new Date(1970, 0, 1).setSeconds(
          Math.floor(this.elapsedTimeTotal / this.elapsedTimeDivider)
        );
  }
}

class GrowthTracker {
  createdLastMonth = 0;
  createdThisMonth = 0;
  currentMonth = new Date().getMonth();
  lastMonth: number = 0;
  // lastMonth = currentMonth === 1 ? 12 : currentMonth - 1;

  /**
   * ...
   *
   * @return
   */
  addDate(createdAt: string | number | Date) {
    var month = new Date(createdAt).getMonth();

    if (month === this.currentMonth) {
      this.createdThisMonth++;
    } else if (month === this.lastMonth) {
      this.createdLastMonth++;
    }

    this.lastMonth = this.currentMonth === 1 ? 12 : this.currentMonth - 1;
  }

  /**
   * ...
   *
   * @return
   */
  compute() {
    var didIncrease = false;
    var percentage = '--%';

    if (this.createdThisMonth === this.createdLastMonth) {
      didIncrease = false;
      percentage = '0%';
    } else {
      didIncrease = this.createdThisMonth > this.createdLastMonth;

      var decrease = Math.abs(
        ((this.createdLastMonth - this.createdThisMonth) /
          this.createdLastMonth) *
          100
      );

      if (decrease === Infinity) {
        decrease = 100;
      }

      percentage = `${decrease.toFixed(2)}%`;
    }

    return { didIncrease, percentage };
  }
}

const computeIncreaseDecrease = (
  statsType: string,
  data: Array<StatItem>[]
) => {
  const today = new Date();
  const thisMonth = today.getMonth();
  const thisYear = today.getFullYear();
  const previousMonth = thisMonth == 0 ? 11 : thisMonth - 1;
  const previousMonthYear = thisMonth == 11 ? thisYear - 1 : thisYear;

  const thisMonthData = find(data, {
    type: statsType,
    year: thisYear,
    monthIndex: thisMonth
  });

  if (!thisMonthData) {
    console.error(
      `[analytics-compute-increase-decrease] Could Not Find This Month Data for ${statsType}`
    );
    return;
  }
  if (!thisMonthData.total && thisMonthData.total !== 0) {
    console.error(
      `[analytics-compute-increase-decrease] No Total Found on This Month Data for ${statsType}`
    );
    return;
  }

  const lastMonthData = find(data, {
    type: statsType,
    year: previousMonthYear,
    monthIndex: previousMonth
  });

  if (!lastMonthData) {
    console.error(
      `[analytics-compute-increase-decrease] Could Not Find Last Month Data for ${statsType}`
    );
    return;
  }
  if (!lastMonthData.total && lastMonthData.total !== 0) {
    console.error(
      `[analytics-compute-increase-decrease] No Total Found on Last Month Data for ${statsType}`
    );
    return;
  }

  const thisMonthTotal = parseInt(thisMonthData.total, 10);

  const lastMonthTotal = parseInt(lastMonthData.total, 10);

  const increase = thisMonthTotal > lastMonthTotal;
  const percentage =
    thisMonthTotal - lastMonthTotal === 0
      ? 0
      : 100 *
        Math.abs(
          (thisMonthTotal - lastMonthTotal) /
            ((thisMonthTotal + lastMonthTotal) / 2)
        );

  // next update is in 6 hours from last update
  const lastUpdated = new Date(thisMonthData.updatedAt);
  const nextUpdate = new Date(thisMonthData.updatedAt).setHours(
    lastUpdated.getHours() + 6
  );

  // check current month's active/inactive users
  let activeUsers;
  let inactiveUsers;
  if (statsType === 'USER') {
    activeUsers = thisMonthData.details?.active;
    inactiveUsers = thisMonthData.details?.inactive;
  }

  return {
    increase,
    percentage: `${percentage.toFixed(0)}%`,
    lastUpdated,
    nextUpdate,
    activeUsers,
    inactiveUsers
  };
};

export default AnalyticsStore;
