import Auth from '@aws-amplify/auth';

function inUrl(...tests: string[]) {
  return tests.some((str) => window.location.href.includes(str));
}

async function customApiHeader() {
  const headers = {
    Pragma: 'no-cache',
    'Cache-Control': 'no-cache'
  };

  try {
    const { accessToken } = await Auth.currentSession();
    headers.Authorization = `Bearer ${accessToken.jwtToken}`;
  } catch (_) {
    // There isn't a user session to retrieve
    // a token from -- silently continue.
  }

  return headers;
}

const AuthEnvironmentConfigs = {
  dev: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_mNwsUE0bL',
    userPoolWebClientId: '4ma2pkereso19kgha1pvnh7r3b'
  },
  us: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_UyLWgEtBK',
    userPoolWebClientId: '6otikf02v33fm4hns9qk9vfiib'
  },
  au: {
    region: 'ap-southeast-2',
    userPoolId: 'ap-southeast-2_8AwfFJyCz',
    userPoolWebClientId: '1b2j40vmivn747nhrd5dhigf89'
  },
  uk: {
    region: 'eu-west-1',
    userPoolId: 'eu-west-1_2BE894dq1',
    userPoolWebClientId: '3or3cgq6ji7n93rhevup1fh5rj'
  }
};

const authConfig = inUrl('au.gifrgears.com')
  ? AuthEnvironmentConfigs.au
  : inUrl(
      '//gifrgears.com',
      '//www.gifrgears.com',
      'gifr-production.us-east',
      'hotfix.gifrgears.com'
    )
  ? AuthEnvironmentConfigs.us
  : inUrl(
      'dev.gifrgears.com',
      'develop.gifrgears.com',
      'staging.gifrgears.com',
      'localhost',
      'buildtest.gifrgears.com',
      'importer.gifrgears.com'
    )
  ? AuthEnvironmentConfigs.dev
  : inUrl('gifrgears.uk', 'ie.gifrgears.com')
  ? AuthEnvironmentConfigs.uk
  : null;

export const amplifyConfig = {
  Auth: authConfig,
  authenticationFlowType: 'CUSTOM_AUTH',
  API: {
    endpoints: [
      {
        name: 'gearsAPI',
        // endpoint: `${apiUrl}/`,
        endpoint: `${process.env.API_ORIGIN}/`,
        custom_header: customApiHeader
      }
    ]
  }
};
