import { angular, Ng } from '@angular';

import routeMap from './route-map';
import $requestWrapper from './request-wrapper';

type GearsManagerMethods = {
  //
};

type InstitutionManagerMethods = {
  createClientContact: (
    params: { clntId: string; instId: string },
    payload: any
  ) => Promise<any>;
  createClientTransferRequest: (
    params: { instId: string },
    payload: any
  ) => Promise<any>;
};

type ClientManagerMethods = {
  //
};

type IcptMethods = {
  //
};

type McptMethods = {
  //
};

type ToolCreatorMethods = {
  //
};

type UserMethods = {
  getGdprRequests(): unknown;
  updateMe(arg0: { phone_number: any }): unknown;
  gdprRequest(): unknown;
  expungeRequest(): unknown;
  //
};

type BaseMethods = {
  //
};

export interface ApiService {
  gearsManager: GearsManagerMethods;
  GM: GearsManagerMethods; // Alias
  institutionManager: InstitutionManagerMethods;
  IM: InstitutionManagerMethods; // Alias
  clientManager: ClientManagerMethods;
  CM: ClientManagerMethods; // Alias
  icpt: IcptMethods;
  ICPT: IcptMethods; // Alias
  mcpt: McptMethods;
  toolCreator: ToolCreatorMethods;
  TC: InstitutionManagerMethods; // Alias
  user: UserMethods;
  base: BaseMethods;
}

/**
 * ...
 */
export type IApiService = ApiService;

declare module 'angular' {
  namespace gears {
    type IApiService = ApiService;
  }
}

// declare module '@angular' {
//   interface Ng {
//     readonly $api: IApiService;
//   }
// }

class RoutePath {
  full: string;
  paramKeys: { key: string; pos: number }[] = [];

  private comps: string[] = [];

  constructor(value: string) {
    this.full = value;

    value.split('/').forEach((item, i) => {
      if (item[0] !== ':') return this.comps.push(item);

      this.paramKeys.push({ key: item.substr(1), pos: i });
      this.comps.push(undefined);
    });
  }

  compose(paramVals: unknown) {
    if (!this.paramKeys.length) {
      return this.full;
    }

    const route = [...this.comps];

    if (this.paramKeys.length === 1 && typeof paramVals !== 'object') {
      route[this.paramKeys[0].pos] = paramVals;
    } else {
      for (const i in this.paramKeys) {
        const map = this.paramKeys[i];

        route[map.pos] = paramVals[map.key];
      }
    }

    return route.join('/');
  }
}

function $ApiService(utils: any, $requestWrapper: typeof $requestWrapper) {
  'ngInject';

  function routeMethod({ type, pathBase, pathRel }) {
    const routeObj = new RoutePath(pathBase + pathRel);

    return function call(params, payload) {
      let route = 'api';

      if (!routeObj.paramKeys.length) {
        route += routeObj.full;
        payload = params;
      } else {
        route += routeObj.compose(params);
      }

      let body = {};

      if (type == 'post' || type == 'del') {
        body = payload;
      }

      return $requestWrapper(type, route, body);
    };
  }

  const $api: Record<string, any> = {};

  for (const groupKey in routeMap) {
    const routeGroup = {},
      groupConfig = routeMap[groupKey];

    for (const typeKey in groupConfig.methods) {
      const methodType = groupConfig.methods[typeKey];

      for (const nameKey in methodType) {
        const route = methodType[nameKey];

        routeGroup[nameKey] = routeMethod({
          name: nameKey,
          type: typeKey,
          pathBase: groupConfig.base,
          pathRel: route
        });
      }
    }

    $api[groupKey] = routeGroup;
  }

  // Abriviated aliases for GIFR service routes
  $api.GM = $api.gearsManager;
  $api.IM = $api.institutionManager;
  $api.CM = $api.clientManager;
  $api.ICPT = $api.icpt;
  $api.TC = $api.toolCreator;

  return $api;
}

export default angular
  .module('app.$api', [])
  .service('$requestWrapper', $requestWrapper)
  .service('$api', $ApiService).name;

// A generator function for creating a static api script
// function createStaticFunctions() {
//   let $api2 = `const $api2 = {\n`;
//
//   for (let groupKey in routeMap) {
//     var routeGroup = {},
//       groupConfig = routeMap[groupKey];
//
//     $api2 += `\t${groupKey}: {\n`;
//
//     for (let typeKey in groupConfig.methods) {
//       let methodType = groupConfig.methods[typeKey];
//
//       for (let nameKey in methodType) {
//         let route = methodType[nameKey];
//
//         $api2 += `
//           ${nameKey}(params) {
//             return $requestWrapper('${typeKey.toUpperCase()}', '${groupConfig.base +
//           route}', params.body);
//           },
//         `;
//       }
//     }
//
//     $api2 += `\t},\n`;
//   }
//
//   $api2 += '};';
//
//   console.log($api2);
// }

// import './route-converter';
