import angular from 'angular';
import { filter, find, forEach, indexOf, maxBy } from 'lodash';
import { ChartOptions } from 'chart.js';

import { OffenderHistory } from '@interfaces/offender-history';
import { ToolData, CustomRiskCategory } from '@interfaces/tool';
import { ClientModel } from '@models/client.model';

export class LoadToolService {
  constructor(
    // private readonly $http: angular.IHttpService,
    private readonly $api2: angular.gears.IAPI2Service,
    // private readonly notify: angular.gears.INotifyService,
    private readonly getToolJson: unknown,
    private readonly getItems: angular.gears.IGetItemsService
  ) {
    'ngInject';
  }

  /**
   * ..
   *
   * @param tool ...
   * @param client ...
   * @return ...
   */
  async load(tool: ToolData, client?: ClientModel) {
    if (client && tool.offenderHistory) {
      if (client.offenderHistory && !client.offenderHistory.length) {
        client.offenderHistory = await this.getItems.clientOffenderHistory(
          client
        );
      }

      let toolOffenderHistories = filter(client.offenderHistory, {
        toolId: tool.id
      });

      let latestOffenderHistory = maxBy(toolOffenderHistories, 'updatedAt');

      const oh = await this.$api2.cm.getOffenderHistory({
        institutionId: client.institutionId!,
        subGroupId: client.subGroup!.id,
        clientId: client.id,
        historyId: latestOffenderHistory.id
      });

      // let evaluationOffenderHistory = await this.$http.get<OffenderHistory>(
      //   `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup?.id}/clients/${client.id}/history/${latestOffenderHistory.id}`
      // );

      if (tool.id === 120 && oh) {
        // for YLS/CMI, find client's normative type from offender history
        let clientNormativeField: OffenderHistory.Field | null = null;

        for (const { sections } of oh.historyData.sections) {
          for (const { fields } of sections) {
            for (const field of fields) {
              if (field.key === 'clientNormativeType') {
                clientNormativeField = field;
              }
            }
          }
        }

        client.clientNormativeType = clientNormativeField
          ? clientNormativeField.model
          : null;
      }
    }

    if (tool.customRiskCategories && tool.customRiskCategories.length) {
      tool.customRiskCategories = checkCustomRiskCategories(
        tool.customRiskCategories,
        client
      );

      const crc = find(tool.customRiskCategories, { criteriaMatch: true });

      if (crc) {
        tool.riskCategories = crc.categories;
      }
    }

    parseCodingFormItems(tool);

    //region Child Tools

    if (tool.childTools && tool.childTools.length) {
      console.log('Grabbing child tools...');

      tool.childTools = await this.getChildTools(tool.childTools, client);
      // set first children's parentTool as the rootTool
      forEach(tool.childTools, (ct) => {
        ct.parentTool = tool;
      });

      buildBarGraphs(tool);
    }

    //endregion Child Tools

    return tool;
  }

  /**
   * ...
   *
   * @param toolId
   * @param toolAddress
   * @param client
   * @return
   */
  private async generateToolJson(
    toolId: number | string,
    toolAddress: string,
    client: Client
  ) {
    toolId = typeof toolId === 'number' ? toolId : parseInt(toolId);

    if (isNaN(toolId)) {
      throw new Error('Tool ID must be a number.');
    }

    const commits = await this.$api2.tc.listToolCommits({ toolId });

    const chosenCommit =
      find(commits, { status: 'Live' }) ?? maxBy(commits, 'createdAt');

    if (!chosenCommit) {
      throw new Error('Failed to retrieve child tool commit.');
    }

    chosenCommit.toolId = toolId;

    const toolJson: ToolData = await this.getToolJson.toolJson(chosenCommit);

    // Add the following to toolJson to manage for performing evaluation
    toolJson.address = toolAddress;
    toolJson.answeredCount = 0;
    toolJson.unansweredCount = 0;
    toolJson.omitted = 0;
    toolJson.valid = false;
    toolJson.commitId = chosenCommit;

    // create addresses for child coding form items
    parseCodingFormItems(toolJson);

    // check for customRiskCategories and find matching criteria to set in toolJson.riskCategories
    if (toolJson.customRiskCategories && toolJson.customRiskCategories.length) {
      toolJson.customRiskCategories = checkCustomRiskCategories(
        toolJson.customRiskCategories,
        client
      );

      const riskCatogory = find(toolJson.customRiskCategories, {
        criteriaMatch: true
      });
      toolJson.riskCategories =
        riskCatogory?.categories ?? toolJson.riskCategories;
    }

    // Keep track of the original max highest score in case we need
    // to revert for omitting and affecting highest risk category

    const rc = maxBy(toolJson.riskCategories, 'high');

    if (rc) {
      rc.originalHigh = rc.high;

      toolJson.progressBar = {
        type: 'warning',
        max: toolJson.riskCategories?.length ?? 0,
        value: 0
      };
    }

    await Promise.all(
      (toolJson.childTools ?? []).map(async (ct, index) => {
        const data = await this.generateToolJson(
          ct.id,
          `${toolJson.id}>${ct.id}>`,
          client
        );

        // set first sub item to active
        if (index === 0) data.active = true;

        // create coding form item addresses
        data.address = toolJson.address
          ? `${toolJson.address}${data.id}>`
          : `${data.id}`;

        for (const cfi of data.codingFormItems) {
          cfi.longAddress = `${data.address}${cfi.id}`;

          (cfi.sources ?? []).forEach((source, i, sources) => {
            if (typeof source === 'string') {
              sources[i] = { value: source, label: source };
            }
          });
        }

        toolJson.childTools[index] = data;
      })
    );

    return toolJson;
  }

  /**
   * ...
   *
   * @param childTools ...
   * @return ...
   */
  private async getChildTools(childTools: number[], client: ClientModel) {
    // Copy childTools to maintain array order once we get tool data back
    // let ctArray = angular.copy(childTools);

    const ctArray: ToolData[] = [];

    // const promises = childTools.map(
    //   (id) =>
    //     new Promise<void>((resolve, reject) =>
    //       this.generateToolJson(id, `${id}>`, client, (data, err) => {
    //         if (err) {
    //           this.notify.error(err);
    //
    //           reject();
    //         } else if (data) {
    //           // Insert data at array location
    //           ctArray[indexOf(childTools, data.id)] = data;
    //           resolve();
    //         }
    //       })
    //     )
    // );

    await Promise.all(
      childTools.map(async (id) => {
        const data = await this.generateToolJson(id, `${id}>`, client);

        ctArray[indexOf(childTools, data.id)] = data;
      })
    );

    // Set first array item as active
    ctArray[0].active = true;

    return ctArray;
  }
}

/**
 * ...
 *
 * @param toolJson ...
 */
function parseCodingFormItems(toolJson: ToolData) {
  for (const cfi of toolJson.codingFormItems) {
    // NOTE: perhaps this can be removed once better type awareness has been
    // established.
    const page = cfi.pdfItemPage as any;

    if (cfi.pdfItemHelpBookmark && cfi.pdfItemHelpBookmark !== '') {
      if (page && page !== '') {
        if (parseInt(page)) {
          cfi.pdfBookmarkPage = page;
        } else {
          console.error('PDF Item Page is Not a Number');
        }
      }
    } else if (page) {
      cfi.pdfBookmarkPage = page;
    }

    cfi.longAddress = `${toolJson.address}${cfi.id}`;

    // Add blank options to answers array.
    cfi.codesAndScore.unshift({ id: '-', score: '-', text: '-' });
  }
}

/**
 * If we have customRiskCategories, find the matching criteria and set to the
 * tool's riskCategories array.
 *
 * @param arr ...
 * @return ...
 */
function checkCustomRiskCategories(
  arr: CustomRiskCategory[],
  client: ClientModel
) {
  for (const crc of arr) {
    if (!crc.criteria) continue;

    let criteriaMatch = true;

    for (const [key, value] of Object.entries(crc.criteria)) {
      switch (key) {
        case 'sex':
          if (value !== client.sex) criteriaMatch = false;
          break;
        case 'clientNormativeType':
          if (value !== client.clientNormativeType) criteriaMatch = false;
          break;
      }
    }

    crc.criteriaMatch = criteriaMatch;
  }

  return arr;
}

/**
 * ...
 *
 * @param tool ...
 * @return ...
 */
function buildBarGraphs(tool: ToolData) {
  for (const ct of tool.childTools) {
    if (!ct.childTools?.length) continue;

    // create barGraph components here
    // check if all riskCategories are the same for childTools
    let allRiskCategoriesSame = true;
    let masterRiskCategories: string[] = [];
    let masterRiskCategoriesOriginal: string[] = [];
    let currentRiskCategories: string[] = [];

    ct.childTools.forEach((ctct, index) => {
      currentRiskCategories = [];

      for (const rc of ctct.riskCategories ?? []) {
        if (index === 0) {
          masterRiskCategories.push(rc.name);
          masterRiskCategoriesOriginal = angular.copy(masterRiskCategories);
          currentRiskCategories = masterRiskCategories;
        } else {
          currentRiskCategories.push(rc.name);
        }
      }
    });

    const chartOptions: ChartOptions = {
      gridLines: {
        display: false
      },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Sub Component'
            }
          }
        ],
        yAxes: [
          {
            ticks: {
              suggestedMax: 0,
              beginAtZero: true,
              stepSize: 1,
              callback(label) {
                if (allRiskCategoriesSame) {
                  switch (label) {
                    case 0:
                      return ' ';
                    default:
                      return masterRiskCategoriesOriginal[label - 1];
                  }
                } else {
                  return label;
                }
              }
            },
            scaleLabel: {
              display: true,
              labelString: 'Risk/Need Level'
            }
          }
        ]
      },
      toolTipEvents: [],
      showTooltips: true,
      tooltipCaretSize: 0,
      onAnimationComplete: function () {
        this.showTooltip(this.segments, true);
      }
    };

    ct.bar = {
      data: [],
      labels: [],
      series: [],
      options: chartOptions,
      colours: { fontColor: '#3a3a3a' }
    };

    let maxRiskCategoryLength = 0;

    for (const ctct of ct.childTools) {
      if (
        ctct.riskCategories &&
        ctct.riskCategories.length > maxRiskCategoryLength
      ) {
        ct.bar.options.scales.yAxes[0].ticks.suggestedMax =
          ctct.riskCategories.length;
        maxRiskCategoryLength = ctct.riskCategories.length;
      }

      // x-axis labels for the bar graph
      ct.bar.labels.push(ctct.flyoutName ? ctct.flyoutName : ctct.name);
      // push data to get the bars initialized
      ct.bar.data.push(0);
    }
  }
}
