import 'jquery-ui-bundle';

import ngAnimate from 'angular-animate';
import ngCookies from 'angular-cookies';
import ngResource from 'angular-resource';
import ngSanitize from 'angular-sanitize';
import uiRouter from 'angular-ui-router';
import uiBootstrap from 'angular-ui-bootstrap';
import uiNotification from 'angular-ui-notification';
import vcRecaptcha from 'angular-recaptcha';
import ngFileUpload from 'ng-file-upload';
import ngMap from 'ngmap';
import angularChartJs from 'angular-chart.js';
// GEARS angular modules.
import ngPrototype from 'angular-prototype';
import ngStore from 'angular-store';
import ngTrust from 'ng-trust';
import fileViewer from 'file-viewer';
import lister from 'lister';

// App-wide styles.
import './base.scss';
import './deps.scss';
import './app.scss';
import './spacing.scss';
import './to-sort.scss';

// Legacy/un-migrated angular modules.
import modules_legacy from '@/__modules';
import components_legacy from '@/__components';
import directives_legacy from '@/__directives';

// Core app angular modules.
import { api } from '@api';
import { services } from '@services';
import { components } from '@components';
import { directives } from '@directives';
import { filters } from '@filters';
import { modals } from '@modals';

const THIRD_PARTY_DEPENDENCIES = [
  ngAnimate,
  ngCookies,
  ngResource,
  ngSanitize,
  uiRouter,
  uiBootstrap,
  uiNotification as string,
  ngFileUpload,
  ngMap,
  vcRecaptcha,
  angularChartJs as string
];

/**
 * Raw record of all local angular dependencies as they were provided.
 */
export const source = {
  api: [api],
  modules: [ngPrototype, ngStore, ngTrust, fileViewer, lister],
  services,
  components,
  directives,
  filters,
  modals,
  // Legacy
  modules_legacy,
  components_legacy,
  directives_legacy
};

const local = Object.entries(source).flatMap(([category, values]) => {
  const names: string[] = [];

  for (const value of values) {
    if (typeof value === 'string') {
      names.push(value);
    } else {
      // eslint-disable-next-line no-console
      console.warn(
        `[app.dependencies] an invalid angular dependency in "${category}" name found.`,
        value
      );
    }
  }

  return names;
});

/**
 * Full list of angular dependencies.
 */
export const list = [...THIRD_PARTY_DEPENDENCIES, ...local];
