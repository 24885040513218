import angular from 'angular';

declare module 'angular' {
  namespace gears {
    type IIdleMonitorService = IdleMonitorService;
  }
}

export class IdleMonitorService {
  private idleTime = 0;
  private userIdle = false;
  private intervalPeriod = 60000; // 1 minute
  private intervalThreshold = 3; // 4 minutes
  private intervalId: angular.IPromise<unknown> | null = null;

  constructor(
    private readonly $document: angular.IDocumentService,
    private readonly $interval: angular.IIntervalService
  ) {
    'ngInject';
  }

  /**
   * ...
   */
  get isIdle() {
    return this.userIdle;
  }

  /**
   * Start the monitoring for user idling.
   */
  start() {
    this.$document.on('mousemove', this.onActivity);
    this.$document.on('keypress', this.onActivity);

    this.intervalId = this.$interval(this.onInterval, this.intervalPeriod);
  }

  /**
   * ...
   */
  end() {
    if (!this.intervalId) return;

    this.$interval.cancel(this.intervalId);
    this.intervalId = null;
  }

  /**
   * ...
   */
  private onActivity = () => {
    this.idleTime = 0;
  };

  /**
   * ...
   */
  private onInterval = () => {
    this.userIdle = ++this.idleTime > this.intervalThreshold;
  };
}

export default angular
  .module('app.idleMonitor', [])
  .service('idleMonitor', IdleMonitorService).name;
