import { Ng, Component, Prop } from '@angular';

@Component({
  name: 'MailTo',
  template: require('./mail-to.html'),
  transclude: true
})
class MailTo extends Ng {
  @Prop() email: string;

  get href() {
    return `mailto:${this.email}`;
  }
}

export default MailTo.$module;
