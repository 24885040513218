import angular from 'angular';

import './viewer-csv.scss';

export default angular
  .module('fileViewer.csvViewer', [])
  .directive('csvViewer', $http => {
    'ngInject';

    return {
      require: '^^fileViewer',
      replace: true,
      template: require('./viewer-csv.html'),
      scope: true,
      async link(scope, element, attrs, viewerCtrl) {
        scope.csvCols = [];
        scope.csvRows = [];
        scope.loading = true;

        // const { data } = $http({
        //   method: 'get',
        //   url: viewerCtrl.filePath,
        //   responseType: 'text'
        // });

        const res = await $http({
          method: 'get',
          url: viewerCtrl.filePath,
          responseType: 'text'
        });

        const data = res?.data;

        const [header, ...rows] = data.split('\n');

        scope.csvCols = header
          .split(',')
          .map((label, index) => ({ label, index }));

        scope.csvRows = rows
          .filter(line => line)
          .map(line => {
            const vals = line.split(',');

            const row = {};

            for (let col of scope.csvCols) {
              row[col.label] = vals[col.index];
            }

            return row;
          });

        scope.loading = false;
      }
    };
  }).name;
