export interface SyntaxScope {
  color: string | null;
  children?: Record<string, SyntaxScope>;
}

/**
 * ...
 */
export const SYNTAX_COLORS: Record<string, SyntaxScope> = {
  comment: { color: '#5c6370', children: {} },
  entity: {
    color: null,
    children: {
      name: {
        color: null,
        children: {
          type: {
            color: '#e5c07b',
            children: { class: { color: '#e5c07b', children: {} } }
          },
          function: { color: '#61afef', children: {} },
          class: { color: '#e5c07b', children: {} },
          section: { color: '#61afef', children: {} },
          tag: { color: '#e06c75', children: {} }
        }
      },
      other: {
        color: null,
        children: {
          'inherited-class': { color: '#98c379', children: {} },
          'attribute-name': {
            color: '#d19a66',
            children: { id: { color: '#61afef', children: {} } }
          }
        }
      }
    }
  },
  keyword: {
    color: '#c678dd',
    children: {
      control: { color: '#c678dd', children: {} },
      operator: { color: '#abb2bf', children: {} },
      other: {
        color: null,
        children: {
          'special-method': { color: '#61afef', children: {} },
          unit: { color: '#d19a66', children: {} }
        }
      }
    }
  },
  storage: {
    color: '#c678dd',
    children: {
      type: {
        color: null,
        children: {
          annotation: { color: '#c678dd', children: {} },
          primitive: { color: '#c678dd', children: {} }
        }
      },
      modifier: {
        color: null,
        children: {
          package: { color: '#abb2bf', children: {} },
          import: { color: '#abb2bf', children: {} }
        }
      }
    }
  },
  constant: {
    color: '#d19a66',
    children: {
      variable: { color: '#d19a66', children: {} },
      character: {
        color: null,
        children: { escape: { color: '#56b6c2', children: {} } }
      },
      numeric: { color: '#d19a66', children: {} },
      other: {
        color: null,
        children: {
          color: { color: '#56b6c2', children: {} },
          symbol: { color: '#56b6c2', children: {} }
        }
      }
    }
  },
  variable: {
    color: '#e06c75',
    children: {
      interpolation: { color: '#be5046', children: {} },
      parameter: { color: '#abb2bf', children: {} }
    }
  },
  string: {
    color: '#98c379',
    children: {
      regexp: { color: '#56b6c2', children: {} },
      other: {
        color: null,
        children: { link: { color: '#e06c75', children: {} } }
      }
    }
  },
  punctuation: {
    color: null,
    children: {
      definition: {
        color: null,
        children: {
          comment: { color: '#5c6370', children: {} },
          'method-parameters': { color: '#abb2bf', children: {} },
          'function-parameters': { color: '#abb2bf', children: {} },
          parameters: { color: '#abb2bf', children: {} },
          separator: { color: '#abb2bf', children: {} },
          seperator: { color: '#abb2bf', children: {} },
          array: { color: '#abb2bf', children: {} },
          heading: { color: '#61afef', children: {} },
          identity: { color: '#61afef', children: {} },
          bold: { color: '#e5c07b', children: {} },
          italic: { color: '#c678dd', children: {} }
        }
      },
      section: {
        color: null,
        children: {
          embedded: { color: '#be5046', children: {} },
          method: { color: '#abb2bf', children: {} },
          class: { color: '#abb2bf', children: {} },
          'inner-class': { color: '#abb2bf', children: {} }
        }
      }
    }
  },
  support: {
    color: null,
    children: {
      class: { color: '#e5c07b', children: {} },
      type: { color: '#56b6c2', children: {} },
      function: {
        color: '#56b6c2',
        children: { 'any-method': { color: '#61afef', children: {} } }
      }
    }
  },
  meta: {
    color: null,
    children: {
      class: {
        color: '#e5c07b',
        children: { body: { color: '#abb2bf', children: {} } }
      },
      'method-call': { color: '#abb2bf', children: {} },
      method: { color: '#abb2bf', children: {} },
      definition: {
        color: null,
        children: { variable: { color: '#e06c75', children: {} } }
      },
      link: { color: '#d19a66', children: {} },
      require: { color: '#61afef', children: {} },
      selector: { color: '#c678dd', children: {} },
      separator: { color: '#abb2bf', children: {} },
      tag: { color: '#abb2bf', children: {} }
    }
  },
  none: { color: '#abb2bf', children: {} },
  invalid: {
    color: null,
    children: {
      deprecated: { color: '#523d14 !important', children: {} },
      illegal: { color: 'white !important', children: {} }
    }
  },
  markup: {
    color: null,
    children: {
      bold: { color: '#d19a66', children: {} },
      changed: { color: '#c678dd', children: {} },
      deleted: { color: '#e06c75', children: {} },
      italic: { color: '#c678dd', children: {} },
      heading: { color: '#e06c75', children: {} },
      link: { color: '#56b6c2', children: {} },
      inserted: { color: '#98c379', children: {} },
      quote: { color: '#d19a66', children: {} },
      raw: { color: '#98c379', children: {} }
    }
  }
};
