import angular from 'angular';

import listerView from './lister-view/lister-view.component';
import listerViewList from './lister-view-list/lister-view-list.component';
import listerViewListItem from './lister-view-list-item/lister-view-list-item.component';
import listerViewSearchList from './lister-view-search-list/lister-view-search-list.component';

export * from './types';

export default angular.module('lister', [
  listerView,
  listerViewList,
  listerViewListItem,
  listerViewSearchList
]).name;
