'use strict';
const angular = require('angular');
import { findIndex, sortBy, forEach, indexOf } from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { Getter } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

@Controller
class SelectEvaluationModalController extends Modal {
  @Inject $scope;
  @Inject $store;
  @Inject SelectionTable;
  @Inject $filter;
  @Inject notify;
  @Getter('clients/getById') getClient;

  $setup() {
    // var parent = $scope.$parent;
    this.evals = angular.copy(this.$props.evals);
    this.evals = sortBy(this.evals, ['createdAt']).reverse();
    var filters = this.$props.filters;
    this.multiSelect = this.$props.multiSelect;
    /**
     * POSSIBLE FILTERS
     * status: number
     * toolHasCasePlan: object with array of compatible tools
     * toolUsed: id of toolUsed
     */

    this.selectedEval = null;
    this.selectedEvals = [];

    var evalStatuses = {
      0: 'Not Started',
      1: 'In Progress',
      2: 'Completed'
    };

    let dataItems = [];
    let tableColumnConfigs;
    forEach(this.evals, (evaluation) => {
      if (!evaluation.toolUsed && evaluation.tool)
        evaluation.toolUsed = evaluation.tool.id;

      if (filters) {
        if (
          filters.hasOwnProperty('status') &&
          evaluation.status !== filters.status
        )
          return;

        if (
          filters.toolHasCasePlan &&
          findIndex(filters.toolHasCasePlan.tools, {
            id: parseInt(evaluation.toolUsed, 10)
          }) < 0
        )
          return;

        if (filters.toolUsed && evaluation.toolUsed !== filters.toolUsed)
          return;
      }

      let evaluatorName = evaluation.evaluator
        ? `${evaluation.evaluator.fName} ${evaluation.evaluator.lName}`
        : null;
      evaluation.toolName = evaluation.tool
        ? evaluation.tool.name
        : 'NO TOOL FOUND';
      evaluation.evaluatorName = evaluatorName
        ? evaluatorName
        : 'NO EVALUATOR FOUND';
      evaluation.updatedAt = this.$filter('dynamicDate')(
        evaluation.updatedAt,
        'MM/dd/yyyy hh:mm a'
      );
      evaluation.statusName = evalStatuses[evaluation.status];
      dataItems.push(evaluation);
    });

    if (!dataItems?.length) {
      this.notify.display('No Evaluations Left After Filter', 'warning');
      this.$close();
      return;
    }

    let client = this.getClient(this.evals[0]?.clientId);

    let clientName = client.name();

    this.table = new this.SelectionTable({
      title: clientName,
      items: dataItems,
      itemsPerPage: 10,
      allowMultiple: this.multiSelect,
      onSelection: (selection) => {
        if (this.multiSelect) {
          if (indexOf(this.selectedEvals, selection) >= 0) {
            this.selectedEvals.splice(
              indexOf(this.selectedEvals, selection),
              1
            );
          } else {
            this.selectedEvals.push(selection);
          }
        } else {
          this.selectedEval = selection;
        }
      }
    })
      .column({
        title: 'Date',
        key: 'updatedAt'
      })
      .column({
        title: 'Status',
        key: 'status'
      })
      .column({
        title: 'Tool Used',
        key: 'toolName'
      })
      .column({
        title: 'Evaluator',
        key: 'evaluatorName'
      });
  }

  submit() {
    this.$close(this.multiSelect ? this.selectedEvals : this.selectedEval);
  }
}

/*@ngInject*/
// export function selectEvaluationModalController(
//   $scope,
//   SelectionTable,
//   $filter
// ) {
//   var parent = $scope.$parent;
//   this.evals = angular.copy(parent.modal.item.evals);
//   this.evals = _.sortBy(this.evals, ['createdAt']).reverse();
//   var filters = parent.modal.item.filters;
//   /**
//    * POSSIBLE FILTERS
//    * status: number
//    * toolHasCasePlan: object with array of compatible tools
//    */
//
//   this.selectedEval = null;
//
//   var evalStatuses = {
//     0: 'Not Started',
//     1: 'In Progress',
//     2: 'Completed'
//   };
//
//   let dataItems = [];
//   let tableColumnConfigs;
//   _.forEach(this.evals, evaluation => {
//     if (filters) {
//       if (filters.hasOwnProperty('status')) {
//         if (evaluation.status !== filters.status) return;
//       }
//       if (filters.toolHasCasePlan) {
//         if (
//           _.findIndex(filters.toolHasCasePlan.tools, {
//             id: evaluation.toolUsed
//           }) < 0
//         )
//           return;
//       }
//     }
//     evaluation.toolName = evaluation.tool
//       ? evaluation.tool.name
//       : 'NO TOOL FOUND';
//     evaluation.evaluatorName = evaluation.evaluator
//       ? evaluation.evaluator.fullName
//       : 'NO EVALUATOR FOUND';
//     evaluation.updatedAt = $filter('dynamicDate')(
//       evaluation.updatedAt,
//       'MM/dd/yyyy hh:mm a'
//     );
//     evaluation.statusName = evalStatuses[evaluation.status];
//     dataItems.push(evaluation);
//   });
//
//   let clientName = this.evals[0].client.fName;
//   if (this.evals[0].client.mName && this.evals[0].client.mName.length)
//     clientName = `${clientName} ${this.evals[0].client.mName}`;
//   clientName = `${clientName} ${this.evals[0].client.lName}`;
//
//   this.table = new SelectionTable({
//     title: clientName,
//     items: dataItems,
//     itemsPerPage: 10,
//     onSelection: selection => {
//       this.selectedEval = selection;
//     }
//   })
//     .column({
//       title: 'Date',
//       key: 'updatedAt'
//     })
//     .column({
//       title: 'Status',
//       key: 'status'
//     })
//     .column({
//       title: 'Tool Used',
//       key: 'toolName'
//     })
//     .column({
//       title: 'Evaluator',
//       key: 'evaluatorName'
//     });
//
//   this.submit = function() {
//     parent.$close(this.selectedEval);
//   };
// }

export default angular
  .module('app.selectEvaluationModal', [])
  .directive('selectEvaluationModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./select-evaluation.html'),
    controller: SelectEvaluationModalController,
    controllerAs: 'vm'
  })).name;
