'use strict';

import angular from 'angular';

import { Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ChooseClientModalController extends Modal {
  client: {};

  @Inject $scope;
  @Inject $store;
  @Inject utils;

  @Action('clients/list') listClients;

  get clients() {
    return this.$store.state.clients.items;
  }

  get loading() {
    return this.$store.state.clients.loading;
  }

  async $setup() {
    // this.clients = this.$props;
    if (!this.clients.length) await this.listClients();
    this.clientNameFunctionExists = this.clients[0].name();
    //
    this.client = {};
  }

  chooseClient1(form) {
    if (form.$valid) {
      this.$close(this.client);
    }
  }
}

export default angular
  .module('app.chooseClientModal', [])
  .directive('chooseClientModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./choose-client.html'),
    controller: ChooseClientModalController,
    controllerAs: 'vm'
  })).name;
