'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class InputRadioTabsComponent {
  tabWidth: number;

  @Inject $scope;
  @Inject $element;

  $onInit() {
    // Validate tab structure
    if (!this.tabs || !Array.isArray(this.tabs)) {
      throw new Error(
        '[inputRadioTabs] The value provided for the "tabs" paramater was not valid.'
      );
    }

    let matchingVal = false;

    for (let tab of this.tabs) {
      if (tab.value === this.ngModel) {
        matchingVal = true;
        break;
      }
    }

    if (!matchingVal) {
      this.ngModel = this.tabs[0].value;
    }
  }

  setWidth(w) {
    if (w > this.tabWidth) {
      this.tabWidth = w;
    }
  }
}

export default angular
  .module('app.inputRadioTabs', [])
  .directive('inputRadioTabs', () => {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        tabs: '<',
        ngModel: '='
      },
      bindToController: true,
      template: require('./input-radio-tabs.html'),
      controller: InputRadioTabsComponent,
      controllerAs: 'vm'
    };
  })
  .directive('radioTab', () => {
    return {
      restrict: 'A',
      require: '^inputRadioTabs',
      link(acope, element, attrs, parentCtrl) {
        let w = element.innerWidth();

        if (!parentCtrl.tabWidth || w > parentCtrl.tabWidth) {
          parentCtrl.tabWidth = w;
        }
      }
    };
  }).name;
