// region Math

declare global {
  interface Math {
    /**
     * Returns the value clamped to the inclusive range of lower and upper.
     *
     * @param x The value to be clamped.
     * @param lower The lower bound of the result.
     * @param upper The upper bound of the result.
     */
    clamp(x: number, lower: number, upper: number): number;

    /**
     * Returns the value looped, so that it is never larger than length and
     * never smaller than 0.
     *
     * @param x The value to be looped.
     * @param length The maximum value the result can be before being looped.
     */
    repeat(x: number, length: number): number;
  }
}

Math.clamp = function clamp(x, lower, upper) {
  return Math.min(upper, Math.max(lower, x));
};

Math.repeat = function repeat(t, length) {
  return Math.clamp(t - Math.floor(t / length) * length, 0, length);
};

// endregion Math

// region Array

declare global {
  interface Array<T> {
    /**
     * Removes elements from an array where predicate is true, returning the
     * original array.
     *
     * @param predicate A function that accepts up to three arguments. The
     * remove method calls the predicate function one time for each element in
     * the array.
     */
    remove(predicate: (value: T, index: number, array: T[]) => unknown): T[];
  }
}

function remove<T>(
  this: Array<T>,
  predicate: (value: T, index: number, array: T[]) => T[]
) {
  let results: T[] = [];

  for (let i = 0; i < this.length; i++) {
    if (predicate(this[i], i, this)) {
      results = results.concat(this.splice(i--, 1));
    }
  }

  return results;
}

Object.defineProperty(Array, 'remove', { value: remove });

// endregion Array

export {};
