import angular from 'angular';

import { AclService } from './acl.service';
import { PolicyTranslatorService } from './policy-translator.service';
import { translationTest } from './translation-test';

declare module 'angular' {
  namespace gears {
    type IAclService = ReturnType<typeof AclService>;
    type IPolicyTranslatorService = PolicyTranslatorService;
  }
}

export default angular
  .module('app.acl', [])
  .service('$acl', AclService)
  .service('policyTranslator', PolicyTranslatorService)
  .run(translationTest).name;
