'use strict';

import angular from 'angular';
import { forEach } from 'lodash';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class LscmiComparativeReportChoicesModalController extends Modal {
  $setup() {
    this.evaluations = this.$props.evaluations;
    this.options = [
      {
        text: 'Introduction',
        selected: true
      },
      {
        text: 'LS/CMI General Risk/Need Total Score',
        selected: true
      },
      {
        text: 'General Risk/Need Assessment Based on Total Score',
        selected: true
      },
      {
        text: 'General Risk/Need Subcomponent Scores',
        selected: true
      },
      {
        text: 'General Risk/Need Subcomponent Areas of Strength',
        selected: true
      },
      {
        text: 'Item Response Table',
        selected: true
      },
      {
        text: 'Additional Item Information',
        selected: true
      },
      {
        text: 'Summary of Omitted Responses',
        selected: true
      },
      {
        text: 'Section 2: Specific Risk/Need Factors',
        selected: true,
        includeEvals: []
      },
      {
        text: 'Section 3: Prison Experience - Institutional Factors',
        selected: true
      },
      {
        text: 'Section 4: Other Client Issues (Social, Health, and Mental Health)',
        selected: true,
        includeEvals: []
      },
      {
        text: 'Section 5: Special Responsivity Considerations',
        selected: true,
        includeEvals: []
      },
      {
        text: 'Section 6: Risk/Need Summary Override',
        selected: true,
        includeEvals: []
      },
      {
        text: 'Section 7: Program/Placement Decision',
        selected: true,
        includeEvals: []
      }
    ];
    forEach(this.options, (opt) => {
      if (opt.hasOwnProperty('includeEvals')) {
        forEach(this.evaluations, (e) => {
          opt.includeEvals.push(e.id);
        });
      }
    });
  }

  evalChoiceClicked(e, arr) {
    if (arr.indexOf(e.id) < 0) {
      arr.push(e.id);
    } else {
      arr.splice(e.id, 1);
    }
  }

  submit() {
    this.$close(this.options);
  }
}

export default angular
  .module('app.lscmiComparativeReportChoicesModal', [])
  .directive('lscmiComparativeReportChoicesModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./lscmi-comparative-report-choices.html'),
    controller: LscmiComparativeReportChoicesModalController,
    controllerAs: 'vm'
  })).name;
