'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ConfirmClientModalController extends Modal {
  client: any;
  prevFcn: any;

  @Inject $scope;

  $setup() {
    this.client = this.$props.client;
    this.prevFcn = angular.copy(this.$props.prevFcn);
    //
    if (this.prevFcn === 'createEvaluationRequest') {
      this.prevFcn = 'Create Evaluation Request for ';
    } else if (this.prevFcn === 'createEvaluation') {
      this.prevFcn = 'Create Evaluation for ';
    }
  }

  accept() {
    this.$close(true);
  }

  deny() {
    this.$close(false);
  }
}

export default angular
  .module('app.confirmClientModal', [])
  .directive('confirmClientModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./confirm-client.html'),
    controller: ConfirmClientModalController,
    controllerAs: 'vm'
  })).name;
