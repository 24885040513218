'use strict';

import angular from 'angular';

import { Controller, Inject, Watch } from '@/decorators/ngCtrl';
import { State, Mutation, Getter, Action } from 'angular-store';

@Controller
class WidgetPolicySelector {
  loadingData: boolean = false;
  $managedPolicies: Policy[] = [];
  $institutionPolicies: Policy[] = [];
  //
  $managedPolicy: Policy = null;
  $institutionPolicy: Policy = null;
  $customPolicy: Policy = null;

  @Inject $scope;
  @Inject $api;
  @Inject $modals;

  @Getter('policies/managed') managedPolicies;
  @Getter('policies/forInstitution') getInstitutionPolicies;

  $onInit() {
    this.$managedPolicies = this.managedPolicies;
    this.$institutionPolicies = this.getInstitutionPolicies(this.institutionId);

    if (!this.selectedPolicy) {
      this.choice = 2;
      return;
    }

    if ('managedPolicyId' in this.selectedPolicy) {
      this.$managedPolicy = this.managedPolicies.find(
        ({ id }) => id == this.selectedPolicy.managedPolicyId
      );

      this.choice = 1;
    } else if ('institutionPolicyId' in this.selectedPolicy) {
      this.$institutionPolicy = this.institutionPolicies.find(
        ({ id }) => id == this.selectedPolicy.institutionPolicyId
      );

      this.choice = 2;
    } else {
      this.$customPolicy = this.selectedPolicy;

      this.choice = 3;
    }

    this.$scope.$watchGroup(
      [
        () => this.choice,
        () => this.$managedPolicy,
        () => this.$institutionPolicy,
        () => this.$customPolicy
      ],
      () => this.setSelection()
    );
  }

  setSelection() {
    switch (this.choice) {
      case 1: {
        this.selectedPolicy = this.$managedPolicy;
        break;
      }
      case 2: {
        this.selectedPolicy = this.$institutionPolicy;
        break;
      }
      case 3: {
        this.selectedPolicy = this.$customPolicy;
        break;
      }
    }
  }

  async createInlinePolicy() {
    let policy = await this.$modals.util.policyEditor(
      this.$customPolicy || {
        name: 'New Policy',
        institutionId: this.institutionId
      },
      true
    );

    if (!policy) {
      return;
    }

    this.$customPolicy = policy;
  }
}

export default angular
  .module('app.widgetPolicySelector', [])
  .directive('widgetPolicySelector', () => ({
    restrict: 'E',
    replace: true,
    scope: {
      institutionId: '<',
      selection: '='
    },
    template: require('./widget-policy-selector.html'),
    link(scope) {
      if (typeof scope.choice !== 'number') {
        scope.choice = 1;
      }
    },
    controller: WidgetPolicySelector,
    controllerAs: 'vm'
  })).name;
