import angular, {
  ui,
  IScope,
  IAugmentedJQuery,
  IHttpService,
  IFilterService,
  ITimeoutService
} from 'angular';

import { State } from 'angular-store';
import { Controller, Inject } from '@decorators/ngCtrl';
import { MeState } from '@store/modules/me';

/**
 * ...
 */
interface MapMarker {
  id: string;
  pos: [number, number];
}

/**
 * ...
 */
const maps = {
  get Geocoder() {
    return window.google.maps.Geocoder;
  },
  get GeocoderStatus() {
    return window.google.maps.GeocoderStatus;
  },
  get LatLng() {
    return window.google.maps.LatLng;
  },
  get event() {
    return window.google.maps.event;
  }
};

@Controller
export default class DashboardHomeView {
  readonly googleMapsUrl =
    'https://maps.google.com/maps/api/js?key=AIzaSyCVkmOdPLEBK1bpiE2vlRtRRMvvh47cJMQ';

  parsingData: boolean = false;
  mapLoading: boolean = false;
  map: any | null = null;
  markers: MapMarker[] = [];
  geocoder: google.maps.Geocoder | null = null;

  @Inject readonly $scope!: IScope;
  @Inject readonly $auth!: angular.gears.IAuthService;
  @Inject readonly $http!: IHttpService;
  @Inject readonly $state!: ui.IStateService;
  @Inject readonly $element!: IAugmentedJQuery;
  @Inject readonly $filter!: IFilterService;
  @Inject readonly $timeout!: ITimeoutService;
  @Inject readonly $store!: any;
  @Inject readonly utils!: any;
  @Inject readonly NgMap!: any;
  // @Inject Analytics;

  @State readonly me!: MeState;
  @State readonly analytics!: any;

  get institutions() {
    return this.$store.state.institutions.items;
  }

  get clients() {
    return this.$store.state.clients.items;
  }

  get users() {
    return this.$store.state.users.items;
  }

  get evaluations() {
    return this.$store.state.evaluations.items;
  }

  get evaluationRequests() {
    return this.$store.state.evaluationRequests.items;
  }

  $onInit() {
    this.$element.find('.data-card').each((_, el) => {
      angular.element(el).css('animation-delay', `${Math.random() * 0.4}s`);
    });

    // TEMP: Disabled due to anoyying error.
    // this.loadMap();
  }

  async createMapNodes() {
    const markers: MapMarker[] = [];

    for (let inst of this.institutions) {
      let res = await this.getGeolocation(
        `${inst.city}, ${inst.stateProvince} ${inst.postalCode} ${inst.country}`
      );

      if (!res) continue;

      markers.push({ id: inst.id, pos: [res.lat(), res.lng()] });
    }

    this.mapLoading = false;

    this.markers = markers;
  }

  async getGeolocation(address: string) {
    const [results, status] = await new Promise((resolve) =>
      this.geocoder.geocode({ address }, (...args: any) => resolve(args))
    );

    if (status === maps.GeocoderStatus.OK) {
      return results[0].geometry.location;
    } else if (status == maps.GeocoderStatus.OVER_QUERY_LIMIT) {
      await this.utils.wait(100);

      return this.getGeolocation(address);
    } else {
      return null;
    }
  }

  async loadMap() {
    this.mapLoading = true;

    this.map = await this.NgMap.getMap();

    this.geocoder = new maps.Geocoder();

    maps.event.trigger(this.map, 'resize');
    this.map.setCenter(new maps.LatLng(40.16, -97.18));

    await this.createMapNodes();

    this.$scope.$apply();
  }
}
