type GenericObject = Record<any, any>;

type Customizer<T, S> = (
  value: unknown,
  key: string,
  target: T,
  source: S
) => unknown;

// class ObjectUtils {
//   map(target: GenericObject = {}, iteratee) {
//     let obj = {};
//
//     Object.entries(target).forEach(([key, value]) => {
//       obj[key] = iteratee ? iteratee(value, key, target) : value;
//     });
//
//     return obj;
//   }
//
//   assign(target: GenericObject, source: GenericObject, customizer: Customizer) {
//     let props = Object.assign({}, target, source);
//
//     for (let key in props) {
//       let value = source[key];
//
//       this[key] = customizer ? customizer(value, key, target, source) : value;
//     }
//
//     return target;
//   }
//
//   assignToWith(target: GenericObject, source: GenericObject, customizer: Customizer) {
//     for (let key in source) {
//       let value = source[key];
//       let willAdd = customizer
//         ? customizer(value, key, target, source)
//         : true;
//
//       if (willAdd) {
//         this[key] = value;
//       }
//     }
//
//     return target;
//   }
// }

// export const object = new ObjectUtils();

export const objectUtils = {
  map(target = {}, iteratee) {
    let obj = {};

    Object.entries(target).forEach(([key, value]) => {
      obj[key] = iteratee ? iteratee(value, key, target) : value;
    });

    return obj;
  },
  assign(target, source, customizer) {
    let props = Object.assign({}, target, source);

    for (let key in props) {
      let value = source[key];

      this[key] = customizer ? customizer(value, key, target, source) : value;
    }

    return target;
  },
  assignToWith(target, source, customizer) {
    for (let key in source) {
      let value = source[key];
      let willAdd = customizer ? customizer(value, key, target, source) : true;

      if (willAdd) {
        this[key] = value;
      }
    }

    return target;
  }
};
