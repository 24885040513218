'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class SelectClientModalController extends Modal {
  clientsSortType: string = 'lName';
  sortReverse: boolean = false;
  clients: any;

  @Inject $scope;

  $setup() {
    this.clients = this.$props?.clients;
  }

  clientSelected(client) {
    this.$close(client);
  }
}

export default angular
  .module('app.selectClientModal', [])
  .directive('selectClientModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./select-client.html'),
    controller: SelectClientModalController,
    controllerAs: 'vm'
  })).name;
