import angular from 'angular';

function onRun(
  Notification: angular.uiNotification.INotificationService,
  $store: angular.gears.IStoreService
) {
  'ngInject';

  const activeReminders: Record<string, () => void> = {};

  $store.subscribe(({ type }, state) => {
    if (type != 'reminders/set' && type != 'reminders/add') {
      return;
    }

    for (let reminder of state.reminders.items) {
      if (reminder.status != 'REMINDING' || reminder.id in activeReminders) {
        continue;
      }

      activeReminders[reminder.id] = () => {
        delete activeReminders[reminder.id];

        $store.dispatch('reminders/dismiss', { reminderId: reminder.id });
      };

      let title = 'Reminder';
      let message = '';

      if (reminder.type == 'ASSESSMENT_REMINDER') {
        let clientName = reminder.data.client
          ? `${reminder.data.client.fName} ${reminder.data.client.lName}`
          : '--';

        let dueIn =
          reminder.$daysLeft > 0
            ? `in ${reminder.$daysLeft} days`
            : reminder.$daysLeft === 0
            ? 'today'
            : `${Math.abs(reminder.$daysLeft)} days ago`;

        title = `Reminder: Upcoming Assessment Due Date`;
        message = `Assessment for client <b>${clientName}</b> with tool <b>${reminder.data.tool.name}</b> is due <b>${dueIn}</b>.`;
      }

      Notification({
        title,
        message,
        positionY: 'top',
        positionX: 'right',
        // delay: false,
        templateUrl: '/assets/templates/reminder-notification.html',
        onClose: activeReminders[reminder.id]
      });
    }
  });
}

export default angular.module('app.alertsHelper', []).run(onRun).name;
