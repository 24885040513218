'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class EmailReportModalController extends Modal {
  users: any;
  emailUsers: {};
  emails: any;

  @Inject $scope;
  @Inject utils;

  $setup() {
    this.users = this.$props.users;
    this.emailUsers = [];
    this.emails = '';
  }

  moveToEmailUsers(index) {
    var user = this.users.splice(index, 1);
    this.emailUsers.push(user[0]);
  }

  moveToUsers(index) {
    var user = this.emailUsers.splice(index, 1);
    this.users.push(user[0]);
  }

  async submitList(form) {
    //
    // this.processingEmails = true;
    var emailList = {};
    emailList.reportId = this.$props.reportId;
    if (this.emailUsers.length > 0) {
      emailList.users = [];
      for (let i = 0; i < this.emailUsers.length; i++) {
        emailList.users.push(this.emailUsers[i].id);
      }
    }

    if (this.emails.length > 0) {
      var emailsArray = [];
      var match = false;
      emailsArray = this.emails.split(',');
      var wrongEmails = [];
      for (let i = 0; i < emailsArray.length; i++) {
        match = false;
        emailsArray[i] = emailsArray[i].replace(/\s+/g, '');
        if (
          emailsArray[i] === '' ||
          !emailsArray[i] ||
          emailsArray[i] === 'undefined'
        ) {
          continue;
        }

        match = emailsArray[i].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        if (!match) {
          wrongEmails.push(emailsArray[i]);
        }
      }

      if (wrongEmails.length) {
        for (let i = 0; i < wrongEmails.length; i++) {
          wrongEmails[i] = ' ' + wrongEmails[i];
        }
        this.utils.notify('error', {});
        return;
      } else {
        emailList.emails = emailsArray;
      }
    } else {
      this.utils.notify('warning', {});
      return;
    }

    this.$close(emailList);
  }
}

export default angular
  .module('app.emailReportModal', [])
  .directive('emailReportModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./email-report.html'),
    controller: EmailReportModalController,
    controllerAs: 'vm'
  })).name;
