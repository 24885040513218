import { find, forEach } from 'lodash';

import { AssessmentInstance } from '@interfaces/assessment-instance';
import { AutoAnswer } from '@interfaces/tool';

/** Condition validation string modifiers */
enum Modifier {
  AtLeast = 'atLeast',
  LessThan = 'lessThan'
}

/** Condition expression string operators */
enum Operator {
  And = '&&',
  Or = '||',
  Equal = '=',
  Unequal = '!=',
  GreaterThan = '>',
  LessThan = '<',
  GreaterThanOrEqualTo = '>=',
  LessThanOrEqualTo = '<='
}

export class EvaluationConditionValidator {
  private passedChecks = 0;

  constructor(private readonly assessment: AssessmentInstance) {}

  /**
   * ...
   */
  static exec(
    assessment: AssessmentInstance,
    conditions: AutoAnswer.Condition[],
    modifier?: string | null
  ) {
    const instance = new EvaluationConditionValidator(assessment);
    const pass = instance.performValidation(conditions, '');
    if (!modifier) return pass;

    const [modType, modValue] = modifier.split(':');

    if (modType === Modifier.AtLeast) {
      return instance.passedChecks >= parseInt(modValue);
    }

    if (modType === Modifier.LessThan) {
      return instance.passedChecks < parseInt(modValue);
    }

    throw new Error(`Invalid value for condition modifier ${modifier}`);
  }

  /**
   * Helper function to take in array of conditions and evaluate as a whole.
   *
   * @param conditions ...
   * @param operator ...
   * @return ...
   */
  private performValidation(
    conditions: AutoAnswer.Condition[],
    operator?: string
  ): boolean {
    const results: boolean[] = [];

    let validationFailed = true;

    for (const condition of conditions) {
      // If the current condition has sub-condittions, determine how to process
      // them based upon whether ot not the condition also has a modifier.

      if ('conditions' in condition) {
        if ('conditionModifier' in condition === false) {
          validationFailed = !this.performValidation(
            condition.conditions ?? [],
            condition.operator
          );
        } else if (!this.performSubValidation(condition)) {
          validationFailed = true;
        }

        // The current condition's validation is contigent upon its
        // sub-conditions, do skip to the next condition.
        continue;
      }

      // Peform a validation given the current condition.
      const result = performConditionValidation(this.assessment, condition);

      // If the result was positive, increment the number of passed checks.
      if (result) this.passedChecks++;

      //
      if (!operator && !result) {
        validationFailed = true;
      } else {
        results.push(result);
      }
    }

    if (operator === Operator.And && results.includes(false)) return false;

    if (operator === Operator.Or && !results.includes(true)) return false;

    if (results.includes(true) && this.passedChecks === conditions.length)
      validationFailed = false;

    return validationFailed ? false : true;
  }

  /**
   * ...
   *
   * @param conditions ...
   * @param modifier ...
   * @return ...
   */
  private performSubValidation(condition: AutoAnswer.Condition) {
    return EvaluationConditionValidator.exec(
      this.assessment,
      condition.conditions ?? [],
      condition.conditionModifier ?? ''
    );
  }
}

/**
 * Helper function to find offender history field.
 *
 * @param data ...
 * @param keys ...
 * @return ...
 */
function getOffenderHistoryValue(data: any, keys: string[]) {
  let section: any = data;
  let finalSection: any = data;
  let field: unknown = null;
  let model: unknown = null;

  for (const key of keys) {
    if (key === 'offenderHistory') return;

    finalSection = section;
    section = find((section ?? {})?.sections ?? [], { key });

    if (section) continue;

    field = find((finalSection ?? {})?.fields ?? [], { key });
  }
  if (field?.model) model = field.model;
  return model;
}

/**
 * ...
 *
 * @param obj ...
 * @param props ...
 * @return ...
 */
function getNestedProperty(obj: unknown, keys: string[]) {
  let value = obj;
  for (const key of keys) {
    if (typeof value !== 'object' || key in (value ?? {}) === false) {
      throw new Error('Invalid nested property reference.');
    }

    value = ((value ?? {}) as Record<string, unknown>)[key];
  }
  return value;
}

/**
 * Attempt to fetch a value for validation from the provided assesment data.
 *
 * @param assessment Assessment data object.
 * @param variable String value representing the property chain ending in the
 * target value.
 * @return The target value, if it exists.
 */
function getAssessmentValue(assessment: AssessmentInstance, variable: string) {
  if (typeof variable !== 'string') {
    throw new Error(`An invalid condition variable was passed ${variable}`);
  }

  const [varName, ...varProps] = variable.split('.');

  if (typeof varName !== 'string') {
    throw new Error(`An invalid condition variable was passed ${variable}`);
  }

  if (varName === 'evaluation') {
    return getNestedProperty(assessment.evaluation, varProps);
  }

  if (varName === 'client') {
    return getNestedProperty(assessment.client, varProps);
  }

  if (varName === 'offenderHistory') {
    return getOffenderHistoryValue(assessment.offenderHistory, varProps);
  }

  return null;
}

/**
 * Helper function to evaluation a single condition object.
 *
 * @param assessment ...
 * @param condition ...
 */
function performConditionValidation(
  assessment: AssessmentInstance,
  condition: AutoAnswer.Condition
) {
  const { variable, operator, comparator } = condition;

  let value: unknown = null;

  try {
    value = getAssessmentValue(assessment, variable ?? '');
  } catch (err) {
    console.error(
      `Error while fetching value to validate for variable " ${variable}": ${err.message}`,
      assessment
    );

    return false;
  }

  let pass: unknown = false;
  const comparArray = comparator.split('||');
  forEach(comparArray, (item) => {
    if (pass) return;
    const test =
      typeof value === 'number' && typeof item === 'string'
        ? parseInt(item)
        : item;
    if (operator === Operator.Equal) {
      pass = value === test;
      return;
    }

    if (operator === Operator.Unequal) {
      pass = value !== test;
      return;
    }

    if (typeof value !== 'number' || typeof test !== 'number') {
      pass = false;
      return;
    }

    if (operator === Operator.GreaterThan) {
      pass = value > test;
      return;
    }
    if (operator === Operator.LessThan) {
      pass = value < test;
      return;
    }
    if (operator === Operator.GreaterThanOrEqualTo) {
      pass = value >= test;
      return;
    }
    if (operator === Operator.LessThanOrEqualTo) {
      pass = value <= test;
      return;
    }
    // pass = false;
  });
  // comparator.split('||').every((item) => {
  //   if (pass) return;
  //   const test =
  //     typeof value === 'number' && typeof item === 'string'
  //       ? parseInt(item)
  //       : item;
  //   console.log('pass check: ', value, operator, test);

  //   console.log('value === test', value === test);
  //   if (operator === Operator.Equal) pass = value === test;
  //   if (operator === Operator.Unequal) pass = value !== test;

  //   if (typeof value !== 'number' || typeof test !== 'number') pass = false;

  //   if (operator === Operator.GreaterThan) pass = value > test;
  //   if (operator === Operator.LessThan) pass = value < test;
  //   if (operator === Operator.GreaterThanOrEqualTo) pass = value >= test;
  //   if (operator === Operator.LessThanOrEqualTo) pass = value <= test;

  //   pass = false;
  // });

  // console.groupCollapsed(`Validation results: ${variable}`);
  // console.log('variable', variable);
  // console.log('operator', operator);
  // console.log('comparator', comparator.split('||'));
  // console.log('value', value);
  // console.log('pass', pass);
  // console.groupEnd();

  return pass;
}

/**
 * Run a validation on an active assessment based on the provided conditions.
 *
 * @param assessment The assessment instance.
 * @param conditions List of consition(s) to use for validation.
 * @param modifier An optional string expression of the format "type:value"
 * that can curtail the final result of the validation.
 * @return ...
 */
export const checkConditions = EvaluationConditionValidator.exec;
