'use strict';

import angular from 'angular';

export default angular
  .module('app.modalBody', [])
  .directive('modalBody', ($q, $timeout) => {
    'ngInject';

    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      template: require('./modal-body.html'),
      link(scope) {
        if ('$uibModalInstance' in scope == false) {
          console.warn('[]');
          return;
        }

        const modalVm = scope.vm;

        scope.loadingData = false;

        // Have the loading overlay stick around for at least a
        // half a second to prevent jerky transition as well as
        // make it clear to the user that information was loaded

        var $loadingDoneDefered;
        var $waiterDefered;

        const loadingStateHandler = async function(isLoading) {
          if (!isLoading) {
            if (scope.loadingData) {
              $loadingDoneDefered.resolve();
            }

            return;
          }

          scope.loadingData = true;

          $loadingDoneDefered = $q.defer();
          $waiterDefered = $q.defer();

          $timeout($waiterDefered.resolve, 500);

          await Promise.all([
            $loadingDoneDefered.promise,
            $waiterDefered.promise
          ]);

          scope.loadingData = false;

          scope.$apply();
        };

        scope.$watch(() => modalVm.loadingData, loadingStateHandler);
      }
    };
  }).name;
