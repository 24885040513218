'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class SelectCountryComponent {
  countries: {}[] = [];

  @Inject $scope;
  @Inject getFormItems;

  $onInit() {
    this.loadCountries();
  }

  async loadCountries() {
    this.countries = await this.getFormItems.countries();
    this.update();
  }

  async update() {
    let region;

    switch (this.ngModel) {
      case 'US':
        region = 'usStates';
        break;
      case 'CA':
        region = 'canadaProvinces';
        break;
      case 'GB':
        region = 'ukProvinces';
        break;
      case 'AU':
        region = 'auStates';
        break;
    }

    if (!region) {
      this.$scope.$parent.$broadcast('selectedCountryUpdated', null);
      return;
    }

    let stateProvinces = await this.getFormItems[region]();

    this.$scope.$parent.$broadcast('selectedCountryUpdated', stateProvinces);
  }
}

export default angular
  .module('app.selectCountry', [])
  .directive('selectCountry', () => ({
    restrict: 'E',
    replace: true,
    template: require('./select-country.html'),
    scope: {
      ngModel: '='
    },
    bindToController: true,
    controller: SelectCountryComponent,
    controllerAs: 'vm'
  })).name;
