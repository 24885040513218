import { ToolData } from '@interfaces/tool';

import { RouteController, Get, Post, Delete, RequestMethod, RouteGroup } from '../route-group';

// region Method Parameter Types

interface RouteBaseOptions {}

export interface ListAllToolsOptions extends RouteBaseOptions {
  // ...
}

export interface GetCodingFormOptions extends RouteBaseOptions {
  toolId: string | number;
  codingFormId: string;
}

export interface GetDescriptionPdfOptions extends RouteBaseOptions {
  toolId: string | number;
  descriptionPdfId: string;
}

export interface GetToolOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface GetToolCommitOptions extends RouteBaseOptions {
  toolId: string | number;
  commitId: string;
}

export interface GetToolPdfOptions extends RouteBaseOptions {
  toolId: string | number;
  toolPdfId: string;
}

export interface GetToolReportTemplateOptions extends RouteBaseOptions {
  toolId: string | number;
  reportTemplateId: string;
}

export interface GetToolReportTemplateCommitOptions extends RouteBaseOptions {
  toolId: string | number;
  reportTemplateId: string;
  commitId: string;
  templateDataOnly?: string;
}

export interface GetToolReportTemplateMediaOptions extends RouteBaseOptions {
  toolId: string | number;
  reportTemplateId: string;
  mediaId: string;
}

export interface ListLiveToolsOptions extends RouteBaseOptions {
  // ...
}

export interface ListManagedCasePlanTemplatesOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface ListInstitutionCasePlanTemplatesOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface ListToolCodingFormsOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface ListToolCommitsOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface ListToolPdfsOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface ListDescriptionPdfsOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface ListToolReportTemplatesOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface ListToolReportTemplateCommitsOptions extends RouteBaseOptions {
  toolId: string | number;
  reportTemplateId: string;
}

export interface ListToolReportTemplateMediaOptions extends RouteBaseOptions {
  toolId: string | number;
  reportTemplateId: string;
}

export interface AddChildOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface AddCodingFormOptions extends RouteBaseOptions {
  toolId: string | number;
  commitId: string;
}

export interface AddDescriptionPdfOptions extends RouteBaseOptions {
  toolId: string | number;
  commitId: string;
}

export interface AddToolPdfOptions extends RouteBaseOptions {
  toolId: string | number;
  commitId: string;
}

export interface ChangePriceOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface CommitToolOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface CommitToolReportTemplateOptions extends RouteBaseOptions {
  toolId: string | number;
  reportTemplateId: string;
}

export interface CreateCodingFormOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface CreateDescriptionPdfOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface CreateToolOptions extends RouteBaseOptions {
  // ...
}

export interface CreateToolPdfOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface CreateToolReportTemplateOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface RemoveChildOptions extends RouteBaseOptions {
  toolId: string | number;
}

export interface SetLiveCodingFormOptions extends RouteBaseOptions {
  toolId: string | number;
  commitId: string;
}

export interface SetLiveDescriptionPdfOptions extends RouteBaseOptions {
  toolId: string | number;
  commitId: string;
}

export interface SetLiveToolPdfOptions extends RouteBaseOptions {
  toolId: string | number;
  commitId: string;
}

export interface SetToolCommitStatusOptions extends RouteBaseOptions {
  toolId: string | number;
  commitId: string;
}

export interface TagToolCommitOptions extends RouteBaseOptions {
  toolId: string | number;
  commitId: string;
}

export interface UploadToolReportTemplateMediaOptions extends RouteBaseOptions {
  toolId: string | number;
  reportTemplateId: string;
}

export interface DeleteToolOptions extends RouteBaseOptions {
  toolId: string | number;
}

// endregion Method Parameter Types

@RouteController('tool-creator')
export class ToolCreatorRouteGroup extends RouteGroup {
  /** ... */
  @Get('tools/')
  readonly listAllTools!: RequestMethod<ToolData[], ListAllToolsOptions>;

  /** ... */
  @Get('tools/:toolId/coding-forms/:codingFormId')
  readonly getCodingForm!: RequestMethod<any, GetCodingFormOptions>;

  /** ... */
  @Get('tools/:toolId/description-pdfs/:descriptionPdfId')
  readonly getDescriptionPdf!: RequestMethod<any, GetDescriptionPdfOptions>;

  /** ... */
  @Get('tools/:toolId')
  readonly getTool!: RequestMethod<ToolData, GetToolOptions>;

  /** ... */
  @Get('tools/:toolId/commits/:commitId')
  readonly getToolCommit!: RequestMethod<any, GetToolCommitOptions>;

  /** ... */
  @Get('tools/:toolId/tool-pdfs/:toolPdfId')
  readonly getToolPdf!: RequestMethod<any, GetToolPdfOptions>;

  /** ... */
  @Get('tools/:toolId/report-templates/:reportTemplateId')
  readonly getToolReportTemplate!: RequestMethod<any, GetToolReportTemplateOptions>;

  /** ... */
  @Get(':toolId/report-templates/:reportTemplateId/commits/:commitId', ['templateDataOnly'])
  readonly getToolReportTemplateCommit!: RequestMethod<any, GetToolReportTemplateCommitOptions>;

  /** ... */
  @Get('tools/:toolId/report-templates/:reportTemplateId/media/:mediaId')
  readonly getToolReportTemplateMedia!: RequestMethod<any, GetToolReportTemplateMediaOptions>;

  /** ... */
  @Get('tools/live')
  readonly listLiveTools!: RequestMethod<ToolData[], ListLiveToolsOptions>;

  /** ... */
  @Get('tools/:toolId/managed-case-plan-templates')
  readonly listManagedCasePlanTemplates!: RequestMethod<any, ListManagedCasePlanTemplatesOptions>;

  /** ... */
  @Get('tools/:toolId/institution-case-plan-templates')
  readonly listInstitutionCasePlanTemplates!: RequestMethod<any, ListInstitutionCasePlanTemplatesOptions>;

  /** ... */
  @Get('tools/:toolId/coding-forms')
  readonly listToolCodingForms!: RequestMethod<any, ListToolCodingFormsOptions>;

  /** ... */
  @Get('tools/:toolId/commits')
  readonly listToolCommits!: RequestMethod<any, ListToolCommitsOptions>;

  /** ... */
  @Get('tools/:toolId/tool-pdfs')
  readonly listToolPdfs!: RequestMethod<any, ListToolPdfsOptions>;

  /** ... */
  @Get('tools/:toolId/description-pdfs')
  readonly listDescriptionPdfs!: RequestMethod<any, ListDescriptionPdfsOptions>;

  /** ... */
  @Get('tools/:toolId/report-templates')
  readonly listToolReportTemplates!: RequestMethod<any, ListToolReportTemplatesOptions>;

  /** ... */
  @Get('tools/:toolId/report-templates/:reportTemplateId/commits')
  readonly listToolReportTemplateCommits!: RequestMethod<any, ListToolReportTemplateCommitsOptions>;

  /** ... */
  @Get('tools/:toolId/report-templates/:reportTemplateId/media')
  readonly listToolReportTemplateMedia!: RequestMethod<any, ListToolReportTemplateMediaOptions>;

  /** ... */
  @Post('tools/:toolId/add-child')
  readonly addChild!: RequestMethod<any, AddChildOptions>;

  /** ... */
  @Post('tools/:toolId/commits/:commitId/coding-forms')
  readonly addCodingForm!: RequestMethod<any, AddCodingFormOptions>;

  /** ... */
  @Post('tools/:toolId/commits/:commitId/description-pdfs')
  readonly addDescriptionPdf!: RequestMethod<any, AddDescriptionPdfOptions>;

  /** ... */
  @Post('tools/:toolId/commits/:commitId/tool-pdfs')
  readonly addToolPdf!: RequestMethod<any, AddToolPdfOptions>;

  /** ... */
  @Post('tools/:toolId/price')
  readonly changePrice!: RequestMethod<any, ChangePriceOptions>;

  /** ... */
  @Post('tools/:toolId/commit')
  readonly commitTool!: RequestMethod<any, CommitToolOptions>;

  /** ... */
  @Post('tools/:toolId/report-templates/:reportTemplateId/commit')
  readonly commitToolReportTemplate!: RequestMethod<any, CommitToolReportTemplateOptions>;

  /** ... */
  @Post('tools/:toolId/coding-forms')
  readonly createCodingForm!: RequestMethod<any, CreateCodingFormOptions>;

  /** ... */
  @Post('tools/:toolId/description-pdf')
  readonly createDescriptionPdf!: RequestMethod<any, CreateDescriptionPdfOptions>;

  /** ... */
  @Post('tools/')
  readonly createTool!: RequestMethod<any, CreateToolOptions>;

  /** ... */
  @Post('tools/:toolId/tool-pdfs')
  readonly createToolPdf!: RequestMethod<any, CreateToolPdfOptions>;

  /** ... */
  @Post('tools/:toolId/report-templates')
  readonly createToolReportTemplate!: RequestMethod<any, CreateToolReportTemplateOptions>;

  /** ... */
  @Post('tools/:toolId/remove-child')
  readonly removeChild!: RequestMethod<any, RemoveChildOptions>;

  /** ... */
  @Post('tools/:toolId/commits/:commitId/set-live-coding-form')
  readonly setLiveCodingForm!: RequestMethod<any, SetLiveCodingFormOptions>;

  /** ... */
  @Post('tools/:toolId/commits/:commitId/set-live-description-pdf')
  readonly setLiveDescriptionPdf!: RequestMethod<any, SetLiveDescriptionPdfOptions>;

  /** ... */
  @Post('tools/:toolId/commits/:commitId/set-live-tool-pdf')
  readonly setLiveToolPdf!: RequestMethod<any, SetLiveToolPdfOptions>;

  /** ... */
  @Post('tools/:toolId/commits/:commitId/status')
  readonly setToolCommitStatus!: RequestMethod<any, SetToolCommitStatusOptions>;

  /** ... */
  @Post('tools/:toolId/commits/:commitId/tag')
  readonly tagToolCommit!: RequestMethod<any, TagToolCommitOptions>;

  /** ... */
  @Post('tools/:toolId/report-templates/:reportTemplateId/media')
  readonly uploadToolReportTemplateMedia!: RequestMethod<any, UploadToolReportTemplateMediaOptions>;

  /** ... */
  @Delete('tools/:toolId')
  readonly deleteTool!: RequestMethod<any, DeleteToolOptions>;
}
