'use strict';

import angular from 'angular';

function processAttrs(scope, attr) {
  let attrNames = attr.$attr;

  Object.keys(attrNames).forEach(key => {
    let name = attrNames[key];

    let shouldEval = name == `:${key}`;

    Object.defineProperty(scope, key, {
      get: () => (shouldEval ? scope.$parent.$eval(attr[key]) : attr[key])
    });
  });
}

class FormGroupTextComponent {
  errors: Array = [];

  constructor() {
    'ngInject';
    //
  }

  get value() {
    return this.ngModelCtrl.$viewValue;
  }

  set value(value) {
    this.ngModelCtrl.$setViewValue(value);
  }
}

export default angular
  .module('app.formGroupText', [])
  .directive('formGroupText', () => ({
    restrict: 'E',
    replace: true,
    require: { ngModelCtrl: 'ngModel' },
    template: require('./form-group-text.html'),
    scope: {
      label: '<',
      validations: '<',
      required: '<'
    },
    // controller: FormGroupComponent,
    // controllerAs: 'vm'
    link($scope, $element, $attr) {
      let evalTo = $attr.$attr.to == ':to';

      Object.defineProperty($scope, 'to', {
        get: () => (evalTo ? $scope.$parent.$eval($attr.to) : $attr.to)
      });

      let evalActiveClass = $attr.$attr.activeClass == ':activeClass';

      Object.defineProperty($scope, 'activeClass', {
        get: () =>
          evalActiveClass
            ? $scope.$parent.$eval($attr.activeClass)
            : $attr.activeClass
      });

      if (!$element.children().length) {
        $element.append(`<span>${$scope.to}</span>`);
      }
    }
  })).name;
