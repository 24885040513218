/* eslint-disable angular/no-run-logic */
import angular from 'angular';
import Prism from 'prismjs';

import * as appDependencies from './app.dependencies';
import appConfig from './app.config';
import appProto from './app.proto';

import { stateConfig, stateTransitions } from '@states';

declare global {
  /**
   * Log all angularJS modules imported from within the client.
   */
  function logAppInfo(): void;
}

function onRun(
  $rootScope: angular.IRootScopeService,
  $store: angular.gears.IStoreService
) {
  'ngInject';

  window.logAppInfo = () => {
    const info = {
      dependencies: appDependencies.source,
      rootScope: $rootScope,
      store: $store
    };

    // eslint-disable-next-line no-console
    console.log(info);
  };

  // if (process.env.NODE_ENV !== 'production') {
  //   logAppInfo();
  // }
}

const app = angular
  .module('app', appDependencies.list)
  .config(appProto)
  .config(appConfig)
  .config(stateConfig)
  .run(stateTransitions)
  .run(onRun);

Prism.languages.json = {
  comment: /\/\/.*|\/\*[\s\S]*?(?:\*\/|$)/,
  property: { pattern: /"(?:\\.|[^\\"\r\n])*"(?=\s*:)/, greedy: !0 },
  string: { pattern: /"(?:\\.|[^\\"\r\n])*"(?!\s*:)/, greedy: !0 },
  number: /-?\d+\.?\d*(e[+-]?\d+)?/i,
  punctuation: /[{}[\],]/,
  operator: /:/,
  boolean: /\b(?:true|false)\b/,
  null: /\bnull\b/
};

export default app;
