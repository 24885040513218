'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { Getter } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

@Controller
class EditPolicyModalController extends Modal {
  policy: Policy = null;

  @Inject $scope;
  @Inject $store;
  @Inject $api;

  @Getter isAdmin;

  $setup() {
    // this.policy = angular.copy(this.$props.policy);
    this.loadPolicyData();
  }

  async loadPolicyData() {
    this.loadingData = true;

    // Change once Eric implements route for
    // getting single policy.
    const id = this.$props.policy.institution.id;

    let { data } = await this.$api.IM.listPolicies(id);
    this.policy = data.find(item => item.id == this.$props.policy.id);

    this.loadingData = false;
  }

  async update() {
    try {
      let res = await this.$api.IM.updatePolicy(
        {
          instId: this.policy.institutionId,
          institutionPolicyId: this.$props.policy.id
        },
        this.policy
      );
    } catch (err) {
      console.error(err);

      return;
    }

    this.utils.notify('success', 'Policy updated.');

    try {
      // let refreashCall = this.isAdmin ? this.$store.dispatch('')
      this.$store.dispatch('policies/list');
    } catch (err) {}

    this.$close();
  }
}

export default angular
  .module('app.editPolicyModal', [])
  .directive('editPolicyModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./edit-policy.html'),
    controller: EditPolicyModalController,
    controllerAs: 'vm'
  })).name;
