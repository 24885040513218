import angular from 'angular';

function getBlockNodes(nodes) {
  var node = nodes[0];
  var endNode = nodes[nodes.length - 1];
  var blockNodes;

  for (var i = 1; node !== endNode && (node = node.nextSibling); i++) {
    if (!blockNodes && nodes[i] === node) {
      continue;
    }

    if (!blockNodes) {
      blockNodes = $(nodes.slice(0, i));
    }

    blockNodes.push(node);
  }

  return blockNodes || nodes;
}

export default angular
  .module('app.ngAuth', [])
  .directive('ngAuth', ($rootScope, $animate, $compile, $auth) => {
    'ngInject';

    return {
      multiElement: true,
      transclude: 'element',
      priority: 601,
      terminal: true,
      restrict: 'A',
      // $$tlb: true,
      link($scope, $element, $attr, ctrl, $transclude) {
        var block, childScope, previousElements;

        const ngAuthWatchAction = function() {
          var hasAuth = $auth.hasAccess($scope.$eval($attr.ngAuth));

          if (!hasAuth) {
            if (previousElements) {
              previousElements.remove();
              previousElements = null;
            }

            if (childScope) {
              childScope.$destroy();
              childScope = null;
            }

            if (block) {
              previousElements = getBlockNodes(block.clone);

              $animate.leave(previousElements).done(response => {
                if (response !== false) {
                  previousElements = null;
                }
              });

              block = null;
            }

            return;
          }

          if (childScope) {
            return;
          }

          $transclude((clone, newScope) => {
            childScope = newScope;
            clone[clone.length++] = $compile.$$createComment('end ngAuth');

            block = { clone };

            $animate.enter(clone, $element.parent(), $element);
          });
        };

        ngAuthWatchAction();

        $rootScope.$on('permissionProfileSet', ngAuthWatchAction);
      }
    };
  }).name;
