import angular from 'angular';

import { Proration, ProrationOptions } from './proration';

export interface ProrationService {
  /**
   * ...
   *
   * @param options ...
   * @return ...
   */
  (options: ProrationOptions): number;
}

/**
 * ...
 */
export type IProrationService = ProrationService;

export * from './types';

declare module 'angular' {
  namespace gears {
    type IProrationService = ProrationService;
  }
}

export default angular.module('app.proration', []).factory('Proration', () => {
  'ngInject';

  return (options: ProrationOptions) => new Proration(options).evaluate();
}).name;
