import { IHttpResponse } from 'angular';
import { kebabCase } from 'lodash';

type StyledLog = [string, string | Record<string, string>];

const STYLES = {
  BADGE: {
    color: '#000000',
    background: '#ff9c53',
    borderRadius: '6px'
  },
  STATUS_GOOD: {
    color: '#000000',
    background: '#12a757',
    fontSize: '12px',
    borderRadius: '6px'
  },
  STATUS_BAD: {
    color: '#000000',
    background: '#ff0047',
    fontSize: '12px',
    borderRadius: '6px'
  },
  METHOD: {
    color: '#ffb167'
  },
  ARROW: {
    color: '#6d6d6d'
  },
  URL_SEG: {
    color: '#44d3f5'
  },
  URL_SEP: {
    color: '#0a98ff'
  }
};

const API_LOG_TAG: StyledLog = [' API2 ', STYLES.BADGE];

function stringifyStyles(style: Record<string, string>) {
  return Object.keys(style)
    .map((key) => `${kebabCase(key)}: ${style[key]};`)
    .join('');
}

function createStyledLog(...args: Array<string | StyledLog>) {
  let str = '';

  const colors: string[] = [];

  for (const arg of args) {
    let [text, style] = Array.isArray(arg) ? arg : [arg, { color: '#e4e4e4' }];

    str += `%c${text}`;

    style = typeof style === 'string' ? { color: style } : style;

    colors.push(stringifyStyles(style));
  }

  return [str, ...colors];
}

function createLog(...args: Array<string | StyledLog>) {
  return () => console.log(...createStyledLog(...args));
}

function createLogGroup(...args: Array<string | StyledLog>) {
  console.groupCollapsed(...createStyledLog(...args));

  return () => console.groupEnd();
}

export function logger(res: IHttpResponse<any>) {
  const wasSuccess =
    res.status === 200 ||
    res.status === 201 ||
    res.status === 202 ||
    res.status === 203 ||
    res.status === 204 ||
    res.status === 205 ||
    res.status === 206 ||
    res.status === 207 ||
    res.status === 208 ||
    res.status === 226;

  const logStatusMarkConfig: StyledLog = [
    wasSuccess ? ' ✔ ' : ' ✘ ',
    wasSuccess ? STYLES.STATUS_GOOD : STYLES.STATUS_BAD
  ];

  const url = res.config.url.match(/\/api\/.*/)?.[0] ?? res.config.url;

  const logPathConfig: StyledLog[] = url
    .split('/')
    .filter((seg) => seg)
    .flatMap((seg, i) => {
      const items: StyledLog[] = [[seg, STYLES.URL_SEG]];

      if (i !== 0) items.unshift(['.', STYLES.URL_SEP]);

      return items;
    });

  const log = createLogGroup(
    API_LOG_TAG,
    '  ',
    logStatusMarkConfig,
    '  ',
    [res.config.method.toUpperCase(), STYLES.METHOD],
    [' : ', STYLES.ARROW],
    // [res.config.url, '#ff6e7a']
    ...logPathConfig
  );

  console.log('status', res.status);
  console.log('statusText', res.statusText);

  let payload: any = res.config.data;

  if (typeof payload === 'string') {
    try {
      payload = JSON.parse(payload);
    } catch (_) {}
  }

  console.log('payload', payload);

  const data = res.data;

  console.log('data', data?.data || data);
  console.log('error', data?.error);
  createLog('feedback - ', [data?.feedback || '', '#E6DB74'])();
  console.log('fullResponce', res);

  log();
}
