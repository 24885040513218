'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class CreateClientRequestModalController extends Modal {
  institutions: any;
  removedInstitution: {};
  clientLocalId: any;
  userRoleId: any;
  clientTargetInstitution: any;
  getFormItems: any;
  countries: any;

  @Inject $scope;
  @Inject $store;
  @Inject $http;
  @Inject $filter;
  @Inject $rootScope;
  @Inject $auth;
  @Inject getFormItems;
  @Inject utils;

  $setup() {
    this.institutions = this.$props;
    this.removedInstitution = {};
    this.clientLocalId = '';

    var me = this.$store.state.me;

    this.clientTargetInstitution = me.institution.id;

    this.getFormItems.countries().then(countries => {
      this.countries = countries;
    });
  }

  async checkClient() {
    this.clientExists = false;
    this.clientExistsAtInstitution = false;

    var localId = angular.copy(this.clientLocalId.toString());
    localId = localId.replace(/^[a-zA-Z-_ ]+$/, '');
    this.clientLocalId = parseInt(localId, 10);

    // TODO (Alex) update this system to take in existing client's current institution id
    if (this.clientLocalId.toString().length >= 8) {
      var externalId = this.clientCountry + this.clientLocalId;
      this.$http
        .get('/api/clients/public/' + externalId)
        .then(response => {
          //
          if (!response.data.error) {
            this.clientId = response.data.data.id;
            this.clientInfo = response.data.data;
            this.clientExists = true;
            if (
              this.clientInfo.institutionId === this.clientTargetInstitution
            ) {
              // check if client exists at their institution already
              this.clientExistsAtInstitution = true;
            }
            if (this.userRoleId === 7 || this.userRoleId === 8) {
              if (this.removedInstitution.id) {
                this.institutions.push(this.removedInstitution);
                this.removedInstitution = {};
              }
              for (var i = 0; i < this.institutions.length; i++) {
                if (this.clientInfo.institutionId === this.institutions[i].id) {
                  this.removedInstitution = this.institutions.splice(i, 1);
                }
              }
            }
          }
        })
        .catch(err => {
          if (err.status === 404) {
            this.clientExists = false;
          }
        });
    } else {
      this.clientExists = false;
    }
  }

  async createClientRequest1() {
    this.$http
      .post('/api/client-requests', {
        targetInstitution: this.clientTargetInstitution,
        clientId: this.clientId
      })
      .then(response => {
        if (!response.data.error) {
          this.utils.notify('success', {});
          // dismiss modal
          this.$close(response);
        } else {
          this.utils.notify('error', {});
        }
      })
      .catch(() => {
        this.utils.notify('error', {});
      });
  }
}

export default angular
  .module('app.createClientRequestModal', [])
  .directive('createClientRequestModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./create-client-request.html'),
    controller: CreateClientRequestModalController,
    controllerAs: 'vm'
  })).name;
