import angular from 'angular';
import { differenceInSeconds, isValid } from 'date-fns';

/**
 * ...
 */
export type IIdolTrackerService = IdolTrackerService;

declare module 'angular' {
  namespace gears {
    type IIdolTrackerService = IdolTrackerService;
  }
}

/**
 * ...
 */
export class IdolTrackerService {
  private _tracking = false;
  private _timeLimit = 870; // in seconds: 14.5 minutes
  private _idolTime = 0;
  private _lastRecordedActionTime = new Date();

  get tracking() {
    return this._tracking;
  }

  get timeLimit() {
    return this._timeLimit;
  }

  get idolTime() {
    return this._idolTime;
  }

  get lastRecordedActionTime() {
    return this._lastRecordedActionTime;
  }

  constructor(
    private readonly $window: angular.IWindowService,
    private readonly $rootScope: angular.IRootScopeService,
    // private readonly $location: ILocationService,
    private readonly $delay: angular.gears.IDelayService,
    private readonly $auth: angular.gears.IAuthService,
    private readonly $modals: any
  ) {
    'ngInject';

    $window.addEventListener('mousemove', () => (this._idolTime = 0));
    $window.addEventListener('keydown', () => (this._idolTime = 0));
  }

  /**
   * ...
   *
   * @param timeLimit ...870 (14.5 minutes)
   * @return ...
   */
  start(timeLimit = 870) {
    if (this._tracking || this.$window.location.hostname === 'localhost') {
      return;
    }

    this._tracking = true;
    this._timeLimit = timeLimit;

    this.tick();
  }

  /**
   * ...
   *
   * @return ...
   */
  stop() {
    if (this._tracking) {
      this._tracking = false;
    }
  }

  /**
   * ...
   *
   * @return ...
   */
  private async tick(): Promise<void> {
    await this.$delay(1000);

    if (!this._tracking) {
      this._idolTime = 0;
      return;
    }

    // set date/time (more reliable to check than interval)
    if (this._idolTime <= 1) {
      this._lastRecordedActionTime = new Date();
    }

    this._idolTime++;
    this.$rootScope.$apply();

    // check if lastRecordedActionTime to now is more than timeout
    let idolTimeOverride;
    if (
      this.lastRecordedActionTime &&
      isValid(this.lastRecordedActionTime) &&
      differenceInSeconds(new Date(), this.lastRecordedActionTime) >=
        this.timeLimit
    ) {
      idolTimeOverride = true;
    }

    if (this._idolTime < this.timeLimit && !idolTimeOverride)
      return this.tick();

    let loggedIn = await this.$auth.loggedInAsync;

    if (!loggedIn) return this.stop();

    let loggedOut = await this.$modals.settings.logoutWarning();

    if (loggedOut) return this.stop();

    this._idolTime = 0;

    this.tick();
  }
}

export default angular
  .module('app.idolTracker', [])
  .service('idolTracker', IdolTrackerService).name;
