import angular, { IController } from 'angular';
import { find } from 'lodash';
import { State, Getter, Action } from 'angular-store';
import { IListItemModel } from 'lister';

import { Controller, Inject, On, WatchCollection } from '@decorators/ngCtrl';
import { Client } from '@interfaces/client';
import { Region } from '@interfaces/region';
import { Subgroup } from '@interfaces/subgroup';
import { Zone } from '@interfaces/zone';
import { RootModules } from '@store/state';

interface ListItems {
  zone: Zone[];
  region: Region[];
  subGroup: Subgroup[];
}

interface ClientData extends Client {
  zone?: Zone;
  region?: Region;
  subGroup?: Subgroup;
}

interface ListModels {
  zone: IListItemModel<Zone, Region>;
  region: IListItemModel<Region, Subgroup>;
  subGroup: IListItemModel<Subgroup, Client>;
}

const TABLE_PROPS = [
  // { label: 'ID', value: 'localId' },
  { label: 'First', value: 'fName' },
  { label: 'Last', value: 'lName' },
  { label: 'Zone', value: 'zone.name' },
  { label: 'Region', value: 'region.name' },
  { label: 'SubGroup', value: 'subGroup.name' }
];

@Controller
class DashboardLocationSetupView implements IController {
  @Inject readonly $scope!: angular.IScope;
  @Inject readonly $location!: angular.ILocationService;
  @Inject readonly $store!: angular.gears.IStoreService;
  @Inject readonly $auth!: angular.gears.IAuthService;
  @Inject readonly $api!: angular.gears.IApiService;
  @Inject readonly $api2!: angular.gears.IAPI2Service;
  @Inject readonly $modals!: angular.gears.IModalsService;
  @Inject readonly utils!: angular.gears.IUtilsService;
  @Inject readonly notify!: angular.gears.INotifyService;
  @Inject readonly $filter!: angular.IFilterService;

  tableProps = TABLE_PROPS;

  loading = false;
  selection: any;
  breadCrumbs: string = '';
  selectedInstId: string | null = null;
  focusedItem: unknown | null = null;
  //
  listModel: ListModels | null = null;
  listItems: ListItems | null = null;
  //
  zones: Zone[] = [];
  regions: Region[] = [];
  subGroups: Subgroup[] = [];
  clients: ClientData[] = [];
  selectableClients: ClientData[] = [];
  //
  tableItems: ClientData[] = [];
  tableColumnActions: unknown[] = [];
  tableActions: unknown[] = [];
  searchText: string = '';
  loadingTable: boolean = false;
  //
  loadingInstitutions: boolean = false;

  @State readonly me!: RootModules['me'];
  @State(({ institutions }) => institutions.items)
  readonly institutions!: RootModules['institutions']['items'];
  @Getter readonly activeInstId!: unknown;

  @Action('institutions/getAll') readonly listInstitutions!: unknown;
  @Action('locations/listZones') readonly listInstitutionZones!: unknown;

  @WatchCollection('tableItems')
  onTableItemsChanged(items: ClientData[]) {
    this.selectableClients = (this.clients || []).filter(
      ({ id }) => !find(items, { id })
    );
  }

  $onCreate() {
    if (!find(this.tableProps, { value: 'localId' })) {
      this.tableProps.unshift({
        label: this.$filter('clientLabel')('localId', true),
        value: 'localId'
      });
    }
    this.$scope.$watch('vm.selection', () => this.selectItem());

    this.selectedInstId =
      this.activeInstId ||
      this.$location.search().institutionId ||
      this.institutions[0]?.id ||
      null;

    this.listModel = {
      zone: {
        type: 'Zone',
        idBy: 'id',
        listIdBy: 'zoneId',
        nameBy: 'name',
        queryBy: ['name'],
        add: this.onZoneAdd,
        edit: this.onZoneEdit,
        remove: this.onZoneRemove,
        canRemove: (item) => !item.isDefault
      },
      region: {
        type: 'Region',
        idBy: 'id',
        listIdBy: 'regionId',
        nameBy: 'name',
        queryBy: ['name'],
        parentModel: 'zone',
        add: this.onRegionAdd,
        edit: this.onRegionEdit,
        remove: this.onRegionRemove,
        canRemove: (item) => !item.isDefault
      },
      subGroup: {
        type: 'SubGroup',
        idBy: 'id',
        listIdBy: 'subGroupId',
        nameBy: 'name',
        queryBy: ['name'],
        parentModel: 'region',
        add: this.onSubGroupAdd,
        edit: this.onSubGroupEdit,
        remove: this.onSubGroupRemove,
        canRemove: (item) => !item.isDefault
      }
    };

    this.tableColumnActions = [
      {
        label: 'Transfer',
        icon: 'arrow-right',
        actions: this.transferClient
      }
    ];

    this.tableActions = [
      {
        label: 'TRANSFER SELECTED CLIENTS',
        fn: this.transferSelectedClients
      }
    ];

    this.initListView();

    // this.$scope.$watchCollection(
    //   () => this.tableItems,
    //   (val) => {
    //     this.selectableClients = (this.clients || []).filter(
    //       (item) => !val.find((o) => o.id == item.id)
    //     );
    //   }
    // );
  }

  $onInit() {
    this.$scope.$on('$destroy', () => {
      if (!this.selectedInstId) return;
      this.listInstitutionZones(this.selectedInstId);
    });
  }

  /**
   * ...
   */
  async initListView() {
    // const $vm = this;

    this.loading = true;

    // Pause for effect...
    await this.utils.wait(500);

    this.zones = [];
    this.regions = [];
    this.subGroups = [];
    this.clients = [];

    if (this.selectedInstId) {
      this.$location.search('institutionId', this.selectedInstId);

      this.zones = await this.listInstitutionZones(this.selectedInstId);

      this.zones.forEach((zone) => {
        zone.regions.forEach((region) => {
          this.regions.push(region);

          region.subGroups.forEach((subGroup) => {
            subGroup.regionName = region.name;
            subGroup.zoneName = zone.name;
            this.subGroups.push(subGroup);

            subGroup.clients.forEach((client) =>
              this.clients.push({ ...client, zone, region, subGroup })
            );
          });
        });
      });

      this.$scope.$apply();
    } else {
      this.$location.search('institutionId', null);
    }

    // Filter out duplicate clients (clients in multiple subgroups) to prevent
    // transfer errors.
    this.clients = this.clients.filter(
      (client, index, arr) => arr.findIndex((o) => o.id == client.id) == index
    );

    this.listItems = {
      zone: this.zones,
      region: this.regions,
      subGroup: this.subGroups
    };

    this.setTableItems();

    this.loading = false;
  }

  /**
   * ...
   */
  selectItem() {
    if (!this.selection) {
      this.breadCrumbs = '';
      this.setTableItems();

      return;
    }

    this.breadCrumbs = this.selection.name;

    let item = this.selection;
    while (item.parent) {
      item = item.parent;
      this.breadCrumbs = `${item.name} > ${this.breadCrumbs}`;
    }

    this.setTableItems();
  }

  /**
   * ...
   */
  setTableItems() {
    if (!this.selection) {
      this.tableItems = [];

      return;
    }

    const { model, data } = this.selection;

    // Does the selection have any data? If not, it might be new.
    this.tableItems = !data?.id
      ? []
      : this.clients.filter((item) => item[model.$name].id === data.id);
  }

  /**
   * ...
   */
  addItem() {
    if (!this.focusedItem) return;

    this.$scope.$broadcast('addListItem', this.focusedItem.listId);
  }

  /**
   * ...
   */
  async transferHere() {
    const subGroup = find(this.subGroups, { id: this.selection.data.id });

    if (!subGroup) return;

    const clientIds: string[] =
      await this.$modals.util.subGroupClientTransferHere(
        subGroup,
        this.clients.filter((item) => item.subGroup.id !== subGroup.id)
      );

    if (!clientIds) return;

    for (const id of clientIds) {
      const client = find(this.clients, { id });

      if (!client) continue;

      client.subGroup = subGroup;

      const region = find(this.regions, { id: subGroup.regionId });
      const zone = find(this.zones, { id: region!.zoneId });

      client.region = region;
      client.zone = zone;
    }

    this.setTableItems();
  }

  /**
   * ...
   */
  async loadInstitutions() {
    if (this.institutions?.length) return;

    this.loadingInstitutions = true;

    try {
      await this.listInstitutions();
    } catch (err) {
      this.notify.display(err, 'error');
    } finally {
      this.loadingInstitutions = false;
    }
  }

  //

  /**
   * ...
   */
  private onZoneAdd = async ({ name }: any) => {
    console.log('onZoneAdd');

    let data = await this.$api2.im.createZone({
      institutionId: this.selectedInstId!,
      name
    });

    let zone = { ...data, regions: [] };
    this.zones.splice(0, 0, zone);

    return zone;
  };

  /**
   * ...
   */
  private onZoneEdit = async ({ name, id }: any) => {
    console.log('onZoneEdit');

    const zoneId =
      (id && id.includes('zone/') ? id.split('/')[1] : null) ?? null;

    if (!zoneId) {
      return console.error('Could not find zoneId from object');
    }

    await this.$api2.im.updateZone({
      institutionId: this.selectedInstId!,
      zoneId,
      name
    });

    let zone = find(this.zones, { id: parseInt(zoneId) });

    if (zone) zone.name = name;

    this.selectItem();

    return zone;
  };

  /**
   * ...
   */
  private onZoneRemove = async (zoneId: Zone['id']) => {
    console.log('onZoneRemove');

    let zone = find(this.zones, { id: zoneId });

    const hasClients = (zone?.regions ?? []).some(({ subGroups }) =>
      subGroups.some(({ clients }) => !!clients.length)
    );

    if (hasClients) {
      this.notify.display(
        'Subgroups in the zone still have clients. You must transfer clients to another zones in order to delete the zone.',
        'error',
        true
      );

      throw new Error('Zone still has clients');
    }

    try {
      await this.$api2.im.deleteZone({
        institutionId: this.selectedInstId!,
        zoneId
      });
    } catch (err) {
      this.notify.display(err, 'error');
    }

    this.zones.splice(
      this.zones.findIndex((item) => item.id === zoneId),
      1
    );
  };

  /**
   * ...
   */
  private onRegionAdd = async ({ name }: any, { id: zoneId }: any) => {
    console.log('onRegionAdd');

    let data = await this.$api2.im.createRegion({
      institutionId: this.selectedInstId!,
      zoneId,
      name
    });

    let region: Region = { ...data, subGroups: [] };

    this.regions.splice(0, 0, region);

    return region;
  };

  /**
   * ...
   */
  private onRegionEdit = async ({ name, id }: any) => {
    console.log('onRegionEdit');

    const regionId =
      (id && id.includes('region/') ? id.split('/')[1] : null) ?? null;

    if (!regionId) {
      return console.error('Could not find regionId from object');
    }

    await this.$api2.im.updateRegion({
      institutionId: this.selectedInstId!,
      regionId,
      name
    });

    let region = find(this.regions, { id: parseInt(regionId) });

    if (region) region.name = name;

    this.selectItem();

    return region;
  };

  /**
   * ...
   */
  private onRegionRemove = async (regionId: Region['id']) => {
    console.log('onRegionRemove');

    let region = find(this.regions, { id: regionId });

    const hasClients = (region?.subGroups ?? []).some(
      ({ clients }) => !!clients.length
    );

    if (hasClients) {
      this.notify.display(
        'Subgroups in the region still have clients. You must transfer clients to another region in order to delete the region.',
        'error',
        true
      );

      throw new Error('Region still has clients');
    }

    try {
      await this.$api2.im.deleteRegion({
        institutionId: this.selectedInstId!,
        regionId
      });
    } catch (err) {
      this.notify.display(err, 'error');
    }

    this.regions.splice(
      this.regions.findIndex((item) => item.id === regionId),
      1
    );
  };

  /**
   * ...
   */
  private onSubGroupAdd = async ({ name }: any, { id: regionId }: any) => {
    console.log('onSubGroupAdd');

    let data = await this.$api2.im.createSubGroup({
      institutionId: this.selectedInstId!,
      regionId,
      name
    });

    let subGroup: Subgroup = { ...data, clients: [] };

    this.subGroups.splice(0, 0, subGroup);

    return subGroup;
  };

  /**
   * ...
   */
  private onSubGroupEdit = async ({ name, id }: any) => {
    console.log('onSubGroupEdit');

    const subGroupId =
      (id && id.includes('subGroup/') ? id.split('/')[1] : null) ?? null;

    if (!subGroupId) {
      return console.error('Could not find subGroup ID from object');
    }

    await this.$api2.im.updateSubGroup({
      institutionId: this.selectedInstId!,
      subGroupId,
      name
    });

    let subGroup = find(this.subGroups, { id: parseInt(subGroupId) });

    if (subGroup) subGroup.name = name;

    this.selectItem();

    return subGroup;
  };

  /**
   * ...
   */
  private onSubGroupRemove = async (subGroupId: Subgroup['id']) => {
    console.log('onSubGroupRemove');

    let subGroup = find(this.subGroups, { id: subGroupId });

    if (subGroup?.clients?.length) {
      this.notify.display(
        'Subgroup still has clients. You must transfer clients to another subgroup in order to delete the subgroup.',
        'error',
        true
      );

      throw new Error('Region still has clients');
    }

    try {
      await this.$api2.im.deleteSubGroup({
        institutionId: this.selectedInstId!,
        subGroupId
      });
    } catch (err) {
      this.notify.display(err, 'error');
    }

    this.subGroups.splice(
      this.subGroups.findIndex((item) => item.id === subGroupId),
      1
    );
  };

  /**
   * ...
   */
  private transferClient = async ({ id }: ClientData) => {
    const subGroup = await this.$modals.util.subGroupClientTransfer(
      this.subGroups,
      id
    );

    if (!subGroup) return;

    const client = find(this.clients, { id });

    if (!client) {
      throw new Error(`Could not find client with ID "${id}".`);
    }

    client.subGroup = subGroup;
    client.region = find(this.regions, { id: client.subGroup.regionId });
    client.zone = find(this.zones, { id: client.region.zoneId });

    this.setTableItems();

    this.$scope.$apply();
  };

  /**
   *
   */
  private transferSelectedClients = async (clients: ClientData[]) => {
    const selectedClients = clients
      .filter((c) => c.selected)
      .map(({ ref }) => ref);

    if (!selectedClients?.length) {
      return this.notify.display('No Clients Selected', 'warning');
    }

    const subGroup = await this.$modals.util.subGroupClientTransfer(
      this.subGroups,
      undefined,
      selectedClients
    );

    if (!subGroup) return;

    selectedClients.forEach(({ id }) => {
      const client = find(this.clients, { id });
      if (!client) return;

      client.subGroup = subGroup;
      client.region = find(this.regions, { id: client.subGroup.regionId });
      client.zone = find(this.zones, { id: client.region.zoneId });
    });

    this.setTableItems();

    this.$scope.$apply();
  };
}

export default DashboardLocationSetupView;
