'use strict';

import angular from 'angular';
import * as _ from 'lodash';
import shortid from 'shortid';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class AddTableItemModalController extends Modal {
  @Inject TableViewDataTypes;
  @Inject $util;
  @Inject $scope;
  @Inject $reincode;

  $setup() {
    'ngInject';

    this._scope = this.$scope;
    this._TableViewDataTypes = this.TableViewDataTypes;
    this.intensityOptions = this.$util.getIntensityOptions();
    this.Util = this.$util;

    this.props = [];
    this.editItem = this.$props.item
      ? angular.copy(this.$props.item.item)
      : null;

    this.conditionRule = false;

    this.$props.itemProps.forEach((prop) => {
      let propModel = Object.assign({}, prop);
      // sometimes given object on propModel.type with value of type inside
      if (typeof propModel.type === 'object' && propModel.type.value) {
        propModel.options = propModel.type.options;
        propModel.type = propModel.type.value;
      }
      // if type is a string, assign type enum via TableViewDataTypes
      if (typeof propModel.type === 'string')
        propModel.type = this.TableViewDataTypes[propModel.type];
      propModel.type = propModel.type || this.TableViewDataTypes.string;

      // assign value here if this.editItem has content
      if (this.editItem) {
        if (prop.key === 'type') {
          // check for options and form influence to assign values
          if (typeof this.editItem.type === 'object') {
            this.editItem.options = this.editItem.type.options;
            this.editItem.type = this.editItem.type.value;
            if (this.editItem.type === 'intervention') {
              _.find(prop.options, {
                value: 'intervention'
              }).formInfluence.values = this.editItem.options;
            }
          }
        }
        let value = this.valueExists(this.editItem[prop.key])
          ? this.editItem[prop.key]
          : null;
        if (value && (prop.type === 'date' || prop.type === 'dateTime')) {
          value = new Date(value);
        }
        prop.value = value;
      }

      propModel.value = this.valueExists(prop.value) ? prop.value : null;

      if (propModel.key === 'conditions') {
        this.conditionRule = true;
        // check if we're passing in existing conditions
        if (this.editItem && propModel.value && propModel.value.length)
          propModel.conditions = propModel.value;

        // process conditions to select the existing option on the form
        _.forEach(propModel.conditions, (cond) => {
          if (propModel.options) {
            let subOption = _.find(propModel.options, { value: cond.value });
            if (subOption && _.find(subOption.options, { type: 'enum' }))
              subOption = _.find(subOption.options, { type: 'enum' });
            _.forEach(cond.subOptions, (subOpt) => {
              if (typeof subOpt.value === 'object') {
                subOpt.value = _.find(subOption.options, {
                  value: subOpt.value.value
                });
              }
              this.checkEnumSubOptions(subOpt.value, subOption);
            });
          }
        });
      }

      if (propModel.type === this.dataTypes.enum && this.editItem) {
        // find the enum option and assign editItem options to formInfluence options
        let enumOption = _.find(propModel.options, {
          value: propModel.value || 'enum'
        });
        if (enumOption && enumOption.value) propModel.value = enumOption.value;

        if (enumOption && enumOption.hasOwnProperty('formInfluence'))
          enumOption.formInfluence.values = this.editItem.options;

        // propModel =
        this.enumSelected(propModel);
      }

      if (propModel.type === this.dataTypes.intervention && this.editItem) {
        if (propModel.value && propModel.value.category) {
          propModel.programSelectedValue = propModel.value.program;
          propModel.programSelected = _.find(propModel.programsOptions, {
            value: propModel.programSelectedValue
          });
          propModel.value = propModel.value.category;
          this.enumSelected(propModel);
          this.programSelected(propModel);
        }
      }

      // reset form influence values (dropdown options)
      if (!this.editItem && propModel.options) {
        _.forEach(propModel.options, (opt) => {
          if (opt.formInfluence && opt.formInfluence.values)
            opt.formInfluence.values = [];
        });
      }

      this.props.push(propModel);
    });
  }

  get parent() {
    return this._scope.$parent;
  }

  get newItem() {
    let item = {};

    this.props.forEach((prop) => {
      if (prop.formInfluence) {
        item[prop.key] = {
          value: prop.value,
          options: prop.formInfluence.values
        };
      } else if (prop.key === 'conditions') {
        let conditions = [];
        _.forEach(prop.conditions, (cond) => {
          conditions.push(cond);
        });
        item[prop.key] = conditions;
      } else if (
        prop.key === 'intervention' &&
        (prop.type === this.TableViewDataTypes.enum ||
          prop.type === this.TableViewDataTypes.intervention)
      ) {
        item[prop.key] = {
          category: prop.value,
          program: prop.programSelectedValue
        };
      } else {
        item[prop.key] = prop.value;
      }
    });

    if (!item.key && (item.label || item.title))
      item.key = item.label
        ? this.Util.camelize(item.label)
        : this.Util.camelize(item.title);

    return item;
  }

  get dataTypes() {
    return this._TableViewDataTypes;
  }

  add() {
    let item = this.newItem;
    this.editItem = null;
    item = this.$reincode.object(item);
    this.$close(item);
  }

  close() {
    this.editItem = null;
    this.$close();
  }

  subOptions(value, options) {
    return _.find(options, { value })
      ? _.find(options, { value }).options
      : null;
  }

  checkEnumSubOptions(value, subOption) {
    Reflect.deleteProperty(subOption, 'subOptions');
    let choice = _.find(subOption.options, value);
    if (choice && choice.options && choice.options.length)
      subOption.subOptions = choice.options;
  }

  enumSelected(prop) {
    let optionSelected = _.find(prop.options, { value: prop.value });
    if (optionSelected && optionSelected.formInfluence) {
      prop.formInfluence = optionSelected.formInfluence;
    } else if (optionSelected && optionSelected.programsOptions) {
      prop.programsOptions = optionSelected.programsOptions;
    } else {
      Reflect.deleteProperty(prop, 'formInfluence');
      Reflect.deleteProperty(prop, 'programsOptions');
    }
    return prop;
  }

  addCondition(prop) {
    if (!prop.conditions) prop.conditions = [];
    prop.conditions.push({ value: '', subOptions: [] });
  }

  removeOption(option, values) {
    _.remove(values, option);
  }

  stripSpaces(option) {
    option.value = option.value.replace(/ /g, '');
  }

  valueExists(val) {
    if (val) {
      return true;
    } else if (val === false) {
      return true;
    } else if (val === 0) {
      return true;
    } else {
      return false;
    }
  }

  //region Intervention options
  addCategoryOption(formInfluence) {
    formInfluence.values.push({
      value: shortid.generate(),
      label: '',
      programsOptions: [
        {
          value: shortid.generate(),
          label: '',
          contactDetails: 'address: \nphone: \nemail: '
        }
      ]
    });
  }

  addProgramsOption(categoryOption) {
    if (!categoryOption.programsOptions) categoryOption.programsOptions = [];
    categoryOption.programsOptions.push({
      value: shortid.generate(),
      label: '',
      contactDetails: 'address: \nphone: \nemail: '
    });
  }

  programSelected(prop) {
    prop.programSelected = _.find(prop.programsOptions, {
      value: prop.programSelectedValue
    });
  }
  //endregion Intervention options

  //region Dropdown options
  addDropdownOption(prop) {
    prop.values.push({ value: shortid.generate(), label: '' });
  }

  checkDuplicates(formInfluence) {
    let values = formInfluence.values;

    let vals = [];
    let labels = [];
    _.forEach(values, (value) => {
      Reflect.deleteProperty(value, 'error');
      vals.push(value.value.replace(/ /g, ''));
      labels.push(value.label);
    });

    let valDups = _.filter(vals, (val, index, iteratee) =>
      _.includes(iteratee, val, index + 1)
    );
    let labelDups = _.filter(vals, (val, index, iteratee) =>
      _.includes(iteratee, val, index + 1)
    );

    formInfluence.errors = [];

    if (valDups.length) {
      _.forEach(valDups, (valDup) => {
        if (valDup !== '') {
          _.forEach(values, (val) => {
            if (val.value === valDup) val.error = true;
          });
          formInfluence.errors.push(`Duplicate Values Not Allowed | ${valDup}`);
        }
      });
    }

    if (labelDups.length) {
      _.forEach(labelDups, (labelDup) => {
        if (labelDup !== '') {
          _.forEach(values, (val) => {
            if (val.label === labelDup) val.error = true;
          });
          formInfluence.errors.push(
            `Duplicate Labels Not Allowed | ${labelDup}`
          );
        }
      });
    }
  }
  //endregion Dropdown options

  log(item) {
    console.log(item);
  }

  filterOptions(options, textFilter = '') {
    return _.filter(options, (opt) => {
      return opt.label?.includes(textFilter);
    });
  }
}

export default angular
  .module('app.add-table-item-modal', [])
  // .controller('AddTableItemModalController', AddTableItemModalController)
  .directive('addTableItemModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./add-table-item.html'),
    controller: AddTableItemModalController,
    controllerAs: 'vm'
  }))
  .directive('dropdownOptions', () => {
    return {
      template: require('./dropdown-options/dropdown-options.html'),
      restrict: 'E',
      scope: {
        prop: '='
      },
      controller: AddTableItemModalController,
      controllerAs: 'vm'
    };
  })
  .directive('interventionOptions', () => {
    return {
      template: require('./intervention-options/intervention-options.html'),
      restrict: 'E',
      scope: {
        prop: '='
      },
      controller: AddTableItemModalController,
      controllerAs: 'vm'
    };
  })
  .directive('conditionsOptions', () => {
    return {
      template: require('./conditions-options/conditions-options.html'),
      restrict: 'E',
      scope: {
        prop: '='
      },
      controller: AddTableItemModalController,
      controllerAs: 'vm'
    };
  }).name;
