import angular from 'angular';

import { Client } from '@interfaces/client';
import { Institution } from '@interfaces/institution';
import { Region } from '@interfaces/region';
import { Subgroup } from '@interfaces/subgroup';
import { User } from '@interfaces/user';
import { Zone } from '@interfaces/zone';

export class ModalConfigurationWrapper {
  constructor(
    private readonly $rootScope: angular.IRootScopeService,
    private readonly $filter: angular.IFilterService,
    private readonly $state: angular.ui.IStateService,
    private readonly $ls: angular.gears.ILsService,
    private readonly $store: angular.gears.IStoreService,
    private readonly aggregateReportsMonitor: angular.gears.IAggregateReportsMonitor,
    private readonly activityReportsMonitor: angular.gears.IActivityReportsMonitor,
    private readonly aggregateUserReportsMonitor: angular.gears.IAggregateUserReportsMonitor
  ) {
    'ngInject';
  }

  /**
   * ...
   */
  get groups() {
    return {
      confirm: this.confirm,
      create: this.create,
      edit: this.edit,
      invite: this.invite,
      evaluation: this.evaluation,
      settings: this.settings,
      login: this.login,
      tool: this.tool,
      test: this.test,
      util: this.util,
      other: this.other,
      delete: this.delete
    };
  }

  /**
   * Confirmation modals.
   */
  private confirm = {
    delete: (items: unknown[]) => ({
      dismissable: true,
      title: 'CONFIRM DELETE',
      component: 'deleteModal',
      props: { items },
      onClose: (output: unknown[]) => output[0]
    }),
    delete2: (item: unknown, itemType: unknown, cb = angular.noop) => ({
      dismissable: true,
      title: 'CONFIRM DELETE',
      component: 'delete2Modal',
      props: { item, itemType },
      onClose: (output: unknown) => cb(output)
    }),
    markMatchedReminders: () => ({
      dismissable: true,
      title: 'ASSOCIATED REMINDERS FOUND',
      component: 'markMatchedRemindersModal',
      onClose: (output: unknown) => output
    })
  };

  /**
   * Creation modals.
   */
  private create = {
    activityReportRequest: () => ({
      dismissable: true,
      title: 'CREATE ACTIVITY REPORT REQUEST',
      component: 'createActivityReportRequestModal',
      // props: { instId },
      onClose: () => this.activityReportsMonitor.check()
    }),
    aggregateReportRequest: () => ({
      dismissable: true,
      title: 'CREATE EVALUATION AGGREGATE REPORT REQUEST',
      component: 'createAggregateReportRequestModal',
      // props: { instId },
      onClose: () => this.aggregateReportsMonitor.check()
    }),
    bulkClients: (instId: Institution['id']) => ({
      dismissable: true,
      title: 'BULK CLIENT CREATION',
      component: 'bulkCreateClients',
      props: { instId },
      onClose: () => this.$store.dispatch('clients/list', true)
    }),
    calculateEvaluationStats: () => ({
      dismissable: true,
      size: 'lg',
      title: 'CALCULATE EVALUATION STATS',
      component: 'calculateEvaluationStatsModal',
      // props: { instId },
      onClose: () => this.$state.go('evaluationStats')
    }),
    client: (institution?: Institution) => ({
      dismissable: true,
      title: 'CREATE CLIENT',
      size: 'lg',
      component: 'createClientModal',
      props: { institution },
      onClose: () => this.$store.dispatch('clients/list', true)
    }),
    clientRequest: (institutions: Institution[]) => ({
      dismissable: true,
      title: 'CREATE CLIENT REQUEST',
      component: 'createClientRequestModal',
      props: institutions,
      onClose: () => this.$rootScope.$broadcast('reloadDashboard')
    }),
    evaluationRequest: (clients: Client[], cb = angular.noop) => ({
      dismissable: true,
      title: 'CREATE EVALUATION REQUEST',
      component: 'createEvaluationRequestModal',
      props: { clients },
      onClose: (output) => cb(output)
    }),
    institution: (userId: User['id']) => ({
      dismissable: true,
      title: 'CREATE INSTITUTION',
      size: 'lg',
      component: 'createInstitutionModal',
      props: userId,
      onClose: () => this.$store.dispatch('institutions/getAll', true)
    }),
    managedPolicy: () => ({
      dismissable: true,
      title: 'CREATE MANAGED POLICY',
      component: 'createManagedPolicyModal',
      onClose: () => null
    }),
    policy: () => ({
      dismissable: true,
      title: 'CREATE POLICY',
      size: 'full',
      component: 'createPolicyModal',
      onClose: () => null
    }),
    reminder: (props) => ({
      dismissable: true,
      title: 'CREATE REMINDER',
      component: 'createReminderModal',
      props,
      onClose: () => null
    }),
    bulkEvaluations: (instId: Institution['id']) => ({
      dismissable: true,
      title: 'BULK EVALUATION CREATION',
      component: 'bulkCreateEvaluations',
      props: { instId },
      onClose: () => this.$store.dispatch('evaluations/list', true)
    })
  };

  /**
   * Edit modals.
   */
  private edit = {
    client: (client: Client) => ({
      dismissable: true,
      title: 'EDIT CLIENT',
      size: 'lg',
      component: 'editClientModal',
      props: { client },
      onClose: () => this.$store.dispatch('clients/list', true)
    }),
    evaluationRequest: (
      tools,
      users: User[],
      evalRequest,
      cb = angular.noop
    ) => ({
      dismissable: true,
      title: 'EDIT EVALUATION REQUEST',
      component: 'editEvaluationRequestModal',
      props: { tools, users, evalRequest },
      onClose: (output: unknown) => cb(output)
    }),
    institution: (institution: Institution) => ({
      dismissable: true,
      title: 'EDIT INSTITUTION',
      size: 'lg',
      component: 'editInstitutionModal',
      props: { institution },
      onClose: () => this.$store.dispatch('institutions/getAll', true)
    }),
    institutionSuperAdmin: (institution: Institution) => ({
      dismissable: true,
      props: { institution },
      title: 'UPDATE INSTITUTION SUPER ADMIN',
      component: 'editInstitutionSuperAdminModal'
    }),
    offenseClassification: (instId: Institution['id'], oc, section, ocId) => ({
      dismissable: true,
      title: 'EDIT OFFENSE CLASSIFICATION',
      component: 'editOffenseClassificationModal',
      props: { instId, oc, section, ocId }
      // onClose: () => this.$store.dispatch('institutions/getAll', true)
    }),
    policy: (policy) => ({
      dismissable: true,
      title: 'EDIT POLICY',
      size: 'lg',
      component: 'editPolicyModal',
      props: { policy }
    }),
    user: (userId: User['id'], user: User) => ({
      dismissable: true,
      title: !user.institution ? 'EDIT USER' : 'EDIT USER POLICY',
      component: 'editUserModal',
      props: { userId, user },
      onClose: () => {
        // this.$rootScope.$broadcast('reloadDashboard');
        this.$store.dispatch('users/list');
      }
    }),
    reminder: (reminder) => ({
      dismissable: true,
      title: 'EDIT REMINDER',
      component: 'editReminderModal',
      props: { reminder },
      onClose: () => null
    }),
    transferAdmin: (inst: Institution, groupId, users) => ({
      dismissable: true,
      title: 'TRANSFER SUPER ADMIN',
      component: 'transferSuperAdminModal',
      props: { inst, groupId, users },
      onClose: () => null
    }),
    invite: (invite) => ({
      dismissable: true,
      title: 'UPDATE INVITE',
      component: 'editInviteModal',
      props: { invite },
      onClose: (invite) => this.$store.dispatch('invitations/update', invite)
    })
  };

  /**
   * Invite modals.
   */
  private invite = {
    invite: (userId: User['id']) => ({
      dismissable: true,
      title: 'INVITE USER',
      component: 'inviteModal',
      props: userId,
      // onClose: () => this.$rootScope.$broadcast('reloadDashboard')
      onClose: () => this.$store.dispatch('invitations/list', true)
    })
  };

  /**
   * Evaluation modals.
   */
  private evaluation = {
    agreement: (evaluation, cb = angular.noop) => ({
      dismissable: true,
      title: 'EVALUATION AGREEMENT',
      component: 'agreementModal',
      props: { evaluation },
      onDismiss: () => cb(),
      onClose: (output) => cb(output)
    }),
    chooseReassessmentOptionType: () => ({
      dismissable: false,
      title: 'CHOOSE REASSESSMENT OPTION TYPE',
      component: 'chooseReassessmentOptionTypeModal',
      onClose: (output) => output
    }),
    dateCalculator: () => ({
      dismissable: true,
      title: 'DATE CALCULATOR',
      component: 'dateCalculatorModal'
    }),
    definition: (term) => ({
      dismissable: true,
      title: 'DEFINITION: ' + this.$filter('capitalize')(term.term),
      component: 'definitionModal',
      props: term
    }),
    emailReport: (users: User[], reportId, cb = angular.noop) => ({
      dismissable: true,
      title: 'RECIPIENTS',
      component: 'emailReportModal',
      props: { users, reportId },
      onClose: (output) => cb(output)
    }),
    report: (evaluation, cb = angular.noop) => ({
      dismissable: true,
      title: 'GENERATE REPORT',
      component: 'reportModal',
      props: evaluation,
      onClose: (output) => cb(output)
    }),
    revertWarning: (evaluation, cb = angular.noop) => ({
      dismissable: true,
      backdrop: 'static',
      title: 'REVERT EVALUATION WARNING',
      component: 'revertEvaluationWarning',
      props: evaluation,
      onClose: (output) => cb(output)
    }),
    selectClient: (clients: Client[], cb = angular.noop) => ({
      dismissable: true,
      title: 'SELECT CLIENT',
      size: 'lg',
      component: 'selectClientModal',
      props: { clients },
      onClose: (output) => cb(output)
    }),
    startAssessment: (evaluations, client: Client, tool) => ({
      dismissable: true,
      title: 'START ASSESSMENT',
      component: 'startAssessmentModal',
      size: 'lg',
      props: { evaluations, client, tool }
    }),
    wizard: (itemWizard, dictionary, pdf) => ({
      dismissable: true,
      title: `ITEM WIZARD - ${
        itemWizard.riskFactor ? itemWizard.riskFactor : itemWizard.id
      }`,
      component: 'wizardModal',
      props: { itemWizard, dictionary, pdf }
    }),
    lscmiComparativeReportChoices: (evaluations) => ({
      dismissable: false,
      title: 'LS/CMI COMPARATIVE REPORT CHOICES',
      component: 'lscmiComparativeReportChoicesModal',
      props: { evaluations },
      onClose: (output) => output
    }),
    lscmiProfileReportChoices: () => ({
      dismissable: true,
      title: 'LS/CMI PROFILE REPORT CHOICES',
      component: 'lscmiProfileReportChoicesModal',
      onClose: (output) => output
    }),
    ylscmiComparativeReportChoices: (evaluations) => ({
      dismissable: false,
      title: 'YLS/CMI COMPARATIVE REPORT CHOICES',
      component: 'ylscmiComparativeReportChoicesModal',
      props: { evaluations },
      onClose: (output) => output
    }),
    ylsProfileReportChoices: () => ({
      dismissable: true,
      title: 'YLS PROFILE REPORT CHOICES',
      component: 'ylsProfileReportChoicesModal',
      onClose: (output) => output
    }),
    harePCLRProfileReportChoices: () => ({
      dismissable: true,
      title: 'Hare PCLR PROFILE REPORT CHOICES',
      component: 'harepclrProfileReportChoicesModal',
      onClose: (output) => output
    }),
    lsirProfileReportChoices: () => ({
      dismissable: true,
      title: 'LSI-R PROFILE REPORT CHOICES',
      component: 'lsirProfileReportChoicesModal',
      onClose: (output) => output
    }),
    acesProfileReportChoices: () => ({
      dismissable: true,
      title: 'ACE Questionnaire PROFILE REPORT CHOICES',
      component: 'acesProfileReportChoicesModal',
      onClose: (output) => output
    }),
    manageMedia: ({ institutionId, subGroupId, clientId, evaluationId }) => ({
      dismissable: true,
      size: 'lg',
      title: 'MANAGE EVALUATION MEDIA',
      component: 'manageMediaModal',
      props: { institutionId, subGroupId, clientId, evaluationId }
      // onClose: output => output
    }),
    offenderHistory: (client: Client, tool, offenseClassifications) => ({
      dismissable: true,
      size: 'lg',
      title: 'OFFENDER HISTORY',
      // templateUrl:
      //   'evaluation/offender-history-modal/offender-history.html',
      component: 'offenderHistoryModal',
      props: { client, tool, offenseClassifications }
    }),
    importRequestValueGuide: (tool) => ({
      dismissable: true,
      size: 'lg',
      title: 'IMPORT REQUEST VALUE GUIDE',
      component: 'importRequestValueGuideModal',
      props: { tool },
      onClose: (output) => output
    }),
    importRequestDetails: (request) => ({
      dismissable: true,
      size: 'full',
      title: 'IMPORT REQUEST DETAILS',
      component: 'importRequestDetailsModal',
      props: { request },
      onClose: (output) => output
    })
  };

  /**
   * User settings modals.
   */
  private settings = {
    changePassword: () => ({
      dismissable: true,
      title: 'CHANGE PASSWORD',
      component: 'changePasswordModal'
    }),
    chooseActiveInstitution: ({ dismissable = true } = {}) => ({
      dismissable,
      title: 'CHOOSE INSTITUTION',
      component: 'chooseActiveInstitutionModal',
      onClose: (output: 'noInstitution' | Institution | null) => output
    }),
    chooseClient: (clients: Client[]) => ({
      dismissable: true,
      backdrop: 'static',
      keyboard: false,
      title: 'CHOOSE CLIENT',
      component: 'chooseClientModal',
      props: clients
    }),
    confirmClient: (client: Client, prevFcn) => ({
      dismissable: false,
      backdrop: 'static',
      keyboard: false,
      title: 'CONFIRM CLIENT',
      component: 'confirmClientModal',
      props: { client, prevFcn }
    }),
    expungeClient: (expungeId, client: Client, requestOngoing) => ({
      dismissable: false,
      backdrop: 'static',
      keyboard: false,
      title: 'EXPUNGE CLIENT',
      component: 'expungeClientModal',
      props: { client, expungeId, requestOngoing }
    }),
    logoutWarning: () => ({
      dismissable: false,
      backdrop: 'static',
      keyboard: false,
      title: 'INACTIVITY WARNING',
      component: 'logoutWarningModal'
    }),
    message: (title: string, message: string) => ({
      dismissable: true,
      backdrop: 'static',
      keyboard: false,
      title: title,
      props: { message },
      component: 'messageModal'
    }),
    openPDF: (page, title, pdf) => ({
      dismissable: true,
      backdrop: 'static',
      title: title,
      size: 'lg',
      component: 'openPdfModal',
      props: { page, pdf }
    }),
    reloadPage: (message: string, title: string) => ({
      dismissable: true,
      backdrop: 'static',
      keyboard: false,
      title,
      component: 'reloadPageModal',
      props: message
    }),
    settings: () => ({
      dismissable: true,
      title: 'USER SETTINGS',
      component: 'settingsModal'
    }),
    smsCodeInputModal: () => ({
      dismissable: true,
      title: 'VERIFICATION CODE',
      component: 'smsCodeInputModal',
      backdrop: 'static',
      onClose: (output) => output
    }),
    termsAndConditions: () => ({
      dismissable: false,
      backdrop: 'static',
      keyboard: false,
      title: 'TERMS & CONDITIONS',
      size: 'lg',
      component: 'termsAndConditionsModal',
      onClose: (output: boolean | null) => output
    }),
    // TODO: Dane -- shift to permissions ref
    tutorialVideo: (role) => ({
      dismissable: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg',
      title: this.$filter('uppercase')(role.roleName) + ' TUTORIAL VIDEO',
      component: 'tutorialVideoModal',
      props: role
    }),
    warning: (message: string, object, cb = angular.noop) => ({
      dismissable: true,
      backdrop: 'static',
      keyboard: false,
      title: 'WARNING',
      props: { message, object },
      component: 'warningModal',
      onClose: (proceed) => cb(proceed)
    })
  };

  /**
   * Login modals.
   */
  private login = {
    login: (cb = angular.noop) => ({
      dismissable: true,
      title: 'LOGIN',
      component: 'loginModal',
      onClose: (output) => (cb ? cb(output) : output)
    })
  };

  /**
   * Tool modals.
   */
  private tool = {
    setToolPrice: (tool) => ({
      dismissable: true,
      title: 'TOOL COST',
      component: 'setToolPriceModal',
      props: tool,
      onClose: () => this.$rootScope.$broadcast('reloadDashboard')
    }),
    selectTerm: (terms, cb = angular.noop) => ({
      dismissable: true,
      title: 'SELECT TERM',
      component: 'selectTermModal',
      props: terms,
      onClose: (output) => (cb ? cb(output) : output)
    }),
    selectTool: (block) => ({
      dismissable: true,
      title: 'SELECT TOOL',
      props: block,
      component: 'selectToolModal',
      onClose: (output) => {
        return output;
      }
    }),
    chooseReportTemplate: (tool, templs, insts, cb = angular.noop) => ({
      dismissable: true,
      title: 'CHOOSE GROUP',
      component: 'chooseReportTemplateModal',
      props: { tool, reportTemplates: templs, institutions: insts },
      onClose: (output) => (cb ? cb(output) : output)
    }),
    agreement: (agreement) => ({
      dismissable: true,
      title: 'TOOL AGREEMENT',
      component: 'toolAgreementModal',
      props: { agreement }
    }),
    changelog: (changelog) => ({
      dismissable: true,
      title: 'TOOL CHANGELOG',
      component: 'toolChangelogModal',
      props: changelog
    }),
    description: (tool) => ({
      dismissable: true,
      size: 'lg',
      title: tool.name.toUpperCase() + ' DESCRIPTION',
      component: 'descriptionModal',
      props: tool
    }),
    uploadReportTemplate: (submission, editorFile) => ({
      dismissable: true,
      title: 'UPDATE REPORT TEMPLATE',
      component: 'uploadReportTemplateModal',
      props: { submission, editorFile },
      onClose: (output) => {
        if (!output.success) {
          return;
        }

        this.$ls.remove('working-tool');
        this.$ls.remove('working-tool-connectors');

        this.$state.go('dashboard', { referrer: output });
      }
    }),
    uploadTool: (submission, tool, onlyPDF, cb = angular.noop) => ({
      dismissable: true,
      title: 'UPDATE TOOL & PDFs',
      component: 'uploadTool',
      props: { submission, tool, onlyPDF },
      onClose: (output) => {
        if (onlyPDF || output === 'cancelled') {
          cb(output);

          return;
        }

        this.$ls.remove('working-tool');
        this.$ls.remove('working-tool-connectors');
        this.$state.go('dashboardTools');
      }
    }),
    selectExistingImage: (elem, toolId) => ({
      dismissable: true,
      title: 'SELECT IMAGE',
      component: 'selectExistingImageModal',
      props: toolId,
      size: 'lg',
      onClose: (output) => {
        elem.$parent.field.model = output.name;
      }
    }),
    viewImage: (url) => ({
      dismissable: true,
      title: 'VIEW IMAGE',
      component: 'viewImageModal',
      props: url,
      size: 'lg'
    }),
    askWorkingTool: (tool, cb = angular.noop) => ({
      dismissable: true,
      title: 'TOOL IN PROGRESS',
      component: 'askWorkingToolModal',
      props: tool,
      onClose: (output) => (cb ? cb(output) : output)
    }),
    casePlanPreCheck: (item) => ({
      dismissable: true,
      size: 'lg',
      title: 'CASE PLAN MANAGEMENT PRE CHECK',
      component: 'casePlanPreCheck',
      props: item
    }),
    commitsViewer: (tool, referrer, cb = angular.noop) => ({
      dismissable: true,
      size: 'lg',
      title: 'COMMITS VIEWER',
      component: 'commitsViewerModal',
      props: { tool, referrer },
      onClose: (output) => (cb ? cb(output) : output)
    }),
    institutionCustomization: (toolId, toolCommitId, customization) => ({
      dismissable: true,
      size: 'lg',
      title: 'INSTITUTION TOOL CUSTOMIZER',
      component: 'institutionCustomizationModal',
      props: { toolId, toolCommitId, customization },
      onClose: (output) => {
        return output;
      }
    }),
    institutionOffenderHistoryCustomization: (toolData, offenderHistory) => ({
      dismissable: true,
      size: 'full',
      title: 'INSTITUTION TOOL OFFENDER HISTORY CUSTOMIZER',
      component: 'institutionOffenderHistoryCustomizationModal',
      props: { toolData, offenderHistory },
      onClose: (output) => {
        return output;
      }
    })
  };

  /**
   * Test modals.
   */
  private test = {
    clientProfile: (userId, client: Client[]) => ({
      dismissable: true,
      title: 'CLIENT PROFILE',
      component: 'clientProfileModal',
      props: { userId, client },
      size: 'lg',
      onClose: () => this.$store.dispatch('clients/list', true)
    }),
    generalForm: (title, items) => ({
      dismissable: true,
      title,
      component: 'generalFormModal',
      props: { items }
    }),
    createTabelItem: (itemProps, item) => ({
      dismissable: true,
      title: !item ? 'ADD TABLE ITEM' : 'EDIT TABLE ITEM',
      component: 'addTableItemModal',
      props: { itemProps, item },
      size: 'lg'
    })
  };

  /**
   * Utility modals.
   */
  private util = {
    generalEdit: (items) => ({
      dismissable: true,
      title: 'EDIT',
      component: 'generalEditModal',
      props: items
    }),
    generalChoice: (
      items,
      type = 'default',
      title,
      orderBy,
      reverse = true
    ) => ({
      dismissable: true,
      title: title ? title : 'CHOOSE',
      component: 'generalChoiceModal',
      props: { items, type, orderBy, reverse }
    }),
    policyEditor: (policy, lockInstitution = false) => ({
      dismissable: true,
      maximisable: true,
      title: 'POLICY EDITOR',
      component: 'policyEditorModal',
      props: { policy, lockInstitution },
      size: 'full'
    }),
    policyJson: (policy) => ({
      dismissable: true,
      maximisable: true,
      title: 'POLICY JSON',
      component: 'policyJsonModal',
      props: { policy },
      size: 'lg'
    }),
    selectEvaluation: (evals, filters, multiSelect) => ({
      dismissable: true,
      title: multiSelect ? 'SELECT EVALUATIONS' : 'SELECT EVALUATION',
      component: 'selectEvaluationModal',
      props: { evals, filters, multiSelect },
      size: 'lg'
    }),
    subGroupClientTransferHere: (subGroup: Subgroup, clients: Client[]) => ({
      dismissable: true,
      title: 'TRANSFER TO CURRENT SUBGROUP',
      component: 'subGroupClientTransferHereModal',
      props: { subGroup, clients },
      onClose: (clientIds: Client['id'][]) => clientIds
    }),
    subGroupClientTransfer: (
      subGroups: Subgroup[],
      clientId?: Client['id'],
      clients?: Client[]
    ) => ({
      dismissable: true,
      title: 'SUBGROUP CLIENT(S) TRANSFER',
      component: 'subGroupClientTransferModal',
      props: { clientId, subGroups, clients },
      onClose: (subGroupId: Subgroup['id']) => subGroupId
    }),
    reportBug: () => ({
      dismissable: true,
      title: 'REPORT BUG',
      component: 'reportBugModal',
      size: 'lg'
    })
  };

  /**
   * Other modals.
   */
  private other = {
    activityLogColumnsDefinitions: () => ({
      dismissable: true,
      title: 'COLUMNS DEFINITIONS',
      component: 'activityLogColumnsDefinitionsModal',
      size: 'lg'
    }),
    addClientToSubgroup: (itemProps, subgroup) => ({
      dismissable: true,
      title: 'ADD CLIENT TO SUBGROUP',
      component: 'addClientToSubgroupModal',
      props: subgroup,
      size: 'lg'
    }),
    uploadFile: (url: string) => ({
      dismissable: true,
      title: 'UPLOAD FILE',
      component: 'uploadFileModal',
      props: { url }
      // size: 'lg'
    }),
    fileViewer: (file: Blob) => ({
      dismissable: true,
      // title: 'F FILE',
      component: 'fileViewerModal',
      props: { file },
      size: 'auto'
    }),
    genericNotice: ({ title, text }) => ({
      dismissable: false,
      title,
      component: 'genericNoticeModal',
      props: { text }
      // size: 'auto'
    }),
    genericPopup: ({
      title,
      text,
      dismissable = true
    }: {
      title: string;
      text: string;
      dismissable?: boolean;
    }) => ({
      dismissable,
      title,
      component: 'genericPopupModal',
      props: { text }
      // size: 'auto'
    })
  };

  /**
   * Delete modals.
   */
  private delete = {
    removeUserFromInstitution: (userId) => ({
      dismissable: true,
      title: 'REMOVE USER FROM INSTITUTION',
      component: 'removeUserFromInstitutionModal',
      props: { userId },
      onClose: () => null
    })
  };
}
