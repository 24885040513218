'use strict';

function InstanceType (obj) {
  return obj.constructor.name;
}

function MathOperation (a, b, operator) {
  let result;

  switch(operator) {
    case '+':
      result = a + b;
      break;
    case '-':
      result = a - b;
      break;
    case '*':
      result = a * b;
      break;
    case '/':
      result = a / b;
      break;
  }

  return result;
}

function CompareOperation (a, b, operator) {
  let result;

  switch(operator) {
    case '<':
      result = a < b;
      break;
    case '>':
      result = a > b;
      break;
    case '<=':
      result = a <= b;
      break;
    case '>=':
      result = a >= b;
      break;
    case '==':
      result = a == b;
      break;
  }

  return result;
}

class Proration {
  constructor(config) {
    this.ops = config.ops || [];
    this.constants = config.constants || [];

    this.input = config.input || _.find(this.ops, item => {
      return InstanceType(item) === 'InputOp';
    });

    this.output = config.output || _.find(this.ops, item => {
      return InstanceType(item) === 'OutputOp';
    });

    this.constants.forEach(item => {
      var opObj = _.find(this.ops, {label: item.label});

      if (opObj) {
        opObj.result = item.value;
      }
    });

    console.debug('New Proration - ', this);
  }

  eval() {
    var getOp = varId => {
      return _.find(this.ops, {varId: varId});
    };

    var inputOp = this.input;
    var outputOp = this.output;

    var itr = 1;
    return (function func (op, prevOp) {
      console.debug('Op - ' + (itr++), op);

      if (!op || Object.getPrototypeOf(op.constructor).name != 'Op') {
        console.error('Proration: Provided value for the operation was invalid.', op);
        return;
      }

      let nextOp;

      let opType = InstanceType(op);

      switch(opType) {
        case 'InputOp':
          op.result = getOp(op.inputCompId).result;
          nextOp = getOp(op.outputCompId);

          break;
        case 'ValueOp':
          op.result = MathOperation(prevOp.result, op.value, op.operator);
          nextOp = getOp(op.nextVarId);

          console.debug('RESULT: ' + (prevOp.result) + op.operator + (op.value) + ' = ', op.result);

          break;
        case 'UniOp':
          op.result = Math[op.operator](prevOp.result);
          nextOp = getOp(op.nextVarId);

          console.debug('RESULT: Math.' + (op.operator) + '(' + prevOp.result + ')' + ' = ', op.result);

          break;
        case 'BinaryOp':
          let opA = getOp(op.variable1);
          let opB = getOp(op.variable2);

          let a, b;

          if (opA == prevOp) {
            a = prevOp.result;
            b = !opB.result ? func(opB, op) : opB.result;
          } else if (opB == prevOp) {
            a = !opA.result ? func(opA, op) : opA.result;
            b = prevOp.result;
          } else {
            a = !opA.result ? func(opA, op) : opA.result;
            b = !opB.result ? func(opB, op) : opB.result;
          }

          op.result = MathOperation(a, b, op.operator);
          nextOp = getOp(op.nextVarId);

          console.debug('RESULT: ' + (a) + op.operator + (b) + ' = ', op.result);

          break;
        case 'ComparatorOp':
          op.result = prevOp.result;

          var check = CompareOperation(op.result, op.compareValue, op.operator);
          nextOp = getOp(check == true ? op.nextVarTrueId : op.nextVarFalseId);

          console.log('Compare RESULT: ' + (op.result) + op.operator + (op.compareValue), check);

          break;
        case 'OutputOp':
          op.result = Math.round(prevOp.result);

          break;
        default:
          console.warn('Operation was not a recognized type.');
      }

      return op === outputOp ? op.result : func(nextOp, op);
    })(inputOp);
  }
}

export default function ProrateService() {
  'ngInject';
  return (config) => {
    new Proration(config).eval();
  };
}
