import shortId from 'shortid';
import {
  indexOf,
  find,
  filter,
  findIndex,
  noop,
  sortBy,
  remove,
  uniqBy
} from 'lodash';

type ColumnType = 'dropdown' | 'enum' | 'intervention';

type TableItem = { [key: string]: unknown };

export interface ColumnConstructorOptions<T> {
  title: string;
  key: keyof T;
  type?: ColumnType | { value: ColumnType };
  options?: unknown[];
  template?: unknown;
  action?: unknown;
  sortable?: boolean;
  progressSection?: unknown;
  tooltip?: unknown;
}

export interface SectionConstructorOptions {
  key?: string;
  title?: string;
}

export interface TableViewConstructorOptions<T> {
  title?: string;
  key?: string;
  items?: T[];
  sections?: SectionConstructorOptions[];
  cols?: ColumnConstructorOptions<T>[];
  pages?: unknown[];
  itemsPerPage?: number;
  currentPage?: number;
  loader?: () => void;
  hasSearchBar?: boolean;
  editable?: boolean;
  hideTitle?: boolean;
  addAction?: () => void;
  editAction?: () => void;
  removeAction?: () => void;
  metaInformation?: unknown;
  sortingBy?: string;
  sortingReversed?: boolean;
  progressReference?: TableView;
  columnsReference?: TableView;
}

function moveArray<T>(arr: T[], oldIndex: number, newIndex: number) {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k--) arr.push(undefined);
  }

  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

  return arr;
}

/**
 * ...
 */
class Column<T> {
  title: string;
  key: keyof T;
  type: ColumnType | { value: string } | undefined;
  options: unknown[] | undefined = [];
  template: unknown | undefined;
  action: unknown | undefined;
  sortable: boolean;
  progressSection: unknown;
  tooltip: unknown | undefined;

  constructor(config: ColumnConstructorOptions<T>) {
    if (!config.title) {
      throw new Error('A dynamic table column must be provided a title.');
    }

    if (!config.key) {
      throw new Error('A dynamic table column must be provided a key.');
    }

    this.title = config.title;
    this.key = config.key;
    this.type = config.type || undefined;
    this.options = config.options || [];
    this.template = config.template || undefined;
    this.action = config.action || undefined;
    this.sortable = config.sortable === true ? config.sortable : false;
    this.progressSection =
      typeof config.progressSection === 'boolean'
        ? config.progressSection
        : false;
    this.tooltip = config.tooltip ? config.tooltip : undefined;
  }
}

/**
 * ...
 */
export class DataItem<T extends Record<any, any>> {
  item: T;
  show: boolean;
  section: unknown | null;

  constructor(data: T, show = true, section: unknown = null) {
    this.item = data;
    this.show = show;
    this.section = section;
  }
}

/**
 * ...
 */
export class Section {
  key: string | null;
  title: string | null;

  constructor(options: SectionConstructorOptions) {
    if (!options.key)
      options.key = options.title ? options.title : shortId.generate();

    this.key = options.key ?? null;
    this.title = options.title ?? null;
  }
}

/**
 * ...
 */
export class TableView<T> {
  title: string;
  key: string;
  items: DataItem<T>[] = [];
  sections: Section[] = [];
  cols: Column<T>[] = [];
  pages: unknown[] = [];
  itemsPerPage = 25;
  currentPage = 1;
  loader: () => any = () => {};
  hasSearchBar = true;
  editable = false;
  hideTitle = false;
  addAction: (() => void) | undefined;
  editAction: (() => void) | undefined;
  removeAction: (() => void) | undefined;
  metaInformation: unknown;
  sortingBy: string | null = '';
  sortingReversed = false;
  progressReference: unknown | null;
  columnsReference: unknown | null;

  get itemCount() {
    return this.items.length ?? this.sections.length ?? 0;
  }

  get pageCount() {
    return this.pages.length ?? 0;
  }

  get pageItems() {
    return this.pages[this.currentPage - 1];
  }

  get getSections() {
    return this.sections;
  }

  constructor(config: TableViewConstructorOptions<T>) {
    this.title = config.title || 'Unnamed Table';
    this.key = config.key || shortId.generate();

    let items: any[] = config.items ?? [];

    // Check if we're given an existing table with items that have items nested.
    if (items.length && items[0]?.item && typeof items[0].item === 'object') {
      items = items.map(({ item }) => item);
    }

    // this.sections = config.sections ?? [];
    this.itemsPerPage = config.itemsPerPage ?? 25;
    this.currentPage = config.currentPage ?? 1;
    this.loader = config.loader ?? noop;

    this.hasSearchBar =
      typeof config.hasSearchBar === 'boolean' ? config.hasSearchBar : true;
    this.editable =
      typeof config.editable === 'boolean' ? config.editable : false;
    this.hideTitle =
      typeof config.hideTitle === 'boolean' ? config.hideTitle : false;

    this.addAction = config.addAction ?? undefined;
    this.editAction = config.editAction ?? undefined;
    this.removeAction = config.removeAction ?? undefined;
    this.metaInformation = config.metaInformation ?? null;
    this.sortingBy = config.sortingBy ?? null;
    this.sortingReversed =
      typeof config.sortingReversed === 'boolean'
        ? config.sortingReversed
        : false;

    this.progressReference = config.progressReference || null;
    this.columnsReference = config.columnsReference || null;

    if (items.length) {
      this.addItems(items);
    }

    if (this.sortingBy) {
      this.items.sort((a, b) => {
        let valA = a.item[this.sortingBy!];
        let valB = b.item[this.sortingBy!];

        return valA > valB ? -1 : valA < valB ? 1 : 0;
      });

      if (this.sortingReversed) this.items.reverse();
    }

    if (config.sections?.length) {
      this.addSections(config.sections);
    }

    if (config.cols) {
      for (const colOptions of config.cols) {
        this.column(colOptions);
      }
    }
  }

  /**
   * ...
   *
   * @return ...
   */
  columnsSection() {
    // should only be one column, otherwise we would need multiple titles per section
    return find(this.cols, { progressSection: true });
  }

  /**
   * ...
   *
   * @return ...
   */
  generateProgressSections() {
    let column = this.columnsSection();
    let columnKey = column ? column.key : null;

    if (!columnKey) return { error: 'No Column Section Found' };

    let sections = [];

    for (const item of this.items) {
      let option = find(column!.options, { value: item.item[columnKey] });

      if (!option) return { error: 'No Option Found' };

      sections.push({ key: option.value, title: option.label });
    }

    if (this.progressReference?.sections) {
      for (const sect of this.progressReference.sections) {
        sections.push(sect);
      }

      sections = uniqBy(sections, 'key');

      this.progressReference.sections = [];
      this.progressReference.addSections(sections);
    }

    return null;
  }

  /**
   * ...
   *
   * @return ...
   */
  removeProgressSection(item: DataItem<T>) {
    let column = this.columnsSection();
    let columnKey = column ? column.key : null;

    if (!columnKey) return { error: 'No Column Section Found' };

    remove(this.progressReference.sections, { key: item.item[columnKey] });

    return null;
  }

  /**
   * ...
   *
   * @return ...
   */
  autoSort() {
    // this.items.sort((a, b) => {
    //   let valA = a.item[this.sortingBy];
    //   let valB = b.item[this.sortingBy];
    //
    //   return valA > valB ? -1 : valA < valB ? 1 : 0;
    // });

    if (!this.sortingBy) return;

    this.items = sortBy(this.items, ({ item }) => item[this.sortingBy!]);

    if (this.sortingReversed) this.items.reverse();
  }

  /**
   * ...
   *
   * @return ...
   */
  item(item: T) {
    this.items.push(new DataItem(item));

    if (this.sortingBy) this.autoSort();

    this.createPages();

    return this;
  }

  /**
   * ...
   *
   * @return ...
   */
  section(options: SectionConstructorOptions) {
    this.sections.push(new Section(options));
    this.createPages();

    return this;
  }

  /**
   * ...
   *
   * @param options ...
   * @return ...
   */
  column(options: ColumnConstructorOptions<T>) {
    options = { ...options };

    if (typeof options.type === 'object') {
      if (!options.options?.length) {
        options.options = options.type.options;
      }

      if (options.type.value === 'dropdown' || options.type.value === 'enum') {
        options.type = 'enum';
      }

      if (options.type.value === 'intervention') {
        options.type = 'intervention';
      }
    }

    const sortable =
      typeof options.sortable === 'boolean'
        ? options.sortable
        : options.type == 'string' ||
          options.type == 'number' ||
          options.type == 'boolean';

    this.cols.push(new Column({ ...options, sortable }));

    return this;
  }

  /**
   * ...
   *
   * @return ...
   */
  sectionItems(options: SectionConstructorOptions) {
    return filter(this.items, (item) => item.item.section === options.key);
  }

  /**
   * ...
   *
   * @return ...
   */
  addSections(options: SectionConstructorOptions[]) {
    this.sections = options.map((opts) => new Section(opts));

    return this;
  }

  /**
   * ...
   *
   * @return ...
   */
  addItems(items: T[]) {
    this.items = items.map((item) => new DataItem(item));

    return this;
  }

  /**
   * ...
   *
   * @return ...
   */
  setItems(items: T[]) {
    this.addItems(items);
    this.createPages();

    return this;
  }

  /**
   * ...
   *
   * @return ...
   */
  moveItem(item: TableItem, direction: 'up' | 'down') {
    let oldIndex = indexOf(this.items, item);
    let newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1;
    this.items = moveArray(this.items, oldIndex, newIndex);

    if (this.columnsReference) {
      oldIndex = findIndex(this.columnsReference.cols, {
        title: item.item.title
      });

      newIndex = direction === 'up' ? oldIndex - 1 : oldIndex + 1;

      this.columnsReference.cols = moveArray(
        this.columnsReference.cols,
        oldIndex,
        newIndex
      );
    }

    this.createPages();
  }

  /**
   * ...
   *
   * @return ...
   */
  async load() {
    var data = await this.loader();

    if (!Array.isArray(data)) {
      data = [];

      console.warn(
        `The loader function for the table "${this.title}" did not return an array.`
      );
    }

    this.items = [];
    this.addItems(data);
    this.createPages();
  }

  /**
   * ...
   *
   * @return ...
   */
  createPages() {
    this.pages = [];
    this.currentPage = 1;

    let addPage = () => this.pages.push([]);
    let addToPage = (num, item) => this.pages[num - 1].push(item);

    if (!this.itemsPerPage) {
      this.itemsPerPage = this.itemCount;
    }

    addPage();

    this.items.forEach((item, i) => {
      if (item.show) {
        addToPage(this.pageCount, item);

        if (i < this.itemCount - 1 && (i + 1) % this.itemsPerPage == 0) {
          addPage();
        }
      }
    });
  }

  /**
   * ...
   *
   * @return ...
   */
  clear() {
    this.items = [];
    this.pages = [];
  }
}

export default TableView;
