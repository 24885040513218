'use strict';
import * as _ from "lodash";

const angular = require('angular');

/*@ngInject*/
export function connectionOptionsFilterFilter() {
  return function(input, nodeId) {
    let connections = [];
    _.forEach(input, function(connector) {
      if (nodeId.includes('Output')) {
        if (connector.outputNode === nodeId) {
          connections.push(connector);
        }
      } else if (nodeId.includes('Input')) {
        if (connector.inputNode === nodeId) {
          connections.push(connector);
        }
      }
    });
    return connections;
  };
}


export default angular.module('app.connection-options-filter', [])
  .filter('connectionOptionsFilter', connectionOptionsFilterFilter)
  .name;
