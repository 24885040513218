'use strict';

import * as _ from 'lodash';

// -------------------------------------------------------------------------
// Classes
// -------------------------------------------------------------------------

// NOTE (Alex) changed .operator to .operatorSymbol for C# discrepancy 7/27/2018

class Op {
  constructor(config) {
    this.varId = config.varId;
    this.type = this.constructor.name;

    this.label = config.label || 'Op - ' + this.varId;
    this.result = null;
    this.operatorSymbol = config.operatorSymbol;
  }
}

export class ValueOp extends Op {
  constructor(config) {
    super(config);

    this.inputVarId = config.inputVarId;
    this.value = config.value;
    this.nextVarId = config.nextVarId;
  }
}

export class UniOp extends Op {
  constructor(config) {
    super(config);

    this.inputVarId = config.inputVarId;
    this.nextVarId = config.nextVarId;
  }
}

export class BinaryOp extends Op {
  constructor(config) {
    super(config);

    this.variable1 = config.variable1;
    this.variable2 = config.variable2;
    this.nextVarId = config.nextVarId;
  }
}

export class ComparatorOp extends Op {
  constructor(config) {
    super(config);

    this.compareValue = config.compareValue;
    this.inputCompId = config.inputCompId;
    this.nextVarTrueId = config.nextVarTrueId;
    this.nextVarFalseId = config.nextVarFalseId;
  }
}

export class InputOp extends Op {
  constructor(config) {
    super(config);

    this.inputCompId = config.inputCompId;
    this.outputCompId = config.outputCompId;
    // this.input;
  }
}

export class OutputOp extends Op {
  constructor(config) {
    super(config);

    this.inputCompIds = config.inputCompIds;
    // this.result;
  }
}

export class ConstantOp extends Op {
  constructor(config) {
    super(config);

    // this.value;
  }
}

// -------------------------------------------------------------------------
// Functions
// -------------------------------------------------------------------------

export function CreateVarId(block) {
  return block.blockId.replace('block-', '');
};

export function ForBlocksRows (blocks, iteratee) {
  var finished = false;

  _.forEach(blocks, block => {
    _.forEach(block.rows, row => {
      var finished = iteratee(row, block) === false ? true : false;

      if (finished) return false;
    });

    if (finished) return false;
  });
}

export function GetFieldModel(rows, identity) {
  var value;

  rows.forEach(row => {
    var field = _.find(row.fields, identity);

    if (field) {
      value = field.model;
      return false;
    }
  });

  return value;
};

function BlockManager(blocks) {
  this.blocks = blocks;

  var get = (id, attr) => {
    let connectionId;

    ForBlocksRows(blocks, (row, block) => {
      if (row[attr] === id) {
        connectionId = CreateVarId(block);

        return false;
      }
    });

    if (!connectionId) {
      console.warn('The block with input ID ' + id + ' was not found.');
    }

    return connectionId;
  };

  this.getInput = outputId => {
    return get(outputId, 'inputNodeId');
  };

  this.getOutput = inputId => {
    return get(inputId, 'outputNodeId');
  };
}

export function MakeOperationsExpression(blocks) {
  var operations = [];

  var blocksMngr = new BlockManager(blocks);

  _.forEach(blocks, op => {
    let varId = CreateVarId(op);

    var opObj;
    var opConfig = {
      varId
    };

    switch (op.type) {
      case 'variable':
        opConfig.label = _.find(op.rows[0].fields, {label: 'Variable'}).text;
        opObj = new ConstantOp(opConfig);

        operations.push(opObj);

        break;

      case 'wizard-variables':
        opConfig.label = op.rows[1].fields[0].model;
        opObj = new ConstantOp(opConfig);

        operations.push(opObj);

        break;

      case 'operator':
        opConfig.inputVarId = blocksMngr.getOutput(op.rows[1].inputIds[0]);
        opConfig.operatorSymbol = op.rows[2].fields[0].model.text;
        opConfig.value = op.rows[2].fields[1].model;
        opConfig.nextVarId = blocksMngr.getInput(op.rows[2].outputIds[0]);

        opObj = new ValueOp(opConfig);

        operations.push(opObj);

        break;

      case 'single-operator':
        _.forEach(op.rows, row => {
          if (row.fields[0].label == 'Variable') {
            opConfig.inputVarId = blocksMngr.getOutput(row.inputIds[0]);
          } else if (row.fields[0].label == 'Operator') {
            opConfig.operatorSymbol = row.fields[0].model.text;
            opConfig.nextVarId = blocksMngr.getInput(row.outputIds[0]);
          }
        });

        opObj = new UniOp(opConfig);

        operations.push(opObj);

        break;

      case 'binary-operator':
        _.forEach(op.rows, row => {
          if (row.fields[0].label == 'Variable 1') {
            opConfig.variable1 = blocksMngr.getOutput(row.inputIds[0]);

            if (!opConfig.variable1) {
              console.warn('Expression Composer - No connection provided for the Variable 1 input of binary node block ' + varId + '.');
            }
          } else if (row.fields[0].label == 'Variable 2') {
            console.debug('Variable 2', op);
            opConfig.variable2 = blocksMngr.getOutput(row.inputIds[0]);

            if (!opConfig.variable2) {
              console.warn('Expression Composer - No connection provided for the Variable 2 input of binary node block ' + varId + '.');
            }

            opConfig.nextVarId = blocksMngr.getInput(row.outputIds[0]);

            if (!opConfig.nextVarId) {
              console.warn('Expression Composer - No connection provided for the output of binary node block ' + varId + '.');
            }
          } else if (row.fields[0].label == 'Operator') {
            opConfig.operatorSymbol = row.fields[0].model.text;
          }
        });

        opObj = new BinaryOp(opConfig);

        operations.push(opObj);

        break;

      case 'numeric-comparator':
        _.forEach(op.rows, row => {
          if (row.fields.length == 2) {
            opConfig.inputCompId = blocksMngr.getOutput(row.inputIds[0]);
            opConfig.operatorSymbol = row.fields[0].model.text;
            opConfig.compareValue = row.fields[1].model;
          } else if (row.fields[0].label == 'True') {
            opConfig.nextVarTrueId = blocksMngr.getInput(row.outputIds[0]);
          } else if (row.fields[0].label == 'False') {
            opConfig.nextVarFalseId = blocksMngr.getInput(row.outputIds[0]);
          }
        });

        opObj = new ComparatorOp(opConfig);

        operations.push(opObj);

        break;

      case 'start-value':
        if (!op.rows[0].inputIds.length) {
          console.warn('Prorate Tool - An initial value has not been asigned to the start node.');
        } else {
          opConfig.inputCompId = blocksMngr.getOutput(op.rows[0].inputIds[0]);
        }

        if (!op.rows[0].outputIds.length) {
          console.warn('Prorate Tool - Start node has not been connected to an initial operation.');
        } else {
          opConfig.outputCompId = blocksMngr.getInput(op.rows[0].outputIds[0]);
        }

        opObj = new InputOp(opConfig);

        operations.push(opObj);

        break;

      case 'prorate-outcome':
        opObj = new OutputOp(opConfig);

        operations.push(opObj);

        break;
      default:
        console.warn('Prorate Tool - The operation block has a type that not recognized.', op);
        break;
    }
  });

  return operations;
}
