import { Ng, Component, Prop, State, Getter, Action } from '@angular';
import { IFormController, INgModelController } from 'angular';
import { find, remove, forEach, cloneDeep, filter } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import * as constants from '@constants';
import { Institution, OffenderHistoryTemplate } from '@root/client/interfaces';
import { FormOption } from '@interfaces';
import { MeState } from '@store/modules/me';
import { User } from '@interfaces/user';
import * as InstitutionStore from '@store/modules/institutions';
import {
  CreateInstitutionOptions,
  UpdateInstitutionOptions
} from '@api/modules/gears-manager';
import {
  CreateCustomOffenderHistoryTemplateOptions,
  ListCustomOffenderHistoryTemplatesOptions,
  GetCustomOffenderHistoryTemplateOptions,
  UpdateCustomOffenderHistoryTemplateOptions,
  DeleteCustomOffenderHistoryTemplateOptions
} from '@api/modules/institution-manager';

import { cfc, CLIENT_STATIC_FIELDS } from './client-fields';

import {
  MFA_OPTIONS,
  REASSESSMENT_OPTIONS,
  CUSTOM_CLIENT_FIELD_TYPES
} from './values';

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface ToolOption {
  id: number;
  name: string;
  disabled: boolean;
}

interface CreateInstitutionPayload extends CreateInstitutionOptions {
  superAdmin?: string;
  superAdminManual?: string;
  superAdminSelect?: string;
}

interface UpdateInstitutionPayload extends UpdateInstitutionOptions {
  id: string;
}

type CreateInstitution = (
  options: CreateInstitutionPayload
) => Promise<Institution>;
type UpdateInstitution = (
  options: UpdateInstitutionPayload
) => Promise<Institution>;
type CreateCustomOffenderHistoryTemplate = (
  options: CreateCustomOffenderHistoryTemplateOptions
) => Promise<OffenderHistoryTemplate>;
type UpdateCustomOffenderHistoryTemplate = (
  options: UpdateCustomOffenderHistoryTemplateOptions
) => Promise<OffenderHistoryTemplate>;
type GetCustomOffenderHistoryTemplate = (
  options: GetCustomOffenderHistoryTemplateOptions
) => Promise<OffenderHistoryTemplate>;
type ListCustomOffenderHistoryTemplate = (
  options: ListCustomOffenderHistoryTemplatesOptions
) => Promise<OffenderHistoryTemplate>;
type DeleteCustomOffenderHistoryTemplate = (
  options: DeleteCustomOffenderHistoryTemplateOptions
) => Promise<OffenderHistoryTemplate>;

@Component({
  name: 'institutionForm',
  template: require('./institution-form.html')
})
export class InstitutionForm extends Ng {
  @Prop() readonly institution!: Institution | null;

  readonly countries = constants.COUNTRIES;
  readonly institutionTypes = constants.INSTITUTION_TYPES;
  readonly step = [{ active: true }, { active: false }, { active: false }];

  submitting = false;
  processing = false;
  loading = false;
  newAdmin = true;
  superAdminEmail = '';
  currentStep = 0;
  // institution: Partial<UpdateInstitutionPayload> = {};
  stateProvinces: FormOption<string>[] = [];
  toolOptions: ToolOption[] = [];
  customizeClientProperties = false;
  clientPropertiesExpanded = false;
  customizeCustomClientProperties = false;
  clientCustomPropertiesExpanded = false;
  customizeClientTypes = false;
  clientTypesExpanded = false;
  customizeEthnicityTypes = false;
  ethnicityTypesExpanded = false;
  evaluationCustomizations: unknown[] = [];
  offenderHistoryCustomizations: unknown[] = [];
  offenderHistoryCustomizationsCreate: unknown[] = [];
  offenderHistoryCustomizationsUpdate: unknown[] = [];
  customizeOHLoading = false;
  ethnicityTypes: unknown = [];
  iccc = cfc.make();
  customClientFieldConfigs: cfc.CustomField[] = [];
  tools: { id?: number; name?: string }[] = [];
  // Institution Parameters
  name: string | null = null;
  email: string | null = null;
  institutionType: string | null = null;
  reassessment: string | null = null;
  mfa: string | null = null;
  maxUsers: number | 0 = 0;
  country: string | null = null;
  address1: string | null = null;
  address2?: string | null = null;
  address3?: string | null = null;
  city: string | null = null;
  stateProvince: string | null = null;
  postalCode: string | null = null;
  superAdmin: string | null = null;
  institutionTools: { id?: number; name?: string }[] = [];
  institutionOriginalTools: { id?: number; name?: string }[] = [];

  private institutionId: string | null = null;
  // private tools: Tool[] = [];

  /**
   * `country` property watcher callback.
   */
  private onCountryChanged = (value: string | null) => {
    this.stateProvinces = this.$util.getCountryProvinceOptions(value);
  };

  /**
   * `institution.tools` property watcher callback.
   */
  private onToolsChanged = (tools: { id: number; name: string }[]) => {
    this.toolOptions = this.tools.map(({ id, name }) => {
      return { id, name };
    });
  };

  @State readonly me!: MeState;
  @State(({ users }) => users.items) readonly users!: User[];
  @Getter readonly isAdmin!: boolean;
  @Action('institutions/get') readonly getInstitution!: (
    id: string
  ) => Promise<Institution>;
  @Action('institutions/create') readonly createInstitution!: CreateInstitution;
  @Action('institutions/update') readonly updateInstitution!: UpdateInstitution;
  @Action('institutions/createCustomOffenderHistoryTemplate')
  readonly createCustomOffenderHistoryTemplate!: CreateCustomOffenderHistoryTemplate;
  @Action('institutions/updateCustomOffenderHistoryTemplate')
  readonly updateCustomOffenderHistoryTemplate!: UpdateCustomOffenderHistoryTemplate;
  @Action('institutions/listCustomOffenderHistoryTemplate')
  readonly listCustomOffenderHistoryTemplate!: ListCustomOffenderHistoryTemplate;
  @Action('institutions/getCustomOffenderHistoryTemplate')
  readonly getCustomOffenderHistoryTemplate!: GetCustomOffenderHistoryTemplate;
  @Action('institutions/deleteCustomOffenderHistoryTemplate')
  readonly deleteCustomOffenderHistoryTemplate!: DeleteCustomOffenderHistoryTemplate;

  /**
   * ...
   */
  get reassessmentTypes() {
    return REASSESSMENT_OPTIONS;
  }

  /**
   * ...
   */
  get mfaTypes() {
    return MFA_OPTIONS;
  }

  /**
   * ...
   */
  get customClientField() {
    return {
      type: 'String',
      label: 'LABEL',
      key: 'label',
      allowNull: false, // functionally like !"required"
      required: false, // just used for form purposes
      show: true,
      isList: false, // if true, allows multiple options to be selected
      giveOptions: false,
      options: []
    };
  }

  /**
   * ...
   */
  get customClientFieldTypes() {
    return CUSTOM_CLIENT_FIELD_TYPES;
  }

  /**
   * Convenience accessor for component form object.
   */
  get form() {
    return this.$scope.form as IFormController;
  }

  /**
   * List of current form errors.
   */
  get errors() {
    return Object.keys(this.form.$error);
  }

  /**
   * ...
   */
  get isValid() {
    if (this.errors.length) return false;

    // specifically check for valid tools array
    let tools = this.institutionTools.map(({ id }) => ({ id }));
    tools = filter(tools, 'id');
    if (!tools.length) return false;

    return true;
  }

  /**
   * ...
   */
  get onLastStep() {
    return this.currentStep == this.step.length - 1;
  }

  /**
   * ...
   */
  get onFirstStep() {
    return this.currentStep == 0;
  }

  $onInit() {
    // TODO: Move to "Watch" decorator when properly working.
    this.$watch(() => this.country, this.onCountryChanged);

    this.load();
  }

  /**
   * ...
   *
   * @return ...
   */
  private async load() {
    let institution: Institution | null = null;

    this.loading = true;

    if (this.institution?.id) {
      try {
        institution = await this.getInstitution(this.institution.id);
      } catch (err) {
        // this.$notify.error('Something went wrong. Please try again.');
      }

      if (!institution) {
        throw new Error(
          `Could not find institution with ID "${this.institution.id}"`
        );
      }
    }

    if (institution) {
      this.institutionId = institution.id;
      this.superAdmin = institution.superAdmin || null;

      this.evaluationCustomizations = institution.evaluationConfigs || [];
      this.offenderHistoryCustomizations =
        institution.customOffenderHistoryTemplates || [];

      // Initialize ClientConfig
      if (institution.clientConfig) {
        this.iccc = cfc.generateFieldsConfig(institution.clientConfig);

        if (this.iccc.ethnicityField.options.length) {
          this.customizeEthnicityTypes = true;
        }

        if (this.iccc.typeField.options.length) {
          this.customizeClientTypes = true;
        }

        if (this.iccc.customFields.length) {
          this.customizeCustomClientProperties = true;
        }
      }

      this.name = institution.name;
      // email: string;
      this.institutionType = institution.institutionType;
      this.reassessment = institution.reassessment;
      this.mfa = institution.mfa;
      this.maxUsers = institution.maxUsers;
      this.country = institution.country;
      this.address1 = institution.address1;
      this.address2 = institution.address2;
      this.address3 = institution.address3;
      this.city = institution.city;
      this.stateProvince = institution.stateProvince;
      this.postalCode = institution.postalCode;

      this.institutionTools = institution.tools?.length
        ? institution.tools
        : [{}];
      this.institutionOriginalTools = cloneDeep(this.institutionTools);
    } else {
      this.institutionTools = [{}];
    }

    this.tools = await this.$api2.tc.listLiveTools();

    this.$watchCollection(() => this.institutionTools, this.onToolsChanged);

    this.loading = false;

    this.$scope.$apply();
  }

  /**
   * ...
   *
   * @return ...
   */
  superAdminOption(choice: boolean) {
    this.newAdmin = choice;
  }

  /**
   * ...
   *
   * @return ...
   */
  updateInstObject() {
    this.$createObjects.newInstitution.update(this.institution);
  }

  /**
   * Proceed to next step in form.
   */
  stepNext() {
    this.currentStep++;

    this.step.forEach((step, i) => {
      step.active = i === this.currentStep;
    });
  }

  /**
   * Go to previous step in form.
   */
  stepPrevious() {
    this.currentStep--;

    this.step.forEach((step, i) => {
      step.active = i === this.currentStep;
    });
  }

  /**
   * ...
   *
   * @return ...
   */
  toolChosen(index: number, { id: toolId }: { id: number }) {
    if (!this.institutionTools || !toolId) return;

    this.institutionTools.forEach((tool, i) => {
      if (index !== i && toolId === tool.id) {
        // tool.id = '';
        this.notify.display('Duplicate Tool Selected', 'warning');
      }
    });
  }

  /**
   * ...
   *
   * @return ...
   */
  addToolChoice() {
    if (this.institutionTools) {
      this.institutionTools.push({});
    }
  }

  /**
   * ...
   *
   * @return ...
   */
  removeToolChoice(toolId: number) {
    if (this.institutionTools) {
      remove(this.institutionTools, ({ id }) => id === toolId);
    }
  }

  /**
   * ...
   *
   * @return ...
   */
  testEmail(email: string, formItem: INgModelController) {
    email = email.toLowerCase();

    this.superAdminManual = email;

    if (!this.$util.validEmail(email)) {
      formItem.$setValidity('Invalid email', false);
    } else {
      formItem.$setValidity('Invalid email', true);
    }
  }

  /**
   * ...
   */
  addClientTypeFieldOption() {
    this.iccc.typeField.options.push({ value: '', label: '' });
  }

  /**
   * ...
   *
   * @param index ...
   */
  removeClientTypeFieldOption(index: number) {
    this.iccc.typeField.options.splice(index, 1);
  }

  /**
   * ...
   */
  addEthnicityFieldOption() {
    this.iccc.ethnicityField.options.push({ value: '', label: '' });
  }

  /**
   * ...
   *
   * @param index ...
   */
  removeEthnicityFieldOption(index: number) {
    this.iccc.ethnicityField.options.splice(index, 1);
  }

  /**
   * ...
   */
  addCustomFieldOption() {
    this.iccc.customFields.push(cfc.makeCustomField());
  }

  /**
   * Validate custom field label prop to ensure it doesn't match any other
   * existing field labels.
   *
   * @param key Custom prop field key.
   * @param value Custom prop field value.
   */
  validateCustomFieldLabel(key: string, value: string) {
    // console.log('validateCustomFieldLabel', value, this.$scope.form);

    const labels = [
      ...CLIENT_STATIC_FIELDS.map(({ label }) => label),
      ...this.iccc.customFields
        .filter((field) => field.key !== key)
        .map(({ label }) => label)
    ];

    (this.form[`${key}Label`] as angular.INgModelController).$setValidity(
      'unique',
      !labels.includes(value)
    );
  }

  /**
   * ...
   *
   * @return ...
   */
  async customizeTool(toolId: number, customization: unknown) {
    const tool = find(this.tools, { id: toolId });

    if (!tool) return this.notify.display('Cannot Find Tool', 'error');

    const commitId = tool.publishedCommitId;

    if (!commitId)
      return this.notify.display('Cannot Find Published Commit Id', 'error');

    if (!customization && find(this.evaluationCustomizations, { toolId }))
      customization = find(this.evaluationCustomizations, { toolId });

    const newCust = await this.$modals.tool.institutionCustomization(
      toolId,
      commitId,
      customization
    );

    if (!newCust) return;

    const hasQuestions = !!Object.keys(newCust?.questions || {}).length;
    const hasComments = !!Object.keys(newCust?.comments || {}).length;

    if (!hasQuestions && !hasComments) return;

    if (find(this.evaluationCustomizations, { toolId }))
      remove(this.evaluationCustomizations, { toolId });

    this.evaluationCustomizations.push(newCust);
  }

  /**
   *
   * @returns
   */
  async customizeOffenderHistory(toolId: number) {
    this.customizeOHLoading = true;
    const tool = find(this.tools, { id: toolId });

    if (!tool) {
      this.customizeOHLoading = false;
      return this.notify.display('Cannot Find Tool', 'error');
    }

    const commitId = tool.publishedCommitId;

    if (!commitId) {
      this.customizeOHLoading = false;
      return this.notify.display('Cannot Find Published Commit Id', 'error');
    }

    const toolData = await this.$store.dispatch('tools/getToolData', {
      toolId,
      commitId
    });

    if (!toolData?.offenderHistory) {
      this.customizeOHLoading = false;
      return this.notify.display(
        'Tool does not have an offender history',
        'error'
      );
    }

    const instOffenderHistory = find(
      this.offenderHistoryCustomizations,
      (ohc) => ohc.tool?.id === toolId
    );

    let instTemplate;
    if (instOffenderHistory) {
      instTemplate = await this.getCustomOffenderHistoryTemplate({
        institutionId: this.institutionId,
        templateId: instOffenderHistory.id
      });
    }

    // Sets "uneditable" to prevent modifying default required sections/fields
    const checkSection = function (section) {
      if (section.required) section.uneditable = true;
      forEach(section.fields, (f) => {
        if (f.required) f.uneditable = true;
      });
      if (section.sections?.length)
        forEach(section.sections, (s) => checkSection(s));
    };

    forEach(toolData.offenderHistory, (ohSections) =>
      forEach(ohSections, (s) => checkSection(s))
    );

    const customOH =
      await this.$modals.tool.institutionOffenderHistoryCustomization(
        toolData,
        instTemplate?.templateData || toolData.offenderHistory
      );

    this.customizeOHLoading = false;

    if (!customOH) return;

    // Strip out all "collapsed" values from sections
    const stripSection = function (section) {
      delete section.collapsed;
      if (section.sections?.length)
        forEach(section.sections, (s) => stripSection(s));
    };

    forEach(customOH.sections, (ohSections) => stripSection(ohSections));

    const newOH = {
      name: 'Offender History',
      templateData: customOH,
      tool: {
        id: tool.id,
        name: tool.name
      }
    };

    if (!instOffenderHistory) {
      this.offenderHistoryCustomizationsCreate.push(newOH);
    } else {
      newOH.templateId = instOffenderHistory.id;
      this.offenderHistoryCustomizationsUpdate.push(newOH);
    }
  }

  /**
   * ...
   *
   * @return ...
   */
  async submit() {
    let data: Institution | null = null;
    let error: Error | null = null;

    this.submitting = true;

    try {
      // data = await this.createInstitution(options);
      data = await this.submitRequest();
    } catch (err) {
      error = err;
    }

    this.submitting = false;

    if (error) {
      console.error(error);

      throw error;
    }

    return data!;
  }

  /**
   *
   */
  private async submitRequest() {
    if (!this.isValid) {
      throw new Error('Institution data invalid.');
    }

    const email = this.newAdmin
      ? this.superAdminManual
      : find(this.users, { id: this.superAdminSelect }).email;

    // Create correctly-formatted tools array.
    let tools = this.institutionTools.map(({ id }) => ({ id }));
    tools = filter(tools, 'id');

    // if it's a new institution, make sure they have tools access
    if (
      (!this.institutionId && !tools.length) ||
      (tools.length === 1 && !tools[0].id)
    ) {
      throw new Error('No Tools Selected for the Institution.');
    }

    const options: InstitutionStore.CreateInstitutionActionOptions = {
      email,
      tools,
      name: this.name,
      institutionType: this.institutionType,
      reassessment: this.reassessment,
      mfa: this.mfa,
      maxUsers: this.maxUsers,
      country: this.country,
      address1: this.address1,
      address2: this.address2,
      address3: this.address3,
      city: this.city,
      stateProvince: this.stateProvince,
      postalCode: this.postalCode
    };

    if (
      this.customizeClientProperties ||
      this.customizeCustomClientProperties ||
      this.customizeClientTypes ||
      this.customizeEthnicityTypes
    ) {
      const fieldsConfig: Partial<cfc.Config> = {};

      if (this.customizeClientProperties) {
        fieldsConfig.coreFields = this.iccc.coreFields;
      }

      if (this.customizeEthnicityTypes) {
        fieldsConfig.ethnicityField = this.iccc.ethnicityField;
      }

      if (this.customizeClientTypes) {
        fieldsConfig.typeField = this.iccc.typeField;
      }

      if (this.customizeCustomClientProperties) {
        fieldsConfig.customFields = this.iccc.customFields;
      }

      options.clientConfig = cfc.generateClientConfig(fieldsConfig);
    }

    // Evaluations customizations
    if (this.evaluationCustomizations.length)
      options.evaluationConfigs = this.evaluationCustomizations;

    // TODO: (Alex) compare new institutions values to original and only send
    // what's changed. Check against original institution types to see what
    // changed.
    if (this.institutionId) {
      for (const [key, item] of Object.entries(options)) {
        if (item == this.institution[key]) delete options[key];
      }

      // Check if we're updating existing institution's tools
      // Check if we added/removed tools
      const addedTools = [];
      forEach(tools, (t) => {
        if (!find(this.institutionOriginalTools, { id: t.id }))
          addedTools.push(t);
      });
      if (addedTools.length) options.addTools = [...addedTools];

      const removedTools = [];
      forEach(this.institutionOriginalTools, (t) => {
        if (!find(tools, { id: t.id })) removedTools.push(t);
      });

      if (removedTools.length)
        options.removeTools = removedTools.map(({ id }) => ({ id }));
    }

    // TODO: (Alex) compare new clientConfig values to original and only send
    // what's changed.
    if (this.institutionId) {
      //
    }

    // TODO: (Alex) compare new evaluationConfigs values to original and only
    // send what's changed.

    const institution = await (this.institutionId
      ? this.updateInstitution({
          institutionId: this.institutionId,
          ...options
        })
      : this.createInstitution(options));

    // Create/Update OHCs here
    if (this.offenderHistoryCustomizationsCreate?.length) {
      if (!this.institutionId) this.institutionId = institution.id;
      forEach(this.offenderHistoryCustomizationsCreate, async (ohc) => {
        ohc.institutionId = this.institutionId;
        try {
          const newOHC = await this.createCustomOffenderHistoryTemplate(ohc);
          this.notify.display(
            'Created Custom Offender History Template',
            'success'
          );
        } catch (err) {
          this.notify.display(err, 'error');
        }
      });
    }

    if (this.offenderHistoryCustomizationsUpdate?.length) {
      if (!this.institutionId) this.institutionId = institution.id;
      forEach(this.offenderHistoryCustomizationsUpdate, async (ohc) => {
        ohc.institutionId = this.institutionId;
        try {
          const updatedOHC = await this.updateCustomOffenderHistoryTemplate(
            ohc
          );
          this.notify.display(
            'Updated Custom Offender History Template',
            'success'
          );
        } catch (err) {
          this.notify.display(err, 'error');
        }
      });
    }

    this.institutionId = institution.id;

    return institution;
  }
}

// region Helper Functions

/**
 * ...
 *
 * @return ...
 */
function createPlaceholderInstitution() {
  return {
    name: `Name ${uuidv4()}`,
    maxUsers: 0,
    institutionType: constants.InstitutionType.CommunityCorrections,
    country: 'US',
    address1: '123 Main St',
    city: 'Some City',
    stateProvince: 'VA',
    postalCode: '12345',
    superAdminManual: `giovanniestep+${uuidv4()}@gmail.com`,
    reassessment: constants.ReassessmentType.NotAllowed,
    mfa: constants.MultiFactorAuthenticationType.None,
    tools: [{ id: 2 }]
  } as CreateInstitutionPayload;
}

// endregion Helper Functions

export default InstitutionForm.$module;
