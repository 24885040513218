import angular, {
  Controller,
  Inject,
  Watch,
  State,
  Getter,
  Action
} from '@angular';

import { forEach, indexOf, filter, find } from 'lodash';
import Prism from 'prismjs';

import policyConfig from './policy-config';

import type { Policy } from '@/types';

@Controller
class SimplePolicyEditorComponent {
  policy: Policy;
  inputPolicy: Policy;
  permissions: {}[] = [];
  permissionsListeners: Function[] = [];
  rawPolicy: string = '';
  editingDescription: boolean = false;
  buildingPermissions: boolean = false;
  loadingInstitutions: boolean = false;

  @Inject $scope;
  @Inject $rootScope;
  @Inject $api;
  @Inject $auth;
  @Inject utils;
  @Inject policyTranslator;
  @Inject $api2;

  @State me;
  @State(({ institutions }) => institutions.items) institutions;
  @Getter isAdmin;
  @Getter('institutions/find') findInstitution;

  @Action('institutions/getAll') listInstitutions;

  @Watch('policy.name') updateRawPolicy;
  @Watch('policy.description') updateRawPolicy;

  get $instId() {
    return this.policy.institutionId;
  }

  set $instId(val) {
    this.policy.institutionId = val;
  }

  async $onInit() {
    // For any new policy groups added in the future
    this.newGroupsViewed = localStorage.getItem('newGroupsViewed');
    this.newGroupsViewed = !this.newGroupsViewed
      ? []
      : JSON.parse(this.newGroupsViewed);
    // Create hard reference to passed policy.
    this.inputPolicy = {
      name: this.policy?.name || '',
      institutionId:
        this.policy?.institutionId ||
        (this.isAdmin ? null : this.me.institution.id),
      description: this.policy?.description || '',
      statement: this.policy?.statement || []
    };

    await this.reset();

    this.$scope.$watch(
      () => this.policy.institutionId,
      (newVal, oldVal) => {
        if (newVal === oldVal) {
          return;
        }

        this.parsePermissions();
      }
    );
  }

  async parsePermissions() {
    const $instId = this.policy.institutionId;

    const addListener = (valGetter, callback, eql = false) => {
      this.permissionsListeners.push(
        this.$scope.$watch(
          valGetter,
          (newVal, oldVal) => callback(newVal, oldVal),
          eql
        )
      );
    };

    while (this.permissionsListeners.length > 0) {
      let deregister = this.permissionsListeners.splice(0, 1)[0];
      deregister();
    }

    this.buildingPermissions = true;

    await this.utils.wait(1000);

    const resourceItems = await this.loadResourceItems();

    // Create action description from passed in policy
    // statement to autofile form
    let permissionProfile = this.policyTranslator.statementToProfile(
      this.inputPolicy.statement
    );

    const $statmentMap = this.policyTranslator.mapStatement(
      this.inputPolicy.statement
    );
    const $permissionProfile =
      this.policyTranslator.permissionProfile($statmentMap);

    console.log({ $statmentMap, $permissionProfile });

    const $statments = [];

    class StatementWrapper {
      statement = [
        {
          effect: 'allow',
          actions: [],
          resources: []
        },
        {
          effect: 'deny',
          actions: []
        }
      ];

      get allowedActions() {
        return this.statement[0].actions;
      }

      set allowedActions(val) {
        this.statement[0].actions = val;
      }

      get deniedActions() {
        return this.statement[1].actions;
      }

      set deniedActions(val) {
        this.statement[1].actions = val;
      }

      get resources() {
        return this.statement[0].actions;
      }

      set resources(val) {
        this.statement[0].resources = val;
      }

      constructor() {
        $statments.push(...this.statement);
      }
    }

    const $sw = new StatementWrapper();

    var includedResources = [];

    const createGrn = function createGrn(config) {
      let service = config.service || '',
        region = config.region || '',
        instId = $instId || '',
        valueType = config.valueType || '',
        value = config.value || '';

      return `grn:gifr:${service}:${region}:${instId}:${valueType}:${value}`;
    };

    const updateResources = () => {
      let resources = [];

      for (let prmsn of this.permissions) {
        if (prmsn.isolatedStatment) {
          prmsn.isolatedStatment.resources = prmsn.resources
            .map(createGrn)
            .sort();

          continue;
        }

        for (let rec of prmsn.resources) {
          let valueType = rec.key;
          let region = rec.region || '';

          if (prmsn.includeAllResources) {
            for (let service of this.utils.makeArray(rec.service)) {
              resources.push(
                createGrn({ service, region, valueType, value: '*' })
              );
            }

            continue;
          }

          for (let val of rec.values) {
            if (!val.selected) {
              continue;
            }

            let value = val.value || '*';
            for (let service of this.utils.makeArray(rec.service)) {
              resources.push(createGrn({ service, region, valueType, value }));
            }
          }
        }
      }

      $sw.resources = resources.sort();

      this.updateRawPolicy();
    };

    const hasAction = (action) => {
      return this.policyTranslator.hasAccess(action, permissionProfile);
    };

    const hasResourceAccess = (action, name, values) => {
      return this.policyTranslator.hasAccess(
        {
          action,
          resources: { name, values }
        },
        permissionProfile
      );
    };

    this.permissions = policyConfig.map((config) => {
      const sw = config.uniqueScope ? new StatementWrapper() : $sw;
      let prmsn = {
        name: config.name,
        icon: config.icon || 'question',
        gifrAdminOnly: !!config.gifrAdminOnly,
        newGroup:
          !!config.newGroup && indexOf(this.newGroupsViewed, config.name) < 0,
        description: config.description || '',
        actions: config.actions
          ? { list: true, read: true, write: true }
          : null,
        resources: [],
        includeAllResources: false,
        isolatedStatment: config.uniqueScope ? sw : null,
        config
      };

      if (!prmsn.actions) {
        return prmsn;
      }

      const mapActions = prmsn.actions,
        actionsList = [];

      ['list', 'read', 'write'].forEach((type) => {
        let actions = config.actions[type];

        if (!actions.length) {
          mapActions[type] = null;

          return;
        }

        let groupStateMatch = true;

        actions.forEach((action) => {
          if (actionsList.includes(action)) {
            throw new Error(
              `[simplePolicyEditor] Action "${action}" was already added.`
            );
          }

          actionsList.push(action);

          if (!hasAction(action)) {
            groupStateMatch = false;
          }
        });

        mapActions[type] = groupStateMatch;

        if (groupStateMatch) {
          sw.allowedActions.push(...actions);
        } else {
          sw.deniedActions.push(...actions);
        }

        addListener(
          () => mapActions[type],
          (newVal, oldVal) => {
            if (newVal === oldVal) {
              return;
            }

            // updateActions(actions, newVal);

            let from = newVal ? sw.deniedActions : sw.allowedActions;
            let to = newVal ? sw.allowedActions : sw.deniedActions;

            actions.forEach((action) => {
              to.push(
                from.splice(
                  from.findIndex((item) => item == action),
                  1
                )[0]
              );
            });

            this.updateRawPolicy();
          }
        );
      });

      return prmsn;
    });

    this.permissions.forEach((prmsn) => {
      if (
        'resources' in prmsn.config === false ||
        !prmsn.config.resources.length
      ) {
        return;
      }

      if (prmsn.isolatedStatment) {
        prmsn.resources = [...prmsn.config.resources];

        return;
      }

      for (let resConfig of prmsn.config.resources) {
        const resource = {
          ...resConfig,
          values: []
        };

        resource.values = resourceItems.get(resource.key).map((item) => {
          //
          item.selected = $sw.allowedActions
            .filter((action) => resource.service.includes(action.split(':')[0]))
            .every((action) =>
              hasResourceAccess(action, resource.key, item.value || '*')
            );

          return item;
        });

        // if (allStared) {
        //   allStared = resource.values.every(val => );
        // }

        addListener(() => resource.values, updateResources, true);

        prmsn.resources.push(resource);
      }

      prmsn.includeAllResources = $sw.allowedActions.every((sa) => {
        let mapRef = $statmentMap.resources.allowed[sa];

        let pass = prmsn.resources.every((rec) => {
          let service = sa.split(':')[0];

          if (!rec.service.includes(service)) {
            return true;
          }

          let grn = createGrn({
            service,
            region: prmsn.region,
            valueType: rec.key,
            value: '*'
          });

          return mapRef.includes(grn);

          // let grn2 = createGrn({
          //   service,
          //   region: prmsn.region,
          //   valueType: '',
          //   value: '*'
          // });
          //
          // return mapRef.includes(grn) || mapRef.includes(grn2);
        });

        return pass;
      });

      includedResources = includedResources.concat(prmsn.resources);

      addListener(() => prmsn.includeAllResources, updateResources, true);
    });

    // this.policy.statement = $sw.statement;
    this.policy.statement = $statments;

    this.updateRawPolicy();

    this.buildingPermissions = false;

    this.$scope.$apply();
  }

  policyViewed(prmsn) {
    // for marking new group policies as viewed
    if (indexOf(this.newGroupsViewed, prmsn.name) < 0) {
      this.newGroupsViewed.push(prmsn.name);
      localStorage.setItem(
        'newGroupsViewed',
        JSON.stringify(this.newGroupsViewed)
      );
    }
  }

  institutionSelected() {
    // if institution id is present, set all gifrAdminOnly permissions to false
    if (this.policy.institutionId) {
      forEach(this.permissions, (perm) => {
        if (perm.gifrAdminOnly) {
          perm.actions.read = false;
          perm.actions.list = false;
          perm.actions.write = false;
        }
      });
    }
  }

  async create() {
    // go through and double check that we don't have MHS GEARS Admin actions
    if (this.policy.institutionId) {
      forEach(this.permissions, (perm) => {
        if (perm.gifrAdminOnly) {
          perm.actions.read = false;
          perm.actions.list = false;
          perm.actions.write = false;
        }
      });
      await new Promise((resolve) => setTimeout(resolve));
    }
    //region Adding in * Resources to Allowed Statement
    // check if we're an institution policy by getting instId of policy
    let { actions: $actions, resources: $resources } = find(
      this.policy.statement,
      (st) => {
        return st.effect === 'allow';
      }
    );

    // remove "deny" statements
    this.policy.statement = filter(this.policy.statement, {
      effect: 'allow'
    });

    if (this.$instId) {
      forEach(this.policy.statement, (statement) => {
        statement.actions = filter(
          statement.actions,
          (action) => !action.includes('gearsmanager')
        );
        // TODO (Alex) filter out mcpt, but we need to leave ListManagedCasePlanTemplates
        // ListCasePlanTemplateCommits, GetCasePlanTemplateCommit
      });
    }

    if (this.$instId && typeof this.$instId === 'number') {
      this.$instId = this.$instId.toString();
    }

    if (this.$instId && $resources !== '*') {
      // check if there are any institutionmanager actions in the allow statement
      // utility statement to check if action and resource already exist

      const addIfMissing = function (service, grn) {
        if (
          $actions.some((action) => action.includes(service)) &&
          $resources.findIndex((val) => val == grn) < 0
        ) {
          $resources.push(grn);
        }
      };

      // check for institutionmanager action and resource existence
      addIfMissing(
        'institutionmanager',
        `grn:gifr:institutionmanager::${this.$instId}::*`
      );

      // check for toolcreator action and resource existence
      addIfMissing('toolcreator', `grn:gifr:toolcreator::::*`);

      // check for icpt action and resource existence
      addIfMissing('icpt', `grn:gifr:icpt::${this.$instId}::*`);

      // check for mcpt action and resource existence
      addIfMissing('mcpt', `grn:gifr:mcpt::::*`);
    }

    //endregion

    // add in globally required/allowed permissions here
    let hasGlobalPermissionsAlready = function (policy) {
      let found = false;
      forEach(policy.statement, (statement) => {
        if (found) return;
        if (statement.actions.indexOf('institutionmanager:GetInstitution') > -1)
          found = true;
      });

      return found;
    };

    if (!hasGlobalPermissionsAlready(this.policy)) {
      this.policy.statement.push({
        effect: 'allow',
        actions: [
          'institutionmanager:GetInstitution',
          'institutionmanager:GetOffenseClassification',
          'institutionmanager:ListInstitutionUsers',
          'institutionmanager:ListZones',
          'institutionmanager:ListRegions',
          'institutionmanager:ListSubGroups',
          'institutionmanager:ListServices',
          'institutionmanager:ListOffenseClassifications',
          'institutionmanager:ListInstitutionTools',
          'institutionmanager:ListCustomOffenderHistoryTemplates',
          'institutionmanager:GetCustomOffenderHistoryTemplate',
          'toolcreator:ListLiveTools',
          'toolcreator:ListManagedCasePlanTemplates'
        ],
        resources: '*'
      });
      //  'institutionmanager:ListInstitutionTools', removed in favor of placing in evaluations card
    }

    this.policy.statement = this.policy.statement.filter(
      (item) => item.actions.length
    );
    this.$scope.$emit('policyCreated', this.policy);
  }

  toggleAllActions(prmsn, mode) {
    // Mode should always be a boolean
    mode = !!mode;

    if (!prmsn.actions) {
      return;
    }

    if (prmsn.actions.list !== null) {
      prmsn.actions.list = mode;
    }

    if (prmsn.actions.read !== null) {
      prmsn.actions.read = mode;
    }

    if (prmsn.actions.write !== null) {
      prmsn.actions.write = mode;
    }
  }

  toggleAll(mode) {
    // Mode should always be a boolean
    mode = !!mode;

    Object.values(this.permissions).forEach((prmsn) => {
      // If it's an institution policy and prmsn is gifrAdminOnly skip it
      if (this.policy.institutionId && prmsn.gifrAdminOnly) return;
      // If the permission has resources...
      if (prmsn.resources?.length) {
        prmsn.includeAllResources = mode;

        // No need to have individual resource items
        // selcted, so mark them all as false.
        prmsn.resources.forEach(({ values }) => {
          if (!values?.length) {
            return;
          }

          for (let value of values) {
            value.selected = false;
          }
        });
      }

      // If permission has actions, set the mode for
      // each (if the action is setable).
      this.toggleAllActions(prmsn, mode);
    });
  }

  async reset() {
    // Redefine the core policy for use with the editor
    this.policy = {
      name: this.inputPolicy.name || '',
      institutionId: this.inputPolicy.institutionId || null,
      description: this.inputPolicy.description || '',
      statement: []
    };

    await this.parsePermissions();
  }

  async loadResourceItems() {
    const resourceItems = {};

    const addResourceItems = function (key, { valueKey, nameKey, items }) {
      let sourceItems = items.map((item) => ({
        value: item[valueKey],
        name: item[nameKey],
        selected: false,
        region: null
      }));

      resourceItems[key] = sourceItems;
    };

    let subGroups = [];

    try {
      if (this.policy.institutionId) {
        const institutionId = this.policy.institutionId;
        // check if we already have the institution locations parsed in store
        const inst = this.findInstitution(institutionId);

        let zones = null;
        if (inst && inst.zones) zones = inst.zones;

        if (!zones) {
          // we haven't retrieved/parsed zones yet so we need to do that here
          // grab zones
          try {
            zones = await this.$api2.im.listZones({ institutionId });
            zones = this.utils.alphabetizeArray(zones, 'name');
          } catch (err) {
            console.error(err);
          }

          // grab regions
          let regions = [];

          try {
            regions = await this.$api2.im.listRegions({ institutionId });
            regions = this.utils.alphabetizeArray(regions, 'name');
          } catch (err) {
            console.error(err);
          }

          // grab subgroups
          let sgs = [];
          try {
            sgs = await this.$api2.im.listSubGroups({ institutionId });
            sgs = this.utils.alphabetizeArray(sgs, 'name');
          } catch (err) {
            console.error(err);
          }

          // Build Zones/Regions/Subgroups Object
          for (const region of regions) {
            region.subGroups = filter(sgs, { regionId: region.id });
          }

          for (const zone of zones) {
            zone.regions = filter(regions, { zoneId: zone.id });
          }
        }

        zones.forEach((z) => {
          z.regions.forEach((r) => {
            r.subGroups.forEach((s) => {
              s.displayName = `${z.name} > ${r.name} > ${s.name}`;
              subGroups.push(s);
            });
          });
        });
      }
    } catch (err) {
      console.error(err);

      console.warn(
        '[simplePolicyEditor] Was unable to load SubGroups for populating resource values.'
      );
    }

    addResourceItems('subGroup', {
      valueKey: 'id',
      nameKey: 'displayName',
      items: subGroups
    });

    let tools = [];

    try {
      let data;

      if (
        this.$auth.hasAccess('gearsmanager:ListAllTools') &&
        !this.policy.institutionId
      ) {
        data = await this.$api.toolCreator.listAllTools();
      } else {
        data = await this.$api.institutionManager.listInstitutionTools(
          this.policy.institutionId
        );
      }
      tools = data?.data;
    } catch (err) {
      console.warn(
        '[simplePolicyEditor] Was unable to load Tools for populating resource values.'
      );
    }

    addResourceItems('tool', {
      valueKey: 'id',
      nameKey: 'name',
      items: tools
    });

    let templates = [];

    try {
      let { data } = await this.$api.icpt.listCasePlanTemplates(
        this.policy.institutionId
      );
      templates = data;
    } catch (err) {
      console.warn(
        '[simplePolicyEditor] Was unable to load Templates for populating resource values.'
      );
    }

    addResourceItems('template', {
      valueKey: 'id',
      nameKey: 'name',
      items: templates
    });

    return {
      get: (key) => resourceItems[key].map((item) => ({ ...item }))
    };
  }

  updateRawPolicy() {
    let policyStr = JSON.stringify(this.policy, null, 4);

    this.rawPolicy = Prism.highlight(policyStr, Prism.languages.json, 'json');
  }

  checkAllResources(val, prm) {
    if (prm?.resources?.length) {
      forEach(prm.resources, (res) => {
        if (res?.values?.length) {
          forEach(res.values, (value) => {
            value.selected = val;
          });
        }
      });
    }
  }

  cancel() {
    this.$scope.$emit('policyEditorCanceled');
  }

  async loadInstitutions() {
    if (!this.institutions?.length) {
      this.loadingInstitutions = true;
      try {
        await this.listInstitutions();
      } catch (err) {
        this.notify.display(err, 'error');
      } finally {
        this.loadingInstitutions = false;
      }
    }
  }
}

export default angular
  .module('app.simplePolicyEditor', [])
  .directive('simplePolicyEditor', () => {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        policy: '=',
        lockInstitution: '<',
        processing: '='
      },
      bindToController: true,
      template: require('./simple-policy-editor.html'),
      controller: SimplePolicyEditorComponent,
      controllerAs: 'vm'
    };
  }).name;
