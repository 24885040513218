import { Component } from '@angular';

import { Modal, ModalProp } from '@mixins/modal.component';

/** ... */
const ACCEPTED_TYPES =
  '.pdf,.txt,.xlsx,.xls,.csv,.png,.jpeg,.jpg,.gif,.JPG,.JPEG,.PNG,.mov,.mp4,.avi,.ogg,.mp3,.doc,.docx,application/pdf,text/rtf,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,image/png,image/jpeg,video/quicktime,video/mp4,video/x-msvideo,audio/ogg,audio/mpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessing';

/** ... */
const NGF_PATTERN =
  'image/*,video/*,audio/*,.pdf,.txt,.xlsx,.xls,.csv,.png,.jpeg,.jpg,.gif,.JPG,.JPEG,.PNG,.mov,.mp4,.avi,.ogg,.mp3,.doc,.docx,application/pdf,text/rtf,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,text/csv,image/png,image/jpeg,video/quicktime,video/mp4,video/x-msvideo,audio/ogg,audio/mpeg,application/msword,application/vnd.openxmlformats-officedocument.wordprocessing';

@Component({
  name: 'uploadFileModal',
  template: require('./upload-file-modal.html')
})
export class UploadFileModalController extends Modal {
  @ModalProp readonly url!: string;

  readonly acceptTypes = ACCEPTED_TYPES;
  readonly ngfPattern = NGF_PATTERN;

  loadingData = false;
  fileTypesExpanded = false;
  file: unknown = null;

  /**
   * ...
   */
  async sendRequest() {
    let data: unknown = null;
    let error: Error | null = null;

    const options = {
      url: this.url,
      file: this.file,
      data: { isReport: false }
      // institutionId: '646209135145',
      // subGroupId: 'e9e88dad-8952-4f3b-8f8f-1a04727b0914',
      // clientId: '0a86adfa-8b96-4e14-820d-52150bead2fa',
      // isReport: false,
      // file: this.file
    };

    this.loadingData = true;

    try {
      data = await this.Upload.upload(options);
      // data = await this.$api2.cm.uploadClientMedia(options);
    } catch (err) {
      error = err;
    }

    console.error(error);

    this.loadingData = false;

    if (!data) {
      return this.notify.error('Failed to upload file.');
    }

    if (error) {
      this.notify.error('Failed to upload file: ' + error.message);
    }

    this.notify.success('Media Successfully Uploaded');

    this.$close(data.data);
  }
}

export default UploadFileModalController.$module;
