'use strict';

import angular from 'angular';
import * as _ from 'lodash';

import { State, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class SelectToolModalController extends Modal {
  selectedTool: {} = {};

  @Inject $scope;
  @Inject $rootScope;
  @Inject getItems;
  @Inject utils;

  @State(({ tools }) => tools.items) tools;
  @Action('tools/list') getAllTools;

  get block() {
    return this.$props;
  }

  $setup() {
    this.getAllTools();
  }

  toolSelected() {
    this.selectedTool = JSON.parse(this.selectedTool);

    if (this.selectedTool.id) {
      if (this.block) {
        this.block.id = this.selectedTool.id;
        this.block.name = this.selectedTool.name;
      }
      this.$rootScope.$broadcast('block-updated', this.block);
      this.$close(this.block);
    } else {
      this.utils.notify('warning', 'Please select a tool.');
    }
  }
}

export default angular
  .module('app.selectToolModal', [])
  .directive('selectToolModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./select-tool.html'),
    controller: SelectToolModalController,
    controllerAs: 'vm'
  })).name;
