import angular from 'angular';
import { forEach } from 'lodash';
import { ActionContext } from 'angular-store';

import { RootState } from './state';

/**
 * ...
 */
export interface RootActions {
  fetchAppVersion: () => Promise<void>;
  clearStates: () => void;
}

/**
 * ...
 */
type RootActionsTree = {
  [P in keyof RootActions]: (
    context: ActionContext<RootState, RootState>,
    payload: Parameters<RootActions[P]>[0]
  ) => ReturnType<RootActions[P]>;
};

// declare module 'angular-store' {
//   export interface Store<S> {
//     dispatch<T extends keyof RootActions>(
//       type: T,
//       payload: Parameters<RootActions[T]>[0]
//     ): void;
//   }
// }

/**
 * ...
 */
export function ActionsConfig(
  $injector: angular.auto.IInjectorService,
  $api2: angular.gears.IAPI2Service
) {
  'ngInject';

  const actions: RootActionsTree = {
    /**
     * ...
     */
    async fetchAppVersion({ commit }) {
      let version = 'X.X.XX';

      try {
        version = await $api2.getAppVersion();
      } catch (err) {
        console.error(err);
      }

      commit('APP_VERSION', version);
    },
    /**
     * Empties the lists of all store states
     */
    clearStates({ state, commit }) {
      const $store = $injector.get<angular.gears.IStoreService>('$store');

      for (const key in state) {
        const clearCommitKey = `${key}/CLEAR`;

        if (clearCommitKey in $store.mutations) commit(`${key}/CLEAR`);
      }
    }
  };

  return actions;
}
