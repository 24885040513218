import angular from 'angular';
import pdfObject from 'pdfobject';

import './viewer-pdf.scss';

export default angular
  .module('fileViewer.pdfViewer', [])
  .directive('pdfViewer', $store => {
    'ngInject';

    return {
      require: '^^fileViewer',
      replace: true,
      template: require('./viewer-pdf.html'),
      scope: true,
      link(scope, element, attrs, viewerCtrl) {
        scope.pdfSrc = viewerCtrl.filePath;
        scope.loading = true;

        pdfObject.embed(scope.pdfSrc, '#pdf-container', {
          width: '800px',
          pdfOpenParams: {
            view: 'FitH',
            toolbar: 0,
            page: 0,
            pagemode: 'none'
          },
          forcePDFJS:
            $store.state.isIE || $store.state.isEdge || $store.state.isSafari,
          PDFJS_URL: 'assets/components/pdfjs/build/generic/web/viewer.html'
        });
      }
    };
  }).name;
