'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ReloadPageModalController extends Modal {
  message: string = '';

  @Inject $ls;
  @Inject $auth;
  @Inject $window;

  $setup() {
    this.message = this.$props;
  }

  reload() {
    this.$ls.remove('activeInstitutionId');
    this.$window.location.reload(true);
  }
}

export default angular
  .module('app.reloadPageModal', [])
  .directive('reloadPageModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./reload-page.html'),
    controller: ReloadPageModalController,
    controllerAs: 'vm'
  })).name;
