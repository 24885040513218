import angular from 'angular';

export class EvlReqUtilsService {
  get _tools() {
    return this.$store.state.tools.items;
  }

  get _clients() {
    return this.$store.state.clients.items;
  }

  get targetClientId() {
    return this.$store.state.clients.focus?.id;
  }

  constructor(
    private readonly $state: angular.ui.IStateService,
    private readonly $store: angular.gears.IStoreService,
    private readonly errorHandler: angular.gears.IErrorHandlerService,
    private readonly utils: angular.gears.IUtilsService,
    private readonly evlUtils: angular.gears.IEvalUtilsService
  ) {
    'ngInject';
  }

  /**
   * Go to perform evaluation from clicking evaluation request notification.
   *
   * @param request ...
   * @param index ...
   * @return ...
   */
  async goToEvaluationFromRequest(request: unknown, index: number) {
    if (!this.targetClientId) {
      return this.errorHandler(
        new Error('A client was not selected for the evaluation request.')
      );
    }

    const client = this._clients.find(({ id }) => id === request.clientId);

    if (!client) {
      return this.errorHandler(
        new Error('The requested client could not be found.')
      );
    }

    request.loading = true;

    this.$store.commit('clients/updateItem', {
      ...client,
      age: this.utils.dateTime.calcAge(client.dob)
    });

    let evaluations: any[] = [];
    let error: Error | null = null;

    try {
      evaluations = await this.$store.dispatch(
        'evaluations/getForClient',
        this.targetClientId
      );
    } catch (err) {
      error = err;
    }

    // this.loading = false;

    if (error) {
      return this.errorHandler(error);
    }

    for (const evaluation of evaluations) {
      for (const tool of this._tools) {
        if (evaluation.toolName === tool.id) {
          evaluation.toolName = tool.name;
        }
      }

      if (!evaluation.dueDate) {
        evaluation.dueDate = 'N/A';
      }
    }

    let evaluation: any = null;

    try {
      evaluation = await this.$store.dispatch(
        'evaluations/get',
        request.evaluationId
      );

      if (!!res.data.error) throw res;
    } catch (err) {
      // $evl = {
      //   toolChosen: request.toolId,
      //   evaluationId: request.evaluationId
      // };
    }

    this.evlUtils.newEvaluation = false;
    this.evlUtils.evaluation = {
      evaluation: evaluation.data.data.data,
      evaluationId: evaluation.data.data.data.evaluationId,
      toolChosen: request.toolId
    };

    this.evlUtils.createTempEvaluation();
    this.evlUtils.evaluationToolChosen();

    this.$state.go('dashboardEvaluationsPerform');

    this.dismissEvaluationRequest(index);

    request.loading = false;
  }

  /**
   * ...
   */
  dismissEvaluationRequest(index: number) {
    // this.notificationEvaluationRequests.splice(index, 1);
  }
}
