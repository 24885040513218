import angular from 'angular';

import './viewer-audio.scss';

export default angular
  .module('fileViewer.audioViewer', [])
  .directive('audioViewer', () => {
    return {
      require: '^^fileViewer',
      replace: true,
      template: require('./viewer-audio.html'),
      scope: true,
      link(scope, element, attrs, viewerCtrl) {
        scope.audioSrc = viewerCtrl.filePath;
        scope.loading = true;

        element.find('audio').on('canplay', event => {
          scope.loading = false;
        });
      }
    };
  }).name;
