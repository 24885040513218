import { find } from 'lodash';

import { ToolData, EvaluationConfig } from '@interfaces';

/**
 * Mark questions as required unless ignoreCount is `true`.
 *
 * @param data Tool data to modify.
 */
function markQuestionsRequired(data: ToolData) {
  for (const cfi of data.codingFormItems) {
    if (!cfi.ignoreCount) cfi.required = true;
  }

  if (data.childTools?.length) {
    data.childTools.forEach(markQuestionsRequired);
  }
}

/**
 * Check tool rules here.
 *
 * @param data Tool data to modify.
 */
function checkToolRules(data: ToolData) {
  if (data.rules?.length && find(data.rules, { ruleType: 2 })) {
    markQuestionsRequired(data);
  }

  if (data.childTools?.length) {
    data.childTools.forEach(checkToolRules);
  }
}

/**
 * ...
 *
 * @param data Tool data to modify.
 * @param evalConfig ...
 */
function checkToolForEvalConfig(data: ToolData, evalConfig: EvaluationConfig) {
  const comment = evalConfig.comments[data.address];

  switch (comment) {
    case 'DISABLED':
      data.showCommentsBox = false;
      break;
    case 'REQUIRED':
      data.commentsRequired = true;
      break;
    case 'OPTIONAL':
      data.commentsRequired = false;
      break;
  }

  if (evalConfig.questions && data.codingFormItems?.length) {
    for (const cfi of data.codingFormItems) {
      const id = cfi.longAddress ?? cfi.id;

      if (id in evalConfig.questions === false) continue;

      switch (evalConfig.questions[id]) {
        case 'OPTIONAL':
          cfi.required = false;
          break;
        case 'REQUIRED':
          cfi.required = true;
          break;
        case 'DISABLED':
          cfi.disabled = true;
          break;
      }
    }
  }

  if (data.childTools?.length) {
    data.childTools.forEach((ct) => checkToolForEvalConfig(ct, evalConfig));
  }
}

/**
 * ...
 */
export class ToolRequirementsParserService {
  /**
   * ...
   *
   * @param data ...
   * @param evalConfig ...
   * @return ...
   */
  parse(data: ToolData, evalConfig?: EvaluationConfig) {
    checkToolRules(data);

    // Evaluation configuration overrides.
    if (evalConfig) {
      checkToolForEvalConfig(data, evalConfig);
    }

    // console.log('Tool requirements parser results:\n', {
    //   toolData: data,
    //   config: evalConfig
    // });

    return data;
  }
}
