'use strict';

import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardClientTransfersView {
  tableProps: {}[] = [];
  tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  @Inject $rootScope;
  @Inject $state;
  @Inject $location;
  @Inject $modals;
  @Inject $auth;

  @State clientTransfers;

  $onInit() {
    var $vm = this;
    var $goTo = this.$state.go;
    var $checkAuth = this.$auth.hasAccess;

    this.tableItems = this.clientTransfers.items;

    this.tableProps = [
      {
        type: 'String',
        key: '',
        label: 'Client Name',
        transform: (prop, row) =>
          row.client.lName || row.client.fName || row.client.mName
            ? `${row.client.lName}, ${row.client.fName} ${row.client.mName}`
            : `${row.client.localId}`
      },
      {
        type: 'String',
        key: 'targetInst',
        label: 'Target Institution'
      },
      {
        type: 'String',
        key: 'previousInst',
        label: 'Previous Institution'
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          {
            label: 'Edit Institution',
            icon: 'edit',
            fn: (val) => $vm.openEditInst(val),
            hide: () => !$checkAuth('UpdateInstitution')
          },
          {
            label: 'Delete Institution',
            icon: 'edit',
            fn: (val) => $vm.deleteInstitution(val),
            hide: () => !$checkAuth('DeleteInstitution')
          }
        ]
      }
    ];
  }
}

export default DashboardClientTransfersView;
