import {
  DEFAULT_CLIENT_TYPE_OPTIONS,
  DEFAULT_ETHNICITY_OPTIONS,
  CLIENT_CONFIG_OTHER_OPTION
} from '@constants';
import { ClientConfig, ClientFieldOptions } from '@interfaces/client-config';

export type ClientConfigCreationAttributes = Partial<ClientConfig>;

/**
 * ...
 *
 * @return ...
 */
function createDefaultNativeFields() {
  return {
    localId: {
      key: 'localId',
      label: 'Client ID',
      allowNull: false,
      show: true,
      type: 'String'
    },
    fName: {
      key: 'fName',
      label: 'First Name',
      allowNull: false,
      show: true,
      type: 'String'
    },
    mName: {
      key: 'mName',
      label: 'Middle Name',
      allowNull: true,
      show: true,
      type: 'String'
    },
    lName: {
      key: 'lName',
      label: 'Last Name',
      allowNull: false,
      show: true,
      type: 'String'
    },
    alias: {
      key: 'alias',
      label: 'Alias',
      allowNull: true,
      show: true,
      type: 'String'
    },
    dob: {
      key: 'dob',
      label: 'Date of Birth',
      allowNull: false,
      show: true,
      type: 'String'
    },
    address1: {
      key: 'address1',
      label: 'Address 1',
      allowNull: true,
      show: true,
      type: 'String'
    },
    address2: {
      key: 'address2',
      label: 'Address 2',
      allowNull: true,
      show: true,
      type: 'String'
    },
    address3: {
      key: 'address3',
      label: 'Address 3',
      allowNull: true,
      show: true,
      type: 'String'
    },
    city: {
      key: 'city',
      label: 'City',
      allowNull: true,
      show: true,
      type: 'String'
    },
    stateProvince: {
      key: 'stateProvince',
      label: 'State/Province',
      allowNull: true,
      show: true,
      type: 'String'
    },
    postalCode: {
      key: 'postalCode',
      label: 'Postal Code',
      allowNull: true,
      show: true,
      type: 'String'
    },
    country: {
      key: 'country',
      label: 'Country',
      allowNull: false,
      show: true,
      type: 'String'
    },
    clinicalInfo: {
      key: 'clinicalInfo',
      label: 'Clinical Info',
      allowNull: true,
      show: true,
      type: 'String'
    },
    phone: {
      key: 'phone',
      label: 'Phone Number',
      allowNull: true,
      show: true,
      type: 'String'
    },
    email: {
      key: 'email',
      label: 'Email',
      allowNull: true,
      show: true,
      type: 'String'
    },
    ethnicity: {
      key: 'ethnicity',
      label: 'Race/Ethnicity',
      allowNull: true,
      show: true,
      type: 'String',
      options: [...DEFAULT_ETHNICITY_OPTIONS, CLIENT_CONFIG_OTHER_OPTION]
    },
    type: {
      key: 'type',
      label: 'Client Type',
      allowNull: true,
      show: true,
      type: 'String',
      options: [...DEFAULT_CLIENT_TYPE_OPTIONS, CLIENT_CONFIG_OTHER_OPTION],
      isList: true
    }
  } as ClientConfig['fields'];
}

/**
 * ...
 *
 * @return ...
 */
function createTestCustomFields() {
  //
  const range = (stop: number) => {
    const arr: number[] = [];

    for (let i = 0; i < stop; i++) arr.push(i);

    return arr;
  };

  //
  const createRandomWord = () =>
    'abcdefghijklmnopqrstuvwxyz'
      .split('')
      .sort(() => (Math.random() < 0.5 ? -1 : 1))
      .slice(0, 10)
      .join('');
  //
  const capitalize = (word: string) =>
    word.replace(/^./, (c) => c.toUpperCase());

  const customFields: ClientConfig['customFields'] = {
    testProp: {
      key: 'testProp',
      label: 'Test Property',
      allowNull: true,
      type: 'String',
      show: true
    },
    listyList: {
      key: 'listyList',
      label: 'My List',
      allowNull: true,
      type: 'String',
      show: true,
      options: range(8).map(() => {
        const value = capitalize(createRandomWord());

        return { label: value, value };
      }),
      isList: true
    }
  };

  // TEMP: for testing...

  for (let i = 0; i < 10; i++) {
    const n = Math.random();
    //
    const key = createRandomWord();
    //
    const label = capitalize(key);
    //
    const type = n < 0.333 ? 'String' : n < 0.666 ? 'Boolean' : 'Number';

    customFields[key] = {
      key,
      type,
      label,
      allowNull: true,
      show: true
    };
  }

  return customFields;
}

/**
 * Client Config data model.
 */
export class ClientConfigModel implements ClientConfig {
  fields: Record<string, ClientFieldOptions>;
  customFields: Record<string, ClientFieldOptions>;

  constructor(options: ClientConfigCreationAttributes) {
    this.fields = options.fields ?? createDefaultNativeFields();
    this.customFields = options.customFields ?? {};
  }

  /**
   * Create a client config with the default values.
   *
   * @return A client config.
   */
  static createDefault() {
    const fields = createDefaultNativeFields();
    // const customFields = createTestCustomFields();

    return new ClientConfigModel({ fields, customFields: {} });
  }
}
