import angular from 'angular';

/**
 * ...
 */
const ngInput: angular.IDirectiveFactory = () => {
  const link: angular.IDirective['link'] = (scope, element, attr) => {
    element.on('input', ({ target }) =>
      scope.$eval(attr.ngInput, { $event: target.value })
    );
  };

  return { restrict: 'A', require: 'ngModel', link };
};

export default angular.module('app.ngInput', []).directive('ngInput', ngInput)
  .name;
