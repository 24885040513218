export default {
  coverPage() {
    return {
      head: [['Name:', 'client.fName client.lName']],
      body: [
        ['ID Number:', 'client.localId'],
        ['Age:', 'clientAge'],
        ['Sex:', 'client.sex'],
        ['Gender Identity:', 'client.gender'],
        ['Race/Ethnicity:', 'client.ethnicity'],
        ['Current Location:', 'clientLocation'],
        ['Location at Time of Assessment:', 'clientAssessmentLocation'],
        ['Assessment Date:', 'assessmentDate'],
        ['Interview Date:', 'interviewDate'],
        ["Assessor's Name:", 'user.fName user.lName']
      ],
      columnStyles: {
        0: {
          minCellWidth: 100
        }
      }
    };
  }
};
