/**
 * ...
 */
export type Operator = '+' | '-' | '*' | '/';

/**
 * ...
 */
export type Comparator = '<' | '>' | '<=' | '>=' | '==';

/**
 * ...
 */
export enum Operation {
  Constant = 'ConstantOp',
  Input = 'InputOp',
  Value = 'ValueOp',
  Uni = 'UniOp',
  Binary = 'BinaryOp',
  Comparator = 'ComparatorOp',
  Output = 'OutputOp'
}

/**
 * ...
 */
export interface ProrationOperation {
  varId: string;
  type: Operation;
  label: string;
  result: number;
  nextVarId: string;
  operatorSymbol: string;
  value: number;
}

/**
 * ...
 */
export interface ConstantOperation extends ProrationOperation {
  type: Operation.Constant;
}

/**
 * ...
 */
export interface InputOperation extends ProrationOperation {
  type: Operation.Input;
  inputCompId: string;
  outputCompId: string;
}

/**
 * ...
 */
export interface ValueOperation extends ProrationOperation {
  type: Operation.Value;
  operatorSymbol: Operator;
}

/**
 * ...
 */
export interface UniOperation extends ProrationOperation {
  type: Operation.Uni;
  operatorSymbol: 'sin' | 'cos';
}

/**
 * ...
 */
export interface BinaryOperation extends ProrationOperation {
  type: Operation.Binary;
  operatorSymbol: Operator;
  variable1: string;
  variable2: string;
}

/**
 * ...
 */
export interface ComparatorOperation extends ProrationOperation {
  type: Operation.Comparator;
  operatorSymbol: Comparator;
  nextVarTrueId: string;
  nextVarFalseId: string;
  compareValue: number;
}

/**
 * ...
 */
export interface OutputOperation extends ProrationOperation {
  type: Operation.Output;
}
