import angular, { animate, IDirectiveLinkFn, IDirectiveFactory } from 'angular';

import './lister-view-list.scss';

/**
 * ...
 */
const linkFn: IDirectiveLinkFn = (scope, _element, _attrs, ctrls) => {
  const { model: $model, parent: $parent } = scope;
  const [$$lister] = ctrls;

  var $items;

  const buildItemList = function buildItemList() {
    $items = Object.values($$lister.$items)
      .filter(
        ({ model, parent }) =>
          model.$name == $model.$name && (!$parent || parent == $parent)
      )
      // Sort items alphabetically by name
      .sort((a, b) => {
        const nameA = a.name.toLowerCase(),
          nameB = b.name.toLowerCase();

        return nameA > nameB ? 1 : nameA < nameB ? -1 : 0;
      });

    if ($$lister.$newItem && $$lister.$newItem.parent == $parent) {
      $items.unshift($$lister.$newItem);
    }
  };

  scope.$on('addChild', (e, { parentId, item }) => {
    if (parentId == $parent?.id) {
      $items.unshift(item);
    }
  });

  scope.$on('removeChild', (e, item) => {
    if ($items.includes(item)) {
      $items.splice(
        $items.findIndex(({ id }) => id == item.id),
        1
      );
    }
  });

  scope.$on('updateChildList', (e, parentId) => {
    if (parentId == $parent?.id) {
      buildItemList();
    }
  });

  buildItemList();

  Object.defineProperty(scope, '$items', { get: () => $items });
};

/**
 * ...
 */
const listerViewListDirectiveFactory: IDirectiveFactory = () => ({
  restrict: 'E',
  require: ['^listerView', '?^^listerViewListItem'],
  replace: true,
  scope: { model: '=', parent: '=' },
  link: linkFn,
  template: require('./lister-view-list.html')
});

/**
 * ...
 */
function listerViewListAnimateFactory($animateCss: animate.IAnimateCssService) {
  'ngInject';

  const enter: animate.IAnimateCallbackObject['enter'] = (el, done) => {
    if (!el.hasClass('new')) {
      done();
      return;
    }

    el = el.find('>.item-header');

    return $animateCss(el, {
      event: 'enter',
      structural: true,
      easing: 'cubic-bezier(0, 0.63, 0.07, 1.005)',
      from: { height: 0 },
      to: { height: 60, opacity: 1 },
      duration: 0.25
    });
  };

  const leave: animate.IAnimateCallbackObject['leave'] = (el, done) => {
    if (!el.hasClass('new')) {
      done();
      return;
    }

    el = el.find('>.item-header');

    return $animateCss(el, {
      event: 'leave',
      structural: true,
      easing: 'cubic-bezier(0, 0.63, 0.07, 1.005)',
      from: { height: 60, opacity: 1 },
      to: { height: 0, opacity: 0 },
      duration: 0.25
    });
  };

  return { enter, leave };
}

export default angular
  .module('lister.listerViewList', [])
  .directive('listerViewList', listerViewListDirectiveFactory)
  .animation('.lister-list-item', listerViewListAnimateFactory).name;
