'use strict';

import { State, Mutation, Getter, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

import { forEach, find, remove } from 'lodash';

@Controller
class DashboardUserView {
  activeSection: number = 0;
  loading: boolean = false;
  phonePattern =
    /^([a-zA-Z,#/ \.\(\)\-\+\*]*[0-9]){7}[0-9a-zA-Z,#/ \.\(\)\-\+\*]*$/;
  countries;
  stateProvinces;
  userData: {} = {
    fName: null,
    lName: null,
    email: null,
    phoneWork: null,
    phoneCell: null,
    country: null,
    address1: null,
    address2: null,
    address3: null,
    stateProvince: null,
    city: null,
    postalCode: null,
    senderId: null
  };
  userInstitutionId: Institution = -1;
  //Form Groups
  editName: boolean = false;
  editEmail: boolean = false;
  editPhone: boolean = false;
  editAddress: boolean = false;
  editPolicy: boolean = false;
  policyChoice: number = 1;
  //
  managedPolicies: {}[] = [];
  managedPolicy: Policy = null;
  institutionPolicies: {}[] = [];
  institutionPolicy: Policy = null;
  customPolicy: Policy = null;
  userPolicy: Policy = null;
  updateInstitutionSuperAdmin: boolean = false;

  @Inject $scope;
  @Inject $rootScope;
  @Inject $filter;
  @Inject $state;
  @Inject $location;
  @Inject $http;
  @Inject $api;
  @Inject $api2;
  @Inject $auth;
  @Inject $util;
  @Inject getFormItems;
  @Inject utils;
  @Inject errorHandler;
  @Inject $modals;
  @Inject Notification;

  @State me;
  @State users;
  @State(({ institutions }) => institutions.items) institutions;
  @Getter activeInstId;
  @Mutation('me/setProps') setMe;
  @Action('institutions/getLite') getLiteInstitution;
  @Action('users/get') getUser;

  get userPermissions() {
    return userPermissions;
  }

  $onInit() {
    const { users, tools } = this;

    this.$me = this.me;
    this.isAdmin = !this.$me.institution;

    let userId = this.$location.$$search.id;
    this.user = this.users.items.find((item) => item.id == userId);

    // Redirect to users pages if specified user can't be found.
    if (!this.user) {
      this.$state.go('dashboardUsers');
      return;
    }

    this.userIsMe = this.$me.id === this.user.id;
    this.userInstitutionId = this.activeInstId;

    this.loadData();

    this.$scope.$watch(
      () => this.activeSection,
      () => {
        [
          'userName',
          'userEmail',
          'userPhone',
          'userAddress',
          'userPermissions',
          'mfa'
        ].forEach((group) => {
          this.cancelEdit(group);
        });
      }
    );
  }

  async loadData() {
    this.loading = true;

    if (!this.user) {
      console.log('NO USER, TRY TO SET');
    }

    this.user = await this.getUser({
      instId: this.activeInstId || this.userInstitutionId,
      userId: this.user.id
    });

    // if (this.isAdmin) {
    //   let res = await this.$api2.gm.getUser({
    //     userId: this.user.id
    //   });
    //   console.log(res);
    //   this.user = res;
    // } else if (
    //   this.$auth.hasAccess(['institutionmanager:GetInstitutionUser'])
    // ) {
    //   let res = await this.$api2.im.getInstitutionUser({
    //     institutionId: this.activeInstId || this.userInstitutionId,
    //     userId: this.user.id
    //   });
    //   this.user = res;
    // }

    // Set user data object
    this.userData.fName = this.user.fName;
    this.userData.lName = this.user.lName;
    this.userData.email = this.user.email;
    this.userData.phoneWork = this.user.phoneWork;
    this.userData.phoneCell = this.user.phoneCell;
    this.userData.country = this.user.country;
    this.userData.address1 = this.user.address1;
    this.userData.address2 = this.user.address2;
    this.userData.address3 = this.user.address3;
    this.userData.stateProvince = this.user.stateProvince;
    this.userData.city = this.user.city;
    this.userData.postalCode = this.user.postalCode;
    this.userData.senderId = this.user.senderId;

    // Get countries
    this.countries = await this.getFormItems.countries();

    // Get stateProvinces
    if (this.user.country) {
      let methodName;

      if (this.user.country === 'US') {
        methodName = 'usStates';
      } else if (this.user.country === 'CA') {
        methodName = 'canadaProvinces';
      } else if (this.user.country === 'GB') {
        methodName = 'ukProvinces';
      } else if (this.user.country === 'AU') {
        methodName = 'auStates';
      }

      this.stateProvinces = await this.getFormItems[methodName]();
    }

    this.loadingData = true;

    // Get managed Polices
    if (this.$auth.hasAccess(['gearsmanager:ListManagedPolicies'])) {
      {
        let res = await this.$api.GM.listManagedPolicies();
        this.managedPolicies = res.data || [];
        remove(this.managedPolicies, {
          id: 'gifradmin'
        });
        remove(this.managedPolicies, {
          id: 'gifradminevaltracker'
        });
      }
    } else if (
      this.$auth.hasAccess(['institutionmanager:ListManagedPolicies'])
    ) {
      let res = await this.$api.IM.listManagedPolicies(this.activeInstId);
      this.managedPolicies = res.data || [];
    }

    // Get institution policies and initialize
    if (!this.isAdmin) {
      let res = await this.$api.IM.listPolicies(this.userInstitutionId);
      this.institutionPolicies = res.data || [];
    } else {
      this.allInstitutionPolicies = {};

      for (let i = 0; i < this.user.policies?.length; i++) {
        let policy = this.user.policies[i];

        if (policy.institutionId) {
          let res = await this.$api.IM.listPolicies(policy.institutionId);
          this.allInstitutionPolicies[policy.institutionId] = res.data || [];
          if (
            policy.institutionPolicyId &&
            find(res.data, { id: policy.institutionPolicyId })
          ) {
            this.user.policies[i] = find(res.data, {
              id: policy.institutionPolicyId
            });
            this.user.policies[i].institutionPolicyId =
              policy.institutionPolicyId;
          }

          // Add institution name to policy
          if (
            !find(this.institutions, {
              id: policy.institutionId
            })
          )
            await this.getLiteInstitution(policy.institutionId);

          this.user.policies[i].institutionName = find(this.institutions, {
            id: policy.institutionId
          }).name;
        } else {
          this.user.policies[i].institutionName = 'No Institution';
        }
      }
    }

    let targetPolicy;

    if (!this.isAdmin) {
      // not admin
      console.log(this.user);
      for (let policy of this.user.policies) {
        if (policy.institutionId == this.userInstitutionId) {
          targetPolicy = policy;
          break;
        }
      }

      if (!targetPolicy) {
        console.warn(
          `None of the user's policies are associated with the current institution ${this.userInstitutionId}`
        );
        this.policyChoice = 3;
      } else {
        if (targetPolicy.managedPolicy) {
          this.managedPolicy = this.managedPolicies.find(
            ({ id }) => id == targetPolicy.managedPolicyId
          );
          this.userPolicy = this.managedPolicy;
          this.userPolicy.institutionId = targetPolicy.institutionId;
          this.policyChoice = 1;
        } else if (targetPolicy.institutionPolicyId) {
          this.institutionPolicy = this.institutionPolicies.find(
            ({ id }) => id == targetPolicy.institutionPolicyId
          );
          this.userPolicy = this.institutionPolicy;

          this.policyChoice = 2;
        } else {
          this.customPolicy = targetPolicy;
          this.userPolicy = this.customPolicy;

          this.policyChoice = 3;
        }
      }
    } else {
      // isAdmin
      this.userPolicy = this.user.policies[0];
      this.policySelected();
    }

    this.loading = false;

    this.$scope.$apply();
  }

  policySelected() {
    let policyInstId;

    if (this.userPolicy.institutionId) {
      this.institutionPolicies =
        this.allInstitutionPolicies[this.userPolicy.institutionId];
      policyInstId = this.userPolicy.institutionId;
    }

    if (this.userPolicy.managedPolicyId) {
      let managedPolicyId = this.userPolicy.managedPolicyId;
      this.managedPolicy = this.managedPolicies.find(
        ({ id }) => id == this.userPolicy.managedPolicyId
      );
      this.userPolicy = this.managedPolicy;
      this.userPolicy.managedPolicyId = managedPolicyId;
      if (policyInstId) this.userPolicy.institutionId = policyInstId;

      this.policyChoice = 1;
    } else if (this.userPolicy.institutionPolicyId) {
      this.institutionPolicy = this.institutionPolicies.find(
        ({ id }) => id == this.userPolicy.institutionPolicyId
      );
      this.userPolicy = this.institutionPolicy;

      this.policyChoice = 2;
    } else {
      this.customPolicy = this.userPolicy;
      this.userPolicy = this.customPolicy;

      this.policyChoice = 3;
    }
  }

  async createInlinePolicy() {
    let policy = await this.$modals.util.policyEditor(
      this.customPolicy || {
        name: 'New User Policy',
        institutionId: this.activeInstId
          ? this.activeInstId
          : this.userPolicy.institutionId
      },
      true
    );

    if (!policy) {
      return;
    }

    console.log('inlinePolicy created!');

    this.customPolicy = policy;
  }

  editFormGroup(groupName) {
    [
      'userName',
      'userEmail',
      'userPhone',
      'userAddress',
      'userPermissions',
      'mfa'
    ]
      .filter((group) => group != groupName)
      .forEach((group) => {
        this.cancelEdit(group);
      });

    switch (groupName) {
      case 'userName':
        this.editName = true;

        break;
      case 'userEmail':
        this.editEmail = true;

        break;
      case 'userPhone':
        this.editPhone = true;

        break;
      case 'userAddress':
        this.editAddress = true;

        break;
      case 'userPermissions':
        this.editPolicy = true;

        break;
      case 'mfa':
        this.editMFA = true;

        break;
      default:
        console.warn('Group does not exist');
    }
  }

  cancelEdit(groupName) {
    switch (groupName) {
      case 'userName':
        this.editName = false;

        this.userData.fName = this.user.fName;
        this.userData.lName = this.user.lName;

        break;
      case 'userEmail':
        this.editEmail = false;

        this.userData.email = this.user.email;
        break;
      case 'userPhone':
        this.editPhone = false;

        this.userData.phoneWork = this.user.phoneWork;
        this.userData.phoneCell = this.user.phoneCell;

        break;
      case 'userAddress':
        this.editAddress = false;

        this.userData.country = this.user.country;
        this.userData.address1 = this.user.address1;
        this.userData.address2 = this.user.address2;
        this.userData.address3 = this.user.address3;
        this.userData.stateProvince = this.user.stateProvince;
        this.userData.city = this.user.city;
        this.userData.postalCode = this.user.postalCode;

        break;
      case 'userPermissions':
        this.editPolicy = false;

        break;
      default:
        console.warn('Group does not exist');
    }
  }

  async updateUserInfo(groupName) {
    let payload = {
      userId: this.user.id
    };

    switch (groupName) {
      case 'userName':
        payload.fName = this.userData.fName;
        payload.lName = this.userData.lName;

        break;
      case 'userEmail':
        payload.email = this.userData.email;

        break;
      case 'userPhone':
        payload.phoneWork = this.userData.phoneWork;
        payload.phoneCell = this.userData.phoneCell;

        break;
      case 'userAddress':
        payload.country = this.userData.country;
        payload.address1 = this.userData.address1;
        payload.address2 = this.userData.address2;
        payload.address3 = this.userData.address3;
        payload.stateProvince = this.userData.stateProvince;
        payload.city = this.userData.city;
        payload.postalCode = this.userData.postalCode;

        break;

      case 'mfa':
        payload.mfa = this.userData.mfa;

        break;
      default:
        console.warn('Group does not exist');
    }

    try {
      var res =
        groupName === 'mfa'
          ? await this.$api.GM.updateUser2FA(this.user.id, payload)
          : await this.$api.GM.updateUser(this.user.id, payload);

      if (res.data?.error) {
        throw res.data?.error;
      }
    } catch (err) {
      console.log(err);
      this.errorHandler(err);

      return;
    }

    Object.keys(this.userData).forEach((key) => {
      this.user[key] = this.userData[key];
    });

    this.utils.notify('success', 'Update User Successful');

    if (this.user.id == this.me.id) {
      this.setMe(this.userData);
    }

    Object.keys(this.userData).forEach((key) => {
      this.user[key] = this.userData[key];
    });

    this.editName = false;
    this.editEmail = false;
    this.editPhone = false;
    this.editAddress = false;
    this.editPolicy = false;
  }

  async updateUserPolicy() {
    let policy,
      payload = {
        userId: this.user.id,
        managedPolicyId: null,
        institutionPolicyId: null,
        inlinePolicy: null,
        existingInstitutionPolicyId: this.isAdmin
          ? this.userPolicy?.institutionPolicyId
          : this.userPolicy?.id
      };

    let policyInstitutionId;

    switch (this.policyChoice) {
      case 1: {
        policy = this.managedPolicy;
        payload.managedPolicyId = policy.id;
        if (this.userPolicy?.institutionId) {
          policyInstitutionId = this.userPolicy.institutionId;
          policy.institutionId = policyInstitutionId;
        }

        break;
      }
      case 2: {
        policy = this.institutionPolicy;
        policyInstitutionId = this.institutionPolicy.institutionId;
        payload.institutionPolicyId = policy.id;
        policy.institutionPolicyId = policy.id;
        break;
      }
      case 3: {
        policy = this.customPolicy;
        policyInstitutionId = this.customPolicy.institutionId
          ? this.customPolicy.institutionId
          : this.userPolicy.institutionId;
        payload.inlinePolicy = policy;
        policy.isInlinePolicy = true;
        break;
      }
    }

    try {
      if (!this.isAdmin) {
        await this.$api.IM.assignPolicyToUser(
          this.userInstitutionId ? this.userInstitutionId : policyInstitutionId,
          payload
        );
      } else if (this.isAdmin) {
        payload.institutionId = this.userInstitutionId
          ? this.userInstitutionId
          : policyInstitutionId;
        payload.updateInstitutionSuperAdmin = this.updateInstitutionSuperAdmin;
        await this.$api.GM.assignPolicyToUser(payload);
      }

      this.userPolicy = policy;

      // update the user policies list with new policy
      this.user.policies.splice(
        this.user.policies.findIndex(
          ({ institutionId }) => institutionId == policy.institutionId
        ),
        1,
        policy
      );

      if (this.isAdmin) {
        for (let policy of this.user.policies) {
          if (policy.institutionId) {
            policy.institutionName = find(this.institutions, {
              id: policy.institutionId
            }).name;
          } else {
            policy.institutionName = 'No Institution';
          }
        }
      }

      this.Notification.success('Updated user policy.');
    } catch (err) {
      console.error('FAILED TO UPDATE USER POLICY', err);
      // this.Notification.success('Failed to update user asigned policy.');
    }

    this.editPolicy = false;

    this.$scope.$apply();
  }
}

export default DashboardUserView;
