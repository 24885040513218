import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
export default class DashboardTestView {
  format: string = 'MM/dd/yyyy';
  timezone: string | null = null;
  someDate: Date = new Date();
  someOtherDate: Date = new Date();
  dates = [];

  @Inject $filter;
  @Inject $sanitize;

  get regionFormat() {
    return window.gearsRegionFormat;
  }

  get someDateFiltered() {
    return this.$filter('dynamicDate')(this.someDate, this.format);
  }

  $onInit() {
    console.log('DashboardTestView view loaded!');

    for (let i = 0; i < 10; i++) {
      this.dates.push(new Date());
    }
  }

  log(t) {
    console.log('input =>: ', t);
    console.log('sanitized =>: ', this.$sanitize(t));
    this.textToBeSanitized = this.$sanitize(t);
  }
}
