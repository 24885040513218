'use strict';

import angular from 'angular';
import _ from 'lodash';
import pdfObject from 'pdfobject';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class DescriptionModalController extends Modal {
  @Inject $scope;
  @Inject $store;
  @Inject $auth;
  @Inject $api;
  @Inject $sce;
  @Inject $http;
  @Inject Notification;

  $setup() {
    this.tool = this.$props;
    console.log('this.tool: ', this.tool);

    this.loading = true;
    this.noDescription = true;

    this.loadData();
  }

  async loadData() {
    try {
      let { id, publishedCommitId } = this.tool;

      let res = await this.$api.toolCreator.getToolCommit({
        toolId: id,
        commitId: publishedCommitId
      });

      if (res.status !== 200) {
        this.Notification.error(
          res.data.feedback ||
            "There was an error retrieving this Tool Commit's Data"
        );
        this.loading = false;
        //

        return;
      }

      if (!res.data.liveDescriptionPdf) {
        this.loading = false;
        return;
      }

      // let pdfData = await this.$api.toolCreator.getDescriptionPdf(
      //   res.data.liveDescriptionPdf.id
      // );
      let pdfData = await this.$api.toolCreator.getDescriptionPdf({
        toolId: id,
        descriptionPdfId: res.data.liveDescriptionPdfId
      });

      if (pdfData.data.error) {
        this.Notification.error(
          pdfData.data.feedback || 'Error retrieving your PDF'
        );

        return;
      }

      let options = {
        pdfOpenParams: {
          view: 'FitV',
          page: '1',
          pagemode: 'none',
          toolbar: 0
        },
        // forcePDFJS: true,
        PDFJS_URL: 'assets/components/pdfjs/build/generic/web/viewer.html'
      };

      if (this.$store.isIE || this.$store.isEdge || this.$store.isSafari)
        this.$store.forcePDFJS = true;
      let file = new Blob([pdfData.data], {
        type: 'application/pdf'
      });

      let fileUrl = URL.createObjectURL(file);

      this.trustedPdf = this.$sce.trustAsResourceUrl(fileUrl);
      this.pdf = fileUrl;
      pdfObject.embed(this.pdf, '#pdfobject-container', options);
      this.noDescription = false;

      this.loading = false;
    } catch (err) {
      console.log(err);
      this.Notification.error(err.feedback || 'Error retrieving your PDF');
    }
  }
}

export default angular
  .module('app.descriptionModal', [])
  .directive('descriptionModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./description.html'),
    controller: DescriptionModalController,
    controllerAs: 'vm'
  })).name;
