'use strict';

import angular from 'angular';

export default angular
  .module('app.modalFooter', [])
  .directive('modalFooter', () => {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      // scope: true,
      template: require('./modal-footer.html')
    };
  }).name;
