import { Component, Ng, Prop } from '@angular';

import { Evaluation } from '@interfaces/evaluation';
import { CodesAndScore } from '@interfaces/tool';

@Component({
  name: 'codingFormItems',
  template: require('./coding-form-items.html')
})
class CodingFormItems extends Ng {
  @Prop() readonly viewingEvaluation!: boolean;
  @Prop() readonly cfi!: unknown;
  @Prop() readonly index!: number;
  @Prop() readonly evaluation!: Evaluation;

  /**
   * ...
   *
   * @param message ...
   */
  openMessageModal(message: string) {
    this.$modals.settings.message('ITEM REFERENCE', message);
  }

  /**
   * ...
   *
   * @param source ...
   * @param choice ...
   */
  sourceChosen(source: unknown, choice: unknown) {
    if (!Array.isArray(source.source)) source.source = [];

    if (choice === 'other' && source.source.indexOf('other') > -1) {
      source.customSource = null;
      source.source.splice('other', 1);
    } else {
      let index = source.source.indexOf(choice);
      if (index > -1) {
        source.source.splice(index, 1);
      } else {
        source.source.push(choice);
      }
      // source.source = source.source === choice ? null : choice;
    }
  }

  /**
   * ...
   *
   * @param itemWizard ...
   * @param test ...
   */
  openWizard(itemWizard: unknown, test: unknown) {
    this.evlUtils.openWizard(itemWizard);
  }

  /**
   * ...
   *
   * @param questionId ...
   * @param questionCodesAndScore ...
   */
  updateEvaluationQuestionData(
    questionId: string,
    questionCodesAndScore: CodesAndScore[]
  ) {
    this.evlUtils.updateEvaluationQuestionData(
      questionId,
      questionCodesAndScore
    );
  }

  /**
   * ...
   *
   * @param page ...
   */
  openPDFBookmark(page: unknown) {
    this.evlUtils.openPDFBookmark(page);
  }

  /**
   * ...
   *
   * @param textColor ...
   * @returns ...
   */
  getTextColor(textColor: unknown) {
    if (textColor) return textColor;

    return this.$store.state.theme === 'dark' ? '#e8e8e8' : '#161616';
  }
}

export default CodingFormItems.$module;

// export default angular
//   .module('app.coding-form-items', [])
//   .directive('codingFormItems', function() {
//     return {
//       template: require('./coding-form-items.html'),
//       restrict: 'EA',
//       scope: {
//         viewingEvaluation: '=',
//         cfi: '=',
//         index: '=',
//         evaluation: '='
//       },
//       controller: CodingFormItems,
//       controllerAs: 'vm'
//     };
//   }).name;
