'use strict';

import angular from 'angular';

export default function Directive(args) {
  return function(ClassRef) {
    const config = { ...args };

    // const config = {
    //   bindToController: args.bindToController || false,
    //   controller: args.controller || false,
    //   controllerAs: args.controllerAs || false,
    //   multiElement: args.multiElement || false,
    //   priority: args.priority || false,
    //   replace: args.replace || false,
    //   require: args.require || false,
    //   restrict: args.restrict || false,
    //   scope: args.scope || false,
    //   template: args.template || false,
    //   templateNamespace: args.templateNamespace || false,
    //   templateUrl: args.templateUrl || false,
    //   terminal: args.terminal || false,
    //   transclude: args.transclude || false,
    // };

    var $properties = [];

    Object.getOwnPropertyNames(ClassRef.prototype)
      .filter(key => key != 'constructor')
      .forEach(key => {
        let descriptor = Object.getOwnPropertyDescriptor(
          ClassRef.prototype,
          key
        );

        $properties.push({ key, descriptor });
      });

    config.compile = function $compile() {
      return {
        pre(scope, element, attrs, ctrls) {
          Object.defineProperties(scope, {
            $el: {
              enumerable: true,
              writable: true,
              value: element
            },
            $attrs: {
              enumerable: true,
              writable: true,
              value: attrs
            }
          });

          $properties.forEach(({ key, descriptor }) => {
            Object.defineProperty(scope, key, {
              ...descriptor,
              enumerable: true
            });
          });

          Object.entries(new ClassRef()).forEach(entry => {
            const [key, val] = entry;

            Object.defineProperty(scope, key, {
              writable: true,
              // conf
              enumerable: true,
              value: val
            });
          });

          if (args.props) {
            let props = !Array.isArray(args.props)
              ? Object.keys(args.props)
              : args.props;

            props.forEach(key => {
              const getVal =
                attrs.$attr[key].charAt(0) == ':'
                  ? val => scope.$eval(val)
                  : val => val;

              Object.defineProperty(scope, key, {
                enumerable: true,
                writable: true,
                value: getVal(attrs[key])
              });

              scope.$watch(
                () => getVal(attrs[key]),
                val => {
                  scope[key] = val;
                }
              );
            });
          }

          if ('$$watchers' in ClassRef) {
            ClassRef.$$watchers.forEach(watcher => {
              scope.$watch(
                watcher.expression,
                (newVal, oldVal) =>
                  scope[watcher.callback].apply(scope, [newVal, oldVal]),
                watcher.objectEquality
              );
            });
          }

          if ('$render' in scope) {
            scope.$render();
          }
        },
        post(scope, element, attrs, ctrls) {
          // console.log('POST', scope, element, attrs, ctrls);
        }
      };
    };

    return angular
      .module(`app.${args.name}`, args.deps || [])
      .directive(args.name, () => config).name;
  };
}

export function Watch(expression, objectEquality = false) {
  return function({ constructor }, prop) {
    if (!constructor.$$watchers) {
      constructor.$$watchers = [];
    }

    constructor.$$watchers.push({ expression, objectEquality, callback: prop });
  };
}
