'use strict';

import angular from 'angular';

export default angular
  .module('app.ngDraggable', [])
  .directive('ngDraggable', $document => {
    'ngInject';

    return {
      restrict: 'A',
      scope: {
        dragOptions: '=ngDraggable'
      },
      link: function(scope, element, attr) {
        var startX = 0,
          startY = 0,
          x = 0,
          y = 0;

        element.on('mousedown', event => {
          // Prevent default dragging of selected content
          event.preventDefault();

          startX = event.pageX - x;
          startY = event.pageY - y;

          $document.on('mousemove', mousemove);
          $document.on('mouseup', mouseup);
        });

        function mousemove(event) {
          y = event.pageY - startY;
          x = event.pageX - startX;

          element.css({
            top: y + 'px',
            left: x + 'px'
          });
        }

        function mouseup() {
          $document.unbind('mousemove', mousemove);
          $document.unbind('mouseup', mouseup);
        }
      }
    };
  }).name;
