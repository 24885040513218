// LSI-R Profile Report Generator
import { find, filter, maxBy, forEach, sortBy } from 'lodash';
import { differenceInYears, format, isValid } from 'date-fns';
import lsirPercentiles from './percentiles';
import lsirProfileReportCoverPage from './coverPage.html';
import lsirProfileReportTables from './tables';
import 'jspdf-autotable';
import fontDataUrls from '../fontDataUrls';
import JsPDF from 'jspdf';

export default {
  async generate(
    mhsLogoDataUrl,
    lsirLogoDataUrl,
    pageSetup,
    services,
    client,
    evaluation,
    tool,
    options,
    $me
  ) {
    let pageHeight = pageSetup.pageHeight;
    let pageWidth = pageSetup.pageWidth;
    let margins = pageSetup.margins;
    let $http = services.$http;
    let $filter = services.$filter;
    let $templateCache = services.$templateCache;
    let $store = services.$store;
    let $auth = services.$auth;
    let Util = services.$util;
    let notify = services.notify;
    let Upload = services.Upload;
    let lsirTitleDataUrl = lsirLogoDataUrl;
    let $reincode = services.$reincode;

    let parseOption = function (text) {
      // parse options from $me selection to see if it should be included in report
      return find(options, {
        text: text
      }).selected;
    };

    let evaluationCompletedDate = evaluation.updatedAt;
    const assessmentDate = evaluation.assignedDate;
    const interviewDate = evaluation.evaluation?.interview?.dateTime || 'N/A';
    // console.log('evaluation before parsing: ', angular.copy(evaluation));
    let evaluator = evaluation.evaluator;
    let offenderHistoryId = evaluation.offenderHistoryId;
    if (evaluation.evaluation) evaluation = evaluation.evaluation;
    if (evaluation.evaluationData) evaluation = evaluation.evaluationData;

    if (!evaluator)
      evaluator =
        evaluation.evaluatorId === $me.id
          ? $me
          : find($store.state.users.items, {
              id: evaluation.evaluatorId
            });

    // console.log('Generating lsir...');
    // console.log('client: ', client);
    // console.log('evaluation: ', evaluation);
    // console.log('tool: ', tool);
    // console.log('evaluator: ', evaluator);

    let section1Tool = find(tool.childTools, {
      id: 168
    });

    let clientNormativeType = evaluation.additionalInformation?.setting;
    if (clientNormativeType.includes('Institutional')) {
      clientNormativeType = 'Institutional';
    } else if (clientNormativeType.includes('Community')) {
      clientNormativeType = 'Community';
    }
    let institutionClient = clientNormativeType.includes('Institutional');
    let assessmentAge = differenceInYears(
      new Date(evaluationCompletedDate),
      new Date(client.dob)
    );
    let referralSource = 'N/A';
    let reasonForReferral = 'N/A';
    let disposition = 'N/A';
    let purposeOfReport = 'N/A';
    let context = 'N/A';
    let otherClientIssues = 'N/A';
    let currentOffenses = 'No current offenses were recorded.';
    let institutionalOffender = 'N/A';

    client.adult = assessmentAge >= 18;

    let offenderHistory;
    // if client doesn't have offender history length, pull offender history
    if (!evaluation.offenderHistory && !client?.offenderHistory.length) {
      let clientOffenderHistory = await $http.get(
        `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history`
      );
      if (clientOffenderHistory)
        client.offenderHistory = clientOffenderHistory.data;
    }
    if (!evaluation.offenderHistory && client?.offenderHistory.length) {
      if (offenderHistoryId) {
        let evaluationOffenderHistory = await $http.get(
          `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history/${offenderHistoryId}`
        );
        offenderHistory =
          evaluationOffenderHistory.status === 200
            ? evaluationOffenderHistory.data.historyData
            : [];
      } else {
        let toolOH = filter(client.offenderHistory, (oh) => {
          return oh.toolId === 157;
        });
        let latestOffenderHistory = maxBy(toolOH, 'updatedAt');
        let evaluationOffenderHistory = await $http.get(
          `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/history/${latestOffenderHistory.id}`
        );
        offenderHistory =
          evaluationOffenderHistory.status === 200
            ? evaluationOffenderHistory.data.historyData
            : [];
      }
    } else {
      offenderHistory = evaluation.offenderHistory;
    }

    // console.log('OFFENDER HISTORY: ', offenderHistory);
    if (!offenderHistory) {
      console.error('No offender history could be found for the evaluation');
      return;
    }
    let checkOHSection = function (section) {
      forEach(section.fields, (field) => {
        switch (field.key) {
          case 'reasonForReferral':
            reasonForReferral = field.model ? field.model : 'N/A';
            break;
          case 'referralSource':
            referralSource = field.model ? field.model : 'N/A';
            break;
          case 'purposeOfReport':
            purposeOfReport = field.model ? field.model : 'N/A';
            break;
          case 'otherOffendersIssues':
            let issuesArray = [];
            forEach(field.options, (opt) => {
              if (opt.selected) {
                issuesArray.push(
                  `${opt.value}${opt.customValue ? ': ' + opt.customValue : ''}`
                );
              }
            });
            if (issuesArray.length) {
              otherClientIssues = '';
              forEach(issuesArray, (iss, i) => {
                if (i === issuesArray.length - 1) {
                  otherClientIssues += 'and ' + iss + '.';
                } else {
                  otherClientIssues += iss + ', ';
                }
              });
            }
            break;
          case 'details':
            if (section.key === 'courtDispositionRecommendations') {
              disposition = field.model ? field.model : 'N/A';
            }
            break;
        }
      });
      if (section.key === 'currentOffenses' && section.table?.items?.length) {
        currentOffenses = {
          head: [
            [
              'Offence',
              '# of Counts',
              'Date',
              'Disposition',
              'Institution',
              'Comments'
            ]
          ],
          body: [],
          theme: 'grid'
        };
        let item;
        forEach(section.table.items, (offense) => {
          item = offense.item;
          currentOffenses.body.push([
            item.offense ? item.offense : 'N/A',
            item.numberOfCounts ? item.numberOfCounts : 'N/A',
            item.dateOfConviction && isValid(new Date(item.dateOfConviction))
              ? format(new Date(item.dateOfConviction), 'PP')
              : 'N/A',
            item.disposition ? item.disposition : 'N/A',
            item.institution ? item.institution : 'N/A',
            item.comments ? item.comments : 'N/A'
          ]);
        });
      } else if (section.key === 'setting' && section.model) {
        if (section.model.label.includes('Institutional')) {
          institutionalOffender = true;
        } else if (section.model.label.includes('Community')) {
          institutionalOffender = false;
        }
      }
      if (section?.sections?.length) {
        forEach(section.sections, (sec) => {
          checkOHSection(sec);
        });
      }
    };

    forEach(offenderHistory?.sections, (section) => {
      checkOHSection(section);
    });

    // Parse Risk Categories
    let riskCategories;
    forEach(tool.customRiskCategories, (crc) => {
      if (client.sex === crc.criteria.sex) {
        riskCategories = crc.categories;
        riskCategories = sortBy(riskCategories, 'low');
      }
    });
    let evaluationRiskCategory = evaluation.riskCategory;
    let evaluationRiskCategoryOverride =
      evaluation.overrideRiskCategory && evaluation.overrideRiskCategory.length
        ? evaluation.overrideRiskCategory
        : null;
    let evaluationScoreTotal = 'Score Not Calculated';
    let totalOmitted = 0;
    if (evaluation.data) {
      // calculate evaluation score total and find total omitted
      evaluationScoreTotal = 0;
      forEach(evaluation.data, (item) => {
        if (item.text === 'Omit' || item.text === 'omit') totalOmitted++;
        if (typeof item.score === 'number') evaluationScoreTotal += item.score;
      });
    }

    $templateCache.put(
      'lsirProfileReportCoverPage.html',
      lsirProfileReportCoverPage
    );
    let lsirPRCoverPage = $templateCache.get('lsirProfileReportCoverPage.html');
    let lsirPRCoverPageTable = JSON.stringify(
      lsirProfileReportTables.coverPage()
    );

    let strengthQuestionAddresses = [
      {
        address: '168>158>Q-tF_HyiU3',
        toolName: '1.1 Criminal History'
      },
      {
        address: '168>159>Q-HSfM0Hhh',
        toolName: '1.2 Education/Employment'
      },
      {
        address: '168>160>Q-7bQW8AEV',
        toolName: '1.3 Financial'
      },
      {
        address: '168>161>Q-IydRnh2g',
        toolName: '1.4 Family/Marital'
      },
      {
        address: '168>162>Q-1IWCjpFt',
        toolName: '1.5 Accommodation'
      },
      {
        address: '168>163>Q-KkLa5CPu',
        toolName: '1.6 Leisure/Recreation'
      },
      {
        address: '168>164>Q-WkgELxr9',
        toolName: '1.7 Companions'
      },
      {
        address: '168>165>Q-9Q9MQujG',
        toolName: '1.8 Alcohol/Drug Problem'
      },
      {
        address: '168>166>Q-4UXVzRpv',
        toolName: '1.9 Emotional/Personal'
      },
      {
        address: '168>167>Q-jqo1Ow7H',
        toolName: '1.10 Attitudes/Orientation'
      }
    ];

    // create variable map of known variables in report
    let variableMap = {};
    let key;
    const clientAge = client.age;
    variableMap[`clientAge`] = clientAge;
    const clientLocation = client.location;
    variableMap[`clientLocation`] = clientLocation;
    let clientAssessmentLocation = '';
    if (evaluation.clientZoneName)
      clientAssessmentLocation += `${evaluation.clientZoneName}`;
    if (evaluation.clientRegionName)
      clientAssessmentLocation += ` > ${evaluation.clientRegionName}`;
    if (evaluation.clientSubGroupName)
      clientAssessmentLocation += ` > ${evaluation.clientSubGroupName}`;
    if (clientAssessmentLocation.length == 0)
      clientAssessmentLocation = 'LOCATION NOT FOUND';
    variableMap[`clientAssessmentLocation`] = clientAssessmentLocation;
    for (key in client) {
      if (key === 'type') {
        let typeList = '';
        if (client.type) {
          let types =
            typeof client.type === 'string'
              ? client.type.split(',')
              : client.type;
          if (types.length === 1) {
            typeList = types[0];
          } else {
            forEach(types, (type, index) => {
              if (index === types.length - 1) {
                typeList += `and ${type}`;
              } else {
                typeList += `${type}, `;
              }
            });
          }
        } else {
          typeList = 'No Client Types Provided';
        }
        variableMap[`client.${key}`] = typeList;
      } else if (key === 'fName' || key === 'lName' || key === 'localId') {
        variableMap[`client.${key}`] = client[key] ? client[key] : 'N/A';
      } else {
        variableMap[`client.${key}`] = client[key]
          ? Util.decamlize(client[key])
          : 'N/A';
      }
    }

    // handle gender exclusively
    if (!client.gender) variableMap['client.gender'] = '-';

    variableMap['date.today'] = $filter('dynamicDate')(new Date(), 'longDate');
    variableMap['assessmentAge'] = assessmentAge;
    variableMap['referralSource'] = referralSource;
    variableMap['reasonForReferral'] = reasonForReferral;
    variableMap['disposition'] = disposition;
    variableMap['purposeOfReport'] = purposeOfReport;
    variableMap['context'] = context;
    variableMap['otherClientIssues'] = otherClientIssues;
    variableMap['clientNormativeType'] = clientNormativeType;
    variableMap['assessmentDate'] = $filter('dynamicDate')(
      new Date(assessmentDate),
      'longDate'
    );
    variableMap['interviewDate'] = $filter('dynamicDate')(
      new Date(interviewDate),
      'longDate'
    );
    for (key in evaluator) {
      variableMap[`user.${key}`] = Util.decamlize(evaluator[key]);
    }

    //================ CREATE VARIABLE MAP =================
    let variableRegEx = Object.keys(variableMap).join('|');
    variableRegEx = variableRegEx.replace(/\[/g, '\\[');
    variableRegEx = variableRegEx.replace(/]/g, '\\]');
    variableRegEx = variableRegEx.replace(/\)/g, '\\)');
    variableRegEx = variableRegEx.replace(/\(/g, '\\(');
    variableRegEx = variableRegEx.replace(/#/g, '\\#');
    variableRegEx = variableRegEx.replace(/\+/g, '\\+');
    variableRegEx = new RegExp(variableRegEx, 'gi');

    lsirPRCoverPage = lsirPRCoverPage.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    lsirPRCoverPageTable = lsirPRCoverPageTable.replace(
      variableRegEx,
      function (matched) {
        return variableMap[matched];
      }
    );
    lsirPRCoverPageTable = JSON.parse(lsirPRCoverPageTable);
    //================ END CREATE VARIABLE MAP =================
    //=================== PDF DOC SETUP ==================
    let specialElementHandlers = {
      '#editor': function () {
        return true;
      }
    };
    let pdf = new JsPDF('p', 'pt', 'letter');

    // set comfortaa font
    pdf.addFileToVFS('ComfortaaRegular.tff', fontDataUrls.comfortaaRegular());
    pdf.addFont('ComfortaaRegular.tff', 'Comfortaa', 'normal');
    pdf.addFileToVFS('ComfortaaBold.tff', fontDataUrls.comfortaaBold());
    pdf.addFont('ComfortaaBold.tff', 'Comfortaa', 'bold');
    pdf.setFont('Comfortaa');
    let page = 2;

    let header = function () {
      pdf.setFontSize(11);
      pdf.setFontStyle('normal');
      pdf.text(`Profile Report: ${client.name()}`, margins.left, 21);
      pdf.text(`Page ${page}`, pageWidth - margins.left - 50, 21);
      pdf.setLineWidth(0.5);
      pdf.setDrawColor(0, 0, 0);
      pdf.line(margins.left, 25, pageWidth - margins.left, 25);
      page = page + 1;
    };

    let footer = function () {
      pdf.addImage(
        mhsLogoDataUrl,
        'JPEG',
        pageWidth - margins.left - 80,
        pageHeight - margins.bottom,
        80,
        35
      );
    };
    //=================== END PDF DOC SETUP ==================
    // ======================COVER PAGE===============================
    pdf.addImage(lsirTitleDataUrl, 'JPEG', margins.left, margins.top, 189, 102);

    pdf.fromHTML(
      lsirPRCoverPage,
      margins.left,
      margins.top + 100,
      {
        width: margins.width,
        elementHandlers: specialElementHandlers
      },
      function () {
        // add cover page table with client info
        pdf.autoTable({
          head: lsirPRCoverPageTable.head,
          body: lsirPRCoverPageTable.body,
          columnStyles: lsirPRCoverPageTable.columnStyles,
          startY: 250,
          theme: 'striped',
          headStyles: {
            fillColor: [84, 9, 5],
            textColor: [255, 255, 255]
          }
        });

        // add omit details
        if (totalOmitted > 0) {
          pdf.setFontSize(10);
          pdf.text(
            `CAUTION: ${totalOmitted} of 54 LSI-R items have been omitted. The report can be produced but the validity and accuracy\nof the LSI-R would be increased if further information was gathered and the missing items completed.`,
            margins.left,
            600
          );
        }

        // add MHS logo and copyright info
        pdf.setFontSize(9);
        pdf.addImage(mhsLogoDataUrl, 'JPEG', margins.left, 680, 125, 54);
        pdf.text(
          'Copyright © 2002, Multi-Health Systems Inc. All rights reserved.\n' +
            'P.O. Box 950, North Tonawanda, NY 14120-0950\n' +
            '3770 Victoria Park Ave., Toronto, ON M2H 3M6',
          margins.left + 135,
          705
        );

        // ==========================END COVER PAGE==============================
        // ========================== PARSE EVALUATION FOR REPORT ====================
        pdf.addPage();
        header();
        footer();
        let y = margins.top + 10;

        let checkEndOfPage = function (y) {
          // console.log('checking end of page: ');
          // console.log('Y: ', y);
          // console.log('ph - mb - 100', pageHeight - margins.bottom - 100);
          return y >= pageHeight - margins.bottom - 100;
        };
        // logic for creating new line or new page if needbe
        let newLine = function (y) {
          if (y + 12 > pageHeight - (margins.top + margins.bottom)) {
            pdf.addPage();
            header();
            footer();
            y = margins.top + 20;
          } else {
            y += 12;
          }
          return y;
        };

        let addText = function (
          text,
          fontSize = 10,
          fontStyle = 'normal',
          align = 'left',
          x
        ) {
          if (!text) {
            console.error('Text parameter must be provided');
            return;
          }
          text = $reincode.text(text);
          if (fontSize > 10 && fontSize <= 14) y += 15;
          if (fontSize > 14 && fontSize < 18) y += 20;

          pdf.setFontSize(fontSize);
          pdf.setFontStyle(fontStyle);
          if (!text?.length || typeof text !== 'string') return;
          let lines = pdf.splitTextToSize(text, margins.width);

          forEach(lines, (line) => {
            pdf.setFontSize(fontSize);
            pdf.setFontStyle(fontStyle);
            pdf.text(line, x ? x : margins.left, y, align);
            y = newLine(y);
          });

          y = newLine(y);
        };

        let newPage = function (pageAlreadyAdded) {
          if (!pageAlreadyAdded) pdf.addPage();
          header();
          footer();
          y = margins.top + 10;
        };

        //#region INTRODUCTION
        if (parseOption('Introduction')) {
          addText(`Introduction`, 14, 'bold');
          addText(
            `The Level of Service Inventory-Revised is a risk and needs assessment tool. This report summarizes the results of the LSI-R administration, and provides information pertinent to the assessment of the individual.`
          );
        }
        //#endregion INTRODUCTION

        //#region Overall Assessment Based on LSI-R Total Score
        if (parseOption('Overall Assessment Based on LSI-R Total Score')) {
          if (checkEndOfPage(y)) newPage();
          addText(`Overall Assessment Based on LSI-R Total Score`, 14, 'bold');
          addText(
            `The graph below shows the LSI-R Total Score and indicates the classification level associated with that score.`
          );
          let makeOABGraph = function () {
            let divisions = 54;
            let totalWidth = pageWidth / 2;
            let totalHeight = 50;
            let lineHeight = 15;
            let lineWidth = totalWidth * (evaluationScoreTotal / divisions);
            let increments = totalWidth / divisions;
            if (y + totalHeight + 40 >= pageHeight - margins.bottom) newPage();
            // write zone titles and dividers
            forEach(riskCategories, (rc) => {
              pdf.text(
                `${rc.name}`,
                margins.left +
                  rc.low * increments +
                  ((rc.high - rc.low) / 2) * increments,
                y - 5,
                {
                  align: 'center'
                }
              );
              // draw divider lines at rc.high
              pdf.setDrawColor(200, 200, 200);
              pdf.line(
                margins.left + increments * rc.high,
                y,
                margins.left + increments * rc.high,
                y + totalHeight
              );
            });
            pdf.text(`0`, margins.left, y + totalHeight + 9);
            pdf.text(`10`, margins.left + increments * 10, y + totalHeight + 9);
            pdf.text(`20`, margins.left + increments * 20, y + totalHeight + 9);
            pdf.text(`30`, margins.left + increments * 30, y + totalHeight + 9);
            pdf.text(`40`, margins.left + increments * 40, y + totalHeight + 9);
            pdf.text(
              `50+`,
              margins.left + increments * 50,
              y + totalHeight + 9
            );
            // draw graph bounds
            pdf.setDrawColor(100, 100, 100);
            pdf.rect(margins.left, y, totalWidth, totalHeight);
            // draw line
            pdf.setDrawColor(255, 0, 0);
            pdf.setLineWidth(lineHeight);
            pdf.line(
              margins.left,
              y + totalHeight / 2,
              margins.left + lineWidth,
              y + totalHeight / 2
            );
            pdf.text(
              `${evaluationScoreTotal}`,
              margins.left + lineWidth + 5,
              y + totalHeight / 2 + 3
            );
            y += totalHeight + 40;
          };
          makeOABGraph();
        }
        //#endregion Overall Assessment Based on LSI-R Total Score

        //#region Assessment Based on LSI-R Score
        if (parseOption('Assessment Based on LSI-R Score')) {
          if (checkEndOfPage(y)) newPage();
          addText(`Assessment Based on LSI-R Score`, 14, 'bold');
          // find risk level recommended
          // let riskLevelDescription;
          // if (institutionClient) {
          //   // 182>183>Q-XUgprLd4M
          //   riskLevelDescription = evaluation.data[`182>183>Q-XUgprLd4M`]?.text
          //     ? evaluation.data[`182>183>Q-XUgprLd4M`].text
          //     : 'N/A';
          // } else {
          //   // 182>184>Q-_n7JcO3uq
          //   riskLevelDescription = evaluation.data[`182>184>Q-_n7JcO3uq`]?.text
          //     ? evaluation.data[`182>184>Q-_n7JcO3uq`].text
          //     : 'N/A';
          // }

          // find probability of recidivisim
          let recidivismRate = lsirPercentiles.recidivismRate(
            evaluationRiskCategoryOverride
              ? evaluationRiskCategoryOverride
              : evaluationRiskCategory,
            institutionClient,
            evaluationScoreTotal,
            client.sex
          );
          let probationGuideline;
          let halfwayHouseDescription;
          let riskLevelDescription;
          if (recidivismRate) {
            probationGuideline = recidivismRate?.probationGuideline
              ? recidivismRate.probationGuideline
              : 'N/A';
            halfwayHouseDescription = recidivismRate?.halfwayHouse
              ? recidivismRate.halfwayHouse
              : 'N/A';
            if (institutionClient) {
              riskLevelDescription =
                recidivismRate?.institutionRiskLevelDescription
                  ? recidivismRate.institutionRiskLevelDescription
                  : 'N/A';
            } else {
              riskLevelDescription =
                recidivismRate?.communityRiskLevelDescription
                  ? recidivismRate.communityRiskLevelDescription
                  : 'N/A';
            }
            recidivismRate = recidivismRate?.percentage
              ? recidivismRate.percentage
              : 'N/A';
          }

          // make table for Source/Purpose... and LSI-R Score
          let ablsTable = {
            head: [['Source/Purpose of Classification', 'LSI-R Score']],
            body: [],
            startY: y,
            theme: 'grid',
            rowPageBreak: 'avoid',
            didDrawPage: (hookData) => {
              if (hookData.pageNumber > 1) newPage(true);
              y = hookData.cursor.y + 25;
            }
          };
          ablsTable.body.push([
            'Overall LSI-R Score',
            `${
              evaluationRiskCategoryOverride
                ? evaluationRiskCategoryOverride
                : evaluationRiskCategory
            } ${evaluationRiskCategoryOverride ? '(Override)' : ''}`
          ]);
          ablsTable.body.push([
            `Risk Level (${
              clientNormativeType === 'Institutional'
                ? 'Institution'
                : 'Community'
            })`,
            riskLevelDescription
          ]);
          ablsTable.body.push([`Probation Guideline`, `${probationGuideline}`]);
          ablsTable.body.push([`Halfway House`, `${halfwayHouseDescription}`]);
          ablsTable.body.push([
            `Probability of Recidivism`,
            `${recidivismRate}`
          ]);
          pdf.autoTable(ablsTable);
          addText(
            "Note: Interpretive guidelines are based on client's raw scores and not the override risk level.",
            11,
            'bold'
          );
        }
        //#endregion Assessment Based on LSI-R Score

        //#region Comparison to Prison Inmates
        if (parseOption('Comparison to Prison Inmates')) {
          if (checkEndOfPage(y)) newPage();
          addText(`Comparison to Prison Inmates`, 14, 'bold');
          let comparisonPercentage = lsirPercentiles.comparisonPercentage(
            evaluationScoreTotal,
            client.sex
          );
          addText(
            `The score is as high or higher than ${comparisonPercentage} of a normative group of prison inmates tested with the LSI-R.`
          );
        }
        //#endregion Comparison to Prison Inmates

        //#region Professional Discretion/Override
        if (parseOption('Professional Discretion/Override')) {
          if (checkEndOfPage(y)) newPage();
          addText(`Professional Discretion/Override`, 14, 'bold');
          if (evaluationRiskCategoryOverride) {
            addText(
              `The professional discretion/override was used in this case to change the risk level from ${evaluationRiskCategory} to ${evaluationRiskCategoryOverride}.`
            );
          } else {
            addText(
              `The professional discretion/override was not used in this case.`
            );
          }
        }
        //#endregion Professional Discretion/Override

        //#region Assessment of Risk/Needs Based on LSI-R Subcomponents
        if (
          parseOption('Assessment of Risk/Needs Based on LSI-R Subcomponents')
        ) {
          // check if title and graph are gonna make it on same page
          if (y + 100 + 275 >= pageHeight - margins.bottom) newPage();
          addText(
            `Assessment of Risk/Needs Based on LSI-R Subcomponents`,
            14,
            'bold'
          );
          addText(
            `The graph below displays specific areas, and indicates whether they are low, medium, or high risk/needs areas.`
          );
          let makeORBLSGraph = function () {
            let divisions = 5;
            let totalWidth = pageWidth - 200;
            let totalHeight = 275;
            let lineHeight = 10;
            let increments = totalWidth / divisions;
            let leftColumnWidth = 100;
            if (y + totalHeight + 40 >= pageHeight - margins.bottom) newPage();
            // make scruture of graph
            pdf.setDrawColor(100, 100, 100);
            // write risk sections
            pdf.text(
              `Very Low`,
              margins.left + leftColumnWidth + increments * 1,
              y + 10,
              {
                align: 'center'
              }
            );
            pdf.text(
              `Low`,
              margins.left + leftColumnWidth + increments * 2,
              y + 10,
              {
                align: 'center'
              }
            );
            pdf.text(
              `Medium`,
              margins.left + leftColumnWidth + increments * 3,
              y + 10,
              {
                align: 'center'
              }
            );
            pdf.text(
              `High`,
              margins.left + leftColumnWidth + increments * 4,
              y + 10,
              {
                align: 'center'
              }
            );
            pdf.text(
              `Very High`,
              margins.left + leftColumnWidth + increments * 5,
              y + 10,
              {
                align: 'center'
              }
            );
            // draw risk section lines
            pdf.setDrawColor(150, 150, 150);
            pdf.setLineWidth(1);
            pdf.line(
              margins.left + leftColumnWidth + 10,
              y + 20,
              margins.left + leftColumnWidth + 10,
              y + 20 + totalHeight
            );
            for (let i = 1; i <= 5; i++) {
              pdf.line(
                margins.left + leftColumnWidth + increments * i,
                y + 20,
                margins.left + leftColumnWidth + increments * i,
                y + 20 + totalHeight
              );
            }
            // write each subcomponent label and line
            let rowHeight = 25;
            let rowPosition = y + 20 + rowHeight;
            let lineWidth;
            pdf.setDrawColor(255, 0, 0);
            pdf.setLineWidth(lineHeight);
            forEach(section1Tool.childTools, (ct) => {
              pdf.text(
                `${ct.flyoutName}`,
                margins.left + leftColumnWidth,
                rowPosition + 3,
                {
                  align: 'right'
                }
              );
              switch (evaluation.toolScores[ct.address]?.riskCategory) {
                case 'Very Low':
                  lineWidth = increments * 1;
                  break;
                case 'Low':
                  lineWidth = increments * 2;
                  break;
                case 'Medium':
                  lineWidth = increments * 3;
                  break;
                case 'High':
                  lineWidth = increments * 4;
                  break;
                case 'Very High':
                  lineWidth = increments * 5;
                  break;
              }
              lineWidth += margins.left + leftColumnWidth;
              pdf.line(
                margins.left + leftColumnWidth + 10,
                rowPosition,
                lineWidth,
                rowPosition
              );
              rowPosition += rowHeight;
            });
            y += totalHeight + 40;
          };
          makeORBLSGraph();
        }
        //#endregion Assessment of Risk/Needs Based on LSI-R Subcomponents

        //#region Details Regarding Subcomponent Risks/Needs
        if (parseOption('Details Regarding Subcomponent Risks/Needs')) {
          if (checkEndOfPage(y)) newPage();
          addText(`Details Regarding Subcomponent Risks/Needs`, 14, 'bold');
          forEach(section1Tool.childTools, (ct) => {
            let foundOne = false;
            if (checkEndOfPage(y)) newPage();
            addText(`${ct.flyoutName}`, 12, 'bold');
            forEach(ct.codingFormItems, (cfi, i) => {
              // skip over strength questions
              if (cfi.customItemNumber === '-') return;
              let evalDataAnswer = evaluation.data[cfi.longAddress];
              if (evalDataAnswer.text === 'Yes') {
                foundOne = true;
                addText(
                  `${cfi.customItemNumber ? cfi.customItemNumber : i + 1}. ${
                    cfi.riskFactor
                  }: Yes`
                );
              } else if (
                evalDataAnswer.text === '0' ||
                evalDataAnswer.text === '1'
              ) {
                foundOne = true;
                addText(
                  `${cfi.customItemNumber ? cfi.customItemNumber : i + 1}. ${
                    cfi.riskFactor
                  }: A relatively unsatisfactory situation with a need for improvement`
                );
              }
            });
            if (!foundOne) addText('N/A');
          });
        }
        //#endregion Details Regarding Subcomponent Risks/Needs

        //#region Summary of LSI-R Item Responses
        if (parseOption('Summary of LSI-R Item Responses')) {
          if (checkEndOfPage(y)) newPage();
          addText(`Summary of LSI-R Item Responses`, 14, 'bold');
          addText(
            `The rater entered the following response values for the items on the Level of Service Inventory-Revised Form.`
          );
          let itemResponsesTable = {
            head: [
              ['Item', 'Response', 'Item', 'Response', 'Item', 'Response']
            ],
            body: [],
            theme: 'grid'
          };
          for (let i = 0; i < 18; i++) {
            itemResponsesTable.body.push([]);
          }
          let bodyIndex = 0;
          forEach(section1Tool.childTools, (ct) => {
            forEach(ct.codingFormItems, (cfi) => {
              //skip strength questions
              if (
                find(strengthQuestionAddresses, (sqa) => {
                  return sqa.address === cfi.longAddress;
                })
              )
                return;
              let evaluationScore = evaluation.data[cfi.longAddress];
              itemResponsesTable.body[bodyIndex].push(cfi.customItemNumber);
              itemResponsesTable.body[bodyIndex].push(evaluationScore.text);
              bodyIndex === 17 ? (bodyIndex = 0) : bodyIndex++;
            });
          });
          pdf.autoTable({
            head: itemResponsesTable.head,
            body: itemResponsesTable.body,
            startY: y,
            theme: itemResponsesTable.theme,
            rowPageBreak: 'avoid',
            didDrawPage: (hookData) => {
              if (hookData.pageNumber > 1) newPage(true);
              y = hookData.cursor.y + 25;
            }
          });
        }
        //#endregion Summary of LSI-R Item Responses

        //#region Additional Item Information
        if (parseOption('Additional Item Information')) {
          if (checkEndOfPage(y)) newPage();
          addText(`Additional Item Information`, 14, 'bold');
          let evaluationScore;
          forEach(section1Tool.childTools, (ct) => {
            forEach(ct.codingFormItems, (cfi) => {
              evaluationScore = evaluation.data[cfi.longAddress];
              if (cfi.subAnswers) {
                forEach(cfi.subAnswers, (sa) => {
                  if (evaluationScore[sa.label]) {
                    addText(
                      `${cfi.customItemNumber}. ${sa.label}: ${
                        evaluationScore[sa.label]
                      }`
                    );
                  } else {
                    addText(
                      `${cfi.customItemNumber}. ${sa.label}: Not Specified`
                    );
                  }
                });
              }
            });
          });
        }
        //#endregion Additional Item Information

        //#region SUMMARY OF OMITTED RESPONSES
        if (parseOption('Summary of Omitted Responses')) {
          if (checkEndOfPage(y)) newPage();
          addText(`Summary of Omitted Responses`, 14, 'bold');
          let omittedList = filter(evaluation.data, (data) => {
            return data.text === 'Omit' || data.text === 'omit';
          });
          if (omittedList?.length) {
            forEach(omittedList, (omittedItem) => {
              forEach(section1Tool.childTools, (ct) => {
                forEach(ct.codingFormItems, (cfi) => {
                  if (cfi.longAddress === omittedItem.qid) {
                    addText(
                      `${cfi.customItemNumber}. ${cfi.riskFactor}: Notes: ${
                        omittedItem.comment
                          ? omittedItem.comment
                          : 'No notes provided'
                      }`
                    );
                  }
                });
              });
            });
          } else {
            addText(`No General/Risk Need Factor items were omitted.`);
          }
        }
        //#endregion SUMMARY OF OMITTED RESPONSES

        //#region SECTION 2: SPECIFIC RISK/NEED FACTORS
        if (parseOption('Section 2: Specific Risk/Need Factors')) {
          if (checkEndOfPage(y)) newPage();
          let section2Tool = find(tool.childTools, {
            id: 169
          });
          addText(`Section 2: Specific Risk/Need Factors`, 14, 'bold');
          addText(
            `The Specific Risk/Need Factors section derives from a review of the research literature. It pinpoints items that may not apply to the general offender population. When "specific" items occur in a given case, they may take a prominent role in the assessment of this offender’s risk. Note that the items are not summed for a total Specific Risk/Need score. Rather, the endorsement of any item should be considered in planning ${client.name()}’s case management plan.`
          );
          addText(
            `Section 2.1: Personal Problems with Criminogenic Potential`,
            14,
            'bold'
          );
          addText(
            `This section focuses on specific attributes and personal characteristics that may be particularly indicative of specific criminogenic needs. Items that were given "Yes" responses for this section are shown below:`
          );
          let ppcp = section2Tool.childTools[0];
          forEach(ppcp.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text === 'Yes'
            ) {
              addText(`${index + 1}. ${cfi.riskFactor}`);
            }
          });
          addText(`Section 2.2: History of Perpetration`, 14, 'bold');
          addText(
            `This section includes a series of historical items that might constitute specific static risk factors. Note that the items refer to history and not simply to the current offence. Items that were given "Yes" responses for this section are shown below:`
          );
          addText(`Section 2.2a: Sexual Assault`, 14, 'bold');
          let hp = section2Tool.childTools[1];
          forEach(hp.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text === 'Yes'
            ) {
              addText(
                `${cfi.customItemNumber ? cfi.customItemNumber : index + 1}. ${
                  cfi.riskFactor
                }`
              );
            }
          });
          addText(
            `Section 2.2b: Nonsexual Physical Assault and Other Forms of Violence`,
            14,
            'bold'
          );
          let hp2 = section2Tool.childTools[2];
          forEach(hp2.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text === 'Yes'
            ) {
              addText(
                `${cfi.customItemNumber ? cfi.customItemNumber : index + 1}. ${
                  cfi.riskFactor
                }`
              );
            }
          });
          addText(
            `Section 2.2c: Other Forms of Antisocial Behavior`,
            14,
            'bold'
          );
          let hp3 = section2Tool.childTools[3];
          forEach(hp3.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text === 'Yes'
            ) {
              addText(
                `${cfi.customItemNumber ? cfi.customItemNumber : index + 1}. ${
                  cfi.riskFactor
                }`
              );
            }
          });
        }
        //#endregion SECTION 2: SPECIFIC RISK/NEED FACTORS

        //#region SECTION 3: PRISON EXPERIENCE - INSTITUTIONAL FACTORS
        if (
          parseOption(`Section 3: Prison Experience - Institutional Factors`)
        ) {
          if (checkEndOfPage(y)) newPage();
          addText(
            `Section 3: Prison Experience - Institutional Factors`,
            14,
            'bold'
          );
          let section3Tool = find(tool.childTools, {
            id: 175
          });
          if (institutionalOffender === true) {
            addText(
              `This section samples items that institutional classification staff have identified as crucial considerations over-and-above the General Risk/Need level and the other sections of the LSI-R. The endorsement of any item should be considered when developing ${client.name()}'s case management plan.`
            );
            let pastIncarcerationItems = [];
            let presentIncarcerationItems = [];
            let barriersToRelease = [];
            // past incarcerations = 176
            // present incarcerations = 177
            // barriersToRelease = 178
            forEach(section3Tool.childTools, (ct) => {
              forEach(ct.codingFormItems, (cfi) => {
                if (
                  evaluation.data[cfi.longAddress] &&
                  evaluation.data[cfi.longAddress].text.includes('Yes')
                ) {
                  let specify = evaluation.data[cfi.longAddress].fillIn;
                  // addText(
                  //   `${cfi.riskFactors}: ${
                  //     evaluation.data[cfi.longAddress].text
                  //   }. ${specify ? 'Specify: ' + specify : ''}`
                  // );
                  if (ct.id === 176) {
                    pastIncarcerationItems.push(
                      `${cfi.riskFactor}: ${
                        evaluation.data[cfi.longAddress].text
                      }. ${specify ? 'Specify: ' + specify : ''}`
                    );
                  } else if (ct.id === 177) {
                    presentIncarcerationItems.push(
                      `${cfi.riskFactor}: ${
                        evaluation.data[cfi.longAddress].text
                      }. ${specify ? 'Specify: ' + specify : ''}`
                    );
                  } else if (ct.id === 178) {
                    barriersToRelease.push(
                      `${cfi.riskFactor}: ${
                        evaluation.data[cfi.longAddress].text
                      }. ${specify ? 'Specify: ' + specify : ''}`
                    );
                  }
                }
              });
            });
            addText(`Past Incarceration`, 13, 'bold');
            addText(
              `Items that were given "Yes" responses for this section are shown below:`
            );
            if (pastIncarcerationItems.length) {
              forEach(pastIncarcerationItems, (item) => {
                addText(`- ${item}`);
              });
            } else {
              addText(
                `No items were given responses of "Yes" for this section.`
              );
            }

            addText(`Present Incarceration:`, 13, 'bold');
            addText(
              `Items that were given "Yes" responses for this section are shown below:`
            );
            if (presentIncarcerationItems.length) {
              forEach(presentIncarcerationItems, (item) => {
                addText(`- ${item}`);
              });
            } else {
              addText(
                `No items were given responses of "Yes" for this section.`
              );
            }

            addText(`Barriers to Release:`, 13, 'bold');
            addText(
              `Items that were given "Yes" responses for this section are shown below:`
            );
            if (barriersToRelease.length) {
              forEach(barriersToRelease, (item) => {
                addText(`- ${item}`);
              });
            } else {
              addText(
                `No items were given responses of "Yes" for this section.`
              );
            }
          } else if (institutionalOffender === false) {
            addText(
              `This section is not applicable since it is used for prison inmates only.`
            );
          } else if (institutionalOffender === 'N/A') {
            addText(
              `This section is not applicable since it is used for prison inmates only. No offender setting was selected in the Offender History form.`
            );
          }
        }
        //#endregion SECTION 3: PRISON EXPERIENCE - INSTITUTIONAL FACTORS

        //#region SECTION 4: OTHER CLIENT ISSUES
        if (
          parseOption(
            `Section 4: Other Client Issues (Social, Health, and Mental Health)`
          )
        ) {
          if (checkEndOfPage(y)) newPage();
          addText(
            `Section 4: Other Client Issues (Social, Health, and Mental Health)`,
            14,
            'bold'
          );
          addText(
            `This section includes supplementary information that may impact on ${client.name()}’s classification and case management decisions. Note that the items are not summed for a total score. Items that were given "Yes" responses for this section are shown below:`
          );
          let section4Tool = find(tool.childTools, {
            id: 179
          });
          forEach(section4Tool.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text.includes('Yes')
            ) {
              addText(`${index + 1}. ${cfi.riskFactor}`);
            }
            if (
              cfi.id === 'Q-F4xUdZi1Z' &&
              evaluation.data[cfi.longAddress].fillIn
            ) {
              addText(
                `${index + 1}. ${cfi.riskFactor}: ${
                  evaluation.data[cfi.longAddress].fillIn
                }`
              );
            }
          });
        }
        //#endregion SECTION 4: OTHER CLIENT ISSUES

        //#region SECTION 5: SPECIAL RESPONSIVITY CONSIDERATIONS
        if (parseOption(`Section 5: Special Responsivity Considerations`)) {
          if (checkEndOfPage(y)) newPage();
          addText(`Section 5: Special Responsivity Considerations`, 14, 'bold');
          addText(
            `This section samples some of the dominant responsivity considerations in clinical research and correctional opinion. Responsivity factors are considerations that may impact upon choice of the most appropriate style and mode of service. Note that the items are not summed for a total score. Items that were given "Yes" responses for this section are shown below:`
          );
          let section5Tool = find(tool.childTools, {
            id: 180
          });
          let section5Items = [];
          forEach(section5Tool.codingFormItems, (cfi, index) => {
            if (
              evaluation.data[cfi.longAddress] &&
              evaluation.data[cfi.longAddress].text.includes('Yes')
            ) {
              // addText(`${index + 1}. ${cfi.riskFactor}`);
              section5Items.push(`${index + 1}. ${cfi.riskFactor}`);
            }
            if (
              cfi.id === 'Q-XJYjgZ39c' &&
              evaluation.data[cfi.longAddress].fillIn
            ) {
              section5Items.push(
                `${index + 1}. ${cfi.riskFactor}: ${
                  evaluation.data[cfi.longAddress].fillIn
                }`
              );
            }
          });
          if (section5Items.length) {
            forEach(section5Items, (item) => {
              addText(`- ${item}`);
            });
          } else {
            addText(`No items were given responses of "Yes" for this section.`);
          }
        }
        //#endregion SECTION 5: SPECIAL RESPONSIVITY CONSIDERATIONS

        //#region SECTION 6: RISK/NEED SUMMARY AND OVERRIDE
        if (parseOption(`Section 6: Risk/Need Summary and Override`)) {
          if (checkEndOfPage(y)) newPage();
          addText(`Section 6: Risk/Need Summary and Override`, 14, 'bold');

          addText(`Client-Based/Clinical Override`, 13, 'bold');
          let clientBasedAnswer = evaluation.data[`181>Q-zpyiq8rBE`];
          if (clientBasedAnswer.aid === 'A-9elo-8LNcK') {
            addText(`The client-based/clinical override was used`);
            addText(
              `Reasons for lowering security/supervision level: ${
                clientBasedAnswer.fillIn
                  ? clientBasedAnswer.fillIn
                  : 'No reasons provided.'
              }`
            );
          } else {
            addText(`The client-based/clinical override was not used`);
          }

          addText(`Administrative/Policy Override`, 13, 'bold');
          let adminBasedAnswer = evaluation.data[`181>Q-pRYF4PvNn`];
          if (adminBasedAnswer.aid === 'A-Y1Gjbn6x9F') {
            addText(`The administrative/policy override was used`);
            addText(
              `Reason specified: ${
                adminBasedAnswer.fillIn
                  ? adminBasedAnswer.fillIn
                  : 'No reasons provided.'
              }`
            );
          } else {
            addText(`The administrative/policy override was not used`);
          }
        }
        //#endregion SECTION 6: RISK/NEED SUMMARY AND OVERRIDE

        //#region SECTION 7: PROGRAM/PLACEMENT DECISION
        if (parseOption(`Section 7: Program/Placement Decision`)) {
          if (checkEndOfPage(y)) newPage();
          addText(`Section 7: Program/Placement Decision`, 14, 'bold');
          let section7Tool = find(tool.childTools, {
            id: 182
          });
          if (institutionalOffender === true) {
            let instTool = find(section7Tool.childTools, {
              id: 183
            });
            let evaluationScore;
            forEach(instTool.codingFormItems, (cfi) => {
              evaluationScore = evaluation.data[cfi.longAddress];
              if (evaluationScore.fillIn) {
                addText(
                  `${cfi.riskFactor}: ${
                    evaluationScore.fillIn ? evaluationScore.fillIn : 'N/A'
                  }`
                );
              } else {
                addText(
                  `${cfi.riskFactor}: ${
                    evaluationScore.text ? evaluationScore.text : 'N/A'
                  }`
                );
              }
            });
          } else {
            let commTool = find(section7Tool.childTools, {
              id: 184
            });
            let evaluationScore;
            forEach(commTool.codingFormItems, (cfi) => {
              evaluationScore = evaluation.data[cfi.longAddress];
              if (evaluationScore.fillIn) {
                addText(
                  `${cfi.riskFactor}: ${
                    evaluationScore.fillIn ? evaluationScore.fillIn : 'N/A'
                  }`
                );
              } else {
                addText(
                  `${cfi.riskFactor}: ${
                    evaluationScore.text ? evaluationScore.text : 'N/A'
                  }`
                );
              }
            });
          }
          addText(
            `Presentence Report: ${
              evaluation.data['182>185>Q-pNUe_VJqO'].fillIn
                ? evaluation.data['182>185>Q-pNUe_VJqO'].fillIn
                : 'No comments provided.'
            }`
          );
          addText(
            `Summary of Findings: ${
              evaluation.data['182>186>Q-KkNP5MZpH'].fillIn
                ? evaluation.data['182>186>Q-KkNP5MZpH'].fillIn
                : 'No comments provided.'
            }`
          );
        }
        //#endregion SECTION 7: PROGRAM/PLACEMENT DECISION
        // ==============END PARSE EVALUATION FOR REPORT==================
        // ================END OF REPORT===================
        let today = $filter('dynamicDate')(new Date(), 'fullDate');
        pdf.text(`Date Printed: ${today}`, margins.left, pageHeight - 35);
        pdf.text(
          `End of Report (Assessement #${evaluation.evaluationId})`,
          margins.left,
          pageHeight - 23
        );
        // addText(`End of Report (Assessment # ${caseplan.evaluation.id})`, null, 'bold');
        // ==========================UPLOAD PDF=========================
        let filename = `${client.name()} - ${$filter('dynamicDate')(
          new Date(),
          'MM-dd-yyyy'
        )} - LSI-R_Profile_Report`;
        // ==========================SAVE PDF=========================
        pdf.save(`${filename}.pdf`);
        // ==========================UPLOAD PDF=========================
        let pdfFile = pdf.output('arraybuffer');
        // let pdfFile = pdf.output('binary');
        pdfFile = new File([pdfFile], `${filename}.pdf`, {
          type: 'application/pdf'
        });

        Upload.upload({
          url: `/api/client-manager/${client.institutionId}/subgroups/${client.subGroup.id}/clients/${client.id}/evaluations/${evaluation.evaluationId}/media`,
          file: pdfFile,
          data: { isReport: true }
        })
          .then((response) => {
            $store.commit('evaluations/setFocus', evaluation.evaluationId);
            $store.dispatch('reports/getForEvaluation', {
              id: evaluation.evaluationId,
              client: client
            });
            return response;
          })
          .catch((err) => {
            return err;
          });
      },
      margins
    );
  }
};
