import { gears } from 'angular';

import { views } from '@views';

export interface StateOptions {
  name: string;
  url: string;
  controllerAs?: string;
  component: any;
  parent?: string;
  data?: Record<string, unknown>;
  params?: Record<string, unknown>;
  onEnter?: (...args: unknown[]) => unknown;
}

export const states: StateOptions[] = [
  {
    name: 'main',
    url: '/',
    controllerAs: 'vm',
    component: views.Main,
    data: {
      title: 'Home',
      requiresUnauth: true
    }
  },
  {
    name: 'about',
    url: '/about',
    controllerAs: 'vm',
    component: views.About,
    data: {
      title: 'About'
    }
  },
  {
    name: 'acceptableUsePolicy',
    url: '/acceptable-use-policy',
    controllerAs: 'vm',
    component: views.AcceptableUsePolicy,
    data: {
      title: 'Acceptable Use Policy'
    }
  },
  {
    name: 'admin',
    url: '/admin',
    controllerAs: 'vm',
    component: views.Admin,
    data: {
      title: 'Admin',
      authenticate: true
    }
  },
  {
    name: 'comparativeReports',
    url: '/comparative-reports',
    params: {
      evaluations: null,
      tool: null,
      options: null,
      report: null
    },
    controllerAs: 'vm',
    component: views.ComparativeReports,
    data: {
      title: 'Comparative Reports',
      authenticate: true
    }
  },
  {
    name: 'contact',
    url: '/contact',
    controllerAs: 'vm',
    component: views.Contact,
    data: {
      title: 'Contact'
    }
  },
  {
    name: 'cookies',
    url: '/cookies',
    controllerAs: 'vm',
    component: views.Cookies,
    data: {
      title: 'Cookie Policy'
    }
  },
  {
    name: 'credits',
    url: '/credits',
    controllerAs: 'vm',
    component: views.Credits,
    data: {
      title: 'Credits'
    }
  },
  {
    name: 'error404',
    url: '/error-404',
    controllerAs: 'vm',
    component: views.Error404,
    data: {
      title: '404'
    }
  },
  {
    name: 'forbidden',
    url: '/forbidden',
    controllerAs: 'vm',
    component: views.Forbidden,
    data: {
      title: 'Forbidden'
    }
  },
  {
    name: 'pricing',
    url: '/pricing',
    controllerAs: 'vm',
    component: views.Pricing,
    data: {
      title: 'Pricing'
    }
  },
  {
    name: 'privacy',
    url: '/privacy',
    controllerAs: 'vm',
    component: views.Privacy,
    data: {
      title: 'Privacy'
    }
  },
  {
    name: 'terms',
    url: '/terms',
    controllerAs: 'vm',
    component: views.Terms,
    data: {
      title: 'Terms'
    }
  },
  {
    name: 'toolCreatorV2',
    url: '/tool-creator-v2',
    params: {
      commit: null,
      reportTemplate: null
    },
    controllerAs: 'vm',
    component: views.ToolCreatorV2,
    data: {
      title: 'Tool Editor',
      authenticate: true
    }
  },
  {
    name: 'mhsGearsAdmin',
    url: '/mhs-gears-admin',
    controllerAs: 'vm',
    component: views.MhsGearsAdmin,
    data: {
      title: 'MHS GEARS Admin',
      authenticate: true,
      access: 'admin'
    }
  },
  {
    name: 'forgotPassword',
    url: '/forgot-password',
    controllerAs: 'vm',
    component: views.ForgotPassword,
    data: {
      title: 'Forget Password'
    }
  },
  {
    name: 'initialPassword',
    url: '/initial-password',
    controllerAs: 'vm',
    component: views.InitialPassword,
    data: {
      title: 'Initial Password',
      authenticate: true
    }
  },
  {
    name: 'login',
    url: '/login',
    params: {
      first: null
    },
    controllerAs: 'vm',
    component: views.Login,
    data: {
      title: 'Login',
      requiresUnauth: true
    }
  },
  {
    name: 'logout',
    url: '/logout?referrer',
    controllerAs: 'vm',
    component: views.Logout,
    data: {
      title: 'Logout',
      referrer: 'main'
    }
  },
  {
    name: 'passwordReset',
    url: '/password-reset',
    controllerAs: 'vm',
    component: views.PasswordReset,
    data: {
      title: 'Password Reset'
    }
  },
  {
    name: 'register',
    url: '/register',
    controllerAs: 'vm',
    component: views.Register,
    data: {
      title: 'Register',
      requiresUnauth: true
    }
  },
  {
    name: 'settings',
    url: '/settings',
    controllerAs: 'vm',
    component: views.Settings,
    data: {
      title: 'Settings',
      authenticate: true
    }
  },
  // {
  //   name: 'signup',
  //   url: '/signup',
  //   controllerAs: 'vm',
  //   component: views.Signup,
  //   data: {
  //     title: 'Signup',
  //     requiresUnauth: true
  //   }
  // },
  {
    name: 'dashboardHome',
    url: '',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardHome,
    data: {
      title: 'Home',
      authenticate: true
    }
  },
  {
    name: 'dashboardClient',
    url: '/client?{id : int, genCasePlanEvalId}',
    params: {
      id: ''
    },
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardClient,
    data: {
      title: 'Client'
    }
  },
  // {
  //   name: 'dashboardClientRequests',
  //   url: '/client-requests',
  //   controllerAs: 'vm',
  //   parent: 'dashboard',
  //   component: views.DashboardClientRequests,
  //   data: {
  //     title: 'Client Requests',
  //     inSideNav: true,
  //     authenticate: ['institutionmanager:ListIncomingClientRequests']
  //   }
  // },
  {
    name: 'dashboardAggregateReports',
    url: '/aggregate-reports',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardAggregateReports,
    data: {
      title: 'Aggregate Reports (Evaluations)',
      authenticate: ['institutionmanager:ListAggregateReportRequests'],
      inSideNav: true
    }
  },
  {
    name: 'dashboardAggregateUserReports',
    url: '/aggregate-user-reports',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardAggregateUserReports,
    data: {
      title: 'Aggregate Reports (Users)',
      authenticate: ['institutionmanager:ListAggUserReportRequests'],
      inSideNav: true
    }
  },
  {
    name: 'dashboardActivityReports',
    url: '/activity-reports',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardActivityReports,
    data: {
      title: 'Activity Reports',
      authenticate: ['institutionmanager:ListLogRequests'],
      inSideNav: true
    }
  },
  {
    name: 'dashboardClients',
    url: '/clients',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardClients,
    data: {
      title: 'Caseload',
      authenticate: [
        'gearsmanager:ListClients||institutionmanager:ListClients'
      ],
      inSideNav: true,
      loadOnClick: true
    },
    params: {
      loadOnClick: true
    }
  },
  {
    name: 'dashboardCreatePolicy',
    url: '/create-policy',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardCreatePolicy,
    data: {
      title: 'Create Policy'
    }
  },
  {
    name: 'dashboardEditPolicy',
    url: '/edit-policy?{id : int}',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardEditPolicy,
    data: {
      title: 'Edit Policy'
    }
  },
  {
    name: 'dashboardInstitutions',
    url: '/institutions',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardInstitutions,
    data: {
      title: 'Institutions',
      authenticate: ['gearsmanager:ListInstitutions'],
      inSideNav: true,
      loadOnClick: true
    },
    params: {
      loadOnClick: true
    }
  },
  {
    name: 'dashboardLocationSetup',
    url: '/location-setup',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardLocationSetup,
    data: {
      title: 'Location Setup',
      authenticate: ['institutionmanager:CreateRegion'],
      inSideNav: true
    }
  },
  {
    name: 'dashboardOffenseClassifications',
    url: '/offense-classifications',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardOffenseClassifications,
    data: {
      title: 'Offense Classifications',
      authenticate: ['institutionmanager:ListOffenseClassifications'],
      inSideNav: true
    }
  },
  {
    name: 'dashboardPolicies',
    url: '/policies',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardPolicies,
    data: {
      title: 'Policies',
      authenticate: [
        'gearsmanager:ListAllInstitutionPolicies||institutionmanager:ListPolicies'
      ],
      inSideNav: true,
      loadOnClick: true
    },
    params: {
      loadOnClick: true
    }
  },
  {
    name: 'dashboardReminders',
    url: '/reminders',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardReminders,
    data: {
      title: 'Reminders',
      authenticate: [],
      inSideNav: true
    }
  },
  // {
  //   name: 'dashboardLogs',
  //   url: '/logs',
  //   controllerAs: 'vm',
  //   parent: 'dashboard',
  //   component: views.DashboardLogs,
  //   data: {
  //     title: 'Logs',
  //     authenticate: ['gearsmanager:ListLogs'],
  //     inSideNav: true,
  //     sideNavMarker: 'proto'
  //   }
  // },
  {
    name: 'dashboardReportTemplates',
    url: '/report-templates',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardReportTemplates,
    data: {
      title: 'Report Templates',
      authenticate: [
        'toolcreator:ListToolReportTemplates',
        'toolcreator:CreateToolReportTemplate'
      ],
      inSideNav: true
    }
  },
  {
    name: 'dashboardReports',
    url: '/reports',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardReports,
    data: {
      title: 'Reports'
    }
  },
  {
    name: 'dashboardTools',
    url: '/tools',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardTools,
    data: {
      title: 'Tools',
      authenticate: [
        'toolcreator:ListAllTools||institutionmanager:ListInstitutionTools'
      ],
      inSideNav: true,
      loadOnClick: true
    },
    params: {
      loadOnClick: true
    }
  },
  {
    name: 'dashboardInstitution',
    url: '/institution',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardInstitution,
    data: {
      title: 'Institution',
      authenticate: ['institutionmanager:GetInstitution'],
      inSideNav: true,
      institutionOnly: true
    }
  },
  {
    name: 'dashboardEvaluations',
    url: '/evaluations?{clientId : int}',
    params: {
      clientId: ''
    },
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardEvaluations,
    data: {
      title: 'Evaluations',
      authenticate: ['clientmanager:ListClientEvaluations'],
      inSideNav: true
    }
  },
  {
    name: 'evaluationStats',
    url: '/evaluation-stats',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardEvaluationStats,
    data: {
      title: 'Evaluation Stats'
    }
  },
  {
    name: 'dashboardViewEvaluation',
    url: '/evaluations/:evalId',
    params: {
      evalId: ''
    },
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardViewEvaluation,
    data: {
      title: 'View Evaluation'
    }
  },
  {
    name: 'dashboardEvaluationsPerform',
    url: '/perform-evaluation',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardEvaluationsPerform,
    params: {
      evalId: null
    },
    data: {
      title: 'Perform Evaluation'
    }
  },
  {
    name: 'dashboardEvaluationRequests',
    url: '/evaluation-requests',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardEvaluationRequests,
    data: {
      title: 'Evaluation Requests',
      authenticate: ['institutionmanager:ListEvaluationRequests'],
      inSideNav: true
    }
  },
  {
    name: 'dashboardCaseManagement',
    url: '/case-management',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardCaseManagement,
    data: {
      title: 'Case Management'
    }
  },
  {
    name: 'dashboardInvitations',
    url: '/invitations',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardInvitations,
    data: {
      title: 'Invitations',
      authenticate: [
        ['gearsmanager:ListInvites', 'institutionmanager:ListInvites']
      ],
      inSideNav: true,
      loadOnClick: true
    },
    params: {
      loadOnClick: true
    }
  },
  {
    name: 'dashboardUser',
    url: '/user?{id : int}',
    params: {
      id: ''
    },
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardUser,
    data: {
      title: 'User'
    }
  },
  {
    name: 'dashboardUsers',
    url: '/users',
    controllerAs: 'vm',
    parent: 'dashboard',
    component: views.DashboardUsers,
    data: {
      title: 'Users',
      authenticate: [
        'gearsmanager:ListUsers||institutionmanager:ListInstitutionUsers'
      ],
      inSideNav: true,
      loadOnClick: true
    },
    params: {
      loadOnClick: true
    }
  },
  {
    name: 'dashboard',
    url: '/dashboard',
    abstract: true,
    params: {
      referrer: null
    },
    controllerAs: 'vm',
    component: views.Dashboard,
    onEnter($store: gears.IStoreService) {
      'ngInject';

      $store.commit('isLoadingData', true);
    },
    data: {
      title: 'Dashboard',
      authenticate: true
    }
  }
];

if (
  process.env.NODE_ENV == 'development' ||
  window.location.href.includes('localhost:3000')
) {
  states.push(
    {
      name: 'dashboardTest',
      url: '/test',
      params: { id: '' },
      controllerAs: 'vm',
      parent: 'dashboard',
      component: views.DashboardTest,
      data: {
        title: 'Test',
        authenticate: [],
        inSideNav: true,
        sideNavMarker: 'dev'
      }
    },
    {
      name: 'dashboardTestBed',
      url: '/test-bed',
      params: { id: '' },
      controllerAs: 'vm',
      parent: 'dashboard',
      component: views.DashboardTestBed,
      data: {
        title: 'Test Bed',
        authenticate: [],
        inSideNav: true,
        sideNavMarker: 'dev'
      }
    },
    {
      name: 'test',
      url: '/test',
      controllerAs: 'vm',
      component: views.Test
    }
  );
}
