import angular from 'angular';
import { v4 as uuidv4 } from 'uuid';

import { Controller, Inject, On } from '@/decorators/ngCtrl';
import Pagination from '@/decorators/pagination';

export type TableProperty = {
  label: string;
  value: string | ((row: {}) => unknown);
};

function invalidTablePropError(
  this: DisplayTableComponent,
  type: string,
  val: string
) {
  console.error(
    `[data-table] The provided data for the dataTable "${val}" ${type} was not an array.`,
    this.props
  );
}

@Controller
@Pagination
class DisplayTableComponent {
  cols: unknown[] = [];
  utilCols: unknown[] = [];
  rows: unknown[] = [];
  sortedColIndex: number = -1;
  activeFlyout: unknown = { row: null, col: null };
  propListeners: unknown[] = [];
  itemListeners: unknown[] = [];
  // these two properties work together
  // utilTableActions take in selected rows
  // selected rows are automatically passed into
  // utilTableAction functions
  selectableRows: boolean = false;
  utilTableActions: unknown[] = [];
  //
  refreshRows: boolean = false;
  prevQuery: string | null = null;
  queryTimeout: unknown = null;
  actionCols: unknown = {};
  actionColWidth = 0;
  docResizeListener: unknown = null;

  @Inject readonly $scope!: angular.IScope;
  @Inject readonly $element!: angular.IElement;
  @Inject readonly $rootScope!: angular.IRootScopeService;
  @Inject readonly $filter!: angular.IFilterService;
  @Inject readonly $timeout!: angular.ITimeoutService;
  @Inject readonly $store!: angular.gears.IStoreService;
  @Inject readonly $delay!: angular.gears.IDelayService;
  @Inject readonly utils!: angular.gears.IUtilService;

  @On('refreashAll')
  refreashAll() {
    this.createCols();
    this.createActions();
    this.createRows();
  }

  @On('refreashTable') readonly createRows!: unknown;

  get pageRows() {
    return this.rows;
  }

  get sortedCol() {
    return this.sortedColIndex.toString();
  }

  get flyoutActive() {
    return !!this.activeFlyout.row && !!this.activeFlyout.col;
  }

  get sortReverse() {
    return this.$store.state.sortReversed;
  }

  get loading() {
    return this.status;
  }

  $watchSet(prop: keyof this, cb: (...args: unknown[]) => unknown) {
    return this.$scope.$watchCollection(() => this[prop], cb.bind(this));
  }

  $onInit() {
    if ('sortByIndex' in this && typeof this.sortByIndex === 'number') {
      this.sortedColIndex = this.sortByIndex;
    }

    if ('setSortReverse' in this && typeof this.setSortReverse === 'boolean') {
      this.$store.commit('reverseTableSort', this.setSortReverse);
    }

    this.$watchSet('props', this.createCols);
    this.$watchSet('actions', this.createActions);
    this.$watchSet('tableActions', this.createTableActions);
    this.$watchSet('items', this.createRows);

    this.$watch('refreshRows', (val) => {
      if (val) this.createRows();
    });

    this.prevQuery = this.query;

    this.$watch('query', (val) => {
      if (this.queryTimeout) {
        this.$timeout.cancel(this.queryTimeout);
      }

      if (this.prevQuery === val) {
        return;
      }

      this.queryTimeout = this.$timeout(() => {
        this.prepareRows();
        this.prevQuery = val;
      }, 500);
    });

    this.$scope.$watchGroup(
      [() => this.sortedColIndex, () => this.sortReverse],
      (newVal, oldVal) => {
        if (newVal === oldVal) {
          return;
        }

        // console.log('DISPLAY UPDATED!');

        this.prepareRows();
      }
    );

    this.$scope.$watchCollection(
      () => this.actionCols,
      () => {
        const widths = Object.values(this.actionCols);

        if (widths.length !== this.utilCols.length) {
          return;
        }

        this.actionColWidth = 0;

        for (let w of widths) {
          if (w > this.actionColWidth) {
            this.actionColWidth = w;
          }
        }
      }
    );
  }

  createCols() {
    this.propListeners.forEach((dispose) => dispose());

    if (!Array.isArray(this.props)) {
      if (this.props) {
        invalidTablePropError.bind(this)('item columns', this.itemTitle);
      }

      this.props = [];

      return;
    }

    const cols = [];

    for (let i in this.props) {
      let prop = this.props[i];

      if (!prop.hide || !prop.hide()) {
        cols.push({
          key: i.toString(),
          // ...prop
          label: prop.label,
          value: prop.value,
          filter: prop.filter,
          isDate: prop.isDate
        });
      }

      this.$scope.$watchCollection(
        () => prop,
        (newVal, oldVal) => {
          if (newVal !== oldVal) {
            this.createCols();
          }
        }
      );
    }

    this.cols = cols;
  }

  createActions() {
    this.utilCols = [];

    if (!Array.isArray(this.actions)) {
      if (this.actions) {
        console.error(
          `[data-table] The provided data for the dataTable "${this.itemTitle}" utility columns was not an array.`,
          this.actions
        );
      }

      return;
    }

    for (let { actions, label, icon, hide, disabled } of this.actions) {
      if (hide && !!hide()) {
        continue;
      }

      let col = { label, icon };

      if (typeof actions == 'function') {
        col.actions = actions;
      } else if (Array.isArray(actions) && actions.length) {
        col.actions = actions.filter(
          (subAction) => !subAction.hide || !subAction.hide()
        );

        if (!col.actions.length) {
          continue;
        }
      } else {
        continue;
      }

      if (typeof disabled == 'boolean') {
        col.disabled = () => disabled;
      } else if (typeof disabled == 'function') {
        col.disabled = disabled;
      } else {
        col.disabled = () => false;
      }

      this.utilCols.push(col);
    }
  }

  createTableActions() {
    this.utilTableActions = [];

    if (!Array.isArray(this.tableActions)) {
      if (this.tableActions) {
        console.error(
          `[data-table] The provided data for the dataTable "${this.itemTitle}" table actions was not an array.`,
          this.tableActions
        );
      }

      return;
    }

    for (let { fn, label, hide, disabled } of this.tableActions) {
      if (hide && !!hide()) {
        continue;
      }

      let tableAction = { label, fn };

      if (typeof disabled == 'boolean') {
        tableAction.disabled = () => disabled;
      } else if (typeof disabled == 'function') {
        tableAction.disabled = disabled;
      } else {
        tableAction.disabled = () => false;
      }

      this.utilTableActions.push(tableAction);
    }
  }

  createRows() {
    this.itemListeners = this.itemListeners.filter((dispose) => {
      dispose();

      return false;
    });

    if (!Array.isArray(this.items)) {
      invalidTablePropError.bind(this)('item rows', this.$scope.itemTitle);

      this.rows = [];

      return;
    }

    let rows = [];

    for (let item of this.items) {
      rows.push(this.$setRowValues(item));

      this.itemListeners.push(
        this.$scope.$watchCollection(
          () => item,
          (newVal, oldVal) => {
            if (newVal !== oldVal) {
              this.refreshRows = true;
            }
          }
        )
      );
    }

    this.rows = rows;
    // this.createPages(this.rows);
    this.prepareRows();

    this.refreshRows = false;
  }

  prepareRows() {
    let rows = this.rows;

    const query = this.query ? this.query.toLowerCase() : null;

    if (query) {
      rows = rows.filter((row) => {
        for (let i = 0; i < this.cols.length; i++) {
          let val = row[i];

          if (!val) {
            continue;
          }

          if (val.toString().toLowerCase().includes(query)) {
            return true;
          }
        }

        return false;
      });
    }

    rows = rows.sort((a, b) => {
      const valA = a[this.sortedColIndex]?.toString().toLowerCase() || '';
      const valB = b[this.sortedColIndex]?.toString().toLowerCase() || '';
      return valA.localeCompare(valB) * (this.sortReverse ? -1 : 1);
    });

    this.createPages(rows);
  }

  $setRowValues(item: unknown) {
    var row = { ref: item };

    for (let col of this.cols) {
      let output;

      let colValType = typeof col.value;

      if (colValType == 'function') {
        try {
          output = col.value(item);
        } catch (err) {
          output = null;
        }

        row[col.key] = prepareForDisplay(output);
        continue;
      }

      if (colValType != 'string') {
        throw new Error(
          `[display-table] Invalid value accsesor type: must be either a string or function. passed value type: ${colValType}`
        );
      }

      let propPath = col.value.split('.');

      output = item;

      try {
        for (var prop of propPath) {
          output = output[prop];
        }
      } catch (err) {
        output = null;
      }

      if (Array.isArray(output)) {
        let str = '';

        output.forEach((val, j) => {
          str += `${j > 0 ? '<br/>' : ''}<span>${val.toString()}</span>`;
        });

        output = str;
      }

      if (Array.isArray(col.filter)) {
        let filterArgs = [].concat(col.filter);
        let filterType = filterArgs.splice(0, 1);

        output = this.$filter(filterType)(output, ...filterArgs);
      } else if (typeof col.filter == 'string') {
        switch (col.filter) {
          case 'Date':
            console.log('TABLE DATE');

            output = this.$filter('dynamicDate')(
              output,
              'MM/dd/yyyy HH:mm:ss'
              // 'UTC'
            );

            break;
          default:
        }
      }

      row[col.key] = prepareForDisplay(output);
    }

    return row;
  }

  colFilter(item: unknown) {
    return item.format;
  }

  unsetFlyout(row: number, col: number) {
    if (this.activeFlyout.row == row && this.activeFlyout.col == col) {
      this.activeFlyout = { row: null, col: null };
    }
  }

  setFlyout(row: number, col: number) {
    if (this.activeFlyout.row != row || this.activeFlyout.col != col) {
      this.activeFlyout = { row, col };
      // console.log('SET', { row, col });
    } else {
      this.unsetFlyout(row, col);
    }
  }

  toggleSort(colIndex: number) {
    this.$store.commit(
      'reverseTableSort',
      this.sortedColIndex == colIndex ? !this.sortReverse : false
    );

    this.sortedColIndex = colIndex;
  }
}

function prepareForDisplay(val) {
  return val !== undefined && val !== null && val !== '' ? val : '--';
}

export default angular
  .module('app.displayTable', [])
  .directive('dtHeaderTd', () => ({
    require: '^^displayTable',
    replace: true,
    scope: { col: '=dtHeaderTd' },
    link(scope, element, attrs, dtCtrl) {
      const id = uuidv4();

      dtCtrl.actionCols[id] = null;

      scope.$watch(
        () => element.innerWidth(),
        (w) => {
          dtCtrl.actionCols[id] = w;
        }
      );

      scope.$on('$destroy', () => {
        delete dtCtrl.actionCols[id];
      });
    }
  }))
  .component('displayTable', {
    template: require('./display-table.html'),
    bindings: {
      props: '=',
      items: '=',
      // actions for each row
      actions: '=',
      query: '=',
      itemTitle: '=',
      sortByIndex: '=',
      setSortReverse: '=',
      itemsPerPage: '=',
      status: '=',
      showIdInActions: '=',
      // adds a select button to each row
      // works in tandum with tableActions
      selectableRows: '=',
      // actions for the entire table
      tableActions: '='
    },
    controller: DisplayTableComponent,
    controllerAs: 'vm'
  }).name;
