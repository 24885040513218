'use strict';

import angular from 'angular';
import { find, uniqBy } from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { State, Getter, Action } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

@Controller
class ChooseActiveInstitutionModalController extends Modal {
  loading: boolean = false;
  choice: {} = null;
  userInstitutions: {}[] = [];

  @State permissions;
  @Getter activeInstId;
  @Action('institutions/getLite') getInstitution;

  @Inject $scope;
  @Inject utils;

  $setup() {
    this.fetchInstitutions();
  }

  async fetchInstitutions() {
    this.loadingData = true;
    let gearsAdminPolicyFound;

    for (let map of this.permissions.maps) {
      let inst = await this.getInstitution(map.institutionId, true);

      if (!map.institutionId) gearsAdminPolicyFound = true;

      if (!inst) continue;

      this.userInstitutions.push(inst);

      if (this.activeInstId === inst.id) {
        this.choice = inst;
      }
    }

    if (gearsAdminPolicyFound) {
      this.userInstitutions.unshift({
        name: '(No Institution)',
        id: null
      });
    }

    if (!this.choice) this.choice = this.userInstitutions[0];

    this.userInstitutions = uniqBy(this.userInstitutions, 'id');

    if (!this.activeInstId && this.permissions?.maps?.length === 1) {
      // if we haven't chosen an active inst before, auto select their only institution
      this.choice = find(this.userInstitutions, (inst) => {
        return inst.id != null;
      });
      if (this.choice) this.setChoice();
    }

    this.loadingData = false;
  }

  setChoice() {
    this.$close(this.choice.id ? this.choice : 'noInstitution');
  }
}

export default angular
  .module('app.chooseActiveInstitutionModal', [])
  .directive('chooseActiveInstitutionModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./choose-active-institution.html'),
    controller: ChooseActiveInstitutionModalController,
    controllerAs: 'vm'
  })).name;
