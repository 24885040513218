'use strict';

import { State } from 'angular-store';
import { Controller } from '@/decorators/ngCtrl';

@Controller
class AdminView {
  constructor() {
    'ngInject';
  }

  @State users;

  $onInit() {}

  delete(user) {
    // user.$remove();
    //
    // this.users.splice(this.users.indexOf(user), 1);
  }
}

export default AdminView;
