import { Controller, Inject, On, Watch } from '@/decorators/ngCtrl';

@Controller
class DashboardEvaluationStatsView {
  tableProps: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';
  evalStats: {}[] = [];
  chartSelection = null;
  chartSelections = [];
  chartLabels = [];
  chartSeries = ['In Progress', 'Complete', 'Deleted'];
  chartOptions = {
    title: {
      display: true,
      text: '',
      color: 'rgb(187, 187, 187)'
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ],
      yAxes: [
        {
          gridLines: {
            borderDash: [2, 2],
            lineWidth: 1,
            color: 'rgb(187, 187, 187)'
          },
          ticks: {
            beginAtZero: true
          }
        }
      ]
    },
    legend: {
      display: true,
      labels: {
        fontColor: 'rgb(187, 187, 187)'
      }
    },
    responsive: true,
    maintainAspectRatio: false
  };

  @Inject $cookies;
  @Inject $ls;
  @Inject $modals;
  @Inject $sce;

  get tableItems() {
    return this.evalStats.institutions;
  }

  $onInit() {
    this.setup();
  }

  setup() {
    this.tableProps = [
      {
        label: 'Institution',
        value: 'name'
      }
    ];

    this.evalStats = this.$ls.get('evalStats');

    if (!this.evalStats) {
      return console.warn('No stats to display.');
    }

    console.log('this tableitems: ', this.tableItems);

    if (this.tableItems?.length) {
      this.tableItems[0].stats.forEach((o) => {
        this.tableProps.push({
          label: this.$sce.trustAsHtml(
            `<span>${o.name}</span><br/><span class="small-text">(In Progress)</span>`
          ),
          value: (row) => {
            let stat = row.stats.find((i) => i.tool === o.tool);
            return `${stat.inProgressEvaluations}`;
          }
        });

        this.tableProps.push({
          label: this.$sce.trustAsHtml(
            `<span>${o.name}</span><br/><span class="small-text">(Completed)</span>`
          ),
          value: (row) => {
            let stat = row.stats.find((i) => i.tool === o.tool);
            return `${stat.completedEvaluations}`;
          }
        });
      });
    }

    this.tableProps.push({
      label: 'Deleted Evaluations',
      value: 'deletedEvaluations'
    });

    // Chart Setup
    this.chartSelection = null;
    this.chartSelections = [];
    this.chartLabels = [];

    let totalInProgress = [];
    let totalCompleted = [];

    this.tableItems[0].stats.forEach((o, i) => {
      this.chartLabels.push(o.name);
      totalInProgress[i] = 0;
      totalCompleted[i] = 0;
    });

    for (let key in this.tableItems) {
      let inst = this.tableItems[key];

      let dataInProgress = [];
      let dataCompleted = [];

      inst.stats.forEach((o, i) => {
        dataInProgress.push(o.inProgressEvaluations);
        dataCompleted.push(o.completedEvaluations);

        totalInProgress[i] += o.inProgressEvaluations;
        totalCompleted[i] += o.completedEvaluations;
      });

      this.chartSelections.push({
        id: key,
        label: inst.name,
        value: {
          data: [dataInProgress, dataCompleted]
        }
      });
    }

    this.chartSelections.unshift({
      key: -1,
      label: 'All',
      value: {
        data: [totalInProgress, totalCompleted]
      }
    });

    this.chartSelection = this.chartSelections[0];

    this.changeSelection();
  }

  changeSelection() {
    this.chartOptions.title.text = `Evaluation Statistic (${this.chartSelection.label})`;
  }

  async newStatsProfile() {
    let res = await this.$modals.create.calculateEvaluationStats();

    if (res) {
      this.setup();

      this.$scope.$apply();
      this.$scope.$broadcast('refreashAll');
    }
  }
}

export default DashboardEvaluationStatsView;
