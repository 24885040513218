import angular, { sanitize } from 'angular';

/**
 * Automatically sanitizes input annd textfield HTML element model text.
 */
function ngTrustDirective($sanitize: sanitize.ISanitizeService) {
  'ngInject';

  const options: angular.IDirective = {
    restrict: 'E',
    require: '?ngModel',
    link: (_scope, _element, attrs, ngModel) => {
      if (!angular.isObject(ngModel)) return;

      if (ngModel && 'ngTrust' in attrs === false) {
        ngModel.$parsers.push((value: unknown) =>
          typeof value === 'string' ? $sanitize(value) : value
        );
      }
    }
  };

  return options;
}

export default angular
  .module('ngTrust', [])
  .directive('input', ngTrustDirective)
  .directive('textarea', ngTrustDirective).name;
