'use strict';

import angular from 'angular';

import { Controller, Inject, On } from '@/decorators/ngCtrl';
import { State, Mutation, Action } from 'angular-store';

type Policy = {
  allowed: boolean,
  denied: boolean
};

type PolicyMap = {
  allowAll: boolean,
  denyAll: boolean,
  actions: Policy[]
};

@Controller
class PermissionsUtilComponent {
  expanded: boolean = false;
  selectedPolicy: number = 1;
  isCustomPolicy: boolean = true;
  searchText: string = '';
  sortBy: string = 'name';
  actions: {} = [];
  policyMap: {};
  userPolicies: {} = null;

  @Inject $scope;
  @Inject $store;
  @Inject utils;

  // @State policies;
  @State(({ permissions }) => permissions.master) masterPolicy;
  @State(({ permissions }) => permissions.source) sourcePolicies;
  @Mutation('permissions/set') setPolicy;
  @Mutation('permissions/modify') modifyPolicy;
  @Mutation('permissions/modifyAction') modifyAction;
  @Action('permissions/setFromTemplates') setPolicyfromTemplates;

  @On('permissionProfileSet') buildActions;

  // get policies() {
  //   return { ...samplePolicies, ...(this.userPolicies || {}) };
  // }

  get managedPolicies() {
    return samplePolicies;
  }

  $onInit() {
    this.buildActions();
  }

  buildActions() {
    if (this.sourcePolicies.length) {
      this.userPolicies = {};

      this.sourcePolicies.forEach((item, i) => {
        const { institutionId: id } = item;

        let name = `User Policy - ${id ? `Inst ${id}` : 'Admin Policy'};`;

        this.userPolicies[`userPolicy${i}`] = { name, ...item };
      });
    } else {
      this.userPolicies = null;
    }

    let { masterPolicy, modifyPolicy, modifyAction } = this;

    let newMap = {
      get allowAll() {
        return masterPolicy.allowAll;
      },
      set allowAll(val) {
        modifyPolicy({ allowAll: val });
      },
      get denyAll() {
        return masterPolicy.denyAll;
      },
      set denyAll(val) {
        modifyPolicy({ denyAll: val });
      },
      actions: Object.entries(masterPolicy.actions).map(([key, action]) => ({
        key,
        get allowed() {
          return action.allowed;
        },
        set allowed(val) {
          modifyAction({ key, allowed: val });
        },
        get denied() {
          return action.denied;
        },
        set denied(val) {
          modifyAction({ key, denied: val });
        }
      }))
    };

    this.policyMap = newMap;
  }

  setPolicyFromTemplate() {
    let policyList = { ...(this.userPolicies || {}), ...samplePolicies };

    if (this.selectedPolicy == '0') {
      this.setPolicyfromTemplates({
        allowAll: false,
        denyAll: false,
        actions: []
      });
    } else {
      this.setPolicyfromTemplates(policyList[this.selectedPolicy].statement);
    }

    this.isCustomPolicy = false;
  }

  batchSet(effect = 'allowed', mode = 0) {
    let actions = this.utils.object.map(this.policies.actions, value => {
      return {
        allowed: effect == 'allowed' ? !!mode : value.allowed,
        denied: effect == 'denied' ? !!mode : value.denied
      };
    });

    this.modifyPolicy({ actions });
  }

  setCustom() {
    this.isCustomPolicy = true;
    this.selectedPolicy = 1;
  }
}

export default angular
  .module('app.permissionsUtil', [])
  .directive('permissionsUtil', () => {
    return {
      restrict: 'E',
      replace: true,
      scope: {},
      template: require('./permissions-util.html'),
      controller: PermissionsUtilComponent,
      controllerAs: 'vm'
    };
  }).name;

const GIFR_ADMIN_POLICY = {
  name: 'GifrAdmin',
  statement: {
    effect: 'allow',
    resources: '*',
    actions: '*'
  }
};

const INSTITUTION_ADMIN_POLICY = {
  managedPolicyId: '',
  institutionPolicyId: '',
  inlinePolicyId: '',
  name: 'InstitutionManagerFullAccess',
  institutionId: '{institutionId}',
  statement: [
    {
      effect: 'allow',
      actions: [
        'institutionmanager:ListInvites',
        'institutionmanager:ListClients',
        'institutionmanager:ListIncomingClientRequests',
        'institutionmanager:ListOutgoingClientRequests',
        'institutionmanager:ListEvaluationRequests',
        'institutionmanager:ListInstitutionUsers',
        'institutionmanager:ListPolicies',
        'institutionmanager:ListInstitutionTools',
        'institutionmanager:ListEvaluations',
        'institutionmanager:GetInstitution',
        'institutionmanager:UpdateInstitution',
        'institutionmanager:AddUser',
        'institutionmanager:RemoveUser',
        'institutionmanager:CreatePolicy',
        'institutionmanager:AssignPolicyToUser',
        'institutionmanager:RemovePolicyFromUser',
        'institutionmanager:CreateClient',
        'institutionmanager:DeleteClient',
        'institutionmanager:CreateExpungeClientRequest',
        'institutionmanager:ListZones',
        'institutionmanager:ListRegions',
        'institutionmanager:ListSubGroups',
        'institutionmanager:ListSubGroupClients',
        'institutionmanager:CreateZone',
        'institutionmanager:CreateRegion',
        'institutionmanager:CreateSubGroup',
        'institutionmanager:AddClientToSubGroup',
        'institutionmanager:RemoveClientFromSubGroup'
      ],
      resources: '*'
    },
    {
      effect: 'allow',
      actions: [
        'icpt:ListCasePlanTemplates',
        'icpt:ListCasePlanTemplateCommits',
        'icpt:GetCasePlanTemplateCommit',
        'icpt:CreateCasePlanTemplate',
        'icpt:CommitCasePlanTemplate',
        'icpt:TagCasePlanTemplateCommit',
        'icpt:SetCasePlanTemplateCommitStatus'
      ],
      resources: '*'
    },
    {
      effect: 'allow',
      actions: [
        'clientmanager:ListClientCasePlans',
        'clientmanager:ListClientEvaluations',
        'clientmanager:ListClientEvaluators',
        'clientmanager:ListClientContacts',
        'clientmanager:ListClientReports',
        'clientmanager:ListOffenderHistory',
        'clientmanager:GetClientCasePlan',
        'clientmanager:GetClientEvaluation',
        'clientmanager:GetOffenderHistory',
        'clientmanager:CreateOffenderHistory',
        'clientmanager:UpdateOffenderHistory',
        'clientmanager:DeleteOffenderHistory',
        'clientmanager:CreateClientEvaluation',
        'clientmanager:SaveClientEvaluation',
        'clientmanager:SubmitClientEvaluation',
        'clientmanager:RevertClientEvaluation',
        'clientmanager:DeleteClientEvaluation',
        'clientmanager:CreateClientCasePlan',
        'clientmanager:UpdateClientCasePlanStatus',
        'clientmanager:UpdateClientCasePlan'
      ],
      resources: '*'
    }
  ]
};

const INSTITUTION_SUPER_ADMIN_POLICY = {
  name: 'InstitutionSuperAdmin',
  institutionId: '{institutionId}',
  statement: {
    effect: 'allow',
    resources: [],
    actions: []
  }
};

const INSTITUTION_EVALUATOR_POLICY = {
  name: 'InstitutionEvaluator',
  institutionId: '{institutionId}',
  statement: {
    effect: 'allow',
    resources: [],
    actions: []
  }
};

var samplePolicies = {
  GIFR_ADMIN_POLICY,
  INSTITUTION_ADMIN_POLICY,
  INSTITUTION_EVALUATOR_POLICY,
  INSTITUTION_SUPER_ADMIN_POLICY
};
