import angular from 'angular';
import * as _ from 'lodash';

import TableViewController from '../table-view.controller';

export default class ItemTableController extends TableViewController {
  canAdd: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;

  constructor($rootScope, $scope, $element, $modals, $util) {
    'ngInject';

    super($rootScope, $scope, $element, $modals, $util);
    this.itemsPerPage = 10; // default set
    this.$modals = $modals;
    this.Util = $util;

    if (typeof this.editable === 'boolean') {
      this.canAdd = this.editable;
      this.canEdit = this.editable;
      this.canDelete = this.editable;
    } else if (typeof this.editable === 'object') {
      this.canAdd = this.editable.canAdd;
      this.canEdit = this.editable.canEdit;
      this.canDelete = this.editable.canDelete;
    }
  }

  get editable() {
    // variable on item-table directive
    return this._scope.editable || false;
  }

  get forbidEdit() {
    // variable on item-table directive
    return this._scope.forbidEditItems || false;
  }

  get forbidDelete() {
    // variable on item-table directive
    return this._scope.forbidDeleteItems || false;
  }

  get sortableRows() {
    // variable on item-table directive
    return this._scope.sortablerows || false;
  }

  parseConditions(conditions) {
    let string = '';
    let subOptions = '';
    _.forEach(conditions, (cond, ind) => {
      subOptions = '';
      _.forEach(cond.subOptions, (subOpt, index) => {
        if (index > 0) subOptions = subOptions + ', ';
        if (subOpt.subOptionValue) {
          subOptions =
            subOptions + subOpt.value.label + ' | ' + subOpt.subOptionValue;
        } else if (subOpt.value && subOpt.value.value) {
          subOptions = subOptions + subOpt.value.value;
        } else if (
          typeof subOpt.value === 'string' ||
          typeof subOpt.value === 'number'
        ) {
          subOptions = subOptions + subOpt.value;
        }
      });
      if (ind > 0) string = string + '<br>';
      string =
        string + '• ' + this._Util.decamlize(cond.value) + ' | ' + subOptions;
    });
    if (!string.length) string = 'NO CONDITIONS FOUND';
    return string;
  }

  enumLabel(options, key) {
    if (key === null || key === undefined) return;

    if (key.hasOwnProperty('$$hashKey'))
      Reflect.deleteProperty(key, '$$hashKey');

    if (!key && key !== false) return 'NO OPTION FOUND';
    let option = _.find(options, { value: key.value ? key.value : key });
    if (option) {
      return option.label ? option.label : 'NO OPTION FOUND';
    } else if (key.value) {
      return key.value;
    } else {
      return 'NO OPTION FOUND';
    }
  }

  interventionLabel(options, item) {
    // console.log('intervention label:');
    // console.log('options: ', options);
    // console.log('item: ', item);
    if (!item) return;
    let category = _.find(options, { value: item.category });
    let label = category ? category.label : 'NO CATEGORY FOUND';
    if (category) {
      let program = _.find(category.programsOptions, { value: item.program });
      if (!program) {
        label = label + ' | NO PROGRAM FOUND';
      } else {
        label = label + ' | ' + program.label;
      }
    }
    return label;
  }

  booleanLabel(key) {
    if (key === true || key === false) {
      return key ? 'Yes' : 'No';
    } else {
      return 'N/A';
    }
  }

  refresh() {
    this._scope.$apply();
  }

  async addItem(table) {
    let cols = angular.copy(this.table.cols);
    _.forEach(this.table.cols, (col) => {
      Reflect.deleteProperty(col, 'value');
    });

    if (this.table.sections.length) {
      let options = [];
      _.forEach(this.table.sections, (sect) => {
        options.push({ value: sect.key, label: sect.title });
      });
      cols.unshift({
        options,
        key: 'section',
        title: 'Section',
        type: 'enum'
      });
    }

    // let newItem = await this.$modals.test.createTabelItem(cols, null);

    let newItem;

    if (table.addAction) {
      newItem = await table.addAction(cols);
    } else {
      newItem = await this.$modals.test.createTabelItem(cols, null);
    }

    if (!newItem) return;

    if (!newItem.key)
      newItem.key = newItem.title
        ? this._Util.camelize(newItem.title)
        : this._Util.camelize(newItem.label);

    // if the table has a reference to another table's columns that it needs to influence
    if (this.table.columnsReference) {
      // this is a column with options (intervention or dropdown)
      if (typeof newItem.type === 'object') {
        newItem.options = newItem.type.options;
        //newItem.type.value === 'intervention' ||
        if (newItem.type.value === 'dropdown' || newItem.type.value === 'enum')
          newItem.type = 'enum';
        if (newItem.type.value === 'intervention')
          newItem.type = 'intervention';
      }
      newItem.title = newItem.label;
      newItem.key = this._Util.camelize(newItem.label);
      this.table.columnsReference.column(newItem);
    }

    this.table.item(newItem);

    // if the table has a progress reference that it needs to influence
    if (this.table.progressReference) this.table.generateProgressSections();

    this._scope.$apply();
  }

  async viewItem({ item }) {
    this.$modals.other.fileViewer(item);
  }

  async editItem(item, table) {
    let originalItem = _.cloneDeep(item);
    let cols = angular.copy(this.table.cols);
    _.forEach(this.table.cols, (col) => {
      Reflect.deleteProperty(col, 'value');
      if (!item.item.hasOwnProperty(col.key)) {
        item.item[col.key] = null;
      }
    });

    // for handling progress tracking table sections
    if (this.table.sections.length) {
      let options = [];
      _.forEach(this.table.sections, (sect) => {
        options.push({ value: sect.key, label: sect.title });
      });
      cols.unshift({
        options,
        key: 'section',
        title: 'Section',
        type: 'enum'
      });
    }

    let editedItem;
    if (!table.editAction) {
      editedItem = await this._$modals.test.createTabelItem(cols, item);
    } else {
      editedItem = await table.editAction(item);
    }

    if (!editedItem) return;

    if (typeof editedItem.type === 'object') {
      editedItem.options = editedItem.type.options;
      // editedItem.type.value === 'intervention' ||
      if (editedItem.type.value === 'dropdown') editedItem.type = 'enum';
      if (editedItem.type.value === 'intervention')
        editedItem.type = 'intervention';
    }

    if (editedItem.label) {
      editedItem.title = editedItem.label;
      editedItem.key = this._Util.camelize(editedItem.label);
    }

    if (!editedItem.key && editedItem.title)
      editedItem.key = this._Util.camelize(editedItem.title);

    if (item.item) {
      _.forEach(editedItem, function (val, key) {
        item.item[key] = editedItem[key];
      });
    }

    // if the table has a reference to another table's columns that it needs to influence
    if (this.table.columnsReference) {
      // this is a column with options that we need to update (intervention or dropdown)
      let columnToBeUpdated = _.find(this.table.columnsReference.cols, {
        key: originalItem.item.key
      });
      if (columnToBeUpdated) {
        _.forEach(columnToBeUpdated, (val, key) => {
          columnToBeUpdated[key] = this._Util.valueExists(editedItem[key])
            ? editedItem[key]
            : val;
        });
      } else {
        this.table.columnsReference.column(editedItem);
      }
    }

    // if the table has a progress reference that it needs to influence
    if (this.table.progressReference) this.table.generateProgressSections();

    this._scope.$apply();
  }

  async removeItem(item) {
    console.log('removeItem');

    if (this.table.removeAction) {
      await this.table.removeAction(item);
    }

    _.remove(this.table.items, (value) => value === item);

    // if the table has a reference to another table's columns that it needs to influence
    if (this.table.columnsReference) {
      // remove the column
      _.remove(this.table.columnsReference.cols, { key: item.item.key });

      // remove any references in data items to the column
      for (let row of this.table.columnsReference.items) {
        if (item.item.key in row.item) {
          delete row.item[item.item.key];
        }
      }
    }

    // if the table has a progress reference that it needs to influence
    if (this.table.progressReference) {
      this.table.removeProgressSection(item);
    }

    this.table.createPages();
  }
}
