'use strict';

import angular from 'angular';

import { State, Action, Mutation } from 'angular-store';
import { Controller, Inject, On, Watch } from '@/decorators/ngCtrl';

@Controller
class FormItemRadioComponent {
  errors: {}[] = [];

  get value() {
    return this.$scope.ngValue;
  }

  set value(value) {
    this.$scope.ngValue = value;
  }

  get model() {
    return this.$scope.ngModel;
  }

  set model(value) {
    this.$scope.ngModel = value;
  }
}

function processAttr(label, scope, attr) {
  let shouldEval = attr.$attr[label] == `:${label}`;

  Object.defineProperty(scope, label, {
    get: () => (shouldEval ? scope.$parent.$eval(attr[label]) : attr[label])
  });
}

export default angular
  .module('app.formItemRadio', [])
  .directive('formItemRadio', () => ({
    restrict: 'E',
    replace: true,
    template: require('./form-item-radio.html'),
    scope: {
      ngValue: '=',
      ngModel: '='
    },
    link(scope, element, attr, controller) {
      // processAttr('name', scope, attr);
      processAttr('label', scope, attr);
    },
    controller: FormItemRadioComponent,
    controllerAs: 'vm'
  })).name;
