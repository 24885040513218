export interface FieldConfigurationOption {
  label: string;
  value: boolean | string | number;
}

export interface BaseClientConfigField {
  label: string;
  required?: boolean;
  show: boolean;
  alias?: string | null;
  format?: string;
  includeOther?: boolean;
  isList?: boolean;
}

export interface GeneralClientConfigField extends BaseClientConfigField {
  type: 'clientProperty';
}

export interface ClientTypeConfigField extends BaseClientConfigField {
  type: 'clientTypes';
  options: FieldConfigurationOption[];
}

export interface ClientEthnicityConfigField extends BaseClientConfigField {
  type: 'ethnicityTypes';
  options: FieldConfigurationOption[];
}

export interface ClientCustomFieldsConfigField extends BaseClientConfigField {
  type: 'customClientFields';
  options: ClientCustomFieldConfigField[];
}

export interface ClientCustomFieldConfigField extends BaseClientConfigField {
  type: 'Boolean' | 'String' | 'Number';
  options?: FieldConfigurationOption[];
}

export type ClientConfigField =
  | GeneralClientConfigField
  | ClientTypeConfigField
  | ClientEthnicityConfigField
  | ClientCustomFieldsConfigField;

// export interface ClientConfigField {
//   type:
//     | 'clientProperty'
//     | 'clientTypes'
//     | 'ethnicityTypes'
//     | 'customClientFields';
//   label: string;
//   required?: boolean;
//   show?: boolean;
//   alias?: string | null;
//   format?: string;
//   includeOther?: boolean;
//   options?: string[];
// }

export type ClientConfigFields = Record<string, ClientConfigField>;

export const REASSESSMENT_OPTIONS = [
  { key: 'NOT_ALLOWED', label: 'Not Allowed' },
  { key: 'AUTO_ANSWER', label: 'Auto Answer' },
  { key: 'SHOW_ANSWER', label: 'Show Answer' }
];

export const MFA_OPTIONS = [
  { key: 'NOMFA', label: 'None' },
  { key: 'EMAIL', label: 'Email' }
];

export const INST_CLIENT_CUSTOMIZATION_CONFIG: ClientConfigFields = {
  fName: {
    type: 'clientProperty',
    label: 'First Name',
    required: true,
    show: true,
    alias: null
  },
  mName: {
    type: 'clientProperty',
    label: 'Middle Name',
    required: false,
    show: true,
    alias: null
  },
  lName: {
    type: 'clientProperty',
    label: 'Last Name',
    required: true,
    show: true,
    alias: null
  },
  alias: {
    type: 'clientProperty',
    label: 'Alias',
    required: false,
    show: true,
    alias: null
  },
  dob: {
    type: 'clientProperty',
    label: 'Date of Birth',
    required: true,
    show: true,
    alias: null,
    format: 'MM/DD/YYYY'
  },
  address1: {
    type: 'clientProperty',
    label: 'Address 1',
    required: false,
    show: true,
    alias: null
  },
  address2: {
    type: 'clientProperty',
    label: 'Address 2',
    required: false,
    show: true,
    alias: null
  },
  address3: {
    type: 'clientProperty',
    label: 'Address 3',
    required: false,
    show: true,
    alias: null
  },
  city: {
    type: 'clientProperty',
    label: 'City',
    required: false,
    show: true,
    alias: null
  },
  stateProvince: {
    type: 'clientProperty',
    label: 'State/Province',
    required: false,
    show: true,
    alias: null
  },
  postalCode: {
    type: 'clientProperty',
    label: 'Postal Code',
    required: false,
    show: true,
    alias: null
  },
  country: {
    type: 'clientProperty',
    label: 'Country',
    required: false,
    show: true,
    alias: null
  },
  phone: {
    type: 'clientProperty',
    label: 'Phone Number',
    required: false,
    show: true,
    alias: null
  },
  email: {
    type: 'clientProperty',
    label: 'Email',
    required: false,
    show: true,
    alias: null
  },
  clinicalInfo: {
    type: 'clientProperty',
    label: 'Clinical Info',
    required: false,
    show: true,
    alias: null
  },
  type: {
    type: 'clientTypes',
    label: 'Client Types',
    includeOther: true,
    show: true,
    options: []
  },
  ethnicity: {
    type: 'ethnicityTypes',
    label: 'Ethnicities',
    includeOther: true,
    show: true,
    options: []
  },
  customClientFields: {
    type: 'customClientFields',
    label: 'Custom Client Fields',
    show: true,
    options: [
      {
        type: 'String',
        label: 'String Field',
        required: false,
        show: true
      },
      {
        type: 'Number',
        label: 'Number Field',
        required: false,
        show: true
      },
      {
        type: 'Boolean',
        label: 'Boolean Field',
        required: false,
        show: true
      },
      {
        type: 'String',
        label: 'Single Selection Field',
        required: false,
        show: true,
        options: [
          { label: 'Option 1', value: 'Option 1' },
          { label: 'Option 2', value: 'Option 2' }
        ]
      },
      {
        type: 'String',
        label: 'Multi Selection Field',
        required: false,
        show: true,
        options: [
          { label: 'A', value: 'A' },
          { label: 'B', value: 'B' },
          { label: 'C', value: 'C' }
        ],
        isList: true
      }
    ]
  }
};

export const CUSTOM_CLIENT_FIELD_TYPES = ['String', 'Number', 'Boolean'];
