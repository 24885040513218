import angular, { Controller } from '@angular';

@Controller
class NavItemDropdown {
  active: boolean = false;
}

export default angular
  .module('app.nav-item-dropdown', [])
  .directive('navItemDropdown', () => ({
    restrict: 'E',
    replace: true,
    transclude: true,
    scope: { icon: '<', tooltip: '<' },
    template: require('./nav-item-dropdown.html'),
    controller: NavItemDropdown,
    controllerAs: 'vm'
  })).name;
