'use strict';

import angular from 'angular';
import _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class SelectExistingImageModalController extends Modal {
  loading: boolean;
  notify: any;
  images: {};
  selected: any;

  @Inject $scope;
  @Inject $http;
  @Inject notify;

  $setup() {
    this.loading = true;

    this.$http
      .get(`/api/templates/${this.$props}/get-images`)
      .then(response => {
        if (response.data.error) {
          this.notify.error(response.data.feedback);
          this.loading = false;
        } else {
          this.images = [];

          let getImageName = function(key) {
            return key.substring(key.lastIndexOf('/') + 1, key.length);
          };
          let rootUrl = 'https://s3.amazonaws.com/gifr-shared/';

          _.forEach(response.data.data, function(image) {
            this.images.push({
              url: rootUrl + image.Key,
              size: image.Size,
              lastModified: image.LastModified,
              name: getImageName(image.Key)
            });
          });
          this.selected = this.images[0];
          this.loading = false;
        }
      })
      .catch(err => {
        this.loading = false;
      });
  }

  choose() {
    this.$close(this.selected);
  }
}

export default angular
  .module('app.selectExistingImageModal', [])
  .directive('selectExistingImageModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./select-existing-image.html'),
    controller: SelectExistingImageModalController,
    controllerAs: 'vm'
  })).name;
