'use strict';

import angular from 'angular';
import { sortBy } from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { Getter } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

@Controller
class StartAssessmentModalController extends Modal {
  client: any;
  evaluations: any;
  tool: any;

  @Inject $scope;
  @Inject SelectionTable;

  $setup() {
    this.client = this.$props?.client;
    this.evaluations = this.$props?.evaluations;
    this.tool = this.$props?.tool;
    this.selectedEval = null;

    // prep evalautions for table
    let dataItems = [];
    _.forEach(this.evaluations, evaluation => {
      if (!evaluation.toolUsed && evaluation.tool) evaluation.toolUsed = evaluation.tool.id;
      let evaluatorName = evaluation.evaluator
        ? `${evaluation.evaluator.fName} ${evaluation.evaluator.lName}`
        : null;
      evaluation.toolName = evaluation.tool
        ? evaluation.tool.name
        : 'NO TOOL FOUND';
      evaluation.evaluatorName = evaluatorName
        ? evaluatorName
        : 'NO EVALUATOR FOUND';
      evaluation.updatedAt = this.$filter('dynamicDate')(
        evaluation.updatedAt,
        'MM/dd/yyyy hh:mm a'
      );
      dataItems.push(evaluation);
    });

    this.table = new this.SelectionTable({
      title: this.client.name(),
      items: dataItems,
      itemsPerPage: 10,
      onSelection: selection => {
        if (selection === this.selectedEval) {
          this.selectedEval = null;
          this.table.selection = null;
        } else {
          this.selectedEval = selection;
        }
      }
    })
      .column({
        title: 'Date',
        key: 'updatedAt'
      })
      .column({
        title: 'Status',
        key: 'status'
      })
      .column({
        title: 'Tool Used',
        key: 'toolName'
      })
      .column({
        title: 'Evaluator',
        key: 'evaluatorName'
      });
  }

  submit() {
    this.$close({
      newAssessment: !this.selectedEval,
      continueAssessment: this.selectedEval
    });
  }
}

export default angular
  .module('app.startAssessmentModal', [])
  .directive('startAssessmentModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./start-assessment.html'),
    controller: StartAssessmentModalController,
    controllerAs: 'vm'
  })).name;
