'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class HarepclrProfileReportChoicesModalController extends Modal {
  $setup() {
    this.options = [
      {
        text: 'Introduction',
        selected: true
      },
      {
        text: 'Total Score',
        selected: true
      },
      {
        text: 'Factor Scores',
        selected: true
      },
      {
        text: 'Facet Scores',
        selected: true
      },
      {
        text: 'Profile for Normative Type',
        selected: true
      },
      {
        text: 'Comparison Group',
        selected: true
      },
      {
        text: 'Item Responses',
        selected: true
      }
    ];
  }

  submit() {
    this.$close(this.options);
  }
}

export default angular
  .module('app.harepclrProfileReportChoicesModal', [])
  .directive('harepclrProfileReportChoicesModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./harepclr-profile-report-choices.html'),
    controller: HarepclrProfileReportChoicesModalController,
    controllerAs: 'vm'
  })).name;
