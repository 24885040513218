import angular from 'angular';
import download from 'downloadjs';
import mime from 'mime';
import { find } from 'lodash';

import { Controller, Inject } from '@decorators/ngCtrl';
import { ClientMedia } from '@interfaces/client-media';

interface MediaTableScope extends angular.IScope {
  route: string;
  filters?: Partial<ClientMedia>;
}

interface Props {
  label: string;
  value: keyof ClientMedia | ((media: ClientMedia) => unknown);
  filter?: { type: string; format: string };
}

interface TableAction {
  label: string;
  icon: string;
  actions: (media: ClientMedia, col: unknown) => void;
  disabled: (media: ClientMedia) => boolean;
}

interface TableColumn {
  processing: unknown;
}

@Controller
class MediaTable {
  props: Props[] = [];
  items: ClientMedia[] = [];
  actions: TableAction[] = [];
  loading = false;

  @Inject readonly $scope!: MediaTableScope;
  @Inject readonly $store!: angular.gears.IStoreService;
  @Inject readonly $http!: angular.IHttpService;
  @Inject readonly $api!: angular.gears.IApiService;
  @Inject readonly $modals!: angular.gears.IModalsService;
  @Inject readonly $delay!: angular.gears.IDelayService;
  @Inject readonly $notify!: angular.gears.INotifyService;

  $onInit() {
    this.props = [
      {
        label: 'File Name',
        value: 'name'
      },
      {
        label: 'Uploaded On',
        value: 'createdAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Status',
        value: ({ tags }) => {
          // new scan tag
          let tag = find(tags, { Key: 'scan-status' });

          // fallback to old scan tag
          if (!tag) tag = find(tags, { Key: 'av-status' });

          return !tag
            ? 'Scanning'
            : tag.Value === 'CLEAN'
            ? 'Completed'
            : tag.Value === 'INFECTED'
            ? 'Issue'
            : '--';
        }
      }
    ];

    this.actions = [
      {
        label: 'View',
        icon: 'eye',
        actions: this.viewMedia.bind(this),
        disabled: ({ tags }) => !tags || !find(tags, { Value: 'CLEAN' })
      },
      {
        label: 'Remove',
        icon: 'trash',
        actions: this.removeMedia.bind(this),
        disabled: ({ tags }) => !tags || !find(tags, { Value: 'CLEAN' })
      },
      {
        label: 'Download',
        icon: 'arrow-circle-down',
        actions: this.downloadMedia.bind(this),
        disabled: ({ tags }) => !tags || !find(tags, { Value: 'CLEAN' })
      }
    ];

    this.$scope.$watch('route', this._onRouteChanged.bind(this));
  }

  async addMedia() {
    let file: ClientMedia;

    try {
      file = (await this.$modals.other.uploadFile(
        this.$scope.route
      )) as unknown as ClientMedia;
    } catch (err) {
      return this.$notify.error('Failed to upload media file.');
    }

    if (!file) return;

    this.items = [file, ...this.items];
  }

  async viewMedia(media: ClientMedia, col: TableColumn) {
    col.processing = media;

    const { data: blob } = await this.$http<Blob>({
      method: 'get',
      url: `${this.$scope.route}/${media.id}`,
      responseType: 'blob'
    });

    let file: Blob;

    try {
      file = new File([blob], media.name);
    } catch {
      // Workaround for IE11
      file = Object.assign(blob, {
        name: media.name,
        lastModifiedDate: media.updatedAt
      });
    }

    void this.$modals.other.fileViewer(file);

    col.processing = false;
  }

  async removeMedia(media: ClientMedia) {
    const shouldContinue = (await this.$modals.other.genericPopup({
      title: 'REMOVING MEDIA',
      text: `Are you sure you want to permanently delete the file <b>${media.name}</b>?`
    })) as unknown as boolean;

    if (!shouldContinue) return;

    try {
      this.$store.commit('setLoadingMessage', 'Removing Media');
      this.$store.commit('setIsLoading', true);
      await this.$http.delete(`${this.$scope.route}/${media.id}`);
    } catch (err) {
      this.$notify.display(err, 'error');
      return;
    } finally {
      this.$store.commit('setIsLoading', false);
    }

    this.$notify.display('Media Successfully Deleted', 'success');

    this.items = this.items.filter(({ id }) => id !== media.id);
  }

  async downloadMedia(media: ClientMedia, col: TableColumn) {
    col.processing = media;

    const { data: blob } = await this.$http<Blob>({
      method: 'get',
      url: `${this.$scope.route}/${media.id}`,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const mimeType = mime.getType(media.name) ?? 'application/octet-stream';

    const reader = new FileReader();

    reader.readAsDataURL(new File([blob], media.name, { type: mimeType }));

    await new Promise((resolve) => {
      reader.onloadend = resolve;
    });

    const data = reader.result?.toString();

    if (data) {
      download(data, media.name, mimeType);
    } else {
      this.$notify.error('The file could not be downloaded');
    }

    col.processing = false;
  }

  async _onRouteChanged(route: string) {
    if (!route) return;

    let media: ClientMedia[] | null = null;
    let error: unknown = null;

    this.loading = true;

    try {
      media = (await this.$http.get<ClientMedia[]>(route))?.data;
    } catch (err) {
      error = err;
    }

    this.loading = false;

    if (error) {
      return this.$notify.display(error, 'error');
    }

    media = media ?? [];

    if (this.$scope.filters) {
      const filters = Object.entries(this.$scope.filters);

      media = media.filter((m) => {
        return filters.every(([key, value]) => {
          return m[key as keyof ClientMedia] === value;
        });
      });
    }

    this.items = media;

    this.$scope.$apply();
  }
}

export default angular
  .module('app.mediaTable', [])
  .directive('mediaTable', () => ({
    restrict: 'E',
    replace: true,
    scope: {
      route: '<',
      filters: '<'
    },
    template: require('./media-table.html'),
    controller: MediaTable,
    controllerAs: 'vm'
  })).name;
