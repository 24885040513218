import { Controller, Inject } from '@/decorators/ngCtrl';
import { State } from 'angular-store';

function round(num) {
  // return parseFloat(num.toFixed(2));
  return num;
}

const BG_AR = round(956 / 1824);

@Controller
class MainView {
  @Inject $location;
  @Inject $auth;
  @Inject $state;
  @Inject $modals;
  @Inject vcRecaptchaService;
  @Inject anchorSmoothScroll;
  @Inject notify;
  @Inject $delay;

  @State theme;

  bgS = 1;
  bgR = 1;

  get bgI() {
    const w = round(this.bgS);
    const h = round(this.bgR * this.bgS);
    const r = round(h / w);

    return { w, h, r, BG_AR };
  }

  get bgd() {
    let uw = this.bgI.w;
    let uh = this.bgI.h;

    let m = uh;

    if (this.bgI.r <= BG_AR) {
      m = BG_AR * uw;
    }

    const w = `calc(100vw * ${uw})`;
    const h = `calc(100vh * ${uh})`;
    const mh = `calc(100vh * ${m})`;

    return { uw, uh, w, h, mh };
  }

  get logoImage() {
    // return this.theme === 'gifr'
    //   ? 'assets/images/global-institute-logo-crest-white.png'
    //   : 'assets/images/logo/mhs-logo-all-white-color.svg';

    return this.theme === 'gifr'
      ? 'assets/images/logo/mhs-logo-all-white-color.svg'
      : this.theme === 'mhs-3'
      ? 'assets/images/logo/mhs-logo-black-color.svg'
      : this.theme === 'mhs-7'
      ? 'assets/images/logo/mhs-logo-all-white-color.svg'
      : 'assets/images/logo/mhs-logo-all-white-grayscale.svg';
  }

  async $onInit() {
    if (!this.$auth.loggedIn) {
      await this.$delay(3000);

      // this.$modals.login.login();
    }
  }

  openMessageModal(message) {
    this.$modals.settings.message('ITEM REFERENCE', message);
  }

  async scheduleDemo(form) {
    this.submitted = true;

    if (!this.request.reCaptcha) {
      this.notify.warning('Please Use the Recaptcha Field');
    }

    if (!form.$valid || !this.request.reCaptcha) {
      return;
    }

    try {
      let res = await this.$api.demoRequest.create(this.request);

      if (res.data.error) {
        this.notify.error(res.data.feedback);
      } else {
        this.notify.success(
          'Thank you for submitting your request. We will get back to you soon!'
        );

        this.request = {
          name: '',
          email: '',
          phone: '',
          institutionName: '',
          dateTime: '',
          details: '',
          demo: false,
          priceQuote: false,
          reCaptcha: null
        };
      }
    } catch (err) {
      let errorText;

      if (err.data !== null && typeof err.data === 'object') {
        errorText = '';

        for (let key in err.data) {
          errorText = errorText + err.data[key] + ' ';
        }
      }

      if (errorText) {
        this.notify.error(errorText);
      } else {
        this.notify.error('Please make sure all fields are filled in.');
      }

      this.vcRecaptchaService.reload(this.widgetId);
    }
  }

  goTo(item) {
    this.$location.hash(item);

    this.anchorSmoothScroll.scrollTo(item);
  }
}

export default MainView;
