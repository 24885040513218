import { differenceInYears } from 'date-fns';

import { Client } from '@interfaces/client';
import { OffenderHistory } from '@interfaces/offender-history';

// export type ClientCreationAttributes = Optional<Client, 'id' >;
export type ClientCreationAttributes = Client;

/** ... */
const STANDARD_PROPERTIES = [
  'id',
  'fName',
  'mName',
  'lName',
  'dob',
  'ethnicity',
  'type',
  'country',
  'address',
  'address1',
  'address2',
  'address3',
  'city',
  'stateProvince',
  'postalCode',
  'localId',
  'sex',
  'phone',
  'email',
  'clinicalInfo',
  'account',
  'externalId',
  'subGroup',
  'zone',
  'region',
  'offenderHistory',
  'evaluations',
  'alias',
  'createdAt',
  'updatedAt'
];

/**
 * Client data model.
 */
export class ClientModel {
  id: string;
  createdAt: string;
  updatedAt: string;
  fName: string;
  mName: string | null;
  lName: string;
  externalId: string | null;
  ethnicity: string | null;
  alias: string | null;
  address: Client.Address | null;
  address1: string | null;
  address2: string | null;
  address3: string | null;
  city: string | null;
  stateProvince: string | null;
  country: string | null;
  postalCode: string | null;
  account: Account | null;
  dob: string;
  sex: Client.Sex;
  type: string[] | null;
  clinicalInfo: string | null;
  phone: string | null;
  email: string | null;
  localId: string | null;
  zone: Client.LocationInfo;
  region: Client.LocationInfo;
  subGroup: Client.LocationInfo;
  offenderHistory?: OffenderHistory;
  //
  evaluations: unknown[];
  // Client config field properties.
  [key: string]: unknown;

  /**
   * ...
   */
  get age() {
    return differenceInYears(new Date(), new Date(this.dob));
  }

  /**
   * ...
   */
  get location() {
    let location = '';

    if (this.zone) location = location + `${this.zone.name} > `;
    if (this.region) location = location + `${this.region.name} > `;
    if (this.subGroup) location = location + `${this.subGroup.name}`;

    return location;
  }

  /**
   * ...
   */
  get institution() {
    return this.account;
  }

  set institution(value) {
    this.account = value;
  }

  /**
   * ...
   */
  get institutionId() {
    return this.account?.id;
  }

  /**
   * ...
   */
  get subGroupName() {
    return this.subGroup?.name;
  }

  constructor(options: ClientCreationAttributes) {
    const props = sortPropData(options);

    this.id = props.core.id;
    this.fName = props.core.fName;
    this.mName = props.core.mName;
    this.lName = props.core.lName;
    this.dob = props.core.dob;
    this.ethnicity = props.core.ethnicity;
    this.type = props.core.type;
    this.country = props.core.country;
    this.address = props.core.address;
    this.address1 = props.core.address1;
    this.address2 = props.core.address2;
    this.address3 = props.core.address3;
    this.city = props.core.city;
    this.stateProvince = props.core.stateProvince;
    this.postalCode = props.core.postalCode;
    this.localId = props.core.localId;
    this.sex = props.core.sex;
    this.phone = props.core.phone;
    this.email = props.core.email;
    this.clinicalInfo = props.core.clinicalInfo;
    this.account = props.core.account;
    this.externalId = props.core.externalId;
    this.subGroup = props.core.subGroup;
    this.zone = props.core.zone;
    this.region = props.core.region;
    this.offenderHistory = props.core.offenderHistory || [];
    this.evaluations = props.core.evaluations || [];
    this.alias = props.core.alias;
    this.createdAt = props.core.createdAt;
    this.updatedAt = props.core.updatedAt;

    for (const key in props.custom) {
      this[key] = props.custom[key];
    }
  }

  /**
   * ...
   *
   * @deprecated
   * @return ...
   */
  getAge() {
    return this.age;
  }

  /**
   * ...
   *
   * @deprecated
   * @return ...
   */
  getLocation() {
    return this.location;
  }

  /**
   * Get client's full name. If missing both a first and last name, the client's
   * `localId` will be used.
   *
   * @param lastFirst Position the last name first.
   * @return The client's full name.
   */
  name(lastFirst = false) {
    if (!this.fName && !this.lName) return this.localId ?? '';

    const names: string[] = [];

    if (lastFirst) {
      names.push(`${this.lName},`, this.fName);
    } else {
      names.push(this.fName, this.lName);
    }

    if (this.mName) names.splice(lastFirst ? 2 : 1, 0, this.mName);

    return names.join(' ');
  }
}

// region Helper Functions

/**
 * ...
 *
 * @param data ...
 * @return ...
 */
function sortPropData(data: ClientCreationAttributes) {
  const core = { ...data };
  const custom: Record<string, unknown> = {};

  for (const key in data) {
    if (!STANDARD_PROPERTIES.includes(key)) {
      custom[key] = data[key];
      delete core[key];
    }
  }

  return { core, custom };
}

// endregion Helper Functions
