import { Ng } from '../ng';
import { NgClass } from './types';

type Constructor = {
  new (...args: any[]): any;
};

export interface PropConfigurationOptions {
  //
}

export type PropOptions =
  | PropConfigurationOptions
  | Constructor
  | Constructor[];

/**
 * angularJS Prop property decorator.
 *
 * @param options Options for the prop
 * @return PropertyDecorator | void
 */
// function Prop(target: Ng, prop: string): any;
// function Prop(options: PropOptions): any;
// function Prop(...options: any[]): any {
//   if (options[0] === NgComponent.prototype) {
//     return createPropDecorator(options[0], options[1]);
//   }
//
//   return createPropDecorator;
// }

// export { Prop };
export function Prop(options: PropOptions = {}): any {
  return (target: NgClass, key: string) => {
    if (!target.constructor.$props) {
      Object.defineProperty(target.constructor, '$props', {
        writable: true,
        value: []
      });
    }

    key = key === 'value' ? 'ngModel' : key;

    target.constructor.$props!.push(key);

    const get = function (this: Ng) {
      return this.$props[key];
    };

    return { enumerable: true, get };
  };
}
