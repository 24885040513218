import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

import { forEach } from 'lodash';

@Controller
class offenderHistoryTemplateCustomizerField {
  @Inject $scope;
  @Inject Util;
  // @Inject $store;
  // @Inject $modals;
  // @Inject evlUtils;

  $onInit() {
    this.field = this.$scope.field;
    this.parentFields = this.$scope.parentFields;
  }

  get fieldTypes() {
    return FIELD_TYPES;
  }

  addFieldOption() {
    this.field.options.push({
      label: 'FIELD LABEL',
      value: 'FIELD VALUE'
    });
  }

  fieldTypeChanged() {
    if (this.field.type !== 'dropdown') this.field.options = [];
    if (this.field.type === 'dropdown')
      this.field.options = [
        {
          label: 'FIELD LABEL',
          value: 'FIELD VALUE'
        }
      ];
  }

  moveField(fields, field, down) {
    // "down" means further into the array
    // "up" or "not down" means sooner in the array
    let currentIndex = fields.indexOf(field);
    let newIndex = down ? currentIndex++ : currentIndex--;
    this.Util.arrayMove(fields, currentIndex, newIndex);
  }

  removeField(parentFields, field) {
    parentFields.splice(parentFields.indexOf(field), 1);
  }

  camelize(val) {
    val = this.Util.camelize(val);
  }
}

const FIELD_TYPES = ['text-input', 'dropdown', 'number-input', 'radio'];

export default angular
  .module('app.offender-history-template-customizer-field', [])
  .directive('offenderHistoryTemplateCustomizerField', function () {
    return {
      template: require('./offender-history-template-customizer-field.html'),
      restrict: 'EA',
      replace: true,
      scope: {
        field: '=',
        parentFields: '='
      },
      controller: offenderHistoryTemplateCustomizerField,
      controllerAs: 'vm'
    };
  }).name;
