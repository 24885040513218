import angular, { IFilterService } from 'angular';

export interface IFilterFilterDynamicDateFunc {
  (date: Date | number | string, format?: string, timezone?: string): string;
}

declare global {
  interface Window {
    gearsRegionFormat: string;
    gearsRegionPatter: RegExp;
  }
}

declare module 'angular' {
  interface IFilterService {
    /**
     * ...
     * @param  window.location.hostname [description]
     * @return                          [description]
     */
    (name: 'dynamicDate'): IFilterFilterDynamicDateFunc;
  }
}

window.gearsRegionFormat = /(au\.gifrgears\.com|gifrgears\.uk)$/.test(
  window.location.hostname
)
  ? 'dd/MM/yyyy'
  : 'MM/dd/yyyy';
// window.gearsRegionFormat = 'dd/MM/yyyy';
// window.gearsRegionFormat = 'MM/dd/yyyy';
// window.gearsRegionFormat = 'yyyy/MM/dd';

window.gearsRegionPatter = /(?:MM|M|mm|m)\/(?:dd|d)\/(?:yyyy|yy|y)/g;

function dynamicDateFilter($filter: IFilterService) {
  'ngInject';

  return ((
    date: Date | number | string,
    format?: string,
    timezone?: string
  ) => {
    format = format
      ? format.replace(window.gearsRegionPatter, window.gearsRegionFormat)
      : window.gearsRegionFormat;

    return $filter('date')(date, format, timezone);
  }) as IFilterFilterDynamicDateFunc;
}

export default angular
  .module('date', [])
  .filter('dynamicDate', dynamicDateFilter).name;
