'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ViewImageModalController extends Modal {
  url: string;

  $setup() {
    this.url = this.$modal;
  }

  close(msg) {
    this.$close(msg);
  }
}

export default angular
  .module('app.viewImageModal', [])
  .directive('viewImageModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./view-image.html'),
    controller: ViewImageModalController,
    controllerAs: 'vm'
  })).name;
