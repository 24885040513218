'use strict';

import angular from 'angular';

import { State } from 'angular-store';
import { Controller, On, Watch } from '@/decorators/ngCtrl';

import packageMeta from '@root/package.json';

@Controller
class AppVersionComponent {
  @State version;

  webVersion = packageMeta.version;
}

export default angular.module('app.appVersion', []).component('appVersion', {
  template: require('./app-version.html'),
  controller: AppVersionComponent,
  controllerAs: 'vm'
}).name;
