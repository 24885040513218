'use strict';

import { State } from 'angular-store';
import { Controller, Inject, On, Watch } from '@/decorators/ngCtrl';

const REASSESSMENT_OPTIONS = {
  NOT_ALLOWED: 'Not Allowed',
  AUTO_ANSWER: 'Auto-Populate Previous Response',
  SHOW_ANSWER: 'Show Previous Response'
};

const MFA_OPTIONS = {
  NOMFA: 'None',
  EMAIL: 'Email'
};

@Controller
class DashboardInstitutionView {
  @Inject $scope;
  @Inject $modals;
  @Inject $auth;
  @Inject $store;
  @Inject $api;
  @Inject notify;

  // @State(({ me }) => me.institution) institution;
  @State me;

  get reassessmentTypes() {
    return REASSESSMENT_OPTIONS;
  }

  get mfaTypes() {
    return MFA_OPTIONS;
  }

  $onInit() {
    this.institution = this.me.institution;
    if (!this.institution) this.institution = this.$store.state.me?.institution;
  }

  openEditInst() {
    this.$modals.edit.institution(this.institution);
  }

  async updateReassessmentOption() {
    if (
      !this.reassessmentUpdateValue ||
      this.reassessmentUpdateValue == this.institution.reassessment
    ) {
      console.error(
        "Reassessment Update Value Not Detected or Reassessment Update Value is the same as the current Institution's Value"
      );
      return;
    }
    this.processing = true;
    try {
      await this.$api.IM.updateInstitution(
        {
          instId: this.institution.id
        },
        {
          reassessment: this.reassessmentUpdateValue
        }
      );
      this.notify.display(
        'Updated Institution Reassessment Option Successfully',
        'success'
      );
      this.institution.reassessment = this.reassessmentUpdateValue;
      this.editingReassessmentOption = false;
    } catch (err) {
      this.notify.display('error', err);
    } finally {
      this.processing = false;
    }
  }

  async updateMFAOption() {
    if (!this.mfaUpdateValue || this.mfaUpdateValue == this.institution.mfa) {
      console.error(
        "MFA Update Value Not Detected or MFA Update Value is the same as the current Institution's Value"
      );
      return;
    }
    this.processing = true;
    try {
      await this.$api.IM.updateInstitution(
        {
          instId: this.institution.id
        },
        {
          mfa: this.mfaUpdateValue
        }
      );
      this.notify.display(
        'Updated Institution MFA Option Successfully',
        'success'
      );
      this.institution.mfa = this.mfaUpdateValue;
      this.editingMFAOption = false;
    } catch (err) {
      this.notify.display('error', err);
    } finally {
      this.processing = false;
    }
  }
}

export default DashboardInstitutionView;
