export default {
  totalScore(score, sex, type) {
    const percentileRanks = {
      0: {
        male: {
          canadian: '1.7%',
          custodial: '0.1%',
          community: '1.6%'
        },
        female: {
          canadian: '1.1%',
          custodial: '0.2%',
          community: '1.8%'
        }
      },
      1: {
        male: {
          canadian: '5.8%',
          custodial: '0.4%',
          community: '5.0%'
        },
        female: {
          canadian: '4.9%',
          custodial: '0.2%',
          community: '5.7%'
        }
      },
      2: {
        male: {
          canadian: '11.8%',
          custodial: '0.9%',
          community: '9.3%'
        },
        female: {
          canadian: '9.9%',
          custodial: '0.2%',
          community: '10.7%'
        }
      },
      3: {
        male: {
          canadian: '19.1%',
          custodial: '2.0%',
          community: '14.6%'
        },
        female: {
          canadian: '13.7%',
          custodial: '1.2%',
          community: '16.2%'
        }
      },
      4: {
        male: {
          canadian: '24.3%',
          custodial: '3.5%',
          community: '20.4%'
        },
        female: {
          canadian: '17.6%',
          custodial: '2.2%',
          community: '21.8%'
        }
      },
      5: {
        male: {
          canadian: '29.8%',
          custodial: '5.5%',
          community: '25.9%'
        },
        female: {
          canadian: '23.1%',
          custodial: '3.3%',
          community: '27.5%'
        }
      },
      6: {
        male: {
          canadian: '35.8%',
          custodial: '7.9%',
          community: '31.1%'
        },
        female: {
          canadian: '31.3%',
          custodial: '4.3%',
          community: '32.8%'
        }
      },
      7: {
        male: {
          canadian: '40.2%',
          custodial: '10.4%',
          community: '36.4%'
        },
        female: {
          canadian: '37.9%',
          custodial: '7.1%',
          community: '38.5%'
        }
      },
      8: {
        male: {
          canadian: '44.5%',
          custodial: '12.8%',
          community: '41.8%'
        },
        female: {
          canadian: '41.8%',
          custodial: '10.2%',
          community: '44.0%'
        }
      },
      9: {
        male: {
          canadian: '48.8%',
          custodial: '15.1%',
          community: '46.9%'
        },
        female: {
          canadian: '45.1%',
          custodial: '12.4%',
          community: '49.3%'
        }
      },
      10: {
        male: {
          canadian: '52.6%',
          custodial: '17.5%',
          community: '51.8%'
        },
        female: {
          canadian: '48.4%',
          custodial: '15.1%',
          community: '54.4%'
        }
      },
      11: {
        male: {
          canadian: '57.5%',
          custodial: '20.4%',
          community: '56.5%'
        },
        female: {
          canadian: '52.7%',
          custodial: '17.6%',
          community: '58.9%'
        }
      },
      12: {
        male: {
          canadian: '61.6%',
          custodial: '23.7%',
          community: '60.9%'
        },
        female: {
          canadian: '56.0%',
          custodial: '19.8%',
          community: '63.1%'
        }
      },
      13: {
        male: {
          canadian: '64.5%',
          custodial: '26.6%',
          community: '65.1%'
        },
        female: {
          canadian: '59.3%',
          custodial: '22.9%',
          community: '67.1%'
        }
      },
      14: {
        male: {
          canadian: '67.1%',
          custodial: '29.5%',
          community: '69.0%'
        },
        female: {
          canadian: '62.6%',
          custodial: '26.1%',
          community: '71.1%'
        }
      },
      15: {
        male: {
          canadian: '68.5%',
          custodial: '33.2%',
          community: '72.2%'
        },
        female: {
          canadian: '65.9%',
          custodial: '28.4%',
          community: '75.2%'
        }
      },
      16: {
        male: {
          canadian: '70.8%',
          custodial: '36.9%',
          community: '75.4%'
        },
        female: {
          canadian: '68.7%',
          custodial: '31.2%',
          community: '78.8%'
        }
      },
      17: {
        male: {
          canadian: '74.0%',
          custodial: '40.5%',
          community: '78.4%'
        },
        female: {
          canadian: '70.3%',
          custodial: '35.1%',
          community: '81.7%'
        }
      },
      18: {
        male: {
          canadian: '76.9%',
          custodial: '44.1%',
          community: '81.0%'
        },
        female: {
          canadian: '73.1%',
          custodial: '40.6%',
          community: '84.5%'
        }
      },
      19: {
        male: {
          canadian: '78.6%',
          custodial: '47.7%',
          community: '83.5%'
        },
        female: {
          canadian: '76.4%',
          custodial: '46.7%',
          community: '87.2%'
        }
      },
      20: {
        male: {
          canadian: '80.9%',
          custodial: '51.3%',
          community: '85.9%'
        },
        female: {
          canadian: '79.1%',
          custodial: '50.4%',
          community: '89.5%'
        }
      },
      21: {
        male: {
          canadian: '84.4%',
          custodial: '55.0%',
          community: '87.9%'
        },
        female: {
          canadian: '81.9%',
          custodial: '53.3%',
          community: '91.5%'
        }
      },
      22: {
        male: {
          canadian: '87.0%',
          custodial: '59.4%',
          community: '89.7%'
        },
        female: {
          canadian: '86.3%',
          custodial: '57.8%',
          community: '93.0%'
        }
      },
      23: {
        male: {
          canadian: '89.6%',
          custodial: '63.8%',
          community: '91.5%'
        },
        female: {
          canadian: '90.7%',
          custodial: '62.0%',
          community: '94.6%'
        }
      },
      24: {
        male: {
          canadian: '92.2%',
          custodial: '67.9%',
          community: '93.0%'
        },
        female: {
          canadian: '92.3%',
          custodial: '66.3%',
          community: '95.9%'
        }
      },
      25: {
        male: {
          canadian: '94.2%',
          custodial: '71.8%',
          community: '94.1%'
        },
        female: {
          canadian: '92.9%',
          custodial: '70.8%',
          community: '96.8%'
        }
      },
      26: {
        male: {
          canadian: '95.4%',
          custodial: '75.8%',
          community: '95.2%'
        },
        female: {
          canadian: '94.0%',
          custodial: '74.9%',
          community: '97.8%'
        }
      },
      27: {
        male: {
          canadian: '96.0%',
          custodial: '79.6%',
          community: '96.2%'
        },
        female: {
          canadian: '94.5%',
          custodial: '80.6%',
          community: '98.4%'
        }
      },
      28: {
        male: {
          canadian: '96.8%',
          custodial: '83.5%',
          community: '97.1%'
        },
        female: {
          canadian: '95.6%',
          custodial: '85.1%',
          community: '98.6%'
        }
      },
      29: {
        male: {
          canadian: '97.7%',
          custodial: '87.0%',
          community: '97.8%'
        },
        female: {
          canadian: '97.3%',
          custodial: '87.8%',
          community: '99.0%'
        }
      },
      30: {
        male: {
          canadian: '98.3%',
          custodial: '89.9%',
          community: '98.4%'
        },
        female: {
          canadian: '97.8%',
          custodial: '90.4%',
          community: '99.2%'
        }
      },
      31: {
        male: {
          canadian: '98.3%',
          custodial: '92.3%',
          community: '98.9%'
        },
        female: {
          canadian: '97.8%',
          custodial: '92.4%',
          community: '99.4%'
        }
      },
      32: {
        male: {
          canadian: '98.6%',
          custodial: '94.2%',
          community: '99.2%'
        },
        female: {
          canadian: '98.4%',
          custodial: '94.1%',
          community: '99.6%'
        }
      },
      33: {
        male: {
          canadian: '98.8%',
          custodial: '95.6%',
          community: '99.5%'
        },
        female: {
          canadian: '98.9%',
          custodial: '95.5%',
          community: '99.7%'
        }
      },
      34: {
        male: {
          canadian: '99.1%',
          custodial: '96.9%',
          community: '99.8%'
        },
        female: {
          canadian: '98.9%',
          custodial: '96.9%',
          community: '99.8%'
        }
      },
      35: {
        male: {
          canadian: '99.7%',
          custodial: '98.1%',
          community: '99.9%'
        },
        female: {
          canadian: '99.5%',
          custodial: '98.0%',
          community: '99.9%'
        }
      },
      36: {
        male: {
          canadian: '100.0%',
          custodial: '98.7%',
          community: '99.9%'
        },
        female: {
          canadian: '100.0%',
          custodial: '98.8%',
          community: '99.9%'
        }
      },
      37: {
        male: {
          canadian: '100.0%',
          custodial: '99.2%',
          community: '99.9%'
        },
        female: {
          canadian: '100.0%',
          custodial: '99.4%',
          community: '99.9%'
        }
      },
      38: {
        male: {
          canadian: '100.0%',
          custodial: '99.5%',
          community: '99.9%'
        },
        female: {
          canadian: '100.0%',
          custodial: '99.8%',
          community: '99.9%'
        }
      },
      39: {
        male: {
          canadian: '100.0%',
          custodial: '99.8%',
          community: '99.9%'
        },
        female: {
          canadian: '100.0%',
          custodial: '99.9%',
          community: '99.9%'
        }
      },
      40: {
        male: {
          canadian: '100.0%',
          custodial: '99.9%',
          community: '99.9%'
        },
        female: {
          canadian: '100.0%',
          custodial: '99.9%',
          community: '99.9%'
        }
      },
      41: {
        male: {
          canadian: '100.0%',
          custodial: '99.9%',
          community: '99.9%'
        },
        female: {
          canadian: '100.0%',
          custodial: '99.9%',
          community: '99.9%'
        }
      },
      42: {
        male: {
          canadian: '100.0%',
          custodial: '100.0%',
          community: '99.9%'
        },
        female: {
          canadian: '100.0%',
          custodial: '99.9%',
          community: '99.9%'
        }
      }
    };
    if (typeof score !== 'number' || score < 0 || score > 42) return;
    if (typeof sex !== 'string' && (sex !== 'male' || sex !== 'female')) return;
    if (typeof type !== 'string' && (type !== 'custodial' || type !== 'community' || type !== 'canadian')) return;
    if (percentileRanks[score]) {
      if (percentileRanks[score][sex]) {
        if (percentileRanks[score][sex][type]) {
          return percentileRanks[score][sex][type];
        }
      }
    }
  }
};
