'use strict';

import { findIndex, remove, uniqBy } from 'lodash';

function InvitationsStore($api, notify, $injector) {
  'ngInject';

  return {
    state: {
      loading: false,
      items: [],
      tableProps: {
        sortedCol: 0,
        searchText: ''
      }
    },
    actions: {
      async list({ state, commit, rootGetters, dispatch }, replace) {
        const $auth = $injector.get('$auth');

        commit('setProps', { loading: true });

        let data = [];
        try {
          let res;

          if ($auth.hasAccess('gearsmanager:ListInvites')) {
            // get all invites
            res = await $api.gearsManager.listInvites();
          } else if ($auth.hasAccess('institutionmanager:ListInvites')) {
            // get invites for institution
            res = await $api.institutionManager.listInvites(
              rootGetters.activeInstId
            );
          } else {
            res = { data: [] };
          }

          data = res.data;
        } catch (err) {
          commit('setProps', { loading: false });
          throw err;
        }

        commit('set', data);

        commit('setProps', { loading: false });

        return state.items;
      },
      async getAll({ state, commit }) {
        commit('setProps', { loading: true });

        let data = [];

        try {
          let res = await $api.gearsManager.listInvites();
          data = res.data;
        } catch (err) {
          commit('setProps', { loading: false });
          throw err;
        }

        commit('add', data);
        commit('setProps', { loading: false });

        return state.items;
      },
      async getForInstitution({ state, rootState, commit }, instId) {
        if (isNaN(instId)) {
          if (!rootState.me.institution) {
            return;
          }

          instId = rootState.me.institution.id;
        }

        commit('setProps', { loading: true });

        let data = [];

        try {
          let res = await $api.institutionManager.listInvites(instId);
          data = res.data;
        } catch (err) {
          commit('setProps', { loading: false });
          throw err;
        }

        commit('add', data);
        commit('setProps', { loading: false });

        return state.items;
      },
      async update({ state }, payload) {
        let index = findIndex(state.items, { id: payload.id });
        if (index < 0) return;
        state.items.splice(index, 1, payload);
        return state.items;
      },
      async delete({ state }, payload) {
        if (!payload.account?.id) {
          notify.display('No Institution ID Found on Invite', 'error');
          return;
        }
        let res = await $api.institutionManager.deleteInvite({
          instId: payload.account?.id,
          inviteId: payload.id
        });
        if (res.status !== 204 && res.status !== 200) {
          notify.display(res, 'error');
          return;
        }
        remove(state.items, { id: payload.id });
      }
    },
    mutations: {
      set(state, payload) {
        state.items = [...payload];
      },
      add(state, payload) {
        state.items = uniqBy([...payload, ...state.items], 'id');
      },
      setProps(state, props = {}) {
        for (let i in props) {
          if (i in state) {
            state[i] = props[i];
          }
        }
      },
      CLEAR(state) {
        Object.assign(state, {
          loading: false,
          items: [],
          tableProps: {
            sortedCol: 0,
            searchText: ''
          }
        });
      }
    }
  };
}

export default InvitationsStore;
