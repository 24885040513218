import { find } from 'lodash';

import {
  ProrationOperation,
  Operation,
  Operator,
  Comparator,
  ConstantOperation,
  InputOperation,
  ValueOperation,
  UniOperation,
  BinaryOperation,
  ComparatorOperation,
  OutputOperation
} from './types';

/**
 * ...
 */
export interface ProrationOptions {
  ops?: ProrationOperation[];
  constants?: ProrationOperation[];
  input?: ProrationOperation;
  output?: ProrationOperation;
}

/**
 * ...
 *
 * @param a ...
 * @param b ...
 * @param operator ...
 * @return ...
 */
function mathOperation(a: number, b: number, operator: Operator) {
  let result: number;

  switch (operator) {
    case '+':
      result = a + b;
      break;
    case '-':
      result = a - b;
      break;
    case '*':
      result = a * b;
      break;
    case '/':
      result = a / b;
      break;
  }

  return result;
}

/**
 * ...
 *
 * @param a ...
 * @param b ...
 * @param comparator ...
 * @return ...
 */
function compareOperation(a: number, b: number, comparator: Comparator) {
  let result: boolean;

  switch (comparator) {
    case '<':
      result = a < b;
      break;
    case '>':
      result = a > b;
      break;
    case '<=':
      result = a <= b;
      break;
    case '>=':
      result = a >= b;
      break;
    case '==':
      result = a == b;
      break;
  }

  return result;
}

/**
 * ...
 */
export class Proration {
  ops: ProrationOperation[];
  constants: ProrationOperation[];
  input: ProrationOperation;
  output: ProrationOperation;

  private hasError = false;

  constructor(config: ProrationOptions = {}) {
    this.ops = config.ops || [];
    this.constants = config.constants || [];

    const input = config.input ?? find(this.ops, { type: Operation.Input });

    if (!input) {
      throw new Error('');
    }

    this.input = input;

    const output = config.output ?? find(this.ops, { type: Operation.Output });

    if (!output) {
      throw new Error('');
    }

    this.output = output;

    for (const item of this.constants) {
      const op = find(this.ops, { label: item.label });

      if (op) {
        op.result = item.value;
      }
    }

    // console.debug('New Proration - ', this);
  }

  /**
   * ...
   *
   * @return ...
   */
  evaluate() {
    return this.onOperation(this.input);
  }

  /**
   * ...
   *
   * @param varId ...
   * @return ...
   */
  private getOp(varId: string) {
    const op = find(this.ops, { varId });

    if (!op) {
      throw new Error(
        `A proration operation with a varId of "${varId}" could not be found.`
      );
    }

    return op;
  }

  /**
   * ...
   *
   * @param op ...
   * @param prevOp ...
   * @return ...
   */
  private onOperation(op: ProrationOperation, prevOp?: ProrationOperation) {
    let nextOp: ProrationOperation | null = null;

    switch (op.type) {
      case Operation.Constant:
        this.handleConstantOperation(op as ConstantOperation);

        break;
      case Operation.Input:
        nextOp = this.handleInputOperation(op as InputOperation) ?? null;

        break;
      case Operation.Value:
        nextOp =
          this.handleValueOperation(op as ValueOperation, prevOp!) ?? null;

        break;
      case Operation.Uni:
        nextOp = this.handleUniOperation(op as UniOperation, prevOp!) ?? null;

        break;
      case Operation.Binary:
        nextOp =
          this.handleBinaryOperation(op as BinaryOperation, prevOp!) ?? null;

        break;
      case Operation.Comparator:
        nextOp =
          this.handleComparatorOperation(op as ComparatorOperation, prevOp!) ??
          null;

        break;
      case Operation.Output:
        this.handleOutputOperation(op as OutputOperation, prevOp!);

        break;
      default:
        // console.warn('Operation was not a recognized type.');
        this.hasError = true;

        console.error(
          `The operation component ${op.label} was not a recognized type.`,
          op
        );
    }

    if (isNaN(op.result)) op.result = 0;

    let result = 0;

    if (this.hasError) {
      result = -1;
    } else if (op.varId === this.output.varId || !nextOp) {
      result = op.result;
    } else {
      result = this.onOperation(nextOp, op);
    }

    return result;
  }

  /**
   * ...
   *
   * @return
   */
  private handleConstantOperation(op: ProrationOperation) {
    if (isNaN(op.result)) {
      this.hasError = true;

      console.error(
        'The value for the Constant Operation Component ' +
          op.label +
          ' was not set or was set to an invalid value: ' +
          op.result,
        op
      );
    }
  }

  /**
   * ...
   *
   * @return
   */
  private handleInputOperation(op: InputOperation) {
    op.result = this.getOp(op.inputCompId).result;

    return this.getOp(op.outputCompId);
  }

  /**
   * ...
   *
   * @return
   */
  private handleValueOperation(op: ValueOperation, prevOp: ProrationOperation) {
    op.result = mathOperation(prevOp.result, op.value, op.operatorSymbol);

    return this.getOp(op.nextVarId);

    // console.log('RESULT: ' + (prevOp.result) + op.operatorSymbol + (op.value) + ' = ', op.result);
  }

  /**
   * ...
   *
   * @return
   */
  private handleUniOperation(op: UniOperation, prevOp: ProrationOperation) {
    op.result = Math[op.operatorSymbol](prevOp.result);

    return this.getOp(op.nextVarId);

    // console.log('RESULT: Math.' + (op.operatorSymbol) + '(' + prevOp.result + ')' + ' = ', op.result);
  }

  /**
   * ...
   *
   * @return
   */
  private handleBinaryOperation(
    op: BinaryOperation,
    prevOp: ProrationOperation
  ) {
    let opA = this.getOp(op.variable1);
    let opB = this.getOp(op.variable2);

    let a: number;
    let b: number;

    if (opA == prevOp) {
      a = prevOp.result;
      b = !opB.result ? this.onOperation(opB, op) : opB.result;
    } else if (opB == prevOp) {
      a = !opA.result ? this.onOperation(opA, op) : opA.result;
      b = prevOp.result;
    } else {
      a = !opA.result ? this.onOperation(opA, op) : opA.result;
      b = !opB.result ? this.onOperation(opB, op) : opB.result;
    }

    op.result = mathOperation(a, b, op.operatorSymbol);

    return this.getOp(op.nextVarId);

    // console.log('RESULT: ' + (a) + op.operatorSymbol + (b) + ' = ', op.result);
  }

  /**
   * ...
   *
   * @return
   */
  private handleComparatorOperation(
    op: ComparatorOperation,
    prevOp: ProrationOperation
  ) {
    op.result = prevOp.result;

    var check = compareOperation(op.result, op.compareValue, op.operatorSymbol);

    return this.getOp(check == true ? op.nextVarTrueId : op.nextVarFalseId);

    // console.log('Compare RESULT: ' + (op.result) + op.operatorSymbol + (op.compareValue), check);
  }

  /**
   * ...
   *
   * @return
   */
  private handleOutputOperation(
    op: OutputOperation,
    prevOp: ProrationOperation
  ) {
    // op.result = Math.round(prevOp.result);

    op.result = Math.sign(prevOp.result) * Math.round(Math.abs(prevOp.result));
  }
}
