'use strict';

import angular from 'angular';

export default angular
  .module('app.noSpecialCharacters', [])
  .directive('noSpecialCharacters', () => ({
    template: `<div></div>`,
    restrict: 'EA',
    link: function(scope, element) {
      element.text('this is the noSpecialCharacters directive');
    }
  })).name;
