import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInYears
} from 'date-fns';

type DatetimeUnit = 'seconds' | 'minutes' | 'hours' | 'days' | 'years';

function differenceIn(
  dateA: number | Date,
  dateB: number | Date,
  unit: DatetimeUnit
) {
  switch (unit) {
    case 'seconds':
      return differenceInSeconds(dateA, dateB);
    case 'minutes':
      return differenceInMinutes(dateA, dateB);
    case 'hours':
      return differenceInHours(dateA, dateB);
    case 'days':
      return differenceInDays(dateA, dateB);
    case 'years':
      return differenceInYears(dateA, dateB);
  }
}

class DatetimeUtils {
  get _now() {
    return new Date();
  }

  /**
   * Get the current datetime.
   *
   * @return The current datetime.
   */
  now() {
    return this._now;
  }

  /**
   * Get the time since a certain date.
   *
   * @param date The target date.
   * @param unit The unit of time the value should be in.
   * @return The time since the provided date.
   */
  timeSince(date: number | Date, unit: DatetimeUnit = 'seconds') {
    date = date ? new Date(date) : this._now;

    return differenceIn(this._now, date, unit);
  }

  /**
   * Get the time until a certain date.
   *
   * @param date The target date.
   * @param unit The unit of time the value should be in.
   * @return The time until the provided date.
   */
  timeUntil(date: number | Date, unit: DatetimeUnit = 'seconds') {
    date = date ? new Date(date) : this._now;

    return differenceIn(date, this._now, unit);
  }

  /**
   * Calculate an age (in years) based on a provided birthdate.
   *
   * @param dob The target birthdate.
   * @return Age (in years).
   */
  calcAge(dob: number | string) {
    return differenceInYears(new Date(), new Date(dob));
  }
}

export const datetimeUtils = new DatetimeUtils();
