import { Component, Ref } from '@angular';

import { Institution } from '@interfaces';
import { InstitutionForm } from '@components/institution-form/institution-form.component';
import { Modal, ModalProp } from '@mixins/modal.component';

@Component({
  name: 'editInstitutionModal',
  template: require('./edit-institution.html')
})
export class EditInstitutionModalController extends Modal {
  @ModalProp readonly institution!: Institution;

  submitting = false;

  @Ref readonly institutionForm!: InstitutionForm;

  get processing() {
    return this.submitting || this.institutionForm.processing;
  }

  get canSubmit() {
    return this.institutionForm.isValid;
  }

  get onLastStep() {
    return this.institutionForm.onLastStep;
  }

  get onFirstStep() {
    return this.institutionForm.onFirstStep;
  }

  nextStep() {
    this.institutionForm.stepNext();
  }

  previousStep() {
    this.institutionForm.stepPrevious();
  }

  $setup() {}

  async submit() {
    let data: any = null;
    let error: Error | null = null;

    this.submitting = true;

    try {
      data = await this.institutionForm.submit();
    } catch (err) {
      error = err;
    }

    this.submitting = false;

    if (error) {
      return this.notify.error(
        `Failed to update the institution: ${error.message}`
      );
    }

    this.notify.success(`Institution updated successfully.`);

    this.$close(data);
  }
}
export default EditInstitutionModalController.$module;
