import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

const ANYTHING_CELL_VALUE =
  '<span class="anything-value" style="font-style: italic;">anything</span>';
const CLIENT_ID_CELL_VALUE =
  '<span class="anything-value" style="font-style: italic;">*Must match Client ID in GEARS*</span>';

const DESCRIPTOR_VALUES = {
  number: '<span>Number</span>',
  string: '<span>Text</span>',
  DateTime: '<span>Date/Time</span>',
  Date: '<span>Date</span>',
  date: '<span>Date</span>'
};

function createPages(sheets, questions) {
  return sheets.map((sheet) => {
    const items = sheet.columns.map((col) => {
      let column = col.headerName;

      if (col.required) {
        column = `<span>${column}<span class="required">*</span></span>`;
      }

      const match = questions.find(
        ({ excelSheet, excelColumn }) =>
          excelSheet === sheet.name && excelColumn === col.headerName
      );

      let values = match?.values;
      let descriptor = match?.descriptor;

      if (values && typeof values == 'object') {
        values = Object.keys(values).map((value) => {
          value = value || '<span class="blank-value">blank</div>';

          return `&#8226 ${value}`;
        });
      } else if (!values && descriptor) {
        values =
          descriptor.type === 'number' &&
          'min' in descriptor &&
          'max' in descriptor
            ? `<span>Number (${descriptor.min} to ${descriptor.max})</span>`
            : DESCRIPTOR_VALUES[descriptor.type];
      } else if (!values && !descriptor) {
        values =
          col.headerName == 'Client_ID' || col.headerName == 'ID'
            ? CLIENT_ID_CELL_VALUE
            : ANYTHING_CELL_VALUE;
      } else if (!Array.isArray(values)) {
        // values = values;
      }

      // console.log(object)

      return { column, values };
    });

    return {
      name: sheet.name,
      required: sheet.required,
      items,
      expanded: true
    };
  });
}

@Controller
class ImportRequestValueGuideModalController extends Modal {
  tableProps: any[] = [
    {
      label: 'Column',
      value: 'column'
    },
    {
      label: 'Value(s)',
      value: 'values'
    }
  ];

  pages: any[] = [];

  @Inject $scope;

  async $setup() {
    const {
      excelValidation,
      supplementalExcelValidation,
      offenderHistoryInformation,
      evaluationData,
      evaluationAdditionalData,
      supplementalEvaluationData,
      supplementalEvaluationAdditionalData
    } = this.$props.tool.evaluationImportConfig;

    const sheets = [...excelValidation.sheets];

    if (supplementalExcelValidation) {
      sheets.push(...supplementalExcelValidation.sheets);
    }

    const questions = [...evaluationData];

    if (offenderHistoryInformation) {
      questions.push(...offenderHistoryInformation.values);
    }

    if (evaluationAdditionalData) {
      questions.push(...evaluationAdditionalData);
    }

    if (supplementalEvaluationData) {
      questions.push(...supplementalEvaluationData);
    }

    if (supplementalEvaluationAdditionalData) {
      questions.push(...supplementalEvaluationAdditionalData);
    }

    this.pages = createPages(sheets, questions);

    console.log(this.pages);
  }
}

export default angular
  .module('gifrApp.importRequestValueGuideModal', [])
  .directive('importRequestValueGuideModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./import-request-value-guide-modal.html'),
    controller: ImportRequestValueGuideModalController,
    controllerAs: 'vm'
  })).name;
