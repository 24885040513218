'use strict';

import angular from 'angular';

export default angular
  .module('app.overlay', [])
  .directive('overlay', () => {
    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: true,
      template: require('./overlay.html'),
      link(scope, element, { $attr, color }) {
        Object.defineProperty(scope, 'color', {
          get: () =>
            ($attr.color == ':color' ? scope.$eval(color) : color) || '#a5adb5'
        });
      }
    };
  }).name;
