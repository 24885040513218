interface RouteSegment {
  text: string;
  parameter: boolean;
}

function mapRouteSegment(text: string) {
  let parameter = false;

  if (text.charAt(0) === ':') {
    text = text.slice(1);
    parameter = true;
  }

  return { text, parameter } as RouteSegment;
}

export class RouteMap {
  private segments: RouteSegment[] = [];

  constructor(
    public readonly route: string,
    public readonly params?: string[]
  ) {}

  static create(route: string, params?: string[]) {
    const routeMap = new RouteMap(route, params);

    routeMap.segments = route.split('/').map(mapRouteSegment);

    return routeMap;
  }

  getRoute(data?: any, prefix?: RouteMap) {
    data = data && typeof data === 'object' ? data : {};

    const segments: RouteSegment[] = [];

    if (prefix) {
      segments.push(...prefix.segments);
    }

    segments.push(...this.segments);

    let route = segments
      .map(({ text, parameter }) => {
        if (!parameter) return text;

        if (text in data) return data[text];

        throw new Error(
          `A value for parameter "${text}" of route "${this.route}" was not provided.`
        );
      })
      .join('/');

    if (this.params) {
      route +=
        '?' +
        this.params
          .filter((param) => param in data)
          .map((param) => param + '=' + data[param])
          .join('&');
    }

    return route;
  }

  extend(route: string, params?: string[]) {
    return RouteMap.create(`${this.route}/${route}`, params);
  }
}
