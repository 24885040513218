'use strict';

import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

import { find, some } from 'lodash';

@Controller
class DashboardReportsView {
  tableProps: {}[] = [];
  // tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  @Inject $state;
  @Inject reportUtils;
  @Inject dataModals;
  @Inject evlUtils;
  @Inject $util;
  @Inject $store;
  @Inject $stateParams;
  @Inject notify;
  @Inject Util;

  @State reports;
  @State(({ clients }) => clients.focus) client;
  @State(({ evaluations }) => evaluations.focus) evaluation;

  get tableItems() {
    return this.reports.items;
  }

  get showPostReportNavOptions() {
    return this.reportUtils.showPostReportNavOptions;
  }

  get getYLSLSSuiteOfTools() {
    return this.Util.getYLSLSSuiteOfTools();
  }

  get frontEndReportTools() {
    return this.$util.getFrontEndReportTools();
  }

  $onInit() {
    // empty report items
    this.reports.items = [];
    this.showReportDeleteWarning = find(this.getYLSLSSuiteOfTools, {
      id: this.evaluation?.toolUsed
        ? this.evaluation?.toolUsed
        : this.evaluation?.tool?.id
    });
    // set the reportUtils.showPostReportNavOptions to false if we cannot generate a case plan off of it
    if (
      !find(this.getYLSLSSuiteOfTools, {
        id: this.evaluation?.toolUsed
          ? this.evaluation?.toolUsed
          : this.evaluation?.tool?.id
      })
    ) {
      this.reportUtils.showPostReportNavOptions = false;
    }
    // set frontEndReport
    this.frontEndReport = some(this.frontEndReportTools, {
      id: this.evaluation?.toolUsed
        ? this.evaluation?.toolUsed
        : this.evaluation?.tool?.id
    });
    if (!this.client) this.$state.go('dashboardClients');

    let { reportUtils, dataModals } = this;

    this.tableProps = [
      {
        label: 'Created',
        value: 'createdAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Tool Used',
        value: 'tool.name'
      },
      {
        label: 'Status',
        value: (val) => {
          if (!val.bucket && !val.path) return 'N/A';
          if (!val.tags || !Array.isArray(val.tags)) return 'Scanning';

          let status = 'Scanning...';
          val.tags.forEach((tag) => {
            if (
              (tag['Key'] === 'av-status' || tag['Key'] === 'scan-status') &&
              tag['Value'] === 'CLEAN'
            )
              status = 'Scanned/Clean';
          });

          return status;
        }
      }
    ];

    this.tableActions = [
      {
        label: 'Options',
        icon: 'cog',
        actions: [
          {
            label: 'Download',
            icon: 'download',
            fn: (val) => reportUtils.getReport(val),
            disabled: (val) => {
              if (!val.bucket && !val.path) return false;
              if (!val.tags || !Array.isArray(val.tags)) return false;

              let disabled = true;
              val.tags.forEach((tag) => {
                if (
                  (tag['Key'] === 'av-status' ||
                    tag['Key'] === 'scan-status') &&
                  tag['Value'] === 'CLEAN'
                )
                  disabled = false;
              });

              return disabled;
            }
            // hide: () => !$checkAuth('UpdateUser')
          },
          {
            label: 'Email',
            icon: 'envelope',
            fn: (val) => reportUtils.openEmailReportModal(val.id),
            disabled: (val) => val.bucket && val.path
          },
          {
            label: 'Delete Report',
            icon: 'times',
            fn: (val) => dataModals.delete(val, 'report')
            // hide: () => !$checkAuth('UpdateUser')
          }
        ]
      }
    ];
  }

  showEvaluations() {
    this.evlUtils.showEvaluations(null, true);
  }

  generateReport() {
    this.reportUtils.generateReport();
  }

  generateCasePlan() {
    let evaluation = this.evaluation;
    if (!evaluation || (!evaluation.id && this.reportUtils.evlForReport)) {
      evaluation = this.reportUtils.evlForReport;
    }
    if (!evaluation || (!evaluation.id && this.reportUtils.evaluation)) {
      evaluation = this.reportUtils.evaluation;
    }
    if (!evaluation || !evaluation.id) {
      this.notify.display('Could not grab evaluation ID', 'error');
      return;
    }
    this.reportUtils.evalToGenCasePlan = evaluation.id;
    if (!this.client || !this.client.id) {
      this.reportUtils.evalToGenCasePlan = null;
      this.notify.display('Could not grab client ID', 'error');
      return;
    }
    this.$state.go('dashboardClient', { id: this.client.id });
  }
}

export default DashboardReportsView;
