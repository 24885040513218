'use strict';

import angular from 'angular';

import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class TransferSuperAdminModalController extends Modal {
  user: number;

  @Inject $scope;
  @Inject $store;
  @Inject $auth;
  @Inject $api;
  @Inject utils;

  @State me;

  $setup() {
    this.users = angular.copy(this.$props.users);
    this.newSuperAdmin = '';

    // var keep = false;
    // var i;
    // for (i = 0; i < this.users.length; i++) {
    //   keep = false;
    //   if (this.inst) {
    //     if (_.find(this.users[i].roles, {institutionId: this.groupId})) {
    //       this.users[i].activeRole = _.find(this.users[i].roles, {institutionId: this.groupId});
    //       keep = true;
    //     }
    //   } else if (_.find(this.users[i].roles, {developerGroupId: this.groupId})) {
    //     this.users[i].activeRole = _.find(this.users[i].roles, {developerGroupId: this.groupId});
    //     keep = true;
    //   }
    //   if (!keep) {
    //     this.users.splice(i, 1);
    //     i--;
    //   }
    // }
    //
    // for (i = 0; i < this.users.length; i++) {
    //   if (this.users[i].activeRole.roleId === 3 || this.users[i].activeRole.roleId === 6) {
    //     this.users.splice(i, 1);
    //     i--;
    //     continue;x
    //   }
    // }
  }

  async transferSuperAdmin1(form) {
    if (!form.$valid) {
      return;
    }

    let error;

    try {
      let paylod = {};
      paylod.institutionId = this.$props.groupId;
      paylod.superAdmin = this.newSuperAdmin;

      let { data } = await this.$api.institutionTransfers.create(paylod);

      if (data.error) {
        error = data;
      }
    } catch (err) {
      error = err;
    }

    if (error) {
      this.utils.notify('error', error.feedback);

      return;
    }

    this.utils.notify('success', 'Transfer Request Submitted Successfully');
    this.parent.$close();
  }
}

export default angular
  .module('app.transferSuperAdminModal', [])
  .directive('transferSuperAdminModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./transfer-super-admin.html'),
    controller: TransferSuperAdminModalController,
    controllerAs: 'vm'
  })).name;
