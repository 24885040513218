'use strict';

import angular from 'angular';

class CmpCasePlanController {
  selectedSection;

  constructor() {

  }
}

export default angular.module('app.section.cmpCasePlan', [])
  .component('cmpCasePlan', {
    template: require('./case-plan.html'),
    bindings: {
      cmpManager: '=cmpManager'
    },
    controller: CmpCasePlanController,
    controllerAs: 'vm'
  })
  .name;