'use strict';

import angular from 'angular';
import { remove, filter } from 'lodash';

import { State, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class NavbarNotificationsComponent {
  active: boolean = false;

  @Inject $scope;
  @Inject $store;
  @Inject $auth;
  @Inject evlReqUtils;
  @Inject evlUtils;
  @Inject utils;

  @State(({ me }) => me.evaluationRequests) evlReqs;
  @State(({ me }) => me.institutionTransfers) instTrans;
  @Action('institutionTransfers/approve') approveTransfer;
  @Action('institutionTransfers/deny') denyTransfer;

  // $onInit() {
  //   for (var i = 0; i < 20; i++) {
  //     this.instTrans.push({
  //       name: `TEST ${i}`
  //     });
  //
  //     this.evlReqs.push({
  //       client: {
  //         fName: 'John',
  //         lName: 'Smith'
  //       },
  //       evaluation: {
  //         tool: {
  //           name: `Some Tool ${i}`
  //         }
  //       }
  //     });
  //   }
  // }

  $onInit() {
    if (this.$auth.loggedIn) this.$store.dispatch('me/getMyEvaluationRequests');
  }

  get notifyCount() {
    let num = this.instTrans.length + this.evlReqs.length;
    return num <= 99 ? num : '99+';
  }

  async requestOpen(req) {
    let evl = angular.copy(req);
    evl.id = evl.evaluation ? evl.evaluation.id : evl.evaluationId;
    this.evlUtils.continueEvaluation(evl);
  }

  requestRemove(i) {
    this.evalReqs.splice(i, 1);
  }
}

export default angular
  .module('app.navbarNotifications', [])
  .directive('navbarNotifications', () => ({
    restrict: 'E',
    replace: true,
    scope: true,
    template: require('./navbar-notifications.html'),
    controller: NavbarNotificationsComponent,
    controllerAs: 'vm'
  })).name;
