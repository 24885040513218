'use strict';

import angular from 'angular';

export class TableHeadCellComponent {
  constructor($scope, $attrs) {
    'ngInject';

    this.$scope = $scope;
    // this.$scope.key = $attrs.key;
    // this.$scope.label = $attrs.label;
  }

  get sortReverse() {
    return this.$scope.$root.dsc.sortReverse;
  }

  set sortReverse(val) {
    this.$scope.$root.dsc.sortReverse = val;
  }

  toggleSort() {
    this.sortType = this.key;
    this.sortReverse = !this.sortReverse;
  }
}

export default angular
  .module('app.tableHeadCell', [])
  .directive('tableHeadCell', () => {
    return {
      restrict: 'A',
      replace: true,
      scope: {
        key: '=',
        label: '=',
        sortType: '='
      },
      template: require('./table-head-cell.html'),
      controller: TableHeadCellComponent,
      controllerAs: 'vm'
    };
  }).name;
