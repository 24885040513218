import { gears } from 'angular';

import { AnalyticsState } from './modules/analytics';
import { ClientsState } from './modules/clients';
import { EvaluationsResquestsState } from './modules/evaluation-requests';
import { InstitutionState } from './modules/institutions';
import { LocationsState } from './modules/locations';
import { MeState } from './modules/me';
import { PermissionsState } from './modules/permissions';
import { RemindersState } from './modules/reminders';
import { ToolsState } from './modules/tools';
import { UsersState } from './modules/users';

/**
 * ...
 */
export interface RootState {
  version: string;
  authenticating: boolean;
  loadingData: boolean;
  loadingProgress: number;
  onMain: boolean;
  onDashboard: boolean;
  onToolCreator: boolean;
  isLoading: boolean;
  prohibitInput: boolean;
  isLoadingMessage: string | null;
  sideNavCollapsed: boolean | null;
  evaluationStatsData: string | null;
  //
  isOpera: boolean;
  isFirefox: boolean;
  isSafari: boolean;
  isIE: boolean;
  isEdge: boolean;
  isChrome: boolean;
  isBlink: boolean;
  //
  theme: 'light' | 'dark';
  // Tables
  sortReversed: boolean;
  //
  logs: unknown[];
  showIdInActions: boolean;
}

/**
 * ...
 */
export interface RootModules {
  analytics: AnalyticsState;
  clients: ClientsState;
  evaluationRequests: EvaluationsResquestsState;
  institutions: InstitutionState;
  locations: LocationsState;
  me: MeState;
  permissions: PermissionsState;
  reminders: RemindersState;
  tools: ToolsState;
  users: UsersState;
}

/**
 * ...
 */
export class State implements RootState {
  version = 'X.X.XX';
  authenticating = false;
  loadingData = false;
  loadingProgress = 0;
  onMain = false;
  onDashboard = false;
  onToolCreator = false;
  isLoading = false;
  prohibitInput = false;
  isLoadingMessage: string | null = null;
  sideNavCollapsed: boolean | null = null;
  evaluationStatsData: string | null = null;
  isOpera = false;
  isFirefox = false;
  isSafari = false;
  isIE = false;
  isEdge = false;
  isChrome = false;
  isBlink = false;
  theme: 'light' | 'dark';
  sortReversed = false;
  logs = [];
  showIdInActions = false;

  constructor($ls: gears.ILsService) {
    'ngInject';

    this.sideNavCollapsed = !!$ls.get<boolean>('sideNavCollapsed');
    this.evaluationStatsData = $ls.get('evalStats');
    this.theme = $ls.get('appTheme') || 'light';
  }
}
