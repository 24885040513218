/**
 * ...
 */
export type ClientCustomizationConfig = {
  fName: ClientCustomizationConfig.GeneralField;
  mName: ClientCustomizationConfig.GeneralField;
  lName: ClientCustomizationConfig.GeneralField;
  alias: ClientCustomizationConfig.GeneralField;
  dob: ClientCustomizationConfig.GeneralField;
  address1: ClientCustomizationConfig.GeneralField;
  address2: ClientCustomizationConfig.GeneralField;
  address3: ClientCustomizationConfig.GeneralField;
  city: ClientCustomizationConfig.GeneralField;
  stateProvince: ClientCustomizationConfig.GeneralField;
  postalCode: ClientCustomizationConfig.GeneralField;
  country: ClientCustomizationConfig.GeneralField;
  phone: ClientCustomizationConfig.GeneralField;
  email: ClientCustomizationConfig.GeneralField;
  clinicalInfo: ClientCustomizationConfig.GeneralField;
  type: ClientCustomizationConfig.TypeField;
  ethnicity: ClientCustomizationConfig.EthnicityField;
  customClientFields: ClientCustomizationConfig.CustomField;
};

export namespace ClientCustomizationConfig {
  /** ... */
  export interface Field {
    label: string;
    required?: boolean;
    show: boolean;
    alias?: string | null;
    format?: string;
    includeOther?: boolean;
    isList?: boolean;
  }

  export namespace Field {
    /** ... */
    export interface Option {
      label: string;
      value: boolean | string | number;
    }
  }

  /** ... */
  export interface GeneralField extends Field {
    type: 'clientProperty';
  }

  /** ... */
  export interface TypeField extends Field {
    type: 'clientTypes';
    options: Field.Option[];
  }

  /** ... */
  export interface EthnicityField extends Field {
    type: 'ethnicityTypes';
    options: Field.Option[];
  }

  /** ... */
  export interface CustomField extends Field {
    type: 'customClientFields';
    options: CustomOptionField[];
  }

  /** ... */
  export interface CustomOptionField extends Field {
    type: 'Boolean' | 'String' | 'Number';
    giveOptions?: boolean;
    options?: Field.Option[];
  }
}

export const REASSESSMENT_OPTIONS = [
  { key: 'NOT_ALLOWED', label: 'Not Allowed' },
  { key: 'AUTO_ANSWER', label: 'Auto Answer' },
  { key: 'SHOW_ANSWER', label: 'Show Answer' }
];

export const MFA_OPTIONS = [
  { key: 'NOMFA', label: 'None' },
  { key: 'EMAIL', label: 'Email' }
];

export const INST_CLIENT_CUSTOMIZATION_CONFIG: ClientCustomizationConfig = {
  fName: {
    type: 'clientProperty',
    label: 'First Name',
    required: true,
    show: true,
    alias: null
  },
  mName: {
    type: 'clientProperty',
    label: 'Middle Name',
    required: false,
    show: true,
    alias: null
  },
  lName: {
    type: 'clientProperty',
    label: 'Last Name',
    required: true,
    show: true,
    alias: null
  },
  alias: {
    type: 'clientProperty',
    label: 'Alias',
    required: false,
    show: true,
    alias: null
  },
  dob: {
    type: 'clientProperty',
    label: 'Date of Birth',
    required: true,
    show: true,
    alias: null,
    format: 'MM/DD/YYYY'
  },
  address1: {
    type: 'clientProperty',
    label: 'Address 1',
    required: false,
    show: true,
    alias: null
  },
  address2: {
    type: 'clientProperty',
    label: 'Address 2',
    required: false,
    show: true,
    alias: null
  },
  address3: {
    type: 'clientProperty',
    label: 'Address 3',
    required: false,
    show: true,
    alias: null
  },
  city: {
    type: 'clientProperty',
    label: 'City',
    required: false,
    show: true,
    alias: null
  },
  stateProvince: {
    type: 'clientProperty',
    label: 'State/Province',
    required: false,
    show: true,
    alias: null
  },
  postalCode: {
    type: 'clientProperty',
    label: 'Postal Code',
    required: false,
    show: true,
    alias: null
  },
  country: {
    type: 'clientProperty',
    label: 'Country',
    required: false,
    show: true,
    alias: null
  },
  phone: {
    type: 'clientProperty',
    label: 'Phone Number',
    required: false,
    show: true,
    alias: null
  },
  email: {
    type: 'clientProperty',
    label: 'Email',
    required: false,
    show: true,
    alias: null
  },
  clinicalInfo: {
    type: 'clientProperty',
    label: 'Clinical Info',
    required: false,
    show: true,
    alias: null
  },
  type: {
    type: 'clientTypes',
    label: 'Client Types',
    includeOther: true,
    show: true,
    options: []
  },
  ethnicity: {
    type: 'ethnicityTypes',
    label: 'Ethnicities',
    includeOther: true,
    show: true,
    options: []
  },
  customClientFields: {
    type: 'customClientFields',
    label: 'Custom Client Fields',
    show: true,
    options: []
  }
};

export const CUSTOM_CLIENT_FIELD_TYPES = ['String', 'Number', 'Boolean'];
