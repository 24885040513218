import angular from 'angular';
import { uniq, merge } from 'lodash';

import {
  Resource,
  PermissionProfile,
  ResourceMap,
  EffectListMap,
  EffectPropsMap,
  StatementMap
} from '@interfaces/permissions';
import { Policy } from '@interfaces/policy';

/**
 * ...
 */
function createStatementMap(
  actions?: EffectListMap,
  resources?: EffectPropsMap
) {
  return {
    actions: actions ?? { allowed: [], denied: [] },
    resources: resources ?? { allowed: {}, denied: {} }
  } as StatementMap;
}

/**
 * ...
 */
function mergeStatementMaps(maps: StatementMap[]) {
  const actions: EffectListMap = { allowed: [], denied: [] };
  const resources: EffectPropsMap = { allowed: {}, denied: {} };

  for (const map of maps) {
    actions.allowed = uniq([...map.actions.allowed, ...actions.allowed]);
    actions.denied = uniq([...map.actions.denied, ...actions.denied]);

    resources.allowed = merge(resources.allowed, map.resources.allowed);
    resources.denied = merge(resources.denied, map.resources.denied);
  }

  return { actions, resources } as StatementMap;
}

/**
 * ...
 */
export class PolicyTranslatorService {
  constructor(
    private readonly $acl: angular.gears.IAclService,
    private readonly utils: angular.gears.IUtilsService
  ) {
    'ngInject';
  }

  /**
   * Returns a Resource object parsed from the passed GRN.
   *
   * @param grn ...
   * @return ...
   */
  parseGrn(grn: string) {
    const values =
      grn.match(/grn:gifr:(.*?):(.*?):(.*?):(.*?)[:\/](\S*)/) || [];

    if (!values.length) {
      console.warn('[policyTranslator] tried to parse invalid grn.', grn);
    }

    const service = values[1] ?? '';
    const region = values[2] ?? '';
    const instId = values[3] ?? '';
    const resourceName = values[4] ?? '*';
    const resourceValue = values[5] ?? '';

    return {
      grn,
      service,
      region,
      instId,
      resourceName,
      resourceValue
    } as Resource;
  }

  /**
   * Create as policy statement map from policy statement(s), which can be used
   * to generate a permission profile.
   *
   * @param statement The policy statement(s) to map.
   * @return A policy statement map.
   */
  mapStatement(statement: Policy.Statement | Policy.Statement[]) {
    const map = createStatementMap();

    for (const item of this.utils.makeArray(statement)) {
      const effect =
        item.effect === 'allow'
          ? 'allowed'
          : item.effect === 'deny'
          ? 'denied'
          : null;

      if (!effect) {
        throw new Error(
          `[policyTranslator:statementMap] Invalid entry for effect ("${item.effect}") in statement scope -- effect must be either "allow" or "deny".`
        );
      }

      const actions = item.actions ? this.utils.makeArray(item.actions) : [];
      const resources = item.resources
        ? this.utils.makeArray(item.resources)
        : null;

      for (let actionKey of actions) {
        // Reference to appropriate action EffectGroup.
        let apm = map.actions[effect];

        // Add the action if it's not already present in the group.
        if (!apm.includes(actionKey)) {
          apm.push(actionKey);
        }

        if (!resources?.length) continue;

        // Reference to appropriate resource EffectGroup.
        let rpm = map.resources[effect];

        if (actionKey in rpm === false) {
          rpm[actionKey] = [];
        }

        for (let resourceKey of resources) {
          if (!rpm[actionKey].includes(resourceKey)) {
            rpm[actionKey].push(resourceKey);
          }
        }
      }
    }

    return map;
  }

  /**
   * Create a permission profile for use with discerning user access throughout
   * the site.
   *
   * @param statementMaps Statement map(s) to be evaluated.
   * @return A permission profile.
   */
  permissionProfile(statementMaps: StatementMap | StatementMap[]) {
    const sm = mergeStatementMaps(this.utils.makeArray(statementMaps));

    const profile: PermissionProfile = { allowed: {}, denied: [] };

    let deniedActions = [...sm.actions.denied];
    profile.denied = deniedActions.includes('*') ? '*' : deniedActions;

    const addResource = (actionKey: string, resourceKey: string) => {
      let [service] = actionKey.split(':');
      let resources = sm.resources.allowed[resourceKey];

      if (resources.includes('*')) {
        profile.allowed[actionKey] = '*';

        return;
      }

      const rm = profile.allowed[actionKey] as ResourceMap;

      for (let grn of resources) {
        let resource = this.parseGrn(grn);
        let { resourceName: recName, resourceValue: recVal } = resource;

        if (service !== resource.service) {
          continue;
        }

        // if (recName in rm === false) {
        //   rm[recName] = recVal === '*' ? '*' : {};
        // }
        //
        // if (rm[recName] === '*') {
        //   continue;
        // }
        //
        // rm[recName][recVal] = resource;

        const entry = (rm[recName] =
          recName in rm ? rm[recName] : recVal === '*' ? '*' : {});

        if (entry === '*') continue;

        entry[recVal] = resource;
      }
    };

    for (let action of sm.actions.allowed) {
      profile.allowed[action] = {};

      if ('*' in sm.resources.allowed) {
        addResource(action, '*');
      }

      if (action in sm.resources.allowed) {
        addResource(action, action);
      }
    }

    return profile;
  }

  /**
   * Convert one or more policy statement(s) directly into a permission profile.
   *
   * @param statement The policy statement(s) to evaluate.
   * @return A permission profile.
   */
  statementToProfile(statement: Policy.Statement | Policy.Statement[]) {
    return this.permissionProfile(this.mapStatement(statement));
  }

  /**
   * @deprecated
   * @param checks ...
   * @param profile ...
   * @return ...
   */
  hasAccess(...args: Parameters<angular.gears.IAclService>) {
    console.warn(
      '[policyTranslator.hasAccess] Migrate to using dedicated $acl service to check access.'
    );

    return this.$acl(...args);
  }
}
