'use strict';

import angular from 'angular';
import { find, filter, maxBy, forEach, remove } from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { State, Getter } from 'angular-store';

@Controller
class ClientCasePlanController {
  // client: Client = null;

  @Inject $scope;
  @Inject $store;
  @Inject $auth;
  @Inject $api;
  @Inject $q;
  @Inject $generatePdf;
  @Inject $modals;
  @Inject CasePlan;
  @Inject getItems;
  @Inject notify;
  @Inject reportUtils;
  @Inject Util;

  @State me;
  @State(({ tools }) => tools.items) tools;

  @Getter('evaluations/getByClientId') getClientEvals;

  $onInit() {
    // console.log('this.$scope: ', this.$scope);
    if (!this.$auth.hasAccess('clientmanager:ListClientCasePlans')) {
      console.error(
        '[client case plan directive]: Does not have access to list client case plans'
      );
      return;
    }

    this.client = this.$scope.vm.client;
    if (this.client) this.loadClientCasePlan(this.client);

    this.$scope.$watch(
      () => this.client,
      (val) => {
        if (this.client) this.loadClientCasePlan(this.client);
      }
    );

    // setTimeout(() => {
    //   console.log('SCOPE!', this.$scope);
    // }, 5000);

    if (this.$scope.vm.generate)
      this.generateNewCasePlan({ id: this.$scope.vm.generate });
    this.$scope.$on('generate-new-case-plan', (message, evaluation) => {
      this.generateNewCasePlan({ id: evaluation.id });
    });
  }

  async loadCasePlan(caseplan) {
    caseplan.loading = true;
    if (!caseplan.plan) {
      let fullPlan = await this.getItems.getCasePlan(this.client, caseplan);
      if (fullPlan.plan) caseplan.plan = fullPlan.plan;
    }
    if (!caseplan.plan) {
      console.error(
        '[client-case-plan directive:loadCasePlan] could not find plan'
      );
      return caseplan;
    }
    caseplan.plan = this.CasePlan.ClientCasePlan(caseplan.plan);
    caseplan.loading = false;
    caseplan.loaded = true;

    return caseplan;
  }

  async loadClientCasePlan(client = {}) {
    this.clientCasePlan = null;
    this.casePlanHistory = {
      plans: [],
      collapsed: true
    };

    let casePlanHistory = await this.getItems.clientCasePlans(client);

    if (find(casePlanHistory, { status: 'CURRENT' })) {
      this.clientCasePlan = find(casePlanHistory, { status: 'CURRENT' });
      remove(casePlanHistory, { status: 'CURRENT' });
      this.clientCasePlan.loading = true;

      this.clientCasePlan = await this.loadCasePlan(this.clientCasePlan);

      this.activeSection = this.clientCasePlan.plan.sections[0];
      this.clientCasePlan.loading = false;
      this.clientCasePlan.loaded = true;

      let casePlanTemplate;

      if (this.clientCasePlan.institutionCasePlanTemplateId) {
        // created with an institution cpt
        casePlanTemplate = await this.getItems.casePlanTemplateCommit(
          this.client.institutionId,
          this.clientCasePlan.institutionCasePlanTemplateId,
          this.clientCasePlan.institutionCpTemplateCommitId
        );

        // if (casePlanTemplate.toolCommits.length) {
        //   casePlanTemplate.toolId = casePlanTemplate.toolCommits[0].toolId;
        // }

        this.clientCasePlan.institutionCasePlanTemplate = casePlanTemplate;
      } else if (this.clientCasePlan.managedCasePlanTemplateId) {
        casePlanTemplate = await this.getItems.managedCasePlanTemplateCommit(
          this.clientCasePlan.managedCpTemplateCommitId,
          this.clientCasePlan.managedCasePlanTemplateId
        );

        // if (casePlanTemplate.toolCommits.length) {
        //   casePlanTemplate.toolId = casePlanTemplate.toolCommits[0].toolId;
        // }

        this.clientCasePlan.managedCasePlanTemplate = casePlanTemplate;
      }

      if (!casePlanTemplate) return;

      this.casePlanTemplate = casePlanTemplate.data;
      this.casePlanTemplateInfo = angular.copy(casePlanTemplate);

      delete this.casePlanTemplateInfo.data;
    }

    if (casePlanHistory) {
      this.casePlanHistory = {
        plans: casePlanHistory,
        collapsed: true
      };
    }
  }

  async generateNewCasePlan(evaluation) {
    let selectedEvaluation = evaluation;
    if (!evaluation) {
      // console.log('client case plan directive: generate new case plan');
      let tools = filter(this.tools, (tool) => {
        return tool?.managedCasePlanTemplates?.length;
      });

      let filters = {
        status: 'COMPLETED',
        toolHasCasePlan: {
          tools
        }
      };

      this.client.evaluations = this.getClientEvals(this.client.id);

      selectedEvaluation = await this.$modals.util.selectEvaluation(
        this.client.evaluations,
        filters
      );
    }

    if (!selectedEvaluation) return;

    this.generatingCasePlan = true;
    selectedEvaluation = await this.getItems.evaluation(
      selectedEvaluation.id,
      this.client
    );

    if (!selectedEvaluation) {
      this.notify.display('Could not retrieve evaluation', 'error');
      return;
    }

    // retrieve case management template
    let toolId = selectedEvaluation.toolUsed;
    if (!toolId) toolId = selectedEvaluation.tool?.id;
    if (!toolId) toolId = selectedEvaluation.evaluationData?.toolUsed;
    // if (!toolId && typeof selectedEvaluation?.data?.toolUsed === 'number')
    //   toolId = selectedEvaluation.data
    //     ? selectedEvaluation.data.toolUsed
    //     : null;
    // if (!toolId && typeof selectedEvaluation?.evaluation?.toolUsed === 'number')
    //   toolId = selectedEvaluation.evaluation
    //     ? selectedEvaluation.evaluation.toolUsed
    //     : null;
    if (!toolId) {
      this.notify.display('Could not detect tool used for evaluation', 'error');
      return;
    }

    let tool = find(this.tools, { id: parseInt(toolId, 10) });
    if (!tool) {
      this.notify.display(
        'Tool used in evaluation is not listed in available tools',
        'error'
      );
      return;
    }

    let casePlanTemplate = await this.getItems.getCasePlanTemplate(tool);
    if (!casePlanTemplate) return;
    this.casePlanTemplate = casePlanTemplate.data;
    this.casePlanTemplateInfo = angular.copy(casePlanTemplate);
    Reflect.deleteProperty(this.casePlanTemplateInfo, 'data');
    let currentCasePlan = this.clientCasePlan
      ? angular.copy(this.clientCasePlan)
      : null;

    // generate case plan based off of evaluation and case plan template
    let casePlan = await this.CasePlan.GenerateCasePlan(
      casePlanTemplate.data,
      selectedEvaluation,
      this.client,
      this.casePlanTemplateInfo
    );
    this.clientCasePlan = { plan: casePlan, id: casePlan.id };
    // add case plan template info
    if (casePlanTemplate.institutionCasePlanTemplateId) {
      this.clientCasePlan.institutionCasePlanTemplate = {
        toolId,
        id: casePlanTemplate.institutionCasePlanTemplateId,
        commitId: casePlanTemplate.institutionCpTemplateCommitId
      };
    } else if (casePlanTemplate.managedCasePlanTemplateId) {
      this.clientCasePlan.managedCasePlanTemplate = {
        toolId,
        id: casePlanTemplate.managedCasePlanTemplateId,
        commitId: casePlanTemplate.managedCpTemplateCommitId
      };
    }
    this.activeSection = this.clientCasePlan.plan.sections[0];
    let statusUpdate = await this.CasePlan.MakeCasePlanCurrent(
      angular.copy(this.clientCasePlan),
      this.client.institutionId,
      this.client.id,
      currentCasePlan,
      this.client?.subGroup?.id
    );
    this.clientCasePlan.loaded = true;
    this.reportUtils.evalToGenCasePlan = null;
    this.generatingCasePlan = false;
    this.$scope.$apply();
  }

  async submitCasePlan() {
    this.savingCasePlan = true;

    let response = await this.CasePlan.SaveCasePlan(
      angular.copy(this.clientCasePlan),
      this.client.institutionId,
      this.client.id,
      this.casePlanTemplateInfo,
      this.client?.subGroup?.id
    );
    if (!this.clientCasePlan.id && response?.id)
      this.clientCasePlan.id = response.id;

    if (
      !this.clientCasePlan.status ||
      this.clientCasePlan.status !== 'CURRENT'
    ) {
      let statusUpdate = await this.CasePlan.MakeCasePlanCurrent(
        angular.copy(this.clientCasePlan.plan),
        this.client.institutionId,
        this.client.id,
        undefined,
        this.client?.subGroup?.id
      );
    }

    this.savingCasePlan = false;
  }

  async generateCasePlanReport(caseplan) {
    // parse caseplan to find tool id used
    this.generatingCasePlanReport = true;
    let toolId;
    if (caseplan.institutionCasePlanTemplate) {
      toolId = caseplan.institutionCasePlanTemplate.toolId;
      if (!toolId && caseplan.institutionCasePlanTemplate.toolCommits?.length)
        toolId = caseplan.institutionCasePlanTemplate.toolCommits[0].toolId;
    } else if (caseplan.managedCasePlanTemplate) {
      toolId = caseplan.managedCasePlanTemplate.toolId;
      if (!toolId && caseplan.managedCasePlanTemplate.toolCommits?.length)
        toolId = caseplan.managedCasePlanTemplate.toolCommits[0].toolId;
    }
    if (!toolId) {
      console.error('[ClientCasePlan:GenerateCasePlanReport: No toolId Found]');
      this.generatingCasePlanReport = false;
    }
    let client = angular.copy(this.client);
    let offenderHistory;
    switch (toolId) {
      case 120: // YLS/CMI
        offenderHistory = filter(this.client.offenderHistory, {
          toolId: 120
        });
        offenderHistory = maxBy(offenderHistory, 'createdAt');
        offenderHistory = await this.getItems.offenderHistoryData(
          this.client,
          offenderHistory
        );
        let clientNormativeField;
        forEach(offenderHistory.sections, (section) => {
          forEach(section.sections, (sect) => {
            forEach(sect.fields, (field) => {
              if (field.key === 'clientNormativeType')
                clientNormativeField = field;
            });
          });
        });
        client.clientNormativeType = clientNormativeField
          ? clientNormativeField.model
          : null;
        this.$generatePdf.generateYLSCaseManagementReport(
          client,
          caseplan,
          (res) => {
            this.generatingCasePlanReport = false;
          }
        );
        break;
      case 124: // LS/CMI
        offenderHistory = filter(this.client.offenderHistory, {
          toolId: 124
        });
        offenderHistory = maxBy(offenderHistory, 'createdAt');
        offenderHistory = await this.getItems.offenderHistoryData(
          this.client,
          offenderHistory
        );
        let reasonForAssessment;
        let clientSetting;
        let offenderType;
        let assessmentContext;
        let institutionalOffender;
        forEach(offenderHistory.sections, (section) => {
          if (section.key === 'reasonForAssessmentReassessment') {
            forEach(section.sections, (section) => {
              if (section.key === 'setting' && section.model) {
                if (section.subOption)
                  offenderType = this.Util.decamlize(section.subOption);
                assessmentContext = section.subOption
                  ? this.Util.decamlize(section.subOption)
                  : 'N/A';
                reasonForAssessment = section.subOption
                  ? `${section.model.label}: ${section.subOption}`
                  : `${section.model.label}`;
                if (section.model.label.includes('Institutional')) {
                  institutionalOffender = true;
                  clientSetting = 'institutional';
                } else if (section.model.label.includes('Community')) {
                  institutionalOffender = false;
                  clientSetting = 'community';
                }
              }
            });
          }
        });
        client.reasonForAssessment = reasonForAssessment
          ? reasonForAssessment
          : 'N/A';
        this.$generatePdf.generateLSCMICaseManagementReport(
          client,
          caseplan,
          (res) => {
            this.generatingCasePlanReport = false;
          }
        );
        break;
    }
  }

  log(item) {
    console.log(item);
  }
}

export default angular
  .module('app.clientCasePlan', [])
  .directive('clientCasePlan', function () {
    return {
      template: require('./client-case-plan.html'),
      restrict: 'EA',
      scope: { client: '=', test: '=', generate: '=' },
      controller: ClientCasePlanController,
      controllerAs: 'vm',
      bindToController: true
    };
  }).name;
