import angular from 'angular';

import { Controller } from '@decorators/ngCtrl';
import Modal from '@mixins/modal.mixin';

@Controller
class FileViewerModalController extends Modal {}

export default angular
  .module('app.fileViewerModal', [])
  .directive('fileViewerModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./file-viewer-modal.html'),
    controller: FileViewerModalController,
    controllerAs: 'vm'
  })).name;
