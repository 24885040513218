import { gears } from 'angular';

// import { MutationTree } from '@modules/angular-store/types';

import { RootState } from './state';

/**
 * ...
 */
export interface SetThemeMutationOptions {
  theme: 'light' | 'dark';
}

/**
 * ...
 */
export interface RootMutations {
  APP_VERSION: (version: string) => void;
  IS_LOADING_DATA: (value: boolean) => void;
  LOADING_PROGRESS: (progress: number) => void;
  IS_AUTHENTICATING: (payload: boolean) => void;
  SET_LOADING_MESSAGE: (message?: string) => void;
  SET_IS_LOADING: (value: boolean) => void;
  SET_PROHIBIT_INPUT: (value: boolean) => void;
  BROWSER_FLAGS: () => void;
  ON_MAIN: (value: boolean) => void;
  ON_DASHBOARD: (value: boolean) => void;
  ON_TOOL_CREATOR: (value: boolean) => void;
  SIDE_NAV_COLLAPSED: (value: boolean) => void;
  REVERSE_TABLE_SORT: (value: boolean) => void;
  SET_LOGS: (logs?: unknown[]) => void;
  SET_THEME: (options: SetThemeMutationOptions) => void;
  SET_SHOW_ID_IN_ACTIONS: (value: boolean) => void;
}

/**
 * ...
 */
type RootMutationsTree = {
  [P in keyof RootMutations]: (
    state: RootState,
    payload: Parameters<RootMutations[P]>[0]
  ) => ReturnType<RootMutations[P]>;
};

declare module 'angular-store' {
  export interface Store<S> {
    commit<T extends keyof RootMutations>(
      type: T,
      payload: Parameters<RootMutations[T]>[0]
    ): void;
  }
}

/**
 * ...
 */
export function MutationsConfig($ls: gears.ILsService) {
  'ngInject';

  const mutations: RootMutationsTree = {
    /**
     * ...
     */
    APP_VERSION(state, val) {
      state.version = val || 'X.X.XX';
    },
    /**
     * ...
     */
    IS_LOADING_DATA(state, val) {
      state.loadingData = !!val;
    },
    /**
     * ...
     */
    LOADING_PROGRESS(state, val) {
      state.loadingProgress = val;
    },
    /**
     * ...
     */
    IS_AUTHENTICATING(state, val) {
      state.authenticating = !!val;
    },
    /**
     * ...
     */
    SET_LOADING_MESSAGE(state, val) {
      state.isLoadingMessage = val ? val : '';
    },
    /**
     * ...
     */
    SET_IS_LOADING(state, val) {
      state.isLoading = !!val;
    },
    /**
     * ...
     */
    SET_PROHIBIT_INPUT(state, val) {
      state.prohibitInput = !!val;
    },
    /**
     * ...
     */
    BROWSER_FLAGS(state) {
      // Opera 8.0+
      state.isOpera =
        (!!window.opr && !!opr.addons) ||
        !!window.opera ||
        navigator.userAgent.indexOf(' OPR/') >= 0;

      // Firefox 1.0+
      state.isFirefox = typeof InstallTrigger !== 'undefined';

      // Safari 3.0+ "[object HTMLElementConstructor]"
      state.isSafari =
        /constructor/i.test(window.HTMLElement) ||
        ((p) => p.toString() === '[object SafariRemoteNotification]')(
          !window['safari'] || safari.pushNotification
        );

      // Internet Explorer 6-11
      state.isIE = /*@cc_on!@*/ false || !!document.documentMode;

      // Edge 20+
      state.isEdge = !state.isIE && !!window.StyleMedia;

      // Chrome 1+
      state.isChrome = !!window.chrome && !!window.chrome.webstore;

      // Blink engine detection
      state.isBlink = (state.isChrome || state.isOpera) && !!window.CSS;
    },
    /**
     * ...
     */
    ON_MAIN(state, payload) {
      state.onMain = payload;
    },
    /**
     * ...
     */
    ON_DASHBOARD(state, payload) {
      state.onDashboard = payload;
    },
    /**
     * ...
     */
    ON_TOOL_CREATOR(state, payload) {
      state.onToolCreator = payload;
    },
    /**
     * ...
     */
    SIDE_NAV_COLLAPSED(state, payload) {
      state.sideNavCollapsed = !!payload;

      $ls.set('sideNavCollapsed', state.sideNavCollapsed);
    },
    /**
     * ...
     */
    REVERSE_TABLE_SORT(state, reverse) {
      state.sortReversed = !!reverse;
    },
    /**
     * ...
     */
    SET_LOGS(state, payload) {
      state.logs = payload || [];
    },
    /**
     * ...
     */
    SET_THEME(state, { theme }) {
      state.theme = theme;

      $ls.set('appTheme', state.theme);
    },
    /**
     * ...
     */
    SET_SHOW_ID_IN_ACTIONS(state, val) {
      state.showIdInActions = val || false;
    }
  };

  // TEMP: remove once all references to them have been removed.
  Object.defineProperties(mutations, {
    appVersion: { enumerable: true, value: mutations.APP_VERSION },
    isLoadingData: { enumerable: true, value: mutations.IS_LOADING_DATA },
    loadingProgress: { enumerable: true, value: mutations.LOADING_PROGRESS },
    isAuthenticating: { enumerable: true, value: mutations.IS_AUTHENTICATING },
    setLoadingMessage: {
      enumerable: true,
      value: mutations.SET_LOADING_MESSAGE
    },
    setIsLoading: { enumerable: true, value: mutations.SET_IS_LOADING },
    setProhibitInput: { enumerable: true, value: mutations.SET_PROHIBIT_INPUT },
    browserFlags: { enumerable: true, value: mutations.BROWSER_FLAGS },
    onMain: { enumerable: true, value: mutations.ON_MAIN },
    onDashboard: { enumerable: true, value: mutations.ON_DASHBOARD },
    onToolCreator: { enumerable: true, value: mutations.ON_TOOL_CREATOR },
    sideNavCollapsed: { enumerable: true, value: mutations.SIDE_NAV_COLLAPSED },
    reverseTableSort: { enumerable: true, value: mutations.REVERSE_TABLE_SORT },
    setLogs: { enumerable: true, value: mutations.SET_LOGS }
  });

  return mutations;
}
