'use strict';

// import $ from 'jquery';
import * as _ from 'lodash';

const itemsPerPageOptions = [
  {
    name: '10',
    id: 10
  },
  {
    name: '25',
    id: 25
  },
  {
    name: '50',
    id: 50
  },
  {
    name: '100',
    id: 100
  }
];

export default class TableViewController {
  table;
  sortingBy = '';
  sortingReversed = false;
  filterText = '';
  noFilterResults = false;

  loading = false;
  loadFailed = false;

  constructor(
    $rootScope,
    $scope,
    $element,
    $modals,
    Util,
    TableViewDataTypes
  ) {
    if (!$scope.table) {
      throw new Error(
        'No table data object was passed to this table view component instance.'
      );
    }

    this._rootScope = $rootScope;
    this._scope = $scope;
    this._$modals = $modals;
    this._Util = Util;
    this._TableViewDataTypes = TableViewDataTypes;
    this.table = $scope.table;

    if (this.table.sortingBy) this.sortBy(this.table.sortingBy);

    this.itemsPerPage = this.table.itemsPerPage || itemsPerPageOptions[1].id;

    if (!this.table.itemCount) {
      this.loadData();
    }
  }

  get itemsPerPageOptions() {
    return this.itemsPerPageOptions;
  }

  get itemsPerPage() {
    return (this.table || {}).itemsPerPage;
  }

  set itemsPerPage(num) {
    if (this.table) {
      this.table.itemsPerPage = num;
      this.table.createPages();
    }
  }

  toPage(num) {
    // this.tableBodyElem.scrollTop(0);
    this.table.currentPage = num;
  }

  toFirstPage() {
    this.toPage(1);
  }

  toLastPage() {
    this.toPage(this.table.pageCount);
  }

  nextPage() {
    if (this.table.currentPage < this.table.pageCount) {
      this.toPage(this.table.currentPage + 1);
    }
  }

  prevPage() {
    if (this.table.currentPage > 1) {
      this.toPage(this.table.currentPage - 1);
    }
  }

  async loadData() {
    this.loading = true;
    this.loadFailed = false;

    try {
      await Promise.all([
        this.table.load(),
        new Promise(resolve => setTimeout(resolve, 1000))
      ]);

      this.loading = false;

      this._rootScope.$apply();
    } catch (err) {
      console.error(err);

      this.loadFailed = true;
      this.loading = false;

      this._rootScope.$apply();
    }
  }

  sortBy(key) {
    if (this.sortingBy == key) {
      this.sortingReversed = !this.sortingReversed;
      this.table.items.reverse();
    } else {
      if (this.sortingReversed) {
        this.table.items.reverse();
        this.sortingReversed = false;
      }

      this.sortingBy = key;

      // console.log('this.sortingBy', this.sortingBy);

      this.table.items.sort((a, b) => {
        let valA = a.item[key];
        let valB = b.item[key];

        if (valA > valB) {
          return -1;
        }
        if (valA < valB) {
          return 1;
        }
        return 0;
      });
    }

    this.table.createPages();

    // this.tableBodyElem.scrollTop(0);
  }

  filter() {
    this.noFilterResults = true;

    this.table.items.forEach(item => {
      let show = false;

      if (!this.filterText || this.filterText == '') {
        this.noFilterResults = false;
        show = true;
      } else {
        _.forEach(item.item, attr => {
          if (
            (typeof attr == 'string' || typeof attr == 'number') &&
            attr.toString().includes(this.filterText)
          ) {
            this.noFilterResults = false;
            show = true;
            return;
          }
        });
      }

      item.show = show;
    });

    this.table.createPages();
  }

  formatValue(item) {
    if (!item.value) {
      return 'No Value';
    }

    let value;

    switch (item.type) {
      case 'boolean':
        value = item.value == true ? 'Yes' : 'No';

        break;
      case 'number':
        value = item.value.toLocaleString('en', {
          style: 'decimal',
          useGrouping: true
          // maximumFractionDigits: 2
        });

        break;
      case 'date':
        value = new Date(item.value).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit'
          // second: 2-digit
        });

        break;
      default:
        value = item.value;

        break;
    }

    return value;
  }
}
