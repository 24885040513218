import angular from 'angular';

export interface ToolJsonConstructorOptions {
  id: string;
  commitId: string;
  name: string;
  tabName: string;
  flyoutName: string;
  riskCategories: unknown;
  customRiskCategories: unknown;
  subRiskCategories: unknown;
  hideBarGraph: unknown;
  childTools: unknown;
  prorate: unknown;
  rules: unknown;
  codingFormItems: unknown;
  dictionary: unknown;
  agreement: unknown;
  offenderHistory: unknown;
  pdfCommitId: string;
  pdfDescriptionId: string;
  showCommentsBox: unknown;
  sourcesOfInformation: unknown;
}

class ToolJson {
  id: string;
  commitId: string;
  name: string;
  tabName: string;
  flyoutName: string;
  riskCategories: unknown;
  customRiskCategories: unknown;
  subRiskCategories: unknown;
  hideBarGraph: unknown;
  childTools: unknown;
  prorate: unknown;
  rules: unknown;
  codingFormItems: unknown;
  dictionary: unknown;
  agreement: unknown;
  offenderHistory: unknown;
  pdfCommitId: string;
  pdfDescriptionId: string;
  showCommentsBox: unknown;
  sourcesOfInformation: unknown;

  constructor(data: ToolJsonConstructorOptions) {
    this.id = data.id.toString();
    this.commitId = data.commitId;
    this.name = data.name;
    this.tabName = data.tabName;
    this.flyoutName = data.flyoutName;
    this.riskCategories = data.riskCategories;
    this.customRiskCategories = data.customRiskCategories;
    this.subRiskCategories = data.subRiskCategories;
    this.hideBarGraph = data.hideBarGraph;
    this.childTools = data.childTools;
    this.prorate = data.prorate;
    this.rules = data.rules;
    this.codingFormItems = data.codingFormItems;
    this.dictionary = data.dictionary;
    this.agreement = data.agreement;
    this.offenderHistory = data.offenderHistory;
    this.pdfCommitId = data.pdfCommitId;
    this.pdfDescriptionId = data.pdfDescriptionId;
    this.showCommentsBox = data.showCommentsBox;
    this.sourcesOfInformation = data.sourcesOfInformation;
  }
}

/**
 * ...
 */
export class GetToolJsonService {
  constructor(public readonly $api2: angular.gears.IAPI2Service) {
    'ngInject';
  }

  /**
   * ...
   *
   * @param commit ...
   * @return ...
   */
  async toolJson(commit: { id: string; toolId: string }) {
    const options = {
      toolId: commit.toolId,
      commitId: commit.id
    };

    // let { data } = await this.$api.toolCreator.getToolCommit(options);
    //
    // if (data.error) {
    //   throw data.error;
    // }

    const { data } = await this.$api2.tc.getToolCommit(options);

    data.toolData.commitId = commit.id;

    return new ToolJson(data.toolData);
  }
}
