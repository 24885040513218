'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class TutorialVideoModalController extends Modal {
  role: any;
  videoLink: any;

  @Inject $scope;
  @Inject $sce;

  $setup() {
    this.role = this.$props;
    this.videoLink = '';
    switch (this.role.roleId) {
      case 3:
        this.videoLink = this.$sce.trustAsResourceUrl(
          'https://player.vimeo.com/video/229036689'
        );
        break;
      case 2:
        this.videoLink = this.$sce.trustAsResourceUrl(
          'https://player.vimeo.com/video/225519675'
        );
        break;
      case 1:
        this.videoLink = this.$sce.trustAsResourceUrl(
          'https://player.vimeo.com/video/225516622'
        );
    }
  }
}

export default angular
  .module('app.tutorialVideoModal', [])
  .directive('tutorialVideoModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./tutorial-video.html'),
    controller: TutorialVideoModalController,
    controllerAs: 'vm'
  })).name;
