'use strict';

import angular from 'angular';
import _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class CommitsViewerModalController extends Modal {
  this: any;
  tool: any;
  referrer: any;
  tagNumber: any;
  statusUpdate: {};
  processing: boolean;
  statusOptions: any;

  @Inject $scope;
  @Inject getItems;
  @Inject $modals;
  @Inject $http;
  @Inject notify;

  $setup() {
    this.tool = this.$props.tool;

    this.referrer = this.$props.referrer;
    this.tagNumber = '';
    this.statusUpdate = null;
    this.processing = false;
    this.statusOptions = ['Saved', 'Testing', 'Live'];

    this.initialize();
  }

  async initialize() {
    this.processing = true;

    if (this.referrer === 'template-commits') {
      let commits = await this.getItems.templateCommits(this.tool);
      this.processing = false;
      this.commits = commits ? commits : null;
    } else {
      // let commits = await this.getItems.toolCommits(this.tool);
      let commits = await this.getItems.toolCommits(this.tool);
      // commits = commits.data || null;

      this.commits = commits ? commits : null;

      let template = await this.getItems.toolTemplate(this.tool);
      if (template) {
        let templates = await this.getItems.templateCommits(template);
        if (templates) {
          _.forEach(templates, function (temp) {
            if (temp.hasOwnProperty('toolCommits') && temp.toolCommits.length) {
              for (let i = 0; i < temp.toolCommits.length; i++) {
                let commit = _.find(commits, { id: temp.toolCommits[i].id });
                if (!commit.hasOwnProperty('templates')) commit.templates = [];
                commit.templates.push(temp);
              }
            }
          });
          this.templates = templates ? templates : [];
        }
      }

      // grab the master case plan template
      this.masterCasePlanTemplate =
        await this.getItems.toolManagedCasePlanTemplate(this.tool);

      if (this.masterCasePlanTemplate) {
        // grab the master case plan template commits and apply them as options to tool commits list
        this.masterCasePlanTemplateCommits =
          await this.getItems.managedCasePlanTemplateCommits(
            this.masterCasePlanTemplate
          );
        if (this.masterCasePlanTemplateCommits) {
          _.forEach(this.masterCasePlanTemplateCommits, (mcpc) => {
            mcpc.casePlanTemplate = true;

            if (mcpc.hasOwnProperty('toolCommits') && mcpc.toolCommits.length) {
              _.forEach(mcpc.toolCommits, (toolCommit) => {
                let foundCommit = _.find(commits, { id: toolCommit.id });
                if (!foundCommit.hasOwnProperty('casePlanTemplates'))
                  foundCommit.casePlanTemplates = [];
                foundCommit.casePlanTemplates.push(mcpc);
              });
            }
            // match tool commit id and add to the commit's casePlanTemplates: []
          });
        }
      }

      let codingForms = await this.getItems.codingForms(this.tool);
      this.codingForms = codingForms ? codingForms : [];
      this.processing = false;
      this.$scope.$apply();
    }
  }

  toggleCommitDetails(commit) {
    _.forEach(this.commits, function (com) {
      com.showTemplates = false;
      if (com.details && com.id === commit.id) {
        com.details = false;
      } else {
        com.details = com.id === commit.id;
      }
    });
  }

  showTemplates(commit) {
    console.log(commit);
  }

  edit(commit, reportTemplate) {
    console.log('edit commit: ', commit);
    this.$close(commit);
  }

  checkForPreviousTemplates(commit) {
    let commits = _.cloneDeep(this.commits);
    _.forEach(commits, function (comm) {
      comm.createdAt = new Date(comm.createdAt);
    });
    commits = _.orderBy(this.commits, 'createdAt', 'desc');
    let foundPreviousTemplate = false;
    _.forEach(commits, function (comm) {
      if (comm.hasOwnProperty('templates') && comm.templates.length) {
        foundPreviousTemplate = true;
      }
    });
    this.foundPreviousTemplate = foundPreviousTemplate;
    this.previousTemplates = commits;
    this.commitForNewTemplate = commit;
    return foundPreviousTemplate;
  }

  previousTemplateChosen(template) {
    this.processing = true;
    this.commitForNewTemplate.newReportTemplate = true;
    if (template) {
      this.commitForNewTemplate.oldReportTemplate = template;
    }
    this.$close(this.commitForNewTemplate);
  }

  newReportTemplate(commit) {
    if (!this.checkForPreviousTemplates(commit)) {
      commit.newReportTemplate = true;
      this.$close(commit);
    }
  }

  manageCasePlan(commit, casePlan) {
    let tool = angular.copy(this.tool);
    tool.commitId = commit.id;
    this.$close({
      casePlan: true,
      commit, // tool commit
      editCasePlan: casePlan ? casePlan : null, // existing case plan
      masterCasePlanTemplate: this.masterCasePlanTemplate, // master case plan exists for tool
      tool
    });
  }

  async copyCasePlanTemplate(commit) {
    let chosenCasePlanTemplate = await this.$modals.util.generalChoice(
      this.masterCasePlanTemplateCommits,
      'commits',
      'CHOOSE A TEMPLATE'
    );

    if (!chosenCasePlanTemplate) {
      this.notify.display('You must choose a template to copy', 'error');
      return;
    }

    // get previous tool commit id
    let previousToolCommitId = chosenCasePlanTemplate.toolCommits[0].id;
    // grab template data
    let templateData = await this.getItems.managedCasePlanTemplateCommit(
      chosenCasePlanTemplate.id,
      chosenCasePlanTemplate.templateId
    );
    if (!templateData) {
      this.notify.display('Could not retrieve template data', 'error');
      return;
    }
    if (templateData.hasOwnProperty('templateData'))
      templateData = templateData.templateData;
    let payload = {
      message: `Copying master case plan template from ${previousToolCommitId} to ${commit.id}`,
      templateData,
      toolCommitId: commit.id,
      previousCommitId: chosenCasePlanTemplate.id
    };
    this.$http
      .post(
        `/api/mcpt/managed-case-plan-templates/${this.masterCasePlanTemplate.id}/commit`,
        payload
      )
      .then((res) => {
        if (res.status === 200) {
          if (res.data && res.data.newCommit) {
            if (!commit.hasOwnProperty('casePlanTemplates'))
              commit.casePlanTemplates = [];
            commit.casePlanTemplates.push(res.data.newCommit);
          }
          this.notify.display(
            'Successfully copied Master Case Plan Template to chosen Tool Commit',
            'success'
          );
        } else {
          this.notify.display(res, 'error');
        }
      })
      .catch((err) => {
        this.notify.display(err, 'error');
      });
  }

  generateCodingForm(commit) {
    this.getItems
      .toolCommitFile(commit)
      .then((file) => {
        if (file) {
          let codingForm = {
            question: {},
            scores: {},
            bottomTable: []
          };
          _.forEach(file.codingFormItems, (cfi) => {
            codingForm.question[cfi.id] = cfi.riskFactor;
            codingForm.scores[cfi.id] = {};
            _.forEach(cfi.codesAndScore, (cas) => {
              codingForm.scores[cfi.id][cas.score] = cas.text;
            });
            codingForm.scores[cfi.id]['NOT ANSWERED'] = 'N/A';
          });
          this.$http
            .post(`/api/tool-creator/tools/${this.tool.id}/coding-forms`, {
              toolId: this.tool.id,
              name: `${this.tool.name} Coding Form`,
              codingForm
            })
            .then((res) => {
              if (res.status !== 200 && res.status !== 204) {
                this.notify.display(res, 'error');
              } else {
                this.setCodingFormToCommit({ id: res.data.data }, commit, true);
              }
            })
            .catch((err) => {
              this.notify.error(err);
            });
        } else {
          this.notify.error('Error retrieving tool commit file');
        }
      })
      .catch((err) => {
        this.notify.error(err);
      });
  }

  createCodingForm(commit) {
    this.showExistingCodingForms = true;
    this.commitForCodingForm = commit;
  }

  setCodingFormToCommit(codingForm, commit, setLive) {
    this.$http
      .post(
        `/api/tool-creator/tools/${this.tool.id}/commits/${commit.id}/coding-forms`,
        {
          codingFormId: codingForm.id
        }
      )
      .then((res) => {
        if (res.status !== 200 && res.status !== 204) {
          this.notify.display(res, 'error');
        } else {
          if (!setLive) {
            this.showExistingCodingForms = false;
            this.notify.display('Coding Form Created', 'success');
            this.initialize();
          } else if (setLive) {
            this.$http
              .post(
                `/api/tool-creator/tools/${this.tool.id}/commits/${commit.id}/set-live-coding-form`,
                {
                  codingFormId: codingForm.id
                }
              )
              .then((liveRes) => {
                if (liveRes.status === 200 || liveRes.status === 204) {
                  this.notify.display('Coding Form Set To Live', 'success');
                  this.showExistingCodingForms = false;
                  this.initialize();
                } else {
                  this.notify.display(liveRes, 'error');
                }
              })
              .catch((liveResErr) => {
                this.notify.error(liveResErr);
              });
          }
        }
      })
      .catch((err) => {
        this.notify.error(err);
      });
  }

  viewCodingForm(commit) {
    console.log(commit);
  }

  showTagField(commit) {
    this.tagInputField = true;
    this.commitToTag = commit;
    this.commitToTagName = commit.id;
    this.commitToTagName += commit.templateId
      ? ' | Report Template'
      : ' | Tool';
  }

  showStatusField(commit) {
    this.statusField = true;
    this.commitToTag = commit;
    this.commitToTagName = commit.id;
    this.commitToTagName += commit.templateId
      ? ' | Report Template'
      : ' | Tool';
  }

  submitStatus() {
    this.processing = true;

    let url = '';
    if (this.commitToTag.templateId) {
      // url = `/api/template-commits/${this.commitToTag.id}/status`;
      url = `/api/tool-creator/report-templates/${this.commitToTag.templateId}/commits/${this.commitToTag.id}/status`;
    } else if (this.commitToTag.casePlanTemplate) {
      // url = `/api/managed-cp-template-commits/${this.commitToTag.id}/status`;
      url = `/api/mcpt/managed-case-plan-template/${this.commitToTag.casePlanTemplate.id}/${this.commitToTag.id}/status`;
    } else {
      // url = `/api/tool-commits/${this.commitToTag.id}/status`;
      url = `/api/tool-creator/tools/${this.tool.id}/commits/${this.commitToTag.id}/status`;
    }
    this.$http
      .post(url, {
        status: this.statusUpdate,
        toolId: this.tool.id
      })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          this.notify.display('Status Successfully Updated', 'success');
          this.statusField = false;
          this.commitToTag.status = this.statusUpdate;
          this.commitToTag = null;
          this.statusUpdate = null;
          this.initialize();
        } else {
          this.notify.display(response, 'error');
        }
      })
      .catch((err) => {
        this.notify.error(err);
      });
  }

  tag() {
    this.processing = true;

    let url = '';
    if (this.commitToTag.templateId) {
      // url = `/api/template-commits/${this.commitToTag.id}/tag`;
      url = `/api/tool-creator/report-templates/${this.commitToTag.templateId}/commits/${this.commitToTag.id}/tag`;
    } else if (this.commitToTag.casePlanTemplate) {
      // url = `/api/managed-cp-template-commits/${this.commitToTag.id}/tag`;
      url = `/api/mcpt/managed-case-plan-template/${this.commitToTag.casePlanTemplate.id}/${this.commitToTag.id}/tag`;
    } else {
      // url = `/api/tool-commits/${this.commitToTag.id}/tag`;
      url = `/api/tool-creator/tools/${this.tool.id}/commits/${this.commitToTag.id}/tag`;
    }
    this.$http
      .post(url, {
        version: this.tagNumber
      })
      .then((response) => {
        if (response.status === 200 || response.status === 204) {
          this.notify.display('Tag Successfully Updated', 'success');
          this.tagInputField = false;
          this.commitToTag.version = this.tagNumber;
          this.commitToTag = null;
          this.tagNumber = null;
          this.initialize();
        } else {
          this.notify.display(response, 'error');
        }
      })
      .catch((err) => {
        this.notify.error(err);
      });
  }

  test(commit) {
    commit = _.cloneDeep(commit);
    commit.toolCommitId = commit.id;
    commit.toolId = this.tool.id;
    commit.test = true;

    this.$close(commit);
  }

  pdf(commit) {
    this.$modals.tool.uploadTool(null, commit, true, () => this.initialize());
  }
}

export default angular
  .module('app.commitsViewerModal', [])
  .directive('commitsViewerModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./commits-viewer.html'),
    controller: CommitsViewerModalController,
    controllerAs: 'vm'
  })).name;
