'use strict';

import angular from 'angular';

import { Getter } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class InstitutionCustomizationModalController extends Modal {
  @Inject $scope;
  @Inject $store;
  @Inject notify;
  @Getter isDev;

  loading = false;

  $setup() {
    this.toolId = this.$props.toolId;
    this.toolCommitId = this.$props.toolCommitId;
    this.customization = this.$props.customization;

    this.questionsOptions = ['OPTIONAL', 'REQUIRED', 'DISABLED'];
    this.commentsOptions = ['OPTIONAL', 'REQUIRED', 'DISABLED'];

    if (!this.toolId) {
      this.notify.display('Tool ID Not Provided', 'error');
      this.$dismiss();
    }

    if (!this.toolCommitId) {
      this.notify.display('Commit ID Not Provided', 'error');
      this.$dismiss();
    }

    this.loadData();
  }

  async loadData() {
    this.loading = true;

    try {
      this.tool = await this.$store.dispatch('tools/getToolData', {
        toolId: this.toolId,
        commitId: this.toolCommitId
      });
    } catch (err) {
      this.notify.display(err, 'error');
    } finally {
      this.loading = false;
      this.$scope.$apply();
    }

    if (!this.customization) {
      this.customization = {
        questions: {},
        comments: {},
        toolId: null,
        toolCommitId: null
      };
      (this.customization.toolId = this.toolId),
        (this.customization.toolCommitId = this.toolCommitId);
    }
  }

  addQuestionRequirements(questionId, requirement) {
    this.customization.questions[questionId] = requirement;
  }

  addCommentRequirements(toolId, requirement) {
    this.customization.comments[toolId] = requirement;
  }

  logCustomization() {
    console.log(this.customization);
  }
}

const RAW_CUSTOMIZER = {
  questions: {},
  comments: {},
  toolId: null,
  toolCommitId: null
};

export default angular
  .module('app.institutionCustomizationModal', [])
  .directive('institutionCustomizationModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./institution-customization-modal.html'),
    controller: InstitutionCustomizationModalController,
    controllerAs: 'vm'
  })).name;
