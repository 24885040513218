import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

import { forEach } from 'lodash';

@Controller
class CodingFormItem {
  // ghostValue: string = null;
  riskFactorStyle: {} = {};
  originalResponce = null;

  @Inject $scope;
  @Inject $store;
  @Inject $modals;
  @Inject evlUtils;

  get viewOnly() {
    return !!this.$scope.viewingEvaluation;
  }

  get $question() {
    return (
      this.$scope.evaluation?.evaluation?.data[
        this.$scope.cfi.longAddress || this.$scope.cfi.id
      ] || {}
    );
  }

  get $reassessmentData() {
    return this.$scope.evaluation?.evaluation?.reassessmentData;
  }

  get toolCfiGroups() {
    return this.evaluation.tool.codingFormItemGroupings;
  }

  get showSelectModel() {
    return !this.cfi.fillIn;
  }

  get showTextModel() {
    return (
      this.cfi.fillIn === 'single-line' ||
      this.$question.fillInAnswerSelected === 'single-line'
    );
  }

  get showTextFieldModel() {
    return (
      this.cfi.fillIn === 'textbox' ||
      this.$question.fillInAnswerSelected === 'textbox'
    );
  }

  $onInit() {
    this.viewingEvaluation = this.$scope.viewingEvaluation;
    this.cfi = this.$scope.cfi;
    this.evaluation = this.$scope.evaluation;

    if (this.$reassessmentData) {
      this.originalResponce = this.$reassessmentData[this.$question.qid];
    }

    this.riskFactorStyle = {
      color: this.getTextColor(this.cfi.textColor),
      'margin-left': this.cfi.indent * -40
    };

    this.$scope.$on('reset', this::this.resetAnswer);
  }

  resetAnswer() {
    for (let key in this.$question) {
      this.$question[key] = this.originalResponce[key];
    }
  }

  openMessageModal() {
    this.$modals.settings.message(
      'ITEM REFERENCE',
      this.cfi.customReferenceText
    );
  }

  sourceChosen(choice) {
    if (!Array.isArray(this.$question.source)) {
      this.$question.source = [];
    }

    if (choice === 'other' && this.$question.source.indexOf('other') > -1) {
      this.$question.customSource = null;
      this.$question.source.splice('other', 1);

      return;
    }

    let index = this.$question.source.indexOf(choice);

    if (index > -1) {
      this.$question.source.splice(index, 1);
    } else {
      this.$question.source.push(choice);
    }
    // source.source = source.source === choice ? null : choice;
  }

  openWizard() {
    this.evlUtils.openWizard(this.cfi);
  }

  updateQuestion() {
    this.$question.fillIn = '';
    this.evlUtils.updateEvaluationQuestionData(
      this.cfi.longAddress ? this.cfi.longAddress : this.cfi.id,
      this.cfi.codesAndScore
    );
  }

  openPDFBookmark() {
    this.evlUtils.openPDFBookmark(this.cfi.pdfBookmarkPage);
  }

  getTextColor(textColor) {
    if (textColor) return textColor;
    return this.$store.state.theme === 'dark' ? '#e8e8e8' : '#161616';
  }
}

export default angular
  .module('app.coding-form-item', [])
  .directive('codingFormItem', function () {
    return {
      template: require('./coding-form-item.html'),
      restrict: 'EA',
      replace: true,
      scope: {
        viewingEvaluation: '=',
        cfi: '=',
        index: '=',
        evaluation: '='
      },
      controller: CodingFormItem,
      controllerAs: 'vm'
      // ,
      // link($scope, $element) {
      //   $element.on('click', event => {
      //     const flyoutElement = document.getElementById('sources-flyout');
      //     let targetElement = event.target;
      //     console.log('flyoutElement: ', flyoutElement);
      //     console.log('targetElement: ', targetElement);
      //     console.log('contains? : ', flyoutElement.contains(targetElement));
      //     if (targetElement.className.includes('sources-button')) return;
      //     if (targetElement.className.includes('fa-quote-right')) return;
      //     if (!flyoutElement.contains(targetElement)) {
      //       $scope.cfi.showSourcesOptions = false;
      //     }
      //   });
      // }
    };
  }).name;
