export default {
  getPercentile(score, scoreType, clientType) {
    const percentileRanks = {
      0: {
        total: {
          'Male Forensic Psychiatric (File Review)': '0.2',
          'Male Offender (File Review)': '1.6',
          'North American Female Offender': '0.0',
          'English Male Offender': '0.0',
          'North American Male Offender': '0.1',
          'North American Male Forensics Psychiatric Inpatient': '0.0'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '6.7',
          'Male Offender (File Review)': '6.9',
          'North American Female Offender': '1.6',
          'English Male Offender': '3.5',
          'North American Male Offender': '1.4',
          'North American Male Forensics Psychiatric Inpatient': '0.8'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '4.0',
          'Male Offender (File Review)': '4.4',
          'North American Female Offender': '0.7',
          'English Male Offender': '2.0',
          'North American Male Offender': '1.0',
          'North American Male Forensics Psychiatric Inpatient': '0.2'
        },
        facet1: {
          'Male Forensic Psychiatric (File Review)': '41.3',
          'Male Offender (File Review)': '25.3',
          'North American Female Offender': '9.3',
          'English Male Offender': '18.2',
          'North American Male Offender': '8.0',
          'North American Male Forensics Psychiatric Inpatient': '11.0'
        },
        facet2: {
          'Male Forensic Psychiatric (File Review)': '8.5',
          'Male Offender (File Review)': '8.9',
          'North American Female Offender': '5.6',
          'English Male Offender': '8.0',
          'North American Male Offender': '3.3',
          'North American Male Forensics Psychiatric Inpatient': '2.3'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '9.0',
          'Male Offender (File Review)': '9.0',
          'North American Female Offender': '1.6',
          'English Male Offender': '3.0',
          'North American Male Offender': '2.7',
          'North American Male Forensics Psychiatric Inpatient': '0.5'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '10.7',
          'Male Offender (File Review)': '10.9',
          'North American Female Offender': '7.4',
          'English Male Offender': '8.7',
          'North American Male Offender': '4.5',
          'North American Male Forensics Psychiatric Inpatient': '1.9'
        }
      },
      1: {
        total: {
          'Male Forensic Psychiatric (File Review)': '1.5',
          'Male Offender (File Review)': '2.4',
          'North American Female Offender': '0.1',
          'English Male Offender': '0.1',
          'North American Male Offender': '0.2',
          'North American Male Forensics Psychiatric Inpatient': '0.1'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '11.2',
          'Male Offender (File Review)': '11.7',
          'North American Female Offender': '5.4',
          'English Male Offender': '7.5',
          'North American Male Offender': '3.2',
          'North American Male Forensics Psychiatric Inpatient': '3.3'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '9.0',
          'Male Offender (File Review)': '8.9',
          'North American Female Offender': '1,6',
          'English Male Offender': '3.0',
          'North American Male Offender': '2.7',
          'North American Male Forensics Psychiatric Inpatient': '0.4'
        },
        facet1: {
          'Male Forensic Psychiatric (File Review)': '53.2',
          'Male Offender (File Review)': '42.6',
          'North American Female Offender': '23.6',
          'English Male Offender': '40.0',
          'North American Male Offender': '19.3',
          'North American Male Forensics Psychiatric Inpatient': '25.9'
        },
        facet2: {
          'Male Forensic Psychiatric (File Review)': '14.9',
          'Male Offender (File Review)': '15.1',
          'North American Female Offender': '17.6',
          'English Male Offender': '15.4',
          'North American Male Offender': '8.6',
          'North American Male Forensics Psychiatric Inpatient': '6.7'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '17.0',
          'Male Offender (File Review)': '19.8',
          'North American Female Offender': '5.5',
          'English Male Offender': '7.7',
          'North American Male Offender': '6.7',
          'North American Male Forensics Psychiatric Inpatient': '3.1'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '22.2',
          'Male Offender (File Review)': '21.1',
          'North American Female Offender': '14.5',
          'English Male Offender': '19.1',
          'North American Male Offender': '9.8',
          'North American Male Forensics Psychiatric Inpatient': '5.3'
        }
      },
      2: {
        total: {
          'Male Forensic Psychiatric (File Review)': '3.5',
          'Male Offender (File Review)': '4.0',
          'North American Female Offender': '0.2',
          'English Male Offender': '0.4',
          'North American Male Offender': '0.4',
          'North American Male Forensics Psychiatric Inpatient': '0.2'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '20.1',
          'Male Offender (File Review)': '17.7',
          'North American Female Offender': '10.7',
          'English Male Offender': '15.6',
          'North American Male Offender': '6.3',
          'North American Male Forensics Psychiatric Inpatient': '6.2'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '14.7',
          'Male Offender (File Review)': '13.5',
          'North American Female Offender': '5.4',
          'English Male Offender': '7.4',
          'North American Male Offender': '4.7',
          'North American Male Forensics Psychiatric Inpatient': '1.0'
        },
        facet1: {
          'Male Forensic Psychiatric (File Review)': '67.7',
          'Male Offender (File Review)': '57.9',
          'North American Female Offender': '41.3',
          'English Male Offender': '61.6',
          'North American Male Offender': '38.8',
          'North American Male Forensics Psychiatric Inpatient': '43.9'
        },
        facet2: {
          'Male Forensic Psychiatric (File Review)': '24.6',
          'Male Offender (File Review)': '25.6',
          'North American Female Offender': '27.7',
          'English Male Offender': '29.6',
          'North American Male Offender': '16.6',
          'North American Male Forensics Psychiatric Inpatient': '13.5'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '23.7',
          'Male Offender (File Review)': '30.2',
          'North American Female Offender': '13.0',
          'English Male Offender': '16.1',
          'North American Male Offender': '12.6',
          'North American Male Forensics Psychiatric Inpatient': '6.9'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '35.9',
          'Male Offender (File Review)': '34.3',
          'North American Female Offender': '26.7',
          'English Male Offender': '28.0',
          'North American Male Offender': '15.8',
          'North American Male Forensics Psychiatric Inpatient': '11.4'
        }
      },
      3: {
        total: {
          'Male Forensic Psychiatric (File Review)': '5.0',
          'Male Offender (File Review)': '6.0',
          'North American Female Offender': '0.6',
          'English Male Offender': '1.1',
          'North American Male Offender': '0.7',
          'North American Male Forensics Psychiatric Inpatient': '0.4'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '25.1',
          'Male Offender (File Review)': '24.5',
          'North American Female Offender': '18.8',
          'English Male Offender': '25.8',
          'North American Male Offender': '11.1',
          'North American Male Forensics Psychiatric Inpatient': '10.5'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '17.2',
          'Male Offender (File Review)': '18.8',
          'North American Female Offender': '8.8',
          'English Male Offender': '11.9',
          'North American Male Offender': '7.4',
          'North American Male Forensics Psychiatric Inpatient': '2.8'
        },
        facet1: {
          'Male Forensic Psychiatric (File Review)': '75.1',
          'Male Offender (File Review)': '67.9',
          'North American Female Offender': '57.1',
          'English Male Offender': '75.6',
          'North American Male Offender': '48.8',
          'North American Male Forensics Psychiatric Inpatient': '60.4'
        },
        facet2: {
          'Male Forensic Psychiatric (File Review)': '31.6',
          'Male Offender (File Review)': '37.2',
          'North American Female Offender': '40.7',
          'English Male Offender': '45.9',
          'North American Male Offender': '27.9',
          'North American Male Forensics Psychiatric Inpatient': '25.0'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '29.9',
          'Male Offender (File Review)': '41.6',
          'North American Female Offender': '20.0',
          'English Male Offender': '27.8',
          'North American Male Offender': '19.5',
          'North American Male Forensics Psychiatric Inpatient': '13.3'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '46.1',
          'Male Offender (File Review)': '44.3',
          'North American Female Offender': '40.7',
          'English Male Offender': '36.9',
          'North American Male Offender': '23.0',
          'North American Male Forensics Psychiatric Inpatient': '19.7'
        }
      },
      4: {
        total: {
          'Male Forensic Psychiatric (File Review)': '7.7',
          'Male Offender (File Review)': '8.1',
          'North American Female Offender': '1.6',
          'English Male Offender': '2.6',
          'North American Male Offender': '1.3',
          'North American Male Forensics Psychiatric Inpatient': '0.8'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '35.1',
          'Male Offender (File Review)': '33.3',
          'North American Female Offender': '26.8',
          'English Male Offender': '36.6',
          'North American Male Offender': '16.8',
          'North American Male Forensics Psychiatric Inpatient': '17.0'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '21.2',
          'Male Offender (File Review)': '24.3',
          'North American Female Offender': '12.9',
          'English Male Offender': '16.9',
          'North American Male Offender': '9.7',
          'North American Male Forensics Psychiatric Inpatient': '4.9'
        },
        facet1: {
          'Male Forensic Psychiatric (File Review)': '84.6',
          'Male Offender (File Review)': '78.9',
          'North American Female Offender': '70.6',
          'English Male Offender': '86.2',
          'North American Male Offender': '64.5',
          'North American Male Forensics Psychiatric Inpatient': '75.5'
        },
        facet2: {
          'Male Forensic Psychiatric (File Review)': '46.0',
          'Male Offender (File Review)': '52.2',
          'North American Female Offender': '56.2',
          'English Male Offender': '61.1',
          'North American Male Offender': '41.3',
          'North American Male Forensics Psychiatric Inpatient': '40.5'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '36.4',
          'Male Offender (File Review)': '52.3',
          'North American Female Offender': '30.4',
          'English Male Offender': '41.3',
          'North American Male Offender': '28.8',
          'North American Male Forensics Psychiatric Inpatient': '24.3'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '56.1',
          'Male Offender (File Review)': '55.4',
          'North American Female Offender': '55.3',
          'English Male Offender': '46.8',
          'North American Male Offender': '31.7',
          'North American Male Forensics Psychiatric Inpatient': '29.1'
        }
      },
      5: {
        total: {
          'Male Forensic Psychiatric (File Review)': '10.7',
          'Male Offender (File Review)': '10.6',
          'North American Female Offender': '2.2',
          'English Male Offender': '4.0',
          'North American Male Offender': '2.0',
          'North American Male Forensics Psychiatric Inpatient': '1.2'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '41.8',
          'Male Offender (File Review)': '41.4',
          'North American Female Offender': '34.9',
          'English Male Offender': '47.1',
          'North American Male Offender': '2.6',
          'North American Male Forensics Psychiatric Inpatient': '25.8'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '23.9',
          'Male Offender (File Review)': '29.7',
          'North American Female Offender': '17.9',
          'English Male Offender': '22.1',
          'North American Male Offender': '13.0',
          'North American Male Forensics Psychiatric Inpatient': '7.6'
        },
        facet1: {
          'Male Forensic Psychiatric (File Review)': '87.1',
          'Male Offender (File Review)': '87.2',
          'North American Female Offender': '82.5',
          'English Male Offender': '93.0',
          'North American Male Offender': '77.5',
          'North American Male Forensics Psychiatric Inpatient': '85.0'
        },
        facet2: {
          'Male Forensic Psychiatric (File Review)': '51.0',
          'Male Offender (File Review)': '67.0',
          'North American Female Offender': '68.1',
          'English Male Offender': '76.4',
          'North American Male Offender': '57.9',
          'North American Male Forensics Psychiatric Inpatient': '56.0'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '44.1',
          'Male Offender (File Review)': '62.4',
          'North American Female Offender': '42.4',
          'English Male Offender': '54.8',
          'North American Male Offender': '40.6',
          'North American Male Forensics Psychiatric Inpatient': '36.3'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '64.8',
          'Male Offender (File Review)': '68.3',
          'North American Female Offender': '67.1',
          'English Male Offender': '57.9',
          'North American Male Offender': '42.2',
          'North American Male Forensics Psychiatric Inpatient': '40.9'
        }
      },
      6: {
        total: {
          'Male Forensic Psychiatric (File Review)': '15.5',
          'Male Offender (File Review)': '12.7',
          'North American Female Offender': '4.2',
          'English Male Offender': '7.2',
          'North American Male Offender': '3.2',
          'North American Male Forensics Psychiatric Inpatient': '1.7'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '54.0',
          'Male Offender (File Review)': '50.7',
          'North American Female Offender': '44.0',
          'English Male Offender': '58.5',
          'North American Male Offender': '31.2',
          'North American Male Forensics Psychiatric Inpatient': '35.6'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '28.9',
          'Male Offender (File Review)': '35.2',
          'North American Female Offender': '23.9',
          'English Male Offender': '27.8',
          'North American Male Offender': '16.8',
          'North American Male Forensics Psychiatric Inpatient': '11.4'
        },
        facet1: {
          'Male Forensic Psychiatric (File Review)': '93.5',
          'Male Offender (File Review)': '93.8',
          'North American Female Offender': '90.9',
          'English Male Offender': '97.0',
          'North American Male Offender': '88.6',
          'North American Male Forensics Psychiatric Inpatient': '91.6'
        },
        facet2: {
          'Male Forensic Psychiatric (File Review)': '71.9',
          'Male Offender (File Review)': '83.9',
          'North American Female Offender': '81.8',
          'English Male Offender': '88.2',
          'North American Male Offender': '74.8',
          'North American Male Forensics Psychiatric Inpatient': '73.4'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '54.4',
          'Male Offender (File Review)': '72.3',
          'North American Female Offender': '56.6',
          'English Male Offender': '69.9',
          'North American Male Offender': '54.4',
          'North American Male Forensics Psychiatric Inpatient': '52.0'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '79.1',
          'Male Offender (File Review)': '79.0',
          'North American Female Offender': '79.3',
          'English Male Offender': '69.1',
          'North American Male Offender': '55.4',
          'North American Male Forensics Psychiatric Inpatient': '55.0'
        }
      },
      7: {
        total: {
          'Male Forensic Psychiatric (File Review)': '18.2',
          'Male Offender (File Review)': '16.0',
          'North American Female Offender': '6.2',
          'English Male Offender': '10.7',
          'North American Male Offender': '4.6',
          'North American Male Forensics Psychiatric Inpatient': '2.9'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '58.2',
          'Male Offender (File Review)': '58.5',
          'North American Female Offender': '52.9',
          'English Male Offender': '68.0',
          'North American Male Offender': '39.9',
          'North American Male Forensics Psychiatric Inpatient': '44.9'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '34.7',
          'Male Offender (File Review)': '42.0',
          'North American Female Offender': '29.1',
          'English Male Offender': '33.8',
          'North American Male Offender': '20.5',
          'North American Male Forensics Psychiatric Inpatient': '15.7'
        },
        facet1: {
          'Male Forensic Psychiatric (File Review)': '95.0',
          'Male Offender (File Review)': '97.2',
          'North American Female Offender': '95.9',
          'English Male Offender': '98.9',
          'North American Male Offender': '96.4',
          'North American Male Forensics Psychiatric Inpatient': '97.4'
        },
        facet2: {
          'Male Forensic Psychiatric (File Review)': '80.1',
          'Male Offender (File Review)': '92.5',
          'North American Female Offender': '92.6',
          'English Male Offender': '96.7',
          'North American Male Offender': '90.9',
          'North American Male Forensics Psychiatric Inpatient': '89.6'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '61.8',
          'Male Offender (File Review)': '80.1',
          'North American Female Offender': '69.6',
          'English Male Offender': '82.3',
          'North American Male Offender': '69.8',
          'North American Male Forensics Psychiatric Inpatient': '70.6'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '86.3',
          'Male Offender (File Review)': '86.5',
          'North American Female Offender': '87.8',
          'English Male Offender': '79.6',
          'North American Male Offender': '68.0',
          'North American Male Forensics Psychiatric Inpatient': '68.0'
        }
      },
      8: {
        total: {
          'Male Forensic Psychiatric (File Review)': '22.4',
          'Male Offender (File Review)': '18.9',
          'North American Female Offender': '8.2',
          'English Male Offender': '14.1',
          'North American Male Offender': '6.0',
          'North American Male Forensics Psychiatric Inpatient': '3.8'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '67.4',
          'Male Offender (File Review)': '66.4',
          'North American Female Offender': '61.8',
          'English Male Offender': '76.3',
          'North American Male Offender': '48.7',
          'North American Male Forensics Psychiatric Inpatient': '55.0'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '41.1',
          'Male Offender (File Review)': '48.2',
          'North American Female Offender': '35.7',
          'English Male Offender': '38.5',
          'North American Male Offender': '25.2',
          'North American Male Forensics Psychiatric Inpatient': '20.7'
        },
        facet1: {
          'Male Forensic Psychiatric (File Review)': '100.0',
          'Male Offender (File Review)': '100.0',
          'North American Female Offender': '100.0',
          'English Male Offender': '100.0',
          'North American Male Offender': '100.0',
          'North American Male Forensics Psychiatric Inpatient': '100.0'
        },
        facet2: {
          'Male Forensic Psychiatric (File Review)': '100.0',
          'Male Offender (File Review)': '100.0',
          'North American Female Offender': '100.0',
          'English Male Offender': '100.0',
          'North American Male Offender': '100.0',
          'North American Male Forensics Psychiatric Inpatient': '100.0'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '71.8',
          'Male Offender (File Review)': '88.5',
          'North American Female Offender': '82.6',
          'English Male Offender': '91.8',
          'North American Male Offender': '83.3',
          'North American Male Forensics Psychiatric Inpatient': '86.1'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '92.3',
          'Male Offender (File Review)': '92.9',
          'North American Female Offender': '94.1',
          'English Male Offender': '87.8',
          'North American Male Offender': '81.1',
          'North American Male Forensics Psychiatric Inpatient': '81.5'
        }
      },
      9: {
        total: {
          'Male Forensic Psychiatric (File Review)': '25.7',
          'Male Offender (File Review)': '22.0',
          'North American Female Offender': '11.3',
          'English Male Offender': '17.1',
          'North American Male Offender': '7.9',
          'North American Male Forensics Psychiatric Inpatient': '5.1'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '72.6',
          'Male Offender (File Review)': '73.0',
          'North American Female Offender': '69.0',
          'English Male Offender': '83.1',
          'North American Male Offender': '57.1',
          'North American Male Forensics Psychiatric Inpatient': '65.8'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '45.9',
          'Male Offender (File Review)': '54.7',
          'North American Female Offender': '42.4',
          'English Male Offender': '46.6',
          'North American Male Offender': '30.7',
          'North American Male Forensics Psychiatric Inpatient': '25.9'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '82.0',
          'Male Offender (File Review)': '94.2',
          'North American Female Offender': '94.6',
          'English Male Offender': '98.3',
          'North American Male Offender': '94.8',
          'North American Male Forensics Psychiatric Inpatient': '96.3'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '96.5',
          'Male Offender (File Review)': '97.0',
          'North American Female Offender': '97.9',
          'English Male Offender': '95.7',
          'North American Male Offender': '92.3',
          'North American Male Forensics Psychiatric Inpatient': '94.0'
        }
      },
      10: {
        total: {
          'Male Forensic Psychiatric (File Review)': '28.9',
          'Male Offender (File Review)': '26.2',
          'North American Female Offender': '15.1',
          'English Male Offender': '21.1',
          'North American Male Offender': '9.4',
          'North American Male Forensics Psychiatric Inpatient': '6.7'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '79.9',
          'Male Offender (File Review)': '80.5',
          'North American Female Offender': '76.1',
          'English Male Offender': '87.7',
          'North American Male Offender': '65.8',
          'North American Male Forensics Psychiatric Inpatient': '74.7'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '51.4',
          'Male Offender (File Review)': '61.6',
          'North American Female Offender': '51.0',
          'English Male Offender': '55.7',
          'North American Male Offender': '36.4',
          'North American Male Forensics Psychiatric Inpatient': '32.2'
        },
        facet3: {
          'Male Forensic Psychiatric (File Review)': '100.0',
          'Male Offender (File Review)': '100.0',
          'North American Female Offender': '100.0',
          'English Male Offender': '100.0',
          'North American Male Offender': '100.0',
          'North American Male Forensics Psychiatric Inpatient': '100.0'
        },
        facet4: {
          'Male Forensic Psychiatric (File Review)': '100.0',
          'Male Offender (File Review)': '100.0',
          'North American Female Offender': '100.0',
          'English Male Offender': '100.0',
          'North American Male Offender': '100.0',
          'North American Male Forensics Psychiatric Inpatient': '100.0'
        }
      },
      11: {
        total: {
          'Male Forensic Psychiatric (File Review)': '31.2',
          'Male Offender (File Review)': '30.9',
          'North American Female Offender': '19.0',
          'English Male Offender': '23.8',
          'North American Male Offender': '11.0',
          'North American Male Forensics Psychiatric Inpatient': '9.2'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '82.3',
          'Male Offender (File Review)': '85.9',
          'North American Female Offender': '83.3',
          'English Male Offender': '92.5',
          'North American Male Offender': '74.7',
          'North American Male Forensics Psychiatric Inpatient': '81.9'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '57.4',
          'Male Offender (File Review)': '67.0',
          'North American Female Offender': '59.5',
          'English Male Offender': '63.2',
          'North American Male Offender': '43.3',
          'North American Male Forensics Psychiatric Inpatient': '39.6'
        }
      },
      12: {
        total: {
          'Male Forensic Psychiatric (File Review)': '33.4',
          'Male Offender (File Review)': '35.2',
          'North American Female Offender': '21.6',
          'English Male Offender': '27.2',
          'North American Male Offender': '13.1',
          'North American Male Forensics Psychiatric Inpatient': '11.1'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '88.3',
          'Male Offender (File Review)': '91.7',
          'North American Female Offender': '88.6',
          'English Male Offender': '95.6',
          'North American Male Offender': '82.7',
          'North American Male Forensics Psychiatric Inpatient': '88.6'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '65.3',
          'Male Offender (File Review)': '73.8',
          'North American Female Offender': '68.1',
          'English Male Offender': '69.3',
          'North American Male Offender': '50.8',
          'North American Male Forensics Psychiatric Inpatient': '50.0'
        }
      },
      13: {
        total: {
          'Male Forensic Psychiatric (File Review)': '36.9',
          'Male Offender (File Review)': '40.9',
          'North American Female Offender': '25.5',
          'English Male Offender': '30.8',
          'North American Male Offender': '15.5',
          'North American Male Forensics Psychiatric Inpatient': '14.1'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '90.8',
          'Male Offender (File Review)': '95.3',
          'North American Female Offender': '93.1',
          'English Male Offender': '97.5',
          'North American Male Offender': '89.9',
          'North American Male Forensics Psychiatric Inpatient': '93.3'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '70.8',
          'Male Offender (File Review)': '79.0',
          'North American Female Offender': '75.6',
          'English Male Offender': '75.4',
          'North American Male Offender': '58.9',
          'North American Male Forensics Psychiatric Inpatient': '58.9'
        }
      },
      14: {
        total: {
          'Male Forensic Psychiatric (File Review)': '39.2',
          'Male Offender (File Review)': '44.9',
          'North American Female Offender': '29.5',
          'English Male Offender': '35.6',
          'North American Male Offender': '18.1',
          'North American Male Forensics Psychiatric Inpatient': '16.4'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '95.8',
          'Male Offender (File Review)': '98.3',
          'North American Female Offender': '96.9',
          'English Male Offender': '98.9',
          'North American Male Offender': '95.5',
          'North American Male Forensics Psychiatric Inpatient': '97.4'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '76.8',
          'Male Offender (File Review)': '84.7',
          'North American Female Offender': '82.6',
          'English Male Offender': '81.6',
          'North American Male Offender': '67.9',
          'North American Male Forensics Psychiatric Inpatient': '69.4'
        }
      },
      15: {
        total: {
          'Male Forensic Psychiatric (File Review)': '42.6',
          'Male Offender (File Review)': '49.9',
          'North American Female Offender': '33.4',
          'English Male Offender': '42.1',
          'North American Male Offender': '21.2',
          'North American Male Forensics Psychiatric Inpatient': '19.8'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '96.8',
          'Male Offender (File Review)': '99.1',
          'North American Female Offender': '98.9',
          'English Male Offender': '99.6',
          'North American Male Offender': '98.6',
          'North American Male Forensics Psychiatric Inpatient': '99.3'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '83.8',
          'Male Offender (File Review)': '88.9',
          'North American Female Offender': '87.4',
          'English Male Offender': '88.2',
          'North American Male Offender': '76.1',
          'North American Male Forensics Psychiatric Inpatient': '78.1'
        }
      },
      16: {
        total: {
          'Male Forensic Psychiatric (File Review)': '46.6',
          'Male Offender (File Review)': '54.0',
          'North American Female Offender': '38.1',
          'English Male Offender': '45.5',
          'North American Male Offender': '24.5',
          'North American Male Forensics Psychiatric Inpatient': '22.6'
        },
        factor1: {
          'Male Forensic Psychiatric (File Review)': '100.0',
          'Male Offender (File Review)': '100.0',
          'North American Female Offender': '100.0',
          'English Male Offender': '100.0',
          'North American Male Offender': '100.0',
          'North American Male Forensics Psychiatric Inpatient': '100.0'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '89.3',
          'Male Offender (File Review)': '92.9',
          'North American Female Offender': '92.8',
          'English Male Offender': '92.1',
          'North American Male Offender': '84.1',
          'North American Male Forensics Psychiatric Inpatient': '87.2'
        }
      },
      17: {
        total: {
          'Male Forensic Psychiatric (File Review)': '50.4',
          'Male Offender (File Review)': '58.4',
          'North American Female Offender': '43.3',
          'English Male Offender': '50.6',
          'North American Male Offender': '28.0',
          'North American Male Forensics Psychiatric Inpatient': '26.5'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '91.3',
          'Male Offender (File Review)': '95.2',
          'North American Female Offender': '96.5',
          'English Male Offender': '95.9',
          'North American Male Offender': '91.5',
          'North American Male Forensics Psychiatric Inpatient': '93.5'
        }
      },
      18: {
        total: {
          'Male Forensic Psychiatric (File Review)': '55.1',
          'Male Offender (File Review)': '63.2',
          'North American Female Offender': '47.5',
          'English Male Offender': '57.1',
          'North American Male Offender': '31.6',
          'North American Male Forensics Psychiatric Inpatient': '32.4'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '95.0',
          'Male Offender (File Review)': '97.5',
          'North American Female Offender': '99.0',
          'English Male Offender': '98.0',
          'North American Male Offender': '96.3',
          'North American Male Forensics Psychiatric Inpatient': '97.7'
        }
      },
      19: {
        total: {
          'Male Forensic Psychiatric (File Review)': '57.4',
          'Male Offender (File Review)': '66.6',
          'North American Female Offender': '52.5',
          'English Male Offender': '61.6',
          'North American Male Offender': '35.1',
          'North American Male Forensics Psychiatric Inpatient': '36.1'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '98.5',
          'Male Offender (File Review)': '99.3',
          'North American Female Offender': '100.0',
          'English Male Offender': '99.2',
          'North American Male Offender': '99.0',
          'North American Male Forensics Psychiatric Inpatient': '99.6'
        }
      },
      20: {
        total: {
          'Male Forensic Psychiatric (File Review)': '61.8',
          'Male Offender (File Review)': '70.1',
          'North American Female Offender': '57.2',
          'English Male Offender': '66.2',
          'North American Male Offender': '39.7',
          'North American Male Forensics Psychiatric Inpatient': '41.9'
        },
        factor2: {
          'Male Forensic Psychiatric (File Review)': '100.0',
          'Male Offender (File Review)': '100.0',
          'North American Female Offender': '100.0',
          'English Male Offender': '100.0',
          'North American Male Offender': '100.0',
          'North American Male Forensics Psychiatric Inpatient': '100.0'
        }
      },
      21: {
        total: {
          'Male Forensic Psychiatric (File Review)': '66.8',
          'Male Offender (File Review)': '73.3',
          'North American Female Offender': '61.7',
          'English Male Offender': '70.0',
          'North American Male Offender': '43.9',
          'North American Male Forensics Psychiatric Inpatient': '46.1'
        }
      },
      22: {
        total: {
          'Male Forensic Psychiatric (File Review)': '69.6',
          'Male Offender (File Review)': '76.8',
          'North American Female Offender': '66.1',
          'English Male Offender': '74.3',
          'North American Male Offender': '48.1',
          'North American Male Forensics Psychiatric Inpatient': '51.8'
        }
      },
      23: {
        total: {
          'Male Forensic Psychiatric (File Review)': '72.8',
          'Male Offender (File Review)': '79.9',
          'North American Female Offender': '69.6',
          'English Male Offender': '79.5',
          'North American Male Offender': '52.4',
          'North American Male Forensics Psychiatric Inpatient': '57.4'
        }
      },
      24: {
        total: {
          'Male Forensic Psychiatric (File Review)': '75.3',
          'Male Offender (File Review)': '83.2',
          'North American Female Offender': '73.2',
          'English Male Offender': '82.5',
          'North American Male Offender': '57.1',
          'North American Male Forensics Psychiatric Inpatient': '62.5'
        }
      },
      25: {
        total: {
          'Male Forensic Psychiatric (File Review)': '78.3',
          'Male Offender (File Review)': '85.7',
          'North American Female Offender': '77.8',
          'English Male Offender': '85.2',
          'North American Male Offender': '62.1',
          'North American Male Forensics Psychiatric Inpatient': '68.5'
        }
      },
      26: {
        total: {
          'Male Forensic Psychiatric (File Review)': '81.8',
          'Male Offender (File Review)': '88.1',
          'North American Female Offender': '81.1',
          'English Male Offender': '88.6',
          'North American Male Offender': '67.2',
          'North American Male Forensics Psychiatric Inpatient': '74.0'
        }
      },
      27: {
        total: {
          'Male Forensic Psychiatric (File Review)': '85.0',
          'Male Offender (File Review)': '90.8',
          'North American Female Offender': '83.7',
          'English Male Offender': '90.3',
          'North American Male Offender': '71.4',
          'North American Male Forensics Psychiatric Inpatient': '78.2'
        }
      },
      28: {
        total: {
          'Male Forensic Psychiatric (File Review)': '87.5',
          'Male Offender (File Review)': '92.5',
          'North American Female Offender': '86.2',
          'English Male Offender': '92.5',
          'North American Male Offender': '75.7',
          'North American Male Forensics Psychiatric Inpatient': '82.3'
        }
      },
      29: {
        total: {
          'Male Forensic Psychiatric (File Review)': '89.8',
          'Male Offender (File Review)': '94.1',
          'North American Female Offender': '89.2',
          'English Male Offender': '94.4',
          'North American Male Offender': '79.5',
          'North American Male Forensics Psychiatric Inpatient': '86.6'
        }
      },
      30: {
        total: {
          'Male Forensic Psychiatric (File Review)': '90.3',
          'Male Offender (File Review)': '95.3',
          'North American Female Offender': '92.6',
          'English Male Offender': '95.8',
          'North American Male Offender': '84.3',
          'North American Male Forensics Psychiatric Inpatient': '90.0'
        }
      },
      31: {
        total: {
          'Male Forensic Psychiatric (File Review)': '91.0',
          'Male Offender (File Review)': '96.8',
          'North American Female Offender': '94.6',
          'English Male Offender': '97.3',
          'North American Male Offender': '88.0',
          'North American Male Forensics Psychiatric Inpatient': '93.3'
        }
      },
      32: {
        total: {
          'Male Forensic Psychiatric (File Review)': '93.0',
          'Male Offender (File Review)': '97.6',
          'North American Female Offender': '97.0',
          'English Male Offender': '98.3',
          'North American Male Offender': '91.2',
          'North American Male Forensics Psychiatric Inpatient': '96.2'
        }
      },
      33: {
        total: {
          'Male Forensic Psychiatric (File Review)': '94.5',
          'Male Offender (File Review)': '98.0',
          'North American Female Offender': '98.4',
          'English Male Offender': '99.2',
          'North American Male Offender': '94.2',
          'North American Male Forensics Psychiatric Inpatient': '97.8'
        }
      },
      34: {
        total: {
          'Male Forensic Psychiatric (File Review)': '96.0',
          'Male Offender (File Review)': '98.6',
          'North American Female Offender': '99.3',
          'English Male Offender': '99.4',
          'North American Male Offender': '96.4',
          'North American Male Forensics Psychiatric Inpatient': '99.0'
        }
      },
      35: {
        total: {
          'Male Forensic Psychiatric (File Review)': '96.8',
          'Male Offender (File Review)': '99.2',
          'North American Female Offender': '99.8',
          'English Male Offender': '99.6',
          'North American Male Offender': '97.7',
          'North American Male Forensics Psychiatric Inpatient': '99.4'
        }
      },
      36: {
        total: {
          'Male Forensic Psychiatric (File Review)': '99.3',
          'Male Offender (File Review)': '99.7',
          'North American Female Offender': '99.9',
          'English Male Offender': '99.7',
          'North American Male Offender': '99.0',
          'North American Male Forensics Psychiatric Inpatient': '99.8'
        }
      },
      37: {
        total: {
          'Male Forensic Psychiatric (File Review)': '99.3',
          'Male Offender (File Review)': '99.8',
          'North American Female Offender': '99.9',
          'English Male Offender': '99.9',
          'North American Male Offender': '99.5',
          'North American Male Forensics Psychiatric Inpatient': '99.9'
        }
      },
      38: {
        total: {
          'Male Forensic Psychiatric (File Review)': '99.8',
          'Male Offender (File Review)': '99.9',
          'North American Female Offender': '100.0',
          'English Male Offender': '99.9',
          'North American Male Offender': '99.8',
          'North American Male Forensics Psychiatric Inpatient': '99.9'
        }
      },
      39: {
        total: {
          'Male Forensic Psychiatric (File Review)': '100.0',
          'Male Offender (File Review)': '99.9',
          'North American Female Offender': '100.0',
          'English Male Offender': '100.0',
          'North American Male Offender': '99.9',
          'North American Male Forensics Psychiatric Inpatient': '100.0'
        }
      },
      40: {
        total: {
          'Male Forensic Psychiatric (File Review)': '100.0',
          'Male Offender (File Review)': '100.0',
          'North American Female Offender': '100.0',
          'English Male Offender': '100.0',
          'North American Male Offender': '100.0',
          'North American Male Forensics Psychiatric Inpatient': '100.0'
        }
      }
    };
    if (typeof score === 'string') {
      if (score.includes('.')) {
        score = parseFloat(score);
        score = Math.round(score);
      } else {
        score = parseInt(score, 10);
      }
    }
    if (typeof score !== 'number' || score < 0 || score > 40) return;
    if (
      typeof scoreType !== 'string' &&
      scoreType !== 'total' &&
      scoreType !== 'factor1' &&
      scoreType !== 'factor2' &&
      scoreType !== 'facet1' &&
      scoreType !== 'facet2' &&
      scoreType !== 'facet3' &&
      scoreType !== 'facet4'
    )
      return;
    if (
      typeof clientType !== 'string' &&
      clientType !== 'North American Female Offender' &&
      clientType !== 'North American Male Offender' &&
      clientType !== 'North American Male Forensics Psychiatric Inpatient' &&
      clientType !== 'Male Forensic Psychiatric (File Review)' &&
      clientType !== 'Male Offender (File Review)'
    )
      return;
    if (percentileRanks[score]) {
      if (percentileRanks[score][scoreType]) {
        if (percentileRanks[score][scoreType][clientType]) {
          return percentileRanks[score][scoreType][clientType];
        }
      }
    }
    return 'N/A';
  }
};
