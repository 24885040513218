import angular from 'angular';

import { CasePlan } from '@interfaces/case-plan';
import { Client } from '@interfaces/client';
import { Evaluation } from '@interfaces/evaluation';
import { Report } from '@interfaces/report';
import { Tool } from '@interfaces/tool';
import { MeState } from '@store/modules/me';

import lscmiProfileReportIndex from './lscmiProfileReport';
import lscmiCasePlanReportIndex from './lscmiCasePlanReport';
import lsirsvReportIndex from './lsirsvProfileReport';
import ylsSRVReportIndex from './ylsSRVReport/index';
import ylsProfileReportIndex from './ylsProfileReport';
import ylsCasePlanReportIndex from './ylsCasePlanReport';
import ylsComparativeReportIndex from './ylsComparativeReport';
import lscmiComparativeReportIndex from './lscmiComparativeReport';
import generateComparativeReportIndex from './generateComparativeReport';
import harePCLRProfileReportIndex from './harePCLRProfileReport';
import lsirProfileReportIndex from './lsirProfileReport';
import acesProfileReportIndex from './acesProfileReport';

import ylsCasePlanImageData from './ylsCasePlanReport/imageDataUrls.js';
import lscmiLogoImageData from './lscmiProfileReport/imageDataUrls.js';
import imageData from './imageDataUrls.js';

/**
 * ...
 */
export type IGeneratePdfService = GeneratePdfService;

declare module 'angular' {
  namespace gears {
    type IGeneratePdfService = GeneratePdfService;
  }
}

const pageWidth = 612;
const pageHeight = 792;

const margins = {
  top: 30,
  bottom: 70,
  left: 40,
  width: pageWidth - 80
};

const pageSetup = {
  pageWidth,
  pageHeight,
  margins
};

/**
 * ...
 */
export class GeneratePdfService {
  private readonly $me: MeState;
  private readonly $services: any;

  constructor(
    $http: angular.IHttpService,
    $filter: angular.IFilterService,
    $sce: angular.ISCEService,
    $templateCache: angular.ITemplateCacheService,
    $store: angular.gears.IStoreService,
    $auth: angular.gears.IAuthService,
    $util: angular.gears.IUtilService,
    $reincode: angular.gears.IReincodeService,
    notify: angular.gears.INotifyService,
    Upload: angular.angularFileUpload.IUploadService
  ) {
    'ngInject';

    this.$me = $auth.user;

    this.$services = {
      $store,
      $http,
      $filter,
      $templateCache,
      $auth,
      $util,
      notify,
      Upload,
      $reincode,
      $sce
    };
  }

  /**
   * Generates a profile report for LS/CMI Evaluations.
   *
   * @param client ...
   * @param evaluation ...
   * @param tool ...
   * @param options ...
   * @return ...
   */
  async generateLSCMIProfileReport(
    client: Client,
    evaluation: Evaluation,
    tool: Tool,
    options: unknown
  ) {
    let mhsLogoDataUrl = imageData.mhsLogo();

    return await lscmiProfileReportIndex.generate(
      mhsLogoDataUrl,
      pageSetup,
      this.$services,
      client,
      evaluation,
      tool,
      options,
      this.$me
    );
  }

  /**
   * Generates a profile report the LSI-R:SV Evaluations.
   *
   * @param client ...
   * @param evaluation ...
   * @param tool ...
   * @return ...
   */
  async generateLSIRSVProfileReport(
    client: Client,
    evaluation: Evaluation,
    tool: Tool
  ) {
    let mhsLogoDataUrl = imageData.mhsLogo();

    return await lsirsvReportIndex.generate(
      mhsLogoDataUrl,
      pageSetup,
      this.$services,
      client,
      evaluation,
      tool,
      this.$me
    );
  }

  /**
   * Generates a profile report for YLS/CMI SRV Evaluations.
   *
   * @param client ...
   * @param evaluation ...
   * @param tool ...
   * @return ...
   */
  async generateYLSSRVProfileReport(
    client: Client,
    evaluation: Evaluation,
    tool: Tool
  ) {
    let mhsLogoDataUrl = imageData.mhsLogo();

    return await ylsSRVReportIndex.generate(
      mhsLogoDataUrl,
      pageSetup,
      this.$services,
      client,
      evaluation,
      tool,
      this.$me
    );
  }

  /**
   * Generates a profile report for YLS/CMI Evaluations.
   *
   * @param client ...
   * @param evaluation ...
   * @param tool ...
   * @param options ...
   * @return ...
   */
  async generateYLSProfileReport(
    client: Client,
    evaluation: Evaluation,
    tool: Tool,
    options: unknown
  ) {
    let mhsLogoDataUrl = imageData.mhsLogo();
    let ylsTitleDataUrl = ylsCasePlanImageData.ylsTitle();

    return await ylsProfileReportIndex.generate(
      mhsLogoDataUrl,
      ylsTitleDataUrl,
      pageSetup,
      this.$services,
      client,
      evaluation,
      tool,
      options,
      this.$me
    );
  }

  /**
   * ...
   *
   * @param client ...
   * @param evaluation ...
   * @param tool ...
   * @param options ...
   * @return ...
   */
  async generateHarePCLRProfileReport(
    client: Client,
    evaluation: Evaluation,
    tool: Tool,
    options: unknown
  ) {
    let mhsLogoDataUrl = imageData.mhsLogo();
    let harePCLRLogoDataUrl = imageData.harePCLRLogo();

    return await harePCLRProfileReportIndex.generate(
      mhsLogoDataUrl,
      harePCLRLogoDataUrl,
      pageSetup,
      this.$services,
      client,
      evaluation,
      tool,
      options,
      this.$me
    );
  }

  /**
   * ...
   *
   * @param client ...
   * @param evaluation ...
   * @param tool ...
   * @param options ...
   * @return ...
   */
  async generateLSIRProfileReport(
    client: Client,
    evaluation: Evaluation,
    tool: Tool,
    options: unknown
  ) {
    let mhsLogoDataUrl = imageData.mhsLogo();
    let lsirLogoDataUrl = imageData.lsirLogo();

    return await lsirProfileReportIndex.generate(
      mhsLogoDataUrl,
      lsirLogoDataUrl,
      pageSetup,
      this.$services,
      client,
      evaluation,
      tool,
      options,
      this.$me
    );
  }

  /**
   * ...
   *
   * @param client ...
   * @param evaluation ...
   * @param tool ...
   * @param options ...
   * @return ...
   */
  async generateACESProfileReport(
    client: Client,
    evaluation: Evaluation,
    tool: Tool,
    options: unknown
  ) {
    let mhsLogoDataUrl = imageData.mhsLogo();

    return await acesProfileReportIndex.generate(
      mhsLogoDataUrl,
      pageSetup,
      this.$services,
      client,
      evaluation,
      tool,
      options,
      this.$me
    );
  }

  /**
   * ...
   *
   * @param client ...
   * @param caseplan ...
   * @param callback ...
   * @return ...
   */
  async generateLSCMICaseManagementReport(
    client: Client,
    caseplan: CasePlan,
    callback: (...args: unknown[]) => void
  ) {
    // generates a case plan report for YLS/CMI Case Plans
    caseplan = angular.copy(caseplan);
    if (caseplan.plan) caseplan = caseplan.plan;
    //https://dataurl.sveinbjorn.org/#dataurlmaker
    let mhsLogoDataUrl = imageData.mhsLogo();
    let lscmiLogoDataUrl = lscmiLogoImageData.lscmiLogo();

    return await lscmiCasePlanReportIndex.generate(
      mhsLogoDataUrl,
      lscmiLogoDataUrl,
      pageSetup,
      this.$services,
      client,
      caseplan,
      callback,
      this.$me
    );
  }

  /**
   * ...
   *
   * @param client ...
   * @param caseplan ...
   * @param callback ...
   * @return ...
   */
  async generateYLSCaseManagementReport(
    client: Client,
    caseplan: CasePlan,
    callback: (...args: unknown[]) => void
  ) {
    // generates a case plan report for YLS/CMI Case Plans
    caseplan = angular.copy(caseplan);
    if (caseplan.plan) caseplan = caseplan.plan;
    //https://dataurl.sveinbjorn.org/#dataurlmaker
    let mhsLogoDataUrl = imageData.mhsLogo();
    let ylsTitleDataUrl = ylsCasePlanImageData.ylsTitle();

    return await ylsCasePlanReportIndex.generate(
      mhsLogoDataUrl,
      ylsTitleDataUrl,
      pageSetup,
      this.$services,
      client,
      caseplan,
      callback,
      this.$me
    );
  }

  /**
   * ...
   *
   * @param client ...
   * @param tool ...
   * @param evaluations ...
   * @param options ...
   * @return ...
   */
  async generateYLSComparativeReport(
    client: Client,
    tool: Tool,
    evaluations: Evaluation[],
    options: unknown
  ) {
    // generates a comparative report object
    let mhsLogoDataUrl = imageData.mhsLogo();
    let ylsTitleDataUrl = ylsCasePlanImageData.ylsTitle();

    return await ylsComparativeReportIndex.createReport(
      mhsLogoDataUrl,
      ylsTitleDataUrl,
      client,
      evaluations,
      tool,
      this.$services,
      pageSetup,
      options
    );
  }

  /**
   * ...
   *
   * @param client ...
   * @param evaluations ...
   * @param tool ...
   * @param report ...
   * @return ...
   */
  async downloadComparativeReport(
    client: Client,
    evaluations: Evaluation[],
    tool: Tool,
    report: Report
  ) {
    let mhsLogoDataUrl = imageData.mhsLogo();

    return await generateComparativeReportIndex(
      mhsLogoDataUrl,
      client,
      evaluations,
      tool,
      report,
      this.$services,
      pageSetup,
      tool.name
    );
  }

  /**
   * ...
   *
   * @param client ...
   * @param tool ...
   * @param evaluations ...
   * @param options ...
   * @return ...
   */
  async generateLSCMIComparativeReport(
    client: Client,
    tool: Tool,
    evaluations: Evaluation[],
    options: unknown
  ) {
    // generates a comparative report object
    let mhsLogoDataUrl = imageData.mhsLogo();
    let lscmiLogoDataUrl = lscmiLogoImageData.lscmiLogo();

    return await lscmiComparativeReportIndex.createReport(
      mhsLogoDataUrl,
      lscmiLogoDataUrl,
      client,
      evaluations,
      tool,
      this.$services,
      pageSetup,
      options
    );
  }
}

export default angular
  .module('app.$generatePdf', [])
  .service('$generatePdf', GeneratePdfService).name;
