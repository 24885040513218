'use strict';

function NotificationsStore($api) {
  'ngInject';

  return {
    state: {
      items: {
        instTransfers: [],
        evlReqs: [],
        clientReqs: []
      }
    },
    getters: {
      all: (state) => {
        return [
          ...state.items.instTransfers,
          ...state.items.evlRequests,
          ...state.items.clientReqs
        ];
      }
    },
    mutations: {
      add(state, payload) {
        if (!payload.type || !(payload.type in state.items)) {
          return;
        }

        state.items[payload.type].push(payload.data);
      },
      // update(state, data) {},
      delete(state, data) {},
      CLEAR(state) {
        Object.assign(state, {
          items: {
            instTransfers: [],
            evlReqs: [],
            clientReqs: []
          }
        });
      }
    }
  };
}

export default NotificationsStore;
