'use strict';

const routeMapLegacy = {
  institutionTransfers: {
    base: '/institution-transfers',
    methods: {
      get: {
        accept: '/:id/accept',
        deny: '/:id/deny',
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  apiClients: {
    base: '/api-clients',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  assignedRoles: {
    base: '/assigned-roles',
    methods: {
      get: {
        getUserRoles: '/:userId',
        list: '/'
      },
      post: {
        create: '/',
        upsert: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  braintree: {
    base: '/braintree',
    methods: {
      get: {
        getToken: '/:id/token'
      },
      post: {
        checkout: '/checkout'
      }
    }
  },
  casePlans: {
    base: '/case-plans',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  clients: {
    base: '/clients',
    methods: {
      get: {
        getByPublicId: '/public/:id',
        getClient: '/:id',
        listCasePlans: '/:id/case-plans',
        listClients: '/',
        listContacts: '/:id/contacts',
        listEvaluationRequests: '/:id/evaluation-requests',
        listEvaluations: '/:id/evaluations',
        listEvaluators: '/:id/evaluators',
        listReports: '/:id/reports'
      },
      post: {
        createClient: '/',
        createClientContact: '/:id/contacts',
        updateClient: '/:id'
      },
      delete: {
        deleteClient: '/:id'
      }
    }
  },
  clientContacts: {
    base: '/client-contacts',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/',
        upsert: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  clientRequests: {
    base: '/client-requests',
    methods: {
      get: {
        accept: '/:key/accept',
        deny: '/:key/deny',
        get: '/:key',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:key'
      },
      delete: {
        delete: '/:key'
      }
    }
  },
  codingForms: {
    base: '/coding-forms',
    methods: {
      get: {
        getCodingForm: '/:id',
        listAllCodingForms: '/'
      },
      post: {
        createCodingForm: '/'
      }
    }
  },
  debug: {
    base: '/debug',
    methods: {
      post: {
        create: '/'
      }
    }
  },
  demoRequest: {
    base: '/demo-request',
    methods: {
      post: {
        create: '/'
      }
    }
  },
  descriptionPdfs: {
    base: '/description-pdfs/:id',
    methods: {
      get: {
        getDescriptionPdf: '/'
      }
    }
  },
  developerGroups: {
    base: '/developer-groups',
    methods: {
      get: {
        get: '/:id',
        getInvites: '/:id/invites',
        getTools: '/:id/tools',
        getUsers: '/:id/users',
        list: '/'
      },
      post: {
        addUser: '/:id/users/add',
        create: '/',
        upsert: '/:id'
      },
      delete: {
        delete: '/:id',
        removeUser: '/:id/users/remove'
      }
    }
  },
  developerTransfers: {
    base: '/developer-transfers',
    methods: {
      get: {
        accept: '/:id/accept',
        deny: '/:id/deny',
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  evaluations: {
    base: '/evaluations',
    methods: {
      get: {
        get: '/:id',
        getReports: '/:id/reports',
        list: '/',
        revert: '/:id/revert'
      },
      post: {
        autoSave: '/:id/save',
        create: '/',
        submit: '/:id/submit'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  evaluationRequests: {
    base: '/evaluation-requests',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/',
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  institutions: {
    base: '/institutions',
    methods: {
      get: {
        getCasePlanTemplateCommit:
          '/:id/case-plan-template/:tmpltId/commits/:commitId',
        getClientCasePlan: '/:id/clients/:clntId/case-plans/:casePlanId',
        getClientEvaluation:
          '/:id/subgroups/:sbGrpId/clients/:clntId/evaluations/:evalId',
        listCasePlanTemplateCommits:
          '/:id/case-plan-templates/:tmpltId/commits',
        listCasePlanTemplates: '/:id/case-plan-templates',
        listClientCasePlans: '/:id/clients/:clntId/case-plans',
        listClientContacts: '/:id/subgroups/:sbGrpId/clients/:clntId/contacts',
        listClientEvaluations:
          '/:id/subgroups/:sbGrpId/clients/:clntId/evaluations',
        listClientEvaluators:
          '/:id/subgroups/:sbGrpId/clients/:clntId/evaluators',
        listClients: '/:id/clients',
        listEvaluationRequests: '/:id/evaluation-requests',
        listEvaluations: '/:id/evaluations',
        listIncomingClientRequests: '/:id/incoming-client-requests',
        listInstitutionInvoices: '/:id/invoices',
        listInstitutionTools: '/:id/tools',
        listInstitutionTransactions: '/:id/transactions',
        listInstitutionUsers: '/:id/users',
        listInvites: '/:id/invites',
        listOutgoingClientRequests: '/:id/outgoing-client-requests',
        listRegions: '/:id/regions',
        listSubgroupClients: '/:id/subgroups/:sbGrpId/clients',
        listSubgroups: '/:id/subgroups',
        listZones: '/:id/zones'
      },
      post: {
        addClientToSubgroup: '/:id/subgroups/add-client',
        addUser: '/:id/users/add',
        assignPolicyToUser: '/:id/policies/assign-policy',
        commitCasePlanTemplate: '/:id/case-plan-templates/:tmpltId/commit',
        createClientCasePlan: '/:id/clients/:clntId/case-plans',
        createClientEvaluation:
          '/:id/subgroups/:sbGrpId/clients/:clntId/evaluations',
        createInstitution: '/',
        createPolicy: '/:id/policies',
        createRegion: '/:id/regions',
        createSubgroup: '/:id/subgroups',
        importClients: '/:id/import',
        removeClientFromSubgroup: '/:id/subgroups/remove-client',
        setCasePlanTemplateCommitStatus:
          '/:id/case-plan-template/:tmpltId/commits/:commitId/status',
        tagCasePlanTemplateCommit:
          '/:id/case-plan-template/:tmpltId/commits/:commitId/tag',
        updateClientCasePlan: '/:id/clients/:clntId/case-plans/:casePlanId',
        updateClientCasePlanStatus:
          '/:id/clients/:clntId/case-plans/:casePlanId/status',
        updateInstitution: '/:id',
        updateUserCap: '/:id/update-user-cap'
      },
      delete: {
        deleteInstitution: '/:id',
        removePolicyFromUser: '/:id/policies/remove-policy',
        removeUser: '/:id/users/remove'
      }
    }
  },
  institutionTools: {
    base: '/institution-tools',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  assignedPolicies: {
    base: '/assigned-policies',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  invites: {
    base: '/invites',
    methods: {
      get: {
        get: '/:id',
        list: '/',
        resendInvite: '/:id/resend'
      },
      post: {
        createInvite: '/'
      },
      delete: {
        deleteInvite: '/:id'
      }
    }
  },
  invoices: {
    base: '/invoices',
    methods: {
      get: {
        get: '/:id',
        list: '/',
        sendEmail: '/:id/email'
      },
      post: {
        credit: '/:id/credit',
        update: '/:id'
      }
    }
  },
  logs: {
    base: '/logs',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  managedCasePlanTemplates: {
    base: '/managed-case-plan-templates',
    methods: {
      get: {
        get: '/:id',
        getByVersion: '/:id/version/:version',
        getCommits: '/:id/commits',
        listManagedCasePlanTemplates: '/'
      },
      post: {
        commitManagedCasePlanTemplate: '/:id/commit',
        create: '/',
        upsert: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  managedCpTemplateCommits: {
    base: '/managed-cp-template-commits/:id',
    methods: {
      get: {
        getCommit: '/'
      },
      post: {
        status: '/status',
        tag: '/tag'
      }
    }
  },
  multiToolDiscounts: {
    base: '/multi-tool-discounts',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/',
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  passwordResets: {
    base: '/password-resets',
    methods: {
      get: {
        get: '/:id'
      },
      post: {
        createV2: '/'
      }
    }
  },
  reports: {
    base: '/reports',
    methods: {
      get: {
        get: '/:id',
        getDocv2: '/:id/doc',
        list: '/',
        pdf: '/:id/pdf'
      },
      post: {
        create: '/',
        emailRequest: '/:id/email',
        upsert: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  reportTemplates: {
    base: '/report-templates',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  reportTemplateCommits: {
    base: '/report-template-commits',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  roles: {
    base: '/roles',
    methods: {
      get: {
        list: '/'
      },
      post: {
        create: '/'
      }
    }
  },
  roleGroups: {
    base: '/role-groups',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  services: {
    base: '/services',
    methods: {
      get: {
        get: '/:id',
        list: '/'
      },
      post: {
        create: '/'
      },
      put: {
        upsert: '/:id'
      },
      patch: {
        patch: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  templates: {
    base: '/templates',
    methods: {
      get: {
        get: '/:id',
        getImages: '/:id/get-images',
        getVersion: '/:id/version/:version',
        list: '/',
        listReportTemplateCommits: '/:id/commits'
      },
      post: {
        addImages: '/:id/add-images',
        commitTemplate: '/:id/commit',
        createV2: '/',
        upsert: '/:id'
      },
      delete: {
        delete: '/:id'
      }
    }
  },
  templateCommits: {
    base: '/template-commits',
    methods: {
      get: {
        getData: '/:id/data',
        getEditorData: '/:id/editor-data',
        list: '/'
      },
      post: {
        status: '/:id/status',
        tag: '/:id/tag'
      }
    }
  },
  token: {
    base: '/token',
    methods: {
      get: {
        auth2test: '/:id/param-test',
        convertCodingForms: '/convert-coding-forms',
        convertEvaluations: '/convert-evaluations',
        convertOldRolesToNew: '/convert-roles',
        convertReports: '/convert-reports',
        convertToolDataOnly: '/convert-tools-data',
        convertTools: '/convert-tools',
        list: '/',
        testIdToken: '/id-test'
      }
    }
  },
  tools: {
    base: '/tools',
    methods: {
      get: {
        getTool: '/:id',
        getToolCommit: '/:id/commits/:commitId',
        listAllTools: '/',
        listDescriptionPdfs: '/:id/description-pdfs',
        listInstitutionCasePlanTemplates:
          '/:id/institution-case-plan-templates',
        listLiveTools: '/live',
        listManagedCasePlanTemplates: '/:id/managed-case-plan-templates',
        listToolCodingForms: '/:id/coding-forms',
        listToolCommits: '/:id/commits',
        listToolPdfs: '/:id/tool-pdfs',
        listToolTemplates: '/:id/templates'
      },
      post: {
        addChild: '/:id/add-child',
        addDescriptionPdf: '/:id/description-pdf',
        addToolPdf: '/:id/tool-pdf',
        changePrice: '/:id/price',
        commitTool: '/:id/commit',
        createTool: '/',
        removeChild: '/:id/remove-child',
        setToolCommitStatus: '/:id/commits/:commitId/status',
        tagToolCommit: '/:id/commits/:commitId/tag'
      },
      delete: {
        deleteTool: '/:id'
      }
    }
  },
  toolCommits: {
    base: '/tool-commits/:id',
    methods: {
      get: {
        getData: '/data',
        getEditorData: '/editor-data'
      },
      post: {
        addCodingForm: '/coding-form',
        addDescriptionPdf: '/description-pdf',
        addToolPdf: '/tool-pdf',
        setLiveCodingForm: '/set-live-coding-form',
        setLiveDescriptionPdf: '/set-live-description-pdf',
        setLiveToolPdf: '/set-live-tool-pdf',
        status: '/status',
        tag: '/tag'
      }
    }
  },
  toolPdfs: {
    base: '/tool-pdfs/:id',
    methods: {
      get: {
        getToolPdf: '/'
      }
    }
  },
  users: {
    base: '/users',
    methods: {
      get: {
        getClients: '/:id/clients',
        getEvaluationRequests: '/:id/evaluation-requests',
        getEvaluations: '/:id/evaluations',
        getMe: '/me',
        getTransferRequests: '/:id/super-admin-transfer',
        getUser: '/:id',
        getAll: '/',
        listReports: '/:id/reports'
      },
      post: {
        createUser: '/',
        resetPassword2: '/reset-password',
        updateMe: '/:id'
      },
      put: {
        changePasswordv2: '/:id/password'
      },
      delete: {
        deleteUser: '/:id'
      }
    }
  },
  version: {
    base: '/version',
    methods: {
      get: {
        get: '/'
      }
    }
  }
};

export default routeMapLegacy;
