import angular, { Controller, Inject } from '@angular';

@Controller
class LoginView {
  @Inject $scope;
  @Inject $element;
  @Inject $timeout;
  @Inject $auth;
  @Inject $http;
  @Inject $state;
  @Inject $location;
  @Inject $stateParams;
  @Inject $api;
  @Inject utils;
  @Inject notify;
  @Inject Notification;

  $onInit() {
    this.firstTime = this.$location.search().first
      ? this.$location.search().first
      : this.$stateParams.first;

    this.user = {
      name: '',
      email: '',
      password: ''
    };

    this.resetPass = {
      code: '',
      password: '',
      confirmPassword: ''
    };

    this.errors = {
      login: undefined
    };

    this.submitted = false;
    this.forgotPass = false;
    this.confirmPass = false;
  }

  checkPassword(passwordField) {
    var pass = passwordField.$modelValue;

    if (pass.length < 8) {
      passwordField.$valid = false;
    } else {
      var hasUpperCase = /[A-Z]/.test(pass);
      var hasLowerCase = /[a-z]/.test(pass);
      var hasNumbers = /\d/.test(pass);
      var hasNonalphas = /\W/.test(pass);
      if (hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas < 3) {
        passwordField.$valid = false;
      } else {
        passwordField.$valid = true;
      }
    }
  }

  confirmPW(pw, passwordField) {
    if (pw.$modelValue !== passwordField.$modelValue) {
      passwordField.$valid = false;
    } else {
      passwordField.$valid = true;
    }
  }

  isFormValid(form) {
    if (this.firstTime) {
      return (
        form.email.$valid &&
        form.password.$valid &&
        form.newPassword.$valid &&
        form.confirmNewPassword.$valid
      );
    }

    return form.email.$valid && form.password.$valid;
  }

  async login(form, event) {
    this.submitted = true;

    if (!form.$valid) {
      return;
    }

    var userData;

    let email = angular.copy(this.user.email);
    email = email.toLowerCase();

    try {
      userData = await this.$auth.login(
        {
          email,
          password: this.user.password
        },
        event
      );
    } catch (err) {
      console.error(err);
      this.submitted = false;
      this.utils.notify('error', err);

      return;
    }

    if (!userData) {
      this.submitted = false;
      return;
    }

    this.utils.notify('success', `Welcome to MHS GEARS, ${userData.fName}`);
    this.submitted = false;
  }

  async forgotPass1(form) {
    if (!form.$valid) {
      return;
    }

    try {
      // let res = await this.$http.post('/api/password-resets', {
      //   email: this.user.email
      // });

      let email = angular.copy(this.user.email);
      email = email.toLowerCase();

      let res = await this.$api.base.passwordReset({ email });

      this.utils.notify('success', {
        title: 'Password Reset Request Submitted',
        message:
          'If you have entered a valid/registered email account you will receive an email shortly with a code to enter on this screen.',
        delay: true
      });

      this.forgotPass = false;
      this.confirmPass = true;
    } catch (err) {
      this.notify.display(err, 'error');
    }
  }

  async submitConfirmPass(form) {
    if (!form.$valid) {
      return;
    }

    let email = angular.copy(this.user.email);
    email = email.toLowerCase();

    try {
      await this.$api.user.resetPassword({
        email,
        code: this.resetPass.code,
        password: this.resetPass.password
      });
      this.confirmPass = false;
      this.user.email = '';
      this.resetPass.code = '';
      this.resetPass.password = '';

      this.notify.display('Password Reset Success', 'success');
    } catch (err) {
      this.notify.display(err, 'error');
    }
  }

  checkPasswordMatch() {
    if (this.resetPass.password && this.resetPass.confirmPassword) {
      return (
        this.resetPass.password.toString() ===
        this.resetPass.confirmPassword.toString()
      );
    }

    return false;
  }

  // checkPassword(passwordField) {
  //   let pass = passwordField.$modelValue;
  //
  //   if (!pass || (pass && pass.length < 8)) {
  //     passwordField.$valid = false;
  //     return;
  //   }
  //
  //   let hasUpperCase = /[A-Z]/.test(pass);
  //   let hasLowerCase = /[a-z]/.test(pass);
  //   let hasNumbers = /\d/.test(pass);
  //   let hasNonalphas = /\W/.test(pass);
  //
  //   passwordField.$valid =
  //     hasUpperCase + hasLowerCase + hasNumbers + hasNonalphas >= 3;
  // }

  resetPassInvalid(confirmPassForm) {
    let invalid = false;

    if (!this.resetPass.code) {
      invalid = true;
    }

    if (confirmPassForm.email.$invalid) {
      invalid = true;
    }

    if (this.resetPass.password && this.resetPass.confirmPassword) {
      if (
        this.resetPass.password.toString() !==
        this.resetPass.confirmPassword.toString()
      ) {
        invalid = true;
      }
    } else {
      invalid = true;
    }

    return invalid;
  }

  toggleForgotForm() {
    this.forgotPass = !this.forgotPass;
  }

  toggleConfirmForm() {
    this.confirmPass = !this.confirmPass;
  }
}

export default LoginView;
