import angular from 'angular';

declare module 'angular' {
  namespace gears {
    type IAnchorSmoothScrollService = AnchorSmoothScrollService;
  }
}

/**
 * ...
 *
 * @return ...
 */
function currentYPosition() {
  // Firefox, Chrome, Opera, Safari
  if (window.pageYOffset) {
    return window.pageYOffset;
  }

  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop) {
    return document.documentElement.scrollTop;
  }

  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) {
    return document.body.scrollTop;
  }

  return 0;
}

/**
 * ...
 *
 * @param elementId ...
 * @return ...
 */
function getElementVerticalPosition(elementId: string) {
  let elem = document.getElementById(elementId);

  if (!elem) {
    throw new Error(`An element with ID "${elementId}" could not be found.`);
  }

  let y = elem.offsetTop;

  while (elem?.offsetParent && elem.offsetParent !== document.body) {
    elem = elem.offsetParent as HTMLElement;
    y += elem.offsetTop;
  }

  return y;
}

export class AnchorSmoothScrollService {
  /**
   * This scrolling function is from:
   * http://www.itnewb.com/tutorial/Creating-the-Smooth-Scroll-Effect-with-JavaScript
   *
   * @param elementId ID of the element to scroll to.
   */
  scrollTo(elementId: string) {
    const startY = currentYPosition();
    const stopY = getElementVerticalPosition(elementId);

    const distance = stopY > startY ? stopY - startY : startY - stopY;

    if (distance < 100) {
      return scrollTo(0, stopY);
    }

    let speed = Math.round(distance / 100);

    if (speed >= 20) speed = 20;

    const step = Math.round(distance / 25);
    let leapY = stopY > startY ? startY + step : startY - step;

    let timer = 0;

    if (stopY > startY) {
      for (let i = startY; i < stopY; i += step) {
        setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);

        leapY += step;

        if (leapY > stopY) leapY = stopY;

        timer++;
      }

      return;
    }

    for (let i = startY; i > stopY; i -= step) {
      setTimeout('window.scrollTo(0, ' + leapY + ')', timer * speed);

      leapY -= step;

      if (leapY < stopY) leapY = stopY;

      timer++;
    }
  }
}

export default angular
  .module('app.anchorSmoothScroll', [])
  .service('anchorSmoothScroll', AnchorSmoothScrollService).name;
