'use strict';

import angular from 'angular';

function evlAnswerFilter() {
  'ngInject';

  return function evlAnswer({ hideScore, text, score }) {
    return hideScore ? `${text}` : `${score} | ${text}`;
  };
}

export default angular
  .module('app.evlAnswer', [])
  .filter('evlAnswer', evlAnswerFilter).name;
