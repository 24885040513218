import { State, Getter, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import pdfObject from 'pdfobject';

@Controller
class DashboardEvaluationsPerformView {
  evaluation: Evaluation = null;
  autoSaving: any;
  randomizeEvalTimes: number = 1;
  randomizeAnswers: boolean = false;

  @Inject $store;
  @Inject $state;
  @Inject $location;
  @Inject $modals;
  @Inject evlUtils;

  @State me;
  @Getter isAdmin;
  @Getter isDev;

  @Action('evaluations/list') listEvaluations;
  @Action('evaluations/get') getEvaluation;
  @Action('clients/list') listClients;
  @Action('users/list') listUsers;
  @Action('tools/list') listTools;

  get client() {
    return this.evlUtils.client;
  }

  get retrievingTool() {
    return this.evlUtils.loadingTool;
  }

  get calculatingEvaluation() {
    return this.evlUtils.calculatingEvaluation;
  }

  get loading() {
    return this.evlUtils.loading;
  }

  get users() {
    return this.$store.state.users.items;
  }

  get tools() {
    return this.$store.state.tools.items;
  }

  get processing() {
    return this.evlUtils.processing;
  }

  get evaluationScoreTotal() {
    return this.evlUtils.evlScoreTotal;
  }

  get progressBar() {
    return this.evlUtils.progressBar;
  }

  get cfis() {
    return this.evaluation.tool.codingFormItems;
  }

  get reassessmentData() {
    return this.evaluation.evaluation.reassessmentData;
  }

  get isLoading() {
    return this.$store.state.isLoading;
  }

  async $onInit() {
    await this.ensureEvaluation();

    if (this.evaluation?.pdf) {
      let options = {
        pdfOpenParams: {
          view: 'FitV',
          page: '1',
          pagemode: 'none',
          toolbar: 0
        },
        PDFJS_URL: 'assets/components/pdfjs/build/generic/web/viewer.html'
      };

      if (
        this.$store.state.isIE ||
        this.$store.state.isEdge ||
        this.$store.state.isSafari
      )
        options.forcePDFJS = true;
      pdfObject.embed(this.evaluation.pdf, '#pdfobject-container', options);
    }

    if (!this.users?.length) await this.listUsers();
  }

  async ensureEvaluation() {
    this.evaluation = this.evlUtils.evaluation;

    if (this.evaluation)
      return this.$location.search('evalId', [this.evaluation.evaluationId]);

    let { evalId } = this.$location.search();
    if (!evalId || evalId === 'undefined')
      return this.$state.go('dashboardClients');

    if (!this.$store.state.clients.items.length) {
      this.$store.commit('setLoadingMessage', 'Loading Clients...');
      this.$store.commit('setIsLoading', true);
      await this.listClients();
      this.$store.commit('setIsLoading', false);
    }

    if (!this.tools.length) {
      this.$store.commit('setLoadingMessage', 'Loading Tools...');
      this.$store.commit('setIsLoading', true);
      await this.listTools();
      this.$store.commit('setIsLoading', false);
    }

    if (!this.$store.state.evaluations.items.length) {
      this.$store.commit('setLoadingMessage', 'Loading Evaluations...');
      this.$store.commit('setIsLoading', true);
      await this.listEvaluations();
      this.$store.commit('setIsLoading', false);
    }

    const evalObj = this.$store.state.evaluations.items.find(
      ({ id }) => id === evalId
    );

    await this.evlUtils.continueEvaluation(evalObj);

    this.evaluation = this.evlUtils.evaluation;

    if (!this.evaluation) {
      this.$state.go('dashboardClients');
    }
  }

  evaluationInvalid() {
    return this.evlUtils.evaluationInvalid();
  }

  cancelInterval() {
    this.evlUtils.cancelInterval();
  }

  showEvaluations(row, noRow) {
    // this.evlUtils.showEvaluations(row, noRow);
    this.cancelInterval();
    this.$state.go('dashboardEvaluations', { clientId: this.client.id });
  }

  evaluationAgreement() {
    this.evlUtils.evaluationAgreement();
  }

  evaluationToolChosen(isTest) {
    this.evlUtils.evaluationToolChosen(isTest);
  }

  openDateCalculator() {
    this.$modals.evaluation.dateCalculator();
  }

  openMediaTable() {
    this.$modals.evaluation.manageMedia({
      institutionId: this.evaluation.client?.institutionId,
      subGroupId: this.evaluation.client?.subGroup?.id,
      clientId: this.evaluation.client?.id,
      evaluationId: this.evaluation.evaluationId
    });
  }

  randomizeEvaluation() {
    return this.evlUtils.randomizeEvaluation(
      this.randomizeEvalTimes,
      this.randomizeAnswers
    );
  }

  resetEvaluation() {
    if (!this.evlUtils.isReassessment) {
      return;
    }

    this.$scope.$broadcast('reset');
  }

  revertEvaluationWarning(fromEvalList) {
    this.evlUtils.updateEvaluationQuestionData(fromEvalList);
  }

  checkCanadaCopyright(toolName) {
    let acceptableNames = [
      'STATIC-99R',
      'STATIC-99R Dev',
      'STABLE-2007',
      'ACUTE-2007'
    ];
    return acceptableNames.includes(toolName);
  }

  checkToolNotes(notesRequired, tool, notes) {
    if (!notesRequired) return;
    if (!notes?.length) {
      tool.valid = false;
      if (!tool.invalidItems) tool.invalidItems = [];
      tool.invalidItems.push('Comments Required');
    } else if (
      !tool.invalidItems ||
      tool.invalidItems[0] === 'Comments Required'
    ) {
      tool.invalidItems = [];
      tool.valid = true;
    }
  }
}

export default DashboardEvaluationsPerformView;
