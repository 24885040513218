import angular from 'angular';

/**
 * ...
 */
export type PrototypeProviderConfiguration =
  | PrototypeProviderOptions
  | ((...args: unknown[]) => PrototypeProviderOptions);

/**
 * ...
 */
export interface PrototypeProviderOptions {
  globals?: Record<string, unknown>;
  factories?: Record<string, new () => unknown>;
  getters?: Record<string, () => unknown>;
}

declare module 'angular' {
  namespace gears {
    /**
     * ...
     */
    type IPrototypeProvider = $PrototypeProvider;
  }
}

// Invoke $prototype at runtime so augmentations are applied.
function onRun($prototype: angular.gears.IPrototypeProvider) {
  'ngInject';

  if (process.env.NODE_ENV === 'development') {
    console.log('$prototype provider initialized.', $prototype);
  }
}

class $PrototypeProvider {
  private _config: PrototypeProviderConfiguration | null = null;

  /**
   * ...
   */
  get config() {
    return this._config;
  }

  /**
   * ...
   *
   * @param config ...
   */
  set(config: PrototypeProviderConfiguration) {
    this._config = config;
  }

  $get = (
    $rootScope: angular.IRootScopeService,
    $injector: angular.auto.IInjectorService
  ) => {
    'ngInject';

    const $prototype = Object.getPrototypeOf($rootScope);

    const addProp = (key: string, val: unknown, isGetter = false) => {
      const options: PropertyDescriptor = { enumerable: true };

      if (isGetter && typeof val === 'function') {
        options.get = val as () => any;
      } else {
        options.writable = false;
        options.value = val;
      }

      Object.defineProperty($prototype, key, options);
    };

    let { globals, factories, getters } =
      typeof this.config === 'function'
        ? $injector.invoke<PrototypeProviderOptions>(this.config)
        : this.config || {};

    if (globals && typeof globals == 'object') {
      for (let [key, val] of Object.entries(globals)) {
        addProp(key, val);
      }
    }

    if (factories && typeof factories == 'object') {
      for (let [key, val] of Object.entries(factories)) {
        addProp(key, () => new val());
      }
    }

    if (getters && typeof getters == 'object') {
      for (let [key, val] of Object.entries(getters)) {
        addProp(key, val, true);
      }
    }

    return $prototype;
  };
}

export default angular
  .module('ngPrototype', [])
  .provider('$prototype', $PrototypeProvider)
  .run(onRun).name;
