import {
  SERVICE_MAP,
  ServiceKey,
  ServiceAddress,
  ResourceMap,
  PermissionProfile,
  ResourceDescriptor,
  AccessCheck,
  AccessCheckGroup
} from '@interfaces/permissions';
import { makeArray } from '@services/utils';

/**
 * ...
 */
export type HasAccessCheckOptions =
  | AccessCheck
  | (AccessCheck | AccessCheckGroup)[];

class AccessCheckError extends Error {
  constructor(checkValue: AccessCheck) {
    super(
      'An invalid value was passed:\n' + JSON.stringify(checkValue, null, 4)
    );

    this.name = 'AccessCheckError';
    this.message = 'An invalid value was passed';
  }
}

/**
 * ...
 *
 * @param resource ...
 * @param descriptor ...
 * @return ...
 */
function checkResource(
  resources: '*' | ResourceMap,
  descriptor: ResourceDescriptor
) {
  const { name, values } = descriptor;

  // Resource check PASSES if...

  // All resources are permitted IMPLICITLY; eg. -- (resources: '*')
  if (resources === '*') return true;

  // If resource type/name is NOT included under the action.
  if (name in resources === false) return false;

  const resource = resources[name];

  // All resources of specified type/name are permitted IMPLICITLY;
  // eg. -- (resources: [ 'grn:gifr:myservice::0:{name}:*' ])
  if (resource === '*') return true;

  // All specified values for selected resource type/name addResource permitted
  // under target action;
  // eg. -- (resources: [ 'grn:gifr:myservice::0:{name}:{values[0]}' ])
  if (makeArray(values).every((value) => value in resource)) return true;

  // Check FAILED.
  return false;
}

/**
 * ...
 *
 * @param statement ...
 * @param profile ...
 * @return ...
 */
function evaluatePermissionsStatement(
  statement: ServiceAddress,
  profile: PermissionProfile
) {
  return statement
    .split('||')
    .map((item) => item.trim())
    .some((item) => hasAccess(item as ServiceAddress, profile));
}

/**
 * Ensure provided value is a valid service address and swap any aliases used
 * with the actualy value.
 *
 * @param address Address value to process.
 * @return Valid service address.
 */
function processServiceAddress(address: string) {
  // let [srv, act] = (address.match(/(.*)[:./](.*)/) || []).splice(1, 2);
  const [service, action] = address.split(':');

  if (service in SERVICE_MAP === false) {
    throw new Error(
      `[$acl] An invalid value for an action SRV "${service}" was parsed.`
    );
  }

  return `${SERVICE_MAP[service as ServiceKey]}:${action}` as ServiceAddress;
}

/**
 * ...
 *
 * @param check ...
 * @param profile ...
 * @return ...
 */
function performAccessCheck(
  check: AccessCheck | AccessCheckGroup,
  profile: PermissionProfile
) {
  if (!check) {
    throw new AccessCheckError(check);
  }

  // If the check expression is itself a group of checks, evaluate them all
  // individualy. Register a pass as long as one of the evaluated checks passes.
  if (Array.isArray(check)) {
    return check.some((item) => hasAccess(item, profile));
  }

  // References for target action and any resource(s)
  let address: ServiceAddress | null = null;
  let resources: ResourceDescriptor[] = [];

  // If the feature is a config object, validate it and reference its valus
  // appropriatly.
  if (typeof check === 'object') {
    address = check.action;
    resources = check.resources ? makeArray(check.resources) : [];
  } else if (typeof check === 'string') {
    address = check;
  }

  if (typeof address !== 'string') {
    throw new AccessCheckError(check);
  }

  // Support for OR (||) convention -- will PASS if at least 1 address
  // check from the parsed address strings passes its check.
  if (address.includes('||')) {
    return evaluatePermissionsStatement(address, profile);
  }

  // ACTION check ----------------------------------------------------------

  const { allowed, denied } = profile;

  // NOTE: Consider removing once typeing for `ServiceAddress` values has been
  // fully implemented.
  const sa = processServiceAddress(address);

  // FAIL if the action has been denied EXPLICITLY...
  if (denied.includes(sa)) return false;

  // FAIL if the action has NOT been allowed IMPLICITLY or EXPLICITLY...
  if ('*' in allowed === false && sa in allowed === false) return false;

  // RESOURCE check --------------------------------------------------------

  // Skip if no resources where provided.
  if (!resources.length) return true;

  // Check if action has accses to resource(s).
  return resources.every((resource) => {
    // The check PASSES if...

    // ...all actions are permitted IMPLICITLY and 'checkResource' PASSES...
    if ('*' in allowed && checkResource(allowed['*'], resource)) {
      return true;
    }

    // ...action is permitted EXPLICITLY and 'checkResource' PASSES.
    if (sa in allowed && checkResource(allowed[sa], resource)) {
      return true;
    }

    // The check FAILED.
    return false;
  });
}

/**
 * Perform any number of access checks for GEARS services against a provided
 * permission profile. If multiple checks are provided, all must pass for the
 * final result of the function itself to register as a pass, although
 * additional nested lists of checks can be passed themselves as a standalone
 * check to formulate "or" clauses.
 *
 * @param checks Access check statement(s) to evaluate. This can be a single
 * check (in the form of a service address string or permission descriptor
 * object), or a list of multiple checks. In the later scenario, nested lists of
 * checks can be passed in as well, which itself will register as a pass as long
 * as at least one of the checks contained within it passes.
 * @param profile Permission profile to perform the check against.
 * @return `true` if the overall check passes, otherwise `false`.
 */
export function hasAccess(
  checks: HasAccessCheckOptions,
  profile: PermissionProfile
): boolean {
  if (!profile || typeof profile !== 'object') {
    throw new Error('[acl] Invalid permissions profile passed.');
  }

  // User has accsess to NOTHING if all actions have been IMPLICITLY denied.
  if (profile.denied === '*') return false;

  // Passes if every check passes...
  return makeArray(checks).every((check) => performAccessCheck(check, profile));
}

/**
 * ...
 */
export function AclService() {
  return hasAccess;
}
