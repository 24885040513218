'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class GeneralFormModalController extends Modal {
  @Inject $scope;
  @Inject TableViewDataTypes;

  $setup() {
    console.log('TableViewDataTypes', this.TableViewDataTypes);
    this.props = [];

    // TODO (Alex) mimic object structure from add-table-item example
    this.$props.items.forEach(prop => {
      console.log(prop);

      let propModel = Object.assign({}, prop);
      if (typeof propModel.type === 'string')
        propModel.type = this.TableViewDataTypes[propModel.type];
      propModel.type = propModel.type || this.TableViewDataTypes.string;
      propModel.value = prop.value || null;

      console.log(propModel);
      this.props.push(propModel);
    });
  }

  get newItem() {
    let item = {};

    this.props.forEach(prop => {
      item[prop.key] = prop.value;
    });

    return item;
  }

  get dataTypes() {
    return this.TableViewDataTypes;
  }

  save() {
    let item = this.newItem;
    console.log('ADD ITEM', item);
    this.$close(item);
  }
}

export default angular
  .module('app.generalFormModal', [])
  .directive('generalFormModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./general-form.html'),
    controller: GeneralFormModalController,
    controllerAs: 'vm'
  })).name;
