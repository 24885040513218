'use strict';

import angular from 'angular';

import ItemTable from './item-table/item-table.service';
import ItemTableController from './item-table/item-table.controller';
import SelectionTable from './selection-table/selection-table.service';
import SelectionTableController from './selection-table/selection-table.controller';

const TableViewDataTypes = {
  string: 0,
  number: 1,
  object: 2,
  function: 3,
  array: 4,
  date: 5,
  enum: 6,
  boolean: 7,
  // duration: 8,
  dateTime: 9,
  intervention: 10,
  intensity: 11
};

export default angular.module('app.module.viewTable', [])
  .value('TableViewDataTypes', TableViewDataTypes)
  .factory('ItemTable', () => ItemTable)
  .directive('itemTable', () => {
    return {
      template: require('./item-table/item-table.html'),
      restrict: 'E',
      scope: {
        table: '=',
        editable: '=',
        sortablerows: '=',
        forbidDeleteItems: '=',
        forbidEditItems: '='
      },
      replace: true,
      controller: ItemTableController,
      controllerAs: 'vm'
    };
  })
  .factory('SelectionTable', () => SelectionTable)
  .directive('selectionTable', () => {
    return {
      template: require('./selection-table/selection-table.html'),
      restrict: 'E',
      scope: {
        table: '=',
        editable: '=',
        sortablerows: '='
      },
      replace: true,
      controller: SelectionTableController,
      controllerAs: 'vm'
    };
  })
  .directive('tableCell', () => {
    return {
      template: require('./table-cell/table-cell.html')
    };
  })
  .name;
