import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class MultiSelector {
  @Inject $scope;
  @Inject SelectionTable;

  table = null;

  $onInit() {
    this.$scope.$watchCollection(
      () => this.viewModel,
      (...args) => this.onTableUpdated(...args)
    );
  }

  onTableUpdated(view) {
    if (!view) {
      this.table = null;

      return;
    }

    const vm = this;

    this.table = new this.SelectionTable({
      // title: view.title,
      hasItemsPerPage: false,
      hasTitle: false,
      hasSearchBar: vm.hasSearchBar,
      items: view.items,
      allowMultiple: vm.allowMultiple,
      itemsPerPage: view.itemsPerPage || 10,
      onSelection: selection => {
        vm.ngModel.$setViewValue(this.table.selection);
      }
    });

    for (let { title, key } of view.cols) {
      this.table.column({ title, key });
    }
  }
}

export default angular
  .module('app.component.multiSelector', [])
  .component('multiSelector', {
    template: require('./multi-selector.html'),
    require: { ngModel: 'ngModel' },
    bindings: { viewModel: '<', allowMultiple: '<', hasSearchBar: '<' },
    controller: MultiSelector,
    controllerAs: 'vm'
  }).name;
