import angular from 'angular';

//import braintree from 'braintree-web';

declare module 'angular' {
  namespace gears {
    type IBraintreeService = BraintreeService;
  }
}

export class BraintreeService {}

export default angular
  .module('app.braintree', [])
  .service('Braintree', BraintreeService).name;
