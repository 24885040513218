import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';
import { Reminder } from '@interfaces/reminder';
import { Tool } from '@interfaces/tool';
import { ClientModel } from '@models/client.model';

interface TypeOption {
  label: string;
  value: Reminder.Type;
}

interface ClientOptions {
  label: string;
  value: string;
}

interface ToolOption {
  label: string;
  value: number;
}

@Controller
class EditReminderModalController extends Modal {
  @Inject readonly $store!: angular.gears.IStoreService;

  processing = false;
  type: string = '';
  dueDate: string | null = null;
  sendEmail: boolean = false;
  alerts: number[] = [];
  tool: number | null = null;
  client: string | null = null;
  minDate = new Date();
  nextAlert = 1;
  types: TypeOption[] = [
    { label: 'Assessment Reminder', value: 'ASSESSMENT_REMINDER' }
  ];
  tools: ToolOption[] = [];
  clients: ClientOptions[] = [];
  oldVal: any = {};

  get reminder() {
    return this.$props.reminder as Reminder;
  }

  get submitable() {
    return (
      !this.$forms.editReminder.$invalid &&
      !this.$forms.editReminder.$pristine &&
      !this.processing
    );
  }

  async $setup() {
    this.loadingData = true;

    this.oldVal = angular.copy(this.reminder);

    this.type = this.reminder.type;
    this.dueDate = this.reminder.dueDate;
    this.tool = this.reminder.data.toolId;
    this.client = this.reminder.data.clientId;
    this.sendEmail = this.reminder.sendEmail;
    this.alerts = [...(this.reminder.alerts ?? [])];

    const loaders = [
      this.$store.dispatch('tools/list'),
      this.$store.dispatch('clients/list')
    ];

    await Promise.all(loaders);

    this.loadingData = false;

    this.$scope.$watchCollection(
      () => this.$store.state.tools.items,
      this.onToolsUpdates.bind(this)
    );

    this.$scope.$watchCollection(
      () => this.$store.state.clients.items,
      this.onClientsUpdates.bind(this)
    );
  }

  addAlert() {
    if (!this.nextAlert || this.nextAlert <= 0) return;
    this.alerts.push(this.nextAlert);
    this.nextAlert = 1;
  }

  removeAlert(i: number) {
    this.alerts.splice(i, 1);
  }

  async sendRequest() {
    const options: any = {
      reminderId: this.reminder.id,
      type: this.type,
      clientId: this.client,
      data: { toolId: this.tool, clientId: this.client },
      sendEmail: this.sendEmail,
      alerts: this.alerts,
      dueDate: new Date(this.dueDate!).toISOString()
    };

    // commented out, we were getting date format errors if
    // date was not changed
    // if (this.dueDate !== this.reminder.dueDate) {
    //   options.dueDate = new Date(this.dueDate!).toISOString();
    // }

    let res: unknown = null;
    let error: Error | null = null;

    this.processing = true;

    try {
      res = await this.$store.dispatch('reminders/update', options);
    } catch (err) {
      error = err;
    }

    this.processing = false;

    if (error) {
      return this.utils.notify('error', 'Error - Sending Request');
    }

    this.utils.notify('success', 'Request Sent');

    this.$close(res);
  }

  /**
   * ...
   */
  private onClientsUpdates(clients: ClientModel[]) {
    this.clients = (clients ?? []).map((o) => ({
      label: o.name(true),
      value: o.id
    }));
  }

  /**
   * ...
   */
  private onToolsUpdates(tools: Tool[]) {
    this.tools = (tools ?? [])
      .filter((item) => item.publishedCommitId)
      .map((o) => ({ label: o.name, value: o.id }));
  }
}

export default angular
  .module('app.editReminderModal', [])
  .directive('editReminderModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./edit-reminder-modal.html'),
    controller: EditReminderModalController,
    controllerAs: 'vm'
  })).name;
