import download from 'downloadjs';

import { State, Getter, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

import { find } from 'lodash';

@Controller
class DashboardAggregateUserReports {
  tableProps: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';
  selectedInstitution: string = '';

  get tableItems() {
    return this.$store.state.aggregateUserReports.items;
  }

  @Inject $rootScope;
  @Inject $state;
  @Inject $location;
  @Inject $modals;
  @Inject $auth;
  @Inject $store;
  @Inject $api;
  @Inject $acl;
  @Inject $http;
  @Inject utils;

  @State me;
  @State permissions;
  @State(({ institutions }) => institutions.items) institutions;
  @Getter activeInstId;
  @Getter isAdmin;

  @Action('institutions/getAll') listInstitutions;
  @Action('aggregateUserReports/list') listAggregateUserReports;
  @Action('aggregateUserReports/create') createAggregateUserReports;

  async $onInit() {
    if (this.activeInstId && !this.isAdmin) {
      this.selectedInstitution = this.activeInstId;
      await this.$store.dispatch('aggregateUserReports/list', {
        instId: this.activeInstId
      });
    }

    if (this.isAdmin && !this.institutions.length) {
      await this.listInstitutions();
    }

    this.tableProps = [
      {
        label: 'Created',
        value: 'createdAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Status',
        value: (row) => {
          let output = '';
          let scanTag = null;

          if (row.tags && row.tags?.length) {
            // new scan tag
            scanTag = find(row.tags, { Key: 'scan-status' });

            // fallback to old scan tag
            if (!scanTag) scanTag = find(row.tags, { Key: 'av-status' });
          }

          if (row.status === 'QUEUED') {
            output = `<label class="status-label in-progress">In Progress</label>`;
          } else if (row.status === 'ERROR') {
            output = row.error
              ? `<label class="status-label not-started">${row.error}</label>`
              : `<label class="status-label not-started">Failed</label>`;
          } else if (
            row.status === 'SCANNING' ||
            (scanTag && scanTag.Value !== 'CLEAN')
          ) {
            output = `<label class="status-label in-progress">Scanning</label>`;
          } else if (row.status === 'COMPLETED') {
            output = `<label class="status-label completed">Completed</label>`;
          }

          return output;
        }
      }
    ];

    this.tableActions = [
      {
        label: 'Download',
        icon: 'cloud-download-alt',
        actions: async (val) => {
          // Download the CSV...
          if (!val.id || !val.account?.id) {
            console.error(
              `[agg-user-reports-dashboard-download] Report ID or Account ID not found`
            );
            return;
          }

          const mimeType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

          var url;

          if (
            this.$acl(
              'IM:DownloadAggUserReportRequest',
              this.permissions.profile
            )
          ) {
            url = `/api/institution-manager/${val.account.id}/agguser-requests/${val.id}/download`;
          }

          const config = {
            responseType: 'arraybuffer',
            headers: {
              Accept:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
          };

          const res = await this.$http.get(url, config);

          var blob = new Blob([res.data], { type: mimeType });

          download(blob, `User_Aggregate_Report_GEARS.xlsx`, mimeType);
        },
        disabled: ({ status, tags }) => {
          if (tags && tags.length) {
            // new scan tag
            let tag = find(tags, { Key: 'scan-status' });

            // fallback to old scan tag
            if (!tag) tag = find(tags, { Key: 'av-status' });

            return tag.Value !== 'CLEAN';
          }

          if (status !== 'COMPLETED') return true;
        }
      }
    ];
  }

  async createAggUserReport() {
    if (!this.selectedInstitution) return;
    this.processing = true;

    try {
      await this.createAggregateUserReports({
        instId: this.selectedInstitution
      });
    } catch (err) {
      this.utils.notify('error', err);
      return;
    } finally {
      this.processing = false;
    }

    this.utils.notify(
      'success',
      'Request Created - Please allow at least 5 minutes to process your request'
    );
  }
}

export default DashboardAggregateUserReports;
