import angular, { Controller, Inject } from '@angular';

import * as _ from 'lodash';
import Sortable from 'sortablejs';

@Controller
class ToolCreatorV2View {
  @Inject $scope;
  @Inject $timeout;
  @Inject $rootScope;
  @Inject ToolEditorConnectors;
  @Inject Block;
  @Inject toolManagement;
  @Inject toolManager;
  @Inject convertLegacyTool;
  @Inject generateReportTemplate;
  @Inject $modals;
  @Inject $api;
  @Inject $ls;
  @Inject getItems;
  @Inject $stateParams;
  @Inject notify;
  @Inject Upload;
  @Inject $reincode;

  $onInit() {
    this.linesListCollapsed = false;
    this.toolBarOptions = {
      startNewOptions: false,
      comparatorOptions: false,
      newBlockOptions: false,
      logOptions: false
    };
    this.createBlockMultiplier = 1;
    this.toolEditorHints = [
      '<strong><i>Double Click</i></strong> on a <i>Block</i> to bring it to the front'
      // 'The <strong><span class="fa fa-chevron-right"></span></strong> on the left side of the screen shows an example of the final Json'
    ];
    this.toolEditorHintsIndex = 0;
    this.toolEditorHint = this.toolEditorHints[this.toolEditorHintsIndex];
    this.blocks = [];
    this.toolEditorLoading = false;
    this.viewTemplate = false;
    this.tipsAndHintsSide = '';

    this.toolEditorConnectorData = this.ToolEditorConnectors.getData();
    let t = this;
    let i;

    this.$scope.$on('editor-lines-updated', function () {
      t.toolEditorConnectorData = t.ToolEditorConnectors.data;
      t.loadingLines = false;
    });
    this.$scope.$on('block-lines-updated', function () {
      t.toolEditorConnectorData = t.ToolEditorConnectors.data;
    });
    this.$scope.$on('block-deleted', function (message, block) {
      _.remove(t.blocks, block);
      t.deleteBlock(block);
    });
    this.$scope.$on('block-updated', function (message, block) {
      let newBlock;

      switch (block.type) {
        case 'child-tool':
          newBlock = new t.Block.ChildTool(block);
          _.forEach(t.tool.childTools, (ct, index) => {
            if (ct.blockId === block.blockId)
              t.tool.childTools[index] = newBlock;
          });
      }

      _.forEach(t.blocks, (bl, index) => {
        if (bl.blockId === block.blockId) t.blocks[index] = newBlock;
      });
    });
    this.$scope.$on('row-deleted', function (message, block, row) {
      _.remove(block.rows, row);
    });
    this.$scope.$on('load-block', function (message, block) {
      t.loadBlock(block, block.type);
    });
    this.$scope.$on(
      'connection-added',
      function (message, outputNode, inputNode) {
        t.toolManagement.updateConnection(
          t.currentLayer,
          outputNode,
          inputNode,
          false,
          t.blocks
        );
      }
    );
    this.$scope.$on(
      'connection-deleted',
      function (message, outputNode, inputNode) {
        t.toolManagement.updateConnection(
          t.currentLayer,
          outputNode,
          inputNode,
          true,
          t.blocks
        );
      }
    );
    this.$scope.$on('bring-block-to-front', function (message, block) {
      t.bringBlockToFront(block);
    });
    this.$scope.$on('tool-updated', function (message, response) {
      if (response.data) {
        t.loadCommit(response.data);
      }
    });
    this.workingOnTool = true;
    this.saveTempTool();

    this.toolEditorLoading = true;

    this.$api.toolCreator.listAllTools().then((tools) => {
      this.tools = tools;
    });

    console.log('initing tool!', this.$stateParams);

    // figure out how we need to load a ToolBox vs a Tool
    if (this.$stateParams.commit) {
      if (this.$stateParams.commit.newReportTemplate) {
        // new report template for a given tool commit
        console.log(this.$stateParams.commit);
        this.loadReportTemplate(null, this.$stateParams.commit);
      } else {
        // work on a given tool commit
        this.loadCommit(this.$stateParams.commit);
      }
    } else if (this.$stateParams.reportTemplate) {
      console.log('template selected!', this.$stateParams.reportTemplate);
      if (this.$stateParams.reportTemplate.toolCommits) {
        // we have a list of tool commits to pass to loadReporTemplate
        if (this.$stateParams.reportTemplate.toolCommits.length) {
          this.loadReportTemplate(
            this.$stateParams.reportTemplate,
            _.max(this.$stateParams.reportTemplate.toolCommits, function (el) {
              return new Date(el.createdAt).getTime();
            })
          );
        } else {
          this.loadReportTemplate(this.$stateParams.reportTemplate);
        }
      } else {
        this.loadReportTemplate(this.$stateParams.reportTemplate);
      }
    } else if (this.$ls.get('working-tool')) {
      let toolDate = this.$ls.get('working-tool');

      this.$modals.tool.askWorkingTool(toolDate, (choice) => {
        if (choice === 'continue') {
          this.loadLocalTool();
        } else if (choice === 'new tool') {
          this.startNewTool();
        } else if (choice === 'new meta tool') {
          this.startNewTool({
            metaTool: true
          });
        }
      });
    } else {
      this.startNewTool();
    }

    this.startEditorHints();
  }

  startNewTool(data) {
    data = data || {};

    if (data.metaTool) {
      this.tool = new this.Block.MetaTool({
        name: 'New Meta Tool'
      });
      this.currentLayer = {
        name:
          this.tool.rootToolInformation.id ||
          `N/A | ${this.tool.rootToolInformation.name}`,
        type: 'meta-tool',
        object: this.tool
      };
      this.loadBlock(this.tool, 'meta-tool');
    } else {
      this.tool = new this.Block.Tool({
        name: 'New Tool'
      });
      this.currentLayer = {
        name:
          this.tool.rootToolInformation.id ||
          `N/A | ${this.tool.rootToolInformation.name}`,
        type: 'tool',
        object: this.tool
      };
      this.loadBlock(this.tool, 'tool');
    }
    this.drawLines();
    this.setUpSortableElement(
      'coding-form-items-list',
      this.tool.codingFormItems
    );
    this.setUpSortableElement('child-tools-list', this.tool.childTools);
  }

  async loadCommit(commit, callback) {
    console.log(commit);
    console.log('getting editor file');

    var file, error;

    try {
      // file = await this.$api.toolCommits.getToolEditorFile(commit.id);
      file = await this.getItems.toolEditorFile(commit);

      console.log('got editor file!');
      console.log('toolEditorFile', angular.copy(file));
    } catch (err) {
      console.debug('failed to get file', err);
      error = err;
    }

    if (!error) {
      try {
        // let commFile = await this.$api.toolCommits.getToolCommitFile(commit.id);
        let commFile = await this.getItems.toolCommitFile(commit);

        if (callback) {
          return callback({ file, commFile });
        } else {
          this.loadTool(file, commit.toolId, commFile, commit.id);
        }
      } catch (err) {
        console.log('Failed to get commit file', err);
        console.log(err);
      }

      return;
    }

    if (err.data.data.code === 'NoSuchKey') {
      try {
        // let commFile = await this.$api.toolCommits.getToolCommitFile(commit.id);
        let commFile = await this.getItems.toolCommitFile(commit);

        if (callback) {
          return callback({ commFile });
        } else {
          this.loadTool(null, commit.toolId, commFile, commit.id);
        }
      } catch (err) {
        console.log(err);
      }

      return;
    }
  }

  regenerateItemDescriptions() {
    this.toolEditorLoading = true;
    let t = this;
    this.loadCommit(
      { id: this.tool.reportTemplateInformation.toolCommitId },
      function (files) {
        let reportCriteria = t.toolManager.regenerateItemDescriptions(
          { file: files.commFile },
          null,
          true
        );
        let template = {
          template: {
            reportCriteria: {
              itemDescriptions: reportCriteria.reportCriteria.itemDescriptions
            }
          }
        };
        console.log(template);
        t.generateReportTemplate.newTemplate(template, function (response) {
          t.tool.itemDescriptions = response.itemDescriptions;
          console.log(t.tool);
          t.loadBlock(t.tool, t.tool.type);
        });
      }
    );
  }

  removeItemDescriptions() {
    Reflect.deleteProperty(this.tool, 'itemDescriptions');
    this.loadBlock(this.tool, this.tool.type);
  }

  loadReportTemplate(reportTemplate, toolCommitChosen) {
    console.log('load report template');
    if (!toolCommitChosen) {
      this.$modals.tool.commitsViewer(this.tool, 'report-template', (result) =>
        this.loadReportTemplate(null, result)
      );
    } else if (toolCommitChosen) {
      let t = this;

      this.loadCommit(toolCommitChosen, function (files) {
        console.log('files: ', files);
        console.log('reportTemplate: ', reportTemplate);
        if (!reportTemplate) {
          // make the first report template
          let reportCriteria = null;
          // t.reportTemplateToolCommit = files;
          if (toolCommitChosen.oldReportTemplate) {
            t.getItems
              .templateCommitFile(toolCommitChosen.oldReportTemplate)
              .then((oldReportTemplate) => {
                t.getItems
                  .templateEditorFile(toolCommitChosen.oldReportTemplate)
                  .then((oldReportTemplateEditFile) => {
                    if (oldReportTemplate.itemDescriptions) {
                      reportCriteria = t.toolManager.regenerateItemDescriptions(
                        { file: files.commFile },
                        oldReportTemplate,
                        false
                      );
                    }
                    t.getItems
                      .toolTemplate({ id: toolCommitChosen.toolId })
                      .then((temp) => {
                        let template = {
                          toolCommitId: toolCommitChosen.id,
                          toolId: toolCommitChosen.toolId,
                          id: temp ? temp.id : null,
                          template: {
                            name: files.commFile.name,
                            version: null,
                            reportCriteria: {
                              overview:
                                oldReportTemplate.reportCriteria.overview,
                              itemDescriptions: reportCriteria
                                ? reportCriteria.reportCriteria.itemDescriptions
                                : null,
                              additionalCriteria:
                                oldReportTemplate.reportCriteria
                                  .additionalCriteria,
                              chartData:
                                oldReportTemplate.reportCriteria.chartData
                            }
                          },
                          doNotGenerateItemDescriptions:
                            oldReportTemplate.reportCriteria
                              .itemDescriptions === null
                        };
                        t.generateReportTemplate.newTemplate(
                          template,
                          function (response) {
                            let oldParsedTemplate;
                            if (!oldReportTemplateEditFile) {
                              oldParsedTemplate =
                                t.convertLegacyTool.loadTemplateFile(
                                  oldReportTemplate
                                );
                            } else {
                              oldParsedTemplate = oldReportTemplateEditFile;
                            }
                            if (oldParsedTemplate.connectors) {
                              _.forEach(
                                oldParsedTemplate.connectors,
                                function (connector) {
                                  response.connectors.push(connector);
                                }
                              );
                            }
                            if (oldParsedTemplate.overview)
                              response.overview = oldParsedTemplate.overview;
                            if (oldParsedTemplate.additionalCriteria)
                              response.additionalCriteria =
                                oldParsedTemplate.additionalCriteria;
                            if (oldParsedTemplate.additionalCriteriaOptions)
                              response.additionalCriteriaOptions =
                                oldParsedTemplate.additionalCriteriaOptions;
                            if (oldParsedTemplate.reportTemplateInformation)
                              response.reportTemplateInformation =
                                oldParsedTemplate.reportTemplateInformation;
                            response.reportTemplateInformation.toolId =
                              toolCommitChosen.toolId;
                            response.reportTemplateInformation.toolCommitId =
                              toolCommitChosen.id;
                            t.tool = response;
                            t.tool.type = 'report-template';

                            t.loadBlock(t.tool, 'report-template');
                            t.setUpSortableElement(
                              'additional-criteria-list',
                              t.tool.additionalCriteria
                            );
                          }
                        );
                      });
                  });
              });
          } else {
            reportCriteria = t.toolManager.regenerateItemDescriptions(
              { file: files.commFile },
              reportTemplate,
              true
            );
            console.log(reportCriteria);
            console.log(toolCommitChosen);
            t.getItems
              .toolTemplate({ id: toolCommitChosen.toolId })
              .then((temp) => {
                let template = {
                  toolCommitId: toolCommitChosen.id,
                  toolId: toolCommitChosen.toolId,
                  id: temp ? temp.id : null,
                  template: {
                    name: files.commFile.name,
                    version: null,
                    reportCriteria: {
                      overview: {},
                      itemDescriptions:
                        reportCriteria.reportCriteria.itemDescriptions,
                      additionalCriteria: [],
                      chartData: []
                    }
                  }
                };
                console.log(temp);
                t.generateReportTemplate.newTemplate(
                  template,
                  function (response) {
                    t.tool = response;
                    t.tool.type = 'report-template';
                    t.loadBlock(t.tool, 'report-template');
                    t.setUpSortableElement(
                      'additional-criteria-list',
                      t.tool.additionalCriteria
                    );
                  }
                );
              })
              .catch(() => {
                t.notify.error('Could not find Tool Template');
              });
          }
        } else {
          // load existing report template
          t.getItems
            .templateEditorFile({
              id: reportTemplate.id
            })
            .then((file) => {
              t.getItems
                .templateCommitFile({
                  id: reportTemplate.id
                })
                .then((commFile) => {
                  t.loadTool(file, reportTemplate.reportTemplateId, commFile);
                })
                .catch((err) => {
                  console.log(err);
                });
            })
            .catch((err) => {
              if (err.data.data.code === 'NoSuchKey') {
                t.getItems
                  .templateCommitFile({
                    id: reportTemplate.id
                  })
                  .then((commFile) => {
                    t.loadTool(null, reportTemplate.reportTemplateId, commFile);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            });
        }
      });
    }
  }

  loadTool(tool, toolId, commFile, commitId) {
    // if (tool) tool = this.toolManagement.refreshTool(tool);
    console.log(tool, toolId, commFile, commitId);
    if (!tool) {
      this.tool = this.convertLegacyTool.loadToolFile(toolId, commFile);
    } else if (tool.reportTemplateInformation || tool.reportCriteria) {
      // recast tool components to get updates
      for (let key in tool) {
        if (key === 'additionalCriteria') {
          for (let i = 0; i < tool[key].length; i++) {
            tool[key][i] = new this.Block.AdditionalCriteria(tool[key][i]);
          }
        }
      }
      this.tool = tool;
      this.tool.reportTemplateInformation.id = toolId ? toolId : null;
      this.loadBlock(this.tool, this.tool.type);
      this.setUpSortableElement(
        'additional-criteria-list',
        this.tool.additionalCriteria
      );
    } else {
      if (typeof tool !== 'string') tool = angular.fromJson(tool);
      this.tool = this.toolManagement.loadToolJsonString(
        tool,
        toolId,
        commFile,
        commitId
      );
      // TODO (Alex) build connectors for loading tool. VRAG-R example specifically
      if (this.tool.connectors) {
        this.ToolEditorConnectors.setConnectors(this.tool.connectors);
      }
      this.tool = this.$reincode.fullObject(this.tool);
      console.log('this.tool: ', this.tool);
      // recast root tool info here
      this.loadBlock(this.tool, 'tool');
      this.setUpSortableElement(
        'coding-form-items-list',
        this.tool.codingFormItems
      );
      this.setUpSortableElement('child-tools-list', this.tool.childTools);
    }
    if (this.tool.connectors) {
      this.ToolEditorConnectors.setConnectors(this.tool.connectors);
      Reflect.deleteProperty(this.tool, 'connectors');
    }
    this.drawLines();
    this.loadTipsAndHints();
  }

  loadLocalTool() {
    if (localStorage.getItem('working-tool-connectors')) {
      this.ToolEditorConnectors.setConnectors(
        angular.fromJson(localStorage.getItem('working-tool-connectors'))
      );
    }
    let tool = localStorage.getItem('working-tool');
    if (angular.fromJson(tool)) {
      this.tool = angular.fromJson(tool);
      if (!this.tool.reportTemplateInformation) {
        this.tool = this.toolManagement.loadToolJsonString(tool);
        // this.tool = this.toolManagement.refreshTool(this.tool);
      }
      if (!this.tool.type) {
        if (this.tool.rootToolInformation) {
          this.tool.type = 'tool';
        }
      }
      this.loadBlock(this.tool, this.tool.type);
      this.drawLines();
      let el;
      let t = this;
      if (this.tool.type === 'tool') {
        this.setUpSortableElement(
          'coding-form-items-list',
          this.tool.codingFormItems
        );
        this.setUpSortableElement('child-tools-list', this.tool.childTools);
      } else if (this.tool.type === 'report-template') {
        this.setUpSortableElement(
          'additional-criteria-list',
          this.tool.additionalCriteria,
          1000
        );
      }
    }
    this.loadTipsAndHints();
  }

  saveTempTool() {
    this.$timeout(
      function () {
        if (this.workingOnTool) {
          this.savingTempTool = true;
          if (this.tool) {
            localStorage.setItem('working-tool', angular.toJson(this.tool));
            localStorage.setItem(
              'working-tool-connectors',
              angular.toJson(this.toolEditorConnectorData.connectors)
            );
          }
          this.$timeout(
            function () {
              this.savingTempTool = false;
            }.bind(this),
            2000
          );
          if (this.workingOnTool) {
            this.saveTempTool();
          }
        }
      }.bind(this),
      10000
    );
  }

  startEditorHints() {
    this.$timeout(
      function () {
        if (this.workingOnTool) {
          if (this.toolEditorHintsIndex >= this.toolEditorHints.length - 1) {
            this.toolEditorHintsIndex = 0;
          } else {
            this.toolEditorHintsIndex += 1;
          }
          this.toolEditorHint = this.toolEditorHints[this.toolEditorHintsIndex];
          this.startEditorHints();
        }
      }.bind(this),
      30000
    );
  }

  loadBlock(block, type) {
    this.toolEditorLoading = true;
    this.loadingLines = true;
    let i;
    let j;
    let k;
    let t = this;
    switch (type) {
      case 'tool':
        this.blocks = [];
        console.log('loading tool!: ', this.tool);
        this.blocks.push(this.tool.rootToolInformation);
        if (this.tool.dictionary) this.blocks.push(this.tool.dictionary);
        if (this.tool.prorate) this.blocks.push(this.tool.prorate);
        if (block.codingFormItems) {
          for (i = 0; i < block.codingFormItems.length; i++) {
            this.blocks.push(block.codingFormItems[i]);
          }
        }
        if (block.riskCategories) {
          for (i = 0; i < block.riskCategories.length; i++) {
            this.blocks.push(block.riskCategories[i]);
          }
        }
        if (block.customRiskCategories) {
          for (i = 0; i < block.customRiskCategories.length; i++) {
            this.blocks.push(block.customRiskCategories[i]);
          }
        }
        if (block.offenderHistory) {
          this.blocks.push(block.offenderHistory);
        }
        if (block.childTools && block.childTools.length) {
          for (i = 0; i < block.childTools.length; i++) {
            this.blocks.push(block.childTools[i]);
          }
        }
        if (block.rules) {
          for (i = 0; i < block.rules.length; i++) {
            this.blocks.push(block.rules[i]);
          }
        }
        if (this.tool.rootToolInformation?.id)
          this.toolEditorConnectorData.toolId =
            this.tool.rootToolInformation.id;
        this.currentLayer = {
          name:
            `${this.tool.rootToolInformation?.id}|${this.tool.rootToolInformation?.name}` ||
            `N/A | ${this.tool.rootToolInformation?.name} | ${this.tool.rootToolInformation?.version}`,
          type: 'tool',
          object: this.tool
        };
        this.drawLines();
        break;
      case 'report-template':
        this.blocks = [];
        let t = this;
        console.log(this.tool);
        if (this.tool.reportTemplateInformation.id)
          this.toolEditorConnectorData.toolId =
            this.tool.reportTemplateInformation.id;
        this.blocks.push(this.tool.reportTemplateInformation);
        this.blocks.push(this.tool.overview);
        if (this.tool.overview.options.length) {
          _.forEach(this.tool.overview.options, function (option) {
            t.blocks.push(option);
          });
        }
        if (this.tool.additionalCriteria) {
          _.forEach(this.tool.additionalCriteria, function (aCrit) {
            t.blocks.push(aCrit);
          });
        }
        if (this.tool.itemDescriptions) {
          this.blocks.push(this.tool.itemDescriptions);
          if (this.tool.itemDescriptions.descriptions.length) {
            _.forEach(
              this.tool.itemDescriptions.descriptions,
              function (description) {
                t.blocks.push(description);
              }
            );
          }
        }
        if (this.tool.connectors) {
          _.forEach(this.tool.connectors, function (connection) {
            let checkConnector = function (inputNode, outputNode) {
              return _.some(
                t.toolEditorConnectorData.connectors,
                function (existingConnection) {
                  return (
                    existingConnection.inputNode === inputNode &&
                    existingConnection.outputNode === outputNode
                  );
                }
              );
            };
            if (!checkConnector(connection.inputNode, connection.outputNode)) {
              t.toolEditorConnectorData.connectors.push({
                inputNode: connection.inputNode,
                outputNode: connection.outputNode
              });
            }
          });
        }
        this.currentLayer = {
          name: `${this.tool.reportTemplateInformation.name}`,
          type: 'report-template',
          object: this.tool
        };
        this.drawLines();
        break;
      case 'item-description':
        let itemDescription = _.find(this.tool.itemDescriptions.descriptions, {
          blockId: block.blockId
        });
        if (!itemDescription) {
          this.notify.error("Couldn't find item description", true);
        }
        this.blocks = [];
        this.currentLayer = {
          name: `${itemDescription.title}`,
          type: 'item-description',
          object: itemDescription
        };
        this.blocks.push(itemDescription);
        for (i = 0; i < itemDescription.answerDescriptions.length; i++) {
          this.blocks.push(itemDescription.answerDescriptions[i]);
        }
        this.drawLines();
        break;
      case 'additional-criteria':
        let additionalCriteria = _.find(this.tool.additionalCriteria, {
          blockId: block.blockId
        });
        if (!additionalCriteria) {
          this.notify.error("Couldn't find additional criteria", true);
        }
        this.blocks = [];
        this.currentLayer = {
          name: `${additionalCriteria.title}`,
          type: 'additional-criteria',
          object: additionalCriteria
        };
        if (
          additionalCriteria.hasOwnProperty('options') &&
          additionalCriteria.options.length
        ) {
          for (i = 0; i < additionalCriteria.options.length; i++) {
            additionalCriteria.options[i].subEdit = true; // DEBUG
            this.blocks.push(additionalCriteria.options[i]);
          }
        }
        if (
          additionalCriteria.hasOwnProperty('additionalCriteriaOptions') &&
          additionalCriteria.additionalCriteriaOptions.length
        ) {
          for (
            i = 0;
            i < additionalCriteria.additionalCriteriaOptions.length;
            i++
          ) {
            additionalCriteria.additionalCriteriaOptions[i].subEdit = true; //DEBUG
            this.blocks.push(additionalCriteria.additionalCriteriaOptions[i]);
            if (
              additionalCriteria.additionalCriteriaOptions[i].choices.length
            ) {
              for (
                j = 0;
                j <
                additionalCriteria.additionalCriteriaOptions[i].choices.length;
                j++
              ) {
                additionalCriteria.additionalCriteriaOptions[i].choices[
                  j
                ].subEdit = true; // DEBUG
                this.blocks.push(
                  additionalCriteria.additionalCriteriaOptions[i].choices[j]
                );
              }
            }
            if (
              additionalCriteria.additionalCriteriaOptions[i].options.length
            ) {
              for (
                j = 0;
                j <
                additionalCriteria.additionalCriteriaOptions[i].options.length;
                j++
              ) {
                additionalCriteria.additionalCriteriaOptions[i].options[
                  j
                ].subEdit = true; //DEBUG
                this.blocks.push(
                  additionalCriteria.additionalCriteriaOptions[i].options[j]
                );
              }
            }
          }
        }
        this.blocks.push(additionalCriteria);
        this.drawLines();
        break;
      case 'additional-criteria-option':
        this.blocks = [];
        // find parent additional criteria to load in block
        let parentAC = _.find(this.tool.additionalCriteria, function (ac) {
          return _.some(ac.options, block);
        });
        if (parentAC) this.blocks.push(parentAC);

        this.currentLayer = {
          name: `${block.text}`,
          type: 'additional-criteria-option',
          object: block
        };
        this.blocks.push(block);
        if (block.hasOwnProperty('choices') && block.choices.length) {
          for (i = 0; i < block.choices.length; i++) {
            block.choices[i].subEdit = true; //DEBUG
            this.blocks.push(block.choices[i]);
          }
        }
        if (block.hasOwnProperty('options') && block.options.length) {
          for (i = 0; i < block.options.length; i++) {
            block.options[i].subEdit = true; //DEBUG
            this.blocks.push(block.options[i]);
          }
        }
        this.drawLines();
        break;
      case 'report-template-choice':
        this.blocks = [];
        this.blocks.push(block);

        if (block.hasOwnProperty('options') && block.options.length) {
          for (i = 0; i < block.options.length; i++) {
            block.options[i].subEdit = true; //DEBUG
            this.blocks.push(block.options[i]);
          }
        }

        this.currentLayer = {
          name: `${block.text}`,
          type: 'report-template-choice',
          object: block
        };
        this.drawLines();
        break;
      case 'meta-tool':
        this.blocks = [];
        this.blocks.push(this.tool.rootToolInformation);
        if (this.tool.codesAndScores) {
          this.blocks.push(this.tool.codesAndScores);
        }
        for (i = 0; i < block.operations.length; i++) {
          this.blocks.push(block.operations[i]);
        }
        for (i = 0; i < block.tools.length; i++) {
          this.blocks.push(block.tools[i]);
        }
        for (i = 0; i < block.riskCategories.length; i++) {
          this.blocks.push(block.riskCategories[i]);
        }
        for (i = 0; i < block.codesAndScore.length; i++) {
          this.blocks.push(block.codesAndScore[i]);
        }
        this.currentLayer = {
          name:
            `${this.tool.rootToolInformation.id} | ${this.tool.rootToolInformation.name}` ||
            `N/A | ${this.tool.rootToolInformation.name} | ${this.tool.rootToolInformation.version}`,
          type: 'meta-tool',
          object: this.tool
        };
        this.drawLines();
        break;
      case 'coding-form-item':
        let codingFormItem = _.find(this.tool.codingFormItems, {
          blockId: block.blockId
        });
        if (!codingFormItem) {
          this.notify.error("Couldn't find coding form item", true);
          return;
        }
        this.blocks = [];
        this.currentLayer = {
          name: `${codingFormItem.id} | ${codingFormItem.riskFactor}`,
          type: 'coding-form-item',
          object: codingFormItem
        };
        this.blocks.push(codingFormItem);
        if (this.tool.dictionary) {
          this.blocks.push(this.tool.dictionary);
        }
        for (i = 0; i < block.codesAndScore.length; i++) {
          this.blocks.push(block.codesAndScore[i]);
        }
        console.log('coding form block', block);
        if (block.itemWizard) {
          this.blocks.push(block.itemWizard);
          for (i = 0; i < block.itemWizard.wizardQuestions.length; i++) {
            this.blocks.push(block.itemWizard.wizardQuestions[i]);
          }
          if (block.itemWizard.multipleAnswerConditions.length) {
            for (
              i = 0;
              i < block.itemWizard.multipleAnswerConditions.length;
              i++
            ) {
              this.blocks.push(block.itemWizard.multipleAnswerConditions[i]);
            }
          }
          if (block.itemWizard.manipulateVariables.length) {
            for (i = 0; i < block.itemWizard.manipulateVariables.length; i++) {
              this.blocks.push(block.itemWizard.manipulateVariables[i]);
            }
          }
          if (block.itemWizard.variables.length) {
            for (i = 0; i < block.itemWizard.variables.length; i++) {
              this.blocks.push(block.itemWizard.variables[i]);
            }
          }
          if (block.itemWizard.conditions.length) {
            for (i = 0; i < block.itemWizard.conditions.length; i++) {
              this.blocks.push(block.itemWizard.conditions[i]);
            }
          }
          if (block.itemWizard.resolutions.length) {
            for (i = 0; i < block.itemWizard.resolutions.length; i++) {
              this.blocks.push(block.itemWizard.resolutions[i]);
            }
          }
          console.log('checking for operations', block.itemWizard);
          if (
            block.itemWizard.hasOwnProperty('operations') &&
            block.itemWizard.operations.length
          ) {
            for (i = 0; i < block.itemWizard.operations.length; i++) {
              this.blocks.push(block.itemWizard.operations[i]);
            }
          }
          if (block.itemWizard.references.length) {
            for (i = 0; i < block.itemWizard.references.length; i++) {
              this.blocks.push(block.itemWizard.references[i]);
            }
          }
        }
        this.drawLines();
        break;
      case 'prorate-tool':
        this.blocks = [];

        this.currentLayer = {
          name: `${this.tool.rootToolInformation.name} | Prorate`,
          type: 'prorate-tool',
          object: block
        };

        if (block.operations.length) {
          for (i = 0; i < block.operations.length; i++) {
            this.blocks.push(block.operations[i]);
          }
        }
        if (block.variables.length) {
          for (i = 0; i < block.variables.length; i++) {
            this.blocks.push(block.variables[i]);
          }
        }
        if (block.outcomes.length) {
          for (i = 0; i < block.outcomes.length; i++) {
            this.blocks.push(block.outcomes[i]);
          }
        }
        if (block.rules.length) {
          for (i = 0; i < block.rules.length; i++) {
            this.blocks.push(block.rules[i]);
          }
        }

        if (block.comparators.length) {
          for (i = 0; i < block.comparators.length; i++) {
            this.blocks.push(block.comparators[i]);
          }
        }

        if (block.startValue) {
          this.blocks.push(block.startValue);
        }

        this.drawLines();

        break;
    }
  }

  bringBlockToFront(block) {
    let maxZ = 0;
    for (let i = 0; i < this.blocks.length; i++) {
      this.blocks[i].zIndex > maxZ ? (maxZ = this.blocks[i].zIndex) : null;
    }
    block.zIndex = maxZ + 1;
  }

  metaToolToolSelected(tool) {
    tool.x = 100;
    tool.y = 100;
    let block = new this.Block.MetaToolTool(tool);
    this.tool.tools.push(block);
    this.blocks.push(block);
  }

  hasBlock(object, type) {
    return object.hasOwnProperty(type);
  }

  addBlock(parent, type, x, y) {
    for (let i = 0; i < this.createBlockMultiplier; i++) {
      let scrollY = angular.element('#tool-editor-wrapper')[0].scrollTop;
      let scrollX = angular.element('#tool-editor-wrapper')[0].scrollLeft;
      if (scrollY > y || !y) {
        y = scrollY + 100;
      }
      if (scrollX > x || !x) {
        x = scrollX + 100;
      }
      x += 20;
      y += 20;
      if (x >= 4500) x = scrollX + 100;
      if (y >= 4500) y = scrollY + 100;
      if (type === 'meta-tool-tool') {
        this.$modals.tool.selectTool(null, (tool) =>
          this.metaToolToolSelected(tool)
        );
      } else {
        let zIndex = 0;
        _.forEach(this.blocks, (block) => {
          if (zIndex <= block.zIndex) {
            zIndex = block.zIndex + 1;
          }
        });
        let block = this.toolManagement.addBlock(parent, type, x, y, zIndex);
        console.log('block: ', block);
        if (Array.isArray(block)) {
          for (let i = 0; i < block.length; i++) {
            this.blocks.push(block[i]);
          }
        } else if (block.blocks) {
          // returning multiple blocks from regenerate report template item descriptions
          if (this.tool.type === 'report-template') {
            this.loadBlock(this.tool, 'report-template');
          }
        } else if (block) {
          console.log('New Block', block);
          if (block.type === 'additional-criteria-option') {
            console.log(this.tool);
            // this.tool.additionalCriteria.push(block);
            this.drawLines();
          } else if (block.type === 'report-template-choice') {
            // this.tool.choices.push(block);
            this.drawLines();
          }
          this.blocks.push(block);
        }
      }
    }
    this.createBlockMultiplier = 1;
  }

  deleteBlock(block) {
    console.log('deleteBlock', block);

    let i;
    let basicData = {
      x: 100,
      y: 100
    };
    switch (block.type) {
      case 'root-tool-information':
        if (this.tool.rootToolInformation.blockId === block.blockId)
          this.tool.rootToolInformation = new this.Block.RootToolInformation(
            basicData
          );
        break;
      case 'risk-category':
        _.remove(this.tool.riskCategories, { blockId: block.blockId });
        this.loadBlock(this.tool, this.tool.type);
        break;
      case 'custom-risk-category':
        _.remove(this.tool.customRiskCategories, { blockId: block.blockId });
        this.loadBlock(this.tool, this.tool.type);
        break;
      case 'sub-risk-category':
        _.remove(this.tool.subRiskCategories, { blockId: block.blockId });
        this.loadBlock(this.tool, this.tool.type);
        break;
      case 'rule-omit':
        _.remove(this.tool.rules, { blockId: block.blockId });
        this.loadBlock(this.tool, this.tool.type);
        break;
      case 'rule-minimum-answers':
        _.remove(this.tool.rules, { blockId: block.blockId });
        this.loadBlock(this.tool, this.tool.type);
        break;
      case 'dictionary':
        this.tool.dictionary = null;
        if (this.currentLayer.type === 'tool') {
          this.loadBlock(this.tool, 'tool');
        } else if (this.currentLayer.type === 'coding-form-item') {
          this.loadBlock(this.currentLayer.object, 'coding-form-item');
        }
        break;
      case 'prorate-tool':
        this.tool.prorate = null;
        Reflect.deleteProperty(this.tool, 'prorate');
        this.loadBlock(this.tool, 'tool');
        break;
      case 'rules':
        this.tool.prorate.rules = [];
        this.loadBlock(this.currentLayer.object, 'prorate-tool');
        break;
      case 'numeric-comparator':
        _.remove(this.tool.prorate.comparators, {
          blockId: block.blockId
        });
        this.loadBlock(this.currentLayer.object, 'prorate-tool');
        break;
      case 'operator':
        if (this.currentLayer.type === 'prorate-tool') {
          _.remove(this.tool.prorate.operations, {
            blockId: block.blockId
          });
        } else if (this.currentLayer.type === 'coding-form-item') {
          _.remove(this.currentLayer.object.itemWizard.operations, {
            blockId: block.blockId
          });
        }
        this.loadBlock(this.currentLayer.object, this.currentLayer.type);
        break;
      case 'single-operator':
        _.remove(this.currentLayer.object.itemWizard.operations, {
          blockId: block.blockId
        });
        this.loadBlock(this.currentLayer.object, this.currentLayer.type);
        break;
      case 'binary-operator':
        if (this.currentLayer.type === 'prorate-tool') {
          _.remove(this.tool.prorate.operations, {
            blockId: block.blockId
          });
          this.tool.prorate.operations.forEach((op) => {
            op.rows.forEach((row) => {
              if (row.inputIds.length) {
                _.remove(row.inputIds, (id) => {
                  let match = false;

                  block.rows.forEach((item) => {
                    if (item.outputNodeId === id) {
                      match = true;
                    }
                  });

                  return match;
                });
              }

              if (row.outputIds.length) {
                _.remove(row.outputIds, (id) => {
                  let match = false;

                  block.rows.forEach((item) => {
                    if (item.inputNodeId === id) {
                      match = true;
                    }
                  });

                  return match;
                });
              }
            });
          });
        } else if (this.currentLayer.type === 'coding-form-item') {
          _.remove(this.currentLayer.object.itemWizard.operations, {
            blockId: block.blockId
          });
        }
        this.loadBlock(this.currentLayer.object, this.currentLayer.type);
        break;
      case 'coding-form-item':
        _.remove(this.tool.codingFormItems, { blockId: block.blockId });
        this.loadBlock(this.tool, 'tool');
        break;
      case 'child-tool':
        _.remove(this.tool.childTools, { blockId: block.blockId });
        this.loadBlock(this.tool, 'tool');
        break;
      case 'offender-history':
        Reflect.deleteProperty(this.tool, 'offenderHistory');
        this.loadBlock(this.tool, 'tool');
        break;
      case 'meta-tool-tool':
        _.remove(this.tool.tools, { blockId: block.blockId });
        this.loadBlock(this.tool, 'meta-tool');
        break;
      case 'meta-tool-comparator':
        _.remove(this.tool.operations, { block: block.blockId });
        this.loadBlock(this.tool, 'meta-tool');
        break;
      case 'codes-and-score':
        _.remove(
          _.find(this.tool.codingFormItems, {
            blockId: this.currentLayer.object.blockId
          }).codesAndScore,
          { blockId: block.blockId }
        );
        this.loadBlock(this.currentLayer.object, 'coding-form-item');
        break;
      case 'wizard-question':
        _.remove(
          _.find(this.tool.codingFormItems, {
            blockId: this.currentLayer.object.blockId
          }).itemWizard.wizardQuestions,
          { blockId: block.blockId }
        );
        this.loadBlock(this.currentLayer.object, 'coding-form-item');
        break;
      case 'wizard-variables':
        _.remove(
          _.find(this.tool.codingFormItems, {
            blockId: this.currentLayer.object.blockId
          }).itemWizard.variables,
          { blockId: block.blockId }
        );
        this.loadBlock(this.currentLayer.object, 'coding-form-item');
        break;
      case 'wizard-conditions':
        _.remove(
          _.find(this.tool.codingFormItems, {
            blockId: this.currentLayer.object.blockId
          }).itemWizard.conditions,
          { blockId: block.blockId }
        );
        this.loadBlock(this.currentLayer.object, 'coding-form-item');
        break;
      case 'wizard-resolutions':
        _.remove(
          _.find(this.tool.codingFormItems, {
            blockId: this.currentLayer.object.blockId
          }).itemWizard.resolutions,
          { blockId: block.blockId }
        );
        this.loadBlock(this.currentLayer.object, 'coding-form-item');
        break;
      case 'wizard-reference':
        _.remove(
          _.find(this.tool.codingFormItems, {
            blockId: this.currentLayer.object.blockId
          }).itemWizard.references,
          { blockId: block.blockId }
        );
        this.loadBlock(this.currentLayer.object, 'coding-form-item');
        break;
      case 'manipulate-variables':
        _.remove(
          _.find(this.tool.codingFormItems, {
            blockId: this.currentLayer.object.blockId
          }).itemWizard.manipulateVariables,
          { blockId: block.blockId }
        );
        this.loadBlock(this.currentLayer.object, 'coding-form-item');
        break;
      case 'multiple-answer-conditions':
        _.remove(
          _.find(this.tool.codingFormItems, {
            blockId: this.currentLayer.object.blockId
          }).itemWizard.multipleAnswerConditions,
          { blockId: block.blockId }
        );
        this.loadBlock(this.currentLayer.object, 'coding-form-item');
        break;
      case 'item-wizard':
        _.find(this.tool.codingFormItems, {
          blockId: this.currentLayer.object.blockId
        }).itemWizard = null;
        this.loadBlock(this.currentLayer.object, 'coding-form-item');
        break;
      case 'overview':
        this.tool.overview = {};
        this.loadBlock(this.currentLayer.object, 'report-template');
        break;
      case 'overview-option':
        _.remove(this.tool.overview.options, { blockId: block.blockId });
        break;
      case 'additional-criteria':
        _.remove(this.tool.additionalCriteria, { blockId: block.blockId });
        this.loadBlock(this.currentLayer.object, 'report-template');
        break;
      case 'additional-criteria-option':
        _.remove(this.currentLayer.object.options, { blockId: block.blockId });
        _.remove(this.tool.additionalCriteriaOptions, {
          blockId: block.blockId
        });
        this.loadBlock(this.currentLayer.object, this.currentLayer.type);
        break;
      case 'report-template-choice':
        _.remove(this.currentLayer.object.choices, { blockId: block.blockId });
        _.remove(this.tool.choices, { blockId: block.blockId });
        this.loadBlock(this.currentLayer.object, this.currentLayer.type);
        break;
    }
  }

  addComparator(type, x, y) {
    this.blocks.push(new this.Block.Comparator(x, y, type));
  }

  addOperator(x, y) {
    this.blocks.push(new this.Block.Operator(x, y));
  }

  addVariable(x, y) {
    this.blocks.push(new this.Block.Variable(x, y));
  }

  toggleToolBarOptions(key, bool) {
    _.forEach(this.toolBarOptions, function (val, cle, tboptions) {
      if (key === cle) {
        if (bool) {
          tboptions[cle] = bool;
        } else {
          tboptions[cle] = !tboptions[cle];
        }
      } else {
        tboptions[cle] = false;
      }
    });
  }

  drawLines() {
    let t = this;
    this.loadingLines = true;

    this.$timeout(() => {
      t.ToolEditorConnectors.drawLines();
      t.toolEditorLoading = false;
    }, 500);
  }

  saveToolToJson() {
    this.workingOnTool = false;
    if (this.tool.type === 'report-template') {
      this.toolManagement.reportTemplateToJson(
        this.tool,
        this.toolEditorConnectorData.connectors,
        this
      );
    } else {
      // tool and meta tool
      console.log(this.tool);
      this.toolManagement.toolToJson(
        this.tool,
        this.toolEditorConnectorData.connectors,
        this
      );
    }
  }

  sectionToJson(section, codingFormItem) {
    this.toolManagement.sectionToJson(section, codingFormItem);
  }

  termSelected(term) {
    this.ToolEditorConnectors.insertDictionaryTerm(`[${term.id}:${term.term}]`);
  }

  insertDictionaryTerm() {
    this.$modals.tool.selectTerm(this.tool.dictionary, (term) =>
      this.termSelected(term)
    );
  }

  resetZ() {
    for (let i = 0; i < this.blocks.length; i++) {
      this.blocks[i].zIndex = i;
    }
  }

  toJSONTest() {
    let output;

    console.group('toJSONTest');
    // output = this.tool.prorate.toJson();
    output = this.tool.codingFormItems[2].toJson();
    console.groupEnd();

    console.log('toJSONTest Output', output);
  }

  setUpSortableElement(elemString, array, timeout) {
    if (!timeout) timeout = 50;
    let t = this;
    this.$timeout(function () {
      let el = document.getElementById(elemString);
      console.log('el: ', el);
      let sortable = Sortable.create(el, {
        handle: '.my-handle',
        onUpdate: function (event) {
          t.moveArrayItem(array, event.oldIndex - 1, event.newIndex - 1);
        }
      });
    }, timeout);
  }

  moveArrayItem(array, oldIndex, newIndex) {
    while (oldIndex < 0) {
      oldIndex += array.length;
    }
    while (newIndex < 0) {
      newIndex += array.length;
    }
    if (newIndex >= array.length) {
      var k = newIndex - array.length;
      while (k-- + 1) {
        array.push(undefined);
      }
    }
    array.splice(newIndex, 0, array.splice(oldIndex, 1)[0]);
  }

  loadTipsAndHints() {
    switch (this.tool.type) {
      case 'report-template':
        this.tipsAndHintsSide =
          '<h2>Key Codes</h2><ul>' +
          "<li><b>[client]</b> = inserts client's name</li>" +
          "<li><b>[score-total]</b> = inserts evaluation's score total</li>" +
          "<li><b>[risk-category]</b> = inserts evaluation's risk category</li>" +
          '<li><b>[br]</b> = inserts line break</li>' +
          '<li><b>[tab]</b> = inserts a tab</li>' +
          '<li><b>[i] content [/i]</b> = makes content italicized</li>' +
          '</ul>';
        break;
      default:
        this.tipsAndHintsSide = '<h2><i>No Tips And Hints</i></h2>';
    }
  }

  cascadeBlocks() {
    let toolEditor = angular.element('#tool-editor');
    let maxX = toolEditor.offsetWidth;
    let maxY = toolEditor.offsetHeight;
    let x = 150;
    let y = 150;
    _.forEach(this.blocks, (block) => {
      block.x = x;
      block.y = y;
      x += 150;
      x = x > maxX ? 150 : x;
      y += 150;
      y = y > maxY ? 150 : y;
    });
    this.notify.success(
      'Blocks Successfully Cascaded. Select another layer, then come back to this layer to view changes.',
      true
    );
  }

  log(item) {
    console.log(item);
  }
}

export default ToolCreatorV2View;
