'use strict';
const angular = require('angular');

import * as _ from 'lodash';

export default angular.module('app.tool-editor-node-options', []).directive('toolEditorNodeOptions', function(ToolEditorConnectors, $rootScope) {
  'ngInject';
  return {
    template: require('./tool-editor-node-options.html'),
    restrict: 'EA',
    link: function(scope, element, attrs) {
      scope.toolEditorConnectorData = ToolEditorConnectors.getData();
      $rootScope.$on('node-options-node-updated', function() {
        scope.toolEditorConnectorData = ToolEditorConnectors.data;
      });
      scope.removeConnection = function(connection) {
        console.log(connection);
        ToolEditorConnectors.deleteConnector(connection.inputNode, connection.outputNode);
      };
      element.on('mousemove', function(event) {
        if (_.indexOf(event.target.classList, 'node-options-link') !== -1) {
          let nodes = event.target.innerText.split(' ');
          let i;
          for (i = 0; i < nodes.length; i++) {
            nodes[i] = nodes[i].replace(' ', '');
            if (nodes[i].length === 0 || nodes[i] === ' ') {
              nodes.splice(i, 1);
              i--;
            }
          }
          ToolEditorConnectors.highlightLineForDeletion(nodes);
        } else {
          ToolEditorConnectors.highlightLineForDeletion();
        }
      });
    }
  };
}).name;
