'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class EditEvaluationRequestModalController extends Modal {
  copyEvaluationRequest: {};
  evaluationRequest: {};
  tools: {};
  users: {};

  @Inject $scope;
  @Inject $http;
  @Inject Notification;
  @Inject $filter;

  $setup() {
    this.evaluationRequest = angular.copy(this.$props.evalRequest);
    this.evaluationRequest.dueDate = this.$filter('dynamicDate')(
      this.evaluationRequest.dueDate,
      'MM/dd/yyyy',
      'UTC'
    );

    this.users = this.$props.users;
    this.tools = this.$props.tools;
    this.copyEvaluationRequest = angular.copy(this.evaluationRequest);
  }

  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
    }

    return true;
  }

  updateEvalReq1(form) {
    if (form.$valid) {
      var updatedEvalRequest = {};

      for (var key in this.evaluationRequest) {
        if (key === 'dueDate' || key === 'toolId' || key === 'userId') {
          if (this.evaluationRequest[key] != this.copyEvaluationRequest[key]) {
            updatedEvalRequest[key] = this.evaluationRequest[key];
          }
        }
      }

      if (!this.isEmpty(updatedEvalRequest)) {
        this.$http
          .post(
            '/api/evaluation-requests/' + this.evaluationRequest.id,
            updatedEvalRequest
          )
          .then(function(response) {
            // console.log(response);
            if (!response.data.error) {
              this.Notification.success({
                message: 'Evaluation Request Successfully Updated',
                positionX: 'center',
                templateUrl: 'assets/components/notification-template.html'
              });
              this.$close();
            } else {
              this.Notification.error({
                message: response.data.feedback,
                positionX: 'center',
                templateUrl: 'assets/components/notification-template.html'
              });
            }
          })
          .catch(function(err) {
            this.Notification.error({
              message: err.feedback,
              positionX: 'center',
              templateUrl: 'assets/components/notification-template.html'
            });
          });
      }
    }
  }
}

export default angular
  .module('app.editEvaluationRequestModal', [])
  .directive('editEvaluationRequestModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./edit-evaluation-request.html'),
    controller: EditEvaluationRequestModalController,
    controllerAs: 'vm'
  })).name;
