'use strict';

import angular from 'angular';

export default angular.module('app.btn', []).directive('btn', () => ({
  restrict: 'EA',
  replace: true,
  transclude: true,
  scope: true,
  template: `
    <button class="btn" type="button" ng-class="btnClasses" ng-transclude></button>
  `,
  link(scope, element, attrs) {
    scope.btnClasses = {
      'btn-reg': attrs.theme == 'reg',
      'btn-default': attrs.theme == 'default',
      'btn-primary': attrs.theme == 'primary',
      'btn-success': attrs.theme == 'success',
      'btn-danger': attrs.theme == 'danger',
      'btn-first': attrs.algn == 'first',
      'btn-last': attrs.algn == 'last'
    };
  }
})).name;
