'use strict';
import angular from 'angular';
import * as _ from 'lodash';
import shortid from 'shortid';

export function CasePlanService(
  $util,
  $modals,
  ItemTable,
  $http,
  notify,
  $q,
  $api,
  $store
) {
  'ngInject';

  //region CLASSES
  class _CasePlan {
    title;
    client;
    sections = [];

    constructor(config = {}, tool) {
      this.title = config.title || 'UNTITLED CASE PLAN';
      this.client = config.client || undefined;
      this.type = '_CasePlan';
      let sections = [];
      if (config.sections) {
        // passing sections that need to be recast
        _.forEach(config.sections, (section) => {
          sections.push(new _CasePlanSection(section, this, tool));
        });
      }
      this.sections = sections;
    }

    section(config = {}) {
      let section = new _CasePlanSection(config, this, tool);

      this.sections.push(section);

      return section;
    }

    get first() {
      return this.sections[0];
    }

    get last() {
      return this.sections[this.sections.length - 1];
    }

    isValid() {
      return true;
    }

    toJson() {
      let submission = {
        title: this.title,
        sections: []
      };
      _.forEach(this.sections, (section) => {
        submission.sections.push(section.toJson());
      });
      return submission;
    }
  }

  class _CasePlanSection {
    number;
    title;
    subSections;

    constructor(config = {}, parent, tool) {
      this.number = config.number || -1;
      this.title = config.title || 'UNTITLED SECTION';
      this.type = '_CasePlanSection';
      let subSections = [];
      if (config.subSections) {
        // passing existing subsections that need to be recast
        _.forEach(config.subSections, (subSection) => {
          subSections.push(new _CasePlanSubSection(subSection, tool));
        });
      }
      this.subSections = subSections;

      this.parent = parent;
    }

    get displayTitle() {
      return `SECTION ${this.number}: ${this.title}`;
    }

    get prevSection() {
      let pos = this.parent.sections.indexOf(this);
      return pos > 0 ? this.parent.sections[pos - 1] : null;
    }

    get nextSection() {
      let pos = this.parent.sections.indexOf(this);
      return pos < this.parent.sections.length - 1
        ? this.parent.sections[pos + 1]
        : null;
    }

    subSection(config = {}, tool) {
      this.subSections.push(new _CasePlanSubSection(config, tool));
      return this;
    }

    toJson() {
      let submission = {
        title: this.title,
        number: this.number,
        subSections: []
      };
      _.forEach(this.subSections, (subSection) => {
        submission.subSections.push(subSection.toJson());
      });
      return submission;
    }
  }

  class _CasePlanSubSection {
    number;
    title;
    description;
    components;

    // parentSection;

    constructor(config = {}, tool) {
      this.number = config.number || -1;
      this.title = config.title || 'UNTITLED SUB-SECTION';
      this.description = config.description || undefined;
      this.type = '_CasePlanSubSection';
      let components = [];
      if (config.components) {
        _.forEach(config.components, (component) => {
          // check for type and cast as that type
          if (
            component.hasOwnProperty('progressTracker') &&
            component.hasOwnProperty('conditions')
          ) {
            components.push(new _ProgressTableComponent(component, tool));
          }
        });
      }
      this.components = components;
      // this.parentSection = config.parentSection;
    }

    component(config = {}, tool) {
      this.components.push(new _CasePlanSubSection(config, tool));
      return this;
    }

    toJson() {
      let submission = {
        title: this.title,
        number: this.number,
        description: this.description,
        components: []
      };
      _.forEach(this.components, (component) => {
        submission.components.push(component.toJson());
      });
      return submission;
    }
  }

  class _CasePlanSubSectionComponent {
    key;
    title;
    description;
    value;
    collapsible;
    collapsed;

    constructor(config = {}) {
      if (!config.key) {
        throw new Error(
          'Case Plan sub-section component must be provided a key'
        );
      } else {
        this.key = config.key;
      }

      // if (!config.title) {
      //   throw new Error('Case Plan sub-section component must be provided a title');
      // } else {
      //   this.title = config.title;
      // }

      this.title = config.title || undefined;
      this.description = config.description || undefined;
      this.collapsible = config.collapsible || undefined;
      this.collapsed = config.collapsed || undefined;
      this.type = '_CasePlanSubSectionComponent';
    }

    //
    // get type() {
    //   return this.constructor.name;
    // }

    get isValid() {
      return this.key && this.title && this.description;
    }
  }

  class _TableComponent extends _CasePlanSubSectionComponent {
    tableConfig;
    tableColumnConfigs;

    constructor(config = {}, tool) {
      super(config);

      if (!config.tableConfig) {
        throw new Error(
          'Case Plan TableComponent must be provided a table config.'
        );
      } else {
        this.tableConfig = config.tableConfig;
      }

      this.columnsReference = config.columnsReference || null;
      this.tableColumnConfigs = config.tableColumnConfigs || [];

      this.value = [];
      this.type = '_TableComponent';
    }

    get isValid() {
      return this.value != null && this.value != undefined && this.value.length;
    }

    tableColumn(config = {}) {
      // Table Configs are in table-view folder
      this.tableColumnConfigs.push(config);

      return this;
    }

    refresh(values) {
      console.log(this);
      console.log(values);
    }
  }

  class _SelectorComponent extends _CasePlanSubSectionComponent {
    label;
    options;
    includeExplanation;

    constructor(config = {}) {
      super(config);

      if (!config.label) {
        throw new Error(
          'Case Plan SelectorComponent must be provided a label.'
        );
      } else {
        this.label = config.label;
      }

      this.options = config.options || [];
      this.includeExplanation = config.includeExplanation || false;

      this.value = {
        selection: undefined,
        explanation: undefined
      };
      this.type = '_SelectorComponent';
    }

    get isValid() {
      return (
        this.value.selection != null &&
        this.value.selection != undefined &&
        this.value.selection != ''
      );
    }

    option(key, value) {
      this.options.push({
        key,
        value,
        isCustom: false
      });

      return this;
    }

    customOption(key) {
      this.options.push({
        key,
        value: undefined,
        isCustom: true
      });

      return this;
    }
  }

  class _TextFieldComponent extends _CasePlanSubSectionComponent {
    label;

    constructor(config = {}) {
      super(config);

      if (!config.label) {
        throw new Error(
          'Case Plan TextFieldComponent must be provided a label.'
        );
      } else {
        this.label = config.label;
      }

      this.value = '';
      this.type = '_TextFieldComponent';
    }

    get isValid() {
      return this.value != null && this.value != undefined && this.value != '';
    }
  }

  class _ProgressTableComponent extends _CasePlanSubSectionComponent {
    number;
    progressTracker;
    tables;

    makeItemTable(table) {
      let valuesTable;
      if (table.tableConfig) {
        valuesTable = new ItemTable({
          title: table.tableConfig.title,
          key: table.key,
          items: table.tableConfig.items,
          itemsPerPage: table.tableConfig.hasOwnProperty('itemsPerPage')
            ? table.tableConfig.itemsPerPage
            : 10,
          currentPage: table.tableConfig.hasOwnProperty('currentPage')
            ? table.tableConfig.currentPage
            : 1,
          hasSearchBar: table.tableConfig.hasOwnProperty('hasSearchBar')
            ? table.tableConfig.hasSearchBar
            : true
        });
      } else {
        // assuming table has already been created
        valuesTable = table;
      }

      _.forEach(table.tableColumnConfigs, (tcc) => {
        // columns definition tables should not be sortable
        if (table.columnsReference) tcc.sortable = false;
        valuesTable.column(tcc);
      });

      if (table.columnsReference) {
        valuesTable.columnsReference = table.columnsReference.key;
        valuesTable.sortableRows = true;
      }
      return valuesTable;
      // tables.push(valuesTable);
    }

    constructor(config = {}, tool) {
      config.key = config.key ? config.key : 'untitledProgressTable';
      if (config.label) config.title = config.label;
      config.title = config.title ? config.title : 'UNTITLED PROGRESS TABLE';
      super(config);

      this.type = '_ProgressTableComponent';

      // fix intervention columns
      // _.forEach(config.columns, col => {
      //   if (col.key === 'intervention') {
      //     _.forEach(col.options, opt => {
      //       _.forEach(opt.programsOptions, progOpt => {
      //         if (progOpt.value === '') {
      //           let newId = shortid.generate();
      //           progOpt.value = newId;
      //           _.forEach(config.conditions, cond => {
      //             let interventionResult = _.find(cond.results, {columnKey: 'intervention'});
      //             if (interventionResult) {
      //               if (interventionResult.option.category === opt.value && interventionResult.option.program === '') {
      //                 interventionResult.option.program = newId;
      //               }
      //             }
      //           });
      //         }
      //       });
      //     });
      //   }
      // });

      this.number = config.number || 0.0;
      this.progressTracker = !!config.progressTracker;
      this.tables = config.tables || [];
      this.collapsible = config.hasOwnProperty('collapsible')
        ? config.collapsible
        : true;

      let conditionsOptions = $util.getConditionTypes();
      let typeOptions = $util.getColumnTypes();

      let customOptionOptions = [
        {
          value: true,
          label: 'Yes'
        },
        {
          value: false,
          label: 'No'
        },
        {
          value: 'N/A',
          label: 'N/A'
        }
      ];

      if (!this.tables.length) {
        /**
         * value: form selection value
         * label: form selection label
         * formInfluence: if item is selected, provide subsequent form fields
         *        type: type of influence (dropdown, interventions, etc.)
         *        values: values/options created by the user
         */

        // check for columns being passed
        let columnsTableItems = [];
        if (config.columns && config.columns.length) {
          _.forEach(config.columns, (col) => {
            if (!col.label && col.title) col.label = col.title;

            // check if enum or intervention type to add values to typeOptions if needbe
            if (typeof col.type === 'object') {
              // corrective for old issue
              col.options = col.type.options;
              col.type = col.type.value;
            }
            if (col.type === 'enum' || col.type === 'intervention') {
              if (col.key === 'intervention') col.type = 'intervention';
              let typeOption = _.find(typeOptions, { value: col.type });
              if (typeOption && typeOption.hasOwnProperty('formInfluence')) {
                typeOption.formInfluence.values = col.options;
              }
            }

            columnsTableItems.push(col);
          });
        }

        let columnsTable = new _TableComponent(
          {
            key: `${config.key}Columns`,
            title: `${config.title} - COLUMNS`,
            tableConfig: {
              title: `${config.title} - COLUMNS`,
              items: columnsTableItems,
              hasSearchBar: false
            }
          },
          tool
        )
          .tableColumn({
            title: 'Label',
            key: 'label',
            type: 'string',
            tooltip: 'Column title'
          })
          .tableColumn({
            title: 'Type',
            key: 'type',
            type: 'enum',
            options: typeOptions
          })
          .tableColumn({
            title: 'Allow Custom Option',
            key: 'allowCustomOption',
            type: 'enum',
            options: customOptionOptions,
            tooltip: 'Should the evaluator be able to input their own option?'
          })
          .tableColumn({
            title: 'Required',
            key: 'required',
            type: 'boolean',
            tooltip:
              'Is this item required to be filled out when adding it as a case plan recommendation?'
          })
          .tableColumn({
            title: 'Progress Section',
            key: 'progressSection',
            type: 'boolean',
            tooltip:
              "Should this be it's own section on the Progress Tracking table?"
          });

        // Progress Tracker Table (2nd Table)
        if (this.progressTracker) {
          let progressTableItems = [];

          // check for conditions being passed
          if (config.conditions && config.conditions.length) {
            _.forEach(config.conditions, (cond) => {
              if (cond.results && cond.results.length) {
                _.forEach(cond.results, (result) => {
                  cond[result.columnKey] = result.option;
                });
              }
              if (cond.conditions && cond.conditions.length) {
                let conditions = [];
                _.forEach(cond.conditions, (condition) => {
                  let newCond = {
                    value: condition.type,
                    subOptions: []
                  };
                  if (condition.type === 'score') {
                    newCond.subOptions.push({ value: condition.value });
                  } else if (condition.type === 'scoreRange') {
                    _.forEach(condition.value, (val) => {
                      newCond.subOptions.push({ value: val });
                    });
                  } else if (condition.type === 'specificAnswer') {
                    // working on specific answer type
                    let q = findQuestion(tool, condition.value?.questionId);
                    if (!q) {
                      console.error(
                        `{Condition Type Specific Answer} Could not find question for condition value = ${condition.value?.questionId}`
                      );
                      return;
                    }
                    let qOptions = [];
                    _.forEach(q.question?.codesAndScore, (cas) => {
                      qOptions.push({
                        value: cas.text,
                        label: cas.text
                      });
                    });
                    let qLabel = `${q.questionTool?.flyoutName} :: ${q.question?.riskFactor}`;
                    newCond.subOptions.push({
                      value: {
                        value: condition.value.questionId,
                        label: qLabel,
                        options: qOptions
                      },
                      subOptionValue: condition.value.answerText
                    });
                  } else if (typeof condition.value === 'string') {
                    // insert options and auto select value
                    let conditionOption = _.find(conditionsOptions, {
                      value: condition.type
                    });
                    conditionOption = _.find(conditionOption.options, {
                      type: 'enum'
                    });

                    newCond.subOptions = [
                      {
                        value: conditionOption
                          ? _.find(conditionOption.options, {
                              value: condition.value
                            })
                          : condition.value,
                        options: conditionOption ? conditionOption.options : []
                      }
                    ];
                  } else if (Array.isArray(condition.value)) {
                    _.forEach(condition.value, (val) => {
                      newCond.subOptions.push({ value: val.value });
                    });
                  } else if (typeof condition.value === 'object') {
                    if (condition.value.hasOwnProperty('toolAddress')) {
                      // riskLevel with subOptionType value
                      // find the conditions column and assign the corresponding option
                      let subOptionOptions;
                      _.forEach(conditionsOptions, (opt) => {
                        if (opt.value === 'riskLevel') {
                          let enumOptions = _.find(opt.options, {
                            type: 'enum'
                          }).options;
                          _.forEach(enumOptions, (option) => {
                            if (option.value === condition.value.toolAddress)
                              subOptionOptions = option.options;
                          });
                        }
                      });
                      newCond.subOptions.push({
                        subOptionValue: condition.value.value,
                        value: {
                          value: condition.value.toolAddress,
                          label: condition.value.toolName,
                          options: subOptionOptions
                        }
                      });
                    }
                  }
                  conditions.push(newCond);
                });
                cond.conditions = conditions;
              }
              progressTableItems.push(cond);
            });
          }

          let progressTable = new _TableComponent({
            key: `${config.key}ConditionRules`,
            title: `${config.title} - CONDITION RULES`,
            tableConfig: {
              title: `${config.title} - CONDITION RULES`,
              items: progressTableItems,
              hasSearchBar: false
            }
          }).tableColumn({
            title: 'Conditions',
            key: 'conditions',
            type: 'conditions',
            template: 'Conditions',
            options: conditionsOptions
          });

          // commented out if check because it wouldn't create the columns
          // if we didn't already have conditions being passed
          // if (progressTableItems.length) {
          _.forEach(columnsTableItems, (cti) => {
            progressTable.tableColumn(cti);
          });
          // }

          columnsTable.columnsReference = progressTable;
          columnsTable = this.makeItemTable(columnsTable);
          progressTable = this.makeItemTable(progressTable);
          this.tables.push(columnsTable);
          this.tables.push(progressTable);
          // assign newly created ItemTable if columnsReference is present
          _.forEach(this.tables, (table) => {
            if (table.columnsReference)
              table.columnsReference = _.find(this.tables, {
                key: table.columnsReference
              });
          });
        } else {
          columnsTable = this.makeItemTable(columnsTable);
          this.tables.push(columnsTable);
        }
      } else if (
        this.progressTracker &&
        _.indexOf(this.tables, (table) => {
          return table.title.includes('CONDITION RULES');
        }) < 0
      ) {
        // already has tables, but needs progress table recast
        let columnsTable;
        for (let i = 0; i < this.tables.length; i++) {
          if (this.tables[i].title.includes('COLUMNS'))
            columnsTable = this.tables[i];
        }

        let progressTable = new _TableComponent({
          key: `${config.key}ConditionRules`,
          title: `${config.title} - CONDITION RULES`,
          tableConfig: {
            title: `${config.title} - CONDITION RULES`,
            items: [],
            hasSearchBar: false
          }
        }).tableColumn({
          title: 'Conditions',
          key: 'conditions',
          type: 'conditions',
          template: 'Conditions',
          options: conditionsOptions
        });

        _.forEach(columnsTable.cols, (cti) => {
          progressTable.tableColumn(cti);
        });
        this.tables.push(progressTable);
      }

      this.value = [];
    }

    get isValid() {
      return this.value != null && this.value != undefined && this.value.length;
    }

    table(config = {}) {
      this.tables.push(config);

      return this;
    }

    toJson() {
      let submission = {
        number: this.number,
        key: this.key,
        label: this.title,
        progressTracker: this.progressTracker,
        columns: [],
        conditions: []
      };
      _.forEach(this.tables, (table) => {
        // check if we're dealing with column table or conditions table
        if (table.title.includes('- COLUMNS')) {
          _.forEach(table.items, (item) => {
            if (item.item) item = item.item;
            if (!item.title && item.label) item.title = item.label;
            let column = {
              key: item.key,
              title: item.title,
              type: item.type,
              required: item.required,
              progressSection: item.progressSection,
              allowCustomOption: item.allowCustomOption
            };

            switch (item.type) {
              case 'text':
                break;
              case 'intervention':
                column.allowCustomOption = item.allowCustomOption;
                column.options = [];
                _.forEach(item.options, (option) => {
                  let opt = {
                    value: option.value,
                    label: option.label
                  };
                  // column could be intervention type and contain option.programsOptions
                  if (option.programsOptions) {
                    opt.programsOptions = [];
                    _.forEach(option.programsOptions, (progOpt) => {
                      opt.programsOptions.push({
                        value: progOpt.value,
                        label: progOpt.label,
                        contactDetails: progOpt.contactDetails
                      });
                    });
                  }
                  column.options.push(opt);
                });
                break;
              case 'enum':
                column.allowCustomOption = item.allowCustomOption;
                column.options = [];
                _.forEach(item.options, (option) => {
                  let opt = {
                    value: option.value,
                    label: option.label
                  };
                  // column could be intervention type and contain option.programsOptions
                  if (option.programsOptions) {
                    opt.programsOptions = [];
                    _.forEach(option.programsOptions, (progOpt) => {
                      opt.programsOptions.push({
                        value: progOpt.value,
                        label: progOpt.label,
                        contactDetails: progOpt.contactDetails
                      });
                    });
                  }
                  column.options.push(opt);
                });
                break;
              case 'date':
                column.format = 'mm/dd/yyyy';
                break;
              case 'dateTime':
                column.format = 'mm/dd/yyyy hh:mm:ss A';
                break;
              case 'duration':
                break;
            }
            submission.columns.push(column);
          });
        } else if (table.title.includes('- CONDITION RULES')) {
          let conditionRule = {
            conditions: [],
            results: []
          };

          let processConditionValue = function (subOption, conditionType) {
            // value field is a subOption and we need more information added
            if (subOption.subOptionValue && conditionType === 'riskLevel') {
              return {
                value: subOption.subOptionValue,
                toolAddress: subOption.value.value,
                toolName: subOption.value.label
              };
            } else if (
              subOption.subOptionValue &&
              conditionType === 'specificAnswer'
            ) {
              return {
                questionId: subOption.value.value,
                answerText: subOption.subOptionValue
              };
            }

            // value field is the exact match
            if (typeof subOption.value !== 'object') return subOption.value;
            if (subOption.value && subOption.value.value)
              return subOption.value.value;
          };

          _.forEach(table.items, (item) => {
            conditionRule = {
              conditions: [],
              results: []
            };
            if (item.item) item = item.item;

            _.forEach(item.conditions, (cond) => {
              let condition = { type: cond.value };
              if (cond.subOptions.length === 1) {
                condition.value = processConditionValue(
                  cond.subOptions[0],
                  cond.value
                );
              } else {
                condition.value = [];
                _.forEach(cond.subOptions, (subOpt) => {
                  condition.value.push(
                    processConditionValue(subOpt, cond.value)
                  );
                });
              }
              conditionRule.conditions.push(condition);
            });

            // process results if conditions are satisfied
            for (let key in item) {
              if (key === 'conditions') continue;
              if (item.hasOwnProperty(key)) {
                conditionRule.results.push({
                  columnKey: key,
                  option: item[key]
                });
              }
            }

            if (conditionRule.conditions.length) {
              submission.conditions.push(conditionRule);
            }
          });
        }
      });
      return submission;
    }
  }

  class _ProgressTrackerTable extends _CasePlanSubSectionComponent {
    constructor(config = {}) {
      super(config);

      let conditionsOptions = $util.getConditionTypes();
      return new _TableComponent({
        key: `${config.key}ConditionRules`,
        title: `${config.title} - CONDITION RULES`,
        tableConfig: {
          title: `${config.title} - CONDITION RULES`,
          items: [],
          hasSearchBar: false
        }
      }).tableColumn({
        title: 'Conditions',
        key: 'conditions',
        type: 'conditions',
        template: 'Conditions',
        options: conditionsOptions
      });
    }
  }

  class _ClientCasePlan {
    constructor(data) {
      this.title = data.title || '';
      this.sections = data.sections || [];
      this.client = data.client || {};
    }
  }

  //endregion CLASSES

  var CasePlan = {
    // generates a case plan template based off of config
    CasePlan(config, tool) {
      return new _CasePlan(config, tool);
    },
    ClientCasePlan(caseplan) {
      _.forEach(caseplan.sections, (section) => {
        _.forEach(section.subSections, (subSection) => {
          _.forEach(subSection.components, (component) => {
            // create first table
            let tableItems = [];
            if (component.table && component.table.items) {
              _.forEach(component.table.items, (item) => {
                tableItems.push(item.item);
              });
            }
            component.table = new ItemTable({
              title: `${component.number} ${component.label}`,
              items: tableItems,
              key: component.key,
              hasSearchBar: false,
              itemsPerPage: 5,
              editable: true
            });

            _.forEach(component.columns, (column) => {
              component.table.column(column);
            });

            // create progress tracker table
            if (component.progressTracker) {
              let progressTrackerItems = [];
              if (
                component.progressTrackerTable &&
                component.progressTrackerTable.items
              ) {
                _.forEach(component.progressTrackerTable.items, (item) => {
                  progressTrackerItems.push(item.item);
                });
              }
              component.progressTrackerTable = new ItemTable({
                title: `${component.number} ${component.label} - Progress Record`,
                items: progressTrackerItems,
                key: `${component.key}ProgressRecord`,
                hasSearchBar: false,
                itemsPerPage: 5,
                editable: true
              })
                .column({
                  title: 'Date',
                  key: 'date',
                  type: 'date'
                })
                .column({
                  title: 'Change',
                  key: 'change',
                  type: 'enum',
                  options: $util.getChangeOptions()
                })
                .column({
                  title: 'Noteworthy Developments',
                  key: 'noteworthyDevelopments',
                  type: 'text'
                });
            }

            // set up progress reference so we can influence changes in the progress table from the first table
            component.table.progressReference = component.progressTrackerTable;
            component.table.generateProgressSections();
          });
        });
      });
      return new _ClientCasePlan({
        title: caseplan.title,
        sections: caseplan.sections,
        client: caseplan.client
      });
    },
    CasePlanSection(config, parent) {
      return new _CasePlanSection(config, parent);
    },
    CasePlanSubSection(config) {
      return new _CasePlanSubSection(config);
    },
    TableComponent(config) {
      return new _TableComponent(config);
    },
    SelectorComponent(config) {
      return new _SelectorComponent(config);
    },
    TextFieldComponent(config) {
      return new _TextFieldComponent(config);
    },
    ProgressTableComponent(config) {
      return new _ProgressTableComponent(config);
    },
    CasePlanSubSectionComponent(config) {
      return new _CasePlanSubSectionComponent(config);
    },
    ProgressTrackerTable(config) {
      return new _ProgressTrackerTable(config);
    },
    /**
     * generates a new case plan given an evaluation, template, and the client
     */
    async GenerateCasePlan(template, evaluation, client, templateInfo) {
      _.forEach(template.sections, (section) => {
        _.forEach(section.subSections, (subSection) => {
          _.forEach(subSection.components, (component) => {
            // create first table
            component.table = new ItemTable({
              title: `${component.number} ${component.label}`,
              items: [],
              key: component.key,
              hasSearchBar: false,
              itemsPerPage: 5,
              editable: true
            });

            _.forEach(component.columns, (column) => {
              component.table.column(column);
            });

            // create progress tracker table
            if (component.progressTracker) {
              component.progressTrackerTable = new ItemTable({
                title: `${component.number} ${component.label} - Progress Record`,
                items: [],
                key: `${component.key}ProgressRecord`,
                hasSearchBar: false,
                itemsPerPage: 5,
                editable: true
              })
                .column({
                  title: 'Date',
                  key: 'date',
                  type: 'date'
                })
                .column({
                  title: 'Change',
                  key: 'change',
                  type: 'enum',
                  options: $util.getChangeOptions()
                })
                .column({
                  title: 'Noteworthy Developments',
                  key: 'noteworthyDevelopments',
                  type: 'text'
                });
            }

            if (component.conditions) {
              _.forEach(component.conditions, (condition) => {
                if (
                  $util.checkCaseConditions(
                    condition.conditions,
                    client,
                    evaluation
                  )
                ) {
                  let dataItem = {};
                  _.forEach(condition.results, (result) => {
                    dataItem[result.columnKey] = result.option;
                  });
                  component.table.item(dataItem);
                }
              });
            }

            // set up progress reference so we can influence changes in the progress table from the first table
            component.table.progressReference = component.progressTrackerTable;
            component.table.generateProgressSections();
          });
        });
      });
      let caseplan = new _ClientCasePlan({
        title: `${template.title} for ${client.name()}`,
        sections: template.sections,
        client: {
          id: evaluation.clientId,
          fName: evaluation.client.fName,
          mName: evaluation.client.mName,
          lName: evaluation.client.lName,
          localId: evaluation.client.localId
        }
      });

      let submissionResponse = await this.SaveCasePlan(
        { plan: caseplan },
        client.institutionId,
        client.id,
        templateInfo,
        client.subGroup.id,
        evaluation.id
      );
      if (submissionResponse.status === 200) {
        submissionResponse = submissionResponse.data;
      } else {
        notify.display(submissionResponse, 'error');
      }
      if (submissionResponse) caseplan.id = submissionResponse.id;
      return caseplan;
    },
    // submit client case plan (new or update)
    async SaveCasePlan(
      caseplan,
      institutionId,
      clientId,
      cpTemplateInfo,
      subGroupId,
      evaluationId
    ) {
      $store.commit('setLoadingMessage', 'Saving Case Plan');
      $store.commit('setIsLoading', true);
      let payload = cpTemplateInfo;
      payload.plan = caseplan.plan ? caseplan.plan : caseplan;
      payload.evaluationId = evaluationId;
      let deferred = $q.defer();
      let response;
      if (caseplan.id) {
        response = await $api.clientManager.updateClientCasePlan(
          {
            instId: institutionId,
            sbGrpId: subGroupId,
            clntId: clientId,
            casePlanId: caseplan.id
          },
          payload
        );
      } else {
        response = await $api.clientManager.createClientCasePlan(
          {
            instId: institutionId,
            sbGrpId: subGroupId,
            clntId: clientId
          },
          payload
        );
      }
      if (response.status === 200) {
        deferred.resolve(response);
        notify.display('Case Plan Saved Successfully', 'success');
        $store.commit('setIsLoading', false);
      } else {
        notify.display(response, 'error');
        deferred.reject();
      }
      $store.commit('setIsLoading', false);
      return deferred.promise;
    },
    // make case plan current
    async MakeCasePlanCurrent(
      caseplan,
      institutionId,
      clientId,
      currentCasePlan,
      subGroupId
    ) {
      $store.commit('setLoadingMessage', 'Setting Case Plan to Current');
      $store.commit('setIsLoading', true);
      let deferred = $q.defer();
      let casePlanId =
        !caseplan.id && caseplan.plan ? caseplan.plan.id : caseplan.id;
      if (!casePlanId) {
        console.error('No case plan id detected');
        $store.commit('setIsLoading', false);
        return;
      }

      if (currentCasePlan) {
        let currentCasePlanId =
          !currentCasePlan.id && currentCasePlan.plan
            ? currentCasePlan.plan.id
            : currentCasePlan.id;
        // let setCurrentToPreviousResponse = await $http.post(
        //   `/api/institutions/${institutionId}/clients/${clientId}/case-plans/${currentCasePlanId}/status`,
        //   { status: 'PREVIOUS' }
        // );
        let setCurrentToPreviousResponse =
          await $api.clientManager.updateClientCasePlanStatus(
            {
              instId: institutionId,
              sbGrpId: subGroupId,
              clntId: clientId,
              casePlanId: currentCasePlanId
            },
            { status: 'PREVIOUS' }
          );
        if (setCurrentToPreviousResponse.status !== 200)
          notify.error('Could not set current case plan to previous', 'error');
      }

      let res = await $api.clientManager.updateClientCasePlanStatus(
        {
          instId: institutionId,
          sbGrpId: subGroupId,
          clntId: clientId,
          casePlanId
        },
        { status: 'CURRENT' }
      );
      if (res.status === 200) {
        notify.display('Case Plan Set to Current', 'success');
        deferred.resolve(res.data);
      } else {
        notify.display(res, 'error');
        deferred.reject();
      }
      $store.commit('setIsLoading', false);
      return deferred.promise;
    },
    // submit case plan template
    async SaveCasePlanTemplate(
      templateData,
      toolId,
      toolCommitId,
      institutionId,
      caseplanOptions
    ) {
      $store.commit('setLoadingMessage', 'Saving Case Plan Template');
      $store.commit('setIsLoading', true);
      let deferred = $q.defer();
      let url;
      let payload = {};
      let templateId;
      let previousCommitId;
      let managedTemplate = false;
      console.log('caseplanoptions: ', caseplanOptions);

      // check if we have a previous commit to work off of
      if (caseplanOptions.previousTemplate) {
        if (caseplanOptions.previousTemplate.managedCasePlanTemplateId) {
          managedTemplate = true;
          templateId =
            caseplanOptions.previousTemplate.managedCasePlanTemplateId;
        } else if (
          caseplanOptions.previousTemplate.institutionCasePlanTemplateId
        ) {
          managedTemplate = false;
          templateId =
            caseplanOptions.previousTemplate.institutionCasePlanTemplateId;
        }
        previousCommitId = caseplanOptions.previousTemplate.id;
      }

      // institution's case plan template
      if (institutionId) {
        managedTemplate = false;
        if (templateId) {
          let message = await $modals.test.generalForm('COMMIT MESSAGE', [
            {
              type: 'string',
              placeholder: 'Commit Message',
              value: '',
              label: 'Message',
              key: 'message'
            }
          ]);

          // if message is undefined, modal was cancelled
          if (!message) {
            $store.commit('setIsLoading', false);
            return;
          }
          // if message is an object and message.message is undefined, the message was null
          if (!message.message) {
            notify.display('Must provide a commit message', 'error');
            $store.commit('setIsLoading', false);
            return;
          }
          message = message.message;
          url = `/api/icpt/${institutionId}/case-plan-templates/${templateId}/commit`;
          payload = {
            templateData,
            toolCommitId,
            message,
            previousCommitId
          };
        } else {
          if (!templateData.title) {
            notify.display('Must provide a name for the template', 'error');
            $store.commit('setIsLoading', false);
            return;
          }
          url = `/api/icpt/${institutionId}/case-plan-templates`;
          payload = {
            templateData,
            toolCommitId,
            toolId,
            name: templateData.title
          };
        }
      }

      // new master/managed case plan template
      else if (caseplanOptions.newMasterTemplate) {
        if (!templateData.title) {
          notify.display('Must provide a name for the template', 'error');
          $store.commit('setIsLoading', false);
          return;
        }
        url = `/api/mcpt/managed-case-plan-templates`;
        payload = {
          templateData,
          toolCommitId,
          toolId,
          name: templateData.title
        };
      }

      // previous template commit exists
      else if (templateId) {
        url = `/api/mcpt/managed-case-plan-templates/${templateId}/commit`;

        let message = await $modals.test.generalForm('COMMIT MESSAGE', [
          {
            type: 'string',
            placeholder: 'Commit Message',
            value: '',
            label: 'Message',
            key: 'message'
          }
        ]);

        // if message is undefined, modal was cancelled
        if (!message) {
          $store.commit('setIsLoading', false);
          return;
        }
        // if message is an object and message.message is undefined, the message was null
        if (!message.message) {
          notify.display('Must provide a commit message', 'error');
          $store.commit('setIsLoading', false);
          return;
        }
        message = message.message;

        if (!previousCommitId) {
          notify.display('Must provide a previous commit id', 'error');
          $store.commit('setIsLoading', false);
          return;
        }
        payload = {
          templateData,
          message,
          toolCommitId,
          previousCommitId
        };
      }

      // commit/post the template
      $http
        .post(url, payload)
        .then((res) => {
          $store.commit('setIsLoading', false);
          if (res.data.error) {
            notify.display(res, 'error');
            deferred.reject(res.data);
          } else {
            notify.display('Case Plan Template saved successfully', 'success');
            deferred.resolve(res);
          }
        })
        .catch((err) => {
          notify.display(err, 'error');
          $store.commit('setIsLoading', false);
          deferred.reject(err);
        });
      return deferred.promise;
    },
    SampleCasePlan() {
      var config = new _CasePlan({
        title: 'CASE PLAN TITLE'
      });

      // config.section({
      //   number: 1,
      //   title: 'CASE PLAN SECTION TITLE'
      // })
      //   .subSection({
      //     number: 1,
      //     title: 'CASE PLAN SUB-SECTION TITLE',
      //     description: 'CASE PLAN SUB-SECTION DESCRIPTION',
      //     components: []
      //   });
      return config;
    },
    SavedCasePlan() {
      return {
        title: 'Example Case Plan',
        sections: [
          {
            title: 'Case Management Plan',
            number: 1,
            subSections: [
              {
                title: 'Criminogenic Needs',
                number: 1.1,
                description:
                  "Evaluate the offender's progress on his or her criminogenic needs by noting the nature of the change, and describe important developments.",
                components: [
                  {
                    number: '1.1.1',
                    key: 'criminogenicNeeds',
                    label: 'Criminogenic Needs',
                    progressTracker: true,
                    columns: [
                      {
                        key: 'criminogenicNeeds',
                        title: 'Criminogenic Needs',
                        type: 'enum',
                        required: true,
                        progressSection: true,
                        allowCustomOption: false,
                        options: [
                          {
                            value: 'familyCircumstancesParenting',
                            label: 'Family Circumstances/Parenting'
                          },
                          {
                            value: 'educationEmployment',
                            label: 'Education/Employment'
                          },
                          {
                            value: 'peerRelations',
                            label: 'Peer Relations'
                          },
                          {
                            value: 'substanceAbuse',
                            label: 'Substance Abuse'
                          },
                          {
                            value: 'leisureRecreation',
                            label: 'Leisure/Recreation'
                          },
                          {
                            value: 'personalityBehavior',
                            label: 'Personality/Behavior'
                          },
                          {
                            value: 'attitudesOrientation',
                            label: 'Attitudes/Orientation'
                          }
                        ]
                      },
                      {
                        key: 'goal',
                        title: 'Goal',
                        type: 'text',
                        required: true,
                        progressSection: false,
                        allowCustomOption: 'N/A'
                      },
                      {
                        key: 'intervention',
                        title: 'Intervention',
                        type: 'intervention',
                        required: true,
                        progressSection: false,
                        allowCustomOption: false,
                        options: [
                          {
                            value: 'alcoholAbuse',
                            label: 'Alcohol Abuse',
                            programsOptions: [
                              {
                                value: 'alcoholicsAnonymous',
                                label: 'Alcoholics Anonymous',
                                contactDetails:
                                  'address:  12345 Second St dfs\nphone: 123456789\nemail:  dasd@fas'
                              }
                            ]
                          },
                          {
                            value: 'substanceAbuse',
                            label: 'Substance Abuse',
                            programsOptions: [
                              {
                                value: 'narcoticsAnonymous',
                                label: 'Narcotics Anonymous',
                                contactDetails:
                                  'address: 123 Main St dfs\nphone: 031654987\nemail:  dadsf@fas'
                              }
                            ]
                          }
                        ]
                      },
                      {
                        key: 'duration',
                        title: 'Duration',
                        type: 'text',
                        required: true,
                        progressSection: false,
                        allowCustomOption: 'N/A'
                      },
                      {
                        key: 'intensity',
                        title: 'Intensity',
                        type: 'intensity',
                        required: true,
                        progressSection: false,
                        allowCustomOption: true
                      }
                    ],
                    conditions: [
                      {
                        conditions: [
                          {
                            type: 'riskLevel',
                            value: 'Moderate'
                          },
                          {
                            type: 'sex',
                            value: 'male'
                          }
                        ],
                        results: [
                          {
                            columnKey: 'criminogenicNeeds',
                            option: 'substanceAbuse'
                          },
                          {
                            columnKey: 'goal',
                            option:
                              'Acknowledge/accept program. Reduce consumption.'
                          },
                          {
                            columnKey: 'intervention',
                            option: {
                              category: 'alcoholAbuse',
                              program: 'alcoholicsAnonymous'
                            }
                          },
                          {
                            columnKey: 'intensity',
                            option: 'monthly'
                          },
                          {
                            columnKey: 'duration',
                            option: '1 Month'
                          }
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      };
    }
  };

  return CasePlan;
}

// export default angular
//   .module('app.service.casePlan', [])
//   .factory(
//     'CasePlan',

//   ).name;

const findQuestion = function (tool, questionId) {
  console.log('find question: ', tool, questionId);
  let question;
  let questionTool;
  let parseTool = function (t) {
    if (question) return;
    _.forEach(t.codingFormItems, (cfi) => {
      if (cfi.id === questionId) {
        question = cfi;
        questionTool = t;
      }
    });
    if (t.childTools?.length) {
      _.forEach(t.childTools, (ct) => {
        parseTool(ct);
      });
    }
  };
  parseTool(tool);
  return { question, questionTool };
};
