import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class CodingFormItemButton {}

export default angular
  .module('app.cfiBtn', [])
  .directive('cfiBtn', function() {
    return {
      template: require('./cfi-btn.html'),
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: {
        icon: '=',
        tooltip: '=',
        classList: '='
      },
      controller: CodingFormItemButton,
      controllerAs: 'vm'
    };
  }).name;
