import { remove, uniqBy, cloneDeep, find } from 'lodash';

function parseEval(evl) {
  if (evl.client)
    evl.client.fullName = `${evl.client.lName}, ${evl.client.fName}`;

  if (evl.evaluator)
    evl.evaluator.fullName = `${evl.evaluator.lName}, ${evl.evaluator.fName}`;

  if (typeof evl.clientId === 'number') evl.clientId = evl.clientId.toString();

  if (!evl.clientId && evl.client) evl.clientId = evl.client.id;

  if (evl.evaluation && typeof evl.evaluation.clientId === 'number')
    evl.evaluation.clientId = evl.evaluation.clientId.toString();

  if (evl.evaluationData && typeof evl.evaluationData.clientId === 'number')
    evl.evaluationData.clientId = evl.evaluationData.clientId.toString();

  if (evl.client?.institutionId) evl.institutionId = evl.client.institutionId;
  if (!evl.instituitonId && evl.client?.accountId)
    evl.instituitonId = evl.client.accountId;

  if (!evl.toolUsed && evl.tool?.id) evl.toolUsed = evl.tool.id;
  return evl;
}

function EvaluationsStore(
  $rootScope,
  $api,
  $api2,
  errorHandler,
  notify,
  $acl,
  $reincode
) {
  'ngInject';

  const state = {
    loading: false,
    items: [],
    table: {
      sortedCol: 0,
      searchText: ''
    }
  };

  const getters = {
    find: (state) => (ref) => {
      return state.items.find((item) => item == ref || item.id == ref.id);
    },
    getById: (state) => (id) => {
      return state.items.find((item) => item.id == id);
    },
    getByClientId: (state) => (id) => {
      return state.items.filter((item) => item.clientId == id);
    }
  };

  const actions = {
    async list({ rootState, state, commit, dispatch, rootGetters }) {
      let data, res;

      const grnProfile = rootState.permissions.profile;

      // check to see if we're restricted to specific SubGroups
      // and get the list from policy
      let canListSubgroupsClientsEvaluations;
      let listClientResources;
      if (!$acl('GM:ListEvaluations', grnProfile)) {
        // Not a GEARS Admin
        listClientResources = rootGetters['permissions/getResources'](
          'institutionmanager:ListClients',
          'subGroup'
        );

        canListSubgroupsClientsEvaluations =
          listClientResources &&
          ((typeof listClientResources === 'object' &&
            Object.getOwnPropertyNames(listClientResources).length > 0) ||
            listClientResources == '*');
      }

      if ($acl('GM:ListEvaluations', grnProfile)) {
        // List All Evaluations
        data = [];

        res = await $api.GM.listEvaluations();

        if (res.status !== 200) throw res;

        data.push(...res.data.map(parseEval));
      } else if ($acl('IM:ListEvaluations', grnProfile)) {
        // List All Institution Evaluations
        data = [];

        res = await $api.IM.listEvaluations({
          instId: rootState.me.institution?.id
        });

        if (res.status !== 200) throw res;

        data.push(...res.data.map(parseEval));
      } else if (
        $acl('IM:ListClients', grnProfile) &&
        $acl('CM:ListClientEvaluations', grnProfile) &&
        canListSubgroupsClientsEvaluations
      ) {
        // List All Evaluations for a list of Sub Groups
        data = [];

        const subgroupIds =
          listClientResources && listClientResources !== '*'
            ? Object.values(listClientResources)
                .map(({ resourceValue }) => resourceValue)
                .filter((val) => typeof val == 'string')
            : '*';

        res = await $api.IM.listSubGroupClientsEvaluations(
          {
            instId: rootGetters.activeInstId
          },
          {
            subgroupIds
          }
        );

        if (res.status !== 200) throw res;

        data.push(...res.data.map(parseEval));
      } else if (
        rootState.clients?.items?.length &&
        $acl('CM:ListClientEvaluations', grnProfile)
      ) {
        // List All Evaluations for a list of Clients
        data = [];

        for (let client of rootState.clients.items) {
          if (!client.subGroup?.id) {
            console.error('[evaluations/list] client must have a sub group');
            continue;
          }
          res = await $api.CM.listClientEvaluations({
            instId: client.institution?.id,
            sbGrpId: client.subGroup?.id,
            clntId: client.id
          });

          if (res.status !== 200) throw res;

          data.push(...res.data.map(parseEval));
        }
      }

      commit('set', data);

      dispatch('analytics/computeForEvaluations', null, true);

      return state.items;
    },
    async get({ state, commit }, { instId, sbGrpId, clntId, evalId }) {
      let data;

      // let res = await $api.evaluations.get(evalId);
      let res;
      try {
        res = await $api.CM.getClientEvaluation({
          instId,
          sbGrpId,
          clntId,
          evalId
        });
      } catch (err) {
        throw err;
      }

      if (res.data.error) throw res;

      if (
        res.data.evaluationData &&
        typeof res.data.evaluationData.clientId !== 'string'
      ) {
        res.data.evaluationData.clientId =
          res.data.evaluationData.clientId.toString();
      }

      data = $reincode.fullObject(res.data);
      data = parseEval(data);
      // let res = await $api.gearsManager.listEvaluations();
      // data = res.data;

      commit('add', data);

      return data;
    },
    async getAll({ state, commit, dispatch }) {
      commit('setProps', { loading: true });

      let data = [];

      try {
        let res = await $api.GM.listEvaluations();

        data = res.data.map(parseEval);
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      commit('set', data);
      dispatch('analytics/computeForEvaluations', null, true);
      commit('setProps', { loading: false });

      return state.items;
    },
    async getForUser({ state, rootState, commit }, userId) {
      userId = userId instanceof Number ? userId : rootState.me.id;

      let data = [];

      let res = await $api.clientManager.listClientEvaluations({
        instId: client.institution?.id,
        sbGrpId: client.subGroup?.id,
        clntId: client.id
      });

      if (res.status !== 200) throw res;

      try {
        let res = await $api.users.getEvaluations(userId);
        data = res.data;
        for (let i = 0; i < data.length; i++) {
          data[i] = parseEval(data[i]);
        }
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      commit('set', data);
      commit('setProps', { loading: false });

      return state.items;
    },
    async getForClient({ state, commit, dispatch, rootGetters }, clientId) {
      let client = rootGetters['clients/getById'](clientId);
      if (!client) {
        console.warn(
          `[ngStore:evaluations:getForClient] Client with id "${clientId}" could not be found.`
        );

        return;
      } else if (
        (!client?.institution?.id && !client?.institutionId) ||
        !client?.subGroup?.id ||
        !client.id
      ) {
        console.warn(
          `[ngStore:evaluations:getForClient] Client must have a Client ID, Institution ID, and SubGroup ID.`
        );

        return;
      }

      commit('setProps', { loading: true });

      let data = [];
      let res = await $api.clientManager.listClientEvaluations({
        instId: client.institution?.id
          ? client.institution.id
          : client.institutionId,
        sbGrpId: client.subGroup?.id,
        clntId: client.id
      });

      if (res.status !== 200) throw res;

      if (!res?.data?.length) return;
      data = res.data;
      for (let i = 0; i < data.length; i++) {
        data[i] = parseEval(data[i]);
      }
      commit('add', data);

      // Compute analytics for evaluation with client data
      dispatch(
        'analytics/computeForEvaluations',
        { tableData: true, client },
        true
      );

      commit(
        'clients/updateItem',
        {
          ref: client,
          props: { evaluations: data }
        },
        true
      );

      commit('setProps', { loading: false });

      // return state.items;
      return data;
    },
    async getForInstitution({ rootState, state, commit, dispatch }, instId) {
      if (!instId) {
        if (!rootState.me.institution) {
          return;
        }

        instId = rootState.me.institution.id;
      }

      let data = [];
      let error = null;

      commit('setProps', { loading: true });

      try {
        data = await $api2.im.listEvaluations({ institutionId: instId });
      } catch (err) {
        error = err;
      }

      commit('setProps', { loading: false });

      if (error) {
        throw error;
      }

      data = data.map((item) => parseEval(item));

      state.items = [];

      commit('set', data);
      commit('setProps', { loading: false });
      dispatch('analytics/computeForEvaluations', { growth: true }, true);

      return state.items;
    },
    async submit({ state, commit }, id) {
      let data = [];

      try {
        let res = await $api.users.getEvaluations(id);
        data = res.data;
        for (let i = 0; i < data.length; i++) {
          data[i] = parseEval(data[i]);
        }
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      commit('set', data);

      return state.items;
    },
    async delete({ state, rootState }, payload) {
      if (!payload) {
        throw console.error('Evaluation - Delete : No Payload provided');
      }

      // Try to find institution Id if not provided on the payload
      if (!payload.institutionId && rootState.activeInstId)
        payload.institutionId = rootState.activeInstId;

      if (
        !payload.institutionId &&
        payload.clientId &&
        find(rootState.clients.items, { id: payload.clientId })
      ) {
        payload.institutionId = find(rootState.clients.items, {
          id: payload.clientId
        }).account?.id;
      }

      if (!payload.institutionId) {
        throw console.error('Evaluation - Delete : No Institution ID provided');
      }

      try {
        let res = await $api.IM.deleteEvaluation({
          instId: payload.institutionId,
          clntId: payload.clientId,
          evalId: payload.id
        });
      } catch (err) {
        console.error(err);
        throw err;
      }

      remove(state.items, { id: payload.id });
    }
  };

  const mutations = {
    set(state, payload) {
      if (!Array.isArray(payload)) payload = [payload];
      state.items = [...payload];
      $rootScope.$broadcast('evaluationsSet');
    },
    add(state, payload) {
      if (!Array.isArray(payload)) payload = [payload];
      state.items = _.uniqBy([...payload, ...state.items], 'id');
      $rootScope.$broadcast('evaluationsSet');
    },
    update(state, payload) {
      //
    },
    updateItem(state, { ref = -1, props = {} }) {
      let item = state.getters.find(ref);

      if (!item) {
        return;
      }

      Object.keys(props).forEach((key) => {
        item[key] = props[key];
      });
    },
    remove({ items }, payload) {
      items.splice(
        items.findIndex((item) => item.id === payload),
        1
      );
    },
    setFocus(state, id) {
      state.focus = state.items.find((item) => item.id === id) || null;
    },
    setProps(state, props = {}) {
      for (let i in props) {
        if (i in state) {
          state[i] = props[i];
        }
      }
    },
    CLEAR(state) {
      Object.assign(state, {
        loading: false,
        items: [],
        table: {
          sortedCol: 0,
          searchText: ''
        }
      });
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
}

export default EvaluationsStore;
