'use strict';

import angular from 'angular';
import * as _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class ProgressTableComponent {
  valuesTable;

  @Inject ItemTable;
  @Inject TableViewDataTypes;

  $onInit() {
    let tables = [];

    _.forEach(this.component.tables, table => {
      let valuesTable;
      if (table.tableConfig) {
        valuesTable = new this.ItemTable({
          title: table.tableConfig.title,
          key: table.key,
          items: table.tableConfig.items,
          itemsPerPage: table.tableConfig.hasOwnProperty('itemsPerPage') ? table.tableConfig.itemsPerPage : 10,
          currentPage: table.tableConfig.hasOwnProperty('currentPage') ? table.tableConfig.currentPage : 1,
          hasSearchBar: table.tableConfig.hasOwnProperty('hasSearchBar') ? table.tableConfig.hasSearchBar : true
        });
      } else {
        // assuming table has already been created
        valuesTable = table;
      }

      _.forEach(table.tableColumnConfigs, tcc => {
        // columns definition tables should not be sortable
        if (table.columnsReference) tcc.sortable = false;
        valuesTable.column(tcc);
      });

      if (table.columnsReference) {
        valuesTable.columnsReference = table.columnsReference.key;
        valuesTable.sortableRows = true;
      }

      tables.push(valuesTable);
    });

    // assign newly created ItemTable if columnsReference is present
    _.forEach(tables, table => {
      if (table.columnsReference) table.columnsReference = _.find(tables, {'key': table.columnsReference});
    });

    this.component.tables = tables;
  }
}

// class ProgressTableComponentController {
//   // selectedOption = {};
//   // customValue;
//   //
//   valuesTable;
//
//   edit() {
//     console.log(this);
//   }
//
//   log(item) {
//     console.log(item);
//   }
// }

export default angular.module('app.component.progressTableComponent', [])
  .component('progressTableComponent', {
    template: require('./progress-table.html'),
    bindings: {
      component: '='
    },
    controller: ProgressTableComponent,
    controllerAs: 'vm'
  })
  .name;
