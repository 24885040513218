import angular from 'angular';

import './viewer-video.scss';

export default angular
  .module('fileViewer.videoViewer', [])
  .directive('videoViewer', () => {
    return {
      require: '^^fileViewer',
      replace: true,
      template: require('./viewer-video.html'),
      scope: true,
      link(scope, element, attrs, viewerCtrl) {
        scope.videoSrc = viewerCtrl.filePath;
        scope.loading = true;

        element.find('video').on('canplay', () => {
          scope.loading = false;
        });
      }
    };
  }).name;
