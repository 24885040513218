import { Component, Ng } from '@angular';
import angular from 'angular';
import $ from 'jquery';
import _ from 'lodash';

@Component({
  name: 'toolExampleJson',
  template: require('./tool-example-json.html')
})
export class DomLogger {
  private levelCheck(elem: Element) {
    return $(elem).parent().closest('tool-example-json');
  }
}

export function domLogger($scope, $element) {
  'ngInject';

  $scope.level = 1;

  let levelCheck = function (elem) {
    return $(elem).parent().closest('tool-example-json');
  };

  let parentElem = levelCheck($element[0]);

  while (parentElem.length > 0) {
    $scope.level++;
    parentElem = levelCheck(parentElem[0]);
  }

  if ($scope.level == 1) {
    $('.tool-editor-template-viewer-wrapper').append($element[0]);
  }

  $scope.attrProps = function (scope) {
    scope.props = new properties(scope.key, scope.value);

    scope.$watch(
      function (scope) {
        return scope.value;
      },
      function (newValue, oldValue) {
        scope.props.update(scope.value);
      },
      true
    );
  };

  var properties = function (k, v) {
    this.title = k.toString();
    this.type = '';
    this.children = 0;
    this.noValue = false;
    this.expanded = false;
    this.touched = false;
    this.hovering = false;
    this.curValue = angular.copy(v);

    this.update = function (v) {
      this.type = null ? 'null' : typeof v;
      if (this.type == 'object') {
        if (Array.isArray(v)) {
          this.type = 'array';
          this.children = v.length;
          this.noValue = this.children > 0;
        } else {
          let self = this;
          _.forEach(v, function (key, value) {
            self.children += v.hasOwnProperty(value) ? 1 : 0;
          });
          this.noValue = !(this.children > 0 || v !== null);
        }
      } else {
        this.expanded = true;
        this.noValue = v === null || v == undefined || v.toString() == '';
      }

      if (this.curValue != v) {
        if (!this.touched) {
          this.touched = true;
          this.expanded = this.expanded && this.children > 0;
        }
      }
      this.curValue = angular.copy(v);
    };

    this.hovering = function (e, hovering) {
      $('.attr-panel').removeClass('hovering');
      if (hovering) {
        $(e.target).closest('.attr-panel').addClass('hovering');
        e.stopPropagation();
      }
    };

    this.toggleExpand = function (e) {
      if (this.children > 0) {
        this.expanded = !this.expanded;
      }
      e.stopPropagation();
    };

    this.update(this.curValue);
  };
}

// export default angular.module('app.toolExampleJson', [])
//   .directive('toolExampleJson', function() {
//     return {
//       template: require('./toolExampleJson.html'),
//       restrict: 'E',
//       scope: {
//         arguments: '='
//       },
//       controller: domLogger
//     };
//   })
//   .name;
