'use strict';

import angular from 'angular';

import { Mutation } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class ExpungeClientModalController extends Modal {
  client: any;
  prevFcn: any;

  @Inject $store;
  @Inject $scope;
  @Inject $api;
  @Inject notify;
  @Mutation('clients/remove') removeClient;

  $setup() {
    this.expungeId = this.$props.expungeId;
    this.requestOngoing = this.$props.requestOngoing;
    this.client = this.$props.client;
    this.validationKey = '';
  }

  async submit() {
    if (!this.expungeId || !this.validationKey) {
      this.notify.display('Must provide an Expunge ID and Validation Key', 'warning');
      return;
    }
    this.processing = true;
    let res = await this.$api.IM.deleteClient({
      instId: this.client.institution.id,
      clntId: this.client.id
    }, {
      expungeClientRequestId: this.expungeId,
      validationKey: this.validationKey
    });

    if (res.status !== 204) {
      this.notify.display(res, 'error');
      this.processing = false;
      return;
    }

    this.notify.display('Client Expunge Request Successfully Submitted', 'success');
    this.notify.display('Please note, it will take some time for the client to ' +
      'be fully removed from the system', 'success', true);
    this.removeClient(this.client.id);
    this.processing = false;
    this.$close({
      error: false,
      client: this.client
    });
  }
}

export default angular
  .module('app.expungeClientModal', [])
  .directive('expungeClientModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./expunge-client.html'),
    controller: ExpungeClientModalController,
    controllerAs: 'vm'
  })).name;
