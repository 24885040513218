import angular from 'angular';

// import { CasePlanService } from './case-plan.service';
import { CasePlanService } from './case-plan.old.service';

declare module 'angular' {
  namespace gears {
    type ICasePlanService = typeof CasePlanService;
  }
}

export default angular
  .module('app.casePlans', [])
  .service('CasePlan', CasePlanService).name;
