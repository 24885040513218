import { ILocationService } from 'angular';
import { GetterTree } from 'angular-store/types';

import { RootState } from './state';

/**
 * ...
 */
export interface RootGetters {
  isAdmin: boolean;
  activeInstId: string | null;
  activeInstAccountId: string | null;
  isDev: boolean;
}

/**
 * ...
 */
export function GettersConfig($location: ILocationService) {
  'ngInject';

  const getters: GetterTree<RootState, RootState> = {
    /**
     * ...
     */
    isAdmin: (state) => !state.me.institution,
    /**
     * ...
     */
    activeInstId: ({ me }) => (me.institution ? me.institution.id : null),
    /**
     * ...
     */
    activeInstAccountId: ({ me }) =>
      me.institution ? me.institution.accountId : null,
    /**
     * ...
     */
    isDev: () => /localhost|staging|dev/.test($location.absUrl())
  };

  return getters;
}
