import { Controller, Inject } from '@angular';

@Controller
class SettingsView {
  message: string = '';
  submitted: boolean = false;
  user: Object = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
  };
  errors: Object = {
    other: undefined
  };

  @Inject $auth;

  async changePassword(form) {
    this.submitted = true;

    if (!form.$valid) {
      return;
    }

    try {
      await this.$auth.changePassword(
        this.user.oldPassword,
        this.user.newPassword
      );

      this.message = 'Password successfully changed.';
    } catch (err) {
      form.password.$setValidity('mongoose', false);
      this.errors.other = 'Incorrect password';
      this.message = '';
    }
  }
}

export default SettingsView;
