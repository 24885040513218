import { TableView } from '../table-view.service';

export interface AddItemConfiguration {
  buttonLabel: string;
  operation: () => void;
}

export class ItemTable<T> extends TableView<T> {
  addItemConfig: AddItemConfiguration = {
    buttonLabel: 'ADD NEW',
    operation: () => console.log('Add something')
  };
}

export default ItemTable;
