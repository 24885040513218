import angular from 'angular';

import { GetToolJsonService } from './get-tool-json.service';
import { LoadToolService } from './load-tool.service';
import { ToolCanvasService } from './tool-canvas.service';
import { ToolManagerService } from './tool-manager.service';
import { ToolRequirementsParserService } from './tool-requirements-parser.service';
import { ToolUtilsService } from './tool-utils.service';

declare module 'angular' {
  namespace gears {
    type IGetToolJsonService = GetToolJsonService;
    type ILoadToolService = LoadToolService;
    type IToolCanvasService = ToolCanvasService;
    type IToolManagerService = ToolManagerService;
    type IToolRequirementsParserService = ToolRequirementsParserService;
    type IToolUtilsService = ToolUtilsService;
  }
}

export default angular
  .module('app.tools', [])
  .service('getToolJson', GetToolJsonService)
  .service('loadTool', LoadToolService)
  .service('toolCanvas', ToolCanvasService)
  .service('toolManager', ToolManagerService)
  .service('$toolRequirementsParser', ToolRequirementsParserService)
  .service('toolUtils', ToolUtilsService).name;
