import { Component, Ref } from '@angular';

import { Client } from '@interfaces';
import { ClientForm } from '@components/client-form/client-form.component';
import { Modal, ModalProp } from '@mixins/modal.component';

@Component({
  name: 'editClientModal',
  template: require('./edit-client.html')
})
export class EditClientModalController extends Modal {
  @ModalProp readonly client!: Client;

  submitting = false;

  @Ref readonly clientForm!: ClientForm;

  get processing() {
    return this.submitting || this.clientForm.processing;
  }

  get canSubmit() {
    return this.clientForm.isValid;
  }

  async submit() {
    let data: any = null;
    let error: Error | null = null;

    this.submitting = true;

    try {
      data = await this.clientForm.submit();
    } catch (err) {
      error = err as Error;
    }

    this.submitting = false;

    if (error) {
      return this.notify.error(`Failed to update the client: ${error.message}`);
    }

    this.notify.success(`Client updated successfully.`);

    this.$close(data);
  }
}

export default EditClientModalController.$module;
