'use strict';

function typeofFilter() {
  return function(val) {
    return typeof val;
  };
}

export default angular
  .module('app.typeof', [])
  .filter('typeof', typeofFilter).name;
