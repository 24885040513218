import angular from 'angular';
import { sortBy } from 'lodash';

import { State, Getter, Mutation } from 'angular-store';
import { Controller, Inject, Watch, On } from '@/decorators/ngCtrl';

@Controller
class SideNavComponent {
  menuItems: {} = [];
  collapsed: boolean = false;

  @Inject $scope;
  @Inject $rootScope;
  @Inject $cookies;
  @Inject $state;
  @Inject $store;
  @Inject $auth;
  @Inject Notification;
  @Inject $modals;

  @State me;
  @State theme;
  @State sideNavCollapsed;
  @Getter isAdmin;
  @Getter isDev;
  @Mutation('sideNavCollapsed') collapseSideNav;

  @State showIdInActions;

  @Watch('collapsed')
  watchCollapsed(val) {
    this.collapseSideNav(val);
  }

  @On('permissionProfileSet') makeMenuItems;

  get logoImage() {
    return 'assets/images/logo/mhs-logo-all-white-color.svg';
  }

  $onInit() {
    this.collapsed = this.sideNavCollapsed;

    this.makeMenuItems();
  }

  makeMenuItems() {
    var { $state, isAdmin } = this;

    let miHome = {
      label: 'Home',
      icon: 'home',
      get active() {
        return $state.current.name == 'dashboardHome';
      },
      action: () => $state.go('dashboardHome')
    };

    this.subMenuItems = $state
      .get()
      .filter(({ data }) =>
        data?.institutionOnly ? data?.inSideNav && !isAdmin : data?.inSideNav
      )
      .map((o) => ({
        ...o,
        title: o.data.title,
        sideNavMarker: o.data.sideNavMarker
      }));

    let miManagment = {
      label: 'Management',
      icon: 'user',
      get active() {
        return $state.current.name != 'dashboardHome';
      },
      action() {
        this.subMenu.expanded = !this.subMenu.expanded;
      },
      subMenu: {
        expanded: $state.current.name != 'dashboardHome',
        items: sortBy(this.subMenuItems, ['title', 'sideNavMarker'])
      }
    };

    this.menuItems = [miHome, miManagment];
  }

  toggleShowIdInActions() {
    this.$store.commit('SET_SHOW_ID_IN_ACTIONS', !this.showIdInActions);
  }
}

export default angular
  .module('app.sideNav', [])
  .directive('sideNav', () => ({
    restrict: 'E',
    replace: true,
    scope: true,
    template: require('./side-nav.html'),
    controller: SideNavComponent,
    controllerAs: 'vm'
  }))
  .animation('.menu-item-sublist', ($animateCss) => {
    'ngInject';

    return {
      add(el, done) {
        el = $(el).find('>ul');

        return $animateCss(el, {
          event: 'enter',
          structural: true,
          easing: 'cubic-bezier(0, 0.63, 0.07, 1.005)',
          from: { height: 0 },
          to: { height: 50, opacity: 1 },
          duration: 1
        });
      },
      remove(el, done) {
        el = $(el).find('>ul');

        return $animateCss(el, {
          event: 'leave',
          structural: true,
          easing: 'cubic-bezier(0, 0.63, 0.07, 1.005)',
          from: { height: 50, opacity: 1 },
          to: { height: 0, opacity: 0 },
          duration: 1
        });
      }
    };
  }).name;
