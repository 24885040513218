'use strict';

import _ from 'lodash';

function ClientRequestsStore($api, errorHandler, notify) {
  'ngInject';

  return {
    state: {
      loading: false,
      items: [],
      tableProps: {
        sortedCol: 0,
        searchText: ''
      }
    },
    getters: {
      newCount: (state) =>
        state.items.filter((item) => item.status === 0).length
    },
    actions: {
      async getAll({ state, commit }) {
        state.loading = true;

        // let res = await $api.clientRequests.getAll();

        let data = [];

        // let res = await $api.clientRequests.list();
        // data = res.data.data;
        let res = await $api.gearsManager.listClientTransferRequests();
        data = res.data;

        commit('set', data);

        state.loading = false;

        return state.items;
      },
      async getIncomingForInstitution({ rootState, state, commit }, instId) {
        if (isNaN(instId)) {
          instId = rootState.me.institution.id;
        }

        state.loading = true;

        let data = [];

        try {
          let res = await $api.institutionManager.listIncomingClientRequests(
            instId
          );
          data = res.data;
        } catch (err) {
          errorHandler(err);
        }

        commit('add', data);

        state.loading = false;

        return state.items;
      },
      async getOutgoingForInstitution({ rootState, state, commit }, instId) {
        if (isNaN(instId)) {
          instId = rootState.me.institution.id;
        }

        state.loading = true;

        let data = [];

        try {
          let res = await $api.institutionManager.listOutgoingClientRequests(
            instId
          );
          data = res.data;
        } catch (err) {
          errorHandler(err);
        }

        commit('add', data);

        state.loading = false;

        return state.items;
      },
      async approve() {},
      async deny() {},
      async delete({ state }, payload) {
        // TODO (Alex) question for Eric, is this how it will be structured?
        let res = await $api.institutionManager.deleteClientRequest({
          clientRequestId: payload.id,
          instId: payload.institutionId
        });
        if (res.status !== 204 && res.status !== 200) {
          notify.display(res, 'error');
          return;
        }
        _.remove(state.items, { id: payload.id });
      },
      async setRelationalData({ state, rootState, commit }) {
        for (let item of state.items) {
          let props = {
            id: item.id,
            client: rootState.clients.items.find(
              (client) => client.id === item.clientId
            ),
            targetInst: rootState.institutions.items.find(
              (inst) => inst.id === item.targetInstitution
            ),
            previousInst: rootState.institutions.items.find(
              (inst) => inst.id === item.previousInstitution
            )
          };

          commit('updateItem', props);
        }
      }
    },
    mutations: {
      // set(state, payload = []) {
      //   state.items = payload ? [...payload] : [];
      // },
      // add(state, payload) {
      //   let match = state.items.find(item => item.id === payload.id);
      //
      //   if (match) {
      //     Object.keys(match).forEach(key => {
      //       match[key] = payload[key];
      //     });
      //   } else {
      //     state.items.push(payload);
      //   }
      // },
      set(state, payload) {
        state.items = [...payload];
      },
      add(state, payload) {
        state.items = _.uniqBy([...payload, ...state.items], 'id');
      },
      updateItem(state, payload) {
        let match = state.items.findIndex((item) => item.id === payload.id);

        if (match === -1) {
          return;
        }

        state.items[match] = Object.assign(state.items[match], payload);
      },
      delete(state, payload) {
        let type = typeof payload;

        let refIndex = -1;

        if (type == 'number') {
          refIndex = payload;
        } else if (type == 'object') {
          refIndex = state.items.findIndex(
            (item) => item == payload || item.id == payload.id
          );
        }

        if (refIndex < 0) {
          console.warn(
            `[ngStore:clientRequests:mutations:delete] Could not find the item to delete from provided refernce`,
            payload
          );
          return;
        }

        state.items.splice(refIndex, 1);
      },
      CLEAR(state) {
        Object.assign(state, {
          loading: false,
          items: [],
          table: {
            sortedCol: 0,
            searchText: ''
          }
        });
      }
    }
  };
}

export default ClientRequestsStore;
