export type RequireContext = __WebpackModuleApi.RequireContext;

export type KeyFilter = (key: string) => boolean;

/**
 * Generates a list of registered angular module names based on a
 * `RequireContext` result from webpack.
 *
 * @param context The context result.
 * @param filter An optional filter for further restricting certain modules.
 * @returns A list of angular module names.
 */
export function autoImporter(
  context: RequireContext,
  filter: KeyFilter = () => true
) {
  const filteredFiles = context.keys().filter(filter);

  const modules = filteredFiles.map((filepath: string) => {
    const module = context(filepath) as unknown;

    // If the file is not JavaScript or TypeScript, ignore it.
    if (/\.(?:ts|js)$/.test(filepath) === false) return null;

    const name = !isObject(module) ? module : module.default ?? module;

    if (typeof name !== 'string') {
      // console.warn(
      //   `[auto-import] The imported module for the module at "${filepath}" is not valid.\n\n@contextId: ${context.id}`
      // );

      return null;
    }

    return name;
  });

  return modules.filter((item) => item !== null) as string[];
}

export default autoImporter;

//#region Helper Functions

/**
 * Type guard for required module.
 */
function isObject(value: unknown): value is GenericObject {
  return value !== undefined && value !== null;
}

//#endregion Helper Functions
