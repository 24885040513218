import angular from 'angular';
import { remove } from 'lodash';

import { Getter, Mutation, Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class RemoveUserFromInstitutionModalController extends Modal {
  loading = false;
  processing = false;
  institutions: unknown[] = [];
  institutionId: number | null = null;

  @Inject $api;
  @Inject $store;
  @Inject notify;

  @Getter('users/find') findUser;
  @Mutation('users/updateItem') updateUser;
  @Action('users/list') listUsers;
  // @Action('users/remove') removeUser;

  /**
   * Target user's ID.
   */
  get userId() {
    return this.$props.userId;
  }

  /**
   * `true` if the form is submittable, otherwise `false`.
   */
  get submittable() {
    return this.institutionId !== null;
  }

  $setup() {
    this._load();
  }

  /**
   * Will attempt to submit the form and remove the user from the selected
   * institution.
   */
  async submit() {
    let res = null;
    let error = null;

    // NOTE: Didn't use store `user/remove` action becuase it does not return a
    // result that can be used by the calling context to know if it succeded or
    // failed -- something we need to know here in order to determine if the
    // modal should be closed or not.

    this.processing = true;

    try {
      res = await this.$api.IM.removeUser(
        { instId: this.institutionId },
        { userId: this.userId }
      );
    } catch (err) {
      error = err;
    }

    this.processing = false;

    if (error) {
      return this.notify.display(error, 'error');
    }

    if (res.status !== 204 && res.status !== 200) {
      return this.notify.display(res, 'error');
    }

    const institutions = [...this.institutions];
    remove(institutions, { id: this.institutionId });

    // NOTE: Updating the user's institutions in the store here, but not really
    // a fan of doing it this way. Would honestly be better to do this via the
    // store, but I'm a bit squeamish about adding new actions to this hotfix
    // branch.
    this.updateUser({ id: this.userId, institutions });

    this.notify.success('Successfully removed user from institution.');

    this.$close();
  }

  /**
   * Load modal dependencies.
   */
  async _load() {
    this.loading = true;

    // NOTE: Was having issues with this call to load the user, so falling back
    // to grabbing it from the store instead. Change once this call works.

    // const user = await this.$api.GM.getUser({ userId: this.userId });

    const user = this.findUser(this.userId);
    this.institutions = user?.institutions || [];

    this.loading = false;
  }
}

export default angular
  .module('app.removeUserFromInstitutionModal', [])
  .directive('removeUserFromInstitutionModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./remove-user-from-institution.html'),
    controller: RemoveUserFromInstitutionModalController,
    controllerAs: 'vm'
  })).name;
