import { Component, Ref } from '@angular';

import { ClientForm } from '@components/client-form/client-form.component';
import { Modal } from '@mixins/modal.component';

import template from './create-client.html';

@Component({ name: 'createClientModal', template })
export class CreateClientModalController extends Modal {
  submitting = false;

  @Ref readonly clientForm!: ClientForm;

  get processing() {
    return this.submitting || this.clientForm.processing;
  }

  get canSubmit() {
    return this.clientForm.isValid;
  }

  async submit() {
    let data: any = null;
    let error: Error | null = null;

    this.submitting = true;

    try {
      data = await this.clientForm.submit();
    } catch (err) {
      error = err;
    }

    this.submitting = false;

    if (error) {
      return this.notify.error(`Failed to create the client: ${error.message}`);
    }

    this.notify.success(`Client created successfully.`);

    this.$close(data);
  }
}

export default CreateClientModalController.$module;
