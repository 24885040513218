'use strict';

import angular from 'angular';
import { find } from 'lodash';

import { Controller, Inject, Watch, On } from '@/decorators/ngCtrl';
import { State } from 'angular-store';

type Permission = {
  service: string,
  effect: string,
  allActions: boolean,
  actions: {},
  listActions: {}[],
  readActions: {}[],
  writeActions: {}[],
  resources: {}[],
  removeWatcher: Function
};

@Controller
class PolicyEditorComponent {
  policy: {} = {};
  permissions: Permission[] = [];
  // policyName: string = '';
  // institutionId: number;
  // description: string = '';
  originalPolicy: Policy;
  // validation
  validation: {} = {
    policyName: false,
    institutionId: false,
    policyJSON: false
  };

  get policyName() {
    return this.policy?.name;
  }

  set policyName(val) {
    this.policy.name = val;
  }

  get institutionId() {
    return this.policy.institutionId;
  }

  set institutionId(val) {
    this.policy.institutionId = val;
  }

  get description() {
    return this.policy.description;
  }

  set description(val) {
    this.policy.description = val;
  }

  @Inject $scope;
  @Inject $rootScope;

  @State me;
  @State(({ institutions }) => institutions.items) institutions;

  @Watch('policyName')
  onPolicyNameEdited(val) {
    this.validation.policyName = !!val && !!val.length;
  }

  @Watch('institutionId')
  onSelectedInstitutionChanged(val) {
    let id = parseInt(val);
    this.validation.institutionId = typeof id == 'number' && id >= 0;
  }

  get services() {
    return $services;
  }

  $onInit() {
    console.log('$onInit');

    if (this.policy) {
      this.originalPolicy = Object.assign({}, this.policy);
      this.parsePolicy(this.policy);
    } else {
      this.policy = {
        name: '',
        institutionId: null,
        description: '',
        statement: []
      };

      this.add();
    }
  }

  create(config = {}) {
    const $vm = this;

    var prmsn: Permission = {
      effect: config.effect || 'allow',
      allActions: config.allActions || false,
      service: config.service || null,
      actions: {},
      listActions: [],
      readActions: [],
      writeActions: [],
      resources: config.resources || [],
      actionsWatcher: $vm.$scope.$watch(
        () => prmsn.actions,
        (val) => console.log('ACTIONS UPDATED'),
        true
      ),
      removeWatcher: $vm.$scope.$watch(
        () => prmsn,
        (newVal, oldVal) => {
          // if (newVal === oldVal) {
          //   return;
          // }
          //
          // $vm.bakePolicy(newVal, oldVal);

          $vm.bakeStatements();
        },
        true
      ),
      remove() {
        prmsn.actionsWatcher();
        prmsn.removeWatcher();
      }
    };

    prmsn = angular.copy(prmsn);

    this.setService(prmsn.service, prmsn, config.selectedActions);

    return prmsn;
  }

  add(config = {}) {
    let prmsn = this.create(config);
    this.permissions.push(prmsn);

    console.log('NEW PERMISSION ADDED', prmsn);
  }

  copy(prmsn) {
    // Create config from input permission...
    prmsn = Object.assign({ selectedActions: [] }, prmsn);

    // Add selected actions arry based off of
    // selected actions from input permission...
    prmsn.selectedActions = Object.entries(prmsn.actions)
      .filter(([key, val]) => !!val)
      .map(([key, val]) => key);

    // Add new copy
    this.add(prmsn);
  }

  delete(prmsn) {
    let i = this.permissions.indexOf(prmsn);

    if (i < 0) {
      return;
    }

    prmsn.remove();

    this.permissions.splice(i, 1);
  }

  reset() {
    this.policyName = '';
    this.institutionId = null;
    this.permissions = [];

    if (this.originalPolicy) {
      this.parsePolicy(this.originalPolicy);
    } else {
      this.add();
    }
  }

  parsePolicy(policy) {
    const { name, institutionId, statement } = policy;

    this.policyName = name;
    this.institutionId = institutionId || -1;

    let statements = Array.isArray(statement) ? statement : [statement];

    for (let stmnt of statements) {
      let allActions = false;

      if (stmnt.actions == '*') {
        allActions = true;

        this.add({
          // service,
          service: '*',
          effect: stmnt.effect,
          allActions,
          selectedActions: [],
          // Change when resourcee implemented
          resources: []
        });

        continue;
      }

      let serviceActions = (Array.isArray(stmnt.actions)
        ? stmnt.actions
        : [stmnt.actions]
      ).map((item) => {
        let [service, action] = item.split(':');

        return { service, action };
      });

      let services = [];
      serviceActions.forEach(({ service, action }) => {
        let serviceRef = services.find((item) => item.service == service);

        if (!serviceRef) {
          serviceRef = {
            service,
            actions: []
          };

          services.push(serviceRef);
        }

        serviceRef.actions.push(action);
      });

      services.forEach(({ service, actions }) => {
        this.add({
          service,
          effect: stmnt.effect,
          allActions,
          selectedActions: actions,
          // Change when resourcee implemented
          resources: []
        });
      });
    }

    console.log('PARSED PERMISSIONS!', this.permissions);
  }

  setService(service, prmsn, selected = []) {
    let selectedService = find($services, (item) => item.key == service);

    if (!selectedService) {
      prmsn.service = selectedService;
      prmsn.actions = {};
      prmsn.listActions = {};
      prmsn.readActions = {};
      prmsn.writeActions = {};

      return;
    }

    prmsn.actions = {};

    const setActionRefs = (type, actions) => {
      let refs = [];

      Object.values(actions).forEach((action) => {
        let ref = Object.assign({ selected: false }, action);

        if (selected.includes(action.action)) {
          ref.selected = true;
        }

        // Object.defineProperty(prmsn.actions, `${type}:${action.action}`, {
        Object.defineProperty(prmsn.actions, action.action, {
          enumerable: true,
          get: () => ref.selected,
          set: (val) => (ref.selected = val)
        });

        refs.push(ref);
      });

      switch (type) {
        case 'list': {
          prmsn.listActions = refs;
          break;
        }
        case 'read': {
          prmsn.readActions = refs;
          break;
        }
        case 'write': {
          prmsn.writeActions = refs;
          break;
        }
      }
    };

    setActionRefs('list', selectedService.params.list);
    setActionRefs('read', selectedService.params.read);
    setActionRefs('write', selectedService.params.write);
  }

  bakeStatements() {
    var statements = this.permissions.map((item) => {
      let actions = [];

      if (item.allActions) {
        actions = '*';
      } else if (item.service) {
        // actions = Object.values(item.actions)
        //   .filter(action => !!action)
        //   .map(action => `${item.service}:${action.action}`);

        actions = Object.entries(item.actions)
          .filter(([action, selected]) => !!selected)
          .map(([action]) => `${item.service}:${action}`);
      }

      let resources = '*';

      return {
        effect: item.effect,
        actions,
        resources
      };
    });

    this.policy.statement = statements;
  }
}

export default angular
  .module('app.policyEditor', [])
  .directive('policyEditor', () => {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        policy: '='
      },
      bindToController: true,
      template: require('./policy-editor.html'),
      controller: PolicyEditorComponent,
      controllerAs: 'vm'
    };
  }).name;

const GEARS_MANAGER_SERVICE = {
  list: {
    ListInstitutions: {
      action: 'ListInstitutions',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    ListManagedPolicies: {
      action: 'ListManagedPolicies',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    ListUsers: {
      action: 'ListUsers',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    ListClientTransferRequests: {
      action: 'ListClientTransferRequests',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    ListClients: {
      action: 'ListClients',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    ListEvaluations: {
      action: 'ListEvaluations',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    ListEvaluationRequests: {
      action: 'ListEvaluationRequests',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    ListInvites: {
      action: 'ListInvites',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    ListInstitutionTools: {
      action: 'ListInstitutionTools',
      service: 'gearsmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListZones: {
      action: 'ListZones',
      service: 'gearsmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListRegions: {
      action: 'ListRegions',
      service: 'gearsmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListSubGroups: {
      action: 'ListSubGroups',
      service: 'gearsmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListSubGroupClients: {
      action: 'ListSubGroupClients',
      service: 'gearsmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    ListManagedOffenseClassifications: {
      action: 'ListManagedOffenseClassifications',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    }
  },
  read: {
    GetManagedPolicy: {
      action: 'GetManagedPolicy',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    GetUser: {
      action: 'GetUser',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    GetManagedOffenseClassification: {
      action: 'GetManagedOffenseClassification',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    }
  },
  write: {
    CreateManagedOffenseClassification: {
      action: 'CreateManagedOffenseClassification',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    UpdateManagedOffenseClassification: {
      action: 'UpdateManagedOffenseClassification',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    CreateInstitution: {
      action: 'CreateInstitution',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    UpdateUserCap: {
      action: 'UpdateUserCap',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    DeleteInstitution: {
      action: 'DeleteInstitution',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    CreateManagedPolicy: {
      action: 'CreateManagedPolicy',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    UpdateManagedPolicy: {
      action: 'UpdateManagedPolicy',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    CreateUser: {
      action: 'CreateUser',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    UpdateUser: {
      action: 'UpdateUser',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    CreateClient: {
      action: 'CreateClient',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    },
    CreateClientContact: {
      action: 'CreateClientContact',
      service: 'gearsmanager',
      institutionId: '',
      resource: '*'
    }
  }
};

const TOOL_CREATOR_SERVICE = {
  list: {
    ListAllTools: {
      action: 'ListAllTools',
      service: 'toolcreator',
      institutionId: '',
      resource: '*'
    },
    ListToolCommits: {
      action: 'ListToolCommits',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    ListToolPdfs: {
      action: 'ListToolPdfs',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    ListDescriptionPdfs: {
      action: 'ListDescriptionPdfs',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    ListToolCodingForms: {
      action: 'ListToolCodingForms',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    ListToolReportTemplates: {
      action: 'ListToolReportTemplates',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    ListToolReportTemplateCommits: {
      action: 'ListToolReportTemplateCommits',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    ListManageCasePlanTemplates: {
      action: 'ListManageCasePlanTemplates',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    ListInstitutionCasePlanTemplates: {
      action: 'ListInstitutionCasePlanTemplates',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    ListToolReportTemplateMedia: {
      action: 'ListToolReportTemplateMedia',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    }
  },
  read: {
    GetTool: {
      action: 'GetTool',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    GetToolCommit: {
      action: 'GetToolCommit',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    GetDescriptionPdf: {
      action: 'GetDescriptionPdf',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    GetCodingForm: {
      action: 'GetCodingForm',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    GetToolPdf: {
      action: 'GetToolPdf',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    GetToolReportTemplateMedia: {
      action: 'GetToolReportTemplateMedia',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    GetToolReportTemplateCommit: {
      action: 'GetToolReportTemplateCommit',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    }
  },
  write: {
    CreateToolReportTemplate: {
      action: 'CreateToolReportTemplate',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    CommitToolReportTemplate: {
      action: 'CommitToolReportTemplate',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    UploadToolReportTemplateMedia: {
      action: 'UploadToolReportTemplateMedia',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    CommitTool: {
      action: 'CommitTool',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    AddToolPdf: {
      action: 'AddToolPdf',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    CreateToolPdf: {
      action: 'CreateToolPdf',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    AddDescriptionPdf: {
      action: 'AddDescriptionPdf',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    CreateDescriptionPdf: {
      action: 'CreateDescriptionPdf',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    AddCodingForm: {
      action: 'AddCodingForm',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    CreateCodingForm: {
      action: 'CreateCodingForm',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    SetLiveCodingForm: {
      action: 'SetLiveCodingForm',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    SetLiveDescriptionPdf: {
      action: 'SetLiveDescriptionPdf',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    SetLiveToolPdf: {
      action: 'SetLiveToolPdf',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    TagToolCommit: {
      action: 'TagToolCommit',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    SetToolCommitStatus: {
      action: 'SetToolCommitStatus',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    CreateTool: {
      action: 'CreateTool',
      service: 'toolcreator',
      institutionId: '',
      resource: '*'
    },
    AddChildTool: {
      action: 'AddChildTool',
      service: 'toolcreator',
      institutionId: '',
      resource: '*'
    },
    RemoveChildTool: {
      action: 'RemoveChildTool',
      service: 'toolcreator',
      institutionId: '',
      resource: '*'
    },
    ChangeToolPrice: {
      action: 'ChangeToolPrice',
      service: 'toolcreator',
      institutionId: '',
      resource: [
        {
          name: 'tool',
          value: 'toolId'
        }
      ]
    },
    DeleteTool: {
      action: 'DeleteTool',
      service: 'toolcreator',
      institutionId: '',
      resource: '*'
    }
  }
};

const CLIENT_MANAGER_SERVICE = {
  list: {
    ListClientCasePlans: {
      action: 'ListClientCasePlans',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    ListClientEvaluations: {
      action: 'ListClientEvaluations',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    ListInstitutionTools: {
      action: 'ListTools',
      service: 'institutionManager',
      institutionId: 'institutionId'
    },
    ListClientEvaluators: {
      action: 'ListClientEvaluators',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    ListClientContacts: {
      action: 'ListClientContacts',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    ListClientReports: {
      action: 'ListClientReports',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    ListOffenderHistory: {
      action: 'ListOffenderHistory',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    ListEvaluationReports: {
      action: 'ListEvaluationReports',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    }
  },
  read: {
    GetClientCasePlan: {
      action: 'GetClientCasePlan',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    GetClientEvaluation: {
      action: 'GetClientEvaluation',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    GetOffenderHistory: {
      action: 'GetOffenderHistory',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    GetEvaluationMedia: {
      action: 'GetEvaluationMedia',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    GetReportDocument: {
      action: 'GetReportDocument',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    GetReportPdf: {
      action: 'GetReportPdf',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    }
  },
  write: {
    CreateEvaluationReport: {
      action: 'CreateEvaluationReport',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    EmailReport: {
      action: 'EmailReport',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    UploadEvaluationMedia: {
      action: 'UploadEvaluationMedia',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    DeleteEvaluationMedia: {
      action: 'DeleteEvaluationMedia',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    CreateOffenderHistory: {
      action: 'CreateOffenderHistory',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    UpdateOffenderHistory: {
      action: 'UpdateOffenderHistory',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    DeleteOffenderHistory: {
      action: 'DeleteOffenderHistory',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    CreateClientEvaluation: {
      action: 'CreateClientEvaluation',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    SaveClientEvaluation: {
      action: 'SaveClientEvaluation',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    SubmitClientEvaluation: {
      action: 'SubmitClientEvaluation',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    RevertClientEvaluation: {
      action: 'RevertClientEvaluation',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    DeleteClientEvaluation: {
      action: 'DeleteClientEvaluation',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    CreateClientCasePlan: {
      action: 'CreateClientCasePlan',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    UpdateClientCasePlanStatus: {
      action: 'UpdateClientCasePlanStatus',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    },
    UpdateClientCasePlan: {
      action: 'UpdateClientCasePlan',
      service: 'clientmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    }
  }
};

const INSTITUTION_CASE_PLAN_TEMPLATE_MANAGER = {
  list: {
    ListCasePlanTemplates: {
      action: 'ListCasePlanTemplates',
      service: 'icpt',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListCasePlanTemplateCommits: {
      action: 'ListCasePlanTemplateCommits',
      service: 'icpt',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'template',
          value: 'templateId'
        }
      ]
    }
  },
  read: {
    GetCasePlanTemplateCommit: {
      action: 'GetCasePlanTemplateCommit',
      service: 'icpt',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'template',
          value: 'templateId'
        }
      ]
    }
  },
  write: {
    CreateCasePlanTemplate: {
      action: 'CreateCasePlanTemplate',
      service: 'icpt',
      institutionId: 'institutionId',
      resource: '*'
    },
    CommitCasePlanTemplate: {
      action: 'CommitCasePlanTemplate',
      service: 'icpt',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'template',
          value: 'templateId'
        }
      ]
    },
    TagCasePlanTemplateCommit: {
      action: 'TagCasePlanTemplateCommit',
      service: 'icpt',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'template',
          value: 'templateId'
        }
      ]
    },
    SetCasePlanTemplateCommitStatus: {
      action: 'SetCasePlanTemplateCommitStatus',
      service: 'icpt',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'template',
          value: 'templateId'
        }
      ]
    }
  }
};

const INSTITUTION_MANAGER_SERVICE = {
  list: {
    ListInvites: {
      action: 'ListInvites',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListClients: {
      action: 'ListClients',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListIncomingClientRequests: {
      action: 'ListIncomingClientRequests',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListOutgoingClientRequests: {
      action: 'ListOutgoingClientRequests',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListEvaluationRequests: {
      action: 'ListEvaluationRequests',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListInstitutionUsers: {
      action: 'ListInstitutionUsers',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListPolicies: {
      action: 'ListPolicies',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListInstitutionTools: {
      action: 'ListInstitutionTools',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListOffenseClassifications: {
      action: 'ListOffenseClassifications',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    }
  },
  read: {
    GetInstitution: {
      action: 'GetInstitution',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    GetOffenseClassification: {
      action: 'GetOffenseClassification',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    }
  },
  write: {
    CreateOffenseClassification: {
      action: 'CreateOffenseClassification',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    CreateClientTransferRequest: {
      action: 'CreateClientTransferRequest',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    InviteUser: {
      action: 'InviteUser',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ResendInvite: {
      action: 'ResendInvite',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ImportClients: {
      action: 'ImportClients',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    UpdateInstitution: {
      action: 'UpdateInstitution',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    AddUser: {
      action: 'AddUser',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    RemoveUser: {
      action: 'RemoveUser',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    CreatePolicy: {
      action: 'CreatePolicy',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    AssignPolicyToUser: {
      action: 'AssignPolicyToUser',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    RemovePolicyFromUser: {
      action: 'RemovePolicyFromUser',
      service: 'ipm',
      institutionId: 'id',
      resource: '*'
    },
    CreateClient: {
      action: 'CreateClient',
      service: 'institutionmanager',
      institutionId: '',
      resource: '*'
    },
    CreateClientContact: {
      action: 'CreateClientContact',
      service: 'institutionmanager',
      institutionId: '',
      resource: '*'
    },
    DeleteClient: {
      action: 'DeleteClient',
      service: 'institutionmanager',
      institutionId: '',
      resource: '*'
    }
  }
};

const LOCATION_MANAGER_SERVICE = {
  list: {
    ListZones: {
      action: 'ListZones',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListRegions: {
      action: 'ListRegions',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListSubGroups: {
      action: 'ListSubGroups',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    ListSubGroupClients: {
      action: 'ListSubGroupClients',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    }
  },
  read: {},
  write: {
    CreateZone: {
      action: 'CreateZone',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    CreateRegion: {
      action: 'CreateRegion',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    CreateSubGroup: {
      action: 'CreateSubGroup',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    UpdateZone: {
      action: 'UpdateZone',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    UpdateRegion: {
      action: 'UpdateRegion',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    UpdateSubGroup: {
      action: 'UpdateSubGroup',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: '*'
    },
    AddClientToSubGroup: {
      action: 'AddClientToSubGroup',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        },
        {
          name: 'clientId',
          value: 'clientId'
        }
      ]
    },
    RemoveClientFromSubGroup: {
      action: 'RemoveClientFromSubGroup',
      service: 'institutionmanager',
      institutionId: 'institutionId',
      resource: [
        {
          name: 'subgroup',
          value: 'subGroupId'
        }
      ]
    }
  }
};

var $services = {
  gearsmanager: {
    name: 'Gears Manager',
    key: 'gearsmanager',
    params: GEARS_MANAGER_SERVICE
  },
  institutionmanager: {
    name: 'Institution Manager',
    key: 'institutionmanager',
    params: INSTITUTION_MANAGER_SERVICE
  },
  clientmanager: {
    name: 'Client Manager',
    key: 'clientmanager',
    params: CLIENT_MANAGER_SERVICE
  },
  // locationmanager: {
  //   name: 'Location Manager',
  //   key: 'locationmanager',
  //   params: LOCATION_MANAGER_SERVICE
  // },
  icpt: {
    name: 'Institution Case Plan Template Manager (ICPT)',
    key: 'icpt',
    params: INSTITUTION_CASE_PLAN_TEMPLATE_MANAGER
  },
  toolcreator: {
    name: 'Tool Creator',
    key: 'toolcreator',
    params: TOOL_CREATOR_SERVICE
  }
};
