import angular from 'angular';

declare module 'angular' {
  namespace gears {
    type IHttpRequestInterceptorService = HttpRequestInterceptorService;
  }
}

/**
 * Duration (in milliseconds) to wait before automatically logging a user out
 * due to idling. Approximately 13 1/2 minutes.
 */
const TIMEOUT_DURATION = 810000;

//#region API Origin Handler

// NOTE: The following is a manual implementation of a request interceptor that
// ensures all API requests made from the site are pointed to the proper domain,
// as dictated by the `process.env.API_ORIGIN` environment variable. It was done
// in this manner (instead of being implemented in the following
// `HttpRequestInterceptorService` class) because some parts of the site
// make API using method's other than Angular's `$http` service. By overriding
// `XMLHttpRequest.open`, we can ensure ALL requests will have the same logic
// applied. This is NOT ideal, and should be better handled in the future.
//
// - Dane

const API_ORIGIN = new URL(process.env.API_ORIGIN);

const openRequest = XMLHttpRequest.prototype.open;

XMLHttpRequest.prototype.open = function (this: XMLHttpRequest, ...args) {
  const url = new URL(args[1], window.location.origin);

  if (url.origin === window.location.origin && /^\/api\/?/.test(url.pathname)) {
    url.host = API_ORIGIN.host;
  }

  args[1] = url;

  openRequest.call(this, ...args);
} as typeof openRequest;

//#endregion API Origin Handler

export class HttpRequestInterceptorService {
  private timeoutId: number | null = null;

  constructor(private readonly $injector: angular.auto.IInjectorService) {
    'ngInject';
  }

  /**
   * Resets time for another 13 1/2 minutes idle session.
   *
   * @param clear ...
   */
  resetTimer(clear: boolean) {
    if (typeof this.timeoutId === 'number') {
      clearTimeout(this.timeoutId);
    }

    this.timeoutId = clear
      ? null
      : window.setTimeout(this.logoutWarning, TIMEOUT_DURATION);
  }

  /**
   * Check is user is logged in and give them the logout warning.
   */
  logoutWarning() {
    const $auth: angular.gears.IAuthService = this.$injector.get('$auth');
    const $modals = this.$injector.get('$modals');

    if ($auth.loggedIn) {
      this.$modals.settings.logoutWarning(this)(() => {});
    } else {
      this.resetTimer(true);
    }
  }

  // /**
  //  * Add the appropriate origin to relative API requests.
  //  */
  // request(config: { url: string }) {
  //   return config;
  // }

  /**
   * ...
   */
  response(config: unknown) {
    // console.log(config);

    // do the token resetting on API calls
    // if (config.config.url.charAt(0) === '/') {
    //   vm.AuthService = $injector.get('$auth');
    //   vm.$modals = $injector.get('$modals');
    //   vm.resetTimer();
    // }

    return config;
  }

  /**
   * ...
   */
  responseError(rejection: unknown) {
    // console.log('rejected');
    // console.log(rejection);
    // return $q.reject(rejection);
  }
}

export default angular
  .module('app.httpRequestInterceptor', [])
  .service('httpRequestInterceptor', HttpRequestInterceptorService).name;
