'use strict';

function ReportTemplatesStore($api) {
  'ngInject';

  return {
    state: {
      loading: false,
      error: false,
      items: [],
      tableProps: {
        sortedCol: 0,
        searchText: ''
      }
    },
    getters: {},
    actions: {
      async list({ state, commit }) {
        state.loading = true;

        let data = [];

        try {
          let res = await $api.reportTemplates.list();
          data = res.data.data;

          commit('setError', false);
        } catch (err) {
          commit('setError', true);

          throw err;
        }

        commit('set', data);

        state.loading = false;

        return state.items;
      },
      async get({ state, commit }, id) {
        state.loading = true;

        let data = [];

        try {
          let res = await $api.reportTemplates.get(id);
          data = res.data.data;

          commit('setError', false);
        } catch (err) {
          commit('setError', true);

          throw err;
        }

        commit('set', data);

        state.loading = false;

        return state.items;
      },
      async create({ state, commit }, payload) {
        state.loading = true;

        let data = [];

        try {
          let res = await $api.reportTemplates.create(payload);
          data = res.data.data;

          commit('setError', false);
        } catch (err) {
          commit('setError', true);

          throw err;
        }

        commit('set', data);

        state.loading = false;

        return state.items;
      }
    },
    mutations: {
      set(state, payload) {
        state.items = [...payload];
      },
      add(state, payload) {
        state.items = _.uniqBy([...payload, ...state.items], 'id');
      },
      setError(state, hasError) {
        state.error = hasError;
      },
      update(state, data = []) {},
      CLEAR(state) {
        Object.assign(state, {
          loading: false,
          error: false,
          items: [],
          tableProps: {
            sortedCol: 0,
            searchText: ''
          }
        });
      }
    }
  };
}

export default ReportTemplatesStore;
