'use strict';

import angular from 'angular';

import * as _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal, { Submit } from '@/mixins/modal.mixin';

function HasDescription(answer) {
  return typeof answer.description === 'string' && answer.description.length;
}

function findTool(toolId, tool) {
  if (tool.id == toolId) return tool;
  let foundTool;
  let searchCT = function (tool) {
    _.forEach(tool.childTools, (ct) => {
      if (ct.id == toolId) foundTool = ct;
      if (foundTool) return;
      if (ct.childTools.length) searchCT(ct);
    });
  };
  searchCT(tool);
  return foundTool;
}

@Controller
class ReportModalController extends Modal {
  @Inject $scope;
  @Inject $api;
  @Inject $filter;
  @Inject $store;
  // @Inject Notification;
  @Inject notify;
  @Inject $http;
  @Inject Upload;

  get evl() {
    return this.$props;
  }

  async $setup() {
    var bracketRegExp = /\[(.*?]*)\]/g;
    this.itemNotes = true;
    this.useAlias = false;
    let client = _.find(this.$store.state.clients.items, {
      id: this.evl.evaluation.clientId.toString()
    });
    if (!client) {
      console.error('client not found');
      this.$close();
    }
    if (!client.alias || !client.alias.length) this.hideAliasOption = true;
    // console.log('this.$props: ', this.$props);
    // console.log('EVALUATION AT BEGINNING');
    this.codingFormItems = this.evl.tool ? this.evl.tool.codingFormItems : null;

    //check that at least one question was answered
    if (this.evl.evaluation.data) {
      this.atLeastOneScoreFound = false;
      for (let key in this.evl.evaluation.data) {
        this.evl.evaluation.data[key].aid
          ? (this.atLeastOneScoreFound = true)
          : null;
      }
      if (!this.atLeastOneScoreFound) {
        this.notify.display(
          'error',
          `No Evaluation Answers Found. Please contact ${process.env.SUPPORT_EMAIL} for assistance.`,
          true
        );
        this.$close('noEvalDataFound');
      }
    } else {
      this.notify.display(
        'error',
        `No Evaluation Answers Found. Please contact ${process.env.SUPPORT_EMAIL} for assistance.`,
        true
      );
      this.$close('noEvalDataFound');
    }

    let i;
    // build scores dictionary to send with report
    this.scores = {};
    for (let key in this.evl.evaluation.data) {
      if (this.evl.evaluation.data[key].score === '-') {
        this.scores[key] = 'NOT ANSWERED';
      } else {
        this.scores[key] =
          typeof this.evl.evaluation.data[key].score === 'number'
            ? `${this.evl.evaluation.data[key].score}`
            : this.evl.evaluation.data[key].text;
      }
    }

    if (!this.evl.report.institutionId && this.evl.evaluation.institutionId) {
      this.evl.report.institutionId = this.evl.evaluation.institutionId;
    } else if (
      this.evl.report.institutionName === '' &&
      this.evl.evaluation.institutionName != ''
    ) {
      this.evl.report.institutionName = this.evl.evaluation.institutionName;
    }
    // create variableMap for replacing bracket variables in text strings
    // console.log('this.evl.report: ', this.evl.report);
    this.clientName = this.evl.report.clientName;
    this.clientLastName = this.clientName.split(',');
    // get first name
    this.clientFirstName = this.clientLastName[1];
    if (this.clientFirstName[0] === ' ') {
      this.clientFirstName = this.clientFirstName.substring(
        1,
        this.clientFirstName.length
      );
    }
    if (this.clientFirstName.indexOf(' ') > -1) {
      this.clientFirstName = this.clientFirstName.split(' ');
    }
    this.clientFirstName = this.clientFirstName[0];
    this.clientFirstLastName = angular.copy(this.clientLastName);
    // build client name first middle last
    this.clientFirstLastName[1] = this.clientFirstLastName[1].substring(
      1,
      this.clientFirstLastName[1].length
    ); // remove space before first name
    this.clientFirstLastName =
      this.clientFirstLastName[1] + ' ' + this.clientFirstLastName[0];
    // get client last name
    this.clientLastName = this.clientLastName[0];

    let clientHonorific;
    if (client?.sex === 'male') {
      clientHonorific = 'Mr.';
    } else if (client?.sex === 'female') {
      clientHonorific = 'Ms.';
    }

    var variableMap = {
      // '[client]': this.clientFirstName + ' ' + this.clientLastName,
      '[risk-category]': this.evl.evaluation.riskCategory,
      '[score-total]': this.evl.evaluation.score,
      '[client-honorific]': clientHonorific
    };

    // for STABLE tool. Variable for [stable-risk-area-count]
    if (this.evl.evaluation && this.evl.evaluation.toolUsed === 100) {
      let emotionalIDWithChildrenOmitted;
      // question id is Q-S1yajsLEG. answer for omit is A-yqee0Xcq6
      if (this.evl.evaluation.data && this.evl.evaluation.data['Q-S1yajsLEG']) {
        if (this.evl.evaluation.data['Q-S1yajsLEG'].aid === 'A-yqee0Xcq6')
          emotionalIDWithChildrenOmitted = true;
      }
      variableMap['[stable-risk-area-count]'] = emotionalIDWithChildrenOmitted
        ? '12'
        : '13';
    }

    // for ACUTE tool. Variable for [answer-sum...] and [sub-risk-category...]. Need to make more dynamic when report generation is refactored
    if (this.evl.evaluation && this.evl.evaluation.toolUsed === 101) {
      let acuteSubQuestionSum = 0;
      let questionsArray = [
        'Q-rJTKcSq4G',
        'Q-Hy1ICBq4M',
        'Q-Sye8RHq4M',
        'Q-B1_8Rr5Nf'
      ];
      _.forEach(this.evl.evaluation.data, (val, key) => {
        if (!questionsArray.includes(key)) return;
        if (typeof val.score !== 'number') return;
        acuteSubQuestionSum += val.score;
      });
      variableMap[
        '[answer-sum-Q-rJTKcSq4G-Q-Hy1ICBq4M-Q-Sye8RHq4M-Q-B1_8Rr5Nf]'
      ] = acuteSubQuestionSum;
      let subRiskCategory;
      _.forEach(this.evl.tool.subRiskCategories, (category) => {
        _.forEach(category.categories, (cat) => {
          if (
            acuteSubQuestionSum === cat.low ||
            acuteSubQuestionSum === cat.high
          ) {
            subRiskCategory = cat.name;
          } else if (
            acuteSubQuestionSum > cat.low &&
            acuteSubQuestionSum < cat.high
          ) {
            subRiskCategory = cat.name;
          }
        });
      });
      if (!subRiskCategory) subRiskCategory = 'NO SUB RISK CATEGORY FOUND';
      variableMap['[sub-risk-category-0]'] = subRiskCategory;
    }

    // for TRAP-18 tool. [list-risk-factors-answer-{toolId}>{answerText}]
    if (this.evl.evaluation && this.evl.evaluation.toolUsed === 148) {
      let presentFor149 = [];
      let absentFor149 = [];
      let presentFor150 = [];
      let absentFor150 = [];
      _.forEach(this.evl.tool.childTools, (ct) => {
        // parse Case Formulation for [answer-input-{answerId}] variables
        if (ct.id === 151) {
          _.forEach(ct.codingFormItems, (cfi) => {
            let dataAnswerId = `${ct.id}>${cfi.id}`;
            if (this.evl.evaluation.data[dataAnswerId]?.fillIn) {
              variableMap[`[answer-input-${cfi.id}]`] = this.evl.evaluation
                .data[dataAnswerId]?.fillIn?.length
                ? this.evl.evaluation.data[dataAnswerId]?.fillIn
                : 'QUESTION UNANSWERED';
            } else {
              variableMap[`[answer-input-${cfi.id}]`] = 'QUESTION UNANSWERED';
            }
          });
        } else {
          _.forEach(ct.codingFormItems, (cfi) => {
            let dataAnswerId = `${ct.id}>${cfi.id}`;
            if (
              this.evl.evaluation.data[dataAnswerId]?.text === 'Present' &&
              ct.id === 149
            )
              presentFor149.push(cfi.riskFactor);
            if (
              this.evl.evaluation.data[dataAnswerId]?.text === 'Absent' &&
              ct.id === 149
            )
              absentFor149.push(cfi.riskFactor);
            if (
              this.evl.evaluation.data[dataAnswerId]?.text === 'Present' &&
              ct.id === 150
            )
              presentFor150.push(cfi.riskFactor);
            if (
              this.evl.evaluation.data[dataAnswerId]?.text === 'Absent' &&
              ct.id === 150
            )
              absentFor150.push(cfi.riskFactor);
          });
        }
      });
      _.forEach(this.evl.evaluation.data, (val, key) => {
        if (key.includes('149>') && val.text === 'Present')
          presentFor149.push();
      });

      let parseArrayToStringList = function (arr) {
        if (!arr.length) return 'None';
        if (arr.length === 1) return arr[0];
        if (arr.length === 2) return `${arr[0]} and ${arr[1]}`;
        let str = '';
        _.forEach(arr, (val, index) => {
          if (index === arr.length - 1) {
            str = str + 'and ' + val;
          } else {
            str = str + val + ', ';
          }
        });
        return str;
      };

      presentFor149 = parseArrayToStringList(presentFor149);
      absentFor149 = parseArrayToStringList(absentFor149);
      presentFor150 = parseArrayToStringList(presentFor150);
      absentFor150 = parseArrayToStringList(absentFor150);

      variableMap['[list-risk-factors-answer-149>Present]'] = presentFor149;
      variableMap['[list-risk-factors-answer-149>Absent]'] = absentFor149;
      variableMap['[list-risk-factors-answer-150>Present]'] = presentFor150;
      variableMap['[list-risk-factors-answer-150>Absent]'] = absentFor150;
    }

    // set original total possible score
    if (this.evl.tool.riskCategories?.length) {
      variableMap['[total-possible-score]'] = this.evl.tool
        ? _.maxBy(this.evl.tool.riskCategories, 'high').high
        : 0;
    }

    // create variable map keys for [list-risk-factors-score-X] & [count-items-score-X]
    let minScore = 0;
    let maxScore = 0;
    _.forEach(this.codingFormItems, (cfi) => {
      if (_.minBy(cfi.codesAndScore, 'score').score < minScore) {
        minScore = _.minBy(cfi.codesAndScore, 'score').score;
      }

      if (_.maxBy(cfi.codesAndScore, 'score').score > maxScore) {
        maxScore = _.maxBy(cfi.codesAndScore, 'score').score;
      }
    });

    let iterations =
      minScore >= 0
        ? maxScore - minScore + 1
        : maxScore + Math.abs(minScore) + 1;
    for (let i = minScore; i < iterations; i++) {
      variableMap[`[list-risk-factors-score-${i}]`] = 'None';
      variableMap[`[count-items-score-${i}]`] = 0;
    }

    let answerKey;
    for (let key in this.evl.evaluation.data) {
      answerKey = `[answer-${key}]`;
      let score = this.evl.evaluation.data[key].score;
      let text = this.evl.evaluation.data[key].text;
      let riskFactor = this.getRiskFactor(this.evl.evaluation.data[key].qid);

      let omitFactor = this.checkOmitFactor(this.evl.evaluation.data[key]);
      if (omitFactor) variableMap['[total-possible-score]'] += omitFactor;

      variableMap[answerKey] = score + '';

      // [list-risk-factors-score-X] & [count-items-score-X]
      // if list-risk-factors-score-X exists, we can assume count-items-score-X does, too
      if (score !== '-' && text !== 'Omit') {
        // must have been scored
        if (variableMap[`[list-risk-factors-score-${score}]`] === 'None') {
          variableMap[`[list-risk-factors-score-${score}]`] = riskFactor;
        } else {
          variableMap[`[list-risk-factors-score-${score}]`] =
            variableMap[`[list-risk-factors-score-${score}]`] +
            ', ' +
            riskFactor;
        }
        variableMap[`[count-items-score-${score}]`]++;
      }
    }

    // add ", and" for the last item in the list
    _.forEach(variableMap, (val, key) => {
      if (key.includes('list-risk-factors-score') && val.includes(',')) {
        let commaIndex = val.lastIndexOf(',') + 1;
        variableMap[key] =
          val.substring(0, commaIndex) + ' and' + val.substring(commaIndex);
      }
    });

    if (this.evl.dictionary) {
      for (i = 0; i < this.evl.dictionary.length; i++) {
        answerKey =
          '[' +
          this.evl.dictionary[i].id +
          ':' +
          this.evl.dictionary[i].term +
          ']';
        variableMap[answerKey] = this.evl.dictionary[i].term + '';
      }
    }
    // console.log('variableMap', variableMap);
    // create variableMap regex for replacing
    this.variableRegEx = Object.keys(variableMap).join('|');
    // console.log(this.variableRegEx);
    this.variableRegEx = this.variableRegEx.replace(/\[/g, '\\[');
    this.variableRegEx = this.variableRegEx.replace(/]/g, '\\]');
    this.variableRegEx = this.variableRegEx.replace(/\)/g, '\\)');
    this.variableRegEx = this.variableRegEx.replace(/\(/g, '\\(');
    this.variableRegEx = this.variableRegEx.replace(/#/g, '\\#');
    this.variableRegEx = this.variableRegEx.replace(/\+/g, '\\+');
    // console.log(this.variableRegEx);
    this.variableRegEx = new RegExp(this.variableRegEx, 'gi');
    // console.log(this.variableRegEx);

    this.replacer = function (match) {
      return variableMap[match];
    };

    // if wizard answers were given from app, parse into site's format

    // check length of this.evl.reportTemplates
    // console.log(this.evl.reportTemplates);
    this.reportSubmission = this.evl.report;
    if (this.evl.reportTemplates.length > 1) {
      this.chooseTemplate = true;
    } else {
      this.chooseTemplate = false;
      this.evl.reportTemplate = this.evl.reportTemplates[0].template;
      this.evl.reportTemplateId = this.evl.reportTemplates[0].templateId;
      this.template = this.evl.reportTemplate;
      Reflect.deleteProperty(this.template.reportCriteria, 'display'); // remove display fields since they aren't needed here
      this.parseTemplate();
    }

    if (!this.evl.report.toolName || this.evl.report.toolName === '') {
      let toolRes = await this.$api.toolCreator.getTool({
        toolId: this.evl?.evaluation?.toolUsed
      });
      if (toolRes.status === 200) {
        this.evl.report.toolName = toolRes.data.name;
        this.reportSubmission.toolName = toolRes.data.name;
      } else {
        this.notify.display('Could not retrieve name of tool.', 'warning');
      }
    }
  }

  // build variable map for replacing keys
  getRiskFactor(id) {
    if (
      this.codingFormItems &&
      _.find(this.codingFormItems, {
        id
      })
    ) {
      return _.find(this.codingFormItems, {
        id
      }).riskFactor;
    }
    return null;
  }

  checkOmitFactor(answer) {
    let codeAndScore = _.find(this.codingFormItems, {
      id: answer.qid
    });
    if (codeAndScore)
      codeAndScore = _.find(codeAndScore.codesAndScore, {
        id: answer.aid
      });
    if (codeAndScore) return codeAndScore.omit;
    return null;
  }

  parseWizardAnswersFromApp(wizard, callback) {
    let bc = wizard.breadcrumb;
    let answers = {};
    let answerText = [];
    _.forEach(bc, (q) => {
      answerText = [];
      _.forEach(q.chosenAnswer, (ca) => {
        answerText.push(ca.text);
      });
      answers[q.question.id] = {
        answerText,
        operator: '',
        selectedAnswer: ''
      };
    });
    wizard.waid = wizard.id;
    wizard.wqid = bc[bc.length - 1].question.id;
    wizard.answers = answers;
    callback(wizard);
  }

  buildTable(content, notesTable) {
    // table type. generate rowData
    if (typeof content.table?.rowData === 'string') {
      // rowData is a key that we have to parse and fill out with an array to send to backend
      // helper function to create an answer row
      let buildAnswerRow = (cfi, headerRow, bold = false) => {
        let row = [];
        let answer = _.find(this.evl.evaluation.data, (val, key) => {
          return key.includes(cfi.id);
        });
        if (!answer) {
          console.error(
            `In Table Generation: ${cfi.riskFactor} - ${cfi.id} : corresponding answer not found!`
          );
          return;
        }
        _.forEach(content.table.headerTableData, (val, index) => {
          if (index === 0) {
            // assuming first column is question text
            if (headerRow) {
              row.push({
                val: cfi.riskFactor,
                opts: {
                  cellColWidth: 2300,
                  b: bold,
                  sz: '20'
                }
              });
            } else {
              row.push(cfi.riskFactor);
            }
          } else {
            // place an X if the answer matches
            if (headerRow) {
              row.push({
                val: val.val === answer.text ? 'X' : '',
                opts: {
                  cellColWidth: 2300,
                  b: bold,
                  sz: '20'
                }
              });
            } else {
              row.push(val.val === answer.text ? 'X' : '');
            }
          }
        });
        return row;
      };
      if (
        content.table.rowData.includes('answer-column-placement-') &&
        !notesTable
      ) {
        // columns are already created, we just need to parse answers from the given tool
        // to fill in the answer rows
        let includeNotes;
        let toolId = content.table.rowData.substring(
          'answer-column-placement-'.length,
          content.table.rowData.length
        );
        // 1. assume first column is question text
        // 2. find answer and place in array position corresponding to content.table.headerTableData
        let tool = findTool(toolId, this.evl.tool);
        if (!tool) {
          console.error(
            `In Table Generation: ${content.table.rowData} : corresponding tool not found!`
          );
          return;
        }
        let rowData = [];
        _.forEach(tool.codingFormItems, (cfi) => {
          rowData.push(buildAnswerRow(cfi));
        });
        content.table.rowData = rowData;
        return content;
      } else if (
        content.table.rowData.includes('include-notes') &&
        notesTable
      ) {
        let tableNotesContents = [];
        let toolId = content.table.rowData.substring(
          'answer-column-placement-include-notes-'.length,
          content.table.rowData.length
        );
        let tool = findTool(toolId, this.evl.tool);
        _.forEach(tool.codingFormItems, (cfi, index) => {
          if (index === 0) {
            content.table.rowData = [buildAnswerRow(cfi)];
            tableNotesContents.push(content);
          } else {
            tableNotesContents.push({
              text: '',
              type: 3,
              image: '',
              table: {
                tableStyle: {
                  tableColWidth: 2300,
                  tableSize: 24,
                  sz: 20,
                  tableColor: '000000',
                  tableAlign: 'center',
                  tableFontFamily: 'Calibri',
                  borders: true
                },
                headerTableData: buildAnswerRow(cfi, true),
                rowData: []
              }
            });
          }
          let note = _.find(this.codingFormItemNotes, (cfin) => {
            return (
              cfin.questionId === cfi.id || cfin.questionId.includes(cfi.id)
            );
          });
          if (note) note = note.notes;
          if (!note) note = '';
          tableNotesContents.push({
            text: `Notes: ${note}`,
            type: 0,
            image: ''
          });
        });
        return tableNotesContents;
      }
    }
    return content;
  }

  parseTemplate() {
    this.template = this.evl.reportTemplate;
    // console.log('BEGINNING OF PARSE TEMPLATE');
    // console.log('this.template: ', this.template);
    this.reportSelections = {
      generalNotes: {
        selected: true
      },
      itemNotes: {
        selected: true
      },
      codingForm: {
        selected: true
      },
      codingFormItemNotes: {
        selected: true
      }
    };

    var i, j, k, l, m, n, key, cle, llave;
    for (key in this.template.reportCriteria) {
      // console.log(`key: ${key}`);
      // console.log('reportCriteria');
      // console.log(this.template.reportCriteria);
      if (key === 'additionalCriteria') {
        this.reportSelections[key] = [];
        for (j = 0; j < this.template.reportCriteria[key].length; j++) {
          // console.log(
          //   'this.template.reportCriteria[key][j]: ',
          //   this.template.reportCriteria[key][j]
          // );
          this.reportSelections[key].push({
            hasToggle: this.template.reportCriteria[key][j].hasToggle,
            toggleDefault: this.template.reportCriteria[key][j].toggleDefault,
            title: this.template.reportCriteria[key][j].title,
            selected: this.template.reportCriteria[key][j].toggleDefault,
            sectionType: this.template.reportCriteria[key][j].sectionType,
            options: []
          });
          // console.log(angular.copy(this.template.reportCriteria[key][j]));
          if (
            parseInt(this.template.reportCriteria[key][j].sectionType, 10) === 1
          ) {
            // if criteria section is conditional based on evaluation score
            // console.log(this.template.reportCriteria[key][j]);
            // console.log('CONDITIONAL BASED ON EVAL SCORE');
            for (
              i = 0;
              i < this.template.reportCriteria[key][j].options.length;
              i++
            ) {
              if (
                this.template.reportCriteria[key][j].options[i].hasOwnProperty(
                  'scoreCondition'
                ) &&
                typeof this.template.reportCriteria[key][j].options[i]
                  .scoreCondition === 'number'
              ) {
                if (
                  this.evl.evaluation.score ===
                  this.template.reportCriteria[key][j].options[i].scoreCondition
                ) {
                  // if score condition is met
                  this.reportSelections[key][j].options.push({
                    isDefault:
                      this.template.reportCriteria[key][j].options[i].isDefault,
                    text: this.template.reportCriteria[key][j].options[i].text,
                    selected: true
                  });
                }
              } else if (
                this.template.reportCriteria[key][j].options[i].hasOwnProperty(
                  'scoreRange'
                ) &&
                typeof this.template.reportCriteria[key][j].options[i]
                  .scoreRange === 'object'
              ) {
                // score range
                if (
                  this.evl.evaluation.score >=
                    this.template.reportCriteria[key][j].options[i].scoreRange
                      .low &&
                  this.evl.evaluation.score <=
                    this.template.reportCriteria[key][j].options[i].scoreRange
                      .high
                ) {
                  // if score condition is met
                  this.reportSelections[key][j].options.push({
                    isDefault:
                      this.template.reportCriteria[key][j].options[i].isDefault,
                    text: this.template.reportCriteria[key][j].options[i].text,
                    selected: true
                  });
                }
              }
            }
          } else {
            // if criteria section is included based on choices
            // console.log(this.template.reportCriteria[key][j]);
            // console.log('NOT CONDITIONAL BASED ON EVAL SCORE');
            for (
              k = 0;
              k < this.template.reportCriteria[key][j].options.length;
              k++
            ) {
              // console.log(this.template.reportCriteria[key][j].options[k]);
              if (
                this.template.reportCriteria[key][j].options[k].choices &&
                this.template.reportCriteria[key][j].options[k].choices.length >
                  0
              ) {
                this.reportSelections[key][j].options.push({
                  isDefault:
                    this.template.reportCriteria[key][j].options[k].isDefault,
                  text: this.template.reportCriteria[key][j].options[k].text,
                  selected:
                    this.template.reportCriteria[key][j].options[k].isDefault,
                  options: [],
                  choices: []
                });
              } else {
                this.reportSelections[key][j].options.push({
                  isDefault:
                    this.template.reportCriteria[key][j].options[k].isDefault,
                  text: this.template.reportCriteria[key][j].options[k].text,
                  selected:
                    this.template.reportCriteria[key][j].options[k].isDefault,
                  options: []
                });
              }
              // console.log(this.reportSelections[key][j].options);
              if (
                parseInt(
                  this.template.reportCriteria[key][j].options[k].sectionType,
                  10
                ) === 1
              ) {
                // if option is conditional based on evaluation score
                if (
                  this.template.reportCriteria[key][j].options[k].options
                    .length > 0
                ) {
                  for (
                    l = 0;
                    l <
                    this.template.reportCriteria[key][j].options[k].options
                      .length;
                    l++
                  ) {
                    if (
                      this.template.reportCriteria[key][j].options[k].options[
                        l
                      ].hasOwnProperty('scoreCondition') &&
                      typeof this.template.reportCriteria[key][j].options[k]
                        .options[l].scoreCondition === 'number'
                    ) {
                      if (
                        this.evl.evaluation.score ===
                        this.template.reportCriteria[key][j].options[k].options[
                          l
                        ].scoreCondition
                      ) {
                        // console.log(this.template.reportCriteria[key][j].options[k].options[l]);
                        // console.log('PUSHING SCORE CONDITION');
                        // console.log(this.template.reportCriteria[key][j].options[k].options[l].text);
                        this.reportSelections[key][j].options[k].options.push({
                          isDefault:
                            this.template.reportCriteria[key][j].options[k]
                              .options[l].isDefault,
                          text: this.template.reportCriteria[key][j].options[k]
                            .options[l].text,
                          selected: true
                        });
                      }
                    } else if (
                      this.template.reportCriteria[key][j].options[k].options[l]
                        .scoreRange
                    ) {
                      // score range
                      if (
                        this.evl.evaluation.score >=
                          parseInt(
                            this.template.reportCriteria[key][j].options[k]
                              .options[l].scoreRange.low,
                            10
                          ) &&
                        this.evl.evaluation.score <=
                          parseInt(
                            this.template.reportCriteria[key][j].options[k]
                              .options[l].scoreRange.high,
                            10
                          )
                      ) {
                        // if score condition is met
                        // console.log('PUSHING SCORE RANGE');
                        // console.log(this.template.reportCriteria[key][j].options[k].options[l].text);
                        this.reportSelections[key][j].options[k].options.push({
                          isDefault:
                            this.template.reportCriteria[key][j].options[k]
                              .options[l].isDefault,
                          text: this.template.reportCriteria[key][j].options[k]
                            .options[l].text,
                          selected: true
                        });
                      }
                    }
                  }
                }
              }
              // console.log(this.reportSelections[key][j].options);
              if (
                this.template.reportCriteria[key][j].options[k].choices &&
                this.template.reportCriteria[key][j].options[k].choices.length >
                  0
              ) {
                // check if choices are givem
                for (
                  l = 0;
                  l <
                  this.template.reportCriteria[key][j].options[k].choices
                    .length;
                  l++
                ) {
                  var options = [];
                  if (
                    parseInt(
                      this.template.reportCriteria[key][j].options[k].choices[l]
                        .basedOnScore,
                      10
                    ) === 1
                  ) {
                    for (
                      m = 0;
                      m <
                      this.template.reportCriteria[key][j].options[k].choices[l]
                        .options.length;
                      m++
                    ) {
                      if (
                        this.template.reportCriteria[key][j].options[k].choices[
                          l
                        ].options[m].hasOwnProperty('scoreCondition') &&
                        typeof this.template.reportCriteria[key][j].options[k]
                          .choices[l].options[m].scoreCondition === 'number'
                      ) {
                        if (
                          this.evl.evaluation.score ===
                          this.template.reportCriteria[key][j].options[k]
                            .choices[l].options[m].scoreCondition
                        ) {
                          // console.log('PUSHING SCORE CONDITION TEXT');
                          // console.log(this.template.reportCriteria[key][j].options[k].choices[l].options[m].text);
                          options.push({
                            text: this.template.reportCriteria[key][j].options[
                              k
                            ].choices[l].options[m].text,
                            selected: true
                          });
                        }
                      } else if (
                        this.template.reportCriteria[key][j].options[k].choices[
                          l
                        ].options[m].scoreRange
                      ) {
                        if (
                          this.evl.evaluation.score >=
                            this.template.reportCriteria[key][j].options[k]
                              .choices[l].options[m].scoreRange.low &&
                          this.evl.evaluation.score <=
                            this.template.reportCriteria[key][j].options[k]
                              .choices[l].options[m].scoreRange.high
                        ) {
                          // console.log('PUSHING SCORE RANGE TEXT');
                          // console.log(this.template.reportCriteria[key][j].options[k].choices[l].options[m].text);
                          options.push({
                            text: this.template.reportCriteria[key][j].options[
                              k
                            ].choices[l].options[m].text,
                            selected: true
                          });
                        }
                      }
                    }
                  } else {
                    for (
                      m = 0;
                      m <
                      this.template.reportCriteria[key][j].options[k].choices[l]
                        .options.length;
                      m++
                    ) {
                      options.push({
                        text: this.template.reportCriteria[key][j].options[k]
                          .choices[l].options[m].text,
                        selected: true
                      });
                    }
                  }
                  // console.log(this.template.reportCriteria[key][j].options[k].choices[l]);
                  this.reportSelections[key][j].options[k].choices.push({
                    text: this.template.reportCriteria[key][j].options[k]
                      .choices[l].text,
                    selected: false,
                    options: options
                  });
                  // if (parseInt(this.template.reportCriteria[key][j].options[k].choices[l].basedOnScore, 10) === 1) { // choice is based on evaluation score
                  // }
                }
              }
            }
          }
        }
        // console.log(this.reportSelections[key]);
      } else if (
        this.template.reportCriteria[key] &&
        this.template.reportCriteria[key].options
      ) {
        if (key === 'itemDescriptions') {
          var itemDescriptions = [];
          var itemDescription = {};
          for (
            i = 0;
            i < this.template.reportCriteria[key].descriptions.length;
            i++
          ) {
            for (cle in this.evl.evaluation.data) {
              if (
                cle ===
                this.template.reportCriteria[key].descriptions[i]
                  .codingFormItemId
              ) {
                // found matching evaluation to reportTemplate question id's
                // console.log(this.evl.evaluation.data[cle]);
                // start constructing itemDescription
                itemDescription = {};
                itemDescription.itemDescription =
                  this.template.reportCriteria[key].descriptions[
                    i
                  ].codingFormItemDescription;
                if (itemDescription.itemDescription) {
                  itemDescription.itemDescription =
                    itemDescription.itemDescription.replace(
                      this.variableRegEx,
                      this.replacer
                    );
                }
                itemDescription.riskFactor =
                  this.template.reportCriteria[key].descriptions[
                    i
                  ].codingFormItemRiskFactor;
                if (this.evl.evaluation.data[cle].wizard) {
                  // create wizardAnswerVariableMap
                  // itemDescription.wizardItemDescriptions = [];
                  // region START create wizardAnswerVariableMap for replacing bracket variables in text strings
                  var wizardAnswerVariableMap = {};
                  if (
                    this.evl.evaluation.data[cle].wizard.breadcrumb &&
                    !this.evl.evaluation.data[cle].wizard.answers
                  ) {
                    this.parseWizardAnswersFromApp(
                      this.evl.evaluation.data[cle].wizard,
                      function (wiz) {
                        this.evl.evaluation.data[cle].wizard = wiz;
                      }
                    );
                  }
                  for (
                    k = 0;
                    k < this.evl.evaluation.data[cle].wizard.answers.length;
                    k++
                  ) {
                    // console.log(this.evl.evaluation.data[cle].wizard.answers[k]);
                    var wQKey = Object.keys(
                      this.evl.evaluation.data[cle].wizard.answers[k]
                    )[0];
                    var wizAnswerKey = '';
                    var wizAnswerValue = [];
                    if (
                      isNaN(
                        parseInt(
                          this.evl.evaluation.data[cle].wizard.answers[k][wQKey]
                            .selectedAnswer,
                          10
                        )
                      )
                    ) {
                      // answers that have a selected answer
                      wizAnswerKey = '[' + wQKey + ']';
                      wizAnswerValue =
                        this.evl.evaluation.data[cle].wizard.answers[k][wQKey]
                          .answerText[0];
                      wizardAnswerVariableMap[wizAnswerKey] = wizAnswerValue;
                    } else {
                      // MCMA Answer
                      for (
                        l = 0;
                        l <
                        this.template.reportCriteria[key].descriptions[i]
                          .answerDescriptions.length;
                        l++
                      ) {
                        if (
                          this.template.reportCriteria[key].descriptions[i]
                            .answerDescriptions[l].id === wQKey
                        ) {
                          for (
                            m = 0;
                            m <
                            this.template.reportCriteria[key].descriptions[i]
                              .answerDescriptions[l].answers.length;
                            m++
                          ) {
                            var scoreOutcome = '';
                            if (
                              this.template.reportCriteria[key].descriptions[
                                i
                              ].answerDescriptions[l].answers[
                                m
                              ].answerText.includes('Score Outcome: ')
                            ) {
                              scoreOutcome = this.template.reportCriteria[
                                key
                              ].descriptions[i].answerDescriptions[l].answers[
                                m
                              ].answerText.replace('Score Outcome: ', '');
                            } else {
                              scoreOutcome =
                                this.template.reportCriteria[key].descriptions[
                                  i
                                ].answerDescriptions[l].answers[m].answerText;
                            }
                            var evalOutcome =
                              this.evl.evaluation.data[cle].wizard.answers[k][
                                wQKey
                              ].operator +
                              ' ' +
                              this.evl.evaluation.data[cle].wizard.answers[k][
                                wQKey
                              ].selectedAnswer;
                            if (scoreOutcome === evalOutcome) {
                              wizAnswerKey =
                                '[' +
                                this.template.reportCriteria[key].descriptions[
                                  i
                                ].answerDescriptions[l].id +
                                ']';
                              if (
                                this.evl.evaluation.data[cle].wizard.answers[k][
                                  wQKey
                                ].answerText.length > 1
                              ) {
                                for (
                                  n = 0;
                                  n <
                                  this.evl.evaluation.data[cle].wizard.answers[
                                    k
                                  ][wQKey].answerText.length;
                                  n++
                                ) {
                                  if (n === 0) {
                                    wizAnswerValue =
                                      this.evl.evaluation.data[cle].wizard
                                        .answers[k][wQKey].answerText[n];
                                  } else if (
                                    n ===
                                    this.evl.evaluation.data[cle].wizard
                                      .answers[k][wQKey].answerText.length -
                                      1
                                  ) {
                                    wizAnswerValue =
                                      wizAnswerValue +
                                      ', and ' +
                                      this.evl.evaluation.data[cle].wizard
                                        .answers[k][wQKey].answerText[n];
                                  } else {
                                    wizAnswerValue =
                                      wizAnswerValue +
                                      this.evl.evaluation.data[cle].wizard
                                        .answers[k][wQKey].answerText[n];
                                  }
                                }
                              } else {
                                wizAnswerValue =
                                  this.evl.evaluation.data[cle].wizard.answers[
                                    k
                                  ][wQKey].answerText[0];
                              }
                              wizardAnswerVariableMap[wizAnswerKey] =
                                wizAnswerValue;
                            } else {
                              // console.log('No score and eval outcome equivalent could be found');
                            }
                          }
                        }
                      }
                    }
                    if (this.evl.evaluation.data[cle].wizard.variables) {
                      // if there are variables, add to variableMap
                      for (llave in this.evl.evaluation.data[cle].wizard
                        .variables) {
                        wizAnswerKey = '[' + llave + ']';
                        wizAnswerValue =
                          this.evl.evaluation.data[cle].wizard.variables[llave];
                        wizardAnswerVariableMap[wizAnswerKey] = wizAnswerValue;
                      }
                    }
                    // create variableMap regex for replacing
                    this.wizVariableRegEx = Object.keys(
                      wizardAnswerVariableMap
                    ).join('|');
                    this.wizVariableRegEx = this.wizVariableRegEx.replace(
                      /\[/g,
                      '\\['
                    );
                    this.wizVariableRegEx = this.wizVariableRegEx.replace(
                      /\]/g,
                      '\\]'
                    );
                    this.wizVariableRegEx = new RegExp(
                      this.wizVariableRegEx,
                      'gi'
                    );
                  }
                  // endregion END create wizardAnswerVariableMap
                }
                if (this.evl.evaluation.data[cle].score === '-') {
                  itemDescription.answerDescription = 'QUESTION UNANSWERED';
                  if (this.evl.evaluation.data[cle].comment) {
                    itemDescription.comment =
                      this.evl.evaluation.data[cle].comment;
                  }
                } else if (
                  this.template.reportCriteria[key].descriptions[i]
                    .includeAllAnswers
                ) {
                  // include all answers
                  // console.log('INCLUDE ALL ANSWERS');
                  for (
                    j = 0;
                    j <
                    this.template.reportCriteria[key].descriptions[i]
                      .answerDescriptions.length;
                    j++
                  ) {
                    if (
                      this.template.reportCriteria[key].descriptions[i]
                        .answerDescriptions[j].id ===
                      this.evl.evaluation.data[cle].qid
                    ) {
                      // handle base question answers here
                      // console.log('BASE ANSWER FOUND: ' + this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].id);
                      // console.log(this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers);
                      for (
                        k = 0;
                        k <
                        this.template.reportCriteria[key].descriptions[i]
                          .answerDescriptions[j].answers.length;
                        k++
                      ) {
                        let answer =
                          this.template.reportCriteria[key].descriptions[i]
                            .answerDescriptions[j].answers[k];

                        if (
                          answer.wizardOverride &&
                          this.evl.evaluation.data[cle].wizard
                        ) {
                          // wizard override
                          // console.log('WIZARD OVERRIDE AND EVALUATION HAS WIZARD DATA');
                          if (answer.id === this.evl.evaluation.data[cle].aid) {
                            if (this.evl.evaluation.data[cle].comment) {
                              itemDescription.comment =
                                this.evl.evaluation.data[cle].comment;
                            }
                            itemDescription.answerDescription =
                              answer.wizardDescriptionOverride;
                            itemDescription.answerDescription =
                              itemDescription.answerDescription.toString();
                            if (itemDescription.answerDescription) {
                              itemDescription.answerDescription =
                                itemDescription.answerDescription.replace(
                                  this.variableRegEx,
                                  this.replacer
                                );
                            }
                          }
                        } else {
                          // no wizard override and no wizard used
                          // console.log('NO WIZARD OVERRIDE');

                          if (HasDescription(answer)) {
                            if (
                              answer.id === this.evl.evaluation.data[cle].aid
                            ) {
                              if (this.evl.evaluation.data[cle].comment) {
                                itemDescription.comment =
                                  this.evl.evaluation.data[cle].comment;
                              }
                              itemDescription.answerDescription =
                                answer.description;
                              itemDescription.answerDescription =
                                itemDescription.answerDescription.toString();
                              if (itemDescription.answerDescription) {
                                itemDescription.answerDescription =
                                  itemDescription.answerDescription.replace(
                                    this.variableRegEx,
                                    this.replacer
                                  );
                              }
                            }
                          }
                        }
                      } // end of handling base question answers
                    } else if (this.evl.evaluation.data[cle].wizard) {
                      // handle wizard answer descriptions here
                      // console.log('HANDLING WIZARD ANSWERS');
                      this.concatenate = false;
                      if (
                        this.template.reportCriteria[key].descriptions[i]
                          .concatenate
                      ) {
                        // console.log('CONCATENATE ANSWERS');
                        this.concatenate = true;
                        this.wizardString = '';
                      } else {
                        // console.log('DONT CONCATENATE ANSWERS');
                        this.concatenate = false;
                      }
                      for (
                        k = 0;
                        k < this.evl.evaluation.data[cle].wizard.answers.length;
                        k++
                      ) {
                        // console.log('COMPARING QUESTION ID\'S:');
                        // console.log(this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].id);
                        // console.log(Object.keys(this.evl.evaluation.data[cle].wizard.answers[k])[0]);
                        if (
                          this.template.reportCriteria[key].descriptions[i]
                            .answerDescriptions[j].id ===
                          Object.keys(
                            this.evl.evaluation.data[cle].wizard.answers[k]
                          )[0]
                        ) {
                          // if question id's match
                          // console.log('QUESTION ID\'S MATCH');
                          var evalWQKey = Object.keys(
                            this.evl.evaluation.data[cle].wizard.answers[k]
                          )[0];
                          var wizardItemDescription = {};
                          for (
                            l = 0;
                            l <
                            this.template.reportCriteria[key].descriptions[i]
                              .answerDescriptions[j].answers.length;
                            l++
                          ) {
                            if (
                              isNaN(
                                parseInt(
                                  this.evl.evaluation.data[cle].wizard.answers[
                                    k
                                  ][evalWQKey].selectedAnswer,
                                  10
                                )
                              )
                            ) {
                              // if single answer
                              // console.log('SINGLE ANSWER');
                              // console.log('COMPARING ANSWER ID\'S:');
                              // console.log(this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers[l].id);
                              // console.log(this.evl.evaluation.data[cle].wizard.answers[k][evalWQKey].selectedAnswer);

                              if (
                                this.template.reportCriteria[key].descriptions[
                                  i
                                ].answerDescriptions[j].answers[l].id ===
                                this.evl.evaluation.data[cle].wizard.answers[k][
                                  evalWQKey
                                ].selectedAnswer
                              ) {
                                let answer =
                                  this.template.reportCriteria[key]
                                    .descriptions[i].answerDescriptions[j]
                                    .answers[l];

                                if (HasDescription(answer)) {
                                  // if description is empty, skip
                                  // wizardItemDescription.wizardQuestionText = this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].questionText;
                                  if (this.concatenate) {
                                    // console.log(this.wizardString);
                                    this.wizardString =
                                      this.wizardString +
                                      this.template.reportCriteria[key]
                                        .descriptions[i].answerDescriptions[j]
                                        .answers[l].description;
                                    // console.log(this.wizardString);
                                    wizardItemDescription.answerDescription =
                                      this.wizardString;
                                    // console.log(wizardItemDescription);
                                  } else {
                                    wizardItemDescription.answerDescription =
                                      this.template.reportCriteria[
                                        key
                                      ].descriptions[i].answerDescriptions[
                                        j
                                      ].answers[l].description;
                                  }
                                  wizardItemDescription.answerDescription =
                                    wizardItemDescription.answerDescription.toString();
                                  if (wizardItemDescription.answerDescription) {
                                    wizardItemDescription.answerDescription =
                                      wizardItemDescription.answerDescription.replace(
                                        this.wizVariableRegEx,
                                        function (matched) {
                                          return wizardAnswerVariableMap[
                                            matched
                                          ];
                                        }
                                      );
                                    wizardItemDescription.answerDescription =
                                      wizardItemDescription.answerDescription.replace(
                                        this.variableRegEx,
                                        this.replacer
                                      );
                                  }
                                  if (!itemDescription.wizardItemDescriptions) {
                                    if (
                                      this.template.reportCriteria[key]
                                        .descriptions[i].answerDescriptions[j]
                                        .answers[l].disclaimer
                                    ) {
                                      itemDescription.disclaimer =
                                        wizardItemDescription.answerDescription;
                                    } else {
                                      if (this.concatenate) {
                                        // if (l === this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers.length - 1) { // check if we're at the end and then push the contcatenation
                                        itemDescription.wizardItemDescriptions =
                                          [];
                                        itemDescription.wizardItemDescriptions.push(
                                          wizardItemDescription
                                        );
                                        // }
                                      } else {
                                        itemDescription.wizardItemDescriptions =
                                          [];
                                        itemDescription.wizardItemDescriptions.push(
                                          wizardItemDescription
                                        );
                                      }
                                    }
                                  } else {
                                    if (
                                      this.template.reportCriteria[key]
                                        .descriptions[i].answerDescriptions[j]
                                        .answers[l].disclaimer
                                    ) {
                                      itemDescription.disclaimer =
                                        wizardItemDescription.answerDescription;
                                    } else {
                                      if (this.concatenate) {
                                        // NOTE removed check below because it was failing on the last item to be inserted
                                        // if (l === this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers.length - 1) {
                                        itemDescription.wizardItemDescriptions.push(
                                          wizardItemDescription
                                        );
                                        // }
                                      } else {
                                        itemDescription.wizardItemDescriptions.push(
                                          wizardItemDescription
                                        );
                                      }
                                    }
                                  }
                                }
                              }
                            } else {
                              // if MCMA
                              // console.log('MULTIPLE CHOICE MULTIPLE ANSWER');
                              if (
                                this.template.reportCriteria[key].descriptions[
                                  i
                                ].answerDescriptions[j].answers[l].answerText
                              ) {
                                var checkScoreOutcome =
                                  this.template.reportCriteria[
                                    key
                                  ].descriptions[i].answerDescriptions[
                                    j
                                  ].answers[l].answerText.replace(
                                    'Score Outcome: ',
                                    ''
                                  );
                              }
                              // console.log('COMPARING SCORE OUTCOME:');
                              // console.log(checkScoreOutcome);
                              if (
                                checkScoreOutcome ===
                                this.evl.evaluation.data[cle].wizard.answers[k][
                                  evalWQKey
                                ].operator +
                                  ' ' +
                                  this.evl.evaluation.data[cle].wizard.answers[
                                    k
                                  ][evalWQKey].selectedAnswer
                              ) {
                                // console.log('FOUND A SCORE OUTCOME MATCH');
                                // console.log(this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers[l].description);

                                let answer =
                                  this.template.reportCriteria[key]
                                    .descriptions[i].answerDescriptions[j]
                                    .answers[l];

                                if (HasDescription(answer)) {
                                  // if description string is empty, skip
                                  // console.log('DESCRIPTION STRING IS NOT EMPTY');
                                  // wizardItemDescription.wizardQuestionText = this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].questionText;
                                  if (this.concatenate) {
                                    // console.log('CONCATENATE ANSWERS:');
                                    // console.log('WIZARD STRING BEFORE:');
                                    // console.log(angular.copy(this.wizardString));
                                    this.wizardString =
                                      this.wizardString +
                                      this.template.reportCriteria[key]
                                        .descriptions[i].answerDescriptions[j]
                                        .answers[l].description;
                                    // console.log('WIZARD STRING AFTER:');
                                    // console.log(angular.copy(this.wizardString));
                                    // console.log('WIZARDITEMDESCRIPTION BEFORE:');
                                    // console.log(angular.copy(wizardItemDescription));
                                    wizardItemDescription.answerDescription =
                                      this.wizardString;
                                    // console.log('WIZARDITEMDESCRIPTION AFTER:');
                                    // console.log(angular.copy(wizardItemDescription));
                                  } else {
                                    wizardItemDescription.answerDescription =
                                      this.template.reportCriteria[
                                        key
                                      ].descriptions[i].answerDescriptions[
                                        j
                                      ].answers[l].description;
                                  }
                                  wizardItemDescription.answerDescription =
                                    wizardItemDescription.answerDescription.toString();
                                  // console.log('VARIABLE REPLACING:');
                                  // console.log('WIZARDANSWERVARIABLE MAP:');
                                  // console.log(angular.copy(wizardItemDescription.answerDescription));
                                  if (wizardItemDescription.answerDescription) {
                                    wizardItemDescription.answerDescription =
                                      wizardItemDescription.answerDescription.replace(
                                        this.wizVariableRegEx,
                                        function (matched) {
                                          return wizardAnswerVariableMap[
                                            matched
                                          ];
                                        }
                                      );
                                  }
                                  // console.log(angular.copy(wizardItemDescription.answerDescription));
                                  // console.log('VARIABLE MAP');
                                  if (wizardItemDescription.answerDescription) {
                                    wizardItemDescription.answerDescription =
                                      wizardItemDescription.answerDescription.replace(
                                        this.variableRegEx,
                                        this.replacer
                                      );
                                  }
                                  // console.log(angular.copy(wizardItemDescription));
                                  // console.log(itemDescription.wizardItemDescriptions);
                                  if (!itemDescription.wizardItemDescriptions) {
                                    if (
                                      this.template.reportCriteria[key]
                                        .descriptions[i].answerDescriptions[j]
                                        .answers[l].disclaimer
                                    ) {
                                      itemDescription.disclaimer =
                                        wizardItemDescription.answerDescription;
                                    } else {
                                      // console.log('NO DISCLAIMER');
                                      if (this.concatenate) {
                                        // console.log('CONCATENATE');
                                        // console.log('CHECK IF WE\'RE AT THE END OF THE ANSWER DESCRIPTIONS.ANSWERS ARRAY');
                                        // console.log(l);
                                        // console.log(this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers.length - 1);

                                        if (wizardItemDescription) {
                                          itemDescription.wizardItemDescriptions =
                                            [];
                                          itemDescription.wizardItemDescriptions.push(
                                            wizardItemDescription
                                          );
                                        }
                                        // if (l === this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers.length - 1) {
                                        //   itemDescription.wizardItemDescriptions = [];
                                        //   itemDescription.wizardItemDescriptions.push(wizardItemDescription);
                                        // }
                                      } else if (wizardItemDescription) {
                                        itemDescription.wizardItemDescriptions =
                                          [];
                                        itemDescription.wizardItemDescriptions.push(
                                          wizardItemDescription
                                        );
                                      }
                                    }
                                  } else {
                                    if (
                                      this.template.reportCriteria[key]
                                        .descriptions[i].answerDescriptions[j]
                                        .answers[l].disclaimer
                                    ) {
                                      itemDescription.disclaimer =
                                        wizardItemDescription.answerDescription;
                                    } else {
                                      if (this.concatenate) {
                                        if (wizardItemDescription) {
                                          // itemDescription.wizardItemDescriptions = [];
                                          itemDescription.wizardItemDescriptions.push(
                                            wizardItemDescription
                                          );
                                        }
                                        // if (l === this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers.length - 1) {
                                        //   itemDescription.wizardItemDescriptions.push(wizardItemDescription);
                                        // }
                                      } else if (wizardItemDescription) {
                                        itemDescription.wizardItemDescriptions.push(
                                          wizardItemDescription
                                        );
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                } else {
                  // only give the answer that gave the score
                  // console.log('ONLY GIVE ANSWER THAT GAVE THE SCORE');
                  // console.log(this.evl.evaluation.data[cle]);
                  if (this.evl.evaluation.data[cle].wizard) {
                    // a wizard was used to get the score
                    for (
                      j = 0;
                      j <
                      this.template.reportCriteria[key].descriptions[i]
                        .answerDescriptions.length;
                      j++
                    ) {
                      // console.log('CHECKING IF QUESTION ID\'S MATCH:');
                      // console.log(this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].id);
                      // console.log(this.evl.evaluation.data[cle].wizard.wqid);
                      if (
                        this.template.reportCriteria[key].descriptions[i]
                          .answerDescriptions[j].id ===
                        this.evl.evaluation.data[cle].wizard.wqid
                      ) {
                        // if question id's match
                        // console.log('QUESTION ID\'S MATCH!');
                        // console.log(this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers);
                        for (
                          k = 0;
                          k <
                          this.template.reportCriteria[key].descriptions[i]
                            .answerDescriptions[j].answers.length;
                          k++
                        ) {
                          // console.log(this.template.reportCriteria[key].descriptions[i].answerDescriptions[j].answers[k]);
                          // console.log(this.evl.evaluation.data[cle].wizard);
                          // if (this.evl.evaluation.data[cle].wizard.waid.substring(0, 2) === 'WQ') { // check if answer id is a question id, if so it's MCMA and needs to be evaluated to get WA ID from template
                          if (
                            this.template.reportCriteria[key].descriptions[
                              i
                            ].answerDescriptions[j].answers[
                              k
                            ].answerText.includes('Score Outcome:')
                          ) {
                            var evalString = this.template.reportCriteria[
                              key
                            ].descriptions[i].answerDescriptions[j].answers[
                              k
                            ].answerText.replace('Score Outcome:', '');
                            evalString = evalString.replace(/ /g, '');
                            if (evalString[0] === '=') {
                              evalString = '=' + evalString;
                            }
                            evalString =
                              this.evl.evaluation.data[cle].wizard.score +
                              evalString;
                            if (eval(evalString)) {
                              // console.log('FOUND EVAL MATCH!');
                              if (this.evl.evaluation.data[cle].comment) {
                                itemDescription.comment =
                                  this.evl.evaluation.data[cle].comment;
                              }
                              itemDescription.answerDescription =
                                this.template.reportCriteria[key].descriptions[
                                  i
                                ].answerDescriptions[j].answers[k].description;
                              itemDescription.answerDescription =
                                itemDescription.answerDescription.toString();
                              if (itemDescription.answerDescription) {
                                itemDescription.answerDescription =
                                  itemDescription.answerDescription.replace(
                                    this.variableRegEx,
                                    this.replacer
                                  );
                                itemDescription.answerDescription =
                                  itemDescription.answerDescription.replace(
                                    this.wizVariableRegEx,
                                    function (matched) {
                                      return wizardAnswerVariableMap[matched];
                                    }
                                  );
                              }
                            }
                          } else if (
                            this.template.reportCriteria[key].descriptions[i]
                              .answerDescriptions[j].answers[k].id ===
                            this.evl.evaluation.data[cle].wizard.waid
                          ) {
                            // console.log('FOUND THE ANSWER MATCH!');
                            if (this.evl.evaluation.data[cle].comment) {
                              itemDescription.comment =
                                this.evl.evaluation.data[cle].comment;
                            }
                            itemDescription.answerDescription =
                              this.template.reportCriteria[key].descriptions[
                                i
                              ].answerDescriptions[j].answers[k].description;
                            itemDescription.answerDescription =
                              itemDescription.answerDescription
                                ? itemDescription.answerDescription.toString()
                                : '';
                            if (itemDescription.answerDescription) {
                              itemDescription.answerDescription =
                                itemDescription.answerDescription.replace(
                                  this.variableRegEx,
                                  this.replacer
                                );
                              itemDescription.answerDescription =
                                itemDescription.answerDescription.replace(
                                  this.wizVariableRegEx,
                                  function (matched) {
                                    return wizardAnswerVariableMap[matched];
                                  }
                                );
                            }
                          }
                        }
                      }
                    }
                  } else {
                    // no wizard used, give base answer
                    for (
                      j = 0;
                      j <
                      this.template.reportCriteria[key].descriptions[i]
                        .answerDescriptions.length;
                      j++
                    ) {
                      if (
                        this.template.reportCriteria[key].descriptions[i]
                          .answerDescriptions[j].id ===
                        this.evl.evaluation.data[cle].qid
                      ) {
                        // handle base question answers here
                        for (
                          k = 0;
                          k <
                          this.template.reportCriteria[key].descriptions[i]
                            .answerDescriptions[j].answers.length;
                          k++
                        ) {
                          let answer =
                            this.template.reportCriteria[key].descriptions[i]
                              .answerDescriptions[j].answers[k];

                          // console.log('answer description answer: ', answer);
                          if (HasDescription(answer)) {
                            if (
                              answer.id === this.evl.evaluation.data[cle].aid
                            ) {
                              if (this.evl.evaluation.data[cle].comment) {
                                itemDescription.comment =
                                  this.evl.evaluation.data[cle].comment;
                              }
                              itemDescription.answerDescription =
                                answer.description;
                              itemDescription.answerDescription =
                                itemDescription.answerDescription.toString();
                              if (itemDescription.answerDescription) {
                                itemDescription.answerDescription =
                                  itemDescription.answerDescription.replace(
                                    this.variableRegEx,
                                    this.replacer
                                  );
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                // console.log(concatenate);
                if (this.concatenate) {
                  if (itemDescription.wizardItemDescriptions) {
                    for (
                      j = 0;
                      j < itemDescription.wizardItemDescriptions.length;
                      j++
                    ) {
                      if (
                        j ===
                        itemDescription.wizardItemDescriptions.length - 1
                      ) {
                        itemDescription.answerDescription =
                          itemDescription.answerDescription +
                          ' ' +
                          itemDescription.wizardItemDescriptions[j]
                            .answerDescription;
                      } else {
                        itemDescription.answerDescription =
                          itemDescription.answerDescription +
                          ' ' +
                          itemDescription.wizardItemDescriptions[j]
                            .answerDescription;
                      }
                    }
                    itemDescription.wizardItemDescriptions = [];
                  }
                }
                // console.log(angular.copy(itemDescription));
                itemDescriptions.push(angular.copy(itemDescription));
                // console.log(itemDescriptions);
              }
            }
            this.reportSelections[key] = {
              hasToggle: this.template.reportCriteria[key].hasToggle,
              toggleDefault: this.template.reportCriteria[key].toggleDefault,
              selected: this.template.reportCriteria[key].toggleDefault,
              title: this.template.reportCriteria[key].title,
              options: [],
              descriptions: itemDescriptions
            };
          }
        } else {
          // console.log(key);
          // console.log(this.template.reportCriteria[key].toggleDefault);
          this.reportSelections[key] = {
            hasToggle: this.template.reportCriteria[key].hasToggle,
            toggleDefault: this.template.reportCriteria[key].toggleDefault,
            selected: this.template.reportCriteria[key].toggleDefault,
            title: this.template.reportCriteria[key].title,
            options: []
          };
          for (
            i = 0;
            i < this.template.reportCriteria[key].options.length;
            i++
          ) {
            if (this.template.reportCriteria[key].options[i].isDefault) {
              // default
              this.reportSelections[key].options[i] = {
                isDefault:
                  this.template.reportCriteria[key].options[i].isDefault,
                text: this.template.reportCriteria[key].options[i].text,
                selected: true
              };
            } else {
              // not default
              this.reportSelections[key].options[i] = {
                isDefault:
                  this.template.reportCriteria[key].options[i].isDefault,
                text: this.template.reportCriteria[key].options[i].text,
                selected: false
              };
            }
          }
        }
      } else if (key === 'chartData') {
        this.reportSelections[key] = this.template.reportCriteria[key];
        for (i = 0; i < this.reportSelections[key].length; i++) {
          var series = this.reportSelections[key][i].data[0];
          console.log(series);
          for (j = 0; j < series.length; j++) {
            // console.log(series[j]);
            series[j] = series[j].toString();
            series[j] = series[j].replace(this.variableRegEx, this.replacer);
          }
          this.reportSelections[key][i].data[0] = series;
        }
      }
    }
    console.log('REPORT SELECTIONS');
    console.log(this.reportSelections);
    // force overview extended to be selected
    if (this.reportSelections.overview) {
      if (this.reportSelections.overview.options.length === 1) {
        this.reportSelections.overview.options[0].isDefault = true;
        this.reportSelections.overview.options[0].selected = true;
      } else {
        for (
          var i = 0;
          i < this.reportSelections.overview.options.length;
          i++
        ) {
          if (this.reportSelections.overview.options[i].text === 'Extended') {
            this.reportSelections.overview.options[i].isDefault = true;
            this.reportSelections.overview.options[i].selected = true;
          } else {
            this.reportSelections.overview.options[i].isDefault = false;
            this.reportSelections.overview.options[i].selected = false;
          }
        }
      }
    }
    if (this.reportSelections.itemDescriptions) {
      _.forEach(
        this.reportSelections.itemDescriptions.description,
        (desc, i) => {
          if (desc.itemDescription)
            desc.itemDescription = desc.itemDescription.replace(
              this.variableRegEx,
              this.replacer
            );
          if (desc.riskFactor)
            desc.riskFactor = desc.riskFactor.replace(
              this.variableRegEx,
              this.replacer
            );
          if (desc.answerDescription)
            desc.answerDescription = desc.answerDescription.replace(
              this.variableRegEx,
              this.replacer
            );
        }
      );
    }
    // build codingFormItemNotes array
    this.codingFormItemNotes = [];
    for (let key in this.evl.evaluation.data) {
      this.codingFormItemNotes.push({
        questionId: key,
        notes: this.evl.evaluation.data[key].comment
          ? this.evl.evaluation.data[key].comment
          : ''
      });
    }
  }

  generateReportChartsSVG() {
    var charts = document.getElementsByClassName('chart');

    this.reportCharts = [];
    for (var i = 0; i < charts.length; i++) {
      var image = new Image();
      var imageString = '';

      image.src = charts[i].toDataURL('image/png');
      imageString = image.src.replace('data:image/png;base64,', '');
      this.reportCharts.push(imageString);
    }
  }

  setTemplate(template) {
    this.evl.reportTemplate = template.template;
    this.evl.reportTemplateId = template.templateId;

    this.parseTemplate();
    this.chooseTemplate = false;
  }

  toggle(item, list) {
    for (var z = 0; z < list.length; z++) {
      list[z].selected = item === list[z];
    }
  }

  logSelections() {
    console.log(this.reportSelections);
  }

  async generateReport() {
    this.reportSubmission.score = this.evl.evaluation.score;
    this.reportSubmission.scores = this.scores;
    this.processingReport = true;
    let i;
    let client = _.find(this.$store.state.clients.items, {
      id: this.evl.evaluation.clientId.toString()
    });
    let pushContent = (content, contentsArray) => {
      _.forEach(content, (cont) => {
        if (cont.type === 0 || cont.type === 1) {
          // text or image type
          contentsArray.push(cont);
        } else if (cont.type === 3) {
          // table type
          if (
            typeof cont.table.rowData === 'string' &&
            cont.table.rowData.includes('include-notes')
          ) {
            let tableNotesContents = this.buildTable(cont, true);
            _.forEach(tableNotesContents, (ct2) => {
              contentsArray.push(ct2);
            });
          } else {
            contentsArray.push(this.buildTable(cont));
          }
        }
      });
      return contentsArray;
    };
    _.forEach(this.reportSelections, (val, key) => {
      if (key === 'generalNotes' && val.selected) {
        if (this.evl.evaluation.generalNotes) {
          this.reportSubmission.reportSections[key] =
            this.evl.evaluation.generalNotes + '';
          this.reportSubmission.reportSections[key] =
            this.reportSubmission.reportSections[key].replace(
              this.variableRegEx,
              this.replacer
            );
        }
      } else if (key === 'codingForm') {
        this.reportSubmission.reportSections[key] = val.selected;
      } else if (key === 'codingFormItemNotes') {
        this.reportSubmission.reportSections[key] = val.selected
          ? this.codingFormItemNotes
          : null;
      } else if (key === 'chartData') {
        this.reportSubmission.reportSections[key] = val;
        _.forEach(this.reportSubmission.reportSections[key], (sec, i) => {
          _.forEach(sec.labels, (secLabel, j) => {
            var label = secLabel.replace(this.variableRegEx, this.replacer);
          });
          _.forEach(sec.data[0], (secData, j) => {
            var series = secData.replace(this.variableRegEx, this.replacer);
          });
        });
      } else if (key === 'itemDescriptions' && val.selected) {
        if (!this.reportSubmission.reportSections.itemDescriptions)
          this.reportSubmission.reportSections.itemDescriptions = [];
        this.reportSubmission.reportSections.itemDescriptions = val;
        if (!this.itemNotes) {
          _.forEach(
            this.reportSubmission.reportSections.itemDescriptions.descriptions,
            (desc) => {
              Reflect.deleteProperty(desc, 'comment');
            }
          );
        }
        var itemDescription = {};
        var itemDescriptions = [];
        var wizardItemDescription = {};
        _.forEach(
          this.reportSubmission.reportSections.itemDescriptions.descriptions,
          (desc, i) => {
            itemDescription = {};
            itemDescription.riskFactor = desc.riskFactor;
            itemDescription.itemDescription = desc.itemDescription;
            itemDescription.answerDescription = desc.answerDescription;
            if (!this.itemNotes) {
              Reflect.deleteProperty(desc, 'comment');
              itemDescription.comment = null;
            } else {
              itemDescription.comment = desc.comment;
            }
            if (desc.wizardItemDescriptions) {
              itemDescription.wizardItemDescriptions = [];
              _.forEach(desc.wizardItemDescriptions, (widesc) => {
                itemDescription.wizardItemDescriptions.push({
                  answerDescription: widesc.answerDescription,
                  wizardQuestionText: widesc.wizardQuestionText
                });
              });
            }
            itemDescriptions.push(itemDescription);
          }
        );
        this.reportSubmission.reportSections.itemDescriptions =
          itemDescriptions;
      } else if (key === 'additionalCriteria') {
        _.forEach(val, (repSel) => {
          if (repSel.selected) {
            var contents = [];
            var title = '';
            var text = '';
            _.forEach(repSel.options, (opt) => {
              if (opt.selected) {
                // found selected base option
                // go through template to find matching contents
                _.forEach(
                  this.evl.reportTemplate.reportCriteria.additionalCriteria,
                  (ac) => {
                    if (repSel.title === ac.title) {
                      title = ac.title; // set title to push later
                      _.forEach(ac.options, (acOpt) => {
                        if (acOpt.text === opt.text) {
                          text = acOpt.text;
                          if (
                            acOpt.content[0].type === 0 &&
                            acOpt.content[0].text != ''
                          ) {
                            // text type based on first content
                            contents = pushContent(acOpt.content, contents);
                          } else {
                            contents = pushContent(acOpt.content, contents);
                          }
                          // check for other options within this option
                          if (opt.options?.length) {
                            _.forEach(opt.options, (opt2) => {
                              if (opt2.selected) {
                                _.forEach(acOpt.options, (acOpt2) => {
                                  if (opt2.text === acOpt2.text) {
                                    contents = pushContent(
                                      acOpt2.content,
                                      contents
                                    );
                                  }
                                });
                              }
                            });
                          }
                          // check for choices within this option
                          if (opt.choices?.length) {
                            _.forEach(opt.choices, (optChoice) => {
                              if (optChoice.selected) {
                                _.forEach(acOpt.choices, (acOptChoice) => {
                                  if (optChoice.text === acOptChoice.text) {
                                    if (
                                      parseInt(acOptChoice.basedOnScore, 10) ===
                                      1
                                    ) {
                                      // basedOnScore
                                      _.forEach(
                                        acOptChoice.options,
                                        (acOptChoiceOpt) => {
                                          if (
                                            acOptChoiceOpt.hasOwnProperty(
                                              'scoreCondition'
                                            ) &&
                                            typeof acOptChoiceOpt.scoreCondition ===
                                              'number'
                                          ) {
                                            if (
                                              this.evl.evaluation.score ===
                                              acOptChoiceOpt.scoreCondition
                                            ) {
                                              contents = pushContent(
                                                acOptChoiceOpt.content,
                                                contents
                                              );
                                            }
                                          } else if (
                                            acOptChoiceOpt.scoreRange
                                          ) {
                                            // based on score range
                                            let low =
                                              acOptChoiceOpt.scoreRange.low;
                                            let high =
                                              acOptChoiceOpt.scoreRange.high;
                                            if (
                                              this.evl.evaluation.score >=
                                                low &&
                                              this.evl.evaluation.score <= high
                                            ) {
                                              contents = pushContent(
                                                acOptChoiceOpt.content,
                                                contents
                                              );
                                            }
                                          }
                                        }
                                      );
                                    } else {
                                      // not basedOnScore, return everything
                                      _.forEach(
                                        acOptChoice.options,
                                        (acOptChoiceOpt) => {
                                          contents = pushContent(
                                            acOptChoiceOpt.content,
                                            contents
                                          );
                                        }
                                      );
                                    }
                                  }
                                });
                              }
                            });
                          }
                        }
                      });
                    }
                  }
                );
              }
            });
            let option = {
              text,
              contents: []
            };
            _.forEach(contents, (cont) => {
              if (cont.type === 0) {
                // text type
                cont.text = cont.text.replace(
                  this.variableRegEx,
                  this.replacer
                );
              }
              option.contents.push(cont);
            });
            console.log('final contents: ', contents);
            console.log('=================================');
            if (!this.reportSubmission.reportSections.additionalSections)
              this.reportSubmission.reportSections.additionalSections = [];
            this.reportSubmission.reportSections.additionalSections.push({
              title,
              option
            });
          }
        });
      } else if (val.selected) {
        // dynamic sections
        _.forEach(this.evl.reportTemplate.reportCriteria, (tempVal, cle) => {
          if (key === cle) {
            _.forEach(val.options, (selOpt) => {
              if (selOpt.selected) {
                _.forEach(tempVal.options, (tempValOpt) => {
                  if (selOpt.text === tempValOpt.text) {
                    this.reportSubmission.reportSections[key] = {
                      title: tempVal.title,
                      option: {
                        text: tempValOpt.text,
                        contents: tempValOpt.content
                      }
                    };
                    _.forEach(
                      this.reportSubmission.reportSections[key].option.contents,
                      (cont) => {
                        if (cont.type === 0) {
                          // text type
                          cont.text = cont.text.replace(
                            this.variableRegEx,
                            this.replacer
                          );
                        } else if (cont.type === 3) {
                          // table
                          cont = this.buildTable(cont);
                        }
                      }
                    );
                  }
                });
              }
            });
          }
        });
      }
    });
    if (this.reportSelections.chartData.length > 0) {
      this.generateReportChartsSVG();
    }
    this.reportSubmission.clientName = this.clientFirstLastName;
    let institutionName = await this.$api.institutionManager.getInstitution({
      instId: this.evl.evaluation.institutionId
    });
    if (institutionName.status === 200) {
      this.reportSubmission.institutionName = institutionName.data.name;
    } else {
      this.reportSubmission.institutionName =
        this.evl.evaluation.institutionName;
    }
    this.reportSubmission.date = this.$filter('dynamicDate')(
      this.evl.date,
      'MM/dd/yyyy',
      'UTC'
    );
    this.processingReport = false;
    this.submittingReport = true;
    Reflect.deleteProperty(this.reportSubmission.reportSections, 'chartData');
    // console.log('REPORT SUBMISSION');
    // console.log(this.reportSubmission);
    // console.log('client: ', client);
    // console.log('this.evl: ', this.evl);
    if (!client) {
      this.notify.display('Client not found!', 'error');
      return;
    }
    let submission = {
      reportTemplateId: this.evl.reportTemplateId,
      institutionId: this.evl.evaluation.institutionId,
      clientId: this.evl.evaluation.clientId,
      toolId: this.evl.toolUsed,
      evaluationId: this.evl.evaluation.evaluationId,
      score: this.evl.evaluation.score,
      reportData: this.reportSubmission,
      codingFormId: this.evl.report.codingFormId,
      useAlias: this.useAlias
    };
    // console.log('final submission: ', submission);
    try {
      let response = await this.$api.clientManager.createEvaluationReport(
        {
          instId: client.institutionId,
          sbGrpId: client?.subGroup?.id,
          clntId: client.id,
          evalId: this.evl.evaluation.evaluationId
        },
        submission
      );
      this.notify.display('Report Created', 'success');
      this.$close(response.data);
    } catch (err) {
      this.notify.display(response, 'error', true, 'Error - Report Creation');
    } finally {
      this.submittingReport = false;
    }
  }
}

export default angular
  .module('app.reportModal', [])
  .directive('reportModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./report.html'),
    controller: ReportModalController,
    controllerAs: 'vm'
  })).name;
