import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { State } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

export function editUserModalController(
  $scope,
  $http,
  $store,
  $auth,
  $util,
  getFormItems,
  getItems,
  Notification
) {
  'ngInject';

  var vm = this;
  vm.phonePattern = /^([a-zA-Z,#/ \.\(\)\-\+\*]*[0-9]){7}[0-9a-zA-Z,#/ \.\(\)\-\+\*]*$/;
  vm.loading = true;
  var parent = $scope.$parent;
  var i, j;

  console.log('$store: ', $store);

  var me = $store.state.me;

  vm.groups = [{ name: 'GEARS', type: 'GIFR' }];

  vm.activeRoleId = -1;

  if (me.institution) {
    this.institutionId = me.institution.id;
    this.institutionName = me.institution.name;
  }

  vm.getCount = 0;
  getItems.allInstitutions().then(response => {
    vm.institutions = response;
    for (i = 0; i < vm.institutions.length; i++) {
      vm.institutions[i].id = vm.institutions[i].id;
      vm.institutions[i].type = 'Institution';
      vm.groups.push(vm.institutions[i]);
    }
    vm.getCount++;
    if (vm.getCount === 2) {
      vm.loading = false;
    }
  });

  getFormItems.countries().then(response => {
    vm.countries = response;
  });

  // grab the item passed to the modal controller and copy relevant information
  var item = parent.modal.item;
  if (item.user.country) {
    if (item.user.country === 'US') {
      getFormItems.usStates().then(response => {
        vm.stateProvinces = response;
      });
    } else if (item.user.country === 'CA') {
      getFormItems.canadaProvinces().then(response => {
        vm.stateProvinces = response;
      });
    } else if (item.user.country === 'GB') {
      getFormItems.ukProvinces().then(response => {
        vm.stateProvinces = response;
      });
    } else if (item.user.country === 'AU') {
      getFormItems.auStates().then(response => {
        vm.stateProvinces = response;
      });
    }
  }

  vm.prevCtrl = parent.modal.item.prevCtrl;

  vm.user = {
    userId: item.user.id,
    fName: item.user.fName,
    lName: item.user.lName,
    email: item.user.email,
    phoneWork: item.user.phoneWork,
    phoneCell: item.user.phoneCell,
    country: item.user.country,
    address1: item.user.address1,
    address2: item.user.address2,
    address3: item.user.address3,
    stateProvince: item.user.stateProvince,
    city: item.user.city,
    postalCode: item.user.postalCode,
    roles: [],
    senderId: item.id
  };

  var instId;

  vm.countryChosen = function(country) {
    if (country === 'US') {
      getFormItems.usStates().then(response => {
        vm.stateProvinces = response;
      });
    } else if (country === 'CA') {
      getFormItems.canadaProvinces().then(response => {
        vm.stateProvinces = response;
      });
    } else if (country === 'GB') {
      getFormItems.ukProvinces().then(response => {
        vm.stateProvinces = response;
      });
    } else if (country === 'AU') {
      getFormItems.auStates().then(response => {
        vm.stateProvinces = response;
      });
    }
  };

  vm.userCopy = angular.copy(vm.user);
  vm.tempRoles = angular.copy(vm.user.roles);
  vm.rolesToAdd = [];
  vm.rolesToChange = [];

  // role choices functions
  vm.addRoleChoice = function() {
    vm.user.roles.push({
      AssignedRole: {
        institution: false,
        developerGroup: false,
        institutionId: null,
        developerGroupId: null
      }
    });
  };

  vm.removeRoleChoice = function(id) {
    for (i = 0; i < vm.user.roles.length; i++) {
      if (id === vm.user.roles[i].id) {
        for (j = 0; j < vm.tempRoles.length; j++) {
          if (id === vm.tempRoles[j].id) {
            vm.tempRoles[j].status = 'delete';
          }
        }
        vm.user.roles.splice(i, 1); // remove deleted role from user roles list
      }
    }
  };

  //notify
  vm.counter = 0;
  vm.notify = function(data) {
    vm.counter += 1;
    if (data.error) {
      vm.handleError(data.error);
    } else {
      Notification.success({
        message: 'Update User Successful',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });
    }
    if (vm.counter === vm.actionCount) {
      parent.$close();
    }
  };

  vm.groupSelected = function(choice) {
    if (!choice.AssignedRole) {
      choice.AssignedRole = {};
    }

    for (i = 0; i < vm.groups.length; i++) {
      if (vm.groups[i].id === choice.group) {
        if (vm.groups[i].type === 'Institution') {
          choice.institution = true;
          choice.developerGroup = false;
          choice.institutionId = choice.group;
          if (choice.developerGroupId) {
            choice.developerGroupId = null;
          }
          choice.roleId = '';
        } else {
          choice.institution = false;
          choice.developerGroup = false;
          choice.institutionId = null;
          choice.developerGroupId = null;
          choice.roleId = '';
        }
      }
    }
  };

  // handle error
  vm.handleError = function(error) {
    if (error.status === 404) {
      Notification.error({
        message: 'Request Not Found',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });
    } else if (error.status === 500) {
      Notification.error({
        message: `Something went wrong on our end. Please try again later or contact ${process.env.SUPPORT_EMAIL} for immediate assistance. Sorry for the inconvenience.`,
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });
    } else if (error.status === 401) {
      Notification.error({
        message: 'Unauthorized',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });
    } else if (error.status === 403) {
      Notification.error({
        message: 'Forbidden',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });
    } else {
      Notification.error({
        message: 'There was an error processing your request',
        positionX: 'center',
        templateUrl: 'assets/components/notification-template.html'
      });
    }
  };

  // super admins remove role
  vm.removeRole = function(choice) {
    if (vm.activeRoleId === 3) {
      let userId = '';
      if (vm.user.id) {
        userId = vm.user.id;
      } else {
        userId = vm.user.userId;
      }
      $http({
        method: 'DELETE',
        url: '/api/institutions/' + choice.institutionId + '/users/remove',
        data: {
          userId
        },
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      })
        .then(response => {
          Notification.success({
            message:
              'User has successfully been removed from your institution.',
            positionX: 'center',
            templateUrl: 'assets/components/notification-template.html'
          });
          parent.$close();
        })
        .catch(err => {
          vm.notify(err);
        });
    } else if (vm.activeRoleId === 6) {
      $http({
        method: 'DELETE',
        url:
          '/api/developer-groups/' + choice.developerGroupId + '/users/remove',
        data: {
          userId
        },
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      })
        .then(response => {
          Notification.success({
            message:
              'User has successfully been removed from your developer group.',
            positionX: 'center',
            templateUrl: 'assets/components/notification-template.html'
          });
          parent.$close();
        })
        .catch(err => {
          vm.notify(err);
        });
    }
  };

  // update user information
  vm.updateUser1 = function(form) {
    vm.counter = 0;
    let userId = '';

    if (vm.user.id) {
      userId = vm.user.id;
    } else {
      userId = vm.user.userId;
    }

    if (vm.activeRoleId === 7 || vm.activeRoleId === 8) {
      // if gifr admin
      vm.errors = [];
      if (form.$valid) {
        vm.formSubmitted = true;
        // USER UPDATE
        var key, cle;
        var userSubmission = {};
        for (key in vm.userCopy) {
          if (
            key === 'userId' ||
            key === 'active' ||
            key === 'dob' ||
            key === 'roles'
          ) {
            continue;
          }
          for (cle in vm.user) {
            if (key === cle) {
              if (vm.userCopy[key] != vm.user[cle]) {
                userSubmission[key] = vm.user[cle];
              }
            }
          }
        }
        if (Object.getOwnPropertyNames(userSubmission).length > 0) {
          $http
            .post('/api/users/' + userId, userSubmission)
            .then(response => {
              if (response.data.error) {
                vm.errors.push('Error - User Profile Update');
                vm.handleError(response);
              }
            })
            .catch(err => {
              vm.errors.push('Error - User Profile Update');
              vm.handleError(err);
            });
        }
        // ROLES UPDATE
        vm.actionCount = 0; // keep track of number of things we're changing so we can close the modal when it's finished with the last one
        for (i = 0; i < vm.user.roles.length; i++) {
          // go through the goal and compare to the original to see if there are matches or changes and mark the status
          var groupMatch = false;
          var fullMatch = false;
          for (j = 0; j < vm.tempRoles.length; j++) {
            if (vm.user.roles[i].institutionId !== null) {
              // we're dealing with an institution
              if (
                vm.user.roles[i].institutionId === vm.tempRoles[j].institutionId
              ) {
                // institutions match
                groupMatch = true;
                if (vm.user.roles[i].roleId === vm.tempRoles[j].roleId) {
                  // institutions and roles match
                  fullMatch = true;
                }
              }
            } else {
              // we're dealing with a developer group
              if (
                vm.user.roles[i].developerGroupId ===
                vm.tempRoles[j].developerGroupId
              ) {
                // developer groups match
                groupMatch = true;
                if (vm.user.roles[i].roleId === vm.tempRoles[j].roleId) {
                  // developer groups and roles match
                  fullMatch = true;
                }
              }
            }
            if (fullMatch) {
              vm.user.roles[i].status = 'ignore'; // ignore, not change needed
            } else if (groupMatch) {
              vm.user.roles[i].status = 'change'; // this is a change to a current role
            } else {
              vm.user.roles[i].status = 'add'; // this is a new role and needs to be added
            }
          }
          if (vm.user.roles[i].status != 'ignore') {
            vm.actionCount++;
          }
        }
        for (i = 0; i < vm.tempRoles.length; i++) {
          if (vm.tempRoles[i].status === 'delete') {
            vm.actionCount++;
          }
        }
        if (vm.actionCount > 0) {
          for (i = 0; i < vm.user.roles.length; i++) {
            // go through user.roles and change or add based on status
            if (vm.user.roles[i].status === 'change') {
              $http
                .post('/api/assigned-roles/' + vm.user.roles[i].id, {
                  userId,
                  roleId: vm.user.roles[i].roleId
                })
                .then(response => {
                  vm.notify(response.data);
                })
                .catch(err => {
                  vm.notify(err);
                });
            } else if (vm.user.roles[i].status === 'add') {
              if (vm.user.roles[i].institution) {
                // add to an institution
                $http
                  .post(
                    '/api/institutions/' +
                      vm.user.roles[i].institutionId +
                      '/users/add',
                    {
                      userId,
                      roleId: vm.user.roles[i].roleId
                    }
                  )
                  .then(response => {
                    vm.notify(response.data);
                  })
                  .catch(err => {
                    vm.notify(err);
                  });
              } else if (vm.user.roles[i].developerGroup) {
                // add to a developer group
                $http
                  .post(
                    '/api/developer-groups/' +
                      vm.user.roles[i].developerGroupId +
                      '/users/add',
                    {
                      userId,
                      roleId: vm.user.roles[i].roleId
                    }
                  )
                  .then(response => {
                    vm.notify(response.data);
                  })
                  .catch(err => {
                    vm.notify(err);
                  });
              } else {
                // add as a gifr super admin or admin
                $http
                  .post('/api/assigned-roles', {
                    userId,
                    roleId: vm.user.roles[i].roleId
                  })
                  .then(response => {
                    vm.notify(response.data);
                  })
                  .catch(err => {
                    vm.notify(err);
                  });
              }
            }
          }

          for (i = 0; i < vm.tempRoles.length; i++) {
            // go through tempRoles and delete based on status
            if (vm.tempRoles[i].status === 'delete') {
              if (vm.tempRoles[i].institutionId !== null) {
                // remove from institution
                $http({
                  method: 'DELETE',
                  url:
                    '/api/institutions/' +
                    vm.tempRoles[i].institutionId +
                    '/users/remove',
                  data: {
                    userId
                  },
                  headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                  }
                })
                  .then(response => {
                    vm.notify(response.data);
                  })
                  .catch(err => {
                    vm.notify(err);
                  });
              } else if (vm.tempRoles[i].developerGroupId !== null) {
                // remove from developer group
                $http({
                  method: 'DELETE',
                  url:
                    '/api/developer-groups/' +
                    vm.tempRoles[i].developerGroupId +
                    '/users/remove',
                  data: {
                    userId
                  },
                  headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                  }
                })
                  .then(response => {
                    vm.notify(response.data);
                  })
                  .catch(err => {
                    vm.notify(err);
                  });
              } else {
                // remove gifr super admin or gifr admin
                $http
                  .delete('/api/assigned-roles/' + vm.tempRoles[i].id)
                  .then(response => {
                    if (response.status === 204) {
                      response.error = false;
                    } else {
                      response.error = true;
                    }
                    vm.notify(response);
                  })
                  .catch(err => {
                    vm.notify(err);
                  });
              }
            }
          }
        } else {
          // no action count
          if (vm.errors.length) {
            for (var i = 0; i < vm.errors.length; i++) {
              Notification.error({
                message: vm.errors[i],
                positionX: 'center',
                templateUrl: 'assets/components/notification-template.html'
              });
            }
          } else {
            Notification.success({
              message: 'User Updated Successfully',
              positionX: 'center',
              templateUrl: 'assets/components/notification-template.html'
            });
            parent.$close();
          }
        }
        // END update user roles
        vm.formSubmitted = false;
      }
    } else if (vm.activeRoleId === 3) {
      // institution super admin so they can only update one role
      for (var i = 0; i < vm.user.roles.length; i++) {
        if (vm.institutionId === vm.user.roles[i].institutionId) {
          $http
            .post('/api/assigned-roles/' + vm.user.roles[i].id, {
              roleId: vm.user.roles[i].roleId
            })
            .then(response => {
              vm.notify(response.data);
              parent.$close();
            })
            .catch(err => {
              vm.notify(err);
            });
        }
      }
    } else if (vm.activeRoleId === 6) {
      // developer super admin so they can only update one role
      for (var i = 0; i < vm.user.roles.length; i++) {
        if (vm.developerGroupId === vm.user.roles[i].developerGroupId) {
          $http
            .post('/api/assigned-roles/' + vm.user.roles[i].id, {
              roleId: vm.user.roles[i].roleId
            })
            .then(response => {
              vm.notify(response.data);
              parent.$close();
            })
            .catch(err => {
              vm.notify(err);
            });
        }
      }
    }
  };
}

export default angular
  .module('app.editUserModal', [])
  .directive('editUserModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./edit-user.html'),
    controller: editUserModalController,
    controllerAs: 'vm'
  })).name;
