'use strict';

import angular from 'angular';

export default angular
  .module('app.statChart', [])
  .directive('statChart', () => {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        width: '<',
        height: '<',
        type: '<',
        config: '<'
      },
      template: function(element, attrs) {
        return `
          <div class="stat-chart">
            <canvas
              class="chart chart-${attrs.type || 'bar'}"
              width="{{width}}"
              height="{{height}}"
              chart-data="config.data"
              chart-labels="config.labels"
              chart-series="config.series"
              chart-options="config.options"
            ></canvas>
          </div>
        `;
      }
    };
  }).name;
