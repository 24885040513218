import angular from 'angular';

export default angular
  .module('app.formFieldNumber', [])
  .directive('formFieldNumber', () => ({
    restrict: 'E',
    replace: true,
    template: require('./form-field-number.html'),
    scope: {
      model: '=',
      label: '<',
      required: '<'
    }
  })).name;
