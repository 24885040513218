import angular from 'angular';

import { ModalConfigurationWrapper } from './modal-configs';

/**
 * ...
 */
type WrapperGroups = ModalConfigurationWrapper['groups'];

/**
 * ...
 */
interface ModalOptions<R = void> {
  title?: string;
  component: string;
  size?: string;
  dismissable?: boolean;
  maximisable?: boolean;
  // props?: Record<string, unknown>;
  props?: unknown;
  onDismiss?: () => unknown;
  onClose?: (output: unknown) => R;
}

/**
 * ...
 */
export interface ModalGroupConfig {
  [key: string]: (...args: unknown[]) => ModalOptions;
}

/**
 * ...
 */
export type ModalConfigGroup<T extends ModalGroupConfig> = {
  [P in keyof T]: (
    ...args: Parameters<T[P]>
  ) => Promise<
    ReturnType<T[P]>['onClose'] extends (...args: unknown[]) => unknown
      ? ReturnType<ReturnType<T[P]>['onClose']>
      : void
  >;
};

declare module 'angular' {
  namespace gears {
    type IModalsService = {
      [P in keyof WrapperGroups]: ModalConfigGroup<WrapperGroups[P]>;
    };
  }
}

function ModalsService(
  $injector: angular.auto.IInjectorService,
  $uibModal: angular.ui.bootstrap.IModalService
) {
  'ngInject';

  /**
   * ...
   *
   * @param options ...
   * @param modalClass ...
   * @return ...
   */
  const open = async (options: ModalOptions, modalClass = 'modal-default') => {
    let output: unknown;
    let dismissed = false;

    if (process.env.NODE_ENV === 'development') {
      console.log(`Modal "${options.component}" opened`, options);
    }

    const modal = $uibModal.open({
      size: options.size || 'md',
      windowClass: modalClass,
      backdrop: 'static',
      backdropClass: 'auto-animate',
      // dismissable: !!options.dismissable,
      component: options.component,
      resolve: {
        title: () => options.title,
        dismissable: () => options.dismissable,
        maximisable: () => options.maximisable,
        props: () => ({ ...(options.props || {}) })
      }
    });

    try {
      output = await modal.result;
    } catch (err) {
      dismissed = true;
    }

    const onDismiss = options.onDismiss
      ? () => options.onDismiss!()
      : () => null;
    const onClose = options.onClose
      ? () => options.onClose!(output)
      : (output: unknown) => output;

    if (dismissed && onDismiss) {
      return onDismiss();
    } else if (!dismissed && onClose) {
      return onClose(output);
    }

    return { output, dismissed };
  };

  /**
   * ...
   *
   * @param config ...
   * @return ...
   */
  const createModalGroup = <T extends ModalGroupConfig>(config: T) => {
    const entries = Object.entries(config).map(([key, value]) => [
      key,
      (...args: unknown[]) => open(value(...args))
    ]);

    return Object.fromEntries(entries);
  };

  //
  const configs = $injector.instantiate(ModalConfigurationWrapper);

  //
  return Object.fromEntries(
    Object.entries(configs.groups).map(([key, config]) => [
      key,
      createModalGroup(config)
    ])
  );
}

export default angular
  .module('app.$modals', [])
  .factory('$modals', ModalsService).name;
