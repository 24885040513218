'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class PolicyEditorModalController extends Modal {
  $setup() {
    this.$on('policyCreated', (e, val) => this.$close(val));
    this.$on('policyEditorCanceled', () => this.$dismiss());
  }
}

export default angular
  .module('app.policyEditorModal', [])
  .directive('policyEditorModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./policy-editor.html'),
    controller: PolicyEditorModalController,
    controllerAs: 'vm'
  })).name;
