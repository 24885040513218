import angular, { Controller, Inject } from '@angular';

@Controller
class SignupView {
  submitted: boolean = false;
  user: any = { name: '', email: '', password: '' };
  errors: any = {};

  @Inject $state;
  @Inject $auth;

  async register(form) {
    this.submitted = true;

    if (!form.$valid) {
      return;
    }

    try {
      await this.$auth.createUser({
        name: this.user.name,
        email: this.user.email,
        password: this.user.password
      });

      this.$state.go('main');
    } catch (err) {
      err = err.data;
      this.errors = {};

      // Update validity of form fields that match the sequelize errors
      if (err.name) {
        angular.forEach(err.fields, field => {
          form[field].$setValidity('mongoose', false);
          this.errors[field] = err.message;
        });
      }
    }
  }
}

export default SignupView;
