import { Store } from './store';

/**
 * ...
 */
class StoreInstanceWrapper {
  private store: Store<unknown> | null = null;

  /**
   * ...
   *
   * @return ...
   */
  get() {
    return this.store;
  }

  /**
   * ...
   *
   * @param store ...
   */
  set(store: Store<unknown>) {
    this.store = store;
  }
}

/**
 * ...
 */
export const storeInstance = new StoreInstanceWrapper();
