'use strict';

import angular from 'angular';

import Block from './factories/Block/Block.service';
import ToolEditorConnectors from './factories/ToolEditorConnectors/ToolEditorConnectors.service';
import toolManagement from './services/tool-management/tool-management.service';
import convertLegacyTool from './services/convert-legacy-tool/convert-legacy-tool.service';
import generateReportTemplate from './services/generate-report-template/generate-report-template.service';
import toolEditor from './directives/tool-editor/tool-editor.directive';
import toolEditorNode from './directives/tool-editor-node/tool-editor-node.directive';
import toolEditorNodeOptions from './directives/tool-editor-node-options/tool-editor-node-options.directive';
import toolEditorShape from './directives/tool-editor-shape/tool-editor-shape.directive';
import connectionOptionsFilter from './filters/connection-options-filter/connection-options-filter.filter';

export default angular.module('app.$toolEditor', [
  Block,
  ToolEditorConnectors,
  toolManagement,
  convertLegacyTool,
  generateReportTemplate,
  toolEditor,
  toolEditorNode,
  toolEditorNodeOptions,
  toolEditorShape,
  connectionOptionsFilter
]).name;
