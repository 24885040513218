import angular from 'angular';

import { PrototypeProviderOptions } from '@modules/angular-prototype';

/**
 * Quickly summon a modal.
 * EX: $modal('create/institution', $me.id)
 *
 * @param modalPath ...
 * @param args ...
 * @return ...
 */
type OpenModalMethod = (modalPath: string, ...args: unknown[]) => unknown;

/**
 * ...
 *
 * @param stateName ...
 */
type GoToMethod = (stateName: string) => void;

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
type ToggleMethod = (value: unknown) => boolean;

/**
 * ...
 *
 * @param ref ...
 * @param arr ...
 * @return ...
 */
type SetArrayItemActiveMethod = (ref: unknown, arr: unknown[]) => void;

/**
 * ...
 *
 * @param elementId ...
 * @return ...
 */
type ScrollToElementMethod = (elementId: string) => void;

/**
 * ...
 *
 * @param name ...
 * @param listener ...
 */
type OnceMethod = (
  name: string,
  listener: (event: angular.IAngularEvent, ...args: unknown[]) => void
) => void;

declare module 'angular' {
  interface IRootScopeService {
    $env: typeof process.env;
    $modal: OpenModalMethod;
    $goTo: GoToMethod;
    $toggle: ToggleMethod;
    $setArrayItemActive: SetArrayItemActiveMethod;
    $scrollToElement: ScrollToElementMethod;
    $once: OnceMethod;
  }
}

function setup(
  $rootScope: angular.IRootScopeService,
  $state: angular.ui.IStateService,
  $store: angular.gears.IStoreService,
  $modals: angular.gears.IModalsService,
  $notify: angular.gears.INotifyService
) {
  'ngInject';

  const $modal: OpenModalMethod = function (modalPath, ...args) {
    if (typeof modalPath !== 'string') {
      throw new Error(`[$modal] provided modal path was not a string.`);
    }

    let [type, modal] = modalPath.split('/');

    if (!type || !modal) {
      throw new Error(
        `[$modal] provided modal path "${modalPath}" was not formated correctly.`
      );
    } else if (!$modals[type] || !$modals[type][modal]) {
      throw new Error(`[$modal] modal "${modalPath}" does not exsist.`);
    }

    return $modals[type][modal](...args);
  };

  const $goTo: GoToMethod = function (stateName) {
    $state.go(stateName);
  };

  const $toggle: ToggleMethod = function (item: unknown) {
    return !item;
  };

  const $setArrayItemActive: SetArrayItemActiveMethod = function (ref, arr) {
    arr.forEach((item) => {
      item.active = item.id === ref.id;
    });
  };

  const $scrollToElement: ScrollToElementMethod = function (elemId) {
    // scrolls to given element on the document
    let elem = document.getElementById(elemId);
    if (elem) elem.scrollIntoView({ behavior: 'smooth' });
  };

  const $once: OnceMethod = function (this: angular.IScope, name, listener) {
    var deregister = this.$on(name, (event, ...args) => {
      listener(event, ...args);
      deregister();
    });
  };

  if (process.env.NODE_ENV === 'development') {
    // Shortcut for toggling dark mode (SHIFT + D)
    document.addEventListener('keydown', ({ shiftKey, key }) => {
      if (!shiftKey || key !== 'D') {
        return;
      }

      const theme =
        $store.state.theme === 'light'
          ? 'dark'
          : $store.state.theme === 'dark'
          ? 'auto'
          : 'light';

      $notify.success(`Changed apperance mode to "${theme}".`);

      $store.commit('SET_THEME', { theme });

      $rootScope.$apply();
    });
  }

  const globals = {
    $env: process.env,
    $modal,
    $goTo,
    $toggle,
    $log: console.log,
    $setArrayItemActive,
    $scrollToElement,
    $once
  };

  const factories = {};

  const getters = {
    theme: () => $store.state.theme,
    dark: () => $store.state.theme === 'dark',
    main: () => $store.state.onMain,
    $state: () => $store.state,
    $rootState: () => $store.state,
    $rootGetters: () => $store.getters,
    $me: () => $store.state.me
  };

  return { globals, factories, getters } as PrototypeProviderOptions;
}

export default function appProto(
  $prototypeProvider: angular.gears.IPrototypeProvider
) {
  'ngInject';

  $prototypeProvider.set(setup);
}
