import angular from 'angular';

import { Controller, Inject } from '@decorators/ngCtrl';

@Controller
export class Modal {
  $modal: Record<string, unknown> = {};
  $title: string = '';
  $dismissable: boolean = false;
  $maximisable: boolean = false;
  $props: Record<string, unknown> = {};
  $forms: Record<string, angular.IFormController> = {};

  loadingData: boolean = false;
  submitting: boolean = false;

  @Inject readonly $scope!: angular.IScope;
  @Inject readonly $element!: angular.IAugmentedJQuery;
  @Inject readonly utils!: angular.gears.IUtilsService;

  submit?(form: angular.IFormController): Promise<void>;

  $onInit() {
    const { $resolve } = this.$scope;

    if (!$resolve) return;

    this.$modal = this.$scope.$resolve;
    this.$title = this.$scope.$title = $resolve.title;
    this.$dismissable = this.$scope.$dismissable = $resolve.dismissable;
    this.$maximisable = this.$scope.$maximisable = $resolve.maximisable;
    this.$props = this.$scope.$props = $resolve.props;

    let maximised: boolean = false;

    const modalDialogEl = this.$element.closest('.modal-dialog');

    this.$scope.$watch(
      () => maximised,
      (val) => {
        if (val) {
          modalDialogEl.addClass('modal-maximised');
        } else {
          modalDialogEl.removeClass('modal-maximised');
        }
      }
    );

    Object.defineProperties(this.$scope, {
      $forms: {
        enumerable: true,
        get: () => this.$forms
      },
      // $submitForm: {
      //   enumerable: true,
      //   value: formName => {
      //     this.$scope.$broadcast('submitModalForm', formName);
      //   }
      // },
      $maximised: {
        enumerable: true,
        get: () => maximised
      },
      $maximize: {
        enumerable: true,
        value: () => (maximised = true)
      },
      $minimize: {
        enumerable: true,
        value: () => (maximised = false)
      }
    });

    try {
      this.$setup();
    } catch (err) {
      console.error(
        '[mixin:Modal] An error occurred while setting up the modal.',
        err
      );

      setTimeout(() => {
        this.$dismiss();
        this.utils.notify('error', {
          title: 'There was an issue loading the modal.',
          message: err.message
        });
      }, 1000);

      return;
    }
  }

  $maximize(value?: unknown) {
    return this.$scope.$maximize(value);
  }

  $minimize(value?: unknown) {
    return this.$scope.$minimize(value);
  }

  $dismiss(value?: unknown) {
    return this.$scope.$dismiss(value);
  }

  $close(value?: unknown) {
    return this.$scope.$close(value);
  }

  // Override this method to use for instantiation.
  $setup() {}

  // $submit
}

export default Modal;

export function Submit(
  target: Modal,
  _prop: string,
  descriptor: PropertyDescriptor
) {
  target.submit = async function submit(form: angular.IFormController) {
    if (!form || !form.$valid) {
      console.warn('[Modal] The submitted form is not valid.', form);

      return;
    }

    this.submitting = true;

    try {
      // await this[prop]();
      await descriptor.value();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('[Modal] An error occurred during submission.', err);
    } finally {
      this.submitting = false;
    }
  };
}
