import angular from 'angular';
import _ from 'lodash';

// import { Policy } from '@interfaces';
import { ActionTree, MutationTree, GetterTree } from '@modules/angular-store';
import { RootState } from '@store/state';
import { Policy, TableProps } from '@/types.flow';

export interface PoliciesState {
  loading: false;
  items: Policy[];
  tableProps: TableProps;
}

export default function PoliciesStore(
  $api: angular.gears.IApiService,
  $injector: angular.auto.IInjectorService
) {
  'ngInject';

  const state: PoliciesState = {
    loading: false,
    items: [],
    tableProps: {
      sortedCol: 0,
      searchText: ''
    }
  };

  const getters: GetterTree<PoliciesState, RootState> = {
    managed: ({ items }) =>
      items.filter((policy) => 'managedPolicyId' in policy),
    forInstitution:
      ({ items }) =>
      (instId) =>
        items.filter(
          (policy) =>
            'institutionPolicyId' in policy &&
            (!instId || policy.institution.id == instId)
        )
  };

  const actions: ActionTree<PoliciesState, RootState> = {
    async list({ state, commit, rootGetters }, replace) {
      commit('setProps', { loading: true });

      let data = [];

      try {
        let res;

        const $auth = $injector.get<angular.gears.IAuthService>('$auth');

        if ($auth.hasAccess('gearsmanager:ListAllInstitutionPolicies')) {
          // get all clients
          res = await $api.gearsManager.listManagedPolicies();
          data = res.data;
          res = await $api.gearsManager.listAllInstitutionPolicies();
          data = data.concat(res.data);
        } else if ($auth.hasAccess('institutionmanager:ListPolicies')) {
          // get policies for institution

          // TODO:uncomment once Eric add's method for grabbing manged
          // policies from institutionmanager.
          // res = await $api.institutionManager.listManagedPolicies();
          // data = data.concat(res.data);

          res = await $api.institutionManager.listPolicies(
            rootGetters.activeInstId
          );
          data = data.concat(res.data);
        }
      } catch (err) {
        commit('setProps', { loading: false });
        throw err;
      }

      commit('set', data);
      commit('setProps', { loading: false });

      return state.items;
    }
  };

  const mutations: MutationTree<PoliciesState> = {
    set(state, payload) {
      state.items = [...payload];
    },
    add(state, payload) {
      state.items = _.uniqBy([...payload, ...state.items], 'id');
    },
    setProps(state, props: Partial<PoliciesState> = {}) {
      for (const i in props) {
        if (i in state) {
          state[i] = props[i];
        }
      }
    },
    CLEAR(state) {
      Object.assign(state, {
        loading: false,
        items: [],
        tableProps: {
          sortedCol: 0,
          searchText: ''
        }
      });
    }
  };

  return {
    state,
    getters,
    actions,
    mutations
  };
}
