'use strict';

import angular from 'angular';
import { forEach, find, cloneDeep } from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class OffenderHistoryModalController extends Modal {
  @Inject $scope;
  @Inject $http;
  @Inject notify;
  @Inject Util;
  @Inject ItemTable;
  @Inject $api2;

  async $setup() {
    // console.log('this.$props: ', this.$props);
    this.client = this.$props?.client;
    this.tool = this.$props?.tool;
    this.offenderHistory = this.tool?.offenderHistory;
    const ocsList = this.$props?.offenseClassifications;
    // console.log('ocsList: ', ocsList);

    const { $http, notify, Util, ItemTable } = this;

    // const ocsList = await Util.getOffenseClassifications();

    // if section has a table type, make table and return data here
    let createSectionTable = function (section) {
      switch (section.table) {
        case 'offenseClassifications':
          return new ItemTable({
            title: section.title,
            items: [],
            key: section.key,
            hasSearchBar: true,
            itemsPerPage: 5,
            editable: true,
            hideTitle: true
          })
            .column({
              title: 'Offense',
              key: 'offense',
              type: 'enum',
              options: ocsList
            })
            .column({
              title: 'Number of Counts',
              key: 'numberOfCounts',
              type: 'text'
            })
            .column({
              title: 'Date of Conviction',
              key: 'dateOfConviction',
              type: 'date'
            })
            .column({
              title: 'Disposition (If Applicable)',
              key: 'disposition',
              type: 'text'
            })
            .column({
              title: 'Institution (If Applicable)',
              key: 'institution',
              type: 'text'
            })
            .column({
              title: 'Comments',
              key: 'comments',
              type: 'text'
            });
      }
    };

    // parse offender history to see if defaultValues need to be set
    let checkDefaultValues = function (section, client) {
      // handle table
      if (
        section.table &&
        typeof section.table === 'string' &&
        section.table.length
      ) {
        section.table = createSectionTable(section);
      } else if (section.table && typeof section.table === 'object') {
        section.table = new ItemTable(section.table);
      }

      // console.log('section: ', section);

      // handle fields
      forEach(section.fields, (field) => {
        if (field.defaultData) {
          let valueMap = field.defaultData.split('.');
          if (valueMap[0] === 'client') {
            field.model = client[valueMap[1]];
          }
        }
      });

      // handle model already selected
      if (section.model && section.model.key) {
        if (find(section.fields, { key: section.model.key })) {
          section.model = find(section.fields, { key: section.model.key });
        }
      }

      // somehow the model got set to a string instead of the field object
      // assuming section.model is the Label of the Field found in this section
      if (section.radio && typeof section.model === 'string') {
        section.model = find(section.fields, { label: section.model });
      }

      // if section has child sections, call function again
      if (section.sections && section.sections.length) {
        forEach(section.sections, (sec) => {
          checkDefaultValues(sec, client);
        });
      }
    };

    forEach(this.offenderHistory.sections, (section, index) => {
      section.number = Util.romanize(index + 1);
      checkDefaultValues(section, this.client);
      // all expanded by default so user can see sub-required fields
      section.collapsed = false;
    });

    this.submitOffenderHistory = async function () {
      let historyData = cloneDeep(this.offenderHistory);

      // Remove Offense Classifications List (superfluous data)
      const checkSectionForOffenseTable = (section) => {
        if (section.table && find(section.table.cols, { key: 'offense' }))
          find(section.table.cols, { key: 'offense' }).options = [];

        if (section?.sections?.length)
          forEach(section.sections, (s) => checkSectionForOffenseTable(s));
      };
      forEach(historyData.sections, (section) => {
        if (section?.sections?.length)
          forEach(section.sections, (s) => checkSectionForOffenseTable(s));
      });

      let response;
      if (this.offenderHistory.id) {
        try {
          response = await this.$api2.cm.updateOffenderHistory({
            clientId: this.client.id,
            institutionId: this.client.institutionId,
            subGroupId: this.client.subGroup.id,
            historyId: this.offenderHistory.id,
            toolId: this.tool.id.toString(),
            historyData
          });
        } catch (err) {
          notify.display(err, 'error');
          return;
        }
      } else {
        try {
          response = await this.$api2.cm.createOffenderHistory({
            clientId: this.client.id,
            institutionId: this.client.institutionId,
            subGroupId: this.client.subGroup.id,
            toolId: this.tool.id.toString(),
            historyData
          });
        } catch (err) {
          notify.display(err, 'error');
          return;
        }
      }

      let returnPayload;
      if (!this.offenderHistory.id) {
        returnPayload = {
          id: response.id,
          toolId: response.toolId,
          updatedAt: response.updatedAt,
          createdAt: response.createdAt,
          toolName: this.tool.name,
          offenderHistory: historyData
        };
      } else if (this.offenderHistory.id) {
        returnPayload = {
          updatedAt: new Date(),
          offenderHistory: historyData
        };
      }
      notify.display('Successfully Submitted Offender History', 'success');
      this.$close(returnPayload);
    };
  }
}

export default angular
  .module('app.offender-history-modal', [])
  // .controller('OffenderHistoryModalController', OffenderHistoryModalController)
  .directive('offenderHistoryModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./offender-history.html'),
    controller: OffenderHistoryModalController,
    controllerAs: 'vm'
  }))
  .directive('section', () => {
    return {
      template: require('./section.html'),
      restrict: 'E',
      scope: {
        section: '=',
        offenderHistory: '='
      },
      controller: [
        '$scope',
        function ($scope) {
          let vm = this;
          let section = $scope.section;
          let offenderHistory = $scope.offenderHistory;

          this.log = function (item) {
            console.log(item);
          };

          this.checkSectionConditions = function () {
            // check if conditions exist and if they are satisfied return false. otherwise return true to hide
            if (!section.conditions || !section.conditions.length) return;
            let hide = true;
            forEach(section.conditions, (condition) => {
              let address = condition.address.split('.');
              let scopedObject = offenderHistory;
              let foundScopedObject = false;
              forEach(address, (key, index) => {
                // this is the final key to assess the value against condition.value
                if (index === address.length - 1) {
                  if (scopedObject[key] === condition.value) hide = false;
                }
                // find the next scope down if it exists
                else {
                  foundScopedObject = false;
                  if (find(scopedObject.sections, { key })) {
                    scopedObject = find(scopedObject.sections, { key });
                    foundScopedObject = true;
                  }
                  if (
                    !foundScopedObject &&
                    find(scopedObject.fields, { key })
                  ) {
                    scopedObject = find(scopedObject.fields, { key });
                    foundScopedObject = true;
                  }
                  if (!foundScopedObject)
                    console.log('Could not find scoped object!');
                }
              });
            });
            return hide;
          };
        }
      ],
      controllerAs: 'vm'
    };
  })
  .directive('field', () => {
    return {
      template: require('./field.html'),
      restrict: 'E',
      scope: {
        field: '='
      }
    };
  }).name;
