import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';

import Modal from '@/mixins/modal.mixin';

@Controller
class GenericPopupModalController extends Modal {}

export default angular
  .module('app.genericPopupModal', [])
  .directive('genericPopupModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./generic-popup-modal.html'),
    controller: GenericPopupModalController,
    controllerAs: 'vm'
  })).name;
