'use strict';

import angular from 'angular';

class ChecKboxListItemController {
  get isSublist() {
    return Array.isArray(this.listItem.value);
  }
}

export default angular
  .module('app.component.checkboxListItem', [])
  .component('checkboxListItem', {
    template: require('./checkbox-list-item.html'),
    bindings: {
      listItem: '='
    },
    controller: ChecKboxListItemController,
    controllerAs: 'vm'
  }).name;
