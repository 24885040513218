'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class CreateManagedPolicyModalController extends Modal {
  permissions: {}[] = [];
  services: {}[] = [];

  @Inject $scope;

  $setup() {
    'ngInject';

    // Initiation

    this.$scope.services = _services;

    this.services = Object.keys(_services).map(key => {
      return {
        label: _services[key].name,
        value: _services[key].name
        // actions: SERVICES[key].actions
      };
    });

    this.add();
  }

  add() {
    this.permissions.push(new UserPolicy());
  }

  copy(permission) {
    this.permissions.push(angular.copy(permission));
  }

  delete(permission) {
    this.permissions.splice(this.permissions.indexOf(permission), 1);
  }

  // Submit/Create new policy
  async create() {}
}

export default angular
  .module('app.createManagedPolicyModal', [])
  .directive('createManagedPolicyModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./create-managed-policy.html'),
    controller: CreateManagedPolicyModalController,
    controllerAs: 'vm'
  })).name;

class UserPolicy {
  serviceKey = '';
  selectedService = null;

  listActions = [];
  readActions = [];
  writeActions = [];
  resources = [];

  selectedActions = [];

  includeAllResources = false;
  selectedResources = [];

  setService(value) {
    this.selectedService = services.find(item => item.name == this.serviceKey);

    if (!this.selectedService) {
      this.service.value;
      this.listActions = [];
      this.readActions = [];
      this.writeActions = [];

      return;
    }

    this.listActions = this.selectedService.listActions.map(item => {
      return {
        label: item,
        value: false
      };
    });

    this.readActions = this.selectedService.readActions.map(item => {
      return {
        label: item,
        value: false
      };
    });

    this.writeActions = this.selectedService.writeActions.map(item => {
      return {
        label: item,
        value: false
      };
    });
  }

  selectAll(listName) {
    let list = this[`${listName}Actions`];

    if (!list) {
      return;
    }

    list.forEach(item => {
      item.value = true;
    });
  }

  selectNone(listName) {
    let list = this[`${listName}Actions`];

    if (!list) {
      return;
    }

    list.forEach(item => {
      item.value = false;
    });
  }

  mangeActions(action) {
    if (action.value) {
      this.selectedActions.push(action);
    } else {
      this.selectedActions.splice(this.selectedActions.indexOf(action), 1);
    }

    this.resources = this.selectedService.resources.map(item => {
      return {
        type: item,
        list: []
      };
    });
  }

  addResource(resources) {}
}

function createActionsArray(actions) {
  return actions.map(
    action => `${action.route.split('/')[0]}.${action.action}`
  );
}

var _services = [
  {
    name: 'ToolCreator',
    resources: ['tool'],
    listActions: [
      'ListTools',
      'ListLiveTools',
      'ListToolBoxes',
      'ListToolCommits',
      'ListToolPdfLookups'
    ],
    readActions: [
      // Tool
      'GetTool',
      'GetCommits',
      'GetToolPdfs',
      'GetDescriptionPdfs',
      'GetCodingForms',
      'GetTemplate',
      'GetTemplates',
      // ToolBox
      'GetToolBox',
      // ToolCommit
      'GetToolCommit',
      'GetData',
      'GetEditorData',
      // ToolPdf
      'GetToolPdf',
      // ToolPdfLookup
      'GetToolPdfLookup'
    ],
    writeActions: [
      'CreateTool',
      'DeleteTool',
      'CreateCommit',
      'CreateToolPdf',
      'CreateDescriptionPdf',
      'CreateChild',
      'DeleteChild',
      'CreatePrice',
      // ToolBox
      'CreateToolBox',
      'PutToolBox',
      'UpdateToolBox',
      'DeleteToolBox',
      // ToolCommit
      'CreateTag',
      'CreateStatus',
      'SetLiveToolPdf',
      'SetLiveDescriptionPdf',
      'SetLiveCodingForm',
      'CreateToolPdf',
      'CreateDescriptionPdf',
      'CreateCodingForm',
      // ToolPdfLookup
      'CreateToolPdfLookup',
      'PutToolPdfLookup',
      'UpdateToolPdfLookup',
      'DeleteToolPdfLookup'
    ]
  }
];
