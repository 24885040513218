'use strict';

import angular from 'angular';

class ListItemComponent {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }
}

export default angular
  .module('app.listItemToggle', [])
  .directive('listItemToggle', () => {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        label: '<',
        model: '=',
        icon: '<'
      },
      template: require('./list-item-toggle.html')
    };
  }).name;
