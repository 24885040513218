import { gears } from 'angular';
import { find } from 'lodash';
import { GetterTree, ActionTree, MutationTree } from 'angular-store';

import {
  CreateReminderOptions,
  UpdateReminderOptions,
  UpdateReminderAlertsOptions,
  UpdateReminderDueDateOptions,
  ToggleEmailReminderOptions,
  DismissReminderOptions,
  CompleteReminderOptions,
  DeleteReminderOptions
} from '@api/modules/user';
import { TableState } from '@interfaces';
import { Reminder } from '@interfaces/reminder';
import { ReminderModel } from '@models/reminder.model';
import { RootState } from '@store/state';

/**
 * ...
 */
export interface RemindersState {
  items: ReminderModel[];
  table: TableState;
}

/** ... */
export type CreateReminderActionOptions = CreateReminderOptions;
/** ... */
export type UpdateReminderActionOptions = UpdateReminderOptions &
  UpdateReminderAlertsOptions &
  UpdateReminderDueDateOptions &
  ToggleEmailReminderOptions;
/** ... */
export type DismissReminderActionOptions = DismissReminderOptions;
/** ... */
export type CheckReminderActionOptions = CompleteReminderOptions;
/** ... */
export type DeleteReminderActionOptions = DeleteReminderOptions;
/** ... */
export type SetRemindersMutationOptions = Reminder[];
/** ... */
export type AddRemindersMutationOptions = Reminder[];
/** ... */
export type RemoveReminderMutationOptions = string[];

export function RemindersStore($api2: gears.IAPI2Service) {
  'ngInject';

  let isLoading = false;
  let $items: Record<string, ReminderModel> = {};

  const state: RemindersState = {
    items: [],
    table: { sortedCol: 0, searchText: '' }
  };

  const getters: GetterTree<RemindersState, RootState> = {
    isLoading: () => isLoading,
    find: (state) => (id: string) => state.items.find((item) => item.id == id)
  };

  const actions: ActionTree<RemindersState, RootState> = {
    async list({ state, commit }) {
      let data: Reminder[] = [];
      let error: Error | null = null;

      isLoading = true;

      try {
        data = await $api2.user.listReminders();
      } catch (err) {
        error = err;
      }

      isLoading = false;

      if (error) {
        throw error;
      }

      commit('SET', data);

      return state.items;
    },
    async create({ commit }, options: CreateReminderActionOptions) {
      // const data = await $api2.user.createReminder(options);
      //
      // commit('ADD', [data]);

      // TEMP: Eric returns an odd response from this. For now, list all
      // reminders to get the newly created one.
      await $api2.user.createReminder(options);
      const data = await $api2.user.listReminders();

      commit('ADD', data);

      return data;
    },
    async update({ commit }, options: UpdateReminderActionOptions) {
      const reminder = $items[options.reminderId];

      const data = { ...reminder };

      if (
        options.type !== reminder.type ||
        options.data.clientId !== reminder.data.clientId ||
        options.data.toolId !== reminder.data.toolId
      ) {
        const props = await $api2.user.updateReminder(options);

        data.type = props.type;
        data.data = props.data;
      }

      if (options.sendEmail !== reminder.sendEmail) {
        const props = await $api2.user.toggleEmailReminder({
          reminderId: options.reminderId,
          sendEmail: options.sendEmail
        });

        data.sendEmail = props.sendEmail;
      }

      let oldDate = reminder.dueDate;
      let newDate = new Date(options.dueDate);

      if (oldDate?.toString() !== newDate.toString()) {
        const props = await $api2.user.updateReminderDueDate({
          reminderId: options.reminderId,
          dueDate: options.dueDate
        });

        data.dueDate = props.dueDate;
      }

      if (options.alerts !== reminder.alerts) {
        const props = await $api2.user.updateReminderAlerts({
          reminderId: options.reminderId,
          alerts: options.alerts
        });

        data.alerts = props.alerts;
      }

      // TEMP: Again, Eric's reponse is wierd. List reminders to get the updated
      // reminder until fixed.

      // commit('ADD', [data]);

      const reminders = await $api2.user.listReminders();
      commit('ADD', reminders);

      return data;
    },
    async dismiss({ state, commit }, options: DismissReminderActionOptions) {
      const reminder = $items[options.reminderId];

      if (!reminder) {
        throw new Error(
          `Reminder with ID "${options.reminderId}" does not exist.`
        );
      }

      // let data = await $api2.user.dismissReminder(options);
      //
      // commit('ADD', [data]);

      await $api2.user.dismissReminder(options);
      const reminders = await $api2.user.listReminders();

      commit('ADD', reminders);

      return find(state.items, { id: options.reminderId })!;
    },
    async check({ commit }, options: CheckReminderActionOptions) {
      const reminder = $items[options.reminderId];

      if (!reminder) {
        throw new Error(
          `Reminder with ID "${options.reminderId}" does not exist.`
        );
      }

      // let data = await $api2.user.completeReminder(options);
      //
      // commit('ADD', [{ ...reminder, ...data }]);

      await $api2.user.completeReminder(options);
      const reminders = await $api2.user.listReminders();

      commit('ADD', reminders);

      return find(state.items, { id: options.reminderId })!;
    },
    async del({ commit }, options: DeleteReminderActionOptions) {
      await $api2.user.deleteReminder(options);

      commit('REMOVE', [options.reminderId]);
    }
  };

  const mutations: MutationTree<RemindersState> = {
    SET(state, options: SetRemindersMutationOptions) {
      $items = {};

      for (let item of options) {
        $items[item.id] = new ReminderModel(item);
      }

      state.items = Object.values($items);
    },
    ADD(state, options: AddRemindersMutationOptions) {
      for (let item of options) {
        $items[item.id] = new ReminderModel(item);
      }

      state.items = Object.values($items);
    },
    REMOVE(state, options: RemoveReminderMutationOptions) {
      for (let item of options) {
        delete $items[item];
      }

      state.items = Object.values($items);
    },
    CLEAR(state) {
      Object.assign(state, {
        items: [],
        table: { sortedCol: 0, searchText: '' }
      });
    }
  };

  // TEMP: remove once all references to them have been removed.
  Object.defineProperties(mutations, {
    set: { enumerable: true, value: mutations.SET },
    add: { enumerable: true, value: mutations.ADD },
    remove: { enumerable: true, value: mutations.REMOVE }
  });

  return { state, getters, actions, mutations };
}

export default RemindersStore;
