import { NgClass } from './types';

/**
 * angularJS Ref property decorator.
 *
 * @return Ref decorator descriptor.
 */
export function Ref(_: NgClass, prop: string): any {
  return {
    enumerable: true,
    get() {
      return this.$scope[prop];
    }
  } as PropertyDescriptor;
}
