'use strict';

import angular from 'angular';

class SelectorComponentController {
  selectedOption = {};
  customValue;

  constructor() {
    this.options = [{
      key: '-- Choose an option --',
      value: 'NO_VALUE'
    }].concat(this.component.options);

    this.selectedOption = this.options[0];
  }

  get selection() {
    return this.selectedOption.key;
  }

  set selection(value) {
    this.selectedOption = this.options.find(item => item.key === value);
    this.component.value.selection = this.selectedOption.value === 'NO_VALUE' ?
      undefined :
      this.selectedOption.value;
  }

  get selectedOptionValue() {
    return this.selectedOption.value;
  }

  set selectedOptionValue(value) {
    this.selectedOption.value = this.component.value.selection = value;
    this.component.value.selection = this.selectedOption.value;
  }

  get explanation() {
    return this.component.value.explanation;
  }

  set explanation(value) {
    this.component.value.explanation = value;
  }
}

export default angular.module('app.component.selectorComponent', [])
  .component('selectorComponent', {
    template: require('./selector.html'),
    bindings: {
      component: '='
    },
    controller: SelectorComponentController,
    controllerAs: 'vm'
  })
  .name;