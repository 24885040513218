'use strict';

import { State, Getter } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardEvaluationsItemView {
  tableProps: {}[] = [];
  tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';
  evaluation: {} = {};

  @Inject $scope;
  @Inject $stateParams;
  @Inject $auth;

  @State evaluations;
  @Getter('evaluations/getById') getById;

  get evalData() {
    return this.evaluation.evaluation.data;
  }

  get evalTool() {
    return this.evaluation.tool || {};
  }

  $onInit() {
    var $vm = this;
    var $checkAuth = this.$auth.hasAccess;

    console.log(this.$stateParams.evalId);

    let evalId = this.$stateParams.evalId;
    if (!!evalId) {
      this.evaluation = this.getById(evalId);
    }

    console.log('EVAL', this.evaluation);

    this.tableItems = this.evalTool.codingFormItems || [];

    this.tableProps = [
      {
        label: 'Question',
        value: 'riskFactor'
      },
      {
        label: 'Answer',
        value: row => {
          let qst = $vm.evalData[row.id];

          return !!qst && qst.aid != '-'
            ? qst.text
            : '<i>Question Unanswered</i>';
        }
      },
      {
        label: 'Wizard',
        value: row => {
          let qst = $vm.evalData[row.id];

          if (!qst.wizard) {
            return '<i>No Wizard Used</i>';
          }

          let wizardAnswers = '';

          qst.wizard.answers.forEach(item => {
            wizardAnswers += `
              <tr>
                <td>
                  ${item.map(wa => `<span>${wa.questionText}</span>`).join('')}
                </td>
                <td>
                 ${item
                   .map(
                     wa => `
                   <span>
                    ${wa.answerText
                      .map(answer => `<span>${answer}</span>`)
                      .join('')}
                   </span>
                 `
                   )
                   .join('')}
                </td>
              </tr>
            `;
          });

          return `
            <div>
              <table class="borders">
                <tr>
                  <th>Question</th>
                  <th>Answer</th>
                </tr>
                ${wizardAnswers}
              </table>
            </div>
          `;
        }
        // hide: () => !$checkAuth('ListInstitutions')
      },
      {
        label: 'Score',
        value: row => $vm.evalData[row.id].score
      }
    ];

    this.tableActions = [];
  }
}

export default DashboardEvaluationsItemView;
