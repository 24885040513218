'use strict';

import angular from 'angular';
import pdfObject from 'pdfobject';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class OpenPdfModalController extends Modal {
  isOpera: any;
  isFirefox: any;
  isSafari: any;
  isIE: any;
  isEdge: any;
  isChrome: any;
  isBlink: any;
  loading: boolean;
  page: any;
  pdf: any;
  trustedPdf: any;
  newPdf: any;

  @Inject $scope;
  @Inject $sce;

  $setup() {
    // browser detection
    // Opera 8.0+
    this.isOpera =
      (!!window.opr && !!window.opr.addons) ||
      !!window.opera ||
      navigator.userAgent.indexOf(' OPR/') >= 0;
    // Firefox 1.0+
    this.isFirefox = typeof InstallTrigger !== 'undefined';
    // Safari 3.0+ "[object HTMLElementConstructor]"
    this.isSafari =
      /constructor/i.test(window.HTMLElement) ||
      (function(p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(!window['safari'] || window.safari.pushNotification);
    // Internet Explorer 6-11
    this.isIE = /*@cc_on!@*/ false || !!document.documentMode;
    // Edge 20+
    this.isEdge = !this.isIE && !!window.StyleMedia;
    // Chrome 1+
    this.isChrome = !!window.chrome && !!window.chrome.webstore;
    // Blink engine detection
    this.isBlink = (this.isChrome || this.isOpera) && !!window.CSS;

    this.loading = true;
    this.page = this.$props.page;
    this.pdf = this.$props.pdf;

    var file = new Blob([this.pdf], {
      type: 'application/pdf'
    });

    var fileUrl = URL.createObjectURL(file);
    this.trustedPdf = this.$sce.trustAsResourceUrl(fileUrl);
    this.newPdf = fileUrl;

    var options = {
      pdfOpenParams: {
        view: 'FitV',
        page: this.page,
        pagemode: 'none',
        toolbar: 0
      },
      PDFJS_URL: 'assets/components/pdfjs/build/generic/web/viewer.html'
    };

    if (this.isIE || this.isEdge || this.isSafari) options.forcePDFJS = true;

    pdfObject.embed(this.newPdf, '#pdfobject-container', options);
    this.loading = false;
  }
}

export default angular
  .module('app.openPdfModal', [])
  .directive('openPdfModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./open-pdf.html'),
    controller: OpenPdfModalController,
    controllerAs: 'vm'
  })).name;
