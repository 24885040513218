'use strict';

import angular from 'angular';

class InputSearchBarController {
  get value() {
    return this.model.$viewValue;
  }

  set value(value) {
    this.model.$setViewValue(value);
  }
}

export default angular
  .module('app.inputSearchBar', [])
  .component('inputSearchBar', {
    template: require('./input-search-bar.html'),
    require: { model: 'ngModel' },
    controller: InputSearchBarController,
    controllerAs: 'vm'
  }).name;
