'use strict';

import { Controller, Inject, On } from '@/decorators/ngCtrl';
import { Getter } from 'angular-store';

@Controller
class DashboardCreatePolicyView {
  policy: Policy = null;
  processing = false;

  @Inject $scope;
  @Inject $store;
  @Inject $state;
  @Inject $api;
  @Inject utils;

  @Getter isAdmin;

  @On('policyCreated') create;
  @On('policyEditorCanceled') canceled;

  $onInit() {}

  async create() {
    this.processing = true;
    try {
      let res;
      if (this.policy.institutionId) {
        res = await this.$api.institutionManager.createPolicy(
          this.policy.institutionId,
          this.policy
        );
      } else {
        res = await this.$api.gearsManager.createManagedPolicy(this.policy);
      }
    } catch (err) {
      this.processing = false;
      console.error(err);

      return;
    }
    this.processing = false;

    this.utils.notify('success', 'Policy created.');

    this.$store.dispatch('policies/list');

    this.$state.go('dashboardPolicies');
  }

  async canceled() {
    this.$store.dispatch('policies/list');
    this.$state.go('dashboardPolicies');
  }
}

export default DashboardCreatePolicyView;
