import angualr from 'angular';
import * as _ from 'lodash';
import { State, Getter, Action } from 'angular-store';

import { Evaluation } from '@interfaces/evaluation';
import { Institution } from '@interfaces/institution';
import { Controller, Inject, On } from '@decorators/ngCtrl';
import { RootModules } from '@store/state';

// type EvalStatus = 0 | 1 | 2;

enum EvaluationState {
  NotStarted = 'NOT_STARTED',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED'
}

/** prohibits editing of YLS and LS suite of tools. */
const prohibitEdit = [
  { id: 4, name: 'LSI-R: SV' },
  { id: 105, name: 'YLS/CMI:SRV' },
  { id: 120, name: 'YLS/CMI 2.0' },
  { id: 124, name: 'LS/CMI' },
  { id: 157, name: 'LSI-R' },
  { id: 188, name: 'ACE Questionnaire' }
];

@Controller
class DashboardEvaluationsView {
  tableProps: unknown[] = [];
  tableItems: Evaluation[] = [];
  tableActions: unknown[] = [];
  searchText: string = '';
  client: unknown = null;
  loadingInstitutions: boolean = false;

  @Inject readonly $scope!: angualr.IScope;
  @Inject readonly $rootScope!: angualr.IRootScopeService;
  @Inject readonly $location!: angualr.ILocationService;
  @Inject readonly $state!: angualr.ui.IStateService;
  @Inject readonly $store!: angualr.gears.IStoreService;
  @Inject readonly $modals!: angualr.gears.IModalsService;
  @Inject readonly $api!: angualr.gears.IApiService;
  @Inject readonly $auth!: angualr.gears.IAuthService;
  @Inject readonly evlUtils!: angualr.gears.IEvalUtilsService;
  @Inject readonly reportUtils!: angualr.gears.IReportUtilsService;
  @Inject readonly dataModals!: angualr.gears.IDataModalsService;
  @Inject readonly notify!: angualr.gears.INotifyService;
  @Inject readonly $filter!: angular.IFilterService;

  @State readonly evaluations!: unknown;
  @State readonly institutions!: RootModules['institutions'];
  @State readonly clients!: RootModules['clients'];
  @State readonly me!: RootModules['me'];
  @State showIdInActions;
  @Getter('clients/getById') readonly getClient!: unknown;
  @Getter('evaluations/getById') readonly getById!: unknown;
  @Getter('evaluations/getByClientId') readonly getClientEvals!: unknown;
  @Getter isAdmin;
  @Action('evaluations/getForInstitution')
  readonly getInstitutionEvals!: unknown;
  @Action('evaluations/getAll') readonly getAllEvals!: unknown;
  @Action('evaluations/list') readonly listEvaluations!: unknown;
  @Action('institutions/getAll') readonly listInstitutions!: unknown;
  @Action('clients/list') readonly listClients!: unknown;

  @On('evaluationsSet')
  onEvaluationSet() {
    return this.tableSetup();
  }

  @On('selectedInstitution')
  async onSelectedInstitutionChanged(institution) {
    await this.getInstitutionEvals(institution);

    this.$scope.$apply();
  }

  get clientId() {
    return ((this.$location.search() as GenericObject<string>)?.clientId ??
      null) as string | null;
  }

  get loadingData() {
    let text = null;

    if (this.evlUtils.loadingTool) {
      text = 'Loading Tool';
    } else if (this.evlUtils.processing) {
      text = 'Processing Evaluation';
    } else if (this.reportUtils.$loading) {
      text = 'Report Loading';
    } else if (this.reportUtils.$generatingPDF) {
      text = 'Generating PDF Report';
    }

    return { loading: !!text, text };
  }

  async $onInit() {
    this.$store.commit('setLoadingMessage', 'Retrieving Evaluations...');
    this.$store.commit('setIsLoading', true);

    // TEMP
    if (!this.isAdmin) await this.listClients();

    void this.tableSetup();

    if (this.clientId) {
      this.$store.commit(
        'clients/SET_FOCUS',
        this.clientId ? this.clientId.toString() : null
      );
    } else if (!this.$auth.hasAccess('GM:ListEvaluations')) {
      // load evaluations for whole institution
      await this.listEvaluations();

      this.tableSetup();
    }

    this.$store.commit('setIsLoading', false);

    // broadcast from deletion method. make sure it's removed from client's evaluations list, too
    this.$rootScope.$on('evaluationDeleted', (res) => {
      this.removeEvaluation(res);
    });

    if (this.isAdmin) {
      this.$scope.$watch(
        'vm.selectedInstitution',
        this.onSelectedInstitutionChanged.bind(this)
      );
    }
  }

  async loadInstitutions() {
    if (!this.institutions?.items?.length) {
      this.loadingInstitutions = true;
      try {
        await this.listInstitutions();
      } catch (err) {
        this.notify.display(err, 'error');
      } finally {
        this.loadingInstitutions = false;
      }
    }
  }

  async reloadEvaluations() {
    this.$store.commit('setLoadingMessage', 'Retrieving Evaluations...');
    this.$store.commit('setIsLoading', true);

    if (this.$auth.hasAccess('institutionmanager:ListEvaluations')) {
      // load evaluations for whole institution
      await this.$store.dispatch('evaluations/getForInstitution');
    } else {
      // load evaluations for all clients
      for (let i = 0; i < this.clients.items.length; i++) {
        await this.$store.dispatch(
          'evaluations/getForClient',
          this.clients.items[i].id
        );
      }
    }

    this.$store.commit('setIsLoading', false);

    this.tableSetup();
  }

  async removeEvaluation(res) {
    if (this.client && this.client.evaluations?.length) {
      _.remove(this.client.evaluations, { id: res.id });
      this.tableItems = await this.getClientEvals(this.client.id);
    }
  }

  async tableSetup() {
    var $vm = this;

    this.tableProps = [
      {
        label: 'Assigned Date',
        value: 'assignedDate',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Completed Date',
        value: (row: any) => {
          if (!row?.updatedAt || row.status !== 'COMPLETED') return 'N/A';
          return row?.updatedAt;
        },
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: this.$filter('clientLabel')('localId', true),
        value: 'client.localId'
      },
      {
        label: 'Client Name',
        value: (row) => {
          if (row?.client?.fullName) return row.client.fullName;
          if (row?.client?.lName && row?.client?.fName)
            return `${row.client.lName}, ${row.client.fName}`;
        }
      },
      {
        label: 'Evaluator',
        value: (row) => {
          if (row?.evaluator?.fullName) return row.evaluator.fullName;
          if (row?.evaluator?.lName && row?.evaluator?.fName)
            return `${row.evaluator.lName}, ${row.evaluator.fName}`;
        }
      },
      {
        label: 'Status',
        value: (row) => {
          let output = '';

          if (row.status === 'IN_PROGRESS') {
            output = `<label class="status-label in-progress">In Progress</label>`;
          } else if (row.status === 'NOT_STARTED') {
            output = `<label class="status-label not-started">Not Started</label>`;
          } else if (row.status === 'COMPLETED') {
            output = `<label class="status-label completed">Completed</label>`;
          }

          return output;
        }
      },
      {
        label: 'Tool',
        value: 'tool.name'
      },
      {
        label: 'Due Date',
        value: 'dueDate',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy'
        }
      }
    ];

    let colOptions = {
      label: 'Options',
      icon: 'cog',
      actions: [
        {
          label: 'View Reports',
          icon: 'fileWord',
          fn: (val) => $vm.reportsOpen(val),
          disabled: (val) => val.status !== 'COMPLETED'
        },
        {
          label: 'View',
          icon: 'eye',
          fn: (val) => $vm.evalView(val, 'evaluations'),
          disabled: (val) => val.status !== 'COMPLETED'
        },
        // GET ==> `/api/---`
        {
          label: 'Edit',
          icon: 'edit',
          fn: (val) => $vm.evalEdit(val),
          disabled: (val) => {
            if (_.find(prohibitEdit, { id: val.toolUsed })) return true;
            return val.status !== 'COMPLETED';
          }
        },
        {
          label: 'Continue',
          icon: 'arrowCircleRight',
          fn: (val) => $vm.evalContinue(val),
          disabled: (val) => val.status === 'COMPLETED'
        },
        {
          label: 'Reassessment',
          icon: 'redo',
          fn: (val) => $vm.evalReassessment(val),
          disabled: (val) => {
            // Disable if...
            // • Institution reassessment option is “Not Allowed”, or...
            // • Evaluation is NOT Completed, or...
            // • Tool used for Evaluation does not all for reassessments

            // return (
            //   !this.institution.reassessmentAllowed ||
            //   !val.reassessmentAllowed ||
            //   val.status !== 'COMPLETED'
            // );

            return (
              this.me.institution?.reassessmentOption != 'NOT_ALLOWED' &&
              val.status !== 'COMPLETED'
            );
          }
        },
        {
          label: 'Manage Media',
          icon: 'file-alt',
          fn: (data) => {
            const client = this.clients.items.find(
              (item) => item.id === data.clientId
            );

            this.$modals.evaluation.manageMedia({
              institutionId: client?.institutionId,
              subGroupId: client?.subGroup?.id,
              clientId: client?.id,
              evaluationId: data.evaluationId ? data.evaluationId : data.id
            });
          }
        },
        {
          label: 'Revert',
          icon: 'history',
          fn: (val) => $vm.evalRevert(val),
          disabled: (val) => {
            if (_.find(prohibitEdit, { id: val.toolUsed })) return true;
            return (
              val.status === 'NOT_STARTED' ||
              val.status === 'IN_PROGRESS' ||
              !val.backup
            );
          }
        },
        {
          label: 'Delete',
          icon: 'trashAlt',
          fn: (val) => $vm.evalDelete(val),
          hide: () =>
            !this.$auth.hasAccess('clientmanager:DeleteClientEvaluation'),
          disabled: (val) => {
            return (
              _.find(prohibitEdit, { id: val.toolUsed }) &&
              val.status === 'COMPLETED'
            );
          }
        }
      ]
    };

    this.tableActions = [colOptions];

    this.client = this.getClient(this.clientId) || null;

    this.tableItems = [];

    if (this.client) {
      if (!this.client.evaluations || !this.client.evaluations?.length) {
        this.client.evaluations = await this.$store.dispatch(
          'evaluations/getForClient',
          this.client.id
        );
      }

      this.tableItems = await this.getClientEvals(this.client.id);
    } else {
      this.tableItems = this.evaluations.items;
    }

    this.$store.commit('reverseTableSort', true);
  }

  get processingEval() {
    return this.evlUtils.processing;
  }

  get loadingTool() {
    return this.evlUtils.loadingTool;
  }

  get reportUtilsLoading() {
    return this.reportUtils.$loading;
  }

  get reportUtilsGeneratingPDF() {
    return this.reportUtils.$generatingPDF;
  }

  async createEvaluation() {
    if (!this.client) {
      if (!this.clients?.items?.length) await this.listClients();
      this.client = await this.$modals.settings.chooseClient(
        this.clients?.items
      );
    }

    if (!this.client) {
      this.notify.display(
        'Must select a client to start an evaluation.',
        'warning'
      );
      return;
    }

    this.evlUtils.startNewEvaluation(this.client);
  }

  async createEvaluationRequest() {
    let evalRequest = await this.$modals.create.evaluationRequest(this.clients);
  }

  reportsOpen(val) {
    let clientId = this.client?.id;
    if (!clientId) clientId = val.client?.id;
    if (!clientId) clientId = val.clientId;
    this.$store.commit(
      'clients/SET_FOCUS',
      clientId ? clientId.toString() : null
    );
    this.$store.commit('evaluations/setFocus', val.id);
    this.reportUtils.evlForReport = val;
    this.reportUtils.openReports(val);
  }

  // get reportUtilsLoading() {
  //   return this.reportUtils.isLoading();
  // }

  async evalView(val, referrer) {
    // let test = await this.$api.toolCreator.getToolCommit({
    //   toolId: val.tool.id,
    //   commitId: val.toolCommitId
    // });

    this.$state.go('dashboardViewEvaluation', { evalId: val.id });
  }

  evalEdit(val) {
    this.evlUtils.editCompletedEvaluationWarning(val);
  }

  async evalDelete(val) {
    this.dataModals.delete(val, 'evaluation');
    // this.evlUtils.delete(val, 'evaluation');
  }

  evalContinue(val) {
    this.evlUtils.continueEvaluation(val);
  }

  evalRevert(val) {
    this.evlUtils.revertEvaluationWarning(val);
  }

  evalBulkCreate() {
    this.$modals.create.bulkEvaluations(this.me.institution?.id);
  }

  async evalReassessment(evalRef) {
    this.evlUtils.startReassessment(evalRef);
  }

  goToClientProfile() {
    this.$state.go('dashboardClient', { id: this.client?.id?.toString() });
  }
}

export default DashboardEvaluationsView;
