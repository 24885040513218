'use strict';

import angular from 'angular';

import { State } from 'angular-store';
import { Controller, Inject, Watch } from '@/decorators/ngCtrl';
// import { $modalsService } from '@/__services/$modals/$modals.service';

@Controller
class DashboardModalTestView {
  modalSections: {} = {};
  modalButtons: {}[] = [];
  modalParamsMap: {} = {};
  searchText: string = '';

  @Inject $modals;
  @Inject $uibModalStack;
  @Inject utils;

  @Watch('searchText')
  onSearch(val) {
    for (let ref of this.modalButtons) {
      ref.show = !val || ref.title.includes(val);
    }
  }

  $onInit() {
    // let matches = $modalsService
    //   .toString()
    //   .match(/(\w+?: function \(\) \{|_regenerator.default.mark\(.*?\{)/g);
    //
    // // console.log(matches);
    //
    // // matches.splice(0, 2);
    // let funcs = [];
    //
    // while (matches.length > 0) {
    //   funcs.push(matches.splice(0, 2));
    // }
    //
    // // console.log(funcs);
    //
    // funcs
    //   .map(item => {
    //     console.log({
    //       a: item[0],
    //       b: item[1]
    //     });
    //
    //     return item;
    //   })
    //   .map(([a, b]) => ({
    //     method: (a.match(/^\w+/) || [])[0],
    //     params: (b.match(/function.+\((.*?)\)/) || [])[1].split(', ')
    //   }))
    //   .forEach(({ method, params }) => {
    //     this.modalParamsMap[method] = params;
    //   });
    //
    // console.log(this.modalParamsMap);

    const { $uibModalStack } = this;

    for (let [name, modals] of Object.entries(this.$modals)) {
      let sec = {};

      if (typeof modals != 'object') {
        continue;
      }

      for (let modal in modals) {
        let title = modal,
          func = modals[title];

        // let params = this.utils.getParamNames(func.prototype);
        // params = [];

        let modalRef = {
          title,
          // params,
          show: true,
          async fn(args) {
            let res;

            let error;

            try {
              res = await func(args);
            } catch (err) {
              console.error(err);
              error = err;
            }

            console.log('MODAL CLOSED');

            if (error) {
              $uibModalStack.dismissAll();
            }
          }
        };

        this.modalButtons.push(modalRef);
        sec[title] = modalRef;
      }

      this.modalSections[name] = sec;
    }

    console.log(this.modalButtons);
  }

  closeModals() {
    this.$uibModalStack.dismissAll();
  }
}

export default DashboardModalTestView;
