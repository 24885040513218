import angular from 'angular';

import { Controller, Watch } from '@/decorators/ngCtrl';

@Controller
class DisplayTablePaginationComponent {
  pages: {}[] = [];
  pageButtons: {}[] = [];
  pageNumber: number = 1;
  itemsPerPage: number = 15;

  get pageCount() {
    return this.pages.length;
  }

  get pageItemsCount() {
    return this.currentPage.length;
  }

  get currentPage() {
    this.pageNumber = Math.clamp(this.pageNumber, 1, this.pages.length);

    return this.pages[this.pageNumber - 1];
  }

  @Watch('pages') updateButtons;
  @Watch('pageNumber') updateButtons;

  createPages() {
    var items = [...this.items],
      pages = [],
      pos = 0;

    while (pos <= items.length) {
      const start = pos;
      pos += this.itemsPerPage;

      pages.push(items.slice(start, pos));
    }

    this.pages = pages;

    return this.pages;
  }

  updateButtons() {
    const arr = [
      {
        type: 'page',
        value: 1
      }
    ];

    if (this.pageCount > 5 && this.pageNumber >= 5) {
      arr.push({
        type: 'ellipsis',
        value: '...'
      });
    }

    var num = 1,
      start;

    do {
      start = num;
      num += 5;
    } while (num < this.pageNumber);

    for (let i = 1; i <= 5; i++) {
      const pageNumber = start + i;

      if (pageNumber > this.pageCount - 1) {
        break;
      }

      arr.push({
        type: 'page',
        value: pageNumber
      });
    }

    if (this.pageCount > 5 && this.pageNumber < this.pageCount - 5) {
      arr.push({
        type: 'ellipsis',
        value: '...'
      });
    }

    if (this.pageCount > 1) {
      arr.push({
        type: 'page',
        value: this.pageCount
      });
    }

    this.pageButtons = arr;
  }

  previousPage() {
    this.pageNumber -= 1;
  }

  nextPage() {
    this.pageNumber -= 1;
  }
}

export default angular
  .module('app.displayTablePagination', [])
  .component('displayTablePagination', {
    template: require('./display-table-pagination.html'),
    // transclude: true,
    bindings: {
      items: '='
    },
    controller: DisplayTablePaginationComponent,
    controllerAs: 'vm'
  }).name;
