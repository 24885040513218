'use strict';

import angular from 'angular';

class TextFieldComponentController {
  constructor() {

  }

  get text() {
    return this.component.value;
  }

  set text(value) {
    return this.component.value = value;
  }
}

export default angular.module('app.component.textFieldComponent', [])
  .component('textFieldComponent', {
    template: require('./text-field.html'),
    bindings: {
      component: '='
    },
    controller: TextFieldComponentController,
    controllerAs: 'vm'
  })
  .name;