'use strict';

import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class PrivacyView {
  site: string = '';
  @Inject $location;

  $onInit() {
    this.site = this.$location.host();
  }
}

export default PrivacyView;
