'use strict';

function LogsStore($api) {
  'ngInject';

  return {
    state: {
      loading: false,
      items: []
    },
    actions: {
      async list({ state, commit }) {
        state.loading = true;

        let data = [];

        let res = await $api.logs.list();
        data = res.data;

        commit('set', data);

        state.loading = false;

        return state.items;
      }
    },
    mutations: {
      set(state, data) {
        state.items = data;
      },
      CLEAR(state) {
        Object.assign(state, { loading: false, items: [] });
      }
    }
  };
}

export default LogsStore;
