import download from 'downloadjs';

import { State, Getter } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

import { find } from 'lodash';

@Controller
class DashboardActivityReports {
  tableProps: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  get tableItems() {
    return this.$store.state.activityReports.items;
  }

  @Inject $rootScope;
  @Inject $state;
  @Inject $location;
  @Inject $modals;
  @Inject $auth;
  @Inject $store;
  @Inject $api;
  @Inject $acl;
  @Inject $http;
  @Inject notify;

  @State me;
  @State permissions;
  @Getter activeInstId;
  @Getter isAdmin;

  async $onInit() {
    await this.$store.dispatch('activityReports/list', {
      instId: this.activeInstId
    });

    this.tableProps = [
      {
        label: 'Start Date',
        value: 'startDate',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'End Date',
        value: 'endDate',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Created',
        value: 'createdAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Status',
        value: (row) => {
          let output = '';
          let scanTag = null;

          if (row.tags && row.tags?.length) {
            // new scan tag
            scanTag = find(row.tags, { Key: 'scan-status' });

            // fallback to old scan tag
            if (!scanTag) scanTag = find(row.tags, { Key: 'av-status' });
          }

          if (row.status === 'QUEUED' || row.status === 'PENDING') {
            output = `<label class="status-label in-progress">In Progress</label>`;
          } else if (row.status === 'ERROR') {
            output = `<label class="status-label not-started">Failed</label>`;
          } else if (
            row.status === 'SCANNING' ||
            (scanTag && scanTag.Value !== 'CLEAN')
          ) {
            output = `<label class="status-label in-progress">Scanning</label>`;
          } else if (row.status === 'COMPLETED') {
            output = `<label class="status-label completed">Completed</label>`;
          } else if (row.status === 'INFO') {
            output = `<label class="status-label info">Info</label>`;
          }

          return output;
        }
      }
    ];

    if (this.isAdmin) {
      this.tableProps.unshift({
        label: 'Institution Access',
        value: (row) => {
          if (row.account?.id === 'gifrgears') return 'N/A';
          return row.institutionAccess ? 'Yes' : 'No';
        }
      });

      this.tableProps.unshift({
        label: 'Institution',
        value: 'account.name'
      });
    }

    this.tableActions = [
      {
        label: 'Download',
        icon: 'cloud-download-alt',
        actions: async (val) => {
          // Download the CSV...

          const mimeType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

          var url;

          if (this.$acl('GM:DownloadLogRequest', this.permissions.profile)) {
            url = `/api/gears-manager/log-requests/${val.id}/download`;
          } else if (
            this.$acl('IM:DownloadLogRequest', this.permissions.profile)
          ) {
            url = `/api/institution-manager/${this.activeInstId}/log-requests/${val.id}/download`;
          }

          const config = {
            responseType: 'arraybuffer',
            headers: {
              Accept:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
          };

          const res = await this.$http.get(url, config);

          var blob = new Blob([res.data], { type: mimeType });

          download(blob, 'activity_report.xlsx', mimeType);
        },
        disabled: ({ status, tags }) => {
          if (tags && tags.length) {
            // new scan tag
            let tag = find(tags, { Key: 'scan-status' });

            // fallback to old scan tag
            if (!tag) tag = find(tags, { Key: 'av-status' });

            return tag.Value !== 'CLEAN';
          }

          if (status !== 'COMPLETED') return true;
        }
      },
      {
        label: 'Info',
        icon: 'info-circle',
        actions: async (val) => {
          // display the message
          this.notify.display(val.message, 'warning');
        },
        disabled: ({ status }) => status !== 'INFO'
      }
    ];
  }
}

export default DashboardActivityReports;
