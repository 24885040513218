'use strict';

import angular from 'angular';

import { Controller } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class LscmiProfileReportChoicesModalController extends Modal {
  $setup() {
    this.options = [
      {
        text: 'Introduction',
        selected: true
      },
      {
        text: 'Offender History Information',
        selected: true
      },
      {
        text: 'General Notes for Evaluation',
        selected: true
      },
      {
        text: 'General Risk/Need Factors',
        selected: true
      },
      {
        text: 'General Risk/Need Assessment Based on General Risk/Need Total Score',
        selected: true
      },
      {
        text: 'Comparison to North American Offenders',
        selected: true
      },
      {
        text: 'Assessment of General Risk/Need Factors',
        selected: true
      },
      {
        text: 'Profile Summary',
        selected: true
      },
      {
        text: 'General Risk/Need Subcomponent Areas of Strength',
        selected: true
      },
      {
        text: 'Details Regarding Subcomponent Risk/Need',
        selected: true
      },
      {
        text: 'Summary of General Risk/Need Item Responses',
        selected: true
      },
      {
        text: 'Additional Item Information',
        selected: true
      },
      {
        text: 'Summary of Omitted Responses',
        selected: true
      },
      {
        text: 'Section 2: Specific Risk/Need Factors',
        selected: true
      },
      {
        text: 'Section 3: Prison Experience - Institutional Factors',
        selected: true
      },
      {
        text: 'Section 4: Other Client Issues (Social, Health, and Mental Health)',
        selected: true
      },
      {
        text: 'Section 5: Special Responsivity Considerations',
        selected: true
      },
      {
        text: 'Section 6: Risk/Need Summary and Override',
        selected: true
      },
      {
        text: 'Section 7: Program/Placement Decision',
        selected: true
      }
    ];
  }

  submit() {
    this.$close(this.options);
  }
}

export default angular
  .module('app.lscmiProfileReportChoicesModal', [])
  .directive('lscmiProfileReportChoicesModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./lscmi-profile-report-choices.html'),
    controller: LscmiProfileReportChoicesModalController,
    controllerAs: 'vm'
  })).name;
