'use strict';
const angular = require('angular');
import * as _ from 'lodash';

export default angular.module('app.tool-editor', []).directive('toolEditor', function(ToolEditorConnectors, $interval) {
  'ngInject';
  return {
    template: require('./tool-editor.html'),
    restrict: 'EA',
    link: function(scope, element, attrs) {
      /**
       * check for mouse position to auto scroll
       */
      //region auto scroll
      var scrollX;
      function startScrollX() {
        scrollX = $interval(function() {
          element[0].scrollLeft = 500;
        }, 1000);
      }
      function endScrollX() {
        $interval.cancel(scrollX);
      }
      var scrollY;
      function startScrollY() {
        scrollY = $interval(function() {
          element[0].scrollTop = 1000;
        }, 1000);
      }
      function endScrollY() {
        $interval.cancel(scrollY);
      }
      // element.on('mousemove', function(event) {
      // let x = event.clientX > 1800;
      // let y = event.clientY > 850;
      // x ? startScrollX(event) : endScrollX();
      // y ? startScrollY(event) : endScrollY();
      // });
      //endregion auto scroll
      element.on('click', function(event) {
        /**
         * if we're not clicking on an editor node, negate any
         * current selection of node connections
         */
        if (event.target.nodeName !== 'TOOL-EDITOR-NODE') {
          ToolEditorConnectors.emptyLiveConnector();
        }
        /**
         * if we're not clicking on an editor node or the
         * editor node options, hide the node options
         */
        if (event.target.nodeName !== 'TOOL-EDITOR-NODE' && event.target.nodeName !== 'TOOL-EDITOR-NODE-OPTIONS') {
          let nodeOptions = angular.element(document.querySelector('#tool-editor-node-options'));
          nodeOptions.css({
            visibility: 'hidden',
            opacity: 0,
            left: '0px',
            top: '0px'
          });
          ToolEditorConnectors.highlightLineForDeletion();
        }
        /**
         * if we're clicking on text input or textarea, save
         * it as the last focused field
         */
        if (event.target.type === 'text' || event.target.type === 'textarea') {
          ToolEditorConnectors.data.lastFocusedInput = event;
        }
      });
      element.on('keyup', function(event) {
        ToolEditorConnectors.data.lastFocusedInput = event;
      });
      element.on('click', function(event) {
        if (event.target.type === 'textarea' || event.target.type === 'text') {
          ToolEditorConnectors.data.lastFocusedInput = event;
        }
      });
      /*
        generate titles for items
         */
      scope.title = function(item) {
        let title = '';
        if (item.type === 'item-description') {
          _.forEach(item.rows, function(row) {
            if (_.some(row.fields, { label: 'Coding Form Item Description' })) {
              title = _.find(row.fields, { label: 'Coding Form Item Description' }).model;
            }
          });
        }
        /*
          works for additional-criteria, additional-criteria-option, and report-template-choice
           */
        _.forEach(item.rows, function(row) {
          if (_.some(row.fields, { label: 'Text' }) && !_.some(row.fields, { label: 'Content Type' })) {
            title = _.find(row.fields, { label: 'Text' }).model;
          }
        });
        return title;
      };
    }
  };
}).name;
