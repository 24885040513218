import angular from 'angular';
import { find, filter, remove, uniqBy } from 'lodash';
import { ActionTree, MutationTree, GetterTree } from 'angular-store';

import { TableState } from '@interfaces';
import { Institution } from '@interfaces/institution';
import { Region } from '@interfaces/region';
import { Subgroup } from '@interfaces/subgroup';
import { Zone } from '@interfaces/zone';
import { HasAccessCheckOptions } from '@services/acl/acl.service';
import { RootState } from '@store/state';

export interface LocationsState {
  loading: boolean;
  items: Zone[];
  focus: Zone | null;
  table: TableState;
}

export interface DeleteLocationActionOptions {
  id: Zone['id']; // TODO: Change to "zoneId" for clarity.
  instId: Institution['id']; // TODO: Change to "institutionId" for clarity.
}

export default function LocationsStore(
  $injector: angular.auto.IInjectorService,
  $api2: angular.gears.IAPI2Service,
  $reincode: angular.gears.IReincodeService
) {
  'ngInject';

  /** ... */
  const hasAccess = (actions: HasAccessCheckOptions) =>
    $injector.get<angular.gears.IAuthService>('$auth').hasAccess(actions);

  const state: LocationsState = {
    loading: false,
    items: [],
    focus: null,
    table: { sortedCol: 0, searchText: '' }
  };

  const getters: GetterTree<LocationsState, RootState> = {
    /**
     * Find a zone by reference.
     *
     * @return The matching zone, if found.
     */
    find: (state) => (ref: { id: Zone['id'] }) => {
      return find(state.items, { id: ref.id });
    },
    /**
     * Find a zone by ID.
     *
     * @return The matching zone, if found.
     */
    getById: (state) => (id: Zone['id']) => {
      return find(state.items, { id });
    },
    /**
     * Find a subgroup by ID
     */
    getSubGroupById: (state) => (id: Subgroup['id']) => {
      return find(state.items, (z) => {
        find(z.regions, (r) => {
          find(r.subGroups, (s) => {
            id;
          });
        });
      });
    }
  };

  const actions: ActionTree<LocationsState, RootState> = {
    /**
     * ...
     *
     * @return ...
     */
    setItems({ commit }, { items, replace = false }) {
      commit(replace ? 'SET' : 'ADD', items);
    },
    /**
     * ...
     *
     * @return ...
     */
    async listZones(
      { rootState, commit, dispatch, rootGetters },
      institutionId: string
    ) {
      // grabs list of locations for an institution for either gears or institution user

      institutionId =
        institutionId ??
        $injector.get<angular.gears.IAuthService>('$auth').institutionId;

      if (!institutionId) {
        return console.warn(
          '[gears-auth] This must provide an institution id to fetch locations (zones)'
        );
      }

      const hasGM = hasAccess('GM:ListZones');
      const hasIM = hasAccess('IM:ListZones');

      if (!hasGM && !hasIM) {
        return console.warn(
          '[gears-auth] User does not have access to List Locations'
        );
      }

      // Get Clients to store under SubGroups
      if (!rootState.clients?.items?.length) {
        await dispatch('clients/list', null, true);
      }

      let zones: Zone[] = [];
      let regions: Region[] = [];
      let subGroups: Subgroup[] = [];

      let error: unknown = null;

      commit('SET_PROPS', { loading: true });

      try {
        // Grab Institution Zones
        zones = await (hasGM
          ? $api2.gm.listZones({ institutionId })
          : $api2.im.listZones({ institutionId }));

        // Grab Institution Regions
        regions = await (hasGM
          ? $api2.gm.listRegions({ institutionId })
          : $api2.im.listRegions({ institutionId }));

        // Grab Institution Sub Groups
        subGroups = await (hasGM
          ? $api2.gm.listSubGroups({ institutionId })
          : $api2.im.listSubGroups({ institutionId }));
      } catch (err) {
        error = err;
      }

      commit('SET_PROPS', { loading: false });

      if (error) {
        throw error;
      }

      const clients = rootGetters['clients/getByInstitutionId'](institutionId);

      // Build Zones/Regions/Subgroups object.

      for (let subGroup of subGroups) {
        subGroup.institutionId = institutionId;
        subGroup = $reincode.fullObject(subGroup);
        subGroup.clients = filter(
          clients,
          (c) => c?.subGroup?.id === subGroup.id
        );
      }

      for (let region of regions) {
        region.institutionId = institutionId;
        region = $reincode.fullObject(region);
        region.subGroups = filter(subGroups, { regionId: region.id });
      }

      for (let zone of zones) {
        zone.institutionId = institutionId;
        zone = $reincode.fullObject(zone);
        zone.regions = filter(regions, { zoneId: zone.id });
      }

      // update/set institution zones in institution store
      const institution = rootGetters['institutions/find'](institutionId);
      if (institution && zones) {
        institution.zones = zones;
        commit('institutions/updateZones', { institutionId, zones }, true);
      }

      commit('SET', zones);

      return zones;
    },
    /**
     * ...
     *
     * @return ...
     */
    async delete({ state, commit }, options: DeleteLocationActionOptions) {
      const promise = hasAccess('GM:DeleteZone')
        ? $api2.gm.deleteZone({
            institutionId: options.instId,
            zoneId: options.id
          })
        : hasAccess('IM:DeleteZone')
        ? $api2.im.deleteZone({
            institutionId: options.instId,
            zoneId: options.id
          })
        : null;

      if (promise === null) {
        return console.warn(
          '[gears-auth] This user does not have access to delete locations (zones)'
        );
      }

      let error: unknown = null;

      commit('SET_PROPS', { loading: true });

      try {
        await promise;
      } catch (err) {
        error = err;
      }

      commit('SET_PROPS', { loading: false });

      if (error) {
        throw error;
      }

      remove(state.items, { id: options.id });
    }
  };

  const mutations: MutationTree<LocationsState> = {
    SET(state, payload) {
      state.items = [...payload];
    },
    ADD(state, payload) {
      state.items = uniqBy([...payload, ...state.items], 'id');
    },
    UPDATE({ items }, payload = []) {
      if (!Array.isArray(payload)) {
        payload = [payload];
      }

      payload.forEach((item) => {
        if (typeof item != 'object' || !item.id) {
          console.warn(
            `[data-store:locations:update] A provided value for location data was not valid.`,
            item
          );
          return;
        }

        let location = items.find((loc) => loc.id == item.id);

        if (!location) {
          console.warn(
            `[data-store:locations:update] The location with ID "${item.id}" could not be found.`
          );
          return;
        }

        Object.keys(item).forEach((key) => {
          location[key] = item[key];
        });
      });
    },
    UPDATE_ITEM({ items }, { ref = -1, props = {} }) {
      let item = items.find((item) => item == ref || item.id == ref.id);

      if (!item) {
        return;
      }

      Object.keys(props).forEach((key) => {
        item[key] = props[key];
      });
    },
    REMOVE({ items }, id) {
      items.splice(
        items.findIndex((item) => item.id === id),
        1
      );
    },
    SET_PROPS(state, props = {}) {
      for (let i in props) {
        if (i in state) {
          state[i] = props[i];
        }
      }
    },
    SET_FOCUS(state, id) {
      state.focus = state.items.find((item) => item.id === id) || null;
    },
    CLEAR(state) {
      Object.assign(state, {
        loading: false,
        items: [],
        focus: null,
        table: { sortedCol: 0, searchText: '' }
      });
    }
  };

  // TEMP: remove once all references to them have been removed.
  Object.defineProperties(mutations, {
    set: { enumerable: true, value: mutations.SET },
    add: { enumerable: true, value: mutations.ADD },
    update: { enumerable: true, value: mutations.UPDATE },
    updateItem: { enumerable: true, value: mutations.UPDATE_ITEM },
    remove: { enumerable: true, value: mutations.REMOVE },
    setProps: { enumerable: true, value: mutations.SET_PROPS },
    setFocus: { enumerable: true, value: mutations.SET_FOCUS }
  });

  return { state, getters, actions, mutations };
}
