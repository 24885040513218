'use strict';
const angular = require('angular');

export default angular
  .module('app.child-tools-institution-customizer', [])
  .directive('childToolsInstitutionCustomizer', function () {
    return {
      template: require('./child-tools-institution-customizer.html'),
      restrict: 'EA'
    };
  }).name;
