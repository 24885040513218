'use strict';

function ClientsTransfersStore($api) {
  'ngInject';

  return {
    state: {
      loading: false,
      items: []
    },
    getters: {
      getById: (state) => (id) => {
        return state.items.find((item) => item.id == id);
      }
    },
    actions: {
      async getAll({ state, commit }) {
        state.loading = true;

        let res = await $api.clientTransfers.getAll();
        state.items = res.data.data;

        commit('set', state.items);

        state.loading = false;

        return state.items;
      }
    },
    mutations: {
      set(state, payload) {
        state.items = [...payload];
      },
      add(state, payload) {
        state.items = _.uniqBy([...payload, ...state.items], 'id');
      },
      update(state, data = []) {},
      CLEAR(state) {
        Object.assign(state, {
          loading: false,
          items: []
        });
      }
    }
  };
}

export default ClientsTransfersStore;
