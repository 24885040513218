'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class SetToolPriceModalController extends Modal {
  newToolCost: any;
  processing: any;
  tool: any;
  utils: any;

  @Inject $scope;
  @Inject $http;
  @Inject utils;

  $setup() {
    var parent = this.$scope.$parent;
    // console.log(this.$modal.item);
    this.tool = parent.modal.item;
    this.processing = true;
    // console.log(this.tool);
    this.$http
      .get('/api/tools/' + this.tool.id)
      .then(cost => {
        // console.log(cost);
        this.processing = false;
        this.newToolCost = {
          perUser: 0,
          perEvaluation: 0
        };
        if (!cost.data.error) {
          this.newToolCost = {
            perUser: cost.data.data.costPerUser,
            perEvaluation: cost.data.data.costPerEvaluation
          };
        } else {
          this.utils.notify('error', {});
        }
      })
      .catch(err => {
        // console.log(err);
        this.utils.notify('error', {});
        this.processing = false;
      });
  }

  setPrice(form) {
    if (form.$valid) {
      // console.log(form);
      // console.log(this.newToolCost);
      this.$http
        .post('/api/tools/' + this.tool.id + '/price', {
          costPerUser: this.newToolCost.perUser,
          costPerEvaluation: this.newToolCost.perEvaluation
        })
        .then(response => {
          // console.log(response);
          if (!response.data.error) {
            this.utils.notify('success', {});
            parent.$close();
          } else {
            this.utils.notify('error', {});
          }
        })
        .catch(error => {
          this.utils.notify('error', {});
        });
    }
  }
}

export default angular
  .module('app.set-tool-price-modal', [])
  .directive('setToolPriceModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./set-tool-price.html'),
    controller: SetToolPriceModalController,
    controllerAs: 'vm'
  })).name;
