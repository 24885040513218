import angular from 'angular';
import { noop, remove } from 'lodash';

type ModelType =
  | 'institution'
  | 'evaluation'
  | 'client request'
  | 'invitation'
  | 'report'
  | 'tool'
  | 'user'
  | 'client'
  | 'evaluation-request';

interface DeleteItemOptions<T> {
  type: ModelType;
  items: T[];
  client: unknown;
  predicate: (item: T) => boolean;
  modifier: (item: T) => void;
}

declare module 'angular' {
  namespace gears {
    type IDataModalsService = DataModalsService;
  }
}

export class DataModalsService {
  /**
   * ...
   */
  get me() {
    return this.$store.state.me;
  }

  /**
   * ...
   */
  get users() {
    return this.$store.state.users.items;
  }

  /**
   * ...
   */
  get clients() {
    return this.$store.state.clients.items;
  }

  /**
   * ...
   */
  get institutions() {
    return this.$store.state.institutions.items;
  }

  /**
   * ...
   */
  get invitations() {
    return this.$store.state.invitations.items;
  }

  /**
   * ...
   */
  get evaluations() {
    return this.$store.state.evaluations.items;
  }

  /**
   * ...
   */
  get evaluationRequests() {
    return this.$store.state.evaluationRequests.items;
  }

  /**
   * ...
   */
  get reports() {
    return this.$store.state.reports.items;
  }

  /**
   * ...
   */
  get tools() {
    return this.$store.state.tools.items;
  }

  constructor(
    private readonly $rootScope: angular.IRootScopeService,
    private readonly $store: angular.gears.IStoreService,
    private readonly $modals: angular.gears.IModalsService,
    private readonly evlUtils: angular.gears.IEvalUtilsService,
    private readonly reportUtils: unknown,
    private readonly errorHandler: angular.gears.IErrorHandlerService
  ) {
    'ngInject';
  }

  // Users
  openCreateUser() {
    this.$modals.invite.invite(this.me.id);
  }

  /**
   * ...
   */
  openEditUser(row: unknown) {
    this.$modals.edit.user(this.me.id, angular.copy(row));
  }

  /**
   * ...
   */
  deleteUser(row: unknown) {
    this.deleteItem({
      type: 'user',
      items: this.users,
      predicate: (item) => item.id === row.id
    });
  }

  /**
   * ...
   */
  // Clients
  openCreateClientRequest() {
    if (!this.me.institution) {
      this.$modals.create.clientRequest(this.institutions);
    } else {
      this.$modals.create.clientRequest(null);
    }
  }

  /**
   * ...
   */
  openCreateClient() {
    var user = {
      userId: this.me.id,
      activeRole: this.me.activeRole
    };

    this.$modals.create.client(user);
  }

  /**
   * ...
   */
  openBulkCreateClients() {
    if (this.me.activeRole.roleId === 2 || this.me.activeRole.roleId === 3) {
      this.$modals.create.bulkClients(this.me.activeRole.institutionId, this);
    }
  }

  /**
   * ...
   */
  openEditClient(row: unknown) {
    this.$modals.edit.client(row);
  }

  /**
   * ...
   */
  deleteClient() {
    this.deleteItem({
      type: 'client',
      items: this.clients,
      predicate: (item) => item.selected
    });
  }

  /**
   * ...
   */
  openClientProfile(row: unknown) {
    this.$modals.test.clientProfile(this.me.id, row);
  }

  /**
   * ...
   */
  // Evaluation Requests
  openEditEvaluationRequest(row: unknown) {
    this.$modals.edit.evaluationRequest(
      this.tools,
      this.users,
      row,
      (result) => {
        this.$rootScope.$broadcast('reloadDashboard');

        this.evlUtils.showEvaluations(result);
      }
    );
  }

  /**
   * ...
   */
  deleteEvaluationRequest() {
    this.deleteItem({
      type: 'evaluationRequest',
      items: this.evaluationRequests,
      predicate: (item) => item.selected
    });
  }

  /**
   * ...
   */
  openCreateEvaluationRequest() {
    this.$modals.create.evaluationRequest((result) => {
      this.$rootScope.$broadcast('reloadDashboard');

      this.evlUtils.showEvaluations(result);
    });
  }

  /**
   * ...
   */
  // Evaluations
  deleteEvaluation() {
    let client = this.$store.state.clients.focus;

    this.deleteItem({
      type: 'evaluation',
      items: this.evaluations,
      client,
      predicate: (item) => item.selected,
      modifier: (item) => `${client.name(true)}`
    });
  }

  /**
   * ...
   */
  // Reports
  deleteReport() {
    this.deleteItem({
      type: 'report',
      items: this.reports,
      predicate: (item) => item.selected
    });
  }

  /**
   * ...
   */
  // Institutions
  openCreateInst() {
    this.$modals.create.institution(this.me.id);
  }

  /**
   * ...
   */
  openEditInst(row: unknown) {
    this.$modals.edit.institution(row);
  }

  /**
   * ...
   */
  deleteInst() {
    this.deleteItem({
      type: 'institution',
      items: this.institutions,
      predicate: (item) => item.selected
    });
  }

  /**
   * ...
   */
  // Tools
  deleteTool() {
    this.deleteItem({
      type: 'tool',
      items: this.tools,
      predicate: (item) => item.selected
    });
  }

  /**
   * ...
   */
  // Invitations
  deleteInvitation() {
    this.deleteItem({
      type: 'invitation',
      items: this.invitations,
      predicate: (item) => item.selected
    });
  }

  /**
   * ...
   */
  delete(
    item: unknown,
    itemType:
      | 'institution'
      | 'evaluation'
      | 'client request'
      | 'invitation'
      | 'report'
      | 'tool'
      | 'user'
      | 'client'
      | 'evaluation-request',
    section: string,
    list: unknown[]
  ) {
    this.$modals.confirm.delete2(item, itemType, (result) => {
      // TODO: correct section names
      switch (itemType) {
        case 'institution':
          this.$rootScope.$broadcast('reloadDashboard');
          break;
        case 'evaluation':
          if (list) remove(list, { id: item.id });
          if (section === 'all-evaluations') {
            this.$store.dispatch('evaluations/getForInstitution');
          } else if (section === 'client-profile') {
            this.$store.dispatch('evaluations/getForClient', item.clientId);
          } else {
            this.evlUtils.showEvaluations(null, true);
          }
          break;
        case 'client request':
          this.$store.dispatch('clientRequests/getAll');
          this.$store.dispatch('clientRequests/getOutgoingForInstitution');

          break;
        case 'invitation':
          // $store.dispatch('invitations/getAll');
          // $store.dispatch('invitations/getForInstitution');
          // $store.dispatch('institutions/get');
          this.$store.dispatch('invitations/list');
          break;
        case 'report':
          this.reportUtils.openReports(null, true);
          break;
        case 'tool':
          this.$store.dispatch('tools/list');
          break;
        case 'user':
          this.$store.dispatch('users/list');
          break;
        case 'client':
          this.$store.dispatch('clients/list');
          break;
        case 'evaluation-request':
          this.$store.dispatch('evaluationRequests/list');

          // if (!result.completedEval) {
          //   $store.dispatch('analytics/setProps', {
          //     allOpenEvaluations:
          //       $store.state.analytics.allOpenEvaluations - 1
          //   });

          //   if (result.evalId) {
          //     $store.dispatch('evaluations/remove', result.evalId);
          //   }
          // }

          break;
      }

      return result;
    });
  }

  /**
   * ...
   *
   * @param options ...
   * @return ...
   */
  private async deleteItem<T>(options: DeleteItemOptions<T>) {
    const {
      type,
      items,
      client = null,
      predicate = noop,
      modifier = noop
    } = options;

    let list = [];

    for (let item of items) {
      if (predicate(item)) {
        item[type] = true;
        modifier(item);

        list.push(item);
      }
    }

    if (!list.length) {
      this.errorHandler({
        data: { feedback: `You must select at least one ${type} to delete.` }
      });

      return;
    }

    console.log('clietn: ', client);
    console.log('list: ', list);

    const result = await this.$modals.confirm.delete(list);

    if (result.evaluation && !result.evaluationRequest) {
      this.evlUtils.showEvaluations(client);
    } else if (result.report) {
      this.reportUtils.reloadReports();
    } else {
      this.$rootScope.$broadcast('reloadDashboard');
    }

    // , client, (result) => {
    //   if (result.evaluation && !result.evaluationRequest) {
    //     this.evlUtils.showEvaluations(client);
    //   } else if (result.report) {
    //     this.reportUtils.reloadReports();
    //   } else {
    //     this.$rootScope.$broadcast('reloadDashboard');
    //   }
    // });
  }
}

export default angular
  .module('app.data-modals', [])
  .service('dataModals', DataModalsService).name;
