import angular from 'angular';

import { NG_COMPONENT_DEPENDENCIES } from './dependencies';

declare global {
  interface Function {
    $module?: string;
    $props?: string[];
  }
}

declare module 'angular' {
  namespace gears {
    interface IAttributes extends Omit<angular.IAttributes, '$attr'> {
      $attr: Record<string, string>;
    }
  }
}

type NgDependencies = typeof NG_COMPONENT_DEPENDENCIES[number];

/**
 * ...
 */
type INg = {
  // [P in NgDependencies]: Ng[P];
  [P in NgDependencies]: unknown;
};

/**
 * ...
 */
type ListenerCallback<T = unknown, S = angular.IScope> = (
  newValue: T,
  oldValue: T,
  scope: S
) => unknown;

/**
 * ...
 */
interface IScope extends angular.IScope {
  vm: Ng;
}

/**
 * ...
 */
export type ComponentScope<T = unknown> = IScope &
  (T extends void ? EmptyObject : T);

/**
 * ...
 */
export abstract class Ng<Scope = void> implements angular.IController, INg {
  static readonly dependencies = [...NG_COMPONENT_DEPENDENCIES];

  readonly $scope!: ComponentScope<Scope>;
  readonly $element!: angular.IElement;
  readonly $attrs!: angular.gears.IAttributes;
  readonly $anchorScroll!: angular.IAnchorScrollService;
  readonly $cacheFactory!: angular.ICacheFactoryService;
  readonly $compile!: angular.ICompileService;
  readonly $controller!: angular.IControllerService;
  readonly $document!: angular.IDocumentService;
  readonly $exceptionHandler!: angular.IExceptionHandlerService;
  readonly $filter!: angular.IFilterService;
  readonly $http!: angular.IHttpService;
  readonly $httpBackend!: angular.IHttpBackendService;
  readonly $httpParamSerializer!: angular.IHttpParamSerializer;
  readonly $httpParamSerializerJQLike!: angular.IHttpParamSerializer;
  readonly $interpolate!: angular.IInterpolateService;
  readonly $interval!: angular.IIntervalService;
  readonly $locale!: angular.ILocaleService;
  readonly $location!: angular.ILocationService;
  readonly $log!: angular.ILogService;
  readonly $parse!: angular.IParseService;
  readonly $q!: angular.IQService;
  readonly $rootElement!: angular.IRootElementService;
  readonly $rootScope!: angular.IRootScopeService;
  readonly $sce!: angular.ISCEService;
  readonly $sceDelegate!: angular.ISCEDelegateService;
  readonly $templateCache!: angular.ITemplateCacheService;
  readonly $templateRequest!: angular.ITemplateRequestService;
  readonly $timeout!: angular.ITimeoutService;
  readonly $window!: angular.IWindowService;
  //
  readonly $state!: angular.ui.IStateService;
  readonly $stateParams!: angular.ui.IStateParamsService;
  readonly $cookies!: angular.cookies.ICookiesService;
  readonly Upload!: angular.angularFileUpload.IUploadService;
  //
  readonly $store!: angular.gears.IStoreService;
  readonly $modals!: angular.gears.IModalsService;
  //
  readonly $acl!: angular.gears.IAclService;
  readonly $api!: angular.gears.IApiService;
  readonly $api2!: angular.gears.IAPI2Service;
  readonly $auth!: angular.gears.IAuthService;
  readonly $createObjects!: angular.gears.ICreateObjectsService;
  readonly $delay!: angular.gears.IDelayService;
  readonly $generatePdf!: angular.gears.IGeneratePdfService;
  readonly $ls!: angular.gears.ILsService;
  readonly $notify!: angular.gears.INotifyService;
  readonly $toolRequirementsParser!: angular.gears.IToolRequirementsParserService;
  readonly anchorSmoothScroll!: angular.gears.IAnchorSmoothScrollService;
  readonly aggregateReportsMonitor!: angular.gears.IAggregateReportsMonitor;
  readonly aggregateUserReportsMonitor!: angular.gears.IAggregateUserReportsMonitor;
  readonly codeTokenizer!: angular.gears.ICodeTokenizerService;
  readonly dataModals!: angular.gears.IDataModalsService;
  readonly errorHandler!: angular.gears.IErrorHandlerService;
  readonly getItems!: angular.gears.IGetItemsService;
  readonly getFormItems!: angular.gears.IGetFormItemsService;
  readonly getToolJson!: angular.gears.IGetToolJsonService;
  readonly httpRequestInterceptor!: angular.gears.IHttpRequestInterceptorService;
  readonly idleMonitor!: angular.gears.IIdleMonitorService;
  readonly idolTracker!: angular.gears.IIdolTrackerService;
  readonly notify!: angular.gears.INotifyService; // depriciated alias.
  readonly paginator!: angular.gears.IPaginatorFactory;
  readonly policyTranslator!: angular.gears.IPolicyTranslatorService;
  readonly Proration!: angular.gears.IProrationService;
  readonly reportUtils!: angular.gears.IReportUtilsService;
  // Evaluations
  readonly evlUtils!: angular.gears.IEvalUtilsService;
  readonly evlReqUtils!: angular.gears.IEvlReqUtilsService;
  // Tools
  readonly loadTool!: angular.gears.ILoadToolService;
  readonly toolCanvas!: angular.gears.IToolCanvasService;
  readonly toolManager!: angular.gears.IToolManagerService;
  readonly toolUtils!: angular.gears.IToolUtilsService;
  // Utils
  readonly $util!: angular.gears.IUtilService;
  readonly utils!: angular.gears.IUtilsService;
  readonly Braintree!: angular.gears.IBraintreeService;
  readonly CasePlan!: angular.gears.ICasePlanService;

  // readonly $refs: Record<string, string> = {};

  protected $$props: IndexableObject = {};

  get $parent() {
    return this.$scope.$parent;
  }

  get $props() {
    return this.$$props;
  }

  private get $inject() {
    return (this.constructor.$inject ?? []) as (keyof INg)[];
  }

  constructor(...args: Ng[keyof INg][]) {
    // eslint-disable-next-line @typescript-eslint/no-for-in-array
    for (const index in this.$inject) {
      Object.defineProperty(this, this.$inject[index], {
        enumerable: true,
        value: args[index]
      });
    }

    for (const prop of this.constructor.$props ?? []) {
      const descriptor: PropertyDescriptor = { enumerable: true };

      if (prop in this.$attrs.$attr === false) {
        descriptor.value = null;
      } else if (/^(?::|ng-)/.test(this.$attrs.$attr[prop]) === false) {
        descriptor.value = this.$attrs[prop];
      } else {
        descriptor.get = () => this.$scope[prop];
      }

      Object.defineProperty(this.$$props, prop, descriptor);
    }
  }

  $set(obj: Record<string, unknown>, key: string, value: unknown) {
    this.$scope.$apply(() => {
      obj[key] = value;
    });
  }

  $emit(event: string, ...args: unknown[]) {
    if (event !== 'input') {
      this.$scope.$emit(event, ...args);
    } else if ('ngModel' in this.$scope) {
      this.$set(this.$scope, 'ngModel', args[0]);
    }
  }

  $on(event: string, callback: (...args: unknown[]) => void) {
    this.$scope.$on(event, (...args) => callback(...args));
  }

  /**
   * ...
   */
  $watch<K extends keyof Scope>(
    watchExpression: K,
    listener?: ListenerCallback<Scope[K], this['$scope']>,
    options?: boolean | Ng.WatchOptions
  ): void;
  $watch<T>(
    watchExpression: (scope: this['$scope']) => T,
    listener?: ListenerCallback<T, this['$scope']>,
    options?: boolean | Ng.WatchOptions
  ): void;
  $watch(
    watchExpression: string | GenericFunction,
    listener: GenericFunction,
    options?: boolean | Ng.WatchOptions
  ) {
    // ...
    const objectEquality =
      typeof options === 'boolean' ? options : options?.objectEquality ?? false;
    // ...
    const immediate =
      typeof options === 'boolean' ? false : options?.immediate ?? false;

    const expression =
      typeof watchExpression === 'function'
        ? watchExpression
        : () => this[watchExpression];

    if (immediate) listener(expression());

    this.$scope.$watch(expression, listener, objectEquality);
  }

  /**
   * ...
   */
  $watchCollection<K extends keyof this>(
    watchExpression: K,
    listener?: ListenerCallback<this[K], this['$scope']>
  ): void;
  $watchCollection<T>(
    watchExpression: (scope: this['$scope']) => T,
    listener?: ListenerCallback<T, this['$scope']>
  ): void;
  $watchCollection(
    watchExpression: string | GenericFunction,
    listener: GenericFunction
  ) {
    if (typeof watchExpression === 'string') {
      this.$scope.$watchCollection(`vm.${watchExpression}`, listener);
    } else {
      this.$scope.$watchCollection(watchExpression, listener);
    }
  }

  /**
   * ...
   */
  $watchGroup<T extends unknown[]>(
    watchExpressions: T,
    listener: ListenerCallback<T[number]>
  ): void;
  $watchGroup<T extends unknown[]>(
    watchExpressions: Array<{ (scope: IScope): unknown }>,
    listener: ListenerCallback<T[number]>
  ): void;
  $watchGroup(watchExpressions: unknown[], listener: ListenerCallback) {
    this.$scope.$watchGroup(watchExpressions, listener);
  }

  /**
   * ...
   */
  $onInit?(): void;

  /**
   * ...
   */
  $postLink?(): void;
}

export namespace Ng {
  /**
   * ...
   */
  export interface WatchOptions {
    objectEquality?: boolean;
    immediate?: boolean;
  }
}

export default Ng;
