import angular from 'angular';
import { sortBy, find, filter } from 'lodash';

import { Action, Getter, State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

import type { Reminder } from 'types';

@Controller
class NavbarRemindersComponent {
  active: boolean = false;
  showCompleted: boolean = false;
  currentDateTime: Date = new Date();
  reminders: Reminder[] = [];
  completedCount: number = 0;
  dueCount: number = -1;
  badgeCount: number | string = null;
  loading: boolean = false;

  @Inject $scope;
  @Inject $store;
  @Inject utils;
  @Inject $modals;
  @Inject evlUtils;

  @Action('reminders/list') listReminders;
  @Action('reminders/check') checkReminder;
  @Action('clients/list') listClients;

  @Getter('clients/getById') getClient;
  @Getter('tools/getById') getTool;
  @Getter isAdmin;

  @State((state) => state.clients.items) clients;
  @State((state) => state.me.institution) myInstitution;

  async $onInit() {
    if (!this.isAdmin && !this.clients?.length) await this.listClients();

    this.$scope.$watchCollection(
      () => this.$store.state.reminders.items,
      (val) => {
        if (!val) {
          return;
        }

        let oldVal = [...this.reminders];

        this.reminders.length = 0;

        // filter out reminders that don't match up with clients list
        // mainly for multi institution users
        val = filter(val, (o) => {
          if (!o?.data?.clientId) return;
          return find(this.clients, { id: o.data.clientId });
        });

        if (!val.length) return;

        sortBy([...val], ['dueDate'])
          .map((o) => {
            let r = find(oldVal, { id: o.id });

            if (r) {
              Object.keys(o).forEach((key) => (r[key] = o[key]));
            } else {
              r = { ...o };
            }

            r.checked = o.status == 'COMPLETED';

            return r;
          })
          .forEach((o, i) => (this.reminders[i] = o));

        this.completedCount = 0;
        this.dueCount = 0;

        for (let reminder of this.reminders) {
          if (reminder.completedOn) {
            this.completedCount++;
          } else if (reminder.$daysLeft === 0) {
            this.dueCount++;
          }
        }

        this.currentDateTime = new Date();
      }
    );

    this.$scope.$watch(
      () => this.dueCount,
      (val) => {
        if (val < 0) val = 0;
        this.badgeCount = val <= 99 ? val : '99+';
      }
    );

    this.$scope.$watch(
      () => this.myInstitution,
      async () => {
        if (this.isAdmin || this.clients?.length) return;
        this.loading = true;
        await this.listClients();
        this.loading = false;
      }
    );
  }

  async toggleCheck(reminder) {
    const toggledState = !(reminder.status === 'COMPLETED');
    reminder.checked = toggledState;

    // let apiCall = toggledState ? this.checkReminder : this.uncheckReminder;

    try {
      await Promise.all([
        this.utils.wait(100),
        this.checkReminder({ reminderId: reminder.id, completed: toggledState })
      ]);
    } catch (err) {
      console.error(err);
      reminder.checked = !toggledState;
      return;
    }

    // reminder.completedOn = toggledState ? new Date() : null;

    this.$scope.$apply();
  }

  async startAssessment(reminder) {
    this.startingAssessment = true;
    let evaluations = await this.$store.dispatch(
      'evaluations/getForClient',
      reminder.data.clientId
    );
    let client = this.getClient(reminder.data.clientId);
    let tool = this.getTool(reminder.data.toolId);
    evaluations = filter(evaluations, (ev) => {
      return (
        ev.toolUsed === reminder.data.toolId && ev.status === 'IN_PROGRESS'
      );
    });
    let res = await this.$modals.evaluation.startAssessment(
      evaluations,
      client,
      tool
    );
    if (res?.newAssessment) {
      this.evlUtils.startNewEvaluation(client, tool);
    } else if (res?.continueAssessment) {
      this.evlUtils.continueEvaluation(res.continueAssessment);
    }
    this.startingAssessment = false;
  }
}

export default angular
  .module('app.navbarReminders', [])
  .directive('navbarReminders', () => ({
    restrict: 'E',
    replace: true,
    scope: true,
    template: require('./navbar-reminders.html'),
    controller: NavbarRemindersComponent,
    controllerAs: 'vm'
  })).name;
