import { Reminder } from '@interfaces/reminder';

export type ReminderModelConstructorOptions = Reminder;

/**
 * ...
 *
 * @param dateA ...
 * @param dateB ...
 * @return ...
 */
function daysBetween(dateA: string | Date, dateB: string | Date) {
  const ta = (dateA instanceof Date ? dateA : new Date(dateA)).getTime();
  const tb = (dateB instanceof Date ? dateB : new Date(dateB)).getTime();

  return Math.round((ta - tb) / 86400000);
}

/**
 * ...
 *
 * @param value ...
 * @return ...
 */
function parseDate(value: unknown) {
  return value instanceof Date
    ? value
    : typeof value === 'string'
    ? new Date(value)
    : null;
}

/**
 * Reminder data model.
 */
export class ReminderModel {
  id: string;
  createdAt: Date | null = null;
  updatedAt: Date | null = null;
  name: string;
  type: Reminder.Type;
  status: Reminder.Status;
  dueDate: Date;
  completedOn: Date | null = null;
  dismissedOn: Date | null = null;
  data: Partial<Reminder['data']> = {};
  alerts: number[];
  sendEmail: boolean;

  // private $description: string = '';
  private $daysLeft: number;
  private $hasReminder: boolean;
  // private $lastSnooze: number = Infinity;

  /**
   * ...
   */
  get daysLeft() {
    return this.$daysLeft;
  }

  /**
   * ...
   */
  get hasReminder() {
    return this.$hasReminder;
  }

  constructor(options: ReminderModelConstructorOptions) {
    const CUR_DATE = new Date();
    CUR_DATE.setHours(0, 0, 0, 0);

    this.id = options.id;
    this.name = options.name;
    this.type = options.type;
    this.createdAt = parseDate(options.createdAt);
    this.updatedAt = parseDate(options.updatedAt);
    this.completedOn = parseDate(options.completedOn);
    this.data = options.data || {};
    this.alerts = options.alerts || [];
    this.sendEmail = options.sendEmail;

    const dueDate = new Date(options.dueDate);

    this.dueDate = new Date(
      dueDate.getTime() + dueDate.getTimezoneOffset() * 60000
    );

    let dismissedOn = parseDate(options.dismissedOn);

    if (dismissedOn) {
      dismissedOn.setHours(0, 0, 0, 0);
      this.dismissedOn = dismissedOn;
    }

    this.$daysLeft = daysBetween(this.dueDate, CUR_DATE);

    let daysLeftOnLastDismiss = this.dismissedOn
      ? daysBetween(this.dueDate, this.dismissedOn)
      : null;

    this.$hasReminder = this.alerts.sort().some((o) => {
      return (
        o >= this.$daysLeft &&
        ((!daysLeftOnLastDismiss && daysLeftOnLastDismiss != 0) ||
          daysLeftOnLastDismiss > o)
      );
    });

    if (!this.$hasReminder) {
      this.$hasReminder =
        this.$daysLeft <= 0 &&
        (typeof daysLeftOnLastDismiss !== 'number' ||
          daysLeftOnLastDismiss < this.$daysLeft);
    }

    this.status = this.completedOn
      ? 'COMPLETED'
      : this.$daysLeft < 0
      ? 'PAST_DUE'
      : this.$hasReminder
      ? 'REMINDING'
      : 'PENDING';
  }
}
