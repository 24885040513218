'use strict';

import angular from 'angular';

function modalFormLink(scope, element, { key: name, callback }) {
  const $modalScope = scope.$parent;

  if ('$uibModalInstance' in $modalScope == false) {
    console.warn('[]');
    return;
  }

  const submitForm = function submitForm() {
    $modalScope.$eval(`${callback}($forms.${name})`);
  };

  const form = {
    $submit: function() {
      element.triggerHandler('submit');
    }
  };

  scope.$watch(name, ngForm => {
    if (!ngForm) {
      return;
    }

    Object.keys(ngForm).forEach(key => {
      Object.defineProperty(form, key, {
        enumerable: true,
        get: () => ngForm[key],
        set: val => (ngForm[key] = val)
      });
    });
  });

  Object.defineProperty($modalScope.$forms, name, {
    writable: true,
    enumerable: true,
    value: form
  });

  Object.defineProperties(scope, {
    formName: {
      enumerable: true,
      get: () => name
    },
    submitForm: {
      enumerable: true,
      value: submitForm
    }
  });
}

export default angular
  .module('app.modalForm', [])
  .directive('modalForm', () => {
    'ngInject';

    return {
      restrict: 'E',
      replace: true,
      transclude: true,
      scope: true,
      template: require('./modal-form.html'),
      link: modalFormLink
    };
  }).name;
