import { uniqBy } from 'lodash';

function AggregateUserReportsStore($api, $acl) {
  'ngInject';

  return {
    state: {
      loading: false,
      items: [],
      table: {
        sortedCol: 0,
        searchText: ''
      },
      focus: null
    },
    getters: {
      find: (state) => (id) => state.items.find((item) => item.id == id)
    },
    actions: {
      async list({ state, commit, rootState, rootGetters }, { instId } = {}) {
        if (!instId)
          instId = state.focused ? state.focused : rootGetters.activeInstId;

        if (!instId) throw '[aggregate-user-reports] No Institution Id Found';

        state.focused = instId;

        let data = [];
        let res;
        if (
          $acl('IM:ListAggUserReportRequests', rootState.permissions.profile)
        ) {
          commit('setProps', { loading: true });

          try {
            res = await $api.IM.listAggUserReportRequests({ instId });
            data = res.data;
          } catch (err) {
            commit('setProps', { loading: false });
            throw err;
          }
        } else {
          console.warn(
            `User does not have permission to execute the action: AggregateReports:list`
          );
          return state.items;
        }

        commit('set', data);

        commit('setProps', { loading: false });

        return state.items;
      },
      async create({ dispatch, rootState, rootGetters }, payload) {
        if (!payload.instId && !this.focused && !rootGetters.activeInstId)
          throw '[aggregate-user-reports] No Institution Id Provided';

        let res;
        if (
          $acl('IM:CreateAggUserReportRequest', rootState.permissions.profile)
        ) {
          try {
            res = await $api.IM.createAggUserReportRequest(payload);
          } catch (err) {
            console.error(err);
            throw err;
          }
        } else {
          return console.warn(
            `User does not have permission to execute the action: AggregateUserReports:create`
          );
        }

        await dispatch('list', {
          instId: payload.instId
        });

        return res;
      }
    },
    mutations: {
      set(state, payload) {
        state.items = [...payload];
      },
      add(state, payload) {
        state.items = uniqBy([...payload, ...state.items], 'id');
      },
      setProps(state, props = {}) {
        for (let i in props) {
          if (i in state) {
            state[i] = props[i];
          }
        }
      },
      CLEAR(state) {
        Object.assign(state, {
          loading: false,
          items: [],
          table: {
            sortedCol: 0,
            searchText: ''
          },
          focus: null
        });
      }
    }
  };
}

export default AggregateUserReportsStore;
