import angular from 'angular';

import './viewer-image.scss';

export default angular
  .module('fileViewer.imageViewer', [])
  .directive('imageViewer', () => {
    return {
      require: '^^fileViewer',
      replace: true,
      template: require('./viewer-image.html'),
      scope: true,
      link(scope, element, attrs, viewerCtrl) {
        scope.imgSrc = viewerCtrl.filePath;

        // element.css('opacity', 0);
        // // element.css('overflow', 'hidden');
        // element.css('transition', '0.25s');
        // viewerCtrl.$element.css('overflow', 'hidden');
        //
        // setTimeout(() => {
        //   viewerCtrl.$element.css('width', `${element.width()}px`);
        //   viewerCtrl.$element.css('height', `${element.height()}px`);
        //
        //   element.css('opacity', 1);
        // });
      }
    };
  }).name;
