'use strict';
import * as _ from 'lodash';

const angular = require('angular');

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

class _BugReport {
  constructor(config = {}) {
    this.fields = {
      project: {
        key: 'GIFR'
      },
      summary: '',
      description: '',
      issuetype: {
        name: 'Bug'
      }
    };
  }
}

@Controller
class ReportBugModalController extends Modal {
  @Inject $scope;
  @Inject $http;
  @Inject notify;

  $setup() {
    this.bugReport = new _BugReport();
    this.summary = '';
    this.environment = '';
    this.url = '';
    this.stepsToReproduce = '';
    this.expectedResults = '';
    this.actualResults = '';
    this.optional = '';
    console.trace();
  }

  async submit() {
    this.loadingData = true;
    let res = await this.$http.post('/api/issues', this.bugReport);
    if (res.status !== 200 && res.status !== 204) {
      this.notify.display(res, 'error');
    } else {
      this.$close();
    }
    this.loadingData = false;
  }
}

export default angular
  .module('app.reportBugModal', [])
  .directive('reportBugModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./report-bug.html'),
    controller: ReportBugModalController,
    controllerAs: 'vm'
  })).name;
