import angular from 'angular';

import { $StoreProvider } from './provider';

export * from './decorators';
export * from './store';
export * from './types';

export default angular.module('ngStore', []).provider('$store', $StoreProvider)
  .name;
