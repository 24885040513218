'use strict';

import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class TermsView {
  site: string = '';
  $onInit() {
  }
}

export default TermsView;
