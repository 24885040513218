import angular from 'angular';

export default angular.module('app.ngMailto', []).directive('ngMailto', () => {
  'ngInject';

  return {
    restrict: 'A',
    scope: { ngMailto: '<' },
    link($scope, $element) {
      $element.attr('href', `mailto:${$scope.ngMailto}`);
    }
  };
}).name;
