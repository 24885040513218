'use strict';

import angular from 'angular';

const $t = 0.25;

const wait = function wait(t) {
  return new Promise(resolve => setTimeout(resolve, t));
};

class Expander {
  #opening: boolean = false;
  #closing: boolean = false;
  #expanded: boolean = false;
  $el: {};

  constructor(el) {
    el.css('overflow', 'hidden');
    el.css('transition', `height ${$t}s`);

    this.$el = el;
  }

  get contentHeight() {
    return this.$el[0].scrollHeight;
  }

  async open() {
    this.#opening = true;
    this.#closing = false;

    if (!this.#expanded) {
      this.#expanded = true;

      await wait();
    }

    this.setHeight(this.contentHeight);

    await wait($t * 1000);

    if (!this.#opening) {
      return;
    }

    this.#opening = false;
    this.setHeight();
  }

  async close() {
    this.#opening = false;
    this.#closing = true;

    this.setHeight(this.contentHeight);

    await wait();

    this.setHeight(0);

    await wait($t * 1000);

    if (!this.#closing) {
      return;
    }

    this.#closing = this.#expanded = false;
  }

  setHeight(h) {
    this.$el.css('height', h);
  }
}

export default angular
  .module('app.ngExpanded', [])
  .directive('ngExpanded', function() {
    'ngInject';

    return {
      restrict: 'A',
      scope: {
        ngExpanded: '='
      },
      link(scope, element) {
        const expander = new Expander(element);

        if (!scope.ngExpanded) {
          expander.setHeight(0);
        }

        scope.$watch('ngExpanded', value => {
          if (value) {
            expander.open();
          } else {
            expander.close();
          }
        });
      }
    };
  }).name;
