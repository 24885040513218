import angular from 'angular';
import idGenerator from 'shortid';

/**
 * ...
 *
 * @param text
 */
function logBanner(text: string, size = 20) {
  const padding = '-'.repeat(size);

  console.log(`<${padding} ${text} ${padding}>`);
}

/**
 * ...
 */
export class ToolManagerService {
  editToolSubmission: unknown = null;
  newToolDevGroup: unknown = null;

  constructor(
    // private readonly $http: unknown,
    private readonly Upload: unknown,
    private readonly Notification: unknown
  ) {
    'ngInject';
  }

  /**
   * ...
   *
   * @param toolSubmission ...
   * @param activeRole ...
   * @param prevCtrl ...
   * @return ...
   */
  createTool(toolSubmission: unknown, activeRole: unknown, prevCtrl: unknown) {
    //
    if (!this.parseTool(toolSubmission, true)) return;

    console.log(activeRole);

    if (activeRole.roleId !== 7 && activeRole.roleId !== 8) {
      // figure out if developer group has been assigned to tool or not
      if (activeRole.developerGroupId !== null) {
        this.newToolDevGroup = activeRole.developerGroupId;
      } else {
        this.Notification.warning({
          message: 'Must Be a Developer to Create Tools',
          positionX: 'left',
          templateUrl: 'assets/components/notification-template.html'
        });
        return;
      }
    } else if (prevCtrl.newToolDevGroup === '') {
      this.Notification.warning({
        message: 'Must Select a Developer Group',
        positionX: 'left',
        templateUrl: 'assets/components/notification-template.html'
      });
      return;
    } else {
      this.newToolDevGroup = prevCtrl.newToolDevGroup;
    }

    delete toolSubmission.display;

    console.log(toolSubmission);

    for (var i = 0; i < toolSubmission.codingFormItems.length; i++) {
      const cfi = toolSubmission.codingFormItems[i];

      console.log(cfi);

      if (!cfi.itemWizard.display) {
        // if they chose no wizard for question, make sure the questions are emptied
        logBanner(
          'itemWizard display is false! Empty the item wizard questions'
        );

        cfi.itemWizard.wizardQuestions = [];
      }

      if (cfi.itemWizard.calculation) {
        logBanner('itemWizard has a calculation');

        cfi.itemWizard.calculation.open = false;

        if (cfi.itemWizard.calculation) {
          logBanner('build variablesObject');

          let variablesObject: unknown = {};

          for (const { variableName, defaultValue } of cfi.itemWizard
            .calculation.variables) {
            variablesObject[variableName] = defaultValue;
          }

          cfi.itemWizard.calculation.variables = variablesObject;

          logBanner('DONE building variablesObject');

          console.log(cfi.itemWizard.calculation.variables);
        }
      }

      // Remove display options.
      delete cfi.open;
      delete cfi.itemWizard.display;

      logBanner('go through the wizardQuestions');

      for (var k = 0; k < cfi.itemWizard.wizardQuestions.length; k++) {
        const wq = cfi.itemWizard.wizardQuestions[k];

        delete wq.open;

        logBanner('go through the wizardAnswers');

        for (const wa of wq.wizardAnswers) {
          if (wa.action === 2) {
            // they chose to spawn modal
            logBanner('ANSWER SPAWNS A MODAL');
            wa.spawnModal = true;
          } else if (wa.action === 3) {
            logBanner('ANSWER RESOLVES VARIABLES');
            wa.resolveVariables = true;
          }

          delete wa.action;
        }
      }

      logBanner(
        'check if itemWizard has a calculation field to remove "open" properties'
      );

      if (cfi.itemWizard.calculation) {
        for (k = 0; k < cfi.itemWizard.calculation.length; k++) {
          delete cfi.itemWizard.calculation.open;
        }
      }
    }

    console.log('Done parsing tool');
    console.log(toolSubmission);

    // prep for submission
    let originalTool = toolSubmission;
    prevCtrl.toolSubmitted = true;

    if (originalTool.pdfDescription) {
      this.Upload.rename(
        originalTool.pdfDescription,
        originalTool.pdfDescription.name + '_desc'
      ); // rename the description to the backend knows which file is which
    }

    let files = [];

    if (originalTool.pdfDocument) {
      files.push(originalTool.pdfDocument);
    }

    if (originalTool.pdfDescription) {
      files.push(originalTool.pdfDescription);
    }

    // fs.writeFile('toolSubmission.json', toolSubmission, 'utf8');
    console.log(JSON.stringify(toolSubmission));

    this.Upload.upload({
      url: '/api/tools',
      data: {
        name: toolSubmission.name,
        data: JSON.stringify(toolSubmission),
        notes: prevCtrl.toolNotes,
        developerGroupId: this.newToolDevGroup
      },
      file: files
    }).then((response) => {
      prevCtrl.toolSubmitted = false;

      if (!response.data.error) {
        this.Notification.success({
          message: 'Tool Created',
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });

        prevCtrl.newTool = {
          // base tool object
          name: '',
          version: '0.0.1',
          pdfDocumentName: '',
          pdfDocument: '',
          dictionary: [],
          display: {
            dictionary: true
          },
          codingFormItems: [
            {
              id: 'Q-' + idGenerator.generate(),
              riskFactor: '',
              codesAndScore: [
                {
                  id: 'A-' + idGenerator.generate(),
                  text: 'Score Label 1',
                  score: 0
                }
              ],
              open: true,
              itemWizard: {
                display: false,
                wizardQuestions: [
                  {
                    id: 'WQ-' + idGenerator.generate(),
                    questionType: 0,
                    questionText: '',
                    pdfItemHelpBookmark: '',
                    pdfItemPage: '',
                    wizardAnswers: [
                      {
                        id: 'WA-' + idGenerator.generate(),
                        text: '',
                        nextWizardQuestionId: '',
                        answerScore: '',
                        spawnModal: false,
                        modalText: ''
                      },
                      {
                        id: 'WA-' + idGenerator.generate(),
                        text: '',
                        nextWizardQuestionId: '',
                        answerScore: '',
                        spawnModal: false,
                        modalText: ''
                      }
                    ],
                    open: true
                  }
                ]
              },
              pdfItemHelpBookmark: '',
              pdfItemPage: ''
            }
          ],
          riskCategories: [
            {
              name: 'Risk Level 0',
              low: 0,
              high: 1,
              isHighRisk: false
            }
          ]
        };

        prevCtrl.changeSection('tools');
        prevCtrl.loadInfo();
      } else {
        this.Notification.warning({
          message: 'Error - Tool Creation',
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });
      }
    });
  }

  /**
   * ...
   *
   * @param tool ...
   * @param prevCtrl ...
   * @return ...
   */
  editTool(tool: unknown, prevCtrl: unknown) {
    this.editToolSubmission = angular.copy(tool);

    console.log(this.editToolSubmission);

    if (this.parseTool(this.editToolSubmission, false)) {
      delete this.editToolSubmission.display;
      for (var i = 0; i < this.editToolSubmission.codingFormItems.length; i++) {
        Reflect.deleteProperty(
          this.editToolSubmission.codingFormItems[i],
          'open'
        ); // remove display options
        if (this.editToolSubmission.codingFormItems[i].itemWizard) {
          if (!this.editToolSubmission.codingFormItems[i].itemWizard.display) {
            // if they chose no wizard for question, make sure the questions are emptied
            this.editToolSubmission.codingFormItems[
              i
            ].itemWizard.wizardQuestions = [];
          }
          if (
            this.editToolSubmission.codingFormItems[i].itemWizard.calculation
          ) {
            var variablesObject = {};
            for (
              var m = 0;
              m <
              this.editToolSubmission.codingFormItems[i].itemWizard.calculation
                .variables.length;
              m++
            ) {
              variablesObject[
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.calculation.variables[m].variableName
              ] = this.editToolSubmission.codingFormItems[
                i
              ].itemWizard.calculation.variables[m].defaultValue;
            }
            this.editToolSubmission.codingFormItems[
              i
            ].itemWizard.calculation.variables = variablesObject;
            console.log(
              this.editToolSubmission.codingFormItems[i].itemWizard.calculation
                .variables
            );
          }
          Reflect.deleteProperty(
            this.editToolSubmission.codingFormItems[i].itemWizard,
            'display'
          ); // remove display options
          for (
            var k = 0;
            k <
            this.editToolSubmission.codingFormItems[i].itemWizard
              .wizardQuestions.length;
            k++
          ) {
            Reflect.deleteProperty(
              this.editToolSubmission.codingFormItems[i].itemWizard
                .wizardQuestions[k],
              'open'
            );
            for (
              var l = 0;
              l <
              this.editToolSubmission.codingFormItems[i].itemWizard
                .wizardQuestions[k].wizardAnswers.length;
              l++
            ) {
              if (
                this.editToolSubmission.codingFormItems[i].id === 'Q1' &&
                this.editToolSubmission.codingFormItems[i].itemWizard
                  .wizardQuestions[k].wizardAnswers[l].id === 'WA1' &&
                this.editToolSubmission.codingFormItems[i].itemWizard
                  .wizardQuestions[k].id === 'WQ18'
              ) {
                console.log(
                  angular.copy(
                    this.editToolSubmission.codingFormItems[i].itemWizard
                      .wizardQuestions[k].wizardAnswers[l]
                  )
                );
              }
              if (
                this.editToolSubmission.codingFormItems[i].itemWizard
                  .wizardQuestions[k].wizardAnswers[l].action === 2
              ) {
                // they chose to spawn modal
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].spawnModal = true;
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].nextWizardQuestionId = '';
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].resolveVariables = null;
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[l].answerScore =
                  '';
              } else if (
                this.editToolSubmission.codingFormItems[i].itemWizard
                  .wizardQuestions[k].wizardAnswers[l].action === 3
              ) {
                // resolve variables
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].resolveVariables = true;
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].spawnModal = false;
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[l].answerScore =
                  '';
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[l].modalText = '';
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].nextWizardQuestionId = '';
              } else if (
                this.editToolSubmission.codingFormItems[i].itemWizard
                  .wizardQuestions[k].wizardAnswers[l].action === 0
              ) {
                // give score
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[l].modalText = '';
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].nextWizardQuestionId = '';
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].spawnModal = false;
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].resolveVariables = null;
              } else if (
                this.editToolSubmission.codingFormItems[i].itemWizard
                  .wizardQuestions[k].wizardAnswers[l].action === 1
              ) {
                // next wizard question
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].spawnModal = false;
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[
                  l
                ].resolveVariables = null;
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[l].modalText = '';
                this.editToolSubmission.codingFormItems[
                  i
                ].itemWizard.wizardQuestions[k].wizardAnswers[l].answerScore =
                  '';
              }
              Reflect.deleteProperty(
                this.editToolSubmission.codingFormItems[i].itemWizard
                  .wizardQuestions[k].wizardAnswers[l],
                'action'
              );
            }
          }
          if (
            this.editToolSubmission.codingFormItems[i].itemWizard.calculation
          ) {
            for (
              k = 0;
              k <
              this.editToolSubmission.codingFormItems[i].itemWizard.calculation
                .length;
              k++
            ) {
              Reflect.deleteProperty(
                this.editToolSubmission.codingFormItems[i].itemWizard
                  .calculation,
                'open'
              );
            }
          }
        }
      }
      // prep for submission
      console.log(this.editToolSubmission);
      this.editToolSubmission = JSON.stringify(this.editToolSubmission);
      prevCtrl.toolSubmitted = true;
      // submit tool here
      // check if PDF is submitted
      if (tool.pdfDocument && tool.pdfDescription) {
        // new pdf document and description were given
        this.Upload.rename(
          tool.pdfDescription,
          tool.pdfDescription.name + '_desc'
        );
        this.Upload.upload({
          url: '/api/tools/' + tool.id,
          data: {
            version: tool.version,
            name: tool.name,
            data: this.editToolSubmission,
            notes: tool.notes,
            developerGroupId: prevCtrl.editToolDevGroup
          },
          file: [tool.pdfDocument, tool.pdfDescription]
        }).then((response) => {
          prevCtrl.toolSubmitted = false;
          if (!response.data.error) {
            this.Notification.success({
              message: 'Tool Updated',
              positionX: 'center',
              templateUrl: 'assets/components/notification-template.html'
            });
            prevCtrl.loadInfo();
            prevCtrl.changeSection('tools');
          } else {
            this.Notification.warning({
              title: 'Error - Tool Update',
              message: response.data.feedback,
              positionX: 'center',
              templateUrl: 'assets/components/notification-template.html'
            });
          }
        });
      } else if (tool.pdfDocument) {
        // only new pdf document was selected
        this.Upload.upload({
          url: '/api/tools/' + tool.id,
          data: {
            version: tool.version,
            name: tool.name,
            data: this.editToolSubmission,
            notes: tool.notes,
            developerGroupId: prevCtrl.editToolDevGroup
          },
          file: tool.pdfDocument
        }).then((response) => {
          prevCtrl.toolSubmitted = false;
          if (!response.data.error) {
            this.Notification.success({
              message: 'Tool Updated',
              positionX: 'center',
              templateUrl: 'assets/components/notification-template.html'
            });
            prevCtrl.loadInfo();
            prevCtrl.changeSection('tools');
          } else {
            this.Notification.warning({
              title: 'Error - Tool Update',
              message: response.data.feedback,
              positionX: 'center',
              templateUrl: 'assets/components/notification-template.html'
            });
          }
        });
      } else if (tool.pdfDescription) {
        // only new pdf description was given
        this.Upload.rename(
          tool.pdfDescription,
          tool.pdfDescription.name + '_desc'
        );
        this.Upload.upload({
          url: '/api/tools/' + tool.id,
          data: {
            version: tool.version,
            name: tool.name,
            data: this.editToolSubmission,
            notes: tool.notes,
            developerGroupId: prevCtrl.editToolDevGroup
          },
          file: tool.pdfDescription
        }).then((response) => {
          prevCtrl.toolSubmitted = false;
          if (!response.data.error) {
            this.Notification.success({
              message: 'Tool Updated',
              positionX: 'center',
              templateUrl: 'assets/components/notification-template.html'
            });
            prevCtrl.loadInfo();
            prevCtrl.changeSection('tools');
          } else {
            this.Notification.warning({
              title: 'Error - Tool Update',
              message: response.data.feedback,
              positionX: 'center',
              templateUrl: 'assets/components/notification-template.html'
            });
          }
        });
      } else {
        // pdf document was not selected
        console.log(this.editToolSubmission);
        $http
          .post('/api/tools/' + tool.id, {
            version: tool.version,
            name: tool.name,
            data: this.editToolSubmission,
            notes: tool.notes,
            developerGroupId: prevCtrl.editToolDevGroup
          })
          .then((response) => {
            console.log(response);
            prevCtrl.toolSubmitted = false;
            if (!response.data.error) {
              this.Notification.success({
                message: 'Tool Updated',
                positionX: 'center',
                templateUrl: 'assets/components/notification-template.html'
              });
              prevCtrl.loadInfo();
              prevCtrl.changeSection('tools');
            } else {
              if (response.data.feedback.message) {
                this.Notification.warning({
                  title: 'Error - Tool Update',
                  message: response.data.feedback.message,
                  positionX: 'center',
                  templateUrl: 'assets/components/notification-template.html'
                });
              } else {
                this.Notification.warning({
                  title: 'Error - Tool Update',
                  message: response.data.feedback,
                  positionX: 'center',
                  templateUrl: 'assets/components/notification-template.html'
                });
              }
            }
          });
      }
    }
  }

  /**
   * Parse tool.
   *
   * @param tool ...
   * @param newTool ...
   * @return ...
   */
  parseTool(tool: unknown, newTool: boolean) {
    if (!newTool) {
      if (tool.changelog === '' || !tool.changelog) {
        this.Notification.error({
          message: 'Changelog must be given on tool update',
          positionX: 'center',
          templateUrl: 'assets/components/notification-template.html'
        });
        return false;
      }
    }
    var errorItems = [];
    if (!tool.name || tool.name === '') {
      errorItems.push({
        item: 'Name',
        message: 'Tool must have a name.'
      });
    }
    var questionIds = [];
    var lowestScore = 0;
    var highestScore = 0;
    var qLowestScore = 0;
    var qHighestScore = 0;
    var questionScoreIds = [];
    var wizardQuestionIds = [];
    var wizardAnswerIds = [];
    var i, j, k;
    for (i = 0; i < tool.codingFormItems.length; i++) {
      // console.log(tool.codingFormItems[i]);
      questionScoreIds = []; // empty out score id's array
      questionIds.push(tool.codingFormItems[i].id);
      // check riskFactor fields for each question
      if (
        !tool.codingFormItems[i].riskFactor ||
        tool.codingFormItems[i].riskFactor === ''
      ) {
        errorItems.push({
          item: 'Question ' + tool.codingFormItems[i].id + ' Text',
          message: 'Question must have question text.'
        });
      }
      // build questionScoreIds array
      qLowestScore = 0;
      qHighestScore = 0;
      for (j = 0; j < tool.codingFormItems[i].codesAndScore.length; j++) {
        questionScoreIds.push(tool.codingFormItems[i].codesAndScore[j].id);
        if (tool.codingFormItems[i].codesAndScore[j].score < qLowestScore) {
          qLowestScore = tool.codingFormItems[i].codesAndScore[j].score;
        }
        if (tool.codingFormItems[i].codesAndScore[j].score > qHighestScore) {
          qHighestScore = tool.codingFormItems[i].codesAndScore[j].score;
        }
        if (
          !tool.codingFormItems[i].codesAndScore[j].text ||
          tool.codingFormItems[i].codesAndScore[j].text === ''
        ) {
          errorItems.push({
            item:
              'Answer Text for ' +
              tool.codingFormItems[i].id +
              ' ' +
              tool.codingFormItems[i].codesAndScore[j].id,
            message: 'Answers must have text field'
          });
        }
      }
      lowestScore += qLowestScore;
      highestScore += qHighestScore;
      // check questionScoreIds for duplicates
      const count = (questionScoreIds) =>
        questionScoreIds.reduce(
          (a, b) =>
            Object.assign(a, {
              [b]: (a[b] || 0) + 1
            }),
          {}
        );
      const duplicates = (dict) => Object.keys(dict).filter((a) => dict[a] > 1);
      if (duplicates(count(questionScoreIds)).length) {
        errorItems.push({
          item: 'Question ' + tool.codingFormItems[i].id + ' Answers',
          message:
            'Duplicate Answer IDs: ' + duplicates(count(questionScoreIds))
        });
      }
      // check if there's a wizard
      if (tool.codingFormItems[i].itemWizard) {
        if (tool.codingFormItems[i].itemWizard.display) {
          wizardQuestionIds = [];
          if (tool.codingFormItems[i].itemWizard.references) {
            for (
              j = 0;
              j < tool.codingFormItems[i].itemWizard.references.length;
              j++
            ) {
              if (
                !tool.codingFormItems[i].itemWizard.references[j].page ||
                !tool.codingFormItems[i].itemWizard.references[j].icon
              ) {
                errorItems.push({
                  item: 'Wizard References ' + tool.codingFormItems[i].id,
                  message: 'References must have a page and an icon'
                });
              }
            }
          }
          for (
            j = 0;
            j < tool.codingFormItems[i].itemWizard.wizardQuestions.length;
            j++
          ) {
            // build wizardQuestionIds array
            wizardQuestionIds.push(
              tool.codingFormItems[i].itemWizard.wizardQuestions[j].id
            );
            // console.log(tool.codingFormItems[i].itemWizard.wizardQuestions[j]);
          }
          for (
            j = 0;
            j < tool.codingFormItems[i].itemWizard.wizardQuestions.length;
            j++
          ) {
            if (
              !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                .questionText ||
              tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                .questionText === ''
            ) {
              errorItems.push({
                item:
                  'Wizard Question ' +
                  tool.codingFormItems[i].itemWizard.wizardQuestions[j].id,
                message: 'Wizard questions must have text field'
              });
            }
            if (
              tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                .questionType === 2
            ) {
              if (
                !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .multipleAnswerConditions ||
                !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .multipleAnswerConditions.length
              ) {
                errorItems.push({
                  item:
                    'Wizard Question ' +
                    tool.codingFormItems[i].itemWizard.wizardQuestions[j].id,
                  message:
                    'Multiple choice/multiple answer wizard questions must have answer conditions.'
                });
              }
              for (
                k = 0;
                k <
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .multipleAnswerConditions.length;
                k++
              ) {
                if (
                  tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .multipleAnswerConditions[k].action === 'giveScore'
                ) {
                  if (
                    tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                      .multipleAnswerConditions[k].answerScore !== 0 &&
                    !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                      .multipleAnswerConditions[k].answerScore
                  ) {
                    errorItems.push({
                      item:
                        'Wizard Question ' +
                        tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                          .id,
                      message:
                        'Multiple choice/multiple answer condition that gives score must have a number.'
                    });
                  }
                  tool.codingFormItems[i].itemWizard.wizardQuestions[
                    j
                  ].multipleAnswerConditions[k].nextWizardQuestionId = null;
                } else if (
                  tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .multipleAnswerConditions[k].action === 'nextQuestion'
                ) {
                  if (
                    !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                      .multipleAnswerConditions[k].nextWizardQuestionId
                  ) {
                    errorItems.push({
                      item:
                        'Wizard Question ' +
                        tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                          .id,
                      message:
                        'Multiple choice/multiple answer condition that leads to another question must have a question selected.'
                    });
                  }
                  tool.codingFormItems[i].itemWizard.wizardQuestions[
                    j
                  ].multipleAnswerConditions[k].answerScore = null;
                }
                if (
                  tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .multipleAnswerConditions[k].right !== 0 &&
                  !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .multipleAnswerConditions[k].right
                ) {
                  errorItems.push({
                    item:
                      'Wizard Question ' +
                      tool.codingFormItems[i].itemWizard.wizardQuestions[j].id,
                    message:
                      'Multiple choice/multiple answer condition must have a number to compare.'
                  });
                }
                if (
                  !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .multipleAnswerConditions[k].operation
                ) {
                  errorItems.push({
                    item:
                      'Wizard Question ' +
                      tool.codingFormItems[i].itemWizard.wizardQuestions[j].id,
                    message:
                      'Multiple choice/multiple answer condition must have an operation selected.'
                  });
                }
              }
            }
            // build wizardAnswerIds array
            wizardAnswerIds = [];
            for (
              k = 0;
              k <
              tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                .wizardAnswers.length;
              k++
            ) {
              if (
                !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers[k].text ||
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers[k].text === ''
              ) {
                errorItems.push({
                  item:
                    'Wizard Answer ' +
                    tool.codingFormItems[i].id +
                    ' | ' +
                    tool.codingFormItems[i].itemWizard.wizardQuestions[j].id +
                    ' | ' +
                    tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                      .wizardAnswers[k].id,
                  message: 'Wizard answers must have text field'
                });
              }
              if (
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .questionType === 2
              ) {
                // if answer is part of multipl choice/multiple answer
                if (
                  !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .wizardAnswers[k].increment &&
                  tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .wizardAnswers[k].increment != 0
                ) {
                  errorItems.push({
                    item:
                      'Wizard Answer Increment ' +
                      tool.codingFormItems[i].id +
                      ' | ' +
                      tool.codingFormItems[i].itemWizard.wizardQuestions[j].id +
                      ' | ' +
                      tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                        .wizardAnswers[k].id,
                    message:
                      'Wizard answers for multiple answer questions must have an increment.'
                  });
                }
              } else if (
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers[k].action === 2
              ) {
                // if answer spawns a modal
                tool.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].spawnModal = true;
                if (
                  !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .wizardAnswers[k].modalText ||
                  tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .wizardAnswers[k].modalText === ''
                ) {
                  errorItems.push({
                    item:
                      'Wizard Answer Modal ' +
                      tool.codingFormItems[i].id +
                      ' | ' +
                      tool.codingFormItems[i].itemWizard.wizardQuestions[j].id +
                      ' | ' +
                      tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                        .wizardAnswers[k].id,
                    message:
                      'Wizard answers that spawn modals must have modal text fields'
                  });
                }
              } else if (
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers[k].action === 1
              ) {
                // if answer goes to next question
                if (
                  wizardQuestionIds.indexOf(
                    tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                      .wizardAnswers[k].nextWizardQuestionId
                  ) === -1
                ) {
                  errorItems.push({
                    item:
                      'Wizard Answer Next Wizard Question ' +
                      tool.codingFormItems[i].id +
                      ' | ' +
                      tool.codingFormItems[i].itemWizard.wizardQuestions[j].id +
                      ' | ' +
                      tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                        .wizardAnswers[k].id,
                    message: 'Next wizard question not found.'
                  });
                }
              } else if (
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers[k].action === 3
              ) {
                // if answer resolves variables
                continue;
              } else if (
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers[k].answerScore !== 0 &&
                !tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers[k].answerScore
              ) {
                // if answer gives a score
                // console.log(tool.codingFormItems[i].itemWizard.wizardQuestions[j].wizardAnswers[k].answerScore);
                tool.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].nextWizardQuestionId = null;
                errorItems.push({
                  item:
                    'Wizard Answer Give Score ' +
                    tool.codingFormItems[i].id +
                    ' | ' +
                    tool.codingFormItems[i].itemWizard.wizardQuestions[j].id +
                    ' | ' +
                    tool.codingFormItems[i].itemWizard.wizardQuestions[j].id +
                    ' ' +
                    tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                      .wizardAnswers[k].id,
                  message:
                    'If a Wizard Answer does not spawn a modal or lead to another wizard question, it must give score.'
                });
              }
              wizardAnswerIds.push(
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers[k].id
              );
            }
            // check wizardAnswerIds array for duplicates
            const wACount = (wizardAnswerIds) =>
              wizardAnswerIds.reduce(
                (a, b) =>
                  Object.assign(a, {
                    [b]: (a[b] || 0) + 1
                  }),
                {}
              );
            const wADuplicates = (dict) =>
              Object.keys(dict).filter((a) => dict[a] > 1);
            if (wADuplicates(wACount(wizardAnswerIds)).length) {
              errorItems.push({
                item:
                  'Wizard ' +
                  tool.codingFormItems[i].itemWizard.wizardQuestions[j].id +
                  ' Answers',
                message:
                  'Duplicate Wizard Answer IDs: ' +
                  wADuplicates(wACount(wizardAnswerIds))
              });
            }
          }
          // check wizardQuestionIds array for duplicates
          const wQCount = (wizardQuestionIds) =>
            wizardQuestionIds.reduce(
              (a, b) =>
                Object.assign(a, {
                  [b]: (a[b] || 0) + 1
                }),
              {}
            );
          const wQDuplicates = (dict) =>
            Object.keys(dict).filter((a) => dict[a] > 1);
          if (wQDuplicates(wQCount(wizardQuestionIds)).length) {
            errorItems.push({
              item: 'Wizard Questions',
              message:
                'Duplicate Wizard Question IDs: ' +
                wQDuplicates(wQCount(wizardQuestionIds))
            });
          }
        }
      }
    }
    // check questionIds for duplicates
    const qCount = (questionIds) =>
      questionIds.reduce(
        (a, b) =>
          Object.assign(a, {
            [b]: (a[b] || 0) + 1
          }),
        {}
      );
    const qDuplicates = (dict) => Object.keys(dict).filter((a) => dict[a] > 1);
    if (qDuplicates(qCount(questionIds)).length) {
      errorItems.push({
        item: 'Duplicate Question IDs',
        message: qDuplicates(qCount(questionIds))
      });
    }
    // check risk categories
    for (i = 0; i < tool.riskCategories.length; i++) {
      if (tool.riskCategories[i].low < lowestScore) {
        errorItems.push({
          item: 'Risk Category - Low',
          message: 'Low risk category exceeds lowest possible score.'
        });
      }
      // console.log(tool.riskCategories[i].high + ' | ' + highestScore);
      if (tool.riskCategories[i].high > highestScore) {
        errorItems.push({
          item: 'Risk Category - High',
          message: 'High risk category exceeds highest possible score.'
        });
      }
    }
    // check errorItems and return notification
    if (errorItems.length) {
      for (i = 0; i < errorItems.length; i++) {
        this.Notification.warning({
          title: errorItems[i].item,
          message: errorItems[i].message,
          positionX: 'left',
          delay: true,
          templateUrl: 'assets/components/notification-template.html',
          maxCount: 10
        });
      }
      if (errorItems.length > 3) {
        var note = Notification;
        var closeAll = function () {
          note.clearAll();
        };
        this.Notification.success({
          message: 'Close All Notifications',
          positionX: 'left',
          delay: true,
          templateUrl: 'assets/components/notification-template.html',
          onClose: function () {
            closeAll();
          }
        });
      }
      return false;
    } else {
      // parse tool and null/empty out fields that aren't necessary. i.e. if wizQuestion.questionType === 2, wizAnswer.increment should be the only fields filled out in each answer
      for (i = 0; i < tool.codingFormItems.length; i++) {
        if (tool.codingFormItems[i].itemWizard) {
          for (
            j = 0;
            j < tool.codingFormItems[i].itemWizard.wizardQuestions.length;
            j++
          ) {
            if (
              tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                .questionType === 2
            ) {
              // if multiple choice/multiple answer question type
              for (
                k = 0;
                k <
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers.length;
                k++
              ) {
                tool.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].spawnModal = false;
                tool.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].answerScore = null;
                tool.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].modalText = '';
                tool.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].nextWizardQuestionId = '';
              }
            } else {
              tool.codingFormItems[i].itemWizard.wizardQuestions[
                j
              ].multipleAnswerConditions = [];
              for (
                k = 0;
                k <
                tool.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .wizardAnswers.length;
                k++
              ) {
                tool.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].increment = '';
                tool.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].resolveVariables = null;
              }
            }
          }
        }
      }

      return true;
    }
  }

  /**
   * Parse template.
   *
   * @param template ...
   * @return ...
   */
  parseTemplate(template: unknown) {
    // TEMPORARY TO ALLOW FOR PARTIAL TEMPLATE SUBMISSIONS. TURN OFF ONCE WE
    // HAVE AUTO SAVE VIA LOCALSTORAGE AND WARNING TO SUBMIT PARTIAL TEMPLATE.
    return true;

    // break binding to template in DOM
    template = angular.copy(template);

    var errorItems = [];
    if (!template.name || template.name === '') {
      errorItems.push({
        item: 'Name',
        message: 'Template must have a name.'
      });
    }
    var key, i, j, k;
    var optionTitles = [];

    for (key in template.reportCriteria.display) {
      if (!template.reportCriteria.display[key]) {
        Reflect.deleteProperty(template.reportCriteria, key);
      }
    }

    for (key in template.reportCriteria) {
      if (key === 'overview') {
        if (
          template.reportCriteria[key].title === '' ||
          template.reportCriteria[key].title === null ||
          template.reportCriteria[key].title === undefined
        ) {
          errorItems.push({
            item: 'Overview',
            message: 'Overview must have a section title.'
          });
        }
        if (template.reportCriteria[key].options) {
          for (i = 0; i < template.reportCriteria[key].options.length; i++) {
            if (
              template.reportCriteria[key].options[i].text === '' ||
              template.reportCriteria[key].options[i].text === null ||
              template.reportCriteria[key].options[i].text === undefined
            ) {
              errorItems.push({
                item: 'Overview',
                message: 'Overview options must have a title.'
              });
            }
            for (
              j = 0;
              j < template.reportCriteria[key].options[i].content.length;
              j++
            ) {
              if (
                template.reportCriteria[key].options[i].content[j].text ===
                  '' ||
                template.reportCriteria[key].options[i].content.text === null ||
                template.reportCriteria[key].options[i].content[j].text ===
                  undefined
              ) {
                errorItems.push({
                  item:
                    'Overview | Option: ' +
                    template.reportCriteria[key].options[i].text,
                  message: 'Overview options must have content.'
                });
              }
            }
          }
        }
      } else if (key === 'additionadditionalCriteria') {
        for (i = 0; i < template.reportCriteria[key].length; i++) {
          if (
            template.reportCriteria[key][i].title === '' ||
            template.reportCriteria[key][i].title === null ||
            template.reportCriteria[key][i].title === undefined
          ) {
            errorItems.push({
              item: 'Additional Criteria',
              message: 'Sections must have a Section Title.'
            });
          }
          if (template.reportCriteria[key][i].options) {
            optionTitles = [];
            for (
              j = 0;
              j < template.reportCriteria[key][i].options.length;
              j++
            ) {
              optionTitles.push(
                template.reportCriteria[key][i].options[j].text
              ); // add title to array to check for uniqueness later
              if (
                template.reportCriteria[key][i].options[j].text === '' ||
                template.reportCriteria[key][i].options[j].text === null ||
                template.reportCriteria[key][i].options[j].text === undefined
              ) {
                errorItems.push({
                  item:
                    'Additional Criteria | Section: ' +
                    template.reportCriteria[key][i].title,
                  message: 'Options must have an Option Title.'
                });
              }
              for (
                k = 0;
                k < template.reportCriteria[key][i].options[j].content.length;
                k++
              ) {
                // console.log(template.reportCriteria[key][i].options[j].content[k]);
                if (
                  template.reportCriteria[key][i].options[j].content[k].type ===
                  0
                ) {
                  if (
                    template.reportCriteria[key][i].options[j].content[k]
                      .text === '' ||
                    template.reportCriteria[key][i].options[j].content[k]
                      .text === null ||
                    template.reportCriteria[key][i].options[j].content[k]
                      .text === undefined
                  ) {
                    errorItems.push({
                      item:
                        'Additional Criteria | Section: ' +
                        template.reportCriteria[key][i].title +
                        ' | Option: ' +
                        template.reportCriteria[key][i].options[j].text +
                        ' | Content Section ' +
                        (k + 1),
                      message: 'Text Content Sections must have content text.'
                    });
                  }
                } else if (
                  template.reportCriteria[key][i].options[j].content[k].type ===
                  1
                ) {
                  if (
                    template.reportCriteria[key][i].options[j].content[k]
                      .image === '' ||
                    template.reportCriteria[key][i].options[j].content[k]
                      .image === null ||
                    template.reportCriteria[key][i].options[j].content[k]
                      .image === undefined
                  ) {
                    errorItems.push({
                      item:
                        'Additional Criteria | Section: ' +
                        template.reportCriteria[key][i].title +
                        ' | Option: ' +
                        template.reportCriteria[key][i].options[j].text +
                        ' | Content Section ' +
                        (k + 1),
                      message: 'Image Content Sections must have an image.'
                    });
                  }
                } else if (
                  template.reportCriteria[key][i].options[j].content[k].type ===
                  2
                ) {
                  if (
                    template.reportCriteria[key][i].options[j].content[k]
                      .text === '' ||
                    template.reportCriteria[key][i].options[j].content[k]
                      .text === null ||
                    template.reportCriteria[key][i].options[j].content[k]
                      .text === undefined
                  ) {
                    errorItems.push({
                      item:
                        'Additional Criteria | Section: ' +
                        template.reportCriteria[key][i].title +
                        ' | Option: ' +
                        template.reportCriteria[key][i].options[j].text +
                        ' | Content Section ' +
                        (k + 1),
                      message:
                        'Chart Content Sections must have a chart selected.'
                    });
                  }
                }
              }
            }
            // check uniqueness of options titles
            if (optionTitles.length) {
              for (k = 0; k < optionTitles.length; k++) {
                for (j = 0; j < optionTitles.length; j++) {
                  if (optionTitles[k] === optionTitles[j] && k != j) {
                    errorItems.push({
                      item:
                        'Additional Criteria | Section: ' +
                        template.reportCriteria[key][i].title,
                      message:
                        'Option Titles must be unique within each criteria section.'
                    });
                  }
                }
              }
            }
          }
        }
      } else if (key === 'chartData') {
        for (i = 0; i < template.reportCriteria[key].length; i++) {
          // console.log(template.reportCriteria[key][i])
          if (
            template.reportCriteria[key][i].options.title.text === '' ||
            template.reportCriteria[key][i].options.title.text === null ||
            template.reportCriteria[key][i].options.title.text === undefined
          ) {
            errorItems.push({
              item: 'Chart Data',
              message: 'Charts must have titles.'
            });
          }
          if (
            template.reportCriteria[key][i].data[0].length !=
            template.reportCriteria[key][i].labels.length
          ) {
            errorItems.push({
              item:
                'Chart Data: ' +
                template.reportCriteria[key][i].options.title.text,
              message: 'Labels and Data must have same number of items.'
            });
          }
        }
      }
    }

    if (errorItems.length) {
      for (i = 0; i < errorItems.length; i++) {
        this.Notification.error({
          title: errorItems[i].item,
          message: errorItems[i].message,
          positionX: 'left',
          delay: true,
          templateUrl: 'assets/components/notification-template.html'
        });
      }
      if (errorItems.length > 3) {
        var note = Notification;
        var closeAll = function () {
          note.clearAll();
        };
        this.Notification.success({
          message: 'Close All Notifications',
          positionX: 'left',
          delay: true,
          templateUrl: 'assets/components/notification-template.html',
          onClose: function () {
            closeAll();
          }
        });
      }
      return false;
    } else {
      return true;
    }
  }

  /**
   * ...
   *
   * @param id ...
   * @param template ...
   * @return ...
   */
  findIDMatch(id: string, template: unknown) {
    if (!template.itemDescriptions) return null;

    if (template.itemDescriptions.descriptions.length) {
      for (const description of template.itemDescriptions.descriptions) {
        if (id === description.codingFormItemId) {
          console.log('✓ Found Match on codingFormItemId');

          return description;
        }

        for (const ad of description.answerDescriptions) {
          if (ad.id === id) {
            console.log('✓ Found Match on answerDescription id');

            return ad;
          }

          console.log(ad);

          for (const answer of ad.answers) {
            if (answer.answerText.includes('Score Outcome:')) {
              if (answer.answerText === id) {
                // for MCMA, check for answerText match
                console.log(
                  '✓ Found Match on answerDescription answer text match'
                );

                return answer;
              }
            } else if (answer.id === id) {
              console.log('✓ Found Match on answerDescription answer id');

              return answer;
            }
          }
        }
      }
    }

    return null;
  }

  /**
   * Regenerate item descriptions.
   *
   * @param tool ...
   * @param oldTemplate ...
   * @param newTemplate ...
   * @return ...
   */
  regenerateItemDescriptions(
    tool: unknown,
    oldTemplate: unknown,
    newTemplate: unknown
  ) {
    let template = {
      reportCriteria: {
        itemDescriptions: {
          title: 'Item Descriptions',
          hasToggle: true,
          toggleDefault: true,
          options: [],
          descriptions: [],
          sectionType: 0
          // collapsed: false // remove on submission
        }
      }
    };

    let description = {
      codingFormItemId: '',
      codingFormItemRiskFactor: '',
      codingFormItemDescription: '',
      includeAllAnswers: false, // 3/6/2017 - Alex
      concatenate: false, // 3/6/2017 - Alex
      answerDescriptions: [],
      collapsed: false
    };

    let question = { id: '', answers: [] };

    let answer = {
      id: '',
      answerText: '',
      disclaimer: false,
      description: ''
    };

    let i, j, k;
    let descriptionMatch = null;

    for (i = 0; i < tool.file.codingFormItems.length; i++) {
      description = {
        codingFormItemId: '',
        codingFormItemRiskFactor: '',
        codingFormItemDescription: '',
        includeAllAnswers: false, // 3/6/2017 - Alex
        concatenate: false, // 3/6/2017 - Alex
        answerDescriptions: []
        // collapsed: true // REMOVE on submission
      };

      // check old report template for question id matches
      if (!newTemplate) {
        descriptionMatch = this.findIDMatch(
          tool.file.codingFormItems[i].id,
          oldTemplate.reportCriteria
        );
      }

      if (descriptionMatch) {
        description.codingFormItemDescription =
          descriptionMatch.codingFormItemDescription;
        description.concatenate = descriptionMatch.concatenate;
        description.includeAllAnswers = descriptionMatch.includeAllAnswers;
      }

      description.codingFormItemId = tool.file.codingFormItems[i].id;
      description.codingFormItemRiskFactor =
        tool.file.codingFormItems[i].riskFactor;

      console.log('itemDescription', description);

      question = {
        id: '',
        answers: []
        // collapsed: true // REMOVE on submission
      };

      question.id = tool.file.codingFormItems[i].id;

      for (j = 0; j < tool.file.codingFormItems[i].codesAndScore.length; j++) {
        // build root answer descriptions
        answer = {
          id: '',
          answerText: '',
          disclaimer: false, // 3/6/2017 - Alex
          description: '',
          wizardOverride: false, // 3/7/2017 - Alex
          wizardDescriptionOverride: '' // 3/7/2017 - Alex
        };

        if (!newTemplate) {
          descriptionMatch = null;
          descriptionMatch = this.findIDMatch(
            tool.file.codingFormItems[i].codesAndScore[j].id,
            oldTemplate.reportCriteria
          );
        }
        answer.id = tool.file.codingFormItems[i].codesAndScore[j].id;
        answer.answerText = tool.file.codingFormItems[i].codesAndScore[j].text;
        if (descriptionMatch) {
          answer.description = descriptionMatch.description;
          answer.disclaimer = descriptionMatch.disclaimer;
          answer.wizardOverride = descriptionMatch.wizardOverride;
          answer.wizardDescriptionOverride =
            descriptionMatch.wizardDescriptionOverride;
        } else {
          answer.description = ''; // empty out description
        }
        question.answers.push(answer);
      }

      description.answerDescriptions.push(question); // push root answer descriptions to answerDescriptions
      // work on the itemWizard now
      if (tool.file.codingFormItems[i].itemWizard) {
        for (
          j = 0;
          j < tool.file.codingFormItems[i].itemWizard.wizardQuestions.length;
          j++
        ) {
          // build wizard question descriptions for each wizard question
          question = {
            id: '',
            answers: [],
            questionText: ''
            // wizard: true, // REMOVE on submission
            // collapsed: true, // REMOVE on submission
          };
          question.id =
            tool.file.codingFormItems[i].itemWizard.wizardQuestions[j].id;
          question.questionText =
            tool.file.codingFormItems[i].itemWizard.wizardQuestions[
              j
            ].questionText;
          if (
            tool.file.codingFormItems[i].itemWizard.wizardQuestions[j]
              .questionType === 2
          ) {
            // multiple answer with conditions
            for (
              k = 0;
              k <
              tool.file.codingFormItems[i].itemWizard.wizardQuestions[j]
                .multipleAnswerConditions.length;
              k++
            ) {
              // build wizard answer descriptions
              answer = {
                id: '',
                answerText: '',
                disclaimer: false, // 3/6/2017 - Alex
                description: ''
              };
              answer.id = `WA-${idGenerator.generate()}`;
              let operationSymbol = '';
              if (
                tool.file.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .multipleAnswerConditions[k].operation === 'greaterThan'
              ) {
                operationSymbol = '>';
              } else if (
                tool.file.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .multipleAnswerConditions[k].operation === 'lessThan'
              ) {
                operationSymbol = '<';
              } else if (
                tool.file.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .multipleAnswerConditions[k].operation === 'equal'
              ) {
                operationSymbol = '=';
              } else if (
                tool.file.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .multipleAnswerConditions[k].operation === 'lessThanEqual'
              ) {
                operationSymbol = '<=';
              } else if (
                tool.file.codingFormItems[i].itemWizard.wizardQuestions[j]
                  .multipleAnswerConditions[k].operation === 'greaterThanEqual'
              ) {
                operationSymbol = '>=';
              }
              answer.answerText = `Score Outcome: ${operationSymbol} ${tool.file.codingFormItems[i].itemWizard.wizardQuestions[j].multipleAnswerConditions[k].right}`;
              if (!newTemplate) {
                descriptionMatch = null;
                descriptionMatch = this.findIDMatch(
                  answer.answerText,
                  oldTemplate.reportCriteria
                );
              }
              if (descriptionMatch) {
                answer.description = descriptionMatch.description;
                answer.disclaimer = descriptionMatch.disclaimer;
              } else {
                answer.description = ''; // empty out description
              }
              question.answers.push(answer);
            }
          } else {
            for (
              k = 0;
              k <
              tool.file.codingFormItems[i].itemWizard.wizardQuestions[j]
                .wizardAnswers.length;
              k++
            ) {
              // build wizard answer descriptions
              answer = {
                id: '',
                answerText: '',
                disclaimer: false, // 3/6/2017 - Alex
                description: ''
              };
              answer.id =
                tool.file.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].id;
              answer.answerText =
                tool.file.codingFormItems[i].itemWizard.wizardQuestions[
                  j
                ].wizardAnswers[k].text;
              if (!newTemplate) {
                descriptionMatch = null;
                descriptionMatch = this.findIDMatch(
                  tool.file.codingFormItems[i].itemWizard.wizardQuestions[j]
                    .wizardAnswers[k].id,
                  oldTemplate.reportCriteria
                );
              }
              if (descriptionMatch) {
                answer.description = descriptionMatch.description;
                answer.disclaimer = descriptionMatch.disclaimer;
              } else {
                answer.description = ''; // empty out description
              }
              question.answers.push(answer);
            }
          }
          description.answerDescriptions.push(question);
        }
      }

      template.reportCriteria.itemDescriptions.descriptions.push({
        codingFormItemId: description.codingFormItemId,
        codingFormItemRiskFactor: description.codingFormItemRiskFactor,
        codingFormItemDescription: description.codingFormItemDescription,
        includeAllAnswers: description.includeAllAnswers, // 3/6/2017 - Alex
        concatenate: description.concatenate, // 3/6/2017 - Alex
        answerDescriptions: description.answerDescriptions
        // collapsed: false
      });
    }

    console.log('generated template', template);

    return template;
  }
}
