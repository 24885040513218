'use strict';

import angular from 'angular';

export default angular
  .module('app.forcelowercase', [])
  .directive('forcelowercase', () => ({
    restrict: 'A',
    require: 'ngModel',
    link: function(scope, element, attrs, ngModel) {
        let makeLowerCase = function(inputValue) {
            if (inputValue == undefined) inputValue = '';
            var lowered = inputValue.toLowerCase();
            if (lowered !== inputValue) {
                // see where the cursor is before the update so that we can set it back
                var selection = element[0].selectionStart;
                ngModel.$setViewValue(lowered);
                ngModel.$render();
                // set back the cursor after rendering
                element[0].selectionStart = selection;
                element[0].selectionEnd = selection;
            }
            return lowered;
        }
        ngModel.$parsers.push(makeLowerCase);
        makeLowerCase(scope[attrs.ngModel]);
    }
  })).name;
