'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { State } from 'angular-store';
import Modal from '@/mixins/modal.mixin';

@Controller
class AddClientToSubgroupModalController extends Modal {
  clientTable: Object;

  @Inject $scope;
  @Inject ItemTable;

  @State clients;

  $setup() {
    // this.subGroup = this.parent.modal.item;

    this.clientTable = new this.ItemTable({
      title: 'Clients'
    })
      .column({
        title: 'ID',
        key: 'id',
        type: 'string'
      })
      .column({
        title: 'First',
        key: 'fName',
        type: 'string'
      })
      .column({
        title: 'Last',
        key: 'lName',
        type: 'string'
      })
      .column({
        title: 'Zone',
        key: 'zone',
        type: 'string'
      })
      .column({
        title: 'Region',
        key: 'region',
        type: 'string'
      })
      .column({
        title: 'SubGroup',
        key: 'subGroup',
        type: 'string'
      });

    this.clientTable.setItems(this.clients.items);
  }
}

export default angular
  .module('app.addClientToSubgroupModal', [])
  .directive('addClientToSubgroupModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./add-client-to-subgroup.html'),
    controller: AddClientToSubgroupModalController,
    controllerAs: 'vm'
  })).name;
