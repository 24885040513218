import angular from 'angular';

export default angular
  .module('app.chart', [])
  .directive('chart', $compile => {
    'ngInject';

    return {
      scope: {
        type: '=',
        datasets: '=',
        labels: '=',
        series: '=',
        options: '=',
        colors: '='
      },
      link(scope, element) {
        scope.chartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                labels: {
                  color: 'rgba(255, 255, 255, 0.1)'
                },
                gridLines: {
                  display: true,
                  color: 'rgba(255, 255, 255, 0.1)'
                },
                ticks: {
                  beginAtZero: true
                }
              }
            ],
            xAxes: [
              {
                gridLines: {
                  display: false
                }
              }
            ]
          },
          ...(scope.options || {})
        };

        scope.chartColors = scope.colors || [
          {
            backgroundColor: `#89d1cd`,
            borderColor: `rgba(0, 0, 0, 0)`
          },
          {
            backgroundColor: `#8fbbdc`,
            borderColor: `rgba(0, 0, 0, 0)`
          },
          {
            backgroundColor: `#b89bdc`,
            borderColor: `rgba(0, 0, 0, 0)`
          },
          {
            backgroundColor: `#c17fb6`,
            borderColor: `rgba(0, 0, 0, 0)`
          }
        ];

        scope.$watch('type', val => {
          element.html(`
              <canvas
                class="chart chart-${val || 'line'}"
                chart-data="datasets"
                chart-labels="labels"
                chart-series="series"
                chart-options="chartOptions"
                chart-colors="chartColors"
              ></canvas>
            `);

          $compile(element.contents())(scope);
        });
      }
    };
  }).name;
