'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { Getter } from 'angular-store';

import Modal from '@/mixins/modal.mixin';

@Controller
class CreatePolicyModalController extends Modal {
  policy: Policy = null;

  @Inject $scope;
  @Inject $store;
  @Inject $api;
  @Inject utils;

  @Getter isAdmin;

  $setup() {}

  async create() {
    try {
      let res = await this.$api.institutionManager.createPolicy(
        this.policy.institutionId,
        this.policy
      );
      console.log(res);
    } catch (err) {
      console.error(err);

      return;
    }

    this.utils.notify('success', 'Policy created.');

    this.$store.dispatch('policies/list');

    this.$close();
  }
}

export default angular
  .module('app.createPolicyModal', [])
  .directive('createPolicyModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./create-policy.html'),
    controller: CreatePolicyModalController,
    controllerAs: 'vm'
  })).name;
