'use strict';

import angular from 'angular';

export default angular
  .module('app.btnDanger', [])
  .directive('btnDanger', btnDirective => {
    'ngInject';

    return Object.assign({}, btnDirective[0], {
      template: `
        <button class="btn" type="button" theme="danger" ng-class="btnClasses" ng-transclude></button>
      `
    });
  }).name;
