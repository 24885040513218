import angular from 'angular';
import shortid from 'shortid';
import { find, flatten, forEach, some } from 'lodash';

import ProrateService from './prorate.service';
import * as OpUtils from './operations-util.js';

/**
 * Making a new Block? Make sure you:
 * 1) update the toolManagement service's addBlock method
 * 2) update the toolEditorConnectors service's addRow method (if need be)
 * 3) update the main component's deleteBlock method
 * 4) update the toolManagement service's updateConnection method
 * @param data
 * @constructor
 */

//region Utilities
class OperatorsGroup {
  operators = [];

  add(label, text) {
    this.operators.push({
      label,
      text
    });

    return this;
  }
}

function cleanJson(str) {
  if (!str?.length) return str;
  return str
    .replace(/\\n/g, '\\n')
    .replace(/\\'/g, "\\'")
    .replace(/\\"/g, '\\"')
    .replace(/\\&/g, '\\&')
    .replace(/\\r/g, '\\r')
    .replace(/\\t/g, '\\t')
    .replace(/\\b/g, '\\b')
    .replace(/\\f/g, '\\f');
}

function dataExists(data, key) {
  if (data && !key) {
    return true;
  } else if (data && key) {
    return data[key];
  } else if (data === false) {
    return true;
  } else if (data === 0) {
    return true;
  } else {
    return false;
  }
}

function arrayContains(array, label) {
  return some(array, {
    label: label
  });
}

class CAS {
  constructor(
    id,
    text,
    score,
    hideScore,
    omit,
    fillIn,
    overrideRiskCategory,
    autoAnswer,
    warningCheck
  ) {
    this.id = id || '';
    this.text = text || '';
    this.score = dataExists(score) ? score : null; // changed to null default to allow for answers without any influence on total score
    this.hideScore = dataExists(hideScore) ? hideScore : false;
    this.omit = dataExists(omit) ? omit : null;
    this.fillIn = fillIn || null;
    this.overrideRiskCategory = dataExists(overrideRiskCategory)
      ? overrideRiskCategory
      : null;
    if (dataExists(autoAnswer) && autoAnswer.length) {
      autoAnswer = autoAnswer
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
      autoAnswer = JSON.parse(autoAnswer);
      this.autoAnswer = autoAnswer;
    } else {
      this.autoAnswer = null;
    }
    if (dataExists(warningCheck) && warningCheck.length) {
      warningCheck = warningCheck
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
      warningCheck = JSON.parse(warningCheck);
      this.warningCheck = warningCheck;
    } else {
      this.warningCheck = null;
    }
  }
}

//endregion Utilities

class Block {
  constructor(data) {
    this.id = data.id || null;
    this.blockId = data.blockId || `block-${shortid.generate()}`;
    this.x = data.x || 50;
    this.y = data.y || 50;
    this.zIndex = data.zIndex || 0;
    this.color = data.color || 'grey';
    this.title = data.title;
    this.type = data.type;
    this.rows = data.rows || [];
  }

  addRow(data) {
    data = data || {};
    let row;
    switch (data.type) {
      case 'answer':
        row = new Answer(data);
        break;
      case 'risk-category-row':
        row = new RiskCategoryRow(data);
        break;
      case 'risk-category-criteria-row':
        row = new RiskCategoryCriteriaRow(data);
        break;
      case 'dictionary-term':
        row = new DictionaryTerm(data);
        break;
      case 'rule':
        row = new Rule(data);
        break;
      case 'multiple-answer-condition':
        row = new MultipleAnswerCondition(data);
        break;
      case 'wizard-condition':
        row = new WizardCondition(data);
        break;
      case 'wizard-resolution':
        row = new WizardResolution(data);
        break;
      case 'wizard-reference':
        row = new WizardReference(data);
        break;
      case 'answer-description':
        row = new AnswerDescription(data);
        break;
      case 'overview-row':
        row = new OverviewRow(data);
        break;
      case 'tool-select':
        row = new ToolSelectRow(data);
        break;
      case 'source':
        row = new SourceRow(data);
        break;
      default:
        row = new Row(data);
    }
    if (data.unshift) {
      this.rows.unshift(row);
    } else {
      this.rows.push(row);
    }

    return row;
  }
}

class Row {
  constructor(data) {
    this.id = data.id || null;
    this.input = data.input;
    this.inputNodeId = data.inputNodeId || `Input-${shortid.generate()}`;
    this.inputIds = data.inputIds || [];
    this.fields = data.fields || [];
    this.output = data.output;
    this.outputNodeId = data.outputNodeId || `Output-${shortid.generate()}`;
    this.outputIds = data.outputIds || [];
    this.type = data.type || null;
  }

  checkDataAndFields(data) {
    data = !data ? {} : data;
    data.fields = !data.fields ? [] : data.fields;
    return data;
  }

  //region Field types
  addInputLabelField(data) {
    data = this.checkDataAndFields(data);
    let iLF = new inputLabelField(data);
    data.fields.push(iLF);
    this.fields.push(iLF);
    return this;
  }

  addOutputLabelField(data) {
    data = this.checkDataAndFields(data);
    let oLF = new outputLabelField(data);
    data.fields.push(oLF);
    this.fields.push(oLF);
    return this;
  }

  addLabelField(data) {
    data = this.checkDataAndFields(data);
    let lF = new labelField(data);
    data.fields.push(lF);
    this.fields.push(lF);
    return this;
  }

  addSelectField(data) {
    data = this.checkDataAndFields(data);
    let sF = new selectField(data);
    data.fields.push(sF);
    this.fields.push(sF);
    return this;
  }

  addDynamicSelectionField(data) {
    data = this.checkDataAndFields(data);
    let sF = new selectField(data);
    data.fields.push(sF);
    this.fields.push(sF);
    return this;
  }

  addNumberInputField(data) {
    data = this.checkDataAndFields(data);
    let nIF = new numberInputField(data);
    data.fields.push(nIF);
    this.fields.push(nIF);
    return this;
  }

  addTextInputField(data) {
    data = this.checkDataAndFields(data);
    let tIF = new textInputField(data);
    data.fields.push(tIF);
    this.fields.push(tIF);
    return this;
  }

  addTextAreaInputField(data) {
    data = this.checkDataAndFields(data);
    let tAIF = new textAreaInputField(data);
    data.fields.push(tAIF);
    this.fields.push(tAIF);
    return this;
  }

  addFileUploadField(data) {
    data = this.checkDataAndFields(data);
    let fUF = new fileUploadField(data);
    data.fields.push(fUF);
    this.fields.push(fUF);
    return this;
  }

  addToolSelectField(data) {
    data = this.checkDataAndFields(data);
    let tSF = new toolSelectField(data);
    data.fields.push(tSF);
    this.fields.push(tSF);
    return this;
  }

  addAddRowField(data) {
    data = this.checkDataAndFields(data);
    let aRF = new addRowField(data);
    data.fields.push(aRF);
    this.fields.push(aRF);
    return this;
  }

  addRemoveRowField(data) {
    data = this.checkDataAndFields(data);
    let rRF = new removeRowField(data);
    data.fields.push(rRF);
    this.fields.push(rRF);
    return this;
  }

  addToggleField(data) {
    data = this.checkDataAndFields(data);
    let tF = new toggleField(data);
    data.fields.push(tF);
    this.fields.push(tF);
    return this;
  }

  addField(data) {
    data = this.checkDataAndFields(data);
    let f = new Field(data);
    data.fields.push(f);
    this.fields.push(f);
    return this;
  }

  deletable(data) {
    data = this.checkDataAndFields(data);
    let rRF = new removeRowField({});
    data.fields.push(rRF);
    this.fields.push(rRF);
    return this;
  }

  //endregion Field types
}

//region Rows

class Answer extends Row {
  constructor(data) {
    data = data || {};
    data.id = data.id || `A-${shortid.generate()}`;
    data.type = data.type || 'answer';
    data.input = true;
    data.output = true;
    if (data.fields) {
      let fields = data.fields;
      data.text = arrayContains(fields, 'Answer')
        ? find(fields, {
            label: 'Answer'
          }).model
        : '';
      data.score = arrayContains(fields, 'Score')
        ? find(fields, {
            label: 'Score'
          }).model
        : 0;
      data.hideScore = arrayContains(fields, 'Hide Score')
        ? find(fields, {
            label: 'Hide Score'
          }).model
        : false;
      data.omit = arrayContains(fields, 'Omit')
        ? find(fields, {
            label: 'Omit'
          }).model
        : null;
      data.fillIn = arrayContains(fields, 'Fill In')
        ? find(fields, {
            label: 'Fill In'
          }).model
        : null;
      data.overrideRiskCategory = arrayContains(
        fields,
        'Override Risk Category'
      )
        ? find(fields, {
            label: 'Override Risk Category'
          }).model
        : null;
      data.autoAnswer = arrayContains(fields, 'Auto Answer')
        ? find(fields, {
            label: 'Auto Answer'
          }).model
        : null;
      data.warningCheck = arrayContains(fields, 'Warning Check')
        ? find(fields, {
            label: 'Warning Check'
          }).model
        : null;
      data.fields = [];
    }
    data.hideScore = true;

    super(data);

    let options = new OperatorsGroup()
      .add('Null', null)
      .add('Textbox', 'textbox')
      .add('Single Line', 'single-line').operators;

    this.addLabelField({
      label: 'Answer ID',
      text: data.id || `A-${shortid.generate()}`
    })
      .addTextAreaInputField({
        label: 'Answer',
        model: dataExists(data.text) ? data.text : null
      })
      .addNumberInputField({
        label: 'Score',
        model: dataExists(data.score) ? data.score : null
      })
      .addToggleField({
        label: 'Hide Score',
        model: dataExists(data.hideScore) ? data.hideScore : null
      })
      .addNumberInputField({
        label: 'Omit',
        model: dataExists(data.omit) ? data.omit : null,
        tooltip:
          'if not null, when this answer is chosen, subtracts the number from the highest riskCategory\'s "high" number (essentially adjusts the total number of possible points accordingly when a question is omitted)'
      })
      .addSelectField({
        label: 'Fill In',
        options,
        model: dataExists(data.fillIn)
          ? find(options, {
              text: data.fillIn.text
            })
          : null
      })
      .addTextInputField({
        label: 'Override Risk Category',
        model: dataExists(data.overrideRiskCategory)
          ? data.overrideRiskCategory
          : null
      })
      .addTextAreaInputField({
        label: 'Auto Answer',
        model: dataExists(data.autoAnswer) ? data.autoAnswer : null
      })
      .addTextAreaInputField({
        label: 'Warning Check',
        model: dataExists(data.warningCheck) ? data.warningCheck : null
      })
      .deletable();
  }
}

class WizardAnswerDescription extends Row {
  constructor(data) {
    data = data || {};
    data.id = data.id || null;
    data.type = data.type || 'answer-description';
    super(data);

    this.addLabelField({
      label: 'Answer ID',
      text: data.id || null
    })
      .addLabelField({
        label: 'Answer Text',
        text: data.answerText || null
      })
      .addToggleField({
        label: 'Disclaimer',
        model: data.disclaimer || false
      })
      .addTextAreaInputField({
        label: 'Description',
        model: data.description || ''
      });
  }
}

class BaseAnswerDescription extends Row {
  constructor(data) {
    data = data || {};
    data.id = data.id || null;
    data.type = data.type || 'answer-description';
    super(data);

    this.addLabelField({
      label: 'Answer ID',
      text: data.id || null
    })
      .addLabelField({
        label: 'Answer Text',
        text: data.answerText || null
      })
      .addToggleField({
        label: 'Disclaimer',
        model: dataExists(data.disclaimer) ? data.disclaimer : false
      })
      .addTextAreaInputField({
        label: 'Description',
        model: data.description || ''
      })
      .addToggleField({
        label: 'Wizard Override',
        model: dataExists(data.wizardOverride) ? data.wizardOverride : false
      })
      .addTextAreaInputField({
        label: 'Wizard Description Override',
        text: data.wizardDescriptionOverride || null
      });
  }
}

class WizardAnswer extends Row {
  constructor(data) {
    data = data || {};
    data.type = data.type || 'wizard-answer';
    data.input = false;
    data.output = true;
    if (data.fields && data.fields.length) {
      forEach(data.fields, function (field) {
        switch (field.label) {
          case 'Answer ID':
            data.id = field.text;
            break;
          case 'Answer':
            data.text = field.model;
            break;
          case 'Spawn Modal':
            data.spawnModal = field.model;
            break;
          case 'Modal Text':
            data.modalText = field.model;
            break;
          case 'Resolve Variables':
            data.resolveVariables = field.model;
            break;
          case 'Increment':
            data.increment = field.model;
            break;
          case 'Multiplicand':
            data.multiplicand = field.model;
            break;
          case 'Is Solo':
            data.isSolo = field.model;
            break;
        }
      });
      Reflect.deleteProperty(data, 'fields');
    }

    super(data);

    this.manipulateVariables = data.manipulateVariables || [];

    this.addLabelField({
      label: 'Answer ID',
      text: data.id || `WA-${shortid.generate()}`
    })
      .addTextAreaInputField({
        label: 'Answer',
        model: dataExists(data.text) ? data.text : null
      })
      .addToggleField({
        label: 'Spawn Modal',
        model: dataExists(data.spawnModal) ? data.spawnModal : null
      })
      .addTextAreaInputField({
        label: 'Modal Text',
        text: 'modal-text',
        model: dataExists(data.modalText) ? data.modalText : null
      })
      .addToggleField({
        label: 'Resolve Variables',
        model: dataExists(data.resolveVariables) ? data.resolveVariables : null
      })
      .addNumberInputField({
        label: 'Increment',
        text: 'increment',
        model: dataExists(data.increment) ? data.increment : null
      })
      .addToggleField({
        label: 'Multiplicand',
        model: dataExists(data.multiplicand) ? data.multiplicand : false
      })
      .addToggleField({
        label: 'Is Solo',
        model: dataExists(data.isSolo) ? data.isSolo : null
      })
      .deletable();
  }
}

class MultipleAnswerCondition extends Row {
  constructor(data) {
    data = data || {};
    data.input = false;
    data.output = true;
    super(data);
    this.fromFile = data.fromFile ? data.fromFile : null;
    let options = new OperatorsGroup()
      .add('<', 'lessThan')
      .add('>', 'greaterThan')
      .add('==', 'equal')
      .add('<=', 'lessThanEqual')
      .add('>=', 'greaterThanEqual').operators;
    this.addSelectField({
      label: 'Operator',
      options,
      model: data.operation
        ? find(options, {
            text: data.operation
          })
        : null
    })
      .addNumberInputField({
        label: 'Right',
        model: dataExists(data.right) ? data.right : null
      })
      .deletable();
  }
}

class ManipulateVariable extends Row {
  constructor(data) {
    data = data || {};
    data.type = 'manipulate-variable';
    data.input = true;
    data.output = false;
    super(data);

    this.addInputLabelField({
      label: 'Variable Input'
    })
      .addSelectField({
        label: 'Operator',
        options: new OperatorsGroup()
          .add('+', '+')
          .add('-', '-')
          .add('/', '/')
          .add('*', '*').operators
      })
      .addNumberInputField({
        label: 'Right'
      })
      .deletable();
  }
}

class WizardVariable extends Row {
  constructor(data) {
    data = data || {};
    data.output = true;
    super(data);

    this.addTextInputField({
      label: 'Variable Name',
      model: dataExists(data.name) ? data.name : null
    })
      .addNumberInputField({
        label: 'Default Value',
        model: dataExists(data.defaultValue) ? data.defaultValue : null
      })
      .deletable();
  }
}

class WizardCondition extends Row {
  constructor(data) {
    data = data || {};
    data.input = true;
    data.output = true;
    super(data);
    let options = new OperatorsGroup()
      .add('<', 'lessThan')
      .add('>', 'greaterThan')
      .add('==', 'equal')
      .add('<=', 'lessThanEqual')
      .add('>=', 'greaterThanEqual').operators;

    this.addInputLabelField({
      label: 'Variable'
    })
      .addLabelField({
        label: 'Condition ID',
        text: data.conditionId || `C-${shortid.generate()}`
      })
      .addSelectField({
        label: 'Operator',
        options,
        model: dataExists(data.operation)
          ? find(options, {
              text: data.operation
            })
          : null
      })
      .addNumberInputField({
        label: 'Right',
        model: dataExists(data.right) ? data.right : null
      })
      .deletable();
  }
}

class WizardResolution extends Row {
  constructor(data) {
    data = data || {};
    data.input = true;
    data.output = true;
    data.id = data.resolutionId || `R-${shortid.generate()}`;
    super(data);

    let options = new OperatorsGroup()
      .add('&&', 'AND')
      .add('||', 'OR').operators;
    this.addInputLabelField({
      label: '2 Conditions'
    })
      .addSelectField({
        label: 'Operator',
        options,
        model: dataExists(data.operation)
          ? find(options, {
              text: data.operation
            })
          : null
      })
      .addOutputLabelField({
        label: 'Code and Score'
      })
      .deletable();
  }
}

class WizardReference extends Row {
  constructor(data) {
    data.input = false;
    data.output = false;
    super(data);

    let options = new OperatorsGroup()
      .add('book', 'book')
      .add('question-circle-o', 'question-circle-o').operators;
    this.addNumberInputField({
      label: 'Page Number',
      model: dataExists(data.page) ? data.page : null
    })
      .addSelectField({
        label: 'Icon',
        options,
        model: data.icon
          ? find(options, {
              text: data.icon
            })
          : null
      })
      .addTextInputField({
        label: 'Tooltip',
        model: dataExists(data.tooltip) ? data.tooltip : null
      })
      .addTextInputField({
        label: 'Modal Title',
        model: dataExists(data.title) ? data.title : null
      })
      .deletable();
  }
}

class DictionaryTerm extends Row {
  constructor(data) {
    data = data || {};
    data.id = data.id ? data.id : `D-${shortid.generate()}`;
    data.input = false;
    data.output = false;
    super(data);
    this.addLabelField({
      label: 'Term ID',
      text: data.id
    })
      .addTextInputField({
        label: 'Term',
        model: dataExists(data.term) ? data.term : null
      })
      .addTextAreaInputField({
        label: 'Definition',
        model: dataExists(data.definition) ? data.definition : null
      })
      .deletable();
  }
}

class Rule extends Row {
  constructor(data) {
    data = data || {};
    data.input = false;
    data.output = false;
    super(data);
    let options = new OperatorsGroup().add(
      'Minimum Answers Required',
      0
    ).operators;

    this.addSelectField({
      label: 'Rule Type',
      options,
      model: dataExists(data.type)
        ? find(options, {
            text: data.type
          })
        : null
    })
      .addNumberInputField({
        label: 'Amount',
        model: dataExists(data.amount) ? data.amount : null
      })
      .deletable();
  }
}

class ToolSelectRow extends Row {
  constructor(data) {
    data = data || {};
    if (data.data) data = data.data;
    super(data);
    this.addToolSelectField({
      label: 'Tool',
      model: data.tool ? data.tool : null
    });
  }
}

class SourceRow extends Row {
  constructor(data) {
    data = data || {};
    if (data.hasOwnProperty('data')) data = data.data;
    super(data);
    this.addTextInputField({
      label: 'Source Name',
      model: data ? data : ''
    }).deletable();
  }
}

class OverviewRow extends Row {
  constructor(data) {
    data = data || {};
    if (data.data) data = data.data;
    // data.type = 'overview-row';
    super(data);

    let options = new OperatorsGroup()
      .add('Text', 0)
      .add('Image', 1)
      .add('Chart', 2).operators;
    this.addSelectField({
      label: 'Content Type',
      options,
      model: dataExists(data.type)
        ? find(options, {
            text: data.type
          })
        : null
    })
      .addTextAreaInputField({
        label: 'Text',
        model: dataExists(data.text) ? data.text : ''
      })
      .addFileUploadField({
        label: 'Image',
        model: dataExists(data.image) ? data.image : ''
      })
      .addNumberInputField({
        label: 'Image Width (max 600)',
        model: dataExists(data.x) ? data.x : null
      })
      .addNumberInputField({
        label: 'Image Height',
        model: dataExists(data.y) ? data.y : null
      })
      .deletable();
  }
}

class RiskCategoryRow extends Row {
  constructor(data) {
    data = data || {};
    if (data.data) data = data.data;
    data.input = false;
    data.output = false;
    super(data);

    this.addTextInputField({
      label: 'Name',
      model: dataExists(data.name) ? data.name : null
    })
      .addToggleField({
        label: 'Is High Risk',
        model: dataExists(data.isHighRisk) ? data.isHighRisk : null
      })
      .addNumberInputField({
        label: 'Low',
        model: dataExists(data.low) ? data.low : null
      })
      .addNumberInputField({
        label: 'High',
        model: dataExists(data.high) ? data.high : null
      })
      .deletable();
  }
}

class RiskCategoryCriteriaRow extends Row {
  constructor(data) {
    data = data || {};
    if (data.data) data = data.data;
    data.input = false;
    data.output = false;
    super(data);

    this.addSelectField({
      label: 'Criteria Type',
      options: new OperatorsGroup()
        .add('sex', 'sex')
        .add('client normative type', 'clientNormativeType').operators
    })
      .addTextInputField({
        label: 'Value'
      })
      .deletable();
  }
}

class AnswerDescription extends Row {}

//endregion Rows

//region Fields
class Field {
  constructor(data) {
    this.label = dataExists(data.label) ? data.label : null;
    this.type = dataExists(data.type) ? data.type : null;
    this.model = dataExists(data.model) ? data.model : null;
    this.text = dataExists(data.text) ? data.text : null;
    this.options = dataExists(data.options) ? data.options : null;
    this.tooltip = dataExists(data.tooltip) ? data.tooltip : null;
  }
}

class inputLabelField extends Field {
  constructor(data) {
    data.type = 'input-label';
    data.label = dataExists(data.label) ? data.label : 'Input';
    super(data);
  }
}

class outputLabelField extends Field {
  constructor(data) {
    data.type = 'output-label';
    data.label = dataExists(data.label) ? data.label : 'Output';
    super(data);
  }
}

class labelField extends Field {
  constructor(data) {
    data.type = 'label';
    data.label = dataExists(data.label) ? data.label : 'Label';
    super(data);
  }
}

class selectField extends Field {
  constructor(data) {
    data.type = 'select';
    data.label = dataExists(data.label) ? data.label : 'Select';
    super(data);
  }
}

class numberInputField extends Field {
  constructor(data) {
    data.type = 'number-input';
    data.label = dataExists(data.label) ? data.label : 'Input Number';
    super(data);
  }
}

class textInputField extends Field {
  constructor(data) {
    data.type = 'text-input';
    data.label = dataExists(data.label) ? data.label : 'Input Text';
    super(data);
  }
}

class textAreaInputField extends Field {
  constructor(data) {
    data.type = 'textarea-input';
    data.label = dataExists(data.label) ? data.label : 'Input Text';
    super(data);
    if (dataExists(data.format)) this.format = data.format;
  }
}

class toolSelectField extends Field {
  constructor(data) {
    data.type = 'tool-select';
    data.label = dataExists(data.label) ? data.label : 'Select Tool';
    super(data);
    this.tool = data.tool ? data.tool : null;
  }

  toolSelected(tool) {
    this.tool = tool;
  }
}

class fileUploadField extends Field {
  constructor(data) {
    data.type = 'file-upload';
    data.label = dataExists(data.label) ? data.label : 'Upload File';
    super(data);
    this.file = data.file ? data.file : null;
  }

  fileSelected(file) {
    this.file = file;
    // this.model = file;
  }
}

class addRowField extends Field {
  constructor(data) {
    data.type = 'add-row';
    data.label = null;
    super(data);
  }
}

class removeRowField extends Field {
  constructor(data) {
    data.type = 'remove';
    data.label = null;
    super(data);
  }
}

class toggleField extends Field {
  constructor(data) {
    data.type = 'toggle';
    data.label = dataExists(data.label) ? data.label : 'Toggle';
    super(data);
  }
}

//endregion Fields

//region Blocks

class Tool {
  constructor(data) {
    this.childTools = data.childTools || [];
    this.codingFormItems = data.codingFormItems || [
      new CodingFormItem({
        x: 375,
        y: 100,
        riskFactor: ''
      })
    ];
    this.riskCategories = data.riskCategories || [
      new RiskCategory({
        x: 600,
        y: 100
      })
    ];
    this.dictionary =
      data.dictionary ||
      new Dictionary({
        x: 800,
        y: 100
      });
    this.rootToolInformation =
      data.rootToolInformation ||
      new RootToolInformation({
        x: 100,
        y: 100,
        id: data.id,
        name: data.name || '',
        version: data.version || '',
        pdfCommitId: data.pdfCommitId,
        pdfDescriptionId: data.pdfDescriptionId,
        agreement: data.agreement || '',
        evaluation: data.evaluation,
        metaTool: data.metaTool // check if the tool is part of a meta tool. If so, give it a space for an evaluation
      });
    this.type = 'tool';
  }
}

class MetaTool {
  constructor(data) {
    this.tools = data.tools || [];
    this.operations = data.operations || [];
    this.codesAndScore = data.codesAndScore || [];
    this.riskCategories = data.riskCategories || [];
    this.type = 'meta-tool';
    this.rootToolInformation =
      data.rootToolInformation ||
      new RootToolInformation({
        x: 100,
        y: 100,
        id: data.id,
        name: data.name || '',
        version: data.version || '',
        pdfCommitId: data.pdfCommitId,
        pdfDescriptionId: data.pdfDescriptionId,
        agreement: data.agreement || ''
      });
  }

  toJson() {
    let metaTool = {};
    // TODO (Alex) finish returning this depending on the json needs
    return metaTool;
  }
}

class ChildTool extends Block {
  constructor(data) {
    data.type = data.type ? data.type : 'child-tool';
    super(data);
    this.id = data.id ? data.id : null;
    this.name = data.name ? data.name : null;
    if (!data.rows || !data.rows.length) {
      this.addRow({
        type: 'tool-select'
      });
    }
  }

  toJson() {
    let toolSelectRow = find(this.rows, {
      type: 'tool-select'
    });
    if (this.id) {
      return this.id;
    } else if (toolSelectRow.toolId) {
      return toolSelectRow.toolId;
    } else {
      console.error('Could not find child tool tool ID to return');
    }
  }
}

class OffenderHistory extends Block {
  constructor(data) {
    data.type = data.type ? data.type : 'offender-history';
    super(data);
    if (data.data && typeof data.data === 'object') {
      data.data = JSON.stringify(data.data, undefined, 4);
    } else if (data.title && data.sections) {
      data.data = JSON.stringify(data, undefined, 4);
    }
    this.data = data.data ? data.data : '';
    if (!data.rows || !data.rows.length) {
      this.addRow().addTextAreaInputField({
        label: 'Data',
        model: dataExists(data.data) ? data.data : ''
      });
    }
  }

  toJson() {
    let data;
    forEach(this.rows, function (row) {
      if (
        some(row.fields, {
          label: 'Data'
        })
      ) {
        data = find(row.fields, {
          label: 'Data'
        }).model;
      }
    });
    if (data && data.length) {
      data = data
        .replace(/\\n/g, '\\n')
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, '\\&')
        .replace(/\\r/g, '\\r')
        .replace(/\\t/g, '\\t')
        .replace(/\\b/g, '\\b')
        .replace(/\\f/g, '\\f');
      data = JSON.parse(data);
    }
    return data;
  }
}

class Comparator extends Block {
  constructor(data) {
    data.type = data.type ? data.type : '2-input-comparator';
    super(data);
    if (data.type === '1-input-comparator') {
      // Row 1 ------------------------------------------------
      this.addRow({
        input: true,
        output: true
      })
        .addSelectField({
          label: 'Operator',
          options: new OperatorsGroup()
            .add('<', '<')
            .add('>', '>')
            .add('==', '==')
            .add('<=', '<=')
            .add('>=', '>=').operators
        })
        .addNumberInputField({
          label: 'Value'
        });
    } else if (data.type === '2-input-comparator') {
      // Row 1 ------------------------------------------------
      this.addRow({
        input: true,
        output: true
      })
        .addInputLabelField({
          label: 'A'
        })
        .addOutputLabelField({
          label: 'A < B'
        });
      // Row 2 ------------------------------------------------
      this.addRow({
        input: true,
        output: true
      })
        .addInputLabelField({
          label: 'B'
        })
        .addOutputLabelField({
          label: 'A > B'
        });
      // Row 3 ------------------------------------------------
      this.addRow({
        input: false,
        output: true
      }).addOutputLabelField({
        label: 'A <= B'
      });
      // Row 4 ------------------------------------------------
      this.addRow({
        input: false,
        output: true
      }).addOutputLabelField({
        label: 'A >= B'
      });
      // Row 5 ------------------------------------------------
      this.addRow({
        input: false,
        output: true
      }).addOutputLabelField({
        label: 'A == B'
      });
    }
  }
}

class Operator extends Block {
  constructor(data) {
    // Add any necessary attributes
    // to the data object
    data.type = 'operator';

    // Complete initialization by passing
    // data object to base class constructor
    super(data);
    this.id = this.blockId.replace('block-', '');

    // Row 1-----------------------------------------------------------------
    this.addRow({
      input: false,
      output: false
    }).addNumberInputField({
      label: 'Step'
    });
    // Row 2-----------------------------------------------------------------
    this.addRow({
      input: true,
      output: false
    }).addField({
      label: 'Variable',
      type: 'input-label'
    });

    // Row 3 -----------------------------------------------------------------
    this.addRow({
      input: false,
      output: true
    })
      .addSelectField({
        label: 'Operator',
        options: new OperatorsGroup()
          .add('+', '+')
          .add('-', '-')
          .add('/', '/')
          .add('*', '*').operators
      })
      .addNumberInputField({
        label: 'Value'
      });
  }
}

class SingleOperator extends Block {
  constructor(data) {
    // Add any necessary attributes
    // to the data object
    data.type = 'single-operator';

    // Complete initialization by passing
    // data object to base class constructor
    super(data);
    this.id = this.blockId.replace('block-', '');

    // Row 1 -----------------------------------------------------------------
    this.addRow({
      input: false,
      output: false
    }).addNumberInputField({
      label: 'Step'
    });

    // Row 2 -----------------------------------------------------------------
    this.addRow({
      input: true,
      output: false
    }).addField({
      label: 'Variable',
      type: 'input-label'
    });

    // Row 3 -----------------------------------------------------------------
    this.addRow({
      input: false,
      output: true
    }).addSelectField({
      label: 'Operator',
      options: new OperatorsGroup()
        .add('Abs', 'abs')
        .add('Round', 'round')
        .add('Ceil', 'ceil')
        .add('Floor', 'floor')
        .add('Max', 'max')
        .add('Min', 'min')
        .add('Trunc', 'trunc').operators
    });
  }
}

class BinaryOperator extends Block {
  constructor(data) {
    // Add any necessary attributes
    // to the data object
    data.type = 'binary-operator';

    // Complete initialization by passing
    // data object to base class constructor
    super(data);
    this.id = this.blockId.replace('block-', '');

    // Row 1 -----------------------------------------------------------------
    this.addRow({
      input: false,
      output: false
    }).addNumberInputField({
      label: 'Step'
    });

    // Row 2 -----------------------------------------------------------------
    this.addRow({
      input: true,
      output: false
    }).addField({
      label: 'Variable 1',
      type: 'input-label'
    });

    // Row 3 -----------------------------------------------------------------
    this.addRow({
      input: false,
      output: false
    }).addSelectField({
      label: 'Operator',
      options: new OperatorsGroup()
        .add('+', '+')
        .add('-', '-')
        .add('/', '/')
        .add('*', '*').operators
    });

    // Row 4 -----------------------------------------------------------------
    this.addRow({
      input: true,
      output: true
    }).addField({
      label: 'Variable 2',
      type: 'input-label'
    });
  }
}

class NumericComparator extends Block {
  constructor(data) {
    data.type = 'numeric-comparator';

    super(data);

    // Row 1-----------------------------------------------------------------
    this.addRow({
      input: false,
      output: false
    }).addNumberInputField({
      label: 'Step'
    });
    // Row 2 ------------------------------------------------
    this.addRow({
      input: true,
      output: false
    })
      .addSelectField({
        label: 'Operator',
        options: new OperatorsGroup()
          .add('<', '<')
          .add('>', '>')
          .add('==', '==')
          .add('<=', '<=')
          .add('>=', '>=').operators
      })
      .addNumberInputField({
        label: 'Value'
      });
    // Row 3 ------------------------------------------------
    this.addRow({
      input: false,
      output: true
    }).addOutputLabelField({
      label: 'True'
    });
    // Row 4 ------------------------------------------------
    this.addRow({
      input: false,
      output: true
    }).addOutputLabelField({
      label: 'False'
    });
  }
}

//region subBlock: Tool Blocks

class Variable extends Block {
  constructor(data) {
    data.color = 'grey';
    data.type = data.type ? data.type : 'variable';

    super(data);

    if (!data.variableName) {
      this.addRow({
        input: dataExists(data.input) ? data.input : true,
        output: dataExists(data.output) ? data.output : true
      })
        .addTextInputField({
          label: 'Variable Name'
        })
        .addNumberInputField({
          label: 'Default Value'
        });
    } else {
      this.undeletable = true;
      this.addRow({
        input: dataExists(data.input) ? data.input : true,
        output: dataExists(data.output) ? data.output : true
      }).addLabelField({
        label: 'Variable',
        text: data.variableName
      });
    }
  }
}

class RootToolInformation extends Block {
  constructor(data) {
    data.type = 'meta-tool-information';
    super(data);
    this.name = data.name;
    this.tabName = data.tabName;
    this.flyoutName = data.flyoutName;
    this.version = data.version;
    this.hideBarGraph = data.hideBarGraph;
    this.showCommentsBox = data.showCommentsBox;
    this.autoAnswer = data.autoAnswer;
    // this.pdfCommitId = data.pdfCommitId;
    // this.pdfDescriptionId = data.pdfDescriptionId;
    this.agreement = data.agreement;
    if (!data.rows) {
      // Row 1 ---------------------------------------------------------
      this.addRow()
        .addLabelField({
          label: 'Version',
          text: data.version
        })
        .addLabelField({
          label: 'ID',
          text: data.id || null
        });
      // Row 2 ---------------------------------------------------------
      this.addRow()
        .addLabelField({
          label: 'PDF Description ID',
          text: data.pdfDescriptionId || null
        })
        .addLabelField({
          label: 'PDF Commit ID',
          text: data.pdfCommitId || null
        });
      // Row 3 ---------------------------------------------------------
      this.addRow().addTextAreaInputField({
        format: 'json',
        label: 'Coding Form Item Groupings',
        model: dataExists(data.codingFormItemGroupings)
          ? data.codingFormItemGroupings
          : '',
        tooltip:
          'Array format of Objects with "id" and "name" as values. e.g. ([{id: "123abc", name: "Name of Group"}])'
      });
      // Row 4 ---------------------------------------------------------
      this.addRow().addTextAreaInputField({
        format: 'json',
        label: 'Table Prorates',
        model: dataExists(data.tableProrates) ? data.tableProrates : '',
        tooltip:
          'Array format of Objects with "name" and "evalScoreVariable" and "getScore" as values. e.g. ([{name: "Total Score Prorated", evalScoreVariable: "totalScoreProrates", getScore: function(rawScore, omittedItems) {let newScore = 0; return newScore}}])'
      });
      // Row 5 ---------------------------------------------------------
      this.addRow().addTextAreaInputField({
        format: 'json',
        label: 'Dynamic Scoring Tables',
        model: dataExists(data.dynamicScoringTables)
          ? data.dynamicScoringTables
          : '',
        tooltip:
          'Array format of Objects with "name" and "evalScoreVariable" and "getScore" as values. e.g. ([{name: "Total Score Prorated", evalScoreVariable: "totalScoreProrates", getScore: function(rawScore, omittedItems) {let newScore = 0; return newScore}}])'
      });
      // Row 6 ---------------------------------------------------------
      this.addRow().addTextAreaInputField({
        label: 'Sources of Information',
        model:
          'Interview with Client,Interview with Others,Criminal Record,Employment History,Psychological Evaluation',
        tooltip:
          'Array format of Strings for Sources of Information options for the tool'
      });
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    let autoAnswer;
    let codingFormItemGroupings;
    let tableProrates;
    let dynamicScoringTables;
    let sourcesOfInformation;

    if (this.autoAnswer && this.autoAnswer.length) {
      autoAnswer = cleanJson(this.autoAnswer);
      autoAnswer = JSON.parse(autoAnswer);
    }

    forEach(this.rows, function (row) {
      if (
        find(row.fields, {
          label: 'Coding Form Item Groupings'
        })
      ) {
        codingFormItemGroupings = find(row.fields, {
          label: 'Coding Form Item Groupings'
        })?.model;
        codingFormItemGroupings = cleanJson(codingFormItemGroupings);
        codingFormItemGroupings = JSON.parse(codingFormItemGroupings);
      } else if (
        find(row.fields, {
          label: 'Table Prorates'
        })
      ) {
        tableProrates = find(row.fields, {
          label: 'Table Prorates'
        })?.model;
        tableProrates = cleanJson(tableProrates);
        tableProrates = JSON.parse(tableProrates);
      } else if (
        find(row.fields, {
          label: 'Dynamic Scoring Tables'
        })
      ) {
        dynamicScoringTables = find(row.fields, {
          label: 'Dynamic Scoring Tables'
        })?.model;
        dynamicScoringTables = cleanJson(dynamicScoringTables);
        dynamicScoringTables = JSON.parse(dynamicScoringTables);
      } else if (
        find(row.fields, {
          label: 'Sources of Information'
        })
      ) {
        sourcesOfInformation = find(row.fields, {
          label: 'Sources of Information'
        })?.model;
        sourcesOfInformation = sourcesOfInformation.split(',');
      }
    });

    return {
      id: this.id || null,
      name: this.name,
      version: this.version,
      // pdfCommitId: this.pdfCommitId,
      // pdfDescriptionId: this.pdfDescriptionId,
      agreement: this.agreement,
      flyoutName: this.flyoutName,
      tabName: this.tabName,
      hideBarGraph: this.hideBarGraph,
      showCommentsBox: this.showCommentsBox,
      autoAnswer,
      codingFormItemGroupings,
      tableProrates,
      dynamicScoringTables,
      sourcesOfInformation
    };
  }
}

class Dictionary extends Block {
  constructor(data) {
    data.type = 'dictionary';
    data.x = data.x || 100;
    data.y = data.y || 100;
    super(data);
    if (!data.rows) {
      this.addRow({
        input: false
      }).addAddRowField({
        text: 'Dictionary Term'
      });
      this.addRow({
        type: 'dictionary-term'
      });
    } else if (data.fromToolFile) {
      this.addRow({
        input: false,
        unshift: true
      }).addAddRowField({
        text: 'Dictionary Term'
      });
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    let dictionary = [];
    let Term = class {
      constructor(id, term, definition) {
        this.id = id;
        this.term = term;
        this.definition = definition;
      }
    };

    forEach(this.rows, function (row) {
      if (
        !find(row.fields, {
          type: 'add-row'
        })
      ) {
        dictionary.push(
          new Term(
            find(row.fields, {
              label: 'Term ID'
            }).text,
            find(row.fields, {
              label: 'Term'
            }).model,
            find(row.fields, {
              label: 'Definition'
            }).model
          )
        );
      }
    });

    return dictionary;
  }
}

class ProrateTool extends Block {
  constructor(data) {
    data.type = 'prorate-tool';
    data.x = data.x || 100;
    data.y = data.y || 100;
    super(data);

    this.proratingRequired = dataExists(data.proratingRequired)
      ? data.proratingRequired
      : false;
    this.startValue = data.startValue ? data.startValue : {};

    this.operations = data.operations ? data.operations : [];
    this.variables = data.variables ? data.variables : [];
    this.outcomes = data.outcomes ? data.outcomes : [];
    this.comparators = data.comparators ? data.comparators : [];

    this.rules = data.rules ? data.rules : [];

    this.subEdit = true;
    if (!data.rows) {
      this.addRow()
        .addLabelField({
          label: 'Prorate Tool',
          text: 'Prorating for the whole tool'
        })
        .addToggleField({
          label: 'Prorating Required?',
          model: dataExists(data.proratingRequired)
            ? data.proratingRequired
            : false
        });
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    let rules = [];
    let operations = [];

    var toolBlocks = this.operations
      .concat(this.comparators)
      .concat(this.variables)
      .concat(this.outcomes);
    toolBlocks.push(this.startValue);

    let proratingRequired;

    // -------------------------------------------------------------------------
    // Check if prorating is required
    // -------------------------------------------------------------------------

    proratingRequired = OpUtils.GetFieldModel(this.rows, {
      label: 'Prorating Required?'
    });

    // -------------------------------------------------------------------------
    // Compose Operations
    // -------------------------------------------------------------------------

    operations = OpUtils.MakeOperationsExpression(toolBlocks);

    // -------------------------------------------------------------------------
    // Compose Rules
    // -------------------------------------------------------------------------

    OpUtils.ForBlocksRows(this.rules, (row) => {
      if (
        some(row.fields, {
          label: 'Rule Type'
        })
      ) {
        rules.push({
          ruleType: find(row.fields, {
            label: 'Rule Type'
          }).model.label,
          amount: find(row.fields, {
            label: 'Amount'
          }).model
        });
      }
    });

    // Add final score as operation...

    console.debug(rules, operations, proratingRequired);

    var res = ProrateService({
      ops: operations,
      constants: [
        {
          label: 'scoreOfAnswered',
          value: -13
        },
        {
          label: 'highestScoreOfAnswered',
          value: -29
        },
        {
          label: 'highestScoreOfUnanswered',
          value: 8
        },
        {
          label: 'lowestScoreOfAnswered',
          value: -29
        },
        {
          label: 'lowestScoreOfUnanswered',
          value: -5
        }
      ]
    });

    console.debug('ProrateService', res);

    return {
      rules,
      operations,
      proratingRequired
    };
  }
}

class Rules extends Block {
  constructor(data) {
    data.type = 'rules';
    data.x = data.x || 100;
    data.y = data.y || 100;
    super(data);

    if (!data.rows) {
      this.addRow({
        input: false
      }).addAddRowField({
        text: 'Rule'
      });
      this.addRow({
        type: 'rule'
      });
    } else if (data.fromToolFile) {
      this.addRow({
        input: false,
        unshift: true
      }).addAddRowField({
        text: 'Rule'
      });
    }
  }

  toJson() {}
}

class RiskCategory extends Block {
  constructor(data) {
    data.type = 'risk-category';
    super(data);
    if (!data.rows) {
      this.addRow()
        .addTextInputField({
          label: 'Name',
          model: dataExists(data.name) ? data.name : null
        })
        .addToggleField({
          label: 'Is High Risk',
          model: dataExists(data.isHighRisk) ? data.isHighRisk : null
        });
      this.addRow()
        .addNumberInputField({
          label: 'High',
          model: dataExists(data.high) ? data.high : null
        })
        .addNumberInputField({
          label: 'Low',
          model: dataExists(data.low) ? data.low : null
        });
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    return {
      name: this.rows[0].fields[0].model,
      isHighRisk: dataExists(this.rows[0].fields[1].model)
        ? this.rows[0].fields[1].model
        : false,
      low:
        typeof this.rows[1].fields[1].model === 'number'
          ? this.rows[1].fields[1].model
          : '-',
      high:
        typeof this.rows[1].fields[0].model === 'number'
          ? this.rows[1].fields[0].model
          : '-'
    };
  }
}

class SubRiskCategory extends Block {
  constructor(data) {
    data.type = 'sub-risk-category';
    super(data);
    if (!data.rows) {
      this.addRow({
        input: false
      }).addAddRowField({
        text: 'Risk Category Row'
      });
      this.addRow({
        type: 'risk-category-row'
      });
      this.addRow().addTextAreaInputField({
        label: 'Questions Array (comma separated)',
        model: dataExists(data.questionRange) ? data.questionRange : ''
      });
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    let categories = [];
    let questionRange = [];
    forEach(this.rows, (row) => {
      if (
        some(row.fields, {
          label: 'Questions Array (comma separated)'
        })
      ) {
        let questionRangeText = find(row.fields, {
          label: 'Questions Array (comma separated)'
        }).model.trim();
        questionRange = questionRangeText.split(',');
      } else if (
        some(row.fields, {
          label: 'Is High Risk'
        })
      ) {
        categories.push({
          name: find(row.fields, {
            label: 'Name'
          }).model,
          isHighRisk: find(row.fields, {
            label: 'Is High Risk'
          }).model,
          low: find(row.fields, {
            label: 'Low'
          }).model,
          high: find(row.fields, {
            label: 'High'
          }).model
        });
      }
    });

    return {
      categories,
      questionRange
    };
  }
}

class CustomRiskCategory extends Block {
  constructor(data) {
    data.type = 'custom-risk-category';
    super(data);
    if (!data.rows) {
      this.addRow({
        input: false
      }).addAddRowField({
        text: 'Risk Category Row'
      });
      this.addRow({
        type: 'risk-category-row'
      });
      this.addRow({
        input: false
      }).addAddRowField({
        text: 'Risk Category Criteria Row'
      });
      this.addRow({
        type: 'risk-category-criteria-row'
      });
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    let categories = [];
    let criteria = {};
    forEach(this.rows, (row) => {
      if (
        some(row.fields, {
          label: 'Is High Risk'
        })
      ) {
        categories.push({
          name: find(row.fields, {
            label: 'Name'
          }).model,
          isHighRisk: find(row.fields, {
            label: 'Is High Risk'
          }).model,
          low: find(row.fields, {
            label: 'Low'
          }).model,
          high: find(row.fields, {
            label: 'High'
          }).model
        });
      } else if (
        find(row.fields, {
          label: 'Criteria Type'
        }) &&
        find(row.fields, {
          label: 'Criteria Type'
        }).model
      ) {
        criteria[
          find(row.fields, {
            label: 'Criteria Type'
          }).model.text
        ] = find(row.fields, {
          label: 'Value'
        }).model;
      }
    });

    return {
      categories,
      criteria
    };
  }
}

class RuleMinimumAnswers extends Block {
  constructor(data) {
    data.type = 'rule-minimum-answers';
    super(data);
    this.ruleType = 2;
    if (!data.rows) {
      this.addRow()
        .addTextAreaInputField({
          label: 'Rule Text',
          model: dataExists(data.ruleText) ? data.ruleText : null
        })
        .addNumberInputField({
          label: 'Minimum Answers Required',
          model: dataExists(data.minimumAnswersRequired)
            ? data.minimumAnswersRequired
            : null
        });
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    let ruleText;
    let minimumAnswersRequired;
    forEach(this.rows, (row) => {
      if (
        some(row.fields, {
          label: 'Rule Text'
        })
      ) {
        ruleText = find(row.fields, {
          label: 'Rule Text'
        }).model;
        minimumAnswersRequired = find(row.fields, {
          label: 'Minimum Answers Required'
        }).model;
      }
    });
    return {
      ruleType: this.ruleType,
      ruleText,
      minimumAnswersRequired
    };
  }
}

class RuleOmit extends Block {
  constructor(data) {
    data.type = 'rule-omit';
    super(data);
    this.ruleType = 0;
    if (!data.rows) {
      this.addRow()
        .addTextAreaInputField({
          label: 'Rule Text',
          model: dataExists(data.ruleText) ? data.ruleText : null
        })
        .addNumberInputField({
          label: 'Max Omit Allowance',
          model: dataExists(data.maxOmitAllowance)
            ? data.maxOmitAllowance
            : null
        })
        .addTextInputField({
          label: 'Group (optional)',
          model: dataExists(data.group) ? data.group : null
        });
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    let ruleText;
    let maxOmitAllowance;
    let group;
    forEach(this.rows, (row) => {
      if (
        some(row.fields, {
          label: 'Rule Text'
        })
      ) {
        ruleText = find(row.fields, {
          label: 'Rule Text'
        }).model;
        maxOmitAllowance = find(row.fields, {
          label: 'Max Omit Allowance'
        }).model;
        if (
          find(row.fields, {
            label: 'Group (optional)'
          })
        ) {
          group = find(row.fields, {
            label: 'Group (optional)'
          }).model;
        }
      }
    });
    return {
      ruleType: this.ruleType,
      ruleText,
      maxOmitAllowance,
      group
    };
  }
}

class CodingFormItem extends Block {
  constructor(data) {
    // declarations for Block properties
    data.type = 'coding-form-item';
    data.title = data.title || data.riskFactor;
    data.id = data.id || `Q-${shortid.generate()}`;
    console.log('cfi data: ', data);
    super(data);
    this.subEdit = true;

    if (data.rows) {
      // recasting codingFormItem from existing block
      let sources = [];
      forEach(data.rows, (row) => {
        if (
          find(row.fields, {
            label: 'Groupings'
          })
        ) {
          data.groupings = find(row.fields, {
            label: 'Groupings'
          }).model;
        } else if (
          find(row.fields, {
            label: 'Custom Item Number'
          })
        ) {
          data.customItemNumber = find(row.fields, {
            label: 'Custom Item Number'
          }).model;
        } else if (
          find(row.fields, {
            label: 'PDF Item Bookmark'
          })
        ) {
          data.pdfItemHelpBookmark = find(row.fields, {
            label: 'PDF Item Bookmark'
          }).model;
        } else if (
          find(row.fields, {
            label: 'PDF Item Page'
          })
        ) {
          data.pdfItemPage = find(row.fields, {
            label: 'PDF Item Page'
          }).model;
        } else if (
          find(row.fields, {
            label: 'Custom Reference Text'
          })
        ) {
          data.customReferenceText = find(row.fields, {
            label: 'Custom Reference Text'
          }).model;
        } else if (
          find(row.fields, {
            label: 'Show'
          })
        ) {
          data.show = find(row.fields, {
            label: 'Show'
          }).model;
        } else if (
          find(row.fields, {
            label: 'Indent'
          })
        ) {
          data.indent = find(row.fields, {
            label: 'Indent'
          }).model;
        } else if (
          find(row.fields, {
            label: 'Text Color'
          })
        ) {
          data.indent = find(row.fields, {
            label: 'Text Color'
          }).model;
        } else if (
          find(row.fields, {
            label: 'Fill In'
          })
        ) {
          data.fillIn = find(row.fields, {
            label: 'Fill In'
          }).model
            ? find(row.fields, {
                label: 'Fill In'
              }).model.text
            : null;
        } else if (
          find(row.fields, {
            label: 'Hide Comments'
          })
        ) {
          data.hideComments = find(row.fields, {
            label: 'Hide Comments'
          }).model
            ? find(row.fields, {
                label: 'Hide Comments'
              }).model
            : null;
        } else if (
          find(row.fields, {
            label: 'Ignore Count'
          })
        ) {
          data.ignoreCount = find(row.fields, {
            label: 'Ignore Count'
          }).model
            ? find(row.fields, {
                label: 'Ignore Count'
              }).model
            : null;
        } else if (
          find(row.fields, {
            label: 'Required'
          })
        ) {
          data.required = find(row.fields, {
            label: 'Required'
          }).model
            ? find(row.fields, {
                label: 'Required'
              }).model
            : null;
        } else if (
          find(row.fields, {
            label: 'Sub Answers'
          })
        ) {
          data.subAnswers = find(row.fields, {
            label: 'Sub Answers'
          }).model
            ? find(row.fields, {
                label: 'Sub Answers'
              }).model
            : null;
        } else if (
          find(row.fields, {
            label: 'Source Name'
          })
        ) {
          sources.push(
            find(row.fields, {
              label: 'Source Name'
            }).model
          );
        }
      });
      if (sources.length) data.sources = sources;
      Reflect.deleteProperty(data, 'rows');
    }

    // specific declarations for Variable properties
    this.riskFactor = data.riskFactor || '';
    this.groupings = dataExists(data.groupings) ? data.groupings : null;
    this.customItemNumber = dataExists(data.customItemNumber)
      ? data.customItemNumber
      : null;
    this.title = data.title || this.riskFactor;
    this.pdfItemHelpBookmark = data.pdfItemHelpBookmark || null;
    this.pdfItemPage = data.pdfItemPage || null;
    this.customReferenceText = data.customReferenceText || null;
    this.show = data.show || null;
    this.indent = data.indent || null;
    this.textColor = data.textColor || null;
    this.fillIn = dataExists(data.fillIn) ? data.fillIn : null;
    this.hideComments = dataExists(data.hideComments)
      ? data.hideComments
      : null;
    this.ignoreCount = dataExists(data.ignoreCount) ? data.ignoreCount : null;
    this.required = dataExists(data.required) ? data.required : null;
    this.subAnswers = dataExists(data.subAnswers) ? data.subAnswers : null;
    this.sources = dataExists(data.sources) ? data.sources : [];

    let options = new OperatorsGroup()
      .add('Null', null)
      .add('Textbox', 'textbox')
      .add('Single Line', 'single-line').operators;

    if (!data.rows) {
      // create object's rows and check if data is being passed in for the rows
      this.addRow({
        input: false,
        output: false
      }).addTextInputField({
        label: 'Groupings',
        model: dataExists(data.groupings) ? data.groupings : null,
        tooltip:
          'Array form where items are the "IDs" of whatever "Groupings" they belong to (listed on the Root Tool) block. e.g. (["123abc", "456def"])'
      });
      this.addRow({
        input: false,
        output: false
      }).addTextInputField({
        label: 'Custom Item Number',
        model: dataExists(data.customItemNumber) ? data.customItemNumber : null
      });
      this.addRow({
        input: false,
        output: false
      }).addTextInputField({
        label: 'PDF Item Bookmark',
        model: dataExists(data.pdfItemHelpBookmark)
          ? data.pdfItemHelpBookmark
          : null
      });
      this.addRow({
        input: false,
        output: false
      }).addNumberInputField({
        label: 'PDF Item Page',
        model: dataExists(data.pdfItemPage)
          ? parseInt(data.pdfItemPage, 10)
          : null
      });
      this.addRow({
        input: false,
        output: false
      }).addTextAreaInputField({
        label: 'Custom Reference Text',
        model: dataExists(data.customReferenceText)
          ? data.customReferenceText
          : null
      });
      this.addRow({
        input: false,
        output: false
      }).addTextAreaInputField({
        label: 'Show',
        model: dataExists(data.show) ? data.show : null
      });
      this.addRow({
        input: false,
        output: false
      }).addNumberInputField({
        label: 'Indent',
        model: dataExists(data.indent) ? parseInt(data.indent, 10) : null
      });
      this.addRow({
        input: false,
        output: false
      }).addTextInputField({
        label: 'Text Color',
        model: dataExists(data.textColor) ? data.textColor : null
      });
      this.addRow().addSelectField({
        label: 'Fill In',
        options,
        model: dataExists(data.fillIn)
          ? find(options, {
              text: data.fillIn
            })
          : null
      });
      this.addRow().addToggleField({
        label: 'Hide Comments',
        model: dataExists(data.hideComments) ? data.hideComments : null
      });
      this.addRow().addToggleField({
        label: 'Ignore Count',
        model: dataExists(data.ignoreCount) ? data.ignoreCount : null
      });
      this.addRow().addToggleField({
        label: 'Required',
        model: dataExists(data.required) ? data.required : null
      });
      this.addRow().addTextAreaInputField({
        label: 'Sub Answers',
        model: dataExists(data.subAnswers) ? data.subAnswers : null
      });
      this.addRow({
        input: false
      }).addAddRowField({
        text: 'Source'
      });

      if (data.sources && data.sources.length) {
        forEach(data.sources, (source) => {
          if (source.hasOwnProperty('data') && !source.data) source.data = '';
          if (typeof source === 'string')
            source = {
              type: 'source',
              data: source
            };
          this.addRow(source);
        });
      }
    }

    // check for existing item wizard being passed in
    this.itemWizard = data.itemWizard ? new ItemWizard(data.itemWizard) : null;
    // check for existing codesAndScore items being passed in
    let i;
    this.codesAndScore = [];
    if (data.codesAndScore && !data.fromToolFile) {
      for (i = 0; i < data.codesAndScore.length; i++) {
        this.codesAndScore.push(new CodesAndScore(data.codesAndScore[i]));
      }
    } else if (data.codesAndScore && data.fromToolFile) {
      let codesAndScores = [];
      for (i = 0; i < data.codesAndScore.length; i++) {
        codesAndScores.push(new Answer(data.codesAndScore[i]));
      }
      this.codesAndScore.push(
        new CodesAndScore({
          rows: codesAndScores,
          fromToolFile: true
        })
      );
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    console.log('this: ', this);
    let CFI = class {
      constructor(data) {
        data = !data ? {} : data;
        this.id = data.id || null;
        this.groupings = dataExists(data.groupings) ? data.groupings : null;
        this.customItemNumber = dataExists(data.customItemNumber)
          ? data.customItemNumber
          : null;
        this.riskFactor = data.riskFactor || null;
        this.codesAndScore = data.codesAndScore || [];
        this.itemWizard = data.itemWizard || null;
        this.pdfItemHelpBookmark = data.pdfItemHelpBookmark || null;
        this.pdfItemPage = data.pdfItemPage || null;
        this.customReferenceText = data.customReferenceText || null;
        this.show = data.show || null;
        this.indent = data.indent || null;
        this.textColor = data.textColor || null;
        this.fillIn = dataExists(data.fillIn) ? data.fillIn : null;
        this.hideComments = dataExists(data.hideComments)
          ? data.hideComments
          : null;
        this.ignoreCount = dataExists(data.ignoreCount)
          ? data.ignoreCount
          : null;
        this.required = dataExists(data.required) ? data.required : null;
        this.sources = dataExists(data.sources) ? data.sources : null;
        if (dataExists(data.subAnswers) && data.subAnswers.length) {
          data.subAnswers = data.subAnswers
            .replace(/\\n/g, '\\n')
            .replace(/\\'/g, "\\'")
            .replace(/\\"/g, '\\"')
            .replace(/\\&/g, '\\&')
            .replace(/\\r/g, '\\r')
            .replace(/\\t/g, '\\t')
            .replace(/\\b/g, '\\b')
            .replace(/\\f/g, '\\f');
          data.subAnswers = JSON.parse(data.subAnswers);
          this.subAnswers = data.subAnswers;
        } else {
          this.subAnswers = null;
        }
      }
    };

    // let CAS = class {
    //   constructor(id, text, score, hideScore, omit, fillIn, overrideRiskCategory, autoAnswer) {
    //     this.id = id || '';
    //     this.text = text || '';
    //     this.score = dataExists(score) ? score : null; // changed to null default for answers that don't have any influence on total score
    //     this.hideScore = dataExists(hideScore) ? hideScore : false;
    //     this.omit = dataExists(omit) ? omit : null;
    //     this.fillIn = dataExists(fillIn) ? fillIn : null;
    //     this.overrideRiskCategory = dataExists(overrideRiskCategory) ? overrideRiskCategory : null;
    //     this.autoAnswer = dataExists(autoAnswer) ? autoAnswer : null;
    //   }
    // };

    let item = new CFI({
      id: this.id,
      riskFactor: this.riskFactor
    });

    let sources = [];
    forEach(this.rows, (row) => {
      if (
        find(row.fields, {
          label: 'Groupings'
        })
      ) {
        item.groupings = find(row.fields, {
          label: 'Groupings'
        }).model;
      } else if (
        find(row.fields, {
          label: 'PDF Item Bookmark'
        })
      ) {
        item.pdfItemHelpBookmark = find(row.fields, {
          label: 'PDF Item Bookmark'
        }).model;
      } else if (
        find(row.fields, {
          label: 'Custom Item Number'
        })
      ) {
        item.customItemNumber = find(row.fields, {
          label: 'Custom Item Number'
        }).model;
      } else if (
        find(row.fields, {
          label: 'PDF Item Page'
        })
      ) {
        item.pdfItemPage = find(row.fields, {
          label: 'PDF Item Page'
        }).model;
      } else if (
        find(row.fields, {
          label: 'Custom Reference Text'
        })
      ) {
        item.customReferenceText = find(row.fields, {
          label: 'Custom Reference Text'
        }).model;
      } else if (
        find(row.fields, {
          label: 'Show'
        })
      ) {
        item.show = find(row.fields, {
          label: 'Show'
        }).model;
      } else if (
        find(row.fields, {
          label: 'Indent'
        })
      ) {
        item.indent = find(row.fields, {
          label: 'Indent'
        }).model;
        if (dataExists(item.indent)) this.indent = item.indent;
      } else if (
        find(row.fields, {
          label: 'Text Color'
        })
      ) {
        item.textColor = find(row.fields, {
          label: 'Text Color'
        }).model;
      } else if (
        find(row.fields, {
          label: 'Fill In'
        })
      ) {
        item.fillIn = find(row.fields, {
          label: 'Fill In'
        }).model
          ? find(row.fields, {
              label: 'Fill In'
            }).model.text
          : null;
      } else if (
        find(row.fields, {
          label: 'Hide Comments'
        })
      ) {
        item.hideComments = find(row.fields, {
          label: 'Hide Comments'
        }).model;
      } else if (
        find(row.fields, {
          label: 'Ignore Count'
        })
      ) {
        item.ignoreCount = find(row.fields, {
          label: 'Ignore Count'
        }).model;
      } else if (
        find(row.fields, {
          label: 'Required'
        })
      ) {
        item.required = find(row.fields, {
          label: 'Required'
        }).model;
      } else if (
        find(row.fields, {
          label: 'Source Name'
        })
      ) {
        sources.push(
          find(row.fields, {
            label: 'Source Name'
          }).model
        );
      } else if (
        find(row.fields, {
          label: 'Sub Answers'
        })
      ) {
        item.subAnswers = find(row.fields, {
          label: 'Sub Answers'
        }).model;
      }
    });
    item = new CFI(item);
    console.log('newly created CFI: ', item);
    if (sources.length) item.sources = sources;

    if (this.itemWizard) item.itemWizard = this.itemWizard.toJson(this);

    forEach(this.codesAndScore, function (codesAndScore) {
      forEach(codesAndScore.rows, function (row) {
        if (
          !find(row.fields, {
            type: 'add-row'
          })
        ) {
          item.codesAndScore.push(
            new CAS(
              find(row.fields, {
                label: 'Answer ID'
              }).text,
              find(row.fields, {
                label: 'Answer'
              }).model,
              find(row.fields, {
                label: 'Score'
              }).model,
              find(row.fields, {
                label: 'Hide Score'
              }).model,
              find(row.fields, {
                label: 'Omit'
              }).model,
              find(row.fields, {
                label: 'Fill In'
              }).model
                ? find(row.fields, {
                    label: 'Fill In'
                  }).model.text
                : null,
              find(row.fields, {
                label: 'Override Risk Category'
              }).model,
              find(row.fields, {
                label: 'Auto Answer'
              }).model,
              find(row.fields, {
                label: 'Warning Check'
              }).model
            )
          );
        }
      });
    });

    return item;
  }
}

class CodesAndScore extends Block {
  constructor(data) {
    // declarations for Block properties
    data.type = 'codes-and-score';
    data.id = data.id || `A-${shortid.generate()}`;
    super(data);

    // specific declarations for Variable properties
    this.title = this.riskFactor;

    // create object's rows and check if data is being passed in for the rows
    if (!data.rows) {
      // Row 1 --------------------------------------------------------------
      this.addRow({
        input: false
      }).addAddRowField({
        text: 'Answer'
      });
      this.addRow({
        type: 'answer'
      });
    } else if (data.fromToolFile) {
      // Row 1 --------------------------------------------------------------
      this.addRow({
        input: false,
        unshift: true
      }).addAddRowField({
        text: 'Answer'
      });
    }
    // recast answer rows
    if (data.rows) {
      for (let i = 0; i < data.rows.length; i++) {
        if (data.rows[i].type === 'answer') {
          data.rows[i] = new Answer(data.rows[i]);
        }
      }
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson() {
    let codesAndScores = [];

    // let CAS = class {
    //   constructor(id, text, score, hideScore, omit, fillIn, overrideRiskCategory, autoAnswer) {
    //     this.id = id || '';
    //     this.text = text || '';
    //     this.score = dataExists(score) ? score : null; // changed to null default to allow for answers without any influence on total score
    //     this.hideScore = dataExists(hideScore) ? hideScore : false;
    //     this.omit = dataExists(omit) ? omit : null;
    //     this.fillIn = fillIn || null;
    //     this.overrideRiskCategory = dataExists(overrideRiskCategory) ? overrideRiskCategory : null;
    //     if (dataExists(autoAnswer)) {
    //       autoAnswer = autoAnswer.replace(/\s/g, '');
    //       this.autoAnswer = autoAnswer.split(',');
    //     } else {
    //       this.autoAnswer = null;
    //     }
    //   }
    // };

    forEach(this.rows, function (row) {
      if (
        find(row.fields, {
          label: 'Answer ID'
        })
      ) {
        codesAndScores.push(
          new CAS(
            find(row.fields, {
              label: 'Answer ID'
            }).text,
            find(row.fields, {
              label: 'Answer'
            }).model,
            find(row.fields, {
              label: 'Score'
            }).model,
            find(row.fields, {
              label: 'Hide Score'
            }).model,
            find(row.fields, {
              label: 'Omit'
            }).model,
            find(row.fields, {
              label: 'Fill In'
            }).model
              ? find(row.fields, {
                  label: 'Fill In'
                }).model.text
              : null,
            find(row.fields, {
              label: 'Override Risk Category'
            }).model,
            find(row.fields, {
              label: 'Auto Answer'
            }).model,
            find(row.fields, {
              label: 'Warning Check'
            }).model
          )
        );
      }
    });

    return codesAndScores;
  }
}

class ItemWizard extends Block {
  constructor(data) {
    // declarations for Block properties
    data.type = 'item-wizard';
    data.id = data.id || `IW-${shortid.generate()}`;
    super(data);

    // specific declarations for Variable properties
    let i;
    this.wizardQuestions = [];
    if (data.wizardQuestions && data.wizardQuestions.length) {
      for (i = 0; i < data.wizardQuestions.length; i++) {
        this.wizardQuestions.push(new WizardQuestion(data.wizardQuestions[i]));
      }
    }
    this.multipleAnswerConditions = data.multipleAnswerConditions || [];
    this.manipulateVariables = data.manipulateVariables || [];
    /*
     NOTE (Alex) manipulateVariables is put on ItemWizard so we can freely access it within
     the scope of editing an itemWizard even though it belongs on a wizardAnswer
      */
    this.calculation = data.calculation || null; // houses variables, conditions, and resolutions
    this.variables = data.variables || [];
    this.conditions = data.conditions || [];
    this.resolutions = data.resolutions || [];
    this.references = data.references || [];
    this.operations = data.operations || [];

    if (!data.rows) {
      // create object's rows and check if data is being passed in for the rows
      // Row 1 --------------------------------------------------------------
      this.addRow({
        input: false,
        output: true
      }).addOutputLabelField({
        label: 'First Wizard Question'
      });
    }
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson(codingFormItem) {
    console.debug('this.wizardQuestions', this.wizardQuestions);

    let t = this;
    let WQ = class {
      // wizard question
      constructor(data) {
        this.id = data.id || '';
        this.questionType = data.questionType || 0;
        this.questionText = data.questionText || '';
        this.pdfItemHelpBookmark = data.pdfItemHelpBookmark || null;
        this.pdfItemPage = data.pdfItemPage || null;
        this.wizardAnswers = data.wizardAnswers || [];
        this.multipleAnswerConditions = data.multipleAnswerConditions || [];
      }
    };

    let WA = class {
      // wizard answer
      constructor(row) {
        this.id =
          find(row.fields, {
            label: 'Answer ID'
          }).text || '';
        this.text =
          find(row.fields, {
            label: 'Answer'
          }).model || '';
        this.nextWizardQuestionId = row.nextWizardQuestionId || null;
        this.answerScore = dataExists(row.answerScore) ? row.answerScore : null;
        this.questionAnswerToSelect = dataExists(row.questionAnswerToSelect)
          ? row.questionAnswerToSelect
          : null;
        this.spawnModal = find(row.fields, {
          label: 'Spawn Modal'
        }).model
          ? true
          : false;
        this.modalText =
          find(row.fields, {
            label: 'Modal Text'
          }).model || '';
        this.manipulateVariables = row.manipulateVariables || [];
        this.resolveVariables =
          find(row.fields, {
            label: 'Resolve Variables'
          }).model || false;
        this.increment = dataExists(
          find(row.fields, {
            label: 'Increment'
          }).model
        )
          ? find(row.fields, {
              label: 'Increment'
            }).model
          : null;
        this.isSolo =
          dataExists(
            find(row.fields, {
              label: 'Is Solo'
            }).model
          ) || false;
        this.multiplicand =
          find(row.fields, {
            label: 'Multiplicand'
          }).model || null;
      }
    };

    let Calculation = class {
      constructor(variables, operations, conditions, resolutions) {
        this.variables = variables || {};
        this.operations = operations || [];
        this.conditions = conditions || [];
        this.resolutions = resolutions || [];
      }
    };

    let Item = class {
      // item wizard item
      constructor(wizardQuestions, calculation, references) {
        this.wizardQuestions = wizardQuestions || [];
        this.calculation = new Calculation(calculation);
        this.references = references || [];
      }
    };

    let MAC = class {
      // multiple answer condition
      constructor(operation, right, answerScore, nextWizardQuestionId, action) {
        this.operation = operation || null;
        this.right = dataExists(right) ? right : null;
        this.answerScore = answerScore || null;
        this.nextWizardQuestionId = nextWizardQuestionId || null;
        this.action = action || null;
      }
    };

    let MV = class {
      // manipulate variable
      constructor(variableLabel, operation, rightOperand) {
        this.variableLabel = variableLabel || null;
        this.operation = operation || null;
        this.rightOperand = rightOperand || null;
      }
    };

    function findInputId(inputId) {
      let object = find(t.wizardQuestions, function (question) {
        return find(question.rows, {
          inputNodeId: inputId
        });
      });

      if (!object) {
        return find(codingFormItem.codesAndScore[0].rows, function (answer) {
          return answer.inputNodeId === inputId;
        });
      } else {
        return object;
      }
    }

    let item = new Item();

    //Find the first wizard question
    let firstWizardQuestionInputId = find(this.rows, function (row) {
      return find(row.fields, {
        label: 'First Wizard Question'
      });
    }).outputIds[0];

    if (!firstWizardQuestionInputId)
      console.error('Could not find First Wizard Question Input Id');

    let firstWizardQuestionId = find(this.wizardQuestions, function (wq) {
      let compare = find(wq.rows, function (wqRow) {
        return find(wqRow.fields, {
          label: 'PDF Item Bookmark'
        });
      }).inputNodeId;

      return firstWizardQuestionInputId === compare;
    });

    if (firstWizardQuestionId) firstWizardQuestionId = firstWizardQuestionId.id;
    if (!firstWizardQuestionId)
      console.error('Could not find First Wizard Question Id');
    let macOutputId;

    // Build the wizard questions
    forEach(this.wizardQuestions, function (wq) {
      let wizQuest = new WQ(wq);
      console.debug(wizQuest);

      forEach(wq.rows, function (row) {
        /*
         create unique clone so we can add/manipulate variables
         without affecting the original wizard question object's row
          */
        row = cloneDeep(row);
        if (
          find(row.fields, {
            label: 'PDF Item Bookmark'
          })
        ) {
          // on meta information row
          wizQuest.pdfItemHelpBookmark = find(row.fields, {
            label: 'PDF Item Bookmark'
          }).model;
          wq.pdfItemHelpBookmark = wizQuest.pdfItemHelpBookmark;
          wizQuest.pdfItemPage = find(row.fields, {
            label: 'PDF Item Page'
          }).model;
          wq.pdfItemPage = wizQuest.pdfItemPage;
          wizQuest.questionText = find(row.fields, {
            label: 'Question Text'
          }).model;
          wq.questionText = wizQuest.questionText;
        } else if (
          find(row.fields, {
            label: 'Answer ID'
          })
        ) {
          // on wizard answer row
          // find nextWizardQuestionId
          if (row.outputIds.length) {
            // should just have one outputId (can only go to one next wizard question)
            // row.nextWizardQuestionId = find(t.wizardQuestions, function(question) {
            //   return some(question.rows, {
            //     inputNodeId: row.outputIds[0]
            //   });
            // });

            let nextQuestion = find(t.wizardQuestions, (question) => {
              return some(question.rows, (item) => {
                return row.outputIds.includes(item.inputNodeId);
              });
            });

            row.nextWizardQuestionId = nextQuestion ? nextQuestion.id : null;

            // find answerScore
            row.answerScore = null; // set default value
            row.questionAnswerToSelect = null; // set default value
            if (!row.nextWizardQuestionId) {
              // no nextWizardQuestionId found so it might give a score
              forEach(codingFormItem.codesAndScore[0].rows, function (answer) {
                if (includes(row.outputIds, answer.inputNodeId)) {
                  row.answerScore = find(answer.fields, {
                    label: 'Score'
                  }).model;
                  row.questionAnswerToSelect = answer.id;
                }
              });
            }
          }
          // find manipulateVariables array
          /*
             we know the wizard answer input is on the first row of the manipulate variables block
             so we can just assert manVar.rows[0].inputIds to figure out if the row we're on is
             connected to this manipulate variables block
              */
          let manVarFound = find(t.manipulateVariables, function (manVar) {
            return includes(manVar.rows[0].inputIds, row.outputNodeId);
          });
          row.manipulateVariables = [];
          if (manVarFound) {
            forEach(manVarFound.rows, function (manVarRow) {
              if (
                !find(manVarRow.fields, {
                  label: 'Wizard Answer Input'
                })
              ) {
                // we're on a manipulate variable row
                let variableFound = null;
                forEach(t.variables, function (varBlock) {
                  forEach(varBlock.rows, function (varBlockRow) {
                    if (
                      !variableFound &&
                      includes(manVarRow.inputIds, varBlockRow.outputNodeId)
                    ) {
                      variableFound = find(varBlockRow.fields, {
                        label: 'Variable Name'
                      }).model;
                    }
                  });
                });
                if (!variableFound)
                  t.notify.error(
                    'Item Wizard variable for Manipulate Variables could not be found!'
                  );
                row.manipulateVariables.push(
                  new MV(
                    variableFound,
                    find(manVarRow.fields, {
                      label: 'Operator'
                    }).model.label,
                    find(manVarRow.fields, {
                      label: 'Right'
                    }).model
                  )
                );
              }
            });
          }
          // create WA() with row info
          wizQuest.wizardAnswers.push(new WA(row));
        } else {
          // we're on the multiple answer conditions row and need to log the macoutputid for later
          macOutputId = row.outputNodeId;
        }
      });
      /*
        Check for Multiple Answer Conditions
       */
      if (t.multipleAnswerConditions.length) {
        // build array for multiple answer conditions
        let mac;
        let linkedObj;
        forEach(t.multipleAnswerConditions, function (multipleAnswerCondition) {
          if (multipleAnswerCondition.rows[0].inputIds[0] === macOutputId) {
            // if connection is found, create the array on the wizard question
            forEach(multipleAnswerCondition.rows, function (row) {
              if (
                some(row.fields, {
                  label: 'Operator'
                })
              ) {
                linkedObj = findInputId(row.outputIds[0]);
                if (linkedObj) {
                  mac = new MAC(
                    find(row.fields, {
                      label: 'Operator'
                    }).model.text,
                    find(row.fields, {
                      label: 'Right'
                    }).model
                  );
                  if (linkedObj.type === 'wizard-question') {
                    mac.action = 'nextQuestion';
                    mac.nextWizardQuestionId = linkedObj.id;
                  } else {
                    mac.action = 'giveScore';
                    mac.answerScore = find(linkedObj.fields, {
                      label: 'Score'
                    }).model;
                  }
                  wizQuest.multipleAnswerConditions.push(mac);
                }
              }
            });
          }
        });
      }
      if (wizQuest.id === firstWizardQuestionId) {
        item.wizardQuestions.unshift(wizQuest);
      } else {
        item.wizardQuestions.push(wizQuest);
      }
    });
    /*
    Build variables for item.calculation.variables
     */
    if (this.variables.length) {
      forEach(this.variables, function (variable) {
        forEach(variable.rows, function (row) {
          if (
            !some(row.fields, {
              type: 'add-row'
            })
          ) {
            item.calculation.variables[
              find(row.fields, {
                label: 'Variable Name'
              }).model
            ] = find(row.fields, {
              label: 'Default Value'
            }).model;
          }
        });
      });
    }
    /*
    Build operations for item.calculation.Operations
    */

    if (this.operations.length) {
      let wizardBlocks = flatten([
        this.variables,
        this.conditions,
        this.operations,
        this.resolutions
      ]);

      console.debug('wizardBlocks', wizardBlocks);

      let operations = OpUtils.MakeOperationsExpression(wizardBlocks);

      item.calculation.operations = operations;
      item.calculation.opInput = find(operations, (op) => {
        let inputBlock;

        OpUtils.ForBlocksRows(wizardBlocks, (row, block) => {
          var field = find(row.fields, {
            label: 'Step'
          });

          if (field && field.model === -1) {
            inputBlock = block;
            return false;
          }
        });

        return inputBlock && op.varId === inputBlock.id;
      });

      item.calculation.opOutput = find(operations, (op) => {
        let match = false;
        let inputBlock;

        OpUtils.ForBlocksRows(wizardBlocks, (row, block) => {
          var field = find(row.fields, {
            label: 'Step'
          });

          if (field && field.model === -2) {
            inputBlock = block;
            return false;
          }
        });

        if (inputBlock && op.varId === inputBlock.id) {
          if (op.nextVarId) {
            op.nextVarId = null;
          }

          if (op.nextVarTrueId) {
            op.nextVarTrueId = null;
          }

          if (op.nextVarFalseId) {
            op.nextVarFalseId = null;
          }

          match = true;
        }

        return match;
      });

      console.debug(item.operations);
    }

    /*
    Build conditions for item.calculation.conditions
     */
    if (this.conditions.length) {
      forEach(this.conditions, function (condition) {
        forEach(condition.rows, function (row) {
          if (
            !some(row.fields, {
              type: 'add-row'
            })
          ) {
            let inputNode = row.inputIds[0];
            let variableName = null;

            find(t.variables, function (variable) {
              if (!variableName) {
                forEach(variable.rows, function (varRow) {
                  if (varRow.outputNodeId === inputNode && !variableName) {
                    variableName = find(varRow.fields, {
                      label: 'Variable Name'
                    }).model;
                  }
                });
              }
            });

            let operation;

            operation = find(row.fields, {
              label: 'Operator'
            }).model
              ? find(row.fields, {
                  label: 'Operator'
                }).model.text
              : null;

            let right;
            right = dataExists(
              find(row.fields, {
                label: 'Right'
              }).model
            )
              ? find(row.fields, {
                  label: 'Right'
                }).model
              : null;

            item.calculation.conditions.push({
              conditionId:
                find(row.fields, {
                  label: 'Condition ID'
                }).text || null,
              left: variableName,
              operation,
              right
            });
          }
        });
      });
    }
    /*
    build resolutions for item.calculation.resolutions
     */
    if (this.resolutions.length) {
      let i;
      let k;
      let j;
      for (i = 0; i < this.resolutions.length; i++) {
        forEach(this.resolutions[i].rows, function (row) {
          if (
            !find(row.fields, {
              type: 'add-row'
            })
          ) {
            let condition1;
            let condition2;
            let operation;
            let result;
            for (j = 0; j < t.conditions.length; j++) {
              forEach(t.conditions[i].rows, function (condRow) {
                for (k = 0; k < row.inputIds.length; k++) {
                  if (condRow.outputNodeId === row.inputIds[k]) {
                    condition1
                      ? (condition2 = find(condRow.fields, {
                          label: 'Condition ID'
                        }).text)
                      : (condition1 = find(condRow.fields, {
                          label: 'Condition ID'
                        }).text);
                  }
                }
              });
            }
            for (j = 0; j < codingFormItem.codesAndScore.length; j++) {
              if (!result) {
                forEach(
                  codingFormItem.codesAndScore[j].rows,
                  function (cASRow) {
                    if (row.outputIds[0] === cASRow.inputNodeId && !result) {
                      result = find(cASRow.fields, {
                        label: 'Score'
                      }).model;
                    }
                  }
                );
              }
            }
            operation = find(row.fields, {
              label: 'Operator'
            }).model.text;
            item.calculation.resolutions.push({
              resolutionId: row.id,
              condition1,
              condition2,
              operation,
              result
            });
          }
        });
      }
    }
    /*
    check if calculation has content. if not, delete it
     */
    let calcStays = false;
    if (item.calculation) {
      // if (item.calculation.operations.length) {
      //
      // }

      if (
        item.calculation.conditions.length ||
        item.calculation.resolutions.length
      ) {
        calcStays = true;
      }

      for (let prop in item.calculation.variables) {
        if (item.calculation.variables.hasOwnProperty(prop)) {
          calcStays = true;
        }
      }

      if (!calcStays) Reflect.deleteProperty(item, 'calculation');
    }
    /*
    build references in item.references
     */
    if (this.references.length) {
      forEach(this.references, function (reference) {
        forEach(reference.rows, function (row) {
          if (
            !find(row.fields, {
              type: 'add-row'
            })
          ) {
            item.references.push({
              page: find(row.fields, {
                label: 'Page Number'
              }).model,
              icon: find(row.fields, {
                label: 'Icon'
              }).model.label,
              tooltip: find(row.fields, {
                label: 'Tooltip'
              }).model,
              title: find(row.fields, {
                label: 'Modal Title'
              }).model
            });
          }
        });
      });
    }

    return item;
  }
}

class WizardQuestion extends Block {
  constructor(data) {
    // declarations for Block properties
    data.type = 'wizard-question';
    data.id = data.id || `WQ-${shortid.generate()}`;

    let dataRowsExist = data.rows ? data.rows : false;

    console.log('wizard question data: ', angular.copy(data));
    // if (data.rows) Reflect.deleteProperty(data, 'rows');
    super(data);

    // specific declarations for Variable properties
    this.pdfItemHelpBookmark = data.pdfItemHelpBookmark || null;
    this.pdfItemPage = data.pdfItemPage || null;
    this.questionText = data.questionText || '';
    this.questionType = dataExists(data.questionType)
      ? data.questionType
      : null;

    let i;
    this.rows = [];
    // create object's rows and check if data is being passed in for the rows
    // Row 1 --------------------------------------------------------------
    let macRowFound = dataRowsExist
      ? find(dataRowsExist, {
          type: 'multiple-answer-conditions'
        })
      : false;
    this.addRow({
      inputNodeId: macRowFound ? macRowFound.inputNodeId : null,
      inputIds: macRowFound ? macRowFound.inputIds : null,
      input: false,
      output: true,
      outputNodeId: macRowFound ? macRowFound.outputNodeId : null,
      outputIds: macRowFound ? macRowFound.outputIds : null,
      type: 'multiple-answer-conditions'
    }).addOutputLabelField({
      label: 'Multiple Answer Conditions',
      text: 'multiple-answer-conditions'
    });
    // Row 2 --------------------------------------------------------------
    let questionRowFound;
    if (dataRowsExist) {
      find(dataRowsExist, (row) => {
        if (
          some(row.fields, {
            label: 'Question Text'
          }) &&
          some(row.fields, {
            label: 'PDF Item Page'
          })
        ) {
          questionRowFound = row;
        }
      });
    }
    this.addRow({
      inputNodeId: questionRowFound ? questionRowFound.inputNodeId : null,
      inputIds: questionRowFound ? questionRowFound.inputIds : null,
      input: true,
      output: false,
      outputNodeId: questionRowFound ? questionRowFound.outputNodeId : null,
      outputIds: questionRowFound ? questionRowFound.outputIds : null,
      type: 'wizard-question-row'
    })
      .addTextAreaInputField({
        label: 'Question Text',
        model: dataExists(data.questionText) ? data.questionText : null
      })
      .addNumberInputField({
        label: 'PDF Item Page',
        model: dataExists(data.pdfItemPage) ? data.pdfItemPage : null
      })
      .addTextInputField({
        label: 'PDF Item Bookmark',
        model: dataExists(data.pdfItemHelpBookmark)
          ? data.pdfItemHelpBookmark
          : null
      })
      .addAddRowField({
        text: 'Wizard Answer'
      });
    if (!data.wizardAnswers && !dataRowsExist) {
      this.rows.push(new WizardAnswer({}));
    } else if (data.wizardAnswers) {
      for (i = 0; i < data.wizardAnswers.length; i++) {
        this.rows.push(new WizardAnswer(data.wizardAnswers[i]));
      }
    } else if (dataRowsExist) {
      for (i = 0; i < dataRowsExist.length; i++) {
        if (dataRowsExist[i].type === 'wizard-answer')
          this.rows.push(new WizardAnswer(dataRowsExist[i]));
      }
    }
  }
}

class WizardVariables extends Block {
  constructor(data) {
    data.type = 'wizard-variables';
    super(data);

    if (!data.rows) {
      this.addRow().addAddRowField({
        text: 'Wizard Variable'
      });
    } else if (data.fromToolFile) {
      this.addRow({
        unshift: true
      }).addAddRowField({
        text: 'Wizard Variable'
      });
    }
  }
}

class WizardConditions extends Block {
  constructor(data) {
    data.type = 'wizard-conditions';
    super(data);

    if (!data.rows) {
      this.addRow().addAddRowField({
        text: 'Wizard Condition'
      });
      this.addRow({
        type: 'wizard-condition'
      });
    } else if (data.fromToolFile) {
      this.addRow({
        unshift: true
      }).addAddRowField({
        text: 'Wizard Condition'
      });
    }
  }
}

class WizardResolutions extends Block {
  constructor(data) {
    data.type = 'wizard-resolutions';
    super(data);

    if (!data.rows) {
      this.addRow().addAddRowField({
        text: 'Wizard Resolution'
      });
      this.addRow({
        type: 'wizard-resolution'
      });
    } else if (data.fromToolFile) {
      this.addRow({
        unshift: true
      }).addAddRowField({
        text: 'Wizard Resolution'
      });
    }
  }
}

class WizardReferences extends Block {
  constructor(data) {
    data.type = 'wizard-reference';
    super(data);

    if (!data.rows) {
      this.addRow().addAddRowField({
        text: 'Wizard Reference'
      });
      this.addRow({
        type: 'wizard-reference'
      });
    } else if (data.fromToolFile) {
      this.addRow({
        unshift: true
      }).addAddRowField({
        text: 'Wizard Reference'
      });
    }
  }
}

class MultipleAnswerConditions extends Block {
  constructor(data) {
    data.type = 'multiple-answer-conditions';
    super(data);

    if (!data.rows) {
      // Row 1 ------------------------------------
      this.addRow({
        input: true
      })
        .addInputLabelField({
          label: 'Wizard Question Input'
        })
        .addAddRowField({
          text: 'Multiple Answer Condition'
        });
      // Row 2 ------------------------------------
      this.addRow({
        type: 'multiple-answer-condition'
      });
    } else if (data.fromToolFile) {
      // Row 1 ------------------------------------
      this.addRow({
        unshift: true,
        input: true
      })
        .addInputLabelField({
          label: 'Wizard Question Input'
        })
        .addAddRowField({
          text: 'Multiple Answer Condition'
        });
    }
  }
}

class ManipulateVariables extends Block {
  constructor(data) {
    data.type = 'manipulate-variables';
    super(data);

    if (!data.rows) {
      this.addRow({
        input: true
      })
        .addInputLabelField({
          label: 'Wizard Answer Input'
        })
        .addAddRowField({
          text: 'Manipulate Variable'
        });
    }
  }
}

//endregion subBlock: Tool Blocks

//region subBlock: Meta Tool Blocks
class MetaToolTool extends Block {
  constructor(data) {
    data.type = 'meta-tool-tool';
    super(data);

    this.toolName = data.name;
    this.toolId = data.id;
    if (!data.rows) {
      this.addRow({
        input: false,
        output: false
      }).addLabelField({
        text: data.name + ' Evaluation'
      });
      this.addRow({
        input: false,
        output: true
      }).addOutputLabelField({
        label: 'Score'
      });
      this.addRow({
        input: false,
        output: true
      }).addOutputLabelField({
        label: 'Risk Category'
      });
    }
  }

  toJson() {
    let metaToolTool = {
      id: this.toolId
    };
    return metaToolTool;
  }
}

class MetaToolComparator extends Block {
  constructor(data) {
    data.type = 'meta-tool-comparator';
    data.id = data.id || `MTC-${shortid.generate()}`;

    super(data);

    // Row 1 -----------------------------------------------
    this.addRow({
      input: true,
      output: false
    })
      .addTextInputField({
        label: 'Value'
      })
      .addSelectField({
        label: 'Operator',
        options: new OperatorsGroup().add('==', '==').add('!=', '!=').operators
      });
    // Row 2 -----------------------------------------------
    this.addRow({
      input: false,
      output: false
    }).addSelectField({
      label: 'Comparator',
      options: new OperatorsGroup().add('&&', '&&').add('||', '||').operators
    });
    // Row 3 -----------------------------------------------
    this.addRow({
      input: true,
      output: false
    })
      .addTextInputField({
        label: 'Value'
      })
      .addSelectField({
        label: 'Operator',
        options: new OperatorsGroup().add('==', '==').add('!=', '!=').operators
      });
    // Row 4 -----------------------------------------------
    this.addRow({
      input: false,
      output: true
    }).addOutputLabelField({
      label: 'Output'
    });
  }

  // To Json function returns clean Json of this object for sending to backend
  toJson(toolData) {
    let MTCSide = class {
      constructor(data) {
        this.toolId = data.toolId;
        this.toolOutputType = data.toolOutputType;
        this.comparator = data.comparator;
        this.compareValue = data.compareValue;
      }
    };

    let Result = class {
      constructor(data) {
        this.answerId = data.answerId;
        this.answer = data.answer;
        this.score = data.score;
      }
    };

    let MTC = class {
      constructor(id, left, operation, right, result) {
        this.id = id;
        this.left = new MTCSide(left);
        this.operation = operation;
        this.right = new MTCSide(right);
        this.result = new Result(result);
      }
    };

    let metaToolComparator = new MTC();

    forEach(this.rows, function (row, index) {
      if (index === 0 || index === 2) {
        // work on "left" or "right"
        // find tool connection
        let side = new MTCSide();
        let inputId = row.inputIds[0];
        let outputType = null;

        let toolConnection = find(toolData.tools, function (tool) {
          if (
            find(tool.rows, {
              outputNodeId: inputId
            })
          ) {
            outputType = find(tool.rows, {
              outputNodeId: inputId
            }).fields[0].label;
            return true;
          }
        });
        if (!toolConnection)
          console.error('No Tool Connection Found on Meta Tool Comparator');

        // assign values to side variable
        side.toolId = toolConnection.toolId;
        side.toolOutputType = outputType;
        side.comparator = find(row.fields, {
          label: 'Operator'
        });
        side.compareValue = find(row.fields, {
          label: 'Value'
        });
        if (index === 0) {
          metaToolComparator.left = side;
        } else {
          metaToolComparator.right = side;
        }
      } else if (index === 1) {
        // get operation
        metaToolComparator.operation = find(row.fields, {
          label: 'Comparator'
        }).model.label;
      } else if (index === 3) {
        // get result from code and score
        let score = find(toolData.codesAndScore.rows, function (cASRow) {
          return cASRow.inputNodeId === row.outputIds[0];
        });
        metaToolComparator.result.answerId = find(score.fields, {
          label: 'Answer ID'
        }).text;
        metaToolComparator.result.answer = find(score.fields, {
          label: 'Answer'
        }).model;
        metaToolComparator.result.score = find(score.fields, {
          label: 'Score'
        }).model;
      }
    });

    return metaToolComparator;
  }
}

//endregion subBlock: Meta Tool Blocks

//region subBlock: Report Template Blocks
class ReportTemplateInformation extends Block {
  constructor(data) {
    data.type = 'report-template-information';
    super(data);
    this.name = data.name;
    this.version = data.version || '';
    this.id = data.id || null;
    this.toolId = data.toolId || null;
    this.toolCommitId = data.toolCommitId || null;
    this.undeletable = true;

    if (!data.rows) {
      // Row 1 ---------------------------------------------------------
      this.addRow()
        .addLabelField({
          label: 'Version',
          text: data.version
        })
        .addLabelField({
          label: 'ID',
          text: data.id || null
        });
    }
  }
}

class Overview extends Block {
  constructor(data) {
    data.type = 'overview';
    super(data);
    this.title = data.title || '';
    this.hasToggle = data.hasToggle || false;
    this.toggleDefault = data.toggleDefault || null;
    this.options = data.options || [];
    this.undeletable = true;

    if (!data.rows) {
      this.addRow().addTextInputField({
        label: 'Title',
        model: dataExists(data.title) ? data.title : null
      });
      this.addRow()
        .addToggleField({
          label: 'Has Toggle',
          model: dataExists(data.hasToggle) ? data.hasToggle : null
        })
        .addToggleField({
          label: 'Toggle Default',
          model: dataExists(data.toggleDefault) ? data.toggleDefault : null
        });
      this.addRow({
        output: true
      }).addOutputLabelField({
        label: 'Overview Options'
      });
    }
  }
}

class OverviewOption extends Block {
  constructor(data) {
    data.type = 'overview-option';
    super(data);
    this.text = data.text || '';
    this.isDefault = data.isDefault || false;
    this.content = data.content || [];

    if (!data.rows) {
      this.addRow({
        input: true
      })
        .addTextInputField({
          label: 'Text',
          model: dataExists(data.text) ? data.text : null
        })
        .addToggleField({
          label: 'Is Default',
          model: dataExists(data.isDefault) ? data.isDefault : null
        })
        .addToggleField({
          label: 'Has Toggle',
          model: dataExists(data.hasToggle) ? data.hasToggle : null
        });
      this.addRow().addAddRowField({
        text: 'Content'
      });
      if (data.content) {
        for (let i = 0; i < data.content.length; i++) {
          this.addRow({
            type: 'overview-row',
            data: data.content[i]
          });
        }
      } else {
        this.addRow({
          type: 'overview-row'
        });
      }
    }
  }
}

class ItemDescriptions extends Block {
  constructor(data) {
    data.type = 'item-descriptions';
    super(data);
    this.title = data.title || '';
    this.hasToggle = data.hasToggle || false;
    this.toggleDefault = data.toggleDefault || null;
    this.descriptions = [];
    this.undeletable = true;

    if (!data.rows) {
      this.addRow().addTextInputField({
        label: 'Title',
        model: dataExists(data.title) ? data.title : null
      });
      this.addRow()
        .addToggleField({
          label: 'Has Toggle',
          model: dataExists(data.hasToggle) ? data.hasToggle : null
        })
        .addToggleField({
          label: 'Toggle Default',
          model: dataExists(data.toggleDefault) ? data.toggleDefault : null
        });
      if (data.hasOwnProperty('descriptions') && data.descriptions.length) {
        this.descriptions = [];
        let x = 1000;
        let y = 150;
        for (let i = 0; i < data.descriptions.length; i++) {
          data.descriptions[i].x = x;
          data.descriptions[i].y = y;
          this.descriptions.push(new ItemDescription(data.descriptions[i]));
          x += 150;
          y += 150;
        }
      }
      this.addRow({
        output: true
      }).addOutputLabelField({
        label: 'Descriptions'
      });
    } else if (
      data.hasOwnProperty('descriptions') &&
      data.descriptions.length
    ) {
      this.descriptions = data.descriptions;
    }
  }
}

class ItemDescription extends Block {
  constructor(data) {
    data.type = 'item-description';
    super(data);
    this.codingFormItemId = data.codingFormItemId || null;
    this.codingFormItemRiskFactor = data.codingFormItemRiskFactor || null;
    this.codingFormItemDescription = data.codingFormItemDescription || '';
    this.includeAllAnswers = data.includeAllAnswers || false;
    this.concatenate = data.concatenate || false;
    this.answerDescriptions = data.answerDescriptions || [];
    this.subEdit = true;
    this.undeletable = true;

    if (!data.rows) {
      this.addRow({
        input: true
      })
        .addLabelField({
          label: 'Coding Form Item ID',
          text: data.codingFormItemId || null
        })
        .addLabelField({
          label: 'Coding Form Item Risk Factor',
          text: data.codingFormItemRiskFactor || null
        });
      this.addRow().addTextAreaInputField({
        label: 'Coding Form Item Description',
        model: data.codingFormItemDescription || null
      });
      this.addRow()
        .addToggleField({
          label: 'Include All Answers',
          model: dataExists(data.includeAllAnswers)
            ? data.includeAllAnswers
            : null
        })
        .addToggleField({
          label: 'Concatenate',
          model: dataExists(data.concatenate) ? data.concatenate : null
        });
      this.addRow({
        output: true
      }).addOutputLabelField({
        label: 'Answer Descriptions'
      });

      if (
        data.hasOwnProperty('answerDescriptions') &&
        data.answerDescriptions.length
      ) {
        this.answerDescriptions = [];
        let x = 600;
        let y = 100;
        for (let i = 0; i < data.answerDescriptions.length; i++) {
          data.answerDescriptions[i].x = x;
          data.answerDescriptions[i].y = y;
          this.answerDescriptions.push(
            new AnswerDescriptions(data.answerDescriptions[i])
          );
          x += 150;
          y += 150;
        }
      }
    }
  }
}

class AnswerDescriptions extends Block {
  constructor(data) {
    data.type = 'answer-descriptions';
    if (data.questionText) {
      data.title = data.questionText;
    } else if (!data.title) {
      data.title = '';
    }
    super(data);
    this.id = data.id || null;
    this.answers = data.answers || [];
    this.undeletable = true;

    if (!data.rows) {
      this.addRow({
        input: true
      }).addLabelField({
        label: 'Answer Descriptions',
        text: ' '
      });
      if (data.hasOwnProperty('answers') && data.answers.length) {
        let rows = this.rows;
        let id = this.id;
        forEach(data.answers, function (ans) {
          if (id.substring(0, 2) === 'Q-') {
            rows.push(new BaseAnswerDescription(ans));
          } else {
            rows.push(new WizardAnswerDescription(ans));
          }
        });
      } else {
        this.addRow({
          type: 'answer-description'
        });
      }
    }
  }
}

class AdditionalCriteria extends Block {
  constructor(data) {
    if (data.rows && data.zIndex) {
      // we're getting a block to recast
      let block = angular.copy(data);
      data = {};
      data.options = block.options;
      data.blockId = block.blockId;
      data.x = block.x;
      data.y = block.y;
      data.zIndex = block.zIndex;
      data.id = block.id;
      forEach(block.rows, (row) => {
        if (
          some(row.fields, {
            label: 'Text'
          })
        ) {
          // Title section
          data.title = find(row.fields, {
            label: 'Text'
          }).model;
          data.titleInputNodeId = row.inputNodeId;
          data.titleOutputNodeId = row.outputNodeId;
        } else if (
          some(row.fields, {
            label: 'Has Toggle'
          })
        ) {
          // Toggle section(s)
          data.hasToggle = find(row.fields, {
            label: 'Has Toggle'
          }).model;
          data.toggleDefault = find(row.fields, {
            label: 'Toggle Default'
          }).model;
          data.hasToggleInputNodeId = row.inputNodeId;
          data.hasToggleOutputNodeId = row.outputNodeId;
        } else if (
          some(row.fields, {
            label: 'Section Type'
          })
        ) {
          // Section type section
          data.sectionType = find(row.fields, {
            label: 'Section Type'
          }).model
            ? find(row.fields, {
                label: 'Section Type'
              }).model.text
            : 0;
          data.sectionTypeInputNodeId = row.inputNodeId;
          data.sectionTypeOutputNodeId = row.outputNodeId;
        } else if (
          some(row.fields, {
            label: 'Additional Criteria Options'
          })
        ) {
          data.acoRowInputNodeId = row.inputNodeId;
          data.acoRowOutputNodeId = row.outputNodeId;
        }
      });
    }
    data.type = 'additional-criteria';
    data.title = data.title || '';
    super(data);
    this.hasToggle = data.hasToggle || false;
    this.toggleDefault = data.toggleDefault || null;
    this.sectionType = data.sectionType || 0;
    this.options = data.options || [];
    this.subEdit = true;

    let options = new OperatorsGroup()
      .add('Normal Choices', 0)
      .add('Conditional Based on Evaluation Score', 1).operators;
    if (!data.rows) {
      this.addRow({
        inputNodeId: data.titleInputNodeId ? data.titleInputNodeId : null,
        outputNodeId: data.titleOutputNodeId ? data.titleOutputNodeId : null
      }).addTextInputField({
        label: 'Text',
        model: data.title
      });
      this.addRow({
        inputNodeId: data.hasToggleInputNodeId
          ? data.hasToggleInputNodeId
          : null,
        outputNodeId: data.hasToggleOutputNodeId
          ? data.hasToggleOutputNodeId
          : null
      })
        .addToggleField({
          label: 'Has Toggle',
          model: data.hasToggle
        })
        .addToggleField({
          label: 'Toggle Default',
          model: data.toggleDefault
        });
      this.addRow({
        inputNodeId: data.sectionTypeInputNodeId
          ? data.sectionTypeInputNodeId
          : null,
        outputNodeId: data.sectionTypeOutputNodeId
          ? data.sectionTypeOutputNodeId
          : null
      }).addSelectField({
        label: 'Section Type',
        options,
        model: dataExists(data.sectionType)
          ? find(options, {
              text: parseInt(data.sectionType, 10)
            })
          : null
      });
      this.addRow({
        output: true,
        inputNodeId: data.acoRowInputNodeId,
        outputNodeId: data.acoRowOutputNodeId
      }).addOutputLabelField({
        label: 'Additional Criteria Options'
      });

      if (data.options) {
        this.options = [];
        let y = data.y;
        let x = data.x;
        for (let i = 0; i < data.options.length; i++) {
          if (!data.options[i].x || !data.options[i].y) {
            y += 350;
            if (y >= 4800) {
              y = 150;
              x += 150;
            }
            data.options[i].x = x;
            data.options[i].y = y;
          }
          this.options.push(new AdditionalCriteriaOption(data.options[i]));
        }
      }
    }
  }
}

class AdditionalCriteriaOption extends Block {
  constructor(data) {
    if (data.rows && data.zIndex) {
      // given a block to recast
      let block = angular.copy(data);
      data = {};
      data.choices = block.choices;
      data.content = [];
      data.options = block.options;
      data.id = block.id;
      data.x = block.x;
      data.y = block.y;
      data.zIndex = block.zIndex;
      data.title = block.title;
      forEach(block.rows, (row) => {
        if (
          some(row.fields, {
            label: 'Text'
          }) &&
          !some(row.fields, {
            label: 'Content Type'
          })
        ) {
          data.text = find(row.fields, {
            label: 'Text'
          }).model;
          data.textInputNodeId = row.inputNodeId;
          data.textOutputNodeId = row.outputNodeId;
        } else if (
          some(row.fields, {
            label: 'Is Default'
          })
        ) {
          data.isDefault = find(row.fields, {
            label: 'Is Default'
          }).model;
          data.hasToggle = find(row.fields, {
            label: 'Has Toggle'
          }).model;
          data.hasToggleInputNodeId = row.inputNodeId;
          data.hasToggleOutputNodeId = row.outputNodeId;
        } else if (
          some(row.fields, {
            label: 'Section Type'
          })
        ) {
          data.sectionType = find(row.fields, {
            label: 'Section Type'
          }).model
            ? find(row.fields, {
                label: 'Section Type'
              }).model.text
            : 0;
          data.scoreCondition = find(row.fields, {
            label: 'Score Condition'
          }).model;
          data.scoreRange = {
            low: null,
            high: null
          };
          data.scoreRange.low = find(row.fields, {
            label: 'Score Range (Low)'
          })
            ? find(row.fields, {
                label: 'Score Range (Low)'
              }).model
            : null;
          data.scoreRange.high = find(row.fields, {
            label: 'Score Range (High)'
          })
            ? find(row.fields, {
                label: 'Score Range (High)'
              }).model
            : null;
          data.scoreConditionInputNodeId = row.inputNodeId;
          data.scoreConditionOutputNodeId = row.outputNodeId;
        } else if (
          some(row.fields, {
            label: 'Content Type'
          })
        ) {
          data.content.push({
            type: find(row.fields, {
              label: 'Content Type'
            }).model
              ? find(row.fields, {
                  label: 'Content Type'
                }).model.text
              : null,
            text: find(row.fields, {
              label: 'Text'
            }).model,
            image: find(row.fields, {
              label: 'Image'
            }).model,
            x: find(row.fields, {
              label: 'Image Width (max 600)'
            }).model,
            y: find(row.fields, {
              label: 'Image Height'
            }).model,
            inputNodeId: row.inputNodeId,
            outputNodeId: row.outputNodeId
          });
        } else if (
          some(row.fields, {
            label: 'Additional Criteria Options'
          })
        ) {
          data.acoRowInputNodeId = row.inputNodeId;
          data.acoRowOutputNodeId = row.outputNodeId;
        } else if (
          some(row.fields, {
            label: 'Choices'
          })
        ) {
          data.choicesInputNodeId = row.inputNodeId;
          data.choicesOutputNodeId = row.outputNodeId;
        } else if (
          some(row.fields, {
            label: 'Content'
          })
        ) {
          data.contentInputNodeId = row.inputNodeId;
          data.contentOutputNodeId = row.outputNodeId;
        }
      });
    }
    data.type = 'additional-criteria-option';
    data.title = data.title || '';
    super(data);
    this.subEdit = true;
    this.text = data.text || '';
    this.isDefault = data.isDefault || false;
    this.hasToggle = data.hasToggle || false;
    this.sectionType = data.sectionType || 0;
    this.scoreCondition = data.scoreCondition || null;
    this.scoreRange = data.scoreRange || {
      low: null,
      high: null
    };
    this.options = data.options || [];
    this.choices = data.choices || [];
    this.content = data.content || [];

    let i;
    let options = new OperatorsGroup()
      .add('Normal Choices', 0)
      .add('Conditional Based on Evaluation Score', 1).operators;
    if (!data.rows) {
      this.addRow({
        input: true,
        inputNodeId: data.textInputNodeId,
        outputNodeId: data.textOutputNodeId
      }).addTextInputField({
        label: 'Text',
        model: dataExists(data.text) ? data.text : null
      });
      this.addRow({
        inputNodeId: data.hasToggleInputNodeId,
        outputNodeId: data.hasToggleOutputNodeId
      })
        .addToggleField({
          label: 'Is Default',
          model: dataExists(data.isDefault) ? data.isDefault : null
        })
        .addToggleField({
          label: 'Has Toggle',
          model: dataExists(data.hasToggle) ? data.hasToggle : null
        });
      this.addRow({
        inputNodeId: data.scoreConditionInputNodeId,
        outputNodeId: data.scoreConditionOutputNodeId
      })
        .addSelectField({
          label: 'Section Type',
          options,
          model: dataExists(data.sectionType)
            ? find(options, {
                text: parseInt(data.sectionType, 10)
              })
            : null
        })
        .addNumberInputField({
          label: 'Score Condition',
          model: dataExists(data.scoreCondition) ? data.scoreCondition : null
        })
        .addNumberInputField({
          label: 'Score Range (Low)',
          model: dataExists(data.scoreRange, 'low') ? data.scoreRange.low : null
        })
        .addNumberInputField({
          label: 'Score Range (High)',
          model: dataExists(data.scoreRange, 'high')
            ? data.scoreRange.high
            : null
        });
      this.addRow({
        output: true,
        inputNodeId: data.acoRowInputNodeId,
        outputNodeId: data.acoRowOutputNodeId
      }).addOutputLabelField({
        label: 'Additional Criteria Options'
      });
      this.addRow({
        output: true,
        inputNodeId: data.choicesInputNodeId,
        outputNodeId: data.choicesOutputNodeId
      }).addOutputLabelField({
        label: 'Choices'
      });
      this.addRow({
        inputNodeId: data.contentInputNodeId,
        outputNodeId: data.contentOutputNodeId
      }).addAddRowField({
        text: 'Content'
      });
      if (data.hasOwnProperty('content') && data.content.length) {
        for (i = 0; i < data.content.length; i++) {
          this.addRow({
            type: 'overview-row',
            label: 'Content',
            data: data.content[i]
          });
        }
      } else {
        this.addRow({
          type: 'overview-row',
          label: 'Content'
        });
      }
      if (data.hasOwnProperty('options') && data.options.length) {
        this.options = [];
        let x = data.x + 1200;
        let y = 100;
        for (i = 0; i < data.options.length; i++) {
          y += 350;
          if (y >= 4800) {
            y = 150;
            x += 150;
          }
          data.options[i].x = x;
          data.options[i].y = y;
          this.options.push(new AdditionalCriteriaOption(data.options[i]));
        }
      }
      if (data.hasOwnProperty('choices') && data.choices.length) {
        this.choices = [];
        let x = data.x + 2000;
        let y = 300;
        for (i = 0; i < data.choices.length; i++) {
          y += 150;
          if (y >= 4500) {
            y = 300;
            x += 200;
          }
          data.choices[i].x = x;
          data.choices[i].y = y;
          this.choices.push(new ReportTemplateChoice(data.choices[i]));
        }
      }
    }
  }
}

class ReportTemplateChoice extends Block {
  constructor(data) {
    data.type = 'report-template-choice';
    super(data);
    this.subEdit = true;
    this.text = data.text || '';
    this.basedOnScore = data.basedOnScore || 0;
    this.options = data.options || [];

    let i;
    let options = new OperatorsGroup()
      .add('Include Everything', 0)
      .add('Conditional Based on Evaluation Score', 1).operators;
    if (!data.rows) {
      this.addRow({
        input: true
      }).addTextInputField({
        label: 'Text',
        model: dataExists(data.text) ? data.text : null
      });
      this.addRow().addSelectField({
        label: 'Based On Score',
        options,
        model: dataExists(data.basedOnScore)
          ? find(options, {
              text: parseInt(data.basedOnScore, 10)
            })
          : null
      });
      this.addRow({
        output: true
      }).addOutputLabelField({
        label: 'Additional Criteria Options'
      });
      if (data.hasOwnProperty('options') && data.options.length) {
        let x = data.x + 600;
        let y = 100;
        this.options = [];
        for (i = 0; i < data.options.length; i++) {
          y += 250;
          if (y >= 4800) {
            y = 150;
            x += 150;
          }
          data.options[i].x = x;
          data.options[i].y = y;
          this.options.push(new AdditionalCriteriaOption(data.options[i]));
        }
      }
    }
  }
}

//endregion subBlock: Report Template Blocks
//endregion Blocks

/*@ngInject*/
export function BlockService() {
  return {
    Tool,
    MetaTool,
    MetaToolTool,
    Block,
    ChildTool,
    OffenderHistory,
    Comparator,
    MetaToolComparator,
    Operator,
    NumericComparator,
    SingleOperator,
    BinaryOperator,
    Variable,
    CodingFormItem,
    CodesAndScore,
    DictionaryTerm,
    Dictionary,
    Rules,
    Rule,
    ProrateTool,
    ItemWizard,
    WizardQuestion,
    MultipleAnswerCondition,
    MultipleAnswerConditions,
    SourceRow,
    Answer,
    WizardAnswer,
    ManipulateVariable,
    ManipulateVariables,
    WizardVariable,
    WizardVariables,
    WizardCondition,
    WizardConditions,
    WizardResolution,
    WizardResolutions,
    WizardReference,
    WizardReferences,
    RiskCategory,
    SubRiskCategory,
    CustomRiskCategory,
    RiskCategoryRow,
    RiskCategoryCriteriaRow,
    RuleOmit,
    RuleMinimumAnswers,
    RootToolInformation,
    ReportTemplateInformation,
    Overview,
    OverviewOption,
    OverviewRow,
    ItemDescriptions,
    ItemDescription,
    AnswerDescriptions,
    AdditionalCriteria,
    AdditionalCriteriaOption,
    ReportTemplateChoice
  };
}

export default // .service('Proration', ProrateService)
angular.module('app.Block', []).factory('Block', BlockService).name;
