import angular, { IDirective, IDirectiveFactory } from 'angular';

/**
 * ...
 */
const ngAnimate: IDirectiveFactory = () => {
  const link: IDirective['link'] = (_, element) => {
    element.addClass('auto-animate');
  };

  return { restrict: 'A', scope: false, link };
};

export default angular
  .module('app.ngAnimate', [])
  .directive('ngAnimate', ngAnimate).name;
