import angular from 'angular';
import {
  find,
  sortBy,
  filter,
  maxBy,
  forEach,
  indexOf,
  uniqBy,
  chain,
  reverse,
  orderBy
} from 'lodash';
import download from 'downloadjs';

import { State, Action, Mutation, Getter } from 'angular-store';
import { Controller, Inject } from '@decorators/ngCtrl';
// import { Client } from '@interfaces/client';
import { ClientModel } from '@models/client.model';
import { ClientConfigModel } from '@models/client-config.model';
import { ClientsState } from '@store/modules/clients';
import { MeState } from '@store/modules/me';
import { ItemTable } from '@/__modules/table-view/item-table/item-table.service';

interface OffenseClassificationTableItem {
  offence: string;
  doc: string;
  disposition: string;
  institution: string;
  comments: string;
}

const PROFILE_SECTIONS = {
  recentActivity: 0,
  casePlans: 1,
  details: 2
};

// prohibits editing of YLS and LS suite of tools
const PROHIBIT_EDIT = [
  { id: 4, name: 'LSI-R: SV' },
  { id: 105, name: 'YLS/CMI:SRV' },
  { id: 120, name: 'YLS/CMI 2.0' },
  { id: 124, name: 'LS/CMI' },
  { id: 157, name: 'LSI-R' }
];

@Controller
class DashboardClientView {
  client!: ClientModel;
  loading = false;
  activeSection: unknown = null;
  activityTable: unknown = null;
  mediaTable: unknown = null;
  recentActivityTable: any = { props: [], items: [], actions: [] };
  clientOhTable: any = { props: [], items: [], actions: [] };
  mediaTableRoute = '';
  hasCasePlanTools = false;
  hasOffenderHistoryTools = false;
  generateCasePlanEvalId: any;
  ocTable: ItemTable<OffenseClassificationTableItem>;
  priorOffencesTable: ItemTable<OffenseClassificationTableItem>;
  //
  customFields: any[] = [];
  // latest evaluations variables
  latestEvaluations: any[] = [];
  parsingLatestEvaluations = true;

  @Inject readonly $scope!: angular.IScope;
  @Inject readonly $rootScope!: angular.IRootScopeService;
  @Inject readonly $location!: angular.ILocationService;
  @Inject readonly $http!: angular.IHttpService;
  @Inject readonly $filter!: angular.IFilterService;
  @Inject readonly $timeout!: angular.ITimeoutService;
  @Inject readonly $state!: angular.ui.IStateService;
  @Inject readonly $api!: angular.gears.IApiService;
  @Inject readonly $store!: angular.gears.IStoreService;
  @Inject readonly $auth!: angular.gears.IAuthService;
  @Inject readonly $acl!: angular.gears.IAclService;
  @Inject readonly $modals!: angular.gears.IModalsService;
  @Inject readonly $generatePdf!: angular.gears.IGeneratePdfService;
  @Inject readonly getItems!: unknown;
  @Inject readonly getFormItems!: angular.gears.IGetFormItemsService;
  @Inject readonly Notification!: angular.uiNotification.INotificationService;
  @Inject readonly ItemTable!: unknown;
  @Inject readonly TableViewDataTypes!: unknown;
  @Inject readonly $util!: angular.gears.IUtilService;
  @Inject readonly evlUtils!: angular.gears.IEvalUtilsService;
  @Inject readonly reportUtils!: unknown;
  @Inject readonly notify!: angular.gears.INotifyService;
  @Inject readonly dataModals!: unknown;

  @State readonly me!: MeState;
  @State readonly clients!: ClientsState;
  @State readonly tools!: unknown;
  @Getter('evaluations/getById') readonly getEvaluationById!: any;
  @Action('evaluations/getForClient') readonly getClientEvls!: any;
  @Action('clients/getOffenderHistory') readonly getClientOffenderHistory!: any;
  @Action('clients/list') readonly listClients!: any;
  @Action('evaluations/get') readonly getEvaluation!: any;
  @Action('tools/list') readonly listTools!: any;
  @Action('tools/getToolData') readonly getToolData!: any;
  @Mutation('clients/remove') readonly removeClient!: any;
  @Getter isAdmin;
  @Getter('institutions/find') findInstitution;
  @Action('institutions/get') getInstitution;

  private onCustomPropertyChange = () => {
    this.customFields = Object.values(this.clientConfig.customFields)
      .filter(({ show }) => show)
      .map((item) => {
        const field: any = { ...item };

        Object.defineProperty(field, 'value', {
          get: () => {
            const value = this.client[field.key];

            if (value === true) return 'Yes';
            if (value === false) return 'No';
            if (value === null || value === undefined) return '[ No Value ]';

            return value;
          }
        });

        return field;
      });
  };

  get clientConfig() {
    return this.$store.state.clients.clientConfig as ClientConfigModel;
  }

  getInstitutionCustomOffenderHistoryTemplates(institutionId: string) {
    return find(this.$store.state.institutions.items, { id: institutionId })
      ?.customOffenderHistoryTemplates;
  }

  async getInstitutionCustomOffenderHistoryTemplate(
    institutionId: string,
    templateId: string
  ) {
    return await this.$store.dispatch(
      'institutions/getCustomOffenderHistoryTemplate',
      { institutionId, templateId }
    );
  }

  // get ethnicityList() {
  //   return this.clientConfig.fields.ethnicity.options ?? [];
  // }

  // get clientTypes() {
  //   return this.clientConfig.fields.type.options ?? [];
  // }

  async $onInit() {
    const { tools } = this;
    this.loading = true;
    this.$store.commit('setLoadingMessage', 'Loading Data...');
    this.$store.commit('setIsLoading', true);

    if (!this.tools?.items?.length) await this.listTools();

    this.hasCasePlanTools = this.$store.state.tools.hasCasePlanTools;

    if (!this.hasCasePlanTools) delete PROFILE_SECTIONS.casePlans;

    this.hasOffenderHistoryTools =
      this.$store.state.tools.hasOffenderHistoryTools;
    this.generateCasePlanEvalId = this.reportUtils.evalToGenCasePlan;

    // check if they have access to LS or YLS and add comparative report PROFILE_SECTIONS
    if (find(tools.items, { id: 124 })) {
      PROFILE_SECTIONS['lsComparativeReport'] = 3;
    }

    if (find(tools.items, { id: 120 })) {
      PROFILE_SECTIONS['ylsComparativeReports'] = 4;
    }

    const clientId = this.$location.search().id;
    if (!this.clients.items.length) await this.listClients();

    const client = this.clients.items.find((item) => item.id == clientId);

    if (!client) {
      this.notify.display('Client with that ID not detected', 'error');
      this.$store.commit('setIsLoading', false);
      return this.$state.go('dashboardHome');
    }

    this.client = client;
    if (this.isAdmin) {
      // GEARS Admin, auto detect custom fields for dynamic display
      let institution = this.findInstitution(this.client?.account?.id);
      if (!institution)
        institution = await this.getInstitution(this.client?.account?.id);
      if (institution?.clientConfig?.customFields)
        this.customClientFields = institution?.clientConfig?.customFields;
    }

    this.loadingClientEvaluations = true;
    const evaluations = await this.getClientEvls(this.client.id);
    await this.getClientOffenderHistory(this.client.id);

    this.$store.commit(
      'clients/SET_FOCUS',
      clientId ? clientId.toString() : null
    );

    // set OH Table at beginning
    if (this.hasOffenderHistoryTools) {
      this.ohTable = new this.ItemTable({
        title: 'Offender History',
        hasSearchBar: false,
        sortingBy: 'createdAt',
        addAction: async function () {
          const ohTools = filter(this.metaInformation.tools, {
            requiresOffenderHistory: true
          });
          const toolSelect =
            await this.metaInformation.$modals.util.generalChoice(
              ohTools,
              undefined,
              'SELECT TOOL'
            );
          if (!toolSelect) return;

          const toolCommits = await this.metaInformation.getItems.toolCommits(
            toolSelect
          );
          let toolCommitId = find(toolCommits, { status: 'Live' });
          if (!toolCommitId) toolCommitId = maxBy(toolCommits, 'createdAt');
          if (!toolCommitId) return;
          toolCommitId = toolCommitId.id;

          const toolData = await this.metaInformation.getItems.toolCommitFile({
            toolId: toolSelect.id,
            id: toolCommitId
          });
          if (!toolData) return;

          const offenseClassifications =
            await this.metaInformation.$util.getOffenseClassifications(
              this.metaInformation.client.institution.id
            );

          const newOffenderHistory =
            await this.metaInformation.$modals.evaluation.offenderHistory(
              this.metaInformation.client,
              toolData,
              offenseClassifications
            );
          if (!newOffenderHistory) return;

          this.item(newOffenderHistory);
          this.metaInformation.client.offenderHistory.push(newOffenderHistory);
        },
        editAction: async function (item) {
          const originalItem = angular.copy(item);
          originalItem.loading = true;
          if (item.item) item = item.item;
          item.offenderHistory =
            await this.metaInformation.getItems.offenderHistoryData(
              this.metaInformation.client,
              item
            );
          item.offenderHistory.id = item.id;
          const tool = {
            offenderHistory: item.offenderHistory,
            id: item.toolId,
            name: item.toolName
          };

          originalItem.loading = false;

          const offenseClassifications =
            await this.metaInformation.$util.getOffenseClassifications(
              this.metaInformation.client.institution.id
            );

          // update offense classifications if they exist in a table
          const searchSection = function (sec) {
            if (sec.sections?.length) {
              forEach(sec.sections, (sec2) => {
                searchSection(sec2);
              });
            }
            if (sec.key?.includes('Offenses') && sec.table) {
              const offenseCol = find(sec.table.cols, { key: 'offense' });
              if (offenseCol) offenseCol.options = offenseClassifications;
            }
          };
          forEach(tool.offenderHistory.sections, (sec) => {
            searchSection(sec);
          });

          const updatedOffenderHistory =
            await this.metaInformation.$modals.evaluation.offenderHistory(
              this.metaInformation.client,
              tool,
              offenseClassifications
            );
          if (!updatedOffenderHistory) return;

          const tableItem = find(this.items, (item) => {
            return item.id === updatedOffenderHistory.id;
          });
          if (tableItem) {
            tableItem.item.offenderHistory =
              updatedOffenderHistory.offenderHistory;
            tableItem.item.updatedAt = updatedOffenderHistory.updatedAt;
          }
          if (
            indexOf(this.metaInformation.client.offenderHistory, {
              id: item.id
            }) > -1
          ) {
            this.metaInformation.client.offenderHistory[
              indexOf(this.metaInformation.client.offenderHistory, {
                id: item.id
              })
            ] = tableItem.item;
          }
        },
        metaInformation: {
          tools: tools.items,
          client: this.client,
          getItems: this.getItems,
          $modals: this.$modals,
          http: this.$http,
          $util: this.$util
        }
      })
        .column({
          title: 'Tool',
          key: 'toolName',
          sortable: true
        })
        .column({
          title: 'Created',
          key: 'createdAt',
          type: 'dateTime',
          sortable: true
        })
        .column({
          title: 'Updated',
          key: 'updatedAt',
          type: 'dateTime',
          sortable: true
        });
    }

    // grab client's evaluations
    this.client.evaluations = [];
    // const evaluations = await this.getClientEvls(clientId);
    this.loadingClientEvaluations = false;

    if (Array.isArray(evaluations)) this.client.evaluations = evaluations;

    // get latest completed evaluations
    const latestCompletedEvaluations = chain(evaluations)
      .filter({ status: 'COMPLETED' })
      .orderBy('updatedAt', 'desc')
      .uniqBy('toolUsed')
      .map((e) => {
        return {
          toolName: e.tool.name,
          date: e.updatedAt,
          score: e.score,
          riskCategory: e.evaluationData.overrideRiskCategory
            ? e.evaluationData.overrideRiskCategory
            : e.evaluationData.riskCategory
        };
      })
      .value();

    forEach(latestCompletedEvaluations, (lce) => {
      if (!find(this.latestEvaluations, { toolName: lce.toolName }))
        this.latestEvaluations.push(lce);
    });

    this.parsingLatestEvaluations = false;
    // end latest completed evaluations

    // check for evaluation given to prompt a case plan to be generated
    if (this.generateCasePlanEvalId || this.$location.$$search.casePlans) {
      // given eval for generating profile report
      this.activeSection = PROFILE_SECTIONS.casePlans;
    } else {
      this.activeSection = PROFILE_SECTIONS.recentActivity;
    }

    this.ocTable = new ItemTable<OffenseClassificationTableItem>({
      title: 'Offense Classification',
      items: [
        {
          offence: 'Armed robbery (1)',
          doc: '02/02/14',
          disposition: '2 years',
          institution: 'Mountain C.C.',
          comments: 'Placed in maximum security'
        }
      ]
    })
      .column({ title: 'Offence', key: 'offence' })
      .column({ title: 'Date of Conviction', key: 'doc' })
      .column({ title: 'Disposition', key: 'disposition' })
      .column({ title: 'Institution', key: 'institution' })
      .column({ title: 'Comments', key: 'comments' });

    this.priorOffencesTable = new ItemTable<OffenseClassificationTableItem>({
      title: 'Offense Classification',
      items: [
        {
          offence: 'B&E (5)',
          doc: '11/10/02',
          disposition: '2 months (concurrent)',
          institution: 'Fielding Institution',
          comments: ''
        },
        {
          offence: 'Armed robbery (1)',
          doc: '02/02/14',
          disposition: '2 years',
          institution: 'Mountain C.C.',
          comments: 'Placed in maximum security'
        }
      ]
    })
      .column({ title: 'Offence', key: 'offence' })
      .column({ title: 'Date of Conviction', key: 'doc' })
      .column({ title: 'Disposition', key: 'disposition' })
      .column({ title: 'Institution', key: 'institution' })
      .column({ title: 'Comments', key: 'comments' });

    this.$timeout(() => {
      if (!this.$location.search()?.genCasePlanEvalId) {
        return;
      }

      this.$location.url(
        `${this.$location
          .url()
          .substring(
            0,
            this.$location.url().indexOf('&genCasePlanEvalId')
          )}&casePlans=true`
      );
    }, 4000);

    this.loading = false;
    this.$store.commit('setIsLoading', false);

    this.$scope.$watchCollection(
      () => this.client,
      this.onCustomPropertyChange.bind(this)
    );

    this.$scope.$watchCollection(
      () => this.clientConfig.customFields,
      this.onCustomPropertyChange.bind(this)
    );

    this.onCustomPropertyChange();

    // Old On Init
    this.load();
  }

  hasAuth(...val: unknown[]) {
    return this.$auth.hasAccess(...val);
  }

  async load() {
    // Errors if client does not have a subgroup...
    if (this.client?.subGroup && !this.client?.offenderHistory?.length) {
      try {
        this.client.offenderHistory = await this.getClientOffenderHistory(
          this.client.id
        );
      } catch (err) {
        this.notify.error(err);
      }
    }

    if (this.client.offenderHistory.length && this.hasOffenderHistoryTools) {
      this.client.offenderHistory = sortBy(this.client.offenderHistory, [
        'updatedAt'
      ]);

      this.client.offenderHistory.reverse();

      for (const oh of this.client.offenderHistory) {
        const tool = find(this.tools.items, { id: oh.toolId });

        if (!tool) {
          continue;
        }

        // oh.toolName = tool.name;
      }
    }

    this.initActivityTable();
    this.initOffenderHistoryTable();

    const instId = this.client.institutionId;
    const subGroupId = this.client.subGroup?.id;
    const clientId = this.client.id;
    this.mediaTableRoute = `api/client-manager/${instId}/subgroups/${subGroupId}/clients/${clientId}/media`;

    this.usStates = await this.getFormItems.usStates();
    this.canadaProvinces = await this.getFormItems.canadaProvinces();
    this.ukProvinces = await this.getFormItems.ukProvinces();
    this.auStates = await this.getFormItems.auStates();

    const contactData = await this.$api.clientManager.listClientContacts({
      instId: this.client?.institutionId,
      sbGrpId: this.client?.subGroup.id,
      clntId: this.client.id
    });

    if (contactData.status !== 200) {
      this.notify.display(contactData, 'error');
    } else {
      this.contacts = contactData.data;
      this.contacts.forEach((item) => {
        item.collapsed = true;

        if (
          item.country != 'US' &&
          item.country != 'CA' &&
          item.country != 'GB' &&
          item.country != 'AU' &&
          item.country != null
        ) {
          item.otherCountry = item.country;
          item.country = 'other';
        } else if (item.country === 'US') {
          item.stateProvinceOptions = this.usStates;
        } else if (item.country === 'CA') {
          item.stateProvinceOptions = this.canadaProvinces;
        } else if (item.country === 'GB') {
          item.stateProvinceOptions = this.ukProvinces;
        } else if (item.country === 'AU') {
          item.stateProvinceOptions = this.auStates;
        }

        this.copyContacts = angular.copy(this.contacts);
        this.loading = false;
      });
    }

    switch (this.client.country) {
      case 'US':
        this.stateProvinces = this.usStates;
        break;
      case 'CA':
        this.stateProvinces = this.canadaProvinces;
        break;
      case 'GB':
        this.stateProvinces = this.ukProvinces;
        break;
      case 'AU':
        this.stateProvinces = this.auStates;
        break;
      default:
        break;
    }
  }

  async initActivityTable() {
    this.$store.commit('reverseTableSort', true);
    this.recentActivityTable.props = [
      {
        label: 'Assigned Date',
        value: 'assignedDate',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Completed Date',
        value: (row: any) => {
          if (!row?.updatedAt || row.status !== 'COMPLETED') return 'N/A';
          return row?.updatedAt;
        },
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Tool',
        value: 'tool'
      },
      {
        label: 'Evaluator',
        value: 'evaluator.fullName'
      },
      {
        label: 'Status',
        // value: 'status'
        value: (row) => {
          let className, text;

          switch (row.status) {
            case 'NOT_STARTED':
              className = 'in-progress';
              text = 'Not Started';
              break;
            case 'IN_PROGRESS':
              className = 'in-progress';
              text = 'In Progress';
              break;
            case 'COMPLETED':
              className = 'completed';
              text = 'Completed';
              break;
          }

          return `
          <div class="status ${className}">
            <span>${text}</span>
          </div>
          `;
        }
      }
    ];

    this.recentActivityTable.actions = [
      {
        label: 'Actions',
        icon: 'cog',
        actions: [
          {
            label: 'View',
            icon: 'eye',
            fn: (val: any) => {
              this.$state.go('dashboardViewEvaluation', { evalId: val.id });
            },
            disabled: ({ status }) => status == 'NOT_STARTED'
          },
          {
            label: 'Generate Case Plan',
            icon: 'briefcase',
            fn: (val: any) => {
              this.activeSection = 1;
              this.$rootScope.$broadcast('generate-new-case-plan', val);
            },
            // disabled: ({ status }) => status != 'COMPLETED'
            disabled: (val: any) => {
              if (status != 'COMPLETED') return true;
              if (!val?.toolUsed || typeof val?.toolUsed !== 'number')
                return status != 'COMPLETED';

              const toolUsed = this.tools.items.find(
                (x) => x.id === val.toolUsed
              );

              return (
                toolUsed.managedCasePlanTemplates?.length ||
                toolUsed.institutionCasePlanTemplates?.length
              );
              // status != 'COMPLETED'
            }
          },
          {
            label: 'Generate Report',
            icon: 'clipboard',
            fn: (val) => {
              this.$store.commit(
                'clients/SET_FOCUS',
                this.client.id ? this.client.id.toString() : null
              );

              this.$store.commit('evaluations/setFocus', val.id);
              this.reportUtils.evlForReport = val;
              this.reportUtils.openReports(val);
            },
            disabled: ({ status }) => status != 'COMPLETED'
          },
          {
            label: 'Continue',
            icon: 'edit',
            fn: (val) => {
              this.evlUtils.continueEvaluation(val);
            },
            disabled: ({ status }) => status == 'COMPLETED'
          },
          {
            label: 'Reassessment',
            icon: 'redo',
            fn: (val) => {
              this.evlUtils.startReassessment({
                id: val.id,
                client: this.client
              });
            },
            disabled: (val) => {
              // Disable if...
              // • Institution reassessment option is “Not Allowed”, or...
              // • Evaluation is NOT Completed, or...
              // • Tool used for Evaluation does not all for reassessments

              // return (
              //   !this.institution.reassessmentAllowed ||
              //   !val.reassessmentAllowed ||
              //   val.status !== 'COMPLETED'
              // );

              return (
                this.me.institution?.reassessmentOption != 'NOT_ALLOWED' &&
                val.status !== 'COMPLETED'
              );
            }
          },
          {
            label: 'Manage Media',
            icon: 'file-alt',
            fn: (data) => {
              const client = this.clients.items.find(
                (item) => item.id == data.clientId
              );

              this.$modals.evaluation.manageMedia({
                institutionId: client?.institutionId,
                subGroupId: client?.subGroup?.id,
                clientId: client?.id,
                evaluationId: data.evaluationId ? data.evaluationId : data.id
              });
            }
          },
          {
            label: 'Delete',
            icon: 'trashAlt',
            fn: (val) =>
              this.dataModals.delete(
                val,
                'evaluation',
                'client-profile',
                this.recentActivityTable.items
              ),
            hide: () =>
              !this.$auth.hasAccess('clientmanager:DeleteClientEvaluation'),
            disabled: (val) => {
              return (
                find(PROHIBIT_EDIT, { id: val.toolUsed }) &&
                val.status === 'COMPLETED'
              );
            }
          }
        ]
      }
    ];

    this.recentActivityTable.items = this.client.evaluations.map((evl) => {
      return {
        date: evl.assignedDate,
        assignedDate: evl.assignedDate,
        updatedAt: evl.updatedAt,
        // date: moment(evl.updatedAt).format('MM/DD/YYYY hh:mm a'),
        tool: evl.tool ? evl.tool.name : '',
        status: evl.status,
        progress: 0,
        notes: '',
        // actions: evalActions[evl.status],
        id: evl.id,
        clientId: evl.clientId,
        evaluator: evl.evaluator,
        institutionId: evl.institutionId,
        toolUsed: evl.toolUsed
      };
    });
  }

  async initOffenderHistoryTable() {
    this.clientOhTable.props = [
      {
        label: 'Tool',
        value: 'toolName'
      },
      {
        label: 'Created',
        value: 'createdAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      },
      {
        label: 'Updated',
        value: 'updatedAt',
        filter: {
          type: 'date',
          format: 'MM/dd/yyyy hh:mm a'
        }
      }
    ];

    this.clientOhTable.actions = [
      {
        label: 'Edit',
        icon: 'edit',
        actions: async (val) => this.editOffenderHistory(val)
      }
    ];

    this.clientOhTable.items = this.client.offenderHistory;
  }

  async addOffenderHistory() {
    const ohTools = this.tools.items.filter((o) => o.requiresOffenderHistory);

    const toolSelect = await this.$modals.util.generalChoice(
      ohTools,
      undefined,
      'SELECT TOOL'
    );

    if (!toolSelect) return;

    this.$store.commit('SET_LOADING_MESSAGE', 'Retrieving Form');
    this.$store.commit('SET_IS_LOADING', true);

    const toolCommits = await this.getItems.toolCommits(toolSelect);

    let toolCommitId =
      find(toolCommits, { status: 'Live' }) || maxBy(toolCommits, 'createdAt');

    if (!toolCommitId) {
      this.$store.commit('SET_IS_LOADING', false);
      return;
    }

    toolCommitId = toolCommitId.id;

    const toolData = await this.getItems.toolCommitFile({
      toolId: toolSelect.id,
      id: toolCommitId
    });

    if (!toolData) {
      this.$store.commit('SET_IS_LOADING', false);
      return;
    }

    // check if institution has custom offender history instead of default
    const customOffenderHistoryTemplates =
      this.getInstitutionCustomOffenderHistoryTemplates(
        this.client.institution.id
      );

    if (
      customOffenderHistoryTemplates?.length &&
      find(
        customOffenderHistoryTemplates,
        (coht) => coht.tool?.id === toolData.id
      )
    ) {
      const customOffenderHistoryTemplate = find(
        customOffenderHistoryTemplates,
        (coht) => coht.tool?.id === toolData.id
      );

      const template = await this.getInstitutionCustomOffenderHistoryTemplate(
        this.client.institution.id,
        customOffenderHistoryTemplate.id
      );

      toolData.offenderHistory = template.templateData;
    }

    const offenseClassifications = await this.$util.getOffenseClassifications(
      this.client.institution.id
    );

    this.$store.commit('SET_IS_LOADING', false);
    const newOffenderHistory = await this.$modals.evaluation.offenderHistory(
      this.client,
      toolData,
      offenseClassifications
    );

    if (!newOffenderHistory) {
      return;
    }

    this.client.offenderHistory.push(newOffenderHistory);
    this.clientOhTable.items = this.client.offenderHistory;
  }

  async editOffenderHistory(oh: unknown) {
    const original = { ...oh };
    original.loading = true;

    if (oh.item) {
      oh = oh.item;
    }

    oh.offenderHistory = await this.getItems.offenderHistoryData(
      this.client,
      oh
    );

    oh.offenderHistory.id = oh.id;

    const tool = {
      offenderHistory: oh.offenderHistory,
      id: oh.toolId,
      name: oh.toolName
    };

    original.loading = false;

    const offenseClassifications = await this.$util.getOffenseClassifications(
      this.client.institution.id
    );

    // update offense classifications if they exist in a table
    const searchSection = (sec) => {
      if (sec.sections?.length) {
        sec.sections.forEach(searchSection);
      }

      if (sec.key?.includes('Offenses') && sec.table) {
        const offenseCol = find(sec.table.cols, { key: 'offense' });

        if (offenseCol) {
          offenseCol.options = offenseClassifications;
        }
      }
    };

    tool.offenderHistory.sections.forEach(searchSection);

    const updatedOffenderHistory =
      await this.$modals.evaluation.offenderHistory(
        this.client,
        tool,
        offenseClassifications
      );

    if (!updatedOffenderHistory) {
      return;
    }

    const tableItem = this.clientOhTable?.items?.find(
      (item) => item.id === updatedOffenderHistory.i
    );

    if (tableItem) {
      tableItem.item.offenderHistory = updatedOffenderHistory.offenderHistory;
      tableItem.item.updatedAt = updatedOffenderHistory.updatedAt;
    }

    const i = indexOf(this.client.offenderHistory, { id: oh.id });
    if (i > -1) {
      this.client.offenderHistory[i] = tableItem.item;
    }
  }

  async editClient() {
    // let updatedClient = await this.dsc.openEditClient(client);
    // let updatedClient = await this.$modals.edit.client(
    //   this.me.id,
    //   this.client,
    //   this.$store.state.institutions.items,
    //   this.me.institution
    // );
    let updatedClient = await this.$modals.edit.client(this.client);

    if (Array.isArray(updatedClient)) {
      // find the updated client in the array
      updatedClient = find(updatedClient, { id: this.client.id });
    }

    let clientWasUpdated = false;
    forEach(this.client, (value, key) => {
      if (this.client[key] !== updatedClient[key]) {
        this.client[key] = updatedClient[key];
        clientWasUpdated = true;
      }
    });
    if (clientWasUpdated) this.$onInit();
  }

  async createComparativeReport() {
    // select tool
    // YLS/CMI and LS/CMI
    const comparativeReportTools = [120, 124];
    const crTools = filter(this.tools.items, (tool) => {
      return indexOf(comparativeReportTools, tool.id) >= 0;
    });
    const toolSelect = await this.$modals.util.generalChoice(
      crTools,
      undefined,
      'SELECT TOOL'
    );
    if (!toolSelect) {
      this.notify.display('Must Select a Tool', 'warning');
      return;
    }
    // select evaluations
    const filters = {
      status: 'COMPLETED',
      toolUsed: toolSelect.id
    };
    const selectedEvaluations = await this.$modals.util.selectEvaluation(
      this.client.evaluations,
      filters,
      true
    );
    if (!selectedEvaluations || selectedEvaluations.length < 2) {
      this.notify.display('Must Select at Least Two Evaluations', 'warning');
      return;
    }
    // select options to include in report
    let options;
    if (toolSelect.id === 120) {
      options = await this.$modals.evaluation.ylscmiComparativeReportChoices(
        selectedEvaluations
      );
    } else if (toolSelect.id === 124) {
      options = await this.$modals.evaluation.lscmiComparativeReportChoices(
        selectedEvaluations
      );
    }

    if (!options) {
      this.notify.display('Must Select Options', 'warning');
      return;
    }
    this.$state.go('comparativeReports', {
      evaluations: selectedEvaluations,
      tool: toolSelect,
      options
    });
  }

  async createComparativeReportOverview(toolId: string, refresh: boolean) {
    if (toolId === 120) {
      this.noYLSCMIEvals = false;
      if (!this.ylsComparativeReport || refresh) {
        this.ylsComparativeReport = [];
      } else {
        return;
      }
    }
    if (toolId === 124) {
      this.noLSCMIEvals = false;
      if (!this.lsComparativeReport || refresh) {
        this.lsComparativeReport = [];
      } else {
        return;
      }
    }
    this.creatingComparativeReport = true;
    let tool = find(this.tools.items, { id: toolId });
    // grab the toolData if needbe
    if (!tool.hasOwnProperty('codingFormItems')) {
      // tool needs to get it's data
      tool = await this.getToolData({
        toolId: tool.id,
        commitId: tool.publishedCommitId
      });
    }
    // grab latest up to 4 evaluations with the toolId

    // let evaluations = filter(this.client.evaluations, {
    //   toolUsed: typeof toolId === 'number' ? toolId.toString() : toolId,
    //   status: 'COMPLETED'
    // });

    let evaluations = filter(
      this.client.evaluations,
      (e) =>
        e.status === 'COMPLETED' && e.toolUsed?.toString() === toolId.toString()
    );

    if (!evaluations.length) {
      this.notify.display(
        'No Evaluations Completed for Selected Comparative Report',
        'warning'
      );
      if (toolId === 124) this.noLSCMIEvals = true;
      if (toolId === 120) this.noYLSCMIEvals = true;
      this.creatingComparativeReport = false;
      return;
    }
    evaluations = sortBy(evaluations, (e) => new Date(e.updatedAt));
    evaluations = evaluations.reverse();
    if (evaluations.length > 4) evaluations.length = 4;
    // get evaluationData
    const clntId = this.client.id;
    const sbGrpId = this.client.subGroup.id;
    const instId = this.client.institutionId;
    for (let i = 0; i < evaluations.length; i++) {
      await this.getEvaluation({
        instId,
        sbGrpId,
        clntId,
        evalId: evaluations[i].id
      });
      evaluations[i] = await this.getEvaluationById(evaluations[i].id);
    }
    // sort evaluations by interview date
    evaluations = sortBy(evaluations, (e) => new Date(e.updatedAt));
    // generate comparative report from $generatePdf
    let options;
    let report;
    if (tool.id === 124) {
      //LS/CMI
      options = [
        {
          text: 'Introduction',
          selected: true
        },
        {
          text: 'LS/CMI General Risk/Need Total Score',
          selected: true
        },
        {
          text: 'General Risk/Need Assessment Based on Total Score',
          selected: true
        },
        {
          text: 'General Risk/Need Subcomponent Scores',
          selected: true
        },
        {
          text: 'General Risk/Need Subcomponent Areas of Strength',
          selected: true
        },
        {
          text: 'Item Response Table',
          selected: true
        },
        {
          text: 'Additional Item Information',
          selected: true
        },
        {
          text: 'Summary of Omitted Responses',
          selected: true
        },
        {
          text: 'Section 2: Specific Risk/Need Factors',
          selected: true,
          includeEvals: []
        },
        {
          text: 'Section 3: Prison Experience - Institutional Factors',
          selected: true
        },
        {
          text: 'Section 4: Other Client Issues (Social, Health, and Mental Health)',
          selected: true,
          includeEvals: []
        },
        {
          text: 'Section 5: Special Responsivity Considerations',
          selected: true,
          includeEvals: []
        },
        {
          text: 'Section 6: Risk/Need Summary Override',
          selected: true,
          includeEvals: []
        },
        {
          text: 'Section 7: Program/Placement Decision',
          selected: true,
          includeEvals: []
        }
      ];
      forEach(options, (opt) => {
        if (opt.hasOwnProperty('includeEvals')) {
          forEach(evaluations, (e) => {
            opt.includeEvals.push(e.id);
          });
        }
      });
      report = await this.$generatePdf.generateLSCMIComparativeReport(
        this.client,
        tool,
        evaluations,
        options
      );
      // console.log('report: ', report);
      this.lsComparativeReport = {
        report,
        tool,
        evaluations,
        client: this.client
      };
    } else if (tool.id === 120) {
      //YLS/CMI
      options = [
        {
          text: 'Introduction',
          selected: true
        },
        {
          text: 'Overall Assessment Based on YLS/CMI 2.0 Total Risk/Need Level',
          selected: true
        },
        {
          text: 'YLS/CMI 2.0 Subscale Scores',
          selected: true
        },
        {
          text: 'Profile Comparison',
          selected: true
        },
        {
          text: 'Item Response Table',
          selected: true
        }
      ];
      report = await this.$generatePdf.generateYLSComparativeReport(
        this.client,
        tool,
        evaluations,
        options
      );
      // console.log('report: ', report);
      this.ylsComparativeReport = {
        report,
        tool,
        evaluations,
        client: this.client
      };
    }
    this.$timeout(() => {
      this.creatingComparativeReport = false;
      this.$scope.$apply();
    }, 3000);
  }

  async goToFullComparativeReport(report: unknown) {
    this.$state.go('comparativeReports', {
      evaluations: report.evaluations,
      tool: report.tool,
      options: report.options,
      report: report.report
    });
  }

  async createExpungeRequest() {
    const res = await this.$api.IM.createExpungeClientRequest({
      instId: this.client.institution?.id,
      clientId: this.client.id
    });
    if (res.status !== 200) {
      this.notify.display(res, 'error');
      return;
    }
    this.expungeId = res.data.id;
    const expungeResponse = await this.$modals.settings.expungeClient(
      this.expungeId,
      this.client
    );
    if (!expungeResponse.error && expungeResponse.client) {
      this.removeClient(this.client.id);
      this.$state.go('dashboardClients');
    }
  }

  profileSectionExists(sec: unknown) {
    return PROFILE_SECTIONS[sec];
  }

  get retrievingTool() {
    return this.evlUtils.retrievingTool;
  }

  get modalConfig() {
    return this._scope.$parent.modal;
  }

  get clientDOB() {
    if (!this.client) {
      return '';
    }

    // return new Date(this.client.dob).toLocaleDateString('en-US');
    return this.$filter('dynamicDate')(this.client.dob);
  }

  get clientAge() {
    if (!this.client) {
      return '';
    }

    // return Math.floor(new Date(this.client.dob).getTime() * 3.1689E-11);
    const ageDate = new Date(Date.now() - new Date(this.client.dob).getTime()); //
    return Math.floor(ageDate.getUTCFullYear() - 1970);
  }
}

export default DashboardClientView;
