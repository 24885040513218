'use strict';
import * as _ from 'lodash';

const angular = require('angular');

/*@ngInject*/
export function toolManagementService(
  Block,
  $modals,
  generateReportTemplate,
  ToolEditorConnectors,
  notify,
  $state,
  $reincode
) {
  this.updateConnection = function (
    currentLayer,
    outputNode,
    inputNode,
    deletion,
    blocks
  ) {
    let iNFound = false;
    let oNFound = false;
    let checkObject = function (object) {
      if (object.inputNodeId === inputNode) {
        deletion
          ? _.pull(object.inputIds, outputNode)
          : object.inputIds.push(outputNode);
        iNFound = true;
      }
      if (object.outputNodeId === outputNode) {
        deletion
          ? _.pull(object.outputIds, inputNode)
          : object.outputIds.push(inputNode);
        oNFound = true;
      }
    };
    let i;
    let j;
    let k;
    if (blocks && blocks.length) {
      _.forEach(blocks, (block) => {
        _.forEach(block.rows, (row) => {
          checkObject(row);
        });
      });
    }
  };

  this.addBlock = function (parent, type, x, y, zIndex) {
    let block;
    let data = {
      x,
      y,
      zIndex
    };
    let inputNode;
    let outputNode;
    switch (type) {
      //region Tool
      case 'coding-form-item':
        if (!parent.codingFormItems) {
          throw new Error('Cannot add coding form item to non-tool object.');
        }
        data.riskFactor = '';
        block = new Block.CodingFormItem(data);
        parent.codingFormItems.push(block);
        return block;
      case 'question':
        return block;
      case 'child-tool':
        console.log('parent type is', parent.type);
        if (!parent.childTools && !parent.type !== 'tool') {
          throw new Error('Cannot add childTools to a non-tool object.');
        } else if (!parent.childTools) {
          parent.childTools = [];
        }
        block = new Block.ChildTool(data);
        parent.childTools.push(block);
        return block;
      case 'offender-history':
        block = new Block.OffenderHistory(data);
        parent.offenderHistory = block;
        return block;
      case 'codes-and-scores':
        if (!parent.codesAndScore) {
          throw new Error(
            'Cannot add codes and score to a non-coding form or meta tool item object.'
          );
        }
        if (parent.type === 'meta-tool' && parent.codesAndScore.length) {
          throw new Error(
            'Cannot add more than one codes and score to a meta tool.'
          );
        }
        block = new Block.CodesAndScore(data);
        parent.codesAndScore.push(block);
        return block;
      case 'wizard-question':
        if (!parent.codesAndScore) {
          throw new Error(
            'Cannot add codes and score to a non-coding form item object.'
          );
        }
        if (!parent.itemWizard) {
          //check that it has an item wizard first
          throw new Error(
            'Cannot add wizard question without an item wizard initiated.'
          );
        }
        block = new Block.WizardQuestion(data);
        parent.itemWizard.wizardQuestions.push(block);
        return block;
      case 'multiple-answer-conditions':
        if (!parent.codesAndScore) {
          throw new Error(
            'Cannot add codes and score to a non-coding form item object.'
          );
        }
        block = new Block.MultipleAnswerConditions(data);
        parent.itemWizard.multipleAnswerConditions.push(block);
        return block;
      case 'manipulate-variables':
        if (!parent.codesAndScore) {
          throw new Error(
            'Cannot add codes and score to a non-coding form item object.'
          );
        }
        block = new Block.ManipulateVariables(data);
        parent.itemWizard.manipulateVariables.push(block);
        return block;
      case 'risk-category':
        if (!parent.codingFormItems && !parent.type === 'meta-tool') {
          throw new Error('Cannot add coding form item to non-tool object.');
        }
        block = new Block.RiskCategory(data);
        parent.riskCategories.push(block);
        return block;
      case 'custom-risk-category':
        if (!parent.codingFormItems && !parent.type === 'meta-tool') {
          throw new Error('Cannot add coding form item to non-tool object.');
        }
        block = new Block.CustomRiskCategory(data);
        if (!parent.hasOwnProperty('customRiskCategories'))
          parent.customRiskCategories = [];
        parent.customRiskCategories.push(block);
        return block;
      case 'sub-risk-category':
        if (!parent.codingFormItems && !parent.type === 'meta-tool') {
          throw new Error(
            'Cannot add sub risk category item to non-tool object.'
          );
        }
        block = new Block.SubRiskCategory(data);
        if (!parent.hasOwnProperty('subRiskCategory'))
          parent.subRiskCategories = [];
        parent.subRiskCategories.push(block);
        return block;
      case 'rule-omit':
        if (!parent.rules) parent.rules = [];
        block = new Block.RuleOmit(data);
        parent.rules.push(block);
        return block;
      case 'rule-minimum-answers':
        if (!parent.rules) parent.rules = [];
        block = new Block.RuleMinimumAnswers(data);
        parent.rules.push(block);
        return block;
      case 'item-wizard':
        if (!parent.codesAndScore) {
          throw new Error(
            'Cannot add codes and score to a non-coding form item object.'
          );
        }
        if (parent.itemWizard) {
          throw new Error('Item wizard already exists!');
        }
        block = new Block.ItemWizard(data);
        parent.itemWizard = block;
        return block;
      case 'wizard-references':
        if (!parent.codesAndScore) {
          throw new Error(
            'Cannot add codes and score to a non-coding form item object.'
          );
        }
        block = new Block.WizardReferences(data);
        parent.itemWizard.references.push(block);
        return block;
      case 'wizard-variables':
        if (!parent.codesAndScore) {
          throw new Error(
            'Cannot add codes and score to a non-coding form item object.'
          );
        }
        block = new Block.WizardVariables(data);
        parent.itemWizard.variables.push(block);
        return block;
      case 'wizard-conditions':
        if (!parent.codesAndScore) {
          throw new Error(
            'Cannot add codes and score to a non-coding form item object.'
          );
        }
        block = new Block.WizardConditions(data);
        parent.itemWizard.conditions.push(block);
        return block;
      case 'wizard-resolutions':
        if (!parent.codesAndScore) {
          throw new Error(
            'Cannot add codes and score to a non-coding form item object.'
          );
        }
        block = new Block.WizardResolutions(data);
        parent.itemWizard.resolutions.push(block);
        return block;
      case 'dictionary':
        block = new Block.Dictionary(data);
        parent.dictionary = block;
        return block;
      case 'prorate-tool':
        block = new Block.ProrateTool(data);

        block.variables.push(
          new Block.Variable({ variableName: 'scoreOfAnswered', input: false })
        );
        block.variables.push(
          new Block.Variable({
            variableName: 'highestScoreOfAnswered',
            input: false,
            y: 350
          })
        );
        block.variables.push(
          new Block.Variable({
            variableName: 'highestScoreOfUnanswered',
            input: false,
            y: 450
          })
        );
        block.variables.push(
          new Block.Variable({
            variableName: 'lowestScoreOfAnswered',
            input: false,
            y: 150
          })
        );
        block.variables.push(
          new Block.Variable({
            variableName: 'lowestScoreOfUnanswered',
            input: false,
            y: 250
          })
        );

        block.outcomes.push(
          new Block.Variable({
            type: 'prorate-outcome',
            variableName: 'finalScore',
            output: false,
            x: 900,
            y: 550
          })
        );
        block.startValue = new Block.Variable({
          type: 'start-value',
          variableName: 'Start Value',
          input: true,
          output: true,
          y: 550
        });

        console.debug('new prorate-tool', block);

        parent.prorate = block;
        return block;
      case 'single-operator':
        block = new Block.SingleOperator(data);
        if (!parent.hasOwnProperty('operations')) {
          parent.operations = [];
        }
        parent.operations.push(block);
        return block;
      case 'binary-operator':
        block = new Block.BinaryOperator(data);
        console.log('parent', parent);
        if (!parent.hasOwnProperty('operations')) {
          parent.operations = [];
        }
        parent.operations.push(block);
        return block;
      case 'operator':
        block = new Block.Operator(data);
        if (!parent.hasOwnProperty('operations')) {
          parent.operations = [];
        }
        parent.operations.push(block);
        return block;
      case 'rules':
        block = new Block.Rules(data);
        parent.rules.push(block);
        return block;
      case 'numeric-comparator':
        block = new Block.NumericComparator(data);
        console.log('numeric-comparator', block);
        parent.comparators.push(block);
        return block;
      //endregion Tool
      //region Meta Tool
      case 'meta-tool-comparator':
        block = new Block.MetaToolComparator(data);
        parent.operations.push(block);
        return block;
      //endregion Meta Tool
      //region Report Template
      case 'overview-option':
        block = new Block.OverviewOption(data);
        parent.overview.options.push(block);
        console.log(parent);
        return block;
      case 'item-descriptions':
        let res = null;
        generateReportTemplate.generateItemDescriptions(
          parent.itemDescriptions,
          function (response, err) {
            if (!err) {
              parent.itemDescriptions = response.itemDescriptions;
              res = response;
            } else {
              console.log('Error in generating item descriptions');
              // TODO (Alex) figure out how to handle error
            }
          }
        );
        return res;
      case 'additional-criteria':
        block = new Block.AdditionalCriteria(data);
        if (!parent.additionalCriteria) {
          parent.additionalCriteria = [];
        }
        parent.additionalCriteria.push(block);
        return block;
      case 'additional-criteria-option':
        console.log('parent', parent);
        block = new Block.AdditionalCriteriaOption(data);
        inputNode = block.rows[0].inputNodeId;
        outputNode;
        if (
          parent.type === 'additional-criteria' ||
          parent.type === 'additional-criteria-option' ||
          parent.type === 'report-template-choice'
        ) {
          if (!parent.options) {
            parent.options = [];
          }
          parent.options.push(block);
          _.forEach(parent.rows, function (row) {
            if (
              _.some(row.fields, {
                label: 'Additional Criteria Options'
              })
            )
              outputNode = row.outputNodeId;
          });
        } else {
          if (!parent.additionalCriteriaOptions) {
            parent.additionalCriteriaOptions = [];
          }
          parent.additionalCriteriaOptions.push(block);
        }
        if (inputNode && outputNode) {
          ToolEditorConnectors.addConnector(inputNode, outputNode);
        } else {
          notify.warning('Cannot find appropriate connection');
        }
        return block;
      case 'report-template-choice':
        block = new Block.ReportTemplateChoice(data);
        inputNode = block.rows[0].inputNodeId;
        if (!parent.choices) {
          parent.choices = [];
        }
        _.forEach(parent.rows, function (row) {
          if (
            _.some(row.fields, {
              label: 'Choices'
            })
          )
            outputNode = row.outputNodeId;
        });
        parent.choices.push(block);
        if (inputNode && outputNode) {
          ToolEditorConnectors.addConnector(inputNode, outputNode);
        } else {
          notify.warning('Cannot find appropriate connection');
        }
        return block;
      //endregion Report Template
    }
  };

  this.sectionToJson = function (section, data) {
    let response = section.toJson(data);
    console.log(response);
  };

  // converts editor report template to json for upload
  this.reportTemplateToJson = function (template, connectors, prevCtrl) {
    //region Tree System
    //https://code.tutsplus.com/articles/data-structures-with-javascript-tree--cms-23393
    function Queue() {
      this._oldestIndex = 1;
      this._newestIndex = 1;
      this._storage = {};
    }

    Queue.prototype.size = function () {
      return this._newestIndex - this._oldestIndex;
    };

    Queue.prototype.enqueue = function (data) {
      this._storage[this._newestIndex] = data;
      this._newestIndex++;
    };

    Queue.prototype.dequeue = function () {
      let oldestIndex = this._oldestIndex;
      let newestIndex = this._newestIndex;
      let deletedData;
      if (oldestIndex !== newestIndex) {
        deletedData = this._storage[oldestIndex];
        delete this._storage[oldestIndex];
        this._oldestIndex++;
        return deletedData;
      }
    };

    function Node(data) {
      this.data = data;
      this.parent = null;
      this.children = [];
    }

    function Tree(data) {
      let node = new Node(data);
      this._root = node;
    }

    Tree.prototype.traverseDF = function (callback) {
      (function recurse(currentNode) {
        // step 2
        for (let i = 0; i < currentNode.children.length; i++) {
          // step 3
          recurse(currentNode.children[i]);
        }

        // step 4
        callback(currentNode);

        // step 1
      })(this._root);
    };

    Tree.prototype.traverseBF = function (callback) {
      var queue = new Queue();
      queue.enqueue(this._root);
      let currentTree = queue.dequeue();
      while (currentTree) {
        for (let i = 0; i < currentTree.children.length; i++) {
          queue.enqueue(currentTree.children[i]);
        }
        callback(currentTree);
        currentTree = queue.dequeue();
      }
    };

    Tree.prototype.contains = function (callback, traversal) {
      traversal.call(this, callback);
    };

    Tree.prototype.add = function (data, toData, traversal) {
      var child = new Node(data),
        parent = null,
        callback = function (node) {
          if (node.data === toData) {
            parent = node;
          }
        };

      this.contains(callback, traversal);

      if (parent) {
        parent.children.push(child);
        child.parent = parent;
      } else {
        throw new Error('Cannot add node to a non-existent parent.');
      }
    };
    //endregion Tree System

    let originalTemplate = angular.copy(template);
    let originalConnectors = angular.copy(connectors);
    template = _.cloneDeep(template);
    connectors = _.cloneDeep(connectors);
    //region Classes
    var ReportCriteria = class {
      constructor(overview, itemDescriptions, additionalCriteria, chartData) {
        this.overview = overview || {};
        this.itemDescriptions = itemDescriptions || null;
        this.additionalCriteria = additionalCriteria || [];
        this.chartData = chartData || [];
      }
    };

    var ReportTemplate = class {
      constructor(name, version, reportCriteria, id) {
        this.name = name || '';
        this.version = version || null;
        this.reportCriteria = reportCriteria || {};
        this.id = id || null;
      }
    };

    var GenericContent = class {
      constructor(type, text, image, x, y) {
        this.type = type;
        /*
        type:
        0 = text;
        1 = image;
         */
        if (!text) text = '';
        if (!image) image = '';
        this.text = text;
        this.image = image;
        this.x = x;
        this.y = y;
      }
    };

    //region Overview Section
    var Overview = class {
      constructor(title, hasToggle, toggleDefault, options) {
        this.title = title || '';
        this.hasToggle = hasToggle;
        this.toggleDefault = toggleDefault;
        this.options = options || [];
      }
    };

    var OverviewOption = class {
      constructor(hasToggle, isDefault, text, content, options) {
        this.hasToggle = hasToggle;
        this.isDefault = isDefault;
        this.text = text || '';
        this.content = content || []; // GenericContent type
        this.options = options || [];
      }
    };
    //endregion Overview Section

    //region Item Descriptions Section
    var ItemDescriptions = class {
      constructor(
        title,
        hasToggle,
        toggleDefault,
        options,
        descriptions,
        sectionType
      ) {
        this.title = title || '';
        this.hasToggle = hasToggle;
        this.toggleDefault = toggleDefault;
        this.options = options || [];
        this.descriptions = descriptions || [];
        this.sectionType = sectionType;
      }
    };

    var ItemDescription = class {
      constructor(
        codingFormItemId,
        codingFormItemRiskFactor,
        codingFormItemDescription,
        includeAllAnswers,
        concatenate,
        answerDescriptions
      ) {
        this.codingFormItemId = codingFormItemId;
        this.codingFormItemRiskFactor = codingFormItemRiskFactor;
        this.codingFormItemDescription = codingFormItemDescription
          ? codingFormItemDescription
          : '';
        this.includeAllAnswers = includeAllAnswers ? includeAllAnswers : false;
        this.concatenate = concatenate ? concatenate : false;
        this.answerDescriptions = answerDescriptions;
      }
    };

    var AnswerDescription = class {
      constructor(id, answers, questionText) {
        this.id = id;
        this.answers = answers || [];
        this.questionText = questionText || null;
      }
    };

    var BaseAnswer = class {
      constructor(
        id,
        disclaimer,
        answerText,
        description,
        wizardOverride,
        wizardDescriptionOverride
      ) {
        this.id = id;
        this.disclaimer = disclaimer ? disclaimer : false;
        this.answerText = answerText;
        this.description = description ? description : '';
        this.wizardOverride = wizardOverride ? wizardOverride : false;
        this.wizardDescriptionOverride = wizardDescriptionOverride
          ? wizardDescriptionOverride
          : false;
      }
    };

    var WizardAnswer = class {
      constructor(id, disclaimer, answerText, description) {
        this.id = id;
        this.disclaimer = disclaimer ? disclaimer : false;
        this.answerText = answerText;
        this.description = description ? description : '';
      }
    };
    //endregion Item Descriptions Section

    //region Additional Criteria Section
    var AdditionalCriteria = class {
      constructor(title, hasToggle, toggleDefault, sectionType, options) {
        this.title = title;
        this.hasToggle = hasToggle;
        this.toggleDefault = toggleDefault;
        this.sectionType = sectionType;
        this.options = options || []; // Additional Criteria Option
      }
    };

    var AdditionalCriteriaOption = class {
      constructor(
        text,
        hasToggle,
        isDefault,
        sectionType,
        scoreCondition,
        scoreRange,
        content,
        options,
        choices
      ) {
        this.text = text;
        this.hasToggle = hasToggle;
        this.isDefault = isDefault;
        this.sectionType = sectionType;
        this.scoreCondition = scoreCondition;
        this.scoreRange = scoreRange;
        this.content = content; // Generic Content
        this.options = options; // Additional Criteria Option
        this.choices = choices;
      }
    };

    var ReportTemplateChoice = class {
      constructor(text, basedOnScore, options) {
        this.text = text;
        this.basedOnScore = basedOnScore;
        this.options = options || [];
      }
    };
    //endregion Additional Criteria Section
    //endregion Classes

    // build item descriptions
    let itemDescription;
    if (template.itemDescriptions) {
      var descriptions = [];
      _.forEach(template.itemDescriptions.descriptions, function (description) {
        var answerDescriptions = [];
        _.forEach(description.answerDescriptions, function (answerDescription) {
          var answers = [];
          _.forEach(answerDescription.rows, function (answerRow) {
            if (_.some(answerRow.fields, { label: 'Answer Descriptions' })) {
              return;
            }
            if (_.some(answerRow.fields, { label: 'Wizard Override' })) {
              answers.push(
                new BaseAnswer(
                  _.find(answerRow.fields, { label: 'Answer ID' }).text,
                  _.find(answerRow.fields, { label: 'Disclaimer' }).model,
                  _.find(answerRow.fields, { label: 'Answer Text' }).text,
                  _.find(answerRow.fields, { label: 'Description' }).model,
                  _.find(answerRow.fields, { label: 'Wizard Override' }).model,
                  _.find(answerRow.fields, {
                    label: 'Wizard Description Override'
                  }).model
                )
              );
            } else {
              answers.push(
                new WizardAnswer(
                  _.find(answerRow.fields, { label: 'Answer ID' }).text,
                  _.find(answerRow.fields, { label: 'Disclaimer' }).model,
                  _.find(answerRow.fields, { label: 'Answer Text' }).text,
                  _.find(answerRow.fields, { label: 'Description' }).model
                )
              );
            }
          });
          answerDescriptions.push(
            new AnswerDescription(
              answerDescription.id,
              answers,
              answerDescription.title
            )
          );
        });
        let codingFormItemDescription;
        let includeAllAnswers;
        let concatenate;
        _.find(description.rows, function (row) {
          if (_.some(row.fields, { label: 'Coding Form Item Description' })) {
            codingFormItemDescription = _.find(row.fields, {
              label: 'Coding Form Item Description'
            }).model;
          } else if (_.some(row.fields, { label: 'Include All Answers' })) {
            // console.log(row);
            includeAllAnswers = _.find(row.fields, {
              label: 'Include All Answers'
            }).model;
            concatenate = _.find(row.fields, { label: 'Concatenate' }).model;
          }
        });
        descriptions.push(
          new ItemDescription(
            description.codingFormItemId,
            description.codingFormItemRiskFactor,
            codingFormItemDescription,
            includeAllAnswers,
            concatenate,
            answerDescriptions
          )
        );
      });
      let title;
      let hasToggle;
      let toggleDefault;
      let options = [];
      let sectionType = 0;
      _.forEach(template.itemDescriptions.rows, function (row) {
        if (_.some(row.fields, { label: 'Title' })) {
          title = _.find(row.fields, { label: 'Title' }).model;
        } else if (_.some(row.fields, { label: 'Has Toggle' })) {
          hasToggle = _.find(row.fields, { label: 'Has Toggle' }).model;
          toggleDefault = _.find(row.fields, { label: 'Toggle Default' }).model;
        }
      });

      itemDescription = new ItemDescriptions(
        title,
        hasToggle,
        toggleDefault,
        options,
        descriptions,
        sectionType
      );
    }

    // build additional criteria
    var additionalCriteriaSubmission = [];

    let createContent = function (row) {
      let type = _.find(row.fields, { label: 'Content Type' }).model
        ? _.find(row.fields, { label: 'Content Type' }).model.text
        : 0;
      let text = _.find(row.fields, { label: 'Text' }).model;
      let image = _.find(row.fields, { label: 'Image' }).model;
      let x = _.find(row.fields, { label: 'Image Width (max 600)' }).model;
      let y = _.find(row.fields, { label: 'Image Height' }).model;
      return new GenericContent(type, text, image, x, y);
    };

    let createChoice = function (choice) {
      let text;
      let basedOnScore;
      console.log('choice', choice);
      _.forEach(choice.rows, function (row) {
        console.log('row', row);
        if (_.some(row.fields, { label: 'Text' })) {
          text = _.find(row.fields, { label: 'Text' }).model;
        } else if (_.some(row.fields, { label: 'Based On Score' })) {
          basedOnScore = _.find(row.fields, { label: 'Based On Score' }).model
            ? _.find(row.fields, { label: 'Based On Score' }).model.text
            : null;
        }
      });
      return new ReportTemplateChoice(text, basedOnScore);
    };

    let createAC = function (ac) {
      let title;
      let hasToggle;
      let toggleDefault;
      let sectionType;
      let outputId; // ID used to find additional criteria options that belong to it

      _.forEach(ac.rows, function (row) {
        if (_.some(row.fields, { label: 'Text' })) {
          title = _.find(row.fields, { label: 'Text' }).model;
        } else if (_.some(row.fields, { label: 'Has Toggle' })) {
          hasToggle = _.find(row.fields, { label: 'Has Toggle' }).model;
          toggleDefault = _.find(row.fields, { label: 'Toggle Default' }).model;
        } else if (_.some(row.fields, { label: 'Section Type' })) {
          sectionType = _.find(row.fields, { label: 'Section Type' }).model
            .text;
        } else if (
          _.some(row.fields, { label: 'Additional Criteria Options' })
        ) {
          outputId = row.outputNodeId;
        }
      });

      return new AdditionalCriteria(
        title,
        hasToggle,
        toggleDefault,
        sectionType,
        []
      );
    };

    let createACO = function (aco) {
      // (text, hasToggle, isDefault, sectionType, scoreCondition, scoreRange, content, options, choices)
      let text;
      let hasToggle;
      let isDefault;
      let sectionType;
      let scoreCondition;
      let scoreRange = {
        low: null,
        high: null
      };
      let content = [];
      let options = [];
      let choices = [];
      // create aco from rows given
      _.forEach(aco.rows, function (row) {
        console.log('aco.row', row);
        if (
          _.some(row.fields, { label: 'Text' }) &&
          !_.some(row.fields, { label: 'Image' })
        ) {
          text = _.find(row.fields, { label: 'Text' }).model;
        } else if (_.some(row.fields, { label: 'Is Default' })) {
          isDefault = _.find(row.fields, { label: 'Is Default' }).model;
          hasToggle = _.find(row.fields, { label: 'Has Toggle' }).model;
        } else if (_.some(row.fields, { label: 'Section Type' })) {
          sectionType = _.find(row.fields, { label: 'Section Type' }).model
            ? _.find(row.fields, { label: 'Section Type' }).model.text
            : null;
          scoreCondition = _.find(row.fields, {
            label: 'Score Condition'
          }).model;
          scoreRange.low = _.find(row.fields, {
            label: 'Score Range (Low)'
          }).model;
          scoreRange.high = _.find(row.fields, {
            label: 'Score Range (High)'
          }).model;
        } else if (
          _.some(row.fields, { label: 'Content Type' }) &&
          _.some(row.fields, { label: 'Image' })
        ) {
          content.push(createContent(row));
        }
      });

      return new AdditionalCriteriaOption(
        text,
        hasToggle,
        isDefault,
        sectionType,
        scoreCondition,
        scoreRange,
        content,
        options,
        choices
      );
    };

    _.forEach(template.additionalCriteria, function (additionalCriteria) {
      let func = (sourceObj) => {
        let newObj;
        if (sourceObj.type === 'additional-criteria') {
          newObj = createAC(sourceObj);
        } else if (sourceObj.type === 'additional-criteria-option') {
          newObj = createACO(sourceObj);
        } else if (sourceObj.type === 'report-template-choice') {
          newObj = createChoice(sourceObj);
        }

        if (sourceObj.hasOwnProperty('options') && sourceObj.options.length) {
          _.forEach(sourceObj.options, (item) => {
            newObj.options.push(func(item));
          });
        }

        if (sourceObj.hasOwnProperty('choices') && sourceObj.choices.length) {
          _.forEach(sourceObj.choices, (item) => {
            newObj.choices.push(func(item));
          });
        }

        return newObj;
      };

      let newAdditionalCriteria = func(additionalCriteria);

      additionalCriteriaSubmission.push(newAdditionalCriteria);
    });

    // build overview
    var overview = {};
    if (!template.overview) {
      console.error('No overview section found');
    } else {
      let title;
      let hasToggle;
      let toggleDefault;
      _.forEach(template.overview.rows, function (row) {
        if (_.find(row.fields, { label: 'Title' })) {
          title = _.find(row.fields, { label: 'Title' }).model;
        } else if (_.find(row.fields, { label: 'Has Toggle' })) {
          hasToggle = _.find(row.fields, { label: 'Has Toggle' }).model;
          toggleDefault = _.find(row.fields, { label: 'Toggle Default' }).model;
        }
      });
      overview = new Overview(title, hasToggle, toggleDefault, []);
      _.forEach(template.overview.options, function (overviewOption) {
        let hasToggle;
        let isDefault;
        let text;
        let content = [];
        let options = [];
        _.forEach(overviewOption.rows, function (row) {
          if (
            _.find(row.fields, { label: 'Text' }) &&
            _.find(row.fields, { label: 'Is Default' })
          ) {
            text = _.find(row.fields, { label: 'Text' }).model;
            isDefault = _.find(row.fields, { label: 'Is Default' }).model;
            hasToggle = _.find(row.fields, { label: 'Has Toggle' }).model;
          } else if (
            _.some(row.fields, { label: 'Content Type' }) &&
            _.some(row.fields, { label: 'Image' })
          ) {
            content.push(
              new GenericContent(
                _.find(row.fields, { label: 'Content Type' }).model.text,
                _.find(row.fields, { label: 'Text' }).model,
                _.find(row.fields, { label: 'Image' }).model
              )
            );
          }
        });
        overview.options.push(
          new OverviewOption(hasToggle, isDefault, text, content, options)
        );
      });
    }

    // build chart data
    var chartData = [];

    // build report template
    let reportCriteria = new ReportCriteria(
      overview,
      itemDescription,
      additionalCriteriaSubmission,
      chartData
    );
    let submission = new ReportTemplate(
      template.reportTemplateInformation.name,
      template.reportTemplateInformation.version,
      reportCriteria,
      template.reportTemplateInformation.id
    );
    originalTemplate.connectors = originalConnectors;
    console.log('TEMPLATE EDITOR FILE:');
    console.log(originalTemplate);
    console.log('===============================');
    console.log('SUBMISSION:');
    console.log(submission);
    $modals.tool.uploadReportTemplate(submission, originalTemplate)(() => {});
  };

  // converts report template commit file to report template editor file
  this.reportTemplateCommitToEditor = function (commitFile, callback) {
    let editorFile;
    console.log(commitFile);
    // TODO (Alex) finish this for taking an existing report template (like the Static) and creating an editor file
    callback(editorFile);
  };

  // converts editor tool to json for upload
  this.toolToJson = async function (tool, connectors, prevCtrl) {
    let submission = {};
    let i;
    // TODO (Alex) add all of the sub keys that could be in a tool for creating editor file
    if (tool.rootToolInformation) {
      // for tool and meta tool
      submission = tool.rootToolInformation.toJson();
    }
    if (tool.riskCategories) {
      // for tool and meta tool
      submission.riskCategories = [];
      for (i = 0; i < tool.riskCategories.length; i++) {
        submission.riskCategories.push(tool.riskCategories[i].toJson());
      }
    }
    if (tool.customRiskCategories) {
      submission.customRiskCategories = [];
      for (i = 0; i < tool.customRiskCategories.length; i++) {
        submission.customRiskCategories.push(
          tool.customRiskCategories[i].toJson()
        );
      }
    }
    if (tool.subRiskCategories) {
      submission.subRiskCategories = [];
      for (i = 0; i < tool.subRiskCategories.length; i++) {
        submission.subRiskCategories.push(tool.subRiskCategories[i].toJson());
      }
    }
    if (tool.rules) {
      submission.rules = [];
      for (i = 0; i < tool.rules.length; i++) {
        submission.rules.push(tool.rules[i].toJson());
      }
    }
    if (tool.childTools) {
      submission.childTools = [];
      for (i = 0; i < tool.childTools.length; i++) {
        submission.childTools.push(tool.childTools[i].toJson());
      }
    }
    if (tool.codingFormItems) {
      // for tool
      submission.codingFormItems = [];
      for (i = 0; i < tool.codingFormItems.length; i++) {
        submission.codingFormItems.push(tool.codingFormItems[i].toJson());
      }
    }
    if (tool.dictionary) {
      // for tool
      submission.dictionary = tool.dictionary.toJson();
    }
    if (tool.offenderHistory)
      submission.offenderHistory = tool.offenderHistory.toJson();
    if (tool.prorate) submission.prorate = tool.prorate.toJson();
    if (tool.codesAndScore) {
      // for meta tool
      submission.codesAndScore = tool.codesAndScore[0].toJson();
    }
    if (tool.operations) {
      // for meta tool
      submission.operations = [];
      for (i = 0; i < tool.operations.length; i++) {
        if (tool.operations[i].type === 'meta-tool-comparator') {
          submission.operations.push(
            tool.operations[i].toJson({
              tools: tool.tools,
              codesAndScore: tool.codesAndScore[0]
            })
          );
        }
      }
    }
    if (tool.tools) {
      // for meta tool
      submission.tools = [];
      for (i = 0; i < tool.tools.length; i++) {
        submission.tools.push(tool.tools[i].toJson());
      }
    }
    tool.connectors = connectors;
    tool = $reincode.fullObject(tool);
    console.log('TOOL EDITOR FILE:');
    console.log(tool);
    console.log('===============================');
    submission = $reincode.fullObject(submission);
    console.log('SUBMISSION:');
    console.log(submission);
    let uploadRes = await $modals.tool.uploadTool(submission, tool, false);
    // console.log('uploadRes: ', uploadRes);
    // $state.go('dashboardTools');
    // $modals.tool.uploadTool(submission, tool, false, () => {
    //   prevCtrl.workingOnTool = true;
    //   prevCtrl.saveTempTool();
    // });
  };

  // loads the tool field retrieved from local storage or tool editor from s3 folder
  this.loadToolJsonString = function (string, toolId, commFile, commitId) {
    let tool = angular.fromJson(string);
    // let comFile = JSON.parse(commFile);
    let i;
    if (tool.rootToolInformation) {
      if (!tool.rootToolInformation.id && toolId) {
        tool.rootToolInformation.id = toolId;
      }
      tool.rootToolInformation = new Block.RootToolInformation(
        tool.rootToolInformation
      );
    }
    if (tool.offenderHistory) {
      tool.offenderHistory = new Block.OffenderHistory(tool.offenderHistory);
    }
    if (tool.riskCategories) {
      let riskCategories = [];
      for (i = 0; i < tool.riskCategories.length; i++) {
        riskCategories.push(new Block.RiskCategory(tool.riskCategories[i]));
      }
      tool.riskCategories = riskCategories;
    }
    if (tool.customRiskCategories) {
      let customRiskCategories = [];
      for (i = 0; i < tool.customRiskCategories.length; i++) {
        customRiskCategories.push(
          new Block.CustomRiskCategory(tool.customRiskCategories[i])
        );
      }
      tool.customRiskCategories = customRiskCategories;
    }
    if (tool.subRiskCategories) {
      let subRiskCategories = [];
      for (i = 0; i < tool.subRiskCategories.length; i++) {
        subRiskCategories.push(
          new Block.SubRiskCategory(tool.subRiskCategories[i])
        );
      }
      tool.subRiskCategories = subRiskCategories;
    }
    if (tool.codingFormItems) {
      let codingFormItems = [];
      for (i = 0; i < tool.codingFormItems.length; i++) {
        codingFormItems.push(new Block.CodingFormItem(tool.codingFormItems[i]));
      }
    }
    if (tool.rules) {
      let rules = [];
      for (i = 0; i < tool.rules.length; i++) {
        if (tool.rules[i].ruleType === 0) {
          // max omit allowance rule type
          rules.push(new Block.RuleOmit(tool.rules[i]));
        } else if (tool.rules[i].ruleType === 2) {
          // minimum answer required rule
          rules.push(new Block.RuleMinimumAnswers(tool.rules[i]));
        }
      }
      tool.rules = rules;
    }
    if (tool.dictionary) {
      tool.dictionary = new Block.Dictionary(tool.dictionary);
    }
    if (tool.prorate) {
      tool.prorate = new Block.ProrateTool(tool.prorate);
    }
    if (tool.childTools && tool.childTools.length) {
      let childTools = [];
      for (i = 0; i < tool.childTools.length; i++) {
        childTools.push(new Block.ChildTool(tool.childTools[i]));
      }
      tool.childTools = childTools;
    }
    if (tool.codingFormItems) {
      let codingFormItems = [];
      let itemWizard = {};
      let newBlock = {};
      for (i = 0; i < tool.codingFormItems.length; i++) {
        if (tool.codingFormItems[i].itemWizard) {
          // itemWizard = new Block.ItemWizard(tool.codingFormItems[i].itemWizard);
          newBlock = new Block.CodingFormItem(tool.codingFormItems[i]);
          // newBlock.itemWizard = itemWizard;
          codingFormItems.push(newBlock);
        } else {
          codingFormItems.push(
            new Block.CodingFormItem(tool.codingFormItems[i])
          );
        }
      }
      tool.codingFormItems = codingFormItems;
    }
    if (tool.tools) {
      let tools = [];
      for (i = 0; i < tool.tools.length; i++) {
        tools.push(new Block.MetaToolTool(tool.tools[i]));
      }
      tool.tools = tools;
    }
    if (tool.operations) {
      let operations = [];
      for (i = 0; i < tool.operations.length; i++) {
        if (tool.operations[i].type === 'meta-tool-comparator') {
          operations.push(new Block.MetaToolComparator(tool.operations[i]));
        }
      }
      tool.operations = operations;
    }
    if (tool.codesAndScore) {
      let codesAndScore = [];
      for (i = 0; i < tool.codesAndScore.length; i++) {
        codesAndScore.push(new Block.CodesAndScore(tool.codesAndScore[i]));
      }
      tool.codesAndScore = codesAndScore;
    }
    // pdfCommitId for reference when updating the tool itself
    if (commitId) tool.toolCommitId = commitId;
    return tool;
  };

  // loads the template retrieved from s3
  this.loadReportTemplate = function (temp) {
    let template;
    temp = angular.fromJson(temp);
    console.log(temp);
    // TODO (Alex) load existing report template blocks
    return template;
  };

  // refreshes a tool's Blocks
  this.refreshTool = function (tool) {
    console.log('refresh tool: ', tool);
    let i, j;
    if (tool.rootToolInformation)
      tool.rootToolInformation = new Block.RootToolInformation(
        tool.rootToolInformation
      );
    if (tool.dictionary)
      tool.dictionary = new Block.Dictionary(tool.dictionary);
    if (tool.codingFormItems && tool.codingFormItems.length) {
      let newCFIs = [];
      for (i = 0; i < tool.codingFormItems.length; i++) {
        console.log('Coding Form Item', angular.copy(tool.codingFormItems[i]));
        newCFIs.push(new Block.CodingFormItem(tool.codingFormItems[i]));
      }
      tool.codingFormItems = newCFIs;
    }
    if (tool.riskCategories && tool.riskCategories.length) {
      for (i = 0; i < tool.riskCategories.length; i++) {
        tool.riskCategories[i] = new Block.RiskCategory(tool.riskCategories[i]);
      }
    }
    console.log('FINAL', tool);
    return tool;
  };
}

export default angular
  .module('app.tool-management', [])
  .service('toolManagement', toolManagementService).name;
