import angular from 'angular';
import _ from 'lodash';

import { Action } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class Delete2ModalController extends Modal {
  parent: any = null;
  item: any = null;
  itemType: any = null;
  itemName: string = '';
  itemNameClient: string = '';
  itemNameDate: string = '';
  itemNameEvaluator: string = '';

  @Inject $state;
  @Inject $scope;
  @Inject $api;
  @Inject $store;
  @Inject utils;
  @Inject $filter;

  @Action('institutions/delete') deleteInstitution; // temp done
  @Action('evaluations/delete') deleteEvaluation; // temp done
  @Action('clientRequests/delete') deleteClientRequest; // temp done
  @Action('invitations/delete') deleteInvitation; // temp done
  @Action('reports/delete') deleteReport; // temp done
  @Action('tools/delete') deleteTool; // temp done
  @Action('users/delete') deleteUser; // TODO (Alex)
  @Action('users/remove') removeUser; // temp done
  @Action('clients/delete') deleteClient; // temp done
  @Action('evaluationRequests/delete') deleteEvaluationRequest; // temp done

  $setup() {
    this.item = angular.copy(this.$props.item);
    this.itemType = this.$props.itemType;

    let {
      name,
      client,
      evaluator,
      institution,
      email,
      assignedDate,
      dueDate,
      createdAt,
      targetInst,
      fName,
      mName,
      lName,
      tool,
      account
    } = this.item;
    if (!client) client = this.$store.state.clients.focus;
    this.client = client;
    const clientName = () =>
      client?.mName
        ? `${client?.lName}, ${client?.fName} ${client?.mName}`
        : `${client?.lName}, ${client?.fName}`;
    if (!evaluator) {
      if (this.item.evaluationId) {
        let foundEval = _.find(this.$store.state.evaluations.items, {
          id: this.item.evaluationId
        });
        if (foundEval) evaluator = foundEval.evaluator;
      }
    }

    const evaluatorName = () =>
      evaluator
        ? `${evaluator?.lName}, ${evaluator?.fName}`
        : 'No Evaluator Found';

    const dateTime = (data, ...formats) =>
      this.$filter('dynamicDate')(data, ...formats);

    switch (this.itemType) {
      case 'institution':
        this.itemName = name;
        break;
      case 'evaluation':
        this.itemNameClient = clientName();
        this.itemNameDate = dateTime(assignedDate, 'MM/dd/yyyy h:mm:ss a');
        this.itemNameEvaluator = evaluatorName();
        break;
      case 'client request':
        this.itemName = `Client: ${clientName()} | Target Institution: ${
          targetInst.name
        }`;
        break;
      case 'invitation':
        if (account) {
          this.itemName = `To: ${email} for Institution: ${account.name}`;
        } else {
          this.itemName = `To: ${email} for {No Institution Detected}`;
        }
        break;
      case 'report':
        this.itemName = `Created on: ${dateTime(
          createdAt,
          'MM/dd/yyyy h:mm:ss a'
        )} | For Client: ${clientName()}`;
        break;
      case 'tool':
        this.itemName = name;
        break;
      case 'user':
        this.itemName = `${fName} ${lName}`;
        break;
      case 'client':
        this.itemName = clientName();
        break;
      case 'evaluation-request':
        this.itemName = `Due Date: ${dateTime(
          dueDate,
          'MM/dd/yyyy'
        )}  | For Client: ${clientName()} | Evaluator: ${evaluatorName()}  | Tool: ${
          tool.name
        }`;

        break;
      case 'remove-user':
        this.itemName = `${fName} ${lName}`;
        break;
    }
  }

  async deleteItem() {
    this.deleting = true;

    var reqFn;

    console.log(this.item);

    switch (this.itemType) {
      case 'institution':
        reqFn = () => this.deleteInstitution(this.item.id);
        break;
      case 'evaluation':
        reqFn = () => this.deleteEvaluation(this.item);
        break;
      case 'client request':
        reqFn = () => this.deleteClientRequest(this.item);
        break;
      case 'invitation':
        reqFn = () => this.deleteInvitation(this.item);
        break;
      case 'report':
        reqFn = () =>
          this.deleteReport({
            id: this.item.id,
            evaluationId: this.item.evaluationId,
            client: this.client
          });
        break;
      case 'tool':
        reqFn = () => this.deleteTool(this.item.id);
        break;
      case 'user':
        reqFn = () => this.deleteUser(this.item.id);
        break;
      case 'remove-user':
        reqFn = () => this.removeUser(this.item);
        break;
      case 'client':
        reqFn = () => this.deleteClient(this.item);
        break;
      case 'evaluation-request':
        reqFn = () => this.deleteEvaluationRequest(this.item);
        break;
    }

    let data, error;

    try {
      let res = await reqFn();
      if (res?.data) data = res?.data;
    } catch (err) {
      error = err;
    } finally {
      this.deleting = false;
    }

    let message = this.itemType.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );

    if (error) {
      this.utils.notify('error', `Error - Deleting ${message}`);
      return;
    }

    this.utils.notify('success', `${message} Successfully Deleted`);

    // if (this.itemType === 'evaluation-request') {
    //   this.item.status === 2
    //     ? (data.completedEval = true)
    //     : (data.completedEval = false);
    //   data.evalId = this.item.evaluation.id;
    // }
    this.$close(data);
  }
}

export default angular
  .module('app.delete2Modal', [])
  .directive('delete2Modal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./delete2.html'),
    controller: Delete2ModalController,
    controllerAs: 'vm'
  })).name;
