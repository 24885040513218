import { Component } from '@angular';
import { Action } from 'angular-store';

import { Subgroup } from '@interfaces/subgroup';
import { Modal, ModalProp } from '@mixins/modal.component';
import { ClientModel } from '@models/client.model';

import template from './subgroup-client-transfer-here.html';

/**
 * ...
 */
interface ClientListItem {
  data: ClientModel;
  selected: boolean;
}

@Component({ name: 'subGroupClientTransferHereModal', template })
export class SubGroupClientTransferHereModal extends Modal {
  @ModalProp readonly clients!: ClientModel[];
  @ModalProp readonly subGroup!: Subgroup;

  selectedClients: ClientListItem[] = [];
  selectionCount = 0;

  @Action('clients/transferLocations') readonly transferLocations!: (options: {
    clientId: string;
    subGroupId: string;
  }) => Promise<ClientModel>;

  $setup() {
    this.selectedClients = this.clients
      .map((client) => ({
        selected: false,
        data: client
      }))
      .sort((a, b) => {
        const aLName = a.data.lName.toUpperCase();
        const bLName = b.data.lName.toUpperCase();
        return aLName < bLName ? -1 : aLName > bLName ? 1 : 0;
      });
  }

  /**
   * ...
   */
  updateSelection() {
    this.selectionCount = 0;

    for (const item of this.selectedClients) {
      if (item.selected) this.selectionCount++;
    }
  }

  /**
   * ...
   */
  async transfer() {
    const clients = this.selectedClients
      .filter((item) => item.selected)
      .map(({ data }) => data);

    const transferedClientIds: string[] = [];

    for (const client of clients) {
      const options = {
        clientId: client.id,
        subGroupId: this.subGroup.id
      };

      try {
        await this.transferLocations(options);
      } catch (err) {
        console.error('[Failed to transfer client]', err);

        continue;
      }

      transferedClientIds.push(client.id);
    }

    this.$close(transferedClientIds);
  }
}

export default SubGroupClientTransferHereModal.$module;
