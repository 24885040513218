'use strict';

import { State } from 'angular-store';
import { Controller } from '@/decorators/ngCtrl';

@Controller
class ForbiddenView {
  constructor() {
    'ngInject';
  }
}

export default ForbiddenView;
