'use strict';

import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class DashboardReportTemplatesView {
  tableProps: {}[] = [];
  tableItems: {}[] = [];
  tableActions: {}[] = [];
  searchText: string = '';

  @Inject $modals;
  @Inject $state;
  @Inject $auth;

  @State reportTemplates;
  @State institutions;
  @State me;

  $onInit() {
    this.tableItems = this.reportTemplates.items;

    this.tableProps = [
      // SELECTED COLUMN
      // {
      //   label: 'Created',
      //   value: row => `${row.lName}, ${row.fName}`
      // },
      {
        label: 'Created',
        value: row => `${row.lName}, ${row.fName}`
      },
      {
        label: 'Template Name',
        value: row => `${row.lName}, ${row.fName}`
      }
    ];

    this.tableActions = [
      {
        //
      }
    ];
  }

  adminTemplateChosen(template) {
    this.$modals.tool.commitsViewer(template, 'template-commits', commit => {
      if (commit.casePlan) {
        this.$store.dispatch('casePlan/load', { cp: commit });
      } else if (commit.newReportTemplate) {
        this.$state.go('toolCreatorV2', { commit, newReportTemplate: true });
      } else if (commit.test) {
        this.toolUtils.testToolStart(template);
      } else {
        this.$state.go('toolCreatorV2', { reportTemplate: commit });
      }
    });
  }

  async openCreateReportTemplateModal() {
    this.$modals.tool.chooseReportTemplate(
      this.reportTemplateTool,
      this.reportTemplates.items,
      this.institutions.items,
      group => this.reportTemplateGroupChosen(group)
    );
  }

  deleteReportTemplate() {}

  reportTemplateGroupChosen(group) {
    this.gifrNewReportTemplate = group;
    this.openCreateReportTemplate(this.reportTemplateTool);
    // this.changeSection('create-report-template');
    this.$state.go('dashboardCreateReportTemplate');
  }

  async openCreateReportTemplate(tool) {
    this.$http.get(`/api/tools/${tool.id}/file`).then(file => {
      this.newReportTemplateTool.file = JSON.parse(file.data.data);
      this.loading = false;
    });

    this.newReportTemplateTool = tool;
    this.legendCollapsed = true;
    this.newReportTemplate = {
      // new report template template
      tool,
      name: this.newReportTemplateTool.name,
      version: '0.0.1',
      reportCriteria: {
        overview: {
          title: 'Overview of ' + this.newReportTemplateTool.name,
          hasToggle: true,
          toggleDefault: true,
          options: [
            {
              isDefault: true,
              text: 'Brief',
              content: [
                {
                  type: 0,
                  text:
                    'This is a brief overview of ' +
                    this.newReportTemplateTool.name,
                  image: ''
                }
              ]
            },
            {
              isDefault: false,
              text: 'Extended',
              content: [
                {
                  type: 0,
                  text:
                    'This is an extended overview of ' +
                    this.newReportTemplateTool.name,
                  image: ''
                }
              ]
            }
          ],
          sectionType: 0 // 0: normal choice, 1: conditional based on evaluation score
        },
        itemDescriptions: {},
        additionalCriteria: [],
        chartData: []
      }
    };

    this.$state.go('tool-creator-v2', {
      reportTemplate: this.newReportTemplate
    });
  }
}

export default DashboardReportTemplatesView;
