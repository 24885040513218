export default {
  coverPage() {
    return {
      head: [['Name:', 'client.fName client.lName']],
      body: [
        ['ID Number:', 'client.localId'],
        ['Age:', 'clientAge'],
        ['Sex:', 'client.sex'],
        ['Gender Identity:', 'client.gender'],
        ['Race/Ethnicity:', 'client.ethnicity'],
        ['Current Location:', 'clientLocation'],
        ['Location at Time of Assessment:', 'clientAssessmentLocation'],
        ['Assessment Date:', 'assessmentDate'],
        ['Interview Date:', 'interviewDate'],
        ["Assessor's Name:", 'user.fName user.lName'],
        ['Normative Option:', 'clientNormativeType'],
        ['Offender Type:', 'client.type']
      ],
      columnStyles: {
        0: {
          minCellWidth: 100
        }
      }
    };
  },
  subSectionCutOffScores() {
    return {
      head: [['Area of Assessment', 'Low', 'Moderate', 'High']],
      body: [
        ['1. Offenses/Dispositions', '0', '1-2', '3-5'],
        ['2. Family/Parenting', '0-2', '3-4', '5-6'],
        ['3. Education/Employment', '0', '1-3', '4-7'],
        ['4. Peer Relations', '0-1', '2-3', '4'],
        ['5. Substance Abuse', '0', '1-2', '3-5'],
        ['6. Leisure/Recreation', '0', '1', '2-3'],
        ['7. Personality/Behavior', '0', '1-4', '5-7'],
        ['8. Attitudes/Orientation', '0', '1-3', '4-5']
      ],
      styles: {
        halign: 'center'
      },
      columnStyles: {
        0: {
          halign: 'left'
        }
      }
    };
  },
  riskNeedsDetails() {
    return {
      head: [
        [
          'Area of Assessment',
          'Items Selected',
          'Strength',
          'Items Not Selected',
          'Comments',
          'Sources'
        ]
      ],
      columnStyles: {
        0: {
          cellWidth: 100
        },
        1: {
          cellWidth: 70
        },
        2: {
          halign: 'center',
          cellWidth: 50
        },
        3: {
          cellWidth: 70
        },
        4: {
          cellWidth: 150
        },
        5: {
          cellWidth: 100
        }
      }
    };
  }
};
