'use strict';

import { State } from 'angular-store';

class DashboardAllEvaluationsView {
  tableProps = [];
  tableItems = [];
  tabelUtilCols = [];
  searchText = '';

  constructor($scope, $state, $store) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
  }

  // @State(state => state.allEvaluations.items) allEvaluations;
}

export default DashboardAllEvaluationsView;
