'use strict';

import angular from 'angular';

import { Controller, Inject } from '@/decorators/ngCtrl';

@Controller
class OauthButtonsController {
  @Inject $window;

  loginOauth(provider) {
    this.$window.location.href = `/auth/${provider}`;
  }
}

export default angular
  .module('app.oauthButtons', [])
  .directive('oauthButtons', function() {
    return {
      template: require('./oauth-buttons.html'),
      restrict: 'EA',
      controller: OauthButtonsController,
      controllerAs: 'vm',
      scope: {
        classes: '@'
      }
    };
  }).name;
