import angular from 'angular';
import { orderBy } from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import { State, Getter, Action } from 'angular-store';
import Modal, { Submit } from '@/mixins/modal.mixin';

const Tools = {
  2: 'STATIC-99R',
  99: 'VRAG-R',
  100: 'STABLE-2007',
  101: 'ACUTE-2007',
  120: 'YLS/CMI',
  124: 'LS/CMI',
  148: 'TRAP-18',
  156: 'Hare PCL-R',
  157: 'LSI-R'
};

const ExampleFiles = {
  2: 'STATIC_EXAMPLE.xlsx',
  99: 'VRAG_EXAMPLE.xlsx',
  100: 'STABLE_EXAMPLE.xlsx',
  101: 'ACUTE_EXAMPLE.xlsx',
  120: 'YLSCMI_YLSCMISRV_EXAMPLE.xlsx',
  124: 'LSCMI_LSIRSV_EXAMPLE.xlsx',
  148: 'TRAP18_EXAMPLE.xlsx',
  156: 'HAREPCLR_EXAMPLE.xlsx',
  157: 'LSIR_EXAMPLE.xlsx'
};

const MimeTypes = {
  XLS: 'application/vnd.ms-excel',
  XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
};

@Controller
class BulkCreateEvaluationsModalController extends Modal {
  spreadsheet: unknown;
  selectedInst: string;
  focusedRequest: unknown = null;
  tools: Tool[] = [];
  selectedTool: Tool;
  toolId: string | null = null;
  requests: EvaluationImportRequests[] = [];

  @Inject $scope;
  @Inject $auth;
  @Inject $api2;
  @Inject $http;
  @Inject $delay;
  @Inject $store;
  @Inject $timeout;
  @Inject Notification;
  @Inject notify;
  @Inject utils;
  @Inject Upload;
  @Inject $modals;

  @Getter isAdmin;
  @State(({ institutions }) => institutions.items) institutions;
  @State(({ tools }) => tools.items) allTools;
  @Action('institutions/getAll') listInstitutions;
  @Action('tools/list') listTools;

  get instId() {
    return this.$props?.instId;
  }

  get exampleFile() {
    return ExampleFiles[this.selectedTool?.id];
  }

  async $setup() {
    if (!this.$store?.state?.tools?.items?.length) await this.listTools();
    this.tools = this.$store.state.tools.items.filter(({ id }) => Tools[id]);

    if (this.isAdmin && !this.institutions?.length)
      await this.listInstitutions();
    this.cooldownTimeout;
    this.refreshCooldown = 0;

    if (!this.instId || this.isAdmin) return;

    await this.listImportRequests(this.instId);
  }

  onTimeout() {
    this.refreshCooldown--;

    if (this.refreshCooldown > 0) {
      this.cooldownTimeout = this.$timeout(() => this.onTimeout(), 1000);
    }
  }

  async listImportRequests(institutionId) {
    let res, error;

    this.loading = true;

    try {
      res = await this.$api2.im.listImportEvaluationRequests({ institutionId });
    } catch (err) {
      error = err;
    }

    this.loading = false;

    if (error) {
      this.notify.display(error, 'error');
    } else {
      this.requests = res.map((item) => ({ ...item, loading: false }));
    }

    this.refreshCooldown = 10;
    this.cooldownTimeout = this.$timeout(() => this.onTimeout(), 1000);

    this.$scope.$apply();
  }

  getInstitutionEvaluationRequests() {
    this.listImportRequests(this.selectedInst);
  }

  openValueGuide() {
    this.$modals.evaluation.importRequestValueGuide(this.selectedTool);
  }

  viewRequestDetails(req) {
    this.$modals.evaluation.importRequestDetails(req);
  }

  @Submit
  async bulkCreateEvaluations() {
    const instId = this.isAdmin ? this.selectedInst : this.instId;

    if (!instId) {
      return console.error(
        '[bulk create evaluations modal] must supply institution id to upload spreadsheet'
      );
    }

    this.uploading = true;

    let spreadsheet = this.spreadsheet;

    if (spreadsheet.type !== MimeTypes.XLS) {
      spreadsheet = spreadsheet.slice(0, spreadsheet.size, MimeTypes.XLS);
    } else if (spreadsheet.type !== MimeTypes.XLSX) {
      spreadsheet = spreadsheet.slice(0, spreadsheet.size, MimeTypes.XLSX);
    }

    const res = await this.Upload.upload({
      url: `/api/institution-manager/${instId}/import-evaluations`,
      data: {
        toolId: this.selectedTool.id,
        file: spreadsheet
      }
    });

    this.uploading = false;

    if (!res) {
      return this.notify.display(
        'There was an issue uploading your spreadsheet. Please contact support@gifrgears.com for assistance.',
        'error'
      );
    } else if (res.status !== 200 && res.status !== 204) {
      return this.notify.display(res, 'error');
    }

    res.data.createdAt = new Date().toISOString();

    this.notify.display(
      'Your request has been created. Please allow us 5 minutes to process your request and check back here for the results',
      'success',
      undefined,
      'Request Successfully Created'
    );

    if (!Array.isArray(this.requests) || !this.requests) this.requests = [];

    this.requests = orderBy([...this.requests, res.data], 'createdAt');
    this.spreadsheet = null;

    this.$scope.$apply();
  }
}

export default angular
  .module('gifrApp.bulkCreateEvaluations', [])
  .directive('bulkCreateEvaluations', () => ({
    restrict: 'E',
    replace: true,
    template: require('./bulk-create-evaluations.html'),
    controller: BulkCreateEvaluationsModalController,
    controllerAs: 'vm'
  })).name;
