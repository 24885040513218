'use strict';
const angular = require('angular');

export default angular
  .module('app.tool-editor-shape', [])
  .directive('toolEditorShape', function(
    $document,
    ToolEditorConnectors,
    $rootScope,
    notify,
    Upload,
    $location,
    $modals
  ) {
    'ngInject';
    var holdingShift = false;
    $(document).on('keyup keydown', e => {
      holdingShift = e.shiftKey;
    });

    let controller = [
      '$scope',
      function($scope) {
        function init() {
          let toolId;
          if (ToolEditorConnectors.data.toolId)
            toolId = ToolEditorConnectors.data.toolId;
          if (
            $location.$$absUrl.includes('localhost') ||
            $location.$$absUrl.includes('dev.gifrgears')
          ) {
            $scope.imageUrl = `https://s3.amazonaws.com/gifr-shared/ReportTemplates/dev_${toolId}`;
          } else {
            $scope.imageUrl = `https://s3.amazonaws.com/gifr-shared/ReportTemplates/${toolId}`;
          }
          $scope.wizardQuestionTypes = [
            {
              label: 'Binary',
              value: 0
            },
            {
              label: 'Multiple Choice/Single Answer',
              value: 1
            },
            {
              label: 'Multiple Choice/Multiple Answer',
              value: 2
            }
          ];
        }

        init();
        $scope.wizardQuestionTypeChosen = function(answer) {
          console.log(answer);
          console.log(ToolEditorConnectors);
        };

        $scope.parseJson = function(text) {
          try {
            JSON.parse(text);
          } catch (e) {
            notify.display('Json is formatted incorrectly', 'error');
            return false;
          }
          return true;
        };

        $scope.format = function(str, format) {
          console.log('str: ', str);
          console.log('format: ', format);
          switch (format) {
            case 'json':
              try {
                str = JSON.parse(str);
                str = JSON.stringify(str, undefined, 4);
              } catch(e) {
                notify.display('Json is formatted incorrectly', 'error');
              }
              console.log('new str: ', str);
              return str;
              break;
            default:
              return str;
          }
        };

        //region uploading image
        $scope.uploadImage = function(file, elem) {
          if (!ToolEditorConnectors.data.toolId) {
            notify.warning(
              'No tool id detected. Must save an initial commit before adding images.'
            );
            return;
          }
          Upload.upload({
            url: `/api/templates/${
              ToolEditorConnectors.data.toolId
            }/add-images`,
            file: [file]
          })
            .then(response => {
              console.log('upload image response', response);
              if (response.data.error) {
                notify.error(response.data.feedback);
              } else {
                notify.success(response.data.feedback);
                elem.$parent.field.model = file.name;
              }
            })
            .catch(err => {
              console.log('upload image error', err);
              notify.error(err);
            });
        };

        $scope.selectExistingImage = function(elem) {
          if (!ToolEditorConnectors.data.toolId) {
            notify.warning(
              'No tool id detected. Must save an initial commit before adding images.'
            );
            return;
          }
          $modals.tool.selectExistingImage(
            elem,
            ToolEditorConnectors.data.toolId
          );
        };

        $scope.enlargeImage = function(url) {
          $modals.tool.viewImage(url);
        };
        //endregion uploading image

        //region select exising tool
        $scope.selectTool = async function(block) {
          let res = await $modals.tool.selectTool(block);
        };
        //endregion select existing tool
      }
    ];

    return {
      template: require('./tool-editor-shape.html'),
      restrict: 'EA',
      controller,
      scope: {
        block: '=', //block object for editor
        operators: '=' //less than, greater than, etc.
      },
      link: function(scope, element, attrs) {
        let editor = angular.element(document.querySelector('#tool-editor'));
        let minX = 0;
        let minY = 0;
        let maxX = editor[0].offsetWidth;
        let maxY = editor[0].offsetHeight;
        let blockWidth = element[0].clientWidth;
        let blockHeight = element[0].clientHeight;
        let startX = scope.block.x;
        let startY = scope.block.y;
        let x = scope.block.x;
        let y = scope.block.y;

        element.on('click', function(event) {
          let className = event.target.className;
          let i;
          if (className.includes('fa-times remove-block')) {
            ToolEditorConnectors.deleteBlock(scope.block);
          } else if (className.includes('fa-pencil')) {
            $rootScope.$broadcast('load-block', scope.block);
            $rootScope.$apply();
          } else if (className.includes('fa-minus-circle fa-2x text-danger')) {
            for (i = 0; i < scope.block.rows.length; i++) {
              if (
                event.target.id ===
                scope.block.rows[i].inputNodeId +
                  scope.block.rows[i].outputNodeId
              ) {
                ToolEditorConnectors.deleteRow(
                  scope.block,
                  scope.block.rows[i]
                );
                break;
              }
            }
          } else if (className.includes('fa-plus-circle fa-2x text-success')) {
            if (scope.block.type === 'wizard-question') {
              if (scope.block.questionType === 0) {
                //binary question type
                let wizardAnswerCount = 0;
                for (i = 0; i < scope.block.rows.length; i++) {
                  if (
                    scope.block.rows[i].fields[0].label === 'Answer ID' &&
                    scope.block.rows[i].fields[0].text.includes('WA-')
                  ) {
                    wizardAnswerCount++;
                  }
                }
                if (wizardAnswerCount >= 2) {
                  notify.warning(
                    'Cannot create another answer for a binary question.'
                  );
                } else {
                  ToolEditorConnectors.addRow(scope.block, event.target.id);
                }
              } else {
                ToolEditorConnectors.addRow(scope.block, event.target.id);
              }
            } else {
              ToolEditorConnectors.addRow(scope.block, event.target.id);
            }
          }
        });

        element.css({
          top: scope.block.y + 'px',
          left: scope.block.x + 'px'
        });

        // element.on('mousedown', function(event) {
        //   if (event.button === 2) {
        //  Right clicked!
        //   }
        // });

        element.on('dblclick', function() {
          ToolEditorConnectors.bringToFront(scope.block);
        });

        let elementHeader = angular.element(element[0].querySelector('.title'));

        elementHeader.on('mousedown', function(event) {
          event.preventDefault();
          startX = event.screenX - x;
          startY = event.screenY - y;
          $document.on('mousemove', mousemove);
          $document.on('mouseup', mouseup);
          blockWidth = element[0].clientWidth;
          blockHeight = element[0].clientHeight;

          element.attr('data-x-pos', startX);
          element.attr('data-y-pos', startY);
        });

        // elementHeader.on('mouseenter', function() {
        //   elementHeader.children()
        //     .children('.block-options-container')
        //     .css('display', 'block');
        // });
        //
        // elementHeader.on('mouseleave', function() {
        //   elementHeader.children()
        //     .children('.block-options-container')
        //     .css('display', 'none');
        // });

        function mousemove(event) {
          y = event.screenY - startY;
          x = event.screenX - startX;

          // Snap by units of 25 when shift is held
          if (holdingShift) {
            var snapX = x - scope.block.x;
            var snapXDir = snapX >= 0 ? 1 : -1;

            if (Math.abs(snapX) >= 25) {
              x = scope.block.x + snapX;

              let rmndrX = x % 25;
              if (rmndrX !== 0) {
                x = rmndrX <= 12 ? x - rmndrX : x + 25 - rmndrX;
              }
            } else {
              x = scope.block.x;
            }

            var snapY = y - scope.block.y;

            if (Math.abs(snapY) >= 25) {
              y = scope.block.y + snapY;

              let rmndrY = y % 25;
              if (rmndrY !== 0) {
                y = rmndrY <= 12 ? y - rmndrY : y + 25 - rmndrY;
              }
            } else {
              y = scope.block.y;
            }
          }

          if (blockHeight < maxY) {
            if (y < minY) {
              y = minY;
            } else if (y > maxY - blockHeight) {
              y = maxY - blockHeight;
            }
          } else if (y < minY) {
            y = minY;
          }

          if (x < minX) {
            x = minX;
          } else if (x > maxX - blockWidth) {
            x = maxX - blockWidth;
          }

          element.attr('data-x-pos', x);
          element.attr('data-y-pos', y);

          element.css({
            top: y + 'px',
            left: x + 'px'
          });

          scope.block.y = y;
          scope.block.x = x;
          ToolEditorConnectors.drawBlockLines(scope.block);
          let nodeOptions = angular.element(
            document.querySelector('#tool-editor-node-options')
          );

          nodeOptions.css({
            visibility: 'hidden',
            opacity: 0,
            left: 0,
            top: 0
          });
        }

        function mouseup() {
          $document.unbind('mousemove', mousemove);
          $document.unbind('mouseup', mouseup);
        }
      }
    };
  }).name;
