import angular from 'angular';

import { UtilService } from './util.service';
import { ClientService } from './client.service';
import { ToolService } from './tool.service';

/**
 * ...
 */
export type IUtilService = UtilService;
/**
 * ...
 */
export type IClientService = ClientService;
/**
 * ...
 */
export type IToolService = ToolService;

declare module 'angular' {
  namespace gears {
    type IUtilService = UtilService;
    type IClientService = ClientService;
    type IToolService = ToolService;
  }
}

export default angular
  .module('app.$util', [])
  .service('$util', UtilService)
  // TODO: Util is an alias for the $util service. Remove references and
  // depreciate.
  .service('Util', UtilService)
  .service('$clientService', ClientService)
  .service('$toolService', ToolService).name;
