import { uniqBy } from 'lodash';

function AggregateReportsStore($api, $acl) {
  'ngInject';

  return {
    state: {
      loading: false,
      items: [],
      table: {
        sortedCol: 0,
        searchText: ''
      }
    },
    getters: {
      find: (state) => (id) => state.items.find((item) => item.id == id)
    },
    actions: {
      async list({ state, commit, rootState, rootGetters }, { instId } = {}) {
        let loader;

        if (
          $acl('GM:ListAggregateReportRequests', rootState.permissions.profile)
        ) {
          loader = $api.GM.listAggregateReportRequests;
        } else if (
          $acl('IM:ListAggregateReportRequests', rootState.permissions.profile)
        ) {
          loader = $api.IM.listAggregateReportRequests;
        } else {
          console.warn(
            `User does not have permission to execute the action: AggregateReports:list`
          );
          return state.items;
        }

        commit('setProps', { loading: true });

        let data = [];

        try {
          let res = await loader({
            instId: instId ? instId : rootGetters.activeInstId
          });
          data = res.data;
        } catch (err) {
          commit('setProps', { loading: false });
          throw err;
        }

        commit('set', data);

        commit('setProps', { loading: false });

        return state.items;
      },
      async create({ dispatch, rootState, rootGetters }, payload) {
        const params = {
          institutionId: payload.institutionId,
          institutions: payload.institutions,
          toolId: payload.toolId,
          startDate: payload.startDate,
          endDate: payload.endDate,
          includeCasePlans: payload.includeCasePlans,
          includeOffenderHistory: payload.includeOffenderHistory,
          includeSupplementalTools: payload.includeSupplementalTools
        };

        let loader;

        if (
          $acl('GM:CreateAggregateReportRequest', rootState.permissions.profile)
        ) {
          loader = () => $api.GM.createAggregateReportRequest(params);
        } else if (
          $acl('IM:CreateAggregateReportRequest', rootState.permissions.profile)
        ) {
          loader = () =>
            $api.IM.createAggregateReportRequest(
              { instId: payload.institutionId },
              params
            );
        } else {
          return console.warn(
            `User does not have permission to execute the action: AggregateReports:create`
          );
        }

        // var res = await $api.IM.createAggregateReportRequest(
        //   { instId: payload.institutionId },
        //   params
        // );

        let res = await loader();

        await dispatch('list', { instId: rootGetters.activeInstId });

        return res;
      }
    },
    mutations: {
      set(state, payload) {
        state.items = [...payload];
      },
      add(state, payload) {
        state.items = uniqBy([...payload, ...state.items], 'id');
      },
      setProps(state, props = {}) {
        for (let i in props) {
          if (i in state) {
            state[i] = props[i];
          }
        }
      },
      CLEAR(state) {
        Object.assign(state, {
          loading: false,
          items: [],
          table: {
            sortedCol: 0,
            searchText: ''
          }
        });
      }
    }
  };
}

export default AggregateReportsStore;
