'use strict';

import angular from 'angular';
import * as _ from 'lodash';

import { Controller, Inject } from '@/decorators/ngCtrl';
import Modal from '@/mixins/modal.mixin';

@Controller
class WarningModalController extends Modal {
  warning: any;
  object: any;

  @Inject $scope;
  @Inject $store;

  $setup() {
    this.warning = this.$props.message;
    this.object = this.$props.object;
    console.log('this.object: ', this.object);
    if (this.object?.evaluationData) {
      if (!this.object?.client && this.object?.clientId) {
        let client = _.find(this.$store.state.clients.items, {
          id: this.object.clientId
        });
        if (client) this.object.client = client;
      }
      if (!this.object?.evaluator && this.object?.evaluatorId) {
        let evaluator = _.find(this.$store.state.users.items, {
          id: this.object.evaluatorId
        });
        if (evaluator) this.object.evaluator = evaluator;
      }
    }
    //
  }
}

export default angular
  .module('app.warningModal', [])
  .directive('warningModal', () => ({
    restrict: 'E',
    replace: true,
    template: require('./warning.html'),
    controller: WarningModalController,
    controllerAs: 'vm'
  })).name;
