import angular from 'angular';
// import moment from 'moment';
// import Chart from 'chart.js';
// import $ from 'jquery';
//
// import { State } from 'angular-store';
import { Controller, Inject } from '@/decorators/ngCtrl';
//
// type GraphType = {
//   name: string,
//   type: string
// };
//
// Chart.defaults.global.defaultFontSize = 14;
// Chart.defaults.global.defaultFontColor = '#fff';
// Chart.defaults.global.elements = {
//   arc: {},
//   line: {
//     pointRadius: 5,
//     pointBorderColor: 'rgba(255, 255, 255, 0.2)',
//     pointBackgroundColor: 'white',
//     borderDash: [],
//     tension: 0.4,
//     borderWidth: 4,
//     borderCapStyle: 'round',
//     // fill: true,
//     fill: false
//   },
//   point: {}
// };
//
// const lineShadow = Chart.controllers.line.extend({
//   draw(ease) {
//     // Call super method first
//     Chart.controllers.line.prototype.draw.call(this, ease);
//
//     var ctx = this.chart.ctx;
//     const canvas = ctx.canvas;
//
//     var originalStroke = ctx.stroke;
//
//     ctx.stroke = function() {
//       ctx.save();
//
//       ctx.shadowColor = '#000';
//       ctx.shadowBlur = 20;
//       ctx.shadowOffsetX = 0;
//       ctx.shadowOffsetY = 10;
//
//       originalStroke.apply(this, arguments);
//       // ctx.globalCompositeOperation = 'source-over';
//
//       ctx.restore();
//     };
//   }
// });
//
// Chart.controllers.lineShadow = lineShadow;
//
// @Controller
// class StatsGraphComponent {
//   chart: Chart = null;
//   data: {} = {};
//   dataType: string = 'evaluations';
//   parsingData: boolean = false;
//   //
//   // filters: {} = {};
//   institution = 'all';
//   tool = 'all';
//   fromDate = null;
//   toDate = null;
//   graphType = 'chart-line';
//   graphLabels = [];
//   graphEvalData = [];
//   graphUserData = [];
//   lineChartColors = [];
//   chartData = {};
//   options = {};
//   ctx = null;
//
//   bgR = 0;
//   bgG = 230;
//   bgB = 183;
//   bgA = 0.2;
//   lnR = 59;
//   lnG = 255;
//   lnB = 176;
//   lnA = 1;
//
//   graphTypes: GraphType[] = [
//     {
//       name: 'Line',
//       type: 'line'
//     },
//     {
//       name: 'Bar/Histogram',
//       type: 'bar'
//     },
//     {
//       name: 'Pie',
//       type: 'pie'
//     },
//     {
//       name: 'Doughnut',
//       type: 'doughnut'
//     }
//   ];
//
//   @Inject $scope;
//   @Inject $element;
//   @Inject $filter;
//   @Inject $store;
//
//   @State analytics;
//   @State institutions;
//   @State evaluations;
//   @State tools;
//
//   $onInit() {
//     this.graphType = this.graphTypes[0].type;
//
//     var fromDate = new Date();
//     fromDate.setFullYear(fromDate.getFullYear() - 1);
//     this.fromDate = this.$filter('dynamicDate')(fromDate, 'MM/dd/yyyy', 'UTC');
//
//     var toDate = new Date();
//     this.toDate = this.$filter('dynamicDate')(toDate, 'MM/dd/yyyy', 'UTC');
//
//     this.setOptions();
//
//     if (this.dataType === 'evaluations') {
//       this.createDataSets();
//     }
//
//     this.$scope.$watch(
//       () => this.$store.state.theme === 'dark',
//       () => this.setOptions()
//     );
//
//     // setTimeout(() => this.test(), 200);
//   }
//
//   // test() {
//   //   var canvas = $('#test-chart').get(0);
//   //
//   //   var ctx = canvas.getContext('2d');
//   //   ctx.beginPath();
//   //   ctx.moveTo(75, 40);
//   //   ctx.bezierCurveTo(75, 37, 70, 25, 50, 25);
//   //   ctx.bezierCurveTo(20, 25, 20, 62.5, 20, 62.5);
//   //   ctx.bezierCurveTo(20, 80, 40, 102, 75, 120);
//   //   ctx.bezierCurveTo(110, 102, 130, 80, 130, 62.5);
//   //   ctx.bezierCurveTo(130, 62.5, 130, 25, 100, 25);
//   //   ctx.bezierCurveTo(85, 25, 75, 37, 75, 40);
//   //   ctx.lineWidth = 0;
//   //   ctx.fillStyle = 'red';
//   //   ctx.strokeStyle = 'rgba(0, 0, 0, 0)';
//   //   ctx.fill();
//   //   ctx.stroke();
//   //
//   //   // ctx.drawImage(img, 0, 0);
//   //   // img.style.display = 'none';
//   //   var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
//   //   var data = imageData.data;
//   //
//   //   console.log(data);
//   //
//   //   const getPixel = i => {
//   //     const x = (i / 4) % canvas.width;
//   //     const y = (i / 4 - x) / canvas.width;
//   //
//   //     const pixel = { x, y };
//   //
//   //     Object.defineProperties(pixel, {
//   //       r: {
//   //         get: () => data[i],
//   //         set: val => (data[i] = val)
//   //       },
//   //       g: {
//   //         get: () => data[i + 1],
//   //         set: val => (data[i + 1] = val)
//   //       },
//   //       b: {
//   //         get: () => data[i + 2],
//   //         set: val => (data[i + 2] = val)
//   //       },
//   //       a: {
//   //         get: () => data[i + 3],
//   //         set: val => (data[i + 3] = val)
//   //       },
//   //       setColor: {
//   //         value(r, g, b, a) {
//   //           this.r = r;
//   //           this.g = g;
//   //           this.b = b;
//   //           this.a = a;
//   //         }
//   //       },
//   //       printColor: {
//   //         value: () =>
//   //           console.log(`rgba(${pixel.r}, ${pixel.g}, ${pixel.b}, ${pixel.a})`)
//   //       }
//   //     });
//   //
//   //     return pixel;
//   //   };
//   //
//   //   // for (var i = 0; i < data.length; i += 4) {
//   //   //   data[i] = 255 - data[i]; // red
//   //   //   data[i + 1] = 255 - data[i + 1]; // green
//   //   //   data[i + 2] = 255 - data[i + 2]; // blue
//   //   // }
//   //
//   //   const shadowDis = canvas.height / 5;
//   //
//   //   console.log(canvas.height);
//   //   console.log(shadowDis);
//   //
//   //   var shadowCols = {};
//   //
//   //   for (var i = 0; i < data.length; i += 4) {
//   //     const pixel = getPixel(i);
//   //
//   //     if (pixel.y == 50) {
//   //       pixel.printColor();
//   //     }
//   //
//   //     if (pixel.x in shadowCols === false) {
//   //       shadowCols[pixel.x] = -1;
//   //     }
//   //
//   //     if (pixel.a != 0 && shadowCols[pixel.x] === -1) {
//   //       shadowCols[pixel.x] = 0;
//   //     }
//   //
//   //     if (shadowCols[pixel.x] >= 0 && shadowCols[pixel.x] <= shadowDis) {
//   //       let ratio = 1 - shadowCols[pixel.x]++ / shadowDis;
//   //
//   //       pixel.setColor(
//   //         pixel.r * (1 - ratio),
//   //         pixel.g * (1 - ratio),
//   //         pixel.b * (1 - ratio),
//   //         pixel.a
//   //       );
//   //     }
//   //   }
//   //
//   //   ctx.putImageData(imageData, 0, 0);
//   // }
//
//   createDataSetConfig(config = {}) {
//     return {
//       ...config
//     };
//   }
//
//   setOptions() {
//     this.options = {
//       scales: {
//         xAxes: [
//           {
//             // gridLines: {
//             //   borderDash: [2, 2],
//             //   lineWidth: 1,
//             //   color: this.$store.state.theme === 'dark'
//             //     ? 'rgba(255, 255, 255, 0.1)'
//             //     : 'gray'
//             // }
//             gridLines: {
//               color: 'rgba(255, 255, 255, 0)',
//
//               // borderDash: [2, 2],
//               lineWidth: 2
//             }
//           }
//         ],
//         yAxes: [
//           {
//             // ticks: {
//             //   padding: 25
//             // },
//             gridLines: {
//               // borderDash: [2, 2],
//               lineWidth: 2,
//               // color: 'rgba(255, 255, 255, 0.1)'
//               color: this.$store.state.theme === 'dark'
//                 ? 'rgba(255, 255, 255, 0.05)'
//                 : 'rgba(255, 255, 255, 0.05)'
//             }
//           }
//         ]
//       },
//       legend: {
//         labels: {
//           // padding: 40,
//           usePointStyle: true
//         }
//       },
//       layout: {
//         padding: {
//           left: 0,
//           right: 0,
//           top: 0,
//           bottom: 0
//         }
//       },
//       plugins: {
//         filler: {
//           propagate: true
//         }
//       },
//       responsive: true,
//       maintainAspectRatio: false
//     };
//   }
//
//   setFilter(dataType) {
//     this.dataType = dataType;
//
//     this.createDataSets();
//   }
//
//   createDataSets() {
//     const dataPoints = [];
//
//     this.parsingData = true;
//
//     let m = moment(this.fromDate, 'MM/DD/YYYY');
//     let endMonth = moment(this.toDate, 'MM/DD/YYYY').valueOf();
//
//     do {
//       dataPoints.push({
//         date: m.format('M/YYYY'),
//         evlCount: 0,
//         userCount: 0
//       });
//
//       m.add(1, 'months');
//     } while (m.valueOf() <= endMonth);
//
//     var uniqueUsers = [];
//
//     for (let evl of this.evaluations.items) {
//       if (evl.status === 2) {
//         continue;
//       }
//
//       if (this.institution != 'all' && evl.institutionId !== this.institution) {
//         continue;
//       }
//
//       if (this.tool != 'all' && evl.toolUsed !== this.tool) {
//         continue;
//       }
//
//       let completeData = moment(evl.updatedAt).format('M/YYYY');
//       let dataPoint = dataPoints.find(dp => dp.date === completeData);
//
//       if (!dataPoint) {
//         continue;
//       }
//
//       dataPoint.evlCount++;
//
//       let userFound = !!uniqueUsers.find(
//         ({ userId, date }) =>
//           userId === evl.evaluatorId && date === completeData
//       );
//
//       if (userFound) {
//         continue;
//       }
//
//       dataPoint.userCount++;
//
//       uniqueUsers.push({ userId: evl.evaluatorId, date: completeData });
//     }
//
//     this.graphLabels = [];
//     this.graphEvalData = [];
//     this.graphUserData = [];
//
//     dataPoints.forEach(dp => {
//       this.graphLabels.push(dp.date);
//       this.graphEvalData.push(dp.evlCount);
//       this.graphUserData.push(dp.userCount);
//     });
//
//     this.parsingData = false;
//
//     if (!this.ctx) {
//       this.ctx = $('#dashboard-chart')
//         .get(0)
//         .getContext('2d');
//     }
//
//     console.log(this.ctx);
//
//     // var width = window.innerWidth || document.body.clientWidth;
//     // var gradientStroke = ctx.createLinearGradient(0, 0, width, 0);
//     // gradientStroke.addColorStop(0, '#33ff89');
//     // gradientStroke.addColorStop(1, '#2aa5e2');
//
//     const userDataConfig = this.createDataSetConfig({
//       label: 'ActiveUsers',
//       data: this.graphUserData,
//       backgroundColor: 'rgba(51, 222, 255, 0.5)',
//       borderColor: '#33deff',
//       fill: true
//     });
//
//     const graphEvalDataConfig = this.createDataSetConfig({
//       label: 'Evaluations',
//       data: this.graphEvalData,
//       backgroundColor: 'rgba(51, 255, 137, 0.5)',
//       // backgroundColor: gradientStroke,
//       borderColor: '#33ff89',
//       // borderColor: gradientStroke,
//       // backgroundColor: 'transparent',
//       fill: true
//     });
//
//     this.chartData = {
//       labels: this.graphLabels,
//       datasets: [userDataConfig, graphEvalDataConfig]
//     };
//
//     // graphEvalDataConfig.backgroundColor = gradientStroke;
//     // userDataConfig.backgroundColor = gradientStroke;
//
//     this.chart = new Chart(this.ctx, {
//       type: this.graphType,
//       // type: 'lineShadow',
//       data: this.chartData,
//       options: this.options
//     });
//   }
// }

@Controller
class StatsGraphComponent {}

export default angular
  .module('app.statsGraph', [])
  .directive('statsGraph', () => {
    return {
      restrict: 'E',
      replace: true,
      scope: {},
      template: require('./stats-graph.html'),
      controller: StatsGraphComponent,
      controllerAs: 'vm'
    };
  }).name;
