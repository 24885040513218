import angular from 'angular';

/**
 * ...
 */
export type NotificationType = 'success' | 'warning' | 'error' | 'primary';
/**
 * ...
 */
export type NotificationHorizontalPosition = 'center' | 'left' | 'right';

declare module 'angular' {
  namespace gears {
    type INotifyService = NotifyService;
  }
}

const TEMPLATE_1 = 'assets/components/notification-template.html';
const TEMPLATE_2 = 'assets/templates/notification.html';
const PROCESSING_TEMPLATE = 'assets/templates/notification_processing.html';

const MISHANDLED_ERROR_MESSAGE = `Oops... an error that wasn't handled correctly occurred. If you continue to experience this or other issues, please contact us at ${process.env.SUPPORT_EMAIL}.`;

/**
 * GEARS Notify service.
 */
export class NotifyService {
  private _notify: angular.uiNotification.INotificationService;

  constructor(Notification: angular.uiNotification.INotificationService) {
    'ngInject';

    this._notify = Notification;
  }

  /**
   * Dynamically displays notifications.
   *
   * @param data Takes either a direct string or object.
   * @param type 'success', 'warning', or 'error', 'primary'.
   * @param delay Timeout in milliseconds for notification to disappear. if
   * true, notification persists.
   * @param title Title of the notification.
   * @param positionX Horizontal screen position of the notification. Can be
   * "left", "right" or "center".
   */
  display(
    data: unknown,
    type: NotificationType,
    delay: boolean | number = 8000,
    title?: string,
    positionX: NotificationHorizontalPosition = 'center'
  ) {
    let message: unknown = null;

    if (typeof data === 'string') {
      message = data;
    } else if (data && typeof data === 'object') {
      data = data.data || data;

      if ('feedback' in data) {
        message = data.feedback;
      }

      if ('message' in data) {
        message = data.message;
      }

      if (!message && data.status) {
        switch (data.status) {
          case 404:
            message = 'Entity Not Found';
            break;
          case 401:
            message = 'Unauthorized';
            break;
        }
      }
    }

    if (!message) {
      if (type !== 'error')
        return console.warn(
          '[notify.display] the notification was not provided with any message to be diplayed.'
        );

      message = MISHANDLED_ERROR_MESSAGE;
    }

    const options = {
      title,
      message,
      positionX,
      delay,
      templateUrl: TEMPLATE_1
    };

    switch (type) {
      case 'success':
        void this._notify.success(options);
        break;
      case 'warning':
        void this._notify.warning(options);
        break;
      case 'error':
        void this._notify.error(options);
        break;
    }
  }

  /**
   * Handle "error" notification.
   *
   * @param error Error data.
   * @param persist Amount of time the notification is removed.
   */
  error(error: unknown, persist?: number) {
    const delay = persist ? false : 5000;

    if (!isValidErrorValue(error)) {
      void this._notify.error({
        message: 'An unknown error occurred.',
        positionX: 'center',
        templateUrl: TEMPLATE_2,
        delay
      });

      return;
    }

    if (typeof error === 'string') {
      void this._notify.error({
        message: error,
        positionX: 'center',
        templateUrl: TEMPLATE_2,
        delay
      });

      return;
    }

    if (error.data) {
      if (error.data.feedback) {
        void this._notify.error({
          message: error.data.feedback,
          positionX: 'center',
          templateUrl: TEMPLATE_2,
          delay
        });
        return;
      }
    } else if (error.feedback) {
      void this._notify.error({
        message: error.feedback,
        positionX: 'center',
        templateUrl: TEMPLATE_2,
        delay
      });
      return;
    }

    switch (error.status) {
      case 404:
        if (error.data) {
          if (error.data.feedback) {
            void this._notify.error({
              message: error.data.feedback,
              positionX: 'center',
              templateUrl: TEMPLATE_2,
              delay
            });
            return;
          }
        }
        void this._notify.error({
          message: 'Entity Not Found',
          positionX: 'center',
          templateUrl: TEMPLATE_2,
          delay
        });
        return;
      case 401:
        void this._notify.error({
          message: 'Unauthorized',
          positionX: 'center',
          templateUrl: TEMPLATE_2,
          delay
        });
        return;
      case 422:
        void this._notify.error({
          message: error.data.message,
          positionX: 'center',
          templateUrl: TEMPLATE_2,
          delay
        });
        return;
      case 403:
        void this._notify.error({
          message: 'Forbidden',
          positionX: 'center',
          templateUrl: TEMPLATE_2,
          delay
        });
        return;
      case 408:
        void this._notify.error({
          message: 'Request Timeout',
          positionX: 'center',
          templateUrl: TEMPLATE_2,
          delay
        });
        return;
      case 500:
        void this._notify.error({
          message:
            'Internal Server Error. Something went wrong on our end. Please contact support@littlearms.com for assistance.',
          positionX: 'center',
          templateUrl: TEMPLATE_2,
          delay
        });
        return;
      case -1:
        void this._notify.error({
          message:
            'Timeout. Please contact us at support@littlearms.com for help.',
          positionX: 'center',
          templateUrl: TEMPLATE_2,
          delay
        });
        return;
    }

    if (error.feedback) {
      void this._notify.error({
        message: error.feedback,
        positionX: 'center',
        templateUrl: TEMPLATE_2,
        delay
      });
    } else if (error.data?.feedback) {
      void this._notify.error({
        message: error.data.feedback,
        positionX: 'center',
        templateUrl: TEMPLATE_2,
        delay
      });
    }
  }

  /**
   *  Handle "warning" notification.
   *
   * @param message Notification message.
   * @param persist Amount of time the notification is removed.
   */
  warning(message: string, persist?: number) {
    const delay = persist ? false : 5000;

    void this._notify.warning({
      message: message,
      positionX: 'center',
      templateUrl: TEMPLATE_2,
      delay
    });
  }

  /**
   *  Handle "success" notification.
   *
   * @param message Notification message.
   * @param persist Amount of time the notification is removed.
   */
  success(message: string, persist?: number) {
    const delay = persist ? false : 5000;

    void this._notify.success({
      message: message,
      positionX: 'center',
      templateUrl: TEMPLATE_2,
      delay
    });
  }

  /**
   * Handle "processing" notification.
   *
   * @param message Notification message.
   * @param persist Amount of time the notification is removed.
   */
  processing(message: string, persist?: number) {
    const delay = persist ? false : 5000;

    void this._notify.primary({
      message: message,
      positionX: 'center',
      templateUrl: PROCESSING_TEMPLATE,
      delay
    });
  }

  /**
   * Clear notifications.
   */
  clear() {
    void this._notify.clearAll();
  }
}

export default angular
  .module('app.notify', [])
  .service('notify', NotifyService)
  .service('$notify', NotifyService).name;

//#region Helper Functions

function isValidErrorValue(
  value: unknown
): value is string | Record<string, unknown> {
  return !!value && (typeof value === 'string' || typeof value === 'object');
}

//#endregion Helper Functions
