'use strict';
import * as _ from 'lodash';

const angular = require('angular');

/*@ngInject*/
export function ToolEditorConnectorsService($rootScope, Notification, Block, notify) {
  var data = {
    connectors: [],
    lines: [],
    nodeOptionsNode: '',
    lastFocusedInput: null,
    toolId: null
  };

  var itemWizardBlock = null;
  // let connectors = [];
  let liveConnector = [];

  // Create a singleton manager object that
  // tracks node selection and draws sample
  // lines while in the midst of a selction.
  const SelectionManager = (function() {
    var selectedNode;
    var selectionCushion = 20;
    var mousePos = {
      x: 0,
      y: 0,
      set(x, y) {
        this.x = x;
        this.y = y;
      }
    };

    var addSelectionLine = (x1, y1, x2, y2, clr = 'yellow') => {
      // data.lines.push({
      //   mx: x1,
      //   my: y1,
      //   cx1: x1 + 50,
      //   cy1: y1,
      //   cx2: x2 - 50,
      //   cy2: y2,
      //   cex: x2,
      //   cey: y2,
      //   stroke: clr,
      //   dasharray: '2, 2',
      //   mouseNode: true
      // });

      data.lines.push({
        mx: x2 + selectionCushion,
        my: y2,
        cx1: x2 + selectionCushion,
        cy1: y2,
        cx2: x2 + selectionCushion,
        cy2: y2,
        cex: x2 - selectionCushion,
        cey: y2,
        stroke: clr,
        dasharray: '2, 2',
        mouseNode: true
      });

      var lineBX1 = x1 + selectionCushion;
      var lineBX2 = x2 - selectionCushion;
      data.lines.push({
        mx: lineBX1,
        my: y1,
        cx1: lineBX1 + 100,
        cy1: y1,
        cx2: lineBX2 - 100,
        cy2: y2,
        cex: lineBX2,
        cey: y2,
        stroke: clr,
        dasharray: '2, 2',
        mouseNode: true
      });

      data.lines.push({
        mx: x1,
        my: y1,
        cx1: x1,
        cy1: y1,
        cx2: x1 + selectionCushion,
        cy2: y1,
        cex: x1 + selectionCushion,
        cey: y1,
        stroke: clr,
        dasharray: '2, 2',
        mouseNode: true
      });
    };

    var drawSampleLine = outputNode => {
      _.remove(data.lines, {
        mouseNode: true
      });

      if (selectedNode) {
        let inputNode = selectedNode;

        let toolEditorBounds = angular.element(document.querySelector('#tool-editor'))[0].getBoundingClientRect();

        let offset = {
          x: toolEditorBounds.left,
          y: toolEditorBounds.top
        };

        let inputNodeBounds = inputNode[0].getBoundingClientRect();

        let x1 = inputNodeBounds.left - offset.x + 10,
          y1 = inputNodeBounds.top - offset.y + 10,
          x2,
          y2;

        let lineColor = 'yellow';

        if (outputNode) {
          lineColor = outputNode.hasClass('input') ? '#05ff55' : 'red';

          let outputNodeBounds = outputNode[0].getBoundingClientRect();

          x2 = outputNodeBounds.left - offset.x + 10;
          y2 = outputNodeBounds.top - offset.y + 10;
        } else {
          x2 = mousePos.x - offset.x + 10 - selectionCushion;
          y2 = mousePos.y - offset.y + 10;
        }

        addSelectionLine(x1, y1, x2, y2, lineColor);
      }

      $rootScope.$apply();
    };

    angular.element(document).on('mousemove', e => {
      if (selectedNode != null) {
        mousePos.set(e.clientX, e.clientY);

        let elem = e.target.nodeName === 'TOOL-EDITOR-NODE' ? angular.element(e.target) : null;
        drawSampleLine(elem);
      }
    });

    return {
      start(node) {
        selectedNode = node;
        // drawLineToMouse();
      },
      end() {
        selectedNode = null;
        drawSampleLine();
      }
    };
  })();

  // Public API here
  function bringToFront(block) {
    $rootScope.$broadcast('bring-block-to-front', block);
    $rootScope.$apply();
  }

  function insertDictionaryTerm(term) {
    if (!data.lastFocusedInput) {
      Notification.warning({
        message: 'No text field selection detected'
      });
    } else {
      data.lastFocusedInput.target.value = [data.lastFocusedInput.target.value.slice(0, data.lastFocusedInput.target.selectionEnd), term, data.lastFocusedInput.target.value.slice(data.lastFocusedInput.target.selectionEnd)].join('');
    }
  }

  function setNodeOptionsNode(node) {
    data.nodeOptionsNode = node;
    $rootScope.$broadcast('node-options-node-updated', data.nodeOptionsNode);
    // $rootScope.$apply();
  }

  function getData() {
    return data;
  }

  function setConnectors(connects) {
    data.connectors = connects;
  }

  function addConnector(inputNode, outputNode) {
    if (inputNode && outputNode) {
      if (!_.some(data.connectors, {
          inputNode,
          outputNode
        })) {
        data.connectors.push({
          inputNode,
          outputNode
        });
      } else {
        notify.error('Connection Already Exists');
      }
    } else if (liveConnector.length === 2) {
      data.connectors.push({
        inputNode: liveConnector[1][0].id,
        outputNode: liveConnector[0][0].id
      });
      let toolEditor = angular.element(document.querySelector('#tool-editor'));
      let toolEditorBounds = toolEditor[0].getBoundingClientRect();
      let xOffset = toolEditorBounds.left;
      let yOffset = toolEditorBounds.top;
      drawLine(`#${liveConnector[1][0].id}`, `#${liveConnector[0][0].id}`, xOffset, yOffset);
      $rootScope.$broadcast('connection-added', liveConnector[0][0].id, liveConnector[1][0].id);
      $rootScope.$apply();
    }
    liveConnector = [];
  }

  function deleteConnector(inputNode, outputNode) {
    data.lines = _.remove(data.lines, function(n) {
      return n.inputNode !== `#${inputNode}` || n.outputNode !== `#${outputNode}`;
    });
    Notification.clearAll();
    _.remove(data.connectors, {
      inputNode,
      outputNode
    });
    $rootScope.$broadcast('connection-deleted', outputNode, inputNode);
  }

  function displayItemWizardDeletionVerification() {
    Notification.warning({
      positionX: 'center',
      templateUrl: 'assets/templates/editor_notification_template.html',
      delay: true
    });
  }

  function deleteBlock(block, approved) {
    let deletion = function() {
      for (let i = 0; i < block.rows.length; i++) {
        _.remove(data.lines, {
          inputNode: `#${block.rows[i].inputNodeId}`
        });
        _.remove(data.lines, {
          outputNode: `#${block.rows[i].outputNodeId}`
        });
        _.remove(data.connectors, {
          inputNode: block.rows[i].inputNodeId
        });
        _.remove(data.connectors, {
          outputNode: block.rows[i].outputNodeId
        });
      }
      itemWizardBlock ? itemWizardBlock = null : null;
      $rootScope.$broadcast('block-deleted', block);
      $rootScope.$apply();
    };
    if (block) {
      if (block.type === 'item-wizard' && approved === undefined) {
        itemWizardBlock = block;
        $rootScope.toolEditorConnectorsService = this;
        displayItemWizardDeletionVerification();
      } else {
        deletion();
      }
    } else if (itemWizardBlock && approved) {
      block = itemWizardBlock;
      deletion();
    } else {
      deletion();
    }
  }

  function addRow(block, type) {
    console.log(`add row type: ${type}`);
    switch (type) {
      case 'Source':
        block.rows.push(new Block.SourceRow());
        break;
      case 'Answer':
        block.rows.push(new Block.Answer());
        break;
      case 'Risk Category Row':
        // find the Risk Category Criteria label row and insert right before that
        block.rows.splice(_.findIndex(block.rows, row => {
          return _.some(row.fields, {text: 'Risk Category Criteria Row'});
        }) - 1, 0, new Block.RiskCategoryRow());
        // block.rows.push(new Block.RiskCategoryRow());
        break;
      case 'Risk Category Criteria Row':
        block.rows.push(new Block.RiskCategoryCriteriaRow());
        break;
      case 'Wizard Answer':
        block.rows.push(new Block.WizardAnswer());
        break;
      case 'Dictionary Term':
        block.rows.push(new Block.DictionaryTerm());
        break;
      case 'Rule':
        block.rows.push(new Block.Rule());
        break;
      case 'Multiple Answer Condition':
        block.rows.push(new Block.MultipleAnswerCondition());
        break;
      case 'Manipulate Variable':
        block.rows.push(new Block.ManipulateVariable());
        break;
      case 'Wizard Variable':
        block.rows.push(new Block.WizardVariable());
        break;
      case 'Wizard Condition':
        block.rows.push(new Block.WizardCondition());
        break;
      case 'Wizard Resolution':
        block.rows.push(new Block.WizardResolution());
        break;
      case 'Wizard Reference':
        block.rows.push(new Block.WizardReference());
        break;
      case 'Overview Option':
        block.rows.push(new Block.OverviewRow());
        break;
      case 'Options':
        block.rows.push(new Block.OverviewRow());
        break;
      case 'Content':
        block.rows.push(new Block.OverviewRow());
        break;
      case 'Risk Category':
        block.rows.push(new Block.RiskCategoryRow());
        break;
    }
  }

  function deleteRow(block, row) {
    _.remove(data.lines, {
      inputNode: `#${row.inputNodeId}`
    });
    _.remove(data.lines, {
      outputNode: `#${row.outputNodeId}`
    });
    _.remove(data.connectors, {
      inputNode: row.inputNodeId
    });
    _.remove(data.connectors, {
      outputNode: row.outputNodeId
    });
    $rootScope.$broadcast('row-deleted', block, row);
    $rootScope.$apply();
  }

  function highlightLineForDeletion(nodes) {
    if (nodes) {
      _.forEach(data.lines, function(line) {
        if (line.inputNode === `#${nodes[0]}` && line.outputNode === `#${nodes[1]}`) {
          line.stroke = 'red';
          line.dasharray = '10, 5';
        }
      });
    } else {
      _.forEach(data.lines, function(line) {
        line.stroke = 'cyan';
        line.dasharray = '';
      });
    }
    $rootScope.$broadcast('block-lines-updated');
    $rootScope.$apply();
  }

  function nodeClicked(node) {
    if (!liveConnector.length) {
      SelectionManager.start(node);

      if (node[0].id.includes('Input')) {
        Notification('Please select an output first');
      } else {
        node.addClass('highlight');
        liveConnector.push(node);
      }
    } else if (node[0].id.includes('Output')) {
      Notification('Please select an input first');
    } else {
      liveConnector[0].removeClass('highlight');
      node.addClass('node-connect-success');
      setTimeout(function() {
        node.removeClass('node-connect-success');
      }, 1000);
      liveConnector.push(node);
      addConnector();

      SelectionManager.end();
    }
  }

  function emptyLiveConnector() {
    SelectionManager.end();

    if (liveConnector.length) {
      liveConnector[0].removeClass('highlight');
      liveConnector = [];
    }
  }

  function drawLine(inputNodeId, outputNodeId, xOffset, yOffset) {
    let outputNode = angular.element(document.querySelector(outputNodeId));
    let inputNode = angular.element(document.querySelector(inputNodeId));
    if (!outputNode[0] || !inputNode[0]) {
      return;
    }
    let outputNodeBounds = outputNode[0].getBoundingClientRect();
    let inputNodeBounds = inputNode[0].getBoundingClientRect();
    //10 sets the origin in the center of the node based on node size being 20x20
    let x1 = inputNodeBounds.left - xOffset + 10;
    let y1 = inputNodeBounds.top - yOffset + 10;
    let x2 = outputNodeBounds.left - xOffset + 10;
    let y2 = outputNodeBounds.top - yOffset + 10;

    // let cushion = 30;
    let cushion = 20;
    // data.lines.push({ // inputNode cushion
    //   x1,
    //   y1,
    //   x2: x1 - cushion,
    //   y2: y1,
    //   stroke: 'cyan',
    //   inputNode: inputNodeId,
    //   outputNode: outputNodeId
    // });
    // data.lines.push({ // outputNode cushion
    //   x1: x2 + cushion,
    //   y1: y2,
    //   x2,
    //   y2,
    //   stroke: 'cyan',
    //   inputNode: inputNodeId,
    //   outputNode: outputNodeId
    // });
    // data.lines.push({ // connector line
    //   x1: x1 - cushion,
    //   y1,
    //   x2: x2 + cushion,
    //   y2,
    //   stroke: 'cyan',
    //   inputNode: inputNodeId,
    //   outputNode: outputNodeId
    // });

    // inputNode cushion
    data.lines.push({
      mx: x1,
      my: y1,
      cx1: x1,
      cy1: y1,
      cx2: x1 - cushion,
      cy2: y1,
      cex: x1 - cushion,
      cey: y1,
      stroke: 'cyan',
      inputNode: inputNodeId,
      outputNode: outputNodeId
    });

    // connector line
    var lineBX1 = x1 - cushion;
    var lineBX2 = x2 + cushion;
    data.lines.push({
      mx: lineBX1,
      my: y1,
      cx1: lineBX1 - 100,
      cy1: y1,
      cx2: lineBX2 + 100,
      cy2: y2,
      cex: lineBX2,
      cey: y2,
      stroke: 'cyan',
      inputNode: inputNodeId,
      outputNode: outputNodeId
    });

    // outputNode cushion
    data.lines.push({
      mx: x2 - cushion,
      my: y2,
      cx1: x2 - cushion,
      cy1: y2,
      cx2: x2 - cushion,
      cy2: y2,
      cex: x2 + cushion,
      cey: y2,
      stroke: 'cyan',
      inputNode: inputNodeId,
      outputNode: outputNodeId
    });

    // data.lines.push({ // inputNode cushion
    //   mx: x1,
    //   my: y1,
    //   cx1: x1 - 50,
    //   cy1: y1,
    //   cx2: x2 + 50,
    //   cy2: y2,
    //   cex: x2,
    //   cey: y2,
    //   stroke: 'cyan',
    //   inputNode: inputNodeId,
    //   outputNode: outputNodeId
    // });

    // data.lines.push({ // inputNode cushion
    //   mx: x1,
    //   my: y1,
    //   cx1: x1 - 50,
    //   cy1: y1,
    //   cx2: x2 + 50,
    //   cy2: y2,
    //   cex: x2,
    //   cey: y2,
    //   stroke: 'cyan',
    //   inputNode: inputNodeId,
    //   outputNode: outputNodeId
    // });
  }

  function drawLines() {
    data.lines = [];
    let toolEditor = angular.element(document.querySelector('#tool-editor'));
    let toolEditorBounds = toolEditor[0].getBoundingClientRect();
    let xOffset = toolEditorBounds.left;
    let yOffset = toolEditorBounds.top;
    for (let i = 0; i < data.connectors.length; i++) {
      drawLine(`#${data.connectors[i].inputNode}`, `#${data.connectors[i].outputNode}`, xOffset, yOffset);
    }
    $rootScope.$broadcast('editor-lines-updated');
    $rootScope.$apply();
  }

  function drawBlockLines(block) {
    let i;
    let inputNodeId = null;
    let outputNodeId = null;
    let toolEditor = angular.element(document.querySelector('#tool-editor'));
    let toolEditorBounds = toolEditor[0].getBoundingClientRect();
    let xOffset = toolEditorBounds.left;
    let yOffset = toolEditorBounds.top;
    for (i = 0; i < block.rows.length; i++) {
      if (block.rows[i].inputNodeId) {
        if (_.some(data.lines, {
            inputNode: `#${block.rows[i].inputNodeId}`
          })) {
          inputNodeId = block.rows[i].inputNodeId;
          outputNodeId = _.find(data.lines, {
            inputNode: `#${block.rows[i].inputNodeId}`
          }).outputNode;
          data.lines = _.remove(data.lines, function(n) {
            return n.inputNode !== `#${block.rows[i].inputNodeId}`;
          });
          _.forEach(data.connectors, function(connector) {
            if (connector.inputNode === inputNodeId) {
              drawLine(`#${inputNodeId}`, `#${connector.outputNode}`, xOffset, yOffset);
            }
          });
        }
      }
      if (block.rows[i].outputNodeId) {
        if (_.some(data.lines, {
            outputNode: `#${block.rows[i].outputNodeId}`
          })) {
          inputNodeId = _.find(data.lines, {
            outputNode: `#${block.rows[i].outputNodeId}`
          }).inputNode;
          outputNodeId = block.rows[i].outputNodeId;
          data.lines = _.remove(data.lines, function(n) {
            return n.outputNode !== `#${block.rows[i].outputNodeId}`;
          });
          _.forEach(data.connectors, function(connector) {
            if (connector.outputNode === outputNodeId) {
              drawLine(`#${connector.inputNode}`, `#${outputNodeId}`, xOffset, yOffset);
            }
          });
        }
      }
    }
    $rootScope.$broadcast('block-lines-updated');
    $rootScope.$apply();
  }

  return {
    data,
    bringToFront,
    insertDictionaryTerm,
    setNodeOptionsNode,
    getData,
    setConnectors,
    addConnector,
    deleteConnector,
    deleteBlock,
    addRow,
    deleteRow,
    highlightLineForDeletion,
    nodeClicked,
    emptyLiveConnector,
    drawLines,
    drawBlockLines,
    liveConnector
  };
}


export default angular.module('app.ToolEditorConnectors', [])
  .factory('ToolEditorConnectors', ToolEditorConnectorsService)
  .name;
