import angular from 'angular';
import { Ng, Component, Prop, State, Getter, Action } from '@angular';
import { IFormController } from 'angular';
import { find, indexOf, uniq, forEach } from 'lodash';

import { UpdateClientContactOptions } from '@api/modules/institution-manager';
import * as constants from '@constants';
import { FormOption } from '@interfaces';
import { Client } from '@interfaces/client';
import { ClientConfig } from '@interfaces/client-config';
import { Institution } from '@interfaces/institution';
import { Zone } from '@interfaces/zone';
import { Subgroup } from '@interfaces/subgroup';
import { ClientContact } from '@interfaces/client-contact';
import { RootState } from '@store/state';
import * as ClientStore from '@store/modules/clients';
import { MeState } from '@store/modules/me';

// import { TEST_CUSTOM_FIELD_CONFIGS } from './test-values';
import {
  ContactInfoFormData,
  GetResource,
  GetClientInstitution,
  CreateClientAction,
  UpdateClientAction,
  ClientFieldOption,
  CustomField,
  ZoneOption,
  SubGroupOption
} from './types';

const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

@Component({
  name: 'clientForm',
  template: require('./client-form.html') as string
})
export class ClientForm extends Ng<ClientForm.Attributes> {
  @Prop() readonly client!: Client | null;

  readonly emailPattern = EMAIL_REGEX;
  readonly countryOptions = constants.COUNTRIES;

  loading = false;
  processing = false;
  loadingInstitution = false;
  stateProvinces: FormOption<string>[] = [];
  zoneOptions: ZoneOption[] = [];
  clientTypeOptions: ClientFieldOption[] = [];
  ethnicityOptions: ClientFieldOption[] = [];
  //
  fName: string | null = null;
  mName: string | null = null;
  lName: string | null = null;
  alias: string | null = null;
  dob: string | null = null;
  localId: string | null = null;
  sex: 'male' | 'female' | null = null;
  gender: string | null = null;
  ethnicity: string | null = null;
  customEthnicity: string | null = null;
  type: string[] = [];
  customType: string | null = null;
  country: string | null = null;
  address1: string | null = null;
  address2: string | null = null;
  address3: string | null = null;
  city: string | null = null;
  stateProvince: string | null = null;
  postalCode: string | null = null;
  phone: string | null = null;
  email: string | null = null;
  clinicalInfo: string | null = null;
  institutionId: string | null = null;
  subGroupId: string | null = null;
  contacts: ContactInfoFormData[] = [];
  customFields: CustomField[] = [];

  private clientId: string | null = null;
  private clientContacts: ClientContact[] = [];

  @State
  readonly me!: MeState;
  @State<RootState>(({ institutions }) => institutions.items)
  readonly institutions!: Institution[];
  @Getter
  readonly isAdmin!: boolean;
  @Getter('permissions/getResources')
  readonly getResources!: GetResource;
  @Action('institutions/get')
  readonly getInstitution!: GetClientInstitution;
  @Action('clients/create')
  readonly createClient!: CreateClientAction;
  @Action('clients/update')
  readonly updateClient!: UpdateClientAction;

  /**
   * Convenience accessor for component form object.
   */
  get form() {
    return this.$scope.form;
  }

  /**
   * Convenience accessor for client config.
   */
  get clientConfig() {
    return this.$store.state.clients.clientConfig;
  }

  /**
   * Convenience accessor for client config custom fields.
   */
  get customClientFields() {
    // return TEST_CUSTOM_FIELD_CONFIGS;
    return this.clientConfig.customFields;
  }

  /**
   * Institution based on the current institution ID.
   */
  get institution() {
    return typeof this.institutionId !== 'string'
      ? null
      : this.institutions.find(({ id }) => id === this.institutionId);
  }

  /**
   * List of current form errors.
   */
  get errors() {
    return Object.keys(this.form.$error);
  }

  /**
   * Whether or not the form is valid and can be submitted.
   */
  get isValid() {
    if (this.errors.length) return false;

    // if it's a new client, make sure we have a subGroupId selected
    if (!this.clientId && !this.subGroupId) return false;

    // specifically check for type being required (checkboxes can't
    // inherently be required in html form. gotta hack it
    if (this.isRequired('type') && !this.type.length) return false;

    const customFieldErrorCount = this.customFields.filter(
      ({ config, value }) =>
        !config.allowNull && (value === undefined || value === null)
    ).length;

    if (customFieldErrorCount > 0) return false;

    return true;
  }

  /**
   * Get name of SubGroup given a SubGroup Id
   */
  get subGroupName() {
    if (!this.subGroupId) return;
    const subGroup = find(this.zoneOptions, { id: this.subGroupId });
    return subGroup ? subGroup.name : 'Not Found';
  }

  $onInit() {
    // TODO: Move to "Watch" decorator when properly working.
    this.$watch('country', this.onCountryChanged);
    // TODO: Move to "Watch" decorator when properly working.
    this.$watch('institutionId', this.onInstitutionIdChanged);
    // TODO: Move to "Watch" decorator when properly working.
    this.$watch('customClientFields', this.onCustomClientFieldsChanged);

    this.$on('contact-removed', (_event: unknown, index: number) =>
      this.removeContact(index)
    );

    void this.load();
  }

  /**
   * Utility function for a field to determine if it's required or not.
   */
  isRequired(key: string) {
    return !this.clientConfig.fields[key].allowNull;
  }

  /**
   * Utility function for a field to determine if it's to be shown or not.
   */
  showField(key: string) {
    return this.clientConfig.fields[key]?.show;
  }

  /**
   * ...
   */
  toggleClientType(value: string) {
    const index = indexOf(this.type, value);

    if (index === -1) {
      this.type.push(value);
    } else {
      if (value === 'other') {
        this.customType = null;
      }

      this.type.splice(index, 1);
    }
  }

  /**
   * ...
   *
   * @param list ...
   * @param value ...
   * @return ...
   */
  toggleListValue<T>(list: T[], value: T) {
    const index = indexOf(list, value);

    if (index === -1) {
      list.push(value);
    } else {
      list.splice(index, 1);
    }
  }

  /**
   * Add a new emergency contact.
   */
  addContact() {
    this.contacts.push({
      id: null,
      fName: null,
      lName: null,
      email: null,
      relation: null,
      country: null,
      address1: null,
      address2: null,
      address3: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      phone: null
    });
  }

  /**
   * Remove an emergency contact.
   */
  removeContact(index: number) {
    this.contacts.splice(index, 1);
  }

  /**
   * Submit form to apply client creation/update.
   *
   * @return A `Promise` that will resolve to the created/updated client.
   */
  async submit() {
    let data: Client | null = null;
    let error: Error | null = null;

    this.processing = true;

    try {
      data = await this.submitRequest();
    } catch (err) {
      error = err;
    }

    this.processing = false;

    if (error) {
      throw error;
    }

    if (!data) {
      return this.$notify.warning(
        'Client creation was successful, but something was wrong with the response from the server.'
      );
    }

    return data;
  }

  /**
   * `country` property watcher callback.
   */
  private onCountryChanged = (value: string | null) => {
    this.stateProvinces = this.$util.getCountryProvinceOptions(value);
  };

  /**
   * `institutionId` property watcher callback.
   */
  private onInstitutionIdChanged = async (value: string | null) => {
    if (!value) return;

    this.loadingInstitution = true;

    if (!this.institution?.zones?.length) {
      await this.getInstitution(value);
    }

    if (!this.institution) return;

    const subGroupAccess = this.isAdmin
      ? '*'
      : this.getResources('institutionmanager:ListClients', 'subGroup');

    this.zoneOptions = createLocationOptions(
      this.institution.zones || [],
      ({ id }) => subGroupAccess === '*' || id in subGroupAccess,
      this.$util
    );

    // see if we only have one subgroup option, expand and set it equal to subgroup
    let subGroups = 0;
    forEach(this.zoneOptions, (z) => {
      forEach(z.regions, (r) => {
        subGroups += r.subGroups.length;
      });
    });

    if (subGroups === 1) {
      forEach(this.zoneOptions, (z) => {
        forEach(z.regions, (r) => {
          if (r.subGroups?.length) {
            z.expanded = true;
            r.expanded = true;
            this.subGroupId = r.subGroups[0].id;
          }
        });
      });
    }

    this.loadingInstitution = false;
  };

  /**
   * `customClientFields` property watcher callback.
   */
  private onCustomClientFieldsChanged = (
    fields: ClientConfig['customFields']
  ) => {
    this.customFields = createCustomFields(fields);

    if (!this.client) return;

    for (const field of this.customFields) {
      field.value = this.client[field.key];
    }
  };

  /**
   * Initialize component by loading and setting all relevant data.
   */
  private async load() {
    this.loading = true;

    let client: Client | null = null;

    if (this.client) {
      client = await this.$store.dispatch('clients/get', this.client);

      if (!client) {
        throw new Error(`Could not find client with ID "${this.client.id}"`);
      }

      this.clientContacts = await this.$api2.cm.listClientContacts({
        institutionId: client.institutionId!,
        subGroupId: client.subGroup.id,
        clientId: client.id
      });

      this.contacts = this.clientContacts.map((client) => angular.copy(client));
    }

    if (this.isAdmin) {
      await this.$store.dispatch('institutions/getAll');
    }

    this.loading = false;

    if (client) {
      this.clientId = client.id;

      this.fName = client.fName;
      this.mName = client.mName;
      this.lName = client.lName;
      this.alias = client.alias;
      this.localId = client.localId;
      this.dob = client.dob;
      this.sex = client.sex;
      this.gender = client.gender;
      this.ethnicity = client.ethnicity;
      this.type = [...(client.type || [])];
      this.country = client.country;
      this.address1 = client.address1;
      this.address2 = client.address2;
      this.address3 = client.address3;
      this.city = client.city;
      this.stateProvince = client.stateProvince;
      this.postalCode = client.postalCode;
      this.phone = client.phone;
      this.email = client.email;
      this.clinicalInfo = client.clinicalInfo;
      this.institutionId = client.institutionId ?? null;
      this.subGroupId = client.subGroup?.id ?? null;
    }

    if (this.dob) {
      this.dob = `${this.dob}T00:00:00`;
    }

    this.institutionId = this.institutionId || this.me.institution?.id || null;
    this.country = this.country || this.me.country || null;

    // Set option values.

    this.ethnicityOptions = uniq([
      ...constants.DEFAULT_ETHNICITY_OPTIONS,
      ...(this.clientConfig.fields.ethnicity.options ?? []),
      constants.CLIENT_CONFIG_OTHER_OPTION
    ]);

    this.clientTypeOptions = uniq([
      ...constants.DEFAULT_CLIENT_TYPE_OPTIONS,
      ...(this.clientConfig.fields.type.options ?? []),
      constants.CLIENT_CONFIG_OTHER_OPTION
    ]);

    // TODO: Devise better way of handling custom value selections for `type`
    // and `ethnicity`.

    if (this.ethnicity) {
      const i = this.ethnicityOptions.findIndex(
        ({ value }) => value === this.ethnicity
      );

      if (i === -1) {
        this.customEthnicity = this.ethnicity;
        this.ethnicity = 'other';
      }
    }

    if (this.type) {
      const i = this.type.findIndex(
        (item) => !this.clientTypeOptions.find(({ value }) => value === item)
      );

      if (i !== -1) {
        this.customType = this.type.splice(i, 1, 'other')[0];
      }
    }

    // TEMP: Fill in form fields for testing.

    // this.fName = 'John';
    // this.mName = 'Doe';
    // this.lName = 'Smith';
    // this.alias = 'Johnny';
    // this.localId = Math.round(Math.random() * 50000).toString();
    // this.dob = '04/30/80';
    // this.sex = 'male';
    // this.ethnicity = 'White';
    // this.customEthnicity = null;
    // this.type = ['Psychopathic Offender'];
    // this.customType = null;
    // this.country = 'US';
    // this.address1 = '1234 Test St';
    // this.address2 = null;
    // this.address3 = null;
    // this.city = 'Arlington';
    // this.stateProvince = 'VA';
    // this.postalCode = '00000';
    // this.phone = '1234567890';
    // this.email = 'john.smith@test.com';
    // this.institutionId = '418759646721';
    // this.subGroupId = '1';

    // Prevents a digest error if no previous promise was awaited.
    await this.$delay(1);

    this.$scope.$apply();
  }

  /**
   * ...
   */
  private async submitRequest() {
    if (!this.isValid) {
      throw new Error('Client data invalid.');
    }

    // Generate payload.

    const options: ClientStore.CreateClientActionOptions = {
      institutionId: this.institutionId!,
      sex: this.sex!,
      dob: this.$filter('dynamicDate')(this.dob!, 'yyyy-MM-dd'),
      country: this.country!
    };

    if (this.alias) options.alias = this.alias;
    if (this.localId) options.localId = this.localId.replace(/ /g, '');
    if (this.fName) options.fName = this.fName;
    if (this.mName) options.mName = this.mName;
    if (this.lName) options.lName = this.lName;

    if (this.ethnicity === 'other') {
      options.ethnicity = this.customEthnicity!;
    } else if (this.ethnicity) {
      options.ethnicity = this.ethnicity;
    }

    if (this.type) {
      options.type = this.type
        // .filter((o) =>
        //   this.clientConfig.fields.type.options.find((t) => t.value === o)
        // )
        .map((t) => (t === 'other' ? this.customType! : t));
    }

    if (this.address1) options.address1 = this.address1;
    if (this.address2) options.address2 = this.address2;
    if (this.address3) options.address3 = this.address3;
    if (this.city) options.city = this.city;
    if (this.stateProvince) options.stateProvince = this.stateProvince;
    if (this.postalCode) options.postalCode = this.postalCode;
    if (this.phone) options.phone = this.phone;
    if (this.email) options.email = this.email;
    if (this.clinicalInfo) options.clinicalInfo = this.clinicalInfo;
    if (this.subGroupId) options.subGroupId = this.subGroupId;
    if (this.gender) options.gender = this.gender;

    // Add custom field values.
    for (const field of this.customFields) {
      options[field.key] = field.value;
    }

    const promise = this.clientId
      ? this.updateClient({ clientId: this.clientId, ...options })
      : this.createClient(options);

    const client = await promise;

    this.clientId = client.id;

    await this.handleContactUpdates();

    return client;
  }

  /**
   * Send request to create contacts for client.
   */
  private async handleContactUpdates() {
    if (!this.institutionId) {
      throw new Error('Invalid institution ID.');
    }

    if (!this.clientId) {
      throw new Error('Invalid client ID.');
    }

    type ContactSubmissionError = {
      name: string;
      op: 'add' | 'edit' | 'delete';
    };

    const errors: ContactSubmissionError[] = [];

    // Create new contacts and updated edited ones.

    const added: ContactInfoFormData[] = [];
    const edited: ContactInfoFormData[] = [];
    const deleted: ClientContact[] = [];

    for (const contact of this.contacts) {
      const { id, ...data } = contact;

      const match = id ? find(this.clientContacts, { id }) : null;

      if (!match) {
        added.push(contact);
      } else if (
        Object.entries(data).some(([key, value]) => value !== match[key])
      ) {
        edited.push(contact);
      }
    }

    for (const contact of this.clientContacts) {
      if (!find(this.contacts, { id: contact.id })) {
        deleted.push(contact);
      }
    }

    for (const contact of [...added, ...edited]) {
      let error: Error | null = null;

      try {
        await this.submitContact(contact);
      } catch (err) {
        error = err as Error;
      }

      if (error) {
        errors.push({
          name: `${contact.fName} ${contact.lName}`,
          op: !contact.id ? 'add' : 'edit'
        });
      }
    }

    for (const { id, fName, lName } of deleted) {
      let error: Error | null = null;

      try {
        await this.deleteContact(id);
      } catch (err) {
        error = err as Error;
      }

      if (error) {
        errors.push({
          name: `${fName} ${lName}`,
          op: 'delete'
        });
      }
    }

    if (!errors.length) return;

    let errorMessage =
      'The modifications to following emergency contacts could not be processed: <br /><br />';

    errorMessage += '<div style="text-align: left;">';

    errorMessage += errors
      .map((err) => {
        return `<small>• Could not ${err.op} ${err.name}</small>`;
      })
      .join('<br />');

    errorMessage += '</dev>';

    throw new Error(errorMessage);
  }

  /**
   * Create/Update client contact.
   *
   * @param data Contact form data.
   */
  private async submitContact(data: ContactInfoFormData) {
    const baseOptions = {
      institutionId: this.institutionId!,
      clientId: this.clientId!,
      fName: data.fName!,
      lName: data.lName!,
      country: data.country!
    };

    const options: Partial<UpdateClientContactOptions> & typeof baseOptions = {
      ...baseOptions
    };

    if (data.email) options.email = data.email;
    if (data.phone) options.phone = data.phone;
    if (data.relation)
      options.relation =
        data.relation == 'Other...' && data.otherRelation
          ? data.otherRelation
          : data.relation;
    if (data.address1) options.address1 = data.address1;
    if (data.address2) options.address2 = data.address2;
    if (data.address3) options.address3 = data.address3;
    if (data.city) options.city = data.city;
    if (data.stateProvince) options.stateProvince = data.stateProvince;
    if (data.postalCode) options.postalCode = data.postalCode;

    if (data.id) {
      await this.$api2.im.updateClientContact({
        contactId: data.id,
        ...options
      });
    } else {
      await this.$api2.im.createClientContact(options);
    }
  }

  /**
   * Delete client contact.
   *
   * @param contactId Contact ID to delete.
   */
  async deleteContact(contactId: string) {
    if (!this.institutionId || !this.clientId || !this.client?.subGroup) {
      return;
    }

    await this.$api2.im.deleteClientContact({
      institutionId: this.institutionId,
      clientId: this.clientId,
      contactId
    });
  }

  /**
   * Transfer existing client
   */
  private transferClient = async () => {
    const subGroups = [];
    // console.log(this.zoneOptions);
    // this.zoneOptions.forEach((zone) => {
    //   subGroups.push({

    //   })
    // zone.regions.forEach((region) => {
    //   region.subGroups.forEach((subGroup) => {
    //     subGroup.regionName = region.name;
    //     subGroup.zoneName = zone.name;
    //     subGroups.push(subGroup);
    //   });
    // });
    // });
    const subGroup = await this.$modals.util.subGroupClientTransfer(
      this.zoneOptions,
      this.clientId
    );
    if (!subGroup) return;

    this.subGroupId = subGroup.id;
  };
}

export namespace ClientForm {
  /** Client form attributes interface */
  export interface Attributes {
    loading: boolean;
    processing: boolean;
    loadingInstitution: boolean;
    stateProvinces: FormOption<string>[];
    zoneOptions: ZoneOption[];
    clientTypeOptions: ClientFieldOption[];
    ethnicityOptions: ClientFieldOption[];
    fName: string | null;
    mName: string | null;
    lName: string | null;
    alias: string | null;
    dob: string | null;
    localId: string | null;
    sex: 'male' | 'female' | null;
    ethnicity: string | null;
    customEthnicity: string | null;
    type: string[];
    customType: string | null;
    country: string | null;
    address1: string | null;
    address2: string | null;
    address3: string | null;
    city: string | null;
    stateProvince: string | null;
    postalCode: string | null;
    phone: string | null;
    email: string | null;
    clinicalInfo: string | null;
    institutionId: string | null;
    subGroupId: string | null;
    contacts: ContactInfoFormData[];
    form: IFormController;
    customFields: CustomField[];
    clientConfig: ClientConfig;
    customClientFields: ClientConfig['customFields'];
    institution: Institution;
    errors: string[];
    isValid: boolean;
  }
}

// region Helper Functions

/**
 * Create location options from zones --> regions --> subGroup.
 *
 * @param zones Nested list of `zones.regions.subGroups`.
 * @param iteratee Callback function for filtering subGroups. SubGroup will be
 * kept out if `true` is returned.
 * @return List of location options.
 */
function createLocationOptions(
  zones: Zone[],
  iteratee: (subgroup: Subgroup) => boolean,
  $util: any
) {
  let subGroups = [];

  // sort alphabetically
  zones = $util.sortArrayByValue(zones, 'name');

  zones.forEach((zone) => {
    if (!Array.isArray(zone.regions) || !zone.regions?.length) return;

    // sort alphabetically
    zone.regions = $util.sortArrayByValue(zone.regions, 'name');

    zone.regions.forEach((region) => {
      let newSubGroups = region.subGroups.filter(iteratee).map((subGroup) => ({
        id: subGroup.id,
        name: subGroup.name,
        zoneRegionName: zone.name + ' > ' + region.name,
        regionName: region.name,
        zoneName: zone.name
      })) as SubGroupOption[];

      newSubGroups = $util.sortArrayByValue(newSubGroups, 'name');
      if (Array.isArray(newSubGroups) && newSubGroups.length) {
        subGroups = subGroups.concat(newSubGroups);
      }
    });
  });
  return subGroups;
  //
  // return zones.map((zone) => {
  //   const regions = zone.regions.map((region) => {
  //     const subGroups = region.subGroups.filter(iteratee).map((subGroup) => ({
  //       id: subGroup.id,
  //       name: subGroup.name
  //     })) as SubGroupOption[];

  //     return { name: region.name, subGroups, expanded: false } as RegionOption;
  //   });

  //   return { name: zone.name, regions, expanded: false } as ZoneOption;
  // });
}

/**
 * ...
 *
 * @param fields ...
 * @return ...
 */
function createCustomFields(fields?: ClientConfig['customFields'] | null) {
  return Object.values(fields || {}).map((config) => {
    return { config, key: config.key, value: config.isList ? [] : null };
  }) as CustomField[];
}

// endregion Helper Functions

export default ClientForm.$module;
