'use strict';
const angular = require('angular');

export default angular.module('app.tool-editor-node', []).directive('toolEditorNode', function(ToolEditorConnectors) {
  'ngInject';
  return {
    template: require('./tool-editor-node.html'),
    restrict: 'EA',
    scope: {
      node: '='
    },
    link: function(scope, element, attrs) {
      element.on('mousedown', function(event) {
        ToolEditorConnectors.nodeClicked(element);

        this.nodeMouseDown = setTimeout(function() {
          ToolEditorConnectors.emptyLiveConnector();
          ToolEditorConnectors.setNodeOptionsNode(element[0].id);
          let toolEditorBounds = angular.element(document.querySelector('#tool-editor'))[0].getBoundingClientRect();
          let xOffset = toolEditorBounds.left;
          let yOffset = toolEditorBounds.top;
          let nodeOptions = angular.element(document.querySelector('#tool-editor-node-options'));
          nodeOptions.css({
            visibility: 'visible',
            opacity: 1,
            left: element[0].getBoundingClientRect().left - xOffset + 20 + 'px',
            top: element[0].getBoundingClientRect().top - yOffset + 'px'
          });
        }, 500);
      });

      element.on('mouseup', function(event) {
        if (this.nodeMouseDown) {
          clearTimeout(this.nodeMouseDown);
        }
      });
    }
  };
}).name;
