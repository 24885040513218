import angular from 'angular';

import { storeConfig } from '@store';

/**
 * ...
 */
export function appConfig(
  $urlRouterProvider: angular.ui.IUrlRouterProvider,
  $locationProvider: angular.ILocationProvider,
  $httpProvider: angular.IHttpProvider,
  $animateProvider: angular.animate.IAnimateProvider,
  $compileProvider: angular.ICompileProvider,
  NotificationProvider: angular.uiNotification.INotificationProvider,
  // ChartJsProvider: angular.chart.IChartJsProvider,
  $storeProvider: angular.store.IStoreProvider
) {
  'ngInject';

  $urlRouterProvider.otherwise('/404');
  $locationProvider.html5Mode(true);
  $httpProvider.interceptors.push('httpRequestInterceptor');

  $httpProvider.defaults.headers = $httpProvider.defaults.headers ?? {};
  $httpProvider.defaults.headers.common['Cache-Control'] = 'no-cache';
  $httpProvider.defaults.cache = false;

  if (!$httpProvider.defaults.headers.get) {
    $httpProvider.defaults.headers.get = {};
  }

  $httpProvider.defaults.headers.get['If-Modified-Since'] = '0';

  $animateProvider.classNameFilter(/auto-animate/);

  // Configure $sanitize service (ng-bind-html) to only allow for resources that
  // share the same origin to be rendered.
  const sanitizeRegex = new RegExp(window.location.origin);
  $compileProvider.aHrefSanitizationWhitelist(sanitizeRegex);
  $compileProvider.imgSrcSanitizationWhitelist(sanitizeRegex);

  NotificationProvider.setOptions({
    positionX: 'center',
    templateUrl: 'assets/components/notification-template.html'
  });

  Chart.pluginService.register({
    beforeRender: (chart) => {
      if (!chart.config.options.showAllTooltips) return;

      // create an array of tooltips
      // we can't use the chart tooltip because there is only one tooltip per chart
      chart.pluginTooltips = [];

      chart.config.data.datasets.forEach(function (dataset, i) {
        chart.getDatasetMeta(i).data.forEach(function (sector, j) {
          chart.pluginTooltips.push(
            new Chart.Tooltip(
              {
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector]
              },
              chart
            )
          );
        });
      });

      // turn off normal tooltips
      chart.options.tooltips.enabled = false;
    },
    afterDraw: (chart, easing) => {
      if (!chart.config.options.showAllTooltips) return;

      // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
      if (!chart.allTooltipsOnce) {
        if (easing !== 1) return;
        chart.allTooltipsOnce = true;
      }

      // turn on tooltips
      chart.options.tooltips.enabled = true;

      Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
        tooltip.initialize();
        tooltip.update();
        // we don't actually need this since we are not animating tooltips
        tooltip.pivot();
        tooltip.transition(easing).draw();
      });
      chart.options.tooltips.enabled = false;
    }
  });

  // Store

  $storeProvider.store(storeConfig);
}

export default appConfig;
