import { angular } from '@angular';

import { ClientManagerRouteGroup } from './modules/client-manager';
import { GearsManagerRouteGroup } from './modules/gears-manager';
import { InstitutionCasePlanTemplateRouteGroup } from './modules/icpt';
import { InstitutionManagerRouteGroup } from './modules/institution-manager';
import { ManagedCasePlanTemplatesRouteGroup } from './modules/mcpt';
import { ToolCreatorRouteGroup } from './modules/tool-creator';
import { UserRouteGroup } from './modules/user';
import { Get, RouteController, RouteGroup, RequestMethod } from './route-group';

/**
 * ...
 */
export interface PasswordResetOptions {
  email: string;
}

declare module 'angular' {
  namespace gears {
    type IAPI2Service = API;
  }
}

/**
 * Request-based API services.
 */
@RouteController('api')
export class API extends RouteGroup {
  /** API Client Manager route requests. */
  readonly cm: ClientManagerRouteGroup;
  /** API GEARS Manager route requests. */
  readonly gm: GearsManagerRouteGroup;
  /** API Institution Case Plan Template route requests. */
  readonly icpt: InstitutionCasePlanTemplateRouteGroup;
  /** API Institution Manager route requests. */
  readonly im: InstitutionManagerRouteGroup;
  /** API Managed Case Plan Templates route requests. */
  readonly mcpt: ManagedCasePlanTemplatesRouteGroup;
  /** API Institution Manager route requests. */
  readonly tc: ToolCreatorRouteGroup;
  /** API User route requests. */
  readonly user: UserRouteGroup;

  /** Get current version of the app. */
  @Get('version')
  readonly getAppVersion!: RequestMethod<string>;

  /** Submit a password reset request for a specified account email address. */
  @Get('password-resets')
  readonly passwordReset!: RequestMethod<void, PasswordResetOptions>;

  constructor() {
    super();

    this.cm = new ClientManagerRouteGroup(this);
    this.gm = new GearsManagerRouteGroup(this);
    this.icpt = new InstitutionCasePlanTemplateRouteGroup(this);
    this.im = new InstitutionManagerRouteGroup(this);
    this.mcpt = new ManagedCasePlanTemplatesRouteGroup(this);
    this.tc = new ToolCreatorRouteGroup(this);
    this.user = new UserRouteGroup(this);
  }
}

export const api = angular.module('app.$api2', []).service('$api2', API).name;
