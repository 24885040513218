import { gears } from 'angular';

/**
 * Test suite for policy translator.
 */
export function translationTest(
  $acl: gears.IAclService,
  policyTranslator: gears.IPolicyTranslatorService
) {
  'ngInject';

  return;

  let statement1 = [
    // {
    //   effect: 'allow',
    //   actions: '*',
    //   resources: '*'
    // },
    // {
    //   effect: 'deny',
    //   actions: '*',
    //   resources: '*'
    // },
    // {
    //   effect: 'deny',
    //   actions: '*',
    //   resources: '*'
    // },
    {
      effect: 'allow',
      actions: ['clientmanager:ListClientEvaluators'],
      resources: ['grn:gifr:clientmanager::1:subGroup:2']
    },
    {
      effect: 'allow',
      actions: ['clientmanager:ListClientContacts'],
      resources: ['grn:gifr:clientmanager::1:subGroup:2']
    },
    {
      effect: 'allow',
      actions: ['clientmanager:UpdateClientContact'],
      resources: '*'
    },
    {
      effect: 'allow',
      actions: ['clientmanager:SubmitClientEvaluation'],
      resources: ['grn:gifr:clientmanager::1:subGroup:*']
    },
    // {
    //   effect: 'deny',
    //   actions: ['clientmanager:UpdateClientCasePlanStatus']
    // },
    {
      effect: 'allow',
      actions: ['clientmanager:ListClientEvaluators']
      // resources: '*'
    },
    {
      effect: 'deny',
      actions: ['clientmanager:ListClientEvaluators']
    }
  ];

  let statement2 = [
    {
      effect: 'allow',
      actions: [
        'institutionmanager:ListClients',
        'clientmanager:ListClientEvaluators',
        'clientmanager:ListClientContacts',
        'clientmanager:ListOffenderHistory',
        'institutionmanager:GetClient',
        'clientmanager:GetOffenderHistory',
        'institutionmanager:CreateClientContact',
        'institutionmanager:UpdateClient',
        'institutionmanager:UpdateClientContact',
        'institutionmanager:CreateEvaluationReport',
        'institutionmanager:EmailReport',
        'institutionmanager:CreateOffenderHistory',
        'institutionmanager:UpdateOffenderHistory',
        'institutionmanager:DeleteOffenderHistory',
        'locationmanager:AddClientToSubGroup',
        'locationmanager:RemoveClientFromSubGroup'
      ],
      resources: [
        'grn:gifr:clientmanager::1:subGroup:2',
        'grn:gifr:clientmanager::1:subGroup:1'
      ]
    },
    {
      effect: 'allow',
      actions: 'clientmanager:GetOffenderHistory',
      resources: [
        'grn:gifr:clientmanager::1:subGroup:2',
        'grn:gifr:clientmanager::1:subGroup:3'
      ]
    },
    // {
    //   effect: 'allow',
    //   actions: '*',
    //   resources: []
    // },
    {
      effect: 'deny',
      actions: [
        'clientmanager:ListClientEvaluatations',
        'clientmanager:GetClientEvaluation',
        'clientmanager:GetEvaluationMedia',
        'clientmanager:CreateClientEvaluation',
        'clientmanager:SaveClientEvaluation',
        // 'clientmanager:SubmitClientEvaluation',
        'clientmanager:DeleteClientEvaluation',
        'clientmanager:RevertClientEvaluation',
        'clientmanager:UploadEvaluationMedia',
        'clientmanager:DeleteEvaluationMedia'
      ]
    },
    {
      effect: 'deny',
      actions: [
        'clientmanager:ListEvaluationReports',
        'clientmanager:ListClientReports',
        'clientmanager:GetReportDocument',
        'clientmanager:GetReportPdf',
        'clientmanager:CreateEvaluationReport',
        'clientmanager:EmailReport'
      ]
    },
    {
      effect: 'deny',
      actions: [
        'clientmanager:ListClientCasePlans',
        'clientmanager:GetClientCasePlan',
        'clientmanager:CreateClientCasePlan',
        'clientmanager:UpdateClientCasePlanStatus',
        'clientmanager:UpdateClientCasePlan'
      ]
    },
    {
      effect: 'deny',
      actions: [
        'institutionmanager:ListInstitutionUsers',
        'institutionmanager:ListInstitutionUser',
        'institutionmanager:InviteUser',
        'institutionmanager:ResendInvite',
        'institutionmanager:RemovePolicyFromUser',
        'institutionmanager:AddUser',
        'institutionmanager:RemoveUser',
        'institutionmanager:AssignPolicyToUser'
      ]
    },
    {
      effect: 'deny',
      actions: [
        'gearsmanager:ListUsers',
        'gearsmanager:GetUser',
        'gearsmanager:CreateUser',
        'gearsmanager:UpdateUser'
      ]
    },
    {
      effect: 'deny',
      actions: [
        'locationmanager:ListZones',
        'locationmanager:ListRegions',
        'locationmanager:ListSubGroups',
        'locationmanager:ListSubGroupClients',
        'locationmanager:CreateZone',
        'locationmanager:CreateRegion',
        'locationmanager:CreateSubGroup'
      ]
    },
    {
      effect: 'deny',
      actions: [
        'icpt:ListCasePlanTemplates',
        'icpt:ListCasePlanTemplateCommits',
        'toolcreator:ListManagedCasePlanTemplates',
        'toolcreator:ListInstitutionCasePlanTemplates',
        'icpt:GetCasePlanTemplateCommit',
        'icpt:CreateCasePlanTemplate',
        'icpt:CommitCasePlanTemplate',
        'icpt:TagCasePlanTemplateCommit',
        'icpt:SetCasePlanTemplateCommitStatus'
      ]
    },
    {
      effect: 'deny',
      actions: [
        'mcpt:ListCasePlanTemplates',
        'mcpt:ListCasePlanTemplateCommits',
        'toolcreator:ListManagedCasePlanTemplates',
        'toolcreator:ListInstitutionCasePlanTemplates'
      ]
    },
    {
      effect: 'deny',
      actions: [
        'institutionmanager:ListPolicies',
        'institutionmanager:CreatePolicy',
        'institutionmanager:UpdatePolicy'
      ]
    },
    {
      effect: 'deny',
      actions: [
        'gearsmanager:ListManagedPolicies',
        'gearsmanager:GetManagedPolicy',
        'gearsmanager:CreateManagedPolicy',
        'gearsmanager:UpdateManagedPolicy'
      ]
    },
    {
      effect: 'deny',
      actions: ['institutionmanager:ListInstitutionOffenseClassification']
    },
    {
      effect: 'deny',
      actions: [
        'gearsmanager:ListManagedOffenseClassifications',
        'gearsmanager:GetManagedOffenseClassification',
        'gearsmanager:CreateManagedOffenseClassification',
        'gearsmanager:UpdateManagedOffenseClassification'
      ]
    },
    {
      effect: 'deny',
      actions: ['institutionmanager:GetInstitution']
    }
  ];

  let statement3 = {
    effect: 'deny',
    actions: '*',
    resources: '*'
  };

  const $sm1 = policyTranslator.mapStatement(statement1);
  const $sm2 = policyTranslator.mapStatement(statement2);
  const $sm3 = policyTranslator.mapStatement(statement3);

  const $pm = policyTranslator.permissionProfile([$sm1, $sm2]);

  console.log('$pm', $pm);

  let $check1 = $acl('clientmanager:GetOffenderHistory', $pm);

  let $check2 = $acl(
    {
      action: 'clientmanager:GetOffenderHistory'
    },
    $pm
  );

  let $check3 = $acl(
    {
      action: 'clientmanager:GetOffenderHistory',
      resources: {
        name: 'subGroup',
        values: '1'
      }
    },
    $pm
  );

  let $check4 = $acl(
    {
      action: 'clientmanager:GetOffenderHistory',
      resources: {
        name: 'subGroup',
        values: ['1', '2', '3']
      }
    },
    $pm
  );

  let $check5 = $acl(
    {
      action: 'clientmanager:GetOffenderHistory',
      resources: {
        type: 'subGroup',
        values: '1'
      }
    },
    $pm
  );

  let $check6 = $acl(
    [
      {
        action: 'clientmanager:GetOffenderHistory',
        resources: {
          name: 'subGroup',
          values: '1'
        }
      },
      {
        action: 'clientmanager:GetOffenderHistory',
        resources: {
          name: 'subGroup',
          values: '2'
        }
      },
      {
        action: 'clientmanager:GetOffenderHistory',
        resources: {
          name: 'subGroup',
          values: '3'
        }
      },
      {
        action: 'clientmanager:GetOffenderHistory',
        resources: {
          name: 'subGroup',
          values: '4'
        }
      }
    ],
    $pm
  );

  let $check7 = $acl(
    {
      action: 'clientmanager:UpdateClientContact',
      resources: {
        name: 'subGroup',
        values: '1'
      }
    },
    $pm
  );

  let $check8 = false;

  try {
    $check8 = $acl(
      `fakeservice:FakeAction        ||





             clientmanager:UpdateClientContact`,
      $pm
    );
  } catch (err) {
    //
  }

  console.log({
    $check1,
    $check2,
    $check3,
    $check4,
    $check5,
    $check6,
    $check7,
    $check8
  });
}
