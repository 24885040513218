export default {
  recidivismRate(riskLevel, institutionClient, score, sex) {
    let percentage;
    let institutionRiskLevelDescription;
    let communityRiskLevelDescription;
    if (typeof score !== 'number' && score) score = parseInt(score, 10);
    if (score !== 0 && !score) return;
    if (sex !== 'male' && sex !== 'female') return;

    if (score >= 0 && score <= 4) {
      percentage = institutionClient ? '0%' : '1%';
      institutionRiskLevelDescription =
        'Level of custody needed us minimum. Consider early release.';
      communityRiskLevelDescription =
        'Level of supervision/service needed is minimum. Consider administrative closure.';
    } else if (score >= 5 && score <= 10) {
      percentage = institutionClient ? '6%' : '8%';
      institutionRiskLevelDescription =
        'Level of custody suggested is minimum. Consider early release.';
      communityRiskLevelDescription =
        'Level of supervision/service needed is minimum. Consider administrative closure.';
    } else if (score >= 11 && score <= 19) {
      percentage = institutionClient ? '28%' : '23%';
      institutionRiskLevelDescription =
        'Level of custody suggested is minimum. Consider early release with short-term treatment.';
      communityRiskLevelDescription =
        'Medium level of supervision/service is suggested but consider minimum with management and/or treatment of dynamic risk factors.';
    } else if (score >= 20 && score <= 29) {
      percentage = institutionClient ? '45%' : '44%';
      institutionRiskLevelDescription =
        'Level of custody suggested us medium. Consider minimum and/or early release with management/treatment of dynamic risk factors.';
      communityRiskLevelDescription =
        'Maximum level of supervision/service is suggested but consider medium supervision with management and/or treatment of dynamic risk factors.';
    } else if (score >= 30) {
      percentage = institutionClient ? '>61%' : '>90%';
      institutionRiskLevelDescription =
        'Medium or maximum level of custody is recommended. Consider medium when prison experience is acceptable. Consider early release ONLY with management/treatment of risk factors.';
      communityRiskLevelDescription =
        'Maximum level of supervision/services is highly recommended.';
    }

    let probationGuideline;
    let halfwayHouse;

    if (sex === 'male') {
      if (score >= 0 && score <= 14) halfwayHouse = 'Highly Appropriate';
      if (score >= 15 && score <= 19)
        halfwayHouse = 'Appropriate with close supervision';
      if (score >= 20)
        halfwayHouse =
          'Not Appropriate unless intensive supervision and treatment are also provided.';
    } else if (sex === 'female') {
      if (score >= 0 && score <= 12) halfwayHouse = 'Highly Appropriate';
      if (score >= 13 && score <= 19)
        halfwayHouse = 'Appropriate with close supervision';
      if (score >= 20)
        halfwayHouse =
          'Not Appropriate unless intensive supervision and treatment are also provided.';
    }

    if (score >= 0 && score <= 7) probationGuideline = 'Minimum Surveillance';
    if (score >= 8 && score <= 15) probationGuideline = 'Medium Surveillance';
    if (score >= 16) probationGuideline = 'Maximum Surveillance';

    return {
      percentage,
      probationGuideline,
      halfwayHouse,
      institutionRiskLevelDescription,
      communityRiskLevelDescription
    };
  },
  comparisonPercentage(score, sex) {
    if (typeof score !== 'number' && score) score = parseInt(score, 10);
    if (score !== 0 && !score) return;
    if (sex !== 'male' && sex !== 'female') return;
    let comps = {
      0: {
        male: '0.2%',
        female: '0.5%'
      },
      1: {
        male: '0.4%',
        female: '1.6%'
      },
      2: {
        male: '0.8%',
        female: '4.4%'
      },
      3: {
        male: '1.4%',
        female: '8.5%'
      },
      4: {
        male: '1.8%',
        female: '12.5%'
      },
      5: {
        male: '2.7%',
        female: '17.3%'
      },
      6: {
        male: '3.6%',
        female: '22.8%'
      },
      7: {
        male: '4.2%',
        female: '27.0%'
      },
      8: {
        male: '5.1%',
        female: '30.1%'
      },
      9: {
        male: '6.6%',
        female: '34.7%'
      },
      10: {
        male: '8.6%',
        female: '38.8%'
      },
      11: {
        male: '10.8%',
        female: '42.4%'
      },
      12: {
        male: '11.6%',
        female: '47.1%'
      },
      13: {
        male: '13.9%',
        female: '51.4%'
      },
      14: {
        male: '16.3%',
        female: '55.0%'
      },
      15: {
        male: '17.6%',
        female: '57.7%'
      },
      16: {
        male: '20.0%',
        female: '61.0%'
      },
      17: {
        male: '22.4%',
        female: '63.8%'
      },
      18: {
        male: '25.0%',
        female: '67.2%'
      },
      19: {
        male: '27.1%',
        female: '69.7%'
      },
      20: {
        male: '30.1%',
        female: '73.2%'
      },
      21: {
        male: '32.6%',
        female: '75.5%'
      },
      22: {
        male: '35.9%',
        female: '77.5%'
      },
      23: {
        male: '40.7%',
        female: '79.9%'
      },
      24: {
        male: '43.9%',
        female: '82.2%'
      },
      25: {
        male: '48.6%',
        female: '84.6%'
      },
      26: {
        male: '52.6%',
        female: '86.5%'
      },
      27: {
        male: '56.1%',
        female: '87.8%'
      },
      28: {
        male: '59.7%',
        female: '89.5%'
      },
      29: {
        male: '63.4%',
        female: '90.7%'
      },
      30: {
        male: '68.4%',
        female: '92.3%'
      },
      31: {
        male: '72.8%',
        female: '93.8%'
      },
      32: {
        male: '76.8%',
        female: '95.1%'
      },
      33: {
        male: '80.5%',
        female: '96.3%'
      },
      34: {
        male: '84.5%',
        female: '96.7%'
      },
      35: {
        male: '87.4%',
        female: '97.3%'
      },
      36: {
        male: '90.6%',
        female: '97.9%'
      },
      37: {
        male: '92.5%',
        female: '98.3%'
      },
      38: {
        male: '94.2%',
        female: '98.8%'
      },
      39: {
        male: '96.5%',
        female: '99.4%'
      },
      40: {
        male: '97.4%',
        female: '99.5%'
      },
      41: {
        male: '98.4%',
        female: '99.5%'
      },
      42: {
        male: '99.2%',
        female: '99.6%'
      },
      43: {
        male: '99.6%',
        female: '99.7%'
      },
      44: {
        male: '99.7%',
        female: '100.0%'
      },
      45: {
        male: '99.8%',
        female: '100.0%'
      },
      46: {
        male: '99.9%',
        female: '100.0%'
      },
      47: {
        male: '100.0%',
        female: '100.0%'
      },
      48: {
        male: '100.0%',
        female: '100.0%'
      },
      49: {
        male: '100.0%',
        female: '100.0%'
      },
      50: {
        male: '100.0%',
        female: '100.0%'
      },
      51: {
        male: '100.0%',
        female: '100.0%'
      },
      52: {
        male: '100.0%',
        female: '100.0%'
      },
      53: {
        male: '100.0%',
        female: '100.0%'
      },
      54: {
        male: '100.0%',
        female: '100.0%'
      }
    };
    if (comps[score] && comps[score][sex]) return comps[score][sex];
    return 'N/A';
  }
};
